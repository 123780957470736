import React from "react";
import { Popover } from "@mui/material";
import { styled } from "@mui/system";
import { CustomPopoverProps } from "./popover.types";
import styles from "../../../../../css/_export.module.scss";

const CustomPopover: React.FC<CustomPopoverProps> = ({ className, ...props }) => <Popover {...props} className={className} />;
const MuiPopover = styled(CustomPopover, { name: "MuiPopover" })({
  "& .MuiPaper-root": {
    width:  "max-content", // "11.1875rem",
    boxShadow: "0px 0.5rem 0.75rem rgba(0, 0, 0, 0.1), 0px 0.25rem 1.25rem rgba(0, 0, 0, 0.1)",
    borderRadius: "0.5rem",
    padding: styles.small,
    marginTop: styles.semi,
    "& .MuiMenuItem-root": {
      height: "2.25rem",
      padding: styles.small,
      gap: styles.small,
      fontFamily: styles.primaryFontFamily,
      fontWeight: "400",
      fontSize: styles.fontSizeSM,
      lineHeight: styles.lineHeightMD,
      borderRadius: "0.5rem",
      color: styles.grey10,
      "& .MuiBox-root": {
        display: "flex",
        width: "1rem",
        height: "1rem",
        alignItems: "center",
      },
      "&:hover": {
        background: styles.grey98,
      },
    },
    "& .Mui-selected": {
      background: styles.grey95,
      "&:hover": {
        background: styles.grey95,
      },
    },
  },
});
export default MuiPopover;

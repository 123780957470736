import React, { FocusEvent, forwardRef, HTMLAttributes, MouseEvent, ReactNode, RefAttributes, useImperativeHandle, useRef, useState } from "react";
import { Menu, MenuProps, MenuItem, MenuItemProps } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import "./NestedMenuItem.scss";

export type NestedMenuItemProps = Omit<MenuItemProps, "button"> & {
  parentMenuOpen: boolean;
  label?: string;
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  ContainerProps?: HTMLAttributes<HTMLElement> & RefAttributes<HTMLElement | null>;
  MenuProps?: Partial<Omit<MenuProps, "children">>;
  button?: true;
};

const NestedMenuItem = forwardRef<HTMLLIElement | null, NestedMenuItemProps>(function NestedMenuItem(
  { parentMenuOpen, label, children, className, ContainerProps: ContainerPropsProp = {}, MenuProps, ...MenuItemProps },
  ref
) {
  const { ref: containerRefProp, ...ContainerProps } = ContainerPropsProp;

  const menuItemRef = useRef<HTMLLIElement | null>(null);
  useImperativeHandle(ref, () => menuItemRef.current! ?? null); // eslint-disable-line @typescript-eslint/no-non-null-assertion

  const containerRef = useRef<HTMLDivElement | null>(null);
  useImperativeHandle(containerRefProp, () => containerRef.current);

  const menuContainerRef = useRef<HTMLDivElement | null>(null);

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const handleMouseEnter = (e: MouseEvent<HTMLElement>) => {
    setIsSubMenuOpen(true);

    if (ContainerProps.onMouseEnter) {
      ContainerProps.onMouseEnter(e);
    }
  };
  const handleMouseLeave = (e: MouseEvent<HTMLElement>) => {
    setIsSubMenuOpen(false);

    if (ContainerProps.onMouseLeave) {
      ContainerProps.onMouseLeave(e);
    }
  };

  const handleFocus = (event: FocusEvent<HTMLElement>) => {
    if (event.target === containerRef.current) {
      setIsSubMenuOpen(true);
    }

    if (ContainerProps.onFocus) {
      ContainerProps.onFocus(event);
    }
  };

  const open = isSubMenuOpen && parentMenuOpen;

  return (
    <div {...ContainerProps} ref={containerRef} onFocus={handleFocus} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <MenuItem {...MenuItemProps} ref={menuItemRef} className={`MuiMenuItem-overrides nested-MenuItem ${className ?? ""}`}>
        {label}
        <ChevronRight />
      </MenuItem>

      <Menu
        anchorEl={menuItemRef.current}
        anchorOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
        open={open}
        autoFocus={false}
        disableAutoFocus
        onClose={() => {
          setIsSubMenuOpen(false);
        }}
        {...MenuProps}
      >
        <div ref={menuContainerRef}>{children}</div>
      </Menu>
    </div>
  );
});

export { NestedMenuItem };

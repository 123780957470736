/*eslint-disable no-magic-numbers*/
import React, { useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import MainContent from "../../components/MainContent/MainContent";
import WaitScreen from "../../components/Onboard/WaitScreen/WaitScreen";
import { AppContext } from "../../contexts/AppContext";
import "./Home.scss";
import { WorkspaceContext } from "../../contexts/WorkspaceContext";
import { NUMERIC_TIMEOUTS } from "../../constants/NumericConstants";
import Dialog from "../../components/library/Dialog/Dialog";
import DialogTitle from "../../components/library/DialogTitle/DialogTitle";
import DialogContent from "../../components/library/DialogContent/DialogContent";
import DialogContentText from "../../components/library/DialogContentText/DialogContentText";
import DialogActions from "../../components/library/DialogActions/DialogActions";
import Button from "../../components/library/Button/Button";
import { UserRoles } from "../../types/enums";
import ReadOnlyBanner from "../../components/library/ReadOnlyBanner/ReadOnlyBanner";
import { useTranslation } from "react-i18next";
import { FeatureFlagContext, FeatureFlagProviderType } from "../../contexts/FeatureFlagContext";
import CustomerProvider from "../../contexts/CustomerContext";
import { Redirect } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import DisableFeatureProvider from "../../contexts/DisableFeatureContext";
import Cookies from "js-cookie";

const Home: React.FC = () => {
  const {
    getSyncStatus,
    setInitialSyncInProgress,
    initialSyncInProgress,
    setSyncError,
    syncError,
    getStatus,
    userStatus: { workspaces: groupWorkspaces, roles: loggedInUserRoles, workspace_permissions: workspacePermissions },
    initialLoad,
  } = React.useContext(AppContext) as AppType;
  const { workspaceHomePath, fetchingWorkspace, switchActiveWorkspace, selectedWorkspace, workspaceConfigChangeInProgress } = React.useContext(
    WorkspaceContext
  ) as WorkspaceDataType;
  const { isEnabled, featureFlagsLoading } = React.useContext(FeatureFlagContext) as FeatureFlagProviderType;
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { instance } = useMsal();
  const [showCreateModal, setShowCreateModal] = React.useState<boolean>(false);
  const { show } = useIntercom();

  const getOnlyPermittedWorkspaces = (workspaces: WorkspaceModel[]) =>
    workspaces?.filter((record) => workspacePermissions?.includes(record?.workspace_type));
  let workspacesForSwitch: WorkspaceModel[] = [];
  if (groupWorkspaces) {
    workspacesForSwitch = loggedInUserRoles.includes(UserRoles.GroupOwner) ? groupWorkspaces : getOnlyPermittedWorkspaces(groupWorkspaces);

    // check selected workspace is permitted for the logged in user - incase selected workspace state coming from session storage on app load/refresh
    // skip this check if logged in user's role is Group Owner
    const isSelectedWorkspacePermitted = workspacePermissions.includes(selectedWorkspace?.workspace_type ?? "");
    if (!loggedInUserRoles.includes(UserRoles.GroupOwner) && !isSelectedWorkspacePermitted) {
      workspacesForSwitch?.[0]?.id && switchActiveWorkspace(workspacesForSwitch?.[0]?.id);
    }
  }

  const checkSyncStatus = async () => {
    const syncRequestKey = `SyncRequest-${instance.getActiveAccount()?.username}`;
    const syncRequestId = localStorage.getItem(syncRequestKey) || "";

    try {
      if (syncRequestId) {
        const response = await getSyncStatus(syncRequestId);

        switch (response.statusCode) {
          case "Connector":
          case "Ready":
          case "In Progress":
            setInitialSyncInProgress(true);
            break;
          case "Success":
          case "Cancelled":
          case "Failed":
            setInitialSyncInProgress(false);
            localStorage.removeItem(syncRequestKey);

            if (response.statusCode === "Failed") {
              setSyncError(true);
            } else {
              setSyncError(false);
            }

            break;
          default:
            break;
        }
      } else {
        setInitialSyncInProgress(false);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log(error);
    }
  };

  const startInitialSync = async () => {
    const userStatusResponse = await getStatus();
    // if status call fails => show error
    if (!userStatusResponse) {
      setSyncError(true);
      return;
    }
    checkSyncStatus();
  };

  useEffect(() => {
    (async function startSync() {
      if (!featureFlagsLoading /* && !updateWorkspaceData */) {
        await startInitialSync();
      }
    })();
  }, [featureFlagsLoading]);

  // We periodically check the sync status so we can show the dashboard when it's completed. We should eventually replace this polling with a
  // push mechanism of some kind.
  useEffect(() => {
    const interval = setInterval(() => {
      checkSyncStatus();
    }, NUMERIC_TIMEOUTS.FIVE_SECOND);

    return () => {
      clearInterval(interval);
    };
  });

  React.useEffect(() => {
    return () => {
      setShowCreateModal(false);
    };
  }, []);

  const workspaceType = selectedWorkspace?.workspace_type_route?.toUpperCase?.();

  const getLoaderMessages = React.useCallback(() => {
    if (workspaceConfigChangeInProgress) {
      return t("appLoaders.makingChangesToYourWorkspaceConfigurations", { ns: "home" });
    } else {
      return undefined;
    }
  }, [i18n.language]);

  const isUnderMaintanence = !Cookies.get("redirect_to_ado") && isEnabled("MAINTANANCE_MODE");

  const loaderPrimaryMessage = getLoaderMessages();
  const loaderSecondaryMessage = loaderPrimaryMessage ? "" : t("loginMessages.captionThree", { ns: "home" });

  return (
    <CustomerProvider>
      <DisableFeatureProvider>
        <div className={`home-wrapper ${isUnderMaintanence ? "maintanance-mode" : ""}`}>
          {/* 34464: check if we want the loader screen in english until the translations are loaded */}
          {featureFlagsLoading || initialSyncInProgress || fetchingWorkspace || initialLoad ? (
            <WaitScreen loadingMessage={loaderPrimaryMessage} secondaryMessage={loaderSecondaryMessage} />
          ) : (
            <>
              {isUnderMaintanence && (
                <>
                  <div className="app-under-maintanance-wrapper">
                    <p className="primary-message">{t("maintainanceAlert.title", { ns: "home" })}</p>
                    <p className="sub-text">{t("maintainanceAlert.subTitle", { ns: "home" })}</p>
                  </div>
                </>
              )}
              {!isUnderMaintanence && syncError && (
                <>
                  <div className="sync-error-wrapper">
                    <div>
                      <p>{t("errors.title", { ns: "home" })}</p>
                      <p>
                        {t("errors.subTitle", { ns: "home" })}&nbsp;
                        <a
                          data-testid="SyncFailedChatWithSupportLink"
                          href="#"
                          rel="noreferrer"
                          onClick={(event) => {
                            event.preventDefault();
                            show();
                          }}
                        >
                          {t("errors.supportTeamHyperlinkLabel", { ns: "home" })}
                        </a>
                        .
                      </p>
                    </div>
                  </div>
                </>
              )}
              {!syncError && !isUnderMaintanence && (
                <Switch>
                  <Redirect
                    exact
                    from={"/"}
                    to={`${workspaceHomePath}/activities/${selectedWorkspace?.workspace_type === "einvoicing" ? "receivedEInvoices" : "mine"}`}
                  />
                  <Redirect
                    exact
                    from={`${workspaceHomePath}/dashboard`}
                    to={`${workspaceHomePath}/activities/${selectedWorkspace?.workspace_type === "einvoicing" ? "receivedEInvoices" : "mine"}`}
                  />
                  {/* Can be used on config array as well */}
                  <Route path={`/`} component={MainContent} />
                </Switch>
              )}
            </>
          )}
          <Dialog open={showCreateModal} onClose={() => setShowCreateModal(false)}>
            <DialogTitle className="h3">{t("workspaceCreationSuccessModal.almostThere", { ns: "home" })}</DialogTitle>
            <DialogContent className="mt-ba mb-lg">
              <DialogContentText className="body1">
                {t("workspaceCreationSuccessModal.connectYourEmailAddress", { ns: "home", workspaceType })}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                className="mr-auto"
                variant="secondary"
                onClick={() => {
                  setShowCreateModal(false);
                }}
              >
                {t("labels.buttons.cancel", { ns: "common" })}
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  setShowCreateModal(false);
                  history.push(`/settings/emailSettings/workspace/${selectedWorkspace?.id}`);
                }}
              >
                {t("labels.buttons.connectEmail", { ns: "common" })}
              </Button>
            </DialogActions>
          </Dialog>
          <ReadOnlyBanner />
        </div>
      </DisableFeatureProvider>
    </CustomerProvider>
  );
};

export default Home;

import React from "react";
import Status from "../Status/Status";
import { Failure } from "../../library/Icons/Icons";
import Button from "../../library/Button/Button";
import { useHistory } from "react-router";
import { AlreadyApprovedErrorProps } from "./LinkExpiryTypes";
import { formatDate } from "../../../db/utils/date";

const AlreadyApprovedError: React.FC<AlreadyApprovedErrorProps> = ({ requestData }) => {
  const history = useHistory();

  return (
    <Status
      size="lg"
      logo={<Failure />}
      statusMessage={`Uh-oh! Unable to redirect`}
      subText={`The approval request was ${requestData?.status ?? "-"} on ${formatDate(requestData?.updated_at) ?? "-"} by ${
        requestData?.approver?.name ?? "-"
      }.`}
    >
      <Button
        variant="primary"
        onClick={() => history.push(`/approval-request/${requestData?.approval_request_id}`, { from: "redirect-link-failure" })}
      >
        View Approval Request
      </Button>
    </Status>
  );
};

export default AlreadyApprovedError;

import React from "react";
import { TemplatePopoverContainer } from "../styled/email.template.styled";
import { EmailTemplatePopoverProps } from "../types/email.template.popover.types";
import EmptyResult from "./EmptyResult";
import TemplateListCardComponent from "./TemplateListCard";
import TemplatePreviewCardComponent from "./TemplatePreviewCardComponent";

/**
 * It's an custom email template popover with customize template list card[search,tabs,language dropdown and template list] and
 * template preview card [description of selected template [subject along with language dropdown[optional] and content] and
 * footer with buttons to apply the templates]
 * ----popoverRootProps----
 * @param isModalOpen: boolean to open popover
 * @param isPreviewCardRequired: if true then only display the preview of the template
 * @param isListViewCardRequired: if true then only display the template list
 * @param anchorReferenceValue: 'anchorEl' | 'anchorPosition' | 'none' type of the popover reference
 * @param iconAnchorEl : element reference around which popover will be open if anchorReferenceValue="anchorEl"
 * @param onCloseEmailTemplatePopover: to handle close event of popover
 * @param popoverAnchorOrigin: [optional]- set the anchor origin posistion of popover
 * @param popoverTransformOrigin: [optional]- set the transform origin posistion of dropdown popover
 * @param custommargin: [optional]- set the margin style of the popover
 * @param isApiFailureOrNoDataFound:[optional]- boolean value to identify template is not available
 * other templateListCardProps and templatePreviewCardProps
 */

const EmailTemplatePopover: React.FC<EmailTemplatePopoverProps> = ({
  popoverRootProps: {
    isModalOpen,
    iconAnchorEl,
    onCloseEmailTemplatePopover,
    handleKeyDownEvent,
    anchorReferenceValue,
    isListViewCardRequired,
    isPreviewCardRequired,
    popoverAnchorOrigin,
    popoverTransformOrigin,
    custommargin,
    isApiFailureOrNoDataFound,
  },
  templateListCardProps,
  templatePreviewCardProps,
}) => {
  return (
    <div className="email-template-wrapper">
      <TemplatePopoverContainer
        custommargin={custommargin}
        anchorReference={anchorReferenceValue}
        open={isModalOpen}
        onClose={onCloseEmailTemplatePopover}
        anchorEl={iconAnchorEl}
        anchorOrigin={
          popoverAnchorOrigin ?? {
            vertical: "top",
            horizontal: "center",
          }
        }
        transformOrigin={
          popoverTransformOrigin ?? {
            vertical: "bottom",
            horizontal: "center",
          }
        }
        onKeyDown={handleKeyDownEvent}
      >
        {/** ------- NO TEMPLATE/DATA FOUND ------- */}
        {isApiFailureOrNoDataFound && <EmptyResult subTitleText={"Please refresh your browser."} />}
        {/** ------- II. TEMPLATE LIST CARD ------- */}
        {isListViewCardRequired && !isApiFailureOrNoDataFound && <TemplateListCardComponent {...templateListCardProps} />}
        {/** ------- II. TEMPLATE PREVIEW CARD ------- */}
        {isPreviewCardRequired && !isApiFailureOrNoDataFound && <TemplatePreviewCardComponent {...templatePreviewCardProps} />}
      </TemplatePopoverContainer>
    </div>
  );
};

export default EmailTemplatePopover;

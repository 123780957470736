import React from "react";
import { Order } from "../../../../../types/enums";
import { Sort as SortIcon } from "../../../Icons/Icons";
import "./Sort.scss";

interface SortProps {
  disable: boolean;
  field: string;
  onClickSort?: (order: SortOrder) => void;
}

const Sort: React.FC<SortProps> = ({ disable = false, field, onClickSort }) => {
  // initial order
  const [order, setOrder] = React.useState<Order>(Order.DESC);

  const onClickIcon = () => {
    onClickSort?.({
      sortKey: field,
      sortOrder: order,
    });
    // reverse order on click
    order === Order.DESC ? setOrder(Order.ASC) : setOrder(Order.DESC);
  };

  return (
    <div className="sort-icon" onClick={disable ? undefined : onClickIcon}>
      <SortIcon />
    </div>
  );
};
export default Sort;

import React from "react";
import SecondaryActivity from "../../SecondaryActivity/SecondaryActivity";
import { SecondaryType } from "../../SecondaryActivity/SecondaryActivityTypes";
import { UnsnoozedProps } from "../ActivityItemVariantTypes";
import { useTranslation } from "react-i18next";

const Unsnoozed: React.FC<UnsnoozedProps> = ({ creator, countryCode }) => {
  const { t } = useTranslation();
  return (
    <SecondaryActivity
      variant={SecondaryType.Base}
      creator={{ ...creator, message: t("activityDetails.feedItems.unsnoozeActivity.headerMessage", { ns: "activities" }) }}
      countryCode={countryCode}
    />
  );
};

export default Unsnoozed;

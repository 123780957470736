import React, { useState, MouseEvent, useEffect } from "react";
import { useParams, useHistory, useRouteMatch } from "react-router-dom";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import TableUtils from "../../../../utils/TableUtils/TableUtils";
import { companiesClient } from "../../../../db/accessor";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../../../constants/NumericConstants";
import {
  EXPORT_CATEGORIES,
  ActivityType as ActivityTypeEnum,
  EmailAction,
  FallbackTypes,
  VIEW_CATEGORIES,
  WorkspaceType,
  ActivityOptions,
} from "../../../../types/enums";
import NewActivityPopup from "../../../library/AddNewActivityDropdown/NewActivityPopup";
import { Download, DownloadCloud, Email, ExportV2, NoteFill, Phone, Share } from "../../../library/Icons/Icons";
import { TemplateDefinitionProps } from "../../../../app/Templates/TemplateFactory";
import { TemplateTypes, getViewTypeTemplateCodes, viewType } from "../../../../app/Templates/TemplateTypes";
import { TemplateContext } from "../../../../contexts/TemplateContext";
import { AppContext } from "../../../../contexts/AppContext";
import { automationClientV2, emailsClientV2, exportClient, invoicesClientV2 } from "../../../../db/version2Accessor";
import { AxiosError } from "axios";
import { CONNECTION_STATUS } from "../../../../constants/ConnectionConstants";
import { AlertContext } from "../../../../contexts/AlertContext";
import { ActivityContext } from "../../../../contexts/ActivityContext";
import TrackingUtils from "../../../Tracking/Tracking.Utils";
import { DocumentSwitchContext } from "../../../../contexts/DocumentSwitchContext";
import { ApplicationRouteContext } from "../../../../contexts/ApplicationRouteContext";
import Utils from "../../../../utils/utils";
import _ from "underscore";
import useLocale from "../../../../hooks/useLocale";

import { Table, useColumnFilters } from "../../../library/AtomicComponents/Table/index";
import usePagination from "../../../library/AtomicComponents/Table/hooks/usePagination";
import useColumnSort from "../../../library/AtomicComponents/Table/hooks/useColumnSort";
import useRowSelection from "../../../library/AtomicComponents/Table/hooks/useRowSelection";
import { DEFAULT_PAGINATION_VALUES } from "../../../../constants/NumericConstants";
import APIClientUtils from "../../../../utils/APIClientUtils/APIClientUtils";
import { RowDataTypes } from "../../../library/AtomicComponents/constants/table.constants";
import { IconButton } from "@mui/material";
import { InvoiceContext } from "../../../../contexts/InvoiceContext";
import {
  ACTIVITY_COMPLETE_SUCCESS,
  ACTIVITY_COMPLETE_FAILURE,
  EMAIL_CONNECTOR_ERROR,
  EMPTY_ACTIONS_MESSAGE,
  GREAT_JOB,
  MESSAGE_NOT_SENT_ERROR,
  PDF_DOWNLOAD_SUCCESS,
  SUPPORT_PDF_ERROR,
  BTN_NEW_ACTIVITY,
  ACTIVITY_SUCCESS_TOAST_MSG,
} from "../../../../constants/config";
import useContacts from "../../../../hooks/useContacts";
import { CustomerDetailsDashboardContext, CustomerDetailsDashboardData } from "../../../../contexts/CustomerDetailsDashboardContext";
import { DropdownItemList } from "../../../library/AtomicComponents/TableFilters/Presets/preset.filter.types";
import { TransactionTypes } from "../../../library/AtomicComponents/constants/string.constants";
import { FeatureFlagContext, FeatureFlagProviderType } from "../../../../contexts/FeatureFlagContext";
import { ExportContext } from "../../../../contexts/ExportContext";
import { ExportToastMessage } from "../../../../constants/ExportConstant";
import { UserAction } from "../../../library/AtomicComponents/Table/types/table.types";
import ActivityFeedMethods from "../../../Activities/ActivityDetail/ActivityFeedV2/helpers/ActivityFeedMethods";
import useWorkspaceConfigurations from "../../../../hooks/useWorkspaceConfigurations";
import { useQuery } from "@tanstack/react-query";
import { RefetchIntervals } from "../../../../constants/CacheConfig";
import useHandleVisibility from "../../../../hooks/useHandleVisibility";

interface ClosedInvoicesProps {
  configs: any;
}
/**
 * * Define the Invoices Tab of the Customer Detail's page
 */
export default function ClosedInvoices(props: ClosedInvoicesProps): React.ReactElement {
  const { path } = useRouteMatch();
  const history = useHistory();
  const { customerId } = useParams<{ customerId: string }>();
  const location = history.location.state as any;
  //eslint-disable-next-line
  const { signature, loginUserCompany } = React.useContext(CustomerContext) as CustomerType;
  const { invoiceData, downloadTransaction } = React.useContext(InvoiceContext) as InvoiceType;
  const params = new URLSearchParams(window.location.search);
  const closedInvoiceConfigs = props.configs.closed_invoices;
  const pageNoFromUrl = parseInt(params.get("page") ?? "1", NUMERIC_VALUES.CONSTANT_TEN) || NUMERIC_VALUES.CONSTANT_ONE;
  //eslint-disable-next-line
  const [isError, setError] = useState<boolean>(false);
  //eslint-disable-next-line
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [fromTime, setFromTime] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [toTime, setToTime] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);

  const [totalCount, setTotalCount] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  // New activity states
  const [newActivityType, setNewActivityType] = useState<string>("");
  const [showActivity, setShowActivity] = useState<boolean>(false);
  const { selectedWorkspace, pathType } = useWorkspaceConfigurations();
  const [supportedTemplateList, setSupportedTemplateList] = useState<TemplateDefinitionProps[]>([]);
  const { userStatus } = React.useContext(AppContext) as AppType;
  const { getContactsOptions, allContactOptions, filterPrimaryContacts } = useContacts();
  const [templateName, setTemplateName] = useState<string>("");
  const [selectedItem, setSelectedItem] = useState<any>();
  const [companyContactOptions, setCompanyContactOptions] = useState<To[]>([]);
  const { templateFactory, handleTemplateAttachment, prepareTemplateDataObject, templateData } = React.useContext(TemplateContext) as ITemplateProps;

  const [companyData, setCompanyData] = useState<CompanyModel>({} as CompanyModel);
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const { handleClose } = React.useContext(ActivityContext) as ActivityType;
  const [editorState, setEditorState] = useState<string>("");
  const {
    setSelectedActivityStreamId,
    setActivityStreamIds,
    setCurrentPaginationState,
    setPlatformPageFetchParams,
    setDocumentType,
    setEnableDocumentSwitch,
  } = React.useContext(DocumentSwitchContext) as DocumentSwitchType;
  const { getBaseRoute } = React.useContext(ApplicationRouteContext) as ApplicationRouteType;
  const { formatDateBasedOnCountry: formatDate, availableLanguagesForTemplates, calendarDateFormat } = useLocale();
  const initalPageNumber = pageNoFromUrl - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE;
  const { setTotalRecords, setTotalPages, updatePageNumber, ...paginationConfig } = usePagination(
    DEFAULT_PAGINATION_VALUES.PAGE_SIZE,
    initalPageNumber ?? DEFAULT_PAGINATION_VALUES.PAGE_COUNT
  );
  const { query: sortQuery, ...sortConfig } = useColumnSort("invoice_date DESC, invoice_number DESC");
  const selectionConfig = useRowSelection(paginationConfig?.page?.totalRecords);
  const { ...initialFilterConfigs } = useColumnFilters(true, [], updatePageNumber);
  const downloadErrorMessage = `PDF Download not supported for ${selectedWorkspace.workspace_type === "accounts_payable" ? "Bill" : "Invoice"} #${
    invoiceData?.referenceCode
  }`;
  const { setViewBasedFilters } = React.useContext(CustomerDetailsDashboardContext) as CustomerDetailsDashboardData;
  const [columnsConfigData, setColumnsConfigData] = useState<any[]>(() => closedInvoiceConfigs?.table?.columns);
  const [isColumnsConfigDataLoading, setIsColumnsConfigDataLoading] = useState<boolean>(true);
  const { isEnabled } = React.useContext(FeatureFlagContext) as FeatureFlagProviderType;
  const isFiltersEnabled = isEnabled("FILTERS_ENABLE");
  const isExportV2Enabled = isEnabled("EXPORTS_V2");
  const [isNoSearchResultFound, setIsNoSearchResultFound] = useState<boolean>(false);
  const { updateExportedRecordsIds } = React.useContext(ExportContext) as ExportType;
  const isAPWorkspace =
    selectedWorkspace.workspace_type === "accounting" ? path?.includes("vendor") : selectedWorkspace.workspace_type === "accounts_payable";
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const filteredContacts = [...companyContactOptions, ...allContactOptions];
  const primaryContact = filterPrimaryContacts(companyContactOptions);
  const filterObjFromUrl = JSON.parse(params.get("filters") ?? "{}");
  const appliedFilters = initialFilterConfigs.generateFilterQuery(Object.values(filterObjFromUrl), true);
  /**
   * setup common state with reducer to handle open/close states for send and send and close button
   * React useState hooks.
   */
  const initialVisibility = {
    isSendBtnDisabled: false,
  };
  const { visibility, dispatch } = useHandleVisibility<typeof initialVisibility>({ isSendBtnDisabled: false });

  //it return category view name for suggestion history
  const getViewCategory = () => {
    const categoryMap: { [key: string]: VIEW_CATEGORIES } = {
      "vendors/active/:customerId/closed_bills": VIEW_CATEGORIES.ACTIVE_VENDOR_CLOSED_BILLS,
      "vendors/archived/:customerId/closed_bills": VIEW_CATEGORIES.ARCHIVED_VENDOR_CLOSED_BILLS,
      "vendors/spam/:customerId/closed_bills": VIEW_CATEGORIES.SPAM_VENDOR_CLOSED_BILLS,
      "vendors/fraud/:customerId/closed_bills": VIEW_CATEGORIES.FRAUD_VENDOR_CLOSED_BILLS,
      "customers/active/:customerId/closed_invoices": VIEW_CATEGORIES.ACTIVE_CUSTOMER_CLOSED_INVOICES,
      "customers/archived/:customerId/closed_invoices": VIEW_CATEGORIES.ARCHIVED_CUSTOMER_CLOSED_INVOICES,
      "customers/spam/:customerId/closed_invoices": VIEW_CATEGORIES.SPAM_CUSTOMER_CLOSED_INVOICES,
      "customers/fraud/:customerId/closed_invoices": VIEW_CATEGORIES.FRAUD_CUSTOMER_CLOSED_INVOICES,
    };
    for (const pathKey in categoryMap) {
      if (path.includes(pathKey)) {
        return categoryMap[pathKey];
      }
    }

    return "";
  };

  const fetchCompanyInfo = (companyId: UUID) => {
    if (!companyId) {
      return;
    }
    companiesClient.getCompany(companyId, "Classification").then((companyModel: CompanyModel) => {
      setCompanyData(companyModel);
    });
  };

  const fetchCompanyContactOptions = async (customerId: string) => {
    await setCompanyContactOptions(await getContactsOptions(customerId));
  };

  React.useEffect(() => {
    fetchCompanyInfo(userStatus.account_company_id as string);
    return () => {
      setTotalCount(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    };
  }, []);

  React.useEffect(() => {
    // rest the selected template code
    setTemplateName("");
  }, [newActivityType]);

  const fetchParser = (fetchResult: InvoiceModelFetchResultV2, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.records?.map((record: InvoiceModelV2) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record.invoice_id,
          }),
          ...((variant === "export" || variant === "all") && {
            invoice_number: record.invoice_number,
            status: record.status,
            invoice_date: record.invoice_date,
            payment_due_date: record.payment_due_date,
            invoice_closed_date: record.invoice_closed_date,
            invoice_amount: record.invoice_amount,
            outstanding_amount: record.outstanding_balance,
            base_currency_invoice_amount: record.base_currency_invoice_amount,
            base_currency_outstanding_balance: record.base_currency_outstanding_balance,
            customer_name: record.customer_name,
            customer_id: record.customer_id,
            transaction_currency_code: record.currency_code,
            invoice_type_code: record.invoice_type_code,
          }),
          ...(variant === "all" && {
            disableCheckbox: undefined,
            isUnread: undefined,
          }),
        };
      }) ?? []
    );
  };

  const getFilters = (exportInvoice = false, ids?: string[]) => {
    const additionalFilters: Record<string, string>[] = [];
    const preDefinedFilters = [
      ...closedInvoiceConfigs.table.preDefinedFilters[location && location.from === "applied-discounts" ? "creditMemos" : "invoices"],
      {
        searchlightFilter: `qa[customer_id_eq]=${customerId}`,
      },
    ];

    preDefinedFilters.forEach((item) => additionalFilters.push(item));

    //If Credit Memo Table page then apply below filter
    if (location && location.from === "applied-discounts") {
      location.creditMemoIds.forEach((id: string) => {
        additionalFilters.push({
          searchlightFilter: `qa[invoice_id_in][]=${id}`,
        });
      });
    }

    // evaluate select filter on export
    if (exportInvoice && !selectionConfig?.isAllRecordsSelected && ids && ids.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      const { hasAllRecordsSelectedButtonClicked, isAllRecordsSelected, tableRecords } = selectionConfig;

      const exportIds =
        hasAllRecordsSelectedButtonClicked?.current && !isAllRecordsSelected
          ? tableRecords?.allRecords?.filter((item) => !item?.isSelected)?.map((deselectedItem) => deselectedItem?.id)
          : ids;

      exportIds.forEach((id) => {
        const searchlightFilter = `qa[invoice_id_${hasAllRecordsSelectedButtonClicked?.current && !isAllRecordsSelected ? "not_in" : "in"}][]=${id}`;
        additionalFilters.push({
          searchlightFilter,
        });
      });
    }
    const filterQuery = TableUtils.getFilterFromURL(params, closedInvoiceConfigs.table.columns);
    return TableUtils.columnFilterParser(filterQuery, true, additionalFilters ?? []);
  };

  const fetchInvoices = async (variant: FetchVariant, columnFilters?: string, pageNumber?: number, pageSize?: number) => {
    const sortQueryString = TableUtils.getSortQueryString(params, sortQuery);
    /**
     * Set page fetch params to ActivityStreamSwitchContext to facilitate page switching
     */
    const fetchParams = {
      pageSize: paginationConfig.page?.pageSize,
      page: paginationConfig.page?.pageNumber,
      order: TableUtils.sortQueryParser(sortQueryString ?? "", true),
      filter: getFilters(),
    };
    setPlatformPageFetchParams(fetchParams as PlatformPageFetchParams);

    const filters = [getFilters(), columnFilters].filter(Boolean).join("&");
    const isSpamOrFraud = path?.includes("spam") || path.includes("fraud");
    return invoicesClientV2
      .getInvoices(
        pageSize ?? paginationConfig.page?.pageSize,
        pageNumber ?? paginationConfig.page?.pageNumber,
        TableUtils.sortQueryParser(sortQueryString ?? "", true),
        filters,
        isSpamOrFraud
      )
      .then((fetchResult: any) => {
        return { ...fetchResult, records: fetchParser(fetchResult, variant) };
      })
      .catch((error: any) => {
        setError(true);
        setErrorMessage(APIClientUtils.buildErrorMessage(error));
      });
  };

  const queryKey = `connection-closed-invoice-index-${selectedWorkspace?.workspace_type_route}`;

  const { isLoading, data: cachedData } = useQuery(
    [queryKey, paginationConfig.page?.pageNumber, paginationConfig.page?.pageSize, TableUtils.getSortQueryString(params, sortQuery), appliedFilters],
    async () => await fetchInvoices("all", appliedFilters),
    {
      refetchOnMount: true,
      refetchInterval: RefetchIntervals.twoHoursDelay,
    }
  );

  useEffect(() => {
    /**
     * update the page number once user perform any action and because of that total records get's changed
     */
    const totalPages = Math.ceil(cachedData?.totalCount / cachedData?.pageSize) ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
    if ((paginationConfig.page?.pageNumber || NUMERIC_VALUES.CONSTANT_ONE) > totalPages) {
      updatePageNumber(totalPages || NUMERIC_VALUES.CONSTANT_ONE);
    }
    setTotalRecords(cachedData?.totalCount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    setTotalPages(totalPages);
    setTotalCount(cachedData?.totalCount);
    setTableData(cachedData?.records ?? []);
  }, [cachedData]);

  useEffect(() => {
    setViewBasedFilters(appliedFilters);
  }, [appliedFilters]);

  // Handles navigation to Invoices details page on row click
  function handleRowClick(e: MouseEvent<HTMLTableRowElement>, row: { id?: string }, currentPageRecords?: { id: string; isSelected?: boolean }[]) {
    /**
     * If a row item is already selected, then clicking anywhere on other rows should select those rows, and do not navigate.
     * if double clicked, then navigate to those row records
     */
    const clickType = e.detail;
    if (clickType === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE && selectionConfig.tableRecords?.selectedRecordsIds?.length && row.id) {
      selectionConfig?.onClickTableRow(row?.id, currentPageRecords ?? []);
      return;
    }

    /**
     * Set the following properties into DocumentSwitchContext
     * to facilitate document switching.
     * @param selectedActivityStreamId - currently selected document id
     * @param activityStreamIds - list of documents in the index
     * @param paginationState - current pagination state for reference
     */
    setEnableDocumentSwitch(true);
    setSelectedActivityStreamId({ id: row.id } as ActivityStreamId);
    setActivityStreamIds(tableData.map((item) => ({ id: item.id })));
    setCurrentPaginationState({
      page: paginationConfig.page?.pageNumber ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
      pageCount: paginationConfig.page?.totalPages ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
      pageSize: paginationConfig.page?.pageSize ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
      totalRecords: totalCount,
    });
    setDocumentType("related-invoice");
    sessionStorage.setItem("lastPath", history.location.pathname);
    history.push(`${getBaseRoute()}/${closedInvoiceConfigs.routeType}/${row.id ?? ""}`);
  }
  // 42656
  // check if template code is required as content-type
  const needTemplateCodeAsPayload = React.useMemo(
    () =>
      [
        TemplateTypes.AR_CREATE_CUSTOMER_PROFILE,
        TemplateTypes.AR_UPDATE_CUSTOMER_PROFILE,
        TemplateTypes.AP_CREATE_VENDOR_PROFILE,
        TemplateTypes.AP_CREATE_VENDOR_PROFILE,
      ].includes(templateName as TemplateTypes),
    [templateName]
  );
  const handleSend = async (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    attachmentIds: AttachmentIds,
    inboxAttachments = [] as TransactionItemType[],
    isSendAndClose?: boolean
  ): Promise<ActivityStream> => {
    dispatch({ type: "open", payload: "isSendBtnDisabled" });
    const getEmailArray = (emailArray: To[]) => emailArray.filter((item) => item !== undefined).map((value: To) => value.id || value.label);
    let toastOptions: ToastOptions = { open: true, severity: "success", message: ACTIVITY_SUCCESS_TOAST_MSG };
    const activityType =
      newActivityType && newActivityType === ActivityOptions.PHONE_CALL
        ? ActivityTypeEnum.PhoneCall
        : newActivityType === ActivityOptions.EMAIL
        ? ActivityOptions.EMAIL
        : newActivityType;
    const activityTransactions = _.uniq([...inboxAttachments, { transaction_id: selectedItem.id, transaction_type: "invoice" }], "transaction_id");
    const payload: Partial<ActivityItemEmailModel> = {
      to: getEmailArray(to),
      cc: getEmailArray(cc),
      bcc: getEmailArray(bcc),
      subject: title,
      content: body,
      email_action: EmailAction.NEW,
      workspace_id: selectedWorkspace?.id || FallbackTypes.Id,
      connection_company_id: customerId ?? "",
      content_type: ActivityFeedMethods.evaluateContentVariantPayload(newActivityType, needTemplateCodeAsPayload ? templateName : ""),
      attachments: attachmentIds.filter((item) => typeof item === "number"),
      activity_type: activityType,
      activity_transactions: activityTransactions,
      primary_connection_id: selectedItem.customer_id,
      attach_pdf: Utils.isThereAnyAttachment(attachmentIds, activityTransactions),
    };

    return new Promise((resolve) => {
      emailsClientV2
        .post(payload)
        .then(async (emailResponse: ActivityStream) => {
          //Only call Automation API when email is sent successfully and Template is selected
          if (templateName && emailResponse.success) {
            let reqBody = {
              to_time: toTime,
              automation_type: "email_templates",
              automation_sub_type: templateName,
              resource_type: "Activity::Email",
              resource_id: emailResponse.data.id,
            } as AutomateTimeModel;
            if (fromTime != DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
              reqBody = { ...{ from_time: fromTime }, ...reqBody };
            }
            setToTime(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
            automationClientV2.post(selectedWorkspace?.id || FallbackTypes.Id, reqBody);
          }

          TrackingUtils.trackFSData(
            to,
            "Ellipsis",
            "New",
            isSendAndClose ?? false,
            allContactOptions,
            closedInvoiceConfigs.routeType,
            path,
            newActivityType ?? "",
            body
          );
          setShowActivity(false);
          //Fetch latest contact (newly added) options to populate in activity pop up if user tries to reply on the same
          getContactsOptions();
          resolve(emailResponse ?? []);
        })
        .catch((err: AxiosError) => {
          toastOptions.severity = "error";
          if (err.response?.status === CONNECTION_STATUS.BAD_REQUEST_400.STATUS_CODE) {
            toastOptions = { ...toastOptions, severity: "error", message: EMAIL_CONNECTOR_ERROR };
          } else {
            const errorResponse = err.response as AxiosErrorResponseData;
            toastOptions = { ...toastOptions, message: errorResponse?.data?.messages?.errors[0] ?? MESSAGE_NOT_SENT_ERROR };
          }
        })
        .finally(() => {
          setToastOptions(toastOptions);
          dispatch({ type: "close", payload: "isSendBtnDisabled" });
        });
    });
  };

  const handleSendAndMarkClosed = async (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    attachmentIds: AttachmentIds,
    inboxAttachments: TransactionItemType[]
  ) => {
    const sendEmailResponse = await handleSend(to, cc, bcc, title, body, attachmentIds, inboxAttachments, true);
    if (sendEmailResponse.success) {
      let toastOptions: ToastOptions = { open: true, severity: "success", message: ACTIVITY_COMPLETE_SUCCESS };
      let response = {} as APIResponse;

      try {
        response = await handleClose(sendEmailResponse.data.activity_stream.id, "");
      } catch (e: unknown) {
        response.success = false;
      } finally {
        if (!response.success) {
          toastOptions = {
            ...toastOptions,
            severity: "error",
            message: ACTIVITY_COMPLETE_FAILURE,
          };
        }
        setToastOptions(toastOptions);
      }
    }
  };

  /**
   * Function which convert the template string to editor content state.
   *
   * @param {string} templateID The templateID selected by user from the subject drop down.
   * @param {EditorState} EditorState The setEditorState will update the email body.
   */
  const prepareActivityBodyByTemplateID = (
    templateID: string | null,
    setEditorState: React.Dispatch<React.SetStateAction<string>>,
    lang: string,
    setFileCallback?: FunctionCall
  ) => {
    if (templateID) {
      setTemplateName(templateID);

      const templateObj = templateData.get(selectedWorkspace?.id)?.get(lang)?.get(templateID);
      setFromTime(templateObj.getFromTime);
      setToTime(templateObj.getToTime);
      setEditorState(
        templateObj.parseTemplate({
          customer: selectedItem?.customer_name || "",
          vendor: selectedItem?.customer_name || "",
          invoiceNumber: selectedItem?.invoice_number || "",
          paymentDueDate: formatDate(selectedItem?.due_date_original) || "",
          invoiceAmount: Utils.formatValueAsCurrency(
            selectedItem.invoice_amount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            userStatus?.currency?.locale,
            selectedItem.transaction_currency_code
          ),
          outstandingBalance: Utils.formatValueAsCurrency(
            selectedItem.outstanding_amount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            userStatus?.currency?.locale,
            selectedItem.transaction_currency_code
          ),
          emailAddress: companyData?.emailAddress || "",
          companyName: companyData?.companyName || "",
          phone: companyData?.phoneNumber || "",
          invoiceDate: selectedItem.invoice_date_original || "",
          signature: signature?.email_signature || "",
          contactName: primaryContact[0]?.label || selectedItem?.customer_name,
        })
      );
      if (templateObj.templateAttachment) {
        const templateDataMapper = prepareTemplateDataObject(
          templateObj,
          [{ name: `Invoice #${selectedItem.invoice_number ?? "N/A"}`, id: selectedItem.id, typeCode: selectedItem.invoice_type_code }],
          null,
          null
        );
        handleTemplateAttachment(templateObj, setFileCallback, templateDataMapper);
      }
    }
  };

  /**
   * The ellipsis icons which will be used in lists.
   */
  const ellipsisIconMappings: Partial<RowSelectButtons> = {
    Download: <DownloadCloud />,
    Share: <Share />,
  };

  /**
   * Function which help's to give template list for the subject drop down
   * based on view type.
   *
   * @param {Object} row The selected row record from table
   * @returns {TemplateDefinitionProps[]} The template definition array of objects.
   */
  const getTemplateDefinitionsByView = (row: any): TemplateDefinitionProps[] => {
    const view: viewType =
      pathType === WorkspaceType.AP ? viewType.AP_CONNECTION_VENDOR_CLOSED_BILLS : viewType.AR_CONNECTION_CUSTOMER_CLOSE_INVOICES;
    return templateFactory.getTemplateDefinitionsByView(
      view,
      { invoiceNumber: row.invoice_number, customer: row.customer_name, companyName: loginUserCompany?.companyName ?? "", vendor: row.customer_name },
      availableLanguagesForTemplates
    );
  };

  /**
   * Download Credit Memo from Index Elipssis
   * Server sends File content (to recieve directly blob FE is sending {responseType: 'blob'} as a config in API)
   * If any error cones from server (because FE initially sends {responseType: 'blob'}) so in error-block response is converted back into JSON
   * And server error is shown
   */
  const onClickDownloadAction = async (row: any) => {
    const toastOptions: ToastOptions = { open: true, severity: "info", message: "Your download will start in a few seconds" };
    try {
      setToastOptions({
        ...toastOptions,
        icon: (
          <IconButton style={{ padding: "0" }}>
            <Download className="download-icon" />
          </IconButton>
        ),
      });
      const response = await downloadTransaction(row.id);
      const uri = URL.createObjectURL(response);
      const fileName = `#${invoiceData?.referenceCode}-${new Date().toDateString()}__${new Date().toLocaleTimeString()}`;
      TableUtils.downloadAll([
        {
          file_name: `CreditMemo-${fileName}`,
          file_url: uri,
        },
      ]);
      setToastOptions({ open: true, severity: "success", message: PDF_DOWNLOAD_SUCCESS });
    } catch (error: any) {
      /**
       * Download failures could be because of
       * 1. User has not enabled third party download services in their ERP system
       * 2. Network Failure
       * 3. Demo Data - strictly relavent to development teams
       */
      if (error.response) {
        const blob = new Blob([error.response.data], { type: "application/json" });
        const fileReader = new FileReader();
        fileReader.onload = (event: any) => {
          // Reason for strict checking is this error possibly will come on for demo connector rest with non-demo connector there will be more meaningful error messages
          const errorMessage =
            (JSON.parse(event.target.result).detail as string).toLocaleLowerCase() === SUPPORT_PDF_ERROR
              ? downloadErrorMessage
              : MESSAGE_NOT_SENT_ERROR;
          setToastOptions({ ...toastOptions, severity: "error", message: errorMessage });
        };
        fileReader.readAsText(blob);
      } else {
        setToastOptions({ ...toastOptions, severity: "error", message: "Something went wrong." });
      }
    }
  };

  /**
   * Event handler which triggered when user select the 'email', 'note' or 'phone call'
   * context menu item. It will open the new activity dialog based to the context menu item
   * selected by the user.
   *
   * @param {Object} row The selected row from the table.
   * @param {String} buttonId The buttonId indicate which button has been selected by the user.
   */
  const onClickActionButton = async (row: any, buttonId: string) => {
    if (buttonId === "email") {
      setSupportedTemplateList(getTemplateDefinitionsByView(row));
    }
    setSelectedItem(row);

    await fetchCompanyContactOptions(row.customer_id);
    setNewActivityType(buttonId);
    setShowActivity(true);
  };

  /**
   * The ellipsis action handlers which used to open the activity dialog.
   */
  const ellipsisActionHandlers: Partial<RowSelectButtons> = {
    "action.newEmail": onClickActionButton,
    "action.newNote": onClickActionButton,
    "action.newPhoneCall": onClickActionButton,
    "action.download": onClickDownloadAction,
  };

  const cellRendererMapping: CellRendererMappingObject = {
    "RowDataTypes.BoldText": RowDataTypes.BoldText,
    "RowDataTypes.DateEpoch": RowDataTypes.DateEpoch,
    "RowDataTypes.ActionsDropdown": RowDataTypes.ActionsDropdown,
    "RowDataTypes.Currency": RowDataTypes.Currency,
    "RowDataTypes.Status": RowDataTypes.Status,
    "RowDataTypes.BoldTextWithCaption": RowDataTypes.BoldTextWithCaption,
  };

  //We return Ellipsis actions based on the type of invoice we have on each row
  const getEllipsisActions = (invoiceTypeCode: string) => {
    const invoiceType = invoiceTypeCode.includes("Credit Memo") ? "creditMemos" : "invoices";
    return closedInvoiceConfigs.table.ellipsisActions[invoiceType].map((action: EllipsisAction) => {
      return { ...action, clickFunction: ellipsisActionHandlers[action.handler], icon: ellipsisIconMappings[action.icon ?? ""] };
    });
  };

  const getPreviousSuggestedData = (recentSearchHistory: FilterDropdownSuggestionRef) => {
    const { filterSearchedText = {} } = initialFilterConfigs ?? {};
    const { invoice_number } = filterSearchedText;

    return {
      invoiceNumber:
        invoice_number?.value?.length >= NUMERIC_VALUES.CONSTANT_THREE
          ? recentSearchHistory?.invoice_number?.filter((item: DropdownItemList) =>
              initialFilterConfigs?.activeFilters?.invoice_number?.ids?.includes(item.id)
            )
          : recentSearchHistory?.invoice_number,
    };
  };

  //it will return maximum of 5 required formated record for dropdown search suggestion
  const getDropdownSearchFilterData = (invoiceTableData: DropdownSuggestionListData[]) => {
    const requiredColumns = [...(closedInvoiceConfigs?.table?.columns ?? [])];
    const { filterSearchedText = {}, getSearchSuggestionHistory } = initialFilterConfigs || {};
    const { invoice_number } = filterSearchedText;
    const recentClosedInvoiceSearchedData = getSearchSuggestionHistory(
      selectedWorkspace.workspace_type_route?.toLocaleLowerCase() ?? WorkspaceType.AR,
      getViewCategory()
    );
    const previousData = getPreviousSuggestedData(recentClosedInvoiceSearchedData);

    /**
     * if user searching for result then return result data along with previous selected data
     * else return max of 5 recent searched data if that is exist
     */
    const invoiceNumberData: DropdownSuggestionListData[] =
      !invoice_number?.value || invoice_number?.value.length < NUMERIC_VALUES.CONSTANT_THREE
        ? (previousData?.invoiceNumber ?? []).slice(NUMERIC_VALUES.CONSTANT_ZERO, NUMERIC_VALUES.CONSTANT_FIVE)
        : [...(previousData?.invoiceNumber ?? []), ...(invoiceTableData ?? [])];

    const invoiceNumberDropdownList = invoiceNumberData
      .filter(({ invoice_number, primaryText }) => invoice_number || primaryText)
      .map(({ id, invoice_number, primaryText, invoice_type_code, secondaryText }) => ({
        id,
        primaryText: invoice_number ?? primaryText,
        isAttachemntRequired: false,
        selected: initialFilterConfigs?.activeFilters?.invoice_number?.ids?.includes(id) ?? false,
        secondaryText: TransactionTypes[invoice_type_code?.toLowerCase()] ?? secondaryText,
        isAvatarRequired: false,
      }));

    /*
     * This code first creates a Map object by mapping each object in the original array to an array with two elements:
     * the key and the value. The Map constructor can then be called with the resulting array of key-value pairs to create
     * a new Map object. Finally, the Array.from method is used to convert the Map object back to an array, where the map
     * function is used to only return the values from the key-value pairs.
     */
    const reverseData = [...invoiceNumberDropdownList].reverse();
    const uniqueInvoiceNumberSuggestions = Array.from(new Map(reverseData.map((obj) => [obj.primaryText, obj])))
      .reverse()
      .map(([, value]) => value);

    return requiredColumns.map((column) => {
      if (column.accessor === "invoice_number") {
        return { ...column, dropdownSearchListItems: uniqueInvoiceNumberSuggestions };
      }
      return column;
    });
  };

  useEffect(() => {
    const { filterSearchedText = {} } = initialFilterConfigs || {};
    const { invoice_number = { value: "" } } = filterSearchedText;

    let invoiceQuery = "";

    if (invoice_number.value.length >= NUMERIC_VALUES.CONSTANT_THREE) {
      invoiceQuery = invoice_number ? `INVOICENUMBER CONTAINS '${invoice_number?.value}'` : "";
    }

    setIsColumnsConfigDataLoading(true);

    const fetchData = async () => {
      try {
        let res;
        if (invoiceQuery.length > NUMERIC_VALUES.CONSTANT_ZERO) {
          res = await fetchInvoices("all", invoiceQuery, NUMERIC_VALUES.CONSTANT_ZERO, NUMERIC_VALUES.CONSTANT_TWENTY);
        } else {
          res = { totalCount: NUMERIC_VALUES.CONSTANT_ZERO, records: [] };
        }

        setIsNoSearchResultFound(!res.totalCount);
        setColumnsConfigData(getDropdownSearchFilterData(res.records));
      } catch (error) {
        setIsNoSearchResultFound(true);
        console.log(error);
      } finally {
        setIsColumnsConfigDataLoading(false);
      }
    };

    fetchData()?.catch(() => {
      setIsNoSearchResultFound(true);
    });
  }, [initialFilterConfigs?.filterSearchedText?.invoice_number?.value, initialFilterConfigs?.filterQuery]);

  const handleExportV2 = async (ids: string[]) => {
    const filters = [getFilters(true, ids), appliedFilters].filter(Boolean).join("&");
    let response: any = {};
    try {
      setExportLoading(true);
      const isExportAllRecords =
        (selectionConfig?.tableRecords?.selectedRecordsIds?.length ?? NUMERIC_VALUES.CONSTANT_ZERO) > NUMERIC_VALUES.CONSTANT_ZERO
          ? selectionConfig.isAllRecordsSelected || !!selectionConfig?.hasAllRecordsSelectedButtonClicked?.current
          : true;
      const export_type = isAPWorkspace ? EXPORT_CATEGORIES.AP_INVOICES : EXPORT_CATEGORIES.AR_INVOICES;
      const sortQueryString = TableUtils.getSortQueryString(params, sortQuery);

      response = await exportClient.exportRecords(export_type, isExportAllRecords, filters, TableUtils.sortQueryParser(sortQueryString ?? "", true));
      if (response.success) {
        setToastOptions({ open: true, severity: "success", message: ExportToastMessage.EXPORT_IN_PROGRESS });
        updateExportedRecordsIds([response.data?.export_request_id], false);
      }
    } catch (error) {
      setToastOptions({ open: true, severity: "error", message: ExportToastMessage.EXPORT_FAILED });
    } finally {
      setExportLoading(false);
    }
  };

  const rowSelectBtns: UserAction[] = [
    {
      children: "Export",
      icon: <ExportV2 />,
      loading: exportLoading,
      callback: (ids: string[]) => {
        handleExportV2(ids);
      },
      visible: {
        onEllipsis: false,
        onHeader: false,
        onHover: false,
        onCaption: true,
      },
    } as UserAction,
  ];

  //return {key:value} pair for template subject to parse the values
  const getSelectedTempSubjSnippetValues = () => {
    return {
      invoiceNumber: selectedItem?.invoice_number ?? "",
      customer: selectedItem?.customer_name ?? "",
      companyName: loginUserCompany?.companyName ?? "",
      vendor: selectedItem?.customer_name ?? "",
    };
  };

  //return suggested template codes based on certain condition
  const getSuggestedTemplatesCode = () => {
    const arTemplates = getViewTypeTemplateCodes(viewType.AR_CONNECTION_CUSTOMER_CLOSE_INVOICES);
    const apTemplates = getViewTypeTemplateCodes(viewType.AP_CONNECTION_VENDOR_CLOSED_BILLS);

    if (isAPWorkspace) {
      return apTemplates;
    } else {
      return arTemplates;
    }
  };

  //using caching data to update the footer at the same time
  const updatedPaginationObject = {
    ...paginationConfig,
    ...TableUtils.getUpdatePaginationPageState(paginationConfig, cachedData?.totalCount, cachedData?.pageSize),
  };

  return (
    <>
      <Table
        configs={{
          //Added the feature flag for the newly added filters excpet which are already there on Production
          columnConfigs: columnsConfigData?.map((item) => {
            if (
              [
                "status",
                "invoice_date",
                "payment_due_date",
                "invoice_closed_date",
                "base_currency_invoice_amount",
                "base_currency_outstanding_balance",
                "action",
              ].includes(item.accessor)
            ) {
              return { ...item, rowDataType: cellRendererMapping[item?.rowDataType] };
            } else {
              return { ...item, rowDataType: cellRendererMapping[item?.rowDataType], enableFilter: isFiltersEnabled };
            }
          }),
          initialTableConfigs: {
            name: path.includes("applied_credit_memos") ? "Credit Memos" : path.includes("closed_bills") ? "Bills" : "Invoices",
            type: path.includes("applied_credit_memos") ? "Credit Memo" : path.includes("closed_bills") ? "Bill" : "Invoice",
            footerTableTypeText: path.includes("applied_credit_memos") ? "Credit Memo" : path.includes("closed_bills") ? "Bill" : "Invoice",
            defaultSortKey: "paymentDueDate DESC, invoiceNumber DESC",
            loading: isLoading,
          },
          paginationConfigs: { ...updatedPaginationObject, enableNavigator: true, enableSummary: true },
          initialSortConfigs: sortConfig,
          selectionConfig: selectionConfig,
          initialFilterConfigs: {
            ...initialFilterConfigs,
            dateFormat: calendarDateFormat,
            isSuggestionDataLoading: isColumnsConfigDataLoading,
            category: getViewCategory(),
            workspace: selectedWorkspace.workspace_type_route?.toLocaleLowerCase() ?? WorkspaceType.AR,
            isNoSearchResultFound,
          },
          currencyConfig: {
            locale: userStatus?.currency?.locale,
            code: userStatus?.currency?.code,
            symbol: userStatus?.currency?.symbol,
          },
        }}
        rows={(cachedData?.records ?? [])?.map((rec: any) => {
          return {
            ...rec,
            read: true,
            invoice_date: new Date(rec.invoice_date).getTime() / DEFAULT_NUMERIC_VALUES.DEFAULT_THOUSAND,
            payment_due_date: new Date(rec.payment_due_date).getTime() / DEFAULT_NUMERIC_VALUES.DEFAULT_THOUSAND,
            invoice_closed_date: new Date(rec.invoice_closed_date).getTime() / DEFAULT_NUMERIC_VALUES.DEFAULT_THOUSAND,
            due_date_original: rec.payment_due_date,
            invoice_date_original: rec.invoice_date,
            closed_date_original: rec.invoice_closed_date,
            row_actions: getEllipsisActions(rec.invoice_type_code),
          };
        })}
        handlers={{
          onRowClick: handleRowClick,
        }}
        emptyMessage={{
          title: EMPTY_ACTIONS_MESSAGE,
          caption: `You have no ${closedInvoiceConfigs.resourceName.toLocaleLowerCase()}. ${GREAT_JOB}`,
        }}
        userActions={isExportV2Enabled ? [...rowSelectBtns] : []}
        hoverBar={false}
        visibility={{
          exportBtn: !isExportV2Enabled,
          hoverbar: false,
        }}
      />
      {showActivity && (
        <NewActivityPopup
          key={`key-add-activity-${newActivityType}`}
          title={BTN_NEW_ACTIVITY}
          open={showActivity}
          contactOptions={filteredContacts}
          defaultTo={_.isEmpty(primaryContact) ? [companyContactOptions[0]] : primaryContact}
          handleSend={handleSend}
          isTemplateSupport={newActivityType === "email"}
          supportedTemplateList={supportedTemplateList}
          editorState={editorState}
          handleSendMarkClosed={handleSendAndMarkClosed}
          onClose={() => {
            setShowActivity(false);
            setEditorState("");
          }}
          activityType={newActivityType}
          setNewActivityType={(type) => {
            setNewActivityType(type);
          }}
          addActivityDropdown={[
            { activityType: "Email", displayName: "Email", icon: <Email /> },
            { activityType: "Note", displayName: "Note", icon: <NoteFill /> },
            { activityType: "Phone Call", displayName: "Phone Call", icon: <Phone /> },
          ]}
          prepareActivityBodyByTemplateID={prepareActivityBodyByTemplateID}
          fromTime={fromTime}
          toTime={toTime}
          setToTime={setToTime}
          enableKeyboardShortcuts={true}
          isSendButtonDisabled={visibility.isSendBtnDisabled}
          suggestedTemplatesCode={getSuggestedTemplatesCode()}
          tempSubjectSnippetValues={getSelectedTempSubjSnippetValues()}
        />
      )}
    </>
  );
}

import React from "react";
import { useRouteMatch } from "react-router";
import { DEFAULT_NUMERIC_VALUES } from "../../../constants/NumericConstants";
import useWorkspaceConfigurations from "../../../hooks/useWorkspaceConfigurations";
import { VIEW_CATEGORIES, WorkspaceType } from "../../../types/enums";
import ActivitiesTable from "../ActivitiesTable/ActivitiesTable";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import useProcessTableConfigs from "../../../hooks/useProcessTableConfigs";
import activityTableConfigs, { ActivityTableViews } from "../ActivitiesTable/activityTable.configs";

export default function AllActivities(): React.ReactElement {
  const { url } = useRouteMatch();
  const currentView = url.slice(url.lastIndexOf("/") + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);

  const { selectedWorkspace } = useWorkspaceConfigurations();

  const getCategoryName = () => {
    if (currentView === "snoozed") {
      return VIEW_CATEGORIES.SNOOZED_ACTIVITES;
    } else if (currentView === "closed") {
      return VIEW_CATEGORIES.COMPLETED_ACTIVITES;
    } else if (currentView === "waitingForResponse") {
      return VIEW_CATEGORIES.AWAITING_ACTIVITES;
    }
    return VIEW_CATEGORIES.ALL_ACTIVITES;
  };

  const currentWorkspaceType = (selectedWorkspace.workspace_type_route ?? WorkspaceType.AR) as WorkspaceType;
  const getCurrentView = () => {
    if (currentView === "snoozed") {
      return ActivityTableViews.SnoozedActivitiesTable;
    } else if (currentView === "closed") {
      return ActivityTableViews.CompletedActivitiesTable;
    } else if (currentView === "waitingForResponse") {
      return ActivityTableViews.AwaitingActivitiesTable;
    }
    return ActivityTableViews.AwaitingActivitiesTable;
  };

  const { /* tableConfigs, */ columns: columnsConfigs } = activityTableConfigs();
  const columns = useProcessTableConfigs(columnsConfigs, currentWorkspaceType, getCurrentView());

  return (
    <div className={`table-activity-wrapper activity-stream-v2`}>
      <OverlayScrollbarsComponent
        options={{
          paddingAbsolute: true,
          autoUpdate: true,
          overflowBehavior: { x: "hidden" },
          className: "overlay-scrollbar-width-reset",
        }}
      >
        <div className="body-table">
          <ActivitiesTable columns={columns} assigneeType={"all"} route={"all"} category={getCategoryName()} />
        </div>
      </OverlayScrollbarsComponent>
    </div>
  );
}

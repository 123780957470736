import React from "react";
import _ from "underscore";
import { enrollmentsClient } from "../../db/accessor";
import { ERP_APP_TYPE } from "../../types/enums";
import { CONNECTION_STATUS } from "../../constants/ConnectionConstants";

/**
 * @function usePdfDownloadValidation
 * reusable handler for validating transaction pdf downloads against the supported
 * ERP and Transaction Types by Lockstep Platform.
 *
 * Implementation Guideline:
 * Developer needs to pass the platform transaction typecode while initializing the
 * hook.
 *
 * @param transactionTypeCode
 * @returns enableDownload: a flag to identify whether download is supported
 * downloadValidation: if download is not supported, returns a validation message
 */
const usePdfDownloadValidation = (transactionTypeCode = "") => {
  // get erp application meta data
  const erpRecordsStr = sessionStorage.getItem("erp-records");
  const erpRecordCache = erpRecordsStr && JSON.parse(sessionStorage.getItem("erp-records") ?? "");

  const [userAppRecords, setUserAppRecords] = React.useState<AppEnrollmentModel[] | undefined>(erpRecordCache);

  /**
   * fetch user account applications
   */
  const fetchUserApps = async () => {
    let userAppRecords;
    try {
      const data = await enrollmentsClient.getEnrollments("(isActive eq 'true')");
      userAppRecords = data?.records?.filter((record: AppEnrollmentModel) =>
        [ERP_APP_TYPE.ERP_CONNECTOR, ERP_APP_TYPE.ON_PREM_ERP_CONNECTOR].includes(record.app.appType as ERP_APP_TYPE)
      );
      setUserAppRecords(userAppRecords ?? []);
      sessionStorage.setItem("erp-records", JSON.stringify(userAppRecords));
    } catch (e) {
      setUserAppRecords([]);
    }
    return userAppRecords;
  };

  const [inputData, setInputData] = React.useState<{ transactionTypeCode: string }>({
    transactionTypeCode,
  });
  const [downloadSupported, setIsDownloadSupported] = React.useState<boolean>(false);
  const [validationMessage, setValidationMessage] = React.useState<string>("");

  const app = React.useMemo(() => userAppRecords?.[0]?.app, [userAppRecords]);

  /**
   * Object stores Supported ERP: Downloadable Transaction Formats mapping to
   * apply validations.
   *
   * Supported Configurations:
   * Sage Intacct supports AR Invoices.
   * Quickbooks Online supports AR Invoices, AR Credit Memos, and AR Payments.
   * Xero supports AR Invoices, AP Invoices, AR Credit Memos, and AP Credit Memos.
   *
   * refer: https://api.dev.lockstep.io/swagger/index.html#/Invoices/v1_Invoices_RetrieveInvoicePdf
   * refer: https://api.dev.lockstep.io/swagger/index.html#/Payments/v1_Payments_RetrievePaymentPdf
   */
  const supportedConfigs = React.useMemo(
    () =>
      Object.freeze({
        "Sage Intacct": ["AR Invoice"],
        "QuickBooks Online": ["AR Invoice", "AR Credit Memo", "AR Payment"],
        Xero: ["AR Invoice", "AP Invoice", "AR Credit Memo", "AP Credit Memo"],
        "Sage 50": ["AR Invoice", "AR Credit Memo"],
      }),
    []
  );

  /** initialiser for input data, if hook was loaded without input */
  const initialiseData = (data: { transactionTypeCode: string }) => setInputData(data);

  /**
   * pure function to determine the validation message based on input parameteres
   *
   * @param isNetworkFailure boolean
   * @param isAppTypeSupported boolean
   * @param isTypeCodeSupported boolean
   * @param appName ERP System Name
   * @param typeCode Attachment Type Code
   * @returns validation message
   */
  const obtainValidationMessage = React.useCallback(
    (isNetworkFailure: boolean, isAppTypeSupported: boolean, isTypeCodeSupported: boolean, appName: string | undefined, typeCode: string) => {
      if (isNetworkFailure) {
        return "PDF download is not available at the moment";
      } else if (!isAppTypeSupported) {
        return `PDF download is not supported for ${appName !== "" ? appName : "connected ERP system"}`;
      } else if (!isTypeCodeSupported) {
        return `PDF download is not supported for ${typeCode !== "" ? typeCode : "this transaction type"}`;
      } else {
        return "";
      }
    },
    []
  );

  /**
   * pure function, returns various parameters for further validation
   * @param typeCode Attachment Type Code
   * @returns {
   *  isNetworkFailure, isAppTypeSupported, isTypeCodeSupported
   * }
   */
  const determineDownloadSupport = React.useCallback((typeCode: string, app: AppModel | undefined) => {
    let isNetworkFailure = false;
    let isAppTypeSupported = false;
    let isTypeCodeSupported = false;

    isNetworkFailure = !app;
    // if there is no network failue
    if (!isNetworkFailure) {
      isAppTypeSupported = Object.keys(supportedConfigs).includes(app?.name ?? "");
      isTypeCodeSupported = supportedConfigs[app?.name as keyof typeof supportedConfigs]?.includes(typeCode ?? "");
    }

    return {
      isNetworkFailure,
      isAppTypeSupported,
      isTypeCodeSupported,
    };
  }, []);

  /**
   * getTooltipMessage function to get the appropraite tooltip message as per status code
   * @param statusCode number
   * @returns the tooltip message as a string
   */
  const getPDFDwnldToastMessage = (statusCode: number, transactionType?: string) => {
    const transactionCode = _.isEmpty(transactionTypeCode) ? transactionType : transactionTypeCode;
    switch (statusCode) {
      case CONNECTION_STATUS.OK_200.STATUS_CODE:
        return "";
      case CONNECTION_STATUS.ACCEPTED_202.STATUS_CODE:
        return `The printed PDF for this ${transactionCode} is being scanned for viruses. It will be available for download shortly.`;
      case CONNECTION_STATUS.BAD_REQUEST_400.STATUS_CODE:
        return `This action is not supported.`;
      case CONNECTION_STATUS.NOT_FOUND_404.STATUS_CODE:
        return `The printed PDF for this ${transactionCode} is not available. Please download it directly from your accounting system.`;
      case CONNECTION_STATUS.LINK_EXPIRED_422.STATUS_CODE:
        return `The printed PDF for this ${transactionCode} is not available. Please download it directly from your accounting system.`;
      default:
        return `The printed PDF for this ${transactionCode} is not available.`;
    }
  };

  /**
   * orchestrator for validation process
   * 1. determines the download support
   * 2. obtains the validation message based on download support
   * 3. set appropriate states
   */
  const validate = React.useCallback(() => {
    const { isNetworkFailure, isAppTypeSupported, isTypeCodeSupported } = determineDownloadSupport(inputData.transactionTypeCode, app);
    setIsDownloadSupported(isTypeCodeSupported);
    const message = obtainValidationMessage(isNetworkFailure, isAppTypeSupported, isTypeCodeSupported, app?.name, inputData.transactionTypeCode);
    setValidationMessage(message);
  }, [inputData.transactionTypeCode, app?.name]);

  /**
   * auto validation if erp app records or input transaction type code or both have changed.
   *
   * upon changing the user app records OR input transaction type code, code
   * will re-evaluate the download validation
   */
  React.useEffect(() => {
    validate();
    return () => sessionStorage.removeItem("erp-records");
  }, [JSON.stringify(userAppRecords), inputData.transactionTypeCode]);

  /**
   * fetch user accounting application records -
   *
   * initial value of userAppRecords is undefined, if it is set to an array,
   * that indicates, api call has already completed once and we did not receive
   * any data for apps enrolled in current user group.
   */
  React.useEffect(() => {
    !_.isArray(userAppRecords) && _.isEmpty(userAppRecords) && (async () => await fetchUserApps())();
  }, []);

  return {
    downloadSupported,
    validationMessage,
    initialiseData,
    determineDownloadSupport,
    obtainValidationMessage,
    fetchUserApps,
    app,
    getPDFDwnldToastMessage,
  };
};

export default usePdfDownloadValidation;

import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../contexts/AppContext";
import { CustomerContext } from "../../../contexts/CustomerContext";
import { ProfileContext } from "../../../contexts/ProfileContext";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";
import { userAccountsClient } from "./../../../db/accessor";
import CryptoJS from "crypto-js";
import Utils from "../../../utils/utils";
import Tracking from "../../Tracking/Tracking";
import { useIntercom } from "react-use-intercom";
import "./MegaMenu.scss";

import CompanyHeader from "./CompanyHeader/CompanyHeader";
import WorkspaceSwitcher from "./WorkspaceSwitcher/WorkspaceSwitcher";
import MenuAccordion from "./MenuAccordion/MenuAccordion";
import _ from "underscore";
import { ReactComponent as SageNetworkLogoSmall } from "../../../assets/Sage_Network_Logo_Small.svg";
import { SettingsContext } from "../../../contexts/SettingsContext";

type UserId = {
  id: string;
  username: string;
  custom: {
    groupKey: string;
    accountName: string;
    companyId: string;
    userRole: string;
  };
};

export default function MegaMenu(): React.ReactElement {
  const { setUserGroupChanged, handleWorkspaceSwitch } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { initialLoad, updateUserStatus, userStatus, setSelectedUserGroup, setCompanySwitcher } = React.useContext(AppContext) as AppType;
  const { imageUrl, setImageUrl, userName, setUserName } = React.useContext(ProfileContext) as ProfileType;
  const { initialCopilotSettings } = React.useContext(SettingsContext) as SettingsType;
  const { getCompany } = React.useContext(CustomerContext) as CustomerType;
  const [userInfo, setUserInfo] = useState<UserId>({
    id: "",
    username: "unknown",
    custom: {
      groupKey: "",
      accountName: "",
      companyId: "",
      userRole: "",
    },
  });

  const history = useHistory();
  const { instance } = useMsal();
  const { boot, update } = useIntercom();

  /**
   * @function setupUserProfileData
   * A helper function to fetch status information
   */
  const setupUserProfileData = (userStatus: StatusModelV2) => {
    // wait until the initial status load is complete
    if (!initialLoad) {
      if (userStatus.logged_in) {
        setUserInfo({
          id: userStatus.user_id ? userStatus.user_id : "",
          username: userStatus.user_name ? userStatus.user_name : "",
          custom: {
            groupKey: userStatus.group_key ? userStatus.group_key : "",
            accountName: userStatus.account_name ? userStatus.account_name : "",
            companyId: userStatus.account_company_id ? userStatus.account_company_id : "",
            userRole: userStatus.roles ? userStatus.roles[0] : "",
          },
        });
        boot({
          userId: userStatus?.user_id ?? "",
          name: userStatus?.user_name ?? "Guest User",
          email: instance.getActiveAccount()?.username ?? "",
          userHash: CryptoJS.enc.Hex.stringify(CryptoJS.HmacSHA256(userStatus?.user_id ?? "", Utils.getIntercomIdSecret())),
        });

        update({ hideDefaultLauncher: false });
        // TODO Resequence: Get the image url & username in the /status call.. remove this additional
        userAccountsClient.getUserAccount(userStatus?.user_id ?? "", "AccountingRole").then((userStatus: UserAccountModel) => {
          setUserName(userStatus?.userName ?? userName);
          setImageUrl(userStatus?.imageURL ?? imageUrl);
        });
        getCompany(userStatus.account_company_id ?? "");
      } else {
        // The user has an Azure B2C account but is not recognized as being set up in Insights, so we need to send them through onboarding
        //needs to be tested and updated
        history.push(`${process.env.REACT_APP_REDIRECT_URL || ""}/account/newAccount`);
      }
    }
  };

  /**
   * @function handleUserGroupChange
   * A function to handle user group change event.
   * Once a change is registered on platform, it will refetch the
   * status information as per the new user group. It calls a method
   * to switch the active user workspace.
   * @param userGroupItem: selected group for switch
   */
  const handleUserGroupChange = async (userGroupItem: UserGroupModel) => {
    setCompanySwitcher(true);
    setSelectedUserGroup(userGroupItem);
    setUserGroupChanged(true);
    userAccountsClient.changeGroup(userGroupItem?.group_key).then((res: UserGroupModelV1) => {
      // group key is set to localstorage and will be passed with every call to server
      window.localStorage.setItem("groupKey", res?.groupKey);
      // get the status object for new group
      updateUserStatus()
        .then((status) => {
          // get default workspace for new group
          if (status) {
            const defaultWorkspace = status.workspaces.filter((workspace) => workspace.default)[0];
            // switch to new workspace
            handleWorkspaceSwitch(defaultWorkspace);
            setCompanySwitcher(false);
          }
        })
        .catch((error) => console.error(error));
    });
  };

  /**
   * fetch user status information when app loads
   */
  useEffect(() => {
    if (!_.isUndefined(userStatus) || !_.isEmpty(userStatus)) {
      setupUserProfileData(userStatus);
    }
    initialCopilotSettings();
  }, [JSON.stringify(userStatus)]);

  return (
    <div className="megamenu-wrapper">
      <Tracking identity={userInfo} profileData={userStatus as unknown as UserAccountModelV2} />
      <CompanyHeader
        companyName={userStatus.account_name || "Pending..."}
        groupKey={userStatus.group_key}
        userGroups={userStatus.user_groups}
        handleUserGroupChange={handleUserGroupChange}
      />
      <WorkspaceSwitcher />
      <MenuAccordion />
      <div className="connected-footer">
        <div className="connected-footer-img">
          <SageNetworkLogoSmall />
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { styled } from "@mui/system";
import { MultiInputDateRangeField } from "@mui/x-date-pickers-pro";
import { CustomeDateRangeFieldProps } from "./input.daterange.field.types";
import styles from "../../../../../css/_export.module.scss";

const CustomDateRangeField: React.FC<CustomeDateRangeFieldProps> = ({ className, ...props }) => (
  <MultiInputDateRangeField {...props} className={className} />
);
const MuiMultiInputDateRangeField = styled(CustomDateRangeField, { name: "MuiMultiInputDateRangeField" })({
  height: "2.3125rem",
  width: "24.0625rem",
  gap: styles.medium,
  "& .MuiTextField-root": {
    width: "10.8125rem",
    background: styles.grey98,
    borderRadius: "0.25rem 0.25rem 0 0",
    margin: 0,
    "& .MuiOutlinedInput-root": {
      fontFamily: styles.primaryFontFamily,
      fontWeight: 400,
      fontSize: styles.fontSizeSM,
      lineHeight: styles.lineHeightMD,
      color: styles.grey10,
      "& .MuiOutlinedInput-input": {
        padding: `${styles.small} ${styles.base}`,
        borderBottom: `${styles.borderline} solid ${styles.grey10}`,
        "&:focus": {
          borderBottom: `${styles.borderline} solid ${styles.link}`,
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
  },
  ">:not(style)+:not(style)": {
    marginLeft: "0",
  },
});
export default MuiMultiInputDateRangeField;

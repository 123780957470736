import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Utils from "../utils/utils";
import PhraseInContextEditorPostProcessor from "i18next-phrase-in-context-editor-post-processor";

// enables debugger on development environments
const isDevEnvironment = Utils.isLocal();

type en = {
  components: { [key: string]: string };
  home: { [key: string]: string };
  activities: { [key: string]: string };
  table: { [key: string]: string };
  einvoices: { [key: string]: string };
  global_search: { [key: string]: string };
};

// initialised with previously loaded locale
const localeCache = localStorage.getItem("locale") ?? "";

/**
 * Helper documentation:
 * load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
 * learn more: https://github.com/i18next/i18next-http-backend
 * want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
 */
i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // load translation resource files with webpack
  .use({
    type: "backend",
    read<Namespace extends keyof en>(
      language: UserLocale,
      namespace: Namespace,
      callback: (errorValue: unknown, translations: null | en[Namespace]) => void
    ) {
      import(`./../../public/locales/${language}/translations/${namespace}.json`)
        .then((resources) => {
          callback(null, resources);
        })
        .catch((error) => {
          callback(error, null);
        });
    },
  })
  //Phrase strings In-Context editor automation workflow
  .use(
    new PhraseInContextEditorPostProcessor({
      phraseEnabled: process.env.REACT_APP_PHRASE_ICE_ENABLED === "true" || false,
      projectId: process.env.REACT_APP_PHRASE_PROJECT_ID || "",
      accountId: process.env.REACT_APP_PHRASE_ACCOUNT_ID || "",
      useOldICE: false,
    })
  )
  // initialise i18next configs
  .init({
    debug: isDevEnvironment,
    // namespaces - common and inbox modules
    ns: ["components", "home", "activities", "table", "einvoices", "global_search"],
    defaultNS: "home",
    // supported languages
    supportedLngs: ["en-US", "en-GB", "fr-CA", "fr-FR"],
    // active language
    lng: localeCache,
    // fallback language
    fallbackLng: "en-US",
    load: "currentOnly",
    // interpolation: dynamic string settings
    interpolation: {
      escapeValue: false,
    },
    // Configuration for initReactI18next
    react: {
      bindI18n: "languageChanged loaded", // Bind I18n on these events
    },
    postProcess: ["phraseInContextEditor"],
  });

export default i18n;

export const BUCKET_CONSTANTS = Object.freeze({
  OPEN: -180,
  CLOSED: -1,
  FIRST_PAST_DUE_BUCKET: 1,
  SECOND_PAST_DUE_BUCKET: 30,
  THIRD_PAST_DUE_BUCKET: 60,
  FOURTH_PAST_DUE_BUCKET: 90,
});

export const CURRENCY_CONSTANTS = Object.freeze({
  CURRENCY_DECIMAL_PRECISION: 4,
  CURRENCY_IN_KILOS: 1000,
  CURRENCY_IN_MILLION: 1000000,
  CURRENCY_IN_BILLION: 1000000000,
  CURRENCY_IN_TRILLION: 1000000000000,
  TRAILING_ZEROS_IN_KILOS: 3,
  TRAILING_ZEROS_IN_MILLION: 6,
  TRAILING_ZEROS_IN_BILLION: 9,
  TRAILING_ZEROS_IN_TRILLION: 12,
});

export const DAYS_CONSTANTS = Object.freeze({
  TODAY: 0,
  DAY: 1,
  MONTH: 30,
  YEAR: 365,
});

export const DATE_CONSTANTS_IN_MIN = Object.freeze({
  MINS: 1,
  HOURS: 60,
  DAY: 1440, // 60 * 24
  DAY_IN_MS: 86400000, // 1000 * 60 * 60 * 24
  WEEK: 10080, // 60 * 24 * 7,
  HRS_IN_MILIS: 3600000,
});

export const DEFAULT_NUMERIC_VALUES = Object.freeze({
  DEFAULT_ZERO: 0,
  DEFAULT_ONE: 1,
  DEFAULT_NEG_ONE: -1,
  DEFAULT_REM_CONVERSION_VALUE: 16,
  DEFAULT_TWO: 2,
  DEFAULT_THREE: 3,
  DEFAULT_FIVE: 5,
  DEFAULT_FOUR: 4,
  DEFAULT_THOUSAND: 1000,
});

export const SENTRY_TRACES_SAMPLE_RATES = Object.freeze({
  PROD: 0.2,
  STAGING: 0.0,
});

interface paginationInterface {
  PAGE_SIZE: number;
  PAGE_COUNT: number;
  TOTAL_PAGE_COUNT: number;
  PAGE_SIZE_OPTIONS: { [key: string]: number };
  START: number;
}

export const DEFAULT_PAGINATION_VALUES: paginationInterface = Object.freeze({
  PAGE_SIZE: 20,
  PAGE_COUNT: 0,
  TOTAL_PAGE_COUNT: 1,
  PAGE_SIZE_OPTIONS: {
    OPTION_1: 20,
    OPTION_2: 50,
    OPTION_3: 100,
  },
  START: 0,
});

export const FILE_SIZE_IN_BYTES = Object.freeze({
  BYTES: 1,
  KBYTES: 1024,
  MBYTES: 1048576,
  GBYTES: 1073741824,
});

export const ISO_DATE_SLICE = Object.freeze({
  START: 0,
  END: 19,
  END_SHORT_DATE: 10,
});

export const NUMERIC_VALUES = Object.freeze({
  CONSTANT_NEGATIVE_ONE: -1,
  CONSTANT_NEGATIVE_TWO: -2,
  CONSTANT_ZERO: 0,
  CONSTANT_ONE: 1,
  CONSTANT_TWO: 2,
  CONSTANT_THREE: 3,
  CONSTANT_FOUR: 4,
  CONSTANT_FIVE: 5,
  CONSTANT_SIX: 6,
  CONSTANT_SEVEN: 7,
  CONSTANT_EIGHT: 8,
  CONSTANT_NINE: 9,
  CONSTANT_TEN: 10,
  CONSTANT_TWELVE: 12,
  CONSTANT_THIRTEEN: 13,
  CONSTANT_TWENTY: 20,
  CONSTANT_TWENTY_FOUR: 24,
  CONSTANT_THIRTY: 30,
  CONSTANT_FIFTY: 50,
  CONSTANT_SIXTY: 60,
  CONSTANT_EIGHTY: 80,
  CONSTANT_HUNDRED: 100,
  CONSTANT_TWO_HUNDRED: 200,
  CONSTANT_FIVE_HUNDRED: 500,
  CONSTANT_THOUSAND: 1000,
  CONSTANT_TWO_THOUSAND: 2000,
  CONSTANT_FIVE_THOUSAND: 5000,
  CONSTANT_TEN_THOUSAND: 10000,
});

export const NEXT_DAYS = Object.freeze({
  SEVEN: 7,
  FORTEEN: 14,
  THIRTY: 30,
});

export const NUMERIC_TIMEOUTS = Object.freeze({
  FOURTYTH_OF_SECOND: 25, // 1000/40 = 25
  TENTH_OF_SECOND: 100,
  HALF_SECOND: 500,
  ONE_SECOND: 1000,
  TWO_SECOND: 2000,
  FIVE_SECOND: 5000,
  TEN_SECOND: 10000,
  THIRTY_SECOND: 30000,
  ONE_MINUTE: 60000,
  CUSTOM: {
    LIBRARY_DETAIL_FADE_OUT: 2600,
  },
});

export const ROTATIONAL_CONSTANTS = Object.freeze({
  ZERO_DEVIATION: 0,
  ROTATE_ONE_QUADRANT_CLOCKWISE: 90,
  ROTATE_HALF_CLOCKWISE: 180,
  ROTATE_ONE_QUADRANT_ANTI_CLOCKWISE: 270,
});

export const TRIM_COUNT_CONSTANTS = Object.freeze({
  DOCUMENT_NAMES: 42,
  CUSTOMER_NAME: 15,
  COMPANY_NAME: 15,
  BACKROUTE: 3,
  RR_ROUTE: 3,
  DSO_VALUE: 3,
  GRAPH_DATA: 3,
  SHOW_EMAIL: 3,
  CONTACTS_TRIM: 2,
  TOP_CUSTOMERS: 5,
  DROPDOWM_ROOTPATH: 3,
  NOTE_STREAMS: 150,
});

export const DIFF_IN_DAYS = Object.freeze({
  OPEN: {
    FROM: 1,
    TO: 30,
  },
  LATE31: {
    FROM: 31,
    TO: 60,
  },
  LATE61: {
    FROM: 61,
    TO: 90,
  },
  LATE91: {
    FROM: 91,
    TO: Number.POSITIVE_INFINITY,
  },
});

export const TEMPLATE_TIMING_CONSTANTS = Object.freeze({
  ZERO: 0,
  THIRTY_SEC: 30,
  ONE_MIN: 60,
  TWO_MIN_THIRTY_SEC: 150,
  THREE_MIN: 180,
  THREE_MIN_THIRTY_SEC: 210,
  FOUR_MIN: 240,
  FIVE_MIN: 300,
});

export const GENERIC_UNICODE_VALUES = Object.freeze({
  BOM: 0xfeff,
  SPACE: 160,
});

export const DECIMAL_VALUES = Object.freeze({
  ZERO_POINT_FIVE: 0.5,
});

export const RTE_FONT_SIZE_LIST = Object.freeze({
  EIGHT: 8,
  NINE: 9,
  TEN: 10,
  ELEVEN: 11,
  TWELVE: 12,
  FORTEEN: 14,
  SIXTEEN: 16,
  EIGHTEEN: 18,
  TWENTY_FOUR: 24,
  THIRTY: 30,
  THIRTY_SIX: 36,
  FORTY_EIGHT: 48,
  SIXTY: 60,
  SEVENTY_TWO: 72,
  NINETY_SIX: 96,
});

import React from "react";
import { FilterProps } from "../../Interfaces/TableActionInterfaces";
import { Filter as FilterIcon, Close } from "../../../Icons/Icons";
import DefaultFilter from "./DefaultFilter";
import SelectFilter from "./SelectFilter";
import Button from "../../../Button/Button";
import "../../../Table/FilterButton/FilterButton.scss";
import { ClickAwayListener, IconButton } from "@mui/material";
import { FilterType } from "../../../../../types/enums";
import TableUtils from "../../../../../utils/TableUtils/TableUtils";
import { DEFAULT_NUMERIC_VALUES } from "../../../../../constants/NumericConstants";

const Filter: React.FC<FilterProps> = ({ type, position, field, token, onClickFilter, onPopupActive, ...props }) => {
  const [disableClear, setDisableClear] = React.useState<boolean>(true);
  const [value, setValue] = React.useState<string>("");
  const [openPopup, setPopup] = React.useState<boolean>(false);
  const [filters, setFilters] = React.useState<SearchFilter[]>([]);

  // opens the filter
  const onClickFilterIcon = () => {
    onPopupActive?.();
    setPopup(true);
  };

  const getPaymentDueDateFilters = (): SearchFilter[] => {
    const filters: any[] = [];
    TableUtils.addInvoiceDaysPastDueFilters(value, field, token, filters, props.upperBoundToken);
    return filters.map((filter: { field: string; value: string; token: string }) => ({
      filterKey: filter.field,
      filterValue: filter.value,
      filterToken: filter.token,
    }));
  };

  // updates the table filters
  const onClickFilterButton = () => {
    const newFilters: SearchFilter[] = [];
    if (field === "days_past_due") {
      newFilters.push(...getPaymentDueDateFilters());
    } else {
      newFilters.push({
        filterValue: value,
        filterKey: field,
        filterToken: token,
      });
    }
    // check if any existing filter is removed
    const removedFilters = filters.filter(
      (item: SearchFilter) => !newFilters.filter((filter: SearchFilter) => item.filterKey === filter.filterKey).length
    );
    // update states
    onClickFilter?.([
      ...removedFilters.map((filter: SearchFilter) => ({
        ...filter,
        clear: true,
      })),
      ...newFilters,
    ]);
    setFilters(newFilters);

    setDisableClear(false);
    setPopup(false);
  };

  const onClickClearButton = () => {
    setValue("");
    // update the table filters
    onClickFilter?.(
      filters.map((filter: SearchFilter) => ({
        filterKey: filter.filterKey,
        filterValue: "",
        filterToken: filter.filterToken,
      })),
      true
    );
    setFilters([]);

    setDisableClear(true);
    setPopup(false);
  };

  // enable keyboard actions
  const onPressKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && value) onClickFilterButton();
    else if (e.key === "Escape") {
      setPopup(false);
      setValue("");
      setDisableClear(false);
    }
  };

  // provides approriate filter
  const FilterVariant = () => {
    return type === FilterType.Search ? (
      <DefaultFilter filterValue={value} setFilterValue={setValue} onPressKeyDown={onPressKeyDown} {...props} />
    ) : (
      <SelectFilter filterValue={value} setFilterValue={setValue} onPressKeyDown={onPressKeyDown} {...props} />
    );
  };

  return (
    <ClickAwayListener onClickAway={() => setPopup(false)}>
      <div className="filter-wrapper" onBlur={() => setPopup(false)} style={{ zIndex: 1000 }}>
        <div onClick={onClickFilterIcon}>
          <FilterIcon
            className={`filter-icon filter-icon-${filters.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO ? "active" : ""}`}
            isFilter={filters.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO}
          />
        </div>
        {openPopup ? (
          <div className={`filter-btn-popup ${position ? `filter-btn-popup-${position}` : ""}`} onBlur={() => setPopup(false)}>
            <div className="filter-header">
              <p className={`filter-btn-popup-title`}>Filter By</p>
              <IconButton disableRipple className={`filter-btn-popup-close`} onClick={() => setPopup(false)}>
                <Close />
              </IconButton>
            </div>
            {FilterVariant()}
            <div className="filter-btn-popup-grp">
              <Button className="filter-btn-popup-btns" disabled={disableClear} size="sm" variant="secondary" onClick={onClickClearButton}>
                Clear Filters
              </Button>
              <Button size="sm" className="filter-btn-popup-btns" disabled={value === ""} onClick={onClickFilterButton}>
                Filter
              </Button>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default Filter;

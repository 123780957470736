import React from "react";
import SecondaryActivity from "../../SecondaryActivity/SecondaryActivity";
import { SecondaryType } from "../../SecondaryActivity/SecondaryActivityTypes";
import { UnsnoozedProps } from "../ActivityItemVariantTypes";
import * as Labels from "../../../../../../../constants/config";

const Unsnoozed: React.FC<UnsnoozedProps> = ({ creator, countryCode }) => {
  return (
    <SecondaryActivity variant={SecondaryType.Base} creator={{ ...creator, message: Labels.HEADER_UNSNOOZED_STREAM }} countryCode={countryCode} />
  );
};

export default Unsnoozed;

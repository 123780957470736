/*eslint-disable no-magic-numbers*/
import { styled } from "@mui/system";
import Chip from "@mui/material/Chip";

export const OpenChip = styled(Chip)({
  color: "#1B5E20",
  border: "none",
  background: "#E8F5E9",
  height: "1.5rem",
  borderRadius: "0.375rem",
});

export const PastDue1Chip = styled(Chip)({
  color: "#0D47A1",
  border: "none",
  background: "#E3F2FD",
  height: "1.5rem",
  borderRadius: "0.375rem",
});

export const PastDue31Chip = styled(Chip)({
  color: "#BF360C",
  border: "none",
  background: "#FFF3E0",
  height: "1.5rem",
  borderRadius: "0.375rem",
});

export const PastDue61Chip = styled(Chip)({
  color: "#BF360C",
  border: "none",
  background: "#FFF3E0",
  height: "1.5rem",
  borderRadius: "0.375rem",
});

export const PastDue91Chip = styled(Chip)({
  color: "#B71C1C",
  border: "none",
  background: "#FFEBEE",
  height: "1.5rem",
  borderRadius: "0.375rem",
});

export const ClosedChip = styled(Chip)({
  color: "#444444",
  border: "none",
  background: "#F8F8F8",
  height: "1.5rem",
  borderRadius: "0.375rem",
});

export const RejectedChip = styled(Chip)({
  color: "#B71C1C",
  border: "none",
  background: "#FFEBEE",
  height: "1.5rem",
  borderRadius: "0.375rem",
});
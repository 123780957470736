import { Button } from "@mui/material";
import { styled } from "@mui/system";
import styles from "../../../css/_export.module.scss";

export const BannerRootContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: `1rem`,
  gap: styles.medium,
  minWidth: "39.875rem",
  height: "9.625rem",
  background: styles.white100,
  borderRadius: "0.5rem",
  marginTop: "1rem",
});
export const BannerContent = styled("div")({
  display: "flex",
  gap: styles.base,
  "& .icon-container": {
    display: "flex",
    fontSize: styles.fontSizeXXL,
    alignItems: "center",
  },
  "& .text-container": {
    height: "4.875rem",
    display: "flex",
    flexDirection: "column",
    gap: styles.small,
    "& .header-text": {
      fontFamily: styles.primaryFontFamily,
      fontWeight: "600",
      fontSize: styles.fontSizeL,
      lineHeight: styles.lineHeightL,
      color: "#4D4D4D",
    },
    "& .content-text": {
      fontFamily: styles.primaryFontFamily,
      fontWeight: "400",
      fontSize: styles.fontSizeXS,
      lineHeight: styles.lineHeightSM,
      color: "#4D4D4D",
    },
  },
});

export const BannerFooterContainer = styled("div")({
  display: "flex",
  gap: styles.medium,
  justifyContent: "flex-end",
});

export const PrimaryButton = styled(Button)({
  height: "2.5rem",
  background: "#EBF1FA",
  color: styles.babyBlue,
  textTransform: "capitalize",
  fontFamily: styles.primaryFontFamily,
  fontWeight: "600",
  fontSize: styles.fontSizeSM,
  lineHeight: styles.lineHeightMD,
  "&:hover": {
    background: "#EBF1FA",
  },
});
export const SecondaryButton = styled(Button)({
  height: "2.5rem",
  background: styles.babyBlue,
  color: styles.white100,
  textTransform: "capitalize",
  fontFamily: styles.primaryFontFamily,
  fontWeight: "600",
  fontSize: styles.fontSizeSM,
  lineHeight: styles.lineHeightMD,
  "&:hover": {
    background: styles.babyBlue,
  },
  "&:not(:first-of-type)": {
    marginLeft: "0",
  },
});

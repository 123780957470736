import APIClient from "../APIClient";
const PLATFORM_API_BASE = process.env.REACT_APP_API_ENDPOINT_PLATFORM_V1;

export class DefinitionsClient {
  public getUnsupportedERPs = (): Promise<UnsupportedERPModel[]> => {
    const url = `${PLATFORM_API_BASE}/Definitions/financialsystems?filter=IsSupported eq false`;

    return APIClient.get(url, undefined, true).then((response) => {
      return response.data.records;
    });
  };

  public getCurrencies = (): Promise<CurrencyModel[]> => {
    const url = `${PLATFORM_API_BASE}/Definitions/currencies`;

    return APIClient.get(url, undefined, true).then((response) => {
      return response.data.records;
    });
  };
}

import AP from "./AP.json";
import AR from "./AR.json";
import AW from "./AW.json";

export interface GSConfigInterface {
  [key: string]: any;
}

export const globalSearchConfigGetter = (workspaceType: string) => {
  const globalSearchConfig: GSConfigInterface = Object.freeze({
    AP,
    AR,
    AW,
  });

  return globalSearchConfig[workspaceType];
};

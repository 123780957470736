import React from "react";
import { EmailTemplateSearch as EmailTemplateSearchIcon } from "../../Icons/Icons";
import { SearchComponentContainer } from "../styled/email.template.styled";
import { Input } from "@mui/material";
import { SearchComponentProps } from "../types/email.template.popover.types";

/**
 * It's an custom search component with search icon and input field
 * @param searchedText: searched value of the input text
 * @param onChangeInSearchInput: handle the change in search input field
 * @param placehHolderText:[optional]- placeholder text of input field
 * @param isAutoFocus:[optional]- to auto focus the cursor when component render
 */
const SearchComponent: React.FC<SearchComponentProps> = ({
  searchedText,
  onChangeInSearchInput,
  placehHolderText = "Search Templates",
  isAutoFocus = true,
}) => {
  return (
    <SearchComponentContainer>
      <span className="icon">
        <EmailTemplateSearchIcon />
      </span>
      <Input placeholder={placehHolderText} disableUnderline={true} value={searchedText} onChange={onChangeInSearchInput} autoFocus={isAutoFocus} />
    </SearchComponentContainer>
  );
};

export default SearchComponent;

import APIClient from "../APIClient";
import { AxiosRequestConfig } from "axios";

const INBOX_API_BASE = `${process.env.REACT_APP_API_ENDPOINT_INBOX_API_PATH}${process.env.REACT_APP_API_ENDPOINT_INBOX_API_V2}`;
const CONFIG_OVERRIDE = {
  baseURL: process.env.REACT_APP_API_ENDPOINT_INBOX,
} as AxiosRequestConfig;

export class TemplatesClient {
  getTemplates(workspaceId: number): Promise<APIResponse> {
    const url = `${INBOX_API_BASE}/workspaces/${workspaceId}/email_templates`;
    return APIClient.get(url, CONFIG_OVERRIDE).then((response) => response.data);
  }

  saveTemplate(workspaceId: number, templateCode: number, body: any): Promise<APIResponse> {
    const url = `${INBOX_API_BASE}/workspaces/${workspaceId}/email_templates/${templateCode}`;
    return APIClient.patch(url, body, CONFIG_OVERRIDE).then((response) => response.data);
  }

  updateTemplateMultLocale(workspaceId: number | string, isMultiLocale: boolean, enabledLanguages: string[]): Promise<APIResponse> {
    const url = `${INBOX_API_BASE}/workspaces/${workspaceId}/email_templates/settings`;
    const options = { multi_locale: isMultiLocale, locales: enabledLanguages };

    return APIClient.patch(url, options, CONFIG_OVERRIDE).then((response) => response.data);
  }
}

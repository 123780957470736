import React, { useState } from "react";
import { Autocomplete, TextField, Typography } from "@mui/material";
import { DebouncedAutocompleteProps } from "../types/debounceAutocomplete";
import useInfiniteScroll from "../hooks/useInfiniteScroll";
import _ from "underscore";

function DebouncedAutocomplete<T>({
  network,
  data: { searchPlaceholder, isOptionEqualToValue, onOptionSelect, optionParser },
  ...autocompleteProps
}: DebouncedAutocompleteProps<T>): React.ReactElement {
  const [searchTerm, setSearchTerm] = useState("");
  const [open, setOpen] = useState<boolean>(true);

  // debounced-infinite network handler
  const { options, isLoading, hasError, lastEle, setLastEleRef } = useInfiniteScroll<T>({ ...network, searchTerm });

  /**
   * helper to deal with key & labels for dropdown options
   * @param option: T
   * @returns object to support option render
   */
  const defaultGetOptionLabel = (option: T | string): { label: string; key: string } => {
    if (typeof option === "string") {
      return { label: option.toString(), key: option.toString() };
    } else if (optionParser) {
      return optionParser(option);
    }
    return { label: "", key: "" };
  };

  const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: T | string) => {
    if (hasError) {
      return (
        <li {...props} key={"error-on-retrieval"}>
          <Typography variant="body2" color="error">
            Error retrieving options
          </Typography>
        </li>
      );
    }
    const { key, label } = defaultGetOptionLabel(option);
    return (
      <li {...props} key={key} ref={!_.isUndefined(lastEle) && _.isMatch(option, lastEle) ? setLastEleRef : null}>
        <span>{label}</span>
      </li>
    );
  };

  const getOptionLabel = (option: T | string) => defaultGetOptionLabel(option)?.["label"];

  return (
    <Autocomplete
      open={open}
      autoComplete
      blurOnSelect
      disableClearable
      disablePortal
      fullWidth
      getOptionLabel={getOptionLabel}
      inputValue={searchTerm}
      isOptionEqualToValue={isOptionEqualToValue}
      ListboxProps={{
        role: "list-box",
      }}
      loading={isLoading}
      loadingText={"Loading..."}
      noOptionsText={!hasError ? "Nothing found" : "Error retrieving options"}
      onChange={(_e, value: unknown) => {
        onOptionSelect?.(value as T);
        setOpen(false);
      }}
      onFocus={() => {
        setOpen(true);
      }}
      onInputChange={(_e, value) => setSearchTerm(value)}
      options={options}
      renderOption={renderOption}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            placeholder: searchPlaceholder,
          }}
        />
      )}
      {...autocompleteProps}
    />
  );
}

export default DebouncedAutocomplete;

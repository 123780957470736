import { ClickAwayListener, IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { ReplyLine, ReplyAllLine, ForwardLine, Ellipses } from "../../../../../../../library/Icons/Icons";
import { EmailAction } from "../../../../../../../../types/enums";
import { ReplyOptionsProps } from "../HelperTypes";
import useKeyboardShortcut from "../../../../../../../../hooks/useKeyboardShortcut";
import { ActivitySetActionShortcuts, KeyboardActionsConstants } from "../../../../../../../../constants/KeyboardShortcutConstants";
import { TooltipTitles, TooltipTypes } from "../../../../../../../../constants/TooltipConstants";
import CustomTooltip from "../../../../../../../library/CustomTooltip/CustomTooltip";
import TableUtils from "../../../../../../../../utils/TableUtils/TableUtils";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const ReplyOptions: React.FC<ReplyOptionsProps> = ({
  onClickForward,
  onClickReplyAll,
  onClickViewOriginalEmail,
  onClickSendForApproval,
  onClickMarkSenderAsSpam,
  onClickMarkSenderAsFraud,
  onClickReply,
  enableShortcuts,
  isApprovalActive,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);
  const { actionToPerform, setActionDone } = useKeyboardShortcut(ActivitySetActionShortcuts, !enableShortcuts);
  const { t } = useTranslation();

  const shortcutDisplayMapper: { [key: string]: string } = useMemo(() => {
    if (!enableShortcuts) return {};
    const mapper: { [key: string]: string } = {};
    ActivitySetActionShortcuts.forEach((item) => {
      if (item.identifier) mapper[item.identifier] = item.displayText;
    });
    return mapper;
  }, [enableShortcuts]);

  /* EVENT HANDLERS */
  const onClickAwayTooltip = () => {
    setIsTooltipOpen(false);
  };

  const onClickTooltipAction = (action: string) => {
    switch (action) {
      case EmailAction.REPLY_ALL:
        onClickReplyAll?.();
        break;
      case EmailAction.FORWARD:
        onClickForward?.();
        break;
      case EmailAction.VIEW_ORIGINAL:
        onClickViewOriginalEmail?.();
        break;
      case EmailAction.SEND_FOR_APPROVAL:
        onClickSendForApproval?.();
        break;
      case EmailAction.MARK_SENDER_AS_SPAM:
        onClickMarkSenderAsSpam?.();
        break;
      case EmailAction.MARK_SENDER_AS_FRAUD:
        onClickMarkSenderAsFraud?.();
        break;
      default:
        throw new Error("Email action does not exist.");
    }
    onClickAwayTooltip();
  };

  /**
   * Actions for Keyboard Shortcuts
   */
  const keyboardActions: { [key: string]: any } = useMemo(
    () => ({
      [KeyboardActionsConstants.send_reply]: () => onClickReply?.({}),
      [KeyboardActionsConstants.send_forward]: () => onClickTooltipAction(EmailAction.FORWARD),
    }),
    [onClickReply, onClickTooltipAction]
  );

  const ellipsesActions = TableUtils.generateMenuList([
    {
      value: t("activityDetails.feedItems.primaryActivity.helpers.replyOptions.actionLabel.sendForApproval", { ns: "activities" }),
      id: EmailAction.SEND_FOR_APPROVAL,
      clickFunction: () => onClickTooltipAction(EmailAction.SEND_FOR_APPROVAL),
      disabled: isApprovalActive,
    },
    // {
    //   value: "Mark Sender as Spam",
    //   id: EmailAction.MARK_SENDER_AS_SPAM,
    //   clickFunction: () => onClickTooltipAction(EmailAction.MARK_SENDER_AS_SPAM),
    // },
    // {
    //   value: "Mark Sender as Fraud",
    //   id: EmailAction.MARK_SENDER_AS_FRAUD,
    //   clickFunction: () => onClickTooltipAction(EmailAction.MARK_SENDER_AS_FRAUD),
    // },
  ]);

  const useTooltipStyles = makeStyles(() => ({
    tooltip: {
      background: "white !important",
      margin: "0 !important",
      padding: "10 !important",
      width: "300px",
      gap: "12px",
      boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.1), 0px 4px 20px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",
    },
  }));

  /**
   * Use Effect Calls
   */
  useEffect(() => {
    if (enableShortcuts && actionToPerform && keyboardActions[actionToPerform] && typeof keyboardActions[actionToPerform] === "function") {
      keyboardActions[actionToPerform]();
      setActionDone();
    }
  }, [actionToPerform, enableShortcuts]);

  const reply = t("activityDetails.feedItems.primaryActivity.helpers.replyOptions.tooltipMessage.reply", { ns: "activities" });
  const replyToEmail = t("activityDetails.feedItems.primaryActivity.helpers.replyOptions.tooltipMessage.replyToEmail", { ns: "activities" });
  const replyAll = t("activityDetails.feedItems.primaryActivity.helpers.replyOptions.tooltipMessage.replyAll", { ns: "activities" });
  const forwardEmail = t("activityDetails.feedItems.primaryActivity.helpers.replyOptions.tooltipMessage.forwardEmail", { ns: "activities" });
  const forward = t("activityDetails.feedItems.primaryActivity.helpers.replyOptions.tooltipMessage.forward", { ns: "activities" });

  return (
    <>
      <CustomTooltip
        type={enableShortcuts ? TooltipTypes.SHORTCUT : TooltipTypes.PLAIN}
        title={enableShortcuts ? replyToEmail : reply}
        shortcutKeysText={enableShortcuts ? shortcutDisplayMapper?.[TooltipTitles.REPLY_TO_EMAIL] : ""}
      >
        <div className="reply-action">
          <IconButton
            disableRipple
            onClick={(e) => {
              e.stopPropagation();
              onClickReply?.(e);
            }}
          >
            <ReplyLine />
          </IconButton>
        </div>
      </CustomTooltip>

      <CustomTooltip
        type={enableShortcuts ? TooltipTypes.SHORTCUT : TooltipTypes.PLAIN}
        title={replyAll}
        shortcutKeysText={enableShortcuts ? shortcutDisplayMapper?.[TooltipTitles.REPLY_TO_ALL] : ""}
      >
        <div className="reply-all-action">
          <IconButton
            disableRipple
            onClick={(e) => {
              e.stopPropagation();
              onClickReplyAll?.();
            }}
          >
            <ReplyAllLine />
          </IconButton>
        </div>
      </CustomTooltip>

      <CustomTooltip
        type={enableShortcuts ? TooltipTypes.SHORTCUT : TooltipTypes.PLAIN}
        title={enableShortcuts ? forwardEmail : forward}
        shortcutKeysText={enableShortcuts ? shortcutDisplayMapper?.[TooltipTitles.FORWARD] : ""}
      >
        <div className="forward-action">
          <IconButton
            disableRipple
            onClick={(e) => {
              e.stopPropagation();
              onClickForward?.();
            }}
          >
            <ForwardLine />
          </IconButton>
        </div>
      </CustomTooltip>

      <ClickAwayListener onClickAway={() => setIsTooltipOpen(false)}>
        <div className="more-actions">
          <Tooltip title={ellipsesActions} open={isTooltipOpen} classes={useTooltipStyles()} placement="bottom-end">
            <IconButton
              disableRipple
              onClick={(e) => {
                e.stopPropagation();
                setIsTooltipOpen(!isTooltipOpen);
              }}
            >
              <Ellipses />
            </IconButton>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </>
  );
};

export default ReplyOptions;

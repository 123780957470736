/* eslint-disable  prefer-const*/
/* eslint-disable no-magic-numbers*/
import React from "react";
import AP from "../utils/JSON/AP.json";
import AR from "../utils/JSON/AR.json";
import { default as E } from "../utils/JSON/EI.json";
import BaseConfig from "../utils/JSON/BaseConfig.json";

interface Props {
  children: (React.ReactNode & { type: string })[] | (React.ReactNode & { type: string });
}

interface ComponentConfigObjectType {
  [key: string]: any;
}

export const ComponentConfigContext = React.createContext<ComponentConfigType | null>(null);

const ComponentConfigProvider: React.FC<Props> = ({ children }: Props) => {
  /**
   * You can add your component config here
   */
  const configProvider: ComponentConfigObjectType = {
    base: BaseConfig,
    ar: AR,
    ap: AP,
    aw: AP,
    e: E,
  };

  const getConfig = (workspaceIdentifier: string) => {
    return configProvider[workspaceIdentifier];
  };
  return <ComponentConfigContext.Provider value={{ getConfig, configProvider }}>{children}</ComponentConfigContext.Provider>;
};

export default ComponentConfigProvider;

import React from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link, Card } from "@mui/material";
import { RecordCardRoot, RecordHeader, RecordTitle, RecordDetails, RecordDate } from "../styled/styled";
import { RecordCardPropTypes } from "./types";

export default function RecordCardView({
  recordLabel,
  recordLabelColor,
  recordDateTitle,
  recordDateValue,
  recordLinkTitle,
  colATitle,
  colAValue,
  colBTitle,
  colBValue,
  onClickRecordLink,
}: RecordCardPropTypes): React.ReactElement {
  return (
    <RecordCardRoot>
      <Card raised={true} className="transaction-card">
        <RecordHeader>
          <RecordTitle style={{ backgroundColor: recordLabelColor }}>{recordLabel}</RecordTitle>
          <RecordDetails>
            <RecordDate>
              {recordDateTitle}: {recordDateValue}
            </RecordDate>
            <Link onClick={onClickRecordLink}>{recordLinkTitle}</Link>
          </RecordDetails>
        </RecordHeader>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{colATitle}</TableCell>
                <TableCell>{colBTitle}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{colAValue}</TableCell>
                <TableCell>{colBValue}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </RecordCardRoot>
  );
}

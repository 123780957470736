import { TEMPLATE_TIMING_CONSTANTS } from "../../constants/NumericConstants";
import BaseTemplate from "./BaseTemplate";

/**
 * Template class which generate the text for Request for Payment Status on Account.
 */
class RequestAccountPaymentStatus extends BaseTemplate {
  /**
   * Templates From Time definition
   */
  protected fromTime = TEMPLATE_TIMING_CONSTANTS.THREE_MIN;

  /**
   * Templates To Time definition
   */
  protected toTime = TEMPLATE_TIMING_CONSTANTS.FOUR_MIN;
}

export default RequestAccountPaymentStatus;

import { styled } from "@mui/system";

export const EmptyStatesWrapper = styled("div", {
  name: "empty-message-wrapper",
  slot: "root",
})({
  display: "flex",
  flexDirection: "column",
  gap: "0.25rem",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",

  "> .empty-title": {
    whiteSpace: "initial",
    overflowWrap: "break-word",
    wordBreak: "break-word",
    width: "26rem",
  },

  "> .empty-caption": {
    overflowWrap: "break-word",
    wordBreak: "break-word",
    color: "#707A8F",
    whiteSpace: "initial",
    width: "26rem",
  },
  "> .icon-container": {
    width: "12.375",
    height: "12.375",
  },
});

import { DateRange } from "@mui/x-date-pickers-pro";
import { Dayjs } from "dayjs";
import React from "react";
import { FilterContainerTypes } from "../../preset.filter.types";
import DateFilterComponent from "./DateFilterComponent";
import { TableFilterComparatorConstant, TableFilterConstant } from "../../../../constants/table.constants";
import { useTranslation } from "react-i18next";

const DateFilterContainer: React.FC<FilterContainerTypes> = ({
  filterKey,
  filterValue,
  filterRangeValue,
  filterComparator,
  onChangeHandler,
  onClearHandler,
  dateFormat = "MMM DD, YYYY",
  filterAlignment = "center",
  searchlightField,
}) => {
  const { t } = useTranslation();
  const [selectedFilterItem, setSelectedFilterItem] = React.useState<string>(TableFilterConstant.getDateDropDownList(t)[0].key);
  const [active, setActive] = React.useState<boolean>(false);
  const [isOpenCustomDateRangePicker, setIsOpenCustomDateRangePicker] = React.useState<boolean>(false);
  //To clear filter
  const clearFilter = () => {
    setSelectedFilterItem(TableFilterConstant.getDateDropDownList(t)[0].key);
    if (onClearHandler) {
      onClearHandler(filterKey);
      setActive(false);
      setIsOpenCustomDateRangePicker(false);
    }
  };

  /* This func get's invoked once user select any list of items from the menu list
   * if Selected item is Custom the open the calendar date range picker to select
   * custom date.
   * else set the value for useCustomFilter through onChangeHandler. then check if
   * selected item is Any item then clear the filter else set the active filter.
   */
  const onDateFilterListOptionChange = (item: string) => {
    if (item?.includes?.(TableFilterComparatorConstant.CUSTOM_DATE)) {
      setIsOpenCustomDateRangePicker(true);
    } else if (item?.includes?.(TableFilterComparatorConstant.ANY_TIME)) {
      clearFilter();
    } else if (onChangeHandler) {
      setSelectedFilterItem(item);
      onChangeHandler([{ key: filterKey, value: item, comparator: item, searchlightField }]);
      setActive(true);
    }
  };

  // This func get's invoked once user save and apply custom date filter
  const onCustomDateFilterApply = (date: DateRange<Dayjs>) => {
    if (onChangeHandler) {
      setSelectedFilterItem(TableFilterComparatorConstant.CUSTOM_DATE);
      onChangeHandler([
        {
          key: filterKey,
          value: "",
          rangeValue: [date?.[0]?.format(TableFilterConstant.DATE_FORMAT) ?? "", date?.[1]?.format(TableFilterConstant.DATE_FORMAT) ?? ""],
          comparator: TableFilterComparatorConstant.CUSTOM_DATE,
          searchlightField,
        },
      ]);
      setIsOpenCustomDateRangePicker(false);
      setActive(true);
    }
  };

  //to close date range calendar picker popover
  const closeDateRangeCalendarPicker = () => setIsOpenCustomDateRangePicker(false);

  React.useEffect(() => {
    setActive(Boolean(filterRangeValue ?? filterValue));
  }, [filterValue, filterRangeValue]);

  React.useEffect(() => {
    setSelectedFilterItem(filterComparator ?? TableFilterConstant.getDateDropDownList(t)[0].key);
  }, [filterComparator]);

  return (
    <DateFilterComponent
      filterValue={selectedFilterItem}
      filterActive={active}
      isOpenCustomDateRangePicker={isOpenCustomDateRangePicker}
      closeDateRangeCalendarPicker={closeDateRangeCalendarPicker}
      menuList={TableFilterConstant.getDateDropDownList(t)}
      onDateFilterListOptionChange={onDateFilterListOptionChange}
      onClickApplyDate={onCustomDateFilterApply}
      dateFormat={dateFormat}
      filterAlignment={filterAlignment}
      filterRangeValue={filterRangeValue}
    />
  );
};

export default DateFilterContainer;

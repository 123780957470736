import React from "react";
import Button from "../../../../../../library/Button/Button";
import SecondaryActivity from "../../SecondaryActivity/SecondaryActivity";
import { SecondaryType } from "../../SecondaryActivity/SecondaryActivityTypes";
import * as Labels from "../../../../../../../constants/config";
import { ForwardLogProps } from "../ActivityItemVariantTypes";

const ForwardedLog: React.FC<ForwardLogProps> = ({ creator, onClickRedirect, readonly, countryCode }) => {
  creator = { ...creator, message: Labels.HEADER_FORWARDED };
  return readonly ? (
    <SecondaryActivity variant={SecondaryType.Base} creator={creator} countryCode={countryCode} />
  ) : (
    <SecondaryActivity variant={SecondaryType.Accordion} creator={creator} countryCode={countryCode}>
      <Button size="sm" onClick={onClickRedirect}>
        {Labels.BTN_SEE_FORWARDED_STREAM}
      </Button>
    </SecondaryActivity>
  );
};

export default ForwardedLog;

import React from "react";

import { useTranslation } from "react-i18next";

import Dialog from "../../../../library/Dialog/Dialog";
import DialogTitle from "../../../../library/DialogTitle/DialogTitle";
import DialogContent from "../../../../library/DialogContent/DialogContent";
import DialogActions from "../../../../library/DialogActions/DialogActions";
import Button from "../../../../library/Button/Button";
import useCopilotDiscardModalClasses from "./useCopilotDiscardModalClasses";

type CopilotDiscardModalProps = {
  showModal: boolean;
  closeModal: () => void;
  handleDisableClick: () => void;
  handleCancel: () => void;
};

export default function CopilotDiscardModal(props: CopilotDiscardModalProps): React.ReactElement {
  const { copilotDiscardDialog } = useCopilotDiscardModalClasses();
  const { t } = useTranslation();

  const onClickDisable = () => {
    props.handleDisableClick();
  };
  const onClickCancel = () => {
    props.handleCancel();
  };
  return (
    <Dialog open={props.showModal} onClose={props.closeModal} overrideDialogClasses={true} className={copilotDiscardDialog}>
      <DialogTitle align="left">{t("activityDetails.copilot.copilotBanner.headerText", { ns: "activities" })}</DialogTitle>
      <DialogContent>
        <p>{t("activityDetails.copilot.copilotBanner.infoText", { ns: "activities" })}</p>
      </DialogContent>
      <DialogActions>
        <Button variant="primary2" onClick={onClickDisable}>
          {t("activityDetails.copilot.copilotBanner.disableButtonprimary", { ns: "activities" })}
        </Button>
        <Button className="ml-auto" variant="secondary2" onClick={onClickCancel}>
          {t("activityDetails.copilot.copilotBanner.disableButtonsecondary", { ns: "activities" })}
        </Button>
      </DialogActions>
      <DialogContent className="mt-ba"> {t("activityDetails.copilot.copilotBanner.footerText", { ns: "activities" })}</DialogContent>
    </Dialog>
  );
}

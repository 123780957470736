import React from "react";
import { WorkspaceContext } from "../../../../../../contexts/WorkspaceContext";
import { contactsClientV2 } from "../../../../../../db/version2Accessor";
import ProfileTab from "../../../../../Activities/ActivityDetail/ActivityTab/ActivityProfileTab/ActivityProfileTab";
import Loading from "../../../../../library/Loading/Loading";

interface RelatedProfileInfoContainerInterface {
  customerId: string;
  email: string;
  phone: string;
}

const RelatedProfileInfoContainer: React.FC<RelatedProfileInfoContainerInterface> = ({
  customerId,
  email,
  phone,
}: RelatedProfileInfoContainerInterface) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [contactData, setContactData] = React.useState<Array<ContactItem>>([]);
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;

  const getContactInfo = async (custId?: string) => {
    setIsLoading(true);
    let filter = `qa[is_active_eq]=true`;
    if (custId) {
      filter = `${filter}&qa[company_id_eq]=${custId}`;
    }
    let response = {} as ContactModelFetchResultV2;
    try {
      response = await contactsClientV2.getContacts(selectedWorkspace.id, filter);
    } catch (err: unknown) {
      console.error(err);
    } finally {
      setContactData(response?.records ?? []);
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    getContactInfo(customerId);
  }, []);

  return isLoading ? <Loading /> : <ProfileTab phone={phone} email={email} contacts={contactData} />;
};

export default RelatedProfileInfoContainer;

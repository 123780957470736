import React from "react";
import { RequestApproval } from "../../../../../../library/Icons/Icons";
import PrimaryActivity from "../../PrimaryActivity/PrimaryActivity";
import { ApprovalRequestProps } from "../ActivityItemVariantTypes";
import * as Labels from "../../../../../../../constants/config";
import ApprovalUserActions from "../../PrimaryActivity/Helpers/ApprovalUserActions/ApprovalUserActions";

const Request: React.FC<ApprovalRequestProps> = ({ onClickApprove, onClickDecline, onClickNudge, onClickCancel, enableReviewOptions, ...props }) => {
  return (
    <PrimaryActivity
      {...{
        ...props,
        icon: <RequestApproval />,
        creator: { ...props.creator, message: Labels.HEADER_APPROVAL_REQUEST },
        FLAGS: { REPLY_OPTIONS: true, TIME_SAVING_INDICATOR: true },
      }}
    >
      {enableReviewOptions && (
        <ApprovalUserActions
          {...{ onClickApprove: onClickApprove, onClickDecline: onClickDecline, onClickNudge: onClickNudge, onClickCancel: onClickCancel }}
        />
      )}
    </PrimaryActivity>
  );
};

export default Request;

import React from "react";
import useWorkspaceConfigurations from "../../../hooks/useWorkspaceConfigurations";
import { VIEW_CATEGORIES, WorkspaceType } from "../../../types/enums";
import ActivitiesTable from "../ActivitiesTable/ActivitiesTable";
import useProcessTableConfigs from "../../../hooks/useProcessTableConfigs";
import activityTableConfigs, { ActivityTableViews } from "../ActivitiesTable/activityTable.configs";

export default function Unassigned(): React.ReactElement {
  const { selectedWorkspace } = useWorkspaceConfigurations();

  const { /* tableConfigs, */ columns: columnsConfigs } = activityTableConfigs();
  const currentWorkspaceType = (selectedWorkspace.workspace_type_route ?? WorkspaceType.AR) as WorkspaceType;
  const columns = useProcessTableConfigs(columnsConfigs, currentWorkspaceType, ActivityTableViews.UnassignedActivitiesTable);

  return (
    <>
      <div className={`table-activity-wrapper activity-stream-v2`}>
        <div className="body-table">
          <ActivitiesTable columns={columns} assigneeType={"unassigned"} route={"unassigned"} category={VIEW_CATEGORIES.UNASSIGNED_ACTIVITES} />
        </div>
      </div>
    </>
  );
}

import { useEffect, useMemo, useRef, useState } from "react";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../AtomicComponents/constants/numeric.constants";
import { useQuery } from "@tanstack/react-query";
import { debounce } from "underscore";
import { NUMERIC_TIMEOUTS } from "../../../../constants/NumericConstants";

type UseInfiniteScrollProps<T> = {
  apiCall: (searchTerm: string, page: number) => Promise<{ records: T[]; totalRecords: number } | null>;
  searchTerm: string;
  debounce: boolean;
  delay?: number;
};

type DebouncedFunction<T> = ((...args: T[]) => void | Promise<void>) & { cancel: () => void };

function useInfiniteScroll<T>({
  apiCall,
  searchTerm = "",
  debounce: hasDebounce = true,
  delay = NUMERIC_TIMEOUTS.HALF_SECOND,
}: UseInfiniteScrollProps<T>) {
  const [options, setOptions] = useState<T[]>([]);
  const [lastEle, setLastEle] = useState<T>();
  const [lastEleRef, setLastEleRef] = useState<Element | null>();

  const didMountRef = useRef<boolean>(false);
  const pageRef = useRef<number>(DEFAULT_NUMERIC_VALUES.ZERO);
  const hasMoreRef = useRef<boolean>(true);

  // fetch
  const {
    refetch,
    isFetching: isLoading,
    isRefetchError: hasError,
  } = useQuery(["infinite-scroll-call", searchTerm, hasMoreRef.current], () => apiCall(searchTerm, pageRef.current), {
    enabled: false,
    onSuccess: (data) => {
      if (data) {
        const records = data?.records;
        setOptions((prevOptions) => {
          if (prevOptions?.length + records.length < data?.totalRecords) {
            hasMoreRef.current = true;
            pageRef.current += 1;
          } else {
            hasMoreRef.current = false;
          }
          return [...prevOptions, ...records.map((option, idx) => ({ ...option, idx }))];
        });
        setLastEle(records[records.length - NUMERIC_VALUES.CONSTANT_THREE]);
      }
    },
  });

  // initial call
  useEffect(() => {
    (async () => await refetch())();
  }, []);

  // debouncing
  const debouncedApiCall: DebouncedFunction<T> = useMemo(
    () =>
      debounce(async () => {
        await refetch();
      }, delay),
    []
  );

  useEffect(() => {
    if (hasDebounce) {
      if (didMountRef.current) {
        // reset
        pageRef.current = DEFAULT_NUMERIC_VALUES.ZERO;
        setOptions([]);
        hasMoreRef.current = true;
        // debounce
        debouncedApiCall();
      }
      didMountRef.current = true;
    }
    return () => debouncedApiCall.cancel();
  }, [searchTerm]);

  // element intersection observer setup
  const handleObserver = (entries: IntersectionObserverEntry[]) => {
    if (entries[0].isIntersecting && hasMoreRef.current) {
      (async () => await refetch())();
    }
  };

  const observer = useRef(
    new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "0px",
      threshold: 0.75,
    })
  );

  useEffect(() => {
    const element = lastEleRef;
    const eleObserver = observer.current;
    if (element) {
      eleObserver?.observe(element);
    }
    return () => {
      if (element) {
        eleObserver?.unobserve(element);
      }
    };
  }, [lastEleRef]);

  return {
    options,
    lastEle,
    isLoading,
    hasError,
    hasMoreRef,
    pageRef,
    setLastEleRef,
  };
}

export default useInfiniteScroll;

import React from "react";
import { DEFAULT_NUMERIC_VALUES } from "../../../../../constants/NumericConstants";
import Utils from "../../../../../utils/utils";
import { AutomateActivity } from "../../../../library/Icons/Icons";

interface TimeIconProps {
  index: number;
  timeFrom?: number;
  timeTo?: number;
}

export default function TimeIcon(props: TimeIconProps): React.ReactElement {
  return (
    <div key={props.index} className={"time-wrapper"}>
      <div>
        <AutomateActivity />
      </div>
      <div className="time-text-wrapper">
        <div className="time-text">Time Savings</div>
        <div className="time-text-minutes">
          {Utils.formatTimeInMinutesAndSeconds(
            props.timeFrom ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            props.timeTo ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
          )}
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { DropdownItemList, FilterContainerTypes } from "../../preset.filter.types";
import StringFilterComponent from "./DropdownSearchFilterComponent";
import { TableFilterComparatorConstant } from "../../../../constants/table.constants";
import { SearchedFilterData } from "../../../../Table/types/hooks.types";
import { NUMERIC_VALUES } from "../../../../constants/numeric.constants";

//Moved debounce function outside the component to avoid recreating it on every render.
const debounce = (func: (searchedTextData: SearchedFilterData[]) => void, timeout = NUMERIC_VALUES.CONSTANT_FIVE_HUNDRED) => {
  let previousTimeout: NodeJS.Timeout;
  return (...args: [searchedTextData: any]) => {
    clearTimeout(previousTimeout);
    previousTimeout = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

const DropdownSearchFilterContainer: React.FC<FilterContainerTypes> = React.memo(
  ({
    filterKey,
    onChangeHandler,
    onClearHandler,
    filterAlignment = "center",
    dropdownSearchListItems = [],
    updateFiltersSearchedText,
    searchlightField,
    filterRangeValue,
    dropdownSearchVariant,
    placeholder,
    isNoSearchResultFound,
    addSearchSuggestionHistory,
    isSuggestionDataLoading,
    workspace,
    category,
  }: FilterContainerTypes) => {
    const [searchFieldText, setSearchFieldText] = useState<string>("");
    const [active, setActive] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onChangeSearchInputFieldValue = (textValue: string) => {
      setSearchFieldText(textValue);
      if (updateFiltersSearchedText) {
        debounce(updateFiltersSearchedText)([{ key: filterKey, value: textValue }]);
      }
      setIsLoading(textValue.length >= NUMERIC_VALUES.CONSTANT_THREE);
    };

    const clearSearchedText = () => {
      setSearchFieldText("");
      setIsLoading(false);
      if (updateFiltersSearchedText) {
        updateFiltersSearchedText([{ key: filterKey, value: "" }]);
      }
    };

    const onClickSuggestionListItem = (id: string) => {
      const selectedIds: string[] = [];
      const selectedFilterText: string[] = [];
      let selectedSuggestionData = {};

      dropdownSearchListItems.forEach((item) => {
        if (item?.id === id || item.selected) {
          selectedIds.push(item.id);
          selectedFilterText.push(item?.isSecondaryTextAppliedAsFilter ? (item.secondaryText as string) : item.primaryText);
        }

        if (item.id === id) {
          selectedSuggestionData = item;
        }
      });

      if (addSearchSuggestionHistory) {
        addSearchSuggestionHistory(workspace ?? "", selectedSuggestionData as DropdownItemList, filterKey, category ?? "");
      }

      if (searchFieldText.length) {
        clearSearchedText();
      }

      if (onChangeHandler) {
        onChangeHandler([
          {
            ids: selectedIds,
            key: filterKey,
            value: searchFieldText,
            rangeValue: selectedFilterText,
            comparator: TableFilterComparatorConstant.MULTI_VALUE,
            searchlightField,
          },
        ]);
        setActive(true);
      }
    };

    const onClickSelectedListItem = (id: string) => {
      const remainingSelectedIds: string[] = [];
      const remainingSelectedFilterText: string[] = [];

      dropdownSearchListItems.forEach((item) => {
        if (item.id !== id && item.selected) {
          remainingSelectedIds.push(item.id);
          remainingSelectedFilterText.push(item?.isSecondaryTextAppliedAsFilter ? (item.secondaryText as string) : item.primaryText);
        }
      });

      if (searchFieldText.length) {
        clearSearchedText();
      }

      if (onChangeHandler && remainingSelectedIds.length) {
        onChangeHandler([
          {
            ids: remainingSelectedIds,
            key: filterKey,
            value: searchFieldText,
            rangeValue: remainingSelectedFilterText,
            comparator: TableFilterComparatorConstant.MULTI_VALUE,
            searchlightField,
          },
        ]);
        setActive(true);
      } else {
        if (onClearHandler) {
          onClearHandler(filterKey);
          setActive(false);
        }
      }
    };

    useEffect(() => {
      setIsLoading(!!isSuggestionDataLoading);
    }, [isSuggestionDataLoading]);

    useEffect(() => {
      setActive(!!filterRangeValue?.length);
    }, [filterRangeValue]);

    return (
      <StringFilterComponent
        onClickSuggestionListItem={onClickSuggestionListItem}
        onClickSelectedListItem={onClickSelectedListItem}
        searchFieldText={searchFieldText}
        filterActive={active}
        onChangeSearchInputFieldValue={onChangeSearchInputFieldValue}
        onClickClearFilter={clearSearchedText}
        filterAlignment={filterAlignment}
        dropdownSearchListItems={dropdownSearchListItems}
        isSuggestionDataLoading={isLoading}
        dropdownSearchVariant={dropdownSearchVariant}
        searchPlaceholder={placeholder}
        isNoSearchResultFound={!!isNoSearchResultFound}
      />
    );
  }
);

DropdownSearchFilterContainer.displayName = "DropdownSearchFilterContainer";

export default React.memo(DropdownSearchFilterContainer);

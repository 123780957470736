import APIClient from "../APIClient";
import { AxiosRequestConfig } from "axios";

const INBOX_API_BASE = `${process.env.REACT_APP_API_ENDPOINT_INBOX_API_PATH}${process.env.REACT_APP_API_ENDPOINT_INBOX_API_V2}`;
const CONFIG_OVERRIDE = {
  baseURL: process.env.REACT_APP_API_ENDPOINT_INBOX,
} as AxiosRequestConfig;

export class AutomationClient {
  /**
   * * POST on inbox/api/v2/workspaces/${workspaceId}/automation_time_savings
   * @param body - Object of AUtomation timings to be posted
   * @returns Promise of type AutomationModel or Exception
   */
  post(workspaceId: number, body: Partial<AutomateTimeModel>): Promise<AutomateTimeModel> {
    const url = `${INBOX_API_BASE}/workspaces/${workspaceId}/automation_time_savings`;
    return APIClient.post(url, body, CONFIG_OVERRIDE).then((res) => res.data);
  }
}

import React from "react";
import { DEFAULT_NUMERIC_VALUES } from "../constants/NumericConstants";
import { ActivityActionTypes, ActivityStatus, FallbackTypes } from "../types/enums";
import { activitiesClientV2 } from "./../db/version2Accessor";
import { WorkspaceContext } from "./WorkspaceContext";
import { AlertContext } from "./AlertContext";
import { ACTIVITY_COMPLETE_FAILURE, ACTIVITY_COMPLETE_SUCCESS, BULK_ACTIVITY_COMPLETE_FALIURE } from "../constants/config";

export const ActivityContext = React.createContext<ActivityType | null>(null);

export interface ActivityProviderProps {
  children: (React.ReactNode & { type: string })[] | (React.ReactNode & { type: string });
}

interface StringBoolObjects {
  [key: string]: boolean;
}

type ReactionItem = {
  [key: string]: {
    count: number;
    givers: Array<{ name: string; id: string; user_id: number }>;
    highlight: boolean;
  };
};

export interface ActivityActionPerformStatus extends StringBoolObjects {
  Assign: boolean;
  Close: boolean;
  Archive: boolean;
  Read: boolean;
  Snooze: boolean;
  Move: boolean;
  Spam: boolean;
}

const ActivityProvider: React.FC<ActivityProviderProps> = ({ children }: ActivityProviderProps) => {
  const [allRowsSelected, setAllRowsSelected] = React.useState<boolean>(false);
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const [activityStatus, setActivityStatus] = React.useState<string>("");
  const [userView, setUserView] = React.useState<string>("");
  const [tableColumnFilters, setTableColumnFilters] = React.useState<SearchFilter[]>([]);
  const [activityPerformInProgress, setActivityPerformInProgress] = React.useState<ActivityActionPerformStatus>({
    Assign: false,
    Close: false,
    Archive: false,
    Read: false,
    Snooze: false,
    Move: false,
    Spam: false,
  });
  const [activityActionInProgress, setActivityActionInProgress] = React.useState<boolean>(false);
  const [activityData, setActivityData] = React.useState<ActivityStreamItem>({} as ActivityStreamItem);
  const [showAttachmentTab, setShowAttachmentTab] = React.useState<boolean>(false);
  const [activityReactions, setActivityReactions] = React.useState<{
    [key: string | number]: ReactionItem;
  }>({});

  React.useEffect(() => {
    setActivityActionInProgress(
      Object.keys(activityPerformInProgress)?.filter((key) => activityPerformInProgress?.[key])?.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
    );
  }, [activityPerformInProgress]);

  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const getStreamColumnFilterPayloadObject = (filters: SearchFilter[]) => {
    const filterPayload = { qa: {} } as Record<string, Record<string, string>>;
    if (filters && filters.length) {
      filters.forEach((filter: SearchFilter) => {
        filterPayload["qa"][`${filter.filterKey}_cont_any`] = `${filter.filterValue}`;
      });
    }
    return filterPayload;
  };

  const attachSelectedAllPayload = (payload: ActivitiesStreamUpdateBody) => {
    if (!allRowsSelected) {
      return payload;
    }
    let body = {};
    body = {
      ...payload,
      workspace_id: selectedWorkspace?.id || FallbackTypes.Id,
      select_all: true,
      filter_status: activityStatus,
      assignee_type: userView,
    };
    if (tableColumnFilters && tableColumnFilters.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      // pass column filters
      body = {
        ...body,
        ...getStreamColumnFilterPayloadObject(tableColumnFilters),
      };
    }
    return body;
  };

  const attachSelectedAllPayloadForCustomers = (payload: UpdateCustomerStatusPayload) => {
    if (!allRowsSelected) {
      return payload;
    }
    let body = {} as UpdateCustomerStatusPayload;
    body = {
      ...payload,
      select_all: true,
      filter_status: activityStatus,
      assignee_type: userView,
    };
    if (tableColumnFilters && tableColumnFilters.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      // pass column filters
      body = {
        ...body,
        ...getStreamColumnFilterPayloadObject(tableColumnFilters),
      };
    }
    return body;
  };

  const handleClose = async (id: string | Array<string>, deselectedRowsFilter?: string) => {
    let reqBody: ActivitiesStreamUpdateBody = {
      status: ActivityStatus.Closed?.toLocaleLowerCase?.() as Status,
    };
    reqBody = attachSelectedAllPayload(reqBody);

    const isBulk = id instanceof Array;
    return activitiesClientV2
      .patch(
        selectedWorkspace?.id || FallbackTypes.Id,
        isBulk ? "" : id,
        ActivityActionTypes.Status,
        isBulk
          ? {
              activity_stream_ids: id,
              ...reqBody,
            }
          : reqBody,
        isBulk,
        deselectedRowsFilter
      )
      .catch((err) => err);
  };

  const activityCloseAction = async (
    activityIds: Array<string>,
    onCallback?: () => Promise<any> | void,
    deselectedRowsFilter?: string,
    selectedActivitiesCount?: number
  ) => {
    const bulkActivitiesCount = selectedActivitiesCount ?? activityIds.length;

    setActivityPerformInProgress({ ...activityPerformInProgress, Close: true });
    let toastOptions: ToastOptions = {
      open: true,
      severity: "success",
      message:
        activityIds.length <= DEFAULT_NUMERIC_VALUES.DEFAULT_ONE ? ACTIVITY_COMPLETE_SUCCESS : `${bulkActivitiesCount} Activities were completed.`,
    };

    let response = {} as APIResponse;

    try {
      response = await handleClose(activityIds.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE ? activityIds[0] : activityIds, deselectedRowsFilter);
    } catch (e: unknown) {
      response.success = false;
    } finally {
      if (!response.success) {
        toastOptions = {
          ...toastOptions,
          severity: "error",
          message:
            activityIds.length <= DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
              ? ACTIVITY_COMPLETE_FAILURE
              : `${bulkActivitiesCount} ${BULK_ACTIVITY_COMPLETE_FALIURE}`,
        };
      }
      if (onCallback) {
        await onCallback();
      }
      setToastOptions(toastOptions);
      setActivityPerformInProgress({ ...activityPerformInProgress, Close: false });
    }
  };

  const handleMove = async (id: string | Array<string>, companyId: string | null, noteValue: string, deselectedRowsFilter?: string) => {
    const isBulk = id instanceof Array;
    return activitiesClientV2
      .patch(
        selectedWorkspace?.id || FallbackTypes.Id,
        isBulk ? "" : id,
        ActivityActionTypes.Move,
        isBulk
          ? {
              activity_stream_ids: id,
              primary_connection_id: companyId ?? undefined,
              note: noteValue,
              select_all: allRowsSelected,
            }
          : {
              primary_connection_id: companyId ?? undefined,
              note: noteValue,
            },
        isBulk,
        deselectedRowsFilter
      )
      .catch((err) => err);
  };

  // move the unlisted email activty to existing connection
  const handleUnlistedEmailMove = async (id: string, confirmed?: boolean, companyId?: string, noteValue?: string) => {
    return activitiesClientV2
      .confirmAndMove(selectedWorkspace?.id || FallbackTypes.Id, id, {
        confirmed,
        primary_connection_id: companyId,
        note: noteValue,
      })
      .catch((err) => err);
  };

  const handleReassign = async (id: string | Array<string>, userId: string | null, noteValue: string, deselectedRowsFilter?: string) => {
    let reqBody: ActivitiesStreamUpdateBody = {
      assignee_id: userId || "",
      note: noteValue,
    };
    reqBody = attachSelectedAllPayload(reqBody);

    const isBulk = id instanceof Array;

    return activitiesClientV2
      .patch(
        selectedWorkspace?.id || FallbackTypes.Id,
        isBulk ? "" : id,
        userId === "" ? ActivityActionTypes.Unassign : ActivityActionTypes.Assign,
        isBulk
          ? {
              ...reqBody,
              activity_stream_ids: id,
            }
          : reqBody,
        isBulk,
        deselectedRowsFilter
      )
      .catch((err) => err);
  };

  return (
    <ActivityContext.Provider
      value={{
        activityPerformInProgress,
        activityActionInProgress,
        activityCloseAction,
        handleClose,
        handleMove,
        handleUnlistedEmailMove,
        handleReassign,
        allRowsSelected,
        setAllRowsSelected,
        setActivityStatus,
        setUserView,
        attachSelectedAllPayload,
        attachSelectedAllPayloadForCustomers,
        activityStatus,
        setTableColumnFilters,
        activityData,
        setActivityData,
        setShowAttachmentTab,
        showAttachmentTab,
        activityReactions,
        setActivityReactions,
      }}
    >
      {children}
    </ActivityContext.Provider>
  );
};

export default ActivityProvider;

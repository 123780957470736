import React, { useState } from "react";
import { Company } from "../../library/Icons/Icons";

type SidebarHeadProps = {
  companyLogoUrl: string;
  accountName: string;
};

const SidebarHead = (props: SidebarHeadProps): React.ReactElement => {
  const [hasError, setHasError] = useState<boolean>(false);
  const handleImageLoadError = () => {
    setHasError(true);
  };

  return (
    <div className="company-details-container">
      <div className="company-logo-container">
        {(props.companyLogoUrl ?? "") && !hasError ? (
          <img src={props.companyLogoUrl ?? ""} alt="User Picture" className="userpic-btn-img" onError={handleImageLoadError} />
        ) : (
          <>{<Company />}</>
        )}
      </div>

      <div className="company-name-container">
        <p className="company-name">{props.accountName || "Pending..."}</p>
      </div>
    </div>
  );
};

export default SidebarHead;

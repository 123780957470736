/*eslint-disable no-magic-numbers*/
import { ClosedChip, OpenChip, WaitingForResponseChip, ActiveChip, SnoozedChip } from "./activitystatuschips.style";
import { ActivityStatusChipsComponentType } from "./activitystatuschips.types";
import { ActivityStatusLabels } from "../../constants/string.constants";
import Typography from "@mui/material/Typography";
import { getFormattedChipLabelForActivity } from "../../utils/table";
import React from "react";

const ActivityStatusChips: React.FC<ActivityStatusChipsComponentType> = ({ chipStatus, chipConfig }: ActivityStatusChipsComponentType) => {
  const getChipText = (displayText: string) => {
    return <Typography variant={chipConfig?.fontVariant || "captionReg"}>{displayText}</Typography>;
  };

  const getStatusChip = (status: string) => {
    const chipLabel = getFormattedChipLabelForActivity(status);
    switch (chipLabel) {
      case ActivityStatusLabels["open"]:
        return <OpenChip label={getChipText(chipLabel)} variant={chipConfig?.chipVariant || "outlined"} />;
      case ActivityStatusLabels["active"]:
        return <ActiveChip label={getChipText(chipLabel)} variant={chipConfig?.chipVariant || "outlined"} />;
      case ActivityStatusLabels["closed"]:
        return <ClosedChip label={getChipText(chipLabel)} variant={chipConfig?.chipVariant || "outlined"} />;
      case ActivityStatusLabels["snoozed"]:
        return <SnoozedChip label={getChipText(chipLabel)} variant={chipConfig?.chipVariant || "outlined"} />;
      case ActivityStatusLabels["waiting_for_response"]:
        return <WaitingForResponseChip label={getChipText(chipLabel)} variant={chipConfig?.chipVariant || "outlined"} />;
      default:
        return <ClosedChip label={getChipText(chipLabel)} variant={chipConfig?.chipVariant || "outlined"} />;
    }
  };

  return <>{getStatusChip(chipStatus)}</>;
};

export default ActivityStatusChips;

import React from "react";
import { Pen } from "../../../../../../library/Icons/Icons";
import PrimaryActivity from "../../PrimaryActivity/PrimaryActivity";
import { ApprovalRedirectProps } from "../ActivityItemVariantTypes";
import * as Labels from "../../../../../../../constants/config";
import ApprovalUserActions from "../../PrimaryActivity/Helpers/ApprovalUserActions/ApprovalUserActions";

const Redirect: React.FC<ApprovalRedirectProps> = ({
  onClickApprove,
  onClickDecline,
  onClickCancel,
  onClickNudge,
  approverName,
  enableReviewOptions,
  ...props
}) => {
  return (
    <PrimaryActivity
      {...{
        ...props,
        icon: <Pen />,
        creator: { ...props.creator, name: `${Labels.HEADER_APPROVAL_REQ_REDIRECT}${approverName ? ` to ${approverName}` : ""}`, message: "" },
        FLAGS: { REPLY_OPTIONS: true, TIME_SAVING_INDICATOR: false },
      }}
    >
      {enableReviewOptions && (
        <ApprovalUserActions
          {...{ onClickApprove: onClickApprove, onClickDecline: onClickDecline, onClickNudge: onClickNudge, onClickCancel: onClickCancel }}
        />
      )}
    </PrimaryActivity>
  );
};

export default Redirect;

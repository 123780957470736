import React from "react";
import { AuditLogType } from "../../../../../../../types/enums";
import SecondaryActivity from "../../SecondaryActivity/SecondaryActivity";
import { SecondaryType } from "../../SecondaryActivity/SecondaryActivityTypes";
import { SnoozedProps } from "../ActivityItemVariantTypes";
import { DateTime } from "luxon";
import useLocale from "../../../../../../../hooks/useLocale";
import { Button } from "../../../../../../library/AtomicComponents/atomic/Button";
import { useTranslation } from "react-i18next";

const Snoozed: React.FC<SnoozedProps> = ({ onClickUnsnooze, timestampUntilSnoozed, creator, status, readonly, countryCode }) => {
  const { formatDateBasedOnCountry: formatDate } = useLocale();
  const { t } = useTranslation();

  const formatDateTime = (value: string) => {
    const date = formatDate(value);
    const time = DateTime.fromFormat(value.split("T")[1], "hh:mm:ss").toFormat("hh:mm a");
    return `${date}, ${time}`;
  };
  // write conversion for time stamp -> MMM dd, yyyy, hh:mm
  creator = {
    ...creator,
    message: t("activityDetails.feedItems.snoozeActivity.headerMessage", {
      time: timestampUntilSnoozed ? formatDateTime(timestampUntilSnoozed) : "N/A",
      ns: "activities",
    }),
  };

  return status === AuditLogType.Snoozed && !readonly ? (
    <SecondaryActivity variant={SecondaryType.DetailedWithAction} creator={creator} countryCode={countryCode}>
      <Button variant="secondary" size="small" onClick={onClickUnsnooze}>
        {t("activityDetails.feedItems.snoozeActivity.unsnoozeButton", { ns: "activities" })}
      </Button>
    </SecondaryActivity>
  ) : (
    <SecondaryActivity variant={SecondaryType.Base} creator={creator} countryCode={countryCode} />
  );
};

export default Snoozed;

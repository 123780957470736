import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { CaretUp } from "../../../../../../library/Icons/Icons";
import BaseActivity from "../BaseActivity/BaseActivity";
import { AccordionActivityProps } from "../SecondaryActivityTypes";
import "./AccordionActivity.scss";
import styles from "../../../../../../../css/_export.module.scss";

const resetActivityAccordionStyles = makeStyles(() => ({
  "MuiAccordion-root": {
    boxShadow: "none",
    "&::before": {
      backgroundColor: "none",
      opacity: 0,
    },
    "& .MuiAccordionSummary-root": {
      paddingRight: 0,
      paddingLeft: 0,
      minHeight: 0,
      backgroundColor: "grey98",
      "& .MuiAccordionSummary-content": {
        marginTop: 0,
        marginBottom: 0,
        minHeight: 0,
        backgroundColor: styles.grey98,
      },
    },
    "& .MuiCollapse-root": {
      backgroundColor: styles.grey98,
      "& .MuiAccordionDetails-root": {
        padding: "0 0 0 0",
        backgroundColor: styles.grey98,
      },
    },
  },
}));

const AccordionActivity: React.FC<AccordionActivityProps> = ({ creator, flags, children, headerActions, countryCode }) => {
  const [isExpanded, setExpanded] = React.useState<boolean>(true);
  return (
    <Accordion disableGutters className={resetActivityAccordionStyles()["MuiAccordion-root"]} defaultExpanded>
      <AccordionSummary onClick={() => setExpanded(!isExpanded)}>
        <div className="accordion-summary">
          <div className={`accordion-caret-icon ${isExpanded ? "expanded" : "close"}`}>
            <CaretUp />
          </div>
          <div className="base-wrapper">
            <BaseActivity creator={creator} flags={flags} headerActions={headerActions} countryCode={countryCode} />
          </div>
        </div>
      </AccordionSummary>
      {/* children enables flexibility to have n-number of 
        variations if can have variation as well such as -
        - only text
        - text + call-to-action button 
        - only call-to-action button
      */}
      <AccordionDetails>
        <div className="accordion-content">{children}</div>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionActivity;

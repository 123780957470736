import React from "react";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";
import { AppContext } from "../../../contexts/AppContext";
import _ from "underscore";
import { useQuery } from "@tanstack/react-query";
import { RefetchIntervals } from "../../../constants/CacheConfig";
import { FallbackTypes } from "../../../types/enums";
import SidebarHead from "./SidebarHead";
import SidebarLinks from "./SidebarLinks";
import { DEFAULT_NUMERIC_VALUES } from "../../../constants/NumericConstants";

import "./EInvoiceSidebar.scss";
import { eInvoicesClient } from "../../../db/version3Accessor";

const EInvoiceSidebar = (): React.ReactElement => {
  const { workspaceHomePath, selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { userStatus } = React.useContext(AppContext) as AppType;

  /**
   * @constant cachedEInvoicesCount
   * EInvoice Count cache key setup
   * Cache key being scoped on workspace_type, id
   */
  const cachedEInvoicesCount = [`${selectedWorkspace?.workspace_type}-${selectedWorkspace?.id}`];
  const { data: { data: count } = {} } = useQuery(cachedEInvoicesCount, async () => eInvoicesClient.getEInvoiceStreamMeta(selectedWorkspace?.id || FallbackTypes.Id), {
    refetchInterval: RefetchIntervals.megaMenu,
  });


  if (_.isNull(userStatus) && _.isUndefined(userStatus)) {
    return <></>;
  }

  const companyLogoUrl = userStatus?.user_groups?.filter((userGroup) => userGroup?.group_key === userStatus?.group_key)?.[
    DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
  ]?.company_logo_url;

  return (
    <div className="e-invoice-sidebar-container">
      <SidebarHead companyLogoUrl={companyLogoUrl ?? ""} accountName={userStatus?.account_name ?? ""} />
      <div className="nav-links-container">
        <SidebarLinks workspaceHomePath={workspaceHomePath} einvoicesCount={count} />
      </div>
    </div>
  );
};
export default EInvoiceSidebar;

import React from "react";

// Create Context
export const NotesContext = React.createContext<NotesType | null>(null);

// Define ActivitiessProvider Props
export interface Props {
  children: (React.ReactNode & { type: string })[] | (React.ReactNode & { type: string });
}

const NotesProvider: React.FC<Props> = ({ children }: Props) => {
  return <NotesContext.Provider value={{}}>{children}</NotesContext.Provider>;
};

export default NotesProvider;

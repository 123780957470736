export const CUSTOM_ACTIVITY_ADD_NOTES_CONSTANTS = Object.freeze({
  SKELETON_INNER_HEIGHT: 359,
  SKELETON_INNER_DIVIDER: 68,
});

export const CUSTOM_ACTIVITY_ADD_PHONE_CONSTANTS = Object.freeze({
  SKELETON_INNER_HEIGHT: 359,
  SKELETON_INNER_DIVIDER: 68,
});

export const CUSTOM_ACTIVITY_FEED_CONSTANTS = Object.freeze({
  SKELETON_INNER_HEIGHT: 254,
  SKELETON_INNER_DIVIDER: 135,
});

export const CUSTOM_ACTIVITY_FEED_ITEM_CONSTANTS = Object.freeze({
  IFRAME_HEIGHT: 80,
  IFRAME_LIMIT_HEIGHT: 5,
  BASE: 16,
  HALF_REM: 0.5,
  PADDING: 25,
  FONTSIZE: 14,
  LINEHEIGHT: 20,
});

export const CUSTOM_ACTIVITY_REASSIGN_CONSTANTS = Object.freeze({
  SKELETON_INNER_HEIGHT: 462,
  SKELETON_INNER_DIVIDER: 68,
});

export const CUSTOM_ACTIVITY_WINDOW_CONSTANTS = Object.freeze({
  ACTIVITY_RECIPIENT_FIELD_HEIGHT: 78,
  ACTIVITY_BODY_HEIGHT: 536,
});

export const CUSTOM_BARCHART_CONSTANTS = Object.freeze({
  BORDER_RADIUS: 15,
  LEFT_MARGIN: 16,
  LEGEND_TEXT_PROGRESS_BAR_GAP: 12,
  LEFT_AXIS_TICK_PADDING: 12,
  RESPONSIVE_BAR_MAX_VAL: 30,
});

export const CUSTOM_DETAILS_CONSTANTS = Object.freeze({
  TOOLTIP_LEFT: 340,
});

export const CUSTOM_DONUT_CHART = Object.freeze({
  COLOR_MODIFIER_DARK: 0.2,
  COLOR_MODIFIER_DARKER: 3,
});

export const POPUP_WINDOW_CONSTANTS = Object.freeze({
  HEIGHT: 800,
  WIDTH: 900,
});

export const STYLED_CONSTANTS = Object.freeze({
  BORDER_RADIUS: 10,
  CHILDREN_OFFSET: 24,
});

export const DETAIL_SKELETON_DEFAULT_SIZING = Object.freeze({
  TAG_WIDTH: 40,
  TAG_HEIGHT: 40,
  TITLE_WIDTH: 80,
  TITLE_HEIGHT: 16,
  TEXT_WIDTH: 144,
  TEXT_HEIGHT: 20,
});

export const CUSTOM_SKELETON_DEFAULT_SIZING = Object.freeze({
  HEAD_WIDTH: 66,
  HEAD_HEIGHT: 16,
  BODY_HEIGHT: 20,
  BODY_WIDTH: 160,
});

export const PERCENTAGE_CONSTANTS = Object.freeze({
  EIGHTY_PERCENT: 80,
});

export const LOCKSTEP_DIMENSIONS = Object.freeze({
  WIDTH: 1440,
  HEIGHT: 900,
});

export const DASHBOARD_STYLES = Object.freeze({
  LOADER_SIZE: 120,
});

export const ACTIVITY_DETAILS_SKELETON_CONSTANTS = Object.freeze({
  HEADER: {
    COMMON_HEIGHT: 24,
    SUBJECT_WIDTH: 288,
    OTHER_WIDTH: 144,
  },
  ITEM: {
    HEAD: { ICON_WIDTH: 40, ICON_HEIGHT: 40 },
    BODY: {
      HEADER_WIDTH: 40,
      HEADER_HEIGHT: 20,
      SUBHEADER_WIDTH: 96,
      SUBHEADER_HEIGHT: 16,
      DESC_HEIGHT: 50,
    },
  },
});

export const CONTACT_DETAIL_HEADER_SKELETON_CONSTANTS = Object.freeze({
  HEAD_HEIGHT: 24,
  WRAPPER_WIDTH: 288,
  CUSTOMER_WIDTH: 144,
});

export const PAYABLES_COMING_DUE_HEADER_SKELETON_CONSTANTS = Object.freeze({
  WIDTH: 96,
  HEIGHT: 20,
});

export const AR_HEADER_SKELETON_CONSTANTS = Object.freeze({
  WIDTH: 96,
  HEIGHT: 20,
});

export const AP_HEADER_SKELETON_CONSTANTS = Object.freeze({
  WIDTH: 96,
  HEIGHT: 20,
});

export const NAVBAR_SKELETON_CONSTANTS = Object.freeze({
  WIDTH: 96,
  HEIGHT: 24,
});

export const MY_PROFILE_SKELETON_CONSTANTS = Object.freeze({
  PROFILE_IMG: {
    RADIUS: 80,
  },
  PROFILE_UPLOAD: {
    WIDTH: 110,
    HEIGHT: 25,
  },
  PROFILE_REMOVE: {
    WIDTH: 147,
    HEIGHT: 25,
  },
});

export const MY_PROFILE_HEADER_SKELETON_CONSTANTS = Object.freeze({
  PROFILE_IMG: {
    RADIUS: 40,
  },
  PROFILE_UPLOAD: {
    WIDTH: 110,
    HEIGHT: 25,
  },
  PROFILE_REMOVE: {
    WIDTH: 147,
    HEIGHT: 25,
  },
  EDIT_BTN: {
    WIDTH: 40,
    HEIGHT: 30,
  },
});

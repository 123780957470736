import React from "react";
import { OnboardingCompletedProps } from "../../ActivityItemVariantTypes";
import "./OnboardingCompleted.scss";
import SecondaryActivity from "../../../SecondaryActivity/SecondaryActivity";
import { SecondaryType } from "../../../SecondaryActivity/SecondaryActivityTypes";
import { DATE_CONSTANTS_IN_MIN, NUMERIC_VALUES } from "../../../../../../../../constants/NumericConstants";
import { formatDate, getDisplayDateFormat } from "../../../../../../../../db/utils/date";

const OnboardingCompleted: React.FC<OnboardingCompletedProps> = ({ creator, countryCode }) => {
  const creatorNew = { ...creator, message: "Vendor had been Successfully onboarded" };
  const isAfter24hrs = creator.createdOn
    ? (new Date().getTime() - new Date(creator.createdOn).getTime()) / DATE_CONSTANTS_IN_MIN.HRS_IN_MILIS > NUMERIC_VALUES.CONSTANT_TWENTY_FOUR
    : false;

  return (
    <>
      {!isAfter24hrs ? (
        <div className="boarding-completed activity-feed-item-card">
          <div className="boarding-completed-icon">👍</div>
          <div className="boarding-completed-body">
            <h3 className="boarding-completed-body-header">Activity Complete!</h3>
            <p className="boarding-completed-body-text">
              Your requested <span className="highlight">vendor profile</span> have been onboarded successfully on{" "}
              {formatDate(creator.createdOn, getDisplayDateFormat(countryCode ?? ""))}.
            </p>
          </div>
        </div>
      ) : (
        <SecondaryActivity variant={SecondaryType.Base} creator={creatorNew} countryCode={countryCode} />
      )}
    </>
  );
};

export default OnboardingCompleted;

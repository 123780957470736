import { Checkbox, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React from "react";
import { TableDataContext, TableDataProviderType } from "../../contexts/TableDataContext";
import { ColumnConfigs } from "../../types/table.types";
import { ReactComponent as SortIconAsc } from "../../../svg/sorting_ascending.svg";
import { ReactComponent as SortIconDesc } from "../../../svg/sorting_descending.svg";
import { ReactComponent as SortIconInactive } from "../../../svg/sorting_disabled.svg";
import { ReactComponent as DownloadIcon } from "../../../svg/download_document.svg";
import { IconContainer, TableCellHeaderContainer } from "./header.styles";
import { InfoIcon } from "../../../atomic/InfoIcon";
import { RowDataTypes, TableFilterVariants } from "../../../constants/table.constants";
import { filterVariants } from "../../../TableFilters";
import { NUMERIC_VALUES } from "../../../constants/numeric.constants";

const TableHeader = () => {
  const {
    tableConfigs: tableConfig,
    selectionConfigs: selectionConfig,
    columnConfigs: columnConfig,
    filterConfigs: filterConfig,
    sortConfigs: sortConfig,
    currencyConfig,
  } = React.useContext(TableDataContext) as TableDataProviderType;

  const iconGetter = (type: string) => {
    switch (type) {
      case RowDataTypes.DownloadDoc:
        return (
          <IconContainer>
            <DownloadIcon />
          </IconContainer>
        );
      default:
        return <></>;
    }
  };

  const getSortIcon = (key: string) => {
    if (sortConfig?.sort.active && sortConfig?.sort.sortField === key) {
      return sortConfig?.sort.sortBy === "asc" ? <SortIconAsc /> : <SortIconDesc />;
    }
    return <SortIconInactive />;
  };

  const hasCurrentPageAllRowsSelected = () =>
    tableConfig?.rows?.every((item) => selectionConfig?.tableRecords?.selectedRecordsIds?.includes(item?.id));

  const hasCurrentPageFewRowsSelected = () =>
    tableConfig?.rows?.some((item) => selectionConfig?.tableRecords?.selectedRecordsIds?.includes(item?.id));

  return (
    <TableHead className="table-header" sx={{ boxShadow: "0px 2.5px 2.5px -1.5px rgba(0, 0, 0, 0.15)" }}>
      <TableRow>
        <TableCell align="center" padding="checkbox">
          <Checkbox
            disableRipple={true}
            disabled={tableConfig?.loading}
            checked={tableConfig?.rows?.length > NUMERIC_VALUES.CONSTANT_ZERO && hasCurrentPageAllRowsSelected()}
            indeterminate={
              selectionConfig?.tableRecords.selectedRecordsIds?.length > NUMERIC_VALUES.CONSTANT_ZERO &&
              hasCurrentPageFewRowsSelected() &&
              !hasCurrentPageAllRowsSelected() &&
              !selectionConfig?.isAllRecordsSelected
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              selectionConfig?.onClickSelectAllCheckbox(
                tableConfig?.rows?.map((row) => row?.id),
                (event.target as HTMLInputElement).checked
              );
            }}
          />
        </TableCell>
        {columnConfig?.map((config: ColumnConfigs, index: number) => (
          <TableCell
            key={`${config.name}**${index}`}
            width={config?.width}
            style={{
              zIndex: filterConfig?.filterControls?.open === config.accessor ? NUMERIC_VALUES.CONSTANT_THREE : NUMERIC_VALUES.CONSTANT_TWO,
              cursor: !(config.enableSort || config.enableFilter) || tableConfig?.loading || config.disableHover ? "default" : "pointer",
            }}
          >
            <TableCellHeaderContainer isHoverDisabled={!(config?.enableSort ?? config?.enableFilter) ?? config?.disableHover}>
              <>
                <div
                  className="table-header-cell-label"
                  style={{
                    justifyContent: config?.columnHeaderAlign === "center" ? "center" : config?.columnHeaderAlign === "right" ? "end" : "start",
                    cursor: !(config.enableSort || config.enableFilter) || tableConfig?.loading || config.disableHover ? "default" : "pointer",
                  }}
                  onClick={!tableConfig?.loading && config.enableSort ? sortConfig?.sortClick?.bind(this, config) : undefined}
                >
                  {config?.enableSort && <span className={`table-sortIcon hide-by-default`}>{getSortIcon(config?.accessor)}</span>}
                  <Typography variant="caption">{config.icon || config.name}</Typography>
                  {config?.enableIcon && iconGetter(config?.rowDataType ?? "")}
                  {config?.enableInfoIcon && <InfoIcon title={config?.infoIconLabel || ""} placement={config?.infoIconPlacement || "right"} />}
                </div>
                {config?.enableFilter && !tableConfig?.loading && (
                  <div className="table-header-filter">
                    {(() => {
                      const Filter = filterVariants({
                        id: config?.filterType ?? TableFilterVariants.DROPDOWN_FILTER,
                      });

                      /* By default behavior of Numeric Filter is the type of amount if currencyConfig is available or numericFilterType==="amount"
                       * else if numericFilterType value is "number" then Numeric Filter behave as a pure number filter
                       */
                      const currencyValue = config?.numericFilterType === "number" ? undefined : currencyConfig;
                      return (
                        <Filter
                          filterValue={filterConfig?.activeFilters?.[config.accessor]?.value}
                          filterKey={config.accessor}
                          filterComparator={filterConfig?.activeFilters?.[config.accessor]?.comparator}
                          filterControls={{
                            open: filterConfig?.filterControls?.open === config.accessor,
                            toggleOpen: filterConfig?.filterControls?.toggleOpen,
                          }}
                          filterName={config?.name}
                          viewType={tableConfig?.name + (tableConfig?.rows?.length > NUMERIC_VALUES.CONSTANT_ZERO ? "s" : "")}
                          onChangeHandler={filterConfig?.setFilter}
                          onClearHandler={filterConfig?.clearFilter}
                          dateFormat={filterConfig?.dateFormat}
                          currencyData={currencyValue}
                          filterAlignment={config.filterAlignment}
                          filterRangeValue={filterConfig?.activeFilters?.[config.accessor]?.rangeValue}
                          updateFiltersSearchedText={filterConfig?.updateFiltersSearchedText}
                          searchedTextValue={filterConfig?.filterSearchedText?.[config?.accessor]?.value}
                          dropdownListItems={config?.dropdownListItems}
                          dropdownSearchListItems={config?.dropdownSearchListItems}
                          searchlightField={config?.searchlightField}
                          isSuggestionDataLoading={filterConfig?.isSuggestionDataLoading}
                          dropdownSearchVariant={config?.dropdownSearchVariant}
                          numericFilterType={config?.numericFilterType}
                          placeholder={config?.placeholder}
                          addSearchSuggestionHistory={filterConfig?.addSearchSuggestionHistory}
                          category={filterConfig?.category}
                          workspace={filterConfig?.workspace}
                          isNoSearchResultFound={filterConfig?.isNoSearchResultFound}
                        />
                      );
                    })()}
                  </div>
                )}
              </>
            </TableCellHeaderContainer>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;

import React from "react";
import { ButtonMaster, ButtonMasterProps } from "../../../../../../../library/AtomicComponents/atomic/Button/button.styles";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { ButtonProps } from "@mui/material/Button";
import { ReactComponent as Close } from "../../../../../../../../assets/Close.svg";
import { Select, Input, MenuItem } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { CheckList } from "../../../../../../../library/Icons/Icons";
import { NUMERIC_VALUES } from "../../../../../../../../constants/NumericConstants";
import CircularProgress from "@mui/material/CircularProgress";

import "./ActionGenerator.scss";

export type ActionButton = {
  label: string;
  variant: ButtonMasterProps["btnVariant"];
};

export type FormItemVariant = {
  variant: "text" | "dropdown";
};

export type ActionConfirmationForm = {
  label: string;
  id: number;
  inputIdentifier: string;
  type: FormItemVariant["variant"];
  required: boolean;
  options?: string[];
};

export type ActionDefinition = {
  actionConfig: {
    action_label: string;
    action_type: string;
    action_variant: ButtonMasterProps["btnVariant"];
    confirmation?: boolean;
    confirmation_options?: {
      title: string;
      subtitle?: string;
      form: ActionConfirmationForm[];
      buttons: ActionButton[];
    };
    loading?: boolean;
    disabled?: boolean;
  };
  callback: (callbackConfig: ActionState | ActionDefinition["actionConfig"]) => void;
};

export type ActionStateValue = {
  value: string;
  required: boolean;
  inputIdentifier: string;
};

export type ActionState = {
  [key: number]: ActionStateValue;
};

const modalBoxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 548,
  padding: "1.5rem",
  alignItems: "center",
  gap: "1.5rem",
  borderRadius: "0.5rem",
  border: "0.0625rem solid  #EEE",
  background: "#FFF",
};

/**
 * @function withEnhancement
 * A react functional Higher Order Component (HOC) to decorate and render an action with UI dependencies,
 * @param WrappedComponent
 * @returns
 */
const withEnhancement = (WrappedComponent: React.FC<ActionDefinition & ButtonProps>) => {
  const WithEnhancement: React.FC<ActionDefinition & ButtonProps> = (props) => {
    const [modalOpen, setModalOpen] = React.useState<boolean>(false);
    const actionConfig = props?.actionConfig;
    /**
     * Construct state dynamically for the form config received by using a Map-Reduce
     */
    const defaultState = actionConfig?.confirmation_options?.form?.reduce(
      (acumulator: any, current: ActionConfirmationForm) => ({
        ...acumulator,
        [current?.id]: { value: "", required: current?.required, inputIdentifier: current?.inputIdentifier },
      }),
      {}
    );
    const [formState, setFormState] = React.useState<ActionState>(defaultState);
    const [isFormInvalid, setIsFormInvalid] = React.useState<boolean>(true);

    const onCloseModal = () => {
      setModalOpen(false);
      setFormState(defaultState);
      setIsFormInvalid(true);
    };

    const onConfirm = () => {
      if (actionConfig?.confirmation) {
        props?.callback({ ...formState, ...actionConfig });
        onCloseModal();
      } else {
        props?.callback(actionConfig);
      }
    };

    React.useEffect(() => {
      if (modalOpen) {
        const hasInvalidValue =
          formState &&
          Object.values(formState)?.filter((item: ActionStateValue) => item && item.required && item.value === "")?.length >
            NUMERIC_VALUES.CONSTANT_ZERO;
        setIsFormInvalid(hasInvalidValue);
      }
    }, [formState, modalOpen]);

    if (actionConfig?.confirmation) {
      return (
        <>
          <Modal open={modalOpen} onClose={onCloseModal} className="states-action-confirmation-modal">
            <Box sx={{ ...modalBoxStyle }}>
              <div className="modal-header">
                <p className="modal-title">{actionConfig?.confirmation_options?.title}</p>
                <span onClick={onCloseModal}>
                  <Close />
                </span>
              </div>
              <div className="modal-body">
                <form
                  onSubmit={(event: React.SyntheticEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    if (!isFormInvalid) {
                      onConfirm();
                    }
                  }}
                >
                  {actionConfig?.confirmation_options?.subtitle && <p className="subtitle">{actionConfig?.confirmation_options?.subtitle}</p>}
                  {actionConfig?.confirmation_options?.form?.map((item: ActionConfirmationForm, index: number) => {
                    if (item?.type === "text") {
                      return (
                        <div key={index} className={`form-item-${item?.type}`}>
                          {item?.label ? <p>{item?.label}</p> : null}
                          <Input
                            className="form-item-input-field"
                            onChange={(e) => {
                              const state = { ...formState };
                              state[item?.id].value = e.target.value as string;
                              setFormState(state);
                            }}
                            value={formState[item.id].value}
                          />
                        </div>
                      );
                    }
                    if (item?.type === "dropdown") {
                      return (
                        <div key={index} className={`form-item-${item?.type}`}>
                          {item?.label ? <p>{item?.label}</p> : null}
                          <Select
                            id="form-item-dropdown"
                            onChange={(e) => {
                              const state = { ...formState };
                              state[item?.id].value = e.target.value as string;
                              setFormState(state);
                            }}
                            className={"form-item-select"}
                            placeholder="Select Refusal Reason"
                            value={formState[item.id].value || "Select Refusal Reason"}
                            autoWidth
                            MenuProps={{
                              sx: { height: 413, minWidth: 400 },
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              className: "form-item-select-root",
                            }}
                            IconComponent={KeyboardArrowDown}
                            renderValue={(selected) => selected}
                          >
                            {item?.options?.map((item: string, key: number) => {
                              const itemLabel = item || "";
                              return (
                                <MenuItem key={key} value={item} className="form-item-select-list">
                                  <Box>{item === formState[index + NUMERIC_VALUES.CONSTANT_ONE].value && <CheckList />}</Box>
                                  <div className="label-overflow">{itemLabel}</div>
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>
                      );
                    }
                  })}
                </form>
              </div>
              <div className="modal-footer">
                {actionConfig?.confirmation_options?.buttons?.map((button: ActionButton, index: number) => {
                  return (
                    <ButtonMaster key={index} disabled={isFormInvalid} btnVariant={button.variant} onClick={onConfirm}>
                      {button.label}
                    </ButtonMaster>
                  );
                })}
              </div>
            </Box>
          </Modal>
          <WrappedComponent onClick={() => setModalOpen(true)} {...props} />
        </>
      );
    }
    return <WrappedComponent {...props} onClick={onConfirm} />;
  };

  return WithEnhancement;
};

/**
 * @function Action
 * A react functional component to render the base action button with a callback.
 * @returns
 */
const Action: React.FC<ActionDefinition & ButtonProps> = ({ actionConfig, onClick }) => {
  return (
    <ButtonMaster disabled={actionConfig?.loading || actionConfig?.disabled} btnVariant={actionConfig.action_variant} onClick={onClick}>
      {actionConfig?.loading && (
        <CircularProgress size={14} sx={{ marginRight: "8px", color: actionConfig.action_variant === "primary" ? "white" : "#757575" }} />
      )}
      {actionConfig.action_label}
    </ButtonMaster>
  );
};

export default withEnhancement(Action);

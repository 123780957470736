/*eslint-disable*/
import React from "react";
import { styled } from "@mui/system";
import { pxToRem } from "../../../theme/theme";
import { Button, IconButton } from "@mui/material";

export const HeaderButton = styled(Button, {
  name: "button",
  slot: "container",
})({
  "&&": {
    backgroundColor: "#FFFFFF",
    color: "#124564",
    borderRadius: pxToRem(8),
    textTransform: "Capitalize",
    padding: `${pxToRem(10)} ${pxToRem(12)}`,
    transition: "none",
    marginLeft: pxToRem(4),
    "&.inactive": {
      background: "#124564",
      color: "#FFFFFF",
      " > .MuiButton-startIcon": {
        "& svg > path": {
          fill: "#FFFFFF",
        },
      },
    },
    // button icon styles
    "> .MuiButton-startIcon": {
      marginLeft: 0,
      marginRight: 0,
      "& svg": {
        width: pxToRem(18),
        height: pxToRem(18),
        "& path": {
          fill: "#124564",
        },
      },
    },
    // button hover states
    "&:hover": {
      background: "#8592AD",
      color: "#FFFFFF",
      " > .MuiButton-startIcon": {
        "& svg > path": {
          fill: "#FFFFFF",
        },
      },
    },

    // button text styles
    "& .MuiTypography-body1": {
      marginLeft: pxToRem(8),
    },
  },
  // icon + text button states
}) as typeof Button;

export const HeaderIconButton = styled(IconButton, {
  name: "container",
})({
  // icon button states
  "&&": {
    borderRadius: pxToRem(8),
    marginLeft: 0,
    marginRight: 0,
    padding: `${pxToRem(10)} ${pxToRem(10)}`,
    "& svg": {
      width: pxToRem(22),
      height: pxToRem(22),
      "& path": {
        fill: "#FFFFFF",
        width: pxToRem(22),
        height: pxToRem(22),
      },
    },
  },
  // button hover states
  "&:hover": {
    background: "#8592AD",
  },
});

export const TableHoverButton = styled(IconButton, {
  name: "container",
})({
  // icon button states
  "&&": {
    borderRadius: pxToRem(8),
    marginLeft: 0,
    marginRight: 0,
    padding: `${pxToRem(10)} ${pxToRem(10)}`,
    "& svg": {
      width: pxToRem(22),
      height: pxToRem(22),
      "& path": {
        fill: "#8592AD",
        width: pxToRem(22),
        height: pxToRem(22),
      },
    },
  },
  // button hover states
  "&:hover": {
    background: "#EEF0F6",
    "& svg": {
      "& path": {
        fill: "#8592AD !important",
      },
    },
  },
  "&:active": {
    background: "#8592AD",
    "& svg": {
      "& path": {
        fill: "#FFFFFF !important",
      },
    },
  },
  "&:disabled": {
    background: "transparent",
    "& svg": {
      "& path": {
        fill: "#BDC3DB",
      },
    },
  },
});

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import _ from "underscore";
import { DEFAULT_NUMERIC_VALUES } from "../../../../../constants/NumericConstants";
import {
  ActivityOptions,
  ActivityType,
  AuditLogType,
  CompanyType,
  EmailAction,
  EmojiActionIds,
  EmojiOptions,
  InlinePrompts,
} from "../../../../../types/enums";
import Utils from "../../../../../utils/utils";
import ViewOriginalEmail from "./PrimaryActivity/Helpers/ViewOriginalEmail/ViewOriginalEmail";

import "./ActivityFeedItem.scss";
import { useHistory, useLocation } from "react-router";
import ActivityFeedItemComponent from "./ActivityFeedItemComponent";
import { ItemHandlers } from "./ActivityFeedItemTypes";

export type ActivityFeedItemVariant = ActivityType | AuditLogType | InlinePrompts;

export type ActivityFeedItemProps = {
  isEnabled: any;
  header?: string;
  to: ContactItem[];
  cc?: ContactItem[];
  bcc?: ContactItem[];
  date?: string;
  content: string;
  isRead: boolean;
  isStreamSpam: boolean;
  variant: ActivityFeedItemVariant;
  icon?: React.ReactNode;
  creator?: any;
  from?: any;
  attachments: any[];
  emailType?: string;
  retryEmail?: (activityId: string, to: ContactItem[], content: string) => void;
  originMessageContent?: string;
  id: string;
  originalStreamId?: string;
  originalStreamItemId?: string;
  setActionType?: (showNewActivity: string) => void;
  setActivityItemId?: (id: string) => void;
  setNewActivityType?: (type: string) => void;
  enableForwardAPAutomationBtn?: boolean;
  handleUnsnoozeStream: () => void;
  preview?: string;
  snoozedUntil?: number;
  simple_content?: string;
  activityId: string;
  status: string;
  type: string;
  isNudgeActivity?: boolean;
  isSnoozeApplicable: boolean;
  isSpamApplicable: boolean;
  handleNudge?: () => void;
  order: number;
  timeSaved?: Partial<AutomateTimeModel>;
  handleApprovalReview?: (reviewAction: ApprovalReviewAction) => void;
  getOriginalEmail?: (itemId: string) => any;
  readonly?: boolean;
  primaryConnection?: Record<string, any>;
  handleNotSpam: () => void;
  assigneeName: string | undefined;
  magicLink?: string | undefined;
  bounceErrorMsg?: string;
  retried: boolean | null,
  flag: string;
  enableKeyboardShortcut?: boolean;
  handleResend: (id: string) => void;
  isResendApplicable?: boolean;
  isApForwardEnabled: boolean;
  isFeatureEnabled: (arg0: string) => boolean | undefined | null;
  isShowPrimaryProfileShareOrAPAutomate?: boolean;
  showAPOrARProfile?: boolean;
  curentActivePopperActivityOrder: number | null;
  setActivePopperActivityOrder: (order: number | null) => void;
  selectedWorkspace: UserItem;
  handleReactionUpdate: (reactionType: EmojiOptions, selector: EmojiActionIds, activityId: string, id: string) => void;
  reactions: Array<{ user_name: string; user_id: number; id: string; reaction_type: string }>;
  activityReaction: {
    [key: string]: {
      count: number;
      givers: Array<{ name: string; id: string; user_id: number }>;
      highlight: boolean;
    };
  };
  isActivityReactionsSupportEnabled?: boolean;
  isExpanded?: boolean;
  handleDismissAutodetectedFlag: (id: string, flag: string) => boolean;
  countryCode: string;
  subject?: string;
  handleCopyNote?: (note: string) => void;
  connectionViaRule?: boolean;
  markedConfirmed?: boolean;
  onClickDownload: (
    event: React.MouseEvent<HTMLTableRowElement | HTMLDivElement | HTMLButtonElement | HTMLAnchorElement | HTMLSpanElement>,
    attachments: AttachmentItem[] | BlobAttachmentItem[]
  ) => void;
  handleSendForApproval: () => void;
  handleMarkSenderAsSpam: (id: string) => void;
  handleMarkSenderAsFraud: (id: string) => void;
  isEInvoicingRoute?: boolean;
  mergedToConnectionName: string;
  movedToConnectionName: string;
  isApprovalActive?: boolean;
  isAutoClosed?: boolean;
};

/**
 * !WARNING!
 *
 * 1) Because this component is being used with MSAL as well as /external Magic
 * Authentication in Approval Activity. It does not support any context
 * implementation (or useContext hook). Please do not import or write api calls
 * directly inside this component, DOING SO WILL BREAK THE IMPLEMENTATION.
 * If you need, this will has to be passed in props either as a handler function
 * or a boolean flag from ActivityFeed.tsx OR ApprovalActivityComponent.tsx
 * OR anyother parent component that implements this.
 *
 * NOTE:
 * To implement feature flags use - isFeatureEnabled prop on baseProps object.
 *
 * 2) Whenever adding any onClick event to any Activity feed Item child component
 * please add an e.stopPropagation() to prevent the event from bubbling and
 * triggering read more read less functionality.
 *
 */
export function ActivityFeedItem(props: ActivityFeedItemProps): React.ReactElement {
  const [openOriginalEmail, setoOriginalEmailVisiblity] = useState(false);
  const [originalEmailContent, setOriginalEmailContent] = useState({ subject: "", body: "" });

  const location = useLocation();
  const history = useHistory();
  const snoozedDate = Utils.evaluateDate(props.snoozedUntil ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);

  const toggleOriginalEmail = () => {
    setoOriginalEmailVisiblity(!openOriginalEmail);
  };

  /**
   * opens up editor with attachments and content with activity
   * type provided as actionType
   * @param actionType reply, reply_all, forward
   */
  const handleAction = (actionType: string) => {
    props.setActionType?.(actionType);
    // default reply, reply_all and forward activity type
    props.setNewActivityType?.("email");
    props.setActivityItemId?.(props.id);
  };

  /**
   * redirects the stream to original/forwarded
   */
  const redirectForwardedStream = () => {
    history.push({
      pathname: `${location.pathname.slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, location.pathname.lastIndexOf("/"))}/${props?.originalStreamId}`,
      state: {
        itemId: props.originalStreamItemId,
      },
    });
  };

  /**
   * opens up editor with attachments and content with activity
   * type as forward ap automation
   */
  const sendApAutomationMail = () => {
    props.setActionType?.("reply");
    // default reply, reply_all and forward activity type
    props.setNewActivityType?.(EmailAction.FORWARD_AP_AUTOMATION);
    props.setActivityItemId?.(props.id);
  };

  /**
   * will return comma separated values of the emails bounced
   */
  const getBouncedEmailArray = (to: ContactItem[]): Array<any> => {
    const emailArray = to.map((item) => item?.email_address);

    return [emailArray.join(", ")];
  };

  /**
   * To set and reset Reactions for Activity
   */

  const handleUserReaction = (reactionType: EmojiOptions, selector: EmojiActionIds) => {
    props.handleReactionUpdate(reactionType, selector, props.activityId, props.id);
  };

  /**
   * base props for primary and secondary activity
   */
  const baseProps = {
    order: props.order,
    id: props.id,
    creator: {
      name: props.creator?.name || props.from?.name || props.to?.[0]?.name,
      message: props.header ?? "",
      createdOn: props.date ?? "",
    },
    icon: "",
    attachments: {
      items: props.attachments,
      onClickForwardToAutomation: sendApAutomationMail,
    },
    contacts: {
      to:
        props.variant === ActivityType.PhoneCall
          ? [props.from]
          : props.variant === ActivityType.BouncedEmail
          ? getBouncedEmailArray(props.to)
          : props.to, // for phone variant to should be from
      cc: props.cc,
      bcc: props.bcc,
      from: props.from,
      creator: props.creator,
    },
    content: {
      HTMLEmailContent: props.content,
      preview: props.preview,
    },
    isRead: props.isRead,
    readonly: props.readonly,
    magicLink: props.magicLink,
    flag: props.flag,
    enableKeyboardShortcut: props.enableKeyboardShortcut,
    isApForwardEnabled: props.isApForwardEnabled,
    isFeatureEnabled: props.isFeatureEnabled,
    curentActivePopperActivityOrder: props.curentActivePopperActivityOrder,
    setActivePopperActivityOrder: props.setActivePopperActivityOrder,
    selectedWorkspace: props.selectedWorkspace,
    reactions: props.activityReaction,
    handleUserReaction,
    isEmojiSupportEnabled: props.isActivityReactionsSupportEnabled,
    isExpanded: props.isExpanded,
    subject: props.subject,
    onClickDownload: props.onClickDownload,
    isApprovalActive: props?.isApprovalActive,
  };

  /**
   * handlers to enable various actions on feed items
   */
  const handlers: ItemHandlers = {
    /**
     * reply options contain - reply, reply all, forward
     * and open email in original view
     */
    reply: {
      onClickViewOriginalEmail: async () => {
        const email = await props.getOriginalEmail?.(props.id);
        setOriginalEmailContent({ subject: email.data.subject, body: email.data.content });
        toggleOriginalEmail();
      },
      onClickReplyAll: () => handleAction(EmailAction.REPLY_ALL),
      onClickForward: () => handleAction(EmailAction.FORWARD),
      onClickReply: () => handleAction(EmailAction.REPLY),
    },
    /** bounce email require retry send functionality */
    bounce: {
      onClickRetrySend: () => {
        props.retryEmail?.(props.id, props.to, props.originMessageContent ?? "");
      },
    },
    /** approval request/redirect need review options
     * as well as nudge as 'send a reminder' functionality
     */
    approval: {
      onClickApprove: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        props.setActivityItemId?.(props.id);
        props.handleApprovalReview?.("approve");
      },
      onClickDecline: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        props.setActivityItemId?.(props.id);
        props.handleApprovalReview?.("decline");
      },
      onClickNudge: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        props.handleNudge?.();
      },
      onClickCancel: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        props.setActivityItemId?.(props.id);
        props.handleApprovalReview?.("cancel");
      },
    },
    /**
     * nudge again functionality handler
     */
    nudge: {
      onClickNudge: () => props.handleNudge?.(),
    },
    /**
     * unsnooze functionality handler
     */
    snooze: {
      onClickUnsnooze: () => props.handleUnsnoozeStream?.(),
    },
    /**
     * see original or forwarded stream functionality handler
     */
    forward: {
      onClickRedirect: () => redirectForwardedStream?.(),
    },
    /**
     * mark activity as not spam functionality handler
     */
    spam: {
      onClickNotSpam: () => props.handleNotSpam?.(),
    },
    /**
     * resend call to action on ap automation activity
     */
    onboardVendor: {
      onClickOnboard: (companyType) => {
        props.setActionType?.(EmailAction.NEW);
        props.setNewActivityType?.(
          companyType === CompanyType.VENDOR.toLocaleLowerCase() ? ActivityOptions.ONBOARD_VENDOR : ActivityOptions.REQUEST_CUSTOMER_PROFILE
        );
        props.setActivityItemId?.(props.id);
      },
      onClickDismissFlag: () => {
        return props.handleDismissAutodetectedFlag(props.id, "new_vendor");
      },
    },
    /** */
    apAutomation: {
      onClickResend: () => {
        props.handleResend(props.id);
        props.setActivityItemId?.(props.id);
      },
    },
    note: {
      onCopyBody: (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        props?.handleCopyNote?.(props.content);
      },
    },
    ellipsis: {
      onClickSendForApproval: () => {
        props?.setActionType?.(EmailAction.REPLY);
        props?.handleSendForApproval?.();
      },
      onClickMarkSenderAsSpam: () => {
        props?.handleMarkSenderAsSpam?.(props?.from?.company_id);
      },
      onClickMarkSenderAsFraud: () => {
        props?.handleMarkSenderAsFraud?.(props?.from?.company_id);
      },
    },
  };

  /**
   * Props that are used in various type of activities to help
   * in display correct activity types, header message or action
   * buttons
   */
  const otherProps = {
    erroneousEmail: props.from?.email_address ?? "N/A",
    note: props.preview ?? "",
    primaryConnection: props.primaryConnection?.name,
    timestampUntilSnoozed: snoozedDate,
    connectionStatus: props.variant,
    timeSaving: {
      from_time: props.timeSaved?.from_time,
      to_time: props.timeSaved?.to_time,
    },
    activityStatus: props.status,
    isStreamSpam: props.isStreamSpam,
    isNudgeActivity: _.isUndefined(props.isNudgeActivity) ? false : props.isNudgeActivity,
    isSnoozeApplicable: props.isSnoozeApplicable,
    isSpamApplicable: props.isSpamApplicable,
    approverName: props?.to?.[0]?.name ?? props?.to?.[0]?.email_address,
    originalStreamItemId: props.originalStreamItemId,
    type: props.type,
    assigneeName: props.assigneeName,
    bounceErrorMsg: props.bounceErrorMsg ?? "",
    retried: props.retried ?? null,
    isResendApplicable: props.isResendApplicable,
    isShowPrimaryProfileShareOrAPAutomate: props.isShowPrimaryProfileShareOrAPAutomate,
    showAPOrARProfile: props.showAPOrARProfile,
    countryCode: props.countryCode,
    isEInvoicingRoute: props?.isEInvoicingRoute,
    isAutoClosed: props?.isAutoClosed,
    mergedToConnectionName: props?.mergedToConnectionName,
    movedToConnectionName: props?.movedToConnectionName,
  };

  return (
    <>
      <ActivityFeedItemComponent baseProps={baseProps} {...otherProps} variant={props.variant} handlers={handlers} />

      <ViewOriginalEmail
        openOriginalEmail={openOriginalEmail}
        toggleOriginalEmail={toggleOriginalEmail}
        originalEmailContent={originalEmailContent}
        creator={props.creator}
        attachments={props.attachments}
        header={props.header}
        to={props.to}
        date={props.date}
        content={props.content}
        is_read={props.isRead}
        variant={props.variant}
        magicLink={props.magicLink}
        countryCode={props.countryCode}
      />
    </>
  );
}

import React from "react";
import { PaginationHook } from "../types/hooks.types";
import { NUMERIC_VALUES } from "../../constants/numeric.constants";

const usePagination: PaginationHook = (initialPageSize: number, initialPageNumber: number) => {
  /**
   * Set the default pagination values
   * @const pageSizeDefault is initialised from PageSize key in session storage as
   * it keeps page size consistent between all table instances. If this value is not
   * available it defaults to tables initial pagination configurationvalues
   */
  const pageSizeDefault = sessionStorage.getItem("PageSize");
  const [pageNumber, setPageNumber] = React.useState<number>(initialPageNumber);
  const [pageSize, setPageSize] = React.useState<number>((pageSizeDefault as unknown as number) ?? initialPageSize);
  const [totalRecords, setTotalRecords] = React.useState<number>(NUMERIC_VALUES.CONSTANT_ZERO);
  const [totalPages, setTotalPages] = React.useState<number>(NUMERIC_VALUES.CONSTANT_ZERO);

  const updatePageNumber = (page: number) => {
    const url = new URL(window.location.href);
    url.searchParams.set("page", page.toString());
    window.history.pushState({}, "", url.toString());
    setPageNumber(page - NUMERIC_VALUES.CONSTANT_ONE);
  };
  /**
   * Pagination on change handler
   * @param event
   * @param page
   */
  const paginationHandler = async (_event: React.ChangeEvent<unknown>, page: number) => updatePageNumber(page);

  /**
   * Set selected page size to sessio storage to keep consistency between table instances
   * @param size
   */
  const pageSizeHandler = (size: number) => {
    sessionStorage.setItem("PageSize", size as unknown as string);
    setPageSize(size);

    //Reset pagination state when page size is changed
    let newPageNumber = initialPageNumber + NUMERIC_VALUES.CONSTANT_ONE;
    const totalPageNumber = Math.ceil(totalRecords / size);

    if (newPageNumber > totalPageNumber) {
      newPageNumber = totalPageNumber || NUMERIC_VALUES.CONSTANT_ONE;
    }
    updatePageNumber(newPageNumber);
  };

  return {
    page: {
      pageSize: pageSize ?? NUMERIC_VALUES.CONSTANT_ZERO,
      pageNumber: (pageNumber ?? NUMERIC_VALUES.CONSTANT_ZERO) + NUMERIC_VALUES.CONSTANT_ONE,
      totalRecords: totalRecords ?? NUMERIC_VALUES.CONSTANT_ZERO,
      totalPages: totalPages ?? NUMERIC_VALUES.CONSTANT_ZERO,
    },
    onChangeHandler: paginationHandler,
    onChangePageSize: pageSizeHandler,
    setTotalRecords: setTotalRecords,
    setTotalPages: setTotalPages,
    updatePageNumber,
  };
};

export default usePagination;

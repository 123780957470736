import { Liquid } from "liquidjs";
import { DEFAULT_NUMERIC_VALUES } from "../../constants/NumericConstants";
import { IData } from "./TemplateTypes";

/**
 * Base template for the all supported template.
 */
class BaseTemplate {
  /**
   * The workSpace indicate that which workspace is
   * currently active.
   */
  public workSpace = "";

  /**
   * The data which used to parse the template
   */
  protected data;

  /**
   * The instance of liquid template engine
   */
  protected liquid;

  /**
   * Contains template body content.
   */
  protected templateBody = "";

  /**
   * Contains template subject content.
   */
  protected templateSubject = "";

  /**
   * Contains template code.
   */
  protected templateCode = "";

  /**
   * Contains template code.
   */
  protected templateName = "";

  /**
   * Contains template Id from BE.
   */
  protected templateId;

  /**
   * Contains template snippets.
   */
  protected templateSnippet = {};

  /**
   * Option to attach invoices/payments/documets with template
   */
  protected templateAttachment = false;

  /**
   * Contains Default From time
   */
  protected fromTime: number = DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;

  /**
   * Contains Default To time
   */
  protected toTime: number = DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;

  /**
   * Constructor
   * @param data
   * @param liquid
   */
  constructor(workSpace: string, templateData: any, liquid: Liquid, data?: IData) {
    this.data = data;
    this.liquid = liquid;
    this.workSpace = workSpace;
    this.templateBody = templateData.content;
    this.templateId = templateData.id;
    this.templateSubject = templateData.subject;
    this.templateCode = templateData.template_code;
    this.templateName = templateData.template_name;
    this.templateSnippet = templateData.snippets;
  }

  /**
   * Function which is used to parse the template.
   * @returns
   * For invalid field in body it will return empty string.
   */
  parseTemplate(data: any): string {
    try {
      return this.liquid.parseAndRenderSync(this.templateBody, data);
    } catch (error) {
      console.error("Template Parsing Error:", error);
      return "";
    }
  }

  /**
   * Function which is used to parse the template subject.
   * @returns
   * For invalid field in body it will return empty string.
   */
  parseTemplateSubject(data: any): string {
    try {
      return this.liquid.parseAndRenderSync(this.templateSubject, data);
    } catch (error) {
      console.error("Template Subject Parsing Error:", error);
      return "";
    }
  }

  get getFromTime(): number {
    return this.fromTime;
  }
  get getTemplateCode(): string {
    return this.templateCode;
  }

  get getTemplateSubject(): string {
    return this.templateSubject;
  }

  get getTemplateBody(): string {
    return this.templateBody;
  }

  get getTemplateId(): number {
    return this.templateId;
  }

  get getTemplateName(): string {
    return this.templateName;
  }

  get getToTime(): number {
    return this.toTime;
  }

  setTemplateName(bodyName: string) {
    this.templateName = bodyName;
    return this.templateName;
  }
  setTemplateBody(bodyTemplate: string) {
    this.templateBody = bodyTemplate;
    return this.templateBody;
  }
  setTemplateSubject(bodySubject: string) {
    this.templateSubject = bodySubject;
    return this.templateSubject;
  }
  setTemplateId(id: string) {
    this.templateId = id;
    return this.templateId;
  }
}

export default BaseTemplate;

import React, { useState, useEffect, useRef } from "react";
import AddNote from "./AddNote/AddNote";
import Button from "../Button/Button";
import { AppContext } from "../../../contexts/AppContext";
import { UserActions } from "../../../types/enums";
import { notesClient } from "../../../db/accessor";
import "./NoteStream.scss";
import { DEFAULT_NUMERIC_VALUES, TRIM_COUNT_CONSTANTS } from "../../../constants/NumericConstants";
import useLocale from "../../../hooks/useLocale";

function getSubstringIndex(text: string): number {
  if (text.length < TRIM_COUNT_CONSTANTS.NOTE_STREAMS) {
    return text.length;
  } else {
    const lastSpace = text.indexOf(" ", TRIM_COUNT_CONSTANTS.NOTE_STREAMS);
    return lastSpace === DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE ? text.length : lastSpace;
  }
}

/**
 * * Represents a specific Row of a Note Stream
 */
function NoteRow(props: { note: NoteModel }): React.ReactElement {
  // Denotes if the text of the note is considered long
  const substringIndex = getSubstringIndex(props?.note?.noteText ?? "");
  const longText: boolean = (props?.note?.noteText?.length ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) > substringIndex;
  const [showAll, setShowAll] = useState<boolean>(!longText);
  const { formatDateBasedOnCountry: formatDate } = useLocale();

  return (
    <div className={`note-row`} key={props.note.noteId}>
      <div className={`line`}>
        <p className={`date`}>
          {props.note.createdUserName} on {props?.note?.created ? formatDate(props.note.created) : "N/A"}
        </p>
      </div>
      <div className={`line`}>
        <p className={`description`}>
          {longText
            ? showAll
              ? props?.note?.noteText ?? ""
              : props?.note?.noteText
              ? props.note.noteText.substring(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, substringIndex) + " ..."
              : ""
            : props.note.noteText}
        </p>
      </div>
      {longText ? (
        <div className={`line`}>
          {showAll ? (
            <p className={`link`} onClick={() => setShowAll(!showAll)}>
              Show less
            </p>
          ) : (
            <p className={`link`} onClick={() => setShowAll(!showAll)}>
              See more
            </p>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

interface NoteStreamProps {
  title: string;
  tableKey: string;
  objectKey: string;
}

/**
 * * Wrapper Element for a Note Stream
 */
export default function NoteStream(props: NoteStreamProps): React.ReactElement {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isError, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [data, setData] = useState<NoteModel[] | undefined>(undefined);
  const [isAddOpen, setAddOpen] = useState<boolean>(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { hasPermission } = React.useContext(AppContext) as AppType;

  // GET on /api/v1/Notes/query
  const fetchData = async () => {
    setError(false);
    setErrorMessage("");

    try {
      await notesClient
        .query(`(tableKey eq ${props.tableKey}) AND (objectKey eq ${props.objectKey}) AND (isArchived eq false)`)
        .then((data: NoteModelFetchResult) => {
          setData(data.records ?? []);
        });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setError(true);
      setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) fetchData();
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    if (isAddOpen) {
      textAreaRef?.current?.focus();
    }
  }, [isAddOpen]);

  return (
    <div className={`note-stream-wrapper`}>
      <div className={`header`}>
        <div className="title-row">
          <p className={`title`}>{props.title}</p>
          {hasPermission(UserActions.AddNote) && (
            <Button
              className={`${isAddOpen ? "hide-note-btn" : ""}`}
              size="sm"
              onClick={() => {
                setAddOpen(true);
              }}
              loading={isLoading}
            >
              Add Note
            </Button>
          )}
        </div>
        <AddNote
          size="sm"
          tableKey={props.tableKey}
          objectKey={props.objectKey}
          isOpen={isAddOpen}
          refreshNotes={fetchData}
          handleClose={() => setAddOpen(false)}
          textAreaRef={textAreaRef}
          setLoading={setLoading}
        />
      </div>
      <div className="body">
        {isError
          ? errorMessage
          : data?.map((note, index, arr) => (
              <NoteRow note={arr[arr.length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE - index]} key={`${note.noteId}-${index}`} />
            ))}
      </div>
    </div>
  );
}

import React, { useState, MouseEvent } from "react";
import _ from "underscore";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
// import { AppContext } from "../../../../contexts/AppContext";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import { Company } from "../../../library/Icons/Icons";
import Userpic from "../Userpic/Userpic";
import Menu from "../../../library/Menu/Menu";
import MenuItem from "../../../library/MenuItem/MenuItem";
import { DEFAULT_NUMERIC_VALUES } from "../../../../constants/NumericConstants";
import "./CompanyHeader.scss";

interface Props {
  companyName: string;
  groupKey: string | null;
  userGroups: UserGroupModel[];
  handleUserGroupChange: (userGroupItem: UserGroupModel) => Promise<void>;
}

/**
 * * Defines Company Settings for Navigation Side Bar
 */
export default function CompanyHeader(props: Props): React.ReactElement {
  const { loginUserCompany } = React.useContext(CustomerContext) as CustomerType;
  // const { updateUserStatus } = React.useContext(AppContext) as AppType;

  const [dropdownMenuAnchorEl, setDropdownMenuAnchorEl] = useState<HTMLDivElement | null>(null);
  const dropdownMenuOpen = Boolean(dropdownMenuAnchorEl);

  const isMultiAccount = props?.userGroups && !_.isEmpty(props?.userGroups) && props?.userGroups.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ONE;

  const onClickMenuItems = (userGroupItem: UserGroupModel) => {
    if (userGroupItem.group_key !== props.groupKey) {
      void props.handleUserGroupChange(userGroupItem);
    }
    setDropdownMenuAnchorEl(null);
  };

  // const fetchUpdatedUserGroupData = async () => {
  //   await updateUserStatus();
  // };

  return (
    <>
      <div
        className="company-header-wrapper"
        onClick={(event: MouseEvent<HTMLDivElement>) => {
          if (isMultiAccount) {
            /**
             * Originally, userStatus was refreshed to get up-to-date count for activities,
             * since that functionality does not exist on the group swither anymore, removing
             * this network call to avoid unnecessary further refresh calls.
             */
            // fetchUpdatedUserGroupData();
            setDropdownMenuAnchorEl(event.currentTarget);
          }
        }}
      >
        <Userpic imageUrl={loginUserCompany.companyLogoUrl ?? ""} icon={<Company />}></Userpic>
        <div className="company-header-name-container">
          <p className="company-name">{props.companyName}</p>
          {isMultiAccount && (dropdownMenuAnchorEl ? <KeyboardArrowUp /> : <KeyboardArrowDown />)}
        </div>
      </div>
      {isMultiAccount && (
        <Menu
          open={dropdownMenuOpen}
          anchorEl={dropdownMenuAnchorEl}
          onClose={() => setDropdownMenuAnchorEl(null)}
          className="company-header-dropdown"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          {props?.userGroups?.map((item) => {
            return (
              <MenuItem key={item?.group_name} onClick={() => onClickMenuItems(item)}>
                <span className="user-group-name">{item?.group_name || "--"}</span>
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </>
  );
}

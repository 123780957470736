import APIClient from "../APIClient";
import { AxiosRequestConfig } from "axios";

const INBOX_API_BASE = `${process.env.REACT_APP_API_ENDPOINT_INBOX_API_PATH}${process.env.REACT_APP_API_ENDPOINT_INBOX_API_V2}`;
const CONFIG_OVERRIDE = {
  baseURL: process.env.REACT_APP_API_ENDPOINT_INBOX,
} as AxiosRequestConfig;

export class WorkflowStatusClient {
  /**
   * @function getApplicableStatuses
   * A helper method to fetch next states - actions
   * @see {@link https://lockstepapi.postman.co/workspace/Lockstep-inbox~e303da34-7ad8-4123-90e3-d552a6ae5670/request/20950407-7c9d020c-72aa-4c12-9109-93c7852dc54b | Get Next States}
   * @param stateId - current state identifier
   * @returns
   */
  getApplicableStatuses(stateId: string, invoiceTypeCode: string): Promise<any> {
    const url = `${INBOX_API_BASE}/workflow_statuses/${stateId}/applicable_statuses?invoice_type_code=${invoiceTypeCode}`;
    return APIClient.get(url, CONFIG_OVERRIDE).then((res) => {
      return res.data;
    });
  }
}

import React, { useState, useEffect, useCallback, useMemo } from "react";
import _ from "underscore";
import Chip from "../../../../../library/Chip/Chip";
import { ReadMore, ReadMoreClicked } from "../../../../../library/Icons/Icons";
import { ReactComponent as Flag } from "../../../../../../assets/Flag.svg";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../../../../../constants/NumericConstants";
import { PrimaryActivityProps } from "./PrimaryActivityTypes";
import DOMPurify from "dompurify";
import { ReplyOptions, TimeSavingIndicator } from "./Helpers";
import useLimitContent from "../../../../../../hooks/useLimitContent";
import "../ActivityFeedItem.scss";
import { MENTION_REGEX_CONSTANTS } from "../../../../../../constants/RegexConstants";
import { useLocation } from "react-router-dom";
import { GLOBAL_SEARCH_CONSTANTS } from "../../../../../../constants/GlobalSearchConstants";
import { SmartText } from "../../../../../library/SmartText";
import { ActivityReadContentShortcuts, KeyboardActionsConstants } from "../../../../../../constants/KeyboardShortcutConstants";
import useKeyboardShortcut from "../../../../../../hooks/useKeyboardShortcut";
import { TooltipTitles, TooltipTypes } from "../../../../../../constants/TooltipConstants";
import { ContactPopover as ContactPopoverV2, SenderPopover } from "../../../../../library/ContactPopoverV2";
import Reactions from "./Helpers/Reactions/Reactions";
import EmojiSelector from "./Helpers/EmojiSelector/EmojiSelector";
import { CUSTOM_ACTIVITY_FEED_ITEM_CONSTANTS } from "../../../../../../constants/StyleConstants";
import CustomTooltip from "../../../../../library/CustomTooltip/CustomTooltip";
import { formatDate, getDisplayDateFormat } from "../../../../../../db/utils/date";
import { AttachmentChip } from "../../../../../library/AtomicComponents/AttachmentChip";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { ActivityTypeChip } from "../../../../../library/AtomicComponents/atomic/ActivityTypeChip";
import { ButtonPrimary, ButtonSecondary, ButtonTertiary } from "../../../../../library/AtomicComponents/atomic/Button/button.styles";
import { AttachmentPreview } from "../../../../../library/AtomicComponents/AttachmentPreview";
import { DisableFeatureContext, DisableFeatureProviderType } from "../../../../../../contexts/DisableFeatureContext";
import { useTranslation } from "react-i18next";

const PrimaryActivity: React.FC<PrimaryActivityProps> = ({
  order,
  creator,
  attachments: { items: attachments },
  FLAGS: ACTION_FLAGS,
  timeSaving,
  actions,
  isRead,
  contacts,
  content,
  children,
  flag,
  readonly: READ_ONLY,
  isFeatureEnabled,
  curentActivePopperActivityOrder,
  setActivePopperActivityOrder,
  selectedWorkspace,
  isExpanded,
  countryCode,
  isApprovalActive,
  ...props
}) => {
  const {
    emailContainerRef: containerRef,
    emailRendererRef: rendererRef,
    limitContent,
    toggleContentLimit,
    contentBody,
  } = useLimitContent(isExpanded ?? false, content);
  const { t } = useTranslation();
  const [isHover, setIsHover] = React.useState<boolean>(false);
  const [currentActivePopper, setCurrentActivePopper] = React.useState<string>("");
  const isKeyboardShortcutFlagEnabled = isFeatureEnabled?.("KEYBOARD_SHORTCUTS");
  const enableShortcuts = props.enableKeyboardShortcut && isKeyboardShortcutFlagEnabled;
  const hasContent = !_.isNull(contentBody) && !_.isEmpty(contentBody);
  const { actionToPerform, setActionDone } = useKeyboardShortcut(ActivityReadContentShortcuts, !enableShortcuts);
  const { search } = useLocation();
  const queryFromParams = React.useMemo(() => new URLSearchParams(search), [search]);
  const [isHighlighting, setIsHighlighting] = useState<boolean>(true);
  const StatusChip = !isRead ? (
    <Chip text={t("activityDetails.feedItems.primaryActivity.fieldLabels.new", { ns: "activities" })} variant="new" />
  ) : (
    <Chip text={t("activityDetails.feedItems.primaryActivity.fieldLabels.seen", { ns: "activities" })} variant="seen" />
  );

  const [openPreview, setOpenPreview] = React.useState<boolean>(false);
  const [activeAttachmentID, setActiveAttachmentID] = React.useState<string | number>("");
  const TopicHeader = <span className="activity-variant">{!_.isUndefined(creator.message) ? creator.message : ""}</span>;
  const { setDisableKeyboardShortcut } = React.useContext(DisableFeatureContext) as DisableFeatureProviderType;

  const flagScheme: Record<StreamFlag, { [key: string]: string }> = {
    new_vendor: {
      title: t("flagChipLabels.newVendor", { ns: "activities" }),
      color: "purple",
    },
    new_bill: {
      title: t("flagChipLabels.newBill", { ns: "activities" }),
      color: "green",
    },
    bill: {
      title: t("flagChipLabels.bill", { ns: "activities" }),
      color: "orange",
    },
    existing_bill: {
      title: t("flagChipLabels.existingBill", { ns: "activities" }),
      color: "orange",
    },
  };

  const onClickPreviewOpen = (activeAttachmentID: string | number) => {
    setActiveAttachmentID(activeAttachmentID);
    setOpenPreview(!openPreview);
  };

  const onClickPreviewClose = (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement> | KeyboardEvent) => {
    event.stopPropagation();
    setOpenPreview(false);
  };

  /**
   * DOMPurify hook to open all links in a new window
   * See {@link https://github.com/cure53/DOMPurify/tree/main/demos#hook-to-open-all-links-in-a-new-window-link | DOMPurify - open all links in a new window}
   */
  DOMPurify.addHook("afterSanitizeAttributes", function (node) {
    // set all elements owning target to target=_blank
    if ("target" in node) {
      node.setAttribute("target", "_blank");
    }
  });

  const iframeRefForOriginal = React.useRef<HTMLIFrameElement>(null);

  /**
   * @function escapeTextHighlight
   * A helper function to catch "escape key" press and disable highlighting of search results, and also delete the query param
   */
  const escapeTextHighlight = useCallback((event: KeyboardEvent) => {
    if ((event && event.keyCode === GLOBAL_SEARCH_CONSTANTS.ESCAPE_KEY_CODE) || event.key === "Escape" || event.code === "Escape") {
      setIsHighlighting(false);
      queryFromParams?.delete("query");
    }
  }, []);

  /**
   * A side-effect to catch escape key press to disable search result text highlighting
   */
  useEffect(() => {
    document.addEventListener("keydown", escapeTextHighlight);
    return () => {
      document.removeEventListener("keydown", escapeTextHighlight);
    };
  }, [escapeTextHighlight]);

  /**
   * @function isValidMagicLink
   * A helper function to validate if the activity has a valid magic-link
   * @returns boolean
   */
  const isValidMagicLink = () => {
    return !_.isUndefined(props?.magicLink);
  };

  /**
   * @function filterMagicLinkHandle
   * A helper function to filter magic-link handle and add button to the email content body
   * @param contentBody - The email content body
   * @returns
   */
  const filterMagicLinkHandle = (contentBody: string) => {
    const magicLinkButton = `<div id='lockstep-email-magic-link-button-div-1' style='margin: 0.75em 0em 1.25em; text-align: left'> <a href= ${
      props?.magicLink
    } target='_blank' style='text-decoration: none; border: 0; color: white; font-size: 0.875em; font-weight: 600; line-height: 1.25em; padding: 0.625em 0.75em; height: 2.5em; background: #1A73E8; border-radius: 0.25em; cursor: pointer; marign-right: .5rem;'>${t(
      "activityDetails.feedItems.primaryActivity.fieldLabels.openPortal",
      { ns: "activities" }
    )}</a></div>`;
    if (isValidMagicLink()) {
      return contentBody.replace("{Portal Link}", magicLinkButton);
    }
    return contentBody;
  };

  /**
   * @function filterMentions
   * A helper function to filter all mentions from email content body and replace with UI friendly syntaxes
   * @param contentBody - The email content body
   * replaces [@John K](mention://user/11/John%20K) to <span style="color:#2D9DE7;">@John K</span>
   */
  const filterMentions = (contentBody: string) => {
    const allMentions = contentBody.match(MENTION_REGEX_CONSTANTS.FILTER_MENTION);
    if (allMentions) {
      let names = allMentions.map((item) => {
        const nameVal = item.match(MENTION_REGEX_CONSTANTS.FILTER_MENTION_NAME);
        if (nameVal) return nameVal[0].replace("[@", "").replace("]", "");
        else return null;
      });
      names = names.filter((name) => name);
      let contentNew = contentBody;
      contentNew = contentNew.replaceAll(MENTION_REGEX_CONSTANTS.FILTER_ALL_URLS, `(replace)`);
      names.forEach((nameVal) => {
        contentNew = contentNew.replace(`[@${nameVal}](replace)`, `<span style="color:#2D9DE7;">@${nameVal}</span>&nbsp;`);
      });
      return contentNew;
    }
    return contentBody;
  };

  /**
   * @function highlightSearchQuery
   * A helper function to highlight the search query in activity body,
   * by replacing the match with itself enclosed in mark tag
   * @param body
   * @returns string
   */
  const highlightSearchQuery = (body: string) => {
    const searchQuery = queryFromParams.get("query") || "";
    if (isHighlighting && searchQuery.length) {
      return body.replace(new RegExp(searchQuery, "gi"), (match: string) => {
        return `<mark>${match}</mark>`;
      });
    }
    return body;
  };

  /**
   * react component to handle activity flags
   * @returns React component
   */
  const ActivityFlag: React.FC<{ flag: StreamFlag }> = ({ flag }) => {
    return (
      <div className={`header-flag ${flagScheme[flag].color}`}>
        <div className="header-flag-icon">
          <Flag />
        </div>
        <div className="header-flag-title">{flagScheme[flag].title}</div>
      </div>
    );
  };

  const keyboardActions: { [key: string]: any } = useMemo(
    () => ({
      [KeyboardActionsConstants.read_more_less]: () => toggleContentLimit(),
    }),
    [toggleContentLimit]
  );

  useEffect(() => {
    if (enableShortcuts && actionToPerform && keyboardActions[actionToPerform] && typeof keyboardActions[actionToPerform] === "function") {
      keyboardActions[actionToPerform]();
      setActionDone();
    }
  }, [actionToPerform, enableShortcuts]);

  const shortcutDisplayMapper: { [key: string]: string } = useMemo(() => {
    if (enableShortcuts) {
      const mapper: { [key: string]: string } = {};
      ActivityReadContentShortcuts.forEach((item) => {
        if (item.identifier) mapper[item.identifier] = item.displayText;
      });
      return mapper;
    }
    return {};
  }, [enableShortcuts]);

  const clearPopperSiblings = (label: string) => {
    setActivePopperActivityOrder(order ?? null);
    setCurrentActivePopper(label);
  };

  useEffect(() => {
    if (openPreview) {
      setDisableKeyboardShortcut(true);
    } else {
      setDisableKeyboardShortcut(false);
    }
    return () => setDisableKeyboardShortcut(false);
  }, [openPreview]);

  return (
    <div
      className="activity-feed-item-wrapper v2"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={toggleContentLimit}
    >
      <div className="body">
        {/* header-section-wrapper-container */}
        <div className="feed-item-section header-section-wrapper">
          <div className="header-left-section">
            <div className="left-column">
              {creator.name && (
                <SenderPopover
                  avatar={true}
                  contact={contacts?.creator}
                  label={"CreatorAvatar"}
                  currentPopper={clearPopperSiblings}
                  clearPopper={currentActivePopper !== "CreatorAvatar" || curentActivePopperActivityOrder !== order}
                  readonly={props?.type === "cancelled" || (creator?.message === "" && creator.name?.includes("approval")) ? true : READ_ONLY}
                  selectedWorkspace={selectedWorkspace}
                />
              )}
            </div>
            <div className="right-column">
              <div className="header">
                <div className="header-details">
                  <p className="body2">
                    {creator.name &&
                      (props?.type === "cancelled" || (creator?.message === "" && creator.name?.toLowerCase().includes("approval")) ? (
                        <span className="activity-creator">
                          <SmartText
                            title={creator.name}
                            highlighter={{
                              enableHighlighter: isHighlighting,
                              matchText: queryFromParams.get("query") ?? "~",
                            }}
                          />
                          &nbsp;
                        </span>
                      ) : (
                        <SenderPopover
                          contact={contacts?.creator}
                          label={"CreatorName"}
                          currentPopper={clearPopperSiblings}
                          clearPopper={currentActivePopper !== "CreatorName" || curentActivePopperActivityOrder !== order}
                          readonly={READ_ONLY}
                          selectedWorkspace={selectedWorkspace}
                          enableHighlighter={isHighlighting}
                          matchText={queryFromParams.get("query") ?? "~"}
                        />
                      ))}
                    {props?.type === "cancelled" && TopicHeader}
                  </p>
                  {!_.isNull(flag) && !_.isEmpty(flag) && <ActivityFlag flag={flag} />}
                </div>
              </div>
              {/* subheader */}
              <div className="subheader-wrapper">
                <table className="subheader-key-value-table">
                  {_.isArray(contacts.to) && _.size(contacts.to) > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && (
                    <tr>
                      <td>{t("activityPopupEditor.inputField.labels.to", { ns: "activities" })}:</td>
                      <td>
                        <ContactPopoverV2
                          to={contacts.to}
                          label={"To"}
                          currentPopper={clearPopperSiblings}
                          clearPopper={currentActivePopper !== "To" || curentActivePopperActivityOrder !== order}
                          readonly={READ_ONLY}
                          selectedWorkspace={selectedWorkspace}
                        />
                      </td>
                    </tr>
                  )}

                  {_.isArray(contacts.to) && _.size(contacts.cc ?? []) > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && (
                    <tr>
                      <td>{t("activityPopupEditor.inputField.labels.cc", { ns: "activities" })}:</td>
                      <td>
                        <ContactPopoverV2
                          to={contacts.cc as ContactItem[]}
                          label={"Cc"}
                          currentPopper={clearPopperSiblings}
                          clearPopper={currentActivePopper !== "Cc" || curentActivePopperActivityOrder !== order}
                          readonly={READ_ONLY}
                          selectedWorkspace={selectedWorkspace}
                        />
                      </td>
                    </tr>
                  )}

                  {_.isArray(contacts.to) &&
                    _.size(contacts.bcc ?? []) > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO /* && loggedInUserEmail === creator.  */ && (
                      <tr>
                        <td>{t("activityPopupEditor.inputField.labels.bcc", { ns: "activities" })}:</td>
                        <td>
                          <ContactPopoverV2
                            to={contacts.bcc as ContactItem[]}
                            label={"Bcc"}
                            currentPopper={clearPopperSiblings}
                            clearPopper={currentActivePopper !== "Bcc" || curentActivePopperActivityOrder !== order}
                            readonly={READ_ONLY}
                            selectedWorkspace={selectedWorkspace}
                          />
                        </td>
                      </tr>
                    )}
                  {creator.message !== "Phone Call" && props?.subject !== "" && (
                    <tr>
                      <td>{t("activityPopupEditor.inputField.labels.subject", { ns: "activities" })}:</td>
                      <td>
                        <p className="subheader caption">
                          <span className="subheader-value">{props?.subject}</span>
                        </p>
                      </td>
                    </tr>
                  )}
                </table>
              </div>
            </div>
          </div>
          <div className="header-right-section">
            <div className="secondary-actions-row">
              {StatusChip}
              {!READ_ONLY && !props.isEInvoicing && props.isEmojiSupportEnabled && <EmojiSelector onClickEmoji={props.handleUserReaction} />}
              {!READ_ONLY && !props.isEInvoicing && ACTION_FLAGS?.REPLY_OPTIONS ? (
                <ReplyOptions
                  {...{
                    onClickForward: props.onClickForward,
                    onClickReplyAll: props.onClickReplyAll,
                    onClickViewOriginalEmail: props.onClickViewOriginalEmail,
                    onClickReply: props.onClickReply,
                    enableShortcuts: enableShortcuts,
                    onClickSendForApproval: props?.onClickSendForApproval,
                    onClickMarkSenderAsSpam: props?.onClickMarkSenderAsSpam,
                    onClickMarkSenderAsFraud: props?.onClickMarkSenderAsFraud,
                    isApprovalActive,
                  }}
                />
              ) : (
                <></>
              )}
            </div>
            {creator.message !== "Note" && (
              <div className="timestamp-row">
                <div className="header-date">
                  <SmartText
                    title={creator.createdOn && `${formatDate(creator.createdOn, getDisplayDateFormat(countryCode ?? ""))}`}
                    highlighter={{
                      enableHighlighter: isHighlighting,
                      matchText: queryFromParams.get("query") ?? "~",
                    }}
                  />
                </div>
              </div>
            )}

            <div className="feed-item-type-row">
              <ActivityTypeChip
                activityType={
                  props.isEInvoicing
                    ? "E-Invoice"
                    : creator.message?.includes("approval")
                    ? "Approval Request"
                    : creator.message
                    ? creator.message
                    : ""
                }
              />
            </div>
          </div>
        </div>

        <div className="feed-item-section feed-body">
          {/* attachments section */}
          {attachments && attachments.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && (
            <div className="feed-item-attachments-container">
              {attachments.map((document: AttachmentItem) => {
                return (
                  <AttachmentChip
                    key={`attachment-${document.id}`}
                    name={document?.file_name ?? ""}
                    extension={document.extension}
                    createdDate={formatDate(new Date((document.created_at as any) * NUMERIC_VALUES.CONSTANT_THOUSAND).toISOString())}
                    attachment={document}
                    onClickAttachmentChip={() => onClickPreviewOpen(document.id)}
                    onClickDownload={(event) => props.onClickDownload(event, [document])}
                  />
                );
              })}
              <div className="attachemnts-actions">
                <Link to="#" component={RouterLink} onClick={(event) => props.onClickDownload(event, attachments)}>
                  {t("activityDetails.feedItems.primaryActivity.downLoadAllLink", { ns: "activities" })}
                </Link>
              </div>
            </div>
          )}
          {openPreview && (
            <AttachmentPreview
              openPreview={openPreview}
              activeAttachmentID={activeAttachmentID}
              handleClose={onClickPreviewClose}
              attachments={attachments}
              onClickDownload={props.onClickDownload}
            />
          )}
          {/* E-Invoice action section */}
          {props.isEInvoicing && (
            <>
              {location.pathname.includes("receivedEInvoices") && (
                <div className="e-invoice-actions" e-invoice>
                  <ButtonPrimary>
                    <p className="btn-text">Accept</p>
                  </ButtonPrimary>
                  <ButtonSecondary>
                    <p className="btn-text">Dispute</p>
                  </ButtonSecondary>
                  <ButtonTertiary>
                    <p className="btn-text">Suspend</p>
                  </ButtonTertiary>
                  <ButtonTertiary error>
                    <p className="btn-text">Refuse</p>
                  </ButtonTertiary>
                </div>
              )}
              <div className="e-invoice-line-items">
                <ButtonPrimary onClick={() => onClickPreviewOpen(attachments?.[0].id)}>View E-Invoice</ButtonPrimary>
              </div>
            </>
          )}

          {/* email content */}
          {!props.isEInvoicing && (
            <div ref={rendererRef} className={`email-renderer ${limitContent ? "full-height" : ""}`}>
              {limitContent ? (
                <div
                  key={"activity-body-content" + isHighlighting}
                  ref={containerRef}
                  className={`email-container ${!limitContent ? "expanded-margin" : ""}`}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      highlightSearchQuery(filterMentions(filterMagicLinkHandle(contentBody).replace(/\t/g, "\u00a0")).replace(/\n/g, "<br/>")),
                      {
                        FORBID_TAGS: ["img"],
                      }
                    ),
                  }}
                />
              ) : (
                hasContent && (
                  <iframe
                    className={`email-container`}
                    ref={iframeRefForOriginal}
                    srcDoc={DOMPurify.sanitize(
                      highlightSearchQuery(filterMentions(filterMagicLinkHandle(contentBody).replace(/\t/g, "\u00a0")).replace(/\n/g, "<br/>"))
                    )}
                    scrolling="auto"
                    onLoad={function () {
                      if (iframeRefForOriginal?.current?.contentWindow) {
                        iframeRefForOriginal.current.style.height =
                          iframeRefForOriginal.current.contentWindow.document.body.scrollHeight < NUMERIC_VALUES.CONSTANT_HUNDRED
                            ? iframeRefForOriginal.current.contentWindow.document.body.scrollHeight +
                              CUSTOM_ACTIVITY_FEED_ITEM_CONSTANTS.IFRAME_HEIGHT +
                              "px"
                            : iframeRefForOriginal.current.contentWindow.document.body.scrollHeight +
                              iframeRefForOriginal.current.contentWindow.document.body.scrollHeight / NUMERIC_VALUES.CONSTANT_TEN +
                              "px";
                        iframeRefForOriginal.current.contentWindow.document.body.style.margin = "0px";
                        iframeRefForOriginal.current.contentWindow.document.body.style.fontFamily =
                          '"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans","Helvetica Neue", sans-serif';
                        iframeRefForOriginal.current.contentWindow.document.body.style.fontWeight = "400";
                        iframeRefForOriginal.current.contentWindow.document.body.style.fontSize = "0.875rem";
                        iframeRefForOriginal.current.contentWindow.document.body.style.lineHeight = " 1.25rem";
                      }
                    }}
                  />
                )
              )}
            </div>
          )}
          {!props.isEInvoicing && (
            <div className={`activity-action-container ${limitContent ? "limitContent" : ""}`}>
              <div className="left">
                {hasContent &&
                  (limitContent ? (
                    <CustomTooltip
                      type={enableShortcuts ? TooltipTypes.SHORTCUT : TooltipTypes.PLAIN}
                      title={
                        enableShortcuts ? (t("activityDetails.feedItems.primaryActivity.fieldLabels.readMore", { ns: "activities" }) as string) : ""
                      }
                      shortcutKeysText={enableShortcuts ? shortcutDisplayMapper?.[TooltipTitles.READ_LESS] : ""}
                    >
                      <div>
                        <ReadMore isHover={isHover} />{" "}
                      </div>
                    </CustomTooltip>
                  ) : (
                    <CustomTooltip
                      type={enableShortcuts ? TooltipTypes.SHORTCUT : TooltipTypes.PLAIN}
                      title={
                        enableShortcuts ? (t("activityDetails.feedItems.primaryActivity.fieldLabels.readLess", { ns: "activities" }) as string) : ""
                      }
                      shortcutKeysText={enableShortcuts ? shortcutDisplayMapper?.[TooltipTitles.READ_LESS] : ""}
                    >
                      <div>
                        <ReadMoreClicked isHover={isHover} />
                      </div>
                    </CustomTooltip>
                  ))}
                {!READ_ONLY && actions?.left}
                {!READ_ONLY && children}
                {!READ_ONLY && props.isEmojiSupportEnabled && <Reactions reactions={props.reactions} onClickReaction={props.handleUserReaction} />}
              </div>
              <div className="right">
                {!READ_ONLY && actions?.right}
                {ACTION_FLAGS?.TIME_SAVING_INDICATOR ? <TimeSavingIndicator timeSaving={timeSaving} /> : <></>}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PrimaryActivity;

/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { MouseEvent, useRef, useState, useEffect } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../../../constants/NumericConstants";
import { attachmentsClientV2 } from "../../../../db/version2Accessor";
import TableUtils from "../../../../utils/TableUtils/TableUtils";
import { Download, Upload } from "../../../library/Icons/Icons";
import Button from "../../../library/Button/Button";
import { FileUploader } from "../../../library/FileUploader";
import Autocomplete from "../../../library/Autocomplete/Autocomplete";
import { TextField } from "@mui/material";
import useWorkspaceConfigurations from "../../../../hooks/useWorkspaceConfigurations";
import { FallbackTypes, VIEW_CATEGORIES, WorkspaceType } from "../../../../types/enums";
import { CustomerContext } from "../../../../contexts/CustomerContext";

import { Table, useColumnFilters } from "../../../library/AtomicComponents/Table/index";
import { UserAction } from "../../../library/AtomicComponents/Table/types/table.types";
import usePagination from "../../../library/AtomicComponents/Table/hooks/usePagination";
import useColumnSort from "../../../library/AtomicComponents/Table/hooks/useColumnSort";
import useRowSelection from "../../../library/AtomicComponents/Table/hooks/useRowSelection";
import { DEFAULT_PAGINATION_VALUES } from "../../../../constants/NumericConstants";
import APIClientUtils from "../../../../utils/APIClientUtils/APIClientUtils";
import { RowDataTypes } from "../../../library/AtomicComponents/constants/table.constants";
import { EMPTY_ACTIONS_MESSAGE, EMPTY_DOCUMENTS_CAPTION_MESSAGE } from "../../../../constants/config";
import { CustomerDetailsDashboardContext, CustomerDetailsDashboardData } from "../../../../contexts/CustomerDetailsDashboardContext";
import { DropdownItemList } from "../../../library/AtomicComponents/TableFilters/Presets/preset.filter.types";
import useLocale from "../../../../hooks/useLocale";
import { FeatureFlagContext, FeatureFlagProviderType } from "../../../../contexts/FeatureFlagContext";
import { useQuery } from "@tanstack/react-query";
import { RefetchIntervals } from "../../../../constants/CacheConfig";

interface DocumentsProps {
  configs: any;
}
/**
 * * Define the Document Tab of the Customer Detail's page
 */
export default function Documents(props: DocumentsProps): React.ReactElement {
  const tableRef = useRef<TableHandle>(null);
  const { customerId } = useParams<{ customerId: string }>();
  const params = new URLSearchParams(window.location.search);
  const pageNoFromUrl = parseInt(params.get("page") ?? "1", NUMERIC_VALUES.CONSTANT_TEN) || NUMERIC_VALUES.CONSTANT_ONE;

  //eslint-disable-next-line
  const [isError, setError] = useState<boolean>(false);
  //eslint-disable-next-line
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [tableData, setTableData] = useState<TableData[]>([]);

  const [fileType, setFileType] = React.useState<string>("W-9");
  const [companyData] = useState<CompanyModel>({} as CompanyModel);
  const [fileUploaderOpen, setFileUploaderOpen] = React.useState<boolean>(false);

  const { selectedWorkspace } = useWorkspaceConfigurations();
  const { toggleDataUpdated } = React.useContext(CustomerContext) as CustomerType;
  const initalPageNumber = pageNoFromUrl - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE;
  const { setTotalRecords, setTotalPages, updatePageNumber, ...paginationConfig } = usePagination(
    DEFAULT_PAGINATION_VALUES.PAGE_SIZE,
    initalPageNumber ?? DEFAULT_PAGINATION_VALUES.PAGE_COUNT
  );
  const { query: sortQueryInternal, ...sortConfig } = useColumnSort("created_at desc");
  const selectionConfig = useRowSelection(paginationConfig?.page?.totalRecords);
  const { ...initialFilterConfigs } = useColumnFilters(true, [], updatePageNumber);
  const { setViewBasedFilters } = React.useContext(CustomerDetailsDashboardContext) as CustomerDetailsDashboardData;
  const [columnsConfigData, setColumnsConfigData] = useState<any[]>(() => props.configs?.table?.columns);
  const [isColumnsConfigDataLoading, setIsColumnsConfigDataLoading] = useState<boolean>(true);
  const { calendarDateFormat } = useLocale();
  const { isEnabled } = React.useContext(FeatureFlagContext) as FeatureFlagProviderType;
  const isFiltersEnabled = isEnabled("FILTERS_ENABLE");
  const [isNoSearchResultFound, setIsNoSearchResultFound] = useState<boolean>(false);
  const { path } = useRouteMatch();
  const [deselectedRowsFilter, setDeselectedRowsFilter] = useState<string>("");
  const filterObjFromUrl = JSON.parse(params.get("filters") ?? "{}");
  const appliedFilters = initialFilterConfigs.generateFilterQuery(Object.values(filterObjFromUrl), true);

  //it return category view name for suggestion history
  const getViewCategory = () => {
    const categoryMap: { [key: string]: VIEW_CATEGORIES } = {
      "vendors/active/:customerId/documents": VIEW_CATEGORIES.ACTIVE_VENDOR_DOCUMENTS,
      "vendors/archived/:customerId/documents": VIEW_CATEGORIES.ARCHIVED_VENDOR_DOCUMENTS,
      "vendors/spam/:customerId/documents": VIEW_CATEGORIES.SPAM_VENDOR_DOCUMENTS,
      "vendors/fraud/:customerId/documents": VIEW_CATEGORIES.FRAUD_VENDOR_DOCUMENTS,
      "customers/active/:customerId/documents": VIEW_CATEGORIES.ACTIVE_CUSTOMER_DOCUMENTS,
      "customers/archived/:customerId/documents": VIEW_CATEGORIES.ARCHIVED_CUSTOMER_DOCUMENTS,
      "customers/spam/:customerId/documents": VIEW_CATEGORIES.SPAM_CUSTOMER_DOCUMENTS,
      "customers/fraud/:customerId/documents": VIEW_CATEGORIES.FRAUD_CUSTOMER_DOCUMENTS,
      "connections/active/:customerId/documents": VIEW_CATEGORIES.ACTIVE_OTHER_DOCUMENTS,
      "connections/archived/:customerId/documents": VIEW_CATEGORIES.ARCHIVED_OTHER_DOCUMENTS,
      "connections/spam/:customerId/documents": VIEW_CATEGORIES.SPAM_OTHER_OPEN_DOCUMENTS,
      "connections/fraud/:customerId/documents": VIEW_CATEGORIES.FRAUD_OTHER_OPEN_DOCUMENTS,
      "internal/active/:customerId/documents": VIEW_CATEGORIES.ACTIVE_INTERNAL_DOCUMENTS,
      "internal/archived/:customerId/documents": VIEW_CATEGORIES.ARCHIVED_INTERNAL_DOCUMENTS,
    };
    for (const pathKey in categoryMap) {
      if (path.includes(pathKey)) {
        return categoryMap[pathKey];
      }
    }

    return "";
  };

  const ellipsisActionHandlers: Partial<RowSelectButtons> = {
    "action.downloadAll": (row: any) => TableUtils.downloadAll([row]),
  };
  const ellipsisActions: RowSelectButtons = props.configs.table.ellipsisActions.map((action: EllipsisAction) => {
    return { ...action, clickFunction: ellipsisActionHandlers[action.handler] };
  });

  const cellRendererMapping: CellRendererMappingObject = {
    "RowDataTypes.BoldText": RowDataTypes.BoldText,
    "RowDataTypes.DateEpoch": RowDataTypes.DateEpoch,
    "RowDataTypes.ActionsDropdown": RowDataTypes.ActionsDropdown,
  };

  const fetchParser = (fetchResult: any, variant?: FetchVariant): Partial<TableFetchResult>[] => {
    return (
      fetchResult?.records?.map((record: any) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record.id,
          }),
          ...((variant === "export" || variant === "all") && {
            file_name: record.file_name,
            owner: record.owner?.name,
            file_type: record.file_type,
            status: record.status,
            created_at: record.created_at,
            file_url: record.file_url,
          }),
          ...(variant === "all" && {
            disableCheckbox: undefined,
            isUnread: undefined,
          }),
        };
      }) ?? []
    );
  };

  const toggleFileUpload = () => {
    if (fileUploaderOpen) {
      tableRef?.current?.softRefresh();
    }
    setFileUploaderOpen(!fileUploaderOpen);
    toggleDataUpdated();
    refetch();
  };

  const fetchDocumentsTableData = async (
    variant: FetchVariant,
    columnFilters?: string,
    pageNumber?: number,
    pageSize?: number,
    isSelectAll = false
  ) => {
    const sortQueryString = TableUtils.getSortQueryString(params, sortQueryInternal);

    const sortQuery = TableUtils.sortQueryParser(sortQueryString ?? "", true) ?? "";
    const filterQuery = TableUtils.columnFilterParser(TableUtils.getFilterFromURL(params, props.configs.table.columns), true);
    const otherFilters = `filter_by=connection&connection_id=${customerId}`;

    const filters = [otherFilters, columnFilters, filterQuery].filter(Boolean).join("&");
    return attachmentsClientV2
      .getAttachments(
        filters,
        sortQuery,
        pageNumber ?? paginationConfig.page?.pageNumber ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
        pageSize ?? paginationConfig.page?.pageSize ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
        isSelectAll
      )
      .then((fetchResult: any) => {
        return { ...fetchResult, records: fetchParser(fetchResult, variant) };
      })
      .catch((error: any) => {
        setError(true);
        setErrorMessage(APIClientUtils.buildErrorMessage(error));
      });
  };

  const queryKey = `connection-document-invoice-index-${selectedWorkspace?.workspace_type_route}`;

  const {
    isLoading,
    data: cachedData,
    refetch,
  } = useQuery(
    [
      queryKey,
      paginationConfig.page?.pageNumber,
      paginationConfig.page?.pageSize,
      TableUtils.getSortQueryString(params, sortQueryInternal),
      appliedFilters,
    ],
    async () => await fetchDocumentsTableData("all", appliedFilters),
    {
      refetchOnMount: true,
      refetchInterval: RefetchIntervals.twoHoursDelay,
    }
  );

  useEffect(() => {
    /**
     * update the page number once user perform any action and because of that total records get's changed
     */
    const totalPages = Math.ceil(cachedData?.totalCount / cachedData?.pageSize) ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
    if ((paginationConfig.page?.pageNumber || NUMERIC_VALUES.CONSTANT_ONE) > totalPages) {
      updatePageNumber(totalPages || NUMERIC_VALUES.CONSTANT_ONE);
    }
    setTotalRecords(cachedData?.totalCount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    setTotalPages(totalPages);
    setTableData(cachedData?.records ?? []);
  }, [cachedData]);

  useEffect(() => {
    setViewBasedFilters(appliedFilters);
  }, [appliedFilters]);

  const handleDownloadDocument = (ids: string[]) => {
    const urls = tableData
      .map((item) => {
        const url = ids.find((id) => parseInt(id, NUMERIC_VALUES.CONSTANT_TEN) === parseInt(item.id, NUMERIC_VALUES.CONSTANT_TEN));
        if (url) {
          return item;
        }
      })
      .filter((element) => !!element);
    TableUtils.downloadAll(urls);
  };

  const handleDownloadAllDocument = async () => {
    const filter = [deselectedRowsFilter, appliedFilters].filter(Boolean).join("&");

    const fetchResult = await fetchDocumentsTableData("all", filter, undefined, NUMERIC_VALUES.CONSTANT_THOUSAND, true);
    TableUtils.downloadAll(fetchResult.records);
  };

  function handleRowClick(e: MouseEvent<HTMLTableRowElement>, row: { id?: string }, currentPageRecords?: { id: string; isSelected?: boolean }[]) {
    /**
     * If a row item is already selected, then clicking anywhere on other rows should select those rows, and do not navigate.
     * if double clicked, then navigate download documents
     */
    const clickType = e.detail;
    if (clickType === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE && selectionConfig.tableRecords?.selectedRecordsIds?.length && row.id) {
      selectionConfig?.onClickTableRow(row?.id, currentPageRecords ?? []);
      return;
    }

    TableUtils.downloadAll([row]);
  }

  const rowSelectButtons: UserAction[] = [
    {
      icon: <Download />,
      tooltipLabel: "Download",
      callback: (ids: any) => {
        selectionConfig?.isAllRecordsSelected || selectionConfig?.hasAllRecordsSelectedButtonClicked?.current
          ? handleDownloadAllDocument()
          : handleDownloadDocument(ids);
      },
      visible: {
        onHeader: true,
        onHover: true,
      },
    },
  ];

  const getPreviousSuggestedData = (recentSearchHistory: FilterDropdownSuggestionRef) => {
    const { filterSearchedText = {} } = initialFilterConfigs ?? {};
    const { file_name, owner } = filterSearchedText;

    return {
      fileName:
        file_name?.value?.length >= NUMERIC_VALUES.CONSTANT_THREE
          ? recentSearchHistory?.file_name?.filter((item: DropdownItemList) => initialFilterConfigs?.activeFilters?.file_name?.ids?.includes(item.id))
          : recentSearchHistory?.file_name,
      owner:
        owner?.value?.length >= NUMERIC_VALUES.CONSTANT_THREE
          ? recentSearchHistory?.owner?.filter((item: DropdownItemList) => initialFilterConfigs?.activeFilters?.owner?.ids?.includes(item.id))
          : recentSearchHistory?.owner,
    };
  };

  //it will return maximum of 5 required formated record for dropdown search suggestion
  const getDropdownSearchFilterData = (documentsTableData: TableData[]) => {
    const requiredColumns = [...(props.configs?.table?.columns ?? [])];
    const { filterSearchedText = {}, getSearchSuggestionHistory } = initialFilterConfigs || {};
    const { file_name, owner } = filterSearchedText;
    const recentDocumentSearchedData = getSearchSuggestionHistory(
      selectedWorkspace.workspace_type_route?.toLocaleLowerCase() ?? WorkspaceType.AR,
      getViewCategory()
    );
    const previousData = getPreviousSuggestedData(recentDocumentSearchedData);

    /**
     * if user searching for result then return result data along with previous selected data
     * else return max of 5 recent searched data if that is exist
     */
    const documentData: DropdownSuggestionListData[] =
      !file_name?.value || file_name?.value.length < NUMERIC_VALUES.CONSTANT_THREE
        ? (previousData?.fileName ?? []).slice(NUMERIC_VALUES.CONSTANT_ZERO, NUMERIC_VALUES.CONSTANT_FIVE)
        : [...(previousData?.fileName ?? []), ...(documentsTableData ?? [])];

    const ownerData: DropdownSuggestionListData[] =
      !owner?.value || owner?.value.length < NUMERIC_VALUES.CONSTANT_THREE
        ? (previousData?.owner ?? []).slice(NUMERIC_VALUES.CONSTANT_ZERO, NUMERIC_VALUES.CONSTANT_FIVE)
        : [...(previousData?.owner ?? []), ...(documentsTableData ?? [])];

    const documentDropdownList = documentData
      .filter(({ file_name, primaryText }) => file_name || primaryText)
      .map(({ id, file_name, primaryText }) => ({
        id,
        primaryText: file_name ?? primaryText,
        isAttachemntRequired: false,
        selected: initialFilterConfigs?.activeFilters?.file_name?.ids?.includes(id) ?? false,
        secondaryText: "",
        isAvatarRequired: false,
      }));

    const ownerDropdownList = ownerData
      .filter(({ owner, primaryText }) => owner || primaryText)
      .map(({ id, owner, primaryText }) => ({
        id,
        primaryText: owner ?? primaryText,
        isAttachemntRequired: false,
        selected: initialFilterConfigs?.activeFilters?.owner?.ids?.includes(id) ?? false,
        secondaryText: "",
        isAvatarRequired: false,
      }));

    /*
     * This code first creates a Map object by mapping each object in the original array to an array with two elements:
     * the key and the value. The Map constructor can then be called with the resulting array of key-value pairs to create
     * a new Map object. Finally, the Array.from method is used to convert the Map object back to an array, where the map
     * function is used to only return the values from the key-value pairs.
     */

    const reverseDocumentData = [...documentDropdownList].reverse();
    const reverseOwnerData = [...ownerDropdownList].reverse();

    const uniqueFileNameSuggestions = Array.from(new Map(reverseDocumentData.map((obj) => [obj.primaryText, obj])))
      .reverse()
      .map(([, value]) => value);
    const uniqueOwnerSuggestions = Array.from(new Map(reverseOwnerData.map((obj) => [obj.primaryText, obj])))
      .reverse()
      .map(([, value]) => value);

    return requiredColumns.map((column) => {
      if (column.accessor === "file_name") {
        return { ...column, dropdownSearchListItems: uniqueFileNameSuggestions };
      }
      if (column.accessor === "owner") {
        return { ...column, dropdownSearchListItems: uniqueOwnerSuggestions };
      }
      return column;
    });
  };

  function createFilterQuery(field: string, value: string) {
    if (value && value.length >= NUMERIC_VALUES.CONSTANT_THREE) {
      return `qa[${field}_cont_any]=${encodeURIComponent(value)}`;
    }
    return "";
  }

  useEffect(() => {
    const { filterSearchedText = {} } = initialFilterConfigs;
    const { file_name = { value: "" }, owner = { value: "" } } = filterSearchedText;

    const filters: string[] = [];

    const addFilterQuery = (field: string, value: string) => {
      if (value.length >= NUMERIC_VALUES.CONSTANT_THREE) {
        const query = createFilterQuery(field, value);
        filters.push(query);
      }
    };

    addFilterQuery("file_name", file_name.value);
    addFilterQuery("owner_name", owner.value);

    setIsColumnsConfigDataLoading(true);

    const fetchData = async () => {
      try {
        let res;
        if (filters.length > NUMERIC_VALUES.CONSTANT_ZERO) {
          const query = filters.join("&");
          res = await fetchDocumentsTableData("all", query, NUMERIC_VALUES.CONSTANT_ONE, NUMERIC_VALUES.CONSTANT_TWENTY);
        } else {
          res = { totalCount: NUMERIC_VALUES.CONSTANT_ZERO, records: [] };
        }

        setIsNoSearchResultFound(!res.totalCount);
        setColumnsConfigData(getDropdownSearchFilterData(res.records));
      } catch (error) {
        setIsNoSearchResultFound(true);
        console.log(error);
      } finally {
        setIsColumnsConfigDataLoading(false);
      }
    };

    fetchData()?.catch(() => {
      setIsNoSearchResultFound(true);
    });
  }, [
    initialFilterConfigs?.filterSearchedText?.file_name?.value,
    initialFilterConfigs?.filterSearchedText?.owner?.value,
    initialFilterConfigs?.filterQuery,
  ]);

  // create deselected ids ransac query when user deselect any records after selecting all
  useEffect(() => {
    const { hasAllRecordsSelectedButtonClicked, isAllRecordsSelected, tableRecords } = selectionConfig;

    if (!hasAllRecordsSelectedButtonClicked?.current || isAllRecordsSelected || !tableRecords?.allRecords) {
      if (deselectedRowsFilter) {
        setDeselectedRowsFilter("");
      }
      return;
    }

    const deselectedIds = tableRecords?.allRecords?.filter((item) => !item?.isSelected)?.map((deselectedItem) => deselectedItem?.id);
    let filter = "";
    deselectedIds.forEach((id) => {
      filter = filter ? `${filter}&qa[id_not_in][]=${id}` : `qa[id_not_in][]=${id}`;
    });
    setDeselectedRowsFilter(filter);
  }, [selectionConfig?.tableRecords?.selectedRecordsIds]);

  //using caching data to update the footer at the same time
  const updatedPaginationObject = {
    ...paginationConfig,
    ...TableUtils.getUpdatePaginationPageState(paginationConfig, cachedData?.totalCount, cachedData?.pageSize),
  };

  return (
    <div className="documents-wrapper with-header-btn">
      <div className="header-button">
        <Button icon={<Upload />} alignIcon="left" onClick={() => setFileUploaderOpen(true)}>
          Upload
        </Button>
      </div>

      <Table
        configs={{
          //Added the feature flag for the newly added filters excpet which are already there on Production
          columnConfigs: columnsConfigData?.map((item) => {
            if (["file_type", "created_at", "owner", "actionHeader", "actionname"].includes(item.accessor)) {
              return { ...item, rowDataType: cellRendererMapping[item?.rowDataType] };
            } else {
              return { ...item, rowDataType: cellRendererMapping[item?.rowDataType], enableFilter: isFiltersEnabled };
            }
          }),
          initialTableConfigs: {
            name: "Attachments",
            type: "Attachment",
            footerTableTypeText: "Attachment",
            defaultSortKey: "created_at desc",
            loading: isLoading,
          },
          paginationConfigs: { ...updatedPaginationObject, enableNavigator: true, enableSummary: true },
          initialSortConfigs: sortConfig,
          selectionConfig: selectionConfig,
          initialFilterConfigs: {
            ...initialFilterConfigs,
            dateFormat: calendarDateFormat,
            isSuggestionDataLoading: isColumnsConfigDataLoading,
            category: getViewCategory(),
            workspace: selectedWorkspace.workspace_type_route?.toLocaleLowerCase() ?? WorkspaceType.AR,
            isNoSearchResultFound,
          },
        }}
        rows={(cachedData?.records ?? [])?.map((rec: any) => {
          return {
            ...rec,
            read: true,
            created_at: new Date(rec.created_at).getTime() / DEFAULT_NUMERIC_VALUES.DEFAULT_THOUSAND,
          };
        })}
        handlers={{
          onRowClick: handleRowClick,
        }}
        emptyMessage={{ title: EMPTY_ACTIONS_MESSAGE, caption: EMPTY_DOCUMENTS_CAPTION_MESSAGE }}
        secondaryRowActions={ellipsisActions}
        userActions={rowSelectButtons}
        hoverBar={false}
        visibility={{
          exportBtn: false,
          hoverbar: false,
        }}
      />
      <FileUploader
        open={fileUploaderOpen}
        toggleFunction={toggleFileUpload}
        tableName={"Company"}
        objectId={companyData.companyId ?? ""}
        attachmentType={fileType}
        origin={"connections"}
        customerId={customerId}
        workspaceId={selectedWorkspace?.id ?? FallbackTypes.Id}
      >
        <p className="caption-med caption-primary-dark">Choose Your File Type</p>
        <Autocomplete
          options={["W-9", "W-8BEN", "Payment Instructions"]}
          blurOnSelect
          onChange={async (_event, value: string) => {
            setFileType(value);
          }}
          value={fileType}
          disableClearable
          disablePortal
          fullWidth
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                placeholder: "Choose A File Type",
              }}
            />
          )}
          isOptionEqualToValue={(option, value) => {
            return option === value;
          }}
        />
      </FileUploader>
    </div>
  );
}

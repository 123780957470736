import { makeStyles } from "@mui/styles";
import styles from "../../../css/_export.module.scss";

const useRangeSliderStyle = () => {
  const useStyles = makeStyles({
    divRoot: {
      border: `${styles.borderline} solid ${styles.grey90}`,
      background: `${styles.white100}`,
      borderRadius: styles.small,
    },
    sliderContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "0.75rem",
      alignItems: "center",
    },
    sliderRoot: {
      color: styles.link,
      height: styles.tiny,
      width: "96%",
      "& .MuiSlider-thumb": {
        height: styles.large,
        width: styles.large,
        backgroundColor: styles.white100,
        border: `${styles.borderline} solid ${styles.grey40}`,
        "&:hover": {
          boxShadow: `0 0 0 ${styles.tiny} rgba(58, 133, 137, 0.16)`,
        },
        "& .range-slider-bar": {
          width: styles.borderline,
          height: styles.small,
          backgroundColor: styles.grey40,
          marginLeft: styles.borderline,
          marginRight: styles.borderline,
        },
      },
      "& .MuiSlider-track": {
        height: styles.atom,
      },
      "& .MuiSlider-rail": {
        color: styles.grey80,
        height: styles.borderline,
      },
    },
    inputWrapper: {
      display: "flex",
      gap: styles.medium,
      alignItems: "center",
    },
    inputContainer: {
      color: styles.primary,
      fontFamily: styles.primaryFontFamily,
      fontSize: styles.fontSizeSM,
      fontWeight: "400",
      padding: "0",
      textIndent: styles.medium,
      background: styles.white100,
      border: `${styles.borderline} solid ${styles.grey90}`,
      borderRadius: styles.small,
      boxSizing: "border-box",
      height: styles.xlarge,
      width: "100%",
      "&::placeholder": {
        fontStyle: "normal",
        fontWeight: "normal",
        color: styles.grey70,
      },
      "&:focus": {
        background: styles.white100,
        borderColor: styles.grey98,
      },
      "&:focus-visible": {
        outline: "none",
      },
      "&:disabled": {
        backgroundColor: styles.grey98,
        color: styles.grey70,
        cursor: "not-allowed",
      },
      "& input[type=number]": {
        MozAppearance: "textfield",
      },
      "& input[type=number]::-webkit-outer-spin-button": {
        WebkitAppearance: "none",
        margin: "0",
      },
      "& input[type=number]::-webkit-inner-spin-button": {
        WebkitAppearance: "none",
        margin: "0",
      },
      "& .MuiInputAdornment-root": {
        "& .MuiTypography-root": {
          fontFamily: styles.primaryFontFamily,
          fontWeight: "400",
          fontSize: styles.fontSizeSM,
          lineHeight: styles.lineHeightMD,
          color: styles.grey10,
        },
      },
    },
  });
  return useStyles();
};

export default useRangeSliderStyle;

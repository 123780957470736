import { Drawer } from "@mui/material";
import React from "react";
import Button from "../../../../../library/Button/Button";
import "./ContactMoveComponent.scss";
import { NUMERIC_VALUES } from "../../../../../../constants/NumericConstants";
import DebouncedAutocomplete from "../../../../../library/DebouncedAutocomplete/tsx/DebouncedAutocomplete";
import { CONNECTION_LIST } from "../../../../../../constants/config";

type ContactMoveComponentProps = {
  ids: string[];
  handleMove: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isLoadingCompanyList: boolean;
  setSelectedCompany: React.Dispatch<React.SetStateAction<Partial<CompanyV2> | null>>;
  onClose: () => void;
  open: boolean;
  isLoading: boolean;
  fetchCompanyList: (value: string) => Promise<{ records: CompanyV2[]; totalRecords: number }>;
};

export const ContactMoveComponent = (props: ContactMoveComponentProps) => {
  /** autocomplete helpers */
  const getOptionInfo = (company: CompanyV2) => {
    return {
      key: company.company_id,
      label: company.company_name ?? "",
    };
  };

  const isOptionEqualToValue = (company: CompanyV2, value: CompanyV2) => company.company_id === value.company_id;

  return (
    <Drawer className="activity-feed-action-drawer" open={props.open} anchor="right" disablePortal onClose={() => props.onClose()}>
      <div className="activity-flyout" style={{ overflow: "hidden" }}>
        <h3 className="header">Move Contact</h3>
        <p className="subheader body1">Move this contact to another connection.</p>
        <label htmlFor={"string"}>Choose a connection</label>
        <div>
          <DebouncedAutocomplete<CompanyV2>
            network={{
              apiCall: props.fetchCompanyList,
              debounce: true,
              delay: NUMERIC_VALUES.CONSTANT_FIVE_HUNDRED,
            }}
            data={{
              isOptionEqualToValue,
              optionParser: getOptionInfo,
              onOptionSelect: (option: CompanyV2 | null) => props.setSelectedCompany(option),
              searchPlaceholder: CONNECTION_LIST,
            }}
          />
        </div>
        <div className="btn-grp">
          <Button className={"move-icon-btn"} size="lg" onClick={props.handleMove} loading={props.isLoading}>
            Move
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

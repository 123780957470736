import React from "react";
import { ActiveFilterSearchedText, ActiveFilterType, ColumnFilter, FilterHook, SearchedFilterData } from "../types/hooks.types";
import { DateTime } from "luxon";
import { TableFilterComparatorConstant, TableFilterConstant } from "../../constants/table.constants";
import { DropdownItemList } from "../../TableFilters/Presets/preset.filter.types";
import { useLocation } from "react-router-dom";
import _ from "underscore";
import { NUMERIC_VALUES } from "../../constants/numeric.constants";

/**
 * hook provides an interface to table column filters, to reduce the
 * state redudancy on table and component implementation side; devs
 * can import this where they are implementing Table component.
 *
 * hooks handles default filters required for backend, also handle
 * query generation for both v1 and v2, provided comparator is passed
 * through through column configurations.
 *
 * Filter specific column configurations are -
 * enableFilter: boolean;
 * filterType: string;
 * filterComparator: string;
 *
 * @param defaultFilters [string], default filters required for table;
 * example - company_type on connection table
 * @param isRansacFilter boolean, if true, generated filter query will
 * be for v2 backend
 * @param resetPageNumber optional callback function to reset page number once we applied any filters
 * @returns
 */
const useColumnFilters: FilterHook = (isRansacFilter = false, defaultFilters, resetPageNumber) => {
  // Column Filters
  const [activeFilters, setActiveFilters] = React.useState<ActiveFilterType>({});
  const [filterSearchedText, setFilterSearchedText] = React.useState<ActiveFilterSearchedText>({});
  const [filterQuery, setFilterQuery] = React.useState<string>("");
  // Uniquely Open filters at a time
  const [currentActiveFilter, setCurrentActiveFilter] = React.useState<string | null>(null);
  const location = useLocation();

  //To persist the applied filters whene user navigates back from details , set the applied filters to url
  const updateUrlWithFilters = (filters: ActiveFilterType) => {
    const url = new URL(window.location.href);
    url.searchParams.set("filters", JSON.stringify(filters));
    window.history.pushState({}, "", url.toString());
  };

  //Retrieved the applied filters from url
  const getAppliedFiltersFromUrl = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const filtersString = urlSearchParams.get("filters");
    let appliedFilters = activeFilters;
    try {
      if (filtersString) {
        const urlFilters = JSON.parse(filtersString);
        if (!_.isEmpty(urlFilters)) {
          appliedFilters = urlFilters;
        }
      }
    } catch (error) {
      // Handle parsing error
      console.error("Error parsing JSON:", error);
    }
    return appliedFilters;
  };
  /**
   * Active filter can be single object, example - string, number OR
   * it can have multiple objects to accomodate range selection on UI
   * example - date range, amount range
   *
   * @param key filter accessor
   * @param comparator filter comparator
   * @param value filter value entered/selected by the user
   */
  const onApplyFilterHandler = (filters: ColumnFilter[]) => {
    //on applied filter page should rest to 1
    if (resetPageNumber) {
      resetPageNumber(NUMERIC_VALUES.CONSTANT_ONE);
    }
    const newFilters = Object.assign({}, ...filters.map((item) => ({ [item.key]: item })));
    updateUrlWithFilters({ ...activeFilters, ...newFilters });
    setActiveFilters({ ...activeFilters, ...newFilters });
  };

  // Clear Column Filter Selection
  const onClearFilterHandler = (key: string) => {
    if (activeFilters?.[key]) {
      const activeFilterList: typeof activeFilters = {};
      Object.assign(activeFilterList, activeFilters);
      delete activeFilterList[key];
      updateUrlWithFilters(activeFilterList);
      setActiveFilters(activeFilterList);
      //on clear page should rest to 1
      if (resetPageNumber) {
        resetPageNumber(NUMERIC_VALUES.CONSTANT_ONE);
      }
    }
  };

  // return prepared query based on filter comparator defined in TableFilterComparatorConstant
  const getPreparedRansacQuery = (filter: ColumnFilter): string => {
    switch (filter.comparator) {
      case TableFilterComparatorConstant.EQUALS_TO:
        return `qa[${filter.key}_eq]=${encodeURIComponent(filter.value)}`;
      case TableFilterComparatorConstant.LESS_THAN:
        return `qa[${filter.key}_lt]=${filter.value}`;
      case TableFilterComparatorConstant.LESS_THAN_EQUAL:
        return `qa[${filter.key}_lteq]=${filter.value}`;
      case TableFilterComparatorConstant.GREATER_THAN:
        return `qa[${filter.key}_gt]=${filter.value}`;
      case TableFilterComparatorConstant.GREATER_THAN_EQUAL:
        return `qa[${filter.key}_gteq]=${filter.value}`;
      case TableFilterComparatorConstant.IN_BETWEEN:
        return `qa[${filter.key}_gteq]=${filter?.rangeValue?.[0]}&qa[${filter.key}_lteq]=${filter?.rangeValue?.[1]}`;
      case TableFilterComparatorConstant.ANY:
        return `qa[${filter.key}_any]=${encodeURIComponent(filter.value)}`;
      case TableFilterComparatorConstant.MULTI_VALUE:
        return filter.rangeValue?.map((item) => `qa[${filter.key}_in][]=${encodeURIComponent(item)}`).join("&") ?? "";
      case TableFilterComparatorConstant.TODAY:
        return `qa[${filter.key}_eq]=${DateTime.now().toFormat("yyyy-MM-dd")}`;
      case TableFilterComparatorConstant.THIS_WEEK:
        return `qa[${filter.key}_gteq]=${DateTime.now().startOf("week").toFormat("yyyy-MM-dd")}&qa[${filter.key}_lteq]=${DateTime.now()
          .endOf("week")
          .toFormat("yyyy-MM-dd")}`;
      case TableFilterComparatorConstant.THIS_MONTH:
        return `qa[${filter.key}_gteq]=${DateTime.now().startOf("month").toFormat("yyyy-MM-dd")}&qa[${filter.key}_lteq]=${DateTime.now()
          .endOf("month")
          .toFormat("yyyy-MM-dd")}`;
      case TableFilterComparatorConstant.LAST_MONTH:
        return `qa[${filter.key}_gteq]=${DateTime.now().minus({ month: 1 }).startOf("month").toFormat("yyyy-MM-dd")}&qa[${
          filter.key
        }_lteq]=${DateTime.now().minus({ month: 1 }).endOf("month").toFormat("yyyy-MM-dd")}`;
      case TableFilterComparatorConstant.LAST_THREE_MONTHS:
        return `qa[${filter.key}_gteq]=${DateTime.now().minus({ month: 3 }).toFormat("yyyy-MM-dd")}&qa[${filter.key}_lteq]=${DateTime.now().toFormat(
          "yyyy-MM-dd"
        )}`;
      case TableFilterComparatorConstant.LAST_SIX_MONTHS:
        return `qa[${filter.key}_gteq]=${DateTime.now().minus({ month: 6 }).toFormat("yyyy-MM-dd")}&qa[${filter.key}_lteq]=${DateTime.now().toFormat(
          "yyyy-MM-dd"
        )}`;
      case TableFilterComparatorConstant.LAST_TWELEVE_MONTHS:
        return `qa[${filter.key}_gteq]=${DateTime.now().minus({ month: 12 }).toFormat("yyyy-MM-dd")}&qa[${filter.key}_lteq]=${DateTime.now().toFormat(
          "yyyy-MM-dd"
        )}`;
      case TableFilterComparatorConstant.YEAR_TO_DATE:
        return `qa[${filter.key}_gteq]=${DateTime.now().startOf("year").toFormat("yyyy-MM-dd")}&qa[${filter.key}_lteq]=${DateTime.now().toFormat(
          "yyyy-MM-dd"
        )}`;
      case TableFilterComparatorConstant.LAST_YEAR_TO_DATE:
        return `qa[${filter.key}_gteq]=${DateTime.now().minus({ year: 1 }).startOf("year").toFormat("yyyy-MM-dd")}&qa[${
          filter.key
        }_lteq]=${DateTime.now().toFormat("yyyy-MM-dd")}`;
      case TableFilterComparatorConstant.CUSTOM_DATE:
        return `qa[${filter.key}_gteq]=${filter?.rangeValue?.[0]}&qa[${filter.key}_lteq]=${filter?.rangeValue?.[1]}`;
      default:
        return "";
    }
  };

  //TODO: Needs to implement for the remaining TableFilterComparatorConstant if required
  const getPreparedPlatformQuery = (filter: ColumnFilter) => {
    switch (filter.comparator) {
      case TableFilterComparatorConstant.EQUALS_TO:
        return `(${filter?.searchlightField} EQ '${filter.value}')`;
      case TableFilterComparatorConstant.LESS_THAN:
        return `(${filter?.searchlightField} LT '${filter.value}')`;
      case TableFilterComparatorConstant.LESS_THAN_EQUAL:
        return `(${filter?.searchlightField} lE '${filter.value}')`;
      case TableFilterComparatorConstant.GREATER_THAN:
        return `(${filter?.searchlightField} GT '${filter.value}')`;
      case TableFilterComparatorConstant.GREATER_THAN_EQUAL:
        return `(${filter?.searchlightField} GE '${filter.value})'`;
      case TableFilterComparatorConstant.IN_BETWEEN:
        return `(${filter?.searchlightField} BETWEEN '${filter?.rangeValue?.[0]}' AND '${filter?.rangeValue?.[1]}')`;
      case TableFilterComparatorConstant.MULTI_VALUE:
        return filter.rangeValue?.length ? `(${filter?.searchlightField} IN (${filter.rangeValue?.toString()}))` : "";
      case TableFilterComparatorConstant.TODAY:
        return `(${filter?.searchlightField} EQ '${DateTime.now().toFormat("yyyy-MM-dd")}')`;
      case TableFilterComparatorConstant.THIS_WEEK:
        return `(${filter?.searchlightField} BETWEEN '${DateTime.now().startOf("week").toFormat("yyyy-MM-dd")}' AND '${DateTime.now()
          .endOf("week")
          .toFormat("yyyy-MM-dd")}')`;
      case TableFilterComparatorConstant.THIS_MONTH:
        return `(${filter?.searchlightField} BETWEEN '${DateTime.now().startOf("month").toFormat("yyyy-MM-dd")}' AND '${DateTime.now()
          .endOf("month")
          .toFormat("yyyy-MM-dd")}')`;
      case TableFilterComparatorConstant.LAST_THREE_MONTHS:
        return `(${filter?.searchlightField} BETWEEN '${DateTime.now()
          .minus({ month: 3 })
          .endOf("month")
          .toFormat("yyyy-MM-dd")}' AND '${DateTime.now().minus({ month: 1 }).endOf("month").toFormat("yyyy-MM-dd")}')`;
      case TableFilterComparatorConstant.LAST_SIX_MONTHS:
        return `(${filter?.searchlightField} BETWEEN '${DateTime.now()
          .minus({ month: 6 })
          .endOf("month")
          .toFormat("yyyy-MM-dd")}' AND '${DateTime.now().minus({ month: 1 }).endOf("month").toFormat("yyyy-MM-dd")}')`;
      case TableFilterComparatorConstant.LAST_TWELEVE_MONTHS:
        return `(${filter?.searchlightField} BETWEEN '${DateTime.now()
          .minus({ month: 12 })
          .endOf("month")
          .toFormat("yyyy-MM-dd")}' AND '${DateTime.now().minus({ month: 1 }).endOf("month").toFormat("yyyy-MM-dd")}')`;
      case TableFilterComparatorConstant.LAST_YEAR_TO_DATE:
        return `(${filter?.searchlightField} BETWEEN '${DateTime.now().startOf("year").toFormat("yyyy-MM-dd")}' AND '${DateTime.now().toFormat(
          "yyyy-MM-dd"
        )}')`;
      case TableFilterComparatorConstant.YEAR_TO_DATE:
        return `(${filter?.searchlightField} BETWEEN '${DateTime.now()
          .minus({ year: 1 })
          .startOf("year")
          .toFormat("yyyy-MM-dd")}' AND '${DateTime.now().toFormat("yyyy-MM-dd")}')`;
      case TableFilterComparatorConstant.CUSTOM_DATE:
        return `(${filter?.searchlightField} BETWEEN '${filter?.rangeValue?.[0]}' AND '${filter?.rangeValue?.[1]}')`;
      default:
        return "";
    }
  };
  /**
   * generates filter query for the table -
   * if isRansac = true: returns query for v2 backend
   * else: retuns query for v1 backend
   *
   * references:
   * Platform's Search Light Query: https://developer.lockstep.io/docs/querying-with-searchlight
   * Ruby's Ransac Query: https://activerecord-hackery.github.io/ransack/getting-started/search-matches/
   */
  const generateFilterQuery = (filters: ColumnFilter[], getRansacQuery: boolean, preDefinedFilters?: string[]): string => {
    let constructedFilter = "";
    const list: string[] = [];
    preDefinedFilters?.forEach((query: string) => list.push(query));
    if (getRansacQuery) {
      filters.forEach((filter: ColumnFilter) => list.push(getPreparedRansacQuery(filter)));
      constructedFilter = `${list?.filter(Boolean).join("&")}`;
    } else {
      // getPlatformFilters
      filters.forEach((filter: ColumnFilter) => list.push(getPreparedPlatformQuery(filter)));
      constructedFilter = `${list?.filter(Boolean).join(" AND ")}`;
    }
    return constructedFilter;
  };

  /*
   * Toggle Open Filters Uniquely
   */
  const toggleFiltersUniquely = (status: boolean, filterKey: string) => {
    setCurrentActiveFilter(status ? filterKey : null);
  };

  /*
   * Set the searh field text for filters
   */
  const updateFiltersSearchedText = (searchedTextData: SearchedFilterData[] = []) => {
    const newFilters = Object.assign({}, ...searchedTextData.map((item: SearchedFilterData) => ({ [item.key]: item })));
    setFilterSearchedText({ ...filterSearchedText, ...newFilters });
  };

  // Creating new recent search history if no data in LS
  const getSearchSuggestionHistory = (worksapceName: string, category: string) => {
    const searchSuggestionHistory = localStorage.getItem(TableFilterConstant.FILTER_SUGGESTIONS);
    const categoryHistory = JSON.parse(searchSuggestionHistory as string)?.[worksapceName]?.[category];

    if (!categoryHistory) {
      localStorage.setItem(
        TableFilterConstant.FILTER_SUGGESTIONS,
        JSON.stringify({
          ap: {
            ...JSON.parse(searchSuggestionHistory as string)?.["ap"],
            [category]: {},
          },
          ar: {
            ...JSON.parse(searchSuggestionHistory as string)?.["ar"],
            [category]: {},
          },
          aw: {
            ...JSON.parse(searchSuggestionHistory as string)?.["aw"],
            [category]: {},
          },
        })
      );
      return {};
    }
    return categoryHistory;
  };

  // Adding new searched list to local storage
  const addSearchSuggestionHistory = (workspaceName: string, suggestionItems: DropdownItemList, columnName: string, category: string) => {
    const recentFilterSuggestion = JSON.parse(localStorage.getItem(TableFilterConstant.FILTER_SUGGESTIONS) ?? "{}");
    const recentViewCategory = recentFilterSuggestion?.[workspaceName]?.[category] ?? {};

    /**
     * creates a new array newSelectedData. This array contains the suggestionItems object and from recentViewCategory[columnName]
     * checks if the primaryText property of each item is not equal to the primaryText of suggestionItems to create unique data.
     */
    const newSelectedData: DropdownItemList[] = [suggestionItems];

    recentViewCategory?.[columnName]?.forEach((item: DropdownItemList) => {
      if (item.primaryText !== suggestionItems?.primaryText) {
        newSelectedData.push(item);
      }
    });

    const newFilterSuggestionData = {
      ...recentFilterSuggestion[workspaceName],
      [category]: {
        ...recentViewCategory,
        [columnName]: newSelectedData,
      },
    };

    const updatedFilterSuggestions = {
      ...recentFilterSuggestion,
      [workspaceName]: newFilterSuggestionData,
    };

    localStorage.setItem(TableFilterConstant.FILTER_SUGGESTIONS, JSON.stringify(updatedFilterSuggestions));
  };

  //remove ls filter-suggestions data
  const removeSearchSuggestionStorage = (): void => {
    localStorage.removeItem(TableFilterConstant.FILTER_SUGGESTIONS);
  };

  /*
   * Updating Data on filter change
   */
  React.useEffect(() => {
    const filters = getAppliedFiltersFromUrl();
    setActiveFilters({ ...activeFilters, ...filters });
  }, [location.search]);

  React.useEffect(() => {
    setFilterQuery(generateFilterQuery(Object.values(activeFilters), isRansacFilter, defaultFilters));
  }, [activeFilters]);

  return {
    clearFilter: onClearFilterHandler,
    setFilter: onApplyFilterHandler,
    filterControls: {
      open: currentActiveFilter,
      toggleOpen: toggleFiltersUniquely,
    },
    activeFilters,
    filterQuery,
    filterSearchedText,
    updateFiltersSearchedText,
    generateFilterQuery,
    getSearchSuggestionHistory,
    addSearchSuggestionHistory,
    removeSearchSuggestionStorage,
  };
};

export default useColumnFilters;

import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Typography } from "@mui/material";
import { EmailOutlined, LocalPhoneOutlined, KeyboardArrowDown } from "@mui/icons-material";
import { ContactsCardPropTypes } from "./types";
import { ContactsCardRoot, ContactType, ContactHeaderPanel, ContactDetails } from "../styled/styled";
import { NUMERIC_VALUES } from "../../constants/numeric.constants";

export default function ContactsCard({ title, activeCard, contacts, setActiveCard }: ContactsCardPropTypes): React.ReactElement {
  const [expanded, setExpanded] = useState<number | false>(activeCard === title ? NUMERIC_VALUES.CONSTANT_ZERO : false);
  const handleChange = (panel: number) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  const stringAvatar = (name: string) => {
    return {
      children: name
        ?.match(/(\b\S)?/g)
        ?.join("")
        ?.match(/(^\S|\S$)?/g)
        ?.join("")
        .toUpperCase(),
    };
  };

  return (
    <ContactsCardRoot raised={true} onClick={() => setActiveCard(title)}>
      <ContactType>{title}</ContactType>
      {contacts.map((contact, index) => {
        return (
          <Accordion disableGutters key={contact.email_address} expanded={activeCard === title && expanded === index} onChange={handleChange(index)}>
            <AccordionSummary expandIcon={<KeyboardArrowDown />}>
              <ContactHeaderPanel>
                <Avatar {...stringAvatar(contact.contact_name ?? "...")} />
                <div className="contact-header-info">
                  <Typography noWrap={true} className="contact-name">
                    {contact.contact_name ?? "- -"}
                  </Typography>
                  <Typography noWrap={true} className="contact-email-tooltip">
                    {expanded === index ? "" : contact.email_address ?? ""}
                  </Typography>
                </div>
              </ContactHeaderPanel>
            </AccordionSummary>
            <AccordionDetails>
              <ContactDetails>
                <LocalPhoneOutlined />
                <Typography noWrap={false} className="contact-detail">
                  {contact.phone ?? "-"}
                </Typography>
              </ContactDetails>
              <ContactDetails>
                <EmailOutlined />
                <Typography noWrap={false} className="contact-detail">
                  {contact.email_address ?? "-"}
                </Typography>
              </ContactDetails>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </ContactsCardRoot>
  );
}

// import React from "react";
import usePdfDownloadValidation from "./usePdfDownloadValidation";
import { WindowAttachment, ERPAttachment } from "../../components/library/AddNewActivityDropdown/types/windowAttachment.types";
// import _ from "underscore";

type ValidatedAttachmentDetails = WindowAttachment & { downloadSupported: boolean; validationMessage: string };

/**
 * @function useAttachmentValidation
 * wraps usePdfDownloadValidation to enable validation and messaging capabilities
 * validates multiple activity window attachments
 *
 * Currently supports attachment data types - TemplateAttachment
 *
 * @returns validateData function
 */
const useAttachmentValidation = () => {
  const { determineDownloadSupport, obtainValidationMessage, app } = usePdfDownloadValidation();

  /**
   * @function validate
   * pure function, runs validation logic over an attachment
   *
   * @param attachment attachment data
   * @returns attachment with validation attributes
   */
  const validate = (attachment: WindowAttachment, app: AppModel | undefined): ValidatedAttachmentDetails => {
    const res = determineDownloadSupport((attachment.data as ERPAttachment)?.transactionTypeCode, app);
    const msg = obtainValidationMessage(
      res.isNetworkFailure,
      res.isAppTypeSupported,
      res.isTypeCodeSupported,
      app?.name ?? "",
      (attachment.data as ERPAttachment)?.transactionTypeCode ?? ""
    );
    return {
      ...attachment,
      downloadSupported: res.isAppTypeSupported && res.isTypeCodeSupported && !res.isNetworkFailure,
      validationMessage: msg,
    };
  };

  /**
   * @function validateData
   * pure function, runs validation to array of window attachments
   *
   * @param attachments list of window attachment of TemplateAttachment data type
   * @returns list of attachment with validation attributes
   */
  const validateData = (attachments: WindowAttachment[]): ValidatedAttachmentDetails[] => attachments.map((attachment) => validate(attachment, app));

  return {
    validateData,
  };
};

export default useAttachmentValidation;

import React from "react";
import { styled } from "@mui/system";
import { DateRangeCalendar } from "@mui/x-date-pickers-pro";
import styles from "../../../../../css/_export.module.scss";
import { CustomeDateRangeCalendarProps } from "./daterange.calendar.types";

const CustomDateRangeCalendar: React.FC<CustomeDateRangeCalendarProps> = ({ className, ...props }) => (
  <DateRangeCalendar {...props} className={className} />
);

const MuiDateRangeCalendar = styled(CustomDateRangeCalendar, { name: "MuiDateRangeCalendar" })({
  width: "32rem",
  height: "18.375rem",
  border: `${styles.borderline} solid ${styles.grey93}`,
  borderRadius: "0.25rem",
  "& .MuiDateRangeCalendar-monthContainer": {
    width: "16rem",
    "&:not(:last-of-type)": {
      borderRight: `${styles.borderline} solid ${styles.grey93}`,
    },
    "& .MuiPickersArrowSwitcher-root": {
      height: "3.25rem",
      padding: `${styles.base} ${styles.large}${styles.medium}`,
      "& .MuiSvgIcon-root": {
        fill: styles.grey40,
      },
      "& .MuiTypography-root": {
        fontFamily: styles.primaryFontFamily,
        fontWeight: "600",
        fontSize: styles.fontSizeMD,
        lineHeight: styles.lineHeightL,
        color: styles.grey40,
      },
    },
    "& .MuiDayCalendar-header": {
      height: "2rem",
      "& .MuiDayCalendar-weekDayLabel": {
        height: "2rem",
        width: "2rem",
        margin: "0",
      },
    },
    "& .MuiDayCalendar-slideTransition": {
      minWidth: "16rem",
      minHeight: "13.125rem",
      "& .MuiDayCalendar-weekContainer": {
        height: "2rem",
        "& .MuiDateRangePickerDay-root": {
          height: "2rem",
          width: "2rem",
          "& .MuiDateRangePickerDay-rangeIntervalPreview": {
            borderWidth: styles.borderline,
            "& .MuiPickersDay-root": {
              height: "2rem",
              width: "2rem",
              transform: "scale(1)",
              fontWeight: "400",
              fontFamily: styles.primaryFontFamily,
              fontSize: styles.fontSizeSM,
              lineHeight: styles.lineHeightMD,
              color: styles.grey10,
            },
            "& .Mui-selected": {
              backgroundColor: styles.link,
              color: "#E8EAF2",
            },
          },
        },
        "& .MuiDateRangePickerDay-hiddenDayFiller": {
          "& .MuiDateRangePickerDay-rangeIntervalPreview": {
            background: styles.white100,
          },
        },
        "& .MuiDateRangePickerDay-rangeIntervalDayHighlight": {
          background: "#e1f0fa",
          "& .MuiButtonBase-root": {
            color: styles.grey10,
          },
        },
        "& .MuiDateRangePickerDay-rangeIntervalDayHighlightStart": {
          "& .MuiButtonBase-root": {
            color: styles.grey93,
          },
        },
      },
    },
  },
});

export default MuiDateRangeCalendar;

import _ from "underscore";
import React, { useEffect } from "react";
import { IconButton } from "@mui/material";
import { useHistory, useRouteMatch } from "react-router";
import { AppContext } from "../../../contexts/AppContext";
import { SettingsContext } from "../../../contexts/SettingsContext";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";
import useLocale from "../../../hooks/useLocale";
import { ACCOUNT_ID, UserActions } from "../../../types/enums";
import { enrollmentsClient, syncClient } from "../../../db/accessor";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_TIMEOUTS, NUMERIC_VALUES } from "../../../constants/NumericConstants";
import { SyncStatusCodes } from "../../../constants/SyncConstants";
import { formatTimeElapsed } from "../../../db/utils/date";
import CustomTooltip from "../../library/CustomTooltip/CustomTooltip";
import { TooltipTypes } from "../../../constants/TooltipConstants";
import { Sync } from "../../library/Icons/Icons";
import { useTranslation } from "react-i18next";

function LastSync(): React.ReactElement {
  const { hasPermission, updateUserStatus } = React.useContext(AppContext) as AppType;
  const [connecting, setConnecting] = React.useState(true);
  const { syncHasUpdated, setLastSync, lastSync, setSyncHasUpdated } = React.useContext(SettingsContext) as SettingsType;
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const history = useHistory();
  const { path } = useRouteMatch();
  const { formatDateBasedOnCountry: formatDate } = useLocale();
  const { t } = useTranslation();

  const tooltipTextFormatter = (tooltipConfig: Array<{ header: string; details: string }>) => (
    <div className="last-sync-tooltip-container">
      {tooltipConfig?.map((itemConfig, index) => (
        <div key={`${itemConfig.header}-${index}`} className="last-sync-tooltip-text-container">
          <span className="last-sync-tooltip-text-header">{itemConfig.header}: </span>
          {itemConfig.details}
        </div>
      ))}
    </div>
  );

  const handleSyncClick = async () => {
    // Sync is running, do nothing
    if (!(lastSync?.statusCode === "Failed" || lastSync?.statusCode === "Success" || lastSync?.statusCode === "Cancelled")) {
      return;
    }

    if (!hasPermission(UserActions.RunSync)) {
      history.push(`${path}/settings/accountingSoftware`);
    }

    try {
      await enrollmentsClient
        .getEnrollments("(isActive eq 'true')")
        .then((data: AppEnrollmentModelFetchResult) => {
          return data?.records?.filter((record: AppEnrollmentModel) => record.app.appType === "ERP Connector") ?? [];
        })
        .then((data: AppEnrollmentModel[]) => {
          // Active ERP exists, then run sync
          if (data[0]) {
            syncClient.startSync({ appEnrollmentId: data[0].appEnrollmentId }).then(() => {
              setSyncHasUpdated((prev: number): number => prev + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);
            });
          } else {
            // Otherwise, route to AS settings
            history.push(`${path}/settings/accountingSoftware`);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setConnecting(true);
    const checkSyncStatus = async () => {
      let res: SyncRequestModelFetchResult = {} as SyncRequestModelFetchResult;
      let lastSyncResponse;
      try {
        res = await syncClient.querySync(NUMERIC_VALUES.CONSTANT_TWO);
        const response = res.records;
        lastSyncResponse = response && response[0];
        if (lastSyncResponse) {
          if (lastSyncResponse?.appEnrollmentId) {
            const appResponse = await enrollmentsClient.getEnrollment(lastSyncResponse?.appEnrollmentId);
            if (appResponse) {
              // save information about connected application and it's last sync with platform
              setLastSync({
                ...lastSyncResponse,
                app: {
                  name: appResponse?.app?.name ?? null,
                  appId: appResponse?.app?.appId ?? null,
                },
              });
              setConnecting(false);
            } else {
              setLastSync({ ...lastSyncResponse });
              setConnecting(false);
            }
          } else {
            setLastSync({ ...lastSyncResponse });
            setConnecting(false);
          }
          if (
            lastSyncResponse?.statusCode === "Failed" ||
            lastSyncResponse?.statusCode === "Success" ||
            lastSyncResponse?.statusCode === "Cancelled"
          ) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            clearInterval(pollSync);
          }
        } else {
          setLastSync({ ...lastSync, appEnrollmentId: null });
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          clearInterval(pollSync);
          setConnecting(false);
        }
        return lastSyncResponse;
      } catch (e) {
        setLastSync({ ...lastSync, appEnrollmentId: null });
        setConnecting(false);
      } finally {
        /**
         * initial/first sync -
         * once the last sync is completed, check the count of total sync request
         * created for the enrolled application.
         *
         * Why?
         * Initial sync completion updates the application base currency, which will
         * not be refreshed until user refreshes browser window. Inbox manually checks
         * for initial sync request and makes a background /status refresh call.
         */
        if (res.totalCount === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE && lastSyncResponse?.statusCode === "Success") {
          await updateUserStatus(true);
        }
      }
    };
    checkSyncStatus();
    const pollSync = setInterval(() => {
      checkSyncStatus();
    }, NUMERIC_TIMEOUTS.FIVE_SECOND);

    return () => {
      clearInterval(pollSync);
    };
  }, [syncHasUpdated, selectedWorkspace]);

  const running =
    !connecting &&
    (lastSync?.statusCode === SyncStatusCodes.failure ||
      lastSync?.statusCode === SyncStatusCodes.success ||
      lastSync?.statusCode === SyncStatusCodes.cancelled);

  /**
   * Future Scope:
   * need genetic handleing for application enrollment details for lastSync
   * need  application enrollment data & supporting functions as TS
   * configurations for quick access and maintainability
   *
   * refer: Account application handling for account enrolled applications' data
   */
  const disableSync = lastSync.app?.appId === ACCOUNT_ID.Sage50;
  const enabledSyncMessage = tooltipTextFormatter([
    {
      header: t("header.tooltipMessages.lastSyncInfo.statusLabel", { ns: "home" }),
      details:
        lastSync?.statusCode === SyncStatusCodes.success || lastSync?.statusCode === SyncStatusCodes.cancelled
          ? t("header.tooltipMessages.lastSyncInfo.status.success", { ns: "home" })
          : t("header.tooltipMessages.lastSyncInfo.status.failed", { ns: "home" }),
    },
    {
      header: t("header.tooltipMessages.lastSyncInfo.detailsLabel", { ns: "home" }),
      details: lastSync?.created
        ? t("header.tooltipMessages.lastSyncInfo.createdAt", { createdOn: formatTimeElapsed(lastSync.created), ns: "home" })
        : "N/A",
    },
  ]);

  const syncMessage = disableSync ? t("header.tooltipMessages.lastSyncInfo.sage50DisabledSyncMessage", { ns: "home" }) : enabledSyncMessage;

  return (
    <div className="last-sync-wrapper">
      {_.isEmpty(lastSync.appEnrollmentId) === false || connecting ? (
        running ? (
          <>
            <span id="last-sync-indicator" className={`status-${lastSync.statusCode?.split(" ").join("-")}`} />
            <CustomTooltip
              title={tooltipTextFormatter([
                {
                  header: t("header.tooltipMessages.lastSyncInfo.statusLabel", { ns: "home" }),
                  details:
                    lastSync?.statusCode === SyncStatusCodes.success || lastSync?.statusCode === SyncStatusCodes.cancelled
                      ? t("header.tooltipMessages.lastSyncInfo.connected", { erp: lastSync?.app?.name ?? "N/A", ns: "home" })
                      : t("header.tooltipMessages.lastSyncInfo.connectionFailure.label", { erp: lastSync?.app?.name ?? "N/A", ns: "home" }),
                },
                {
                  header: t("header.tooltipMessages.lastSyncInfo.detailsLabel", { ns: "home" }),
                  details:
                    lastSync.statusCode === SyncStatusCodes.success || lastSync.statusCode === SyncStatusCodes.cancelled
                      ? t("header.tooltipMessages.lastSyncInfo.connectionFailure.lastSuccessDetails", {
                          date: formatDate(lastSync.created) ?? "N/A",
                          ns: "home",
                        })
                      : t("header.tooltipMessages.lastSyncInfo.connectionFailure.details1", { ns: "home" }),
                },
              ])}
              type={TooltipTypes.RICH}
              disableFocusListener
            >
              <span className={`light-${lastSync.statusCode?.split(" ").join("-")}`}>
                {lastSync?.statusCode === SyncStatusCodes.success || lastSync?.statusCode === SyncStatusCodes.cancelled
                  ? t("header.tooltipMessages.lastSyncInfo.connected", { erp: lastSync?.app?.name ?? "N/A", ns: "home" })
                  : t("header.tooltipMessages.lastSyncInfo.connectionFailure.label", { erp: lastSync?.app?.name ?? "N/A", ns: "home" })}
              </span>
            </CustomTooltip>
            <CustomTooltip title={syncMessage} type={TooltipTypes.RICH} disableFocusListener>
              {/* 
                  blank div helps on-hover event on disabled icon button 
                  refer: https://github.com/mui/material-ui/issues/8416
                */}
              <div>
                <IconButton size="small" disabled={disableSync} onClick={() => handleSyncClick()}>
                  <Sync />
                </IconButton>
              </div>
            </CustomTooltip>
          </>
        ) : (
          <>
            <span className="light-animated">{t("header.syncDetails.connecting", { ns: "home" })}</span>
          </>
        )
      ) : (
        <>
          <span id="last-sync-indicator" className={`status-Failed`} />
          <CustomTooltip
            title={tooltipTextFormatter([
              {
                header: t("header.tooltipMessages.lastSyncInfo.statusLabel", { ns: "home" }),
                details: t("header.tooltipMessages.lastSyncInfo.connectionFailure.details1", { ns: "home" }),
              },
              {
                header: t("header.tooltipMessages.lastSyncInfo.detailsLabel", { ns: "home" }),
                details: t("header.tooltipMessages.lastSyncInfo.connectionFailure.details2", { ns: "home" }),
              },
            ])}
            type={TooltipTypes.RICH}
            disableFocusListener
          >
            <span className={`light-Failed`}>{t("header.syncDetails.notConnected", { ns: "home" })}</span>
          </CustomTooltip>
        </>
      )}
    </div>
  );
}

export default LastSync;

import React from "react";
import { useRouteMatch, Switch, Route } from "react-router";
import { ApplicationRouteContext } from "../../contexts/ApplicationRouteContext";
import Navbar from "../library/Navbar/Navbar";
import "../Invoices/Invoices.scss";
import { InvoiceContext } from "../../contexts/InvoiceContext";
import useWorkspaceConfigurations from "../../hooks/useWorkspaceConfigurations";
import { FeatureFlagContext, FeatureFlagProviderType } from "../../contexts/FeatureFlagContext";

const InvoiceNavbar = () => {
  const { getBaseRoute } = React.useContext(ApplicationRouteContext) as ApplicationRouteType;
  const { invoiceData } = React.useContext(InvoiceContext) as InvoiceType;
  const { isEnabled } = React.useContext(FeatureFlagContext) as FeatureFlagProviderType;
  const { path } = useRouteMatch();
  const { configProvider, pathType, configs: currentWorkspaceConfigs } = useWorkspaceConfigurations();

  const configs = isEnabled("ACCOUNTING_WORKSPACE") ? configProvider[pathType] : currentWorkspaceConfigs;

  return (
    <div className={`invoice-head`}>
      <Switch>
        <Route
          exact
          path={[
            `${path}`,
            `${path}/late_91`,
            `${path}/late_61_90`,
            `${path}/late_31_60`,
            `${path}/late_1_30`,
            `${path}/future_due`,
            `${path}/closed`,
          ]}
          component={() => <Navbar tabs={configs.invoices.routes.main} rootPath={path} />}
        />
        {/*
         * Navbar with the back route pointing to invoice table view
         */}
        <Route
          exact
          path={`${path}/:invoiceId`}
          component={() => {
            return (
              <Navbar
                tabs={configs.invoices.routes.main}
                rootPath={path}
                handleBackRoute={true}
                backRoute={path}
                backDisplayName={`All ${configs.invoices.routes.dropdownDefaultTitle}`}
              />
            );
          }}
        />
        {/*
         * Navbar with back route pointing to invoice details page
         */}
        <Route
          path={[`${path}/:invoiceId`]}
          component={() => {
            const invoiceNumber = invoiceData?.referenceCode;
            return (
              <Navbar
                rootPath={path}
                tabs={configs.invoices.routes.main}
                handleBackRoute={true}
                backRoute={getBaseRoute()}
                backDisplayName={`${configs.invoices.routes.dropdownDefaultTitle} #${invoiceNumber}`}
              />
            );
          }}
        />
      </Switch>
    </div>
  );
};

export default InvoiceNavbar;

import React from "react";
import { ATTACH_MANUALLY, INVALID_ATTACHMENT } from "./constants/InboxTextbox.messages";
import { TextBoxMsgVariants } from "./constants/InboxTextbox.configs";

const TextboxMsgs: React.FC<{ variant: TextBoxMsgVariants | undefined }> = ({ variant }) => {
  if (variant === TextBoxMsgVariants.INVALID_ATTACHMENT) {
    return (
      <>
        {INVALID_ATTACHMENT}
        <br />
        {ATTACH_MANUALLY}
      </>
    );
  } else {
    return <></>;
  }
};

export default TextboxMsgs;

import React, { useEffect, useRef, useState } from "react";
import { Drawer, TextField, DrawerProps, createFilterOptions } from "@mui/material";
import Button from "../../../../../library/Button/Button";
import { TextArea } from "../../../../../library/TextArea/TextArea";
import { UserPic } from "../../../../../library/Icons/Icons";
import Autocomplete from "../../../../../library/Autocomplete/Autocomplete";
import { ActivityContext } from "../../../../../../contexts/ActivityContext";
import { AlertContext } from "../../../../../../contexts/AlertContext";
import "./../ActivityFeedActions.scss";
import { WorkspaceContext } from "../../../../../../contexts/WorkspaceContext";
import { DEFAULT_NUMERIC_VALUES } from "../../../../../../constants/NumericConstants";
import { ACTIVITY_REASSIGN_PENDO } from "../../../../../../constants/config";
import { DisableFeatureContext, DisableFeatureProviderType } from "../../../../../../contexts/DisableFeatureContext";
import CustomTooltip from "../../../../../library/CustomTooltip/CustomTooltip";
import _ from "underscore";
import TrackingUtils from "../../../../../Tracking/Tracking.Utils";
import { useTranslation } from "react-i18next";

interface ActivityReassignProps extends DrawerProps {
  onClose: () => void;
  onCallback: () => void;
  activityIds: string[];
  currentAssignee?: any;
  deselectedRowsFilter?: string;
  selectedActivitiesCount?: number;
  connectionIdQueryFilter?: string;
}

interface UserEmailProps {
  option: any;
}

function UserEmailComponent({ option }: UserEmailProps): React.ReactElement {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [disableHover, setDisableHover] = useState(false);
  const handleResize = () => {
    if (wrapperRef) {
      if (
        (wrapperRef.current?.offsetWidth ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) <
        (wrapperRef.current?.scrollWidth ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO)
      ) {
        setDisableHover(false);
      } else {
        setDisableHover(true);
      }
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  return (
    <CustomTooltip title={option.userEmail} followCursor disableHoverListener={disableHover}>
      <span ref={wrapperRef} className="contact-email">
        {option.userEmail || "--"}
      </span>
    </CustomTooltip>
  );
}

export default function ActivityReassign({
  open,
  onClose,
  onCallback,
  activityIds,
  currentAssignee,
  selectedActivitiesCount,
  deselectedRowsFilter,
  connectionIdQueryFilter,
  ...rest
}: ActivityReassignProps): React.ReactElement {
  const { handleReassign } = React.useContext(ActivityContext) as ActivityType;
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const { workspaceMembers, fetchingWorkspaceMembers } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { setDisableKeyboardShortcut } = React.useContext(DisableFeatureContext) as DisableFeatureProviderType;

  const [isLoadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [noteValue, setNoteValue] = useState<string>("");

  const [teamMemberList, setTeamMemberList] = useState<Partial<UserAccountModel>[]>([]);
  const [teamMemberListOpen, setTeamMemberListOpen] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<Partial<UserAccountModel> | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (open) {
      setSelectedUser({
        userEmail: currentAssignee?.email ?? "",
        userId: currentAssignee?.id ?? "",
        userName: currentAssignee?.name ?? "",
      } as Partial<UserAccountModel>);
    }
  }, [open]);

  const onReassignHandler = async () => {
    const bulkActivitiesCount = selectedActivitiesCount ?? activityIds.length;
    let toastOptions: ToastOptions = {
      open: true,
      severity: "success",
      message:
        activityIds.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE ? (
          <p>
            {t("toastMessages.reassignActivity.success", { ns: "activities" })} <span style={{ fontWeight: 700 }}>{selectedUser?.userName}</span>
          </p>
        ) : (
          t("toastMessages.reassignActivity.bulkSuccess", { activitesCount: bulkActivitiesCount, ns: "activities" })
        ),
    };
    let response = {} as APIResponse;
    const filter = [connectionIdQueryFilter, deselectedRowsFilter].filter(Boolean).join("&");

    try {
      response = await handleReassign(
        activityIds.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE ? activityIds[0] : activityIds,
        selectedUser?.userId ?? null,
        noteValue,
        filter
      );
    } catch (e: unknown) {
      response.success = false;
    } finally {
      if (!response.success) {
        toastOptions = {
          ...toastOptions,
          severity: "error",
          message:
            activityIds.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
              ? t("toastMessages.reassignActivity.failed", { ns: "activities" })
              : t("toastMessages.reassignActivity.bulkfailed", { activitesCount: bulkActivitiesCount, ns: "activities" }),
        };
      }
      TrackingUtils.trackUserActivities(ACTIVITY_REASSIGN_PENDO, response.success);
      onCallback();
      setNoteValue("");
      onClose();
      setToastOptions(toastOptions);
    }
  };

  const handleSubmit = async () => {
    setLoadingSubmit(true);
    await onReassignHandler();
    setLoadingSubmit(false);
  };

  useEffect(() => {
    if (!_.isEmpty(workspaceMembers)) {
      setTeamMemberList(
        workspaceMembers?.map((user: Partial<UserItem>) => ({
          userId: `${user.id}`,
          userName: user.name ?? "",
          userEmail: user.email_address,
        })) ?? []
      );
    }
    const unassignedUser = { userId: "", userName: t("modalAndFlyout.reassignActivity.unassigned", { ns: "activities" }), userEmail: "" };
    setTeamMemberList((prev: Partial<UserAccountModel>[]) => {
      return [...prev, unassignedUser];
    });
  }, [workspaceMembers]);

  useEffect(() => {
    if (open) {
      setDisableKeyboardShortcut(true);
    } else {
      setDisableKeyboardShortcut(false);
    }
    return () => setDisableKeyboardShortcut(false);
  }, [open]);

  /**
   * This Function filters out the options when user types to search for members.
   *
   * @param val After comparing return the label if Id is present else return the Id.
   */
  const getOptions = (val: any) => {
    return val.userName;
  };

  const filterOptions = createFilterOptions({
    stringify: (option: any) => option.userName + option.userEmail,
  });

  return (
    <Drawer className="activity-feed-action-drawer" open={open} anchor="right" disablePortal onClose={() => onClose()} {...rest}>
      <div className="activity-flyout">
        <h3 className="header">{t("modalAndFlyout.reassignActivity.header", { ns: "activities" })}</h3>
        <p className="subheader body1">{t("modalAndFlyout.reassignActivity.subHeader", { ns: "activities" })}</p>
        <label htmlFor={"string"}>{t("modalAndFlyout.reassignActivity.teamMemberLabel", { ns: "activities" })}</label>
        <div>
          <Autocomplete
            getOptionLabel={getOptions}
            filterOptions={filterOptions}
            options={teamMemberList}
            loading={fetchingWorkspaceMembers}
            open={teamMemberListOpen}
            className="team-member-select"
            value={selectedUser}
            onOpen={() => {
              setTeamMemberListOpen(true);
            }}
            onClose={() => {
              setNoteValue("");
              setTeamMemberListOpen(false);
            }}
            onChange={async (_event, value: Partial<UserAccountModel>) => {
              setSelectedUser(value);
            }}
            blurOnSelect
            disableClearable
            disablePortal
            fullWidth
            popupIcon={<UserPic />}
            staticIcon={true}
            renderOption={(props, option) => {
              return (
                <li key={option.userId} className="contact-item-parent" {...props}>
                  <div className="contact-item-profile-tag">
                    <UserPic></UserPic>
                  </div>
                  <div className="contact-info-item">
                    {option.userName !== "" && option.userEmail !== "" ? (
                      <>
                        <span className="contact-name">{option.userName || "--"}</span>
                        <UserEmailComponent option={option} />
                      </>
                    ) : (
                      <span className="contact-name">{option.userEmail || option.userName || "--"}</span>
                    )}
                  </div>
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  placeholder: t("modalAndFlyout.reassignActivity.autoCompleteSearchPlaceHolder", { ns: "activities" }) as string,
                  disableUnderline: true,
                }}
              />
            )}
          />
        </div>

        <TextArea
          label={{
            regularText: t("modalAndFlyout.noteTextFieldLabel.label1", { ns: "activities" }),
            extraText: t("modalAndFlyout.noteTextFieldLabel.label2", { ns: "activities" }),
          }}
          placeholder={""}
          onChange={(e) => setNoteValue(e.target.value)}
          defaultValue={noteValue}
        />
        <div className="btn-grp">
          <Button variant="secondary" size="lg" onClick={() => onClose()} loading={isLoadingSubmit}>
            {t("modalAndFlyout.buttons.cancel", { ns: "activities" })}
          </Button>
          <Button size="lg" onClick={() => handleSubmit()} loading={isLoadingSubmit}>
            {t("modalAndFlyout.buttons.assign", { ns: "activities" })}
          </Button>
        </div>
      </div>
    </Drawer>
  );
}

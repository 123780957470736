import React, { useRef } from "react";
import TemplateFactory from "../app/Templates/TemplateFactory";
import { TemplateTypes } from "../app/Templates/TemplateTypes";
import { AttachmentType } from "../types/enums";
import { WorkspaceContext } from "./WorkspaceContext";
import { TemplatesClientV2 } from "../db/version2Accessor";
import BaseTemplate from "../app/Templates/BaseTemplate";
import { AlertContext } from "./AlertContext";
import { AppContext } from "./AppContext";
import useLocale from "../hooks/useLocale";
import _ from "underscore";
import { NUMERIC_VALUES } from "../constants/NumericConstants";

export const TemplateContext = React.createContext<ITemplateProps | null>(null);

export interface TemplateProviderProps {
  children: (React.ReactNode & { type: string })[] | (React.ReactNode & { type: string });
}

// update interface - add attachmentTypeCode prop to validate download function availability
export interface TemplateAttachDetail {
  name: string;
  id: UUID | number;
  typeCode?: string;
  extension?: string;
}

/**
 * Template mapper will take attachment type as keys and return TemplateAttachDetail
 */
export type TemplateDataMapper = {
  [AttachmentType.INVOICE]?: TemplateAttachDetail[];
  [AttachmentType.DOCUMENT]?: TemplateAttachDetail[];
  [AttachmentType.PAYMENT]?: TemplateAttachDetail[];
  [AttachmentType.BILL]?: TemplateAttachDetail[];
};

const TemplateProvider: React.FC<TemplateProviderProps> = ({ children }: TemplateProviderProps) => {
  const { updateUserStatus } = React.useContext(AppContext) as AppType;
  const { enabledLanguagesForTemplates } = useLocale();
  const { selectedWorkspace, workspaceData } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const [hasAllTemplatesDataProcessed, setHasAllTemplatesDataProcessed] = React.useState(false);
  const [templateSettingsTableData, setTemplateSettingsTableData] = React.useState<Map<number, TableTemplatesType[]>>();

  const templateSettingTableListMap = new Map<number, TableTemplatesType[]>();
  const allTemplatesData = useRef(new Map());
  const templateFactory = new TemplateFactory(allTemplatesData.current?.get(selectedWorkspace?.id));

  const truncatedTemplate = (template: string) => {
    if (template.length < NUMERIC_VALUES.CONSTANT_THIRTY) {
      return template;
    }
    const truncatedTemplate = template.substring(NUMERIC_VALUES.CONSTANT_ZERO, NUMERIC_VALUES.CONSTANT_THIRTY).trim();
    return `${truncatedTemplate}...`;
  };

  const getTemplateObjectList = (templateAPIData: MultiLangTemplateModelV2[], workspaceId: number, updatedTemplateLanguages?: string[]) => {
    const languageMap = new Map();
    const formatDataForEnabledLanguages = updatedTemplateLanguages ?? enabledLanguagesForTemplates;

    if (selectedWorkspace?.id) {
      const formatSettingsTableData: TableTemplatesType[] = [];

      //Iterate for each languages and prepare data into required format
      formatDataForEnabledLanguages.forEach((langItem: string) => {
        const templateInstanceMap: Map<TemplateTypes, BaseTemplate> = new Map();
        const formatTemplateData: formatTemplateDataType[] = [];

        templateAPIData.forEach((item: MultiLangTemplateModelV2) => {
          const itemVariant = item?.locale_texts?.[langItem];
          if (itemVariant?.content) {
            formatTemplateData.push({
              id: item?.id,
              template_code: item?.template_code,
              snippets: item?.snippets,
              subject: itemVariant?.subject,
              content: itemVariant?.content,
              template_name: item?.template_name,
              workspace_type: item?.workspace_type,
            });
          }
        });

        //create template instance for required templates
        templateFactory.createTemplateObjects(formatTemplateData).forEach((val: any) => {
          if (val) {
            templateInstanceMap.set(val.templateCode as TemplateTypes, val);
          }
        });

        //map the object template to different languages
        languageMap.set(langItem, templateInstanceMap);
      });

      //map the languageMap to different workspaces
      allTemplatesData.current.set(workspaceId, languageMap);

      //create instance for current selected workspace
      if (workspaceId === selectedWorkspace?.id) {
        templateFactory.setTemplateObjectsMap(allTemplatesData?.current?.get(selectedWorkspace?.id));
      }

      //prepare data for template setting page table
      templateAPIData.forEach((item: MultiLangTemplateModelV2) => {
        if (item.locale_texts) {
          formatSettingsTableData.push({
            id: item.id,
            templateAvailableIn: Object.keys(item?.locale_texts)?.sort((a, b) => a.localeCompare(b)),
            templateName: item.template_name,
            workspaceType: item.workspace_type,
            templateCode: item.template_code,
          });
        }
      });
      templateSettingTableListMap.set(workspaceId, formatSettingsTableData);
    }
    //set the data for template settingg page table
    setTemplateSettingsTableData(templateSettingTableListMap);
  };

  const fetchTemplatesList = async (workspaceId: number, updatedTemplateLanguages?: string[]) => {
    return TemplatesClientV2.getTemplates(workspaceId)
      .then((response) => {
        if (response.success) {
          getTemplateObjectList(response.data, workspaceId, updatedTemplateLanguages);
        }
      })
      .catch((error) => {
        //pass empty array in case of failure to genearet empty mapping data
        getTemplateObjectList([], workspaceId, updatedTemplateLanguages);
        console.log(error);
      });
  };

  const createTemplateMapsForWorkspaces = async (updatedTemplateLanguages?: string[]) => {
    if (!_.isEmpty(workspaceData) && !!workspaceData) {
      const allWorkspaceTemplatePromises: Promise<void>[] = [];
      workspaceData.forEach((item) => {
        allWorkspaceTemplatePromises.push(fetchTemplatesList(item.id, updatedTemplateLanguages));
      });

      //wait for all workspace templates to be formatted
      await Promise.allSettled(allWorkspaceTemplatePromises)
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setHasAllTemplatesDataProcessed(true);
        });
    }
  };

  const saveAndUpdateTemplate = (
    templateBody: string,
    templateSubject: string,
    templateCode: string,
    workspaceId: number,
    templateId: number,
    templateName: string,
    tempUpdateLanguage: string
  ) => {
    let toastOptions: ToastOptions = {
      open: true,
      severity: "error",
      message: `There was an error while updating '${truncatedTemplate(templateName)}'.`,
    };
    setHasAllTemplatesDataProcessed(false);
    TemplatesClientV2.saveTemplate(workspaceId, templateId, {
      subject: templateSubject,
      content: templateBody,
      locale_code: tempUpdateLanguage,
      template_name: templateName,
    })
      .then((response) => {
        if (response.success) {
          /* iterate over all enabled languages and check for templated code which we want update the template data
           * then check whether enabledLanguage === tempUpdateLanguage then update template content,body and id.
           * else for other enabledLanguage languages update only template Id
           */
          enabledLanguagesForTemplates?.forEach((enabledLanguage) => {
            Array.from(allTemplatesData.current?.get(workspaceId).get(enabledLanguage).values()).forEach((item: any) => {
              if (item.templateCode === templateCode) {
                item.setTemplateName(response.data?.template_name);
                if (tempUpdateLanguage === enabledLanguage) {
                  item.setTemplateId(response.data.id);
                  item.setTemplateBody(response.data.locale_texts?.[tempUpdateLanguage].content);
                  item.setTemplateSubject(response.data.locale_texts?.[tempUpdateLanguage].subject);
                } else {
                  item.setTemplateId(response.data.id);
                }
              }
            });
          });

          const updatedTemplateSettingsTableData = templateSettingsTableData?.get(workspaceId)?.map((item: any) => {
            if (item.templateCode === templateCode) {
              item.templateName = response.data?.template_name;
            }
            return item;
          }) as TableTemplatesType[];

          templateSettingTableListMap.set(workspaceId, updatedTemplateSettingsTableData ?? ([] as TableTemplatesType[]));
          //just need to update the template for specific workspace and other should be as it is.
          setTemplateSettingsTableData((prevData) => {
            const prevTemplateTableData = new Map(prevData);
            if (prevTemplateTableData.has(workspaceId)) {
              // Update the specific key with newData
              prevTemplateTableData.set(workspaceId, updatedTemplateSettingsTableData);
            }
            return prevTemplateTableData;
          });

          toastOptions = {
            ...toastOptions,
            severity: "success",
            message: `Email template '${truncatedTemplate(templateName)}' updated successfully.`,
          };
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setToastOptions(toastOptions);
        setHasAllTemplatesDataProcessed(true);
      });
  };

  //Enable template languages
  const updateTemplateEnableLanguage = (workspaceId: number | string, isMultiLocale: boolean, enabledLanguages: string[]) => {
    let toastOptions: ToastOptions = {
      open: true,
      severity: "error",
      message: `There was an error while updating language`,
    };

    TemplatesClientV2.updateTemplateMultLocale(workspaceId, isMultiLocale, enabledLanguages)
      .then((response) => {
        if (response.success) {
          updateUserStatus();
          toastOptions = {
            open: true,
            severity: "success",
            message: `Language updated successfully`,
          };
        }
      })
      .catch((error) => {
        console.log(error);
        setHasAllTemplatesDataProcessed(true);
      })
      .finally(() => {
        setToastOptions(toastOptions);
        return true;
      });
  };

  /**
   *
   * @param templateObj : Template Object extending BaseTemplate
   * @param setFileCallback : callback for setting files in editor
   * @param templateDataMapper :
   * @returns : void
   */
  const handleTemplateAttachment = (templateObj: any, setFileCallback: FunctionCall, templateDataMapper: TemplateDataMapper) => {
    const templateAttachmentType = templateObj.attachmentType ?? [];
    const fileNames = templateAttachmentType
      .map((type: AttachmentType) =>
        templateDataMapper[type]?.map((item) => {
          return {
            transactionId: item.id,
            transactionTypeCode: item.typeCode,
            transactionType: type,
            attachmentTitle: item.name,
            extension: item.extension,
          };
        })
      )
      .flat();
    setFileCallback(fileNames);
  };

  /**
   * creates a nested object of attachments which includes,
   * invoices, documents & payments.
   *
   * @param templateObj template object
   * @param invoiceData invoices attachment list
   * @param documentData document attachment list
   * @param paymentData payment attachment list
   * @returns TemplateDataMapper
   */
  const prepareTemplateDataObject = (
    templateObj: any,
    invoiceData: TemplateAttachDetail[] | null,
    documentData: TemplateAttachDetail[] | null,
    paymentData: TemplateAttachDetail[] | null
  ) => {
    const templateDataMapper = {} as TemplateDataMapper;
    const templateAttachmentType = templateObj.attachmentType ?? [];
    templateAttachmentType.forEach((attachmentType: AttachmentType) => {
      if (attachmentType === AttachmentType.INVOICE && invoiceData !== null) {
        templateDataMapper[AttachmentType.INVOICE] = invoiceData as TemplateAttachDetail[];
      } else if (attachmentType === AttachmentType.DOCUMENT && documentData !== null) {
        templateDataMapper[AttachmentType.DOCUMENT] = documentData as TemplateAttachDetail[];
      } else if (attachmentType === AttachmentType.PAYMENT && paymentData !== null) {
        templateDataMapper[AttachmentType.PAYMENT] = paymentData as TemplateAttachDetail[];
      }
    });
    return templateDataMapper;
  };

  return (
    <TemplateContext.Provider
      value={{
        templateFactory,
        handleTemplateAttachment: handleTemplateAttachment,
        prepareTemplateDataObject: prepareTemplateDataObject,
        saveAndUpdateTemplate: saveAndUpdateTemplate,
        updateTemplateEnableLanguage,
        createTemplateMapsForWorkspaces: createTemplateMapsForWorkspaces,
        templateData: allTemplatesData.current,
        hasAllTemplatesDataProcessed,
        setHasAllTemplatesDataProcessed,
        templateSettingsTableData,
      }}
    >
      {children}
    </TemplateContext.Provider>
  );
};

export default TemplateProvider;

import Utils from "../utils/utils";
import { ActivityTypes } from "./ActivityConstants";
import { TooltipTitles } from "./TooltipConstants";

type ShortcutConfig = {
  keyCombo: { ctrl: boolean; alt: boolean; key: string; shift: boolean };
  action: string;
  identifier: string;
  displayText: string;
};

export const KeyboardActionsConstants = {
  mark_spam: "mark_as_spam",
  mark_unread: "mark_unread",
  mark_snooze: "mark_snooze",
  mark_move: "mark_move",
  mark_assign: "mark_assign",
  mark_close: "mark_close",
  mark_as_complete: "mark_as_complete",
  make_activity_email: "make_activity_email",
  make_activity_note: "make_activity_note",
  make_activity_phone_call: "make_activity_phone_call",
  make_activity_approval_request: "make_activity_approval_request",
  go_next: "go_next",
  go_previous: "go_previous",
  send_reply: "send_reply",
  send_forward: "send_forward",
  focus_search: "focus_search",
  read_more_less: "read_more_less",
  send_action: "send_action",
  send_close_action: "send_close_action",
  go_back_activity: "go_back_activity",
  open_command_center: "open_command_center",
  cancel_draft: "cancel_draft",
};

export const ActivityFeedKeyboardShortCutConstants: Array<ShortcutConfig> = [
  {
    keyCombo: {
      ctrl: false,
      shift: true,
      alt: false,
      key: "!",
    },
    action: KeyboardActionsConstants.mark_spam,
    identifier: TooltipTitles.MOVE_AS_SPAM,
    displayText: `!`,
  },
  {
    keyCombo: {
      ctrl: false,
      shift: true,
      alt: false,
      key: "G",
    },
    action: KeyboardActionsConstants.mark_assign,
    identifier: TooltipTitles.ASSIGN,
    displayText: `${Utils.getShiftKey()} + G`,
  },
  {
    keyCombo: {
      ctrl: false,
      shift: true,
      alt: false,
      key: "g",
    },
    action: KeyboardActionsConstants.mark_assign,
    identifier: TooltipTitles.ASSIGN,
    displayText: `${Utils.getShiftKey()} + G`,
  },
  {
    keyCombo: {
      ctrl: false,
      shift: false,
      alt: false,
      key: "m",
    },
    action: KeyboardActionsConstants.mark_move,
    identifier: TooltipTitles.MOVE,
    displayText: `M`,
  },
  {
    keyCombo: {
      ctrl: false,
      shift: false,
      alt: false,
      key: "M",
    },
    action: KeyboardActionsConstants.mark_move,
    identifier: TooltipTitles.MOVE,
    displayText: `M`,
  },
  {
    keyCombo: {
      ctrl: false,
      shift: false,
      alt: false,
      key: "b",
    },
    action: KeyboardActionsConstants.mark_snooze,
    identifier: TooltipTitles.SNOOZE,
    displayText: `B`,
  },
  {
    keyCombo: {
      ctrl: false,
      shift: false,
      alt: false,
      key: "b",
    },
    action: KeyboardActionsConstants.mark_snooze,
    identifier: TooltipTitles.SNOOZE,
    displayText: `B`,
  },
  {
    keyCombo: {
      ctrl: false,
      shift: false,
      alt: false,
      key: "B",
    },
    action: KeyboardActionsConstants.mark_snooze,
    identifier: TooltipTitles.SNOOZE,
    displayText: `B`,
  },
  {
    keyCombo: {
      ctrl: false,
      shift: false,
      alt: false,
      key: "u",
    },
    action: KeyboardActionsConstants.mark_unread,
    identifier: TooltipTitles.MARK_AS_UNREAD,
    displayText: `U`,
  },
  {
    keyCombo: {
      ctrl: false,
      shift: false,
      alt: false,
      key: "U",
    },
    action: KeyboardActionsConstants.mark_unread,
    identifier: TooltipTitles.MARK_AS_UNREAD,
    displayText: `U`,
  },
  {
    keyCombo: {
      ctrl: false,
      shift: false,
      alt: false,
      key: "x",
    },
    action: KeyboardActionsConstants.mark_as_complete,
    identifier: TooltipTitles.MARK_AS_COMPLETE,
    displayText: `X`,
  },
  {
    keyCombo: {
      ctrl: false,
      shift: false,
      alt: false,
      key: "X",
    },
    action: KeyboardActionsConstants.mark_as_complete,
    identifier: TooltipTitles.MARK_AS_COMPLETE,
    displayText: `X`,
  },
  {
    keyCombo: {
      ctrl: false,
      shift: false,
      alt: false,
      key: "x",
    },
    action: KeyboardActionsConstants.mark_close,
    identifier: TooltipTitles.CLOSE,
    displayText: `X`,
  },
  {
    keyCombo: {
      ctrl: false,
      shift: false,
      alt: false,
      key: "X",
    },
    action: KeyboardActionsConstants.mark_close,
    identifier: TooltipTitles.CLOSE,
    displayText: `X`,
  },
  {
    keyCombo: {
      ctrl: true,
      shift: false,
      alt: false,
      key: "K",
    },
    action: KeyboardActionsConstants.open_command_center,
    identifier: TooltipTitles.OPEN_COMMAND_CENTER,
    displayText: `K`,
  },
  {
    keyCombo: {
      ctrl: true,
      shift: false,
      alt: false,
      key: "k",
    },
    action: KeyboardActionsConstants.open_command_center,
    identifier: TooltipTitles.OPEN_COMMAND_CENTER,
    displayText: `K`,
  },
];

export const ActivityAddNewButtonShortcuts: Array<ShortcutConfig> = [
  {
    keyCombo: {
      ctrl: false,
      shift: false,
      alt: false,
      key: "c",
    },
    action: KeyboardActionsConstants.make_activity_email,
    identifier: ActivityTypes.email,
    displayText: `C`,
  },
  {
    keyCombo: {
      ctrl: false,
      shift: false,
      alt: false,
      key: "C",
    },
    action: KeyboardActionsConstants.make_activity_email,
    identifier: ActivityTypes.email,
    displayText: `C`,
  },
  {
    keyCombo: {
      ctrl: false,
      shift: false,
      alt: false,
      key: "t",
    },
    action: KeyboardActionsConstants.make_activity_note,
    identifier: ActivityTypes.note,
    displayText: `T`,
  },
  {
    keyCombo: {
      ctrl: false,
      shift: false,
      alt: false,
      key: "T",
    },
    action: KeyboardActionsConstants.make_activity_note,
    identifier: ActivityTypes.note,
    displayText: `T`,
  },
  {
    keyCombo: {
      ctrl: false,
      shift: false,
      alt: false,
      key: "y",
    },
    action: KeyboardActionsConstants.make_activity_phone_call,
    identifier: ActivityTypes.phone_call,
    displayText: `Y`,
  },
  {
    keyCombo: {
      ctrl: false,
      shift: false,
      alt: false,
      key: "Y",
    },
    action: KeyboardActionsConstants.make_activity_phone_call,
    identifier: ActivityTypes.phone_call,
    displayText: `Y`,
  },
  {
    keyCombo: {
      ctrl: false,
      shift: false,
      alt: false,
      key: "a",
    },
    action: KeyboardActionsConstants.make_activity_approval_request,
    identifier: ActivityTypes.approval_request,
    displayText: `A`,
  },
  {
    keyCombo: {
      ctrl: false,
      shift: false,
      alt: false,
      key: "A",
    },
    action: KeyboardActionsConstants.make_activity_approval_request,
    identifier: ActivityTypes.approval_request,
    displayText: `A`,
  },
];

export const ActivityNavigationShortcuts: Array<ShortcutConfig> = [
  {
    keyCombo: {
      ctrl: false,
      shift: false,
      alt: false,
      key: "N",
    },
    action: KeyboardActionsConstants.go_next,
    identifier: TooltipTitles.GO_NEXT,
    displayText: `N`,
  },
  {
    keyCombo: {
      ctrl: false,
      shift: false,
      alt: false,
      key: "n",
    },
    action: KeyboardActionsConstants.go_next,
    identifier: TooltipTitles.GO_NEXT,
    displayText: `N`,
  },
  {
    keyCombo: {
      ctrl: false,
      shift: false,
      alt: false,
      key: "p",
    },
    action: KeyboardActionsConstants.go_previous,
    identifier: TooltipTitles.GO_PREVIOUS,
    displayText: `P`,
  },
  {
    keyCombo: {
      ctrl: false,
      shift: false,
      alt: false,
      key: "P",
    },
    action: KeyboardActionsConstants.go_previous,
    identifier: TooltipTitles.GO_PREVIOUS,
    displayText: `P`,
  },
];

export const ActivitySetActionShortcuts: Array<ShortcutConfig> = [
  {
    keyCombo: {
      ctrl: false,
      shift: false,
      alt: false,
      key: "r",
    },
    action: KeyboardActionsConstants.send_reply,
    identifier: TooltipTitles.REPLY_TO_EMAIL,
    displayText: `R`,
  },
  {
    keyCombo: {
      ctrl: false,
      shift: false,
      alt: false,
      key: "R",
    },
    action: KeyboardActionsConstants.send_reply,
    identifier: TooltipTitles.REPLY_TO_EMAIL,
    displayText: `R`,
  },
  {
    keyCombo: {
      ctrl: false,
      shift: false,
      alt: false,
      key: "f",
    },
    action: KeyboardActionsConstants.send_forward,
    identifier: TooltipTitles.FORWARD,
    displayText: `F`,
  },
  {
    keyCombo: {
      ctrl: false,
      shift: false,
      alt: false,
      key: "F",
    },
    action: KeyboardActionsConstants.send_forward,
    identifier: TooltipTitles.FORWARD,
    displayText: `F`,
  },
];

export const ActivityGlobalSearchShortcuts: Array<ShortcutConfig> = [
  {
    keyCombo: {
      ctrl: false,
      shift: false,
      alt: false,
      key: "/",
    },
    action: KeyboardActionsConstants.focus_search,
    identifier: TooltipTitles.GO_TO_SEARCH,
    displayText: `/`,
  },
];

export const ActivityReadContentShortcuts: Array<ShortcutConfig> = [
  {
    keyCombo: {
      ctrl: true,
      shift: false,
      alt: false,
      key: "r",
    },
    action: KeyboardActionsConstants.read_more_less,
    identifier: TooltipTitles.READ_LESS,
    displayText: `${Utils.getOSCommandKey()} + R`,
  },
  {
    keyCombo: {
      ctrl: true,
      shift: false,
      alt: false,
      key: "R",
    },
    action: KeyboardActionsConstants.read_more_less,
    identifier: TooltipTitles.READ_LESS,
    displayText: `${Utils.getOSCommandKey()} + R`,
  },
];

export const ActivityEmailBodyActionShortcuts: Array<ShortcutConfig> = [
  {
    keyCombo: {
      ctrl: false,
      shift: false,
      alt: false,
      key: "Escape",
    },
    action: KeyboardActionsConstants.cancel_draft,
    identifier: TooltipTitles.CANCEL_DRAFT,
    displayText: `Escape`,
  },
  {
    keyCombo: {
      ctrl: true,
      shift: false,
      alt: false,
      key: "s",
    },
    action: KeyboardActionsConstants.send_action,
    identifier: TooltipTitles.SEND,
    displayText: `${Utils.getOSCommandKey()} +  S`,
  },
  {
    keyCombo: {
      ctrl: true,
      shift: false,
      alt: false,
      key: "S",
    },
    action: KeyboardActionsConstants.send_action,
    identifier: TooltipTitles.SEND,
    displayText: `${Utils.getOSCommandKey()} +  S`,
  },
  {
    keyCombo: {
      ctrl: false,
      shift: false,
      alt: true,
      key: "ß",
    },
    action: KeyboardActionsConstants.send_close_action,
    identifier: TooltipTitles.SEND_CLOSE,
    displayText: `${Utils.getOSCommandKey()} + ${Utils.getOSAltKey()} + S`,
  },
  {
    keyCombo: {
      ctrl: true,
      shift: false,
      alt: true,
      key: "ß",
    },
    action: KeyboardActionsConstants.send_close_action,
    identifier: TooltipTitles.SEND_CLOSE,
    displayText: `${Utils.getOSCommandKey()} + ${Utils.getOSAltKey()} + S`,
  },
];

export const ActivityNavBarActionShortcuts: Array<ShortcutConfig> = [
  {
    keyCombo: {
      ctrl: true,
      shift: false,
      alt: false,
      key: "h",
    },
    action: KeyboardActionsConstants.go_back_activity,
    identifier: TooltipTitles.GO_BACK_ACTIVITIES,
    displayText: `${Utils.getOSCommandKey()} + H`,
  },
  {
    keyCombo: {
      ctrl: true,
      shift: false,
      alt: false,
      key: "H",
    },
    action: KeyboardActionsConstants.go_back_activity,
    identifier: TooltipTitles.GO_BACK_ACTIVITIES,
    displayText: `${Utils.getOSCommandKey()} + H`,
  },
];

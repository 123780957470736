/*eslint-disable no-magic-numbers*/
import { styled } from "@mui/system";
import { styled as muiStyled } from "@mui/material/styles";
import Card from "@mui/material/Card";

export const ContactsCardRoot = muiStyled(Card, {
  name: "contacts-card",
  slot: "root",
})({
  padding: "1.5rem",
  boxShadow: "none",
  border: "0.0625rem solid #bdc3db",
  borderRadius: "0.5rem",
  maxHeight: "20rem",
  overflowY: "scroll",
  fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif`,
  color: "#4D4D4D",
  "& .MuiPaper-root::before": {
    display: "none",
  },
  "& .MuiPaper-root": {
    boxShadow: "none",
  },
  "& .MuiButtonBase-root": {
    padding: 0,
  },
  "& .MuiAccordionDetails-root": {
    padding: 0,
  },
});

export const ContactType = styled("div", {
  name: "contact-type",
  slot: "root",
})({
  display: "flex",
  flexDirection: "row",
  fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif`,
  fontWeight: 500,
  fontSize: "0.75rem",
  lineHeight: "0.875rem",
});

export const ContactHeaderPanel = styled("div", {
  name: "contact-header-panel",
  slot: "root",
})({
  display: "flex",
  gap: "1rem",
  "& .MuiAvatar-root": {
    border: "0.0625rem solid #143b52",
    backgroundColor: "#ffffff",
    color: "#143b52",
    width: "2rem",
    height: "2rem",
    fontWeight: 600,
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    margin: 0,
  },
  "& .contact-header-info": {
    width: "16rem",
    overflow: "hidden",
  },
  "& .contact-name": {
    fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif`,
    fontWeight: 600,
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },
  "& .contact-email-tooltip": {
    fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif`,
    fontWeight: 400,
    fontSize: "0.75rem",
    lineHeight: "1rem",
  },
});

export const ContactDetails = styled("div", {
  name: "contact-details",
  slot: "root",
})({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "1rem",
  padding: "0.625rem 0",
  "& .contact-detail": {
    fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif`,
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    wordBreak: "break-all",
  },
});

/*eslint-disable no-magic-numbers*/
import { ClosedChip, OpenChip, PastDue1Chip, PastDue31Chip, PastDue61Chip, PastDue91Chip, RejectedChip } from "./statuschips.style";
import { StatusChipComponentType } from "./statuschips.types";
import { StatusLabels } from "../../constants/string.constants";
import Typography from "@mui/material/Typography";
import { getFormattedChipLabel } from "../../utils/table";
import React from "react";

const EInvoiceDaysStatusChipsComponent: React.FC<StatusChipComponentType> = ({
  chipStatus,
  daysPastDue,
  chipConfig,
  translationObject: t,
}: StatusChipComponentType) => {
  const getChipText = (displayText: string) => {
    return (
      <Typography variant={chipConfig?.fontVariant ?? "captionReg"}>
        {t ? t(`body.chipStatus.${displayText?.toLowerCase()?.split(" ").join("")}`, { ns: "table" }) : displayText}
      </Typography>
    );
  };

  const getStatusChip = (status: string, daysPastDue: number) => {
    const chipLabel = getFormattedChipLabel(status, daysPastDue);
    switch (chipLabel) {
      case StatusLabels["Open"]:
        return <OpenChip label={getChipText(chipLabel)} variant={chipConfig?.chipVariant ?? "outlined"} />;
      case StatusLabels["Closed"]:
        return <ClosedChip label={getChipText(chipLabel)} variant={chipConfig?.chipVariant ?? "outlined"} />;
      case StatusLabels["Past Due 1-30"]:
        return <PastDue1Chip label={getChipText(chipLabel)} variant={chipConfig?.chipVariant ?? "outlined"} />;
      case StatusLabels["Past Due 31-60"]:
        return <PastDue31Chip label={getChipText(chipLabel)} variant={chipConfig?.chipVariant ?? "outlined"} />;
      case StatusLabels["Past Due 61-90"]:
        return <PastDue61Chip label={getChipText(chipLabel)} variant={chipConfig?.chipVariant ?? "outlined"} />;
      case StatusLabels["Past Due 91+"]:
        return <PastDue91Chip label={getChipText(chipLabel)} variant={chipConfig?.chipVariant ?? "outlined"} />;
      case StatusLabels["Rejected"]:
        return <RejectedChip label={getChipText(chipLabel)} variant={chipConfig?.chipVariant ?? "outlined"} />;
      default:
        return <ClosedChip label={getChipText(chipLabel)} variant={chipConfig?.chipVariant ?? "outlined"} />;
    }
  };

  return <>{getStatusChip(chipStatus, daysPastDue ?? 0)}</>;
};

export default EInvoiceDaysStatusChipsComponent;

import React from "react";
import { Input, InputAdornment, Slider, SliderThumb } from "@mui/material";
import useRangeSliderStyle from "./useRangeSliderStyle";
import { NUMERIC_VALUES } from "../../../constants/NumericConstants";

interface RangeSliderProps {
  adornmentSymbol: string;
  range: Array<number>;
  onSliderChange: (event: Event, value: number[]) => void;
  onMinChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onMaxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  numericFilterType?: string;
  placeholder?: string;
}

function RangeSliderThumbComponent(props: React.HTMLAttributes<unknown>) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span className="range-slider-bar" />
      <span className="range-slider-bar" />
      <span className="range-slider-bar" />
    </SliderThumb>
  );
}

const RangeSliderProps: React.FC<RangeSliderProps> = ({
  adornmentSymbol = "",
  range,
  onSliderChange,
  onMinChange,
  onMaxChange,
  numericFilterType,
  placeholder = "0.00",
}) => {
  const { sliderRoot, sliderContainer, inputWrapper, inputContainer } = useRangeSliderStyle();

  return (
    <div className={sliderContainer}>
      <Slider
        className={sliderRoot}
        components={{ Thumb: RangeSliderThumbComponent }}
        onChange={(event, value) => onSliderChange(event, value as number[])}
        value={range}
        min={NUMERIC_VALUES.CONSTANT_ZERO}
        max={NUMERIC_VALUES.CONSTANT_TEN_THOUSAND}
      />
      <div className={inputWrapper}>
        <Input
          className={inputContainer}
          value={range[NUMERIC_VALUES.CONSTANT_ZERO]}
          placeholder={placeholder}
          onChange={onMinChange}
          startAdornment={<InputAdornment position="start">{adornmentSymbol}</InputAdornment>}
          type="number"
          disableUnderline={true}
          error={
            range[NUMERIC_VALUES.CONSTANT_ZERO] < NUMERIC_VALUES.CONSTANT_ZERO ||
            range[NUMERIC_VALUES.CONSTANT_ZERO] > range[NUMERIC_VALUES.CONSTANT_ONE]
          }
          onKeyDown={(e) => {
            if (e.key === "." && numericFilterType === "number") {
              e.preventDefault();
            }
          }}
        />
        -
        <Input
          className={inputContainer}
          value={range[NUMERIC_VALUES.CONSTANT_ONE]}
          placeholder={placeholder}
          onChange={onMaxChange}
          startAdornment={<InputAdornment position="start">{adornmentSymbol}</InputAdornment>}
          type="number"
          disableUnderline={true}
          error={
            range[NUMERIC_VALUES.CONSTANT_ONE] < range[NUMERIC_VALUES.CONSTANT_ZERO] ||
            range[NUMERIC_VALUES.CONSTANT_ONE] > NUMERIC_VALUES.CONSTANT_TEN_THOUSAND
          }
          onKeyDown={(e) => {
            if (e.key === "." && numericFilterType === "number") {
              e.preventDefault();
            }
          }}
        />
      </div>
    </div>
  );
};

export default RangeSliderProps;

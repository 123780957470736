import React from "react";
import AttachmentsComponent from "./AttachmentsComponent";
import { AttachmentsContainerProps } from "./interfaces/AttachmentsInterfaces";
import useTable from "../../../../hooks/useTable";
import { NUMERIC_VALUES } from "../../../../constants/NumericConstants";
import { attachmentsClientV2, invoicesClientV2, PaymentsClientV2 } from "../../../../db/version2Accessor";
import { WorkspaceType } from "../../../../types/enums";
import { TableRow } from "./interfaces/TableConfigurationInterfaces";
import useWorkspaceConfigurations from "../../../../hooks/useWorkspaceConfigurations";
import { ColumnConfigInterface } from "../../../library/TableWithInfiniteScroll/Interfaces/TableInterface";

const AttachmentsContainer: React.FC<AttachmentsContainerProps> = (props) => {
  const [showModal, setShowModal] = React.useState<boolean>(true);
  const { workspaceType, configs } = useWorkspaceConfigurations();

  /* invoice index api */
  const fetchInvoices = async (page: number, order: string, filters: string) => {
    let defaultFilters = `qa[customer_id_eq]=${props.customerId}&${
      workspaceType === WorkspaceType.AP ? `qa[invoice_type_code_eq]=AP Invoice` : `qa[invoice_type_code_eq]=AR Invoice`
    }`;
    if (filters) {
      defaultFilters = `${defaultFilters}&${filters}`;
    }
    const res = await invoicesClientV2.getInvoices(NUMERIC_VALUES.CONSTANT_TEN, page, order, defaultFilters, true);
    return res;
  };

  /* payments index api */
  const fetchPayments = async (page: number, order: string, filters: string) => {
    let defaultFilters = `qa[payment_company_id_eq]=${props.customerId}&qa[payment_company_id_eq]=${props.customerId}&${
      workspaceType === WorkspaceType.AP ? `qa[invoice_type_code_eq]=AP Payment` : `qa[invoice_type_code_eq]=AR Payment`
    }`;
    if (filters) {
      defaultFilters = `${defaultFilters}&${filters}`;
    }
    const res = await PaymentsClientV2.querySummaries(defaultFilters, order, NUMERIC_VALUES.CONSTANT_TEN, page, false);
    return res;
  };

  /* documents index api */
  const fetchDocuments = async (page: number, order: string, filters: string) => {
    let defaultFilters = `filter_by=connection&connection_id=${props.customerId}&${order}`;
    if (filters) {
      defaultFilters = `${defaultFilters}&${filters}`;
    }
    const res = await attachmentsClientV2.getAttachments(defaultFilters, order, page, NUMERIC_VALUES.CONSTANT_TEN);
    return res;
  };

  const invoiceParser = (data: InvoiceModelV2[]): TableRow<Partial<InvoiceModelV2>>[] => {
    const results =
      data?.map((record: Partial<InvoiceModelV2>) => {
        return {
          id: record.invoice_id,
          invoice_number: record.invoice_number,
          status: record.status,
          invoice_date: record.invoice_date,
          payment_due_date: record.payment_due_date,
          invoice_amount: record.invoice_amount,
          outstanding_balance: record.outstanding_balance,
          // added other
          isSelected: false,
          invoice_id: record.invoice_id,
          invoice_type_code: record.invoice_type_code,
        };
      }) ?? [];
    return results;
  };

  const paymentParser = (data: PaymentSummaryModelV2[]): TableRow<Partial<PaymentSummaryModelV2>>[] => {
    return (
      data?.map((record: PaymentSummaryModelV2) => {
        return {
          payment_id: record.payment_id,
          reference_code: record.reference_code,
          tender_type: record.tender_type,
          payment_date: record.payment_date,
          payment_amount: record.payment_amount,
          invoice_list: record.invoice_list,
          // added other
          isSelected: false,
          id: record.payment_id,
          payment_type: record.payment_type,
        };
      }) ?? []
    );
  };

  const documentParser = (data: AttachmentItem[]): TableRow<Partial<AttachmentItem>>[] => {
    return (
      data?.map((record: AttachmentItem) => {
        return {
          id: record.id,
          file_name: record.file_name,
          file_type: record.file_type,
          status: record.status,
          updated_at: record.updated_at,
          file_url: record.file_url,
          // added other
          isSelected: false,
          owner_name: record.owner?.name,
        };
      }) ?? []
    );
  };

  // have contracts for invoices, payments, documents
  // invoke contract based on the type received
  const invoices = useTable<InvoiceModelV2>({
    columns: configs.attachments.view.invoices.columns as ColumnConfigInterface[],
    fetch: fetchInvoices,
    parser: invoiceParser,
    ransacQuery: true,
  });
  const payments = useTable<PaymentSummaryModelV2>({
    columns: configs.attachments.view.payments.columns as ColumnConfigInterface[],
    fetch: fetchPayments,
    parser: paymentParser,
    ransacQuery: true,
  });
  const documents = useTable<AttachmentItem>({
    columns: configs.attachments.view.documents.columns as ColumnConfigInterface[],
    fetch: fetchDocuments,
    parser: documentParser,
    ransacQuery: true,
  });

  /** load intial page for all on first load */
  React.useEffect(() => {
    invoices.data.getRecords(true);
    payments.data.getRecords(true);
    documents.data.getRecords(true);
  }, []);

  return (
    <AttachmentsComponent
      showModal={showModal}
      setShowModal={setShowModal}
      invoices={invoices}
      payments={payments}
      documents={documents}
      {...props}
    />
  );
};

export default AttachmentsContainer;

import React from "react";
import Button from "../../../../../../library/Button/Button";
import SecondaryActivity from "../../SecondaryActivity/SecondaryActivity";
import { SecondaryType } from "../../SecondaryActivity/SecondaryActivityTypes";
import { ApAutomationProps } from "../ActivityItemVariantTypes";
import * as Labels from "../../../../../../../constants/config";
import automationProviders, { evaluateProvider } from "../../../../../../Settings/ApAutomation/providerDetails";

const ApAutomation: React.FC<ApAutomationProps> = ({ onClickResend, creator, email, readonly, countryCode }) => {
  const provider = automationProviders[evaluateProvider(email) ?? "others"].alt;
  creator = {
    ...creator,
    name: "Sage Network",
    message: `${Labels.HEADER_FORWARD_AP_AUTOMATION} ${provider === "Other" ? "ERP" : provider}`,
  };

  const actions = !readonly
    ? [
        <Button variant="secondary" size="sm" onClick={onClickResend} key="resend">
          {Labels.BTN_RESEND}
        </Button>,
      ]
    : [];

  return (
    <SecondaryActivity
      variant={SecondaryType.Base}
      creator={creator}
      flags={{ TIME_SAVING_INDICATOR: true }}
      headerActions={actions}
      countryCode={countryCode}
    />
  );
};

export default ApAutomation;

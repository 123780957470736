import React, { useEffect } from "react";
import _ from "underscore";
import { useHistory, useLocation, NavLink } from "react-router-dom";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, AccordionProps as MuiAccordionProps } from "@mui/material";
import { DEFAULT_NUMERIC_VALUES } from "../../../constants/NumericConstants";
import "./AccordionNav.scss";

export type AccordionNavItemType = { id: string; label: string; icon?: React.ReactNode; count?: number; exact: boolean };

interface AccordionNavItemProps extends Omit<AccordionNavItemType, "id"> {
  className?: string;
  to: string;
  exact: boolean;
}

export function AccordionNavItem(props: AccordionNavItemProps): React.ReactElement {
  return (
    <NavLink to={props.to} exact={props.exact}>
      <div className="accordion-megamenu-nav-item">
        {props.icon && <div className={`icon`}>{props.icon}</div>}
        <p className="body2">
          {props.label.charAt(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO).toUpperCase() + props.label.slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ONE)}
        </p>
        <div className="count-wrapper">
          <div className="count">{props.count}</div>
        </div>
      </div>
    </NavLink>
  );
}

interface AccordionNavProps extends Omit<MuiAccordionProps, "children"> {
  className?: string;
  title: string;
  icon?: React.ReactNode;
  paths: string[];
  listItems: AccordionNavItemType[];
  toParser: (value: Omit<AccordionNavItemType, "icon" | "count">) => string;
  activeAccordion: string | false;
  changeActiveAccordion: (event: React.SyntheticEvent, isExpanded: boolean, activeAccordion: string) => void;
  setActiveAccordion: (value: string) => void;
}

export default function AccordionNav({
  className,
  title,
  icon,
  listItems,
  paths,
  toParser,
  activeAccordion,
  changeActiveAccordion,
  setActiveAccordion,
  ...rest
}: AccordionNavProps): React.ReactElement {
  const history = useHistory();
  const { pathname } = useLocation();
  const isAccordionExpanded = activeAccordion === title;
  const isAccordionPathActive = !_.isEmpty(paths.filter((path) => pathname.includes(path)));

  useEffect(() => {
    if (isAccordionPathActive) {
      setActiveAccordion(title);
    } else if (pathname.includes("/settings")) {
      setActiveAccordion("");
    }
  }, [pathname]);

  return (
    <Accordion
      className={`accordion-megamenu-nav-overrides ${className ?? ""}`}
      {...rest}
      expanded={isAccordionExpanded}
      onChange={(event, isExpanded) => changeActiveAccordion(event, isExpanded, title)}
    >
      <AccordionSummary
        expandIcon={!_.isEmpty(listItems) && <KeyboardArrowDown />}
        onClick={() => {
          (title === "Dashboard" && history.push("/dashboard")) ||
            (!isAccordionExpanded && !isAccordionPathActive && history.push(toParser(listItems[DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO])));
        }}
      >
        <div className={`icon`}>{icon}</div>
        <p>{title}</p>
      </AccordionSummary>
      <AccordionDetails>
        {listItems.map((value: AccordionNavItemType) => {
          return (
            <AccordionNavItem
              key={`${value.label.toLocaleLowerCase().split(" ").join("-")}-item`}
              label={value.label}
              to={toParser(value)}
              icon={value.icon}
              count={value.count}
              exact={value.exact}
            />
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
}

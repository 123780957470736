import { CONNECTION_STATUS } from "../../constants/ConnectionConstants";
import { DEFAULT_NUMERIC_VALUES } from "../../constants/NumericConstants";

export default class APIClientUtils {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static buildErrorMessage(error: any): string {
    let msg = "An error has occurred, please retry";

    if (error) {
      if (error.response) {
        if (error.response.data && Array.isArray(error.response.data) && error.response.data.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
          if (error.response.data[0].FieldValue) {
            msg = `Sorry, we could not find results for\n"${error.response.data[0].FieldValue}"`;
          } else if (error.response.data[0].ErrorName) {
            msg = `Sorry, the following error occurred:\n${error.response.data[0].ErrorName}`;
          }
        } else {
          switch (error.response.status) {
            case CONNECTION_STATUS.BAD_REQUEST_400.STATUS_CODE:
              msg = "A bad request was made";
              break;
            case CONNECTION_STATUS.UNAUTHORIZED_401.STATUS_CODE:
              msg = "You are not unauthorized";
              break;
            case CONNECTION_STATUS.FORBIDDEN_403.STATUS_CODE:
              msg = "You are not allowed to perform the operation";
              break;
            case CONNECTION_STATUS.NOT_FOUND_404.STATUS_CODE:
              msg = "A resource could not be found";
              break;
            case CONNECTION_STATUS.INTERNAL_SERVER_ERROR_500.STATUS_CODE:
              msg = "An unexpected server error occurred";
              break;
            case CONNECTION_STATUS.SERVICE_UNAVAILABLE_503.STATUS_CODE:
              msg = "A service is currently unavailable";
              break;
          }
        }
      } else if (error.message) {
        msg = `Error: ${error.message}`;
      }
    }

    return msg;
  }
}

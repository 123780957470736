import React, { useEffect, useState } from "react";
import { getCalendarFieldDateFormat } from "../components/library/AtomicComponents/utils/date";
import { AppContext } from "../contexts/AppContext";
import { formatDate, getDisplayDateFormat } from "../db/utils/date";

/* This hook is implemented to handle language and country mapping with the locale data
 * for ex:  i. date format based on country code,
 * TODO: ii. templates accessibility based on different languages/country.
 * Using this hook all reuired locale data can be access anywhere in the code base
 */
const useLocale = () => {
  const { userStatus } = React.useContext(AppContext) as AppType;
  const [defaultLanguageForTemplates, setDefaultLanguageForTemplates] = useState<string>("");
  const [defaultSelectedLangaugeForTemplateTabs, setDefaultSelectedLangaugeForTemplateTabs] = useState<string>("");
  const [availableLanguagesForTemplates, setAvailableLanguagesForTemplates] = useState<templateLanguage[]>([]);
  const [enabledLanguagesForTemplates, setEnabledLanguagesForTemplates] = useState<string[]>([]);

  //Date format for datepicker calendar dajys
  const calendarDateFormat: string = getCalendarFieldDateFormat(userStatus?.locale_data?.country_code);

  const formatDateBasedOnCountry = (value: string | null): string => formatDate(value, getDisplayDateFormat(userStatus?.locale_data?.country_code));

  //Convert the data into required format
  const getFormatedDataForTemplateLocales = (locales: templateLocalesData[]): { data: templateLanguage[]; enabledLanguageList: string[] } => {
    const format: templateLanguage[] = [];
    const enableList: string[] = [];
    locales.forEach((langItem: templateLocalesData) => {
      format.push({
        id: langItem?.locale_code,
        displayText: langItem?.locale_name,
        label: langItem?.locale_name,
        disabled: langItem?.default,
        isChecked: langItem?.enabled,
      });

      if (langItem?.enabled) {
        enableList.push(langItem?.locale_code);
      }
    });
    return { data: format, enabledLanguageList: enableList };
  };

  //get default selected language for template tabs
  const getDefaultSelectedLangauge = (locales: templateLocalesData[]): string => {
    const defaultEnabledLanguage = locales.find((item: templateLocalesData) => (item.default && item.enabled) || item.enabled);

    return defaultEnabledLanguage?.locale_code ?? "";
  };

  //get default template language
  const getDefaultLangaugeForTemplates = (locales: templateLocalesData[]): string => {
    const defaultEnabledLanguage = locales.find((item: templateLocalesData) => item.default);
    return defaultEnabledLanguage?.locale_code ?? "";
  };

  useEffect(() => {
    const formatData = getFormatedDataForTemplateLocales(userStatus?.locale_data?.locales);
    setDefaultLanguageForTemplates(getDefaultLangaugeForTemplates(userStatus?.locale_data?.locales));
    setDefaultSelectedLangaugeForTemplateTabs(getDefaultSelectedLangauge(userStatus?.locale_data?.locales));
    setAvailableLanguagesForTemplates(formatData?.data);
    setEnabledLanguagesForTemplates(formatData.enabledLanguageList);
  }, [userStatus?.locale_data?.locales]);
  return {
    formatDateBasedOnCountry,
    calendarDateFormat,
    countryCode: userStatus?.locale_data?.country_code,
    defaultLanguageForTemplates,
    defaultSelectedLangaugeForTemplateTabs,
    enabledLanguagesForTemplates,
    setEnabledLanguagesForTemplates,
    availableLanguagesForTemplates,
    setDefaultSelectedLangaugeForTemplateTabs,
  };
};

export default useLocale;

import React from "react";
import { ApprovalUserActionsProps } from "../HelperTypes";
import { Button } from "../../../../../../../library/AtomicComponents/atomic/Button";
import { useTranslation } from "react-i18next";

const ApprovalUserActions: React.FC<ApprovalUserActionsProps> = ({ onClickApprove, onClickCancel, onClickDecline, onClickNudge }) => {
  const { t } = useTranslation();
  return (
    <>
      <Button onClick={onClickApprove} variant="primary">
        {t("activityDetails.feedItems.primaryActivity.helpers.approvalUserActions.buttons.approve", { ns: "activities" })}
      </Button>
      <Button onClick={onClickDecline} variant="secondary">
        {t("activityDetails.feedItems.primaryActivity.helpers.approvalUserActions.buttons.decline", { ns: "activities" })}
      </Button>
      <Button onClick={onClickCancel} variant="secondary">
        {t("activityDetails.feedItems.primaryActivity.helpers.approvalUserActions.buttons.cancelRequest", { ns: "activities" })}
      </Button>
      <Button onClick={onClickNudge} variant="tertiary">
        {t("activityDetails.feedItems.primaryActivity.helpers.approvalUserActions.buttons.sendReminder", { ns: "activities" })}
      </Button>
    </>
  );
};

export default ApprovalUserActions;

import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { GLOBAL_SEARCH_CONSTANTS } from "../../../constants/GlobalSearchConstants";
import { DEFAULT_NUMERIC_VALUES, DEFAULT_PAGINATION_VALUES, NUMERIC_VALUES } from "../../../constants/NumericConstants";
import { AppContext } from "../../../contexts/AppContext";
import { DocumentSwitchContext } from "../../../contexts/DocumentSwitchContext";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";
import { globalSearchClientV2 } from "../../../db/version2Accessor";
import { GlobalSearchCategories, WorkspaceType } from "../../../types/enums";
import { globalSearchConfigGetter } from "../Config";
import { ColumnConfigInterface } from "../../library/TableWithInfiniteScroll/Interfaces/TableInterface";
import {
  InfiniteScrollAPILoadingInterface,
  ObjectInterface,
  PaginationPageCount,
  SearchResultsStringObject,
} from "../Interface/SearchResultContentInterface";
import { createNewSearchHistory, getSearchHistoryProperty, onSelectRecentSearchHistory, setSearchHistory } from "../Utils/LocalStorageUtils";
import ResultPageComponent from "./ResultPageComponent";

let previousTimeout: NodeJS.Timeout;

const ResultPageContainer: React.FC = () => {
  const { setEnableDocumentSwitch } = React.useContext(DocumentSwitchContext) as DocumentSwitchType;
  const history = useHistory();

  // User Status for Currency
  const { userStatus } = React.useContext(AppContext) as AppType;
  const { selectedWorkspace, workspaceHomePath } = React.useContext(WorkspaceContext) as WorkspaceDataType;

  //  Global Search Config
  const colConfigRef = React.useRef(
    globalSearchConfigGetter(selectedWorkspace?.workspace_type_route?.toUpperCase() || WorkspaceType.AR?.toUpperCase())["global-search-results"]
  );
  const searchConfigRef = React.useRef(
    globalSearchConfigGetter(selectedWorkspace?.workspace_type_route?.toUpperCase() || WorkspaceType.AR?.toUpperCase())["global-search-suggestions"]
  );

  const [loading, setLoading] = React.useState<InfiniteScrollAPILoadingInterface>({ initialLoading: true, fetchMoreResultsLoading: false });
  const [searchResults, setSearchResults] = React.useState<Array<GlobalSearchResponse>>([]);
  const [paginationData, setPaginationData] = React.useState<PaginationPageCount>({
    pageCount: DEFAULT_PAGINATION_VALUES.PAGE_COUNT,
    totalPageCount: DEFAULT_PAGINATION_VALUES.TOTAL_PAGE_COUNT,
  });
  const [highlightQuery, setHighlightQuery] = React.useState<string>("");

  // Get Search Filter
  const { category } = useParams<{ category: string }>();

  //  Get Search String
  const { pathname, search } = useLocation();
  const [filterQuery, setFilterQuery] = React.useState<string>("");
  const [filterParamKeyQuery, setFilterParamKeyQuery] = React.useState({});
  const getQueryFromParams = React.useMemo(() => {
    const searchParams = new URLSearchParams(search);
    return { query: searchParams.get("query")?.toString() || "", sort: searchParams.get("sort")?.toString() || "" };
  }, [search]);

  const [searchFieldText, setSearchFieldText] = React.useState<string>(
    getQueryFromParams?.query?.length < GLOBAL_SEARCH_CONSTANTS.MIN_SEARCH_CHARACTERS
      ? getSearchHistoryProperty("searchDialog")?.["searchKeyword"] || ""
      : getQueryFromParams?.query
  );

  // Fetch Search API Call
  const fetchSearchResults = async (initLoad = false, searchQuery: string) => {
    if (searchQuery?.length < GLOBAL_SEARCH_CONSTANTS.MIN_SEARCH_CHARACTERS) {
      setHighlightQuery("");
      setSearchResults([]);
      return;
    }
    /**
     * For Accounting workspace: if category is invoices or bills update
     * query to have filters.
     * TODO: update object to have pre-defined filters on search tabs and
     * make it the filters generic
     **/
    const resultFilters = (function () {
      if (selectedWorkspace?.workspace_type_route === WorkspaceType.AW) {
        if (category === "invoices") {
          return [{ invoice_type_code: ["AR Invoice", "AR Credit Memo"] }];
        } else if (category === "bills") {
          return [{ invoice_type_code: ["AP Invoice", "AP Credit Memo"] }];
        }
      } else return [];
    })();
    let result = {} as APIResponse;
    setLoading({ ...loading, initialLoading: initLoad, fetchMoreResultsLoading: !initLoad });
    try {
      result = await globalSearchClientV2.getSearchSuggestions(
        `${searchQuery ?? ""}${filterQuery}`,
        (GlobalSearchCategories as SearchResultsStringObject)[category],
        selectedWorkspace?.id,
        getQueryFromParams?.sort,
        initLoad ? DEFAULT_NUMERIC_VALUES.DEFAULT_ONE : paginationData?.pageCount + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
        DEFAULT_PAGINATION_VALUES.PAGE_SIZE,
        resultFilters,
        filterParamKeyQuery
      );
    } catch (error) {
      console.error("error:", error);
      setSearchResults([]);
    } finally {
      if (result.success) {
        const resultData = result.data ?? [];

        const resultsArray = resultData
          ?.map((data: ObjectInterface) => {
            return data && data?.result?.map((res: ObjectInterface, pos: number) => ({ id: pos, ...res }));
          })
          ?.flat(DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);

        setSearchResults(initLoad ? resultsArray : searchResults.concat(resultsArray));
        setHighlightQuery(searchQuery);
        setPaginationData({
          ...paginationData,
          pageCount: result?.page as number,
          totalPageCount: result?.total_pages as number,
        });
      }
      setLoading({ ...loading, initialLoading: false, fetchMoreResultsLoading: false });
    }
  };

  const updateFilterQuery = (filterQuery: string, filterKeyQuery: { [key: string]: string | string[] }) => {
    setFilterQuery(filterQuery);
    setFilterParamKeyQuery(filterKeyQuery);
  };

  // Load More func to be called at the end of infinite scroll
  const loadMoreResults = () => {
    fetchSearchResults(false, searchFieldText);
  };

  // Managing Recent Search
  const createRecentSearchHistory = () => {
    createNewSearchHistory(category, selectedWorkspace.workspace_type_route?.toUpperCase() as string);
  };

  const appendRecentSearchHistory = (id: string, query: string, einvoice_status: string,
    category: string, app_enrollment_id: string, company_type: string) => {
    onSelectRecentSearchHistory(
      id,
      query,
      einvoice_status,
      category,
      selectedWorkspace.workspace_type_route?.toUpperCase() as string,
      app_enrollment_id,
      company_type
    );
  };

  // Onclick Routing Function
  const onClickResultsHandler = (id: string, query: string, einvoice_status: string, 
    category: string, app_enrollment_id: string, company_type: string) => {
    if (!id) {
      return;
    }
    setSearchHistory({
      currentSearchContext: {
        currentSearchTitle: "Search",
        currentSearchActivePath: pathname ? pathname + search : `${workspaceHomePath}/dashboard`,
      },
    });
    appendRecentSearchHistory(id, query, einvoice_status, category, app_enrollment_id, company_type);
    setEnableDocumentSwitch(false);
    switch (category) {
      case GlobalSearchCategories.activity_streams:
        return history.push(`${workspaceHomePath}/search/activity_streams/${id}${search}`);
      case GlobalSearchCategories.invoices:
        return history.push(`${workspaceHomePath}/search/${searchConfigRef.current.invoiceType}/${id}${search}`);
      case GlobalSearchCategories.sent_einvoices:
        return history.push(`${workspaceHomePath}/activities/sentEInvoices/${id}`);
      case GlobalSearchCategories.received_einvoices:
        return history.push(`${workspaceHomePath}/activities/receivedEInvoices/${id}`);
      case GlobalSearchCategories.payments:
        return history.push(`${workspaceHomePath}/search/payments/${id}${search}`);
      case GlobalSearchCategories.connections: {
        const routeType = company_type?.includes("Company")
          ? "internal"
          : app_enrollment_id !== null
          ? searchConfigRef.current.connectionType
          : "connections";
        return history.push(`${workspaceHomePath}/search/${routeType}/active/${id}${search}&sort=last_activity_at+DESC`);
      }
      case GlobalSearchCategories.attachments:
        return history.push(
          `${workspaceHomePath}/search/${searchConfigRef.current.connectionType}/active/${id}/documents${search}&sort=created_at+DESC&file_name=${query}`
        );
      case GlobalSearchCategories.contacts:
        return history.push(
          `${workspaceHomePath}/search/${searchConfigRef.current.connectionType}/active/${id}/contacts${search}&sort=contact_name&name=${query}`
        );
      default:
        console.error("Category selection not found");
    }
  };

  // Handler for table row click
  const onClickRowHandler = (data: ObjectInterface) => {
    if (data) {
      const colConfig = searchConfigRef.current["dataSelectors"][category];
      onClickResultsHandler(data?.[colConfig?.id], data?.[colConfig?.header?.key], 
        data?.[colConfig?.einvoice_status?.key], category, data?.app_enrollment_id, data?.company_type);
    }
  };

  /**
   *  Debounce Handler
   */

  const debounce = (
    func: (initLoad: boolean, searchQuery: string) => Promise<void>,
    query: string,
    timeout = NUMERIC_VALUES.CONSTANT_FIVE_HUNDRED
  ) => {
    return (...args: [initLoad: boolean, searchQuery: string]) => {
      clearTimeout(previousTimeout);
      previousTimeout = setTimeout(() => {
        func.apply(this, args);
        history.push({
          pathname: `${workspaceHomePath}/search/${category}`,
          search: `?query=${query}&sort=relevant`,
        });
      }, timeout);
    };
  };

  //handler for search input
  const onChangeSearchTextField = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchFieldText(event?.target?.value);
    debounce(fetchSearchResults, event?.target?.value)(true, event?.target?.value);
  };

  // Fetch Search Results on filter query change
  React.useEffect(() => {
    debounce(fetchSearchResults, searchFieldText)(true, searchFieldText);
  }, [filterQuery, category, filterParamKeyQuery]);

  // Maintaining the LS
  React.useEffect(() => {
    createRecentSearchHistory();
  }, []);

  //If Result page gets invoked from search dialog then reset the hasSearchedFromDialog and again invoke api for search data
  React.useEffect(() => {
    if (getSearchHistoryProperty("searchDialog")?.["hasSearchedFromDialog"]) {
      setSearchFieldText(getSearchHistoryProperty("searchDialog")?.["searchKeyword"] || "");
      debounce(fetchSearchResults, getSearchHistoryProperty("searchDialog")?.["searchKeyword"] || "")(
        true,
        getSearchHistoryProperty("searchDialog")?.["searchKeyword"] || ""
      );
      setSearchHistory({
        searchDialog: {
          searchKeyword: getSearchHistoryProperty("searchDialog")?.["searchKeyword"],
          hasSearchedFromDialog: false,
        },
      });
    }
  }, [getSearchHistoryProperty("searchDialog")?.["hasSearchedFromDialog"]]);
  return (
    <ResultPageComponent
      gsrConfig={{
        initialLoading: loading.initialLoading,
        moreResultsLoading: loading.fetchMoreResultsLoading,
        updateFilterQuery,
        isEInvoice: selectedWorkspace?.workspace_type_route === "e"
      }}
      tableConfig={{
        header: { title: searchConfigRef.current?.["displayLabels"]?.[category] ?? "Invalid Filter" },
        column: colConfigRef.current["table"]["config"]["column"][category] as Array<ColumnConfigInterface>,
        rows: searchResults,
        controllers: {
          loading: loading.initialLoading || loading.fetchMoreResultsLoading,
          hasMore: paginationData.pageCount < paginationData.totalPageCount,
          loadMore: loadMoreResults,
        },
        handlers: {
          onClickRowHandler,
        },
        highlighter: {
          enableHighlighter: true,
          matchText: highlightQuery,
        },
        currencyConfig: userStatus?.currency,
      }}
      searchInputProps={{
        searchText: searchFieldText,
        onChangeSearchTextField,
      }}
    />
  );
};

export default ResultPageContainer;

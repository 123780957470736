import React, { useState, useEffect } from "react";
import { featureFlagClient } from "../db/accessor";
import { useIsAuthenticated } from "@azure/msal-react";
import _ from "underscore";

/**
 * @constant FeatureFlags
 * A react context to consume FeatureFlagsProvider api
 */
export const FeatureFlagContext = React.createContext<FeatureFlagProviderType | null>(null);

export type Props = {
  children: (React.ReactNode & { type: string })[] | (React.ReactNode & { type: string });
};

/**
 * @constant featureFlagsMap
 * An object to hold the feature flag key value mapping.
 */
const featureFlagsMap = {
  FORWARD_AP_AUTOMATION: "Inbox.ForwardToAPAutomation",
  PROFILE_MANAGEMENT: "Inbox.ProfileManagement",
  KEYBOARD_SHORTCUTS: "Inbox.KeyboardShortcuts",
  EMOJI_REACTIONS: "Inbox.EmojiReactions",
  GLOBAL_SEARCH_V2: "Inbox.GlobalSearch_V2",
  ACCOUNTING_WORKSPACE: "Inbox.AccountingWorkspace",
  ACTIVITY_STREAM_V2: "Inbox.ActivityStream_V2",
  ACTIVITY_SHOW_V2: "Inbox.ActivityShow_V2",
  TABLEV2: "Inbox.TableV2",
  MAINTANANCE_MODE: "Inbox.MaintananceMode",
  SELF_SERVICE_APP_SWITCHER: "Inbox.SelfServiceAppSwitcher",
  FILTERS_ENABLE: "Inbox.TableV2.Filters",
  EXPORTS_V2: "Inbox.TableV2.ExportsV2",
  LOCALIZATION_MULTILANG: "Inbox.Localization.Multilanguage",
  NOTIFICATION_ENABLE: "Inbox.Settings.Notification",
  PORTAL_LINK: "Inbox.Portal.InsertLink",
  PROFILE_MANAGEMENT_TEMPLATES: "Inbox.Portal.ProfileManagementTemplates",
  INVOICE_NEW_DOWNLOAD_METHOD: "Inbox.Invoice.DownloadValidations",
  TEMPLATE_WORKKFLOW_V2: "Inbox.EmailTemplatesV2",
};

export type FEATURE_FLAGS = keyof typeof featureFlagsMap;

export type FeatureFlagProviderType = {
  isEnabled: (FLAG_NAME: FEATURE_FLAGS) => boolean;
  updateFlag: (FLAG_NAME: FEATURE_FLAGS, isSilent?: boolean) => Promise<FeatureFlagsResponseModel | undefined>;
  featureFlagsLoading: boolean;
};

/**
 * @function FeatureFlagsProvider
 * A react function to provide FeatureFlags context
 * @param param
 * @returns
 */
const FeatureFlagsProvider: React.FC<Props> = ({ children }: Props) => {
  const [featureFlags, setFeatureFlags] = useState<{ [key: string]: boolean | undefined }>({});
  const [featureFlagsLoading, setFeatureFlagsLoading] = useState<boolean>(true);
  const isAuthenticated = useIsAuthenticated();

  /**
   * @function procesPartialFlags
   * A helper function to check and update specific flags in featureFlags state.
   * @param flags
   * @returns
   */
  const procesPartialFlags = (flags: { [key: string]: boolean | undefined }) => {
    const changed: { [key: string]: boolean | undefined } = {} as { [key: string]: boolean | undefined };
    Object.keys(flags).forEach((flag) => {
      if (featureFlags[flag] !== flags[flag]) changed[flag] = flags[flag];
    });
    return changed;
  };

  /**
   * @function fetchFlag
   * A helper function to fetch a feature flag currently enabled for the user logged in.
   * @param flags Array of flags to be fetched from backend
   * @param isSilent Passed as true when we need to refresh specific set of Feature flags
   * @returns
   */
  const fetchFlag = async (flags: string[], isSilent = false) => {
    try {
      const response = await featureFlagClient.fetchFeatureFlags({ names: flags });
      if (isSilent) {
        const changed = procesPartialFlags(response.values);
        !_.isEmpty(changed) && setFeatureFlags((prevVal) => ({ ...prevVal, ...changed }));
      } else {
        setFeatureFlags(response.values);
      }
      return response;
    } catch (error) {
      console.error(error);
    } finally {
      // always reset the loading
      if (!isSilent) {
        setFeatureFlagsLoading(false);
      }
    }
  };

  /**
   * @function isEnabled
   * A context hook to check if a feature flag is enabled for the current user
   * @param flag
   * @returns
   */
  const isEnabled = (flag: FEATURE_FLAGS) => {
    return featureFlags[featureFlagsMap[flag]] ?? false;
  };

  /**
   * @function isEnabled
   * A context hook to update a specific feature flag
   * @param flag
   * @returns
   */
  const updateFlag = (flag: FEATURE_FLAGS, isSilent?: boolean) => {
    return fetchFlag([featureFlagsMap[flag]], isSilent);
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchFlag(Object.values(featureFlagsMap));
    }
  }, [isAuthenticated]);

  return <FeatureFlagContext.Provider value={{ isEnabled, updateFlag, featureFlagsLoading }}>{children}</FeatureFlagContext.Provider>;
};

export default FeatureFlagsProvider;

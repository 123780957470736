/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ChangeEvent, useState, Dispatch, FormEvent } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { Skeleton, TextField } from "@mui/material";
import Userpic from "../../../library/Sidebar/Userpic/Userpic";
import Button from "../../../library/Button/Button";
import { Close, Delete } from "../../../library/Icons/Icons";
import { DataMode } from "../../../../types/enums";
import { Input } from "../../../library/Input/Input";
import Autocomplete from "../../../library/Autocomplete/Autocomplete";
import { countries } from "../../../../Countries";
import { AppContext } from "../../../../contexts/AppContext";
import { userAccountsClient, companiesClient } from "../../../../db/accessor";
import Dialog from "../../../library/Dialog/Dialog";
import DialogTitle from "../../../library/DialogTitle/DialogTitle";
import DialogContent from "../../../library/DialogContent/DialogContent";
import DialogContentText from "../../../library/DialogContentText/DialogContentText";
import DialogActions from "../../../library/DialogActions/DialogActions";
import "../Profiles.scss";
import IndeterminateCheckbox from "../../../library/Table/Checkbox/Checkbox";
import { states } from "../../../../States";
import { ImageUploader } from "../../../library/ImageUploader";
import { ProfileContext } from "../../../../contexts/ProfileContext";
import { MY_PROFILE_SKELETON_CONSTANTS } from "../../../../constants/StyleConstants";
import Utils from "../../../../utils/utils";
import { AlertContext } from "../../../../contexts/AlertContext";
import { LBL_OPTIONAL } from "../../../../constants/config";
import * as DOMPurify from "dompurify";

type AutoCompleteOption = {
  id: string;
  label: string;
};

type EditMyProfileProps = {
  setDataMode: Dispatch<DataMode>;
  defaultData: UserAccountModel;
  setDefaultData: Dispatch<UserAccountModel>;
  codeDefinitions: CodeDefinitionModel[];
  showCancel: boolean;
  setShowCancel: (showCancel: boolean) => void;
  saveLoad: boolean;
  setSaveLoad: (saveLoad: boolean) => void;
  submitBtnRef: React.RefObject<HTMLButtonElement>;
  isLoading: boolean;
  dataMode: DataMode;
  handleImageUpload: (imageURL: string) => Promise<UserAccountModel>;
  showUploadModal: boolean;
  setShowUploadModal: (showUploadModal: boolean) => void;
  imageUrl: string;
  setImageUrl: (imageUrl: string) => void;
  timezones: AutoCompleteOption[];
  selectedWorkspace: UserItem;
};

export default function EditMyProfile(props: EditMyProfileProps): React.ReactElement {
  const { userStatus, initialLoad } = React.useContext(AppContext) as AppType;
  const { setUserName } = React.useContext(ProfileContext) as ProfileType;
  const [profileData, setProfileData] = useState<UserAccountModel>(props.defaultData);
  const [updateCompanyAddress, setUpdateCompanyAddress] = useState<boolean>(true);
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;

  const handleSave = async () => {
    try {
      props.setSaveLoad(true);
      const userAccount = await userAccountsClient.patchUserAccount(profileData.userId, {
        accountingRoleCodeDefId: profileData.accountingRoleCodeDefId,
        address1: profileData.address1,
        address2: profileData.address2,
        city: profileData.city,
        country: countries.find((country) => country.label === profileData.country)?.id ?? "US",
        faxNumber: profileData.faxNumber,
        phoneNumber: profileData.phoneNumber,
        postalCode: profileData.postalCode,
        stateRegion: profileData.stateRegion,
        title: profileData.stateRegion,
        userName: profileData.userName,
      });
      if (userAccount.userName) {
        setUserName(userAccount.userName);
      }
      props.setDefaultData({ ...userAccount, accountingRoleCodeDefinition: profileData.accountingRoleCodeDefinition });
      if (updateCompanyAddress) {
        await companiesClient.patchCompany(userStatus.account_company_id ?? "", {
          address1: profileData.address1,
          address2: profileData.address2,
          address3: profileData.address3,
          postalCode: profileData.postalCode,
          city: profileData.city,
          stateRegion: profileData.stateRegion,
          country: countries.find((country) => country.label === profileData.country)?.id ?? "US",
        });
      }
    } catch (e) {
      setToastOptions({
        open: true,
        severity: "error",
        message: "An unknown error occurred",
      });
    }
    props.setDataMode(DataMode.View);
    setToastOptions({
      open: true,
      severity: "success",
      message: "Your profile was saved successfully",
    });
    props.setSaveLoad(false);
    return false;
  };

  const checkboxProps = {
    onChange: (event: ChangeEvent<HTMLInputElement>) => {
      setUpdateCompanyAddress(event.target.checked);
    },
    checked: updateCompanyAddress,
  };

  return (
    <div className="edit-profile">
      <div className="profile-edit-bar">
        <div className="info-row">
          <h2 className="info-header">Personal Information</h2>
          <p>Confirm or edit your photo and personal details here.</p>
        </div>
        <Dialog open={props.showCancel} onClose={() => props.setShowCancel(false)} maxWidth="xs">
          <span
            className="dialog-cancel-close-icon"
            onClick={() => {
              props.setShowCancel(false);
            }}
          >
            <Close />
          </span>
          <DialogTitle>Unsaved Changes</DialogTitle>
          <DialogContent className="mt-ba mb-lg">
            <DialogContentText>
              We noticed you made some changes to your profile. Are you sure you want to leave without saving those changes?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className="primary-border"
              variant="primary"
              onClick={() => {
                setProfileData(props.defaultData);
                props.setDataMode(DataMode.View);
                props.setShowCancel(false);
              }}
            >
              Yes, I’m fine without saving.
            </Button>
            <Button className="ml-auto secondary-border" variant="secondary" onClick={() => props.setShowCancel(false)}>
              Oops, I’m still working.
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <OverlayScrollbarsComponent options={{ paddingAbsolute: true, autoUpdate: true, sizeAutoCapable: true }}>
        <form
          onSubmit={(e: FormEvent) => {
            e.preventDefault();
            handleSave();
            return false;
          }}
          className="container"
          id="EditMyProfileForm"
        >
          <div className="user-avatar-info">
            <p className="avatar-title">Your Photo</p>
            <div className="user-avatar-container">
              {initialLoad || props.isLoading ? (
                <>
                  <Skeleton
                    variant="circular"
                    width={Utils.getConvertedSizeInRem(MY_PROFILE_SKELETON_CONSTANTS.PROFILE_IMG.RADIUS)}
                    height={Utils.getConvertedSizeInRem(MY_PROFILE_SKELETON_CONSTANTS.PROFILE_IMG.RADIUS)}
                    animation="wave"
                  />
                  <div className="btns-grp">
                    <Skeleton
                      variant="text"
                      width={Utils.getConvertedSizeInRem(MY_PROFILE_SKELETON_CONSTANTS.PROFILE_UPLOAD.WIDTH)}
                      height={Utils.getConvertedSizeInRem(MY_PROFILE_SKELETON_CONSTANTS.PROFILE_UPLOAD.HEIGHT)}
                      animation="wave"
                      className="profile-edit-btn"
                    />
                    <Skeleton
                      variant="text"
                      width={Utils.getConvertedSizeInRem(MY_PROFILE_SKELETON_CONSTANTS.PROFILE_REMOVE.WIDTH)}
                      height={Utils.getConvertedSizeInRem(MY_PROFILE_SKELETON_CONSTANTS.PROFILE_REMOVE.HEIGHT)}
                      animation="wave"
                      className="profile-edit-btn"
                    />
                  </div>
                </>
              ) : (
                <>
                  <Userpic className="avatar-view" imageUrl={props?.imageUrl ?? ""} />
                  <div className="btns-grp">
                    <Button
                      type="button"
                      variant="primary"
                      size="sm"
                      className="profile-edit-btn primary"
                      onClick={() => props.setShowUploadModal(true)}
                    >
                      Upload A Photo
                    </Button>
                    <Button
                      type="button"
                      variant="secondary"
                      size="sm"
                      alignIcon="right"
                      icon={<Delete />}
                      className="profile-edit-btn secondary-border"
                      onClick={() => {
                        props.setImageUrl("");
                        props.handleImageUpload("");
                      }}
                    >
                      Delete This Photo
                    </Button>
                  </div>
                  <ImageUploader
                    open={props.showUploadModal}
                    toggleFunction={(bool: boolean) => {
                      props.setShowUploadModal(bool);
                    }}
                    getImageURL={(url: string) => {
                      props.handleImageUpload(url).then(() => {
                        props.setImageUrl(url);
                      });
                    }}
                    selectedWorkspace={props.selectedWorkspace}
                  />
                </>
              )}
            </div>
          </div>
          <div className="form-row">
            <div className="form-sub-row">
              <Input
                label={"Your Full Name"}
                defaultValue={profileData.userName}
                onChange={(e) =>
                  setProfileData((prev: UserAccountModel) => {
                    return { ...prev, userName: DOMPurify.sanitize(e.target.value) };
                  })
                }
                className="form-input"
                placeholder="Chelsea Henley"
                data-testid="EditMyName"
                required
              />
              <Input
                label={{
                  regularText: "Your Title ",
                  extraText: LBL_OPTIONAL,
                }}
                defaultValue={profileData.title}
                onChange={(e) =>
                  setProfileData((prev: UserAccountModel) => {
                    return { ...prev, title: DOMPurify.sanitize(e.target.value) };
                  })
                }
                className="form-input"
                placeholder="AR Associate"
                data-testid="EditTitleName"
              />
            </div>
            <div className="form-sub-row">
              <Input
                label={"Your Email"}
                defaultValue={profileData.email}
                onChange={(e) =>
                  setProfileData((prev: UserAccountModel) => {
                    return { ...prev, email: DOMPurify.sanitize(e.target.value) };
                  })
                }
                placeholder="ap@gekko.com"
                data-testid="EditEmail"
                className="form-input"
                required
                disabled
              />
              <div className="sb-wrapper form-input">
                <span className="sb-label-container">
                  <label className="sb-label">
                    Your Role <span className="sb-label-optional">{LBL_OPTIONAL}</span>
                  </label>
                </span>
                <Autocomplete
                  className="field-md"
                  options={props.codeDefinitions.map((codeDef: CodeDefinitionModel) => {
                    return {
                      id: codeDef.codeDefinitionId,
                      label: codeDef.codeDescription,
                    };
                  })}
                  onChange={(_: any, value: { id: string; label: string }) => {
                    setProfileData((prev: UserAccountModel) => {
                      return {
                        ...prev,
                        accountingRoleCodeDefId: value.id,
                        accountingRoleCodeDefinition: props.codeDefinitions.find((elem) => elem.codeDefinitionId === value.id) ?? null,
                      };
                    });
                  }}
                  value={profileData.accountingRoleCodeDefinition?.codeDescription ?? null}
                  disableClearable
                  isOptionEqualToValue={(option: any, value: any) => {
                    return option.label === (value.label || value);
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="form-sub-row">
              <Input
                label={"Your Phone"}
                defaultValue={profileData.phoneNumber}
                onChange={(e) =>
                  setProfileData((prev: UserAccountModel) => {
                    return { ...prev, phoneNumber: DOMPurify.sanitize(e.target.value) };
                  })
                }
                className="form-input"
                placeholder="(123) 456-7890"
                data-testid="EditPhone"
                maxLength={20}
              />
              <Input
                label={"Your Fax"}
                defaultValue={profileData.faxNumber}
                onChange={(e) =>
                  setProfileData((prev: UserAccountModel) => {
                    return { ...prev, faxNumber: DOMPurify.sanitize(e.target.value) };
                  })
                }
                className="form-input"
                placeholder="(123) 456-7890"
                data-testid="EditFax"
                maxLength={20}
              />
            </div>
          </div>
          <div className="form-address-row last-field">
            <div className="address-checkbox-row">
              <div>
                <IndeterminateCheckbox {...checkboxProps} />
              </div>
              <span className="checkbox-text">My address and company address are the same</span>
            </div>
            <div className="form-sub-row">
              <div className="col-wl">
                <Input
                  label={"My Street Address"}
                  defaultValue={profileData.address1}
                  onChange={(e) =>
                    setProfileData((prev: UserAccountModel) => {
                      return { ...prev, address1: DOMPurify.sanitize(e.target.value) };
                    })
                  }
                  className="form-input"
                  placeholder="123 Main Street"
                  data-testid="EditStreetAddr"
                />
              </div>
              <div className="col-wm">
                <Input
                  label={{
                    regularText: "Unit or Suite ",
                    extraText: LBL_OPTIONAL,
                  }}
                  defaultValue={profileData.address2}
                  onChange={(e) =>
                    setProfileData((prev: UserAccountModel) => {
                      return { ...prev, address2: DOMPurify.sanitize(e.target.value) };
                    })
                  }
                  className="form-input"
                  placeholder="2"
                  data-testid="EditStreetAddr"
                />
              </div>
            </div>
            <div className="form-sub-row">
              <div className="col-wl">
                <Input
                  label={"Zip Code"}
                  defaultValue={profileData.postalCode}
                  onChange={(e) =>
                    setProfileData((prev: UserAccountModel) => {
                      return { ...prev, postalCode: DOMPurify.sanitize(e.target.value) };
                    })
                  }
                  className="form-input"
                  placeholder="94016"
                  data-testid="EditZipcode"
                  maxLength={10}
                />
                <Input
                  label={"City"}
                  defaultValue={profileData.city}
                  onChange={(e) =>
                    setProfileData((prev: UserAccountModel) => {
                      return { ...prev, city: DOMPurify.sanitize(e.target.value) };
                    })
                  }
                  className="form-input"
                  placeholder="San Fransisco"
                  data-testid="EditCity"
                />
              </div>
              <div className="col-wm">
                <div className="sb-wrapper">
                  <div className="sb-label-container">
                    <label className="sb-label" htmlFor="DefaultStateInput">
                      State
                    </label>
                  </div>
                  <Autocomplete
                    options={states}
                    className="form-input"
                    onChange={(_: any, value: { id: string; label: string }) => {
                      if (value) {
                        setProfileData((prev: UserAccountModel) => {
                          return { ...prev, stateRegion: value.label };
                        });
                      } else {
                        setProfileData((prev: UserAccountModel) => {
                          return { ...prev, stateRegion: "CA" };
                        });
                      }
                    }}
                    value={profileData.stateRegion}
                    disableClearable
                    isOptionEqualToValue={(option: any, value: any) => option.label === value}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                          autoComplete: "state",
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="form-sub-row">
              <div className="col-wl">
                <div className="sb-wrapper">
                  <div className="sb-label-container">
                    <label className="sb-label" htmlFor="DefaultCountryInput">
                      Country
                    </label>
                  </div>
                  <Autocomplete
                    options={countries}
                    className="form-input"
                    onChange={(_: any, value: { id: string; label: string }) => {
                      if (value) {
                        setProfileData((prev: UserAccountModel) => {
                          return { ...prev, country: value.label };
                        });
                      } else {
                        setProfileData((prev: UserAccountModel) => {
                          return { ...prev, country: "United States Of America" };
                        });
                      }
                    }}
                    value={countries.find((country) => country.id === profileData.country || country.label === profileData.country)?.label}
                    disableClearable
                    isOptionEqualToValue={(option: any, value: any) => option.label === value || option.id === value}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                          autoComplete: "country",
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <button ref={props.submitBtnRef} type="submit" style={{ display: "none" }}>
            submit
          </button>
        </form>
      </OverlayScrollbarsComponent>
    </div>
  );
}

import { TEMPLATE_TIMING_CONSTANTS } from "../../constants/NumericConstants";
import BaseTemplate from "./BaseTemplate";

/**
 * Template class which generate the text for Payment Confirmation.
 */
class PaymentConfirmation extends BaseTemplate {
  /**
   * Templates From Time definition
   */
  protected fromTime = TEMPLATE_TIMING_CONSTANTS.THREE_MIN_THIRTY_SEC;

  /**
   * Templates To Time definition
   */
  protected toTime = TEMPLATE_TIMING_CONSTANTS.FIVE_MIN;
}

export default PaymentConfirmation;

import React from "react";
import InvoiceDetail from "./InvoiceDetail/InvoiceDetail";
import { Switch, Route, useRouteMatch, matchPath } from "react-router-dom";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import "./Invoices.scss";
import FourOFourError from "../../routes/FourOFourError/FourOFourError";
import InvoiceNavbar from "./InvoiceNavbar";
import useWorkspaceConfigurations from "../../hooks/useWorkspaceConfigurations";
import { WorkspaceType } from "../../types/enums";
import InvoiceTableV2 from "./InvoiceTable/InvoiceTableV2";

/**
 * * Defines the Invoices Tab (Top-level route)
 */
export default function Invoices(): React.ReactElement {
  const { path } = useRouteMatch();
  const { configProvider, pathType, configs: currentWorkspaceConfigs, selectedWorkspace } = useWorkspaceConfigurations();
  const configsV2 = (selectedWorkspace.workspace_type_route === WorkspaceType.AW ? configProvider[pathType] : currentWorkspaceConfigs)["invoicesV2"];
  const matchActivityId = matchPath(location.pathname, {
    path: "*/activities/:id",
    exact: true,
    strict: true,
  });

  return (
    <div className={`invoices-wrapper`}>
      <InvoiceNavbar />
      <OverlayScrollbarsComponent options={{ paddingAbsolute: true, autoUpdate: true, overflowBehavior: { x: "hidden" } }}>
        <div className={`body table-v2 ${matchActivityId !== null ? "padding-v2" : ""}`}>
          <Switch>
            <Route
              exact
              path={[
                `${path}`,
                `${path}/late_91`,
                `${path}/late_61_90`,
                `${path}/late_31_60`,
                `${path}/late_1_30`,
                `${path}/future_due`,
                `${path}/closed`,
                `${path}/:invoiceId/applied_credit_memos`,
              ]}
              component={() => {
                return <InvoiceTableV2 configs={configsV2} />;
              }}
            />
            <Route
              path={`${path}/:invoiceId`}
              render={() => {
                return <InvoiceDetail />;
              }}
            />
            <Route
              component={() => {
                return <FourOFourError />;
              }}
            />
          </Switch>
        </div>
      </OverlayScrollbarsComponent>
    </div>
  );
}

/*eslint-disable*/
import React from "react";
import useExport from "./useExport";
import { ColumnConfigs, SortConfigs, TableInitialConfigs, PaginationConfigs, CurrencyConfigType } from "../types/table.types";
import { FilterConfigs, TableHook } from "../types/hooks.types";
import { RowSelect } from "../types/hooks.types";

const useTable: TableHook = (
  tableInitialConfigs: TableInitialConfigs,
  columnConfigs: ColumnConfigs[],
  rows: any[],
  sortInitialConfigs: SortConfigs,
  selectionConfig: RowSelect,
  filterInitialConfigs: FilterConfigs,
  paginationConfig: PaginationConfigs,
  currencyConfig?: CurrencyConfigType
) => {
  const exportConfig = useExport(columnConfigs, rows, "", currencyConfig);
  const [actionHoverId, setActionHoverId] = React.useState<string>("");

  //Reset the selected state of the tables when there is change in sorting and filter
  React.useEffect(() => {
    selectionConfig.resetTableRecords();
  }, [filterInitialConfigs.filterQuery, sortInitialConfigs?.sort?.active]);

  React.useEffect(() => {
    if (rows?.length) {
      selectionConfig?.addNewTableData(rows ?? []);
    }
  }, [paginationConfig?.page?.pageNumber, paginationConfig?.page?.totalRecords, tableInitialConfigs?.loading, tableInitialConfigs?.isRefetching]);

  return {
    sortConfigs: sortInitialConfigs,
    actionConfigs: {
      actionHoverId,
      setActionHoverId,
    },
    selectionConfigs: {
      ...selectionConfig,
      ...exportConfig,
    },
    filterConfigs: filterInitialConfigs,
    tableConfigs: {
      ...tableInitialConfigs,
      name: tableInitialConfigs?.name ?? new Date().getTime()?.toString(),
      loading: tableInitialConfigs?.loading ?? false,
      extraHandlers: {
        drawerHandler: tableInitialConfigs?.extraHandlers?.drawerHandler || (() => {}),
        setSnackbarConfig: tableInitialConfigs?.extraHandlers?.setSnackbarConfig || (() => {}),
      },
      rows: tableInitialConfigs?.loading ? new Array(20).fill(0) : rows instanceof Array ? rows : [],
    },
  };
};

export default useTable;

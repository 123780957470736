import React from "react";
import EInvoiceFeed from "./EInvoiceFeed/EInvoiceFeed";
import "./EInvoiceDetail.scss";

export default function EInvoiceDetail(): React.ReactElement {
  return (
    <div className="activity-detail-wrapper-grid e-invoicing">
      <div className="activity-detail-left">
        <div className="body">
          <EInvoiceFeed />
        </div>
      </div>
    </div>
  );
}

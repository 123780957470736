import { Jodit } from "jodit-pro-react";
import { LinkDark } from "../../JoditIcons/JoditIcons";
import { IJodit } from "jodit/types";
import DOMPurify from "dompurify";

/**
 * @function openEditHrefModal
 * @param editor - The editor instance of type Partial<IJodit>
 */
function openEditHrefModal(editor?: any) {
  /**
   * Get reference to the edit hyperlink modal
   */
  const editModalContainer = document.querySelector(".hyperlink-edit-modal-container") as HTMLDivElement;

  /**
   * @function closeEditModal
   * A helper to close the edit hyperlink modal
   */
  function closeEditModal() {
    editModalContainer.innerHTML = "";
    editModalContainer.classList.remove("is-visible");
  }

  /**
   * @function extractContent
   * A helper function to extract the content of a HTML string
   * @see {@link https://stackoverflow.com/questions/28899298/extract-the-text-out-of-html-string-using-javascript | Extract the text out of HTML string using JavaScript}
   * @param s - HTML string
   * @param space - Whether to include space or not
   * @returns text string
   */
  function extractContent(s: string, space: boolean) {
    const span = document.createElement("span");
    span.innerHTML = s;
    if (space) {
      const children = span.querySelectorAll("*");
      for (let i = 0; i < children.length; i++) {
        if (children[i].textContent) children[i].textContent += " ";
        else (children[i] as HTMLElement).innerText += " ";
      }
    }
    return [span.textContent || span.innerText].toString().replace(/ +/g, " ");
  }

  /**
   * save the current selection
   */
  const selectedText = editor.s.html;
  /**
   * Template for the edit hyperlink modal
   */
  editModalContainer.innerHTML = `<div class="modal-dialog">
    <header class="modal-header">
    <span class="modal-title">Edit</span>
    <span class="close-button"><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CloseIcon"><path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg></span>
    </header>
    <section class="modal-content">
    <div class="modal-form">
    <label>Text</label>
    <input type="text" value="${extractContent(selectedText || "", false)}" name="text"/>
    <label>Link</label>
    <input type="text" value="" name="href"/>
    </div>
    </section>
    <footer class="modal-footer">
    <button class="btn btn-secondary btn-lg secondary-border cancel-edit"><p class="btn-text">Cancel</p></button>
    <button class="btn btn-primary btn-lg secondary-border save-edit"><p class="btn-text">Save</p></button>
  </footer>
  </div>`;
  editModalContainer.classList.add("is-visible");
  const modalClose = editModalContainer.querySelector(".close-button") as HTMLDivElement;
  modalClose.addEventListener("click", closeEditModal);
  const cancelEdit = editModalContainer.querySelector(".cancel-edit") as HTMLDivElement;
  cancelEdit.addEventListener("click", closeEditModal);
  const saveEdit = editModalContainer.querySelector(".save-edit") as HTMLDivElement;
  saveEdit.addEventListener("click", () => {
    const text = (editModalContainer.querySelector('input[name="text"]') as HTMLInputElement)?.value;
    const href = (editModalContainer.querySelector('input[name="href"]') as HTMLInputElement)?.value;

    //Sanitize user input to avoid vulnerable to HTML injection
    const sanitizezHref = DOMPurify.sanitize(href);
    const sanitizedLinkText = DOMPurify.sanitize(text);
    const anchor = document.createElement("a");
    anchor.href = sanitizezHref;
    anchor.textContent = sanitizedLinkText;

    /**
     * Remove the selection and insert the constructed hyperlink
     */
    if (selectedText.length) {
      editor.s.remove();
    }
    editor.s.insertHTML(anchor);
    closeEditModal();
  });
}

/**
 * Add additional button to the default options
 */
Jodit.defaultOptions.controls.LinkEditorPlugin = {
  icon: LinkDark,
  tooltip: "Insert Link",
  exec: (editor: any) => {
    openEditHrefModal(editor);
  },
};

/**
 * @class LinkEditor
 * A complex class based Jodit plugin to add hyperlink or modify a selection to a hyperlink.
 * This plugin adds a new button to the default controls list
 */
export default class LinkEditor {
  buttons = [
    {
      name: "LinkEditorPlugin",
      group: "insert",
    },
  ];

  init(jodit: IJodit): void {
    jodit.events.on("afterInit", () => {
      return;
    });
  }
}

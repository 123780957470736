import React from "react";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import { Caution } from "../Icons/Icons";
import "./Chip.scss";

// Chip variant colors
export type ChipVariant =
  | "open"
  | "late1"
  | "late31"
  | "late61"
  | "late91"
  | "closed"
  | "grey"
  | "connected"
  | "disconnected"
  | "active"
  | "not-started"
  | "activity-closed"
  | "archived"
  | "snoozed"
  | "customer"
  | "company"
  | "internal"
  | "other"
  | "vendor"
  | "new"
  | "seen"
  | "connecting";

// Define Chip Props
interface ChipProps {
  variant: ChipVariant;
  iconVariant?: "caution" | "danger";
  text: string;
  size?: "sm" | "lg";
  className?: string;
  dueDate?: string;
}

// Define Chip ReactElement

/**
 * Chip icon element for displaying invoice status indicators.
 *
 * @param { ChipVariant } variant Type of status indicator, added to chip css class.
 * @param { ChipProps.size } size Options "sm", "lg". Default is "lg"
 * @param { ChipProps.text } text Custom text parameter for "grey" indicators. Will not
 *                                be applied to other variants.
 *
 * @returns Chip ReactElement in div with styles applied.
 */
export default function Chip({ dueDate = "", ...props }: ChipProps): React.ReactElement {
  return (
    <div className={`chip ${"chip-" + props.variant} ${props.size === "sm" ? "chip-sm" : "chip-lg"} ${props.className ? props.className : ""}`}>
      <div className={`chip-bg`}></div>
      <p className={`chip-text`}>{props.text}</p>
      {props.iconVariant === "caution" && (
        <CustomTooltip title={`Due on ${dueDate}`} placement="top">
          <div className="chip-caution-info">
            <Caution />
          </div>
        </CustomTooltip>
      )}
    </div>
  );
}

import React from "react";
import { AttachmentChipRoot, AttachmentFileDetails, AttachmentFileName, AttachmentFileSize, AttachmentFileDownload } from "../styled/styled";
import { Skeleton } from "@mui/material";

export default function ContactsCardLoader(): React.ReactElement {
  return (
    <AttachmentChipRoot>
      <Skeleton variant="rectangular" width="1.5rem" height="1.5rem" animation="wave" />
      <AttachmentFileDetails>
        <AttachmentFileName>
          <Skeleton variant="text" width="90%" height="100%" animation="wave" />
        </AttachmentFileName>
        <AttachmentFileSize>
          <Skeleton variant="text" width="80%" height="100%" animation="wave" />
        </AttachmentFileSize>
      </AttachmentFileDetails>
      <AttachmentFileDownload>
        <Skeleton variant="circular" width="0.875rem" height="0.875rem" animation="wave" />
      </AttachmentFileDownload>
    </AttachmentChipRoot>
  );
}

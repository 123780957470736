import React from "react";
import _ from "underscore";
import { Input, InputAdornment, Skeleton, Typography } from "@mui/material";
import { Search } from "@mui/icons-material";
import { CheckedRoundSuccessGreen, CommandCenterActivityMove } from "../../../../../library/Icons/Icons";
import { NUMERIC_VALUES } from "../../../../../library/AtomicComponents/constants/numeric.constants";
import { ActivityContext } from "../../../../../../contexts/ActivityContext";
import { ActivitySubmenuContainer, ActivitySubmenuHeader, SearchInputContainer, OptionValuesList, OptionValuesListItem } from "./styled";
import { companiesClientV2 } from "../../../../../../db/version2Accessor";
import { ConnectionStatus, FallbackTypes, WorkspaceType } from "../../../../../../types/enums";
import useWorkspaceConfigurations from "../../../../../../hooks/useWorkspaceConfigurations";
import useInfiniteScroll from "../../../../../library/DebouncedAutocomplete/hooks/useInfiniteScroll";
import { DEFAULT_NUMERIC_VALUES } from "../../../../../../constants/NumericConstants";
import { SmartText } from "../../../../../library/SmartText";
import { useTranslation } from "react-i18next";

interface ActivityMoveViewProps {
  activityIds: string[];
  selectedCompany: Partial<CompanyV2> | null;
  setSelectedCompany: (value: Partial<CompanyV2>) => void;
  setShowBulkMoveModal: (value: boolean) => void;
  onClickBulkModalClose: (connectionId: string) => Promise<void>;
  currentConnection?: Partial<PrimaryConnection>;
  isUnlistedEmailMoveActivity?: boolean;
  keyboardShortcutText: string;
}

const ActivityMoveView: React.FC<ActivityMoveViewProps> = ({
  onClickBulkModalClose,
  activityIds,
  selectedCompany,
  setSelectedCompany,
  setShowBulkMoveModal,
  currentConnection,
  isUnlistedEmailMoveActivity,
  keyboardShortcutText,
}) => {
  const [searchText, setSearchText] = React.useState<string>("");
  const { activityData } = React.useContext(ActivityContext) as ActivityType;
  const { pathType, selectedWorkspace } = useWorkspaceConfigurations();
  const handleUserSearch = (event: { target: { value: string } }) => setSearchText(event.target.value);
  const { t } = useTranslation();

  const handleCompanySelection = async (data: CompanyV2[], companyID: string | undefined) => {
    const companySelection = data.find((item) => item.company_id === companyID);
    if (companySelection?.company_id) {
      setSelectedCompany(companySelection);
      if (
        activityIds.length === NUMERIC_VALUES.CONSTANT_ONE &&
        activityData?.primary_connection?.app_enrollment_id === null &&
        !isUnlistedEmailMoveActivity
      ) {
        setShowBulkMoveModal(true);
      } else {
        void (async () => await onClickBulkModalClose(companySelection.company_id))();
      }
    }
  };

  const companyTypes = React.useMemo(() => {
    if (selectedWorkspace.workspace_type_route === WorkspaceType.AW) {
      return ["Vendor", "Customer", "Other"];
    }
    if (pathType === WorkspaceType.AP) {
      return ["Vendor"];
    }
    return ["Customer"];
  }, [selectedWorkspace.workspace_type_route]);

  const fetchCompanyList = async (searchText: string, page?: number) => {
    return companiesClientV2.getCustomersList(
      ConnectionStatus.ACTIVE,
      "qs[s]=company_name",
      NUMERIC_VALUES.CONSTANT_TWENTY,
      page ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
      selectedWorkspace.id || FallbackTypes.Id,
      companyTypes,
      activityIds.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE ? currentConnection?.id : "",
      searchText ? `qa[company_name_cont]=${searchText}` : undefined
    );
  };

  const fetchCompanyListFunc = async (searchText: string, page: number): Promise<{ records: CompanyV2[]; totalRecords: number }> => {
    const response = await fetchCompanyList(searchText, page);
    return { records: response.records ?? [], totalRecords: response.totalCount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO };
  };

  const { options, isLoading, hasError, lastEle, setLastEleRef } = useInfiniteScroll<CompanyV2>({
    apiCall: fetchCompanyListFunc,
    debounce: true,
    delay: NUMERIC_VALUES.CONSTANT_FIVE_HUNDRED,
    searchTerm: searchText,
  });

  const hasOptions = _.isEmpty(searchText) && (options ?? []).length > NUMERIC_VALUES.CONSTANT_ZERO;
  const hasSearchResults = !_.isEmpty(searchText) && options?.some((item) => item?.company_name?.toLowerCase().includes(searchText.toLowerCase()));

  return (
    <ActivitySubmenuContainer>
      <ActivitySubmenuHeader>
        <CommandCenterActivityMove />
        <div className="description">
          <Typography className="title">{t("activityDetails.commandCenter.body.activitiyActionList.move", { ns: "activities" })}</Typography>
          <Typography className="subtitle">{t("activityDetails.commandCenter.body.ActivityMoveView.subTitle", { ns: "activities" })}</Typography>
        </div>
        <Typography className="keyboard-shortcut-text">{keyboardShortcutText}</Typography>
      </ActivitySubmenuHeader>
      <SearchInputContainer>
        <Input
          key="searchInput"
          value={searchText}
          placeholder={t("activityDetails.commandCenter.body.ActivityMoveView.searchPlaceHolderText", { ns: "activities" }) as string}
          onChange={handleUserSearch}
          onKeyDown={(event) => event.stopPropagation()}
          disableUnderline={true}
          startAdornment={
            <InputAdornment position="start">
              <Search className="" />
            </InputAdornment>
          }
        />
      </SearchInputContainer>
      <Typography className="current-value-subtitle">
        {t("activityDetails.commandCenter.body.ActivityMoveView.connectionListHeading.currentConnection", { ns: "activities" })}
      </Typography>
      {currentConnection && (
        <OptionValuesListItem>
          <span className="list-item-icon">
            <CheckedRoundSuccessGreen />
          </span>
          {currentConnection.name}
        </OptionValuesListItem>
      )}
      <Typography className="current-value-subtitle">
        {hasOptions || hasSearchResults || (isLoading && !hasError)
          ? t("activityDetails.commandCenter.body.ActivityMoveView.connectionListHeading.allConnection", { ns: "activities" })
          : t("activityDetails.commandCenter.body.ActivityMoveView.connectionListHeading.nothingFound", { ns: "activities" })}
      </Typography>
      {options.length !== DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && (
        <OptionValuesList>
          {options
            .filter(
              (item: Partial<CompanyV2>) =>
                !_.contains(selectedCompany?.company_id ?? "", item.company_id) &&
                (_.isEmpty(searchText) ||
                  item?.company_name?.toLowerCase().indexOf(searchText.toLowerCase()) !== NUMERIC_VALUES.CONSTANT_NEGATIVE_ONE)
            )
            .map((item: Partial<CompanyV2>) => {
              return (
                <OptionValuesListItem key={item.company_id} onClick={() => void (() => handleCompanySelection(options, item.company_id))()}>
                  <span ref={!_.isUndefined(lastEle) && _.isMatch(item, lastEle) ? setLastEleRef : null} className="list-item-icon">
                    {currentConnection?.id === item.company_id && <CheckedRoundSuccessGreen />}
                  </span>
                  <SmartText
                    highlighter={{
                      enableHighlighter: searchText.length > NUMERIC_VALUES.CONSTANT_ZERO ? true : false,
                      matchText: searchText ?? "~",
                    }}
                    title={item.company_name ?? ""}
                    className="list-item-value"
                  />
                </OptionValuesListItem>
              );
            })}
        </OptionValuesList>
      )}
      {isLoading && (
        <OptionValuesList>
          <OptionValuesListItem>
            <span className="list-item-icon"></span>
            <Skeleton variant="text" width="15%" height="100%" animation="wave" />
          </OptionValuesListItem>
          <OptionValuesListItem>
            <span className="list-item-icon"></span>
            <Skeleton variant="text" width="15%" height="100%" animation="wave" />
          </OptionValuesListItem>
          <OptionValuesListItem>
            <span className="list-item-icon"></span>
            <Skeleton variant="text" width="15%" height="100%" animation="wave" />
          </OptionValuesListItem>
          <OptionValuesListItem>
            <span className="list-item-icon"></span>
            <Skeleton variant="text" width="15%" height="100%" animation="wave" />
          </OptionValuesListItem>
        </OptionValuesList>
      )}
    </ActivitySubmenuContainer>
  );
};

export default ActivityMoveView;

import { makeStyles } from "@mui/styles";
import styles from "../../../../css/_export.module.scss";

const useTemplateSelectMenuStyle = () => {
  const useStyles = makeStyles({
    subjectRoot: {
      display: "flex",
      width: "100%",
    },
    searchInput: {
      width: "100%",
      marginLeft: styles.small,
      fontFamily: styles.primaryFontFamily,
      fontWeight: "400",
      fontSize: styles.fontSizeSM,
      lineHeight: styles.lineHeightMD,
      color: styles.primary,
      "&::placeholder": {
        color: styles.grey70,
      },
    },
    select: {
      height: "2.5rem",
      boxShadow: "none",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
        boxShadow: "none",
      },
      "& .MuiSelect-icon": {
        color: styles.grey700,
      },
    },
    menuRoot: {
      "& .MuiPaper-root": {
        width: `39.37rem`,
        minHeight: `2.5rem`,
        maxHeight: `24rem`,
        background: styles.white100,
        borderRadius: styles.atom,
        boxShadow: `0 ${styles.small} ${styles.medium} rgba(0, 0, 0, 0.1), 0 ${styles.tiny} ${styles.semi} rgba(0, 0, 0, 0.1)`,
        gap: styles.small,
        overflowY: "overlay",
        overflowX: "hidden",
        msOverflowStyle: "none",
        scrollbarWidth: "none",
        marginTop: -styles.medium,
        "&::-webkit-scrollbar": {
          display: "none",
        },
        "& .Mui-selected": {
          background: styles.grey95,
          "&:hover": {
            background: styles.grey95,
          },
        },
      },
      "& .MuiList-root": {
        height: "100%",
        padding: 0,
        "& .MuiMenuItem-root": {
          height: "2.625rem",
          fontFamily: styles.primaryFontFamily,
          fontWeight: "400",
          fontSize: styles.fontSizeSM,
          lineHeight: styles.lineHeightMD,
          gap: styles.small,
          borderRadius: styles.tiny,
          padding: `0 ${styles.base}`,
          "& .label-overflow": {
            overflow: "hidden",
            textOverflow: "ellipsis"
          },
          "&:hover": {
            background: styles.grey98,
          },
        },
      },
    },
    subHeader: {
      padding: 0,
    },
  });
  return useStyles();
};

export default useTemplateSelectMenuStyle;

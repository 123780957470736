import APIClient from "../APIClient";
import { AxiosRequestConfig } from "axios";

const INBOX_API_BASE = `${process.env.REACT_APP_API_ENDPOINT_INBOX_API_PATH}${process.env.REACT_APP_API_ENDPOINT_INBOX_API_V2}`;
const CONFIG_OVERRIDE = {
  baseURL: process.env.REACT_APP_API_ENDPOINT_INBOX,
} as AxiosRequestConfig;

type ApAutomationSettings = {
  ap_automation_email: string;
  activity_stream_id?: string | number;
};

type CopilotSettings = {
  suggested_response_setting: boolean;
};

export class SettingsClient {
  BASE_ROUTE = `${INBOX_API_BASE}/accounts`;

  async updateSettings(payload: ApAutomationSettings): Promise<APIResponse> {
    const res = await APIClient.patch(this.BASE_ROUTE, payload, CONFIG_OVERRIDE);
    return res.data;
  }

  async getCopilotSettings(): Promise<APIResponse> {
    const url = `${INBOX_API_BASE}/account-users/settings`;
    const res = await APIClient.get(url, CONFIG_OVERRIDE);
    return res.data;
  }

  copilotSettingsPatch(payload: CopilotSettings) {
    const url = `${INBOX_API_BASE}/account-users`;
    return APIClient.patch(url, payload, CONFIG_OVERRIDE).then((res) => res.status);
  }
}

import React from "react";
import { EmailChip, ApprovalChip, NoteChip, RaiseDispute, PromiseToPay, EInvoice } from "./activitytypechip.style";
import { ReactComponent as EmailSVG } from "../../svg/email_activity_type.svg";
import { ReactComponent as ApprovalSVG } from "../../svg/approval_activity_type.svg";
import { ReactComponent as NoteSVG } from "../../svg/notes_activity_type.svg";
import { ReactComponent as RaiseDisputeSVG } from "../../svg/raise_dispute_activity_type.svg";
import { ReactComponent as PromiseToPaySVG } from "../../svg/promise_to_pay_activity_type.svg";
import { ReactComponent as EInvoiceSVG } from "../../svg/e_invoice.svg";
import { ReactComponent as PhoneCall } from "../../svg/phone_call.svg";

import { useTranslation } from "react-i18next";

type ActivityTypeChipType = {
  activityType: string;
};

const ActivityTypeChip = ({ activityType }: ActivityTypeChipType): React.ReactElement => {
  const { t } = useTranslation();
  switch (activityType) {
    case "Nudge":
    case "Email":
      return (
        <EmailChip className="activity-type-chip email">
          <EmailSVG /> {t("activityTypeChip.email", { ns: "components" })}
        </EmailChip>
      );
    case "Approval Request":
      return (
        <ApprovalChip className="activity-type-chip email">
          <ApprovalSVG /> {t("activityTypeChip.approvalRequest", { ns: "components" })}
        </ApprovalChip>
      );
    case "Note":
      return (
        <NoteChip className="activity-type-chip email">
          <NoteSVG /> {t("activityTypeChip.note", { ns: "components" })}
        </NoteChip>
      );
    case "Raise Dispute":
      return (
        <RaiseDispute className="activity-type-chip email">
          <RaiseDisputeSVG /> {t("activityTypeChip.raiseDispute", { ns: "components" })}
        </RaiseDispute>
      );
    case "Promise To Pay":
      return (
        <PromiseToPay className="activity-type-chip email">
          <PromiseToPaySVG /> {t("activityTypeChip.promiseToPay", { ns: "components" })}
        </PromiseToPay>
      );
    case "Phone Call":
      return (
        <PromiseToPay className="activity-type-chip email">
          <PhoneCall /> {t("activityTypeChip.phoneCall", { ns: "components" })}
        </PromiseToPay>
      );
    case "E-Invoice":
      return (
        <EInvoice className="activity-type-chip e-invoice">
          <EInvoiceSVG />
          {t("activityTypeChip.einvoice", { ns: "components" })}
        </EInvoice>
      );
    default:
      return <></>;
  }
};
export default ActivityTypeChip;

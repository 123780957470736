import React from "react";
import { Input } from "../../../library/Input/Input";

interface ActivityTitleProps {
  title: string;
  isDisabled?: boolean;
  handleOnChange: (value: string) => void;
  placeHolderText?: string;
  validationMessage?: string;
  showValidationMessage?: boolean;
  setShowValidationMessage?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ActivityTitle(props: ActivityTitleProps): React.ReactElement {
  return (
    <Input
      className={`${props?.showValidationMessage ? "error" : ""}`}
      value={props?.showValidationMessage ? props?.validationMessage : props.title}
      disabled={props?.isDisabled ?? false}
      onChange={(e) => props.handleOnChange(e.target.value)}
      placeholder={props?.placeHolderText ?? "Subject"}
      onClick={() => {
        props?.setShowValidationMessage && props?.setShowValidationMessage(false);
      }}
    />
  );
}

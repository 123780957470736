import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { DEFAULT_NUMERIC_VALUES, DEFAULT_PAGINATION_VALUES } from "../../../constants/NumericConstants";
import { AppContext } from "../../../contexts/AppContext";
import { DocumentSwitchContext } from "../../../contexts/DocumentSwitchContext";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";
import { globalSearchClientV2 } from "../../../db/version2Accessor";
import { GlobalSearchCategories, WorkspaceType } from "../../../types/enums";
import { ColumnConfigInterface } from "../../library/TableWithInfiniteScroll/Interfaces/TableInterface";
import { globalSearchConfigGetter } from "../Config";
import { InfiniteScrollAPILoadingInterface, PaginationPageCount, SearchResultsStringObject } from "../Interfaces/SearchResultsInterfaces";
import { createNewSearchHistory, onSelectRecentSearchHistory, setSearchHistory } from "../Utils/LocalStorageUtils";
import ResultsViewComponent from "./ResultsViewComponent";

const ResultsViewContainer: React.FC = () => {
  /**
   * Document Switcher for Activity Streams, Invoices & Payments
   */
  const { setEnableDocumentSwitch } = React.useContext(DocumentSwitchContext) as DocumentSwitchType;

  /**
   * Nav States
   */
  const history = useHistory();

  /**
   * User Status for Currency
   */
  const { userStatus } = React.useContext(AppContext) as AppType;

  /**
   * Workspace
   */
  const { selectedWorkspace, workspaceHomePath } = React.useContext(WorkspaceContext) as WorkspaceDataType;

  /**
   * Global Search Config
   */

  // Column Config
  const colConfigRef = React.useRef(
    globalSearchConfigGetter(selectedWorkspace?.workspace_type_route?.toUpperCase() || WorkspaceType.AR?.toUpperCase())["global-search-results"]
  );

  // Search Results Config
  const searchConfigRef = React.useRef(
    globalSearchConfigGetter(selectedWorkspace?.workspace_type_route?.toUpperCase() || WorkspaceType.AR?.toUpperCase())["global-search-suggestions"]
  );

  /**
   * Search Results States
   */
  const [loading, setLoading] = React.useState<InfiniteScrollAPILoadingInterface>({ initialLoading: false, fetchMoreResultsLoading: false });
  const [searchResults, setSearchResults] = React.useState<Array<GlobalSearchResponse>>([]);
  const [paginationData, setPaginationData] = React.useState<PaginationPageCount>({
    pageCount: DEFAULT_PAGINATION_VALUES.PAGE_COUNT,
    totalPageCount: DEFAULT_PAGINATION_VALUES.TOTAL_PAGE_COUNT,
  });

  /**
   * Get Search Filter
   */
  const { category } = useParams<{ category: string }>();

  /**
   * Get Search String
   */
  const { pathname, search } = useLocation();
  const getQueryFromParams = React.useMemo(() => new URLSearchParams(search).get("query"), [search]);

  /**
   * Fetch Search API Call
   */
  const fetchSearchResults = async (initLoad = false) => {
    let result = {} as APIResponse;
    setLoading({ ...loading, initialLoading: initLoad && true, fetchMoreResultsLoading: !initLoad && true });
    if (getQueryFromParams?.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      setSearchResults([]);
      setLoading({ ...loading, initialLoading: false, fetchMoreResultsLoading: false });
      return;
    }
    try {
      result = await globalSearchClientV2.getSearchSuggestions(
        getQueryFromParams as string,
        (GlobalSearchCategories as SearchResultsStringObject)[category],
        selectedWorkspace?.id,
        "relevant",
        initLoad ? DEFAULT_NUMERIC_VALUES.DEFAULT_ONE : paginationData?.pageCount + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
        DEFAULT_PAGINATION_VALUES.PAGE_SIZE
      );
    } catch (error: any) {
      console.error(error.response);
    } finally {
      if (result.success) {
        const resultData = result.data ?? [];

        const resultsArray = resultData
          ?.map((data: any) => {
            return data && data?.result?.map((res: any, pos: number) => ({ id: pos, ...res }));
          })
          ?.flat(DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);

        setSearchResults(initLoad ? resultsArray : searchResults.concat(resultsArray));

        setPaginationData({
          ...paginationData,
          pageCount: result?.page as number,
          totalPageCount: result?.total_pages as number,
        });
      }
      setLoading({ ...loading, initialLoading: false, fetchMoreResultsLoading: false });
    }
  };

  /**
   * Load More func to be called at the end of infinite scroll
   */
  const loadMoreResults = () => {
    fetchSearchResults();
  };

  /**
   *  Managing Recent Search
   */
  const createRecentSearchHistory = () => {
    createNewSearchHistory(category, selectedWorkspace.workspace_type_route?.toUpperCase() as string);
  };

  const appendRecentSearchHistory = (id: string, query: string, category: string) => {
    onSelectRecentSearchHistory(id, query, category, selectedWorkspace.workspace_type_route?.toUpperCase() as string);
  };

  /**
   * Onclick Routing Function
   */
  const onClickResultsHandler = (id: string, query: string, category: string) => {
    if (!id) {
      return;
    }
    setSearchHistory({
      currentSearchContext: {
        currentSearchTitle: "Search",
        currentSearchActivePath: pathname ? pathname + search : `${workspaceHomePath}/dashboard`,
      },
    });
    appendRecentSearchHistory(id, query, category);
    setEnableDocumentSwitch(false);
    switch (category) {
      case GlobalSearchCategories.activity_streams:
        return history.push(`${workspaceHomePath}/search/activity_streams/${id}${search}`);
      case GlobalSearchCategories.invoices:
        return history.push(`${workspaceHomePath}/search/${searchConfigRef.current.invoiceType}/${id}${search}`);
      case GlobalSearchCategories.payments:
        return history.push(`${workspaceHomePath}/search/payments/${id}${search}`);
      case GlobalSearchCategories.connections:
        return history.push(`${workspaceHomePath}/search/${searchConfigRef.current.connectionType}/active/${id}${search}&sort=last_activity_at+DESC`);
      case GlobalSearchCategories.attachments:
        return history.push(
          `${workspaceHomePath}/search/${searchConfigRef.current.connectionType}/active/${id}/documents${search}&sort=created_at+DESC&file_name=${query}`
        );
      case GlobalSearchCategories.contacts:
        return history.push(
          `${workspaceHomePath}/search/${searchConfigRef.current.connectionType}/active/${id}/contacts${search}&sort=contact_name&name=${query}`
        );
      default:
        console.error("Category selection not found");
    }
  };

  // Handler for table row click
  const onClickRowHandler = (data: any) => {
    if (data) {
      const colConfig = searchConfigRef.current["dataSelectors"][category];
      onClickResultsHandler(data?.[colConfig?.id], data?.[colConfig?.header?.key], category);
    }
  };

  /**
   * Lifecycle Hooks
   *
   */
  // Fetch Search Results on Fresh load
  React.useEffect(() => {
    fetchSearchResults(true);
  }, [getQueryFromParams, category]);

  // Maintaining the LS
  React.useEffect(() => {
    createRecentSearchHistory();
  }, []);

  return (
    <ResultsViewComponent
      gsrConfig={{
        initialLoading: loading.initialLoading,
        moreResultsLoading: loading.fetchMoreResultsLoading,
      }}
      tableConfig={{
        header: { title: searchConfigRef.current?.["displayLabels"]?.[category] ?? "Invalid Filter" },
        column: colConfigRef.current["table"]["config"]["column"][category] as Array<ColumnConfigInterface>,
        rows: searchResults,
        controllers: {
          loading: loading.initialLoading || loading.fetchMoreResultsLoading,
          hasMore: paginationData.pageCount < paginationData.totalPageCount,
          loadMore: loadMoreResults,
        },
        handlers: {
          onClickRowHandler,
        },
        highlighter: {
          enableHighlighter: true,
          matchText: getQueryFromParams ?? "",
        },
        currencyConfig: userStatus?.currency,
      }}
    />
  );
};

export default ResultsViewContainer;

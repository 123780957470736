import React from "react";
import Dialog from "../../library/Dialog/Dialog";
import DialogTitle from "../../library/DialogTitle/DialogTitle";
import DialogContent from "../../library/DialogContent/DialogContent";
import DialogActions from "../../library/DialogActions/DialogActions";
import useGlobalSearchStyle from "../Styles/useGlobalSearchStyles";
import { FlowChart, AllConnectionsGrey, AllContacts, FilesExtSimple, Invoices, Payments, EInvoiceGSIcon } from "../../library/Icons/Icons";
import GlobalSeachDialogFooter from "./GlobalSearchDialogFooter";
import GlobalSearchDialogHeader from "./GlobalSearchDialogHeader";
import { DECIMAL_VALUES, DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../../constants/NumericConstants";
import { GLOBAL_SEARCH_CONSTANTS } from "../../../constants/GlobalSearchConstants";
import { useHistory, useLocation } from "react-router-dom";
import { GlobalSearchCategories, KeyPressEventKeys, WorkspaceType } from "../../../types/enums";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";
import { globalSearchClientV2 } from "../../../db/version2Accessor";
import { AppContext } from "../../../contexts/AppContext";
import { DocumentSwitchContext } from "../../../contexts/DocumentSwitchContext";
import { globalSearchConfigGetter } from "../Config";
import Utils from "../../../utils/utils";
import GlobalSearchDialogContent from "./GlobalSearchDialogContent";
import { GlobalSearchDialogProps, ObjectInterface, RecentSearchItem, WidgetIconsMapInterface } from "../Interface/SearchResultContentInterface";
import useHandleVisibility from "../../../hooks/useHandleVisibility";
import { DisableFeatureContext, DisableFeatureProviderType } from "../../../contexts/DisableFeatureContext";
import { useQuery } from "@tanstack/react-query";
import { RefetchIntervals } from "../../../constants/CacheConfig";
import { createNewSearchHistory, getSearchHistoryProperty, onSelectRecentSearchHistory, setSearchHistory } from "../Utils/LocalStorageUtils";
import _ from "underscore";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

let previousTimeout: NodeJS.Timeout;

const GlobalSearchDialogContainer: React.FC<GlobalSearchDialogProps> = ({ openDialog, onCloseGlobalSearch, context = ""}) => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { t } = useTranslation();

  const { selectedWorkspace, workspaceHomePath } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const configRef = React.useRef(
    globalSearchConfigGetter(selectedWorkspace?.workspace_type_route?.toUpperCase() || WorkspaceType.AR?.toUpperCase())["global-search-suggestions"]
  );
  const isEInvoice = selectedWorkspace?.workspace_type_route === "e";
  const { searchDialogContainer, rootDialogContent } = useGlobalSearchStyle({ isEInvoice });
  const [selectedCategory, setSelectedCategory] = React.useState<string>(
    pathname?.split("/")?.includes("search")
      ? (getSearchHistoryProperty("filters")?.category as string) ?? GlobalSearchCategories.all
      : GlobalSearchCategories.all
  );
  const predefinedCategorySet = React.useRef<Array<string>>(configRef.current.predefinedCategorySet);
  const defaultSelectedTab = i18next.exists("displayLabels.all", { ns: "global_search" }) ? t("displayLabels.all", { ns: "global_search" }) : "All";
  
  const [selectedTab, setSelectedTab] = React.useState<string>(defaultSelectedTab);
  const [searchString, setSearchString] = React.useState<string>("");
  const [highlightQuery, setHighlightQuery] = React.useState<string>("");
  const [searchSort, setSearchSort] = React.useState<string>("relevant");
  const inputRef = React.useRef<HTMLInputElement>(null);
  const searchContainerActiveSelectorRef = React.useRef<HTMLDivElement>(null);
  const searchActiveItemSelectorRef = React.useRef<HTMLDivElement>(null);
  const searchResultsArrayIndexes = React.useRef<Array<number>>([]);
  const [searchResponseData, setSearchResponseData] = React.useState<Array<GlobalSearchResponse>>([]);
  const [activeKeyDownIndex, setActiveKeyDownIndex] = React.useState<number | null>(null);
  const [recentSearchArray, setRecentSearchArray] = React.useState<Array<RecentSearchItem>>([]);
  const { setDisableKeyboardShortcut } = React.useContext(DisableFeatureContext) as DisableFeatureProviderType;
  const [contentScrollTop, setContentScrollTop] = React.useState<number>(NUMERIC_VALUES.CONSTANT_ZERO);

  const initialVisibility = {
    loading: false,
    enableGlobalSuggestions: false,
    enableRecentViewed: false,
  };

  const { visibility, dispatch } = useHandleVisibility<typeof initialVisibility>(initialVisibility);

  // User State for Currency
  const { userStatus } = React.useContext(AppContext) as AppType;

  //Document Switcher for Activity Streams, Invoices & Payments
  const { setEnableDocumentSwitch } = React.useContext(DocumentSwitchContext) as DocumentSwitchType;

  const resultItemsIconMap: WidgetIconsMapInterface = {
    activity_streams: <FlowChart />,
    connections: <AllConnectionsGrey />,
    contacts: <AllContacts />,
    attachments: <FilesExtSimple />,
    invoices: <Invoices />,
    received_einvoices: <EInvoiceGSIcon/>,
    sent_einvoices: <EInvoiceGSIcon/>,
    bills: <Invoices />,
    payments: <Payments />,
  };

  //Local Storage Handler
  const createRecentSearchHistory = () => {
    setRecentSearchArray(createNewSearchHistory(selectedCategory, selectedWorkspace.workspace_type_route?.toUpperCase() as string));
  };

  const appendRecentSearchHistory = (id: string, query: string, einvoice_status: string, 
    category: string, app_enrollment_id: string, company_type: string,) => {

    setRecentSearchArray(
      onSelectRecentSearchHistory(
        id,
        query,
        einvoice_status,
        category,
        selectedWorkspace.workspace_type_route?.toUpperCase() as string,
        app_enrollment_id,
        company_type
      )
    );
  };

  // API Handlers
  const getCategoryFilter = (category?: string) => {
    /**
     * since bills and invoices are same, backend needs 'invoices' as
     * category for both
     * TODO: update object options to keep a backend category which can be
     * duplicated without affecting UI and can be utilized in network calls.
     **/
    const useCategory = category ?? selectedCategory;
    return useCategory?.toLocaleLowerCase() === GlobalSearchCategories.all ? undefined : selectedCategory === "bills" ? "invoices" : selectedCategory;
  };

  // Show the recent search only if there is at least one recent search data is there
  const toggleDropDown = () => {
    const recentSearchData = createNewSearchHistory(selectedCategory, selectedWorkspace.workspace_type_route?.toUpperCase() as string);
    if (recentSearchData?.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      setRecentSearchArray(recentSearchData);
    }
  };

  // Format data to add top 2 random item to  "all"  category data from total list of item
  const formatDataForAllCategory = (data: ObjectInterface) => {
    const shuffled = [...Object.values(data).flat(DEFAULT_NUMERIC_VALUES.DEFAULT_ONE)].sort(() => DECIMAL_VALUES.ZERO_POINT_FIVE - Math.random());
    data["all"] = shuffled.slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, DEFAULT_NUMERIC_VALUES.DEFAULT_TWO);
    return data;
  };

  //search_suggestion api will get invoked with mentioned interval
  const { isLoading: isSuggestionApiLoading, data: searchQuerySuggetionData } = useQuery(
    [`search_suggestions-${selectedWorkspace?.workspace_type}-${selectedWorkspace?.id}`],

    async () => {
      const result = await globalSearchClientV2.getSearchQuerySuggestionList(selectedWorkspace.id);
      const resultData = Object.create({});
      Object.entries(result.data).forEach(([ele, val]) => {
        resultData[ele] = _.uniq(val as [], "key");
        /**
         * since bills and invoices are same, backend needs 'invoices' as
         * category for both
         * TODO: update object options to keep a backend category which can be
         * duplicated without affecting UI and can be utilized in network calls.
         **/
        if (ele === "invoices") {
          resultData["bills"] = _.uniq(val as [], "key");
        }
      });
      return formatDataForAllCategory(resultData);
    },
    {
      onError: (error) => {
        console.error("error:", error);
      },
      refetchInterval: RefetchIntervals.searchSuggestionList,
    }
  );

  const getSearchResultData = async (queryString: string, callToggleDropDown = true, categoryString?: string) => {
    /**
     * For Accounting workspace: if category is invoices or bills update
     * query to have filters.
     * TODO: update object to have pre-defined filters on search tabs and
     * make it the filters generic
     **/
    const resultFilters = (function () {
      if (selectedWorkspace?.workspace_type_route === WorkspaceType.AW) {
        if (selectedCategory === "invoices") {
          return [{ invoice_type_code: ["AR Invoice", "AR Credit Memo"] }];
        } else if (selectedCategory === "bills") {
          return [{ invoice_type_code: ["AP Invoice", "AP Credit Memo"] }];
        }
      } else return [];
    })();
    const searchCategory = getCategoryFilter(categoryString);
    if (queryString?.length < GLOBAL_SEARCH_CONSTANTS.MIN_SEARCH_CHARACTERS) {
      if (callToggleDropDown) {
        toggleDropDown();
      }
      dispatch({ type: "open", payload: "enableRecentViewed" });
      dispatch({ type: "close", payload: "enableGlobalSuggestions" });

      return null;
    }
    dispatch({ type: "open", payload: "loading" });
    dispatch({ type: "open", payload: "enableGlobalSuggestions" });
    dispatch({ type: "close", payload: "enableRecentViewed" });

    let result = {} as APIResponse;
    try {
      const checkCategory = categoryString ? categoryString === GlobalSearchCategories.all : selectedCategory === GlobalSearchCategories.all;
      /** TODO: to pass filters */
      result = await globalSearchClientV2.getSearchSuggestions(
        queryString,
        searchCategory,
        selectedWorkspace.id,
        searchSort,
        checkCategory ? undefined : DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
        checkCategory ? undefined : NUMERIC_VALUES.CONSTANT_THREE,
        resultFilters
      );
    } catch (error) {
      console.error("error:", error);
      setSearchResponseData([]);
    } finally {
      if (result.success) {
        const resultData = result.data ?? [];
        setSearchResponseData(resultData);
        searchResultsArrayIndexes.current = resultData
          ?.map((data: { result: Array<object> }, index: number) => {
            return data && data?.result?.map((res: object, pos: number) => res && index * GLOBAL_SEARCH_CONSTANTS.ALL_FILTER_MAX_SHOW_RESULTS + pos);
          })
          ?.flat(DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);
        setHighlightQuery(queryString);
      }
      dispatch({ type: "close", payload: "loading" });
    }
  };

  // Tab Click Handlers
  const changeCategory = (event: React.ChangeEvent<object>, newValue: string) => {
    setSelectedCategory(configRef?.current?.tabs[newValue]);
    setSelectedTab(newValue);
  };

  //Sorting [Recent/Relevant] handler
  const handleSortDropdownChange = (event: { target: { value: string } }) => {
    const sortVal = event.target.value;
    setSearchSort(sortVal);
  };

  // Suggestions Handlers
  const onClickSuggestionsHandler = (id: string, query: string, einvoice_status: string, 
    category: string, app_enrollment_id: string, company_type: string) => {
    onCloseGlobalSearch();
    if (!id) {
      return;
    }
    appendRecentSearchHistory(id, query, einvoice_status, category, app_enrollment_id, company_type);
    setActiveKeyDownIndex(null);
    setEnableDocumentSwitch(false);
    switch (category) {
      case GlobalSearchCategories.activity_streams:
        return history.push(`${workspaceHomePath}/activities/all/${id}?query=${searchString && searchString.length ? searchString : query}`);
      case GlobalSearchCategories.invoices:
        return history.push(`${workspaceHomePath}/${configRef.current.invoiceType}/${id}`);
      case GlobalSearchCategories.received_einvoices:
          return history.push(`${workspaceHomePath}/activities/receivedEInvoices/${id}`);
      case GlobalSearchCategories.sent_einvoices:
            return history.push(`${workspaceHomePath}/activities/sentEInvoices/${id}`);
      case GlobalSearchCategories.payments:
        return history.push(`${workspaceHomePath}/payments/${id}`);
      case GlobalSearchCategories.connections: {
        const routeType = company_type?.includes("Company")
          ? "internal"
          : app_enrollment_id !== null
          ? configRef.current.connectionType
          : "connections";
        return history.push(`${workspaceHomePath}/${routeType}/active/${id}`);
      }
      case GlobalSearchCategories.attachments:
        return history.push(
          `${workspaceHomePath}/${configRef.current.connectionType}/active/${id}/documents?sort=created_at+DESC&file_name=${query}`
        );
      case GlobalSearchCategories.contacts:
        return history.push(`${workspaceHomePath}/${configRef.current.connectionType}/active/${id}/contacts?sort=contact_name&name=${query}`);
      default:
        console.error("Category selection not found");
    }
  };

  const onClickNavToAllResults = (query: string, category: string) => {
    onCloseGlobalSearch();
    setActiveKeyDownIndex(null);
    // storing search keyword to acces in result page and hasSearchedFromDialog to identify view all clicked
    setSearchHistory({
      searchDialog: {
        searchKeyword: query,
        hasSearchedFromDialog: true,
      },
    });
    history.push({
      pathname: `${workspaceHomePath}/search/${category}`,
      search: `?query=${query}&sort=${searchSort}`,
    });

    const getURLActiveView: string = pathname?.split?.("/")?.[NUMERIC_VALUES.CONSTANT_THREE];

    // Check if we search all in the search results page again
    if (getURLActiveView !== "search") {
      setSearchHistory({
        filters: {
          category: selectedCategory,
        },
        previousContext: {
          previousContextName: Utils.capitalize(getURLActiveView) ?? "Dashboard",
          previousPath: pathname ? pathname + search : `${workspaceHomePath}/dashboard`,
        },
      });
    }
    setActiveKeyDownIndex(null);
    inputRef?.current?.blur();
  };

  // Input Handlers
  const debounce = (func: (query: string) => Promise<null | undefined>, timeout = NUMERIC_VALUES.CONSTANT_FIVE_HUNDRED) => {
    return (...args: [query: string]) => {
      clearTimeout(previousTimeout);
      previousTimeout = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  };

  //handler for whatever we type in input field
  const searchInputHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const searchVal = event.target.value;
    setSearchString(searchVal);
    setActiveKeyDownIndex(null);
    debounce(getSearchResultData)(searchVal?.trim());
  };

  const onFocusHandler = () => {
    if (searchString?.length < GLOBAL_SEARCH_CONSTANTS.MIN_SEARCH_CHARACTERS) {
      dispatch({ type: "open", payload: "enableRecentViewed" });
    } else {
      dispatch({ type: "open", payload: "enableGlobalSuggestions" });
    }
    toggleDropDown();
  };

  const scrollHandler = (eventKey: string) => {
    if (searchActiveItemSelectorRef?.current && searchContainerActiveSelectorRef?.current) {
      // On Arrow Down
      if (
        eventKey === KeyPressEventKeys.arrowDown &&
        searchContainerActiveSelectorRef.current?.scrollTop + searchContainerActiveSelectorRef.current.offsetHeight <=
          searchContainerActiveSelectorRef.current?.scrollHeight
      ) {
        searchContainerActiveSelectorRef.current?.scrollTo(
          DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
          searchContainerActiveSelectorRef.current?.scrollTop + searchActiveItemSelectorRef.current.offsetHeight
        );
      }
      // On Arrow UP
      else if (eventKey === KeyPressEventKeys.arrowUp && searchContainerActiveSelectorRef.current?.scrollTop >= DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
        searchContainerActiveSelectorRef.current?.scrollTo(
          DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
          searchContainerActiveSelectorRef.current?.scrollTop - searchActiveItemSelectorRef.current.offsetHeight
        );
      }
    }
  };

  //To handle key down-up arrow event
  const onKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (visibility.enableRecentViewed) {
      // Event Key = Arrow Up
      if (event.key === KeyPressEventKeys.arrowUp) {
        if (activeKeyDownIndex !== null && activeKeyDownIndex > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
          setActiveKeyDownIndex(activeKeyDownIndex - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);
        } else {
          setActiveKeyDownIndex(null);
        }
        scrollHandler(event.key);
      }
      // Event Key = Arrow Down
      else if (event.key === KeyPressEventKeys.arrowDown) {
        if (activeKeyDownIndex !== null && activeKeyDownIndex < recentSearchArray?.length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) {
          setActiveKeyDownIndex(activeKeyDownIndex + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);
        } else if (activeKeyDownIndex === null) {
          setActiveKeyDownIndex(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
        }
        scrollHandler(event.key);
      }
      // Event Key = Enter
      else if (activeKeyDownIndex !== null && event.key === KeyPressEventKeys.enter) {
        const getRecentSearchData = recentSearchArray[activeKeyDownIndex] as RecentSearchItem;
        onClickSuggestionsHandler(
          getRecentSearchData?.id,
          getRecentSearchData?.query,
          getRecentSearchData?.einvoice_status,
          getRecentSearchData?.category,
          getRecentSearchData?.app_enrollment_id,
          getRecentSearchData?.company_type
        );
        setActiveKeyDownIndex(null);
        inputRef?.current?.blur();
      }
    } else if (visibility.enableGlobalSuggestions) {
      const resultArrayIndexes = searchResultsArrayIndexes.current;
      const currentIndexPosition = resultArrayIndexes?.indexOf(activeKeyDownIndex ?? DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE);
      // Event Key = Arrow Up
      if (event.key === KeyPressEventKeys.arrowUp) {
        if (activeKeyDownIndex !== null && currentIndexPosition > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
          setActiveKeyDownIndex(resultArrayIndexes[currentIndexPosition - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE]);
        } else {
          setActiveKeyDownIndex(null);
        }
        scrollHandler(event.key);
      }
      // Event Key = Arrow Down
      else if (event.key === KeyPressEventKeys.arrowDown) {
        if (activeKeyDownIndex !== null && currentIndexPosition < resultArrayIndexes?.length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) {
          setActiveKeyDownIndex(resultArrayIndexes[currentIndexPosition + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE]);
        } else if (activeKeyDownIndex === null) {
          setActiveKeyDownIndex(resultArrayIndexes[DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO]);
        }
        scrollHandler(event.key);
      } else if (activeKeyDownIndex !== null && event.key === KeyPressEventKeys.enter) {
        // Event Key = Enter
        const getResultByCategory =
          searchResponseData[
            selectedCategory === GlobalSearchCategories.all
              ? Math.floor(activeKeyDownIndex / GLOBAL_SEARCH_CONSTANTS.ALL_FILTER_MAX_SHOW_RESULTS)
              : DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
          ];
        const getResultCategory = getResultByCategory?.category;
        const getResultData =
          getResultByCategory?.result[
            selectedCategory === GlobalSearchCategories.all
              ? activeKeyDownIndex % GLOBAL_SEARCH_CONSTANTS.ALL_FILTER_MAX_SHOW_RESULTS
              : activeKeyDownIndex
          ];
        const getConfig = configRef.current.dataSelectors[getResultCategory];
        onClickSuggestionsHandler(
          getResultData[getConfig?.id],
          getResultData[getConfig?.header.key],
          getResultData[getConfig?.einvoice_status.key],
          getResultCategory,
          getResultData?.app_enrollment_id,
          getResultData?.company_type
        );
        setActiveKeyDownIndex(null);
        inputRef?.current?.blur();
      }
    }
  };

  //Tab key handler to navigates tabs
  const onKeyDownTab = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const currentTabIndex = Object.keys(configRef.current?.tabs)?.indexOf(selectedTab);
    if (event.key === KeyPressEventKeys.tab) {
      const nextTabIndex =
        currentTabIndex === Object.keys(configRef.current?.tabs).length - NUMERIC_VALUES.CONSTANT_ONE
          ? NUMERIC_VALUES.CONSTANT_ZERO
          : currentTabIndex + NUMERIC_VALUES.CONSTANT_ONE;

      setSelectedTab(Object.keys(configRef.current?.tabs)[nextTabIndex]);
      setSelectedCategory(configRef.current?.tabs[Object.keys(configRef.current?.tabs)[nextTabIndex]]);
    }
  };
  //Workspace Change Handler
  const changeWorkspace = () => {
    setSearchString("");
    setSearchSort("relevant");
    setSelectedTab(defaultSelectedTab);
    setSelectedCategory(GlobalSearchCategories.all);
    dispatch({ type: "open", payload: "enableRecentViewed" });
    dispatch({ type: "close", payload: "enableGlobalSuggestions" });

    createRecentSearchHistory();
    configRef.current = globalSearchConfigGetter(selectedWorkspace?.workspace_type_route?.toUpperCase() || WorkspaceType.AR?.toUpperCase())[
      "global-search-suggestions"
    ];
    predefinedCategorySet.current = configRef.current.predefinedCategorySet;
    setActiveKeyDownIndex(null);
  };

  // Filtering Recent Search Based on Filters
  React.useEffect(() => {
    createRecentSearchHistory();
    getSearchResultData(searchString, false);
    setSearchHistory({
      filters: {
        category: selectedCategory,
      },
    });
  }, [selectedCategory, searchSort]);

  // Unselect key down index on view change
  React.useEffect(() => {
    if (visibility.enableRecentViewed || visibility.enableGlobalSuggestions) {
      setActiveKeyDownIndex(null);
    }
  }, [visibility.enableRecentViewed, visibility.enableGlobalSuggestions, selectedCategory]);

  // On Workspace Change
  React.useEffect(() => {
    changeWorkspace();
  }, [selectedWorkspace?.workspace_type_route]);

  React.useEffect(() => {
    openDialog ? setDisableKeyboardShortcut(true) : setDisableKeyboardShortcut(false);

    return () => setDisableKeyboardShortcut(false);
  }, [openDialog]);

  return (
    <Dialog
      onClose={onCloseGlobalSearch}
      open={openDialog}
      className={`${searchDialogContainer} ${context}`}
      onKeyDown={onKeyDownTab}
      onScrollCapture={(e) => setContentScrollTop((e.target as HTMLElement).scrollTop)}
    >
      <DialogTitle>
        <GlobalSearchDialogHeader
          rootProps={{
            contentScrollTop,
          }}
          inputProps={{
            inputRef,
            value: searchString,
            onChangeHandlerForInput: searchInputHandler,
            onFocusHandler,
            onKeyDownHandler,
            placeholder: configRef?.current?.searchBoxPlaceholder,
          }}
          tabProps={{
            selectedTabValue: selectedTab,
            handlClickTabs: changeCategory,
            tabLabels: Object.keys(configRef?.current?.tabs),
          }}
          resultHeaderProps={{
            showResultHeader:
              !(searchResponseData.length === NUMERIC_VALUES.CONSTANT_ZERO && searchString.length >= NUMERIC_VALUES.CONSTANT_THREE) ||
              visibility.loading ||
              isSuggestionApiLoading,
            searchSortValue: searchSort,
            handleSortDropdownChange,
            isEInvoice,
          }}
        />
      </DialogTitle>

      {/* Body */}
      <DialogContent className={rootDialogContent}>
        <GlobalSearchDialogContent
          commonProps={{
            activeItemRef: searchActiveItemSelectorRef,
            activeKeyDownIndex,
            config: configRef.current,
            containerRef: searchContainerActiveSelectorRef,
            iconMap: resultItemsIconMap,
            loading: visibility.loading || isSuggestionApiLoading,
            onClickNavToAllResults,
            onClickSuggestions: onClickSuggestionsHandler,
            query: highlightQuery,
            currency: userStatus?.currency,
            selectedTab: selectedTab,
          }}
          globalSearchResultProps={{
            enableGlobalSuggestions: visibility.enableGlobalSuggestions,
            data: searchResponseData,
            isEInvoice,
          }}
          recentSearchProps={{
            enableRecentViewed: visibility.enableRecentViewed,
            recentSuggestions: recentSearchArray,
          }}
          suggestionQueryListProps={{
            searchQueryListData: searchQuerySuggetionData?.[configRef.current?.tabs[selectedTab]],
          }}
        />
      </DialogContent>

      {/* Footer */}
      {(!(searchString.length >= NUMERIC_VALUES.CONSTANT_THREE && searchResponseData.length === NUMERIC_VALUES.CONSTANT_THREE) ||
        visibility.loading ||
        isSuggestionApiLoading) && (
        <DialogActions>
          <GlobalSeachDialogFooter />
        </DialogActions>
      )}
    </Dialog>
  );
};

export default GlobalSearchDialogContainer;

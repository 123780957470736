import React from "react";
import SecondaryActivity from "../../SecondaryActivity/SecondaryActivity";
import { SecondaryType } from "../../SecondaryActivity/SecondaryActivityTypes";
import { MoveActivityProps } from "../ActivityItemVariantTypes";
import { useTranslation } from "react-i18next";

const MoveActivity: React.FC<MoveActivityProps> = ({ connectionName, creator, note, countryCode }) => {
  const { t } = useTranslation();
  const headerMessage = connectionName
    ? t("activityDetails.feedItems.moveActivity.headerMessage.movedToConnection", { connectionName, ns: "activities" })
    : t("activityDetails.feedItems.moveActivity.headerMessage.moved", { ns: "activities" });

  return (
    <SecondaryActivity variant={SecondaryType.DetailedWithAction} creator={{ ...creator, message: headerMessage }} countryCode={countryCode}>
      <p>{note ?? t("activityDetails.feedItems.moveActivity.belongingMessage", { connectionName, ns: "activities" })}</p>
    </SecondaryActivity>
  );
};

export default MoveActivity;

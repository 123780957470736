import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import useGlobalSearchStyle from "../Styles/useGlobalSearchStyles";
import { SearchSuggestion } from "../../library/Icons/Icons";
import { ObjectInterface } from "../Interface/SearchResultContentInterface";
import { GLOBAL_SEARCH_STATIC_TEXTS } from "../../../constants/GlobalSearchConstants";
import _ from "underscore";
import ContentHeadingComponent from "./ContentHeading";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const SearchSuggestions: React.FC<{ data: Array<ObjectInterface> }> = (props) => {
  const classes = useGlobalSearchStyle({});
  const { t } = useTranslation();
  return (
    <>
      {!_.isUndefined(props.data) && (
        <ContentHeadingComponent
          title={
            i18next.exists("globalSearchSuggestions.searchSuggestions", { ns: "global_search" })
              ? t("globalSearchSuggestions.searchSuggestions", { ns: "global_search" })
              : GLOBAL_SEARCH_STATIC_TEXTS.SEARCH_SUGGESTIONS
          }
          isViewResultRequired={false}
        />
      )}
      <List className={classes.searchListContainer}>
        {props.data?.map((item, i) => (
          <div key={`${item}-${i}`}>
            <ListItem className={classes.listItem}>
              <ListItemAvatar>
                <Avatar className={classes.listItemAvatar}>
                  <SearchSuggestion />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                disableTypography={true}
                primary={
                  <div className={classes.suggestionPrimaryConatinar}>
                    Try
                    <span className={classes.suggestionQueryFieldText}>{item?.key}:</span>
                    {item?.display_text}
                  </div>
                }
              />
            </ListItem>
            <Divider />
          </div>
        ))}
      </List>
    </>
  );
};

export default SearchSuggestions;

import React, { useMemo } from "react";

export type CustomerDetailsDashboardData = {
  viewBasedFilters: string;
  setViewBasedFilters: React.Dispatch<React.SetStateAction<string>>;
  initialDropdownSuggestionRef: any;
};

export const CustomerDetailsDashboardContext = React.createContext<CustomerDetailsDashboardData | null>(null);

export interface CustomerDetailsProviderProps {
  children: (React.ReactNode & { type: string })[] | (React.ReactNode & { type: string });
}

export const CustomerDetailsDashboardProvider: React.FC<CustomerDetailsProviderProps> = ({ children }) => {
  const [viewBasedFilters, setViewBasedFilters] = React.useState<string>("");
  const initialDropdownSuggestionRef = React.useRef<FilterDropdownSuggestionRef>({});
  const contextValue = useMemo(
    () => ({
      viewBasedFilters,
      setViewBasedFilters,
      initialDropdownSuggestionRef,
    }),
    [viewBasedFilters]
  );
  return <CustomerDetailsDashboardContext.Provider value={contextValue}>{children}</CustomerDetailsDashboardContext.Provider>;
};

export default CustomerDetailsDashboardProvider;

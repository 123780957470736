import React from "react";
import { CONTACT_POPOVER_EMPTY_ITEM } from "../../../constants/config";
import { Copy as CopySVG, NewEmail, NewPhone } from "../Icons/Icons";
import "./ContactPopoverCard.scss";
import { Typography } from "@mui/material";
import _ from "underscore";
import { DEFAULT_NUMERIC_VALUES } from "../AtomicComponents/constants/numeric.constants";
import usePhoneNumberUtilities from "../../../hooks/usePhoneNumberUtilities";

type ContactPopoverCardProps = {
  selectedContact: ContactItem;
  onCopyToClipBoard: (string?: string, type?: string) => void;
};

const ContactPopoverCard: React.FC<ContactPopoverCardProps> = ({ selectedContact, onCopyToClipBoard }) => {
  const { phoneUtil, PNF, locale } = usePhoneNumberUtilities();

  /**
   * Funtion to format the Phone number as per user locale
   * @returns a formmated string of Phone Number or Empty State in International format
   */
  const getFormattedPhoneNumber = (): string => {
    return _.isEmpty(selectedContact?.phone)
      ? CONTACT_POPOVER_EMPTY_ITEM
      : phoneUtil.format(phoneUtil.parse(selectedContact?.phone ?? "", locale), PNF.INTERNATIONAL);
  };
  /**
   * Funtion to derive the initials to be displayed in avatar
   * @returns the appropriate initials (2) acoording o name/email availability
   */
  const getAvatarInitials = (): string => {
    let avatarInitials = "";
    const splitArray = selectedContact?.name?.split(" ") ?? [];
    if (_.isEmpty(selectedContact?.name)) {
      avatarInitials =
        selectedContact?.email_address
          ?.match(/(^[A-Za-z]{2}?)/g)
          ?.join("")
          .toUpperCase() ?? "";
    } else if (splitArray?.length <= DEFAULT_NUMERIC_VALUES.ONE) {
      avatarInitials =
        selectedContact?.name
          ?.match(/(^[A-Za-z]{2}?)/g)
          ?.join("")
          .toUpperCase() ?? "";
    } else {
      avatarInitials =
        selectedContact?.name
          ?.match(/(\b\S)?/g)
          ?.join("")
          ?.match(/(^\S|\S$)?/g)
          ?.join("")
          .toUpperCase() ?? "";
    }
    return avatarInitials;
  };

  return (
    <div
      className="contact-card-container"
      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
      }}
    >
      <div
        className="contact-profile-row"
        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
          event.stopPropagation();
        }}
      >
        <div className="contact-avatar-container">{getAvatarInitials()}</div>
        <div className="contact-profile-container">
          <span className="contact-name">
            <Typography variant="body1Med">{selectedContact?.name ?? CONTACT_POPOVER_EMPTY_ITEM}</Typography>
          </span>
          <span className="contact-profile-email">
            <Typography variant="body1Reg">{selectedContact?.email_address ?? CONTACT_POPOVER_EMPTY_ITEM}</Typography>
          </span>
          {(selectedContact?.role_code || selectedContact?.title) && selectedContact.type !== "Workspace" && (
            <span className="contact-role">
              <Typography variant="captionMed">
                {selectedContact?.role_code ? selectedContact?.role_code : selectedContact?.title ?? CONTACT_POPOVER_EMPTY_ITEM}
              </Typography>
            </span>
          )}
        </div>
      </div>
      <div className={"contact-contacts-row hide-border"}>
        <div
          className={`contact-phone ${selectedContact?.phone ? "show-hover" : ""}`}
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            event.stopPropagation();
            selectedContact?.phone && onCopyToClipBoard(selectedContact?.phone, "phone");
          }}
        >
          <NewPhone />
          <span>{getFormattedPhoneNumber()}</span>
          {selectedContact?.phone && (
            <div>
              <CopySVG
                onClick={(event: React.MouseEvent<SVGSVGElement>) => {
                  event.stopPropagation();
                  onCopyToClipBoard(selectedContact?.phone, "phone");
                }}
              />
            </div>
          )}
        </div>
        <div
          className={`contact-email ${selectedContact?.email_address ? "show-hover" : ""}`}
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            event.stopPropagation();
            onCopyToClipBoard(selectedContact?.email_address, "email");
          }}
        >
          <NewEmail />
          <span>{selectedContact?.email_address ?? CONTACT_POPOVER_EMPTY_ITEM}</span>
          {selectedContact?.email_address && (
            <div>
              <CopySVG
                onClick={(event: React.MouseEvent<SVGSVGElement>) => {
                  event.stopPropagation();
                  onCopyToClipBoard(selectedContact?.email_address, "email");
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactPopoverCard;

/*eslint-disable*/
import React from "react";
import { styled } from "@mui/system";
import { pxToRem } from "../../../theme/theme";
import { Menu, MenuItem, Tooltip } from "@mui/material";

export const TableCaptionContainer = styled("div", {
  name: "table-caption",
  slot: "root",
})({
  padding: `${pxToRem(7)} ${pxToRem(24)}`,
  background: "#F7F7FB",
  borderRadius: `${pxToRem(8)} ${pxToRem(8)} ${pxToRem(0)} ${pxToRem(0)}`,
  border: `${pxToRem(1)} solid #DEE1ED`,
  borderBottom: "none",
  flex: "0 0 auto",
  color: "#4D4D4D",
  transition: "all 100ms linear",
  height: pxToRem(64),
  "& .table-caption-wrapper": {
    height: `${pxToRem(48)}`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& a": {
      textDecoration: "none",
    },
    "& .user-actions": {
      display: "flex",
      alignItems: "center",
      "& button": {
        marginLeft: pxToRem(11),
      },
    },
  },
});

export const MenuItemContainer = styled(MenuItem, { name: "menu-item" })({
  "&&": {
    minHeight: "2.5rem",
    color: "#143b52",
    background: "#FFFFFF",

    "&:hover": {
      background: "#f2f3f8",
      fontWeight: 500,
    },
    "&:active": {
      background: "$grey93",
    },
    // TODO: Discuss with Gibby the color of a selected dropdown menu
    "&.Mui-selected": {
      fontWeight: 500,
    },
  },
});

export const MenuContainer = styled(Menu, { name: "menu" })({
  "&&": {
    ".MuiPaper-root": {
      boxShadow: "0 0.5rem 0.75rem rgb(0 0 0 / 10%), 0 0.25rem 1.125rem rgb(0 0 0 / 10%)",
      ul: {
        padding: 0,
      },
    },
  },
});

import React from "react";
import { TableDataContext, TableDataProviderType } from "../../contexts/TableDataContext";
import { HeaderIconButton, TableHoverButton } from "../Body/button.styles";
import TableHoverbarContainer from "./hoverbar.styles";
import { MenuContainer, MenuItemContainer } from "../Caption/caption.styles";
import { Typography } from "@mui/material";
import { Ellipses } from "../../../../Icons/Icons";
import { NUMERIC_VALUES } from "../../../constants/numeric.constants";
import CustomTooltip from "../../../CustomTooltip/CustomTooltip";
import { useTranslation } from "react-i18next";

const Hoverbar: React.FC<{ row: any }> = ({ row }) => {
  const { userActions, actionConfigs: actionConfig, tableConfigs } = React.useContext(TableDataContext) as TableDataProviderType;
  const [actionTooltipVisible, setActionTooltipVisible] = React.useState<boolean>(false);
  const [paginationMenuAnchorEl, setPaginationMenuAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  if (tableConfigs.type === "Contact" && row.isPrimary) {
    return null;
  }
  const getReadUnreadTitle = (hasRead: boolean) =>
    hasRead ? t("hoverBar.markAsUnread", { ns: "table" }) : t("hoverBar.markAsRead", { ns: "table" });

  return (
    <TableHoverbarContainer>
      <div className="user-actions">
        {userActions &&
          userActions.map((action) => {
            if (tableConfigs.type === "Contact") {
              if ((action?.type === "edit" && row?.appEnrollmentId) || (action?.type === "makePrimary" && row?.isPrimary)) {
                return null;
              }
              return (
                action.visible.onHover &&
                !row.isPrimary && (
                  <CustomTooltip title={action?.tooltipLabel ?? ""} placement="top-start">
                    <TableHoverButton
                      className="table-hover-action"
                      disableFocusRipple={true}
                      disableRipple={true}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (action?.type === "makePrimary") {
                          return action.callback({
                            contactId: row.id,
                            contactName: row.contact_name,
                            contactRole: row.role_code,
                            contactEmail: row.email_address,
                            contactPhone: row.phoneNumber,
                            contactIsPrimary: row.isPrimary,
                          } as ContactDataType);
                        }
                        action.callback(actionConfig.actionHoverId ?? []);
                      }}
                    >
                      {action.icon}
                    </TableHoverButton>
                  </CustomTooltip>
                )
              );
            }
            return (
              action.visible.onHover && (
                <CustomTooltip
                  title={(action?.type as string) === "read-unread-toggle" ? getReadUnreadTitle(row?.read) : action?.tooltipLabel ?? ""}
                  placement="top-start"
                >
                  <TableHoverButton
                    className="table-hover-action"
                    disableFocusRipple={true}
                    disableRipple={true}
                    onClick={(e) => {
                      e.stopPropagation();
                      if ((action?.type as string) == "read-unread-toggle" || (action?.type as string) == "assign-to") {
                        action.callback(actionConfig.actionHoverId ?? [], row);
                      } else {
                        action.callback(actionConfig.actionHoverId ?? []);
                      }
                    }}
                  >
                    {action.icon}
                  </TableHoverButton>
                </CustomTooltip>
              )
            );
          })}
        {userActions.filter((action) => action.visible.onEllipsis).length > NUMERIC_VALUES.CONSTANT_ZERO && (
          <>
            <MenuContainer
              open={actionTooltipVisible}
              onClose={() => setActionTooltipVisible(false)}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              anchorEl={paginationMenuAnchorEl}
            >
              {userActions
                .filter((action) => action.visible.onEllipsis)
                .map((item, index) => {
                  return (
                    <MenuItemContainer
                      key={index}
                      onClick={(e) => {
                        e.stopPropagation();
                        item.callback(actionConfig.actionHoverId);
                      }}
                      id={"x"}
                    >
                      <Typography variant="body1Reg">{item.tooltipLabel}</Typography>
                    </MenuItemContainer>
                  );
                })}
            </MenuContainer>

            <HeaderIconButton
              disabled={false}
              onClick={(e: any) => {
                e.stopPropagation();
                setPaginationMenuAnchorEl(e.currentTarget);
                setActionTooltipVisible((prevVal) => !prevVal);
              }}
            >
              <Ellipses />
            </HeaderIconButton>
          </>
        )}
      </div>
    </TableHoverbarContainer>
  );
};

export default Hoverbar;

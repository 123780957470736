import React, { ChangeEvent, FormEvent } from "react";
import { useHistory, useParams } from "react-router";
import _ from "underscore";
import { ApprovalActivityAccessor } from "../../../db/unauthAccessors";
import Utils from "../../../utils/utils";
import { Success } from "../../library/Icons/Icons";
import Loading from "../../library/Loading/Loading";
import { RequestDetailsType } from "../ApprovalsTypes";
import { RedirectFormData } from "./RedirectRequestTypes";
import Status from "../Status/Status";
import "./RedirectRequest.scss";
import RedirectRequestComponent from "./RedirectRequestComponent";
import { MagicAuthContext, MagicAuthContextType } from "../../../contexts/external/MagicAuthContext";
import { ApprovalDetailsContext, ApprovalsProviderType } from "../../../contexts/external/ApprovalDetailsContext";
import { AxiosError } from "axios";
import { CONNECTION_STATUS } from "../../../constants/ConnectionConstants";

const RedirectRequestContainer = () => {
  const [formData, setFormData] = React.useState<RedirectFormData>({ email_address: "", name: "", note: "" });
  const [formError, setFormError] = React.useState<Partial<RedirectFormData>>({ email_address: "", name: "" });
  const [redirectStatus, setRedirectStatus] = React.useState<boolean>();
  const [validating, setValidating] = React.useState<boolean>(false);
  const [hasSubmitted, setIsSubmitted] = React.useState<boolean>(false);

  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { getToken } = React.useContext(MagicAuthContext) as MagicAuthContextType;
  const { fetchRequestData } = React.useContext(ApprovalDetailsContext) as ApprovalsProviderType;

  /** NETWORK */
  const postRedirectRequest = async (id: UUID, data: RedirectFormData) => {
    let response = {} as APIResponse;
    setIsSubmitted(true);
    try {
      response = await ApprovalActivityAccessor.redirectApprovalRequest(id, data);
    } catch (error) {
      response.success = false;
      history.push("/error");
    } finally {
      setRedirectStatus(response.success);
      setIsSubmitted(false);
    }
  };

  const fetchRequest = async () => {
    let res = {} as RequestDetailsType;
    try {
      res = await fetchRequestData(id);
    } catch (error) {
      if ((error as AxiosError).response?.status === CONNECTION_STATUS.LINK_EXPIRED_422.STATUS_CODE) {
        history.push("/link-expired", {
          from: "redirect-request",
          error: (error as AxiosError).response?.data.messages.errors_meta.magic_link[0] ?? "reviewed",
          info: {
            has_active_approval: (error as AxiosError).response?.data.messages.errors_meta.has_active_approval[0] ?? false,
            status: (error as AxiosError).response?.data.messages.errors_meta.status[0],
            approval_request_id: id,
            expired_duration: (error as AxiosError).response?.data.messages.errors_meta.expiry_duration,
          },
        });
      } else {
        history.push("/error");
      }
    } finally {
      // display link expiry page if request is already reviewed or redirected
      if (["approved", "declined", "redirected"].includes(res.status)) {
        history.push("/link-expired", { from: "redirect-request", error: "reviewed" });
      } else if (res.status === "cancelled") {
        history.push("/link-expired", { from: "redirect-request", error: "cancelled" });
      }
    }
  };

  const validateFormData = () => {
    let errors = {} as Partial<RedirectFormData>;

    Object.keys(formData).forEach((key) => {
      if (key === "email_address") {
        _.isEmpty(formData.email_address)
          ? (errors = { ...errors, email_address: "NO EMAIL GIVEN" })
          : !Utils.validEmail(formData.email_address) && (errors = { ...errors, email_address: "INVALID EMAIL GIVEN" });
      }
      if (key === "name") {
        _.isEmpty(formData.name) && (errors = { ...errors, name: "NO NAME GIVEN" });
      }
      if (key === "note") {
        _.isEmpty(formData.note) && (errors = { ...errors, note: "NO NOTE GIVEN" });
      }
    });

    setFormError(errors);
    // valid if no errors are present
    return _.isEmpty(errors);
  };

  /** EVENT HANDLERS */
  const onSubmitForm = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    // make api call to submit redirect request.
    postRedirectRequest(id, formData);
  };

  const onChangeFormData = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    validateFormData();
  };

  /**
   * Initial call for token to verify the validity of redirect magic link id.
   * any failure of this request will redirect the user to /link-expired view.
   *
   * Redirect link expires after the defined time-period in the database OR
   * when user has already redirected OR reviewed (approved/declined) the
   * respective approval request.
   */
  React.useEffect(() => {
    (async function validateRedirectLink() {
      try {
        setValidating(true);
        await getToken(id, "redirect-request");
        await fetchRequest();
      } finally {
        setValidating(false);
      }
    })();
  }, []);

  return validating ? (
    <div className="redirect-form-loader">
      <Loading isRelative={true} />
    </div>
  ) : redirectStatus === true ? (
    <Status size="lg" logo={<Success />} statusMessage={`Approval request has been redirected to ${formData.name}`} />
  ) : (
    <RedirectRequestComponent
      onSubmitForm={onSubmitForm}
      onChangeFormData={onChangeFormData}
      onBlurValidateData={validateFormData}
      formErrors={formError}
      hasSubmitted={hasSubmitted}
    />
  );
};

export default RedirectRequestContainer;

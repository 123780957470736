import React from "react";
import { ReactComponent as PDFAttachment } from "../../svg/chip_pdf_attachment.svg";
import { ReactComponent as DOCAttachment } from "../../svg/chip_doc_attachment.svg";
import { ReactComponent as CSVAttachment } from "../../svg/chip_csv_attachment.svg";
import { ReactComponent as XLSSAttachment } from "../../svg/chip_xlss_attachment.svg";
import { ReactComponent as OtherAttachment } from "../../svg/chip_other_attachment.svg";
import { ReactComponent as DownloadCloud } from "../../svg/chip_download_cloud.svg";
import { ReactComponent as Close } from "../../svg/cross_icon.svg";
import { ReactComponent as Paperclip } from "../../svg/paperclip.svg";
import { ReactComponent as Eye } from "../../svg/eye.svg";
import CustomTooltip from "../../CustomTooltip/CustomTooltip";
import { TooltipTypes } from "../../constants/tooltip.constants";
import { AttachmentChipPropTypes } from "./types";
import {
  AttachmentChipRoot,
  AttachmentFileDetails,
  AttachmentFileName,
  AttachmentFileSize,
  AttachmentFileDownload,
  AttachmentFileDisposeContainer,
} from "../styled/styled";
import { EINVOICE_ATTACHMENT_TYPES } from "../../../../../types/enums";

export default function AttachmentChip({
  name,
  extension,
  createdDate,
  attachment,
  onClickAttachmentChip,
  onClickDownload,
  clossable,
  onClose,
  origin = null,
}: AttachmentChipPropTypes): React.ReactElement {
  const getAttachmentIcon = (extension: string) => {
    switch (extension?.toLowerCase()) {
      case "pdf":
        return <PDFAttachment />;
      case "doc":
        return <DOCAttachment />;
      case "csv":
        return <CSVAttachment />;
      case "xlss":
        return <XLSSAttachment />;
      default:
        return <OtherAttachment />;
    }
  };

  const QuickActions = () => {
    if (
      origin &&
      origin === "e-invoice" &&
      (attachment as AttachmentItem & EInvoiceAttachmentItem)?.attachment_type !== EINVOICE_ATTACHMENT_TYPES.DEMATERIALISED
    ) {
      return <Eye />;
    } else {
      return (
        <>
          {!clossable && (
            <AttachmentFileDownload onClick={(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => onClickDownload(event, attachment)}>
              <DownloadCloud />
            </AttachmentFileDownload>
          )}
        </>
      );
    }
  };

  return (
    <CustomTooltip type={TooltipTypes.PLAIN} title={name}>
      <AttachmentChipRoot
        onClick={onClickAttachmentChip}
        className={`${origin} ${
          (attachment as AttachmentItem & EInvoiceAttachmentItem)?.attachment_type !== EINVOICE_ATTACHMENT_TYPES.DEMATERIALISED ? "previewable" : ""
        }`}
      >
        {origin && origin === "e-invoice" ? <Paperclip /> : getAttachmentIcon(extension)}
        <AttachmentFileDetails>
          <AttachmentFileName>{name}</AttachmentFileName>
          <AttachmentFileSize>{createdDate}</AttachmentFileSize>
        </AttachmentFileDetails>
        <QuickActions />
        {clossable && (
          <AttachmentFileDisposeContainer
            onClick={(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
              event.stopPropagation();
              onClose?.(event, attachment);
            }}
          >
            <Close />
          </AttachmentFileDisposeContainer>
        )}
      </AttachmentChipRoot>
    </CustomTooltip>
  );
}

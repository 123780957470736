import React, { MouseEvent, useEffect, useMemo, useState } from "react";
import { Divider } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { AppContext } from "../../../contexts/AppContext";
import { Search } from "../../library/Icons/Icons";
import Menu from "../../library/Menu/Menu";
import MenuItem from "../../library/MenuItem/MenuItem";
import Userpic from "../../library/Sidebar/Userpic/Userpic";
import "./BodyContentHead.scss";
import { ProfileContext } from "../../../contexts/ProfileContext";
import SettingsPopUp from "../../Settings/SettingsPopUp/SettingsPopUp";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";
import LockstepBar from "../LockstepBar/LockstepBar";
import { GlobalSearchWidget } from "../../GlobalSearch";
import { TemplateContext } from "../../../contexts/TemplateContext";
import GlobalSearchDialogContainer from "../../AdvanceGlobalSearch/GlobalSearchDialog/GlobalSearchDialogContainer";
import { FeatureFlagContext, FeatureFlagProviderType } from "../../../contexts/FeatureFlagContext";
import { ActivityGlobalSearchShortcuts, KeyboardActionsConstants } from "../../../constants/KeyboardShortcutConstants";
import { TooltipTitles, TooltipTypes } from "../../../constants/TooltipConstants";
import useKeyboardShortcut from "../../../hooks/useKeyboardShortcut";
import CustomTooltip from "../../library/CustomTooltip/CustomTooltip";
import BreadcrumbsComponent from "../../library/Breadcrumbs/Breadcrumbs";
import LastSync from "../LastSync/LastSync";
import useContacts from "../../../hooks/useContacts";
import { useTranslation } from "react-i18next";

/**
 * * Defines the Head of the Main Content being displayed to the user for a given Route
 */
export default function BodyContentHead(): React.ReactElement {
  const { selectedWorkspace, setWorkspaceData, userGroupChanged, workspaceData, processWorkspaceData } = React.useContext(
    WorkspaceContext
  ) as WorkspaceDataType;
  const { logout, userStatus } = React.useContext(AppContext) as AppType;
  const { getContactsOptions } = useContacts();
  const { imageUrl } = React.useContext(ProfileContext) as ProfileType;
  const { createTemplateMapsForWorkspaces } = React.useContext(TemplateContext) as ITemplateProps;
  const [isOpenGloablSearch, setIsOpenGlobalSearch] = React.useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const menuIsOpen = Boolean(menuAnchorEl);
  const history = useHistory();
  const { shutdown } = useIntercom();
  const { isEnabled, featureFlagsLoading } = React.useContext(FeatureFlagContext) as FeatureFlagProviderType;
  const searchDivRef = React.useRef<HTMLDivElement>(null);
  const isGloabalSearchV2Enable = isEnabled("GLOBAL_SEARCH_V2");
  const isKeyboardShortcutFlagEnabled = isEnabled("KEYBOARD_SHORTCUTS");
  const { actionToPerform, setActionDone } = useKeyboardShortcut(ActivityGlobalSearchShortcuts, !isKeyboardShortcutFlagEnabled);
  const { t } = useTranslation();

  useEffect(() => {
    getContactsOptions();
  }, [selectedWorkspace?.name]);

  /**
   * Once a user group change is registered on platform, this effect will update workspace
   * configuration as per the new user group and redirect to the default workspace of that group.
   */
  useEffect(() => {
    if (userStatus.workspaces && userGroupChanged) {
      setWorkspaceData(processWorkspaceData(userStatus?.workspaces));
    }
  }, [userStatus?.workspaces, userGroupChanged]);

  /**
   * Whenever multiAccount switch is done load the templates for the selected company
   */
  useEffect(() => {
    createTemplateMapsForWorkspaces();
  }, [workspaceData]);

  const onGlobalSearchClose = () => setIsOpenGlobalSearch(false);

  const keyboardActions: { [key: string]: any } = useMemo(
    () => ({
      [KeyboardActionsConstants.focus_search]: () => searchDivRef?.current?.click(),
    }),
    [searchDivRef.current]
  );

  React.useEffect(() => {
    if (
      isKeyboardShortcutFlagEnabled &&
      actionToPerform &&
      keyboardActions[actionToPerform] &&
      typeof keyboardActions[actionToPerform] === "function"
    ) {
      keyboardActions[actionToPerform]();
      setActionDone();
    }
  }, [actionToPerform, isKeyboardShortcutFlagEnabled]);

  const shortcutDisplayMapper: { [key: string]: string } = useMemo(() => {
    if (isKeyboardShortcutFlagEnabled) {
      const mapper: { [key: string]: string } = {};
      ActivityGlobalSearchShortcuts.forEach((item) => {
        if (item.identifier) {
          mapper[item.identifier] = item.displayText;
        }
      });
      return mapper;
    }

    return {};
  }, [isKeyboardShortcutFlagEnabled]);

  return (
    <>
      <BreadcrumbsComponent />
      <div className="accountInfo-wrapper">{!(isGloabalSearchV2Enable || featureFlagsLoading) && <GlobalSearchWidget />}</div>

      <div className="userInfo-wrapper">
        {isGloabalSearchV2Enable && !featureFlagsLoading && (
          <>
            {isOpenGloablSearch && <GlobalSearchDialogContainer openDialog={isOpenGloablSearch} onCloseGlobalSearch={onGlobalSearchClose} />}
            <CustomTooltip
              type={TooltipTypes.SHORTCUT}
              title={isKeyboardShortcutFlagEnabled ? (t("header.tooltipMessages.globalSearch", { ns: "home" }) as string) : ""}
              shortcutKeysText={isKeyboardShortcutFlagEnabled ? shortcutDisplayMapper?.[TooltipTitles.GO_TO_SEARCH] : ""}
              placement="bottom-end"
              disableFocusListener
            >
              <div className="global-search-icon" onClick={() => setIsOpenGlobalSearch((prevState: boolean) => !prevState)} ref={searchDivRef}>
                <Search />
              </div>
            </CustomTooltip>
            <Divider orientation="vertical" className="header-divider" />
          </>
        )}
        <LastSync />
        <SettingsPopUp tooltipPlacement="bottom-end" />
        <LockstepBar />
        <Userpic id="logout-btn" onClick={(e: MouseEvent<HTMLButtonElement>) => setMenuAnchorEl(e.currentTarget)} imageUrl={imageUrl ?? ""} />
        <Menu
          open={menuIsOpen}
          anchorEl={menuAnchorEl}
          onClose={() => setMenuAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <MenuItem
            onClick={() => {
              history.push(`/profiles`);
              setMenuAnchorEl(null);
            }}
          >
            {t("header.tooltipMessages.inboxTabs.myProfile", { ns: "home" })}
          </MenuItem>
          <MenuItem
            onClick={() => {
              shutdown();
              logout();
            }}
          >
            {t("header.tooltipMessages.inboxTabs.signOut", { ns: "home" })}
          </MenuItem>
        </Menu>
      </div>
    </>
  );
}

import React, { useState, MouseEvent, useEffect } from "react";
import { eInvoicesClient } from "../../../db/version3Accessor";
import { exportClient } from "../../../db/version2Accessor";
import { useHistory, useRouteMatch, useParams } from "react-router-dom";
import { AlertContext } from "../../../contexts/AlertContext";
import { ActivityStatus, WorkspaceType, EINVOICE_ATTACHMENT_TYPES } from "../../../types/enums";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES, DEFAULT_PAGINATION_VALUES } from "../../../constants/NumericConstants";
import { ActivityContext } from "../../../contexts/ActivityContext";
import { DocumentSwitchContext } from "../../../contexts/DocumentSwitchContext";
import { ApplicationRouteContext } from "../../../contexts/ApplicationRouteContext";
import { Table, useColumnFilters } from "../../library/AtomicComponents/Table/index";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { RefetchIntervals } from "../../../constants/CacheConfig";
import usePagination from "../../library/AtomicComponents/Table/hooks/usePagination";
import useColumnSort from "../../library/AtomicComponents/Table/hooks/useColumnSort";
import useRowSelection from "../../library/AtomicComponents/Table/hooks/useRowSelection";
import useLocale from "../../../hooks/useLocale";
import { useTranslation } from "react-i18next";
import useWorkspaceConfigurations from "../../../hooks/useWorkspaceConfigurations";
import TableUtils from "../../../utils/TableUtils/TableUtils";
import { AppContext } from "../../../contexts/AppContext";
import { UserAction } from "../../library/AtomicComponents/Table/types/table.types";
import { ExportContext } from "../../../contexts/ExportContext";
import { EInvoiceExportModalStates } from "../../../constants/ExportConstant";
import ExportProgressModal from "./ExportModals/ExportProgressModal";
import { AttachmentPreview } from "../../library/AtomicComponents/AttachmentPreview";
import { documentsClient } from "../../../db/accessor";
import DocumentStateActionsRenderer from "../EInvoiceDetail/EInvoiceFeed/EInvoiceFeedItem/PrimaryActivity/DocumentStateActionsRenderer/DocumentStateActionsRenderer";
import { IDocumentState } from "../EInvoiceDetail/EInvoiceFeed/EInvoiceFeedItem/PrimaryActivity/DocumentStateActionsRenderer/DocumentStateActionsRenderer";

import "./EInvoices.scss";

type TableProps = {
  columns: any[];
  predefinedFilter?: { route: string; searchlightFilter: string }[];
  assigneeType?: string;
  route?: string;
  fromCustomers?: boolean;
  category?: string;
};

type EInvoiceRecord = {
  conversation_type?: "einvoice";
  created_at?: number;
  updated_at?: number;
  last_activity_at?: number;
  invoice_id?: string;
  reference_code?: string;
  payment_due_date?: number;
  aging?: string;
  base_currency_total_amount?: number;
  base_currency_sales_tax_amount?: number;
  einvoice_status?: string;
  company_name?: string;
  purchase_order_code?: string;
  pre_tax_transaction_amount?: number;
  workflow_status_id?: string;
  invoice_type_code?: IDocumentState["invoiceTypeCode"];
};

export default function EInvoicesTable(props: TableProps): React.ReactElement {
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const { selectedWorkspace, workspaceHomePath } = useWorkspaceConfigurations();
  const { userStatus } = React.useContext(AppContext) as AppType;
  const { setActivityStatus, setUserView, setAllRowsSelected } = React.useContext(ActivityContext) as ActivityType;
  const { updateExportedRecordsIds, setOverrideExportBehaviour, exportData, triggerDownload, setIsPollingEnabled, flushExportData } =
    React.useContext(ExportContext) as ExportType;
  const params = new URLSearchParams(window.location.search);
  const { path } = useRouteMatch();
  const history = useHistory();
  const { customerId } = useParams<{ customerId: string }>();
  const {
    setSelectedActivityStreamId,
    setActivityStreamIds,
    setCurrentPaginationState,
    setDocumentType,
    setEnableDocumentSwitch,
    setRootSwitcherStateSnapshot,
  } = React.useContext(DocumentSwitchContext) as DocumentSwitchType;
  const { getBaseRoute } = React.useContext(ApplicationRouteContext) as ApplicationRouteType;
  const { url } = useRouteMatch();
  const [exportLoading, setExportLoading] = useState<boolean>(false);

  // bind table states & configs
  const pageNoFromUrl = parseInt(params.get("page") ?? "1", NUMERIC_VALUES.CONSTANT_TEN) || NUMERIC_VALUES.CONSTANT_ONE;
  const { setTotalRecords, setTotalPages, updatePageNumber, ...paginationConfig } = usePagination(
    DEFAULT_PAGINATION_VALUES.PAGE_SIZE,
    pageNoFromUrl ? pageNoFromUrl - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE : DEFAULT_PAGINATION_VALUES.PAGE_COUNT
  );
  const { query: sortQuery, ...sortConfig } = useColumnSort("payment_due_date desc");
  const selectionConfig = useRowSelection(paginationConfig?.page?.totalRecords);
  const { ...initialFilterConfigs } = useColumnFilters(true, [], updatePageNumber);
  const { calendarDateFormat } = useLocale();
  const [columnsConfigData, setColumnsConfigData] = useState<any[]>(() => props.columns);
  const [isColumnsConfigDataLoading, setIsColumnsConfigDataLoading] = useState<boolean>(true);
  const [isNoSearchResultFound, setIsNoSearchResultFound] = useState<boolean>(false);
  const [deselectedRowsFilter, setDeselectedRowsFilter] = useState<string>("");
  const selectedRowsCount = selectionConfig?.getSelectedRowsCount();
  const filterObjFromUrl = JSON.parse(params.get("filters") ?? "{}");
  const appliedFilters = initialFilterConfigs.generateFilterQuery(Object.values(filterObjFromUrl), true);
  const { t } = useTranslation();
  const eInvoiceType = path.includes("receivedEInvoices") ? "receivedEInvoice" : "sentEInvoice";
  const [showExportModal, setShowExportModal] = useState<boolean>(false);
  const [exportState, setExportState] = useState<string>("");
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const [activeAttachmentID, setActiveAttachmentID] = useState<string | number>("");
  const [enhancedAttachemnts, setEnhancedAttachemnts] = useState<any[]>([]);
  const [isAttachmentsLoading, setIsAttachmentsLoading] = React.useState<boolean>(true);
  const [eInvoiceId, setEInvoiceId] = useState<string>("");
  const [eInvoiceWorkflowStatus, setEInvoiceWorkflowStatus] = useState<IDocumentState>({} as IDocumentState);

  const setViewAndStatus = (url: string) => {
    //it return status for the activities , but for connection activities returns connection id
    const status = url.slice(url.lastIndexOf("/") + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);

    setUserView(props?.assigneeType ?? "me");
    const connectionKeywords = /(customers|vendors|connections|internal)/;

    if (status === props?.assigneeType || status === "mine") {
      setActivityStatus("active");
    } else if (status === "waitingForResponse") {
      setActivityStatus("waiting_for_response");
    } else if (connectionKeywords.test(url)) {
      //for connections activities set the assignee type as all and pass primary connection id as ransac
      setActivityStatus("all");
    } else {
      setActivityStatus(status);
    }
  };

  const getSearchStatus = () => {
    const statusArray = Object.keys(ActivityStatus).map((e) => Object(ActivityStatus)[e]?.toLocaleLowerCase?.());
    let searchStatus = url.slice(url.lastIndexOf("/") + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);
    if (searchStatus === "waitingForResponse") {
      searchStatus = "waiting_for_response";
    } else if (!statusArray.includes(searchStatus)) {
      searchStatus = "active";
    } else if (props?.assigneeType === "spam") {
      searchStatus = "";
    }
    searchStatus =
      url.includes("customers") || url.includes("vendors") || url.includes("connections") || url.includes("internal") ? "" : searchStatus;
    return searchStatus;
  };

  function getFilterFromURL(): SearchFilter[] {
    const ret: SearchFilter[] = [];
    const filters: any[] = [];

    props?.columns &&
      props?.columns.map((column: any) => {
        const value = params.get(column.accessor)?.replaceAll(/'/g, "''") ?? null;
        filters.push({ field: column.searchlightField ?? null, token: column.searchlightToken ?? null, value: value ?? null });
      });
    filters.forEach((filter: { field: string; token: string; value: string | null }) => {
      if (filter.value !== null) {
        ret.push({ filterKey: filter.field, filterToken: filter.token, filterValue: filter.value });
      }
    });
    return ret;
  }

  const queryClient = useQueryClient();

  const refreshInboxMegamenuCount = async () => {
    await queryClient.refetchQueries({ queryKey: [`${selectedWorkspace?.workspace_type}-${selectedWorkspace?.id}`], type: "active", exact: true });
  };

  const queryKey = `activity-streams-index-${selectedWorkspace?.workspace_type_route}-${props?.assigneeType}-${getSearchStatus()}-${
    paginationConfig.page?.pageSize
  }-${paginationConfig.page?.pageNumber}-${sortQuery}`;

  const fetchParser = (fetchResult: ActivityModelFetchResult, variant?: FetchVariant) => {
    return (
      fetchResult?.data?.map((record: ActivityStreamItem & EInvoiceRecord) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record.invoice_id,
          }),
          ...((variant === "export" || variant === "all") && {
            //E-Invoice keys
            invoice_number: record?.reference_code,
            payment_due_date: Date.parse(record?.payment_due_date as unknown as string) / NUMERIC_VALUES.CONSTANT_THOUSAND,
            payment_due_date_raw: record?.payment_due_date,
            invoice_status: record?.aging,
            total_amount: record?.base_currency_total_amount,
            amount_paid: record?.pre_tax_transaction_amount,
            state: record?.einvoice_status,
            company_name: record?.company_name,
            purchase_order_code: record?.purchase_order_code,
            reference_code: record?.reference_code,
            attachments: record?.attachments,
            invoice_id: record?.invoice_id,
            workflow_status_id: record?.workflow_status_id,
            invoice_type_code: record?.invoice_type_code,
          }),
          ...(variant === "all" && {
            read: record.read,
          }),
        };
      }) ?? []
    );
  };

  const getActivitiesTableRecords = async (searchedTextQuery?: string, columnFilters?: string, pageNumber?: number, pageSize?: number) => {
    const finalAppliedFilter = [searchedTextQuery, columnFilters].filter(Boolean).join("&");
    const res = await eInvoicesClient.getEInvoices(
      selectedWorkspace?.id,
      pageNumber ?? paginationConfig.page?.pageNumber,
      pageSize ?? paginationConfig.page?.pageSize,
      TableUtils.getSortQueryString(params, sortQuery),
      getFilterFromURL(),
      path.includes("receivedEInvoices") ? "received" : "sent",
      finalAppliedFilter
    );
    return res;
  };

  const {
    isLoading,
    data: cachedData,
    isFetching,
    isError,
  } = useQuery(
    [
      queryKey,
      paginationConfig.page?.pageNumber,
      paginationConfig.page?.pageSize,
      getSearchStatus(),
      TableUtils.getSortQueryString(params, sortQuery),
      appliedFilters,
      customerId,
      eInvoiceType,
    ],
    async () => {
      const res = await getActivitiesTableRecords("", appliedFilters);
      return { ...res, data: fetchParser(res, "all") };
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchInterval: RefetchIntervals.activityStreamsIndex,
    }
  );

  useEffect(() => {
    /**
     * update the page number once user perform any action and because of that total records get's changed
     */
    if ((paginationConfig.page?.pageNumber || NUMERIC_VALUES.CONSTANT_ONE) > cachedData?.total_pages) {
      updatePageNumber(cachedData?.total_pages || NUMERIC_VALUES.CONSTANT_ONE);
    }
    setTotalRecords(cachedData?.total_records ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    setTotalPages(cachedData?.total_pages ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);

    if (isError) {
      updatePageNumber(NUMERIC_VALUES.CONSTANT_ONE);
      setTotalRecords(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
      setTotalPages(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    }
    /**
     * Side-effect to watch cachedData change and if an update is received,
     * then make an active call to update meta count
     */
    refreshInboxMegamenuCount();
  }, [cachedData, isError]);

  async function handleRowClick(
    e: MouseEvent<HTMLTableRowElement>,
    row: { id?: string; read?: boolean },
    currentPageRecords?: { id: string; isSelected?: boolean }[]
  ) {
    /**
     * If a row item is already selected, then clicking anywhere on other rows should select those rows, and do not navigate.
     * if double clicked, then navigate to those row records
     */
    const clickType = e.detail;
    if (clickType === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE && selectionConfig.tableRecords?.selectedRecordsIds.length && row.id) {
      selectionConfig?.onClickTableRow(row?.id, currentPageRecords ?? []);
      return;
    }

    let pathToPush!: string;

    if (path.includes("receivedEInvoices")) {
      const queryKeyParams =
        "?read=true" +
        `&assigneeType=${props?.assigneeType}` +
        `&searchStatus=${getSearchStatus()}` +
        `&pageSize=${paginationConfig.page?.pageSize}` +
        `&pageNumber=${paginationConfig.page?.pageNumber}` +
        `&sortQuery=${sortQuery}`;
      pathToPush = `${workspaceHomePath}/activities/receivedEInvoices/${row.id}${!row.read ? queryKeyParams : ""}`;
    } else if (path.includes("sentEInvoices")) {
      pathToPush = `${workspaceHomePath}/activities/sentEInvoices/${row.id}`;
    } else {
      const route = getBaseRoute();
      pathToPush = `${route}/activities/${row.id}`;
    }

    /**
     * Set the following properties into ActivityStreamSwitchContext
     * to facilitate activity stream switching.
     * @param selectedActivityStreamId - currently selected activity stream id
     * @param activityStreamIds - list of activity streams in the index
     * @param paginationState - current pagination state for reference
     */
    setEnableDocumentSwitch(true);
    setSelectedActivityStreamId({ id: row.id } as ActivityStreamId);
    setActivityStreamIds(cachedData?.data.map((item: any) => ({ id: item.id })));
    setCurrentPaginationState({
      page: paginationConfig.page?.pageNumber ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
      pageCount: cachedData?.total_records,
      pageSize: paginationConfig.page?.pageSize ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
      totalRecords: paginationConfig.page?.totalRecords ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
    });
    setDocumentType("activity_stream");
    setRootSwitcherStateSnapshot();
    history.push(pathToPush);
  }

  /**
   * Ellipse handler which open the new activity dialog.
   *
   * @param {TableData} row The current selected row of the table.
   * @param {boolean} requireTemplates Flag to check if templates are required or not. This flag is set on hover bar nudge activity creation
   */
  useEffect(() => {
    setViewAndStatus(url);
  }, [url]);

  useEffect(() => {
    setAllRowsSelected(selectionConfig?.isAllRecordsSelected || !!selectionConfig?.hasAllRecordsSelectedButtonClicked?.current);
  }, [selectionConfig?.isAllRecordsSelected]);

  // create deselected ids ransac query when user deselect any records after selecting all
  useEffect(() => {
    const { hasAllRecordsSelectedButtonClicked, isAllRecordsSelected, tableRecords } = selectionConfig;

    if (!hasAllRecordsSelectedButtonClicked?.current || isAllRecordsSelected || !tableRecords?.allRecords) {
      if (deselectedRowsFilter) {
        setDeselectedRowsFilter("");
      }
      return;
    }

    const deselectedIds = tableRecords?.allRecords?.filter((item) => !item?.isSelected)?.map((deselectedItem) => deselectedItem?.id);
    let filter = "";
    deselectedIds.forEach((id) => {
      filter = filter ? `${filter}&qa[uuid_not_in][]=${id}` : `qa[uuid_not_in][]=${id}`;
    });
    setDeselectedRowsFilter(filter);
  }, [selectionConfig?.tableRecords?.selectedRecordsIds]);

  const getPreviousSuggestedData = (recentSearchHistory: FilterDropdownSuggestionRef) => {
    const { filterSearchedText } = initialFilterConfigs ?? {};
    const { reference_code } = filterSearchedText ?? {};

    return {
      reference_code:
        reference_code?.value?.length >= NUMERIC_VALUES.CONSTANT_THREE
          ? recentSearchHistory?.reference_code?.filter((item) => initialFilterConfigs?.activeFilters?.reference_code?.includes(item.id))
          : recentSearchHistory?.reference_code,
    };
  };

  //it will return maximum of 5 required formated record for dropdown search suggestion or result data
  const getDropdownSearchFilterData = (activitiesTableData: any) => {
    const { filterSearchedText = {}, getSearchSuggestionHistory } = initialFilterConfigs || {};
    const { reference_code } = filterSearchedText;
    const recentActivitiesSearchedData = getSearchSuggestionHistory("e", props.category ?? "");
    const previousData = getPreviousSuggestedData(recentActivitiesSearchedData);

    /**
     * if user searching for result then return result data along with previous selected data
     * else return max of 5 recent searched data if that is exist
     */
    const purchaseOrderCodeData =
      !reference_code?.value || reference_code?.value.length < NUMERIC_VALUES.CONSTANT_THREE
        ? (previousData.reference_code ?? []).slice(NUMERIC_VALUES.CONSTANT_ZERO, NUMERIC_VALUES.CONSTANT_FIVE)
        : [...(previousData?.reference_code ?? []), ...(activitiesTableData ?? [])];

    //formatting data for th dropdown suggestion list
    const purchaseOrderCodeDropDownList = purchaseOrderCodeData
      ?.filter(({ reference_code, primaryText }) => reference_code || primaryText)
      .map(({ id, reference_code, company_name = "", primaryText, secondaryText }) => ({
        id,
        primaryText: reference_code ?? primaryText,
        isAttachemntRequired: false,
        selected: initialFilterConfigs?.activeFilters?.reference_code?.ids?.includes(id) ?? false,
        secondaryText: company_name || secondaryText || "--",
        isAvatarRequired: false,
      }));

    /**
     * This code first creates a Map object by mapping each object in the original array to an array with two elements:
     * the key and the value. The Map constructor can then be called with the resulting array of key-value pairs to create
     * a new Map object. Finally, the Array.from method is used to convert the Map object back to an array, where the map
     * function is used to only return the values from the key-value pairs.
     */
    const uniquePurchaseOrderCodeSuggestions = Array.from(new Map(purchaseOrderCodeDropDownList?.reverse().map((obj) => [obj.primaryText, obj])))
      .reverse()
      .map(([, value]) => value);

    return props.columns.map((column) => {
      switch (column.accessor) {
        case "reference_code":
          return { ...column, dropdownSearchListItems: uniquePurchaseOrderCodeSuggestions };
        default:
          return column;
      }
    });
  };

  function createFilterQuery(field: string, value: string) {
    if (value && value.length >= NUMERIC_VALUES.CONSTANT_THREE) {
      return `qa[${field}_contains]=${encodeURIComponent(value)}`;
    }
    return "";
  }

  /**
   * if user type anything, it will invoke api and retrieve the result when searched text value greater than 3
   * else display max of 5 recent searched data if that is exist
   */
  useEffect(() => {
    const { filterSearchedText } = initialFilterConfigs || {};
    const { reference_code } = filterSearchedText || "";
    setIsColumnsConfigDataLoading(true);
    let filters = "";

    if (reference_code?.value?.length >= NUMERIC_VALUES.CONSTANT_THREE) {
      const fromQuery = createFilterQuery("reference_code", reference_code.value);
      filters += (filters ? "&" : "") + fromQuery;
    }

    if (filters) {
      getActivitiesTableRecords(filters, "", NUMERIC_VALUES.CONSTANT_ONE, NUMERIC_VALUES.CONSTANT_TWENTY)
        .then((res) => {
          if (res.success) {
            setIsNoSearchResultFound(!res.total_records);
            setColumnsConfigData(getDropdownSearchFilterData(fetchParser(res, "all")));
          }
        })
        .catch(() => {
          setIsNoSearchResultFound(true);
        })
        .finally(() => {
          setIsColumnsConfigDataLoading(false);
        });
    } else {
      setIsColumnsConfigDataLoading(false);
      setColumnsConfigData(getDropdownSearchFilterData([]));
    }
  }, [initialFilterConfigs?.filterSearchedText, initialFilterConfigs?.filterQuery, appliedFilters]);

  /**
   * This Logic has been implemented to handle the table header showing activities count and selection,
   * this is a one of case where activity plural becomes activities, for other cases like invoice -< invoices, payments -> payments
   * we have generalised handling inside library, if there are more such occurences in future we might look at making hchanges to
   * accomodate those in the library itself
   */
  const getHeaderTableType = () => {
    if (selectedRowsCount === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) {
      return t(`table.headerCaption.tableType.${eInvoiceType}`, { ns: "einvoices" });
    } else if (selectedRowsCount < DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) {
      return cachedData?.data.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
        ? t(`table.headerCaption.tableType.${eInvoiceType}`, { ns: "einvoices" })
        : t(`table.headerCaption.tableType.${eInvoiceType}s`, { ns: "einvoices" });
    } else {
      return t(`table.headerCaption.tableType.${eInvoiceType}s`, { ns: "einvoices" });
    }
  };

  const getFooterTableType = () => {
    if (cachedData?.data.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) {
      return t(`table.headerCaption.tableType.${eInvoiceType}`, { ns: "einvoices" });
    } else {
      return t(`table.headerCaption.tableType.${eInvoiceType}s`, { ns: "einvoices" });
    }
  };

  /**
   * @function getFilters
   * A helper function to construct a filter string to be sent in the export paylod.
   * @param exportInvoice
   * @param ids
   * @returns
   */
  const getFilters = (exportInvoice = false, ids?: string[]) => {
    const additionalFilters: Array<Record<string, string>> = [];
    // evaluate select filter on export
    if (exportInvoice && !selectionConfig?.isAllRecordsSelected && ids && ids.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      const { hasAllRecordsSelectedButtonClicked, isAllRecordsSelected, tableRecords } = selectionConfig;

      const exportIds =
        hasAllRecordsSelectedButtonClicked?.current && !isAllRecordsSelected
          ? tableRecords?.allRecords?.filter((item) => !item?.isSelected)?.map((deselectedItem) => deselectedItem?.id)
          : ids;

      exportIds.forEach((id) => {
        const searchlightFilter = `qa[invoice_id_${hasAllRecordsSelectedButtonClicked?.current && !isAllRecordsSelected ? "not_in" : "in"}][]=${id}`;
        additionalFilters.push({
          searchlightFilter,
        });
      });
    }
    const filterQuery = TableUtils.getFilterFromURL(params, props.columns);
    return TableUtils.columnFilterParser(filterQuery, true, additionalFilters);
  };

  /**
   * @function handleExports
   * A helper function to create an export request payload and initiate the export.
   * @param ids
   */
  const handleExports = async (ids: string[]) => {
    setOverrideExportBehaviour(true);
    setExportState(EInvoiceExportModalStates.EXPORT_IN_PROGRESS);
    setShowExportModal(true);
    const filters = [getFilters(true, ids), initialFilterConfigs?.filterQuery].filter(Boolean).join("&");
    let response: any = {};
    try {
      setExportLoading(true);
      const isExportAllRecords =
        (selectionConfig?.tableRecords?.selectedRecordsIds?.length ?? NUMERIC_VALUES.CONSTANT_ZERO) > NUMERIC_VALUES.CONSTANT_ZERO
          ? selectionConfig.isAllRecordsSelected || !!selectionConfig?.hasAllRecordsSelectedButtonClicked?.current
          : true;
      const export_type = path.includes("receivedEInvoices") ? "received_einvoices" : "sent_einvoices";

      response = await exportClient.exportRecords(
        export_type,
        isExportAllRecords,
        filters,
        `qs[s]=${TableUtils.getSortQueryString(params, sortQuery).toLowerCase()}`,
        selectedWorkspace?.id
      );
      if (response.success) {
        updateExportedRecordsIds([response.data?.export_request_id], false);
      }
    } catch (error) {
      setShowExportModal(false);
      setToastOptions({ open: true, severity: "error", message: t("table.exports.toast.failure", { ns: "einvoices" }) });
      console.log(error);
    } finally {
      setExportLoading(false);
    }
  };

  /**
   * @function triggerExportDownload
   * A helper function to trigger a download for the export created, and close the modal, and reset the export context.
   */
  const triggerExportDownload = () => {
    setOverrideExportBehaviour(false);
    setExportState("");
    setShowExportModal(false);
    triggerDownload();
    if (exportState === EInvoiceExportModalStates.EXPORT_SUCCESS) {
      flushExportData();
    }
    setToastOptions({ open: true, severity: "success", message: t("table.exports.toast.success", { ns: "einvoices" }) });
  };

  /**
   * Side-effect to check if the export data is ready, with records having success status.
   * If so then stop polling, and change the modal UI to success state and show download button
   */
  useEffect(() => {
    if (exportData?.length) {
      const exportedFiles = exportData?.filter((item) => item.status === "success" || item.status === "failure");
      exportedFiles?.forEach((item) => {
        if (item.status === "success") {
          setExportState(EInvoiceExportModalStates.EXPORT_SUCCESS);
          setIsPollingEnabled(false);
        }
        if (item.status === "failure") {
          setToastOptions({ open: true, severity: "error", message: t("table.exports.toast.failure", { ns: "einvoices" }) });
          setOverrideExportBehaviour(false);
          setExportState("");
          setShowExportModal(false);
          flushExportData();
        }
      });
    }
  }, [exportData]);

  /**
   * @function getFileUrls
   * A helper function to fetch url for a file object from platform using file id.
   * @param id
   * @returns
   */
  const getFileUrls = async (id: string) => {
    const asyncFileURLs = await Promise.resolve(documentsClient.downloadUrl(id));
    return asyncFileURLs?.downloadLink;
  };

  const validateAttachments = async (files: (AttachmentItem & EInvoiceAttachmentItem)[]) => {
    return await Promise.all(
      files
        ?.filter((file) => !file?.is_archived)
        ?.map(async (file: any) => {
          const fileUrl = await getFileUrls(file?.attachment_id);
          const data = {
            ...file,
            file_url: file?.file_url || (fileUrl as unknown as string),
            extension: file?.file_ext?.split(".")[NUMERIC_VALUES.CONSTANT_ONE],
            file_name: file?.file_name + file?.file_ext,
            created_at: file?.created,
            id: file?.attachment_id || Math.random(),
          };
          return await Promise.resolve(data);
        })
    );
  };

  const previewAttachment = (attachment: (AttachmentItem & EInvoiceAttachmentItem)[]) => {
    setEInvoiceId(attachment?.[DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO]?.["invoice_id"] as string);
    const currentEInvoice =
      cachedData?.data?.filter((item: any) => item?.invoice_id === attachment?.[DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO]?.["invoice_id"])?.[
        DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
      ] || null;
    setEInvoiceWorkflowStatus({
      id: currentEInvoice?.workflow_status_id,
      key: currentEInvoice?.state?.split(" ")?.join("_")?.toLowerCase(),
      value: currentEInvoice?.state,
      invoiceTypeCode: currentEInvoice?.invoice_type_code,
    });
    setActiveAttachmentID(attachment?.[DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO]?.["attachment_id"] as any);
    setIsAttachmentsLoading(true);
    setOpenPreview(true);
    validateAttachments(attachment)
      .then((results) => {
        setEnhancedAttachemnts(results as AttachmentItem[]);
      })
      .catch(() => {
        setToastOptions({ open: true, severity: "error", message: t("table.attachmentPreviewError", { ns: "einvoices" }) });
      })
      .finally(() => {
        setIsAttachmentsLoading(false);
      });
  };

  const resetAttachmentPreview = () => {
    setOpenPreview(false);
    setActiveAttachmentID("");
    setEnhancedAttachemnts([]);
    setIsAttachmentsLoading(true);
    setEInvoiceId("");
    setEInvoiceWorkflowStatus({} as IDocumentState);
  };

  const onClickPreviewClose = (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement> | KeyboardEvent) => {
    event.stopPropagation();
    resetAttachmentPreview();
  };

  const onClickDownload = (
    event: React.MouseEvent<HTMLTableRowElement | HTMLDivElement | HTMLButtonElement | HTMLAnchorElement | HTMLSpanElement>,
    attachments: AttachmentItem[] | BlobAttachmentItem[]
  ) => {
    event.stopPropagation();
    TableUtils.downloadAll(attachments, setToastOptions);
  };

  /**
   * Bulk action buttons
   */
  const rowSelectBtns: UserAction[] = [
    {
      children: t("table.exports.actionLabel", { ns: "einvoices" }),
      loading: exportLoading,
      callback: (ids: string[]) => {
        handleExports(ids);
      },
      visible: {
        onEllipsis: false,
        onHeader: false,
        onHover: false,
        onCaption: true,
      },
    } as UserAction,
  ];

  return (
    <>
      <div className={`table-activity-wrapper activity-stream-v2 e-invoices`}>
        <div className="body-table e-invoices">
          <Table
            configs={{
              columnConfigs: columnsConfigData,
              initialTableConfigs: {
                name: "E-Invoices",
                type: getHeaderTableType(),
                footerTableTypeText: getFooterTableType(),
                loading: isLoading,
                extraHandlers: {
                  setSnackbarConfig: (payload: any) =>
                    setToastOptions({
                      open: true,
                      severity: payload?.type ?? "info",
                      message: payload?.text ?? t("toastMessages.downloadAttachment.inProgress", { ns: "activities" }),
                    }),
                  drawerHandler: (attachemnt: any) => previewAttachment(attachemnt as (AttachmentItem & EInvoiceAttachmentItem)[]),
                },
                isRefetching: isFetching,
              },
              paginationConfigs: { ...paginationConfig, enableNavigator: true, enableSummary: true },
              initialSortConfigs: sortConfig,
              selectionConfig: selectionConfig,
              initialFilterConfigs: {
                ...initialFilterConfigs,
                dateFormat: calendarDateFormat,
                isSuggestionDataLoading: isColumnsConfigDataLoading,
                category: props.category,
                workspace: selectedWorkspace.workspace_type_route?.toLocaleLowerCase() ?? WorkspaceType.AR,
                isNoSearchResultFound,
              },
              currencyConfig: {
                locale: userStatus?.currency?.locale,
                code: userStatus?.currency?.code,
                symbol: userStatus?.currency?.symbol,
              },
            }}
            rows={
              !isError
                ? (cachedData?.data || [])?.map((rec: any) => {
                    return {
                      ...rec,
                    };
                  })
                : []
            }
            handlers={{
              onRowClick: handleRowClick,
            }}
            userActions={isError || cachedData?.data?.length === NUMERIC_VALUES.CONSTANT_ZERO ? [] : [...rowSelectBtns]}
            visibility={{
              exportBtn: false,
              hoverbar: false,
            }}
            hoverBar={false}
            emptyMessage={{
              title: isError ? t("table.errorMessage.title", { ns: "einvoices" }) : t("table.emptyMessage.title", { ns: "einvoices" }),
              caption: isError ? t("table.errorMessage.caption", { ns: "einvoices" }) : t("table.emptyMessage.caption", { ns: "einvoices" }),
            }}
          />
          <ExportProgressModal open={showExportModal} state={exportState} onSuccess={triggerExportDownload} />
          {openPreview && (
            <AttachmentPreview
              openPreview={openPreview}
              isAttachmentsLoading={isAttachmentsLoading}
              activeAttachmentID={activeAttachmentID}
              handleClose={onClickPreviewClose}
              attachments={enhancedAttachemnts?.filter((item) => item?.attachment_type !== EINVOICE_ATTACHMENT_TYPES.DEMATERIALISED)}
              onClickDownload={onClickDownload}
              renderProps={<DocumentStateActionsRenderer recordId={eInvoiceId} state={eInvoiceWorkflowStatus} onSuccess={resetAttachmentPreview} />}
            />
          )}
        </div>
      </div>
    </>
  );
}

import React from "react";
import { Paper } from "@mui/material";
import { ItemSearchHeadingInterface } from "../Interface/SearchResultContentInterface";
import useGlobalSearchStyle from "../Styles/useGlobalSearchStyles";
import { useTranslation } from "react-i18next";

const ContentHeadingComponent: React.FC<ItemSearchHeadingInterface> = ({ title, isViewResultRequired, totalCount, onClickViewAll }) => {
  const { t } = useTranslation();
  const { contentHeadingContainer, contenHeadingLeftSpan, contenHeadingRightSpan } = useGlobalSearchStyle({});
  return (
    <Paper className={contentHeadingContainer}>
      <span className={contenHeadingLeftSpan}>{title}</span>
      {isViewResultRequired && (
        <span className={contenHeadingRightSpan} onClick={onClickViewAll}>
          {t("globalSearchSuggestions.viewAll", {ns: "global_search"})} ({totalCount})
        </span>
      )}
    </Paper>
  );
};

export default ContentHeadingComponent;

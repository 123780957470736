import React from "react";
import { AttachmentChips, AttachmentContainer } from "../styled/dropdown.search.filter.styled";
import { PDFAttachment } from "../../../../../Icons/Icons";
import { NUMERIC_VALUES } from "../../../../constants/numeric.constants";

type AttachmentChipItemsProps = {
  attachmentList: string[];
  attachmentCount: number;
};

const AttachmentChipItems: React.FC<AttachmentChipItemsProps> = ({ attachmentList, attachmentCount }) => {
  return (
    <AttachmentContainer>
      {attachmentList?.slice(NUMERIC_VALUES.CONSTANT_ZERO, NUMERIC_VALUES.CONSTANT_TWO)?.map((attachName) => (
        <AttachmentChips key={attachName}>
          <PDFAttachment />
          <span className="pdf-text">{attachName}</span>
        </AttachmentChips>
      ))}

      {attachmentCount > NUMERIC_VALUES.CONSTANT_TWO && (
        <AttachmentChips>
          <span className="pdf-text">{attachmentCount - NUMERIC_VALUES.CONSTANT_TWO} more</span>
        </AttachmentChips>
      )}
    </AttachmentContainer>
  );
};

export default AttachmentChipItems;

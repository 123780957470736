import React from "react";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../../../constants/NumericConstants";
import useAttachmentFilesUploader from "./useAttachmentFilesUploader";
import useHandleVisibility from "../../../../hooks/useHandleVisibility";
import { AttachmentTypes, PreviousUploaded, WindowAttachment, ERPAttachment } from "../types/windowAttachment.types";
import useAttachmentValidation from "../../../../hooks/validations/useAttachmentValidation";

/**
 * @function useWindowAttachmentManager
 *
 * A hook to manage - add/remove activity attachments of many kinds by
 * storing them in a consistent interface - WindowAttachment
 *
 * it exports simple to understand add & remove apis, along with derived
 * meta data necessary to perform various operations on activity popup
 * window attachments.
 *
 * This hook also provides a medium to validate the attachments being
 * added with activity popup window w.r.t. backend servicability.
 *
 * @param defaultAttachments window attachement property
 */
const useWindowAttachmentManager = (defaultAttachments: AttachmentItem[]) => {
  // imports & helpers
  const { handleSingleFileUpload, handleMultipleFileUpload } = useAttachmentFilesUploader();
  const { validateData } = useAttachmentValidation();
  const initialEleVisibility = {
    uploading: false,
    didRemoveUnsupported: false,
  };
  const { visibility, dispatch } = useHandleVisibility(initialEleVisibility);

  // attachments data
  const [inboxAttachments, setInboxAttachments] = React.useState<WindowAttachment[]>([]);
  const [uploadAttachments, setUploadAttachments] = React.useState<WindowAttachment[]>([]);
  const [previouslyUploadedAttachments, setPreviouslyUploadedAttachments] = React.useState<WindowAttachment[]>([]);
  const [windowInputAttachments, setWindowInputAttachments] = React.useState<WindowAttachment[]>([]);
  const [hasTemplateAttachments, setHasTemplateAttachments] = React.useState<boolean>(false);

  // maintains payload for inbox drive/platform dependent payloads
  // creates payload from original template data
  const inboxAttachmentPayload = React.useMemo(
    () =>
      inboxAttachments.map((item: WindowAttachment) => ({
        transaction_id: (item.data as ERPAttachment).transactionId,
        transaction_type: (item.data as ERPAttachment).transactionType,
      })),
    [inboxAttachments]
  );

  // consolidated attachment ids required to Filter, Show & Remove Attachments
  const allAttachmentIds = React.useMemo(() => {
    return [
      ...inboxAttachments.map((item) => item.id),
      ...uploadAttachments.map((item) => item.id),
      ...previouslyUploadedAttachments.map((item) => item.id),
      ...windowInputAttachments.map((item) => item.id),
    ];
  }, [inboxAttachments, uploadAttachments, previouslyUploadedAttachments, windowInputAttachments]);

  // provides a consolidated partial windowAttachment interface
  const attachmentConfigs = React.useMemo(
    () => [...inboxAttachments, ...uploadAttachments, ...previouslyUploadedAttachments, ...windowInputAttachments],
    [inboxAttachments, uploadAttachments, previouslyUploadedAttachments, windowInputAttachments]
  );

  const disableValidationBanner = () => dispatch({ type: "close", payload: "didRemoveUnsupported" });

  /**
   * @function addUploadedAttachment
   * helper function to manage attachments with data type - File
   *
   * @param fileList list of new files user wants to upload to the server
   * @returns void
   */
  const addUploadedAttachment = async (fileList: FileList) => {
    const files = Array.from(fileList);
    if (files.length < DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) {
      return;
    }
    let logs;
    dispatch({ type: "open", payload: "uploading" });
    if (files.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) {
      logs = await handleSingleFileUpload(files[0]);
    } else {
      logs = await handleMultipleFileUpload(files);
    }

    // processing successfull uploads
    if (logs?.successes && logs.successes.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      // prepare windowAttachment
      const attachments: WindowAttachment[] = logs?.successes.map((item) => ({
        data: item.response.data,
        id: item.response.data.id,
        name: item.response.data.file_name,
        variant: AttachmentTypes.Upload,
      }));
      // add successes to the attachment files
      setUploadAttachments([...uploadAttachments, ...attachments]);
    }
    dispatch({ type: "close", payload: "uploading" });
  };

  /**
   * @function addInboxDriveAttachments
   * helper function to manage inbox drive i.e. ERP based attachments, data type could be - InboxDrive OR TemplateProp
   *
   * @param data list of erp attachment & their attributes
   * @param via data type - InboxDrive OR TemplateProp
   * @param validate flag to run validation while adding an attachment
   * @returns void
   */
  const addInboxDriveAttachments = (data: ERPAttachment[], via: AttachmentTypes.InboxDrive | AttachmentTypes.TemplateProp, validate = false) => {
    // filter duplicates
    const files = data.filter((item: ERPAttachment) => item && allAttachmentIds.indexOf(item.transactionId) === NUMERIC_VALUES.CONSTANT_NEGATIVE_ONE);
    if (files.length < DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) {
      return;
    }

    // prepare window attachment
    const documents: WindowAttachment[] = [];
    let attachments: WindowAttachment[] = [];
    files.forEach((item) => {
      const attach = {
        data: item,
        id: item.transactionId,
        name: item.attachmentTitle,
        variant: via,
      };
      if (item.transactionType === "document") {
        documents.push(attach);
      } else {
        attachments.push(attach);
      }
    });

    // add validation
    if (validate) {
      const validatedAttachments = validateData(attachments);
      const unservicable = validatedAttachments.filter((attachment) => !attachment.downloadSupported);
      if (unservicable) {
        // remove it from the attachments
        const unservicableIds = unservicable.map((item) => item.id);
        attachments = attachments.filter((item) => !unservicableIds.includes(item.id));
        // opens the unsupported attachment banner
        if (unservicableIds.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
          dispatch({ type: "open", payload: "didRemoveUnsupported" });
        }
      }
    }

    // add values to attachment files
    setInboxAttachments([...inboxAttachments, ...documents, ...attachments]);
  };

  /**
   * @function addWindowPropAttachment
   * helper funtion to manage attachments that comes along with activity window
   * initilization such as Forward activity
   *
   * server already maintains the file in storage, we tag the attachment id in
   * the payload to notify the same
   *
   * @param data list of activity window prop attachments
   * @returns void
   */
  const addWindowPropAttachment = (data: AttachmentItem[]) => {
    // filter duplicates
    const files = data.filter((item) => item && allAttachmentIds.indexOf(item.id) === NUMERIC_VALUES.CONSTANT_NEGATIVE_ONE);
    if (files.length < DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) {
      return;
    }
    // prepare window attachment
    const attachments: WindowAttachment[] = files.map((item) => ({
      data: item,
      id: item.id,
      name: item.file_name,
      variant: AttachmentTypes.WindowProp,
    }));
    // add values to attachment files
    setWindowInputAttachments(attachments);
  };

  /**
   * @function addPreviousUploadAttachments
   * helper function to manage document type attachments from inbox attachment modal
   *
   ** server already maintains the file in storage, we tag the attachment id in
   * the payload to notify the same
   *
   * @param data
   * @returns
   */
  const addPreviousUploadAttachments = (data: PreviousUploaded[]) => {
    // filter duplicates
    const files = data.filter((item: any) => item && allAttachmentIds.indexOf(item.id) === NUMERIC_VALUES.CONSTANT_NEGATIVE_ONE);
    if (files.length < DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) {
      return;
    }

    // prepare window attachment
    const attachments: WindowAttachment[] = files.map((item) => ({
      data: item,
      id: item.id,
      name: item.name,
      variant: AttachmentTypes.PreviousUpload,
    }));

    // add values to attachment files
    setPreviouslyUploadedAttachments([...previouslyUploadedAttachments, ...attachments]);
  };

  /**
   * @function add
   * helper function to manage attachment addition to an activity popup window
   *
   * @param data list of attachment data
   * @param via data type - InboxDrive OR TemplateProp
   * @param validate flag to run validation while adding an attachment
   */
  const add = async (data: any, via: AttachmentTypes, validate = false) => {
    switch (via) {
      case AttachmentTypes.Upload:
        // closes the unsupported attachment banner
        dispatch({ type: "close", payload: "didRemoveUnsupported" });
        await addUploadedAttachment(data);
        break;
      case AttachmentTypes.WindowProp:
        addWindowPropAttachment(data);
        break;
      case AttachmentTypes.PreviousUpload:
        addPreviousUploadAttachments(data);
        break;
      case AttachmentTypes.InboxDrive:
      case AttachmentTypes.TemplateProp:
        addInboxDriveAttachments(data, via, validate);
        break;
      default:
        break;
    }
  };

  /**
   * @function remove
   * helper function to remove attachment from list of added attachments
   *
   * @param index index of attachment in the attachmentIds list
   */
  // removes an attachment
  const remove = (index: number) => {
    const removedAttachId = allAttachmentIds[index];
    // if anything selected from company records, remove those
    if (inboxAttachments) {
      setInboxAttachments([...inboxAttachments.filter((attach: WindowAttachment) => attach && attach.id !== removedAttachId)]);
    }
    if (uploadAttachments) {
      setUploadAttachments([...uploadAttachments.filter((attach: WindowAttachment) => attach && attach.id !== removedAttachId)]);
    }
    if (previouslyUploadedAttachments) {
      setPreviouslyUploadedAttachments([
        ...previouslyUploadedAttachments.filter((attach: WindowAttachment) => attach && attach.id !== removedAttachId),
      ]);
    }
    if (windowInputAttachments) {
      setWindowInputAttachments([...windowInputAttachments.filter((attach: WindowAttachment) => attach && attach.id !== removedAttachId)]);
    }
  };

  const removeAllAttachedFiles = () => {
    setInboxAttachments([]);
    setUploadAttachments([]);
    setPreviouslyUploadedAttachments([]);
    setWindowInputAttachments([]);
    dispatch({ type: "close", payload: "didRemoveUnsupported" });
  };

  /** add activity popup window - attachment props */
  React.useEffect(() => {
    (async function addAttachment() {
      await add(defaultAttachments, AttachmentTypes.WindowProp);
    })();
  }, [defaultAttachments]);

  return {
    add,
    remove,
    removeAllAttachedFiles,
    disableValidationBanner,
    allAttachmentIds,
    attachmentConfigs,
    visibility,
    inboxAttachmentPayload,
    hasTemplateAttachments,
    setHasTemplateAttachments,
  };
};

export default useWindowAttachmentManager;

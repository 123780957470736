import { IJodit } from "jodit/types";
import { Close } from "../../JoditIcons/JoditIcons";
import DOMPurify from "dompurify";
import _ from "underscore";
/**
 * @function InlineLinkEditor
 * Jodit plugin function to enable inline link editing.
 * A modal will be opened to perform editing of link or unlink.
 * @param jodit - The Jodit instance of type IJodit
 */
export default function InlineLinkEditor(jodit: IJodit) {
  /**
   * Get references to containers to render various modal.
   */
  const modalContainer = document.querySelector(".hyperlink-modal-container") as HTMLDivElement;
  const editModalContainer = document.querySelector(".hyperlink-edit-modal-container") as HTMLDivElement;
  let editorSelection: any;
  let clickTarget: any;

  /**
   * @function closeModal
   * A helper function to close hyperlink modal
   */
  function closeModal() {
    modalContainer.innerHTML = "";
    modalContainer.classList.remove("is-visible");
  }

  /**
   * @function removeHref
   * A helper function to remove href/anchor properties from an anchor element and convert it into a regular text node.
   */
  function removeHref() {
    // const commonAncestorContainer = jodit.selection.range.commonAncestorContainer as HTMLDivElement;
    // let pElement;
    // if (commonAncestorContainer?.children) {
    //   pElement = commonAncestorContainer?.children[0] as HTMLElement;
    // } else {
    //   pElement = commonAncestorContainer.parentElement as HTMLElement;
    // }
    // pElement.innerHTML = pElement?.innerText;
    clickTarget.parentElement.textContent = clickTarget.innerText;
    closeModal();
  }

  /**
   * @function closeEditModal
   * A helper function to close the edit hyperlink modal.
   */
  function closeEditModal() {
    editModalContainer.innerHTML = "";
    editModalContainer.classList.remove("is-visible");
  }

  /**
   * @function commitChanges
   * A helper function to commit the current changes made to the hyperlink/anchor element in the selection to the editor
   */
  function commitChanges(linkText: string | undefined, orignalHref: string | undefined) {
    const text = (editModalContainer.querySelector('input[name="text"]') as HTMLInputElement)?.value;
    const href = (editModalContainer.querySelector('input[name="href"]') as HTMLInputElement)?.value;

    //added validation to simply close the edit modal in case of no change
    const parsedChangedAnchorText = text.replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;");
    const hasContentChanged = !(_.isEqual(parsedChangedAnchorText, linkText) && _.isEqual(orignalHref, href));
    if (!hasContentChanged) {
      closeEditModal();
      return;
    }
    /**
     * Focus the editor to the current selection. Then select the current range
     */
    editorSelection.focus();
    editorSelection.selectRange(editorSelection.range, true);
    editorSelection.select(editorSelection.range.commonAncestorContainer, true);
    const commonAncestorContainer = editorSelection.range.commonAncestorContainer;
    const pElement = commonAncestorContainer.parentElement as HTMLAnchorElement;
    pElement.textContent = text;
    pElement.href = href;
    closeEditModal();
    /**
     * Finally clear the selection
     */
    editorSelection.clear();
  }

  /**
   * @function openEditHrefModal
   * A helper function to render a modal to perform hyperlink editing.
   * @param linkText
   * @param href
   */
  function openEditHrefModal(linkText: string | undefined, href: string | undefined) {
    editModalContainer.innerHTML = `<div class="modal-dialog">
      <header class="modal-header">
      <span class="modal-title">Edit</span>
      <span class="close-button">${Close}</span>
      </header>
      <section class="modal-content">
      <div class="modal-form">
      <label>Text</label>
      <input type="text" value="${`${linkText}`}" name="text"/>
      <label>Link</label>
      <input type="text" value="${href}" name="href"/>
      </div>
      </section>
      <footer class="modal-footer">
      <button class="btn btn-secondary btn-lg secondary-border cancel-edit"><p class="btn-text">Cancel</p></button>
      <button class="btn btn-primary btn-lg secondary-border save-edit"><p class="btn-text">Save</p></button>
    </footer>
    </div>`;
    closeModal();
    editModalContainer.classList.add("is-visible");
    const modalClose = editModalContainer.querySelector(".close-button") as HTMLDivElement;
    modalClose.addEventListener("click", closeEditModal);
    const cancelEdit = editModalContainer.querySelector(".cancel-edit") as HTMLDivElement;
    cancelEdit.addEventListener("click", closeEditModal);
    const saveEdit = editModalContainer.querySelector(".save-edit") as HTMLDivElement;
    saveEdit.addEventListener("click", () => commitChanges(linkText, href));
  }

  /**
   * @function ModalRenderer
   * A helper function to render a modal to display the link/anchor properties
   * @param linkText
   * @param href
   */
  function ModalRenderer(linkText: string | undefined, href: string | undefined) {
    modalContainer.innerHTML = `<div class="modal-dialog">
      <header class="modal-header">
      <span class="modal-title">${linkText}</span>
      <span class="close-button">${Close}</span>
      </header>
      <section class="modal-content">
      <p>${href}</p>
      </section>
      <footer class="modal-footer">
      <button class="btn btn-secondary btn-lg secondary-border edit-href"><p class="btn-text">Edit</p></button>
      <button class="btn btn-error btn-lg secondary-border remove-href"><p class="btn-text">Remove</p></button>
    </footer>
    </div>`;
    modalContainer.classList.add("is-visible");
    const modalClose = modalContainer.querySelector(".close-button") as HTMLDivElement;
    modalClose.addEventListener("click", closeModal);
    const removeHrefBtn = modalContainer.querySelector(".remove-href") as HTMLDivElement;
    removeHrefBtn.addEventListener("click", removeHref);
    const editHrefBtn = modalContainer.querySelector(".edit-href") as HTMLDivElement;
    editHrefBtn.addEventListener("click", () => openEditHrefModal(linkText, href));
  }

  /**
   * Add an event listener to the jodit instance to look for clicks on link/hyperlink elements
   * in the editor to render modals to perform hyperlink editing
   */
  jodit.events.on("click", (event) => {
    if (event?.target?.tagName?.toLowerCase() === "a") {
      if (jodit?.selection?.range?.commonAncestorContainer?.nodeName === "#text") {
        /**
         * get and save selection for all future use
         */
        clickTarget = event.target;
        editorSelection = jodit.selection;
        const hyperlinkNode = editorSelection.range?.commonAncestorContainer?.parentNode as HTMLAnchorElement | null;
        const url = hyperlinkNode?.href;
        //Sanitize user input to avoid vulnerable to HTML injection
        const htmlContent = hyperlinkNode?.innerHTML ?? "";
        const text = htmlContent.replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;");
        const sanitizedLinkText = DOMPurify.sanitize(text);

        ModalRenderer(sanitizedLinkText, url);
      }
    }
  });
}

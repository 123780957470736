import _ from "underscore";
import APIClient from "../APIClient";
import { AxiosRequestConfig } from "axios";

const INBOX_API_BASE = `${process.env.REACT_APP_API_ENDPOINT_INBOX_API_PATH}${process.env.REACT_APP_API_ENDPOINT_INBOX_API_V2}`;
const CONFIG_OVERRIDE = {
  baseURL: process.env.REACT_APP_API_ENDPOINT_INBOX,
} as AxiosRequestConfig;

export class GlobalSearchClient {
  getSearchSuggestions(
    query: string,
    category: string | undefined,
    workspace_id: number,
    sort: string,
    pageCount?: number,
    pageSize?: number,
    filters?: any[],
    filterParamKey?: { [key: string]: string | string[] }
  ): Promise<APIResponse> {
    const url = `${INBOX_API_BASE}/workspaces/${workspace_id ?? ""}/search`;
    const options = {
      params: Object.assign(
        {
          query,
          category,
          page_size: pageSize,
          page: pageCount,
          sort,
          ...(!_.isEmpty(filterParamKey) && { filter: filterParamKey }),
        },
        ...(filters ?? [])
      ),
      ...CONFIG_OVERRIDE,
    };
    return APIClient.get(url, options).then((res) => res.data);
  }

  getSearchQuerySuggestionList(workspace_id: number): Promise<APIResponse> {
    const url = `${INBOX_API_BASE}/workspaces/${workspace_id ?? ""}/search_suggestions`;
    const options = {
      ...CONFIG_OVERRIDE,
    };

    return APIClient.get(url, options).then((res) => res.data);
  }
}

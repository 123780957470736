import React, { useState, MouseEvent, useEffect, SetStateAction, Dispatch, useMemo } from "react";
import Button from "../Button/Button";
import ActivityTo from "./ActivityTo/ActivityTo";
import ActivityTitle from "./ActivityTitle/ActivityTitle";
import ActivityBody from "./ActivityBody/ActivityBody";
import CloseIcon from "@mui/icons-material/Close";
import "./NewActivityPopup.scss";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../../constants/NumericConstants";
import Loading from "../../library/Loading/Loading";
import DropMenu from "../../library/DropMenu/DropMenu";
import Menu from "../../library/Menu/Menu";
import MenuItem from "../../library/MenuItem/MenuItem";
import _ from "underscore";
import { VIEW } from "./Attachments/AttachmentConstants";
import { TemplateTypes } from "../../../app/Templates/TemplateTypes";
import DOMPurify from "dompurify";
import { Reply } from "../../library/Icons/Icons";
import * as constants from "../../../constants/config";
import useWorkspaceConfigurations from "../../../hooks/useWorkspaceConfigurations";
import { MENTION_REGEX_CONSTANTS } from "../../../constants/RegexConstants";
import { CustomerContext } from "../../../contexts/CustomerContext";
import { ActivityOptions, ActivityType, APARStances } from "../../../types/enums";
import { useLocation } from "react-router";
import { ActivityEmailBodyActionShortcuts, KeyboardActionsConstants } from "../../../constants/KeyboardShortcutConstants";
import useKeyboardShortcut from "../../../hooks/useKeyboardShortcut";
import { TooltipTitles, TooltipTypes } from "../../../constants/TooltipConstants";
import { FeatureFlagContext, FeatureFlagProviderType } from "../../../contexts/FeatureFlagContext";
import { DisableFeatureContext, DisableFeatureProviderType } from "../../../contexts/DisableFeatureContext";
import { SelectChangeEvent } from "@mui/material";
import TemplateSelectMenu, { TemplateType } from "./TemplateSelectMenu/TemplateSelectMenu";
import useLocale from "../../../hooks/useLocale";
import useWindowAttachmentManager from "./hooks/useWindowAttachmentManager";
import { AttachmentTypes, ERPAttachment } from "./types/windowAttachment.types";
import { useTranslation } from "react-i18next";
import DiscardDraftModal from "../DiscardDraftModal/DiscardDraftModal";
import useDragAndDropFiles from "../../../hooks/useDragAndDropFiles";
import useProcessTemplateData, { TempSubjectSnippetValuesType } from "../../../hooks/useProcessTemplateData";
import { TemplateListItemType } from "../EmailTemplatePopoverComponent/types/email.template.popover.types";
import Utils from "../../../utils/utils";

export type ActivityToRef = {
  onSelectTo: (contacts: To[]) => void;
} | null;

export type ActivityPopupRef = {
  refreshPopupContent: () => void;
} | null;

type MentionsDataItemType = {
  id: number;
  name?: string;
  email_address?: string;
  active_at?: string;
  user_role?: string;
};

type MentionMembersType = {
  loading: boolean;
  members: Array<MentionsDataItemType>;
};

export interface NewActivityPopupProps {
  title?: string | null;
  open?: boolean;
  defaultTo?: To[];
  defaultCc?: To[];
  defaultBcc?: To[];
  defaultTitle?: string;
  defaultBody?: string;
  defaultFiles?: (File | string)[];
  replyOptions?: { replyButton: boolean; start: string; reply: To[]; replyAll: To[]; replyAllCC: To[] };
  forwarding?: boolean;
  disableTitle?: boolean;
  handleSend?: (to: To[], cc: To[], bcc: To[], title: string, body: string, attachmentIds: AttachmentIds, inboxAttachments: any) => void;
  handleSendMarkClosed?: (to: To[], cc: To[], bcc: To[], title: string, body: string, attachmentIds: AttachmentIds, inboxAttachments: any) => void;
  onClose?: () => void;
  contactOptions?: To[];
  activityType?: string | null;
  setNewActivityType?: (value: any) => void;
  addActivityDropdown?: ActivityDropdownItem[];
  editorState?: any;
  buttonProps?: {
    primaryActionLabel?: string;
    secondaryActionLabel?: string;
  } | null;
  typeAheadAction?: (val: string) => void;
  setDefaultTitle?: (value: any) => void;
  isTemplateSupport?: boolean;
  supportedTemplateList?: any;
  defaultTemplateId?: TemplateTypes | string;
  prepareActivityBodyByTemplateID?: (
    templateID: string | null,
    setEditorState: React.Dispatch<React.SetStateAction<string>>,
    lang: string,
    cb: any
  ) => void;
  clickDisabled?: boolean;
  disableSecondaryAction?: boolean;
  loading?: boolean;
  fromTime?: number;
  toTime?: number;
  setToTime?: Dispatch<SetStateAction<number>>;
  defaultAttachmentsList?: AttachmentItem[];
  refs?: {
    toRef?: React.MutableRefObject<ActivityToRef>;
    popupRef?: React.MutableRefObject<ActivityPopupRef>;
  };
  enableKeyboardShortcuts?: boolean | undefined;
  getDefaultTemplateId?: (newActivityType: ActivityOptions | string) => TemplateTypes | string;
  isSendButtonDisabled?: boolean;
  suggestedTemplatesCode?: string[];
  tempSubjectSnippetValues?: TempSubjectSnippetValuesType;
}

export default function NewActivityPopup(props: NewActivityPopupProps): React.ReactElement {
  const { t } = useTranslation();
  const sendText = t("activityPopupEditor.buttons.labels.send", { ns: "activities" });
  const sendAndCloseText = t("activityPopupEditor.buttons.labels.sendAndClose", { ns: "activities" });
  const replyText = t("activityPopupEditor.buttons.labels.reply", { ns: "activities" });
  const replyAllText = t("activityPopupEditor.buttons.labels.replyAll", { ns: "activities" });
  const saveText = t("activityPopupEditor.buttons.labels.save", { ns: "activities" });
  const saveAndCloseText = t("activityPopupEditor.buttons.labels.saveAndClose", { ns: "activities" });
  const emptySubject = t("activityPopupEditor.inputField.validationMessages.subjectMissing", { ns: "activities" });
  const invalidEmail = t("activityPopupEditor.inputField.validationMessages.invalidEmail", { ns: "activities" });
  const unsupportedFileToastMessage = t("toastMessages.dragAndDropAttachment.unsupportFileType", { ns: "activities" });
  const newActivityTypings = new Map();
  newActivityTypings.set("Reply", props?.replyOptions?.reply);
  newActivityTypings.set("Reply All", props?.replyOptions?.replyAll);
  const replyButtonStateMappings = new Map();
  replyButtonStateMappings.set("Reply", replyAllText);
  replyButtonStateMappings.set("Reply All", replyText);
  const [to, setTo] = React.useState<To[]>(props.defaultTo ?? []);
  const [cc, setCc] = React.useState<To[]>(props.defaultCc ?? []);
  const [bcc, setBcc] = React.useState<To[]>(props.defaultBcc ?? []);
  const [title, setTitle] = React.useState<string>(props.defaultTitle ?? "");
  const [editorState, setEditorState] = React.useState<string>(props?.defaultBody || props.editorState || "");
  const [ccVisible, setCcVisible] = useState<boolean>(props.defaultCc && props.defaultCc.length ? true : false);
  const [bccVisible, setBccVisible] = useState<boolean>(props.defaultBcc && props.defaultBcc.length ? true : false);
  const [defaultPrimaryActionLabel, setDefaultPrimaryActionLabel] = React.useState<string>(sendText);
  const [defaultSecondaryActionLabel, setDefaultSecondaryActionLabel] = React.useState<string>(sendAndCloseText);
  const [dropdownMenuAnchorEl, setDropdownMenuAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const dropdownMenuOpen = Boolean(dropdownMenuAnchorEl);
  const [selectedTemplateID, setSelectedTemplateID] = useState<string>("");
  const [toOption, setToOption] = useState<string>(replyButtonStateMappings.get(props?.replyOptions?.start) ?? "");
  const [subjectInputValue, setSubjectInputValue] = useState<string>("");

  const [contentHasMagicLink, setContentHasMagicLink] = React.useState<boolean>(false);
  const [mentionMembers, setMentionMembers] = React.useState<MentionMembersType>({ loading: false, members: [] });
  const mentionsAvailableFor = Object.freeze(["note", "phone_call", "call_log"]);
  const { workspaceMembers, fetchingWorkspaceMembers } = useWorkspaceConfigurations();
  const { signature } = React.useContext(CustomerContext) as CustomerType;
  const { isEnabled } = React.useContext(FeatureFlagContext) as FeatureFlagProviderType;
  const { defaultSelectedLangaugeForTemplateTabs, availableLanguagesForTemplates } = useLocale();
  const [currentSelectedTabLang, setCurrentSelectedTabLang] = React.useState<string>(defaultSelectedLangaugeForTemplateTabs);
  const location = useLocation();

  const [showDiscardDraftModal, setShowDiscardDraftModal] = React.useState<boolean>(false);
  const [isDraftDirty, setIsDraftDirty] = React.useState<boolean>(false);
  const acceptedFileTypes: string[] = [".jpg", ".xls", ".pdf", ".docx", ".xlsx", ".csv", ".txt", ".jfif"];
  const { droppedFiles, handleFilesDragOver, handleFilesDrop } = useDragAndDropFiles(acceptedFileTypes, unsupportedFileToastMessage);
  const [selectedLangCode, setSelectedLangCode] = React.useState<string>("");
  const { suggestedEmailTemplateList, templateTabList, allEmailTemplateList, tempalteLanguageDropdownList, getSelectedTemplateSubject } =
    useProcessTemplateData(
      [
        "AP_APPROVAL_REQUEST",
        "AR_APPROVAL_REQUEST",
        "AP_VENDOR_PROFILE_REQUEST",
        "AP_SHARE_PROFILE",
        "AR_SHARE_PROFILE",
        "AR_CUSTOMER_PROFILE_REQUEST",
      ],
      props.suggestedTemplatesCode,
      selectedLangCode,
      props.tempSubjectSnippetValues
    );
  const [templateList, setTemplateList] = React.useState<TemplateListItemType[]>([]);
  const [selectedTabKey, setSelectedTabKey] = React.useState<string>("");
  const [showValidationSubject, setShowValidationSubject] = React.useState<boolean>(false);
  const [showEmptyToValidation, setShowEmptyToValidation] = React.useState<boolean>(false);

  /**
   * Flag for Keyboard Shortcut
   */
  const isKeyboardShortcutFlagEnabled = isEnabled("KEYBOARD_SHORTCUTS");
  const isTemplateWorkflowV2Enabled = isEnabled("TEMPLATE_WORKKFLOW_V2");

  /**
   * HOC Hook for Keyboard ShortCut UI
   */
  const { actionToPerform, setActionDone } = useKeyboardShortcut(
    ActivityEmailBodyActionShortcuts,
    !props.enableKeyboardShortcuts && !isKeyboardShortcutFlagEnabled,
    !props.open
  );

  const { setDisableKeyboardShortcut } = React.useContext(DisableFeatureContext) as DisableFeatureProviderType;
  const { add, remove, disableValidationBanner, attachmentConfigs, visibility, inboxAttachmentPayload, allAttachmentIds, removeAllAttachedFiles } =
    useWindowAttachmentManager(props?.defaultAttachmentsList ?? []);

  /**
   * @function checkAndAddFiles
   * @param attachments : TemplateAttachDetail[]
   * @param unsupportedAttachments list of unsupported template attachments
   * This function is responsible for attaching invoices/payments/documents into the editor.
   * Which will be for viewing of file in the editor and setting up corresponding Id's
   * Note: not responsible to prepare payload for API
   *
   * if haveInvalidAttachments = true > triggers attachment-validation with inline textbox
   */
  const checkAndAddTemplateAttachments = (attachments: ERPAttachment[]) => {
    add(attachments, AttachmentTypes.TemplateProp, true);
  };

  /**
   * This function takes in array of items selected from the new
   * attachment modal and attaches them to the activity pop-up
   *
   * @param selection files selected from the new attachment modal
   */
  const addCompanyAttachments = (selection: InboxAttachment[]) => {
    // invoices and payments are attached as inbox attachments
    add(
      selection
        .filter((item) => item.transaction_type !== VIEW.DOCUMENTS)
        .map((item) => ({
          transactionId: item.transaction_id,
          transactionType: item.transaction_type.toLocaleLowerCase(),
          transactionTypeCode: item.transaction_type_code,
          attachmentTitle: item.attachment_title,
        })),
      AttachmentTypes.InboxDrive
    );
    // documents are stored as normal attachments as they have
    add(
      selection
        .filter((item) => item.transaction_type === VIEW.DOCUMENTS)
        .map((item) => ({
          name: item.attachment_title,
          id: item.transaction_id,
        })),
      AttachmentTypes.PreviousUpload
    );
    return;
  };

  const setActivityTitle = (value: string) => {
    setTitle(DOMPurify.sanitize(value));
    if (!_.isEmpty(value)) {
      setShowValidationSubject(false);
    }
  };

  /**
   * This function toggles cc, bcc based on the action
   * selected by the user. It requires replyOptions
   * props to be able to understand what action is performed.
   *
   * Applicable actions: reply, reply-all and forward
   */
  const toggleCC = () => {
    if (props?.replyOptions) {
      if (toOption === replyText) {
        // set reply all and cc
        setCc(props?.replyOptions?.replyAllCC ?? []);
        setTo(props?.replyOptions?.replyAll ?? []);
        setCcVisible(true);
      } else {
        // reset reply and cc
        setCc([]);
        setBcc([]);
        setTo(props?.replyOptions?.reply ?? []);
        setCcVisible(false);
        setBccVisible(false);
      }
    }
  };

  const handleReplyButtonClick = () => {
    setTo(newActivityTypings.get(toOption) ?? []);
    setToOption(replyButtonStateMappings.get(toOption) ?? "");
    if (toOption === replyAllText) {
      setCcVisible(true);
      setCc(props?.replyOptions?.replyAllCC ?? []);
      setTo(props?.replyOptions?.replyAll ?? []);
    } else {
      setCc([]);
      setBcc([]);
      setCcVisible(false);
      setBccVisible(false);
    }
  };

  const handleSetTo = (e: React.SyntheticEvent, value: To[]) => {
    value.forEach((val: string | To, index, value) => {
      if (typeof val === "string") {
        value[index] = { id: "", label: val.replace(/\s+/g, "") };
      }
    });
    setTo(value);

    // trigger component to rerender the template
    if (props.refs?.toRef) {
      props.refs?.toRef?.current?.onSelectTo(value);
    }
  };

  const handleSetCc = (e: React.SyntheticEvent, value: To[]) => {
    value.forEach((val: string | To, index, value) => {
      if (typeof val === "string") {
        value[index] = { id: "", label: val.replace(/\s+/g, "") };
      }
    });
    setCc(value);
  };

  const handleSetBcc = (e: React.SyntheticEvent, value: To[]) => {
    value.forEach((val: string | To, index, value) => {
      if (typeof val === "string") {
        value[index] = { id: "", label: val.replace(/\s+/g, "") };
      }
    });
    setBcc(value);
  };

  /**
   * function uploads single/multiple files to the server and updates the attachmentIds
   *
   * @param newFiles list of files to upload and attach with activity stream
   */
  const handleFileSelect = async (newFiles: FileList) => {
    await add(newFiles, AttachmentTypes.Upload);
  };

  const handleFileRemove = (index: number) => {
    remove(index);
  };

  const handleCcVisibility = () => {
    setCcVisible(true);
  };

  const handleBccVisibility = () => {
    setBccVisible(true);
  };

  const typeAheadActionHandler = (val: string) => {
    if (props.typeAheadAction) {
      props.typeAheadAction(val);
    }
  };

  /**
   * @ mention tag replacer
   * @returns editorState with body removing move markers and replaces all tags with backend friendly format [@John K](mention://user/11/John%20K)
   */
  const replaceTagsWithMentions = () => {
    if (props.activityType === "phone_call" || props.activityType === "call_log" || props.activityType === "note") {
      let newState = editorState;
      let allMentions: Array<MentionsDataItemType> = [];
      const listOfMentions = newState.match(MENTION_REGEX_CONSTANTS.REGEX_REPLACE_MENTION);
      if (listOfMentions) {
        const listOfAllMentions = listOfMentions.map((item) =>
          item.replace(`<span><span style="color:#2D9DE7;text-indent:2rem;">@`, "").replace(`</span>&nbsp;</span>`, "")
        );
        allMentions = mentionMembers.members.filter((item) => listOfAllMentions?.indexOf(item.name ?? "") !== DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE);
      }
      allMentions.forEach((item) => {
        newState = newState.replaceAll(
          `<span><span style="color:#2D9DE7;text-indent:2rem;">@${item.name}</span>&nbsp;</span>`,
          `[@${item.name}](mention://user/${item.id}/${encodeURIComponent(item.name ?? "")}) `
        );
      });
      return newState.replaceAll(`<span id="move-marker-here"></span>`, "");
    }
    return editorState.replaceAll(`<span id="move-marker-here"></span>`, "");
  };

  const isCcApplicable = props.activityType !== "phone_call" && props.activityType !== "approval_request";
  const isBccApplicable = props.activityType !== "phone_call" && props.activityType !== "approval_request";

  const closePopUp = () => {
    setCcVisible(false);
    setBccVisible(false);
    props?.setToTime?.(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    if (props?.onClose) {
      props.onClose();
    }
    setSelectedTemplateID("");
  };

  /*
   * Actions for Keyboard Shortcuts
   */
  const keyboardActions: { [key: string]: any } = useMemo(
    () => ({
      [KeyboardActionsConstants.cancel_draft]: () => {
        if (isDraftDirty) {
          setShowDiscardDraftModal(true);
        } else {
          closePopUp();
        }
      },
      [KeyboardActionsConstants.send_action]: () => {
        if (props?.handleSend) {
          props.handleSend(to, cc, bcc, title, replaceTagsWithMentions(), allAttachmentIds, inboxAttachmentPayload);
        }
      },
      [KeyboardActionsConstants.send_close_action]: () => {
        if (props?.handleSendMarkClosed) {
          props.handleSendMarkClosed(to, cc, bcc, title, replaceTagsWithMentions(), allAttachmentIds, inboxAttachmentPayload);
        }
      },
    }),
    [props.handleSend, props.handleSendMarkClosed, to, cc, bcc, title, replaceTagsWithMentions, allAttachmentIds, inboxAttachmentPayload]
  );

  const shortcutDisplayMapper: { [key: string]: string } = useMemo(() => {
    if (isKeyboardShortcutFlagEnabled && props.enableKeyboardShortcuts) {
      const mapper: { [key: string]: string } = {};
      ActivityEmailBodyActionShortcuts.forEach((item) => {
        if (item.identifier) mapper[item.identifier] = item.displayText;
      });
      return mapper;
    }
    return {};
  }, [props.enableKeyboardShortcuts, isKeyboardShortcutFlagEnabled]);

  /**
   * @function isMagicLinkSupported
   * A helper function to check if the current popup origin supports magic link insertion.
   * @returns boolean
   */
  const isMagicLinkSupported = () => {
    if (!isEnabled("PORTAL_LINK")) return false;
    const validRoutes = ["invoices", "bills", "payments", "vendors", "customers"];
    return validRoutes.some((route) => location.pathname.includes(route));
  };

  const sanitizeTitle = () => {
    const selectedActivity = props?.addActivityDropdown?.find((item) => item.activityType.toLowerCase().split(" ").join("_") === props.activityType);

    if (props.activityType === ActivityOptions.REQUEST_CUSTOMER_PROFILE) {
      const requestCustomerProfile = t("addNewActivity.dropdownList.requestCustomerProfile", { ns: "activities" });
      return requestCustomerProfile?.replace("Customer ", "");
    } else {
      return selectedActivity?.displayName;
    }
  };

  const getDisplayName = (val: string) => {
    if (val === APARStances.REQUEST_CUSTOMER_PROFILE) return val.replace("Customer ", "");
    return val;
  };

  //Event handlers
  const onChangeTabs = (event: React.ChangeEvent<object>, newValue: string) => {
    setCurrentSelectedTabLang(newValue);
    setSelectedTemplateID("");
  };

  const onChangeTemplateListItem = (event: SelectChangeEvent) => {
    //on change of template previous attached files should get removed
    removeAllAttachedFiles();
    setSelectedTemplateID?.(event?.target?.value);

    const selectedItem = props.supportedTemplateList.get(currentSelectedTabLang)?.filter((item: TemplateType) => event?.target?.value === item?.id);

    setActivityTitle(selectedItem?.length > NUMERIC_VALUES.CONSTANT_ZERO ? selectedItem[NUMERIC_VALUES.CONSTANT_ZERO]?.displayText : "");

    setSubjectInputValue(selectedItem?.length > NUMERIC_VALUES.CONSTANT_ZERO ? selectedItem[NUMERIC_VALUES.CONSTANT_ZERO]?.displayText : "");
  };

  const onChangeSubjectInputText = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSubjectInputValue(event?.target?.value);
    setActivityTitle(event?.target?.value);
  };

  React.useEffect(() => {
    if (["phone_call", "note"].includes(props.activityType ?? "")) {
      setDefaultPrimaryActionLabel(saveText);
      setDefaultSecondaryActionLabel(saveAndCloseText);
      props.setToTime?.(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    } else {
      setDefaultPrimaryActionLabel(sendText);
      setDefaultSecondaryActionLabel(sendAndCloseText);
    }
  }, [props.activityType]);

  useEffect(() => {
    // hide activity validation text box if template is changed
    disableValidationBanner();
    // Update the email body when user has selected template from subject dropdown.
    if (props.prepareActivityBodyByTemplateID && !_.isEmpty(selectedTemplateID)) {
      props.prepareActivityBodyByTemplateID(selectedTemplateID, setEditorState, currentSelectedTabLang, checkAndAddTemplateAttachments);
    }
  }, [selectedTemplateID]);

  /**
   * attach event hooks for activity pop up ref
   */
  React.useImperativeHandle(props.refs?.popupRef, () => ({
    refreshPopupContent() {
      if (props.prepareActivityBodyByTemplateID && !_.isEmpty(selectedTemplateID)) {
        props.prepareActivityBodyByTemplateID?.(selectedTemplateID ?? "", setEditorState, currentSelectedTabLang, checkAndAddTemplateAttachments);
      }
    },
  }));

  /**
   * checks the editor state on every update for magic link snippet
   * if snippet is not matched, editor enables the 'Insert Magic Link'
   * button on the activity popup actions
   */
  useEffect(() => {
    setContentHasMagicLink(editorState?.includes(constants.MAGIC_LINK_SNIPPET_BUTTON));
  }, [editorState]);

  /**
   * Use Effect Calls
   */
  useEffect(() => {
    if (
      isKeyboardShortcutFlagEnabled &&
      props.enableKeyboardShortcuts &&
      actionToPerform &&
      keyboardActions[actionToPerform] &&
      typeof keyboardActions[actionToPerform] === "function"
    ) {
      keyboardActions[actionToPerform]();
      setActionDone();
    }
  }, [actionToPerform, props.enableKeyboardShortcuts, isKeyboardShortcutFlagEnabled]);

  useEffect(() => {
    if (props.open) {
      setDisableKeyboardShortcut(true);
    } else {
      setDisableKeyboardShortcut(false);
    }
    return () => setDisableKeyboardShortcut(false);
  }, [props.open, props.editorState]);

  /**
   * Setup user's signature into activity popup body
   * If activity type is email or approval request and has no template selected
   * then simply append the signature from user context at once.
   */
  const appendSignatureToEditorState = (): string => {
    if (!_.isEmpty(signature) && props?.forwarding) {
      return `${editorState}<div></div>${signature.email_signature ?? ""}`;
    } else if (
      !_.isEmpty(signature) &&
      editorState.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO &&
      ["email", "approval_request"].includes(props.activityType ?? "")
    ) {
      return `${editorState}<div></div>${signature.email_signature ?? ""}`;
    }
    return editorState;
  };
  /* This gets invoked whenever there is any changes in defaultTemplateId and template list
   * If the defaultTemplateId is empty then set the editor state to default else as per
   * the default templateId
   */
  useEffect(() => {
    if (
      (!_.isEmpty(props.defaultTemplateId) || !_.isUndefined(props?.getDefaultTemplateId)) &&
      !_.isEmpty(defaultSelectedLangaugeForTemplateTabs) &&
      props.supportedTemplateList?.size &&
      (props.activityType === ActivityOptions.APPROVAL_REQUEST || props.defaultTemplateId === ActivityOptions.APPROVAL_REQUEST)
    ) {
      const defaultId = !_.isUndefined(props.getDefaultTemplateId)
        ? props?.getDefaultTemplateId(ActivityOptions.APPROVAL_REQUEST)
        : props.defaultTemplateId ?? "";
      const selectedItem = props.supportedTemplateList.get(defaultSelectedLangaugeForTemplateTabs)?.find((item: any) => item.id === defaultId);
      setActivityTitle(selectedItem?.displayText ?? "");
      setSubjectInputValue(selectedItem?.displayText ?? "");
      setSelectedTemplateID(defaultId);
    } else {
      setEditorState(appendSignatureToEditorState());
      setSubjectInputValue("");
    }
  }, [props.defaultTemplateId, props.supportedTemplateList?.size, defaultSelectedLangaugeForTemplateTabs]);

  useEffect(() => {
    toggleCC();
  }, [toOption]);

  useEffect(() => {
    if (fetchingWorkspaceMembers) {
      setMentionMembers({ loading: true, members: [] });
    } else if (!fetchingWorkspaceMembers && !_.isEmpty(workspaceMembers) && !_.isUndefined(workspaceMembers)) {
      setMentionMembers({ loading: false, members: workspaceMembers as unknown as MentionsDataItemType[] });
    } else {
      setMentionMembers({ loading: false, members: [] });
    }
  }, [workspaceMembers]);

  useEffect(() => {
    if (!_.isEmpty(defaultSelectedLangaugeForTemplateTabs)) {
      setCurrentSelectedTabLang(defaultSelectedLangaugeForTemplateTabs);
    }
  }, [defaultSelectedLangaugeForTemplateTabs]);

  //filter tab list of enable languages for which templates are available
  const tabs = availableLanguagesForTemplates.filter(
    (item) => props.supportedTemplateList?.size >= NUMERIC_VALUES.CONSTANT_ZERO && props.supportedTemplateList?.has(item.id) && item.isChecked
  );

  const toLabel = t("activityPopupEditor.inputField.labels.to", { ns: "activities" });
  const ccLabel = t("activityPopupEditor.inputField.labels.cc", { ns: "activities" });
  const bccLabel = t("activityPopupEditor.inputField.labels.bcc", { ns: "activities" });
  const subjectPlaceholder = t("activityPopupEditor.inputField.placeholderText.subject", { ns: "activities" });

  /**
   * @function validateIfDraftDirty
   * A helper function check if activity draft has any change made to it
   */
  const validateIfDraftDirty = (stateString?: string) => {
    if (
      to?.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO &&
      cc?.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO &&
      bcc?.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO &&
      subjectInputValue === "" &&
      title === "" &&
      (!stateString || stateString === "<div><br></div>" || stateString === "" || stateString === undefined)
    ) {
      setIsDraftDirty(false);
    } else {
      setIsDraftDirty(true);
    }
  };

  //prepare templateList on tab change
  const onChangeTemplateTabs = (tabsId: number) => {
    setSelectedTabKey(templateTabList?.[tabsId]?.tabKey);
    setTemplateList(templateTabList?.[tabsId]?.tabKey === "suggested" ? suggestedEmailTemplateList : allEmailTemplateList);
  };

  //prepare templateList on tab change
  const onChangeTemplateLanguage = (langCode: string) => {
    setSelectedLangCode(langCode);
  };

  //apply the template to the editior [subject and body]
  const onApplySelectedTemplate = (templateID: string) => {
    removeAllAttachedFiles();
    if (props.prepareActivityBodyByTemplateID) {
      setActivityTitle(getSelectedTemplateSubject(templateID));
      props.prepareActivityBodyByTemplateID(
        templateID,
        setEditorState,
        !_.isEmpty(selectedLangCode) ? selectedLangCode : (tempalteLanguageDropdownList[0]?.id as string),
        checkAndAddTemplateAttachments
      );
    }
  };

  //all the required templateworkflow props
  const getTemplateWorkflowProps = () => {
    return {
      // to enable and disable email template option in editor toolbar
      isTemplateFlowRequired:
        isTemplateWorkflowV2Enabled &&
        props.isTemplateSupport &&
        [
          ActivityOptions.EMAIL,
          ActivityOptions.APPROVAL_REQUEST,
          ActivityOptions.ONBOARD_VENDOR,
          ActivityOptions.SHARE_AP_PROFILE,
          ActivityOptions.SHARE_AR_PROFILE,
          ActivityOptions.REQUEST_CUSTOMER_PROFILE,
        ].includes(props.activityType as ActivityOptions),

      //list of enabled langauge for template
      languageDropdownList: tempalteLanguageDropdownList,
      //to display count along with tab name
      isCountRequiredWithTabs: true,
      /**
       * For email both listview and preview card required , but for other activity
       * like approval,share profile etc activity  only preview card required
       */
      isTemplateListViewRequired: props.activityType === ActivityOptions.EMAIL,
      isTemplatePreviewRequired: true,
      // to display api failed or data not found message
      hasTemplateApiFailedOrNoDataFound:
        props.activityType !== ActivityOptions.EMAIL
          ? suggestedEmailTemplateList?.length <= NUMERIC_VALUES.CONSTANT_ZERO
          : templateList?.length <= NUMERIC_VALUES.CONSTANT_ZERO,
      // to display search component
      isTemplateSearchRequired: true,
      // to display tab component
      isTemplateTabRequired: true,
      // to display langauage dropdown option on listview card header
      isTempListCardLangDrpdwnRequired: true,
      // to display langauage dropdown option on preview card header
      isTempPreviewLangDrpdwnRequired: props.activityType !== ActivityOptions.EMAIL,
      //All template list based on selected tabs[Suggested and All]
      templateList,
      // all the intial tabs
      templateTabList,
      // to handle logic on tab change,template language change,and on template apply
      onChangeTemplateTabs,
      onChangeTemplateLanguage,
      onApplySelectedTemplate,
    };
  };

  /**
   *
   * @param recipients
   * @returns a boolean if true means it contains invalid email(s)
   */
  const verifyRecipients = (recipients: To[]) => {
    const filteredRecipients = recipients.filter((item) => !_.isEmpty(item));
    return (
      filteredRecipients?.filter((item) => {
        return !Utils.validEmail(_.isEmpty(item?.id) ? item?.label : item?.id);
      }).length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
    );
  };

  /**
   * @function isValidActivityForm
   * A helper function to validate activity create form
   * @returns boolean
   */
  const isValidActivityForm = () => {
    const validatedTo = to.filter((item) => !_.isEmpty(item));

    if (_.isEmpty(title) || validatedTo?.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      if (_.isEmpty(title)) {
        setShowValidationSubject(true);
      }
      if (validatedTo?.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && props?.activityType === ActivityType.Note) {
        return true;
      }
      if (validatedTo?.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
        setShowEmptyToValidation(true);
      }
      return false;
    }
    if (verifyRecipients(validatedTo)) {
      return false;
    } else if (!_.isEmpty(cc) && verifyRecipients(cc)) {
      return false;
    } else if (!_.isEmpty(bcc) && verifyRecipients(bcc)) {
      return false;
    }
    return true;
  };

  /**
   *
   * @param _to
   * @returns a formatter funtion that helps to add the invalid email message to recipients in chips
   */
  const validateRecipientEmails = (_to: To[]) => {
    return _to.map((item) => {
      if (_.isEmpty(item?.id)) {
        return { ...item, label: Utils.validEmail(item?.label) ? item?.label : item?.label + invalidEmail };
      } else {
        return { ...item, id: Utils.validEmail(item?.id) ? item?.id : item?.id + invalidEmail };
      }
    });
  };

  //set the templatelist based on selected tab
  useEffect(() => {
    setTemplateList(
      _.isEmpty(suggestedEmailTemplateList) || (selectedTabKey !== "suggested" && !_.isEmpty(selectedTabKey))
        ? allEmailTemplateList
        : suggestedEmailTemplateList
    );
  }, [suggestedEmailTemplateList, allEmailTemplateList, selectedLangCode]);

  /**
   * Side-effect to check and set activity draft as dirty if user has made changes
   */
  useEffect(() => {
    validateIfDraftDirty();
  }, [to, cc, bcc, subjectInputValue, title]);

  /**
   * Side-effect to check and set activity draft as dirty if user
   * has made changes - in case if signature is present
   */
  useEffect(() => {
    if (editorState === "" || editorState === '"<div><br></div>"') {
      setIsDraftDirty(false);
    }
    if (!_.isEmpty(signature)) {
      const dirtyText = editorState.replace(signature.email_signature, "");
      if (dirtyText === "<div></div>") {
        setIsDraftDirty(false);
      } else {
        setIsDraftDirty(true);
      }
    }
  }, [editorState, showEmptyToValidation, showValidationSubject]);

  React.useEffect(() => {
    if (droppedFiles && droppedFiles.length > NUMERIC_VALUES.CONSTANT_ZERO) {
      handleFileSelect(droppedFiles);
    }
  }, [droppedFiles]);

  return (
    <>
      <div className={`na-wrapper${props.open ? "" : "-closed"}`}>
        <div className={"na-header"}>
          <p className={"na-header-title-text"}>{props.title}</p>
          <div
            className={`na-header-close-wrapper`}
            onClick={() => {
              if (isDraftDirty) {
                setShowDiscardDraftModal(true);
              } else {
                closePopUp();
              }
            }}
          >
            <CloseIcon />
          </div>
        </div>
        {props.loading ? (
          <div className={"na-body loading"}>
            <Loading />
          </div>
        ) : (
          <>
            <div className={"na-body"}>
              {props?.addActivityDropdown?.length ? (
                <div className={"na-body-to na-body-child"}>
                  <div className={`na-body-activity-type-line ${props.clickDisabled ? "disabled" : ""}`}>
                    <DropMenu
                      key={`activity-type-dropdown-${props?.addActivityDropdown?.length}`}
                      title={sanitizeTitle() ?? ""}
                      isActive={dropdownMenuOpen}
                      onClick={(e: MouseEvent<HTMLDivElement>) => (props.clickDisabled ? null : setDropdownMenuAnchorEl(e.currentTarget))}
                      iconAlign="right"
                      appendIcon={
                        props.addActivityDropdown && props.addActivityDropdown.length ? (
                          props.addActivityDropdown.filter(
                            (item: ActivityDropdownItem) => item.displayName.toLowerCase().split(" ").join("_") === props.activityType
                          )[0]?.icon
                        ) : (
                          <></>
                        )
                      }
                    />
                    <Menu
                      open={dropdownMenuOpen}
                      anchorEl={dropdownMenuAnchorEl}
                      onClose={() => setDropdownMenuAnchorEl(null)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      className="activity-types-list-menu"
                    >
                      {props?.addActivityDropdown?.length
                        ? props?.addActivityDropdown.map((tab: ActivityDropdownItem, index: number) => {
                            if (!tab.disabled) {
                              return (
                                <MenuItem
                                  key={index}
                                  onClick={() => {
                                    if (props?.setNewActivityType) {
                                      props?.setNewActivityType(tab.activityType.toLowerCase().split(" ").join("_"));
                                      setDropdownMenuAnchorEl(null);
                                    }
                                  }}
                                >
                                  <div style={{ display: "flex", alignItems: "center" }}>
                                    <div className="icon">{tab.icon}</div>
                                    <div>{getDisplayName(tab.displayName)}</div>
                                  </div>
                                </MenuItem>
                              );
                            }
                          })
                        : null}
                    </Menu>
                  </div>
                </div>
              ) : null}
              {props.activityType !== "note" ? (
                <div className={"na-body-to na-body-child"}>
                  <div className={`na-body-toline ${showEmptyToValidation || verifyRecipients(to) ? "error" : ""}`}>
                    <div className={"na-body-to-To"}>
                      <p>{toLabel}</p>
                    </div>
                    <ActivityTo
                      options={props?.contactOptions && props?.contactOptions.length ? props?.contactOptions : []}
                      to={to}
                      setTo={handleSetTo}
                      typeAheadAction={typeAheadActionHandler}
                      restrictOneContact={
                        props.activityType === ActivityOptions.APPROVAL_REQUEST &&
                        to.filter((item) => !_.isEmpty(item)).length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
                      }
                      showEmptyToValidation={showEmptyToValidation}
                      setShowEmptyToValidation={setShowEmptyToValidation}
                      validateRecipientEmails={validateRecipientEmails}
                    />
                    <div className={"na-body-to-extra-recipients"}>
                      {isCcApplicable && !ccVisible ? (
                        <div className={"na-body-to-extra-cc"} onClick={handleCcVisibility}>
                          <p>{ccLabel}</p>
                        </div>
                      ) : null}
                      {isBccApplicable && !bccVisible ? (
                        <div className={"na-body-to-extra-bcc"} onClick={handleBccVisibility}>
                          <p>{bccLabel}</p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {props?.replyOptions?.replyButton && (
                    <div className={"na-body-toline-reply"}>
                      <Button icon={<Reply />} onClick={handleReplyButtonClick} alignIcon={"right"} variant={"secondary"}>
                        {toOption}
                      </Button>
                    </div>
                  )}
                </div>
              ) : null}

              {ccVisible && (
                <div className={"na-body-to na-body-child"}>
                  <div className={`na-body-ccline ${!_.isEmpty(cc) && verifyRecipients(cc) ? "error" : ""}`}>
                    <div className={"na-body-to-Cc"}>
                      <p>{ccLabel}</p>
                    </div>
                    <ActivityTo
                      options={props?.contactOptions && props?.contactOptions.length ? props?.contactOptions : []}
                      to={cc}
                      setTo={handleSetCc}
                      typeAheadAction={typeAheadActionHandler}
                      validateRecipientEmails={validateRecipientEmails}
                    />
                  </div>
                </div>
              )}

              {bccVisible && (
                <div className={"na-body-to na-body-child"}>
                  <div className={`na-body-bccline ${!_.isEmpty(bcc) && verifyRecipients(bcc) ? "error" : ""}`}>
                    <div className={"na-body-to-Bcc"}>
                      <p>{bccLabel}</p>
                    </div>
                    <ActivityTo
                      options={props?.contactOptions && props?.contactOptions.length ? props?.contactOptions : []}
                      to={bcc}
                      setTo={handleSetBcc}
                      typeAheadAction={typeAheadActionHandler}
                      validateRecipientEmails={validateRecipientEmails}
                    />
                  </div>
                </div>
              )}

              {(props.activityType === ActivityOptions.EMAIL ||
                props.activityType === ActivityOptions.APPROVAL_REQUEST ||
                props.activityType === ActivityOptions.ONBOARD_VENDOR ||
                props.activityType === ActivityOptions.SHARE_AP_PROFILE ||
                props.activityType === ActivityOptions.SHARE_AR_PROFILE ||
                props.activityType === ActivityOptions.REQUEST_CUSTOMER_PROFILE) &&
              props.isTemplateSupport &&
              props.supportedTemplateList?.size > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO &&
              !isTemplateWorkflowV2Enabled ? (
                <div className={"na-body-title na-body-child na-body-template-selector"}>
                  <TemplateSelectMenu
                    templateList={props.supportedTemplateList}
                    tabList={tabs}
                    isTabsRequired={tabs?.length > NUMERIC_VALUES.CONSTANT_ONE}
                    subjectInputValue={subjectInputValue}
                    currentSelectedTab={currentSelectedTabLang}
                    selectedTemplateItemId={selectedTemplateID}
                    onChangeTabs={onChangeTabs}
                    onChangeSubjectInputText={onChangeSubjectInputText}
                    onChangeTemplateListItem={onChangeTemplateListItem}
                  />
                </div>
              ) : (
                <div className={"na-body-title na-body-child"}>
                  <ActivityTitle
                    title={title ?? ""}
                    handleOnChange={setActivityTitle}
                    isDisabled={props.disableTitle}
                    placeHolderText={subjectPlaceholder}
                    showValidationMessage={showValidationSubject}
                    validationMessage={_.isEmpty(title) ? emptySubject : ""}
                    setShowValidationMessage={setShowValidationSubject}
                  />
                </div>
              )}
              <div className={"hyperlink-modal-container"}>{/* Insert hyperlink edit modal here*/}</div>
              <div className={"hyperlink-edit-modal-container"}>{/* Insert hyperlink edit modal here*/}</div>
              {visibility.uploading ? (
                <Loading />
              ) : (
                <div className={"na-body-message na-body-child"} onDragOver={handleFilesDragOver} onDrop={handleFilesDrop}>
                  <ActivityBody
                    key={`body${attachmentConfigs.length}`}
                    forwarding={props.forwarding}
                    editorState={editorState}
                    setEditorState={setEditorState}
                    handleFileSelect={handleFileSelect}
                    onClickAddAttachments={addCompanyAttachments}
                    handleFileRemove={handleFileRemove}
                    isCC={ccVisible}
                    isBCC={bccVisible}
                    to={to} // connection id of the first contact's in the to list
                    addMagicLinkSnippet={contentHasMagicLink}
                    enableMentions={mentionsAvailableFor.includes(props.activityType ?? "")}
                    mentionMembers={mentionMembers}
                    attachments={attachmentConfigs}
                    didRemoveUnsupported={visibility.didRemoveUnsupported}
                    onChange={validateIfDraftDirty}
                    templateWorkflowData={getTemplateWorkflowProps()}
                  />

                  <div className={"na-body-message-send"}>
                    <div className={"na-body-message-send-button"}>
                      {/* Temporary removal of time saving from Activity Pop up untill new revamp story is implemented 
                    {!_.isUndefined(props.toTime) &&
                      props.toTime > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO &&
                      (props.activityType === ActivityOptions.EMAIL ||
                        props.activityType === ActivityOptions.APPROVAL_REQUEST ||
                        props.activityType === ActivityOptions.ONBOARD_VENDOR ||
                        props.activityType === ActivityOptions.SHARE_AP_PROFILE ||
                        props.activityType === ActivityOptions.SHARE_AR_PROFILE) && (
                        <TimeIcon index={0} timeFrom={props.fromTime} timeTo={props.toTime} />
                      )} */}
                      {isMagicLinkSupported() && props.activityType === "email" && (
                        <Button
                          variant="solo-icon"
                          size="sm"
                          disabled={contentHasMagicLink}
                          onClick={() => setContentHasMagicLink(true)}
                          // tooltip={!to?.[0]?.erpContact ? "The Self Service portal not supported for email generated connections" : ""}
                        >
                          {"Insert Portal Link"}
                        </Button>
                      )}
                      {!props.disableSecondaryAction && (
                        <Button
                          tooltipType={isKeyboardShortcutFlagEnabled && props.enableKeyboardShortcuts ? TooltipTypes.SHORTCUT : TooltipTypes.PLAIN}
                          shortcutKeysText={
                            isKeyboardShortcutFlagEnabled && props.enableKeyboardShortcuts ? shortcutDisplayMapper?.[TooltipTitles.SEND_CLOSE] : ""
                          }
                          tooltip={isKeyboardShortcutFlagEnabled && props.enableKeyboardShortcuts ? sendAndCloseText : ""}
                          disabled={visibility.uploading || props.isSendButtonDisabled}
                          variant={"secondary"}
                          onClick={() => {
                            if (props.handleSendMarkClosed && isValidActivityForm()) {
                              props.handleSendMarkClosed(to, cc, bcc, title, replaceTagsWithMentions(), allAttachmentIds, inboxAttachmentPayload);
                            }
                          }}
                        >
                          {props?.buttonProps?.secondaryActionLabel ?? defaultSecondaryActionLabel}
                        </Button>
                      )}

                      <Button
                        tooltipType={isKeyboardShortcutFlagEnabled && props.enableKeyboardShortcuts ? TooltipTypes.SHORTCUT : TooltipTypes.PLAIN}
                        shortcutKeysText={
                          isKeyboardShortcutFlagEnabled && props.enableKeyboardShortcuts ? shortcutDisplayMapper?.[TooltipTitles.SEND] : ""
                        }
                        tooltip={isKeyboardShortcutFlagEnabled && props.enableKeyboardShortcuts ? sendText : ""}
                        variant={"primary"}
                        disabled={visibility.uploading || props.isSendButtonDisabled}
                        onClick={() => {
                          if (props?.handleSend && isValidActivityForm()) {
                            props.handleSend(to, cc, bcc, title, replaceTagsWithMentions(), allAttachmentIds, inboxAttachmentPayload);
                          }
                        }}
                      >
                        {props?.buttonProps?.primaryActionLabel ?? defaultPrimaryActionLabel}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <DiscardDraftModal
        showModal={showDiscardDraftModal}
        closeModal={() => setShowDiscardDraftModal(!showDiscardDraftModal)}
        onDiscard={() => closePopUp()}
      />
    </>
  );
}

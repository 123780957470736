import React from "react";
import Status from "../Status/Status";
import { Failure } from "../../library/Icons/Icons";
import Button from "../../library/Button/Button";
import { ApprovalActivityAccessor } from "../../../db/unauthAccessors";
import { useHistory } from "react-router";
import { ReactTimedOutProps } from "./LinkExpiryTypes";
import { NUMERIC_VALUES } from "../../../constants/NumericConstants";

const RequestTimedOut: React.FC<ReactTimedOutProps> = ({
  errorMsg,
  requestInfo: { approval_request_id, status, has_active_approval, expiry_duration = NUMERIC_VALUES.CONSTANT_THIRTY },
}) => {
  const history = useHistory();
  const [linkCreationInProgress, setLinkCreationProgress] = React.useState<boolean>(false);

  const actionTaken = status && !["active", "sent"].includes(status);
  /* 
  CTA will be available if
  1. there is active approval request on the activity stream.
  2. the previous request got expired and no action was taken by the 
  user i.e. reviewed/redirected/cancelled. 
  */
  const eligibleForNewRequest = !actionTaken && has_active_approval;

  /**
   * Status page will show status as reviewed/cancelled/redirected if link
   * was previously used by the user and has now reached a 60 days expiry
   * time. Otherwise, it will inform user the link expiry date.
   */
  const infoText = actionTaken
    ? `This approval request has already been ${status} hence the link is no longer valid.`
    : `Approval request links expire after ${expiry_duration} days. This link ${errorMsg}.`;

  /**
   * handler to generate a new link if link was not reviewed before and
   * reached it's expiry limit of 60 days.
   */
  const onClickCreateNewLink = async () => {
    setLinkCreationProgress(true);
    try {
      await ApprovalActivityAccessor.regenerateLink(approval_request_id);
      history.push("/link-regeneration-success");
    } catch (error) {
      history.push("/error");
    } finally {
      setLinkCreationProgress(false);
    }
  };

  return (
    <Status size="lg" logo={<Failure />} statusMessage={`This link has expired`} subText={infoText}>
      {eligibleForNewRequest && (
        <Button variant="primary" loading={linkCreationInProgress} onClick={onClickCreateNewLink}>
          Request New Link
        </Button>
      )}
    </Status>
  );
};

export default RequestTimedOut;

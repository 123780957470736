import React, { useState, useEffect } from "react";

import Detail from "../../../../library/Detail/Detail";
import { DataMode } from "../../../../../types/enums";
import { Drawer } from "@mui/material";
import { useHistory, useParams } from "react-router";
import { companiesClient, codeDefinitionsClient } from "../../../../../db/accessor";
import { contactsClientV2 } from "../../../../../db/version2Accessor";
import FourOFourError from "../../../../../routes/FourOFourError/FourOFourError";
import Loading from "../../../../library/Loading/Loading";
import Button from "../../../../library/Button/Button";
import { Email, Fax, LinkedIn, Location, Phone, Position } from "../../../../library/Icons/Icons";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { formatAddress } from "../../../../../db/utils/address";
import { countries } from "../../../../../Countries";
import NotesProvider from "../../../../../contexts/NoteContext";
import NoteStream from "../../../../library/NoteStream/NoteStream";
import Dialog from "../../../../library/Dialog/Dialog";
import DialogTitle from "../../../../library/DialogTitle/DialogTitle";
import DialogContent from "../../../../library/DialogContent/DialogContent";
import DialogContentText from "../../../../library/DialogContentText/DialogContentText";
import DialogActions from "../../../../library/DialogActions/DialogActions";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../../../../constants/NumericConstants";
import { ContactFormContainer } from "../ContactForm/ContactFormContainer";
import { Modal } from "../../../../library/DialogModal";
import { AlertContext } from "../../../../../contexts/AlertContext";
import { WorkspaceContext } from "../../../../../contexts/WorkspaceContext";
import { TooltipTypes } from "../../../../../constants/TooltipConstants";
import ContactDetailHeader from "./ContactDetailHeader";
import "./ContactDetail.scss";
import useContacts from "../../../../../hooks/useContacts";

export default function ContactProfile(): React.ReactElement {
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { getContactsOptions } = useContacts();

  const [dataMode, setDataMode] = React.useState<DataMode>(DataMode.View);
  const [contactData, setContactData] = useState<Partial<ContactItem>>({} as ContactItem);
  const [companyData, setCompanyData] = useState<CompanyModel>({} as CompanyModel);
  const [hasError, setHasError] = useState<boolean>(false);

  const [isLoadingRoleList, setIsLoadingRoleList] = useState<boolean>(true);
  const [roleList, setRoleList] = useState<CodeDefinitionModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { contactId, customerId } = useParams<{ contactId: string; customerId: string }>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const history = useHistory();
  const [contactEditData, setContactEditData] = useState<ContactDataType | null>(null);
  const [contactFlyout, setContactFlyout] = useState<boolean>(false);
  const [newPrimaryContact, setNewPrimaryContact] = React.useState<ContactDataType | null>(null);
  const [isContactAlreadyPrimary, setiIsContactAlreadyPrimary] = React.useState<boolean>(false);
  const [showPrimaryContactWarning, setShowPrimaryContactWarning] = React.useState<boolean>(false);
  const [showLoader, setShowLoader] = React.useState<boolean>(false);
  const [contactERPMessage, setContactERPMessage] = useState<string>("");
  const [contactPrimaryMessage, setContactPrimaryMessage] = useState<string>("");

  const handleDeleteContact = async () => {
    try {
      const response = await contactsClientV2.deleteContacts([contactId], selectedWorkspace?.id);

      if (response.success) {
        setToastOptions({
          severity: "success",
          open: true,
          message: "Contact Deleted",
        });
        setTimeout(() => history.goBack(), NUMERIC_VALUES.CONSTANT_FIVE_HUNDRED);
      }
    } catch (error: any) {
      setToastOptions({
        severity: "error",
        open: true,
        message: "Deletion Failed",
      });
    } finally {
      setShowDeleteModal(false);
    }
  };
  const transformTableData = (companyContacts: ContactModel[]): TableData[] => {
    const contactsTransformed = companyContacts.map((contact) => {
      return {
        id: contact.contactId,
        name: contact?.contactName,
        role: contact?.roleCode,
        email: contact?.emailAddress,
        appEnrollmentId: contact?.appEnrollmentId,
        isActive: contact.isActive,
      };
    });
    return contactsTransformed.filter((item) => item.isActive === true);
  };

  const tranformContactData = (
    contactId: UUID,
    contactName: string,
    contactRole: string,
    contactEmail: string,
    contactPhone: string,
    contactIsPrimary: boolean
  ): ContactDataType => ({
    contactId,
    contactName,
    contactRole,
    contactEmail,
    contactPhone,
    contactIsPrimary,
  });

  const editContacts = () => {
    setDataMode(DataMode.Edit);
    setContactEditData(
      tranformContactData(
        contactData?.contact_id ?? "",
        contactData?.contact_name ?? "",
        contactData?.role_code ?? "",
        contactData?.email_address ?? "",
        contactData?.phone ?? "",
        contactData?.is_primary ?? false
      )
    );
    setContactFlyout(true);
  };

  const markPrimaryContact = (data: ContactDataType) => {
    setNewPrimaryContact(data);
    setShowPrimaryContactWarning(true);
  };

  const assignContactMessage = (contact: Partial<ContactItem>) => {
    if (contact.app_enrollment_id) {
      setContactERPMessage("Contact created from your accounting system, you cannot edit/move/delete this contact");
      setContactPrimaryMessage("Contact created from your accounting system, you cannot edit/move/delete this contact");
    } else if (contact.is_primary) {
      setContactPrimaryMessage("Primary contact cannot be removed");
    }
  };

  const fetchData = async () => {
    try {
      const contactRes = await contactsClientV2.getContactDetails(contactId, selectedWorkspace.id ?? DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE);
      setContactData(contactRes.data);
      assignContactMessage(contactRes.data);
      const companyRes = await companiesClient.getCompany(contactRes?.data.company_id ?? "", "contacts");
      setCompanyData(companyRes);
      setIsLoadingRoleList(true);
      await codeDefinitionsClient
        .queryCodeDefinitions("CODETYPE EQ 'AccountingRole'", undefined, "codeDefinitionId")
        .then((data: CodeDefinitionModelFetchResult) => {
          setRoleList(data?.records ?? []);
        });
      setIsLoadingRoleList(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setHasError(true);
      console.error(error);
    }
    setIsLoading(false);
  };

  const handleMarkPrimaryContact = async () => {
    setShowLoader(true);
    let toastOptions: ToastOptions = {
      severity: "error",
      message: "Something Went Wrong",
      open: true,
    };
    if (newPrimaryContact) {
      try {
        await contactsClientV2.updateContacts(
          {
            items: [
              {
                contact_id: newPrimaryContact.contactId,
                is_primary: true,
              },
            ],
          },
          selectedWorkspace.id ?? DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE
        );
        toastOptions = {
          ...toastOptions,
          severity: "success",
          message: `${newPrimaryContact.contactName} is now the primary contact`,
        };
      } catch (error) {
        console.log("error:: ", error);
      } finally {
        setShowLoader(false);
        setToastOptions(toastOptions);
        // reset states
        setShowPrimaryContactWarning(false);
        setNewPrimaryContact(null);
        // refresh table
        getContactsOptions();
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (contactEditData?.contactIsPrimary) {
      setiIsContactAlreadyPrimary(true);
    }
  }, [contactEditData?.contactIsPrimary]);

  return hasError ? (
    <FourOFourError />
  ) : isLoading ? (
    <Loading />
  ) : (
    <>
      <div className="profile-detail">
        <ContactDetailHeader
          contactName={contactData.contact_name ?? "N/A"}
          isPrimary={contactData.is_primary ?? false}
          erpConnection={companyData.appEnrollmentId ? true : false}
          companyId={customerId ?? contactData?.company_id}
          loading={isLoading}
          companyName={companyData.companyName ?? "N/A"}
          modifiedDate={contactData.modified ?? ""}
        />
        <OverlayScrollbarsComponent options={{ paddingAbsolute: true, autoUpdate: true }}>
          <div className="container-contact container-split">
            <div className="left">
              <div className="btn-grp">
                <Button
                  disabled={contactData.app_enrollment_id ? true : false}
                  fullWidth={true}
                  tooltip={contactERPMessage}
                  tooltipType={TooltipTypes.RICH_CONDENSE}
                  variant="primary"
                  size="sm"
                  alignIcon={"left"}
                  onClick={() => editContacts()}
                >
                  Edit
                </Button>
                <Button
                  disabled={contactData.is_primary || contactData.app_enrollment_id ? true : false}
                  tooltip={contactPrimaryMessage}
                  tooltipType={TooltipTypes.RICH_CONDENSE}
                  fullWidth={true}
                  variant="secondary"
                  size="sm"
                  alignIcon={"left"}
                  onClick={() => setShowDeleteModal(true)}
                >
                  Delete
                </Button>
              </div>
              <div className="details-grp">
                {/* TODO: replace company name with the actual data once it is tracked by the contact API*/}
                <Detail
                  icon={<Position />}
                  columns={{
                    title: "Position",
                    value: `${
                      contactData?.title && contactData.role_code
                        ? `${contactData?.title} (${contactData?.role_code}), ${companyData.companyName}`
                        : contactData?.title
                        ? `${contactData.title}, ${companyData.companyName}`
                        : contactData?.role_code
                        ? `${contactData.role_code}, ${companyData.companyName}`
                        : `Employee, ${companyData.companyName}`
                    }`,
                  }}
                />
                <Detail icon={<Email />} columns={{ title: "Email", value: contactData?.email_address ?? "", format: "email" }} />
                <Detail icon={<Phone />} columns={{ title: "Phone", value: contactData?.phone ?? "", format: "tel" }} />
                <Detail icon={<Fax />} columns={{ title: "Fax", value: contactData?.fax ?? "", format: "tel" }} />
                <Detail
                  icon={<Location />}
                  columns={{
                    title: "Address",
                    value: formatAddress(
                      contactData?.address1 ?? "",
                      contactData?.address2 ?? "",
                      "",
                      contactData?.city ?? "",
                      contactData?.state_region ?? "",
                      contactData?.postal_code ?? "",
                      countries.filter((country) => {
                        return country.id === contactData?.country_code;
                      })[0]?.label ?? ""
                    ),
                  }}
                />
                <Detail icon={<LinkedIn />} columns={{ title: "LinkedIn", value: contactData?.webpage_url ?? "", format: "link" }} />
              </div>
            </div>
            <div className="right">
              <NotesProvider>
                <NoteStream title="Notes" tableKey="Contact" objectKey={contactId} />
              </NotesProvider>
            </div>
          </div>
        </OverlayScrollbarsComponent>
        <Dialog open={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
          <DialogTitle>Delete Contact</DialogTitle>
          <DialogContent className="mt-ba mb-lg">
            <DialogContentText>Are you sure you want to delete this contact</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
              Cancel
            </Button>
            <Button className="ml-auto" variant="error" onClick={() => handleDeleteContact()}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <Drawer
        anchor={"right"}
        open={contactFlyout}
        onClose={() => {
          setContactFlyout(false);
          setDataMode(DataMode.View);
        }}
      >
        <ContactFormContainer
          dataMode={dataMode}
          customerId={customerId}
          setAddContactFlyout={setContactFlyout}
          flyoutState={contactFlyout}
          tableData={transformTableData(companyData.contacts ?? ({} as ContactModel[]))}
          isLoadingRoleList={isLoadingRoleList}
          roleList={roleList}
          contactEditData={contactEditData}
          setDataMode={setDataMode}
          markPrimaryContact={markPrimaryContact}
          isContactAlreadyPrimary={isContactAlreadyPrimary}
        />
      </Drawer>
      <Modal
        modalStates={{
          open: showPrimaryContactWarning,
          onCloseFunction: () => setShowPrimaryContactWarning(false),
        }}
        title={{
          label: "Make primary contact?",
        }}
        content={{
          label: `${newPrimaryContact?.contactName} will become the default contact for communications to ${companyData.companyName}.  You can change to another contact in the future.`,
        }}
        primaryButton={{
          enable: true,
          type: "button",
          variant: "primary",
          label: "Yes, make primary",
          loader: showLoader,
          onClickHandler: handleMarkPrimaryContact,
        }}
        secondaryButton={{
          enable: true,
          type: "button",
          variant: "grey",
          label: "No",
          onClickHandler: () => setShowPrimaryContactWarning(false),
        }}
      />
    </>
  );
}

import React from "react";
import { TableFooterContainer } from "./footer.styles";
import { DEFAULT_PAGINATION_VALUES } from "../../../constants/table.constants";
import { Button, Pagination, Skeleton, Typography } from "@mui/material";
import { ReactComponent as ArrowDown } from "../../../svg/arrow_down.svg";
import { ReactComponent as ArrowUp } from "../../../svg/arrow_up.svg";
import { pxToRem } from "../../../theme/theme";
import { TableDataContext, TableDataProviderType } from "../../contexts/TableDataContext";
import Menu from "../../../atomic/Menu/Menu";
import MenuItem from "../../../atomic/MenuItem/MenuItem";
import { NUMERIC_VALUES } from "../../../constants/numeric.constants";
import { useTranslation } from "react-i18next";

const TableFooter: React.FC = () => {
  const {
    paginationConfigs: paginationConfig,
    tableConfigs: tableConfig,
    selectionConfigs: selectionConfig,
  } = React.useContext(TableDataContext) as TableDataProviderType;
  const [paginationMenuAnchorEl, setPaginationMenuAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const paginationMenuOpen = Boolean(paginationMenuAnchorEl);
  const PAGINATION_DROPDOWN = Object.keys(DEFAULT_PAGINATION_VALUES.PAGE_SIZE_OPTIONS).map(
    (e: string) => DEFAULT_PAGINATION_VALUES.PAGE_SIZE_OPTIONS[e]
  );
  const { isAllRecordsSelected, onClickSelectAndClearAllRecords, getSelectedRowsCount } = selectionConfig;
  const selectedCount = getSelectedRowsCount();
  const { t } = useTranslation();
  const getFooterText = () => {
    const totalRecordCount = paginationConfig?.page?.totalRecords ?? NUMERIC_VALUES.CONSTANT_ZERO;
    const selectRecordText =
      totalRecordCount > NUMERIC_VALUES.CONSTANT_ONE
        ? t("footer.selectAll.multiRecordText", { count: totalRecordCount, tableType: tableConfig.footerTableTypeText, ns: "table" })
        : t("footer.selectAll.singleRecordText", { count: totalRecordCount, tableType: tableConfig.footerTableTypeText, ns: "table" });

    return isAllRecordsSelected ? t("footer.clearAll", { ns: "table" }) : selectRecordText;
  };

  const getPaginationText = () => {
    const pageSize = paginationConfig?.page?.pageSize ?? NUMERIC_VALUES.CONSTANT_ZERO;
    const pageNumber = paginationConfig?.page?.pageNumber ?? NUMERIC_VALUES.CONSTANT_ONE;
    const totalRecords = paginationConfig?.page?.totalRecords ?? NUMERIC_VALUES.CONSTANT_ZERO;
    const firstIndexOfPage =
      (pageNumber - NUMERIC_VALUES.CONSTANT_ONE) * pageSize +
      (totalRecords > NUMERIC_VALUES.CONSTANT_ZERO ? NUMERIC_VALUES.CONSTANT_ONE : NUMERIC_VALUES.CONSTANT_ZERO);
    const lastIndexOfPage = pageNumber * pageSize > totalRecords ? totalRecords : pageNumber * pageSize;

    return t("footer.paginationText", {
      firstIndexOfPage,
      lastIndexOfPage,
      totalRecords,
      ns: "table",
    });
  };

  return (
    <TableFooterContainer className="table-pagination-container">
      {!tableConfig?.loading && paginationConfig?.enableNavigator && (
        <Pagination
          page={paginationConfig?.page?.pageNumber}
          count={
            paginationConfig?.page?.pageSize && paginationConfig?.page?.pageSize > NUMERIC_VALUES.CONSTANT_ZERO
              ? Math.ceil(paginationConfig?.page?.totalRecords / paginationConfig?.page?.pageSize)
              : NUMERIC_VALUES.CONSTANT_ZERO
          }
          variant="outlined"
          shape="rounded"
          onChange={paginationConfig?.onChangeHandler}
        />
      )}
      {tableConfig?.loading && (
        <>
          <div className="pagination-nav-skeleton-container">
            {new Array(NUMERIC_VALUES.CONSTANT_FIVE).fill(NUMERIC_VALUES.CONSTANT_ZERO).map(() => (
              <Skeleton
                key={`paging-key-${Math.random()}`}
                animation="wave"
                width={pxToRem(NUMERIC_VALUES.CONSTANT_THIRTY_TWO)}
                height={pxToRem(NUMERIC_VALUES.CONSTANT_THIRTY_TWO)}
              />
            ))}
          </div>
        </>
      )}
      {!tableConfig?.loading && paginationConfig?.enableSummary && paginationConfig?.page && (
        <>
          <Menu
            open={paginationMenuOpen}
            anchorEl={paginationMenuAnchorEl}
            onClose={() => setPaginationMenuAnchorEl(null)}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: 52,
            }}
          >
            {PAGINATION_DROPDOWN.map((value: number) => {
              // Parse the pageSize if it's a string
              const parsedPageSize =
                typeof paginationConfig?.page?.pageSize === "string"
                  ? parseInt(paginationConfig.page.pageSize, NUMERIC_VALUES.CONSTANT_TEN)
                  : paginationConfig?.page?.pageSize;

              return (
                <MenuItem
                  key={value}
                  onClick={() => {
                    //call onChangePageSize only when different options get's selected
                    if (parsedPageSize !== value) {
                      paginationConfig.onChangePageSize?.(value);
                      setPaginationMenuAnchorEl(null);
                    }
                  }}
                  selected={parsedPageSize === value}
                >
                  {t("footer.pageSizeList", { pageSize: value, ns: "table" })}
                </MenuItem>
              );
            })}
          </Menu>
          {/* Selection Text */}
          <Typography variant="body1Reg" onClick={(e: any) => setPaginationMenuAnchorEl(e.currentTarget)}>
            {paginationMenuOpen ? <ArrowUp /> : <ArrowDown />}
            {getPaginationText()}
            {/* select all and clear all action */}
            {paginationConfig?.page.pageNumber <= paginationConfig?.page.totalPages && selectedCount > NUMERIC_VALUES.CONSTANT_ZERO && (
              <div className="bulk-selection-container" onClick={(e: any) => e.stopPropagation()}>
                <Button
                  className="bulk-action-button"
                  variant="contained"
                  disableFocusRipple={true}
                  disableRipple={true}
                  onClick={() => onClickSelectAndClearAllRecords(!isAllRecordsSelected)}
                >
                  <Typography variant="body1">{getFooterText()}</Typography>
                </Button>
              </div>
            )}
          </Typography>
        </>
      )}
    </TableFooterContainer>
  );
};

export default TableFooter;

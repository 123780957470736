export enum TemplateTypes {
  INVOICE_COPY = "INVOICE_COPY",
  INVOICE_PAST_DUE_REMINDER = "INVOICE_PAST_DUE_REMINDER",
  INVOICE_SECOND_REMINDER = "INVOICE_SECOND_REMINDER",
  ACCOUNT_PAST_DUE_REMINDER = "ACCOUNT_PAST_DUE_REMINDER",
  ACCOUNT_PAST_DUE_SECOND_REMINDER = "ACCOUNT_PAST_DUE_SECOND_REMINDER",
  REQUEST_FOR_INVOICE_PAYMENT_STATUS = "REQUEST_FOR_INVOICE_PAYMENT_STATUS",
  REQUEST_FOR_ACCOUNT_PAYMENT_STATUS = "REQUEST_FOR_ACCOUNT_PAYMENT_STATUS",
  PAYMENT_CONFIRMATION = "PAYMENT_CONFIRMATION",
  REMITTANCE_REQUEST = "REMITTANCE_REQUEST",
  BANK_INFO = "BANK_INFO",
  AR_TAX_EXEMPT_CERTIFICATE = "AR_TAX_EXEMPT_CERTIFICATE", // Customer
  AP_TAX_EXEMPT_CERTIFICATE = "AP_TAX_EXEMPT_CERTIFICATE", // Vendor.
  W9_FORM = "W9_FORM",

  BILL_RECEIPT_NOTIFICATION = "BILL_RECEIPT_NOTIFICATION",
  REQUEST_COPY_BILL = "REQUEST_COPY_BILL",
  ACTION_REQUIRED_BILL = "ACTION_REQUIRED_BILL",
  BILL_PAYMENT_SCHEDULED = "BILL_PAYMENT_SCHEDULED",
  ACCOUNT_PAYMENT_SCHEDULED = "ACCOUNT_PAYMENT_SCHEDULED",
  PAYMENT_REMITTANCE_INFO = "PAYMENT_REMITTANCE_INFO",
  PAYMENT_APPLICATION_DISCREPANCY = "PAYMENT_APPLICATION_DISCREPANCY",
  PAYMENT_CONFIRMATION_REQUEST = "PAYMENT_CONFIRMATION_REQUEST",
  REQUEST_W9 = "REQUEST_W9",
  REQUEST_BANK_INFO = "REQUEST_BANK_INFO",
  SUPPORTING_DOCUMENTS = "SUPPORTING_DOCUMENTS",

  AR_APPROVAL_REQUEST = "AR_APPROVAL_REQUEST",
  AP_APPROVAL_REQUEST = "AP_APPROVAL_REQUEST",

  AP_VENDOR_PROFILE_REQUEST = "AP_VENDOR_PROFILE_REQUEST",
  AP_SHARE_PROFILE = "AP_SHARE_PROFILE",
  AR_SHARE_PROFILE = "AR_SHARE_PROFILE",
  AR_CUSTOMER_PROFILE_REQUEST = "AR_CUSTOMER_PROFILE_REQUEST",

  AR_CREATE_CUSTOMER_PROFILE = "AR_CREATE_CUSTOMER_PROFILE",
  AP_CREATE_VENDOR_PROFILE = "AP_CREATE_VENDOR_PROFILE",
  AR_UPDATE_CUSTOMER_PROFILE = "AR_UPDATE_CUSTOMER_PROFILE",
  AP_UPDATE_VENDOR_PROFILE = "AP_UPDATE_VENDOR_PROFILE",
}

export interface IData {
  customer?: string;
  invoiceNumber?: string;
  invoiceDate?: string;
  paymentDueDate?: string;
  outstandingBalance?: number;
  invoiceAmount?: number;
  emailAddress?: string;
  phone?: string;
  vendor?: string;
  billNumber?: string;
  invoiceInfo?: Record<string, string | number>;
  companyName?: string;
}

export enum viewType {
  AR_DASHBOARD_AGING,
  AR_DASHBOARD_DAYS_SALES_OUTSTANDING,
  AR_DASHBOARD_RECEIVABLES_SUMMARY_INVOICED,
  AR_DASHBOARD_RECEIVABLES_SUMMARY_COLLECTED,
  AR_DASHBOARD_RECEIVABLES_RISK,
  AR_ALL_CUSTOMER_CONNECTIONS,
  AR_TRANSACTION_INVOICES,
  AR_TRANSACTION_INVOICES_CLOSED,
  AR_TRANSACTION_PAYMENTS,
  AR_CONNECTION_CUSTOMER_ACTIVITIES,
  AR_CONNECTION_CUSTOMER_OPEN_INVOICES,
  AR_CONNECTION_CUSTOMER_CLOSE_INVOICES,
  AR_CONNECTION_CUSTOMER_PAYMENTS,
  AP_DASHBOARD_AGING,
  AP_DASHBOARD_SUMMARY_BILLED,
  AP_DASHBOARD_SUMMARY_PAID,
  AP_DASHBOARD_DAYS_PAYABLE_OUTSTANDING,
  AP_DASHBOARD_PAYABLE_COMING_DUE,
  AP_DASHBOARD_PAYABLES_SUMMARY_PAID,
  AP_DASHBOARD_PAYABLES_SUMMARY_BILLED,
  AP_ALL_VENDOR_CONNECTIONS,
  AP_TRANSACTION_BILLS,
  AP_TRANSACTION_BILLS_CLOSED,
  AP_TRANSACTION_PAYMENTS,
  AP_TRANSACTION_ADVANCE_PAYMENTS,
  AP_CONNECTION_VENDOR_ACTIVITY,
  AP_CONNECTION_VENDOR_PROFILE,
  AP_CONNECTION_VENDOR_OPEN_BILLS,
  AP_CONNECTION_VENDOR_CLOSED_BILLS,
  AP_CONNECTION_VENDOR_PAYMENTS,
  AR_APPROVAL_REQUEST_ACTIVITY,
  AP_APPROVAL_REQUEST_ACTIVITY,
  VENDOR_ONBOARD_REQUEST_ACTIVITY,
  AP_SHARE_PROFILE_ACTIVITY,
  AR_SHARE_PROFILE_ACTIVITY,
  AR_CUSTOMER_PROFILE_REQUEST_ACTIVITY,
}

export const getViewTypeTemplateCodes = (view: viewType) => {
  switch (view) {
    case viewType.AR_DASHBOARD_AGING:
    case viewType.AR_DASHBOARD_RECEIVABLES_RISK:
    case viewType.AR_TRANSACTION_INVOICES:
    case viewType.AR_CONNECTION_CUSTOMER_OPEN_INVOICES:
      return [
        TemplateTypes.INVOICE_COPY,
        TemplateTypes.INVOICE_PAST_DUE_REMINDER,
        TemplateTypes.INVOICE_SECOND_REMINDER,
        TemplateTypes.REQUEST_FOR_INVOICE_PAYMENT_STATUS,
        TemplateTypes.AR_CREATE_CUSTOMER_PROFILE,
        TemplateTypes.AR_UPDATE_CUSTOMER_PROFILE,
      ];
    case viewType.AR_DASHBOARD_DAYS_SALES_OUTSTANDING:
    case viewType.AR_ALL_CUSTOMER_CONNECTIONS:
      return [
        TemplateTypes.ACCOUNT_PAST_DUE_REMINDER,
        TemplateTypes.ACCOUNT_PAST_DUE_SECOND_REMINDER,
        TemplateTypes.REQUEST_FOR_ACCOUNT_PAYMENT_STATUS,
        TemplateTypes.AR_CREATE_CUSTOMER_PROFILE,
        TemplateTypes.AR_UPDATE_CUSTOMER_PROFILE,
      ];
    case viewType.AP_ALL_VENDOR_CONNECTIONS:
      return [TemplateTypes.AP_CREATE_VENDOR_PROFILE, TemplateTypes.AP_UPDATE_VENDOR_PROFILE];
    case viewType.AR_DASHBOARD_RECEIVABLES_SUMMARY_COLLECTED:
    case viewType.AR_TRANSACTION_PAYMENTS:
    case viewType.AR_CONNECTION_CUSTOMER_PAYMENTS:
      return [TemplateTypes.PAYMENT_CONFIRMATION, TemplateTypes.AR_CREATE_CUSTOMER_PROFILE, TemplateTypes.AR_UPDATE_CUSTOMER_PROFILE];
    case viewType.AR_DASHBOARD_RECEIVABLES_SUMMARY_INVOICED:
      return [TemplateTypes.INVOICE_COPY];
    case viewType.AR_CONNECTION_CUSTOMER_ACTIVITIES:
      return [
        TemplateTypes.AR_TAX_EXEMPT_CERTIFICATE,
        TemplateTypes.W9_FORM,
        TemplateTypes.AR_CREATE_CUSTOMER_PROFILE,
        TemplateTypes.AR_UPDATE_CUSTOMER_PROFILE,
      ];
    case viewType.AR_CONNECTION_CUSTOMER_CLOSE_INVOICES:
    case viewType.AR_TRANSACTION_INVOICES_CLOSED:
      return [TemplateTypes.INVOICE_COPY, TemplateTypes.AR_CREATE_CUSTOMER_PROFILE, TemplateTypes.AR_UPDATE_CUSTOMER_PROFILE];
    case viewType.AP_DASHBOARD_AGING:
      return [
        TemplateTypes.BILL_RECEIPT_NOTIFICATION,
        TemplateTypes.REQUEST_COPY_BILL,
        TemplateTypes.ACTION_REQUIRED_BILL,
        TemplateTypes.BILL_PAYMENT_SCHEDULED,
        TemplateTypes.AP_CREATE_VENDOR_PROFILE,
        TemplateTypes.AP_UPDATE_VENDOR_PROFILE,
      ];
    case viewType.AP_TRANSACTION_BILLS:
    case viewType.AP_CONNECTION_VENDOR_OPEN_BILLS:
      return [
        TemplateTypes.BILL_RECEIPT_NOTIFICATION,
        TemplateTypes.REQUEST_COPY_BILL,
        TemplateTypes.ACTION_REQUIRED_BILL,
        TemplateTypes.BILL_PAYMENT_SCHEDULED,
        TemplateTypes.SUPPORTING_DOCUMENTS,
        TemplateTypes.AP_CREATE_VENDOR_PROFILE,
        TemplateTypes.AP_UPDATE_VENDOR_PROFILE,
      ];
    case viewType.AP_DASHBOARD_SUMMARY_PAID:
    case viewType.AP_TRANSACTION_PAYMENTS:
    case viewType.AP_CONNECTION_VENDOR_PAYMENTS:
      return [
        TemplateTypes.PAYMENT_REMITTANCE_INFO,
        TemplateTypes.PAYMENT_APPLICATION_DISCREPANCY,
        TemplateTypes.AP_CREATE_VENDOR_PROFILE,
        TemplateTypes.AP_UPDATE_VENDOR_PROFILE,
        TemplateTypes.PAYMENT_CONFIRMATION_REQUEST,
      ];
    case viewType.AP_CONNECTION_VENDOR_ACTIVITY:
      return [
        TemplateTypes.REQUEST_W9,
        TemplateTypes.REQUEST_BANK_INFO,
        TemplateTypes.AP_TAX_EXEMPT_CERTIFICATE,
        TemplateTypes.AP_CREATE_VENDOR_PROFILE,
        TemplateTypes.AP_UPDATE_VENDOR_PROFILE,
      ];
    case viewType.AP_CONNECTION_VENDOR_CLOSED_BILLS:
    case viewType.AP_TRANSACTION_BILLS_CLOSED:
      return [TemplateTypes.REQUEST_COPY_BILL, TemplateTypes.AP_CREATE_VENDOR_PROFILE, TemplateTypes.AP_UPDATE_VENDOR_PROFILE];
    case viewType.AR_APPROVAL_REQUEST_ACTIVITY:
      return [TemplateTypes.AR_APPROVAL_REQUEST];
    case viewType.AP_APPROVAL_REQUEST_ACTIVITY:
      return [TemplateTypes.AP_APPROVAL_REQUEST];
    case viewType.VENDOR_ONBOARD_REQUEST_ACTIVITY:
      return [TemplateTypes.AP_VENDOR_PROFILE_REQUEST];
    case viewType.AP_SHARE_PROFILE_ACTIVITY:
      return [TemplateTypes.AP_SHARE_PROFILE];
    case viewType.AR_SHARE_PROFILE_ACTIVITY:
      return [TemplateTypes.AR_SHARE_PROFILE];
    case viewType.AR_CUSTOMER_PROFILE_REQUEST_ACTIVITY:
      return [TemplateTypes.AR_CUSTOMER_PROFILE_REQUEST];
    case viewType.AP_TRANSACTION_ADVANCE_PAYMENTS:
      return [TemplateTypes.AP_CREATE_VENDOR_PROFILE, TemplateTypes.AP_UPDATE_VENDOR_PROFILE, TemplateTypes.PAYMENT_CONFIRMATION_REQUEST];
    default:
      return [];
  }
};

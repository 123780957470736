import React, { Dispatch, SetStateAction } from "react";
import "./ContactsPopoverShowMore.scss";
import { ClickAwayListener, Popper, Typography } from "@mui/material";
import { CONTACT_POPOVER_EMPTY_ITEM } from "../../../constants/config";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES, TRIM_COUNT_CONSTANTS } from "../AtomicComponents/constants/numeric.constants";
import { Button } from "../AtomicComponents/atomic/Button";
import { useTranslation } from "react-i18next";
import { AlertContext } from "../../../contexts/AlertContext";
import ContactPopoverCard from "../ContactPopoverCard/ContactPopoverCard";
import _ from "underscore";

type ContactsPopoverShowMoreProps = {
  setShowPopover: Dispatch<SetStateAction<boolean>>;
  showPopover: boolean;
  showContactPopup: boolean;
  setShowContactPopup: Dispatch<SetStateAction<boolean>>;
  anchorEl: null | HTMLElement;
  to: any;
  setIsShowMoreChipActive: Dispatch<SetStateAction<boolean>>;
};

const ContactsPopoverShowMore: React.FC<ContactsPopoverShowMoreProps> = ({
  showPopover,
  anchorEl,
  to,
  setShowPopover,
  showContactPopup,
  setShowContactPopup,
  setIsShowMoreChipActive,
}) => {
  const { t } = useTranslation();
  const [showMoreButton, setShowMoreButton] = React.useState<boolean>(false);
  const [selectedContact, setSelectedContact] = React.useState<ContactItem>({});
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;

  /**
   * @function onCopyToClipBoard
   * A helper function to store a string to the clipboard.
   * @param string - the string to store
   */
  const onCopyToClipBoard = (string?: string, type?: string) => {
    if (string) {
      navigator.clipboard.writeText(string ?? "N/A");
      let toastOptions = {} as ToastOptions;
      switch (type) {
        case "phone":
          toastOptions = {
            open: true,
            severity: "success",
            message: t("activityDetails.feedItems.contactAndSenderPopover.toastMessages.copyPhone", { ns: "activities" }),
          };
          setToastOptions(toastOptions);
          break;

        case "email":
          toastOptions = {
            open: true,
            severity: "success",
            message: t("activityDetails.feedItems.contactAndSenderPopover.toastMessages.copyEmail", { ns: "activities" }),
          };
          setToastOptions(toastOptions);
          break;

        default:
          break;
      }
    }
  };
  return (
    <ClickAwayListener
      onClickAway={() => {
        setShowContactPopup(false);
        setShowPopover(false);
        setIsShowMoreChipActive(false);
      }}
    >
      <div>
        <Popper
          open={showPopover}
          disablePortal={false}
          anchorEl={anchorEl}
          placement={"bottom-start"}
          className="contact-show-more-popper"
          modifiers={[
            {
              name: "flip",
              enabled: true,
              options: {
                altBoundary: true,
                rootBoundary: "document",
                padding: 8,
              },
            },
          ]}
        >
          <div className="contact-show-more-container">
            <div className="show-contacts-list">
              {to
                .slice(DEFAULT_NUMERIC_VALUES.ZERO, showMoreButton ? to.length : TRIM_COUNT_CONSTANTS.SHOW_CONTACT_LIST)
                .map((item: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className="contact-item"
                      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                        event.stopPropagation();
                        setSelectedContact(item as ContactItem);
                        setShowContactPopup(true);
                        setShowPopover(false);
                      }}
                    >
                      <div className="contact-avatar-container">
                        {(item as any).name
                          ?.match(/(\b\S)?/g)
                          ?.join("")
                          ?.match(/(^\S|\S$)?/g)
                          ?.join("")
                          .toUpperCase()}
                      </div>
                      <div className="contact-details-container">
                        <span className="contact-name">
                          <Typography variant="body1Med">{item?.name || item?.email_address}</Typography>
                        </span>
                        {item?.title && item.type !== "Workspace" && (
                          <span className="contact-role">
                            <Typography variant="captionMed">{item?.title ?? CONTACT_POPOVER_EMPTY_ITEM}</Typography>
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
            {_.size(to) > NUMERIC_VALUES.CONSTANT_FOUR && (
              <div className="button-container">
                <Button
                  className="see-more-button"
                  variant="secondary"
                  size="large"
                  onClick={(event) => {
                    event.stopPropagation();
                    setShowMoreButton(!showMoreButton);
                  }}
                >
                  {showMoreButton
                    ? t("activityDetails.feedItems.primaryActivity.fieldLabels.showLess", { ns: "activities" })
                    : t("activityDetails.feedItems.primaryActivity.fieldLabels.showMore", { ns: "activities" })}
                </Button>
              </div>
            )}
          </div>
        </Popper>
        <Popper
          open={showContactPopup && !showPopover}
          disablePortal={false}
          anchorEl={anchorEl}
          placement={"bottom-start"}
          className="contact-popover-popper"
          modifiers={[
            {
              name: "flip",
              enabled: true,
              options: {
                altBoundary: true,
                rootBoundary: "document",
                padding: 8,
              },
            },
          ]}
        >
          <div className="contact-card-wrapper">
            <div className="back-button-container">
              <Button
                className="see-more-button"
                variant="secondary"
                size="large"
                onClick={(event) => {
                  event.stopPropagation();
                  setShowPopover(true);
                  setShowContactPopup(false);
                }}
              >
                {t("activityDetails.feedItems.primaryActivity.fieldLabels.goBack", { ns: "activities" })}
              </Button>
            </div>
            <ContactPopoverCard selectedContact={selectedContact} onCopyToClipBoard={onCopyToClipBoard} />
          </div>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default ContactsPopoverShowMore;

import React from "react";
import { useHistory } from "react-router";
import Status from "../Status/Status";
import { Failure } from "../../library/Icons/Icons";
import Button from "../../library/Button/Button";

const ConnectionError = () => {
  const history = useHistory();

  return (
    <Status
      size="lg"
      logo={<Failure />}
      statusMessage={`Oops! We ran into a problem`}
      subText={"There was a problem loading this page. If this issue persists, please contact your system administrator."}
    >
      <Button variant="primary" onClick={() => history.goBack()}>
        Try Again
      </Button>
    </Status>
  );
};
export default ConnectionError;

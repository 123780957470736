import React from "react";
import Button from "../../../../../../library/Button/Button";
import { Forward } from "../../../../../../library/Icons/Icons";
import PrimaryActivity from "../../PrimaryActivity/PrimaryActivity";
import * as Labels from "../../../../../../../constants/config";
import { ForwardActivityProps } from "../ActivityItemVariantTypes";

const ForwardedActivity: React.FC<ForwardActivityProps> = ({ onClickRedirect, ...props }) => {
  return (
    <PrimaryActivity
      {...{
        ...props,
        creator: { ...props.creator, message: Labels.HEADER_FORWARDED },
        icon: <Forward />,
        FLAGS: { REPLY_OPTIONS: true },
      }}
    >
      <Button size="sm" onClick={onClickRedirect}>
        {Labels.BTN_SEE_ORIGINAL_STREAM}
      </Button>
    </PrimaryActivity>
  );
};

export default ForwardedActivity;

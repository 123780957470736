import React from "react";
import libphonenumber from "google-libphonenumber";
import { AppContext } from "../contexts/AppContext";

/**
 * Hook to utilise the google-libphonenumber library and format the Phone number in our app according to our needs
 * @returns PhoneUtil an instace of PhoneNumber Utils Class, locale of the Use and PNF PhoneNumberFOrmat types
 *
 */
const usePhoneNumberUtilities = () => {
  const { userStatus } = React.useContext(AppContext) as AppType;
  const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
  const PNF = libphonenumber.PhoneNumberFormat;

  return {
    phoneUtil,
    locale: userStatus.locale_data.country_code,
    PNF,
  };
};

export default usePhoneNumberUtilities;

import React from "react";
import { Pen } from "../../../../../../library/Icons/Icons";
import PrimaryActivity from "../../PrimaryActivity/PrimaryActivity";
import { ApprovalResponseProps } from "../ActivityItemVariantTypes";

const Response: React.FC<ApprovalResponseProps> = (props) => {
  return (
    <PrimaryActivity
      {...{
        ...props,
        creator: { ...props.creator, message: "", name: props.creator.message },
        icon: <Pen />,
        FLAGS: { REPLY_OPTIONS: false, TIME_SAVING_INDICATOR: true },
      }}
    />
  );
};

export default Response;

import React from "react";
import { Skeleton } from "@mui/material";

const AttachmentsLoadingSkeleton = () => (
  <div className="feed-item-attachments-loader">
    <div className="attachments">
      <Skeleton variant="rectangular" width={"10rem"} sx={{ borderRadius: "0.5rem" }} height={"3.75rem"} />
      <Skeleton variant="rectangular" width={"10rem"} sx={{ borderRadius: "0.5rem" }} height={"3.75rem"} />
    </div>
  </div>
);

export default AttachmentsLoadingSkeleton;

import React, { useState } from "react";
import { Alert, Snackbar } from "@mui/material";
import { RoundedError, RoundedInfo, RoundedSuccess, RoundedWarning } from "../components/library/Icons/Icons";

export const AlertContext = React.createContext<AlertContextType | null>(null);

export interface Props {
  children: (React.ReactNode & { type: string })[] | (React.ReactNode & { type: string });
}

const alertIcon = (severityType: string): React.ReactNode => {
  switch (severityType) {
    case "success":
      return <RoundedSuccess />;
    case "error":
      return <RoundedError />;
    case "warning":
      return <RoundedWarning />;
    case "info":
      return <RoundedInfo />;
    default:
      return "";
  }
};

const AlertProvider: React.FC<Props> = ({ children }: Props) => {
  const [toastOptions, setToastOptions] = useState<ToastOptions>({
    open: false,
    severity: "success",
    message: "",
  });
  return (
    <AlertContext.Provider value={{ setToastOptions }}>
      {children}

      {/* Render Snackbar Alert */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={toastOptions.open}
        onClose={() => setToastOptions((prev) => ({ ...prev, open: false }))}
        autoHideDuration={3000}
      >
        <Alert
          icon={alertIcon(toastOptions.severity ?? "")}
          onClose={() => setToastOptions((prev) => ({ ...prev, open: false }))}
          severity={toastOptions.severity}
          sx={{ width: "100%" }}
        >
          {toastOptions.message}
        </Alert>
      </Snackbar>
    </AlertContext.Provider>
  );
};

export default AlertProvider;

import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { CircularProgress, Grid } from "@mui/material";
import { loginRequest } from "../../auth/authConfig";
import "./LogoutSuccess.scss";

export default function LogoutSuccess(): React.ReactElement {
  const { instance } = useMsal();

  useEffect(() => {
    instance.loginRedirect({ ...loginRequest, redirectStartPage: window.location.origin });
  }, []);

  return (
    <Grid id="logout-success-container" container direction="column" justifyContent="center" alignItems="center">
      <CircularProgress />
      <p>Logging out...</p>
    </Grid>
  );
}

import { ClickAwayListener, IconButton, Tooltip } from "@mui/material";
import React from "react";
import TableUtils from "../../../../../../../../utils/TableUtils/TableUtils";
import { EmojiHeart, EmojiLine } from "../../../../../../../library/Icons/Icons";
import { EmojiSelectorProps } from "../HelperTypes";
import { EmojiActionIds, EmojiOptions } from "../../../../../../../../types/enums";
import CustomTooltip from "../../../../../../../library/CustomTooltip/CustomTooltip";
import { useTranslation } from "react-i18next";

const EmojiSelector: React.FC<EmojiSelectorProps> = ({ onClickEmoji }) => {
  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);
  const { t } = useTranslation();

  const onClickEmojiButton = (reactionType: EmojiOptions) => {
    onClickEmoji?.(reactionType, EmojiActionIds.selector);
    setIsTooltipOpen(false);
  };

  const ellipsesActionConfigs = TableUtils.generateMenuList([
    {
      value: "👍",
      id: EmojiOptions.THUMBS_UP,
      clickFunction: () => onClickEmojiButton(EmojiOptions.THUMBS_UP),
    },
    {
      value: "👎",
      id: EmojiOptions.THUMBS_DOWN,
      clickFunction: () => onClickEmojiButton(EmojiOptions.THUMBS_DOWN),
    },
    {
      value: <EmojiHeart />,
      id: EmojiOptions.HEART,
      clickFunction: () => onClickEmojiButton(EmojiOptions.HEART),
    },
    {
      value: "😃",
      id: EmojiOptions.HAPPY,
      clickFunction: () => onClickEmojiButton(EmojiOptions.HAPPY),
    },
    {
      value: "😦",
      id: EmojiOptions.SAD,
      clickFunction: () => onClickEmojiButton(EmojiOptions.SAD),
    },
    {
      value: "🎉",
      id: EmojiOptions.CELEBRATE,
      clickFunction: () => onClickEmojiButton(EmojiOptions.CELEBRATE),
    },
  ]);

  const onClickAwayTooltip = () => {
    setIsTooltipOpen(false);
  };

  return (
    <>
      <ClickAwayListener onClickAway={onClickAwayTooltip}>
        <div
          className="emoji-actions"
          onClick={(e) => {
            //To suppress event bubbling up to feed component and triggering "Read More" toggle
            e.stopPropagation();
            setIsTooltipOpen(!isTooltipOpen);
          }}
        >
          <Tooltip title={ellipsesActionConfigs} open={isTooltipOpen} classes={{ tooltip: "emoji-tooltip" }} placement="bottom-start">
            <IconButton disableRipple>
              <CustomTooltip
                title={t("activityDetails.feedItems.primaryActivity.helpers.emojiSelector.tooltipMessage", { ns: "activities" }) as string}
                placement="bottom-start"
              >
                <span>
                  <EmojiLine />
                </span>
              </CustomTooltip>
            </IconButton>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </>
  );
};

export default EmojiSelector;

import React from "react";
import { Skeleton } from "@mui/material";
import StateActionLoadingSkeleton from "./StateActionLoadingSkeleton";

const FeedLoadingSkeleton = () => (
  <div className="activity-feed-wrapper e-invoicing v2">
    <div id="infinite-scroll" className="">
      <div className="e-invoicing-feed-item-wrapper">
        <div className="body">
          <div className="feed-item-section header-section-wrapper">
            <div className="header-left-section">
              <div className="left-column">
                <div className="icon contact-avatar">
                  <Skeleton variant="circular" width={40} height={40} animation="wave" />
                </div>
              </div>
              <div className="right-column">
                <div className="header">
                  <div className="header-details">
                    <Skeleton variant="text" width="6rem" height="100%" animation="wave" />
                  </div>
                </div>
                <div className="subheader-wrapper">
                  <table className="subheader-key-value-table">
                    <tr>
                      <td>
                        <Skeleton variant="text" width="6rem" height="100%" animation="wave" />
                      </td>
                      <td className="subheader caption v2">
                        <Skeleton variant="text" width="6rem" height="100%" animation="wave" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Skeleton variant="text" width="6rem" height="100%" animation="wave" />
                      </td>
                      <td className="subheader caption v2">
                        <Skeleton variant="text" width="6rem" height="100%" animation="wave" />
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div className="header-right-section">
              <div className="timestamp-row">
                <Skeleton variant="text" width="4rem" height="100%" animation="wave" />
              </div>
              <div className="feed-item-type-row">
                <Skeleton variant="text" width="4rem" height="100%" animation="wave" />
              </div>
            </div>
          </div>
          <div className="feed-item-section feed-body">
            <StateActionLoadingSkeleton />
            <div className="e-invoice-line-items-table">
              <Skeleton variant="text" width="60rem" height="100%" animation="wave" />
              <br />
              <Skeleton variant="text" width="60rem" height="100%" animation="wave" />
              <br />
              <Skeleton variant="text" width="60rem" height="100%" animation="wave" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default FeedLoadingSkeleton;

import React from "react";
import getCroppedImg from "./CropUtilities";
import CropComponent from "./CropComponent";
import { Point, Area } from "react-easy-crop/types";
import { CropContainerProps } from "../UploadComponentInterfaces/UploadComponentInterfaces";
import { DEFAULT_NUMERIC_VALUES } from "../../../../constants/NumericConstants";

const CropContainer: React.FC<CropContainerProps> = ({
  cropperProps,
  finalFileUploading,
  imageCropSuccess,
  imgSrc,
  labelProps,
  resetUploadedImage,
  onClickCloseModal,
  onCompleteCropping,
  onCompleteSave,
}: CropContainerProps) => {
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState({} as Area);

  const onCropComplete = React.useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const cropImage = React.useCallback(async () => {
    try {
      const newCroppedImage = await getCroppedImg(imgSrc, croppedAreaPixels);
      onCompleteCropping({ blob: newCroppedImage as Blob, localBlobDataURL: URL.createObjectURL(newCroppedImage as Blob) });
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  return (
    <CropComponent
      crop={crop}
      cropImage={cropImage}
      cropperProps={cropperProps}
      finalFileUploading={finalFileUploading}
      imageCropSuccess={imageCropSuccess}
      imgSrc={imgSrc}
      labelProps={labelProps}
      onClickCloseModal={onClickCloseModal}
      onCropComplete={onCropComplete}
      onCompleteSave={onCompleteSave}
      resetUploadedImage={resetUploadedImage}
      setCrop={(value: Point) => {
        setCrop(value);
      }}
      zoom={zoom}
      setZoom={setZoom}
    />
  );
};

export default CropContainer;

import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import FourOFourError from "../../../routes/FourOFourError/FourOFourError";
import ActivityDetailV2 from "../../Activities/ActivityDetailV2/ActivityDetail";
import CustomerDetail from "../../Customers/CustomerDetail/CustomerDetail";
import InvoiceDetail from "../../Invoices/InvoiceDetail/InvoiceDetail";
import PaymentDetail from "../../Payments/PaymentDetail/PaymentDetail";
import ResultsViewNavbar from "../Components/Navbar/ResultsViewNavbar";
import "../Styles/ResultsViewStyles.scss";
import ResultsViewContainer from "./ResultsViewContainer";

const ResultsViewNavigator: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <div className="gsr-nav-wrapper">
      <div className="head">
        <Switch>
          <Route exact path={`${path}/:category`} render={() => <ResultsViewNavbar currentSearchNestingLevel="L1" />} />
        </Switch>
        <Switch>
          <Route
            exact
            path={[
              `${path}/activity_streams/:activityId`,
              `${path}/invoices/:invoiceId`,
              `${path}/bills/:invoiceId`,
              `${path}/payments/:paymentId`,
              `${path}/customers/active/:customerId`,
              `${path}/customers/active/:customerId/documents`,
              `${path}/customers/active/:customerId/contacts`,
              `${path}/vendors/active/:customerId`,
              `${path}/vendors/active/:customerId/documents`,
              `${path}/vendors/active/:customerId/contacts`,
              `${path}/vendors/active/:customerId`,
            ]}
            render={() => <ResultsViewNavbar currentSearchNestingLevel="L2" />}
          />
        </Switch>
      </div>
      <div className="body">
        <Switch>
          {/**
           * L1 Navigation - Table
           */}
          <Route
            exact
            path={`${path}/:category`}
            render={() => {
              return <ResultsViewContainer />;
            }}
          />
          {/**
           * L2 Navigation - Filter Details View
           */}
          <Route
            exact
            path={`${path}/activity_streams/:activityId`}
            render={() => {
              return <ActivityDetailV2 userView="all" />;
            }}
          />
          <Route
            exact
            path={[`${path}/invoices/:invoiceId`, `${path}/bills/:invoiceId`]}
            render={() => {
              return <InvoiceDetail />;
            }}
          />
          <Route
            exact
            path={`${path}/payments/:paymentId`}
            render={() => {
              return <PaymentDetail />;
            }}
          />
          <Route
            path={[`${path}/customers/active/:customerId`, `${path}/vendors/active/:customerId`]}
            render={() => {
              return <CustomerDetail />;
            }}
          />
          {/**
           * 404 Error
           */}
          <Route
            render={() => {
              return <FourOFourError />;
            }}
          />
        </Switch>
      </div>
    </div>
  );
};

export default ResultsViewNavigator;

import { AttachmentType } from "../../types/enums";
import { TEMPLATE_TIMING_CONSTANTS } from "../../constants/NumericConstants";
import BaseTemplate from "./BaseTemplate";

/**
 * Template class which generate the text for Bill Receipt Notification.
 */
class BillReceiptNotification extends BaseTemplate {
  /**
   * Templates From Time definition
   */
  protected fromTime = TEMPLATE_TIMING_CONSTANTS.ZERO;

  /**
   * Templates To Time definition
   */
  protected toTime = TEMPLATE_TIMING_CONSTANTS.TWO_MIN_THIRTY_SEC;

  /**
   * To allow attachment with templates
   */
  protected templateAttachment = true;

  /**
   * Allowing multiple type of attachments with templates
   */
  public attachmentType = [AttachmentType.BILL];
}

export default BillReceiptNotification;

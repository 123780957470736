import React from "react";
import Button from "../../../../../../../library/Button/Button";
import "./OnboardVendor.scss";
import { OnboardVendorProps } from "../../ActivityItemVariantTypes";
import Menu from "../../../../../../../library/Menu/Menu";
import MenuItem from "../../../../../../../library/MenuItem/MenuItem";
import { CaretUp } from "../../../../../../../library/Icons/Icons";
import { CompanyType } from "../../../../../../../../types/enums";

const OnboardVendor: React.FC<OnboardVendorProps> = ({ onClickOnboard, onClickDismissFlag, dynamicCompanySelection = false }) => {
  const [disappear, setDisappear] = React.useState<boolean>(false);
  const [startOnboardAnchor, setStartOnboardAnchor] = React.useState<HTMLDivElement | HTMLButtonElement | null>(null);
  const [actionTooltipVisible, setActionTooltipVisible] = React.useState<boolean>(false);

  // AP - Start Onboarding Flow
  const onClickEnhancedOnboard = () => {
    onClickOnboard(CompanyType.VENDOR.toLocaleLowerCase());
    setDisappear(true);
  };

  // AW - Combined Onboarding Flow
  const onClickDynamicOnboardOption = (id: string) => {
    // 1. make tooltip disappear
    setActionTooltipVisible(false);
    // 2. call the onClickOnboard to open up the popup
    onClickOnboard(id);
    // 3. disappear the banner
    setDisappear(true);
  };

  const onClickDismiss = async () => {
    // 1. call backend handler
    const res = await onClickDismissFlag();
    // if dismiss was a success
    if (res) {
      // 2. hide banner
      setDisappear(true);
    }
  };

  const onboardingOptions = [
    {
      label: "Customer",
      id: CompanyType.CUSTOMER.toLocaleLowerCase(),
    },
    {
      label: "Vendor",
      id: CompanyType.VENDOR.toLocaleLowerCase(),
    },
  ];

  return !disappear ? (
    <div className="onboard-activity activity-feed-item-card">
      <div className="onboard-activity-icon">📢</div>
      <div className="onboard-activity-body">
        <h3 className="onboard-activity-body-header">Heads Up!</h3>
        <p className="onboard-activity-body-text">
          A <span className="highlight">new vendor</span> has sent you a bill.
          <br />
          Go ahead and add them to your ERP system.
        </p>
      </div>
      <Menu
        open={actionTooltipVisible}
        onClose={() => setActionTooltipVisible(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorEl={startOnboardAnchor}
      >
        {onboardingOptions.map((item, index) => {
          return (
            <MenuItem key={`${item.id}-${index}`} onClick={() => onClickDynamicOnboardOption(item.id)} id={item.id}>
              {item.label}
            </MenuItem>
          );
        })}
      </Menu>

      <div
        className="onboard-activity-action"
        onClick={(e) => {
          setStartOnboardAnchor(e.currentTarget ?? null);
          setActionTooltipVisible((prev) => !prev);
        }}
      >
        {!dynamicCompanySelection ? (
          <>
            <Button className="request-onboard-btn" onClick={onClickEnhancedOnboard}>
              Start Onboarding
            </Button>
          </>
        ) : (
          <div className="request-onboard-menu">
            <div className="request-onboard-menu-details">
              <span className="request-onboard-menu-title">Start Onboarding</span>
              <div className={`menu-caret-icon ${actionTooltipVisible ? "expanded" : "close"}`}>
                <CaretUp />
              </div>
            </div>
          </div>
        )}
        <>
          <Button
            className="dismiss-onboard-btn"
            variant="transparent"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClickDismiss();
            }}
          >
            Dismiss
          </Button>
        </>
      </div>
    </div>
  ) : null;
};

export default OnboardVendor;

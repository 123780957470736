export const ExportToastMessage = {
  EXPORT_IN_PROGRESS: "Export action in progress",
  EXPORT_COMPLETED: "Selected rows successfully exported.",
  EXPORT_FAILED: "Export action failed, please try again.",
};

export const EInvoiceExportModalStates = {
  EXPORT_IN_PROGRESS: "in_progress",
  EXPORT_SUCCESS: "success",
};

import React from "react";
import { PopoverProps, Typography } from "@mui/material";
import { styled } from "@mui/system";
import Popover from "../../../../atomic/Popover/Popover";

export const CustomInputDateFieldSepartor = styled(Typography, { name: "CustomInputDateFieldSepartor" })({
  fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  "Helvetica Neue", sans-serif`,
  fontWeight: "400",
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
  color: "#1a1a1a",
});

const CustomDateRangePopoverContainer: React.FC<PopoverProps> = ({ className, ...props }) => <Popover {...props} className={className} />;
export const DateRangeCalendarPopupContainer = styled(CustomDateRangePopoverContainer, {
  name: "DateRangeCalendarPopupContainer",
})({
  "& .MuiPaper-root": {
    display: "flex",
    flexDirection: "column",
    height: "26.6875rem",
    width: "34rem",
    boxShadow: `0 0.5rem 0.75rem rgba(0, 0, 0, 0.1), 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1)`,
    borderRadius: "0.5rem",
    padding: "1rem",
    gap: "0.75rem",
    marginTop: "1.25rem",
  },
});

import React from "react";
import { AutocompleteProps as MuiAutocompleteProps } from "@mui/material";
import { Autocomplete as MUIAutocomplete } from "@mui/material";
import { DropdownArrow } from "../Icons/Icons";
import "./Autocomplete.scss";
interface AutocompleteProps<T> extends MuiAutocompleteProps<T, boolean, boolean, boolean> {
  className?: string;
  staticIcon?: boolean; // Disable icon flip
  label?: string;
}

export default function Autocomplete({ className, staticIcon, label, ...rest }: AutocompleteProps<any>): React.ReactElement {
  return (
    <>
      {label && <label className="MuiAutocomplete-label">{label}</label>}
      <MUIAutocomplete className={`${className ?? ""} ${staticIcon ? "Autocomplete-static-icon" : ""} `} {...rest} />
    </>
  );
}

Autocomplete.defaultProps = {
  forcePopupIcon: true,
  popupIcon: <DropdownArrow />,
};

import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { CircularProgress, Grid } from "@mui/material";
import { signupRequest } from "../../auth/authConfig";
import "./Invitation.scss";

export default function Invitation(): React.ReactElement {
  const queryParams = new URLSearchParams(window.location.search);
  const [error, setError] = useState<string>("");
  const { instance } = useMsal();

  useEffect(() => {
    (async () => {
      // Log out of all accounts
      await instance
        .logoutRedirect({
          onRedirectNavigate: () => false,
        })
        .catch((err) => {
          if (err?.errorCode === "interaction_in_progress") {
            setError("Interaction In Progress. Please close all tabs and try again.");
          }
        });
      // Invite flow
      await instance.loginRedirect({ ...signupRequest, extraQueryParameters: { code: queryParams.get("code") || "" }, prompt: "login" });
    })();
  }, [instance]);

  return (
    <Grid id="page_invitation" container direction="column" justifyContent="center" alignItems="center">
      <CircularProgress />
      <p>{error || "Redirecting..."}</p>
    </Grid>
  );
}

import React, { useEffect, useState, useRef } from "react";
import { Select, SelectProps } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import useDropdownStyle from "./useDropdownStyle";
import { CheckList } from "../Icons/Icons";
import _ from "underscore";
import { MenuOptionType } from "../AtomicComponents/TableFilters/Presets/preset.filter.types";
import { TableFilterComparatorConstant, TableFilterConstant } from "../AtomicComponents/constants/table.constants";
import CustomDateRangePicker from "../AtomicComponents/TableFilters/Presets/DateFilter/tsx/CustomDateRangePicker";
import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { NUMERIC_VALUES } from "../../../constants/NumericConstants";

interface DropdownProps extends SelectProps {
  handleDropDownChange: (event: any, param: string, type?: string) => void;
  selectedValue: string;
  optionList: MenuOptionType[];
  hasValueChanged: boolean;
  defaultText: string;
  param?: string;
  isNoneItemRequired?: boolean;
  selectedRange?: string[];
}

const DateAndRangePicker: React.FC<DropdownProps> = ({
  handleDropDownChange,
  selectedValue = "",
  optionList = [],
  hasValueChanged,
  defaultText = "",
  param = "",
  className,
  selectedRange = [],
  isNoneItemRequired,
}) => {
  const { t } = useTranslation();
  const { select, menuRoot, divRoot, fillDivRoot, itemText, listItemIcon, dateSelectDropdownList } = useDropdownStyle();
  const [isOpenCustomDateRangePicker, setIsOpenCustomDateRangePicker] = useState<boolean>(false);
  const selectRef = useRef(null);
  const [dateFilterAnchorEl, setDateFilterAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isCustomRange, setIsCustomRange] = useState<boolean>(false);
  const [selectedOperator, setSelectedOperator] = useState<string | null>("");
  const customDateStringTranslated = TableFilterConstant.getDateDropDownList(t)?.find(
    (filter) => filter.value === TableFilterComparatorConstant.CUSTOM_DATE
  );

  const onClickDateRangeFilter = () => {
    setDateFilterAnchorEl(selectRef?.current);
  };

  const onCloseDateRangePicker = () => {
    setIsOpenCustomDateRangePicker(false);
    setDateFilterAnchorEl(null);
    setIsCustomRange(false);
    setIsOpenCustomDateRangePicker(false);
  };

  const onClickApplyDateButton = (selectedDateRange: DateRange<dayjs.Dayjs>) => {
    const dateRange = [selectedDateRange?.[0]?.format("YYYY-MM-DD") ?? "", selectedDateRange?.[1]?.format("YYYY-MM-DD") ?? ""];
    handleDropDownChange(dateRange.join(","), param, TableFilterComparatorConstant.CUSTOM_DATE);
    setDateFilterAnchorEl(null);
    onCloseDateRangePicker();
  };

  /**
   * @function processSelectedValue
   * Function to get multiple selected values from Dropdown
   * and return computed custom Title to be shown on Dropdown
   * based on condition that one or more menu items have been selected
   * @param selected string[]: array of valus selected in Dropdown
   * @returns string
   */
  const processSelectedValue = (selected: string) => {
    if (selectedRange?.length === NUMERIC_VALUES.CONSTANT_TWO) {
      return selectedRange?.join(` ${t("globalSearchResults.filters.miscellaneous.to", { ns: "global_search" })} `);
    } else if (isCustomRange) {
      return customDateStringTranslated?.label;
    } else {
      return _.isEmpty(selected) ? defaultText : selected;
    }
  };

  const handleDropdownSelectionChange = (event: any) => {
    if (event.currentTarget.dataset["value"] !== TableFilterComparatorConstant.CUSTOM_DATE) {
      setSelectedOperator(event.currentTarget.dataset["value"]);
      setIsCustomRange(false);
      setIsOpenCustomDateRangePicker(false);
      handleDropDownChange(event, param);
      setDateFilterAnchorEl(null);
    } else {
      onClickDateRangeFilter();
      setIsCustomRange(true);
      setIsOpenCustomDateRangePicker(true);
      setSelectedOperator(TableFilterComparatorConstant.CUSTOM_DATE);
    }
  };

  /**
   * Convert selectedValue into translated string (if applicable) in dropdown
   */
  const selectedOption = optionList.find((optionItem) => selectedValue === optionItem.value);
  const selectedOptionValue = selectedOption?.label ? selectedOption?.label : selectedValue;

  useEffect(() => {
    if (selectedOption?.key === TableFilterComparatorConstant.CUSTOM_DATE) {
      setIsOpenCustomDateRangePicker(true);
    }
  }, [selectedOption]);

  return (
    <div className={`${hasValueChanged ? fillDivRoot : divRoot} ${className}`}>
      <Select
        id="dropdown"
        ref={selectRef}
        onClose={onCloseDateRangePicker}
        className={select}
        value={isCustomRange ? customDateStringTranslated?.label : selectedOptionValue}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          className: menuRoot,
        }}
        IconComponent={KeyboardArrowDown}
        defaultValue=""
        displayEmpty={true}
        renderValue={(selected) => processSelectedValue(selected)}
      >
        <div className={dateSelectDropdownList}>
          {isNoneItemRequired && !isOpenCustomDateRangePicker && !isCustomRange && (
            <div
              className={`date-select-dropdown-item ${"" === selectedOperator ? "selected" : ""}`}
              key={"empty-option"}
              data-value={""}
              onClick={(event: any) => handleDropdownSelectionChange(event)}
            >
              <span className={listItemIcon}>{"" === selectedOperator && <CheckList />}</span>
              <span className={itemText}>{t("globalSearchResults.filters.miscellaneous.none", { ns: "global_search" })}</span>
            </div>
          )}
          {!isOpenCustomDateRangePicker &&
            !isCustomRange &&
            optionList?.map((item) => (
              <div
                className={`date-select-dropdown-item ${item.value === selectedOperator ? "selected" : ""}`}
                key={item.key}
                data-value={item.value}
                onClick={(event: any) => handleDropdownSelectionChange(event)}
              >
                <span className={listItemIcon}>{item.value === selectedOperator && <CheckList />}</span>
                <span className={itemText}>{item.label ?? item.label}</span>
              </div>
            ))}
        </div>
      </Select>
      {isOpenCustomDateRangePicker && isCustomRange && (
        <CustomDateRangePicker
          isOpenDateRangePopup={isOpenCustomDateRangePicker}
          onCloseDateRangePicker={onCloseDateRangePicker}
          anchorEl={dateFilterAnchorEl}
          onClickApplyDate={onClickApplyDateButton}
          onClickCancel={onCloseDateRangePicker}
          dateFormat={"MMM DD, YYYY"}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          appliedDateRangeValue={selectedRange}
          origin={"gs-date-range-filters"}
        />
      )}
    </div>
  );
};

export default DateAndRangePicker;

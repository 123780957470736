import React, { ReactElement } from "react";
import { ThumbsUp } from "../../../library/Icons/Icons";

const AwsomeText = (): ReactElement => {
  return (
    <div className="awsome-text-container">
      <span className="emoji">
        <ThumbsUp />
      </span>
      <div className="awsome-text">
        <div className="help-text-header">Awesome, You’re All Set!</div>
        <div className="help-text-body">
          Your signature had been succesfully added in Inbox, this will now auto-populate in your email drafts and templates.
        </div>
      </div>
    </div>
  );
};

export default AwsomeText;

import React from "react";
import Activities from "../Activities/Activities";
import Customers from "../Customers/Customers";
import Invoices from "../Invoices/Invoices";
import PrivateRoute from "../../auth/PrivateRoute";
import Payments from "../Payments/Payments";
import { Switch, useRouteMatch } from "react-router-dom";

import { WorkspaceContext } from "../../contexts/WorkspaceContext";
import { ComponentConfigContext } from "../../contexts/ComponentConfigContext";
import { WorkspaceType } from "../../types/enums";
import ApplicationRouteProvider from "../../contexts/ApplicationRouteContext";
import { GlobalSearchResults } from "../GlobalSearch";
import GlobalSearchNavigation from "../AdvanceGlobalSearch/SearchResultPageView/GlobalSearchNavigation";
import { FeatureFlagContext, FeatureFlagProviderType } from "../../contexts/FeatureFlagContext";

const MainContentView: React.FC = () => {
  const { path } = useRouteMatch();
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { getConfig } = React.useContext(ComponentConfigContext) as ComponentConfigType;
  const componentConfig = getConfig(selectedWorkspace.workspace_type_route?.toLocaleLowerCase() ?? WorkspaceType.AR) as any;
  const { isEnabled, featureFlagsLoading } = React.useContext(FeatureFlagContext) as FeatureFlagProviderType;
  const isGloabalSearchV2Enable = isEnabled("GLOBAL_SEARCH_V2");

  return (
    <ApplicationRouteProvider>
      <Switch>
        <PrivateRoute
          path={`${path}/search`}
          render={() => {
            return !featureFlagsLoading ? isGloabalSearchV2Enable ? <GlobalSearchNavigation /> : <GlobalSearchResults /> : "";
          }}
        />
        {/* 
          * Note: hiding /dashboard until data issues are fixed and/or product confirmation
          {!fetchingWorkspace && selectedWorkspace.workspace_type_route !== WorkspaceType.AW && (
            <PrivateRoute path={`${path}/dashboard`} render={() => <Dashboard configs={componentConfig.dashboard} />} />
          )} 
          */}
        <PrivateRoute
          path={[`${path}/customers`, `${path}/vendors`, `${path}/connections`, `${path}/${componentConfig.myCompany.routeType}`]}
          render={() => <Customers />}
        />
        <PrivateRoute path={`${path}/activities`} render={() => <Activities />} />
        <PrivateRoute path={[`${path}/invoices`, `${path}/bills`]} render={() => <Invoices />} />
        <PrivateRoute
          path={[`${path}/payments`, ...(isEnabled("ACCOUNTING_WORKSPACE") ? [`${path}/vendor-payments`, `${path}/customer-payments`] : [])]}
          render={() => <Payments />}
        />
      </Switch>
    </ApplicationRouteProvider>
  );
};

export default MainContentView;

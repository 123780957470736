import React from "react";
import { Switch, Route, useRouteMatch, matchPath } from "react-router-dom";
import PaymentDetail from "./PaymentDetail/PaymentDetail";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import "./Payments.scss";
import FourOFourError from "../../routes/FourOFourError/FourOFourError";
import PaymentNavbar from "./PaymentsNavbar";
import { WorkspaceType } from "../../types/enums";
import useWorkspaceConfigurations from "../../hooks/useWorkspaceConfigurations";
import AllPaymentsV2 from "./AllPayments/AllPaymentsV2";
import UnappliedPaymentsV2 from "./UnappliedPayments/UnappliedPaymentsV2";
import AdvancedPaymentsV2 from "./AdvancedPayments/AdvancedPaymentsV2";

/**
 * * Defines the Payments Tab (Top-level route)
 */
export default function Payments(): React.ReactElement {
  const { path } = useRouteMatch();
  const { configs: currentWorkspaceConfigurations, configProvider, selectedWorkspace, pathType } = useWorkspaceConfigurations();
  const matchActivityId = matchPath(location.pathname, {
    path: "*/activities/:id",
    exact: true,
    strict: true,
  });
  const configsV2 = (selectedWorkspace.workspace_type_route === WorkspaceType.AW ? configProvider[pathType] : currentWorkspaceConfigurations)[
    "paymentsV2"
  ];

  return (
    <div className="payments-wrapper">
      <PaymentNavbar />
      <OverlayScrollbarsComponent
        options={{
          paddingAbsolute: true,
          autoUpdate: true,
          overflowBehavior: { x: "hidden" },
          className: "overlay-scrollbar-width-reset",
        }}
      >
        <div className={`body table-v2} ${matchActivityId !== null ? "padding-v2" : ""}`}>
          <Switch>
            {/* TODO: figure out a way to dynamically generate routes based on dependencies such as - workspace etc. */}
            {[WorkspaceType.AP, WorkspaceType.AR, WorkspaceType.AW].includes(selectedWorkspace.workspace_type_route as WorkspaceType) && (
              <Route
                exact
                path={[`${path}`, `${path}/vendor-payments`, `${path}/customer-payments`]}
                component={() => {
                  return <AllPaymentsV2 configs={configsV2} />;
                }}
              />
            )}
            {[WorkspaceType.AR, WorkspaceType.AW].includes(selectedWorkspace.workspace_type_route as WorkspaceType) && (
              <Route
                exact
                path={[`${path}/unappliedPayments`]}
                component={() => {
                  return <UnappliedPaymentsV2 configs={configsV2} />;
                }}
              />
            )}
            {[WorkspaceType.AP, WorkspaceType.AW].includes(selectedWorkspace.workspace_type_route as WorkspaceType) && (
              <Route
                exact
                path={[`${path}/advancedPayments`]}
                component={() => {
                  return <AdvancedPaymentsV2 configs={configsV2} />;
                }}
              />
            )}
            <Route
              path={[`${path}/:paymentId`, `${path}/customer-payments/:paymentId`, `${path}/vendor-payments/:paymentId`]}
              render={() => {
                return <PaymentDetail />;
              }}
            />
            <Route
              component={() => {
                return <FourOFourError />;
              }}
            />
          </Switch>
        </div>
      </OverlayScrollbarsComponent>
    </div>
  );
}

/*eslint-disable no-magic-numbers*/
import { CustomerCurrencyTypes, VendorCurrencyTypes } from "../constants/table.constants";
import { SUPPORTED_APPLICATIONS } from "../constants/global.constants";
import { CURRENCY_CONSTANTS } from "../constants/numeric.constants";
import { getLocalstorageValue } from "./cypher";
import { roundToPrecisionPoint } from "./numeric";

export const CurrencyTypes = () =>
  getLocalstorageValue("access-connector")
    ? getLocalstorageValue("access-connector") === SUPPORTED_APPLICATIONS.CUSTOMER
      ? CustomerCurrencyTypes
      : VendorCurrencyTypes
    : window.location.pathname?.split?.("/")?.[2] === SUPPORTED_APPLICATIONS.CUSTOMER
    ? CustomerCurrencyTypes
    : VendorCurrencyTypes;

export const formatCurrency = (
  num: number | undefined,
  enableTypeCheckMultiplier = false,
  type?: string,
  fracDigits?: { maximumFractionDigits: number; minimumFractionDigits: number },
  locale = "en-US",
  code = "USD"
) => {
  const newCurrency = enableTypeCheckMultiplier && type && CurrencyTypes()?.DEBIT?.includes(type?.toLowerCase()) ? (num || 0) * -1 : num;
  const currency =
    num && typeof num === "number"
      ? new Intl.NumberFormat(locale, {
          maximumFractionDigits: fracDigits ? fracDigits.maximumFractionDigits : 2,
          minimumFractionDigits: fracDigits ? fracDigits.maximumFractionDigits : 2,
          style: "currency",
          currency: code,
        }).format(newCurrency || 0)
      : new Intl.NumberFormat(locale, {
          maximumFractionDigits: 0,
          style: "currency",
          currency: code,
        }).format(0);
  return currency;
};

export const getCurrencySuffix = (amount: number): string => {
  const stringifiedAmountLength = String(Math.abs(Math.floor(amount))).length;
  if (stringifiedAmountLength <= CURRENCY_CONSTANTS.TRAILING_ZEROS_IN_KILOS) {
    return "";
  } else if (stringifiedAmountLength <= CURRENCY_CONSTANTS.TRAILING_ZEROS_IN_MILLION) {
    return "k";
  } else if (stringifiedAmountLength <= CURRENCY_CONSTANTS.TRAILING_ZEROS_IN_BILLION) {
    return "m";
  } else if (stringifiedAmountLength <= CURRENCY_CONSTANTS.TRAILING_ZEROS_IN_TRILLION) {
    return "b";
  } else {
    return "t";
  }
};

export const shortenCurrency = (amount: number, decimal?: boolean): string => {
  const stringifiedAmount = String(Math.abs(Math.floor(amount)));
  const decimalPrecisionMap: { [key: string]: number } = {
    "1": 3,
    "2": 2,
    "3": 1,
  };

  const getRoundedPrecision = (currencyBucket: number) => {
    const precisionAmount = Math.abs(amount) / currencyBucket;
    return roundToPrecisionPoint(precisionAmount, 4 - precisionAmount?.toString?.()?.split(".")?.[0].length);
  };

  if (decimal) {
    let decimalVal = 0.0;
    if (stringifiedAmount.length <= CURRENCY_CONSTANTS.TRAILING_ZEROS_IN_MILLION) {
      decimalVal = getRoundedPrecision(CURRENCY_CONSTANTS.CURRENCY_IN_KILOS);
    } else if (stringifiedAmount.length <= CURRENCY_CONSTANTS.TRAILING_ZEROS_IN_BILLION) {
      decimalVal = getRoundedPrecision(CURRENCY_CONSTANTS.CURRENCY_IN_MILLION);
    } else if (stringifiedAmount.length <= CURRENCY_CONSTANTS.TRAILING_ZEROS_IN_TRILLION) {
      decimalVal = getRoundedPrecision(CURRENCY_CONSTANTS.CURRENCY_IN_BILLION);
    } else {
      decimalVal = getRoundedPrecision(CURRENCY_CONSTANTS.CURRENCY_IN_TRILLION);
    }

    const decimalString = decimalVal.toString();
    const [decimalNumber, precisionNumber] = decimalString.split(".");

    if (amount !== 0) {
      return `${amount < 0 ? "-" : ""}${decimalNumber ?? 0}.${precisionNumber ?? ""}${"000".slice(
        0,
        (decimalPrecisionMap[decimalNumber?.length?.toString?.()] ?? 0) - (precisionNumber?.length ?? 0)
      )}`;
    }
    return stringifiedAmount;
  } else {
    if (stringifiedAmount.length <= CURRENCY_CONSTANTS.TRAILING_ZEROS_IN_KILOS) {
      return String(Math.round(amount));
    } else if (stringifiedAmount.length <= CURRENCY_CONSTANTS.TRAILING_ZEROS_IN_MILLION) {
      return String(Math.round(amount / CURRENCY_CONSTANTS.CURRENCY_IN_KILOS));
    } else if (stringifiedAmount.length <= CURRENCY_CONSTANTS.TRAILING_ZEROS_IN_BILLION) {
      return String(Math.round(amount / CURRENCY_CONSTANTS.CURRENCY_IN_MILLION));
    } else if (stringifiedAmount.length <= CURRENCY_CONSTANTS.TRAILING_ZEROS_IN_TRILLION) {
      return String(Math.round(amount / CURRENCY_CONSTANTS.CURRENCY_IN_BILLION));
    } else {
      return String(Math.round(amount / CURRENCY_CONSTANTS.TRAILING_ZEROS_IN_TRILLION));
    }
  }
};

const getAdditionalValue = (value: number, getAddition: boolean): string => (getAddition && value > 999 ? "+" : "");

export const formatCurrencyWithPattern = (
  currency: number,
  decimal = true,
  getAddition = false,
  fracDigits?: { maximumFractionDigits: number; minimumFractionDigits: number }
) => {
  let currencyRet = formatCurrency(parseFloat(shortenCurrency(currency, true)), false, undefined, fracDigits);
  if (!decimal) {
    currencyRet = currencyRet.split(".")[0];
  }
  return currencyRet + getCurrencySuffix(currency) + getAdditionalValue(currency, getAddition);
};

export const formatTooltipCurrency = (currency: number) => {
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: "currency",
    currency: "USD",
  }).format(currency);
};

import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Button from "../../../../library/Button/Button";
import Detail from "../../../../library/Detail/Detail";
import { DropdownArrow, Email, Phone, PrimaryContact, UserPic } from "../../../../library/Icons/Icons";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useHistory } from "react-router-dom";
import "./ActivityProfileTab.scss";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../../../../constants/NumericConstants";
import { ApplicationRouteContext } from "../../../../../contexts/ApplicationRouteContext";

interface ProfileProps {
  phone: string;
  email: string;
  contacts: ContactItem[];
}

export default function ProfileTab(props: ProfileProps): React.ReactElement {
  const history = useHistory();
  const [showAllContacts, setShowAllContacts] = React.useState<boolean>(false);
  const { getBaseRoute } = React.useContext(ApplicationRouteContext) as ApplicationRouteType;
  const [contactsData, setContactData] = React.useState<Array<ContactItem>>([]);

  const arrangeContacts = () => {
    let contacts = props?.contacts ?? [];
    if (contacts?.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      setContactData([]);
      return;
    }

    const getPrimaryContactIndex = contacts?.map((contactItem: ContactItem) => contactItem?.is_primary ?? false).indexOf(true);
    if (getPrimaryContactIndex === DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE) {
      setContactData(contacts);
      return;
    }

    contacts = [
      contacts[getPrimaryContactIndex],
      ...contacts.slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, getPrimaryContactIndex),
      ...contacts.slice(getPrimaryContactIndex + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE, contacts.length),
    ];
    setContactData(contacts);
  };

  React.useEffect(() => {
    arrangeContacts();
  }, [props.contacts]);

  return (
    <div className="activity-profile-tab">
      <Accordion defaultExpanded disableGutters>
        <AccordionSummary expandIcon={<DropdownArrow />}>
          <p className="caption-med">GENERAL INFO</p>
        </AccordionSummary>
        <AccordionDetails>
          {props.phone !== "N/A" && <Detail icon={<Phone />} columns={{ value: props.phone, format: "tel" }} />}
          {props.email !== "N/A" && <Detail icon={<Email />} columns={{ value: props.email, format: "email" }} />}
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded disableGutters>
        <AccordionSummary expandIcon={<DropdownArrow />}>
          <p className="caption-med">CONTACTS</p>
        </AccordionSummary>
        <AccordionDetails>
          <OverlayScrollbarsComponent options={{ paddingAbsolute: true, autoUpdate: true, sizeAutoCapable: true }}>
            <div className="activity-contact-list">
              {contactsData.slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, showAllContacts ? contactsData.length : NUMERIC_VALUES.CONSTANT_FIVE).map(
                (contact: ContactItem, index: number) =>
                  contact.is_active && (
                    <div className="contact-item" key={index} onClick={() => history.push(`${getBaseRoute()}/contactProfile/${contact.contact_id}`)}>
                      <Detail
                        icon={contact?.is_primary ? <PrimaryContact /> : <UserPic />}
                        columns={{ title: contact?.contact_name || contact?.email_address || "N/A", value: contact?.title || " " }}
                      />
                    </div>
                  )
              )}
            </div>
          </OverlayScrollbarsComponent>
          {contactsData.length > NUMERIC_VALUES.CONSTANT_FIVE ? (
            <Button className="contact-toggle-btn" size="sm" variant="secondary" onClick={() => setShowAllContacts((prev) => !prev)}>
              {showAllContacts ? "Show Less" : "Show More"}
            </Button>
          ) : (
            <></>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

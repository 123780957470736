import APIClient from "../APIClient";
import { AxiosRequestConfig } from "axios";

const INBOX_API_BASE = `${process.env.REACT_APP_API_ENDPOINT_INBOX_API_PATH}${process.env.REACT_APP_API_ENDPOINT_INBOX_API_V2}`;
const CONFIG_OVERRIDE = {
  baseURL: process.env.REACT_APP_API_ENDPOINT_INBOX,
} as AxiosRequestConfig;

export class EInvoicesClient {
  /**
   * @function getEInvoices
   * A helper method to fetch all e-invocies
   * @see {@link https://lockstepapi.postman.co/workspace/Lockstep-inbox~e303da34-7ad8-4123-90e3-d552a6ae5670/example/20950407-4ef246ab-5641-4b0f-8665-9cde649628ac | PostMan :: Received E-Invoices}
   * @param workspaceId
   * @param page
   * @param page_size
   * @param sortQuery
   * @param searchFilter
   * @param others
   * @returns
   */
  getEInvoices(
    workspaceId: number,
    page?: number,
    page_size?: number,
    sortQuery?: string,
    searchFilter?: SearchFilter[],
    activityStreamType?: string,
    others?: string
  ): Promise<any> {
    let url = `${INBOX_API_BASE}/workspaces/${workspaceId}/activity_streams/einvoices/${
      activityStreamType ?? "received"
    }?page=${page}&page_size=${page_size}&attachments=true`;
    if (sortQuery) {
      url = `${url}&qs[s]=${sortQuery.toLowerCase()}`;
    }
    if (searchFilter && searchFilter.length) {
      let constructedFilter = "";
      searchFilter.forEach((filter: SearchFilter) => {
        constructedFilter = constructedFilter + `&qa[${filter.filterKey}_${filter.filterToken}]=${filter.filterValue}`;
      });
      url = url + constructedFilter;
    }
    if (others) {
      url = `${url}&${others}`;
    }
    return APIClient.get(url, CONFIG_OVERRIDE).then((res) => {
      return res.data;
    });
  }

  /**
   * @function getEInvoice
   * A helper method to fetch all e-invocies
   * @see {@link https://lockstepapi.postman.co/workspace/Lockstep-inbox~e303da34-7ad8-4123-90e3-d552a6ae5670/example/20950407-4ef246ab-5641-4b0f-8665-9cde649628ac | PostMan :: Received E-Invoices}
   * @param workspaceId
   * @param activityId
   * @param page
   * @param page_size
   * @param others
   * @returns
   */
  getEInvoice(workspaceId: number, activityId: string, page?: number, page_size?: number, others?: string): Promise<any> {
    let url = `${INBOX_API_BASE}/workspaces/${workspaceId}/activity_streams/einvoices/${activityId}/activities/?page=${page}&page_size=${page_size}&attachments=true`;
    if (others) {
      url = `${url}&${others}`;
    }
    return APIClient.get(url, CONFIG_OVERRIDE).then((res) => res.data);
  }

  getEInvoiceStreamMeta(workspaceId: number): Promise<EInvoiceStreamMeta> {
    const url = `${INBOX_API_BASE}/workspaces/${workspaceId}/activity_streams/einvoices/meta`;
    return APIClient.get(url, CONFIG_OVERRIDE).then((res) => res.data);
  }

  patchEInvoice(workspaceId: number, eInvoiceId: string, body: any): Promise<any> {
    const url = `${INBOX_API_BASE}/workspaces/${workspaceId}/activity_streams/einvoices/${eInvoiceId}`;
    return APIClient.patch(url, body, CONFIG_OVERRIDE).then((res) => res.data);
  }
}

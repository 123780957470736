import { Divider, Input, Tooltip } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import Button from "../../library/Button/Button";
import { DropdownArrow, Search, BackArrow } from "../../library/Icons/Icons";
import { WidgetComponentInterface } from "../Interfaces/WidgetInterfaces";
import { DEFAULT_NUMERIC_VALUES } from "../../../constants/NumericConstants";

const WidgetComponent: React.FC<WidgetComponentInterface> = ({
  dropdownProps: {
    config,
    anchorEl,
    selectedCategory,
    dropdownOpen,
    predefinedCategorySet,
    handleDropdownClick,
    handleDropdownClose,
    changeCategory,
  },
  inputProps: { inputRef, value, onChangeHandlerForInput, onFocusHandler, onBlurHandler, onKeyDownHandler, goBack, tooltipTitle },
}: WidgetComponentInterface) => {
  const getSearchCategoryDropdown = () => (
    <div className="gsw-dropdown-container">
      <div className="library-button-container">
        <Button
          className="transparent-GSW-button"
          icon={<DropdownArrow />}
          alignIcon="right"
          variant="transparent"
          aria-controls={dropdownOpen ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={dropdownOpen ? "true" : undefined}
          onClick={handleDropdownClick}
        >
          {config["displayLabels"][selectedCategory]}
        </Button>
      </div>
      <Menu
        className="gsw-menu-list"
        anchorEl={anchorEl}
        open={dropdownOpen}
        onClose={handleDropdownClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {predefinedCategorySet.map((category) => (
          <MenuItem key={category} className="gsw-dropdown-menuitem-styles" onClick={() => changeCategory(category)}>
            {config["displayLabels"][category]}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );

  const getSearchInputField = () => (
    <Tooltip title={tooltipTitle} placement="bottom-end" disableFocusListener>
      <div className="gsw-input-container">
        <Input
          inputRef={inputRef}
          value={value}
          onChange={onChangeHandlerForInput}
          onFocus={onFocusHandler}
          className="gsw-input-styles"
          disableUnderline
          placeholder="Search your Sage Network"
          startAdornment={value?.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && <BackArrow className="gsw-back-icon" onClick={goBack} />}
          endAdornment={value?.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && <Search className="gsw-search-icon" />}
          onKeyDown={onKeyDownHandler}
          onBlur={onBlurHandler}
        />
      </div>
    </Tooltip>
  );

  return (
    <>
      {getSearchCategoryDropdown()}
      <Divider orientation="vertical" />
      {getSearchInputField()}
    </>
  );
};

export default WidgetComponent;

import { Divider, Input } from "@mui/material";
import React from "react";
import { Search } from "../../library/Icons/Icons";
import Loading from "../../library/Loading/Loading";
import { TableWithInfiniteScroll } from "../../library/TableWithInfiniteScroll";
import { ResultPageComponentInterface } from "../Interface/SearchResultContentInterface";
import FilterViewContainer from "./FilterViewContainer";
import NoResultFoundComponent from "../GlobalSearchDialogComponents/NoResultFound";
import _ from "underscore";
import { GLOBAL_SEARCH_CONSTANTS } from "../../../constants/GlobalSearchConstants";

const ResultPageComponent: React.FC<ResultPageComponentInterface> = ({
  gsrConfig: { initialLoading, updateFilterQuery, isEInvoice },
  tableConfig,
  searchInputProps: { onChangeSearchTextField, searchText },
}: ResultPageComponentInterface) => {
  return (
    <div className={`gsp-component-wrapper ${isEInvoice ? "e-invoice" : ""}`}>
      <div className="gsp-content-header-container">
        <div className="gsp-search-wrapper">
          <span className="search-icon">
            <Search />
          </span>
          <div className="gsp-search-div-container ">
            <Input value={searchText} disableUnderline={true} classes={{ input: "search-input" }} onChange={onChangeSearchTextField} />
            <Divider />
          </div>
        </div>
        <FilterViewContainer updateFilterQuery={updateFilterQuery} />
      </div>
      {initialLoading ? (
        <Loading />
      ) : (
        <>
          {_.isEmpty(tableConfig?.rows) ? (
            <div className="emtpy-result-container ">
              <NoResultFoundComponent hasEmptySearchKeyword={searchText?.length < GLOBAL_SEARCH_CONSTANTS.MIN_SEARCH_CHARACTERS} />
            </div>
          ) : (
            <div className="gsp-component-container">
              <TableWithInfiniteScroll tableConfig={tableConfig} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ResultPageComponent;

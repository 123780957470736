import styles from "../../../../../css/_export.module.scss";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Switch, Route, useRouteMatch } from "react-router";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../../../../constants/NumericConstants";
import Utils from "../../../../../utils/utils";
import { Caution } from "../../../../library/Icons/Icons";
import HeaderComponentProps, { HeaderItemMeta } from "../HeaderInterfaces/HeaderInterfaces";
import "./../Header.scss";
import CustomTooltip from "../../../../library/CustomTooltip/CustomTooltip";
import { TooltipTypes } from "../../../../../constants/TooltipConstants";
import { SUMMARY_HEADER_TOOLTIP } from "../../../../../constants/config";

const CustomTooltipComponent = (props: { elementText: string; tooltipMsg: string }) => {
  const useRichTooltipStyles = makeStyles(() => ({
    tooltip: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: styles.base,
      gap: "0.875rem",
      backgroundColor: styles.white100,
      color: styles.primary,
      boxShadow: `0px 0.25rem 0.25rem rgba(0, 0, 0, 0.08), 0px -0.125rem 0.5rem rgba(0, 0, 0, 0.04)`,
      borderRadius: styles.tiny,
      minHeight: "3.75rem",
      maxWidth: "17.5rem",
    },
  }));

  return (
    <>
      <div className="other-connection">
        <span className="text">{props.elementText}</span>
        <CustomTooltip
          type={props.tooltipMsg?.length > NUMERIC_VALUES.CONSTANT_TWENTY ? TooltipTypes.RICH : TooltipTypes.PLAIN}
          classes={{ tooltip: useRichTooltipStyles()?.tooltip }}
          title={props.tooltipMsg}
          followCursor
          placement="bottom-start"
        >
          <div className="column-icon">
            <Caution />
          </div>
        </CustomTooltip>
      </div>
    </>
  );
};

export const HeaderComponent = (props: HeaderComponentProps) => {
  const { path } = useRouteMatch();
  const isOtherConnection = path.includes("connections");
  const isMyCompany = path.includes("internal");
  const [isPaymentHeader, setIsPaymentHeader] = React.useState<boolean>(false);
  const tooltipText = isPaymentHeader ? "" : SUMMARY_HEADER_TOOLTIP;

  const getCurrencyEle = (value: number, label: string): JSX.Element => {
    return (
      //Added Tooltip Text on Header as requested in User Story 34226
      <CustomTooltip placement="bottom" title={tooltipText} disableFocusListener>
        <div key={label}>
          <span className={`balance-amount-dark`}>
            {Utils.formatValueAsCurrency(value, props.currencyConfig?.locale, props.currencyConfig?.code)}
          </span>
          <span className={`balance-amount-light`}> {label}</span>
        </div>
      </CustomTooltip>
    );
  };

  const formatCustomerInfo = (value: number, label: string): React.ReactElement => {
    const displayLabel = Utils.formatLabelPluralization(value, label);
    return (
      //Added Tooltip Text on Header as requested in User Story 34226
      <CustomTooltip title={tooltipText} placement="bottom" disableFocusListener>
        <div key={label}>
          <span className={`balance-amount-dark`}>{value} </span>
          <span className={`balance-amount-light`}>{displayLabel}</span>
        </div>
      </CustomTooltip>
    );
  };

  const formatFunctionMap: any = {
    formatCurrency: getCurrencyEle,
    formatCustomerInfo,
  };

  return (
    <>
      <div className="left">
        <div className={`tag`}>{props.companyData?.name?.charAt(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) ?? ""}</div>
        <p className={`customerName`}>{props.companyData?.name ?? ""}</p>
      </div>
      <div className="right">
        <div className={`amounts-wrapper ${isOtherConnection ? "amounts-separator" : ""}`}>
          <Switch>
            <Route
              exact
              path={[`${path}/open_invoices`, `${path}/open_bills`]}
              render={() => {
                return (
                  <>
                    {props.configs.openInvoices.map((item: HeaderItemMeta) => {
                      const data = props.transactionData;
                      return formatFunctionMap[item.value](
                        data[item.accessor as keyof typeof data] ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
                        item.label
                      );
                    })}
                  </>
                );
              }}
            />
            <Route
              exact
              path={[`${path}/closed_invoices`, `${path}/closed_bills`]}
              render={() => {
                return (
                  <>
                    {props.configs.closedInvoices.map((item: HeaderItemMeta) => {
                      // update label for specific fields
                      let label = item.label;
                      if (item.currentYear) {
                        label = `${label} ${new Date().getFullYear()}`;
                      } else if (item.prevYear) {
                        label = `${label} ${new Date().getFullYear() - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE}`;
                      }
                      const data = props.transactionData;
                      return formatFunctionMap[item.value](data[item.accessor as keyof typeof data] ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, label);
                    })}
                  </>
                );
              }}
            />
            <Route
              exact
              path={`${path}/payments`}
              render={() => {
                return (
                  <>
                    {props.configs.payments.map((item: HeaderItemMeta) => {
                      // update label for specific fields
                      let label = item.label;
                      if (item.currentYear) {
                        label = `${label} ${new Date().getFullYear()}`;
                      }
                      const data = props.transactionData;
                      setIsPaymentHeader(true);
                      return formatFunctionMap[item.value](data[item.accessor as keyof typeof data] ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, label);
                    })}
                  </>
                );
              }}
            />
            <Route
              exact
              path={`${path}/contacts`}
              render={() => {
                return <>{formatCustomerInfo(props.contactsCount, "Total Contact")}</>;
              }}
            />
            <Route
              exact
              path={`${path}/documents`}
              render={() => {
                return !isMyCompany ? <>{formatCustomerInfo(props.documentsCount, "Total Document")}</> : <></>;
              }}
            />
          </Switch>
        </div>
        {isOtherConnection && (
          <CustomTooltipComponent
            elementText="Other Connection"
            tooltipMsg="This connection was created because the email address was not found in the ERP."
          />
        )}

        {isMyCompany && <CustomTooltipComponent elementText="My Company" tooltipMsg="This connection, is a Team Member and belongs to My Company." />}
      </div>
    </>
  );
};

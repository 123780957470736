import { styled } from "@mui/system";

export const CustomerChip = styled("div")({
  display: "flex",
  width: "max-content",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "0.375rem",
  padding: "0.125rem 0.375rem",
  background: "#E3F2FD",
  color: "#0D47A1",
});

export const VendorChip = styled("div")({
  display: "flex",
  width: "max-content",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "0.375rem",
  padding: "0.125rem 0.375rem",
  background: "#EDE7F6",
  color: "#311B92",
});

export const OtherChip = styled("div")({
  display: "flex",
  width: "max-content",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "0.375rem",
  padding: "0.125rem 0.375rem",
  background: "#FFF3E0",
  color: "#BF360C",
});

export const InternalChip = styled("div")({
  display: "flex",
  width: "max-content",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "0.375rem",
  padding: "0.125rem 0.375rem",
  background: "#E8F5E9",
  color: "#1B5E20",
});

export const CompanyChipText = styled("div")({
  fontFamily: "Sage UI",
  fontSize: "0.875rem",
  fontWeight: "400",
  lineHeight: "1.3125rem",
});

/*eslint-disable*/
import React, { SetStateAction } from "react";
import { ColumnConfigs, TableInitialConfigs, UserAction, PaginationConfigs, CurrencyConfigType } from "../types/table.types";
import { SnackbarConfigType } from "../../Snackbar/tsx/types";

export const TableDataContext = React.createContext<any>(null);

export type TableDataProviderType = {
  tableConfigs: TableInitialConfigs & {
    rows: { id: string; [key: string]: any }[];
    extraHandlers?: {
      drawerHandler?: (id: string) => void;
      setSnackbarConfig: (config: SnackbarConfigType) => void;
    };
  };
  selectionConfigs: any;
  columnConfigs: ColumnConfigs[];
  paginationConfigs: PaginationConfigs;
  captionConfigs: { header?: string } | undefined;
  filterConfigs: any;
  sortConfigs: any;
  children: React.ReactElement;
  actionConfigs: {
    actionHoverId: UUID;
    setActionHoverId: React.Dispatch<SetStateAction<UUID>>;
  };
  userActions: UserAction[];
  visibility: { exportBtn: boolean; hoverbar: boolean };
  currencyConfig?: CurrencyConfigType;
};

const TableDataProvider: React.FC<TableDataProviderType> = ({ children, ...configs }) => {
  return (
    <TableDataContext.Provider
      value={{
        ...configs,
      }}
    >
      {children}
    </TableDataContext.Provider>
  );
};

export default TableDataProvider;

import React, { useState, MouseEvent, useEffect, useContext, useRef } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import TableUtils from "../../../utils/TableUtils/TableUtils";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../../constants/NumericConstants";
import { automationClientV2, companiesClientV2, emailsClientV2, exportClient } from "../../../db/version2Accessor";
import { Archive, Email, ExportV2, Merge, NoteFill, Phone } from "../../library/Icons/Icons";
import { AlertContext } from "../../../contexts/AlertContext";
import { CustomerContext } from "../../../contexts/CustomerContext";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";
import {
  FallbackTypes,
  ConnectionStatus,
  EmailAction,
  WorkspaceType,
  ActivityOptions,
  VIEW_CATEGORIES,
  EXPORT_CATEGORIES,
} from "../../../types/enums";
import { ActivityContext } from "../../../contexts/ActivityContext";
import { CSVLink } from "react-csv";
import Utils from "../../../utils/utils";
import NewActivityPopup from "../../library/AddNewActivityDropdown/NewActivityPopup";
import { TemplateContext } from "../../../contexts/TemplateContext";
import { TemplateTypes, getViewTypeTemplateCodes, viewType } from "../../../app/Templates/TemplateTypes";
import { TemplateDefinitionProps } from "../../../app/Templates/TemplateFactory";
import { AppContext } from "../../../contexts/AppContext";
import { companiesClient } from "../../../db/accessor";
import { AxiosError } from "axios";
import { CONNECTION_STATUS } from "../../../constants/ConnectionConstants";
import { MergeFlyout } from "../CustomerActions/Merge/Index";
// import { UseTableCellProps } from "react-table";
import TrackingUtils from "../../Tracking/Tracking.Utils";
import { ApplicationRouteContext } from "../../../contexts/ApplicationRouteContext";
import useWorkspaceConfigurations from "../../../hooks/useWorkspaceConfigurations";
import useLocale from "../../../hooks/useLocale";

import { Table, useColumnFilters } from "../../library/AtomicComponents/Table/index";
import { UserAction } from "../../library/AtomicComponents/Table/types/table.types";
import usePagination from "../../library/AtomicComponents/Table/hooks/usePagination";
import useColumnSort from "../../library/AtomicComponents/Table/hooks/useColumnSort";
import useRowSelection from "../../library/AtomicComponents/Table/hooks/useRowSelection";
import { DEFAULT_PAGINATION_VALUES } from "../../../constants/NumericConstants";
import APIClientUtils from "../../../utils/APIClientUtils/APIClientUtils";
import { RowDataTypes } from "../../library/AtomicComponents/constants/table.constants";
import {
  ACTIVITY_COMPLETE_FAILURE,
  ACTIVITY_COMPLETE_SUCCESS,
  ACTIVITY_SUCCESS_TOAST_MSG,
  BTN_NEW_ACTIVITY,
  MY_COMPANY_EMPTY_MESSAGE,
  NO_DATA_MESSAGE,
  OTHER_CONNECTIONS_EMPTY_MESSAGE,
} from "../../../constants/config";
import useContacts from "../../../hooks/useContacts";
import { FeatureFlagContext, FeatureFlagProviderType } from "../../../contexts/FeatureFlagContext";
import { ExportContext } from "../../../contexts/ExportContext";
import { ExportToastMessage } from "../../../constants/ExportConstant";
import ActivityFeedMethods from "../../Activities/ActivityDetail/ActivityFeedV2/helpers/ActivityFeedMethods";
import { useQuery } from "@tanstack/react-query";
import _ from "underscore";
import { RefetchIntervals } from "../../../constants/CacheConfig";
import useHandleVisibility from "../../../hooks/useHandleVisibility";

const CustomerTable = (): React.ReactComponentElement<any> => {
  const { url, path } = useRouteMatch();
  const { configs: currentWorkspaceConfigs, configProvider, pathType } = useWorkspaceConfigurations();
  const params = new URLSearchParams(window.location.search);
  const pageNoFromUrl = parseInt(params.get("page") ?? "1", NUMERIC_VALUES.CONSTANT_TEN) || NUMERIC_VALUES.CONSTANT_ONE;
  const history = useHistory();
  //eslint-disable-next-line
  const [isError, setError] = useState<boolean>(false);
  //eslint-disable-next-line
  const [errorMessage, setErrorMessage] = useState<string>("");
  //eslint-disable-next-line
  const [totalCount, setTotalCount] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const { setToastOptions } = useContext(AlertContext) as AlertContextType;
  const { toggleConnectionUpdate, setCustomerId, viewDetails, setViewDetails, signature, loginUserCompany } = useContext(
    CustomerContext
  ) as CustomerType;
  const { allRowsSelected, handleClose, setAllRowsSelected } = useContext(ActivityContext) as ActivityType;

  // New activity dropdown states
  const [fromTime, setFromTime] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [toTime, setToTime] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [newActivityType, setNewActivityType] = useState<string>("");
  const [showActivity, setShowActivity] = useState<boolean>(false);
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  // export states
  //eslint-disable-next-line
  const [columnFilters, setColumnFilters] = useState<SearchFilter[]>([]);
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [exportData, setExportData] = useState<any>([]);
  const csvInstance = useRef<any | null>(null);

  const { templateFactory, templateData } = React.useContext(TemplateContext) as ITemplateProps;
  const [supportedTemplateList, setSupportedTemplateList] = useState<Map<string, TemplateDefinitionProps[]>>();
  const [selectedCompany, setSelectedCompany] = useState<any>();
  const [companyData, setCompanyData] = useState<CompanyModel>({} as CompanyModel);
  const { userStatus } = React.useContext(AppContext) as AppType;
  const { getContactsOptions, allContactOptions, filterPrimaryContacts } = useContacts();
  const [editorState, setEditorState] = useState<string>("");

  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [openMergeFlyout, setOpenMergeFlyout] = useState<boolean>(false);
  const [selectedRowData, setSelectedRowData] = useState<TableData[]>([]);
  const [containERPConnection, setContainERPConnection] = useState<boolean>(false);
  const [companyContactOptions, setCompanyContactOptions] = useState<To[]>([]);
  const [templateName, setTemplateName] = useState<string>("");

  const configs = selectedWorkspace.workspace_type_route === WorkspaceType.AW ? configProvider[pathType] : currentWorkspaceConfigs;

  const urlTokens = url.split("?")[DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO].split("/");
  const isOtherConnection = path.includes("connections");
  const connectionType = isOtherConnection ? "otherConnectionsV2" : "connectionsV2";
  const isMyCompany = path.includes(configs?.myCompany?.routeType);
  const tableConfigs = configs[isMyCompany ? "myCompanyV2" : connectionType];
  const { availableLanguagesForTemplates, calendarDateFormat } = useLocale();

  const primaryContact = filterPrimaryContacts(companyContactOptions);

  const userView = useRef<CustomerTableView>({
    view: urlTokens[NUMERIC_VALUES.CONSTANT_THREE],
    status: urlTokens[NUMERIC_VALUES.CONSTANT_FOUR] as ConnectionStatus,
  });

  // determine the type of connection and corresponding company_type filter
  const companyTypeFilterVals = Object.create({
    customers: "Customer",
    vendors: "Vendor",
    connections: "Other",
    internal: "Company",
  });

  const companyTypePayload: CompanyTypePayload = React.useMemo(() => {
    if (isMyCompany) {
      return companyTypeFilterVals.internal;
    }
    if (selectedWorkspace.workspace_type_route === WorkspaceType.AR) {
      return companyTypeFilterVals.customers;
    } else if (selectedWorkspace.workspace_type_route === WorkspaceType.AP) {
      return companyTypeFilterVals.vendors;
    } else {
      // accounting workspace
      if (isOtherConnection) {
        return companyTypeFilterVals.connections;
      } else if (path.includes("customers")) {
        return companyTypeFilterVals.customers;
      } else {
        return companyTypeFilterVals.vendors;
      }
    }
  }, [path, selectedWorkspace.workspace_type_route]);

  const initalPageNumber = pageNoFromUrl - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE;
  const { setTotalRecords, setTotalPages, updatePageNumber, ...paginationConfig } = usePagination(
    DEFAULT_PAGINATION_VALUES.PAGE_SIZE,
    initalPageNumber ?? DEFAULT_PAGINATION_VALUES.PAGE_COUNT
  );

  const selectionConfig = useRowSelection(paginationConfig?.page?.totalRecords);
  const { ...initialFilterConfigs } = useColumnFilters(true, [], updatePageNumber);
  const companyType = isOtherConnection ? "connections" : isMyCompany ? "internal" : path.includes("customers") ? "customers" : "vendors";
  const customerTable = tableConfigs.view.all;
  const defaultSortKey =
    isOtherConnection || isMyCompany
      ? "last_activity_at DESC"
      : companyType === "vendors"
      ? "amount_billed_past_thirty_days desc"
      : "amount_past_due DESC";
  const { query: sortQuery, ...sortConfig } = useColumnSort(defaultSortKey);
  const status = (function getStatusForView() {
    if (viewDetails?.view !== userView.current.view) {
      return ConnectionStatus.ACTIVE;
    }
    return userView.current.status;
  })();
  const companyEmptyMessage = `${companyTypeFilterVals[companyType]} data unavailable.`;
  const companyEmptyCaptionMessage = `No ${companyTypeFilterVals[companyType].toLowerCase()} information to be found.`;
  const otherMessage = isOtherConnection ? OTHER_CONNECTIONS_EMPTY_MESSAGE : companyEmptyCaptionMessage;
  const [columnsConfigData, setColumnsConfigData] = useState<any[]>(() => customerTable.columns);
  const [isColumnsConfigDataLoading, setIsColumnsConfigDataLoading] = useState<boolean>(true);
  const { isEnabled } = React.useContext(FeatureFlagContext) as FeatureFlagProviderType;
  const isFiltersEnabled = isEnabled("FILTERS_ENABLE");
  const isExportV2Enabled = isEnabled("EXPORTS_V2");
  const [isNoSearchResultFound, setIsNoSearchResultFound] = useState<boolean>(false);
  const { updateExportedRecordsIds } = React.useContext(ExportContext) as ExportType;
  const [deselectedRowsFilter, setDeselectedRowsFilter] = useState<string>("");
  const selectedRowsCount = selectionConfig?.getSelectedRowsCount();
  const filterObjFromUrl = JSON.parse(params.get("filters") ?? "{}");
  const appliedFilters = initialFilterConfigs.generateFilterQuery(Object.values(filterObjFromUrl), true);
  /**
   * setup common state with reducer to handle open/close states for send and send and close button
   * React useState hooks.
   */
  const initialVisibility = {
    isSendBtnDisabled: false,
  };
  const { visibility, dispatch } = useHandleVisibility<typeof initialVisibility>({ isSendBtnDisabled: false });
  const isTemplateWorkflowV2Enabled = isEnabled("TEMPLATE_WORKKFLOW_V2");

  //it return category view name for suggestion history
  const getViewCategory = () => {
    const categoryMap: { [key: string]: VIEW_CATEGORIES } = {
      "internal/active": VIEW_CATEGORIES.ACTIVE_INTERNAL,
      "internal/archived": VIEW_CATEGORIES.ARCHIVED_INTERNAL,
      "connections/active": VIEW_CATEGORIES.ACTIVE_OTHER,
      "connections/archived": VIEW_CATEGORIES.ARCHIVED_OTHER,
      "connections/spam": VIEW_CATEGORIES.SPAM_OTHER,
      "connections/fraud": VIEW_CATEGORIES.FRAUD_OTHER,
      "vendors/active": VIEW_CATEGORIES.ACTIVE_VENDOR,
      "vendors/archived": VIEW_CATEGORIES.ARCHIVED_VENDOR,
      "vendors/spam": VIEW_CATEGORIES.SPAM_VENDOR,
      "vendors/fraud": VIEW_CATEGORIES.FRAUD_VENDOR,
      "customers/active": VIEW_CATEGORIES.ACTIVE_CUSTOMER,
      "customers/archived": VIEW_CATEGORIES.ARCHIVED_CUSTOMER,
      "customers/spam": VIEW_CATEGORIES.SPAM_CUSTOMER,
      "customers/fraud": VIEW_CATEGORIES.FRAUD_CUSTOMER,
    };

    for (const pathKey in categoryMap) {
      if (path.includes(pathKey)) {
        return categoryMap[pathKey];
      }
    }

    return VIEW_CATEGORIES.FRAUD_CUSTOMER;
  };

  useEffect(() => {
    setViewDetails({ view: userView.current.view, status });
  }, [userView.current]);

  useEffect(() => {
    setTemplateName("");
  }, [newActivityType]);

  const { updateBaseRoute } = React.useContext(ApplicationRouteContext) as ApplicationRouteType;

  const fetchCompanyInfo = (companyId: UUID) => {
    if (!companyId) {
      return;
    }
    companiesClient.getCompany(companyId, "Classification").then((companyModel: CompanyModel) => {
      setCompanyData(companyModel);
    });
  };

  const fetchParser = (fetchResult: ConnectionsSummaryModelFetchResultV2, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.records?.map((record: CustomerSummaryModelV2 | VendorSummaryModelV2) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: (record?.company_id || record?.vendor_id) as string,
          }),
          ...((variant === "export" || variant === "all") && {
            company_name: record?.company_name,
            primary_contact: record?.primary_contact,
            contact_name: record?.contact_name,
            last_activity_at: (record?.last_activity_at as number) || null,
            outstanding_invoices: record?.outstanding_invoices as number,
            outstanding_amount: record?.outstanding_amount as number,
            amount_past_due: record?.amount_past_due as number,
            vendor_name: record?.vendor_name,
            primary_contact_name: record?.primary_contact_name,
            open_bill_count: record.open_bill_count as number,
            amount_billed_outstanding: record?.amount_billed_outstanding as number,
            amount_billed_past_thirty_days: record?.amount_billed_past_thirty_days as number,
            email_address: record?.email_address,
            app_enrollment_id: record?.app_enrollment_id || null,
          }),
          ...(variant === "all" && {
            disableCheckbox: undefined,
            isUnread: undefined,
          }),
        };
      }) ?? []
    );
  };

  const getFilters = (exportConnection = false, ids?: string[]) => {
    const otherAdditionFilters: { searchlightFilter: string }[] = [
      {
        searchlightFilter: `status=${status}`,
      },
      {
        searchlightFilter: `workspace_id=${selectedWorkspace?.id || FallbackTypes.Id}`,
      },
      {
        searchlightFilter: `qa[app_enrollment_id${isOtherConnection ? "" : "_not"}_null]=1`,
      },
    ];

    const myCompanyAdditionFilter: { searchlightFilter: string }[] = [
      {
        searchlightFilter: `status=${status}`,
      },
      {
        searchlightFilter: `workspace_id=${selectedWorkspace?.id || FallbackTypes.Id}`,
      },
    ];
    const companyTypeFilter: { searchlightFilter: string }[] = [
      {
        searchlightFilter: `company_type=${companyTypeFilterVals[companyType]}`,
      },
    ];
    const additionalFilters = isMyCompany ? myCompanyAdditionFilter : otherAdditionFilters;
    // evaluate export filters - consider ids only if all rows are not selected

    if (exportConnection && !selectionConfig?.isAllRecordsSelected && ids && ids.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      const { hasAllRecordsSelectedButtonClicked, isAllRecordsSelected, tableRecords } = selectionConfig;

      const exportIds =
        hasAllRecordsSelectedButtonClicked?.current && !isAllRecordsSelected
          ? tableRecords?.allRecords?.filter((item) => !item?.isSelected)?.map((deselectedItem) => deselectedItem?.id)
          : ids;

      const keyId = companyType === "vendors" ? "vendor_id" : "company_id";

      exportIds.forEach((id) => {
        const searchlightFilter = `qa[${keyId}_${hasAllRecordsSelectedButtonClicked?.current && !isAllRecordsSelected ? "not_in" : "in"}][]=${id}`;
        additionalFilters.push({
          searchlightFilter,
        });
      });
    }
    const filterQuery = TableUtils.getFilterFromURL(params, customerTable.columns);
    return TableUtils.columnFilterParser(
      filterQuery,
      true,
      additionalFilters ? [...additionalFilters, ...(!isMyCompany ? companyTypeFilter : [])] : undefined
    );
  };

  /**
   * Function that customises the sortquery depending on conditions for Customer Table
   * @returns String of sortQuery
   */
  const getSortQuery = (): string => {
    let sortQueryString = TableUtils.getSortQueryString(params, sortQuery);
    const hasLastActivtySorting = sortQueryString?.includes("last_activity_at");
    if (hasLastActivtySorting) {
      sortQueryString = `${sortQueryString} NULLS LAST`;
    }

    return sortQueryString;
  };

  /**
   * Company table index call -
   * company_type is mandatory for ERP & Non-ERP connections. It's not
   * required for my company connections.
   */
  const fetchConnections = (variant: FetchVariant, columnFilters?: string, pageNumber?: number, pageSize?: number) => {
    const filters = [getFilters() || "", columnFilters].filter(Boolean).join("&");
    const sortQueryString = getSortQuery();

    //API call baseed on if its a vendor/customer or other connection
    return isOtherConnection
      ? companiesClientV2
          .getOtherConnectionData(
            filters,
            TableUtils.sortQueryParser(sortQueryString ?? "", true),
            pageSize ?? paginationConfig.page?.pageSize ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            pageNumber ?? paginationConfig.page?.pageNumber ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            selectedWorkspace.id
          )
          .then((fetchResult: any) => {
            return { ...fetchResult, records: fetchParser(fetchResult, variant) };
          })
          .catch((error: any) => {
            setError(true);
            setErrorMessage(APIClientUtils.buildErrorMessage(error));
          })
      : companiesClientV2
          .getConnectionSummariesData(
            filters,
            TableUtils.sortQueryParser(sortQueryString ?? "", true),
            pageSize ?? paginationConfig.page?.pageSize ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            pageNumber ?? paginationConfig.page?.pageNumber ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            selectedWorkspace.id
          )
          .then((fetchResult: any) => {
            return { ...fetchResult, records: fetchParser(fetchResult, variant) };
          })
          .catch((error: any) => {
            setError(true);
            setErrorMessage(APIClientUtils.buildErrorMessage(error));
          });
  };

  const fetchCompanyList = async (searchText: string, page?: number) => {
    return companiesClientV2.getCustomersList(
      ConnectionStatus.ACTIVE,
      "qs[s]=company_name desc",
      NUMERIC_VALUES.CONSTANT_TWENTY,
      page ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
      selectedWorkspace.id || FallbackTypes.Id,
      selectedWorkspace.workspace_type_route === WorkspaceType.AW
        ? ["Vendor", "Customer", "Other"]
        : pathType === WorkspaceType.AP
        ? ["Vendor"]
        : ["Customer"],
      undefined,
      searchText ? `qa[company_name_cont]=${searchText}` : undefined
    );
  };

  const fetchCompanyListFunc = async (searchText: string, page: number): Promise<{ records: CompanyV2[]; totalRecords: number }> => {
    const response = await fetchCompanyList(searchText, page);
    return { records: response.records ?? [], totalRecords: response.totalCount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO };
  };

  const fetchMyCompany = (variant: FetchVariant, columnFilters?: string, pageNumber?: number, pageSize?: number) => {
    const filters = [getFilters() || "", columnFilters].filter(Boolean).join("&");
    const sortQueryString = getSortQuery();

    return companiesClientV2
      .getMyComanyData(
        filters,
        TableUtils.sortQueryParser(sortQueryString ?? "", true),
        pageSize ?? paginationConfig.page?.pageSize ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
        pageNumber ?? paginationConfig.page?.pageNumber ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
      )
      .then((fetchResult: any) => {
        return { ...fetchResult, records: fetchParser(fetchResult, variant) };
      })
      .catch((error: any) => {
        setError(true);
        setErrorMessage(APIClientUtils.buildErrorMessage(error));
      });
  };

  const queryKey = `connections-index-${selectedWorkspace?.workspace_type_route}-${getViewCategory()}`;

  const {
    isLoading,
    data: cachedData,
    refetch,
  } = useQuery(
    [queryKey, paginationConfig.page?.pageNumber, paginationConfig.page?.pageSize, getSortQuery(), appliedFilters, isMyCompany],
    async () => (isMyCompany ? await fetchMyCompany("all", appliedFilters) : await fetchConnections("all", appliedFilters)),
    {
      refetchOnMount: true,
      refetchInterval: RefetchIntervals.twoHoursDelay,
    }
  );

  useEffect(() => {
    /**
     * update the page number once user perform any action and because of that total records get's changed
     */
    const totalPages = Math.ceil(cachedData?.totalCount / cachedData?.pageSize) ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
    if ((paginationConfig.page?.pageNumber || NUMERIC_VALUES.CONSTANT_ONE) > totalPages) {
      updatePageNumber(totalPages || NUMERIC_VALUES.CONSTANT_ONE);
    }
    setTotalRecords(cachedData?.totalCount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    setTotalPages(totalPages);
    setTotalCount(cachedData?.totalCount);
  }, [cachedData]);

  const handleRowClick = (
    e: MouseEvent<HTMLTableRowElement>,
    row: {
      id?: string;
    },
    currentPageRecords?: { id: string; isSelected?: boolean }[]
  ) => {
    /**
     * If a row item is already selected, then clicking anywhere on other rows should select those rows, and do not navigate.
     * if double clicked, then navigate to those row records
     */
    const clickType = e.detail;
    if (clickType === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE && selectionConfig.tableRecords?.selectedRecordsIds?.length && row.id) {
      selectionConfig?.onClickTableRow(row?.id, currentPageRecords ?? []);
      return;
    }

    sessionStorage.setItem("lastPath", history.location.pathname);
    sessionStorage.setItem("lastPage", (paginationConfig.page?.pageNumber ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO).toString());
    history.push(`${path}/${row.id ?? ""}`);
    updateBaseRoute(`${path}/${row.id ?? ""}`);
    setCustomerId(row?.id ?? "");
  };

  useEffect(() => {
    fetchCompanyInfo(userStatus.account_company_id as string);
    return () => {
      setTotalCount(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    };
  }, []);

  useEffect(() => {
    setAllRowsSelected(selectionConfig?.isAllRecordsSelected || !!selectionConfig?.hasAllRecordsSelectedButtonClicked?.current);
  }, [selectionConfig?.isAllRecordsSelected]);

  useEffect(() => {
    const { hasAllRecordsSelectedButtonClicked, isAllRecordsSelected, tableRecords } = selectionConfig;

    if (!hasAllRecordsSelectedButtonClicked?.current || isAllRecordsSelected || !tableRecords?.allRecords) {
      if (deselectedRowsFilter) {
        setDeselectedRowsFilter("");
      }
      return;
    }

    const deselectedIds = tableRecords?.allRecords?.filter((item) => !item?.isSelected)?.map((deselectedItem) => deselectedItem?.id);
    let filter = "";
    const isNotActive = [ConnectionStatus.ARCHIVED, ConnectionStatus.FRAUD, ConnectionStatus.SPAM].includes(status as ConnectionStatus);
    const keyId = isNotActive && !isMyCompany ? "connection_id" : "company_id";
    deselectedIds.forEach((id) => {
      filter = filter ? `${filter}&qa[${keyId}_not_in][]=${id}` : `qa[${keyId}_not_in][]=${id}`;
    });

    setDeselectedRowsFilter(filter);
  }, [selectionConfig?.tableRecords?.selectedRecordsIds]);

  useEffect(() => {
    if (
      (exportData?.length ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO &&
      csvInstance &&
      csvInstance.current &&
      csvInstance.current.link
    ) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setExportData("");
      });
    }
  }, [exportData]);

  /**
   * triggers on row select or de-select, checks whether selected records has
   * any ERP connection in them. if yes, set flag for ERP connection to true.
   * This flag is used to enable or disable the merge icon and update it's tooltip
   * text.
   */
  useEffect(() => {
    const erpConnections = selectedRowData?.filter((item) => item.app_enrollment_id && item.app_enrollment_id !== null);
    setContainERPConnection(erpConnections.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  }, [selectedRowData]);

  const getColumnFilterPayloadObject = (filters: SearchFilter[]) => {
    const filterPayload = { qa: {} } as Record<string, Record<string, string>>;
    if (filters && filters.length) {
      filters.forEach((filter: SearchFilter) => {
        filterPayload["qa"][`${filter.filterKey}_${filter.filterToken}`] = `${filter.filterValue}`;
      });
    }
    return filterPayload;
  };

  const attachSelectedAllPayload = (payload: UpdateCustomerStatusPayload) => {
    let bulkPayload = {
      ...payload,
      select_all: true,
      filter_status: status,
    };
    bulkPayload.connection_ids = []; // make ids array blank
    bulkPayload.qa = {
      [`app_enrollment_id${isOtherConnection ? "" : "_not"}_null`]: 1,
    };
    if (columnFilters && columnFilters.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      bulkPayload = {
        ...bulkPayload,
        ...getColumnFilterPayloadObject(columnFilters),
      };
    }
    return bulkPayload;
  };

  const handleConnectionStatusUpdate = async (ids: string[], action: string) => {
    let response: APIResponse = {} as APIResponse;
    let toastOptions: ToastOptions = { open: true, severity: "success", message: "" };

    const createMessage = (actionTaken: string, noOfRecords: number) => {
      return noOfRecords > DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
        ? `${noOfRecords} connections are marked as ${actionTaken} successfully`
        : `Connection is marked as ${actionTaken} successfully`;
    };

    let payload = {
      connection_ids: ids,
      status: action,
      workspace_id: selectedWorkspace?.id || FallbackTypes.Id,
      filter_status: status,
      company_type: companyTypePayload,
    };
    payload = allRowsSelected ? attachSelectedAllPayload(payload) : payload;
    const bulkActivitiesCount = selectedRowsCount || ids?.length;

    try {
      if (isMyCompany && (action === ConnectionStatus.ARCHIVED || action === ConnectionStatus.ACTIVE)) {
        response = await companiesClientV2.updateMyCompanyStatus(payload, deselectedRowsFilter);
        toastOptions = {
          ...toastOptions,
          message: createMessage(action, bulkActivitiesCount),
        };
      } else {
        response = await companiesClientV2.updateCustomer(payload, deselectedRowsFilter);
        toastOptions = {
          ...toastOptions,
          message: createMessage(action, bulkActivitiesCount),
        };
      }
    } finally {
      if (!response.success) {
        toastOptions = { ...toastOptions, severity: "error", message: "Something went wrong!" };
      }
      setToastOptions(toastOptions);
      selectionConfig.resetTableRecords();
      refetch();
      if (response.success) {
        toggleConnectionUpdate();
      }
    }
  };

  const handleExportCustomer = async (ids: string[]) => {
    let response: APIResponse = {} as APIResponse;
    const filterStrings = [getFilters(true, ids) || "", initialFilterConfigs?.filterQuery].filter(Boolean);
    const finalAppliedFilter = filterStrings.join("&");

    try {
      setExportLoading(true);
      if (isMyCompany) {
        response = await companiesClientV2.exportMyCompany(finalAppliedFilter);
      } else {
        response = isOtherConnection
          ? await companiesClientV2.exportOtherConnections(finalAppliedFilter)
          : await companiesClientV2.exportConnections(finalAppliedFilter);
      }
    } finally {
      setExportData(response);
      setExportLoading(false);
    }
  };

  const getExportCatgoryType = () => {
    if (isMyCompany) {
      return EXPORT_CATEGORIES.MY_COMPANY;
    } else if (isOtherConnection) {
      return EXPORT_CATEGORIES.OTHER_CONNECTION;
    } else {
      const isAPWorkspace =
        selectedWorkspace.workspace_type === "accounting" ? url?.includes("vendor") : selectedWorkspace.workspace_type === "accounts_payable";

      return isAPWorkspace ? EXPORT_CATEGORIES.VENDOR : EXPORT_CATEGORIES.CUSTOMER;
    }
  };

  const handleExportV2 = async (ids: string[]) => {
    let response: APIResponse = {} as APIResponse;
    const filters = [getFilters(true, ids) || "", appliedFilters].filter(Boolean).join("&");
    try {
      setExportLoading(true);
      const isExportAllRecords =
        (selectionConfig?.tableRecords?.selectedRecordsIds?.length ?? NUMERIC_VALUES.CONSTANT_ZERO) > NUMERIC_VALUES.CONSTANT_ZERO
          ? selectionConfig.isAllRecordsSelected || !!selectionConfig?.hasAllRecordsSelectedButtonClicked?.current
          : true;
      const sortQueryString = getSortQuery();

      response = await exportClient.exportRecords(
        getExportCatgoryType(),
        isExportAllRecords,
        filters,
        TableUtils.sortQueryParser(sortQueryString ?? "", true)
      );
      if (response.success) {
        setToastOptions({ open: true, severity: "success", message: ExportToastMessage.EXPORT_IN_PROGRESS });
        updateExportedRecordsIds([response.data?.export_request_id], false);
      }
    } catch (error) {
      setToastOptions({ open: true, severity: "error", message: ExportToastMessage.EXPORT_FAILED });
    } finally {
      setExportLoading(false);
    }
  };

  const handleConnectionMerge = async (ids: string[]) => {
    setSelectedRowIds(ids);
    setOpenMergeFlyout(true);
  };

  const markArchiveRowButton = {
    icon: <Archive />,
    alignIcon: "left",
    children: <>Archive</>,
    tooltipLabel: "Archive Connection",
    callback: async (ids: string[]) => {
      await handleConnectionStatusUpdate(ids, ConnectionStatus.ARCHIVED);
    },
    visible: {
      onHeader: true,
      onHover: false,
    },
  } as UserAction;

  const markActiveRowButton = {
    children: (function getLabel() {
      return status === ConnectionStatus.ARCHIVED ? "Unarchive" : status === ConnectionStatus.FRAUD ? "Not Fraud" : "Not Spam";
    })(),
    callback: async (ids: string[]) => {
      await handleConnectionStatusUpdate(ids, ConnectionStatus.ACTIVE);
    },
    visible: {
      onHeader: true,
      onHover: false,
    },
  } as UserAction;

  const mergeConnectionButton = {
    icon: <Merge />,
    alignIcon: "left",
    children: <>Merge</>,
    tooltipLabel: containERPConnection ? "ERP-created Connections cannot be merged" : "Merge Connection",
    disabled: containERPConnection,
    callback: async (ids: string[]) => {
      await handleConnectionMerge(ids);
    },
    visible: {
      onHeader: true,
      onHover: false,
    },
  } as UserAction;

  const rowSelectHeaderBtn = [
    {
      children: "Export",
      icon: <ExportV2 />,
      alignIcon: "left",
      type: "button",
      loading: exportLoading,
      callback: (ids: string[]) => {
        isExportV2Enabled ? handleExportV2(ids) : handleExportCustomer(ids);
      },
      visible: {
        onEllipsis: false,
        onHeader: false,
        onHover: false,
        onCaption: true,
      },
    },
  ] as UserAction[];

  const isNotActive = [ConnectionStatus.ARCHIVED, ConnectionStatus.FRAUD, ConnectionStatus.SPAM].includes(status as ConnectionStatus);

  const rowSelectBtns: UserAction[] = [
    ...(isNotActive ? [markActiveRowButton] : [markArchiveRowButton, ...(isOtherConnection ? [mergeConnectionButton] : [])]),
    ...rowSelectHeaderBtn,
  ];

  // check if template code is required as content-type
  const needTemplateCodeAsPayload = React.useMemo(
    () =>
      [
        TemplateTypes.AR_CREATE_CUSTOMER_PROFILE,
        TemplateTypes.AR_UPDATE_CUSTOMER_PROFILE,
        TemplateTypes.AP_CREATE_VENDOR_PROFILE,
        TemplateTypes.AP_CREATE_VENDOR_PROFILE,
      ].includes(templateName as TemplateTypes),
    [templateName]
  );

  const handleSend = async (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    attachmentIds: AttachmentIds,
    inboxAttachments: TransactionItemType[],
    isSendAndClose?: boolean
  ): Promise<ActivityStream> => {
    dispatch({ type: "open", payload: "isSendBtnDisabled" });
    const getEmailArray = (emailArray: To[]) => emailArray.filter((item) => item !== undefined).map((value: To) => value.id || value.label);
    let toastOptions: ToastOptions = { open: true, severity: "success", message: ACTIVITY_SUCCESS_TOAST_MSG };
    const payload: Partial<ActivityItemEmailModel> = {
      to: getEmailArray(to),
      cc: getEmailArray(cc),
      bcc: getEmailArray(bcc),
      subject: title,
      content: body,
      email_action: EmailAction.NEW,
      workspace_id: selectedWorkspace?.id || FallbackTypes.Id,
      connection_company_id: selectedCompany.id ?? "",
      content_type: ActivityFeedMethods.evaluateContentVariantPayload(newActivityType ?? "", needTemplateCodeAsPayload ? templateName : ""),
      attachments: attachmentIds.filter((item) => typeof item === "number"),
      activity_type:
        newActivityType && newActivityType == "phone_call"
          ? "call_log"
          : newActivityType && newActivityType === "payment_reminder"
          ? "email"
          : newActivityType,
      activity_transactions: inboxAttachments,
      primary_connection_id: selectedCompany.id,
      attach_pdf: Utils.isThereAnyAttachment(attachmentIds, inboxAttachments),
    };

    return new Promise((resolve) => {
      emailsClientV2
        .post(payload)
        .then(async (emailResponse: ActivityStream) => {
          //Only call Automation API when email is sent successfully and Template is selected
          if (templateName && emailResponse.success) {
            let reqBody = {
              to_time: toTime,
              automation_type: "email_templates",
              automation_sub_type: templateName,
              resource_type: "Activity::Email",
              resource_id: emailResponse.data.id,
            } as AutomateTimeModel;

            if (fromTime != DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
              reqBody = { ...{ from_time: fromTime }, ...reqBody };
            }
            setToTime(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
            automationClientV2.post(selectedWorkspace?.id || FallbackTypes.Id, reqBody);
          }

          TrackingUtils.trackFSData(
            to,
            "New Activity Stream",
            "New",
            isSendAndClose ?? false,
            allContactOptions,
            tableConfigs.view.all.tableConfig.displayName,
            path,
            newActivityType ?? "",
            body
          );
          setShowActivity(false);
          //Fetch latest contact (newly added) options to populate in activity pop up if user tries to reply on the same
          getContactsOptions();
          resolve(emailResponse ?? []);
        })
        .catch((err: AxiosError) => {
          toastOptions.severity = "error";
          if (err.response?.status === CONNECTION_STATUS.BAD_REQUEST_400.STATUS_CODE) {
            toastOptions = { ...toastOptions, severity: "error", message: "Messages not sent. Please check that an Email Connector is connected." };
          } else {
            const errorResponse = err.response as AxiosErrorResponseData;
            toastOptions = { ...toastOptions, message: errorResponse?.data?.messages?.errors[0] ?? "Messages not sent." };
          }
        })
        .finally(() => {
          setToastOptions(toastOptions);
          dispatch({ type: "close", payload: "isSendBtnDisabled" });
        });
    });
  };

  const handleSendAndMarkClosed = async (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    attachmentIds: AttachmentIds,
    inboxAttachments: TransactionItemType[]
  ) => {
    const sendEmailResponse = await handleSend(to, cc, bcc, title, body, attachmentIds, inboxAttachments, true);
    if (sendEmailResponse.success) {
      let toastOptions: ToastOptions = { open: true, severity: "success", message: ACTIVITY_COMPLETE_SUCCESS };
      let response = {} as APIResponse;

      try {
        response = await handleClose(sendEmailResponse.data.activity_stream.id, "");
      } catch (e: unknown) {
        response.success = false;
      } finally {
        if (!response.success) {
          toastOptions = {
            ...toastOptions,
            severity: "error",
            message: ACTIVITY_COMPLETE_FAILURE,
          };
        }
        setToastOptions(toastOptions);
      }
    }
  };

  /**
   * Function which convert the template string to editor content state.
   *
   * @param {string} templateID The templateID selected by user from the subject drop down.
   * @param {EditorState} EditorState The setEditorState will update the email body.
   */
  const prepareActivityBodyByTemplateID = (templateID: string | null, setEditorState: React.Dispatch<React.SetStateAction<string>>, lang: string) => {
    if (templateID) {
      setTemplateName(templateID);
      const templateObj = templateData.get(selectedWorkspace?.id)?.get(lang)?.get(templateID);
      setFromTime(templateObj.getFromTime);
      setToTime(templateObj.getToTime);
      setEditorState(
        templateObj.parseTemplate({
          customer: selectedCompany?.company_name || selectedCompany?.vendor_name || "",
          vendor: selectedCompany?.company_name || selectedCompany?.vendor_name || "",
          pastDueAmount: Utils.formatValueAsCurrency(
            selectedCompany.amount_past_due ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            userStatus?.currency?.locale,
            userStatus?.currency?.code
          ),
          accountTotalBalance: Utils.formatValueAsCurrency(
            selectedCompany.outstanding_amount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            userStatus?.currency?.locale,
            userStatus?.currency?.code
          ),
          emailAddress: companyData?.emailAddress || "",
          companyName: companyData?.companyName || "",
          phone: companyData?.phoneNumber || "",
          signature: signature?.email_signature || "",
          contactName: primaryContact[0]?.label || selectedCompany?.company_name || selectedCompany?.vendor_name,
        })
      );
    }
  };

  /**
   *
   * @param customerId
   */
  const fetchCompanyContactOptions = async (customerId: string) => {
    setCompanyContactOptions(await getContactsOptions(customerId));
  };

  /**
   * Function which help's to give template list for the subject drop down
   * based on view type.
   *
   * @param {Object} row The selected row record from table
   * @returns {TemplateDefinitionProps[]} The template definition array of objects.
   */
  const getTemplateDefinitionsByView = (row: any): Map<string, TemplateDefinitionProps[]> => {
    // assign number outside the viewType enum range
    let view: viewType = 100;

    if (userView.current.view === "customers") {
      view = viewType.AR_ALL_CUSTOMER_CONNECTIONS;
    } else if (userView.current.view === "vendors") {
      view = viewType.AP_ALL_VENDOR_CONNECTIONS;
    }
    return templateFactory.getTemplateDefinitionsByView(
      view,
      {
        invoiceNumber: row.invoice_number,
        customer: row.company_name || row.vendor_name,
        companyName: loginUserCompany?.companyName ?? "",
        vendor: row.company_name || row.vendor_name,
      },
      availableLanguagesForTemplates
    );
  };

  /**
   * Event handler which triggered when user select the 'email', 'note' or 'phone call'
   * context menu item. It will open the new activity dialog based to the context menu item
   * selected by the user.
   *
   * @param {Object} data The data of selected row from the table.
   * @param {String} buttonId The buttonId indicate which button has been selected by the user.
   */
  const onClickActionButton = async (data: any, buttonId: string) => {
    setSelectedCompany(data);
    if (buttonId === "email") {
      setSupportedTemplateList(getTemplateDefinitionsByView(data));
    }

    if (buttonId !== "note") {
      await fetchCompanyContactOptions(data.id);
    }
    setNewActivityType(buttonId);
    setShowActivity(true);
  };

  const options = {
    spam: [
      {
        value: "Not Spam",
        id: "notSpam",
        clickFunction: (data: TableData) => {
          handleConnectionStatusUpdate([data.id], ConnectionStatus.ACTIVE);
        },
      },
    ],
    fraud: [
      {
        value: "Not Fraud",
        id: "notFraud",
        clickFunction: (data: TableData) => {
          handleConnectionStatusUpdate([data.id], ConnectionStatus.ACTIVE);
        },
      },
    ],
    archived: [
      {
        value: "Unarchive",
        id: "new",
        clickFunction: (data: TableData) => {
          handleConnectionStatusUpdate([data.id], ConnectionStatus.ACTIVE);
        },
      },
    ],
    active: [
      {
        value: "New Email",
        id: "email",
        clickFunction: onClickActionButton,
      },
      {
        value: "New Note",
        id: "note",
        clickFunction: onClickActionButton,
      },
      {
        value: "New Phone Call",
        id: "phone_call",
        clickFunction: onClickActionButton,
      },
    ],
  };

  const actionItems = options[status as keyof typeof options];

  // table configuration
  const cellRendererMapping: CellRendererMappingObject = {
    "RowDataTypes.BoldText": RowDataTypes.BoldText,
    "RowDataTypes.DateEpoch": RowDataTypes.DateEpoch,
    "RowDataTypes.ActionsDropdown": RowDataTypes.ActionsDropdown,
    "RowDataTypes.Currency": RowDataTypes.Currency,
  };

  const tableName = path.includes("internal")
    ? "Internal Connection"
    : path.includes("customers")
    ? "Customer"
    : path.includes("vendors")
    ? "Vendor"
    : "Connection";

  useEffect(() => {
    setSelectedRowData(selectionConfig.tableRecords?.selectedRecords);
  }, [selectionConfig.tableRecords?.selectedRecords]);

  const getPreviousSuggestedData = (recentSearchHistory: FilterDropdownSuggestionRef) => {
    const { filterSearchedText } = initialFilterConfigs ?? {};
    const { company_name, vendor_name, primary_contact, primary_contact_name, email_address, contact_name } = filterSearchedText || {};

    return {
      customerOrConnectionName:
        company_name?.value?.length >= NUMERIC_VALUES.CONSTANT_THREE
          ? recentSearchHistory?.company_name?.filter((item) => initialFilterConfigs?.activeFilters?.company_name?.ids?.includes(item.id))
          : recentSearchHistory?.company_name,
      customerContact:
        primary_contact?.value?.length >= NUMERIC_VALUES.CONSTANT_THREE
          ? recentSearchHistory?.primary_contact?.filter((item) => initialFilterConfigs?.activeFilters?.primary_contact?.ids?.includes(item.id))
          : recentSearchHistory?.primary_contact,
      vendorName:
        vendor_name?.value?.length >= NUMERIC_VALUES.CONSTANT_THREE
          ? recentSearchHistory?.vendor_name?.filter((item) => initialFilterConfigs?.activeFilters?.vendor_name?.ids?.includes(item.id))
          : recentSearchHistory?.vendor_name,
      vendorContact:
        primary_contact_name?.value?.length >= NUMERIC_VALUES.CONSTANT_THREE
          ? recentSearchHistory?.primary_contact_name?.filter((item) =>
              initialFilterConfigs?.activeFilters?.primary_contact_name?.ids?.includes(item.id)
            )
          : recentSearchHistory?.primary_contact_name,
      connectionEmail:
        email_address?.value?.length >= NUMERIC_VALUES.CONSTANT_THREE
          ? recentSearchHistory?.email_address?.filter((item) => initialFilterConfigs?.activeFilters?.email_address?.ids?.includes(item.id))
          : recentSearchHistory?.email_address,
      connectionContact:
        contact_name?.value?.length >= NUMERIC_VALUES.CONSTANT_THREE
          ? recentSearchHistory?.contact_name?.filter((item) => initialFilterConfigs?.activeFilters?.contact_name?.ids?.includes(item.id))
          : recentSearchHistory?.contact_name,
    };
  };

  //it will return maximum of 5 required formated record for dropdown search suggestion or result data
  const getDropdownSearchFilterData = (activitiesTableData: any) => {
    const requiredColumns = [...customerTable.columns];
    const { filterSearchedText = {}, getSearchSuggestionHistory } = initialFilterConfigs || {};
    const { company_name, vendor_name, primary_contact, primary_contact_name, email_address, contact_name } = filterSearchedText;

    const recentConnectionSearchedData = getSearchSuggestionHistory(
      selectedWorkspace.workspace_type_route?.toLocaleLowerCase() ?? WorkspaceType.AR,
      getViewCategory()
    );
    const previousData = getPreviousSuggestedData(recentConnectionSearchedData);

    /**
     * if user searching for result then return result data along with previous selected data
     * else return max of 5 recent searched data if that is exist
     */
    const connectionNameData =
      !company_name?.value || company_name?.value.length < NUMERIC_VALUES.CONSTANT_THREE
        ? (previousData?.customerOrConnectionName ?? []).slice(NUMERIC_VALUES.CONSTANT_ZERO, NUMERIC_VALUES.CONSTANT_FIVE)
        : [...(previousData?.customerOrConnectionName ?? []), ...(activitiesTableData ?? [])];

    const customerContactData =
      !primary_contact?.value || primary_contact?.value.length < NUMERIC_VALUES.CONSTANT_THREE
        ? (previousData?.customerContact ?? []).slice(NUMERIC_VALUES.CONSTANT_ZERO, NUMERIC_VALUES.CONSTANT_FIVE)
        : [...(previousData?.customerContact ?? []), ...(activitiesTableData ?? [])];

    const vendorNameData =
      !vendor_name?.value || vendor_name?.value.length < NUMERIC_VALUES.CONSTANT_THREE
        ? (previousData?.vendorName ?? []).slice(NUMERIC_VALUES.CONSTANT_ZERO, NUMERIC_VALUES.CONSTANT_FIVE)
        : [...(previousData?.vendorName ?? []), ...(activitiesTableData ?? [])];

    const vendorContactData =
      !primary_contact_name?.value || primary_contact_name?.value.length < NUMERIC_VALUES.CONSTANT_THREE
        ? (previousData?.vendorContact ?? []).slice(NUMERIC_VALUES.CONSTANT_ZERO, NUMERIC_VALUES.CONSTANT_FIVE)
        : [...(previousData?.vendorContact ?? []), ...(activitiesTableData ?? [])];
    const connectionEmailData =
      !email_address?.value || email_address?.value.length < NUMERIC_VALUES.CONSTANT_THREE
        ? (previousData?.connectionEmail ?? []).slice(NUMERIC_VALUES.CONSTANT_ZERO, NUMERIC_VALUES.CONSTANT_FIVE)
        : [...(previousData?.connectionEmail ?? []), ...(activitiesTableData ?? [])];

    const connectionContactData =
      !contact_name?.value || contact_name?.value.length < NUMERIC_VALUES.CONSTANT_THREE
        ? (previousData?.connectionContact ?? []).slice(NUMERIC_VALUES.CONSTANT_ZERO, NUMERIC_VALUES.CONSTANT_FIVE)
        : [...(previousData?.connectionContact ?? []), ...(activitiesTableData ?? [])];

    const customerOrConnectionDropdownList = connectionNameData
      .filter(({ company_name, primaryText }) => company_name || primaryText)
      .map(({ id, company_name, primaryText }) => ({
        id,
        primaryText: company_name ?? primaryText,
        isAttachemntRequired: false,
        selected: initialFilterConfigs?.activeFilters?.company_name?.ids?.includes(id) ?? false,
        secondaryText: "",
        isAvatarRequired: true,
      }));

    const customerContactDropdownList = customerContactData
      .filter(({ primary_contact, primaryText }) => primary_contact || primaryText)
      .map(({ id, primary_contact, primaryText }) => ({
        id,
        primaryText: primary_contact ?? primaryText,
        isAttachemntRequired: false,
        selected: initialFilterConfigs?.activeFilters?.primary_contact?.ids?.includes(id) ?? false,
        secondaryText: "",
        isAvatarRequired: true,
      }));

    const vendorNameDropdownList = vendorNameData
      .filter(({ vendor_name, primaryText }) => vendor_name || primaryText)
      .map(({ id, vendor_name, primaryText }) => ({
        id,
        primaryText: vendor_name ?? primaryText,
        isAttachemntRequired: false,
        selected: initialFilterConfigs?.activeFilters?.vendor_name?.ids?.includes(id) ?? false,
        secondaryText: "",
        isAvatarRequired: true,
      }));

    const vendorContactDropdownList = vendorContactData
      .filter(({ primary_contact_name, primaryText }) => primary_contact_name || primaryText)
      .map(({ id, primary_contact_name, primaryText }) => ({
        id,
        primaryText: primary_contact_name ?? primaryText,
        isAttachemntRequired: false,
        selected: initialFilterConfigs?.activeFilters?.primary_contact_name?.ids?.includes(id) ?? false,
        secondaryText: "",
        isAvatarRequired: true,
      }));

    const connectionEmailDropdownList = connectionEmailData
      .filter(({ email_address, primaryText }) => email_address || primaryText)
      .map(({ id, email_address, primaryText }) => ({
        id,
        primaryText: email_address ?? primaryText,
        isAttachemntRequired: false,
        selected: initialFilterConfigs?.activeFilters?.email_address?.ids?.includes(id) ?? false,
        secondaryText: "",
        isAvatarRequired: false,
      }));

    const connectionContactDropdownList = connectionContactData
      .filter(({ contact_name, primaryText }) => contact_name || primaryText)
      .map(({ id, contact_name, primaryText }) => ({
        id,
        primaryText: contact_name ?? primaryText,
        isAttachemntRequired: false,
        selected: initialFilterConfigs?.activeFilters?.contact_name?.ids?.includes(id) ?? false,
        secondaryText: "",
        isAvatarRequired: false,
      }));

    /*
     * This code first creates a Map object by mapping each object in the original array to an array with two elements:
     * the key and the value. The Map constructor can then be called with the resulting array of key-value pairs to create
     * a new Map object. Finally, the Array.from method is used to convert the Map object back to an array, where the map
     * function is used to only return the values from the key-value pairs.
     */

    const uniqueCustomerOrConnectionSuggestions = Array.from(new Map(customerOrConnectionDropdownList.reverse().map((obj) => [obj.primaryText, obj])))
      .reverse()
      .map(([, value]) => value);
    const uniqeCustomerContactSuggestions = Array.from(new Map(customerContactDropdownList.reverse().map((obj) => [obj.primaryText, obj])))
      .reverse()
      .map(([, value]) => value);

    const uniqueVendorNameSuggestions = Array.from(new Map(vendorNameDropdownList.reverse().map((obj) => [obj.primaryText, obj])))
      .reverse()
      .map(([, value]) => value);

    const uniqueVendorContactSuggestions = Array.from(new Map(vendorContactDropdownList.reverse().map((obj) => [obj.primaryText, obj])))
      .reverse()
      .map(([, value]) => value);

    const uniqueConnectionEmailSuggestions = Array.from(new Map(connectionEmailDropdownList.reverse().map((obj) => [obj.primaryText, obj])))
      .reverse()
      .map(([, value]) => value);

    const uniqueConnectionContactSuggestions = Array.from(new Map(connectionContactDropdownList.reverse().map((obj) => [obj.primaryText, obj])))
      .reverse()
      .map(([, value]) => value);

    return requiredColumns.map((column) => {
      if (column.accessor === "company_name") {
        return { ...column, dropdownSearchListItems: uniqueCustomerOrConnectionSuggestions };
      } else if (column.accessor === "vendor_name") {
        return { ...column, dropdownSearchListItems: uniqueVendorNameSuggestions };
      } else if (column.accessor === "primary_contact") {
        return { ...column, dropdownSearchListItems: uniqeCustomerContactSuggestions };
      } else if (column.accessor === "primary_contact_name") {
        return { ...column, dropdownSearchListItems: uniqueVendorContactSuggestions };
      } else if (column.accessor === "email_address") {
        return { ...column, dropdownSearchListItems: uniqueConnectionEmailSuggestions };
      } else if (column.accessor === "contact_name") {
        return { ...column, dropdownSearchListItems: uniqueConnectionContactSuggestions };
      }
      return column;
    });
  };

  function createFilterQuery(field: string, value: string) {
    if (value && value.length >= NUMERIC_VALUES.CONSTANT_THREE) {
      return `qa[${field}_cont_any]=${encodeURIComponent(value)}`;
    }
    return "";
  }

  useEffect(() => {
    const { filterSearchedText = {} } = initialFilterConfigs || {};
    const {
      company_name = { value: "" },
      vendor_name = { value: "" },
      primary_contact = { value: "" },
      primary_contact_name = { value: "" },
      email_address = { value: "" },
      contact_name = { value: "" },
    } = filterSearchedText;

    const filters: string[] = [];

    const addFilterQuery = (field: string, value: string) => {
      if (value.length >= NUMERIC_VALUES.CONSTANT_THREE) {
        const query = createFilterQuery(field, value);
        filters.push(query);
      }
    };

    addFilterQuery("company_name", company_name?.value);
    addFilterQuery("vendor_name", vendor_name.value);
    addFilterQuery("primary_contact", primary_contact.value);
    addFilterQuery("primary_contact_name", primary_contact_name.value);
    addFilterQuery("email_address", email_address.value);
    addFilterQuery("contact_name", contact_name.value);

    setIsColumnsConfigDataLoading(true);

    const fetchData = async () => {
      try {
        let res;
        if (filters.length > NUMERIC_VALUES.CONSTANT_ZERO) {
          const query = filters.join("&");
          res = isMyCompany
            ? await fetchMyCompany("all", query, NUMERIC_VALUES.CONSTANT_ONE, NUMERIC_VALUES.CONSTANT_TWENTY)
            : await fetchConnections("all", query, NUMERIC_VALUES.CONSTANT_ONE, NUMERIC_VALUES.CONSTANT_TWENTY);
        } else {
          res = { totalCount: NUMERIC_VALUES.CONSTANT_ZERO, records: [] };
        }
        setIsNoSearchResultFound(!res.totalCount);
        setColumnsConfigData(getDropdownSearchFilterData(res.records));
      } catch (error) {
        setIsNoSearchResultFound(true);
        console.log(error);
      } finally {
        setIsColumnsConfigDataLoading(false);
      }
    };
    fetchData()?.catch(() => {
      setIsNoSearchResultFound(true);
    });
  }, [initialFilterConfigs?.filterSearchedText, initialFilterConfigs?.filterQuery]);

  const softRefresh = async () => {
    selectionConfig.resetTableRecords();
    refetch();
  };

  //return {key:value} pair for template subject to parse the values
  const getSelectedTempSubjSnippetValues = () => {
    return {
      invoiceNumber: selectedCompany?.invoice_number ?? "",
      customer: selectedCompany?.company_name ?? selectedCompany?.vendor_name,
      companyName: loginUserCompany?.companyName ?? "",
      vendor: selectedCompany?.company_name ?? selectedCompany?.vendor_name,
    };
  };

  //return suggested template codes based on certain condition
  const getSuggestedTemplatesCode = () => {
    const arTemplates = getViewTypeTemplateCodes(viewType.AR_ALL_CUSTOMER_CONNECTIONS);
    const apTemplates = getViewTypeTemplateCodes(viewType.AP_ALL_VENDOR_CONNECTIONS);

    if (userView.current.view === "customers") {
      return arTemplates;
    } else if (userView.current.view === "vendors") {
      return apTemplates;
    } else {
      return [];
    }
  };

  //using caching data to update the footer at the same time
  const updatedPaginationObject = {
    ...paginationConfig,
    ...TableUtils.getUpdatePaginationPageState(paginationConfig, cachedData?.totalCount, cachedData?.pageSize),
  };

  return (
    <>
      <Table
        configs={{
          //Added the feature flag for the newly added filters excpet which are already there on Production
          columnConfigs: columnsConfigData?.map((item) => {
            if (
              [
                "amount_billed_outstanding",
                "open_bill_count",
                "amount_billed_past_thirty_days",
                "last_activity_at",
                "contact_name",
                "outstanding_invoices",
                "outstanding_amount",
                "amount_past_due",
                "action",
              ].includes(item.accessor)
            ) {
              return { ...item, rowDataType: cellRendererMapping[item?.rowDataType] };
            } else {
              return { ...item, rowDataType: cellRendererMapping[item?.rowDataType], enableFilter: isFiltersEnabled };
            }
          }),
          initialTableConfigs: {
            name: tableName,
            type: tableName,
            footerTableTypeText: tableName,
            loading: isLoading,
          },
          paginationConfigs: { ...updatedPaginationObject, enableNavigator: true, enableSummary: true },
          initialSortConfigs: sortConfig,
          selectionConfig: selectionConfig,
          initialFilterConfigs: {
            ...initialFilterConfigs,
            dateFormat: calendarDateFormat,
            isSuggestionDataLoading: isColumnsConfigDataLoading,
            category: getViewCategory(),
            workspace: selectedWorkspace.workspace_type_route?.toLocaleLowerCase() ?? WorkspaceType.AR,
            isNoSearchResultFound,
          },
          currencyConfig: {
            locale: userStatus?.currency?.locale,
            code: userStatus?.currency?.code,
            symbol: userStatus?.currency?.symbol,
          },
        }}
        rows={(cachedData?.records ?? [])?.map((rec: any) => {
          return {
            ...rec,
            read: true,
            last_activity_at: rec.last_activity_at,
          };
        })}
        handlers={{
          onRowClick: handleRowClick,
        }}
        emptyMessage={{
          title: `${isOtherConnection || isMyCompany ? NO_DATA_MESSAGE : companyEmptyMessage}`,
          caption: `${isMyCompany ? MY_COMPANY_EMPTY_MESSAGE : otherMessage}`,
        }}
        secondaryRowActions={actionItems}
        userActions={[...rowSelectBtns]}
        hoverBar={false}
        visibility={{
          exportBtn: false,
          hoverbar: false,
        }}
      />
      {showActivity && (
        <NewActivityPopup
          key={`key-add-activity-${newActivityType}`}
          title={BTN_NEW_ACTIVITY}
          open={showActivity}
          contactOptions={allContactOptions}
          defaultTo={_.isEmpty(primaryContact) ? [companyContactOptions[0]] : primaryContact}
          handleSend={handleSend}
          isTemplateSupport={
            isTemplateWorkflowV2Enabled ||
            (newActivityType === ActivityOptions.EMAIL && supportedTemplateList && supportedTemplateList?.size > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO)
          }
          supportedTemplateList={supportedTemplateList}
          editorState={editorState}
          handleSendMarkClosed={handleSendAndMarkClosed}
          onClose={() => {
            setShowActivity(false);
            setEditorState("");
          }}
          activityType={newActivityType}
          setNewActivityType={(type) => {
            setNewActivityType(type);
          }}
          addActivityDropdown={[
            { activityType: "Email", displayName: "Email", icon: <Email /> },
            { activityType: "Note", displayName: "Note", icon: <NoteFill /> },
            { activityType: "Phone Call", displayName: "Phone Call", icon: <Phone /> },
          ]}
          prepareActivityBodyByTemplateID={prepareActivityBodyByTemplateID}
          fromTime={fromTime}
          toTime={toTime}
          setToTime={setToTime}
          enableKeyboardShortcuts={true}
          isSendButtonDisabled={visibility.isSendBtnDisabled}
          suggestedTemplatesCode={getSuggestedTemplatesCode()}
          tempSubjectSnippetValues={getSelectedTempSubjSnippetValues()}
        />
      )}
      {(exportData?.length ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO ? (
        <CSVLink data={exportData} filename={customerTable.tableConfig.exportFileName} ref={csvInstance} style={{ display: "none" }} />
      ) : undefined}
      {openMergeFlyout && (
        <MergeFlyout
          connectionIds={selectedRowIds}
          open={openMergeFlyout}
          setConnectionIds={setSelectedRowIds}
          setOpen={setOpenMergeFlyout}
          softRefresh={softRefresh}
          fetchCompanyList={fetchCompanyListFunc}
          selectedActivitiesCount={selectedRowsCount}
          deselectedRowsFilter={deselectedRowsFilter}
        />
      )}
    </>
  );
};

export default CustomerTable;

/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useEffect, useMemo, useRef } from "react";
import Loading from "../../../library/Loading/Loading";
import { Email, Phone, NoteFill, Download, Pen, PreviewInfo, DownloadAttachment } from "../../../library/Icons/Icons";
import { InvoiceContext } from "../../../../contexts/InvoiceContext";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import Detail from "../../../library/Detail/Detail";
import TableUtils from "../../../../utils/TableUtils/TableUtils";
import "./Details.scss";
import { DEFAULT_NUMERIC_VALUES, DEFAULT_PAGINATION_VALUES } from "../../../../constants/NumericConstants";
import NewActivityPopup from "../../../library/AddNewActivityDropdown/AddNewActivityDropdown";
import { AlertContext } from "../../../../contexts/AlertContext";
import { ActivityOptions, FallbackTypes, WorkspaceType, ActivityType as ActivityTypeEnum, EmailAction } from "../../../../types/enums";
import { companiesClient, invoicesClient } from "../../../../db/accessor";
import { activitiesClientV2, automationClientV2, emailsClientV2 } from "../../../../db/version2Accessor";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import DetailsTab from "../../../Payments/PaymentDetail/Detail/DetailsTab/DetailsTab";
import Utils from "../../../../utils/utils";
import { AppContext } from "../../../../contexts/AppContext";
import { getIcon } from "../../../../utils/IconUtils/IconUtils";
import { TemplateDefinitionProps } from "../../../../app/Templates/TemplateFactory";
import { TemplateTypes, getViewTypeTemplateCodes, viewType } from "../../../../app/Templates/TemplateTypes";
import { TemplateContext } from "../../../../contexts/TemplateContext";
import { Button, IconButton } from "@mui/material";
import _ from "underscore";
import DocumentSwitcher from "../../../../components/library/DocumentSwitcher/DocumentSwitcher";
import { ApplicationRouteContext } from "../../../../contexts/ApplicationRouteContext";
import { DocumentSwitchContext } from "../../../../contexts/DocumentSwitchContext";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import useLocale from "../../../../hooks/useLocale";
import CustomTooltip from "../../../library/CustomTooltip/CustomTooltip";
import { TooltipTitles, TooltipTypes } from "../../../../constants/TooltipConstants";
import usePdfDownloadValidation from "../../../../hooks/validations/usePdfDownloadValidation";
import {
  ACTIVITY_COMPLETE_FAILURE,
  ACTIVITY_COMPLETE_SUCCESS,
  ACTIVITY_SUCCESS_TOAST_MSG,
  BTN_NEW_ACTIVITY,
  EMAIL_CONNECTOR_ERROR,
  EMPTY_TRANSACTIONS_MESSAGE,
  MESSAGE_NOT_SENT_ERROR,
  NO_DATA_MESSAGE,
  PDF_DOWNLOAD_SUCCESS,
  TOTAL_DISCOUNT,
  TOTAL_DUE,
} from "../../../../constants/config";
import useContacts from "../../../../hooks/useContacts";
import { AttachmentPreview } from "../../../library/AtomicComponents/AttachmentPreview";
import useWorkspaceConfigurations from "../../../../hooks/useWorkspaceConfigurations";
import useHandleVisibility from "../../../../hooks/useHandleVisibility";
import TrackingUtils from "../../../Tracking/Tracking.Utils";
import { AxiosError } from "axios";
import { CONNECTION_STATUS } from "../../../../constants/ConnectionConstants";
import { ActivityContext } from "../../../../contexts/ActivityContext";
import ActivityFeedMethods from "../../../Activities/ActivityDetail/ActivityFeedV2/helpers/ActivityFeedMethods";
import { FeatureFlagContext, FeatureFlagProviderType } from "../../../../contexts/FeatureFlagContext";
import EmptyStates from "../../../library/AtomicComponents/Table/tsx/EmptyStates/EmptyStates";

type PaymentsAppliedData = {
  id: string;
  referenceCode: string | null;
  appliedAmount: number;
  applyToInvoiceDate: string | null;
  type: string;
  handleClick: ((id: string) => void) | (() => null);
};

type CreditMemoInvoices = {
  id: string;
  referenceCode: string | null;
  totalAmount: number;
  discountApplied: number | null;
  individualDiscounts: number | null;
  paymentAmount: number;
  type: string;
  handleClick: ((id: string) => void) | (() => null);
};

type DetailValueMappingObject = {
  [key: string]: any;
};

interface DetailsInterface {
  configs: any;
}

export default function InvoiceDetail(props: DetailsInterface): React.ReactElement {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const { path } = useRouteMatch();
  const { get, setInvoiceData, invoiceData, downloadTransaction, checkPDFStatusForTransaction } = React.useContext(InvoiceContext) as InvoiceType;
  const history = useHistory();
  const tooltipRef = useRef<HTMLDivElement>(null);
  const detailRef = useRef<any>(null);
  const { signature, loginUserCompany } = React.useContext(CustomerContext) as CustomerType;
  const [newActivityType, setNewActivityType] = useState<string>("");
  const [showActivity, setShowActivity] = useState<boolean>(false);
  const { selectedWorkspace, workspaceHomePath, pathType } = useWorkspaceConfigurations();
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const { userStatus } = React.useContext(AppContext) as AppType;
  const { getContactsOptions, allContactOptions, filterPrimaryContacts } = useContacts();
  const [companyData, setCompanyData] = useState<CompanyModel>({} as CompanyModel);
  const [companyContactOptions, setCompanyContactOptions] = useState<To[]>([]);
  const [supportedTemplateList, setSupportedTemplateList] = useState<TemplateDefinitionProps[]>([]);
  const [enableTypeAheadSuggestions, setEnableTypeAheadSuggestions] = React.useState(false);
  // templateName > indicates the selected template code by the user
  const [templateName, setTemplateName] = useState<string>("");
  const { templateFactory, handleTemplateAttachment, prepareTemplateDataObject, templateData } = React.useContext(TemplateContext) as ITemplateProps;
  const { getBaseFromPath, updateBaseRoute, getBaseRoute } = React.useContext(ApplicationRouteContext) as ApplicationRouteType;
  const [fromTime, setFromTime] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [toTime, setToTime] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [defaultTemplateId, setDefaultTemplateId] = useState<TemplateTypes | string>();
  const {
    setSelectedActivityStreamId,
    setActivityStreamIds,
    setCurrentPaginationState,
    setDocumentType,
    setEnableDocumentSwitch,
    setCurrentSwitcherStateSnapshot,
  } = React.useContext(DocumentSwitchContext) as DocumentSwitchType;
  const { formatDateBasedOnCountry: formatDate, availableLanguagesForTemplates } = useLocale();
  const { downloadSupported, validationMessage, getPDFDwnldToastMessage } = usePdfDownloadValidation(invoiceData?.invoiceTypeCode ?? "");
  const [creditMemoInvoices, setCreditMemoInvoices] = useState<CreditMemoInvoices[] | null>([]);
  const getNumberFormatted = (amt: number) =>
    new Intl.NumberFormat(userStatus?.currency?.locale ?? "en-US", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: "currency",
      currency: invoiceData?.currencyCode ?? "USD",
    }).format(amt);

  const fetchCompanyInfo = (companyId: UUID) => {
    if (!companyId) return;
    companiesClient.getCompany(companyId, "Classification").then((companyModel: CompanyModel) => {
      setCompanyData(companyModel);
    });
  };
  const primaryContact = filterPrimaryContacts(companyContactOptions);
  const [openAttachmentPreview, setOpenAttachmentPreview] = React.useState<boolean>(false);
  const [attachment, setAttachment] = React.useState<null | BlobAttachmentItem>(null);
  const [activeAttachmentID, setActiveAttachmentID] = React.useState<string | number>("");
  const [lastApprover, setLastApprover] = useState<To>({} as To);
  const [lastApproverloading, setLastApproverloading] = useState<boolean>(false);
  const [editorState, setEditorState] = useState<string>("");
  const { handleClose } = React.useContext(ActivityContext) as ActivityType;
  const { isEnabled } = React.useContext(FeatureFlagContext) as FeatureFlagProviderType;
  const isNewDownloadValidation = isEnabled("INVOICE_NEW_DOWNLOAD_METHOD");
  const [downloadCheckMessage, setDownloadCheckMessage] = React.useState<string>("");
  const [isDownloadAvailable, setIsDownloadAvailable] = React.useState<boolean>(false);
  const finalValidationMessage = isNewDownloadValidation ? downloadCheckMessage : validationMessage;
  const finalDownloadCheck = isNewDownloadValidation ? isDownloadAvailable : downloadSupported;
  const { url } = useRouteMatch();
  const isAPWorkspace =
    selectedWorkspace.workspace_type === "accounting" ? url?.includes("bills") : selectedWorkspace.workspace_type === "accounts_payable";

  /**
   * setup common state with reducer to handle open/close states for send and send and close button
   * React useState hooks.
   */
  const initialVisibility = {
    isSendBtnDisabled: false,
  };
  const { visibility, dispatch } = useHandleVisibility<typeof initialVisibility>({ isSendBtnDisabled: false });

  const toggleActivity = async (toggler: boolean) => {
    setShowActivity(toggler);
  };

  const addActivityDropdownOptions: ActivityDropdownItem[] = [
    { activityType: "Email", displayName: "Email", icon: <Email /> },
    { activityType: "Note", displayName: "Note", icon: <NoteFill /> },
    { activityType: "Phone Call", displayName: "Phone Call", icon: <Phone /> },
    { activityType: "Approval Request", displayName: "Approval Request", icon: <Pen /> },
  ];

  // Calculates the payments applied amount for a given invoice
  const calculatedPaymentsApplied = useMemo(
    () =>
      invoiceData?.payments
        ? invoiceData?.payments.reduce((a, b) => a + b.paymentAppliedAmount, DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO as number)
        : DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
    [invoiceData]
  );

  const fetchData = async () => {
    setErrorMessage("");
    setLoading(true);
    try {
      const res = await get(invoiceId);
      setInvoiceData(res);
    } catch (error: any) {
      setErrorMessage(error.message);
    }
    setLoading(false);
  };

  const fetchCompanyContacts = async () => {
    setCompanyContactOptions(await getContactsOptions(invoiceData.customer?.companyId));
  };

  //Fetch API call for Credit Memo
  const fetchCreditMemo = () => {
    return invoicesClient.getCreditMemos(`(CreditMemoInvoiceId EQ '${invoiceData.invoiceId}') AND (creditMemoAppliedId IS NOT NULL)`);
  };

  //Logic to calculate Payment amount for an invoice
  const calculatePaymentAmount = (totalAmount: number, outstandingAmount: number, discount: number): number => {
    const paymentAmount = totalAmount - outstandingAmount - discount;
    return paymentAmount;
  };

  /**
   * Calculate Total Credit Memo Amount
   * Calculate the total credit memos amount for an invoice and return the currency formatted amount
   */
  const getTotalCreditMemo = (creditMemoList: CreditMemoInvoiceModel[] | null) => {
    let sum = 0;
    if (creditMemoList && creditMemoList?.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      creditMemoList?.forEach((creditMemo) => {
        if (creditMemo.creditMemoAppliedAmount) {
          sum = sum + creditMemo.creditMemoAppliedAmount;
        }
      });
    }
    return sum;
  };

  const fetchInvoicesDataForCreditMemo = async () => {
    const fetchResult = await fetchCreditMemo();
    const invoiceIdsArray = {} as any;
    fetchResult?.records?.forEach((item) => {
      invoiceIdsArray[item.invoiceId] = item.creditMemoAppliedAmount;
    });
    const finalResult = await invoicesClient.getInvoices(`invoiceId in (${Object.keys(invoiceIdsArray)})`, "CreditMemos, Payments");
    const mappedInvoices = finalResult?.records?.map((invoice: InvoiceModel) => {
      const totalDiscounts = getTotalCreditMemo(invoice.creditMemos);
      return {
        id: invoice.invoiceId,
        referenceCode: invoice.referenceCode,
        totalAmount: invoice.totalAmount,
        discountApplied: totalDiscounts,
        individualDiscounts: invoiceIdsArray ? invoiceIdsArray[invoice.invoiceId] : DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
        paymentAmount: calculatePaymentAmount(
          invoice.totalAmount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
          invoice.outstandingBalanceAmount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
          totalDiscounts
        ),
        type: "Credit Memo",
        handleClick: (id: string) => handlePaymentClick(id),
      } as CreditMemoInvoices;
    });
    setCreditMemoInvoices(mappedInvoices ?? []);
  };

  /**
   * Check PDF availabilty with a HEAD API call and enable/disable the Preview/Download buttons and set appropriate message
   */
  const checkPDFAvailability = async () => {
    try {
      const status = await checkPDFStatusForTransaction(invoiceId);
      const message = getPDFDwnldToastMessage(status);
      if (status === CONNECTION_STATUS.OK_200.STATUS_CODE) {
        setIsDownloadAvailable(true);
      } else {
        setIsDownloadAvailable(false);
      }
      setDownloadCheckMessage(message);
    } catch (error: any) {
      const errorMessage = getPDFDwnldToastMessage(error.response.status);
      setIsDownloadAvailable(false);
      setDownloadCheckMessage(errorMessage);
    }
  };

  useEffect(() => {
    checkPDFAvailability();
    fetchCompanyInfo(userStatus.account_company_id as string);
    fetchCompanyContacts();
    if (Object.keys(invoiceData).length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      fetchData();
    }
    //fetch CreditMemo Data if the selected invoice is Credit Memo
    if (invoiceData.invoiceTypeCode?.includes("Credit Memo")) {
      fetchInvoicesDataForCreditMemo();
    }
  }, []);

  const handleNameClick = (id: string) => {
    if (
      _.isUndefined(props.configs) ||
      _.isUndefined(props.configs.routes) ||
      _.isUndefined(props.configs.routes.resource) ||
      _.isUndefined(props.configs.routes.resource.customer)
    )
      return;
    sessionStorage.setItem("lastPath", history.location.pathname);
    const base = `${workspaceHomePath}/${props.configs.routes.resource.customer}/active/${id ?? ""}`;
    updateBaseRoute(base);
    history.push(base);
  };

  const handlePaymentClick = (id: string) => {
    /**
     * Set the following properties into DocumentSwitchContext
     * to facilitate document switching.
     */
    setEnableDocumentSwitch(true);
    /**
     * Save the current document switcher state
     */
    setCurrentSwitcherStateSnapshot();
    setSelectedActivityStreamId({ id: id } as ActivityStreamId);
    setActivityStreamIds(paymentsApplied.map((item) => ({ id: item.id })));
    setCurrentPaginationState({
      page: DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
      pageCount: DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
      pageSize: paymentsApplied.length,
      totalRecords: paymentsApplied.length,
    });
    setDocumentType("related-payment");
    sessionStorage.setItem("lastPath", history.location.pathname);
    history.push(`${getBaseRoute()}/payments/${id ?? ""}`);
  };

  const hasPayments = !(invoiceData.payments == null || invoiceData.payments.length < DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);
  const hasCreditMemos = !(invoiceData.creditMemos == null || invoiceData.creditMemos.length < DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);
  const paymentsApplied: PaymentsAppliedData[] = [
    ...(hasPayments ? (invoiceData.payments as InvoicePaymentDetailModel[]) : []).map((element: InvoicePaymentDetailModel) => {
      return {
        id: element.paymentId,
        referenceCode: element.referenceCode,
        appliedAmount: element.paymentAppliedAmount,
        applyToInvoiceDate: element.applyToInvoiceDate,
        type: "Payment",
        handleClick: (id: string) => handlePaymentClick(id),
      };
    }),
  ].sort((first, second) =>
    (first?.applyToInvoiceDate ?? "") < (second?.applyToInvoiceDate ?? "")
      ? DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
      : DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE
  );

  const typeAheadAction = (val: string) => {
    if (val.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      setEnableTypeAheadSuggestions(true);
    } else {
      setEnableTypeAheadSuggestions(false);
    }
  };

  //Function to get current substring for appropriate routing
  const getRouteStringPath = () => {
    //const path = getBaseRoute();
    if (path.includes("open_invoices")) {
      return "/open_invoices";
    } else if (path.includes("closed_invoices")) {
      return "/closed_invoices";
    } else if (path.includes("open_bills")) {
      return "/open_bills";
    } else if (path.includes("closed_bills")) {
      return "/closed_bills";
    } else if (path.includes("bills")) {
      return "/bills";
    } else if (path.includes("invoices")) {
      return "/invoices";
    } else return "";
  };

  const handleInvoiceClick = (id: string) => {
    /**
     * Set the following properties into DocumentSwitchContext
     * to facilitate document switching.
     */
    setEnableDocumentSwitch(true);
    /**
     * Save the current document switcher state
     */
    setCurrentSwitcherStateSnapshot();
    setSelectedActivityStreamId({ id: id } as ActivityStreamId);
    setActivityStreamIds(creditMemoInvoices?.map((item) => ({ id: item.id })) ?? []);
    setCurrentPaginationState({
      page: DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
      pageCount: DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
      pageSize: creditMemoInvoices?.length ?? DEFAULT_PAGINATION_VALUES.PAGE_SIZE,
      totalRecords: creditMemoInvoices?.length ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
    });
    setDocumentType("related-invoices");
    sessionStorage.setItem("lastPath", history.location.pathname);
    const basePath = getBaseFromPath();
    const route = getRouteStringPath();
    if (route === "/invoices" || route === "/bills") {
      history.push(`${basePath.substring(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, basePath.lastIndexOf(route))}${route}/${id ?? ""}`);
    } else {
      history.push(`${basePath}${route}/${id ?? ""}`);
    }
  };

  //Credit Memo or Invoices detail config as per the type code of invoice
  const isCreditMemo = invoiceData.invoiceTypeCode?.includes("Credit Memo");
  const detailsBody = isCreditMemo ? props?.configs?.details.creditMemo?.body : props?.configs?.details.invoices.body;

  // File Reader handlers - 2XX
  const onSuccessfulResponseLoadBlob = (readerEvt: any, data: any) => {
    // Reason for strict checking is this error possibly will come on for demo connector
    // rest with non-demo connector there will be more meaningful error messages
    if (readerEvt.target.result.toLocaleLowerCase().includes("try again later")) {
      setToastOptions({
        open: true,
        severity: "error",
        message: `The PDF for ${invoiceData?.invoiceTypeCode?.includes("Credit Memo") ? "Credit Memo" : "Invoice"} ${
          invoiceData?.referenceCode
        } is not available. Please try again later.`,
      });
    } else {
      const uri = URL.createObjectURL(data);
      const fileName = `#${invoiceData?.referenceCode}-${new Date().toDateString()}__${new Date().toLocaleTimeString()}`;
      let completeFileName = "";
      if (selectedWorkspace.workspace_type === "accounts_payable") {
        completeFileName = `Bill-${fileName}`;
      } else {
        completeFileName = `${invoiceData?.invoiceTypeCode?.includes("Credit Memo") ? "Credit-Memo" : "Invoice"}-${fileName}`;
      }
      TableUtils.downloadAll([
        {
          file_name: completeFileName,
          file_url: uri,
        },
      ]);
      setToastOptions({ open: true, severity: "success", message: PDF_DOWNLOAD_SUCCESS });
    }
  };

  // File Reader handlers - 4/5XX
  const onFailurefulResponseLoadBlob = (readerEvt: any) => {
    // Reason for strict checking is this error possibly will come on for demo connector rest with non-demo connector there will be more meaningful error messages
    const returnedError = (JSON.parse(readerEvt.target.result).detail as string).toLocaleLowerCase();
    const downloadNotSupportedError =
      returnedError.includes("not supported") || returnedError.includes("not have an associated pdf")
        ? "PDF cannot be downloaded since it doesn't exist in ERP."
        : "Download failed, please check ERP download permission settings.";
    const errorMessage =
      returnedError === "the app demo data does not support pdf retrieval."
        ? `PDF Download not supported for ${selectedWorkspace.workspace_type === "accounts_payable" ? "Bill" : "Invoice"} #${
            invoiceData?.referenceCode
          }`
        : downloadNotSupportedError;
    setToastOptions({ open: true, severity: "error", message: errorMessage });
  };

  const onClickPreviewClose = (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement> | KeyboardEvent) => {
    event.stopPropagation();
    setOpenAttachmentPreview(false);
  };

  /**
   * Download Invoice or Bill
   * Server sends File content (to recieve directly blob FE is sending {responseType: 'blob'} as a config in API)
   * If any error cones from server (because FE initially sends {responseType: 'blob'}) so in error-block response
   * is converted back into JSON And server error is shown
   *
   * Download Errors:
   * 1. HTTP CODE: 202, ERROR: Pdf is not available for invoice, please try again later.
   * 2. HTTP CODE: 400, ERROR: Pdf does not exist in the ERP.
   * 3. HTTP CODE: 400, ERROR: Pdf download is not supported. => Proactive validation
   * 4. Fallback: Download Failed
   */
  const downloadInvoiceAndBill = async () => {
    const toastOptions: ToastOptions = { open: true, severity: "info", message: "Your download will start in a few seconds" };
    try {
      setToastOptions({
        ...toastOptions,
        icon: (
          <IconButton style={{ padding: "0" }}>
            <Download className="download-icon" />
          </IconButton>
        ),
      });
      const data = await downloadTransaction(invoiceId);
      const blob = new Blob([data], { type: "application/json" });
      const reader = new FileReader();
      reader.onload = (readerEvt: any) => onSuccessfulResponseLoadBlob(readerEvt, data);
      reader.readAsText(blob);
    } catch (error: any) {
      /**
       * Download failures could be because of
       * 1. User has not enabled third party download services in their ERP system
       * 2. Network Failure
       * 3. Demo Data - strictly relavent to development teams
       */
      //CODE to GET PLATFORM RESPONSE STATUS
      if (error.response) {
        const blob = new Blob([error.response.data], { type: "application/json" });
        const fileReader = new FileReader();
        fileReader.onload = (event: any) => onFailurefulResponseLoadBlob(event);
        fileReader.readAsText(blob);
      } else {
        setToastOptions({ ...toastOptions, severity: "error", message: "Something went wrong." });
      }
    }
  };

  const previewInvoiceAndBill = async () => {
    const toastOptions: ToastOptions = { open: true, severity: "info", message: "Your download will start in a few seconds" };
    try {
      const data = await downloadTransaction(invoiceId);
      if (data?.type === "application/pdf") {
        const file_name = `${invoiceData?.invoiceTypeCode?.includes("Credit Memo") ? "Credit Memo" : "Invoice"}-#${invoiceData?.referenceCode}`;
        const id = _.uniqueId(`invoice_${invoiceId}`);
        const file = {
          id,
          file_name,
          file_url: data,
          extension: data?.type,
        };
        setAttachment(file);
        setActiveAttachmentID(id);
        setOpenAttachmentPreview(!openAttachmentPreview);
      } else {
        const blob = new Blob([data], { type: "application/json" });
        const reader = new FileReader();
        reader.onload = (readerEvt: any) => onSuccessfulResponseLoadBlob(readerEvt, data);
        reader.readAsText(blob);
      }
    } catch (error: any) {
      setToastOptions({ ...toastOptions, severity: "error", message: "Preview not available." });
    }
  };

  const calculateTotalDiscountAmount = () => {
    let sum = 0;
    creditMemoInvoices &&
      creditMemoInvoices.forEach((invoice) => {
        sum = sum + (invoice?.individualDiscounts ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
      });
    return getNumberFormatted(sum);
  };
  const propertyValueMap: DetailValueMappingObject = {
    "value.invoiceNumber": invoiceData?.referenceCode ?? null,
    "value.primaryContact": invoiceData?.customerPrimaryContact?.contactName ?? null,
    "value.email": invoiceData?.customerPrimaryContact?.emailAddress ?? null,
    "value.totalAmount": invoiceData.totalAmount !== null ? getNumberFormatted(invoiceData.totalAmount) : null,
    "value.appliedAmount": getNumberFormatted(calculatedPaymentsApplied),
    "value.outstandingBalanceAmount":
      invoiceData?.outstandingBalanceAmount !== null ? getNumberFormatted(invoiceData.outstandingBalanceAmount) : null,
    "value.invoiceDate": formatDate(invoiceData?.invoiceDate ?? null),
    "value.paymentDueDate": formatDate(invoiceData?.paymentDueDate ?? null),
    "value.expectedPaymentDate": invoiceData?.invoiceClosedDate ? null : "N/A",
    "value.invoiceClosedDate": invoiceData?.invoiceClosedDate ? formatDate(invoiceData.invoiceClosedDate) : null,
    "value.totalCreditMemoAmount": hasCreditMemos
      ? getNumberFormatted(getTotalCreditMemo(invoiceData.creditMemos))
      : DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
    "value.totalDiscountAmount": creditMemoInvoices ? calculateTotalDiscountAmount() : null,
  };

  // check if template code is required as content-type
  const needTemplateCodeAsPayload = React.useMemo(
    () =>
      [
        TemplateTypes.AR_CREATE_CUSTOMER_PROFILE,
        TemplateTypes.AR_UPDATE_CUSTOMER_PROFILE,
        TemplateTypes.AP_CREATE_VENDOR_PROFILE,
        TemplateTypes.AP_CREATE_VENDOR_PROFILE,
      ].includes(templateName as TemplateTypes),
    [templateName]
  );

  const handleSend = async (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    attachmentIds: AttachmentIds,
    inboxAttachments = [] as TransactionItemType[],
    isSendAndClose?: boolean
  ): Promise<ActivityStream> => {
    dispatch({ type: "open", payload: "isSendBtnDisabled" });
    const getEmailArray = (emailArray: To[]) => emailArray.filter((item) => item !== undefined).map((value: To) => value.id || value.label);
    let toastOptions: ToastOptions = { open: true, severity: "success", message: ACTIVITY_SUCCESS_TOAST_MSG };
    const activityType =
      newActivityType && newActivityType === ActivityOptions.PHONE_CALL
        ? ActivityTypeEnum.PhoneCall
        : newActivityType === ActivityOptions.EMAIL
        ? ActivityOptions.EMAIL
        : newActivityType;
    const activityTransactions = _.uniq([...inboxAttachments, { transaction_id: invoiceId, transaction_type: "invoice" }], "transaction_id");
    const payload: Partial<ActivityItemEmailModel> = {
      to: getEmailArray(to),
      cc: getEmailArray(cc),
      bcc: getEmailArray(bcc),
      subject: title,
      content: body,
      email_action: EmailAction.NEW,
      workspace_id: selectedWorkspace?.id || FallbackTypes.Id,
      connection_company_id: invoiceData?.customer?.companyId ?? "",
      content_type: ActivityFeedMethods.evaluateContentVariantPayload(newActivityType, needTemplateCodeAsPayload ? templateName : ""),
      attachments: attachmentIds.filter((item) => typeof item === "number"),
      activity_type: activityType,
      activity_transactions: activityTransactions,
      primary_connection_id: invoiceData?.customerId,
      attach_pdf: Utils.isThereAnyAttachment(attachmentIds, activityTransactions),
    };

    return new Promise((resolve) => {
      emailsClientV2
        .post(payload)
        .then(async (emailResponse: ActivityStream) => {
          //Only call Automation API when email is sent successfully and Template is selected
          if (templateName && emailResponse.success) {
            let reqBody = {
              to_time: toTime,
              automation_type: "email_templates",
              automation_sub_type: templateName,
              resource_type: "Activity::Email",
              resource_id: emailResponse.data.id,
            } as AutomateTimeModel;
            if (fromTime != DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
              reqBody = { ...{ from_time: fromTime }, ...reqBody };
            }
            setToTime(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
            automationClientV2.post(selectedWorkspace?.id || FallbackTypes.Id, reqBody);
          }

          TrackingUtils.trackFSData(
            to,
            "Ellipsis",
            "New",
            isSendAndClose ?? false,
            allContactOptions,
            "Transaction Details",
            path,
            newActivityType ?? "",
            body
          );
          setShowActivity(false);
          //Fetch latest contact (newly added) options to populate in activity pop up if user tries to reply on the same
          getContactsOptions();
          resolve(emailResponse ?? []);
        })
        .catch((err: AxiosError) => {
          toastOptions.severity = "error";
          if (err.response?.status === CONNECTION_STATUS.BAD_REQUEST_400.STATUS_CODE) {
            toastOptions = { ...toastOptions, severity: "error", message: EMAIL_CONNECTOR_ERROR };
          } else {
            const errorResponse = err.response as AxiosErrorResponseData;
            toastOptions = { ...toastOptions, message: errorResponse?.data?.messages?.errors[0] ?? MESSAGE_NOT_SENT_ERROR };
          }
        })
        .finally(() => {
          setToastOptions(toastOptions);
          dispatch({ type: "close", payload: "isSendBtnDisabled" });
        });
    });
  };

  const handleSendAndMarkClosed = async (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    attachmentIds: AttachmentIds,
    inboxAttachments: TransactionItemType[]
  ) => {
    const sendEmailResponse = await handleSend(to, cc, bcc, title, body, attachmentIds, inboxAttachments, true);
    if (sendEmailResponse.success) {
      let toastOptions: ToastOptions = { open: true, severity: "success", message: ACTIVITY_COMPLETE_SUCCESS };
      let response = {} as APIResponse;

      try {
        response = await handleClose(sendEmailResponse.data.activity_stream.id, "");
      } catch (e: unknown) {
        response.success = false;
      } finally {
        if (!response.success) {
          toastOptions = {
            ...toastOptions,
            severity: "error",
            message: ACTIVITY_COMPLETE_FAILURE,
          };
        }
        setToastOptions(toastOptions);
      }
    }
  };

  /**
   * Function which convert the template string to editor content state.
   *
   * @param {string} templateID The templateID selected by user from the subject drop down.
   * @param {EditorState} EditorState The setEditorState will update the email body.
   */
  const prepareActivityBodyByTemplateID = (
    templateID: string | null,
    setEditorState: React.Dispatch<React.SetStateAction<string>>,
    lang: string,
    setFileCallback?: FunctionCall
  ) => {
    setTemplateName(templateID ?? "");
    if (templateID) {
      const { companyName = "{Company name}" } = invoiceData.customer as CompanyModel;
      const templateObj = templateData.get(selectedWorkspace?.id)?.get(lang)?.get(templateID);
      setFromTime(templateObj?.getFromTime);
      setToTime(templateObj?.getToTime);
      if (templateObj?.templateAttachment) {
        const templateDataMapper = prepareTemplateDataObject(
          templateObj,
          [{ name: `Invoice #${invoiceData?.referenceCode ?? "N/A"}`, id: invoiceId, typeCode: invoiceData?.invoiceTypeCode }],
          null,
          null
        );
        handleTemplateAttachment(templateObj, setFileCallback, templateDataMapper);
      }
      setEditorState(
        templateObj?.parseTemplate({
          customer: companyName,
          vendor: companyName,
          invoiceNumber: invoiceData?.referenceCode,
          invoiceDate: formatDate(invoiceData.invoiceDate) || "",
          paymentDueDate: formatDate(invoiceData.paymentDueDate) || "",
          invoiceAmount: Utils.formatValueAsCurrency(
            invoiceData.totalAmount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            userStatus?.currency?.locale,
            invoiceData?.currencyCode ?? "USD"
          ),
          outstandingBalance: Utils.formatValueAsCurrency(
            invoiceData.outstandingBalanceAmount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            userStatus?.currency?.locale,
            invoiceData?.currencyCode ?? "USD"
          ),
          emailAddress: companyData?.emailAddress || "",
          companyName: companyData?.companyName || "",
          phone: companyData?.phoneNumber || "",
          userName: `${userStatus?.user_name ?? "{Your Name}"}`,
          workspaceName: selectedWorkspace?.name,
          contactName: primaryContact[0]?.label ?? companyName,
          totalPaymentsApplied: Utils.formatValueAsCurrency(
            calculatedPaymentsApplied ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            userStatus?.currency?.locale,
            invoiceData?.currencyCode ?? "USD"
          ),
          //first payment number assuming its the latest
          paymentNumber: paymentsApplied ? paymentsApplied[0]?.referenceCode : "{paymentNumber}",
          signature: signature.email_signature || "",
        })
      );
    }
  };

  /**
   * Function which help's to give template list for the subject drop down
   * based on view type.
   *
   * @param {Object} row The selected row record from table
   * @returns {TemplateDefinitionProps[]} The template definition array of objects.
   */
  const getTemplateDefinitionsByView = (item: InvoiceModel): TemplateDefinitionProps[] => {
    let view: viewType;
    if (pathType === WorkspaceType.AP) {
      view = item.invoiceStatusCode === "Closed" ? viewType.AP_TRANSACTION_BILLS_CLOSED : viewType.AP_TRANSACTION_BILLS;
    } else {
      view = item.invoiceStatusCode === "Closed" ? viewType.AR_TRANSACTION_INVOICES_CLOSED : viewType.AR_TRANSACTION_INVOICES;
    }
    const { companyName = "{Company name}" } = item.customer as CompanyModel;
    return templateFactory.getTemplateDefinitionsByView(
      view,
      { invoiceNumber: item.referenceCode, customer: companyName, companyName: loginUserCompany?.companyName ?? "", vendor: companyName },
      availableLanguagesForTemplates
    );
  };

  const getSelectedTempSubjSnippetValues = () => {
    const companyName = invoiceData?.customer?.companyName ?? "";
    return {
      invoiceNumber: invoiceData?.referenceCode ?? "",
      customer: companyName,
      companyName: loginUserCompany?.companyName ?? "",
      vendor: companyName,
    };
  };

  //return suggested template codes based on certain condition
  const getSuggestedTemplatesCode = () => {
    const isRecordStatusClosed = invoiceData?.invoiceStatusCode === "Closed";
    if (newActivityType === ActivityOptions.APPROVAL_REQUEST) {
      return isAPWorkspace
        ? getViewTypeTemplateCodes(viewType.AP_APPROVAL_REQUEST_ACTIVITY)
        : getViewTypeTemplateCodes(viewType.AR_APPROVAL_REQUEST_ACTIVITY);
    }

    if (isAPWorkspace) {
      return isRecordStatusClosed
        ? getViewTypeTemplateCodes(viewType.AP_TRANSACTION_BILLS_CLOSED)
        : getViewTypeTemplateCodes(viewType.AP_TRANSACTION_BILLS);
    } else {
      return isRecordStatusClosed
        ? getViewTypeTemplateCodes(viewType.AR_TRANSACTION_INVOICES_CLOSED)
        : getViewTypeTemplateCodes(viewType.AR_TRANSACTION_INVOICES);
    }
  };

  useEffect(() => {
    if (newActivityType === ActivityOptions.EMAIL) {
      setSupportedTemplateList(getTemplateDefinitionsByView(invoiceData));
      setDefaultTemplateId("");
    } else if (newActivityType === ActivityOptions.APPROVAL_REQUEST) {
      setSupportedTemplateList(
        templateFactory.getTemplateDefinitionsByView(
          selectedWorkspace.workspace_type === "accounts_payable" ? viewType.AP_APPROVAL_REQUEST_ACTIVITY : viewType.AR_APPROVAL_REQUEST_ACTIVITY,
          "",
          availableLanguagesForTemplates
        )
      );
      setDefaultTemplateId(
        selectedWorkspace.workspace_type === "accounts_payable" ? TemplateTypes.AP_APPROVAL_REQUEST : TemplateTypes.AR_APPROVAL_REQUEST
      );
    }
    // reset template states
    else {
      setSupportedTemplateList([]);
      setDefaultTemplateId("");
    }
    // rest the selected template code
    setTemplateName("");
  }, [newActivityType]);

  const getLastApprover = () => {
    setLastApproverloading(true);
    activitiesClientV2
      .getLastApprover(selectedWorkspace?.id, invoiceData.customerId)
      .then((response) => {
        if (response?.data?.last_approver?.email_address) {
          setLastApprover({
            id: response.data.last_approver.email_address || "",
            label: response.data.last_approver.contact_name || "",
            primary: false,
            connectionId: response.data.last_approver.company_id || "",
            erpContact: false,
            companyId: response.data.last_approver.company_id || "",
          });
        } else {
          setLastApprover({} as To);
        }
      })
      .finally(() => {
        setLastApproverloading(false);
      });
  };

  const filteredContacts = [...companyContactOptions, ...allContactOptions];

  useEffect(() => {
    if (newActivityType === ActivityOptions.APPROVAL_REQUEST && showActivity) {
      getLastApprover();
    } else {
      setLastApprover({} as To);
    }
  }, [newActivityType, showActivity]);

  /**
   * Function which return the component.
   *
   * @returns {Component} return the NewActivityDropdown component.
   */
  const addActivity = () => {
    return (
      <NewActivityPopup
        newActivityType={newActivityType}
        setShowAddActivity={setShowActivity}
        showAddActivity={showActivity}
        key={`loading-${lastApproverloading}`}
        title={BTN_NEW_ACTIVITY}
        contactOptions={enableTypeAheadSuggestions ? filteredContacts : companyContactOptions}
        defaultTo={newActivityType === ActivityOptions.APPROVAL_REQUEST ? [lastApprover] : primaryContact}
        handleReplySend={handleSend}
        isTemplateSupport={newActivityType === ActivityOptions.EMAIL || newActivityType === ActivityOptions.APPROVAL_REQUEST}
        supportedTemplateList={supportedTemplateList}
        editorState={editorState}
        handleReplySendClose={handleSendAndMarkClosed}
        disableSecondaryAction={newActivityType === ActivityOptions.APPROVAL_REQUEST}
        onClose={() => {
          setShowActivity(false);
          setEditorState("");
        }}
        setNewActivityType={(type) => {
          setNewActivityType(type);
        }}
        addActivityDropdown={[
          { activityType: "Email", displayName: "Email", icon: <Email /> },
          { activityType: "Note", displayName: "Note", icon: <NoteFill /> },
          { activityType: "Phone Call", displayName: "Phone Call", icon: <Phone /> },
        ]}
        prepareActivityBodyByTemplateID={prepareActivityBodyByTemplateID}
        fromTime={fromTime}
        toTime={toTime}
        setToTime={setToTime}
        enableKeyboardShortcuts={true}
        isSendButtonDisabled={visibility.isSendBtnDisabled}
        defaultTemplateId={defaultTemplateId ?? ""}
        typeAheadAction={typeAheadAction}
        loading={lastApproverloading}
        suggestedTemplatesCode={getSuggestedTemplatesCode()}
        tempSubjectSnippetValues={getSelectedTempSubjSnippetValues()}
      />
    );
  };

  const getCreditMemoIds = () => {
    return invoiceData?.creditMemos?.map((item) => item.creditMemoInvoiceId);
  };

  //Generate the tooltip text when user hovers over the Discounts applied value
  const getCreditMemoTooltipText = () => {
    let tooltipText = "This Invoice had multiple credit memo applied. Under credit memo number";
    invoiceData?.creditMemos?.forEach((item, i) => {
      const formattedCurrency = getNumberFormatted(item.creditMemoAppliedAmount);
      tooltipText =
        tooltipText +
        ` ${item.referenceCode} a discount of ${formattedCurrency} ${
          i + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE === invoiceData.creditMemos?.length ? "" : "and"
        }`;
    });
    return tooltipText + "had been applied.";
  };

  const handleCreditMemoClick = () => {
    history.push(`${history.location.pathname}/applied_credit_memos`, { from: "applied-discounts", creditMemoIds: getCreditMemoIds() });
  };
  const getHeaderAmount = () => {
    let amount = "0";
    amount = isCreditMemo
      ? calculateTotalDiscountAmount()
      : getNumberFormatted(invoiceData?.outstandingBalanceAmount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    return amount;
  };

  return (
    <div className={`id-wrapper`}>
      {isLoading ? (
        <Loading isRelative />
      ) : errorMessage ? (
        <div className="empty-data-wrapper">
          <EmptyStates title={NO_DATA_MESSAGE} caption={EMPTY_TRANSACTIONS_MESSAGE} />
        </div>
      ) : (
        <>
          {openAttachmentPreview && attachment !== null && (
            <AttachmentPreview
              openPreview={openAttachmentPreview}
              activeAttachmentID={activeAttachmentID}
              handleClose={onClickPreviewClose}
              attachments={[attachment]}
              onClickDownload={() => downloadInvoiceAndBill()}
            />
          )}
          <div className={`header-invoices`}>
            <p className={`title`}>{`${
              invoiceData.invoiceTypeCode?.includes("Credit Memo")
                ? props.configs.details.creditMemo.routeType
                : props.configs.details.invoices.routeType
            } #${invoiceData?.referenceCode ?? "N/A"}`}</p>
            <div className={`due-wrapper-invoices`}>
              <p className={`due-amount`}>{getHeaderAmount()}</p>
              <p className={`due-text`}>{isCreditMemo ? TOTAL_DISCOUNT : TOTAL_DUE} </p>
            </div>
            {TableUtils.formatInvoiceStatus(invoiceData.invoiceStatusCode, invoiceData.paymentDueDate ?? "", false)}
            <div className={`tag-invoices`}>
              {invoiceData && (invoiceData?.customer?.companyName ?? "N/A").charAt(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO)}
            </div>
            <p className={`name`} onClick={() => handleNameClick(invoiceData.customerId)}>
              {invoiceData && (invoiceData?.customer?.companyName ?? "N/A")}
            </p>
            <DocumentSwitcher />
          </div>
          {/* Body of Details*/}
          <div className={`body`}>
            {/* Invoice Details */}
            <div className={`invoice`}>
              <div className={`invoice-contact-wrapper`}>
                <div className={`head`}>
                  {addActivity()}
                  <div>
                    {/** Preview Button */}
                    <CustomTooltip
                      type={TooltipTypes.RICH_CONDENSE_V2}
                      title={finalValidationMessage !== "" ? finalValidationMessage : TooltipTitles.CLICK_TO_PREVIEW}
                      placement="bottom-start"
                    >
                      <span className="custom-preview-download-button preview-button">
                        <Button startIcon={<PreviewInfo />} variant="contained" disabled={!finalDownloadCheck} onClick={previewInvoiceAndBill}>
                          {isAPWorkspace ? "Preview Bill" : "Preview Invoice"}
                        </Button>
                      </span>
                    </CustomTooltip>

                    {/** Download Button */}
                    <CustomTooltip
                      type={TooltipTypes.RICH_CONDENSE_V2}
                      title={finalValidationMessage !== "" ? finalValidationMessage : ""}
                      placement="bottom-start"
                    >
                      <span className="custom-preview-download-button download-btn">
                        <Button
                          startIcon={<DownloadAttachment />}
                          variant="outlined"
                          disabled={!finalDownloadCheck}
                          onClick={downloadInvoiceAndBill}
                          disableRipple
                        >
                          {isAPWorkspace ? "Download Bill" : "Download Invoice"}
                        </Button>
                      </span>
                    </CustomTooltip>
                  </div>
                </div>
                <div className="body">
                  {detailsBody.map((detail: any) => {
                    return (
                      <Detail
                        ref={detailRef}
                        key={detail.icon}
                        toolTipText={getCreditMemoTooltipText()}
                        icon={getIcon(detail.icon)}
                        columns={detail.row.map((column: any) => ({ ...column, value: propertyValueMap[column.value] }))}
                        handleTransactionActivity={setShowActivity}
                        onClickHandler={handleCreditMemoClick}
                      />
                    );
                  })}
                </div>
              </div>

              {hasPayments && (
                <div className={`payment-wrapper`}>
                  <div className={`title`}>Payments Applied</div>
                  <div className="row row-header">
                    {props.configs.details.invoices.table.columns.map((column: any) => {
                      return (
                        <div className={`header ${column.styles}`} key={column.header}>
                          {column.header}
                        </div>
                      );
                    })}
                  </div>
                  <OverlayScrollbarsComponent options={{ paddingAbsolute: true, autoUpdate: true, sizeAutoCapable: false }}>
                    {paymentsApplied &&
                      paymentsApplied?.map((val: PaymentsAppliedData) => {
                        return (
                          <div className="row" onClick={() => val.handleClick(val.id)} key={val.id}>
                            <p className="id">{`${val?.referenceCode || " N/A"}`}</p>
                            <p className={`type`}>
                              {val.appliedAmount == invoiceData.totalAmount ? `Full ${val.type} Applied` : `Partial ${val.type} Applied`}
                            </p>
                            <p className={`date`}>{formatDate(val.applyToInvoiceDate)}</p>
                            <p className={`amount`}>{val.appliedAmount !== undefined && getNumberFormatted(val.appliedAmount)}</p>
                          </div>
                        );
                      })}
                  </OverlayScrollbarsComponent>
                </div>
              )}

              {creditMemoInvoices && creditMemoInvoices.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && (
                <div className={`payment-wrapper`}>
                  <div className={`title`}>{props.configs.details.creditMemo.title}</div>
                  <div className="row row-header">
                    {props.configs.details.creditMemo.table.columns.map((column: any) => {
                      return (
                        <div className={`header ${column.styles}`} key={column.header}>
                          {column.header}
                        </div>
                      );
                    })}
                  </div>
                  <OverlayScrollbarsComponent options={{ paddingAbsolute: true, autoUpdate: true, sizeAutoCapable: false }}>
                    {creditMemoInvoices &&
                      creditMemoInvoices?.map((val: CreditMemoInvoices) => {
                        return (
                          <div className="row" onClick={() => handleInvoiceClick(val.id)} key={val.id}>
                            <p className="id">{`${val?.referenceCode || " N/A"}`}</p>
                            <p className={`amount`}>
                              {val.totalAmount !== undefined && getNumberFormatted(val.totalAmount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO)}
                            </p>
                            <p className={`amount`}>
                              -
                              {val.individualDiscounts !== undefined &&
                                getNumberFormatted(val.individualDiscounts ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO)}
                            </p>
                            <p className={`amount`}>
                              {val.paymentAmount !== undefined && getNumberFormatted(val.paymentAmount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO)}
                            </p>
                          </div>
                        );
                      })}
                  </OverlayScrollbarsComponent>
                </div>
              )}
            </div>
            {/* tabs */}
            <div className="details-tab-wrapper">
              <DetailsTab
                newActivityComponentProps={{
                  setNewActivityType,
                  setShowAddActivity: toggleActivity,
                  addActivityDropdown: addActivityDropdownOptions,
                }}
                id={invoiceId}
                type="invoice"
                profileInfo={{
                  customerId: invoiceData?.customerId ?? "",
                  phone: invoiceData?.customerPrimaryContact?.phone ?? "",
                  email: invoiceData?.customerPrimaryContact?.emailAddress ?? "",
                }}
              />
            </div>
          </div>
          <div id="tooltip-container" style={{ display: "none" }} ref={tooltipRef} />
        </>
      )}
    </div>
  );
}

import React, { ReactElement, useEffect, useMemo } from "react";
import { Skeleton, SvgIcon, Button as MuiButton } from "@mui/material";
import { ActivityActionTypes, ActivityStatus, ConnectionStatus, FallbackTypes, WorkspaceType } from "../../../../../types/enums";
import Button from "../../../../library/Button/Button";
import { Caution, Check } from "../../../../library/Icons/Icons";
import ApprovalRequestReview from "../ActivityFeedActions/ReviewApprovalRequest/ReviewApprovalRequest";
import methods from "../helpers/ActivityFeedMethods";
import { useHistory, useParams, useRouteMatch, useLocation } from "react-router-dom";
import { activitiesClientV2, settingsClient } from "../../../../../db/version2Accessor";
import useWorkspaceConfigurations from "../../../../../hooks/useWorkspaceConfigurations";
import { ActivityContext } from "../../../../../contexts/ActivityContext";
import { AlertContext } from "../../../../../contexts/AlertContext";
import { ApplicationRouteContext } from "../../../../../contexts/ApplicationRouteContext";
import { ComponentConfigContext } from "../../../../../contexts/ComponentConfigContext";
import { FeatureFlagContext, FeatureFlagProviderType } from "../../../../../contexts/FeatureFlagContext";
import { SettingsContext } from "../../../../../contexts/SettingsContext";
import * as Labels from "../../../../../constants/config";
import { ActivityFeedKeyboardShortCutConstants, KeyboardActionsConstants } from "../../../../../constants/KeyboardShortcutConstants";
import { NUMERIC_VALUES } from "../../../../../constants/NumericConstants";
import { TooltipTitles, TooltipTypes } from "../../../../../constants/TooltipConstants";
import SetupAutomationEmail from "../ActivityFeedActions/SetupAutomationEmail/SetupAutomationEmail";
import _ from "underscore";
import useHandleVisibility from "../../../../../hooks/useHandleVisibility";
import Utils from "../../../../../utils/utils";
import CancelApprovalRequest from "../ActivityFeedActions/CancelApprovalRequest/CancelApprovalRequest";
import CustomTooltip from "../../../../library/CustomTooltip/CustomTooltip";
import { ReactComponent as CommandCenterActive } from "../../../../../assets/Command_Center_Active.svg";
import { ReactComponent as CommandCenterDefault } from "../../../../../assets/Command_Center_Default.svg";
import CommandCenterModal from "../../../ActivityDetailV2/CommandCenter/CommandCenterModal";
import ActivityReassignDropdown from "./ActivityReassignDropdown/ActivityReassignDropdown";
import useKeyboardShortcut from "../../../../../hooks/useKeyboardShortcut";
import InviteMembersFlyoutButton from "../../../../Settings/UserPermissions/InviteMemberFlyoutButton/InviteMemberFlyoutButton";
import { ReactComponent as ActivityFlag } from "../../../../library/AtomicComponents/svg/activity_flag.svg";
import { DisableFeatureContext, DisableFeatureProviderType } from "../../../../../contexts/DisableFeatureContext";
import "./ActionBar.scss";
import TrackingUtils from "../../../../Tracking/Tracking.Utils";
import { useTranslation } from "react-i18next";
import CompanyTypeChip from "../../../../library/AtomicComponents/atomic/CompanyChip/Component";

interface ActionBarProps {
  isLoading: boolean;
  ActivityDropdown: ReactElement;
  getActivityStream: (
    withLoadingAnimation: boolean,
    onActionPerform?: boolean,
    explicitlySetPageSize?: number,
    refreshStream?: boolean,
    refreshActivityDetail?: boolean
  ) => void;
  refreshInboxMegamenuCount: () => Promise<void>;
  details: {
    refreshActivity: () => void;
    connectionStatus: string;
    companyName: string;
    companyType: string;
    companyId: string;
    erpConnection: boolean;
    streamId: UUID;
    streamFlag: StreamFlag;
  };
  reassign: {
    handlers: {
      resetData: () => void;
    };
  };
  nudge: {
    handlers: {
      handleNudge: () => void;
    };
  };
  approval: {
    approvalReviewDrawer: boolean;
    approvalCancelModal: boolean;
    activityItemId: string | undefined;
    reviewAction: string | undefined;
    handlers: {
      onCloseApprovalReview: () => Promise<void>;
    };
  };
}

const ActionBar: React.FC<ActionBarProps> = ({
  isLoading,
  getActivityStream,
  refreshInboxMegamenuCount,
  details: { refreshActivity, connectionStatus, companyName, companyType, companyId, erpConnection, streamId, streamFlag = null },
  reassign: {
    handlers: { resetData },
  },
  nudge: {
    handlers: { handleNudge },
  },
  approval: {
    approvalReviewDrawer,
    approvalCancelModal,
    activityItemId,
    reviewAction,
    handlers: { onCloseApprovalReview },
  },
  ActivityDropdown,
}) => {
  const classes = methods.useTooltipStyles();
  const { url } = useRouteMatch();
  const { getBaseRoute } = React.useContext(ApplicationRouteContext) as ApplicationRouteType;
  const { customerId, activityId } = useParams<{ customerId: string; activityId: string }>();
  const history = useHistory();

  /** DATA IN-FLOW */
  const { activityData, activityCloseAction, activityPerformInProgress } = React.useContext(ActivityContext) as ActivityType;
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const { apAutomationMail, setApAutomationMail } = React.useContext(SettingsContext) as SettingsType;
  const { isEnabled } = React.useContext(FeatureFlagContext) as FeatureFlagProviderType;

  /**
   * setup common state with reducer to handle open/close states for multiple
   * activity actions - tooltips, modals and flyouts to remove multiple redundant
   * React useState hooks.
   */

  const subMenuVisibility = {
    assignSubMenu: false,
    moveSubMenu: false,
    snoozeSubMenu: false,
    senderSpamSubMenu: false,
    senderFraudSubMenu: false,
    setupAutomation: false,
    approvalReviewLoader: false,
  };

  const { selectedWorkspace, workspaceHomePath, configs } = useWorkspaceConfigurations();
  const { getConfig } = React.useContext(ComponentConfigContext) as ComponentConfigType;
  const componentConfig = getConfig(selectedWorkspace.workspace_type_route?.toLocaleLowerCase() ?? WorkspaceType.AR) as any;
  const { updateBaseRoute } = React.useContext(ApplicationRouteContext) as ApplicationRouteType;
  const { visibility, dispatch } = useHandleVisibility<typeof subMenuVisibility>(subMenuVisibility);
  const [isSecondaryViewOpen, setIsSecondaryViewOpen] = React.useState<boolean>(false);
  const [inviteFlyout, setInviteFlyout] = React.useState<boolean>(false);
  const { setDisableKeyboardShortcut } = React.useContext(DisableFeatureContext) as DisableFeatureProviderType;

  /** STATES */
  const [inputAutomationEmail, setInputAutomationEmail] = React.useState<string>("");
  const [openCommandCenter, setOpenCommandCenter] = React.useState(false);
  const [showCommandCenterTooltip, setShowCommandCenterTooltip] = React.useState(false);
  const [commandCenterHover, setCommandCenterHover] = React.useState<boolean>(false);

  /** Mapping CONDITION */
  const getCompanyType = (() => {
    if (erpConnection === false) {
      if (companyType === "Company") {
        return "My Company";
      } else {
        return "Other Connection";
      }
    } else {
      return companyType;
    }
  })();
  const enableActivityActions =
    !activityData?.spam && ![ConnectionStatus.FRAUD, ConnectionStatus.SPAM].includes(connectionStatus as ConnectionStatus);
  const enableSetupAutomation =
    selectedWorkspace.workspace_type_route === "ap" && isEnabled("FORWARD_AP_AUTOMATION") && streamFlag === "new_bill" && _.isEmpty(apAutomationMail);
  const { pathname } = useLocation();
  const isEInvoicingRoute = pathname.includes("receivedEInvoices") || pathname.includes("sentEInvoices");
  const { t } = useTranslation();

  const flagScheme: Record<StreamFlag, { [key: string]: string }> = {
    new_vendor: {
      title: t("flagChipLabels.newVendor", { ns: "activities" }),
      color: "purple",
    },
    new_bill: {
      title: t("flagChipLabels.newBill", { ns: "activities" }),
      color: "green",
    },
    bill: {
      title: t("flagChipLabels.bill", { ns: "activities" }),
      color: "orange",
    },
    existing_bill: {
      title: t("flagChipLabels.existingBill", { ns: "activities" }),
      color: "orange",
    },
  };

  /****** NETWORK *******/

  const handleApAutomationEmail = async () => {
    const toastOptions = {
      open: true,
      message: t("toastMessages.setupAutomation.invalidEmail", { ns: "activities" }),
      severity: "error",
    } as ToastOptions;
    if (!Utils.validEmail(inputAutomationEmail)) {
      setToastOptions(toastOptions);
      return;
    }
    try {
      await settingsClient.updateSettings({
        ap_automation_email: inputAutomationEmail,
        activity_stream_id: streamId,
      });
      setApAutomationMail(inputAutomationEmail);
      dispatch({ type: "close", payload: "setupAutomation" });
      setToastOptions({
        ...toastOptions,
        severity: "success",
        message: t("toastMessages.setupAutomation.success", { ns: "activities" }),
      });
    } catch (error) {
      setToastOptions({
        ...toastOptions,
        message: t("toastMessages.setupAutomation.error", { ns: "activities" }),
      });
    }
  };

  /****** EVENT HANDLERS *******/
  /**
   * @function onCloseCallback
   * A helper callback to set specific behaviour on closing an activity stream.
   * history.goBack() is used to navigate back the index from which activity stream has been opened.
   * Alternatively getActivityStream(true, true); & props.refreshActivity(); can be used to refresh the current activity stream.
   */
  const onCloseCallback = () => {
    history.goBack();
    getActivityStream(false, undefined, undefined, true, true);
  };

  const onClickUpdate = async (note = "") => {
    dispatch({ type: "open", payload: "approvalReviewLoader" });
    let toastOptions: ToastOptions = {
      open: true,
      severity: "success",
      message: t("toastMessages.updateApproval.success", { ns: "activities" }),
    };
    let response = {} as APIResponse;
    try {
      response = await activitiesClientV2.reviewActivityRequest(selectedWorkspace.id, activityId, activityItemId ?? "", reviewAction ?? "", note);
    } catch (e: unknown) {
      response.success = false;
    } finally {
      if (!response.success) {
        toastOptions = {
          ...toastOptions,
          severity: "error",
          message: t("toastMessages.updateApproval.error", { ns: "activities" }),
        };
      }
      onCloseApprovalReview();
      setToastOptions(toastOptions);
      // refresh activity
      getActivityStream(false, false, NUMERIC_VALUES.CONSTANT_ONE, true, true);
    }
    dispatch({ type: "close", payload: "approvalReviewLoader" });
  };

  const onClickCustomerName = () => {
    const erpConnectionRoute = companyType === "Vendor" ? "vendors" : "customers";
    const connectionRoute = erpConnection ? erpConnectionRoute : componentConfig.otherConnections.routeType;
    const backRoute = activityData?.primary_connection?.type === "Company" ? componentConfig.myCompany.routeType : connectionRoute;
    const base = `${workspaceHomePath}/${backRoute}/active/${companyId}`;
    updateBaseRoute(base);
    history.push(base);
  };

  const onCloseSubMenu = () => {
    dispatch({ type: "close", payload: "assignSubMenu" });
    dispatch({ type: "close", payload: "moveSubMenu" });
    dispatch({ type: "close", payload: "senderSpamSubMenu" });
    dispatch({ type: "close", payload: "senderFraudSubMenu" });
    dispatch({ type: "close", payload: "snoozeSubMenu" });
    setIsSecondaryViewOpen(false);
  };

  const onClickCommandCenterButton = () => {
    setShowCommandCenterTooltip(true);
    setOpenCommandCenter(true);
  };

  const onClickCloseCommandCenter = () => {
    onCloseSubMenu();
    setOpenCommandCenter(false);
    setShowCommandCenterTooltip(false);
    refreshInboxMegamenuCount();
  };

  const onClickOpenAssignSubMenu = () => {
    dispatch({ type: "open", payload: "assignSubMenu" });
    setIsSecondaryViewOpen(true);
    setOpenCommandCenter(true);
  };

  const onClickOpenMoveSubMenu = () => {
    dispatch({ type: "open", payload: "moveSubMenu" });
    setIsSecondaryViewOpen(true);
    setOpenCommandCenter(true);
  };

  const onClickOpenSnoozeSubMenu = () => {
    dispatch({ type: "open", payload: "snoozeSubMenu" });
    setIsSecondaryViewOpen(true);
    setOpenCommandCenter(true);
  };

  const onClickMarkSenderSpam = () => {
    dispatch({ type: "open", payload: "senderSpamSubMenu" });
    setIsSecondaryViewOpen(true);
  };

  const onClickMarkSenderFraud = () => {
    dispatch({ type: "open", payload: "senderFraudSubMenu" });
    setIsSecondaryViewOpen(true);
  };

  const onMarkActivitySpam = async () => {
    onClickCloseCommandCenter();
    let toastOptions: ToastOptions = {
      open: true,
      severity: "success",
      message: !activityData?.spam
        ? t("toastMessages.markAsSpam.success", { ns: "activities" })
        : t("toastMessages.markAsNotSpam.success", { ns: "activities" }),
    };
    let response = {} as APIResponse;
    try {
      response = await activitiesClientV2.patch(selectedWorkspace?.id || FallbackTypes.Id, activityId, ActivityActionTypes.Spam, {
        spam: !activityData?.spam,
      });
    } catch {
      response.success = false;
    } finally {
      if (!response.success) {
        toastOptions = { ...toastOptions, severity: "error", message: t("toastMessages.markAsSpam.error", { ns: "activities" }) };
      }
      //Call Pendo event only when we are marking activity as spam
      if (!activityData?.spam) {
        TrackingUtils.trackUserActivities(Labels.ACTIVITY_SPAM_PENDO, response.success);
      }
      setToastOptions(toastOptions);
      getActivityStream(false, false, undefined, true, true);
      refreshActivity();
    }
  };

  const onMarkActivityUnread = async () => {
    onClickCloseCommandCenter();
    let toastOptions: ToastOptions = {
      open: true,
      severity: "success",
      message: t("toastMessages.markAsUnRead.detailSuccess", { ns: "activities" }),
    };
    let response = {} as APIResponse;
    try {
      response = await activitiesClientV2.patch(selectedWorkspace?.id || FallbackTypes.Id, activityId, ActivityActionTypes.Read, {
        read: false,
      });
      if (customerId) {
        // We visited by way of the contact detail page
        // In case of Contact evaluating if Activity opened through Other Connections or Customers/Vendors
        // In case of Contact evaluting if the contact is archived
        history.push(
          `${workspaceHomePath}/${url.toLowerCase().includes("/connections") ? "connections" : configs.activities.detail.resources.connections}/${
            url.toLowerCase().includes("/archived") ? "archived" : "active"
          }/${customerId}`
        );
      } else if (getBaseRoute().includes("activities")) {
        // we came from activities table view, go back to table
        history.goBack();
      } else {
        // we came from some other view, move back to base route
        history.push(getBaseRoute());
      }
    } catch (error: unknown) {
      response.success = false;
    } finally {
      if (!response.success) {
        toastOptions = { ...toastOptions, severity: "error", message: t("toastMessages.markAsUnRead.detailFailed", { ns: "activities" }) };
      }
      setToastOptions(toastOptions);
    }
  };

  const handleEscapeKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Escape" || event.code === "Escape") {
      onClickCloseCommandCenter();
    }
  };

  /**
   * Flag for Keyboard Shortcut
   */

  const isKeyboardShortcutFlagEnabled = isEnabled("KEYBOARD_SHORTCUTS");

  /**
   * HOC Hook for Keyboard ShortCut UI
   **/
  const { actionToPerform, setActionDone } = useKeyboardShortcut(ActivityFeedKeyboardShortCutConstants, !isKeyboardShortcutFlagEnabled);

  /**
   * Actions for Keyboard Shortcuts
   */

  const keyboardActions: { [key: string]: any } = useMemo(
    () => ({
      [KeyboardActionsConstants.open_command_center]: () => setOpenCommandCenter(true),
      [KeyboardActionsConstants.mark_unread]: () => {
        if (activityData?.status?.toLowerCase() !== ActivityStatus.Closed.toLowerCase() && !activityData?.spam) {
          onMarkActivityUnread();
        }
      },
      [KeyboardActionsConstants.mark_assign]: () => !activityData?.spam && onClickOpenAssignSubMenu(),
      [KeyboardActionsConstants.mark_spam]: (): Promise<void> => onMarkActivitySpam(),
      [KeyboardActionsConstants.mark_move]: () => !activityData?.spam && onClickOpenMoveSubMenu(),
      [KeyboardActionsConstants.mark_snooze]: () => {
        if (activityData?.status?.toLowerCase() !== ActivityStatus.Snoozed.toLowerCase() && !activityData?.spam) {
          onClickOpenSnoozeSubMenu();
        }
      },
      [KeyboardActionsConstants.mark_as_complete]: () => {
        if (activityData?.status?.toLowerCase() !== ActivityStatus.Closed.toLowerCase() && !activityData?.spam) {
          activityCloseAction([activityId], onCloseCallback);
        }
      },
    }),
    [
      activityId,
      activityData,
      openCommandCenter,
      setOpenCommandCenter,
      onMarkActivitySpam,
      onMarkActivityUnread,
      onClickOpenAssignSubMenu,
      onClickOpenMoveSubMenu,
      onClickOpenSnoozeSubMenu,
      visibility.assignSubMenu,
      visibility.moveSubMenu,
      visibility.snoozeSubMenu,
    ]
  );

  //handle onClose activity
  const shortcutDisplayMapper: { [key: string]: string } = useMemo(() => {
    if (isKeyboardShortcutFlagEnabled) {
      const mapper: { [key: string]: string } = {};
      ActivityFeedKeyboardShortCutConstants.forEach((item) => {
        if (item.identifier) {
          mapper[item.identifier] = item.displayText;
        }
      });
      return mapper;
    }
    return {};
  }, [isKeyboardShortcutFlagEnabled]);

  const onClickMarkComplete = _.debounce(() => {
    activityCloseAction([activityId], onCloseCallback);
  }, NUMERIC_VALUES.CONSTANT_THOUSAND);

  /**
   * Use Effect Calls
   */

  useEffect(() => {
    if (
      isKeyboardShortcutFlagEnabled &&
      actionToPerform &&
      keyboardActions[actionToPerform] &&
      typeof keyboardActions[actionToPerform] === "function"
    ) {
      keyboardActions[actionToPerform]();
      setActionDone();
    }
  }, [actionToPerform, isKeyboardShortcutFlagEnabled]);

  useEffect(() => {
    if (inviteFlyout || isSecondaryViewOpen) {
      setDisableKeyboardShortcut(true);
    } else {
      setDisableKeyboardShortcut(false);
    }
    return () => setDisableKeyboardShortcut(false);
  }, [inviteFlyout, isSecondaryViewOpen]);

  useEffect(() => {
    if (openCommandCenter) {
      window.addEventListener("keydown", handleEscapeKeyPress);
    }

    return () => {
      window.removeEventListener("keydown", handleEscapeKeyPress);
    };
  }, [openCommandCenter]);

  return (
    <div className="action-bar-container">
      <div className="head-grp">
        <div className="left">
          {ActivityDropdown}
          <span className={`connection-tag ${companyType.toLocaleLowerCase()}`}>
            {isLoading ? (
              <Skeleton variant="text" width="3rem" height="1.5rem" animation="wave" />
            ) : (
              <CompanyTypeChip companyType={getCompanyType} translationObject={t} />
            )}
          </span>
          <div className="company-title" onClick={onClickCustomerName}>
            {isLoading ? <Skeleton variant="text" width="3rem" height="100%" animation="wave" /> : companyName}
          </div>
          {enableSetupAutomation && (
            <div className="setup-automation">
              <Button
                size="sm"
                variant="secondary"
                onClick={() => {
                  dispatch({ type: "open", payload: "setupAutomation" });
                }}
              >
                {t("activityDetails.actionBar.setupAutomation.autoForwardingButton", { ns: "activities" })}
              </Button>
              <CustomTooltip
                type={TooltipTypes.RICH}
                classes={{ tooltip: classes.richTooltip }}
                title={t("activityDetails.actionBar.setupAutomation.autoForwardTooltip", { ns: "activities" }) as string}
                followCursor
                placement="bottom-start"
              >
                <div className="column-icon">
                  <Caution />
                </div>
              </CustomTooltip>
            </div>
          )}
        </div>
        <div className="right">
          {enableActivityActions && (
            <>
              <ApprovalRequestReview
                open={approvalReviewDrawer}
                onClose={onCloseApprovalReview}
                onClickUpdate={onClickUpdate}
                loader={visibility.approvalReviewLoader}
              />
              <SetupAutomationEmail
                showModal={visibility.setupAutomation}
                updateSettings={handleApAutomationEmail}
                setInputEmail={setInputAutomationEmail}
                closeModal={() => dispatch({ type: "close", payload: "setupAutomation" })}
              />
              <CancelApprovalRequest
                open={approvalCancelModal}
                onClose={onCloseApprovalReview}
                onClickCancel={onClickUpdate}
                loader={visibility.approvalReviewLoader}
              />
            </>
          )}
          {streamFlag && Object.keys(flagScheme).includes(streamFlag as string) ? (
            <div className={`activity-flag ${flagScheme[streamFlag].color}`}>
              <div className={`activity-flag-icon`}>
                <ActivityFlag />
              </div>
              <span className={`activity-flag-title ${streamFlag}`}>{flagScheme[streamFlag].title}</span>
            </div>
          ) : (
            <></>
          )}
          {!isEInvoicingRoute && (
            <>
              <ActivityReassignDropdown
                onCallback={() => {
                  resetData();
                  getActivityStream(true, true);
                  refreshActivity();
                }}
                activityIds={[activityId]}
                currentAssignee={isLoading ? null : activityData.assignee}
                setInviteFlyout={setInviteFlyout}
              />
              {activityData?.status?.toLowerCase() !== ActivityStatus.Closed.toLowerCase() && !activityData?.spam && (
                <CustomTooltip
                  type={TooltipTypes.SHORTCUT}
                  title={t("activityDetails.actionBar.markComplete.tooltipText", { ns: "activities" }) as string}
                  shortcutKeysText={isKeyboardShortcutFlagEnabled ? shortcutDisplayMapper?.[TooltipTitles.MARK_AS_COMPLETE] : ""}
                >
                  <span className="mark-as-complete">
                    <MuiButton startIcon={<Check />} variant="outlined" onClick={onClickMarkComplete} disabled={activityPerformInProgress?.Close}>
                      {t("activityDetails.actionBar.markComplete.buttonText", { ns: "activities" })}
                    </MuiButton>
                  </span>
                </CustomTooltip>
              )}
              <span
                onMouseEnter={() => setCommandCenterHover(true)}
                onMouseLeave={() => setCommandCenterHover(false)}
                onClick={onClickCommandCenterButton}
                className="command-center-button"
              >
                <SvgIcon component={openCommandCenter || commandCenterHover ? CommandCenterActive : CommandCenterDefault} inheritViewBox></SvgIcon>
              </span>
            </>
          )}
        </div>
      </div>
      {openCommandCenter && !isEInvoicingRoute ? (
        <CommandCenterModal
          open={openCommandCenter}
          isSecondaryViewOpen={isSecondaryViewOpen}
          showCommandCenterTooltip={showCommandCenterTooltip}
          visibility={visibility}
          handleCommandCenterClose={onClickCloseCommandCenter}
          handleNudge={handleNudge}
          getActivityStream={getActivityStream}
          refreshActivity={refreshActivity}
          onCloseSubMenu={onCloseSubMenu}
          onMarkActivitySpam={onMarkActivitySpam}
          onMarkActivityUnread={onMarkActivityUnread}
          onClickOpenAssignSubMenu={onClickOpenAssignSubMenu}
          onClickOpenMoveSubMenu={onClickOpenMoveSubMenu}
          onClickOpenSnoozeSubMenu={onClickOpenSnoozeSubMenu}
          onClickMarkSenderSpam={onClickMarkSenderSpam}
          onClickMarkSenderFraud={onClickMarkSenderFraud}
          onCallback={() => {
            resetData();
            getActivityStream(true, true);
            refreshActivity();
          }}
        />
      ) : null}
      <InviteMembersFlyoutButton open={inviteFlyout} setInviteFlyout={setInviteFlyout} />
    </div>
  );
};
export default ActionBar;

import DOMPurify from "dompurify";
import React from "react";
import { GLOBAL_SEARCH_CONSTANTS } from "../../../constants/GlobalSearchConstants";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../../constants/NumericConstants";
import useLocale from "../../../hooks/useLocale";
import { GlobalSearchCategories } from "../../../types/enums";
import Utils from "../../../utils/utils";
import { RecentSearch, RecentSearchSimple, Search } from "../../library/Icons/Icons";
import Loading from "../../library/Loading/Loading";
import { SmartText } from "../../library/SmartText";
import { RecentSearchItem, WidgetSuggestionsInterface } from "../Interfaces/WidgetInterfaces";
import "../Styles/WidgetSuggestionsStyles.scss";

const WidgetSuggestions: React.FC<WidgetSuggestionsInterface> = ({
  commonProps: {
    activeItemRef,
    activeKeyDownIndex,
    config,
    containerRef,
    enableDropdown,
    iconMap,
    loading,
    onClickNavToAllResults,
    onClickSuggestions,
    query,
    currency,
  },
  globalSearchResultProps: { data, enableGlobalSuggestions },
  recentSearchProps: { enableRecentSuggestions, recentSuggestions },
}: WidgetSuggestionsInterface) => {
  const { formatDateBasedOnCountry: formatDate } = useLocale();

  /**
   * // No Result Found //
   */
  const showNoResultsFound = () => (
    <div className="gsw-no-results-container">
      <div className="no-results-icon">
        <Search />
      </div>
      <p className="no-results-header">No Results Found</p>
      <p className="no-results-subheader">Try different keywords or remove search filters</p>
    </div>
  );

  /**
   * // Results Loading //
   */
  const fetchSearchResultsLoading = () => (
    <div className="gsw-search-loading-container">
      <div className="gsw-search-loading-icon">
        <Loading />
      </div>
      <p className="gsw-search-loading-header">We are fetching the search results in a bit</p>
      <p className="gsw-search-loading-subheader">Hang tight, this won’t take more than a few seconds</p>
    </div>
  );

  /**
   * // List Items View //
   */
  const getListItem = (
    header = "N/A",
    subheader = "N/A",
    contentPreview = "",
    info = "N/A",
    moreDetails = "",
    id = "invalid-id",
    category = "",
    itemIndex: number
  ) => (
    <div
      key={`gsw-${header}`}
      ref={(enableGlobalSuggestions && itemIndex === activeKeyDownIndex && activeItemRef) || undefined}
      className={`gsw-suggestion-listItem-container ${itemIndex === activeKeyDownIndex ? "gsw-suggestion-listItem-container-active" : ""} ${category
        ?.split("_")
        .join("-")}`}
      onClick={onClickSuggestions.bind(this, id, header, category)}
    >
      <div className="gsw-suggestion-listItem-row-intermediate">
        <div className="gsw-suggestion-listItem-row">
          <SmartText
            title={DOMPurify.sanitize(header) ?? ""}
            highlighter={{
              enableHighlighter: true,
              matchText: query ?? "~",
            }}
            className="gsw-suggestion-listItem-header"
          />
          <p className="gsw-suggestion-listItem-info">{DOMPurify.sanitize(info) ?? ""}</p>
        </div>
        <div className="gsw-suggestion-listItem-row">
          <SmartText
            title={DOMPurify.sanitize(subheader) ?? ""}
            highlighter={{
              enableHighlighter: true,
              matchText: query ?? "~",
            }}
            className="gsw-suggestion-listItem-subheader"
          />
          <p className="gsw-suggestion-listItem-info">{DOMPurify.sanitize(moreDetails) ?? ""}</p>
        </div>
        <div className="gsw-suggestion-listItem-row">
          <SmartText
            title={DOMPurify.sanitize(contentPreview) ?? ""}
            highlighter={{
              enableHighlighter: true,
              matchText: query ?? "~",
            }}
            className="gsw-suggestion-listItem-content-preview"
          />
        </div>
      </div>
    </div>
  );

  /**
   * // List Category View //
   */

  const getCategoryListItems = (category: string, totalCount: number, search: string) => (
    <div key={`gsw-${category}`} className="gsw-suggestion-categoryView-container">
      <div className="gsw-suggestion-category-header">
        <div className="gsw-suggestion-category-header-icon">{iconMap[category]}</div>
        <div className="gsw-suggestion-category-header-text">{config["displayLabels"][category]}</div>
      </div>
      <div className="gsw-suggestion-category-view-all-container">
        <div className="gsw-suggestion-category-view-all-text" onClick={onClickNavToAllResults.bind(this, search, category)}>
          View all ({totalCount > NUMERIC_VALUES.CONSTANT_THOUSAND ? NUMERIC_VALUES.CONSTANT_THOUSAND + "+" : totalCount})
        </div>
      </div>
    </div>
  );

  /**
   * // Formatters for different values //
   */

  const formatListData = (value: string | number | null | undefined, type?: string): string | undefined => {
    switch (type) {
      case "timestamp":
        return formatDate(Utils.evaluateDate(value as number));
      case "isoDate":
        return formatDate(value as string);
      case "currency":
        return Utils.formatValueAsCurrency(value as number, currency?.locale, currency?.code);
      default:
        return value?.toString();
    }
  };

  /**
   * // Logic for list data splitting //
   */

  const getListData = () => {
    const dataKeyMap = config.dataSelectors;
    return (
      <>
        {data.map((info, index: number) => {
          const infoData = dataKeyMap[info.category ?? ""];
          if (!infoData) return;
          return (
            <>
              {getCategoryListItems(info?.category, info?.count, query)}
              {info?.result.map((item: any, pos: number) => {
                return getListItem(
                  formatListData(item[infoData.header.key], infoData.header.type),
                  formatListData(item[infoData.subheader.key], infoData.subheader.type),
                  infoData?.contentPreview && item[infoData?.contentPreview?.key]
                    ? formatListData(item[infoData?.contentPreview?.key], infoData.contentPreview.type)
                    : "",
                  formatListData(item[infoData.info.key], infoData.info.type),
                  formatListData(item[infoData.moreDetails.key], infoData.moreDetails.type),
                  item[infoData.id],
                  info?.category,
                  index * GLOBAL_SEARCH_CONSTANTS.ALL_FILTER_MAX_SHOW_RESULTS + pos
                );
              })}
            </>
          );
        })}
      </>
    );
  };

  /**
   * // View for search Results //
   */

  const getAllSearchResults = () => {
    return (
      <div ref={(enableGlobalSuggestions && containerRef) || undefined} className="gsw-suggestion-all-category">
        {getListData()}
      </div>
    );
  };

  /**
   * Recent Suggestions
   */

  const getRecentSuggestionsItems = (item: RecentSearchItem, index: number) => {
    const recentQuery = item.query ? DOMPurify.sanitize(item?.query) : "N/A";
    return (
      <div
        key={item?.id + item?.category + recentQuery}
        ref={(enableRecentSuggestions && index === activeKeyDownIndex && activeItemRef) || undefined}
        className={`gsw-recent-suggestions-item ${index === activeKeyDownIndex ? "gsw-recent-suggestions-item-selected" : ""}`}
        onClick={onClickSuggestions.bind(this, item?.id, recentQuery, item?.category)}
      >
        <div className="gsw-recent-category-icon">{iconMap[item?.category]}</div>
        <div className="gsw-recent-suggestions-item-container">
          <div className="gsw-recent-suggestions-item-query">
            <SmartText title={recentQuery} className="recent-search-smart-text" />
          </div>
          {item?.category !== GlobalSearchCategories.all && (
            <div className="gsw-recent-suggestions-item-category">{config["displayLabels"][item.category]}</div>
          )}
        </div>
      </div>
    );
  };

  const getRecentSuggestions = () => {
    return (
      <div ref={(enableRecentSuggestions && containerRef) || undefined} className="gsw-recent-suggestions-container">
        <div className="gsw-recent-suggestions-header">
          <div className="gsw-recent-suggestions-header-icon">
            <RecentSearchSimple />
          </div>
          <div className="gsw-recent-suggestions-header-text">RECENTLY VIEWED RESULTS</div>
        </div>
        {recentSuggestions?.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO ? (
          <div className="gsw-recent-suggestions-no-recent">
            <div className="gsw-recent-suggestions-no-recent-icon">
              <RecentSearch />
            </div>
            <div className="gsw-recent-suggestions-no-recent-text">No Recent Search History Found</div>
          </div>
        ) : (
          recentSuggestions?.map((item: RecentSearchItem, index: number) => getRecentSuggestionsItems(item, index))
        )}
      </div>
    );
  };

  /**
   * // Rendering Logic //
   */

  const getSuggestionView = () => {
    if (enableGlobalSuggestions) {
      if (loading) {
        return fetchSearchResultsLoading();
      } else if (data?.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
        return showNoResultsFound();
      } else {
        return getAllSearchResults();
      }
    } else {
      return getRecentSuggestions();
    }
  };

  return <div className={`gsw-suggestions-container gsw-suggestions-dropdown-enable-${enableDropdown}`}>{enableDropdown && getSuggestionView()}</div>;
};

export default WidgetSuggestions;

import DOMPurify from "dompurify";
import React from "react";
import { PrimaryActivityContent } from "../components/Activities/ActivityDetail/ActivityFeed/ActivityFeedItem/PrimaryActivity/PrimaryActivityTypes";

const useLimitContent = (isExpanded: boolean, content: Omit<PrimaryActivityContent, "originalContent">) => {
  const [limitContent, setLimitContent] = React.useState(true);
  const [contentBody, setContentBody] = React.useState(DOMPurify.sanitize(content.preview ?? ""));

  const emailRendererRef = React.useRef<HTMLDivElement | null>(null);
  const emailContainerRef = React.useRef<HTMLDivElement | null>(null);

  /**
   * Get preview content and simple_content and keep them sanitized
   */
  const previewContent = DOMPurify.sanitize(content.preview ?? "");
  const HTMLContent = DOMPurify.sanitize(content.HTMLEmailContent ?? "");

  /**
   * When an overflow is detected inside activity renderer iframe and height limit is exceeded,
   * then show preview content else show simple content
   */
  React.useEffect(() => {
    if (limitContent) {
      setContentBody(previewContent);
    }
    if (!limitContent) {
      setContentBody(HTMLContent);
    }
  });


  /**
   * Open the first activity in the stream in read more mode and set read less button
   */
  React.useEffect(() => {
    if (isExpanded) {
      setLimitContent(!limitContent);
    }
  }, []);

  const toggleContentLimit = () => {
    setLimitContent(!limitContent);
  };

  return { contentBody, emailRendererRef, emailContainerRef, limitContent, toggleContentLimit };
};

export default useLimitContent;

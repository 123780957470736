export const BTN_SAVE = "Save";
export const BTN_ADD = "Add";
export const BTN_CANCEL = "Cancel";
export const BTN_SAVE_EDITS = "Save Edits";
export const BTN_CANCEL_EDIT = "Cancel Edit";
export const BTN_SEND = "Send";
export const BTN_SEND_AND_CLOSE = "Send & Close";
export const BTN_MOVE = "Move";
export const BTN_ASSIGN = "Assign";
export const BTN_RESEND = "Resend";
export const BTN_APPROVE = "Approve";
export const BTN_COPY = "Copy";
export const BTN_DECLINE = "Decline";
export const BTN_CANCEL_REQUEST = "Cancel Request";
export const BTN_SEND_REMINDER = "Send Reminder";
export const BTN_RETRY_SENDING = "Retry Sending";
export const BTN_NOT_SPAM = "Not Spam";

export const LBL_OPTIONAL = "(Optional)";

export const LBL_BCC = "Bcc";
export const LBL_CC = "Cc";

export const LBL_TO = "To";
export const HEADING_ADD_NOTE = "Add a Note";
export const SUB_HEADING_ADD_NOTE = "This note will only be visible to team members within Sage Network.";

export const HEADING_ADD_PHONE_CALL = "Add a Phone Call";
export const SUB_HEADING_ADD_PHONE_CALL = "This phone call will only be visible to team members within Sage Network.";

export const HEADING_MOVE_ACTIVITY_STREAM = "Move Activity";
export const SUB_HEADING_MOVE_ACTIVITY_STREAM = "Move this activity to another connection.";
export const LBL_CONNECTION = "Connection";

export const HEADING_CHANGE_ASSIGNMENT_OWNER = "Change Assignment Owner";
export const SUB_HEADING_CHANGE_ASSIGNMENT_OWNER = "Assign this assignment to another team member within Sage Network.";
export const LBL_TEAM_MEMBER = "Team Member";

export const HEADER_PHONE_CALL = "Phone Call";
export const HEADER_NOTE = "Note";
export const HEADER_ASSIGNED_ACTIVITY_STREAM = "Assigned Activity";
export const HEADER_UNASSIGNED_ACTIVITY_STREAM = "Unassigned Activity";
export const HEADER_ACTIVITY_CLOSE = "Completed Activity";
export const HEADER_MARKED_AS_SPAM = "Marked as Spam";
export const HEADER_FORWARDED = "Forwarded";
export const HEADER_MOVED_NEW_CONNECTION = "Moved to New Connection";
export const HEADER_SNOOZED = "Snoozed";
export const HEADER_UNSNOOZED_STREAM = "Unsnoozed activity";
export const HEADER_SPAM_SENDER = "Marked sender as spam";
export const HEADER_UNSPAM_SENDER = "Removed sender as spam";
export const HEADER_FRAUD_SENDER = "Marked sender as fraud";
export const HEADER_UNFRAUD_SENDER = "Removed sender as unfraud";
export const HEADER_MERGED_CONNECTION = "Merged Connection";
export const HEADER_APPROVAL_REQUEST = "Approval Request";
export const HEADER_FORWARD_AP_AUTOMATION = "Forwarded to ";
export const HEADER_APPROVAL_REQ_REDIRECT = "Approval request is redirected";
export const HEADER_MOVED_STREAM = "Moved activity";
export const HEADER_REMOVED_MARKED_NOT_SPAM = "Removed from Spam";
export const HEADER_EMAIL = "Email";
export const HEADER_NUDGE = "Nudge";
export const HEADER_SAVE_AP_AUTOMATION_EMAIL = "Successfully set up the AP automation email";
export const LBL_REPLAY_ALL = "Reply All";
export const LBL_FORWARD = "Forward";
export const LBL_VIEW_ORIGINAL = "View Original";
export const BTN_RETRY_MESSAGE = "Retry This Message";
export const BTN_UNSNOOZE = "Unsnooze";
export const LBL_MESSAGE_NOT_DELIVERED = "Message Not Delivered";
export const LBL_MESSAGE_NOT_DELIVERED_MSG = "There was a problem delivering your message to";
export const BTN_NUDGE_AGAIN = "Nudge Again";
export const BTN_SEE_FORWARDED_STREAM = "See Forwarded Stream";
export const BTN_SEE_ORIGINAL_STREAM = "See Original Stream";

export const BTN_REMOVE = "Remove";
export const BTN_CLOSE = "CLOSE";
export const BTN_CHANGE_OWNER = "Change Owner";
export const LBL_CHANGE_OWNERSHIP = "Change Ownership";
export const BTN_REMIND = "Remind";
export const BTN_DELETE = "Delete";
export const BTN_INVITE_MEMBERS = "Invite Members";
export const BTN_SEND_INVITE = "Send Invite";
export const BTN_INVITE = "Invite";
export const BTN_EDIT = "Edit";
export const BTN_UPDATE = "Update";
export const BTN_CONNECT_EMAIL = "Connect Email";
export const BTN_NEW_ACTIVITY = "New Activity";
export const BTN_MOVE_ACTIVITY_PRIMARY = "Yes, move all";
export const BTN_MOVE_ACTIVITY_SECONDARY = "No, just this one";
export const BTN_VIEW_ACTIVITY = "View Activity";

export const SNIPPET_INFO =
  "These templates consist of snippets and brackets, e.g., '{{emailAddress}}'. If you modify these snippets or brackets, the system won't be able to recognize the command and autofill the appropriate content. Please do not modify them.";
export const MAGIC_LINK_SNIPPET =
  "{Portal Link}<br>You can use the secure link above to access your invoices and records.  If you have any questions, please reply to this email or give me a call directly.<br>";
export const MAGIC_LINK_SNIPPET_BUTTON = "{Portal Link}";

export const LIST_OS = {
  Win: "Win",
  Mac: "Mac",
  Linux: "Linux",
};

export const LIST_OS_KEYS = {
  Ctrl: "Ctrl",
  Cmd: "Cmd",
  Option: "Optn",
  Alt: "Alt",
  Shift: "Shift",
  Meta: "Meta",
};
export const CONTACT_POPOVER_EMPTY_ITEM = "Not Available";
export const AUTOMATION_SETUP_INFO_TOOLTIP =
  "Set up your preferred AP Automation email, and we will forward all of your new bills when it hits Sage Network. This will help you save 2-3 minutes per email.";
export const BTN_AUTOMATION_SETUP = "Set up autoforwarding";

export const BTN_ADD_WORKSPACE = "Add Workspace";
export const SAGE_50_DISABLED_SYNC_MESSAGE =
  "Manage your data sync details within Sage 50. From the menu bar, go to Connected Services and then Sage Network Settings.";

export const LOGIN_MAIN_MESSAGE = "Loading Sage Network...";
export const LOGIN_CAPTION_MESSAGE_ONE = "Did you know: You can manage your AP approval workflows within Sage Network.";
export const LOGIN_CAPTION_MESSAGE_TWO = "Did you know: You can connect Sage Network Self-Service to accept payments?";
export const LOGIN_CAPTION_MESSAGE_THREE = "Did you know: You can manage your AR collections workflows using Sage Network's built-in templates.";
export const LOGOUT_MESSAGE = "Logging Out...";

// empty state messages
export const NO_DATA_MESSAGE = "Data unavailable.";
export const MY_COMPANY_EMPTY_MESSAGE = "There is no colleague data in my company.";
export const OTHER_CONNECTIONS_EMPTY_MESSAGE = "There are no other connections to be found.";
export const EMPTY_ACTIVITY_CAPTION_MESSAGE = 'Create your first activity by clicking on the "New Activity" button on top.';
export const GET_STARTED = "Get started with your";
export const GREAT_JOB = "Great job!";
export const EMPTY_PAYMENT_CAPTION_MESSAGE = "There is no payment data to be found.";
export const EMPTY_COMPANY_PROFILE_MESSAGE = "Profile data unavailable.";
export const EMPTY_COMPANY_PROFILE_CAPTION_MESSAGE = "No profile information to be found.";
export const EMPTY_CONGRATS_CAPTION_MESSAGE = "You have no open bills. Great job!";
export const EMPTY_CONGRATS_MESSAGE = "Congrats! You are all caught up!";
export const EMPTY_ACTIONS_MESSAGE = "No new actions required.";
export const EMPTY_CLOSED_BILLS_CAPTION_MESSAGE = "You have no closed bills.";
export const EMPTY_DOCUMENTS_CAPTION_MESSAGE = "There are no documents to be found.";
export const EMPTY_TEMPLATE_SETTINGS_MESSAGE = "There are no active templates to be found.";
export const EMPTY_TRANSACTIONS_ACTIVITY_MESSAGE = "There have been no invoice or payments for this activity yet.";
export const EMPTY_RELATED_TRANSACTIONS_MESSAGE = "No Activities yet";
export const EMPTY_TRANSACTIONS_MESSAGE = "No transaction data found.";

export const TOTAL_DISCOUNT = "Total Discount";
export const TOTAL_DUE = "Total Due";

export const EMAIL_CONNECTOR_ERROR = "Messages not sent. Please check that an Email Connector is connected.";
export const MESSAGE_NOT_SENT_ERROR = "Messages not sent.";

export const SUPPORT_PDF_ERROR = "the app demo data does not support pdf retrieval.";
export const DOWNLOAD_FAILED_ERROR = "Download failed, please check ERP download permission settings.";
export const PDF_DOWNLOAD_SUCCESS = "PDF download is complete";

export const SUMMARY_HEADER_TOOLTIP = "Summary count does not include credit memo.";
// workspace settings labels
export const FAILURE_TO_DELETE = "Unable to remove workspace at the moment";
export const FAILURE_TO_UPDATE = "Unable to edit workspace at the moment";
export const DELETE_SUCCESS = "Successfully removed workspace.";
export const UPDATE_SUCCESS = "Changes have been updated successfully";
export const ATLEAST_ONE_WORKSPACE_VALIDATION = "One workspace is required.";
export const WORKSPACE_REMOVAL_WARNING =
  "Removing a workspace will delete all data associated with the workspace including Activity and accounting data. Are you sure you want to remove this workspace?";

// activity action toast messages
export const MOVE_ACTIVITY_SUCCESS = "Activity moved successfully";
export const ACTIVITY_COMPLETE_SUCCESS = "Activity completed successfully";
export const ACTIVITY_COMPLETE_FAILURE = "Activity was not completed";
export const BULK_ACTIVITY_COMPLETE_FALIURE = "Activities were not completed";

//Copilot Varaibles
export const COPILOT_TOOLTIP_MESSAGE = "To save you time, we've crafted a response to the this email.";
export const COPILOT_HEADER_TEXT =
  "Sage uses AI to suggest replies. You can review if needed. For more information about personal data processing please read our ";
export const COPILOT_MAIN = "Suggested Response";
export const COPILOT_CAPTION = "AI created draft";
export const COPILOT_COLLPASED = "Suggested Response Available";
export const COPILOT_COLLPASED_CAPTION = "Collapse Message";
export const COPILOT_POLICY_TEXT_PART_ONE =
  "Sage Network AI is powered by Microsoft’s Azure OpenAI. For more information about how personal data is processed please check out";
export const COPILOT_POLICY_TEXT_PART_TWO = "Privacy Notice";

// attachment upload toast
export const UPLOAD_SUCCESS = "File uploaded successfully";
export const UPLOAD_FAILURE = "File upload failed";
// user permissions > invite function
export const ERROR_ON_INVITE = "An error occured while sending the Invitation";
export const ERROR_RESPONSE_ALREADY_REGISTERED = "already registered";
export const ERROR_RESPONSE_RECENTLY_INVITED = "was recently invited";
export const ERROR_NO_EMAILS = "[NO EMAIL GIVEN]";
export const ERROR_INVALID_EMAIL_IDS = "[INVALID EMAIL(S) GIVEN]";
export const LBL_INVITE_BY_EMAIL = "Invite by Email";
export const LBL_COMMA_SEPARATED = "(Comma Separated)";
export const LBL_INVITE_MEMBERS = "Invite Members To\nSage Network";
export const EMAIL_PLACEHOLDER = "abbie@example.com";

// USER PERMISSIONS > CHANGE OF OWNERSHIP
export const ERROR_ON_SAVE = "An error occured while saving User information";

// USER PERMISSIONS > REMOVE USER
export const ERROR_ON_REMOVE = "An error occured while removing the User";
export const USER_NOT_REMOVED = "Users were not removed";
export const USER_REMOVED = "Successfully removed user";

// USER PERMISSIONS > REMIND USER
export const ERROR_ON_REMIND = "An error occured while sending the Reminder";
export const REMINDER_SUCCESS = "Reminder Sent!";
export const ERROR_ON_INVITE_REMOVAL = "An error occured while removing the Invitation";
export const INVITE_REMOVAL_SUCCESS = "Invitation Deleted!";

//Activity Move text messages
export const ACTIVITY_MOVE_SUBTITLE_MESSAGE = "You can move this activity to another connection by selecting or searching one below.";

//Activity Merge messages
export const ACTIVITY_MERGE_HEADER_MESSAGE = "Merge Connection";
export const ACTIVITY_MERGE_SUBHEADER_MESSAGE_ONE = "The activities, contacts, and documents will be merged into another connection.";
export const ACTIVITY_MERGE_SUBHEADER_MESSAGE_TWO =
  "The activities, contacts, documents will be all merged into the connection and cannot be undone.";

export const ACTIVITY_FETCH_MORE_MESSAGE = "Fetching More Activities...";

//Detailss Tabs
export const PROFILE_TAB_LBL = "Profile";
export const ACTIVITIES_TAB_LBL = "Activities";
export const ATTACHMENTS_TAB_LBL = "Attachments";

//Pendo Event Constants
export const ACTIVITY_SPAM_PENDO = "Activity Spam";
export const ACTIVITY_MOVE_PENDO = "Activity Move";
export const ACTIVITY_REASSIGN_PENDO = "Activity Assign";
// PLACEHOLDERS
export const CONNECTION_LIST = "Choose a connection";

//Activtiy Toast Message
export const ACTIVITY_SUCCESS_TOAST_MSG = "Message Sent";

//Privacy Policy Links
export const SAGE_POLICY_UK_LINK = "https://www.sage.com/en-gb/legal/privacy-and-cookies/";
export const SAGE_POLICY_US_LINK = "https://www.sage.com/en-us/legal/privacy-and-cookies/";

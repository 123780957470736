/*eslint-disable no-magic-numbers*/
import { ColumnConfigs, CurrencyConfigType } from "../Table/types/table.types";
import { RowDataTypes } from "../constants/table.constants";
import { formatCurrency } from "../utils/currency";
import { DetailsListColumnConfigType } from "../DetailsList/tsx/types";
import { StatusLabels, ActivityStatusLabels } from "../constants/string.constants";
import { formatDate } from "./date";
import { NUMERIC_VALUES } from "../constants/numeric.constants";

export const makeDefaultFilter = (defaultFilter?: string, alwaysOnFilter?: string) => {
  return defaultFilter?.includes(alwaysOnFilter || "Error") || alwaysOnFilter?.toString?.()?.length === 0
    ? defaultFilter || ""
    : defaultFilter + " AND " + alwaysOnFilter;
};

export const getFormattedChipLabel = (status: string, daysPastDue: number) => {
  switch (status) {
    case "Open":
      return StatusLabels["Open"];
    case "Closed":
      return StatusLabels["Closed"];
    case "Past Due":
      if (daysPastDue) {
        if (daysPastDue > 0 && daysPastDue <= 30) {
          return StatusLabels["Past Due 1-30"];
        } else if (daysPastDue > 30 && daysPastDue <= 60) {
          return StatusLabels["Past Due 31-60"];
        } else if (daysPastDue > 60 && daysPastDue <= 90) {
          return StatusLabels["Past Due 61-90"];
        } else if (daysPastDue > 90) {
          return StatusLabels["Past Due 91+"];
        }
      }
      return "N/A";
    case "Rejected":
      return StatusLabels["Rejected"];
    default:
      return "N/A";
  }
};

export const getFormattedChipLabelForActivity = (status: string) => {
  if (status && ActivityStatusLabels[status]) {
    return ActivityStatusLabels[status];
  }
  return "N/A";
};

export const filesFormattedLabel = (filesCount: number) => {
  const formattedCount = new Intl.NumberFormat("en-IN", {
    maximumSignificantDigits: 3,
    maximumFractionDigits: 0,
  }).format(filesCount);
  return filesCount && filesCount >= 1 ? formattedCount + ` Application${filesCount === 1 ? "" : "s"}` : "N/A";
};

export const formatRowDataForExport = (
  colConfig: Array<ColumnConfigs | DetailsListColumnConfigType>,
  rowData: any,
  dateFormat: string,
  currencyFormat?: CurrencyConfigType
) => {
  const formattedRowData: { [key: string]: string } = {};
  colConfig?.forEach((col) => {
    if (rowData[col?.accessor] == "-" || rowData[col?.accessor] === "N/A") {
      formattedRowData[`${col?.accessor}`] = "-";
    } else {
      if (col?.rowDataType === RowDataTypes?.Currency) {
        formattedRowData[`${col?.accessor}`] = formatCurrency(
          rowData[col?.accessor],
          undefined,
          undefined,
          undefined,
          currencyFormat?.locale,
          currencyFormat?.code
        );
      } else if (col?.rowDataType === RowDataTypes?.CurrencyWithTypeCheck) {
        formattedRowData[`${col?.accessor}`] = formatCurrency(
          rowData[col?.accessor],
          true,
          rowData[col?.conditionalAccessor as string],
          undefined,
          currencyFormat?.locale,
          currencyFormat?.code
        );
      } else if (col?.rowDataType === RowDataTypes?.DateString) {
        formattedRowData[`${col?.accessor}`] = formatDate(rowData[col?.accessor], dateFormat);
      } else if (col?.rowDataType === RowDataTypes?.DateEpoch) {
        /**
         * Unix epoch timestamp is in seconds, where as javascript assumes timestamps in milli seconds, so multiply by thousand
         */

        //added this condition to handle string value where we are using DATEEPOCH for "yyyy-mm-dd"
        if (typeof rowData[col?.accessor] === "string" && rowData[col?.accessor]) {
          formattedRowData[`${col?.accessor}`] = formatDate(new Date(rowData[col?.accessor]).toISOString(), dateFormat);
        } else if (typeof rowData[col?.accessor] === "number" && rowData[col?.accessor]) {
          formattedRowData[`${col?.accessor}`] = formatDate(
            new Date(rowData[col?.accessor] * NUMERIC_VALUES.CONSTANT_THOUSAND).toISOString(),
            dateFormat
          );
        } else {
          formattedRowData[`${col?.accessor}`] = "-";
        }
      } else if (col?.rowDataType === RowDataTypes.Status) {
        formattedRowData[`${col?.accessor}`] = getFormattedChipLabel(rowData[col?.accessor], rowData[col?.conditionalAccessor as string]);
      } else if ([RowDataTypes.FileCount, RowDataTypes.FileCountWithDrawerHandler].includes(col?.rowDataType || ("" as any))) {
        formattedRowData[`${col?.accessor}`] = filesFormattedLabel(rowData[col?.accessor]);
      } else {
        formattedRowData[`${col?.accessor}`] = rowData[col?.accessor]?.toString?.();
      }
    }
  });
  return formattedRowData;
};

export const formatExportName = (view: string) => {
  const newDateString = new Date(Date.now()).toLocaleString()?.split(", ");
  const firstHalf = newDateString[0].replace(/_/g, "/");
  const finalHalf = newDateString[1].replace(/_/g, ":");
  return `${view?.toLowerCase()}-data-exported-${firstHalf}-@-${finalHalf}`;
};

export const getValuebyStringPath = (object: any, string: string) => {
  string = string.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  string = string.replace(/^\./, ""); // strip a leading dot
  const a = string.split(".");
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i];
    if (k && object && k in object) {
      object = object[k];
    } else {
      return;
    }
  }
  return object;
};

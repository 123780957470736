import React from "react";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import useCustomTooltipStyle from "./useCustomTooltipStyle";
import _ from "underscore";
import { TooltipTypes } from "../constants/tooltip.constants";

/**
 * @param type : different types of tooltip PLAIN, RICH_CONDENSE,RICH_CONDENSE,SHORTCUT
 *  default value is PLAIN
 * @param shortcutKeysText: for tooltip type =SHORTCUT, need to display keyboard characters as button in tooltip content
 *  expected value type is string for more than two keyboard button , string should be concate with + (ex: cmd + /)
 */
interface CustomTooltipProps extends TooltipProps {
  type?: string;
  shortcutKeysText?: string;
}
const CustomTooltip = ({ type = TooltipTypes.PLAIN, shortcutKeysText = "", ...props }: CustomTooltipProps) => {
  const { plainTooltip, richTooltip, richCondenseTooltip, shortcutTooltip, shortcutDivRoot, shortcutButtonContainer, shortcutButtons } =
    useCustomTooltipStyle();

  const getShortcutTitleContent = (text: string, keys: string) => {
    return (
      <div className={shortcutDivRoot}>
        {text}
        {!_.isEmpty(keys) && (
          <div className={shortcutButtonContainer}>
            {keys?.split("+")?.map((item) => (
              <span key={item} className={shortcutButtons}>
                {item}
              </span>
            ))}
          </div>
        )}
      </div>
    );
  };
  const getTooltip = () => {
    switch (type) {
      case TooltipTypes.PLAIN:
        return <Tooltip {...props} title={props?.title} classes={{ tooltip: plainTooltip }} disableInteractive />;
      case TooltipTypes.RICH:
        return <Tooltip {...props} title={props?.title} classes={{ tooltip: richTooltip }} disableInteractive />;
      case TooltipTypes.RICH_CONDENSE:
        return <Tooltip {...props} title={props?.title} classes={{ tooltip: richCondenseTooltip }} disableInteractive />;
      case TooltipTypes.SHORTCUT:
        return (
          <Tooltip
            {...props}
            title={getShortcutTitleContent(props?.title?.toString(), shortcutKeysText)}
            classes={{ tooltip: shortcutTooltip }}
            disableInteractive
          />
        );
      default:
        return;
    }
  };

  return <>{getTooltip()}</>;
};

export default CustomTooltip;

import { styled } from "@mui/system";
import { styled as muiStyled } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";
import Grid from "@mui/material/Grid";

export const AttachmentPreviewRoot = muiStyled(Backdrop, {
  name: "attachment-preview",
  slot: "root",
})({
  color: "#fff",
  zIndex: "1200",
  padding: "1rem",
  background: "rgba(0, 0, 0, 0.68)",
});

export const AttachmentPreviewContainerGrid = muiStyled(Grid)(() => ({
  height: "100%",
}));

export const AttachmentTopbarGrid = muiStyled(Grid)(() => ({
  height: "3.5rem",
}));

export const AttachmentFilePreviewGrid = muiStyled(Grid)(() => ({
  height: "calc(100% - 3.5rem)",
}));

export const AttachmentFilePreviewGridItem = muiStyled(Grid)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const PreviousFileGrid = muiStyled(Grid)(() => ({
  textAlign: "left",
}));

export const NextFileGrid = muiStyled(Grid)(() => ({
  textAlign: "right",
}));

export const PreviewTopbarLeftContainer = styled("div", {
  name: "attachment-preview-topbar-left-container",
  slot: "root",
})({
  display: "flex",
  justifyContent: "left",
  alignItems: "center",
  gap: "0.5rem",
  "& .filename": {
    fontWeight: "600",
    fontSize: "1rem",
    lineHeight: "1.5rem",
  },
});

export const PreviewTopbarRightContainer = styled("div", {
  name: "attachment-preview-topbar-right-container",
  slot: "root",
})({
  display: "flex",
  justifyContent: "right",
  alignItems: "center",
  gap: "0.5rem",
});

export const PDFPreviewRoot = styled("div", {
  name: "attachment-pdf-preview",
  slot: "root",
})({
  display: "flex",
  justifyContent: "center",
  position: "relative",
  width: "100%",
});

export const PDFPreviewControls = styled("div", {
  name: "attachment-pdf-controls",
  slot: "root",
})({
  position: "absolute",
  bottom: "20px",
  left: "50%",
  transform: "translateX(-50%)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "4rem",
  background: "#4D4D4D",
  borderRadius: "0.5rem",
  paddingLeft: "1.25rem",
  paddingRight: "1.25rem",
  gap: "1.25rem",
});

export const ImagePreviewRoot = styled("div", {
  name: "attachment-image-preview",
  slot: "root",
})({
  height: "85vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& img": {
    maxHeight: "100%",
    maxWidth: "100%",
  },
});

export const PreviewUnavailableRoot = styled("div", {
  name: "attachment-preview-unavailable",
  slot: "root",
})({
  height: "38rem",
  width: "46rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  background: "#FFFFFF",
  color: "#000",
  borderRadius: "16px",
});

export const PreviewUnavailableView = styled("div", {
  name: "attachment-preview-view",
  slot: "root",
})({
  textAlign: "center",
  "& .lf-player-container #lottie": {
    height: "10rem",
  },
  "& .heading": {
    marginTop: "0",
    fontWeight: "600",
    fontSize: "1rem",
    lineHeight: "1.5rem",
    color: "#000000",
  },
  "& .content": {
    marginTop: "0.25rem",
    marginBottom: "1.5rem",
    fontWeight: "400",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    color: "#707A8F",
  },
  "& .MuiButton-root": {
    textTransform: "none",
    fontWeight: "600",
    fontSize: "0.875rem",
    lineHeight: "1.5rem",
  },
});

export const RenderPropsSubChildContainer = styled("div", {
  name: "render-props-subchild-container",
  slot: "root",
})({
  position: "fixed",
  right: "0rem",
  bottom: "0rem",
  "& .e-invoice-actions": {
    flexDirection: "column",
    marginBottom: "0rem",
    backgroundColor: "white",
    borderBottomLeftRadius: "0rem",
    borderBottomRightRadius: "0rem",
    marginRight: "5.0625rem",
    "& .actions-info": {
      display: "none",
    },
    "& .actions": {
      flexDirection: "column",
      "& .MuiButton-root": {
        width: "-webkit-fill-available",
      },
    },
  },
});

import React from "react";
import { ActivityType, AuditLogType, InlinePrompts, WorkspaceType } from "../../../../../types/enums";
import { ActivityFeedItemContainerProps } from "./ActivityFeedItemTypes";
import {
  AccessRevoked,
  ApAutomation,
  Assign,
  BouncedEmail,
  Cancelled,
  Closed,
  Email,
  ForwardedActivity,
  ForwardedLog,
  MarkedSender,
  MergeConnection,
  MoveActivity,
  NoteActivity,
  NudgeActivity,
  OnboardingCompleted,
  OnboardingRequested,
  OnboardVendor,
  PhoneActivity,
  ProfileRequest,
  ProfileShare,
  Redirect,
  Regenerate,
  Request,
  Response,
  Reviewed,
  SaveApAutomationEmail,
  Snoozed,
  Spam,
  Unsnoozed,
  Unspam,
  Unassign,
} from "./ActivityItemVariants";

/**
 * !WARNING!
 *
 * Because this component or any child component is being used with MSAL
 * as well as /external Magic Authentication in Approval Activity. It does
 * not support any context implementation (or useContext hook). Please do not
 * import or write api calls directly inside this component, DOING SO WILL
 * BREAK THE IMPLEMENTATION. If you need, this will has to be passed in props
 * either as a handler function or a boolean flag from ActivityFeed.tsx OR
 * ApprovalActivityComponent.tsx OR anyother parent component that implements
 * this.
 *
 * NOTE:
 * To implement feature flags use - isFeatureEnabled prop on baseProps object.
 */
const ActivityFeedItemComponent: React.FC<ActivityFeedItemContainerProps> = ({ variant, handlers, timeSaving, baseProps, ...props }) => {
  switch (variant) {
    case ActivityType.Email:
    case ActivityType.IncomingEmail:
      return (
        <Email
          timeSaving={timeSaving}
          countryCode={props.countryCode}
          isEInvoicing={props?.isEInvoicingRoute}
          {...{ ...baseProps, ...handlers?.reply, ...handlers?.ellipsis }}
        />
      );
    case ActivityType.BouncedEmail:
      return (<BouncedEmail
            creator={baseProps.creator}
            readonly={baseProps.readonly}
            erroneousEmail={baseProps.contacts.to ? baseProps.contacts.to[0] : ""}
            id={baseProps.id}
            bounceErrorMsg={props.bounceErrorMsg ?? ""}
            countryCode={props?.countryCode}
            retried={props.retried}
            {...handlers?.bounce}
          />
      );
    case ActivityType.PhoneCall:
      return <PhoneActivity countryCode={props.countryCode} {...baseProps} />;
    case ActivityType.Note:
      return (
        <NoteActivity
          enableReviewOptions={true}
          countryCode={props.countryCode}
          {...{ ...baseProps, ...handlers?.note, contacts: { to: null, cc: null, bcc: null, creator: baseProps?.contacts?.creator } }}
        />
      );
    case ActivityType.ApprovalRequest:
      return (
        <Request
          enableReviewOptions={!["approved", "declined", "cancelled"].includes(props?.activityStatus)}
          timeSaving={timeSaving}
          countryCode={props.countryCode}
          {...{ ...baseProps, ...handlers?.approval, ...handlers?.reply, ...handlers?.ellipsis }}
        />
      );
    case ActivityType.ApprovalRedirect:
      return (
        <Redirect
          enableReviewOptions={!["approved", "declined", "redirected", "cancelled", "expired"].includes(props?.activityStatus)}
          approverName={props.approverName}
          countryCode={props.countryCode}
          {...{ ...baseProps, ...handlers?.reply, ...handlers?.approval, ...handlers?.ellipsis }}
        />
      );
    case ActivityType.ApprovalReviewed:
      return <Reviewed timeSaving={timeSaving} countryCode={props.countryCode} {...{ ...baseProps, ...handlers?.reply, ...handlers?.ellipsis }} />;
    case ActivityType.ApprovalAccessRevoked:
      return (
        <AccessRevoked timeSaving={timeSaving} countryCode={props.countryCode} {...{ ...baseProps, ...handlers?.reply, ...handlers?.ellipsis }} />
      );
    case ActivityType.ApprovalResponse:
      return <Response countryCode={props.countryCode} {...baseProps} />;
    case ActivityType.ApprovalCancelled:
      return <Cancelled countryCode={props.countryCode} {...baseProps} />;
    case ActivityType.ApprovalLinkRegenerated:
      return (
        <Regenerate
          enableReviewOptions={!["approved", "declined", "redirected", "cancelled", "expired"].includes(props?.activityStatus)}
          approverName={props.approverName}
          countryCode={props.countryCode}
          {...{ ...baseProps, ...handlers?.approval }}
        />
      );
    case ActivityType.Nudge:
      return (
        <NudgeActivity
          enableNudgeAgain={props.isNudgeActivity}
          countryCode={props.countryCode}
          {...{ ...baseProps, ...handlers?.reply, ...handlers?.nudge, ...handlers?.ellipsis }}
        />
      );
    case AuditLogType.Assign:
      return <Assign creator={baseProps.creator} assigneeNote={props.note} assigneeName={props.assigneeName} countryCode={props?.countryCode} />;
    case AuditLogType.Unassign:
      return <Unassign creator={baseProps.creator} assigneeNote={props.note} assigneeName={props.assigneeName} countryCode={props?.countryCode} />;
    case AuditLogType.Close:
      return <Closed creator={baseProps.creator} isAutoClosed={props?.isAutoClosed} countryCode={props?.countryCode} />;
    case AuditLogType.Spam:
      return (
        <Spam
          creator={baseProps.creator}
          readonly={props.isSpamApplicable ? baseProps.readonly : true}
          enableNotSpam={props.isStreamSpam}
          {...handlers?.spam}
          countryCode={props?.countryCode}
        />
      );
    case AuditLogType.Unspam:
      return <Unspam creator={baseProps.creator} readonly={baseProps.readonly} countryCode={props?.countryCode} />;
    case AuditLogType.Forward:
    case ActivityType.Forward:
      return props.type === ActivityType.AuditLog ? (
        <ForwardedLog creator={baseProps.creator} readonly={baseProps.readonly} {...handlers?.forward} countryCode={props?.countryCode} />
      ) : (
        <ForwardedActivity countryCode={props.countryCode} {...{ ...baseProps, ...handlers?.forward, ...handlers.reply, ...handlers?.ellipsis }} />
      );
    case ActivityType.Move:
      return (
        <MoveActivity creator={baseProps.creator} connectionName={props?.movedToConnectionName} note={props.note} countryCode={props?.countryCode} />
      );
    case AuditLogType.Snoozed:
      return (
        <Snoozed
          creator={baseProps.creator}
          timestampUntilSnoozed={props.timestampUntilSnoozed}
          status={props.activityStatus}
          readonly={props.isSnoozeApplicable ? baseProps.readonly : true}
          {...handlers?.snooze}
          countryCode={props?.countryCode}
        />
      );
    case AuditLogType.Unsnoozed:
      return <Unsnoozed creator={baseProps.creator} countryCode={props?.countryCode} />;
    case AuditLogType.SpamConnection:
    case AuditLogType.UnspamConnection:
    case AuditLogType.FraudConnection:
    case AuditLogType.UnfraudConnection:
      return <MarkedSender creator={baseProps.creator} currentStatus={props.connectionStatus} countryCode={props?.countryCode} />;
    case AuditLogType.MergeConnection:
      return <MergeConnection creator={baseProps.creator} connectionName={props?.mergedToConnectionName} countryCode={props?.countryCode} />;
    case AuditLogType.ApAutomation:
      return (
        <ApAutomation
          readonly={!props.isResendApplicable || baseProps.readonly}
          creator={baseProps.creator}
          email={baseProps?.contacts?.to?.[0].email_address}
          countryCode={props?.countryCode}
          {...{ ...handlers.apAutomation }}
        />
      );
    case AuditLogType.OnboardRequest:
      return baseProps?.isFeatureEnabled?.("FORWARD_AP_AUTOMATION") ? (
        <OnboardingRequested
          creator={baseProps.creator}
          isShowPrimaryOnboardRequested={props.isShowPrimaryProfileShareOrAPAutomate}
          mailList={baseProps.contacts}
          countryCode={props?.countryCode}
          // onClickResendOnboardingRequest={handlers.apAutomation.onClickResend}
        />
      ) : null;
    case AuditLogType.SaveApAutomationEmail:
      return baseProps?.isFeatureEnabled?.("FORWARD_AP_AUTOMATION") ? (
        <SaveApAutomationEmail creator={baseProps.creator} countryCode={props?.countryCode} />
      ) : null;
    case InlinePrompts.ONBOARD_VENDOR:
      return baseProps?.isFeatureEnabled?.("FORWARD_AP_AUTOMATION") ? (
        <OnboardVendor
          dynamicCompanySelection={baseProps.selectedWorkspace.workspace_type_route === WorkspaceType.AW}
          {...{ ...handlers.onboardVendor }}
        />
      ) : null;
    case AuditLogType.VendorOnboardingCompleted:
      return baseProps?.isFeatureEnabled?.("FORWARD_AP_AUTOMATION") ? (
        <OnboardingCompleted creator={baseProps.creator} countryCode={props?.countryCode} />
      ) : null;
    case AuditLogType.ProfileShare:
      return (
        <ProfileShare
          creator={baseProps.creator}
          isShowPrimaryProfileShare={props.isShowPrimaryProfileShareOrAPAutomate}
          mailList={baseProps.contacts}
          showAPOrARProfile={props.showAPOrARProfile}
          countryCode={props?.countryCode}
        />
      );
    case AuditLogType.ProfileRequest:
      return (
        <ProfileRequest
          creator={baseProps.creator}
          isShowPrimaryProfileShare={props.isShowPrimaryProfileShareOrAPAutomate}
          mailList={baseProps.contacts}
          countryCode={props.countryCode}
        />
      );
    default:
      console.error("Activity Type does not exist");
      return <></>;
  }
};

export default ActivityFeedItemComponent;

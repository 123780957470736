import APIClient from "../APIClient";
const PLATFORM_API_BASE = process.env.REACT_APP_API_ENDPOINT_PLATFORM_V1;

export class StatusClient {
  /**
   * * GET on /api/v1/Status
   * @returns Promise of type StatusModel or Exception
   */
  getStatus(): Promise<StatusModel> {
    const url = `${PLATFORM_API_BASE}/Status`;

    return APIClient.get(url).then((response) => {
      return response.data;
    });
  }
}

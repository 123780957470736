import { GLOBAL_SEARCH_CONSTANTS } from "../../../constants/GlobalSearchConstants";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../../constants/NumericConstants";
import { GlobalSearchCategories } from "../../../types/enums";
import { HistoryStateInterface } from "../Interfaces/SearchResultsInterfaces";
import { RecentSearch, RecentSearchItem } from "../Interfaces/WidgetInterfaces";

// Creating new recent search history if no data in LS
export const createNewSearchHistory = (category: string, workspaceType: string): Array<RecentSearchItem> => {
  const recentSearchHistory: string = localStorage.getItem(GLOBAL_SEARCH_CONSTANTS.recentSearch) as string;
  if (!recentSearchHistory) {
    localStorage.setItem(GLOBAL_SEARCH_CONSTANTS.recentSearch, JSON.stringify({ AP: [], AR: [] }));
    return [];
  } else {
    const recentSearchHistoryData: Array<RecentSearchItem> =
      (JSON.parse(recentSearchHistory) as RecentSearch)?.[workspaceType]?.filter?.((item: RecentSearchItem) =>
        category === GlobalSearchCategories.all ? true : category === item.category
      ) ?? [];
    return recentSearchHistoryData;
  }
};

// Adding new entries to LS
export const addSearchHistory = (filteredArray: Array<RecentSearchItem>, workspaceType: string): void => {
  const recentSearch = JSON.parse(localStorage.getItem(GLOBAL_SEARCH_CONSTANTS.recentSearch) as string) as RecentSearch;
  localStorage.setItem(
    GLOBAL_SEARCH_CONSTANTS.recentSearch,
    JSON.stringify({
      ...recentSearch,
      [workspaceType]: filteredArray,
    })
  );
};

// Clicking and Sorting logic for Recent Search
export const onSelectRecentSearchHistory = (id: string, query: string, category: string, workspaceType: string): Array<RecentSearchItem> => {
  const dataItem: RecentSearchItem = {
    category,
    query,
    id,
  };
  let firstIndex = null;
  let newSearchArray =
    ((JSON.parse(localStorage.getItem(GLOBAL_SEARCH_CONSTANTS.recentSearch) as string) as RecentSearch)?.[
      workspaceType
    ] as Array<RecentSearchItem>) ?? [];
  newSearchArray?.forEach((item, index) => {
    if (JSON.stringify(item) === JSON.stringify(dataItem)) {
      firstIndex = index;
    }
  });
  if (firstIndex === null) {
    newSearchArray = [dataItem, ...newSearchArray];
  } else if (firstIndex === newSearchArray?.length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) {
    newSearchArray = [dataItem, ...newSearchArray.slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, firstIndex)];
  } else if (firstIndex > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
    newSearchArray = [
      dataItem,
      ...newSearchArray.slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, firstIndex),
      ...newSearchArray.slice(firstIndex + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE, newSearchArray?.length),
    ];
  }

  const filteredArray = newSearchArray?.slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, NUMERIC_VALUES.CONSTANT_TWENTY) ?? [];
  addSearchHistory(filteredArray, workspaceType);
  return filteredArray;
};

// Setting previous context view and current search context and filters of the widget
export const setSearchHistory = (storageItem: HistoryStateInterface): void => {
  const jsonString = localStorage.getItem(GLOBAL_SEARCH_CONSTANTS.searchHistory) as string;
  let searchHistory: HistoryStateInterface = {};
  if (jsonString) {
    searchHistory = JSON.parse(jsonString);
  }
  localStorage.setItem(
    GLOBAL_SEARCH_CONSTANTS.searchHistory,
    JSON.stringify({
      ...searchHistory,
      ...storageItem,
    })
  );
};

// Getter for Previous View Context
export const getPreviousViewContext = (): Record<string, string> | undefined => {
  return (JSON.parse(localStorage.getItem(GLOBAL_SEARCH_CONSTANTS.searchHistory) as string) as HistoryStateInterface)?.previousContext;
};

// Getter for Current Search Context
export const getCurrentSearchContext = (): Record<string, string> | undefined => {
  return (JSON.parse(localStorage.getItem(GLOBAL_SEARCH_CONSTANTS.searchHistory) as string) as HistoryStateInterface)?.currentSearchContext;
};

// Getter for Current Search filters
export const getSearchFilters = (): Record<string, string> | undefined => {
  return (JSON.parse(localStorage.getItem(GLOBAL_SEARCH_CONSTANTS.searchHistory) as string) as HistoryStateInterface)?.filters;
};

// LS Destroyer
export const globalSearchRemoveStorage = (): void => {
  localStorage.removeItem(GLOBAL_SEARCH_CONSTANTS.searchHistory);
  localStorage.removeItem(GLOBAL_SEARCH_CONSTANTS.recentSearch);
};

export default null;

import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import { ApplicationRouteContext } from "../../contexts/ApplicationRouteContext";
import { PaymentContext } from "../../contexts/PaymentContext";
import useWorkspaceConfigurations from "../../hooks/useWorkspaceConfigurations";
import Navbar from "../library/Navbar/Navbar";
import { WorkspaceType } from "../../types/enums";

const PaymentNavbar = () => {
  const { isDetailViewLoading, paymentDetailViewData } = React.useContext(PaymentContext) as PaymentType;
  const { getBaseRoute } = React.useContext(ApplicationRouteContext) as ApplicationRouteType;

  const { path } = useRouteMatch();
  const { selectedWorkspace, configs: currentWorkspaceConfigs, configProvider, pathType } = useWorkspaceConfigurations();
  const configs = selectedWorkspace.workspace_type_route === WorkspaceType.AW ? configProvider[pathType] : currentWorkspaceConfigs;

  const navBarTabs = configs["payments"]["routes"]["main"];

  return (
    <div className="payment-head">
      <Switch>
        {/* TABLE/INDEX */}
        <Route
          exact
          path={[
            `${path}`,
            `${path}/customer-payments/unappliedPayments`,
            `${path}/vendor-payments/advancedPayments`,
            `${path}/unappliedPayments`,
            `${path}/advancedPayments`,
          ]}
          component={() => {
            return <Navbar tabs={navBarTabs} rootPath={path} />;
          }}
        />
        {/* DETAIL/SHOW */}
        <Route
          exact
          path={[
            `${path}/:paymentId`,
            `${path}/customer-payments/advancedPayments/:paymentId`,
            `${path}/vendor-payments/unappliedPayments/:paymentId`,
            `${path}/unappliedPayments/:paymentId`,
            `${path}/advancedPayments/:paymentId`,
          ]}
          component={() => {
            return (
              <Navbar
                tabs={navBarTabs}
                rootPath={path}
                hideDropdown={isDetailViewLoading}
                handleBackRoute={true}
                backRoute={path}
                backDisplayName={`All Payments`}
              />
            );
          }}
        />
        {/* REST */}
        <Route
          path={[`${path}/:paymentId`, `${path}/unappliedPayments/:paymentId`, `${path}/advancedPayments/:paymentId`]}
          component={() => {
            return (
              <Navbar
                tabs={navBarTabs}
                rootPath={path}
                hideDropdown={isDetailViewLoading}
                handleBackRoute={true}
                backRoute={getBaseRoute()}
                backDisplayName={`Payment #${paymentDetailViewData?.referenceCode}`}
              />
            );
          }}
        />
      </Switch>
    </div>
  );
};

export default PaymentNavbar;

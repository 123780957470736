import React from "react";
import _ from "underscore";
import { DEFAULT_NUMERIC_VALUES } from "../../../../../../../../constants/NumericConstants";
import Utils from "../../../../../../../../utils/utils";
import CustomTooltip from "../../../../../../../library/CustomTooltip/CustomTooltip";
import { AutomateActivity } from "../../../../../../../library/Icons/Icons";
import { TimeSavingIndicatorProps } from "../HelperTypes";
import { useTranslation } from "react-i18next";

const TimeSavingIndicator: React.FC<TimeSavingIndicatorProps> = ({ timeSaving }) => {
  const { t } = useTranslation();
  const enableTimeSaving =
    !_.isUndefined(timeSaving) &&
    !_.isUndefined(timeSaving.to_time) &&
    !_.isUndefined(timeSaving.from_time) &&
    timeSaving.to_time > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO &&
    timeSaving.from_time > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;

  return enableTimeSaving ? (
    <div className="automate-time-wrapper">
      <CustomTooltip
        title={t("activityDetails.feedItems.primaryActivity.helpers.timeSavinIndicatior.tooltipMessage", { ns: "activities" }) as string}
        placement="top"
        componentsProps={{ tooltip: { sx: { maxWidth: "none" } } }}
      >
        <div>
          <AutomateActivity />
        </div>
      </CustomTooltip>

      <p className="time-text-wrapper">
        {t("activityDetails.feedItems.primaryActivity.helpers.timeSavinIndicatior.fieldLabel.timeSavings", {
          time: Utils.formatTimeInMinutesAndSeconds(
            timeSaving?.from_time ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            timeSaving?.to_time ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
          ),
          ns: "activities",
        })}
      </p>
    </div>
  ) : (
    <></>
  );
};

export default TimeSavingIndicator;

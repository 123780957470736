import React from "react";
import Cropper from "react-easy-crop";
import Button from "../../Button/Button";
import { CropComponentProps } from "../UploadComponentInterfaces/UploadComponentInterfaces";
import { CircularArrowLoader } from "../../Icons/Icons";
import { DEFAULT_NUMERIC_VALUES } from "../../../../constants/NumericConstants";

const CropComponent: React.FC<CropComponentProps> = ({
  cropperProps,
  crop,
  cropImage,
  finalFileUploading,
  imageCropSuccess,
  imgSrc,
  labelProps,
  onClickCloseModal,
  onCropComplete,
  onCompleteSave,
  resetUploadedImage,
  setCrop,
  zoom,
  setZoom,
}: CropComponentProps) => {
  const getCroppedImageUploadButton = () => {
    return (
      <div className="btn-grp">
        <Button
          disabled={finalFileUploading}
          icon={
            finalFileUploading && (
              <span className="rotate_anim_loader">
                <CircularArrowLoader />
              </span>
            )
          }
          alignIcon="right"
          variant="primary"
          className="crop-btn"
          type="button"
          onClick={onCompleteSave}
        >
          {finalFileUploading ? "Uploading ..." : labelProps?.buttonProps?.uploadToServerButtonGroup?.actionLabel || "Let’s save this."}
        </Button>
        <Button variant="secondary" onClick={onClickCloseModal}>
          {labelProps?.buttonProps?.uploadToServerButtonGroup?.cancelLabel || "Cancel"}
        </Button>
      </div>
    );
  };

  const getCropButtonGroup = () => {
    return (
      <div className="btn-grp">
        <Button variant="primary" className="crop-btn" type="button" onClick={cropImage}>
          {labelProps?.buttonProps?.cropperButtonGroup?.actionLabel || "Save Photo"}
        </Button>
        <Button variant="secondary" onClick={onClickCloseModal}>
          {labelProps?.buttonProps?.cropperButtonGroup?.cancelLabel || "Cancel"}
        </Button>
      </div>
    );
  };

  return (
    <>
      <div className="crop_resize_container ">
        <div className="crop_container">
          {!imageCropSuccess && (
            <Cropper
              aspect={
                (cropperProps?.aspectRatio?.[0] || DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) /
                (cropperProps?.aspectRatio?.[1] || DEFAULT_NUMERIC_VALUES.DEFAULT_ONE)
              }
              crop={crop}
              image={imgSrc}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              zoom={zoom}
            />
          )}
          {imageCropSuccess && <img src={imgSrc} alt="cropped_image_display" className="cropped_image_display" />}
        </div>
        <p className="body1 crop_reset_text" onClick={resetUploadedImage}>
          {labelProps?.resetUploadText || "I need to upload a different photo."}
        </p>
      </div>
      {!imageCropSuccess && getCropButtonGroup()}
      {imageCropSuccess && getCroppedImageUploadButton()}
    </>
  );
};

export default CropComponent;

import React from "react";
import { ComponentConfigContext } from "../contexts/ComponentConfigContext";
import { WorkspaceContext } from "../contexts/WorkspaceContext";
import { WorkspaceType } from "../types/enums";
import { useRouteMatch } from "react-router";

const useWorkspaceConfigurations = () => {
  const { path } = useRouteMatch();
  // TODO Resequence: only keep states that are helpful for managing workspace configurations
  const { selectedWorkspace, workspaceHomePath, workspaceMembers, fetchingWorkspaceMembers } = React.useContext(
    WorkspaceContext
  ) as WorkspaceDataType;
  const { getConfig, configProvider } = React.useContext(ComponentConfigContext) as ComponentConfigType;

  const workspaceType = selectedWorkspace.workspace_type_route?.toLocaleLowerCase() ?? WorkspaceType.AR;
  const currentWorkspaceConfigs = getConfig(workspaceType) as any;

  /** path keywords to differentiate path for Combined workspace */
  const payablesPaths = ["/vendors", "/bills", "/vendor-payments"];
  const recievablesPath = ["/customers", "/invoices", "/customer-payments"];

  type PathType = "ap" | "ar";

  /** determines the record workspace type based on workspace type */
  const pathType: PathType = (function checkWorkspacePathType() {
    if (payablesPaths.some((payablePath) => path.includes(payablePath)) || path.includes("AP")) {
      return "ap";
    } else if (recievablesPath.some((recievablePath) => path.includes(recievablePath)) || path.includes("AR")) {
      return "ar";
    } else {
      return "ar";
    }
  })();

  return {
    configs: currentWorkspaceConfigs,
    configProvider,
    workspaceType,
    selectedWorkspace,
    pathType,
    workspaceHomePath,
    workspaceMembers,
    fetchingWorkspaceMembers,
  };
};

export default useWorkspaceConfigurations;

import React, { useRef, useEffect, useState, MouseEvent, useMemo } from "react";
import NavLinkWrapper from "../NavLink/index";
import ContentNavLink from "../ContentNavLink/ContentNavLink";
import { BackArrowNav, Ellipses } from "../Icons/Icons";
import { useHistory, useLocation, matchPath } from "react-router-dom";
import { Divider, Skeleton } from "@mui/material";
import MenuItem from "../MenuItem/MenuItem";
import Menu from "../Menu/Menu";
import "./Navbar.scss";
import DropMenu from "../DropMenu/DropMenu";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../../constants/NumericConstants";
import { NAVBAR_SKELETON_CONSTANTS, STYLED_CONSTANTS } from "../../../constants/StyleConstants";
import SmartTextContainer from "../SmartText/SmartTextContainer";
import { DocumentSwitchContext } from "../../../contexts/DocumentSwitchContext";
import Utils from "../../../utils/utils";
import _ from "underscore";
import { ActivityNavBarActionShortcuts, KeyboardActionsConstants } from "../../../constants/KeyboardShortcutConstants";
import useKeyboardShortcut from "../../../hooks/useKeyboardShortcut";
import { TooltipTitles, TooltipTypes } from "../../../constants/TooltipConstants";
import { FeatureFlagContext, FeatureFlagProviderType } from "../../../contexts/FeatureFlagContext";
import CustomTooltip from "../CustomTooltip/CustomTooltip";

type Tab = {
  displayName: string;
  routeName: string;
  tooltip?: string;
};

interface NavbarProps {
  tabs: Tab[];
  dropdown?: Tab[];
  dropdownDefault?: string;
  backDisplayName?: string;
  backRoute?: string;
  rootPath: string;
  dropdownRootPath?: string;
  hideDropdown?: boolean;
  persistQueryParams?: boolean;
  tooltip?: string;
  handleBackRoute?: boolean;
  meta?: ActivityCountDetailed;
  title?: string;
  subtitle?: string;
  enableKeyboardShortcut?: boolean | undefined;
}

export default function Navbar(props: NavbarProps): React.ReactElement {
  const { revertCurrentSwitcherStateSnapshot } = React.useContext(DocumentSwitchContext) as DocumentSwitchType;
  const location = useLocation();
  const tabWrapperRef = useRef<HTMLDivElement>(null);
  const [tabBreakpoint, setTabBreakpoint] = useState<number>(props.tabs.length);
  const [tabWidth, setTabWidth] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const history = useHistory();

  const [overflowMenuAnchorEl, setOverflowMenuAnchorEl] = useState<HTMLDivElement | null>(null);
  const overflowMenuOpen = Boolean(overflowMenuAnchorEl);

  const [dropdownMenuAnchorEl, setDropdownMenuAnchorEl] = useState<HTMLDivElement | null>(null);
  const dropdownMenuOpen = Boolean(dropdownMenuAnchorEl);
  const currentStream = props.rootPath.slice(props.rootPath.lastIndexOf("/") + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);

  /**
   * @function getCountfromMeta
   * A helper function to get count of respective tab from meta data
   * @param key - Key of the tab
   * @returns
   */
  function getCountfromMeta(key: string) {
    if (!_.isEmpty(props.meta)) {
      return (
        (props.meta as any)[(currentStream + "_count") as keyof typeof props.meta][key.split(" ").join("_")] ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
      );
    }
    return null;
  }

  const { isEnabled } = React.useContext(FeatureFlagContext) as FeatureFlagProviderType;

  /**
   * Flag for Keyboard Shortcut
   */

  const isKeyboardShortcutFlagEnabled = isEnabled("KEYBOARD_SHORTCUTS");

  /**
   * HOC Hook for Keyboard ShortCut UI
   */

  const { actionToPerform, setActionDone } = useKeyboardShortcut(
    ActivityNavBarActionShortcuts,
    !props.enableKeyboardShortcut && !isKeyboardShortcutFlagEnabled
  );

  function handleResize() {
    const tabWrapperWidth = tabWrapperRef.current?.offsetWidth ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
    const tabChildren = tabWrapperRef.current?.children;
    let runningWidth = 0;
    if (tabChildren) {
      for (let i = 0; i < tabChildren.length; i++) {
        runningWidth +=
          (tabChildren[i] as HTMLElement).offsetWidth +
          (i !== tabChildren.length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE ? STYLED_CONSTANTS.CHILDREN_OFFSET : DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
        if (runningWidth > tabWrapperWidth + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) {
          setTabBreakpoint(i);
          setTabWidth(
            runningWidth -
              (tabChildren[i] as HTMLElement).offsetWidth -
              (i !== tabChildren.length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE ? STYLED_CONSTANTS.CHILDREN_OFFSET : DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO)
          );
          return;
        }
      }
    }
    setTabBreakpoint(props.tabs.length);
  }

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  /*
   * Actions for Keyboard Shortcuts
   */

  const keyboardActions: { [key: string]: any } = useMemo(
    () => ({
      [KeyboardActionsConstants.go_back_activity]: () => {
        if (props.backRoute) {
          history.push(props.backRoute);
        } else {
          history.goBack();
        }
        revertCurrentSwitcherStateSnapshot("rootSwitcherStateSnapshot");
      },
    }),
    [history, revertCurrentSwitcherStateSnapshot]
  );

  /**
   * Use Effect Calls
   */

  useEffect(() => {
    if (
      isKeyboardShortcutFlagEnabled &&
      props.enableKeyboardShortcut &&
      actionToPerform &&
      keyboardActions[actionToPerform] &&
      typeof keyboardActions[actionToPerform] === "function"
    ) {
      keyboardActions[actionToPerform]();
      setActionDone();
    }
  }, [actionToPerform, props.enableKeyboardShortcut, isKeyboardShortcutFlagEnabled]);

  const shortcutDisplayMapper: { [key: string]: string } = useMemo(() => {
    if (isKeyboardShortcutFlagEnabled && props.enableKeyboardShortcut) {
      const mapper: { [key: string]: string } = {};
      ActivityNavBarActionShortcuts.forEach((item) => {
        if (item.identifier) mapper[item.identifier] = item.displayText;
      });
      return mapper;
    }
    return {};
  }, [props.enableKeyboardShortcut, isKeyboardShortcutFlagEnabled]);

  return (
    <div className="navbar-wrapper">
      <div className="navbar-tabs">
        <div className="navbar-visible-tabs" ref={tabWrapperRef}>
          {props.tabs &&
            props.tabs.map((tab: Tab, index: number) => {
              return (
                <CustomTooltip key={index} title={tab.tooltip ?? ""} placement="bottom" disableInteractive>
                  <div>
                    <NavLinkWrapper
                      className={`navbar-navlink ${index >= tabBreakpoint ? "navbar-navlink-hidden" : ""}`}
                      exact
                      key={tab.displayName}
                      to={
                        props.persistQueryParams
                          ? tab.routeName && !tab.routeName.startsWith("?")
                            ? { pathname: `${props.rootPath}/${tab.routeName.replace(/\?.+$/, "")}`, search: location.search }
                            : { pathname: `${props.rootPath}`, search: location.search }
                          : `${props.rootPath}${tab.routeName && !tab.routeName.startsWith("?") ? `/${tab.routeName}` : tab.routeName}`
                      }
                    >
                      {(isActive: boolean) => (
                        <ContentNavLink
                          isActive={isActive}
                          noMargin
                          count={getCountfromMeta("total")}
                          currentStream={currentStream}
                          tab={tab.displayName}
                        >
                          <>
                            {tab.displayName.charAt(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO).toUpperCase() +
                              tab.displayName.slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ONE)}
                          </>
                        </ContentNavLink>
                      )}
                    </NavLinkWrapper>
                  </div>
                </CustomTooltip>
              );
            })}
          <div className="navbar-details">
            {props.title && <h4 className="navbar-title">{props.title}</h4>}
            {props.subtitle && (
              <>
                <Divider className="navbar-details-divider" orientation="vertical" flexItem />
                <p className="navbar-subtitle">{props.subtitle}</p>
              </>
            )}
          </div>
        </div>
        {props.tabs && tabBreakpoint !== props.tabs.length && (
          <>
            <div
              className="overflow-dropdown"
              style={{ left: tabWidth }}
              onClick={(e: MouseEvent<HTMLDivElement>) => setOverflowMenuAnchorEl(e.currentTarget)}
            >
              <Ellipses />
            </div>
            <Menu
              open={overflowMenuOpen}
              anchorEl={overflowMenuAnchorEl}
              onClose={() => setOverflowMenuAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              {props.tabs.slice(tabBreakpoint).map((tab: Tab, index: number) => {
                return (
                  <MenuItem
                    key={index}
                    onClick={() => history.push(`${props.rootPath}/${tab.routeName}`)}
                    selected={
                      !!matchPath(location.pathname, {
                        path: `${props.rootPath}/${tab.routeName.replace(/\?.+$/, "")}`,
                        exact: false,
                        strict: false,
                      })
                    }
                  >
                    {tab.displayName}
                  </MenuItem>
                );
              })}
            </Menu>
          </>
        )}
      </div>
      <div className="navbar-back">
        {!props.hideDropdown && props.dropdown && (
          <>
            <CustomTooltip
              type={(props.tooltip ?? "").length > NUMERIC_VALUES.CONSTANT_TWENTY ? TooltipTypes.RICH : TooltipTypes.PLAIN}
              title={props.tooltip ?? ""}
              placement="bottom-end"
              disableInteractive
            >
              <div>
                <DropMenu
                  title={props.dropdownDefault || ""}
                  isActive={dropdownMenuOpen}
                  onClick={(e: MouseEvent<HTMLDivElement>) => setDropdownMenuAnchorEl(e.currentTarget)}
                  iconAlign="left"
                />
              </div>
            </CustomTooltip>

            <Menu
              open={dropdownMenuOpen}
              anchorEl={dropdownMenuAnchorEl}
              onClose={() => setDropdownMenuAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              {props.dropdown.map((tab: Tab, index: number) => {
                return (
                  <MenuItem key={index} onClick={() => history.push(`${props.dropdownRootPath}/${tab.routeName}`)}>
                    {tab.displayName}
                  </MenuItem>
                );
              })}
            </Menu>
          </>
        )}
        {props.dropdown && props.backRoute && !props.hideDropdown && <div className="divider"></div>}
        {props.backRoute && (
          <NavLinkWrapper className="navbar-navlink-back" key="navlink-back" to={props.backRoute}>
            {() => {
              return (
                <CustomTooltip
                  type={
                    props.backDisplayName && isKeyboardShortcutFlagEnabled && props.enableKeyboardShortcut
                      ? TooltipTypes.SHORTCUT
                      : TooltipTypes.PLAIN
                  }
                  title={
                    props.backDisplayName && isKeyboardShortcutFlagEnabled && props.enableKeyboardShortcut ? TooltipTitles.GO_BACK_ACTIVITIES : ""
                  }
                  shortcutKeysText={
                    props.backDisplayName && isKeyboardShortcutFlagEnabled && props.enableKeyboardShortcut
                      ? shortcutDisplayMapper?.[TooltipTitles.GO_BACK_ACTIVITIES]
                      : ""
                  }
                >
                  <div
                    className={`back-btn`}
                    onClick={() => {
                      if (!props.handleBackRoute) {
                        history.goBack();
                      }
                      revertCurrentSwitcherStateSnapshot("rootSwitcherStateSnapshot");
                    }}
                  >
                    {props.backDisplayName ? (
                      <>
                        <BackArrowNav />
                        <div className="back-text">
                          <SmartTextContainer title={props.backDisplayName} />
                        </div>
                      </>
                    ) : (
                      <Skeleton
                        variant="text"
                        width={Utils.getConvertedSizeInRem(NAVBAR_SKELETON_CONSTANTS.WIDTH)}
                        height={Utils.getConvertedSizeInRem(NAVBAR_SKELETON_CONSTANTS.HEIGHT)}
                        animation="wave"
                      />
                    )}
                  </div>
                </CustomTooltip>
              );
            }}
          </NavLinkWrapper>
        )}
      </div>
    </div>
  );
}

import React from "react";
import { useHistory, useParams } from "react-router";
import useMagicAuth from "../../hooks/useMagicAuth";
import ApprovalRequestContainer from "./ApprovalRequest/ApprovalRequestContainer";
import ApprovalActivityContainer from "./ApprovalActivity/ApprovalActivityContainer";
import "./Approvals.scss";
import Status from "./Status/Status";
import { Success } from "../library/Icons/Icons";
import { MagicAuthContext, MagicAuthContextType } from "../../contexts/external/MagicAuthContext";
import { ApprovalDetailsContext, ApprovalsProviderType } from "../../contexts/external/ApprovalDetailsContext";
import { ApprovalActivityAccessor } from "../../db/unauthAccessors";
import Loading from "../library/Loading/Loading";
import { RequestDetailsType } from "./ApprovalsTypes";
import { ActivityStreamData } from "./ApprovalActivity/ApprovalActivityTypes";
import Utils from "../../utils/utils";
import { AxiosError } from "axios";
import { CONNECTION_STATUS } from "../../constants/ConnectionConstants";
import _ from "underscore";
import { formatDate, getDisplayDateFormat } from "../../db/utils/date";

type ReviewAction = { actionTaken: boolean; success: boolean };

const ApprovalReview = () => {
  const [action, setAction] = React.useState<ReviewAction>();
  const [activity, setActivity] = React.useState<ActivityStreamData>({} as ActivityStreamData);
  const [reviewerName, setReviewerName] = React.useState<string>("");

  const { authenticated, getToken } = React.useContext(MagicAuthContext) as MagicAuthContextType;
  const { requestData, loading, setError, fetchRequestData } = React.useContext(ApprovalDetailsContext) as ApprovalsProviderType;
  const history = useHistory();

  // UUID to set up recurring token request
  const { id } = useParams<{ id: string }>();
  useMagicAuth(id, "view-request");

  /** prepare payload for request details card */
  const parseRequestDetails = React.useCallback(
    (data: RequestDetailsType) => {
      return {
        title: `${data.from ?? "Unknown"} requested an approval from you`,
        date: `${formatDate(data.date, getDisplayDateFormat(activity?.countryCode))}`,
        message: data.message,
        status: data.status,
        redirectId: data.redirect_request_id,
        isReadonly: data.read_only,
      };
    },
    [activity?.countryCode]
  );

  /**
   * fetch request details and checks for action performed,
   * if the request is already reviewed, redirects the user
   * to reviewed screen
   */
  const getRequestData = async () => {
    // if loaded from redirect failure screen, no need to make an api call for approval request details
    if ((history.location.state as { from: string })?.from === "redirect-link-failure" && !_.isEmpty(requestData)) return;
    try {
      const res = await fetchRequestData(id);
      if (res.status === "cancelled") {
        history.push("/link-expired", { from: "view-request", error: "cancelled" });
      }
    } catch (error) {
      if ((error as AxiosError).response?.status === CONNECTION_STATUS.LINK_EXPIRED_422.STATUS_CODE) {
        history.push("/link-expired", {
          from: "view-request",
          error: (error as AxiosError).response?.data.messages.errors_meta.magic_link[0] ?? "reviewed",
          info: {
            has_active_approval: (error as AxiosError).response?.data.messages.errors_meta.has_active_approval[0] ?? false,
            status: (error as AxiosError).response?.data.messages.errors_meta.status[0],
            approval_request_id: id,
            expired_duration: (error as AxiosError).response?.data.messages.errors_meta.expiry_duration,
          },
        });
      } else {
        history.push("/error");
      }
    }
  };

  /**
   * fetches the activity stream details where approval request
   * was intialized.
   */
  const fetchActivityFeed = async () => {
    let response = {} as APIResponse;
    try {
      response = await ApprovalActivityAccessor.getActivityDetails(id);
    } catch (error) {
      /** check for the error HTTP code */
      if ((error as AxiosError).response?.status === CONNECTION_STATUS.LINK_EXPIRED_422.STATUS_CODE) {
        history.push("/link-expired", {
          from: "view-request",
          error: (error as AxiosError).response?.data.messages.errors_meta.magic_link[0] ?? "reviewed",
          info: {
            has_active_approval: (error as AxiosError).response?.data.messages.errors_meta.has_active_approval[0] ?? false,
            status: (error as AxiosError).response?.data.messages.errors_meta.status[0],
            approval_request_id: id,
            expired_duration: (error as AxiosError).response?.data.messages.errors_meta.expiry_duration,
          },
        });
      } else {
        history.push("/error");
      }
      setError(true);
    } finally {
      if (response.success) {
        setActivity({
          stream: response.data.activity_stream,
          transactions: response.data.transactions,
          attachments: response.data.attachments?.map((item: AttachmentItem) => ({
            ...item,
            created_at: Utils.evaluateDate(item.created_at as number),
          })),
          erpConnection: response.data.erp_connection,
          currency: response.data.currency ?? { code: "USD", symbol: "$", locale: "en-US" },
          countryCode: response.data.country_code ?? "US",
        });
      }
    }
  };

  /**
   * This function required to check whether action is
   * taken against a request or not and updates the state
   * accordingly.
   * @param action whether action against the request has
   * been taken
   */
  const onClickReview = (action: ReviewAction, reviewerName: string) => {
    setAction(action);
    setReviewerName(reviewerName);
  };

  const SuccessScreen = () => (
    <Status
      size="lg"
      logo={<Success />}
      statusMessage={`Nice work, thanks for taking care of ${reviewerName ? `${reviewerName}’s` : "the"} request!`}
      subText={"Your response to the approval request has been sent to the requestor."}
    />
  );

  React.useEffect(() => {
    (async function fetchDetails() {
      if (!authenticated) {
        await getToken(id, "view-request");
      } else {
        await getRequestData();
        await fetchActivityFeed();
      }
    })();
  }, [authenticated]);

  return action?.actionTaken && action?.success ? (
    <SuccessScreen />
  ) : authenticated && !loading ? (
    <div className="approval-container">
      <div className="header">{requestData.subject}</div>
      <ApprovalRequestContainer id={id} onClickReview={onClickReview} requestData={parseRequestDetails(requestData)} />
      <ApprovalActivityContainer {...activity} isReadonly={requestData.read_only} />
    </div>
  ) : (
    <div className="redirect-form-loader">
      <Loading isRelative={true} />
    </div>
  );
};

export default ApprovalReview;

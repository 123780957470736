import React from "react";
import { EmptySuggestionMessageContainer } from "../styled/dropdown.search.filter.styled";
import { useTranslation } from "react-i18next";

type EmptySuggestionMessageProps = {
  headerText?: string;
  subHeaderText?: string;
};

const EmptySuggestionMessage: React.FC<EmptySuggestionMessageProps> = ({ headerText, subHeaderText }) => {
  const { t } = useTranslation();
  return (
    <EmptySuggestionMessageContainer>
      <div className="header-text">{headerText ?? t("filters.dropdownSearchFilter.emptySuggestionMessage.header", { ns: "table" })} </div>
      <div className="subheader-text">{subHeaderText ?? t("filters.dropdownSearchFilter.emptySuggestionMessage.subHeader", { ns: "table" })}</div>
    </EmptySuggestionMessageContainer>
  );
};

export default EmptySuggestionMessage;

import { Skeleton } from "@mui/material";
import React from "react";
import { useHistory } from "react-router";
import { DEFAULT_NUMERIC_VALUES } from "../../../../../constants/NumericConstants";
import { CONTACT_DETAIL_HEADER_SKELETON_CONSTANTS } from "../../../../../constants/StyleConstants";
import { ApplicationRouteContext } from "../../../../../contexts/ApplicationRouteContext";
import { WorkspaceContext } from "../../../../../contexts/WorkspaceContext";
import { WorkspaceType } from "../../../../../types/enums";
import Utils from "../../../../../utils/utils";
import { PrimaryContact, UserPic } from "../../../../library/Icons/Icons";
import "./ContactDetailHeader.scss";
import useWorkspaceConfigurations from "../../../../../hooks/useWorkspaceConfigurations";
import useLocale from "../../../../../hooks/useLocale";

interface ContactDetailHeaderProps {
  contactName: string;
  isPrimary: boolean;
  companyId: string;
  loading: boolean;
  companyName: string;
  modifiedDate: string;
  erpConnection: boolean;
}

export default function Header(props: ContactDetailHeaderProps): React.ReactElement {
  const history = useHistory();
  const { selectedWorkspace, workspaceHomePath } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { configs: currentWorkspaceConfigs, configProvider, pathType } = useWorkspaceConfigurations();
  const componentConfig = selectedWorkspace.workspace_type_route === WorkspaceType.AW ? configProvider[pathType] : currentWorkspaceConfigs;
  const { updateBaseRoute } = React.useContext(ApplicationRouteContext) as ApplicationRouteType;
  const { formatDateBasedOnCountry: formatDate } = useLocale();

  const onClickCustomerName = () => {
    const base = `${workspaceHomePath}/${
      props.erpConnection ? componentConfig.connections.routeType : componentConfig.otherConnections.routeType
    }/active/${props.companyId}`;
    updateBaseRoute(base);
    history.push(base);
  };

  if (props.loading) {
    return (
      <div className="contact-detail-head">
        <div className="contact-wrapper">
          <Skeleton
            variant="text"
            width={Utils.getConvertedSizeInRem(CONTACT_DETAIL_HEADER_SKELETON_CONSTANTS.WRAPPER_WIDTH)}
            height={Utils.getConvertedSizeInRem(CONTACT_DETAIL_HEADER_SKELETON_CONSTANTS.HEAD_HEIGHT)}
            animation="wave"
          />
        </div>
        <div className="customer">
          <Skeleton
            variant="text"
            width={Utils.getConvertedSizeInRem(CONTACT_DETAIL_HEADER_SKELETON_CONSTANTS.CUSTOMER_WIDTH)}
            height={Utils.getConvertedSizeInRem(CONTACT_DETAIL_HEADER_SKELETON_CONSTANTS.HEAD_HEIGHT)}
            animation="wave"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="contact-detail-head">
      <div className="contact-wrapper">
        <div className="profile-wrapper">{props.isPrimary ? <PrimaryContact /> : <UserPic />}</div>
        <span className="contactName">{props.contactName}</span>
      </div>
      <div className="date-company-wrapper">
        <div className="date-wrapper">
          <span className="dark">{formatDate(props.modifiedDate)}</span>
          <span className="light">Last Updated</span>
        </div>
        <div className="customer" onClick={onClickCustomerName}>
          <div className={`tag`}>{props.companyName.charAt(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) ?? ""}</div>
          <p className={`customerName`}>{props.companyName}</p>
        </div>
      </div>
    </div>
  );
}

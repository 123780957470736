import React from "react";
import _ from "underscore";
import { MenuItem, Select, SelectChangeEvent, SelectProps, Input, InputAdornment, Typography } from "@mui/material";
import { KeyboardArrowDown, Search } from "@mui/icons-material";
import useDropdownSearchStyle from "./useDropdownSearchStyle";
import { CheckList } from "../Icons/Icons";
import { DEFAULT_NUMERIC_VALUES } from "../../../constants/NumericConstants";
import { useTranslation } from "react-i18next";

interface DropdownProps extends SelectProps {
  handleDropDownChange: (event: SelectChangeEvent<string[]>, param: string, child: React.ReactNode) => void;
  selectedValues: Array<string>;
  optionList: Array<string>;
  hasValueChanged: boolean;
  defaultText: string;
  param: string;
  multiSelectLabel: string;
}

const DropdownSearch: React.FC<DropdownProps> = ({
  handleDropDownChange,
  selectedValues = [],
  optionList = [],
  hasValueChanged,
  defaultText,
  param,
  multiSelectLabel = "Connections",
}) => {
  const {
    select,
    menuRoot,
    menuSearchBox,
    adornmentSearchIcon,
    menuTypographyElement,
    divRoot,
    fillDivRoot,
    itemText,
    listItemIcon,
    hidelist,
    typography,
    horizontalRule,
  } = useDropdownSearchStyle();
  const [searchText, setSearchText] = React.useState<string>("");
  const handleSearch = (event: { target: { value: string } }) => setSearchText(event.target.value);
  const {t} = useTranslation();
  /**
   * @function processSelectedValue
   * Function to get multiple selected values from Dropdown
   * and return computed custom Title to be shown on Dropdown
   * based on condition that one or more menu items have been selected
   * @param selected string[]: array of valus selected in Dropdown
   * @returns string
   */
  const processSelectedValue = (selected: string[]) => {
    if (selected.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      return defaultText;
    } else if (selected.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) {
      return `${selected[DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO]}`;
    } else {
      return `${DEFAULT_NUMERIC_VALUES.DEFAULT_TWO}${selected.length > DEFAULT_NUMERIC_VALUES.DEFAULT_TWO ? "+" : ""} ${multiSelectLabel}`;
    }
  };

  return (
    <div className={`${hasValueChanged ? fillDivRoot : divRoot}`}>
      <Select
        id="dropdown"
        onChange={(event, child?) => handleDropDownChange(event, param, child)}
        className={select}
        multiple={true}
        value={selectedValues}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          className: menuRoot,
        }}
        IconComponent={KeyboardArrowDown}
        displayEmpty={true}
        renderValue={(selected) => processSelectedValue(selected)}
      >
        <MenuItem key={defaultText} value={defaultText} className={hidelist}>
          {defaultText}
        </MenuItem>
        <Input
          key="searchInput"
          value={searchText}
          defaultValue={searchText}
          className={menuSearchBox}
          onChange={handleSearch}
          onKeyDown={(event) => event.stopPropagation()}
          disableUnderline={true}
          startAdornment={
            <InputAdornment position="start">
              <Search className={adornmentSearchIcon} />
            </InputAdornment>
          }
        />
        {optionList?.map((item) => {
          if (_.contains(selectedValues, item)) {
            return (
              <MenuItem key={item} value={item}>
                <span key={item} className={listItemIcon}>
                  <CheckList />
                </span>
                <span className={itemText}>{item}</span>
              </MenuItem>
            );
          }
        })}
        <hr className={horizontalRule} />
        <MenuItem disabled className={menuTypographyElement} value="">
          <Typography key="typography" className={typography} variant={"subtitle2" as any}>
            {(_.isEmpty(searchText) && optionList.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) ||
            (!_.isEmpty(searchText) && optionList.some((item) => item.toLowerCase().includes(searchText.toLowerCase())))
              ? t("globalSearchResults.filters.searchFilter.suggestions", {ns: "global_search"})
              : t("globalSearchResults.filters.searchFilter.nothingFound", {ns: "global_search"})}
          </Typography>
        </MenuItem>
        {optionList?.map((item) => {
          if (!_.contains(selectedValues, item)) {
            if (_.isEmpty(searchText) || item.toLowerCase().indexOf(searchText.toLowerCase()) !== DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE) {
              return (
                <MenuItem key={item} value={item}>
                  <span key={item} className={listItemIcon}></span> <span className={itemText}>{item}</span>
                </MenuItem>
              );
            }
          }
        })}
      </Select>
    </div>
  );
};

export default DropdownSearch;

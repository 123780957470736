import React from "react";
import { Typography } from "@mui/material";
import { CommandCenterActivitySnooze, SnoozedToast } from "../../../../../library/Icons/Icons";
import { NUMERIC_VALUES } from "../../../../../library/AtomicComponents/constants/numeric.constants";
import { AlertContext } from "../../../../../../contexts/AlertContext";
import { ActivitySubmenuContainer, ActivitySubmenuHeader, OptionValuesList, OptionValuesListItem } from "./styled";
import { activitiesClientV2 } from "../../../../../../db/version2Accessor";
import { ActivityActionTypes, ActivityStatus, FallbackTypes } from "../../../../../../types/enums";
import { useTranslation } from "react-i18next";

interface ActivityReassignViewProps {
  getActivityStream: (
    withLoadingAnimation: boolean,
    onActionPerform?: boolean,
    explicitlySetPageSize?: number,
    refreshStream?: boolean,
    refreshActivityDetail?: boolean
  ) => void;
  refreshActivity: () => void;
  handleCommandCenterClose: () => void;
  selectedWorkspace: UserItem;
  activityId: string;
  currentAssignee: UserItem | null;
  keyboardShortcutText: string;
}

const ActivityReassignView: React.FC<ActivityReassignViewProps> = ({
  selectedWorkspace,
  activityId,
  currentAssignee,
  refreshActivity,
  getActivityStream,
  handleCommandCenterClose,
  keyboardShortcutText,
}) => {
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const { t } = useTranslation();

  const getSnoozeList = () => {
    const hours = t("snoozeMenuListLabels.hours", { ns: "activities" });
    const days = t("snoozeMenuListLabels.days", { ns: "activities" });
    const week = t("snoozeMenuListLabels.week", { ns: "activities" });
    const snoozeFor = t("snoozeMenuListLabels.snoozeFor", { ns: "activities" });
    return [
      { id: `12 ${hours}`, value: `${snoozeFor} 12 ${hours}`, valueInEpoch: 43200 },
      { id: `24 ${hours}`, value: `${snoozeFor} 24 ${hours}`, valueInEpoch: 86400 },
      { id: `2 ${days}`, value: `${snoozeFor} 2 ${days}`, valueInEpoch: 172800 },
      { id: `3 ${days}`, value: `${snoozeFor} 3 ${days}`, valueInEpoch: 259200 },
      { id: `1 ${week}`, value: `${snoozeFor} 1 ${week}`, valueInEpoch: 604800 },
    ];
  };

  const onClickSnoozeOption = async (event: any) => {
    handleCommandCenterClose();
    const snoozeList = getSnoozeList();
    const item = snoozeList[parseInt(event.target.value, NUMERIC_VALUES.CONSTANT_TEN)];
    const toastOptions: ToastOptions = { open: true, severity: "info", message: "" };
    event.stopPropagation();
    let response = {} as APIResponse;
    const now: number = Math.floor(Date.now() / NUMERIC_VALUES.CONSTANT_THOUSAND);
    try {
      response = await activitiesClientV2.patch(selectedWorkspace?.id || FallbackTypes.Id, activityId, ActivityActionTypes.Status, {
        status: ActivityStatus.Snoozed.toLocaleLowerCase() as Status,
        snooze_until: now + item.valueInEpoch,
      });
      if (response.success) {
        setToastOptions({
          ...toastOptions,
          message: t("toastMessages.markSnooze.success", { timeDuration: item.id, ns: "activities" }),
          icon: <SnoozedToast />,
        });
        refreshActivity();
        getActivityStream(false, true, currentAssignee === null ? NUMERIC_VALUES.CONSTANT_TWO : NUMERIC_VALUES.CONSTANT_ONE);
      }
    } catch (error) {
      response.success = false;
      setToastOptions({
        ...toastOptions,
        severity: "error",
        message: t("toastMessages.markSnooze.failed", { ns: "activities" }),
        icon: <SnoozedToast />,
      });
      console.log(error);
    }
  };

  return (
    <ActivitySubmenuContainer>
      <ActivitySubmenuHeader>
        <CommandCenterActivitySnooze />
        <div className="description">
          <Typography className="title">{t("activityDetails.commandCenter.body.activitySnoozeView.title", { ns: "activities" })}</Typography>
          <Typography className="subtitle"></Typography>
        </div>
        <Typography className="keyboard-shortcut-text">{keyboardShortcutText}</Typography>
      </ActivitySubmenuHeader>
      <OptionValuesList>
        {getSnoozeList()?.map((item, index) => {
          return (
            <OptionValuesListItem key={item.value} value={index} onClick={onClickSnoozeOption}>
              <span className="list-item-icon"></span>
              {item.value}
            </OptionValuesListItem>
          );
        })}
      </OptionValuesList>
    </ActivitySubmenuContainer>
  );
};

export default ActivityReassignView;

export const STYLE_CONSTANT = Object.freeze({
  SEARCH_DIALOG_HEIGHT: 877,
  SEARCH_DIALOG_WIDTH: 560,
  SEARCH_HEADER_CONTAINER_WIDTH: 56,
  TAB_CONTAINER_HEIGHT: 48,
  TABS_MIN_HEIGHT: 32,
  TABS_MIN_WIDTH: 32,
  RESULT_HEADER_HEIGHT: 52,
  ROOT_DIALOG_CONTEN_HEIGHT: 372,
  LIST_ITEM_MIN_HEIGHT: 56,
  LIST_ITEM_MAX_WIDTH: 76,
  CONTENT_HEADING_HEIGHT: 36,
  FOOTER_HEIGHT: 36,
  SORT_MENU_HEIGHT: 99,
  SORT_MENU_WIDTH: 92,
});

export const SKELTON_NUMERIC_CONSTANT = Object.freeze({
  HEADER_ROOT_HEIGHT: 36,
  LEFT_HEADER_WIDTH: 123,
  LEFT_HEADER_HEIGHT: 14,
  RIGHT_HEADER_WIDTH: 76,
  RIGHT_HEADER_HEIGHT: 14,
  AVATAR_SIZE: 38,
  LEFT_PRIMARY_TEXT_WIDTH: 292,
  LEFT_PRIMARY_TEXT_HEIGHT: 20,
  RIGHT_PRIMARY_TEXT_WIDTH: 98,
  RIGHT_PRIMARY_TEXT_HEIGHT: 14,
  SECONDARY_TEXT_WIDTH: 98,
  SECONDARY_TEXT_HEIGHT: 14,
  SECONDARY_SUBHEADER_TEXT_WIDTH: 526,
  SECONDARY_SUBHEADER_TEXT_HEIGHT: 16,
});

import React from "react";
import { Skeleton } from "@mui/material";

const StateActionLoadingSkeleton = () => (
  <div className="e-invoice-actions">
    <div className="actions-info">
      <Skeleton variant="text" width="15rem" height={20} animation="wave" />
      <Skeleton variant="text" width="20rem" height={20} animation="wave" />
    </div>
    <div className="actions">
      <Skeleton variant="rectangular" width={65} sx={{ borderRadius: "0.5rem" }} height={40} />
      <Skeleton variant="rectangular" width={65} sx={{ borderRadius: "0.5rem" }} height={40} />
      <Skeleton variant="rectangular" width={65} sx={{ borderRadius: "0.5rem" }} height={40} />
      <Skeleton variant="rectangular" width={65} sx={{ borderRadius: "0.5rem" }} height={40} />
    </div>
  </div>
);

export default StateActionLoadingSkeleton;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import { Switch, Route, useRouteMatch, useParams, useHistory } from "react-router";
import Profile from "./Profile/Profile";
import Activities from "./Activities/Activities";
import ContactDetail from "./Contacts/ContactDetail/ContactDetail";
import ActivityDetailV2 from "../../Activities/ActivityDetailV2/ActivityDetail";
import { CustomerContext } from "../../../contexts/CustomerContext";
import Loading from "../../library/Loading/Loading";
import ErrorMessage from "../../library/Error/CustomError";
import { Header } from "./Header";
import useWorkspaceConfigurations from "../../../hooks/useWorkspaceConfigurations";
import { ApplicationRouteContext } from "../../../contexts/ApplicationRouteContext";
import { WorkspaceType } from "../../../types/enums";

import OpenInvoicesV2 from "./OpenInvoices/OpenInvoicesV2";
import ClosedInvoicesV2 from "./ClosedInvoices/ClosedInvoicesV2";
import PaymentsV2 from "./Payments/PaymentsV2";
import DocumentsV2 from "./Documents/DocumentsV2";
import { CustomerDetailsDashboardProvider } from "../../../contexts/CustomerDetailsDashboardContext";
import ContactsView from "./Contacts/ContactsViewV2";

export default function CustomerDetail(): React.ReactElement {
  const { path } = useRouteMatch();
  const { setCustomerId, customerId } = React.useContext(CustomerContext) as CustomerType;
  const { customerId: paramCustomerId } = useParams<{ customerId: string }>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { getCompanyDetails } = React.useContext(CustomerContext) as CustomerType;
  const { configs: currentWorkspaceConfigs, configProvider, pathType, selectedWorkspace } = useWorkspaceConfigurations();
  const { updateBaseRoute, getBaseFromPath } = React.useContext(ApplicationRouteContext) as ApplicationRouteType;
  const [hideHeader, setHidHeader] = React.useState<boolean>(false);
  const configs = selectedWorkspace.workspace_type_route === WorkspaceType.AW ? configProvider[pathType] : currentWorkspaceConfigs;

  /* passing the same instance of initialFilterConfigs to all the details table, as the changes in active filters
   * should also reflect, to the common summary header, hence passing viewBasedFilters to Header which is build with
   * respective views tabs.
   */

  const fetchData = async () => {
    setErrorMessage("");
    setLoading(true);
    try {
      await getCompanyDetails(paramCustomerId);
    } catch (error: any) {
      setErrorMessage(error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    /**
     * Updates the active customer id and the base route
     *
     * this updates the customer id if it's not present OR
     * it's not equal to the customer id present in the url.
     */
    if (paramCustomerId && (customerId === "" || customerId !== paramCustomerId)) {
      setCustomerId(paramCustomerId);
      updateBaseRoute(getBaseFromPath());
    }
    fetchData();
  }, [paramCustomerId]);

  return errorMessage ? (
    <div className="customer-detail-wrapper">
      <ErrorMessage isRelative>{errorMessage}</ErrorMessage>
    </div>
  ) : (
    <CustomerDetailsDashboardProvider>
      <div className="customer-detail-wrapper">
        {!hideHeader && (
          <Switch>
            <Route
              path={[
                `${path}`,
                `${path}/profile`,
                `${path}/open_invoices`,
                `${path}/open_bills`,
                `${path}/closed_invoices`,
                `${path}/closed_bills`,
                `${path}/payments`,
                `${path}/contacts`,
                `${path}/documents`,
              ]}
              component={() => {
                return (
                  <div className="head">
                    <Header isLoading={isLoading} />
                  </div>
                );
              }}
            />
          </Switch>
        )}
        {isLoading ? (
          <div className="customer-detail-wrapper">
            <Loading isRelative />
          </div>
        ) : (
          <Switch>
            <Route
              exact
              path={`${path}`}
              component={() => {
                return (
                  <div className="body-table">
                    <Activities />
                  </div>
                );
              }}
            />
            <Route
              exact
              path={[`${path}/profile`, `${path}/customers/:customerId`]}
              render={() => {
                return (
                  <div className="body">
                    <Profile />
                  </div>
                );
              }}
            />
            <Route
              exact
              path={[
                `${path}/open_invoices`,
                `${path}/open_bills`,
                `${path}/invoices`,
                `${path}/bills`,
                `${path}/open_bills/:invoiceId/applied_credit_memos`,
                `${path}/open_invoices/:invoiceId/applied_credit_memos`,
              ]}
              component={() => {
                return (
                  <div className="body-table">
                    <OpenInvoicesV2 configs={configs.connectionsV2.details} />
                  </div>
                );
              }}
            />
            <Route
              exact
              path={[
                `${path}/closed_invoices`,
                `${path}/closed_bills`,
                `${path}/closed_bills/:invoiceId/applied_credit_memos`,
                `${path}/closed_invoices/:invoiceId/applied_credit_memos`,
              ]}
              component={() => {
                return (
                  <div className="body-table">
                    <ClosedInvoicesV2 configs={configs.connectionsV2.details} />
                  </div>
                );
              }}
            />
            <Route
              exact
              path={`${path}/payments`}
              component={() => {
                return (
                  <div className="body-table">
                    <PaymentsV2 configs={configs.connectionsV2.details} />
                  </div>
                );
              }}
            />
            <Route
              exact
              path={`${path}/contacts`}
              component={() => {
                return (
                  <div className="body-table">
                    <ContactsView configs={configs.connectionsV2.details} />
                  </div>
                );
              }}
            />
            {/* supported details routes */}
            <Route
              exact
              path={`${path}/contactProfile/:contactId`}
              component={() => {
                setHidHeader(true);
                return (
                  <div>
                    <ContactDetail />
                  </div>
                );
              }}
            />
            <Route
              exact
              path={`${path}/documents`}
              component={() => {
                return (
                  <div className="body-table">
                    <DocumentsV2 configs={configs.connectionsV2.details.documents} />
                  </div>
                );
              }}
            />
            <Route
              exact
              path={`${path}/activities/:activityId`}
              component={() => {
                setHidHeader(true);
                return <ActivityDetailV2 />;
              }}
            />
          </Switch>
        )}
      </div>
    </CustomerDetailsDashboardProvider>
  );
}

export const ActivityTypes = {
  email: "Email",
  note: "Note",
  phone_call: "Phone Call",
  approval_request: "Approval Request",
};

export const TransactionsConfig = {
  Invoice: {
    recordDateTitle: "Due Date",
    recordLinkTitle: "View Invoice",
    colATitle: "Total Amount",
    colBTitle: "Outstanding Balance",
  },
  Bill: {
    recordDateTitle: "Due Date",
    recordLinkTitle: "View Bill",
    colATitle: "Total Amount",
    colBTitle: "Outstanding Balance",
  },
  Payment: {
    recordDateTitle: "Payment Date",
    recordLinkTitle: "View Payment",
    colATitle: "Total Amount",
    colBTitle: "Unapplied Amount",
  },
};

export const CompanyProfileConfig = {
  PrimaryContact: "Primary Contact",
  OtherContacts: "Other Contacts",
};

export const ArchiveModalConstant = {
  HEADER_TITLE: "ERP Connection Found",
  CONTENT_TEXT:
    "This connection is frequently used by vendors to send invoices. Archiving this is not recommended, as it may impact your receipt of invoices from vendors in the future.",
  PRIMARY_BUTTON_TEXT: "Archive, anyway",
  SECONDARY_BUTTON_TEXT: "Cancel, for now",
};

export const ActivityBannerConstant = {
  HEADER_TITLE: "Existing Contact Identified",
  CONTENT_TEXT: `This email address appears to be related to an existing connection CONNECTION_NAME. Would you like to move this activity to that connection or assign manually.`,
  PRIMARY_BUTTON_TEXT: "Select Manually",
  SECONDARY_BUTTON_TEXT: "Confirm & Move",
};

export const ErpDataExistBannerConstant = {
  HEADER_TITLE: "Existing Contact Identified",
  FIRST_CONTENT_TEXT: "This email address appears to be related to an existing connection with",
  SECOND_CONTENT_TEXT: "Would you like to move this activity to that connection or assign manually.",
  PRIMARY_BUTTON_TEXT: "Select Manually",
  SECONDARY_BUTTON_TEXT: "Confirm & Move",
};

export const ErpDataNotExistBannerConstant = {
  HEADER_TITLE: "Email Not Recognized",
  FIRST_CONTENT_TEXT: "We are unable to locate a pre-existing vendor mapped to this email address.",
  SECOND_CONTENT_TEXT: "Would you like to assign this email address to the appropriate vendor?",
  PRIMARY_BUTTON_TEXT: "Not Now",
  SECONDARY_BUTTON_TEXT: "Select Manually",
};

export const CommandCenterOptionTitles = {
  MARK_AS_COMPLETE: "Mark as Complete",
  MARK_AS_UNREAD: "Mark as Unread",
  SNOOZE: "Snooze",
  MOVE_AS_SPAM: "Mark as Spam",
  MOVE_AS_NOT_SPAM: "Mark as Not Spam",
  MOVE: "Move",
  ASSIGN: "Assign",
  MARK_SENDER_AS_SPAM: "Mark Sender as Spam",
  MARK_SENDER_AS_FRAUD: "Mark Sender as Fraud",
  NUDGE: "Nudge Activity",
};

export const ActivitiesPlaceholderConstant = {
  SUBJECT_PLACEHOLDER_TEXT: "Search Subject Name",
  FROM_PLACEHOLDER_TEXT: "Search Contact Name",
  ASSIGNEE_PLACEHOLDER_TEXT: "Search Contact Name",
};
import React, { useEffect } from "react";
import { BrowserUtils } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { CircularProgress, Grid } from "@mui/material";
import "./Logout.scss";

export default function Logout(): React.ReactElement {
  const { instance } = useMsal();

  useEffect(() => {
    instance.logoutRedirect({
      account: instance.getActiveAccount(),
      onRedirectNavigate: () => !BrowserUtils.isInIframe(),
    });
  }, [instance]);

  return (
    <Grid id="logout-container" container direction="column" justifyContent="center" alignItems="center">
      <CircularProgress />
      <p>Logging out...</p>
    </Grid>
  );
}

import React from "react";
//Import dayjs date-time library
import { default as dayjs } from "dayjs";
//import relativeTime module for relative time string calculation
import relativeTime from "dayjs/plugin/relativeTime";
//extend dayjs to use relativeTime module
dayjs.extend(relativeTime);
//import the locale definitions for french and french-canadian, eng is default
import { default as dayjs_fr } from "dayjs/locale/fr";
import { default as dayjs_fr_ca } from "dayjs/locale/fr-ca";

import "./AuditLog.scss";

/**
 * @const locales
 * A locale library constant
 */
const locales: any = {
  fr: dayjs_fr,
  fr_ca: dayjs_fr_ca,
};

type AuditLogType = {
  countryCode: string;
  feedItem: {
    title: string;
    created_at: number;
    reason: string;
    note: string;
  };
};

const AuditLog: React.FC<AuditLogType> = ({ feedItem, countryCode }) => {
  /**
   * set locale to dayjs
   * @see {@link https://day.js.org/en/}
   * @see {@link https://day.js.org/docs/en/i18n/loading-into-browser}
   */
  dayjs.locale(locales[countryCode?.toLowerCase()]);

  return (
    <div className="audit-log-container">
      <div className="audit-log-data">
        <div className="audit-log-message-header">
          <div className="audit-log-message">{feedItem?.title}</div>
          {feedItem?.created_at && <div className="audit-log-timestamp">{dayjs.unix(feedItem?.created_at).fromNow()}</div>}
        </div>
        {feedItem?.reason && <div className="audit-log-reason">Reason: {feedItem?.reason}</div>}
        {feedItem?.note && <div className="audit-log-note">Note: "{feedItem?.note}"</div>}
      </div>
    </div>
  );
};

export default AuditLog;

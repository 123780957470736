/**
 * @const CachePolicyGlobal
 * The global cache policy for react-query client.
 * @see {@link https://tanstack.com/query/v4/docs/react/guides/important-defaults | Important defaults}
 * @see {@link https://tanstack.com/query/v4/docs/react/reference/QueryClient | QueryClient config}
 */
export const CachePolicyGlobal = {
  refetchOnWindowFocus: false,
  refetchOnMount: false,
  cacheTime: Infinity,
  retry: 2,
  refetchInterval: 300000,
  networkMode: "always",
};

export const CachePolicyMutationGlobal = {
  cacheTime: Infinity,
  retry: 2,
};

/**
 * All time intervals are in milliseconds
 */
export const RefetchIntervals = {
  accountSummary: 600000,
  aging: 600000,
  daysOutStanding: 600000,
  receivablesRisk: 600000,
  topCompanies: 300000,
  activityStreamsIndex: 120000,
  activityStreamsSpamIndexOverride: 300000,
  activityStreamDetail: 120000,
  activityStreamFeed: 120000,
  searchSuggestionList: 3600000,
  megaMenu: 120000,
  workspaceSettings: 600000,
  contactRoles: 600000,
  export: 5000,
  twoHoursDelay: 7200000,
};

/*eslint-disable no-magic-numbers*/
import { DATE_CONSTANTS_IN_MIN, DEFAULT_NUMERIC_VALUES } from "../constants/numeric.constants";
import { DateTime } from "luxon";

/**
 * * Returns the number of days between @param date and current date. If @param date is before
 * * current date, return a positive integer. If @param date is after current date, return a
 * * negative integer. Otherwise, return 0.
 * @param date - YYYY-MM-DD date string
 * @returns number or null
 */
export const calcDaysBetween = (date: string | null): number | null => {
  if (!date) {
    return null;
  }
  const parsedDate = DateTime.fromFormat(date, "yyyy-MM-dd");
  if (!parsedDate.isValid) {
    return null;
  } else {
    return Math.floor(-parsedDate.diffNow("days").days);
  }
};

/**
 * * Converts Javascript Date Object to <Long month> <Numeric day>, <Numeric year> string
 * @param value - Javascript Date Object
 * @returns <Long month> <Numeric day>, <Numeric year>
 */
export const formatDate = (value: string | null, dateFormat?: string): string => {
  if (!value) {
    return "N/A";
  }
  return DateTime.fromFormat(value.split("T")[0], "yyyy-MM-dd").toFormat(dateFormat || "MMM dd, yyyy");
};

export const formatEpoch = (value: string | null): string => {
  if (value) {
    const dateFromEpoch = new Date(Number(value) * 1000);
    return dateFromEpoch.toISOString();
  } else {
    return "N/A";
  }
};

/**
 * Converts Javascript Date Object to <Hours>:<Minutes> string
 * @param value Javascript Date Object
 * @returns <Hours>:<Minutes> <AM/PM>
 */
export const formatTime = (value: string | null): string => {
  if (!value) {
    return "N/A";
  }
  return DateTime.fromSQL((value + "UTC").replace("T", " ").replace("Z", ""))
    .toLocaleString(DateTime.TIME_SIMPLE)
    .replace(" ", "");
};

export const formatTimeZone = () => {
  return new Date().toLocaleTimeString("en-us", { timeZoneName: "short" }).split(" ")[2];
};

/**
 * Conversts javascript date object to time elapsed
 * @param date Javascript Date object
 * @returns ** minutes/hours/days/weeks
 */
export const formatTimeElapsed = (date: string | null): string | null => {
  if (!date) {
    return null;
  }

  const parsedDate = DateTime.fromSQL(date.replace("T", " ").replace("Z", ""), {
    zone: "UTC",
  });
  if (!parsedDate.isValid) {
    return null;
  } else {
    const timeElapsed = Math.floor(-parsedDate.diffNow("minutes").minutes);
    if (timeElapsed < DATE_CONSTANTS_IN_MIN.HOURS) {
      return `${Math.ceil(timeElapsed)} minutes`;
    }
    if (timeElapsed < DATE_CONSTANTS_IN_MIN.DAY) {
      return `${Math.ceil(timeElapsed / DATE_CONSTANTS_IN_MIN.HOURS)} hours`;
    }
    if (timeElapsed < DATE_CONSTANTS_IN_MIN.WEEK) {
      return `${Math.ceil(timeElapsed / DATE_CONSTANTS_IN_MIN.DAY)} days`;
    }
    return `${Math.ceil(timeElapsed / DATE_CONSTANTS_IN_MIN.WEEK)} weeks`;
  }
};

/**
 * @param val - value to be formatted
 * @returns date/month number with preceeding zero incase of single digit value.
 */
export const getNumberWithPreceedingZero = (val: number): string => {
  return ("0" + val.toString()).slice(-2);
};

/**
 *
 * @param val - date to be formatted
 * @returns yyyy-mm-dd
 */
export const getFormattedDate = (val: Date): string => {
  return `${val.getFullYear()}-${getNumberWithPreceedingZero(val.getMonth() + DEFAULT_NUMERIC_VALUES.ONE)}-${getNumberWithPreceedingZero(
    val.getDate()
  )}`;
};

/**
 *
 * @param countryCode - country for which need date format
 * @returns  string Dayjs date format base country code
 */
export const getCalendarFieldDateFormat = (countryCode: string): string => {
  switch (countryCode) {
    case "US":
      return "MMM DD, YYYY";
    case "IE":
    case "CA":
    case "UK":
      return "DD MMM, YYYY";
    default:
      return "MMM DD, YYYY";
  }
};

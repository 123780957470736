import React from "react";
import { Upload } from "../../Icons/Icons";
import { DraggableComponentProps } from "../UploadComponentInterfaces/UploadComponentInterfaces";

const DraggableComponent: React.FC<DraggableComponentProps> = ({
  dragging,
  draggableArea,
  inputRef,
  labelProps,
  onChangeFileHandler,
}: DraggableComponentProps) => {
  return (
    <div className="draggable-area" ref={draggableArea}>
      <span>
        <Upload />
      </span>
      {!dragging && <p className="body2">{labelProps?.dragNDropLabels?.inactiveLabel || "Drag and drop your profile image"}</p>}
      {dragging && <p className="body2">{labelProps?.dragNDropLabels?.activeHoverLabel || "Drop Here"}</p>}
      <input type="file" accept="image/*" className="hidden-input" ref={inputRef} onChange={onChangeFileHandler} />
    </div>
  );
};

export default DraggableComponent;

import React from "react";
import { VIEW_CATEGORIES } from "../../../types/enums";
import { default as EInvoicesTable } from "../EInvoicesTable/EInvoicesTable";
import { RowDataTypes } from "../../library/AtomicComponents/constants/table.constants";
import { useTranslation } from "react-i18next";
import { File } from "../../library/Icons/Icons";

export default function ReceivedEInvoices(): React.ReactElement {
  const { t } = useTranslation();

  const RECEIVED_E_INVOCIES = [
    {
      name: t("table.receivedEInvoices.columns.eInvoicingStatus", { ns: "einvoices" }),
      accessor: "state",
      sortField: "state",
      rowDataAlign: "left",
      enableSkeleton: true,
      rowDataType: RowDataTypes.EInvoiceStatus,
      enableSort: false,
      enableFilter: false,
      filterType: "dropdown",
      filterAlignment: "right",
      dropdownOptions: [
        {
          label: "Accepted",
          id: "accepted",
        },
        {
          label: "Rejected",
          id: "rejected",
        },
      ],
      dropdownListItems: [
        { label: "Accepted", value: "accepted" },
        { label: "Rejected", value: "rejected" },
      ],
      width: "10%",
    },
    {
      name: t("table.receivedEInvoices.columns.from", { ns: "einvoices" }),
      sortField: "company_name",
      accessor: "company_name",
      rowDataType: RowDataTypes.BoldText,
      rowDataAlign: "left",
      enableSkeleton: true,
      enableSort: false,
      enableFilter: false,
      filterType: "dropdown-search",
      filterAlignment: "center",
      placeholder: t("table.receivedEInvoices.placeHolder.from", { ns: "einvoices" }),
      width: "10%",
      emboldenOnUnread: true,
    },
    {
      name: "Attachment",
      icon: <File />,
      accessor: "attachments",
      conditionalAccessor:"invoice_id",
      rowDataType: RowDataTypes.EInvoiceAttachment,
      rowDataAlign: "left",
      enableSkeleton: true,
      width: "10%",
    },
    {
      name: t("table.receivedEInvoices.columns.invoice", { ns: "einvoices" }),
      accessor: "reference_code",
      sortField: "reference_code",
      rowDataAlign: "left",
      rowDataType: RowDataTypes.BoldText,
      enableSkeleton: true,
      enableSort: true,
      enableFilter: true,
      filterType: "dropdown-search",
      filterAlignment: "right",
      dropdownSearchVariant: "large",
      placeholder: t("table.receivedEInvoices.placeHolder.invoiceNumber", { ns: "einvoices" }),
      width: "10%",
      emboldenOnUnread: true,
    },
    {
      name: t("table.receivedEInvoices.columns.dueDate", { ns: "einvoices" }),
      accessor: "payment_due_date",
      sortField: "payment_due_date",
      rowDataAlign: "left",
      enableSkeleton: true,
      rowDataType: RowDataTypes.DateEpoch,
      enableSort: true,
      enableFilter: true,
      filterType: "date",
      filterAlignment: "right",
      width: "10%",
      emboldenOnUnread: true,
    },
    {
      name: t("table.receivedEInvoices.columns.aging", { ns: "einvoices" }),
      accessor: "invoice_status",
      conditionalAccessor: "payment_due_date_raw",
      rowDataAlign: "left",
      enableSkeleton: true,
      rowDataType: RowDataTypes.EInvoiceDaysStatusChips,
      enableSort: false,
      enableFilter: false,
      filterType: "dropdown",
      filterAlignment: "right",
      dropdownOptions: [
        {
          label: "OPEN",
          value: "-180",
          id: "open",
        },
        {
          label: "PAST DUE 1-30",
          value: "0",
          id: "late1",
        },
        {
          label: "PAST DUE 31-60",
          value: "30",
          id: "late31",
        },
        {
          label: "PAST DUE 61-90",
          value: "60",
          id: "late61",
        },
        {
          label: "PAST DUE 91+",
          value: "90",
          id: "late91",
        },
        {
          label: "CLOSED",
          value: "-1",
          id: "closed",
        },
      ],
      dropdownListItems: [
        { key: "All Status", label: "All Status", value: "All Status" },
        { key: "OPEN", label: "OPEN", value: "Open" },
        { key: "CLOSED", label: "CLOSED", value: "Closed" },
        { key: "PAST DUE", label: "PAST DUE", value: "Past Due" },
      ],
      width: "10%",
    },
    {
      name: t("table.receivedEInvoices.columns.transactionAmount", { ns: "einvoices" }),
      accessor: "total_amount",
      enableSort: true,
      rowDataAlign: "right",
      enableSkeleton: true,
      sortField: "base_currency_total_amount",
      alignment: "right",
      filterAnchorPosition: "center",
      rowDataType: RowDataTypes.Currency,
      enableFilter: true,
      filterType: "numeric",
      filterAlignment: "right",
      width: "4%",
      emboldenOnUnread: true,
    },
    {
      name: t("table.receivedEInvoices.columns.pretaxTransactionAmount", { ns: "einvoices" }),
      accessor: "amount_paid",
      enableSort: false,
      rowDataAlign: "right",
      enableSkeleton: true,
      sortField: "amount_paid",
      alignment: "right",
      filterAnchorPosition: "center",
      rowDataType: RowDataTypes.Currency,
      enableFilter: false,
      filterType: "numeric",
      filterAlignment: "right",
      width: "10%",
      emboldenOnUnread: true,
    },
  ];

  return (
    <div className={`table-activity-wrapper activity-stream-v2`}>
      <div className="body-table">
        <EInvoicesTable columns={RECEIVED_E_INVOCIES} assigneeType={"all"} route={"all"} category={VIEW_CATEGORIES.MINE_ACTIVITES} />
      </div>
    </div>
  );
}

/**
 * * Parses Database fields to address string
 * @param address1
 * @param address2
 * @param address3
 * @param city
 * @param state
 * @param postalCode
 * @param country
 * @returns
 */
export const formatAddress = (
  address1: string,
  address2: string,
  address3: string,
  city: string,
  state: string,
  postalCode: string,
  country: string
): string => {
  const address = `${address1} ${address2} ${address3}`.trim();
  const arr = [];
  if (address !== "") {
    arr.push(address);
  }
  if (city !== "") {
    arr.push(city);
  }
  if (state !== "") {
    arr.push(state);
  }
  if (postalCode !== "") {
    arr.push(postalCode);
  }
  if (country !== "") {
    arr.push(country);
  }
  return arr.join(", ");
};

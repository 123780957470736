import { styled } from "@mui/system";
import { styled as muiStyled } from "@mui/material/styles";
import { List, ListItem } from "@mui/material";

export const ActivitySubmenuContainer = styled("div", {
  name: "activity-submenu-container",
  slot: "root",
})({
  "& .current-value-subtitle": {
    display: "flex",
    width: "100%",
    fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
    fontWeight: "500",
    fontSize: "0.75rem",
    lineHeight: "1rem",
    color: "#576375",
    padding: "0.5rem 1rem",
    "& .list-item-end-icon": {
      marginLeft: "auto",
    },
  },
  "& .subtitle-item": {
    cursor: "pointer",
  },
});

export const ActivitySubmenuHeader = styled("div", {
  name: "activity-submenu-header",
  slot: "root",
})({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "0.5rem",
  padding: "1rem 1.5rem",
  "& .description": {
    flexDirection: "column",
    "& .title": {
      fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
      fontWeight: "500",
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      color: "#4d4d4d",
    },
    "& .subtitle": {
      fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
      fontWeight: "400",
      fontSize: "0.75rem",
      lineHeight: "1rem",
      color: "#4d4d4d",
    },
  },
  "& .keyboard-shortcut-text": {
    display: "flex",
    alignItems: "center",
    background: "#4d4d4d",
    borderRadius: "0.125rem",
    color: "#ffffff",
    fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
    fontWeight: "400",
    fontSize: "0.75rem",
    lineHeight: "0.875rem",
    height: "1rem",
    padding: "0.125rem 0.25rem",
    marginLeft: "auto",
  },
});

export const ActivitySubmenuContentBody = styled("div", {
  name: "activity-submenu-content-body",
  slot: "root",
})({
  padding: "1rem 1.5rem",
});

export const SearchInputContainer = styled("div", {
  name: "activity-reassign-search-input-container",
  slot: "root",
})({
  width: "100%",
  height: "2.25rem",
  padding: "0 0.5rem",
  marginBottom: "0.5rem",
  "& .MuiInput-root": {
    background: "#F7F7FB",
    borderColor: "",
    borderRadius: "0.5rem",
    boxSizing: "border-box",
    width: "100%",
    height: "100%",
    fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
    fontWeight: "400",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    "& :before": {
      borderBottom: "none",
    },
    "&:focus": {
      borderColor: "",
    },
    "& .MuiSvgIcon-root": {
      marginLeft: "0.5rem",
    },
  },
});

export const OptionValuesList = muiStyled(List, {
  name: "option-values-list",
  slot: "root",
})({
  // maxHeight: "9.8rem",
  overflowX: "hidden",
  overflowY: "scroll",
  "& .MuiListItem-root": {
    fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
    fontWeight: "400",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },
});

export const OptionValuesListItem = muiStyled(ListItem, {
  name: "option-values-list",
  slot: "root",
})({
  display: "flex",
  alignItems: "center",
  fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
  paddingLeft: "1.125rem",
  cursor: "pointer",
  gap: "0.75rem",
  "&:hover": {
    background: "#f7f7fb",
  },
  "& .list-item-icon": {
    display: "flex",
    alignItems: "center",
    width: "1.125rem",
  },
  "& .list-item-value": {
    fontWeight: "400!important",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },
});

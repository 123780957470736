import React from "react";
import { Box } from "@mui/system";
import { CheckList } from "../../../../../Icons/Icons";
import Popover from "../../../../atomic/Popover/Popover";
import { MenuItem } from "@mui/material";
import { DateFilteMenuListProps } from "../../preset.filter.types";

const DateFilterMenuList: React.FC<DateFilteMenuListProps> = ({
  isOpenDateFilteMenu,
  onCloseMenuList,
  anchorEl,
  anchorOrigin,
  transformOrigin,
  menuList,
  selectedItemIndex,
  onClickMenuListItem,
}) => {
  return (
    <>
      <Popover
        id={"datelist-popover"}
        open={isOpenDateFilteMenu}
        onClose={onCloseMenuList}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {menuList?.map((item, index: number) => {
          return (
            <MenuItem key={item.key} value={item.value} selected={index === selectedItemIndex} onClick={() => onClickMenuListItem(item.value)}>
              <Box>{index === selectedItemIndex && <CheckList />}</Box>
              <span>{item.label ?? item.value}</span>
            </MenuItem>
          );
        })}
      </Popover>
    </>
  );
};

export default DateFilterMenuList;

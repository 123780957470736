import React from "react";
import { Phone } from "../../../../../../library/Icons/Icons";
import PrimaryActivity from "../../PrimaryActivity/PrimaryActivity";
import { PhoneProps } from "../ActivityItemVariantTypes";
import * as Labels from "../../../../../../../constants/config";

// primary activity
const PhoneActivity: React.FC<PhoneProps> = (props) => {
  return <PrimaryActivity {...{ ...props, icon: <Phone />, creator: { ...props.creator, message: Labels.HEADER_PHONE_CALL } }} />;
};

export default PhoneActivity;

import React, { Dispatch, SetStateAction } from "react";
import Dialog from "../../../../../library/Dialog/Dialog";
import DialogActions from "../../../../../library/DialogActions/DialogActions";
import DialogContent from "../../../../../library/DialogContent/DialogContent";
import DialogContentText from "../../../../../library/DialogContentText/DialogContentText";
import DialogTitle from "../../../../../library/DialogTitle/DialogTitle";
import Button from "../../../../../library/Button/Button";
import { useTranslation } from "react-i18next";

type SpamFraudModalProps = {
  connectionSpam: boolean;
  showModal: boolean;
  closeModal: Dispatch<SetStateAction<boolean>>;
  handleSpamFraud: () => void;
};

export default function SpamFraudModal(props: SpamFraudModalProps): React.ReactElement {
  const { t } = useTranslation();
  const spamTitleText = t("modalAndFlyout.spamFraudActivity.spamDialogTitle", { ns: "activities" });
  const fraudTitleText = t("modalAndFlyout.spamFraudActivity.fraudDialogTitle", { ns: "activities" });
  const spamContentText = t("modalAndFlyout.spamFraudActivity.spamDialogMessage", { ns: "activities" });
  const fraudContentText = t("modalAndFlyout.spamFraudActivity.fraudDialogMessage", { ns: "activities" });
  const markSpam = t("modalAndFlyout.buttons.markSpam", { ns: "activities" });
  const markFraud = t("modalAndFlyout.buttons.markFraud", { ns: "activities" });

  const title = props.connectionSpam ? spamTitleText : fraudTitleText;
  const content = props.connectionSpam ? spamContentText : fraudContentText;
  const primaryButtonLabel = props.connectionSpam ? markSpam : markFraud;
  const secondaryButtonLabel = t("modalAndFlyout.buttons.cancel", { ns: "activities" });

  return (
    <Dialog open={props.showModal} onClose={() => props.closeModal(false)}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className="mt-ba mb-lg">
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="primary" onClick={() => props.handleSpamFraud()}>
          {primaryButtonLabel}
        </Button>
        <Button className="ml-auto" variant="secondary" onClick={() => props.closeModal(false)}>
          {secondaryButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/*eslint-disable no-magic-numbers*/
import { styled } from "@mui/system";
import { pxToRem } from "../../../theme/theme";

export const ActionButtonWrapper = styled("div", {
  name: "action",
  slot: "root",
})({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: pxToRem(16),
  "@keyframes blink": {
    "0%": {
      opacity: 1,
    },
    "50%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
  "& .table-action-icon-blink": {
    animation: `blink 1s infinite linear`,
    cursor: "default",
  },
});

export const ActionContainer = styled("div", {
  name: "action",
  slot: "root",
})({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  "& .table-action-icon": {
    cursor: "pointer",
    "& svg": {
      height: pxToRem(20),
    },
  },
});

export const FilesCountContainer = styled("div", {
  name: "files-count",
  slot: "root",
})({
  cursor: "pointer",
  "& .files-label-active": {
    color: "#2D9DE7",
    fontWeight: "500",
  },
});

export const ElipsisActionWithIcon = styled("div", {
  name: "elipsis-elenment-with-icon",
  slot: "root",
})({
  display: "flex",
  flexDirection: "row",
  gap: "0.25rem",
  alignItems: "center",
});

export const ContactWrapper = styled("div", {
  name: "contact-wrapper",
  slot: "root",
})({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyItems: "center",
  gap: "1rem",
  "& div": {
    alignItems: "center",
    backgroundColor: "#dee1ed",
    borderRadius: "50%",
    display: "flex",
    height: "2.2rem",
    justifyContent: "center",
    width: "2.2rem",

    "& svg": { height: "1.125rem", width: "1.125rem" },
  },
});

export const SubjectAttachmentWrapper = styled("div", { name: "subject-attachment-wrapper", slot: "root" })({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  marginTop: "1.125rem",
  "& .attachments-container": {
    display: "flex",
    gap: "0.5rem",
    "& .attachment-show-more-chip ": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "0.125rem 0.5rem",
      background: "white",
      width: "4rem",
      height: "1.75rem",
      border: "1px solid #BDC3DB",
      borderRadius: "0.5rem",
      cursor: "pointer",
      "&:hover": {
        background: "#F0F0F0",
      },
    },
    "& .attachment-chip": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "0.125rem 0.5rem",
      gap: "0.25rem",
      background: "white",
      width: "8rem",
      height: "1.75rem",
      border: " 1px solid #BDC3DB",
      borderRadius: " 0.5rem",
      flex: "none",
      cursor: "pointer",
      flexGrow: "0",
      "&:hover": {
        background: "#F0F0F0",
      },
      "& svg": {
        height: pxToRem(12),
        width: pxToRem(12),
      },
    },
  },
});

export const TextWithCaption = styled("div", { name: "text-with-caption", slot: "root" })({
  display: "flex",
  flexDirection: "column",
  gap: "0.25rem",
  color: "#4c4c4c",
});

export const TableFormattedCell = styled("div", { name: "table-formatted-cell", slot: "root" })({
  display: "inline-block",
  verticalAlign: "middle",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",

  "> .snoozed-activity-formatted-cell": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "0.5rem",

    "> .body4": {
      color: " #4c4c4c",
    },
  },
  "&.light": {
    fontWeight: "400",
  },
  "&.bold": {
    fontWeight: "500",
    color: "black",
    "> .email-content": {
      fontWeight: "400",
      color: " #4c4c4c",
    },
  },
  "> p": {
    margin: "0",
  },
  "&.unread": {
    ".subject": {
      fontWeight: "500",
      color: " #000000",
    },
    ".body": {
      fontWeight: "400",
      color: " #4c4c4c",
    },
  },
  "&.read": {
    ".subject": {
      fontWeight: "400",
      color: " #1a1a1a",
    },
    ".body": {
      fontWeight: "400",
      color: "#576375",
    },
  },

  span: {
    "&.light": {
      color: " #4c4c4c",
      fontWeight: "400",
    },
  },
  "&.read-bold": {
    fontWeight: "400",
    color: " #1a1a1a",
    "> .email-content": {
      fontWeight: "400",
      color: "#576375",
    },
  },
  "&.contact-bold": {
    fontWeight: "400",
    color: " #000000",
  },
  "> .icon-wrapper": {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transform: "translateY(-50%)",
    "&.in-line": {
      width: "2rem",
      height: "2rem",
      borderRadius: "50%",
      backgroundColor: "#dee1ed",
      "> svg": {
        fill: "#a1adc4",
      },
    },
  },
  "> .user-pic": {
    alignItems: "center",
    justifyContent: "center",
    transform: "translateY(-50%)",
    width: "2rem",
    height: "2rem",
    borderRadius: "50%",
    backgroundColor: "#dee1ed",
    "> svg": {
      fill: "#a1adc4",
    },
  },
  "> .user-name": {
    paddingLeft: "3.25rem",
    paddingTop: "0.5rem",
  },
  "&-flag": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",

    "&-icon": {
      width: "1rem",
      height: "1rem",
      marginRight: "0.5rem",
    },
    "&-title": {
      fontFamily: "Helvetica",
      fontSize: "  0.875rem",
      fontWeight: "400",
      lineHeight: "1rem",
      letterSpacing: "0.25rem",
      textAlign: "center",
    },
  },
  "& .purple": {
    " > span": {
      color: "#7d4ed8",
    },
    "svg > path": {
      fill: "#7d4ed8",
    },
  },
  "& .orange": {
    "> span": {
      color: "#f38923",
    },
    "svg > path": {
      fill: "#f38923",
    },
  },
  "& .green": {
    "> span": {
      color: "#24b14b",
    },
    "svg > path": {
      fill: "#24b14b",
    },
  },
});

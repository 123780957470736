import APIClient from "../APIClient";
import { AxiosRequestConfig } from "axios";

const PLATFORM_API_BASE = process.env.REACT_APP_API_ENDPOINT_PLATFORM_V1;
const INBOX_API_BASE = `${process.env.REACT_APP_API_ENDPOINT_INBOX_API_PATH}${process.env.REACT_APP_API_ENDPOINT_INBOX_API_V2}`;
const CONFIG_OVERRIDE = {
  baseURL: process.env.REACT_APP_API_ENDPOINT_INBOX,
} as AxiosRequestConfig;

export class DocumentsClient {
  /**
   * GET on /api/v1/Attachments/{id}/download
   * @param id - The unique ID number of the Attachment whose URI will be returned
   * @returns - a download link
   */
  download(id: string): Promise<UriModel> {
    const url = `${PLATFORM_API_BASE}/Attachments/${id}/download`;
    return APIClient.get(url).then((res) => res.data);
  }

  downloadUrl(id: string): Promise<UriModel> {
    const url = `${PLATFORM_API_BASE}/Attachments/${id}/download-url`;
    return APIClient.get(url).then((res) => res.data);
  }
  /**
   * DELETE on /api/v1/Attachments/{id}
   * @param id - The unique ID number of the Attachment being marked as archived.
   * @returns - a download link
   */
  archive(id: string): Promise<ActionResultModel> {
    const url = `api/v1/Attachments/${id}`;
    return APIClient.delete(url).then((res) => res.data);
  }
  /**
   * POST on /api/v1/Attachments/{id}
   * @param id - The unique ID number of the Attachment being marked as archived.
   * @returns - a download link
   */
  upload(tableName: string, objectId: string, file: File, attachmentType?: string): Promise<ActionResultModel> {
    const url = `${PLATFORM_API_BASE}/Attachments`;
    const formData = new FormData();
    formData.append("files", file);

    const options = {
      params: { tableName, objectId, attachmentType },
    };
    return APIClient.post(url, formData, options).then((res) => res.data);
  }

  uploadToV2(file: File, options: any): Promise<any> {
    const url = `${INBOX_API_BASE}/attachments/`;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("connection_id", options.connection_id);
    formData.append("attachment_type", options.attachmentType);
    formData.append("workspace_id", options.workspace_id);
    return APIClient.post(url, formData, { ...options, ...CONFIG_OVERRIDE }).then((res) => res.data);
  }
}

import React, { Dispatch, SetStateAction } from "react";
import Dialog from "../Dialog/Dialog";
import DialogActions from "../DialogActions/DialogActions";
import DialogContent from "../DialogContent/DialogContent";
import DialogContentText from "../DialogContentText/DialogContentText";
import DialogTitle from "../DialogTitle/DialogTitle";
import Button from "../Button/Button";
import { Exclamation } from "../Icons/Icons";
import "./styles.scss";
import { useTranslation } from "react-i18next";

type DiscardDraftModal = {
  showModal: boolean;
  closeModal: Dispatch<SetStateAction<boolean>>;
  onDiscard: () => void;
  className?: string;
};

export default function DiscardDraftModal(props: DiscardDraftModal): React.ReactElement {
  const { t } = useTranslation();
  return (
    <Dialog open={props.showModal} className={`discard-draft-modal ${props?.className}`} onClose={() => props.closeModal(false)}>
      <DialogTitle className="dialogue-title">
        <Exclamation></Exclamation>
        {t("modalAndFlyout.discardDraft.dialogTitle", { ns: "activities" })}
      </DialogTitle>
      <DialogContent className="mt-ba mb-lg">
        <DialogContentText className="dialogue-message">{t("modalAndFlyout.discardDraft.dialogContent", { ns: "activities" })}</DialogContentText>
      </DialogContent>
      <DialogActions className="action-bar">
        <Button className="ml-auto " variant="secondary" onClick={() => props.closeModal(false)}>
          {t("modalAndFlyout.buttons.goBack", { ns: "activities" })}
        </Button>
        <Button variant="primary" onClick={props?.onDiscard}>
          {t("modalAndFlyout.buttons.discard", { ns: "activities" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

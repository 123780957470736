export const TransactionTypes: { [key: string]: string } = Object.freeze({
  "ar invoice": "Invoice",
  "ap invoice": "Bill",
  "ar credit memo": "Credit Memo",
  "ap credit memo": "Credit Memo",
  "ar payment": "Payment",
  "ap payment": "Payment",
});

export const StatusLabels: { [key: string]: string } = Object.freeze({
  Open: "Open",
  "Past Due 1-30": "Past Due 1-30",
  "Past Due 31-60": "Past Due 31-60",
  "Past Due 61-90": "Past Due 61-90",
  "Past Due 91+": "Past Due 91+",
  Closed: "Closed",
  Rejected: "Rejected",
});

export const ActivityStatusLabels: { [key: string]: string } = Object.freeze({
  active: "Active",
  closed: "Closed",
  waiting_for_response: "Waiting For Response",
  snoozed: "Snoozed",
  accepted: "Accepted",
  refused: "Refused",
});

export const EInvoiceStatusLabels: { [key: string]: string } = Object.freeze({
  received_by_platform: "Received by Platform",
  rejected: "Rejected",
  made_available: "Made Available",
  in_hand: "In Hand",
  refused: "Refused",
  approved: "Approved",
  disputed: "Disputed",
  partially_approved: "Partially Approved",
  suspended: "Suspended",
  payment_sent: "Payment Sent",
});

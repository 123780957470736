import { styled } from "@mui/system";
import { Avatar, Input, ListItem, Skeleton } from "@mui/material";
import Popover from "../../../../atomic/Popover/Popover";
import { DropdownSearchFilterVariant } from "../../preset.filter.types";

export const DropdownSearchPopupContainer = styled(Popover)<DropdownSearchFilterVariant>(({ dropdownSearchVariant }) => ({
  "& .MuiPaper-root": {
    display: "flex",
    flexDirection: "column",
    minHeight: "3.25rem",
    maxHeight: "27.375rem",
    width: dropdownSearchVariant === "large" ? "29.75rem" : "18.75rem",
    boxShadow: `0 0.5rem 0.75rem rgba(0, 0, 0, 0.1), 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1)`,
    borderRadius: "0.5rem",
    padding: "0 0.625rem 0.625rem 0.625rem",
    gap: "0.75rem",
    marginTop: "1.25rem",
    ".MuiMenuItem-root": {
      height: "max-content",
      minHeight: "2.25rem",
      maxHeight: "4.375rem",
      padding: "0.5rem",
    },

    ".MuiListSubheader-root": {
      padding: "0",
    },
  },
}));

export const SearchInputField = styled(Input)({
  height: "2.25rem",
  width: "100%",
  padding: "0.5rem 0.5rem 0.5rem 0.25rem",
  gap: "0.75rem",
  background: "#f7f7fb",
  borderRadius: "0.5rem",
  ".MuiInputAdornment-root": {
    height: "1.25rem",
    minWidth: "1.25rem",
    padding: "0",
    margin: "0",
    alignItems: "center",
    justifyContent: "center",
    ".MuiSvgIcon-root": {
      fontSize: "0.875rem",
      fill: "#576375",
    },
  },
  ".MuiInputAdornment-positionEnd": {
    cursor: "pointer",
  },
  ".MuiInput-input": {
    fontSize: "0.875rem",
    fontWeight: "400",
    lineHeight: "1.25rem",
  },
});

export const SelectedMenuItemsContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
});

export const SuggestionMenuItemsContainer = styled(SelectedMenuItemsContainer)({
  ".MuiMenuItem-root": {
    padding: "0.5re 0.5rem 0.5rem 2.25rem",
  },
});

export const HeaderLabel = styled("div")({
  fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  "Helvetica Neue", sans-serif`,
  fontWeight: "500",
  fontSize: "0.75rem",
  lineHeight: "1rem",
  color: "#576375",
});

export const ListItemContainer = styled(ListItem)({
  padding: "0",
  overflow: "hidden",
  gap: "0.75rem",
  ".MuiListItemAvatar-root": {
    height: "2.5rem",
    minWidth: "2.5rem",
    ".MuiAvatar-root": {
      fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif`,
      fontWeight: "600",
      fontSize: "1rem",
      lineHeight: "1.5rem",
      color: "#143b52",
      background: "#FFFFFF",
      border: "0.03125rem solid #143b52",
      textTransform: "capitalize",
    },
  },
  ".MuiListItemText-root": {
    display: "flex",
    flexDirection: "column",
    gap: "0.125rem",
    ".MuiTypography-root": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      ".container": {
        ".default-styles-p": {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
    },
    ".MuiListItemText-primary": {
      fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif`,
      fontWeight: "400",
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      color: "#1a1a1a",
    },
    ".MuiListItemText-secondary": {
      fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif`,
      fontWeight: "500",
      fontSize: "0.75rem",
      lineHeight: "1rem",
      color: "#707a8f",
    },
  },
});

export const AttachmentContainer = styled("div")({
  display: "flex",
  gap: "0.75rem",
});
export const AttachmentChips = styled("div")({
  display: "flex",
  border: "0.0625rem solid #bdc3db",
  borderRadius: "0.5rem",
  height: "1.75rem",
  minWidth: "4.0625rem",
  maxWidth: "8rem",
  gap: "0.125rem",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "0.75rem",
  lineHeight: "1rem",
  color: "#124564",
  fontWeight: "400",
  padding: "0.125rem 0.5rem",
  background: "#FFFFFF",
  svg: {
    minWidth: "0.75rem",
    height: "0.75rem",
  },
  ".pdf-text": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
});

export const AvatarSkeletonContainer = styled(Avatar)({
  background: "none",
  marginLeft: "1.625rem",
  marginRight: "0.6875rem",
  ".MuiSkeleton-root": {
    width: "2.5rem",
    height: "2.5rem",
  },
});

export const PrimaryTextSkeleton = styled(Skeleton)({
  width: "5.9375rem",
  height: "0.875rem",
  marginBottom: "0.3125rem",
});

export const SecondaryTextSkeleton = styled(Skeleton)({
  width: "8rem",
  height: "0.875rem",
});

export const SubjectSkeletonContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "1.1875rem",
  marginLeft: "1.8125rem",
  ".primary-skeleton": {
    width: "18.625rem",
    height: "0.875rem",
  },
  ".subject-skeleton-root": {
    display: "flex",
    flexDirection: "column",
    gap: "0.4375rem",
    ".primary": {
      width: "18.625rem",
      height: "0.875rem",
    },
    ".attachment-container": {
      display: "flex",
      gap: "0.75rem",
      ".attachment": {
        width: "8rem",
        height: "1.75rem",
        borderRadius: "0.4375rem",
      },
      ".more-attachment": {
        width: "4.3125rem",
        height: "1.75rem",
        borderRadius: "0.4375rem",
      },
    },
  },
});

export const EmptySuggestionMessageContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  height: "9.375rem",
  width: "17.5rem",
  gap: "0.5rem",
  justifyContent: "center",
  alignItems: "center",
  padding: "0 0.625rem",
  alignSelf: "center",

  ".header-text": {
    fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif`,
    fontWeight: "600",
    fontSize: "1.125rem",
    lineHeight: "1.5rem",
    textAlign: "center",
  },
  ".subheader-text": {
    fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif`,
    fontWeight: "400",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    textAlign: "center",
    color: "#707A8F",
  },
});

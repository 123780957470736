// Docs: https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript-react-plugin
// https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

const reactPlugin = new ReactPlugin();

let appInsights: ApplicationInsights | null = null;
const instrumentationKey = process.env.REACT_APP_APP_INSIGHTS_KEY;
if (instrumentationKey) {
  const browserHistory = createBrowserHistory({ basename: "" });
  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: instrumentationKey,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
    },
  });
  appInsights.loadAppInsights();
}

export { reactPlugin, appInsights };

import React from "react";

type VisibilityModes = "open" | "close" | "toggle";

/** Generic return type - enable typeahead options */
type ElementVisibility<T> = {
  visibility: T;
  dispatch: React.Dispatch<{ type: VisibilityModes; payload: keyof T }>;
};

/**
 * This hook is implemented to reduce number of visibility states
 * for multiple UI elements and consolidate the events with a single
 * state to -
 * 1. provide a better interface for managing the visibility states
 * 2. (if required) easily track and manage the side-effects of events
 * occuring on UI
 *
 * These states can include, multiple main screen overlays such as -
 * activity popup, flyouts, modals or any other element of similar kind.
 *
 * Hook also provides type binding to enable payload typeahead options while
 * preparing dispatch. To enable these pass the type of initialVisibility
 * to the hook's constructor call. Checkout more details in the example
 * below.
 *
 * @param initialVisibility object of visibility states/flags
 * @returns common visibility state & a dispatcher to update
 * the common state object
 *
 * @example
 *
 * here we have defined initial state for 3 elements:
 *
 * const initialVisibility = {
 *   markConnection: false,
 *   snoozeTooltip: false,
 *   markConnectionTooltip: false,
 *   reassign: false,
 * };
 * const { visibility, dispatch } = useElementVisibilityHandler<typeof initialVisibility>(initialVisibility);
 *
 * a state update can be dispatched with close, open or toggle action:
 * ...
 * dispatch({ type: "close", payload: "markConnectionTooltip" });
 * ...
 *
 * visibility state can be used to render UI:
 * ...
 * open={visibility.markConnection}
 * ...
 */
function useHandleVisibility<T>(initialVisibility: T): ElementVisibility<T> {
  /** TYPE DECLARATIONS */
  type State = typeof initialVisibility;
  type Action = { type: VisibilityModes; payload: keyof typeof initialVisibility };

  /**
   * reducer function recognizes only available Visibility modes mentioned
   * above.
   */
  function reducer(state: State, action: Action): State {
    switch (action.type) {
      case "open":
        state = { ...state, [action.payload]: true };
        return state;
      case "close":
        state = { ...state, [action.payload]: false };
        return state;
      case "toggle":
        state = { ...state, [action.payload]: !state[action.payload] };
        return state;
      default:
        return state;
    }
  }
  const [visibility, dispatch] = React.useReducer<typeof reducer>(reducer, initialVisibility);

  return { visibility, dispatch };
}

export default useHandleVisibility;

import React from "react";
import SecondaryActivity from "../../SecondaryActivity/SecondaryActivity";
import { SecondaryType } from "../../SecondaryActivity/SecondaryActivityTypes";
import { MarkedSenderProps } from "../ActivityItemVariantTypes";
import * as Labels from "../../../../../../../constants/config";
import { AuditLogType } from "../../../../../../../types/enums";

const MarkedSender: React.FC<MarkedSenderProps> = ({ currentStatus: connectionStatus, creator, countryCode }) => {
  const auditMessage =
    connectionStatus === AuditLogType.SpamConnection
      ? Labels.HEADER_SPAM_SENDER
      : connectionStatus === AuditLogType.UnspamConnection
      ? Labels.HEADER_UNSPAM_SENDER
      : connectionStatus === AuditLogType.FraudConnection
      ? Labels.HEADER_FRAUD_SENDER
      : connectionStatus === AuditLogType.UnfraudConnection
      ? Labels.HEADER_UNFRAUD_SENDER
      : "N/A";

  return <SecondaryActivity variant={SecondaryType.Base} creator={{ ...creator, message: auditMessage }} countryCode={countryCode} />;
};

export default MarkedSender;

import React from "react";
import AccordionNav from "../../../library/AccordionMegaMenuNav/AccordionNav";
import { AppContext } from "../../../../contexts/AppContext";
import { MegamenuDashboard, MegamenuConnections, Transactions, Inbox } from "../../../library/Icons/Icons";
import { WorkspaceContext } from "../../../../contexts/WorkspaceContext";
import { FallbackTypes, WorkspaceType } from "../../../../types/enums";
import useSidebar, { OptionType } from "./menuAccordion.configs";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FeatureFlagContext, FeatureFlagProviderType } from "../../../../contexts/FeatureFlagContext";
import { RefetchIntervals } from "../../../../constants/CacheConfig";
import { activitiesClientV2 } from "../../../../db/version2Accessor";
import "./MenuAccordion.scss";

const iconProvider = (key: string) => {
  return {
    dashboard: <MegamenuDashboard />,
    connections: <MegamenuConnections />,
    transactions: <Transactions />,
    inbox: <Inbox />,
  }[key];
};

export default function MainContentSidebar(): React.ReactElement {
  const { isEnabled, updateFlag } = React.useContext(FeatureFlagContext) as FeatureFlagProviderType;
  const { syncError } = React.useContext(AppContext) as AppType;
  const sidebar = useSidebar();
  const { workspaceHomePath, selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const workspaceMenu: OptionType[] = sidebar.fetchMenu(selectedWorkspace?.workspace_type_route?.toLocaleLowerCase() as WorkspaceType);

  const [activeAccordion, setActiveAccordion] = React.useState<string | false>(false);

  const changeActiveAccordion = (_event: React.SyntheticEvent, isExpanded: boolean, activeAccordion: string) => {
    setActiveAccordion(isExpanded ? activeAccordion : false);
  };

  /**
   * This function can be removed later on when maintanence mode is revamped
   * @param error
   */
  const onMetaError = (error: AxiosError) => {
    updateFlag("MAINTANANCE_MODE", true);
    if (error && isEnabled("MAINTANANCE_MODE")) {
      window.location.reload();
    }
  };

  /**
   * @constant cachedActivityCount
   * Activity Count cache key setup
   * Cache key being scoped on workspace_type, id
   */
  const cachedActivityCount = [`${selectedWorkspace?.workspace_type}-${selectedWorkspace?.id}`];
  const { data: activityCountData } = useQuery(
    cachedActivityCount,
    async () => activitiesClientV2.getActivityStreamMeta(selectedWorkspace?.id || FallbackTypes.Id),
    { refetchInterval: RefetchIntervals.megaMenu, onError: onMetaError }
  );

  return (
    <div className="menu-accordion-wrapper">
      {!syncError &&
        workspaceMenu.map((section: OptionType) => {
          return (
            <AccordionNav
              title={section.title}
              listItems={sidebar.parseMenuAccordianOptions(section.listItems, iconProvider, activityCountData?.data?.count)}
              toParser={(value) => `${workspaceHomePath}/${value.id}`}
              icon={iconProvider(section.icon)}
              paths={section.path}
              className={section.className}
              key={`${section.title.toLocaleLowerCase().split(" ").join("-")}-accordian-nav`}
              activeAccordion={activeAccordion}
              changeActiveAccordion={changeActiveAccordion}
              setActiveAccordion={setActiveAccordion}
            />
          );
        })}
    </div>
  );
}

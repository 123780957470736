import { styled } from "@mui/system";
import { styled as muiStyled } from "@mui/material/styles";
import { Box, MenuList } from "@mui/material";

export const CommandCenterContainer = muiStyled(Box, {
  name: "command-center-container",
  slot: "root",
})({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "51rem",
  height: "27rem",
  borderRadius: "1rem",
  outline: "none",
});

export const CommandCenterHeader = styled("div", {
  name: "command-center-header",
  slot: "root",
})({
  display: "flex",
  alignItems: "center",
  width: "100%",
  height: "3.5rem",
  background: "#000000",
  color: "#ffffff",
  borderTopLeftRadius: "1rem",
  borderTopRightRadius: "1rem",
  padding: "1rem 1.5rem",
  fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
  fontWeight: "400",
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
  "& .command-center-title": {
    display: "flex",
    alignItems: "center",
    gap: "0.75rem",
    fontWeight: "400",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    svg: {
      cursor: "pointer",
    },
  },
  "& .command-center-close": {
    marginLeft: "auto",
    cursor: "pointer",
  },
});

export const CommandCenterBody = styled("div", {
  name: "command-center-body",
  slot: "root",
})({
  width: "100%",
  height: "calc(100% - 3.5rem)",
  background: "#ffffff",
  color: "#000000",
  borderBottomLeftRadius: "1rem",
  borderBottomRightRadius: "1rem",
  overflowY: "scroll",
});

export const CommandCenterActionsView = styled("div", {
  name: "command-center-search-input",
  slot: "root",
})({
  height: "100%",
  overflowY: "hidden",
});

export const CommandCenterActionsList = muiStyled(MenuList, {
  name: "command-center-search-input",
  slot: "root",
})({
  height: "100%",
  overflowY: "scroll",
  paddingBottom: "5rem",
  "& .cc-primary-menu-item": {
    display: "flex",
    alignItems: "center",
    padding: "1.125rem 1.625rem",
    fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
    fontWeight: "500",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    "& .keyboard-shortcut-text": {
      display: "flex",
      alignItems: "center",
      background: "#4d4d4d",
      borderRadius: "0.125rem",
      color: "#ffffff",
      fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
      fontWeight: "400",
      fontSize: "0.75rem",
      lineHeight: "0.875rem",
      height: "1rem",
      padding: "0.125rem 0.25rem",
    },
  },
});

export const CommandCenterSearchInputContainer = styled("div", {
  name: "command-center-search-input",
  slot: "root",
})({
  display: "flex",
  alignItems: "center",
  padding: "1.125rem 1.625rem",
  fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
  fontWeight: "500",
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
  boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.1), 0px 4px 20px rgba(0, 0, 0, 0.1);",
  "& .MuiInput-root": {
    width: "100%",
  },
});

export const CommandCenterShortcutMessageBox = styled("div", {
  name: "command-center-shortcut-message-box",
  slot: "root",
})({
  position: "fixed",
  margin: "0 auto",
  bottom: "-2rem",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "#1C4DA5",
  width: "32rem",
  minHeight: "3rem",
  maxHeight: "3.5rem",
  borderRadius: "1rem 1rem 0 0",
  fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
  fontWeight: "500",
  fontSize: "0.875rem",
  lineHeight: "1.125rem",
  color: "#ffffff",
  padding: "0.875rem 1.875rem",
  textAlign: "center",
  "& .shortcut": {
    width: "1rem",
    height: "1rem",
    background: "#ffffff",
    color: "#4D4D4D",
    fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`,
    fontWeight: "400",
    fontSize: "0.75rem",
    lineHeight: "1rem",
    padding: "0 0.25rem",
    borderRadius: "0.125rem",
  },
});

/*eslint-disable no-magic-numbers*/
import { ActivityStatusChipsComponentType } from "./activitystatuschips.types";
import { EInvoiceStatusLabels } from "../../constants/string.constants";
import Typography from "@mui/material/Typography";
import { StateLabelChip } from "../../../../Activities/EInvoiceDetail/EInvoiceFeed/EInvoiceFeedItem/PrimaryActivity/StateLabelChip/StateLabelChip";
import React from "react";

const EInvoiceStatusChips: React.FC<ActivityStatusChipsComponentType> = ({
  chipStatus,
  chipConfig,
  translationObject,
}: ActivityStatusChipsComponentType) => {
  const getChipText = (displayText: string | null) => {
    if (displayText) {
      if (translationObject?.i18n.exists(`table.chips.${displayText?.split(" ")?.join("_")?.toLowerCase()}`, { ns: "einvoices" })) {
        return (
          <Typography variant={chipConfig?.fontVariant || "captionReg"}>
            {translationObject?.t
              ? translationObject?.t(`table.chips.${displayText?.split(" ")?.join("_")?.toLowerCase()}`, { ns: "einvoices" })
              : displayText}
          </Typography>
        );
      } else {
        return <Typography variant={chipConfig?.fontVariant || "captionReg"}>{displayText}</Typography>;
      }
    }
    return "-";
  };

  const getFormattedChipLabelForEInvoice = (status: string) => {
    const key = status?.split(" ")?.join("_")?.toLowerCase();
    if (status && EInvoiceStatusLabels[key]) {
      return EInvoiceStatusLabels[key];
    }
    return status?.length && status !== "-" ? status : null;
  };

  const getStatusChip = (status: string) => {
    const chipLabel = getFormattedChipLabelForEInvoice(status);
    if (chipLabel) {
      return <StateLabelChip variant={status?.split(" ")?.join("_")?.toLowerCase() as any}>{getChipText(chipLabel)}</StateLabelChip>;
    }
    return "-";
  };

  return <>{getStatusChip(chipStatus)}</>;
};

export default EInvoiceStatusChips;

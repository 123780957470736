import React from "react";
import { RowSelectionHook } from "../types/hooks.types";
import { NUMERIC_VALUES } from "../../../../../constants/NumericConstants";

/*
 * Checkbox Selection - Single + Multiple
 */
const useRowSelection: RowSelectionHook = (totalRecords?: number) => {
  const [tableRecords, setTableRecords] = React.useState<{
    allRecords: { id: string; isSelected?: boolean }[];
    selectedRecordsIds: string[];
    selectedRecords: { id: string; isSelected?: boolean }[];
  }>({
    allRecords: [],
    selectedRecordsIds: [],
    selectedRecords: [],
  });
  const [isAllRecordsSelected, setIsAllRecordsSelected] = React.useState<boolean>(false);
  const hasAllRecordsSelectedButtonClicked = React.useRef(false);

  //update tableData
  const updateTableRecords = (
    rows: { id: string; isSelected?: boolean }[]
  ): {
    allRecords: { id: string; isSelected?: boolean }[];
    selectedRecordsIds: string[];
    selectedRecords: { id: string; isSelected?: boolean }[];
  } => {
    const selectedRows = rows.filter((data) => data?.isSelected);
    const selectedIds = selectedRows.map((data) => data?.id);
    return { allRecords: rows, selectedRecords: selectedRows, selectedRecordsIds: selectedIds };
  };

  //reset the table records
  const resetTableRecords = () => {
    setIsAllRecordsSelected(false);
    hasAllRecordsSelectedButtonClicked.current = false;
    setTableRecords((prevRecords) => {
      const newRecords = prevRecords.allRecords?.map((record) => {
        return { ...record, isSelected: false };
      });
      return { allRecords: newRecords, selectedRecords: [], selectedRecordsIds: [] };
    });
  };

  //add new rows to the tableData
  const addNewTableData = (rows: { id: string; isSelected?: boolean }[]) => {
    const newData = [...(tableRecords?.allRecords ?? [])];
    rows.forEach((row) => {
      const existingRowIndex = newData.findIndex((dataRow) => dataRow.id === row.id);

      if (existingRowIndex !== NUMERIC_VALUES.CONSTANT_NEGATIVE_ONE) {
        const existingRow = newData[existingRowIndex];
        row.isSelected = existingRow.isSelected; // Keep the existing selected state
        newData[existingRowIndex] = row; // Update the existing row with new data
      } else {
        row.isSelected = isAllRecordsSelected || hasAllRecordsSelectedButtonClicked?.current;
        newData.push(row);
      }
    });

    setTableRecords(updateTableRecords(newData));
  };

  //handle click for select all checkbox
  const onClickSelectAllCheckbox = (rowsId: string[], checked: boolean) => {
    setTableRecords((prevRecords) => {
      const newRecords = prevRecords.allRecords?.map((record) => {
        if (rowsId?.includes(record.id)) {
          return { ...record, isSelected: checked };
        }
        return record;
      });
      const updatedData = updateTableRecords(newRecords);
      const isAllSelected =
        updatedData.selectedRecords?.length > NUMERIC_VALUES.CONSTANT_ZERO && updatedData.selectedRecords?.length === totalRecords;
      setIsAllRecordsSelected(isAllSelected);
      if (updatedData.selectedRecords?.length === totalRecords) {
        hasAllRecordsSelectedButtonClicked.current = isAllSelected;
      }
      return updatedData;
    });
  };

  //handle click for rows
  const onClickTableRow = (rowId: string) => {
    setTableRecords((prevRecords) => {
      const newRecords = prevRecords.allRecords?.map((record) => {
        if (rowId === record.id) {
          return { ...record, isSelected: !record.isSelected };
        }
        return record;
      });
      const updatedData = updateTableRecords(newRecords);

      /** If user has clicked selected all records and then deselect/select any then mark
       * isAllRecordsselected true , if selected records length and all fetched records length are equal.
       */
      if (hasAllRecordsSelectedButtonClicked?.current) {
        setIsAllRecordsSelected(
          updatedData.selectedRecords?.length > NUMERIC_VALUES.CONSTANT_ZERO && updatedData.selectedRecords?.length === updatedData.allRecords?.length
        );
      } else {
        setIsAllRecordsSelected(
          updatedData.selectedRecords?.length > NUMERIC_VALUES.CONSTANT_ZERO && updatedData.selectedRecords?.length === totalRecords
        );
      }

      return updatedData;
    });
  };

  // handle click for select all and clear all records
  const onClickSelectAndClearAllRecords = (hasSelectAllClicked: boolean) => {
    setIsAllRecordsSelected(hasSelectAllClicked);
    hasAllRecordsSelectedButtonClicked.current = hasSelectAllClicked;
    setTableRecords((prevRecords) => {
      const newRecords = prevRecords.allRecords?.map((record) => {
        return { ...record, isSelected: hasSelectAllClicked };
      });
      return updateTableRecords(newRecords);
    });
  };

  const getSelectedRowsCount = (): number => {
    const totalRecordsCount = totalRecords ?? NUMERIC_VALUES.CONSTANT_ZERO;
    const deselectedCount = tableRecords.allRecords?.length - tableRecords.selectedRecordsIds?.length;
    if (isAllRecordsSelected) {
      return totalRecordsCount;
    } else {
      return hasAllRecordsSelectedButtonClicked?.current ? totalRecordsCount - deselectedCount : tableRecords.selectedRecordsIds?.length;
    }
  };

  return {
    addNewTableData,
    onClickSelectAllCheckbox,
    onClickTableRow,
    onClickSelectAndClearAllRecords,
    tableRecords,
    isAllRecordsSelected,
    resetTableRecords,
    hasAllRecordsSelectedButtonClicked,
    getSelectedRowsCount,
  };
};

export default useRowSelection;

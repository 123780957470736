import React from "react";
import _ from "underscore";
import { AutomateActivity, Ellipse } from "../../../../../../library/Icons/Icons";
import { SmartText } from "../../../../../../library/SmartText";
import { BaseActivityProps } from "../SecondaryActivityTypes";
import "./BaseActivity.scss";
import { DEFAULT_NUMERIC_VALUES } from "../../../../../../../constants/NumericConstants";
import { formatDate, getDisplayDateFormat } from "../../../../../../../db/utils/date";

const BaseActivity: React.FC<BaseActivityProps> = ({ creator, flags: ACTION_FLAGS, headerActions, countryCode }) => {
  return (
    <div className="details-wrapper">
      <div className="left">
        <SmartText title={creator.name ?? ""} className="creator" />
        {!_.isNull(creator.message) && (
          <>
            <div className="separator">
              <Ellipse />
            </div>
            {ACTION_FLAGS?.TIME_SAVING_INDICATOR && (
              <div className="time-wrapper">
                <AutomateActivity />
              </div>
            )}
            <SmartText title={creator.message} className="message" />
          </>
        )}
      </div>
      <div className="right">
        {!_.isUndefined(headerActions) && headerActions?.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && headerActions}
        <div className="date">{formatDate(creator.createdOn, getDisplayDateFormat(countryCode ?? ""))}</div>
      </div>
    </div>
  );
};

export default BaseActivity;

/*eslint-disable*/
import React from "react";
import { styled } from "@mui/system";
import { pxToRem } from "../../theme/theme";
import { MenuItem as MuiMenuItem } from "@mui/material";

export const MenuItem = styled(MuiMenuItem, {
  name: "menu-item",
  slot: "root",
})({
  fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif`,
  fontWeight: 400,
  fontSize: pxToRem(14),
  lineHeight: pxToRem(20),
  margin: 0,
  minHeight: `${pxToRem(40)} !important`,
  color: "#143b52",
  backgroundColor: "#ffffff",
  " &:hover": {
    backgroundColor: "#f2f3f8",
    fontWeight: 500,
  },
  "&:active": {
    backgrounsColor: "#e8eaf2",
  },
  " &.Mui-selected": {
    fontWeight: 500,
  },
}) as typeof MuiMenuItem;

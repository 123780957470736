import React from "react";
import { AlertContext } from "../../../contexts/AlertContext";
import { documentsClient } from "../../../db/accessor";
import UploaderComponent from "./UploaderComponent";
import { UploadContainerInterface } from "./UploaderInterfaces/UploaderInterfaces";

const UploaderContainer: React.FC<UploadContainerInterface> = ({
  attachmentType,
  children,
  labelProps,
  onFileUploadRun,
  objectId,
  workspaceId,
  open,
  tableName,
  toggleFunction,
  origin,
  customerId,
  setParentLoader,
}: UploadContainerInterface) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [file, setFile] = React.useState<File | undefined>();
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;

  const resetStates = () => {
    setFile(undefined);
  };

  const handleClose = () => {
    if (open) {
      resetStates();
      toggleFunction();
      if (setParentLoader) {
        setParentLoader(true);
      }
    }
  };

  const getUploadedFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFile(event.target?.files?.[0]);
  };

  const uploadFileToPlatformAPI = () => {
    if (file) {
      let toastOptions: ToastOptions = { open: true, severity: "success", message: "File successfully uploaded!" };
      setLoading(true);
      documentsClient
        .upload(tableName, objectId, file, attachmentType)
        .then((response) => {
          if (response && onFileUploadRun) {
            onFileUploadRun(true, false);
          }
        })
        .catch((err) => {
          console.error(err);
          toastOptions = { ...toastOptions, severity: "error", message: "File upload failed! Please try again." };
        })
        .finally(() => {
          setToastOptions(toastOptions);
          handleClose();
          setLoading(false);
        });
    }
  };

  const uploadFileToConnection = () => {
    if (file) {
      let toastOptions: ToastOptions = { open: true, severity: "success", message: "File successfully uploaded!" };
      setLoading(true);
      documentsClient
        .uploadToV2(file, { connection_id: customerId, attachmentType: attachmentType, workspace_id: workspaceId })
        .then((response) => {
          if (response && onFileUploadRun) {
            onFileUploadRun(true, false);
          }
        })
        .catch((err) => {
          console.error(err);
          const errorReason = err?.response?.data?.messages?.errors?.[0];
          toastOptions = {
            ...toastOptions,
            severity: "error",
            message: `File upload failed! Please try again. \n${errorReason ? `Reason: ${errorReason}` : ""}`,
          };
        })
        .finally(() => {
          setToastOptions(toastOptions);
          handleClose();
          setLoading(false);
        });
    }
  };

  return (
    <UploaderComponent
      file={file}
      open={open}
      getUploadedFile={getUploadedFile}
      loading={loading}
      toggleFunction={handleClose}
      labelProps={labelProps}
      uploadFileToPlatformAPI={origin && origin == "connections" ? uploadFileToConnection : uploadFileToPlatformAPI}
    >
      {children}
    </UploaderComponent>
  );
};

export default UploaderContainer;

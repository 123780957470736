import { ActivitiesClient } from "./version2Clients/ActivitiesClient";
import { EmailsClient } from "./version2Clients/EmailsClient";
import { AttachmentsClient } from "./version2Clients/AttachmentsClient";
import { UserAccountsClient } from "./version2Clients/UserAccountsClient";
import { WorkspaceClient } from "./version2Clients/WorkspaceClient";
import { EnrollmentsClient } from "./version2Clients/EnrollmentsClient";
import { DashboardClient } from "./version2Clients/DashboardClient";
import { InvoicesClient } from "./version2Clients/InvoicesClient";
import { CompaniesClient } from "./version2Clients/CompaniesClient";
import { IndexClient } from "./version2Clients/IndexClient";
import { PaymentsClient } from "./version2Clients/PaymentsClient";
import { ContactsClient } from "./version2Clients/ContactsClient";
import { StatusClient } from "./version2Clients/StatusClient";
import { GlobalSearchClient } from "./version2Clients/GlobalSearchClient";
import { AutomationClient } from "./version2Clients/AutomationClient";
import { ReportsClient } from "./version2Clients/ReportsClient";
import { NotificationsClient } from "./version2Clients/NotificationsClient";
import { TemplatesClient } from "./version2Clients/TemplatesClient";
import { SignatureClients } from "./version2Clients/SignatureClients";
import { SettingsClient } from "./version2Clients/SettingsClient";
import { ExportClient } from "./version2Clients/ExportClient";
import { LocaleClient } from "./version2Clients/LocaleClient";

export const activitiesClientV2 = new ActivitiesClient();
export const emailsClientV2 = new EmailsClient();
export const attachmentsClientV2 = new AttachmentsClient();
export const userAccountsClientV2 = new UserAccountsClient();
export const workspaceClientV2 = new WorkspaceClient();
export const enrollmentsClientV2 = new EnrollmentsClient();
export const dashboardClientV2 = new DashboardClient();
export const invoicesClientV2 = new InvoicesClient();
export const companiesClientV2 = new CompaniesClient();
export const IndicesClient = new IndexClient();
export const PaymentsClientV2 = new PaymentsClient();
export const contactsClientV2 = new ContactsClient();
export const StatusClientV2 = new StatusClient();
export const globalSearchClientV2 = new GlobalSearchClient();
export const automationClientV2 = new AutomationClient();
export const reportsClientV2 = new ReportsClient();
export const NotificationsClientV2 = new NotificationsClient();
export const TemplatesClientV2 = new TemplatesClient();
export const SignatureClientsV2 = new SignatureClients();
export const settingsClient = new SettingsClient();
export const exportClient = new ExportClient();
export const localeClient = new LocaleClient();

/*eslint-disable no-magic-numbers*/
import { styled } from "@mui/system";

export type StateLabelProps = {
  variant:
    | "made_available"
    | "in_hand"
    | "approved"
    | "partially_appoved"
    | "suspended"
    | "disputed"
    | "payment_sent"
    | "payment_received"
    | "refused"
    | "complete";
};

export const StateLabelChip = styled("div")<StateLabelProps>(({ variant }) => {
  let color = "#BF360C";
  let bgColor = "#FFFDE7";
  if (variant === "approved" || variant === "complete") {
    color = "#0D47A1";
    bgColor = "#E3F2FD";
  } else if (variant === "made_available" || variant === "in_hand" || variant === "disputed") {
    color = "#BF360C";
    bgColor = "#FFFDE7";
  } else if (variant === "payment_received") {
    color = "#1B5E20";
    bgColor = "#E8F5E9";
  } else if (variant === "refused") {
    color = "#B71C1C";
    bgColor = "#FFEBEE";
  } else if (variant === "payment_sent" || variant === "partially_appoved") {
    color = "#BF360C";
    bgColor = "#FFF3E0";
  } else if (variant === "suspended") {
    color = "#B71C1C";
    bgColor = "#FFFDE7";
  }
  return {
    height: "24px",
    width: "fit-content",
    padding: "2px 6px",
    alignItems: "center",
    gap: "10px",
    borderRadius: "6px",
    background: bgColor,
    color: color,
    fontFamily: "'Sage UI'",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%",
    textTransform: "capitalize",
  };
});

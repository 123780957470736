import { AxiosError } from "axios";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import React, { useState, useEffect, ReactElement, useRef, useMemo } from "react";
import _ from "underscore";
import { ProfileRequest, SnoozedToast } from "../../../library/Icons/Icons";
import { useHistory, useParams, useLocation, useRouteMatch } from "react-router";
import {
  ActivityActionTypes,
  ActivityType as ActivityTypeEnum,
  ActivityStatus,
  FallbackTypes,
  AuditLogType,
  EmailAction,
  EmailType,
  ActivityOptions,
  AttachmentType,
  InlinePrompts,
  EmojiActionIds,
  EmojiOptions,
} from "../../../../types/enums";

import { CONNECTION_STATUS } from "../../../../constants/ConnectionConstants";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_TIMEOUTS, NUMERIC_VALUES, TEMPLATE_TIMING_CONSTANTS } from "../../../../constants/NumericConstants";
import { CUSTOM_ACTIVITY_FEED_CONSTANTS } from "../../../../constants/StyleConstants";
import { AlertContext } from "../../../../contexts/AlertContext";
import { AppContext } from "../../../../contexts/AppContext";
import { WorkspaceContext } from "../../../../contexts/WorkspaceContext";
import { activitiesClientV2, emailsClientV2, automationClientV2 } from "../../../../db/version2Accessor";
import Utils from "../../../../utils/utils";
import "./ActivityFeed.scss";
import { ActivityFeedItem, ActivityFeedItemProps } from "./ActivityFeedItem/ActivityFeedItemContainer";
import NewActivityDropdown from "../../../library/NewActivityCard/AddNewActivityDropdown";
import NewActivityPopup, { NewActivityPopupProps } from "../../../library/NewActivityCard/NewActivityCard";
import { ActivityContext } from "../../../../contexts/ActivityContext";
import InfiniteScroll from "react-infinite-scroll-component";
import { TemplateTypes, getViewTypeTemplateCodes, viewType } from "../../../../app/Templates/TemplateTypes";
import { TemplateDefinitionProps } from "../../../../app/Templates/TemplateFactory";
import { TemplateContext } from "../../../../contexts/TemplateContext";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import TrackingUtils from "../../../Tracking/Tracking.Utils";
import methods from "./helpers/ActivityFeedMethods";
import ActionBar from "./ActionBar/ActionBar";
import { companiesClient } from "../../../../db/accessor";
import ActivityFeedItemSkeleton from "./ActivityFeedItem/ActivityItemSkeleton/ActivityFeedItemSkeleton";
import useRefreshApprover from "../../../../hooks/useRefreshActivityPopupContent";
import { FeatureFlagContext, FeatureFlagProviderType } from "../../../../contexts/FeatureFlagContext";
import { useInfiniteQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { ReactionsDefault } from "../../../../constants/ReactionConstants";
import { RefetchIntervals } from "../../../../constants/CacheConfig";
import { DateTime } from "luxon";
import useLocale from "../../../../hooks/useLocale";
import ActivityMove from "./ActivityFeedActions/MoveActivity/MoveActivity";
import ActivitiesHeaderBanner from "../../ActivitiesHeaderBanner/ActivitiesHeaderBanner";
import TableUtils from "../../../../utils/TableUtils/TableUtils";
import useContacts from "../../../../hooks/useContacts";
import Copilot from "./Copilot/Copilot";
import { useTranslation } from "react-i18next";
import useHandleVisibility from "../../../../hooks/useHandleVisibility";
import { SettingsContext } from "../../../../contexts/SettingsContext";

type ActivityDropdownItem = {
  activityType: string;
  displayName: string;
  icon: ReactElement;
};

type ActivityFeedProps = {
  activityData: ActivityStreamItem;
  refreshActivity: () => void;
  addActivityDropdown: ActivityDropdownItem[];
  connectionStatus: string;
  onboardingInitiated: boolean | null | undefined;
  userView?: string;
  companyName: string;
  companyType: string;
  companyId: string;
  erpConnection: boolean;
  isRefetching: boolean;
};

type ReactionItem = {
  [key: string]: {
    count: number;
    givers: Array<{ name: string; id: string; user_id: number }>;
    highlight: boolean;
  };
};

/**
 * @function ActivityFeed
 * A react functional component to fetch an  activity-stream by its id and render it in an infinite-scroll view.
 * @param {ActivityFeedProps} props - The input props for the functional component
 * The activity feed is cached and served using useInfiniteQuery hook from react-query.
 * On component mount, cache is enabled by default to triggers fetch activity-stream feed API through the function fetchFeed
 * Response is cached using the constant cachedActivityFeedKey identifier and cache will be refreshed as per
 * time interval defined under constant RefetchInterval.activityStreamFeed
 * The response of function fetchFeed is monitored by function createFeed to populate the feed object.
 * Actions that add activity in the feed are appended into existing feed using useMutation hook from react-query
 * @see {@link https://tanstack.com/query/latest/docs/react/reference/useQuery | useQuery}
 * @see {@link https://tanstack.com/query/latest/docs/react/reference/useInfiniteQuery | useInfiniteQuery}
 * @see {@link https://tanstack.com/query/latest/docs/react/reference/useMutation | useMutation}
 */
export default function ActivityFeed(props: ActivityFeedProps): React.ReactElement {
  const { t } = useTranslation();
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const { userStatus } = React.useContext(AppContext) as AppType;
  const { getContactsOptions, allContactOptions } = useContacts();
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { handleClose, attachSelectedAllPayload, setActivityReactions, activityReactions, handleUnlistedEmailMove } = React.useContext(
    ActivityContext
  ) as ActivityType;
  const [enableTypeAheadSuggestions, setEnableTypeAheadSuggestions] = React.useState(false);
  const { customerId, activityId } = useParams<{ customerId: string; activityId: string }>();
  const { path } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const [retryEmailPopup, setRetryEmailPopup] = useState<boolean>(false);
  const [retryPopupValues, setRetryPopupValues] = useState<{ retryActivityId: string, reply: To[]; replyAll: To[]; replyAllCC: To[]; content: string }>();
  // reply stream
  const [NAComponentOptions, setNAComponentOptions] = useState<{ reply: To[]; replyAll: To[]; replyAllCC: To[]; replyAllBCC: To[]; nudgeTo: To[] }>();
  const [companyContactOptions, setCompanyContactOptions] = useState<To[]>([]);
  const [feed, setFeed] = useState<ActivityFeedItemProps[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [approvalReviewDrawer, setApprovalReviewDrawer] = useState<boolean>(false);
  const [approvalCancelModal, setApprovalCancelModal] = useState<boolean>(false);

  const [newActivityType, setNewActivityType] = useState<ActivityOptions | string | null>(null);
  const [fromTime, setFromTime] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [toTime, setToTime] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [showAddActivity, setShowAddActivity] = useState<boolean>(false);
  const [isNudgeActivity, setIsNudgeActivity] = useState<boolean>(false);
  const [showNewActivity, setNewActivityVisibility] = useState<boolean>(false);
  const [actionType, setActionType] = useState<string>("");
  const [activityItemId, setActivityItemId] = useState<string>();
  // reply stream item
  const [activityReplyOptions, setActivityReplyOptions] = useState<{ reply: To[]; replyAll: To[]; replyAllCC: To[] }>();
  const [activityToOptions, setActivityToOptions] = useState<ContactItem[]>();
  const [forwardActivityBody, setForwardActivityBody] = useState<string>("");
  const [forwardAttachments, setForwardAttachments] = useState<Array<AttachmentItem>>([]);
  // templateName > indicates the selected template code by the user
  const [templateName, setTemplateName] = useState<string>("");
  const [defaultTemplateId, setDefaultTemplateId] = useState<TemplateTypes | string>();
  const scrollRef = useRef<OverlayScrollbarsComponent>(null);
  const [isEmailPresent, setEmailPresent] = useState<boolean>(false);
  const [activityPageDetails, setActivityPageDetails] = React.useState({
    total_records: 0,
    page: 1,
    page_size: 20,
  });
  const [suggestedResponse, setSuggestedResponse] = useState<SuggestedResponseModel>({} as SuggestedResponseModel);
  const [reviewAction, setReviewAction] = useState<ApprovalReviewAction | undefined>();
  const [supportedTemplateList, setSupportedTemplateList] = useState<TemplateDefinitionProps[]>([]);
  const { templateFactory, prepareTemplateDataObject, handleTemplateAttachment, templateData } = React.useContext(TemplateContext) as ITemplateProps;
  const { loginUserCompany, signature } = React.useContext(CustomerContext) as CustomerType;
  const [company, setCompany] = useState<CompanyModel | null>(null);
  const [trackActivityTransactions, setTrackActivityTransactions] = useState<TransactionItemType[]>([]);
  const [approvalAttachments, setApprovalAttachments] = useState<AttachmentItem[]>([]);
  const [streamFlag, setStreamFlag] = useState<StreamFlag>();
  const { isEnabled } = React.useContext(FeatureFlagContext) as FeatureFlagProviderType;
  const toastSuccessTexts: { [key: string]: string } = useMemo(
    () => ({
      [ActivityOptions.ONBOARD_VENDOR]: t("addNewActivity.toastMessages.vendorOnboardSuccess", { ns: "activities" }),
      [ActivityOptions.APPROVAL_REQUEST]: t("addNewActivity.toastMessages.messageSent", { ns: "activities" }),
      [ActivityOptions.EMAIL]: t("addNewActivity.toastMessages.messageSent", { ns: "activities" }),
      [ActivityOptions.PHONE_CALL]: t("addNewActivity.toastMessages.messageSent", { ns: "activities" }),
      [ActivityOptions.SHARE_AP_PROFILE]: t("addNewActivity.toastMessages.apProfileSuccess", { ns: "activities" }),
      [ActivityOptions.SHARE_AR_PROFILE]: t("addNewActivity.toastMessages.arProfileSuccess", { ns: "activities" }),
      [ActivityOptions.REQUEST_CUSTOMER_PROFILE]: t("addNewActivity.toastMessages.requestedProfileSuccess", { ns: "activities" }),
    }),
    []
  );
  const [curentActivePopperActivityOrder, setActivePopperActivityOrder] = useState<number | null>(null);
  //The list of email activities of which the first needs to be shown in expanded state on load
  const primaryActivityVariantsList: Array<string> = [
    ActivityTypeEnum.Email,
    ActivityTypeEnum.Approval,
    ActivityTypeEnum.Forward,
    ActivityTypeEnum.IncomingEmail,
    ActivityTypeEnum.ApprovalRequest,
    ActivityTypeEnum.ApprovalReviewed,
    ActivityTypeEnum.Nudge,
  ];

  // queryClient to access and mutate cached ActivityFeed when needed
  const queryClient = useQueryClient();

  const isActivityReactionsSupportEnabled = isEnabled("EMOJI_REACTIONS");

  const [loadingReactions, setLoadingReactions] = React.useState(false);

  // approval request, approver auto population & template update setup
  const { toRef, popupRef, approver, setApprover } = useRefreshApprover();

  const lastApprover = props.activityData.primary_connection?.last_approver;
  const isLastApproverEligible =
    newActivityType === ActivityOptions.APPROVAL_REQUEST && !_.isUndefined(lastApprover) && !_.isEmpty(lastApprover) && _.has(lastApprover, "email");
  const lastApproverContact = {
    id: lastApprover?.email ?? "",
    label: lastApprover?.name ?? lastApprover?.email ?? "",
  };
  const { formatDateBasedOnCountry: formatDate, availableLanguagesForTemplates } = useLocale();
  const [isOpenSelectAndMoveActivity, setIsOpenSelectAndMoveActivity] = useState<boolean>(false);

  const toastContext = React.useContext(AlertContext) as AlertContextType;
  const [showCopilot, setShowCopilot] = useState<boolean>(false);
  const [copilotContent, setCopilotContent] = useState<string>("");
  const [firstPrimaryActivty, setFirstPrimaryActivty] = useState<ActivityFeedItemProps>();
  const { isCopilotEnabled } = React.useContext(SettingsContext) as SettingsType;
  /**
   * setup common state with reducer to handle open/close states for send and send and close button
   * React useState hooks.
   */
  const initialVisibility = {
    isSendBtnDisabled: false,
  };
  const { visibility, dispatch } = useHandleVisibility<typeof initialVisibility>({ isSendBtnDisabled: false });

  /**
   * @function getPrimaryActivity returns the first primary activity in feed
   */
  const getPrimaryActivity = () => {
    for (const feedItem of feed) {
      if (primaryActivityVariantsList.includes(feedItem.variant)) {
        return feedItem;
      }
    }
  };

  const onClickDownload = (
    event: React.MouseEvent<HTMLTableRowElement | HTMLDivElement | HTMLButtonElement | HTMLAnchorElement | HTMLSpanElement>,
    attachments: AttachmentItem[] | BlobAttachmentItem[]
  ) => {
    event.stopPropagation();
    TableUtils.downloadAll(attachments, toastContext?.setToastOptions);
  };

  /**
   * update approver if either activity type or new activity component
   * options are changed.
   */
  useEffect(() => {
    setApprover(isLastApproverEligible ? lastApproverContact : NAComponentOptions?.reply[0] ?? ({} as To));
  }, [newActivityType, NAComponentOptions]);

  const handleResend = async (id: string) => {
    let response = {} as APIResponse;
    let toastOptions = {
      open: true,
      severity: "success",
      message: t("toastMessages.forwardAutomationBill.success", { ns: "activities" }),
    } as ToastOptions;
    try {
      response = await activitiesClientV2.resend(selectedWorkspace?.id || FallbackTypes.Id, activityId, id);
      //eslint-disable-next-line
      getActivityStream(false, true, undefined, true);
    } catch (error: unknown) {
      response.success = false;
      toastOptions = {
        open: true,
        severity: "error",
        message: t("toastMessages.forwardAutomationBill.error", { ns: "activities" }),
      };
    } finally {
      setToastOptions(toastOptions);
    }
  };

  /**
   * @function refreshInboxMegamenuCount
   * A helper function to refetch the meta data for megamenu
   */
  const refreshInboxMegamenuCount = async () => {
    await queryClient.refetchQueries({ queryKey: [`${selectedWorkspace?.workspace_type}-${selectedWorkspace?.id}`], type: "active", exact: true });
  };

  const handleRead = async () => {
    let response = {} as APIResponse;
    try {
      response = await activitiesClientV2.markAsRead(selectedWorkspace?.id || FallbackTypes.Id, activityId);
    } catch (error: unknown) {
      response.success = false;
      console.log(error);
    } finally {
      refreshInboxMegamenuCount();
    }
  };

  const retryEmail = async (retryActivityId: string, to: ContactItem[], content: string) => {
    const recipients = to.map((contact: ContactItem) => {
      return {
        id: contact.email_address,
        label: contact?.name || contact.email_address,
      };
    }) as To[];
    await setRetryPopupValues({
      reply: recipients,
      replyAll: recipients,
      replyAllCC: recipients,
      retryActivityId,
      content,
    });
    setRetryEmailPopup(true);
    setNewActivityType(ActivityOptions.EMAIL);
  };

  const handleUnsnoozeStream = async () => {
    let toastOptions: ToastOptions = {
      open: true,
      severity: "info",
      message: t("toastMessages.markUnsnooze.success", { activitesCount: "", ns: "activities" }),
      icon: <SnoozedToast />,
    };
    const reqBody: ActivitiesStreamUpdateBody = {
      status: ActivityStatus.Active.toLocaleLowerCase() as Status,
    };
    try {
      const res = await activitiesClientV2.patch(selectedWorkspace?.id || FallbackTypes.Id, activityId, ActivityActionTypes.Status, reqBody);
      if (res.success) {
        //eslint-disable-next-line
        getActivityStream(false, false, undefined, true, true);
      }
    } catch (error) {
      toastOptions = {
        ...toastOptions,
        severity: "error",
        message: t("toastMessages.markUnsnooze.failed", { ns: "activities" }),
      };
      console.log(error);
    } finally {
      setToastOptions(toastOptions);
    }
  };

  const handleApprovalReview = (reviewAction: ApprovalReviewAction) => {
    if (reviewAction != "cancel") {
      // open drawer
      setApprovalReviewDrawer(true);
    } else {
      setApprovalCancelModal(true);
    }
    // pass the details of request and action taken
    setReviewAction(reviewAction);
  };

  /**
   * makes a call to dismiss_flag endpoint
   *
   * returns the update state - success/failure to the component
   * shows an error toast if updates goes wrong.
   * @param id activity stream item id
   * @param flag value of flag
   * @returns true/false
   */
  const handleDismissAutodetectedFlag = async (id: string, flag: string) => {
    let success = false;
    try {
      const res = await activitiesClientV2.dismissAutodetectedFlag(selectedWorkspace?.id || FallbackTypes.Id, activityId, id, flag);
      success = res.success;
      //eslint-disable-next-line
      refetchFeed();
    } catch (error) {
      console.error(error);
      setToastOptions({
        open: true,
        severity: "error",
        message: t("toastMessages.dismissAutodetectedFlag.error", { ns: "activities" }),
      });
      success = false;
    }
    return success;
  };

  /**
   * @function getEnhancedFeed
   * A helper function to enhance an activity feed with nudge feature if available but only for the first occurence
   * @param feed
   * @returns ActivityFeedItemProps[]
   */
  const getEnhancedFeed = (feed: ActivityFeedItemProps[]) => {
    if (feed?.length) {
      let nudgeActivityOccurence: number = DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
      let apAutomationOccurence: number = DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
      let profileShareOccurence: number = DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
      let firstPrimaryActivityOccurence: number = DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
      let snoozeActivityOccurence: number = DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
      let spamActivityOccurence: number = DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
      return feed.map((feedItem: ActivityFeedItemProps, order: number) => {
        if (!_.isEmpty(feedItem.flag)) {
          setStreamFlag(feedItem.flag);
        }
        nudgeActivityOccurence =
          feedItem.emailType === "nudge" ? nudgeActivityOccurence + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE : nudgeActivityOccurence;
        apAutomationOccurence =
          feedItem.variant === AuditLogType.ApAutomation ? apAutomationOccurence + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE : apAutomationOccurence;
        profileShareOccurence =
          feedItem.variant === AuditLogType.ProfileShare ||
          feedItem.variant === AuditLogType.OnboardRequest ||
          feedItem.variant === AuditLogType.ProfileRequest
            ? profileShareOccurence + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
            : profileShareOccurence;
        firstPrimaryActivityOccurence = primaryActivityVariantsList.includes(feedItem.variant)
          ? firstPrimaryActivityOccurence + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
          : firstPrimaryActivityOccurence;
        snoozeActivityOccurence =
          feedItem.variant === AuditLogType.Snoozed ? snoozeActivityOccurence + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE : snoozeActivityOccurence;
        spamActivityOccurence =
          feedItem.variant === AuditLogType.Spam ? spamActivityOccurence + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE : spamActivityOccurence;
        return {
          ...feedItem,
          ...(props?.activityData.status?.toLowerCase() === ActivityStatus.WaitingForResponse.toLowerCase()
            ? { isNudgeActivity: nudgeActivityOccurence === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE }
            : {}),
          isResendApplicable: apAutomationOccurence === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
          isShowPrimaryProfileShareOrAPAutomate: profileShareOccurence === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
          showAPOrARProfile: selectedWorkspace.workspace_type === "accounts_payable",
          isExpanded: firstPrimaryActivityOccurence === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE && primaryActivityVariantsList.includes(feedItem.variant),
          isSnoozeApplicable: snoozeActivityOccurence === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
          isSpamApplicable: spamActivityOccurence === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
          order,
        };
      });
    }
  };

  const handleNotSpam = async () => {
    let toastOptions: ToastOptions = { open: true, severity: "success", message: t("toastMessages.markAsNotSpam.success", { ns: "activities" }) };
    let response = {} as APIResponse;
    try {
      let reqBody: ActivitiesStreamUpdateBody = { spam: false };
      reqBody = attachSelectedAllPayload(reqBody);

      response = await activitiesClientV2.patch(selectedWorkspace?.id || FallbackTypes.Id, activityId, ActivityActionTypes.Spam, reqBody);
      //eslint-disable-next-line
      getActivityStream(false, false, undefined, true, true);
    } catch {
      response.success = false;
    } finally {
      if (!response.success) {
        toastOptions = {
          ...toastOptions,
          severity: "error",
          message: t("toastMessages.markAsNotSpam.error", { ns: "activities" }),
        };
      }
      setToastOptions(toastOptions);
    }
  };

  const getOriginalEmail = async (itemId: string) => {
    return await emailsClientV2.getActivity(selectedWorkspace?.id || FallbackTypes.Id, activityId, itemId);
  };

  const getStreams = (fetchResults: ActivityStreamFetchResponse): ActivityFeedItemProps[] => {
    const results = fetchResults?.data ?? [];
    return results.reduce((previousValue: any[], feedItem: ActivityItemModel & AuditLogItemModel, order) => {
      const activityStreamType = methods.evaluateVariant(feedItem);
      const to = methods.evaluateTo(feedItem);
      const from = methods.evaluateFrom(feedItem, userStatus);
      const content = methods.evaluateContent(feedItem) ?? "";
      const status = methods.evaluateStatus(feedItem) ?? ";";
      const header = methods.evaluateHeader(feedItem, activityStreamType, formatDate) ?? "";
      const useFrom = [
        ActivityTypeEnum.Note,
        ActivityTypeEnum.Move,
        ActivityTypeEnum.PhoneCall,
        AuditLogType.Snoozed,
        AuditLogType.Assign,
        AuditLogType.Close,
        AuditLogType.Spam,
        AuditLogType.FraudConnection,
        AuditLogType.SpamConnection,
        AuditLogType.UnspamConnection,
        AuditLogType.UnfraudConnection,
        AuditLogType.Unspam,
      ].includes(activityStreamType as ActivityTypeEnum | AuditLogType);
      /** Insert an activity item if type is new vendor & connection_via rule is false; to render onboard vendor */
      /**
       * TODO: add a new condition
       * if activity (current - current) time is < 24 hrs,
       * please remove this comment once done.
       */
      if (feedItem.auto_detected_flag === "new_vendor" && !props.onboardingInitiated && !feedItem?.activity_stream?.connection_via_rule) {
        previousValue.push({
          flag: feedItem.auto_detected_flag ?? "",
          variant: InlinePrompts.ONBOARD_VENDOR,
          setActionType,
          setNewActivityType,
          setActivityItemId,
          isFeatureEnabled: isEnabled ?? undefined,
          handleDismissAutodetectedFlag,
          // id of the stream item where flag is detected
          id: feedItem?.id,
          primaryConnection: feedItem?.activity_stream?.primary_connection,
          connectionViaRule: feedItem?.activity_stream?.connection_via_rule,
          markedConfirmed: feedItem?.activity_stream?.marked_confirmed,
        });
      }
      return previousValue.concat({
        ...header,
        primaryConnection: feedItem?.activity_stream?.primary_connection,
        to: useFrom ? from : to,
        date: Utils.evaluateDate(feedItem?.created_at ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO),
        content: feedItem?.content?.replace(/(\r\n|\n|\r)/gm, "") ?? "",
        isRead: feedItem?.is_read,
        isStreamSpam: feedItem?.activity_stream?.spam,
        variant: activityStreamType,
        creator:
          (feedItem?.type === ActivityTypeEnum.AuditLog && feedItem?.action_type === AuditLogType.Forward) ||
          feedItem?.action_type === AuditLogType.Snoozed ||
          feedItem?.action_type === AuditLogType.Unsnoozed ||
          feedItem?.action_type === AuditLogType.Assign ||
          feedItem?.action_type === AuditLogType.Unassign ||
          feedItem?.action_type === AuditLogType.MergeConnection ||
          feedItem?.action_type === AuditLogType.Move ||
          feedItem?.action_type === ActivityTypeEnum.Approval
            ? feedItem?.action_by
            : feedItem?.type === ActivityTypeEnum.IncomingEmail
            ? feedItem?.from
            : feedItem?.creator,
        attachments: feedItem?.attachments,
        emailType: feedItem?.email_type,
        originMessageContent: feedItem?.origin_message_content,
        id: feedItem?.id,
        cc: feedItem?.cc ?? feedItem?.cc,
        bcc: feedItem?.bcc ?? feedItem?.bcc,
        from: feedItem?.from,
        workspace: feedItem?.workspace,
        originalStreamId: feedItem?.origin_activity_stream_id,
        originalStreamItemId: feedItem?.origin_activity_id,
        preview:
          feedItem?.type === ActivityTypeEnum.AuditLog && feedItem?.action_type === AuditLogType.MergeConnection ? content : feedItem?.preview ?? "",
        handleUnsnoozeStream: handleUnsnoozeStream,
        snoozedUntil: feedItem?.snooze_until ?? null,
        simple_content:
          feedItem?.type === ActivityTypeEnum.AuditLog && feedItem?.action_type === AuditLogType.MergeConnection
            ? content
            : feedItem?.simple_content ?? "",
        activityId: activityId,
        status: status,
        type: feedItem?.type,
        order,
        magicLink: feedItem?.magic_link_url,
        flag: feedItem?.auto_detected_flag,
        timeSaved: feedItem?.time_saved,
        handleApprovalReview: handleApprovalReview,
        getOriginalEmail: getOriginalEmail,
        handleNotSpam: handleNotSpam,
        assigneeName: feedItem?.to_user?.name ?? feedItem?.to_user?.email,
        handleResend,
        isFeatureEnabled: isEnabled ?? undefined,
        bounceErrorMsg: feedItem?.smtp_description ?? "",
        retried: feedItem?.retried ?? null,
        reactions: feedItem.reactions,
        handleDismissAutodetectedFlag,
        subject: feedItem?.subject ?? "",
        connectionViaRule: feedItem?.activity_stream?.connection_via_rule,
        markedConfirmed: feedItem?.activity_stream?.marked_confirmed,
        isAutoClosed: feedItem?.auto_closed,
        mergedToConnectionName: feedItem?.merge_info?.merged_into?.name ?? "",
        movedToConnectionName: feedItem?.moved_connection_name ?? "",
      });
    }, []);
  };

  /**
   * @function fetchCopilotResponse
   * Function to get the Suggested response for latest incoming activity
   * @returns ActivityStreamFetchResponse
   */
  const fetchCopilotResponse = async () => {
    const SuggestedAPIResponse = await activitiesClientV2.getSuggestedResponse(activityId);
    setSuggestedResponse(SuggestedAPIResponse.data);
    setShowCopilot(!_.isEmpty(SuggestedAPIResponse.data));
  };

  /**
   * @function fetchFeed
   * Function mapped to cache: cachedActivityFeedKey for fetching feed from API
   * @param pageParam
   * @returns ActivityStreamFetchResponse
   */
  const fetchFeed = async ({ pageParam = DEFAULT_NUMERIC_VALUES.DEFAULT_ONE }) => {
    const feedAPIResponse = await activitiesClientV2.getStream(selectedWorkspace?.id || FallbackTypes.Id, activityId, {
      page: pageParam,
      page_size: activityPageDetails.page_size,
      "qs[s]": "id desc",
    });
    return feedAPIResponse;
  };

  interface fetchMutatedFeedVariables {
    onActionPerform: boolean;
    explicitlySetPageSize: number;
  }

  /**
   * @function fetchMutatedFeed
   * Function mapped to mutator function: fetchOnActionPerform for updating feed with fetched items onActionPerformed
   * @param fetchMutatedFeedVariables
   * @returns ActivityStreamFetchResponse
   */
  const fetchMutatedFeed = async ({
    onActionPerform = false,
    explicitlySetPageSize = DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
  }: fetchMutatedFeedVariables) => {
    setSuggestedResponse({} as SuggestedResponseModel);
    const mutatedFeedAPIResponse = await activitiesClientV2.getStream(selectedWorkspace?.id || FallbackTypes.Id, activityId, {
      page: onActionPerform ? DEFAULT_NUMERIC_VALUES.DEFAULT_ONE : activityPageDetails.page,
      page_size: onActionPerform ? explicitlySetPageSize : activityPageDetails.page_size,
      "qs[s]": "id desc",
    });
    //Call fetchCopilotResponse to refetch the latest copilot response
    fetchCopilotResponse();
    return mutatedFeedAPIResponse;
  };

  /**
   * @constant cachedActivityFeedKey
   * Activity Feed cache key setup
   * Cache key being scoped on workspace_type, id, activityId and activity-feed string
   */
  const cachedActivityFeedKey = [`${selectedWorkspace?.workspace_type}-${selectedWorkspace?.id}-${activityId}`, activityId, "activity-feed"];
  const {
    isLoading: cacheLoading,
    data: feedAPIResponse,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: [cachedActivityFeedKey],
    queryFn: fetchFeed,
    getNextPageParam: () => {
      if (feed?.length < activityPageDetails.total_records) {
        return activityPageDetails.page + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE;
      }
      return undefined;
    },
    refetchInterval: RefetchIntervals.activityStreamFeed,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  /**
   * @function createFeed
   * Function to iterate on cached data pages hashed with cachedActivityFeedKey to generate feed
   */
  const createFeed = () => {
    if (feedAPIResponse?.pages) {
      let feedRecords: ActivityFeedItemProps[] = [];
      feedAPIResponse.pages.forEach((page: ActivityStreamFetchResponse) => {
        const gettingStream: ActivityFeedItemProps[] = getStreams(page);
        const gettingEnhancedFeed = getEnhancedFeed([...feedRecords, ...gettingStream]) as ActivityFeedItemProps[];
        feedRecords = gettingEnhancedFeed;
      });
      const results = feedRecords ?? [];
      const latestEmail = results.filter((feedItem: any) => {
        return (
          feedItem.type === ActivityTypeEnum.Email ||
          feedItem.type === ActivityTypeEnum.IncomingEmail ||
          feedItem.type === ActivityTypeEnum.PhoneCall ||
          feedItem.type === ActivityTypeEnum.Approval
        );
      })[0];

      const isEmailPresent = results.filter(
        (feedItem: any) => feedItem.type === ActivityTypeEnum.Email || feedItem.type === ActivityTypeEnum.IncomingEmail
      );

      if (isEmailPresent.length) {
        setEmailPresent(true);
      }

      const replyOptions: any = methods.getReplyOptions(latestEmail);
      setApprovalAttachments(results.filter((feedItem: any) => feedItem.type === ActivityTypeEnum.Email)?.[0]?.attachments ?? []);

      setNAComponentOptions({
        reply: replyOptions.reply,
        replyAll: replyOptions.replyAll,
        replyAllCC: replyOptions.replyAllCC,
        replyAllBCC: replyOptions.replyAllBCC,
        nudgeTo: replyOptions.nudgeTo,
      });
      const params = history.location.state as any;
      if (params && params.itemId) {
        // if only itemId is present
        setTimeout(() => {
          const element = scrollRef.current;
          const instance = element?.osInstance();
          const ele = document.getElementById(params?.itemId);
          if (ele) {
            instance?.scroll(ele, NUMERIC_TIMEOUTS.ONE_SECOND);
          }
        }, NUMERIC_TIMEOUTS.ONE_SECOND);
      }
      setFeed(feedRecords);
      setActivityPageDetails({
        ...activityPageDetails,
        page: feedAPIResponse.pageParams.length,
        total_records:
          feedAPIResponse.pages[feedAPIResponse.pageParams.length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE]?.total_records ??
          activityPageDetails.total_records,
      });
      setLoading(false);
    }
  };

  /**
   * @function fetchOnActionPerform
   * Function to mutate the cached activity feed data when an action is performed
   */
  const { mutate: fetchOnActionPerform } = useMutation({
    mutationFn: fetchMutatedFeed,
    onSettled: () => {
      createFeed();
    },
    onSuccess: (mutatedFeedAPIResponse: any) => {
      const newPagesArray = feedAPIResponse?.pages ?? [];
      newPagesArray.forEach((page: ActivityStreamFetchResponse, index) => {
        if (index === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
          page.data = [...mutatedFeedAPIResponse.data, ...page.data];
        }
        page.total_records = mutatedFeedAPIResponse.total_records;
      });
      queryClient.setQueryData([cachedActivityFeedKey], {
        pages: newPagesArray,
        pageParams: feedAPIResponse?.pageParams,
      });
      setActivityPageDetails({
        ...activityPageDetails,
        // page: feedAPIResponse.pageParams.length,
        total_records: mutatedFeedAPIResponse.total_records,
      });
    },
  });

  /**
   * @function refetchFeed
   * Function to expose refetch functionality exposed by queryCache
   * that can be called when needed to refresh stream.
   * This also sets isLoading false to hide loader skeleton.
   */
  const refetchFeed = () => {
    refetch();
    setLoading(false);
  };

  /**
   * This function fetches and sets the activity feed.
   * To refresh stream with page 1, refreshStream should be provided
   * as true.
   *
   * @param withLoadingAnimation shows loading animation if true
   * @param onActionPerform shows whetherany activity action has been performed
   * @param explicitlySetPageSize defines the page size for the fetch
   * @param refreshStream refreshes the stream entirely
   */
  const getActivityStream = (
    withLoadingAnimation: boolean,
    onActionPerform = false,
    explicitlySetPageSize: number = DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
    refreshStream = false,
    refreshActivityDetail = false
  ) => {
    setLoading(withLoadingAnimation);
    let setPageNumber = refreshStream ? DEFAULT_NUMERIC_VALUES.DEFAULT_ONE : activityPageDetails.page;
    if (!onActionPerform) {
      if (refreshStream) {
        refetchFeed();
      }
    } else {
      if (explicitlySetPageSize >= activityPageDetails.page_size) {
        setPageNumber = DEFAULT_NUMERIC_VALUES.DEFAULT_ONE;
      } else {
        fetchOnActionPerform({ onActionPerform, explicitlySetPageSize });
      }
    }
    if (refreshActivityDetail) {
      props.refreshActivity();
    }
    setActivityPageDetails({
      ...activityPageDetails,
      page: onActionPerform ? setPageNumber : setPageNumber + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
    });
  };

  const onCloseApprovalReview = async () => {
    // close drawer
    setApprovalReviewDrawer(false);
    setApprovalCancelModal(false);
    // reset details of request and action taken
    setReviewAction(undefined);
    setActivityItemId(undefined);
  };

  const resetData = () => {
    setFeed([]);
    setActivityPageDetails({
      total_records: 0,
      page: 1,
      page_size: 20,
    });
  };

  const getActivityTypePayload = () =>
    isNudgeActivity
      ? EmailType.nudge
      : newActivityType && newActivityType === ActivityOptions.PHONE_CALL
      ? ActivityTypeEnum.PhoneCall
      : newActivityType === ActivityOptions.APPROVAL_REQUEST
      ? ActivityTypeEnum.Approval
      : newActivityType === ActivityOptions.ONBOARD_VENDOR
      ? ActivityTypeEnum.VendorOnboarding
      : newActivityType === ActivityOptions.SHARE_AP_PROFILE || newActivityType === ActivityOptions.SHARE_AR_PROFILE
      ? ActivityTypeEnum.AP_AR_SHARE_PROFILE
      : newActivityType === ActivityOptions.REQUEST_CUSTOMER_PROFILE
      ? ActivityTypeEnum.AR_REQUEST_PROFILE
      : newActivityType === ActivityOptions.NOTE
      ? ActivityTypeEnum.Note
      : !_.isEmpty(suggestedResponse)
      ? ActivityOptions.EMAIL
      : newActivityType;

  // check if template code is required as content-type
  const needTemplateCodeAsPayload = React.useMemo(
    () =>
      [
        TemplateTypes.AR_CREATE_CUSTOMER_PROFILE,
        TemplateTypes.AR_UPDATE_CUSTOMER_PROFILE,
        TemplateTypes.AP_CREATE_VENDOR_PROFILE,
        TemplateTypes.AP_CREATE_VENDOR_PROFILE,
      ].includes(templateName as TemplateTypes),
    [templateName]
  );

  const handleReplySend = async (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    retryActivityId: string,
    attachmentIds: AttachmentIds,
    inboxAttachments = [] as TransactionItemType[],
    disableSoftRefresh?: boolean,
    isSendAndClose?: boolean,
    fromCopilot = false,
    responseEdited = false
  ) => {
    setLoading(true);
    dispatch({ type: "open", payload: "isSendBtnDisabled" });
    const getEmailArray = (emailArray: To[]) => emailArray.filter((item) => item !== undefined).map((value: To) => value.id || value.label);
    let responseData: any = {};
    const sendSuccess = t("toastMessages.emailSend.success", { ns: "activities" });
    let toastOptions: ToastOptions = { open: true, severity: "success", message: sendSuccess };
    const transactionSelected = _.uniq([...inboxAttachments, ...trackActivityTransactions], "transaction_id");
    const contentBody = body?.replace("[View Profile Button]", "{View Profile Button}") ?? body;

    let payload: Partial<ActivityItemEmailModel> = {
      workspace_id: selectedWorkspace?.id || FallbackTypes.Id,
      to: getEmailArray(to),
      cc: getEmailArray(cc),
      bcc: getEmailArray(bcc),
      subject: title,
      content: contentBody,
      email_action: actionType ? actionType : isEmailPresent ? EmailAction.REPLY : EmailAction.NEW,
      // 42656
      content_type: methods.evaluateContentVariantPayload(newActivityType ?? "", needTemplateCodeAsPayload ? templateName : ""),
      connection_company_id: customerId ?? props.activityData.primary_connection?.id ?? "",
      attachments: attachmentIds.filter((item) => typeof item === "number"),
      activity_type: getActivityTypePayload(),
      activity_transactions: transactionSelected.filter(
        (item) => attachmentIds.indexOf(item.transaction_id) !== DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE
      ),
      attach_pdf: Utils.isThereAnyAttachment(attachmentIds, transactionSelected)
    };
    if(retryActivityId){
      payload.retry = true;
      payload.retry_activity_id = retryActivityId;
    }
    if (actionType === "forward") {
      payload = {
        origin_message_id: activityItemId,
        ...payload,
      };
    }
    await emailsClientV2
      .postActivities(selectedWorkspace?.id || FallbackTypes.Id, activityId, payload)
      .then(async (response: ActivityStream) => {
        //Only call Automation API when email is sent successfully and Template is selected
        if ((templateName || newActivityType === EmailAction.FORWARD_AP_AUTOMATION) && response.success) {
          let reqBody = {
            to_time: newActivityType === EmailAction.FORWARD_AP_AUTOMATION ? TEMPLATE_TIMING_CONSTANTS.ONE_MIN : toTime,
            automation_type: "email_templates",
            automation_sub_type: newActivityType === EmailAction.FORWARD_AP_AUTOMATION ? actionType : templateName,
            resource_type: "Activity::Email",
            resource_id: response.data.id,
          } as AutomateTimeModel;
          if (fromTime != DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO || newActivityType === EmailAction.FORWARD_AP_AUTOMATION) {
            reqBody = {
              ...{ from_time: newActivityType === EmailAction.FORWARD_AP_AUTOMATION ? TEMPLATE_TIMING_CONSTANTS.THIRTY_SEC : fromTime },
              ...reqBody,
            };
          }
          setToTime(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
          automationClientV2.post(selectedWorkspace?.id || FallbackTypes.Id, reqBody);
        }
        TrackingUtils.trackFSData(
          to,
          "Add Activity",
          actionType ? actionType : "Reply",
          isSendAndClose ?? false,
          allContactOptions,
          customerId ? "Customer" : props.userView ?? "",
          `${path}/${customerId ? "Customer" : props.userView ?? ""}`,
          newActivityType ?? "",
          body,
          fromCopilot,
          !_.isEmpty(suggestedResponse),
          responseEdited
        );
        setToastOptions({
          open: true,
          severity: "success",
          message: newActivityType ? toastSuccessTexts[newActivityType] ?? sendSuccess : sendSuccess,
        });
        setShowAddActivity(false);
        setNewActivityVisibility(false);
        setActionType("");
        setActivityItemId("");
        setIsNudgeActivity(false);
        if (retryEmailPopup) {
          setRetryEmailPopup(false);
        }
        if (!disableSoftRefresh) {
          //eslint-disable-next-line
          getActivityStream(true, true, undefined, undefined, true);
          setLoading(false);
        }
        setNewActivityType("");
        responseData = response;
        //Fetch latest contact (newly added) options to populate in activity pop up if user tries to reply on the same
        getContactsOptions();
        props.refreshActivity();
      })
      .catch((err: AxiosError) => {
        setLoading(false);
        toastOptions.severity = "error";

        if (err.response?.status === CONNECTION_STATUS.BAD_REQUEST_400.STATUS_CODE) {
          toastOptions = { ...toastOptions, severity: "error", message: t("toastMessages.emailSend.failed.statusCode400", { ns: "activities" }) };
        } else {
          const errorResponse = err.response as AxiosErrorResponseData;
          toastOptions = {
            ...toastOptions,
            message: errorResponse?.data?.messages?.errors[0] ?? t("toastMessages.emailSend.failed.otherError", { ns: "activities" }),
          };
        }
        setToastOptions(toastOptions);
      });
    dispatch({ type: "close", payload: "isSendBtnDisabled" });
    return responseData;
  };

  const handleReplySendClose = async (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    retryActivityId: string,
    attachmentIds: AttachmentIds,
    inboxAttachments: any,
    fromCopilot = false,
    responseEdited = false
  ) => {
    const replyResponseData = await handleReplySend(
      to,
      cc,
      bcc,
      title,
      body,
      retryActivityId,
      attachmentIds,
      inboxAttachments,
      true,
      true,
      fromCopilot,
      responseEdited
    );
    if (replyResponseData.success) {
      setNewActivityVisibility(false);
      setShowAddActivity(false);
      setIsNudgeActivity(false);
      setActivityItemId("");
      setActionType("");
      let toastOptions: ToastOptions = {
        open: true,
        severity: "success",
        message: t("toastMessages.emailSendAndClosed.success", { ns: "activities" }),
      };
      let response = {} as APIResponse;

      try {
        response = await handleClose(activityId, "");
      } catch (e: unknown) {
        response.success = false;
      } finally {
        if (!response.success) {
          toastOptions = {
            ...toastOptions,
            severity: "error",
            message: t("toastMessages.emailSendAndClosed.failed", { ns: "activities" }),
          };
        }

        /**
         * Set specific behaviour on closing an activity stream.
         * history.goBack() is used to navigate back the index from which activity stream has been opened.
         * Alternatively props.refreshActivity(); & await getActivityStream(true, true, NUMERIC_VALUES.CONSTANT_TWO);
         * can be used to refresh the current activity stream.
         */
        setToastOptions(toastOptions);
        setLoading(false);
        history.goBack();
      }
    }
  };

  const fectCompanyContacts = async (customerId: string) => {
    setCompanyContactOptions(await getContactsOptions(customerId));
  };

  useEffect(() => {
    if (props?.activityData?.status) {
      handleRead();
    }
  }, [props?.activityData?.status]);

  /**
   * if onboarding is initiated, prompt will be
   * removed from the activity stream.
   */
  useEffect(() => {
    if (props?.onboardingInitiated) {
      refetchFeed();
    }
  }, [props?.onboardingInitiated]);

  /**
   * Clear out pagination state and feed data
   * This triggers feed generation when data has been fetched by useInfiniteQuery
   * Added cleanup function to clear data on unmount
   */
  useEffect(() => {
    createFeed();
    setLoading(false);
    return () => {
      resetData();
    };
  }, [feedAPIResponse?.pages]);

  useEffect(() => {
    if (customerId) {
      fectCompanyContacts(customerId);
    }
  }, [location.pathname, activityId]);

  const setReplyOptions = async (item: ActivityFeedItemProps) => {
    try {
      const getReplyOptionsResult = methods.getReplyOptions(item);
      const replyOptions = { ...getReplyOptionsResult };
      // populate replyOptions
      setActivityReplyOptions({
        reply: replyOptions.reply,
        replyAll: replyOptions.replyAll,
        replyAllCC: replyOptions.replyAllCC,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setNewActivityVisibility(true);
      setShowAddActivity(false);
    }
  };

  useEffect(() => {
    const currentItem = feed.filter((item) => item.id === activityItemId)[0];
    if (currentItem) {
      setReplyOptions(currentItem);
      // set forward email body
      setActivityToOptions(currentItem.to);
      setForwardActivityBody(currentItem.content);
      setForwardAttachments(currentItem.attachments as Array<AttachmentItem>);
    }
  }, [activityItemId]);

  /**
   * Only show contacts relevant to the connection
   */
  let filteredContactOptions = allContactOptions;

  if (customerId) {
    filteredContactOptions = enableTypeAheadSuggestions ? [...companyContactOptions, ...allContactOptions] : companyContactOptions;
  }

  const typeAheadAction = (val: string) => {
    if (val.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      setEnableTypeAheadSuggestions(true);
    } else {
      setEnableTypeAheadSuggestions(false);
    }
  };

  const getLoadingSkeleton = () => (
    <>
      {[
        ...Array(
          Math.max(
            DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
            Math.floor(
              (window.innerHeight - CUSTOM_ACTIVITY_FEED_CONSTANTS.SKELETON_INNER_HEIGHT) / CUSTOM_ACTIVITY_FEED_CONSTANTS.SKELETON_INNER_DIVIDER
            )
          )
        ),
      ].map((_val, index: number) => (
        <ActivityFeedItemSkeleton key={index} />
      ))}
    </>
  );

  const handleNudge = () => {
    setNewActivityType(ActivityOptions.EMAIL);
    setShowAddActivity(true);
    setIsNudgeActivity(true);
  };

  /**
   * @function handleCopyNote
   * A helper function to copy note string from activity item to clipboard
   * @param note {String} - The note string
   */
  const handleCopyNote = (note: string) => {
    let toastOptions: ToastOptions = {
      open: true,
      severity: "success",
      message: t("toastMessages.copyNote.success", { ns: "activities" }),
    };
    navigator.clipboard
      .writeText(note)
      .catch(() => {
        toastOptions = {
          ...toastOptions,
          severity: "error",
          message: t("toastMessages.copyNote.failed", { ns: "activities" }),
        };
      })
      .finally(() => {
        setToastOptions(toastOptions);
      });
  };

  const getCompanyData = async (): Promise<CompanyModel> => {
    let companyToReturn = company;
    if (!company) {
      const companyModel: CompanyModel = await companiesClient.getCompany(userStatus.account_company_id as string, "Attachments");
      setCompany(companyModel);
      companyToReturn = companyModel;
    }
    return new Promise((resolve) => resolve(companyToReturn as CompanyModel));
  };

  /**
   * default contact options precedence:
   * If it's approval request activity -> check for last approver or else leave empty let user select
   * If it's nudge activity -> check for nudge to contact
   * If respective email contacts are not present -> populate reply option
   * from activity feed reply options
   */
  const getDefaultContact = () => {
    if (newActivityType === ActivityOptions.APPROVAL_REQUEST) {
      return isLastApproverEligible ? [lastApproverContact] : [];
    } else {
      return isNudgeActivity ? NAComponentOptions?.nudgeTo : NAComponentOptions?.reply ?? [];
    }
  };

  const handleTemplateAttachments = async (templateObj: any, setFileCallback: FunctionCall) => {
    const comapnyData = await getCompanyData();
    const allW9Documents: AttachmentModel[] = (comapnyData.attachments as AttachmentModel[]).filter(
      (attachment: AttachmentModel) => attachment.attachmentType === "W-9"
    );
    const allDcoumentsMeta = allW9Documents
      ?.filter((document) => !document.isArchived)
      ?.map((document: AttachmentModel) => {
        const documentMetaForTemplate = { name: `${document.fileName ?? "N/A"}`, id: document.attachmentId, extension: document.fileExt };
        return documentMetaForTemplate;
      });
    setTrackActivityTransactions([
      ...allDcoumentsMeta.map((document) => {
        return { transaction_id: document.id, transaction_type: AttachmentType.DOCUMENT };
      }),
    ]);
    const templateDataMapper = prepareTemplateDataObject(templateObj, null, allDcoumentsMeta, null);
    handleTemplateAttachment(templateObj, setFileCallback, templateDataMapper);
  };

  const getOptionsString = (to?: ContactItem[], replyAllCC?: To[]) => {
    const OptionsArray =
      to && to?.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
        ? to?.map((item) => item.name + ` &lt;${item.email_address}&gt;`)
        : replyAllCC?.map((item) => item.label + ` &lt;${item.id}&gt;`);
    return OptionsArray?.join(", ");
  };

  /**
   * getEmailDate function to determine which feed item is clicked on and return the body date accordingly
   * @returns String of Date Time formatted for email clicked
   */
  const getEmailDate = () => {
    const feedItem = feed.find((item) => item.id === activityItemId);
    const date = feedItem?.date ?? "";
    if (!_.isEmpty(date)) {
      const time = DateTime.fromFormat(date.split("T")[1], "hh:mm:ss").toFormat("hh:mm a");
      const formattedDate = formatDate(date);
      return `${formattedDate} ${time}`;
    }
    return "";
  };

  const getFormattedEmail = (content: string, reply: To[], to: ContactItem[], replyAllCC?: To[]) => {
    const dateTime = DateTime.now();
    //fallback date for current date incase we do not get any date from feed item
    const fallBackDate = `${formatDate(dateTime.toString())} ${dateTime.toLocaleString(DateTime.TIME_24_SIMPLE)}`;
    const date = getEmailDate();
    const fromName = props.activityData.status === "waiting_for_response" ? selectedWorkspace?.name : reply[0]?.label;
    const fromEmail = props.activityData.status === "waiting_for_response" ? selectedWorkspace?.email_address : reply[0]?.id;
    const fromLabel = t("activityPopupEditor.inputField.labels.from", { ns: "activities" });
    const dateLabel = t("activityPopupEditor.inputField.labels.date", { ns: "activities" });
    const subjectLabel = t("activityPopupEditor.inputField.labels.subject", { ns: "activities" });
    const toLabel = t("activityPopupEditor.inputField.labels.to", { ns: "activities" });
    const ccLabel = t("activityPopupEditor.inputField.labels.cc", { ns: "activities" });
    const blockquoteLabel = t("activityPopupEditor.blockquoteLabel", { ns: "activities" });

    return `
    <br>
    <br>
    ${signature?.email_signature || ""}
    <br>
    <blockquote style="font-size: 12px; margin:0px 0px 0px 0.8ex;border-left:1px solid rgb(204,204,204);padding-left:1ex; color:#20025A;">
    ------------ ${blockquoteLabel} ------------ <br>
    ${fromLabel}: ${fromName} &lt;${fromEmail}&gt;<br>
    ${dateLabel}: ${!_.isEmpty(date) ? date : fallBackDate}<br>

    ${subjectLabel}: ${props.activityData?.subject}<br>
    ${toLabel}: ${getOptionsString(to)}<br>
    ${replyAllCC && replyAllCC.length ? `${ccLabel}: ${getOptionsString([], replyAllCC)}<br>` : ""}
    ------------------------------------------
    <br>
    ${content}
    </blockquote>
    <br>`;
  };

  /**
   * Function which convert the template string to editor content state.
   *
   * @param {string} templateID The templateID selected by user from the subject drop down.
   * @param {EditorState} EditorState The setEditorState will update the email body.
   */
  const prepareActivityBodyByTemplateID = async (
    templateID: string | null,
    setEditorState: React.Dispatch<React.SetStateAction<string>>,
    lang: string,
    setFileCallback?: FunctionCall
  ) => {
    if (templateID) {
      setTemplateName(templateID);
      const { primary_connection } = props.activityData;
      const templateObj = templateData.get(selectedWorkspace?.id)?.get(lang)?.get(templateID);
      setFromTime(templateObj?.getFromTime);
      setToTime(templateObj?.getToTime);
      const getReplyOptionsResult = methods.getReplyOptions(firstPrimaryActivty);
      const replyOptions = { ...getReplyOptionsResult };
      const templateBody = templateObj?.parseTemplate({
        customer: primary_connection?.name || "{customer}",
        workspaceCompanyName: loginUserCompany?.companyName || "{AR Company Name}",
        emailAddress: loginUserCompany?.emailAddress || "",
        companyName: loginUserCompany?.companyName || "",
        phone: loginUserCompany?.phoneNumber || "",
        userName: `${userStatus?.user_name ?? "{Your Name}"}`,
        workspaceName: `${selectedWorkspace?.name}`,
        contactName: `${approver?.label ?? "{Recipient's Name}"}`,
        signature: signature?.email_signature || "",
        // map invoiceId data once new/existing bill flags are developed
        invoiceId: `{Bill Number}`,
        vendor: primary_connection?.name || "{vendor}",
        view_profile_button: "<span>[View Profile Button]</span>",
      });

      const emailTrailBody = getFormattedEmail(
        firstPrimaryActivty?.content ?? "",
        replyOptions?.reply ?? [],
        firstPrimaryActivty?.to ?? [],
        replyOptions?.replyAllCC ?? []
      );
      setEditorState(`${templateBody}${emailTrailBody}`);
      if (templateObj?.templateAttachment) {
        handleTemplateAttachments(templateObj, setFileCallback as FunctionCall);
      }
    }
  };

  /**
   * Function which help's to give template list for the subject drop down
   * based on view type.
   *
   * @param {Object} row The selected row record from table
   * @returns {TemplateDefinitionProps[]} The template definition array of objects.
   */
  const getTemplateDefinitionsByView = (): TemplateDefinitionProps[] => {
    const view =
      selectedWorkspace.workspace_type === "accounts_payable" ? viewType.AP_CONNECTION_VENDOR_ACTIVITY : viewType.AR_CONNECTION_CUSTOMER_ACTIVITIES;
    const { primary_connection } = props.activityData;
    return templateFactory.getTemplateDefinitionsByView(
      view,
      { customer: primary_connection?.name, vendor: primary_connection?.name, companyName: loginUserCompany?.companyName ?? "" },
      availableLanguagesForTemplates
    );
  };

  //formatCopilotContent function handles the attaching of email trail
  /**
   * @function formatCopilotContent
   * Function to attach the latest email thread to Copilot response
   * @param content Copilot response content
   * @returns void
   */
  const formatCopilotContent = () => {
    const primaryActivity = getPrimaryActivity();
    const getReplyOptionsResult = methods.getReplyOptions(primaryActivity);
    const replyOptions = { ...getReplyOptionsResult };

    const emailTrailContent = getFormattedEmail(
      primaryActivity?.content ?? "",
      replyOptions?.reply ?? [],
      primaryActivity?.to ?? [],
      replyOptions?.replyAllCC ?? []
    );
    setCopilotContent(`${suggestedResponse?.content} ${emailTrailContent}`);
  };

  const evaluateNewActivityProps = () => {
    const replyOptionStartMap = new Map();
    replyOptionStartMap.set(EmailAction.REPLY, "Reply");
    replyOptionStartMap.set(EmailAction.REPLY_ALL, "Reply All");
    //use this reply option when email recieved from third party like QBO and connection_via rule is true
    const whiteListedReplyOption = [
      {
        id: feed?.[0]?.primaryConnection?.reply_to,
        label: feed?.[0]?.primaryConnection?.reply_to,
      },
    ];
    let newActivityProps = {
      activityType: newActivityType ?? "email",
      open: showNewActivity,
      onClose: () => {
        setNewActivityVisibility(false);
        setActivityItemId("");
        setActionType("");
        setForwardActivityBody("");
        setForwardAttachments([]);
        setNewActivityType("");
      },
      setNewActivityType: setNewActivityType,
      addActivityDropdown: props.addActivityDropdown,
    } as NewActivityPopupProps;

    if (actionType === EmailAction.REPLY || actionType === EmailAction.REPLY_ALL) {
      newActivityProps = {
        ...newActivityProps,
        title: `Reply to ${props.activityData?.subject ?? ""}`,
        defaultTitle: `${props.activityData?.subject ?? ""}`,
        contactOptions: allContactOptions?.filter((v, i, a) => a.findIndex((t) => t?.label === v?.label) === i),
        defaultTo: activityReplyOptions?.reply ?? [],
        replyOptions: {
          replyButton: false,
          start: replyOptionStartMap?.get(actionType),
          reply:
            feed?.[0]?.connectionViaRule && feed?.[NUMERIC_VALUES.CONSTANT_ZERO]?.primaryConnection?.reply_to
              ? whiteListedReplyOption
              : activityReplyOptions?.reply ?? [],
          replyAll:
            feed?.[0]?.connectionViaRule && feed?.[NUMERIC_VALUES.CONSTANT_ZERO]?.primaryConnection?.reply_to
              ? whiteListedReplyOption
              : activityReplyOptions?.replyAll ?? [],
          replyAllCC: activityReplyOptions?.replyAllCC ?? [],
        },
        editorState: getFormattedEmail(
          forwardActivityBody,
          activityReplyOptions?.reply ?? [],
          activityToOptions ?? [],
          activityReplyOptions?.replyAllCC ?? []
        ),
        handleSend: handleReplySend,
        handleSendMarkClosed: handleReplySendClose,
      };
    } else if (actionType === EmailAction.FORWARD) {
      // forward
      newActivityProps = {
        ...newActivityProps,
        title: `Forward: ${props.activityData.subject ?? ""}`,
        defaultTitle: `Forward: ${props.activityData.subject ?? ""}`,
        editorState:
          getFormattedEmail(
            forwardActivityBody,
            activityReplyOptions?.reply ?? [],
            activityToOptions ?? [],
            activityReplyOptions?.replyAllCC ?? []
          ) ?? "",
        defaultFiles: (forwardAttachments ?? []) as any,
        defaultAttachmentsList: forwardAttachments ?? [],
        contactOptions: allContactOptions,
        handleSend: handleReplySend,
        handleSendMarkClosed: handleReplySendClose,
        forwarding: true,
      };
    }
    return newActivityProps;
  };

  const getLatestEmailContent = (): string => {
    const emailBody = feed.find((item) => primaryActivityVariantsList.includes(item.variant));
    const replyOptions = methods.getReplyOptions(emailBody);
    return getFormattedEmail(emailBody?.content ?? "", replyOptions?.reply ?? [], emailBody?.to ?? [], replyOptions?.replyAllCC ?? []) ?? "";
  };

  useEffect(() => {
    if (newActivityType === ActivityOptions.EMAIL) {
      setSupportedTemplateList(getTemplateDefinitionsByView());
      setDefaultTemplateId("");
    } else if (newActivityType === ActivityOptions.APPROVAL_REQUEST) {
      setSupportedTemplateList(
        templateFactory.getTemplateDefinitionsByView(
          selectedWorkspace.workspace_type === "accounts_payable" ? viewType.AP_APPROVAL_REQUEST_ACTIVITY : viewType.AR_APPROVAL_REQUEST_ACTIVITY,
          "",
          availableLanguagesForTemplates
        )
      );
      setDefaultTemplateId(
        selectedWorkspace.workspace_type === "accounts_payable" ? TemplateTypes.AP_APPROVAL_REQUEST : TemplateTypes.AR_APPROVAL_REQUEST
      );
    } else if (newActivityType === ActivityOptions.ONBOARD_VENDOR) {
      const templateObj: Array<TemplateDefinitionProps> = templateFactory.getTemplateDefinitionsByView(
        viewType.VENDOR_ONBOARD_REQUEST_ACTIVITY,
        { companyName: loginUserCompany?.companyName ?? "" },
        availableLanguagesForTemplates
      );
      setSupportedTemplateList(templateObj);
      setDefaultTemplateId(TemplateTypes.AP_VENDOR_PROFILE_REQUEST);
      setNewActivityVisibility(false);
      setShowAddActivity(true);
    } else if (newActivityType === ActivityOptions.SHARE_AP_PROFILE) {
      const templateObj: Array<TemplateDefinitionProps> = templateFactory.getTemplateDefinitionsByView(
        viewType.AP_SHARE_PROFILE_ACTIVITY,
        { companyName: loginUserCompany?.companyName ?? "" },
        availableLanguagesForTemplates
      );
      setSupportedTemplateList(templateObj);
      setDefaultTemplateId(TemplateTypes.AP_SHARE_PROFILE);
    } else if (newActivityType === ActivityOptions.SHARE_AR_PROFILE) {
      const templateObj: Array<TemplateDefinitionProps> = templateFactory.getTemplateDefinitionsByView(
        viewType.AR_SHARE_PROFILE_ACTIVITY,
        { companyName: loginUserCompany?.companyName ?? "" },
        availableLanguagesForTemplates
      );
      setSupportedTemplateList(templateObj);
      setDefaultTemplateId(TemplateTypes.AR_SHARE_PROFILE);
    } else if (newActivityType === ActivityOptions.REQUEST_CUSTOMER_PROFILE) {
      const templateObj: Array<TemplateDefinitionProps> = templateFactory.getTemplateDefinitionsByView(
        viewType.AR_CUSTOMER_PROFILE_REQUEST_ACTIVITY,
        { companyName: loginUserCompany?.companyName ?? "" },
        availableLanguagesForTemplates
      );
      setSupportedTemplateList(templateObj);
      setDefaultTemplateId(TemplateTypes.AR_CUSTOMER_PROFILE_REQUEST);
      setNewActivityVisibility(false);
      setShowAddActivity(true);
    }
    // reset template states
    else {
      setSupportedTemplateList([]);
      setDefaultTemplateId("");
    }
    // rest the selected template code
    setTemplateName("");
  }, [newActivityType, loginUserCompany?.companyName]);

  const getReactionBasedOnUpdate = (
    reaction: { [key: string | number]: ReactionItem },
    operation: string,
    reactionType: string,
    reactionId: string,
    userId?: number | undefined | null,
    id?: string,
    name?: string | undefined | null
  ) => {
    const newReactions = { ...reaction[reactionId] };
    if (operation === "add") {
      newReactions[reactionType].count += 1;
      newReactions[reactionType].givers.push({
        name: name ?? "N/A",
        user_id: userId ?? DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE,
        id: id ?? "",
      });
    } else {
      newReactions[reactionType].count -= 1;
      newReactions[reactionType].givers = activityReactions[reactionId][reactionType].givers.filter((giver) => giver.user_id !== userId);
    }
    const allReactions = { ...activityReactions, [reactionId]: newReactions };
    return allReactions;
  };

  const handleReactionUpdate = async (reactionType: EmojiOptions, selector: EmojiActionIds, activityId: string, id: string) => {
    try {
      if (userStatus.inbox_account_id && userStatus.inbox_user_id && activityId && id && selectedWorkspace?.id && reactionType && !loadingReactions) {
        if (
          activityReactions[id][reactionType].givers.filter((giver) => giver.user_id === userStatus?.inbox_user_id).length ===
          DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
        ) {
          setLoadingReactions(true);

          const res = await activitiesClientV2.postReactions(
            selectedWorkspace?.id,
            activityId,
            id,
            userStatus.inbox_account_id,
            userStatus.inbox_user_id,
            reactionType
          );
          setActivityReactions(
            getReactionBasedOnUpdate(activityReactions, "add", reactionType, id, userStatus?.inbox_user_id, res.data.id, userStatus?.user_name)
          );
          setLoadingReactions(false);
        } else if (selector === EmojiActionIds.tag) {
          setLoadingReactions(true);
          const reactionToBeRemoved = activityReactions[id][reactionType].givers.filter((giver) => giver.user_id === userStatus?.inbox_user_id)[0];
          await activitiesClientV2.deleteReactions(selectedWorkspace?.id, activityId, id, reactionToBeRemoved.id);
          setActivityReactions(getReactionBasedOnUpdate(activityReactions, "delete", reactionType, id, userStatus?.inbox_user_id));
          setLoadingReactions(false);
        }
      }
    } catch (e) {
      setToastOptions?.({
        open: true,
        severity: "error",
        message: t("toastMessages.reactionUpdate.failed", { ns: "activities" }),
      });
    }
  };

  const onClickSelectManually = () => {
    setIsOpenSelectAndMoveActivity(true);
  };

  const onClickConfirmAndMove = async () => {
    let toastOptions: ToastOptions = {
      open: true,
      severity: "success",
      message: t("toastMessages.moveActivity.success", { ns: "activities" }),
    };
    //if click on not now then no need to send confirmed in payload just send primaryConnectionId;
    const isConfirmed = _.isUndefined(feed?.[0]?.markedConfirmed) ? undefined : true;
    const primaryConnectionId = _.isUndefined(feed?.[0]?.markedConfirmed) ? feed?.[0]?.primaryConnection?.id : undefined;
    let response = {} as APIResponse;
    try {
      response = await handleUnlistedEmailMove(activityId, isConfirmed, primaryConnectionId);
    } catch (e) {
      console.error("Error:", e);
    } finally {
      if (!response.success) {
        toastOptions = {
          ...toastOptions,
          severity: "error",
          message: t("toastMessages.moveActivity.failed", { ns: "activities" }),
        };
      }
      getActivityStream(true, true);
      props.refreshActivity();
      setToastOptions(toastOptions);
    }
  };

  /* return banner content text , if markedConfirmed is undefined i.e not there in api response display Banner text for third
   * party email which is not matched with any erp data else return text for third party email which is matched with erp data.
   */
  const getBannerContentText = () => {
    if (_.isUndefined(feed?.[0]?.markedConfirmed)) {
      return (
        <>
          <span>{t("activityDetails.activitesHeaderBanner.erpDataNotExist.contentText.firstPart", { ns: "activities" })}</span>
          <br />
          <span>{t("activityDetails.activitesHeaderBanner.erpDataNotExist.contentText.lastPart", { ns: "activities" })}</span>
        </>
      );
    } else {
      return (
        <>
          <span>
            {t("activityDetails.activitesHeaderBanner.erpDataExist.contentText.firstPart", { ns: "activities" })}
            <b>{props.activityData?.primary_connection?.name ?? ""}</b>.
          </span>
          <br />
          <span>{t("activityDetails.activitesHeaderBanner.erpDataExist.contentText.lastPart", { ns: "activities" })}</span>
        </>
      );
    }
  };

  //This Function handles the no thanks on suggested response to show Reply draft
  const onDiscard = () => {
    setNewActivityVisibility(true);
    setActionType(EmailAction.REPLY);
    setNewActivityType?.(ActivityOptions.EMAIL);
    for (const feedItem of feed) {
      if (feedItem.variant === ActivityTypeEnum.IncomingEmail) {
        setReplyOptions(feedItem);
        setActivityItemId(feedItem.id);
        break;
      }
    }
  };

  //return {key:value} pair for template subject to parse the values
  const getSelectedTempSubjSnippetValues = () => {
    return {
      customer: props.activityData?.primary_connection?.name ?? "",
      companyName: loginUserCompany?.companyName ?? "",
    };
  };

  //return suggested template codes based on certain condition
  const getSuggestedTemplatesCode = () => {
    const arTemplates = getViewTypeTemplateCodes(viewType.AR_CONNECTION_CUSTOMER_ACTIVITIES);
    const apTemplates = getViewTypeTemplateCodes(viewType.AP_CONNECTION_VENDOR_ACTIVITY);
    const approvalTemplates = [
      getViewTypeTemplateCodes(viewType.AR_APPROVAL_REQUEST_ACTIVITY),
      getViewTypeTemplateCodes(viewType.AP_APPROVAL_REQUEST_ACTIVITY),
    ];

    if (newActivityType === ActivityOptions.APPROVAL_REQUEST) {
      return selectedWorkspace.workspace_type === "accounts_payable" ? approvalTemplates[1] : approvalTemplates[0];
    }

    const templateMap: { [key: string]: string[] } = {
      new_vendor_onboarding: getViewTypeTemplateCodes(viewType.VENDOR_ONBOARD_REQUEST_ACTIVITY),
      share_ap_profile: getViewTypeTemplateCodes(viewType.AP_SHARE_PROFILE_ACTIVITY),
      share_ar_profile: getViewTypeTemplateCodes(viewType.AR_SHARE_PROFILE_ACTIVITY),
      request_customer_profile: getViewTypeTemplateCodes(viewType.AR_CUSTOMER_PROFILE_REQUEST_ACTIVITY),
    };

    const templateKey = templateMap[newActivityType as keyof typeof templateMap];

    if (templateKey) {
      return templateKey;
    }

    if (selectedWorkspace.workspace_type === "accounts_receivable") {
      return arTemplates;
    } else if (selectedWorkspace.workspace_type === "accounts_payable") {
      return apTemplates;
    } else {
      return [...arTemplates, ...apTemplates];
    }
  };

  /**
   * UseEffect for Reactions
   */
  useEffect(() => {
    if (isActivityReactionsSupportEnabled && feed?.length && userStatus.inbox_user_id) {
      const new_reactions = { ...activityReactions };
      feed.forEach((feedItem) => {
        if (!new_reactions[feedItem.id] && feedItem.reactions) {
          const new_reaction = JSON.parse(JSON.stringify(ReactionsDefault));
          feedItem.reactions.forEach((reactionItem) => {
            if (reactionItem.reaction_type && new_reaction[reactionItem.reaction_type]) {
              new_reaction[reactionItem.reaction_type].count += 1;
              new_reaction[reactionItem.reaction_type].givers.push({
                name: reactionItem.user_name,
                user_id: reactionItem.user_id,
                id: reactionItem.id,
              });
              new_reaction[reactionItem.reaction_type].highlight = reactionItem.user_id === userStatus?.inbox_user_id;
            }
          });
          new_reactions[feedItem.id] = new_reaction;
        }
      });
      setActivityReactions(new_reactions);
      formatCopilotContent();
      setFirstPrimaryActivty(getPrimaryActivity());
    }
  }, [isActivityReactionsSupportEnabled, feed, userStatus, suggestedResponse]);

  useEffect(() => {
    fetchCopilotResponse();
  }, []);

  const streamListRef = React.createRef<HTMLTableSectionElement>();
  useEffect(() => {
    if (
      actionType === EmailAction.FORWARD ||
      actionType === EmailAction.REPLY ||
      actionType === EmailAction.REPLY_ALL ||
      actionType === EmailAction.SEND_FOR_APPROVAL
    ) {
      streamListRef.current && streamListRef.current.scrollIntoView();
    }
  }, [actionType]);

  const isEInvoicingRoute = location?.pathname.includes("receivedEInvoices") || location?.pathname.includes("sentEInvoices");

  return (
    <div className="activity-feed-wrapper v2">
      <ActionBar
        isLoading={isLoading || cacheLoading || props.isRefetching}
        ActivityDropdown={<></>}
        getActivityStream={getActivityStream}
        refreshInboxMegamenuCount={refreshInboxMegamenuCount}
        details={{
          refreshActivity: props.refreshActivity,
          connectionStatus: props.connectionStatus,
          companyName: props.companyName,
          companyType: props.companyType,
          companyId: props.companyId,
          erpConnection: props.erpConnection,
          streamId: activityId,
          streamFlag,
        }}
        reassign={{ handlers: { resetData } }}
        nudge={{ handlers: { handleNudge } }}
        approval={{ approvalReviewDrawer, approvalCancelModal, activityItemId, reviewAction, handlers: { onCloseApprovalReview } }}
      />
      <div id="infinite-scroll" className="body">
        {isLoading || cacheLoading ? (
          getLoadingSkeleton()
        ) : (
          <InfiniteScroll
            dataLength={feed?.length}
            next={fetchNextPage}
            hasMore={hasNextPage ?? false}
            loader={getLoadingSkeleton()}
            scrollableTarget="infinite-scroll"
          >
            {!isEInvoicingRoute && (
              <NewActivityDropdown
                handleReplySend={handleReplySend}
                handleReplySendClose={handleReplySendClose}
                addActivityDropdown={
                  newActivityType === ActivityOptions.ONBOARD_VENDOR
                    ? [
                        ...props.addActivityDropdown,
                        {
                          activityType: "New Vendor Onboarding",
                          icon: <ProfileRequest />,
                          displayName: t("addNewActivity.dropdownList.newVendorOnboarding", { ns: "activities" }),
                        },
                      ]
                    : props.addActivityDropdown
                }
                replyToOptions={filteredContactOptions}
                newActivityType={newActivityType}
                setNewActivityType={setNewActivityType}
                showAddActivity={showAddActivity}
                setShowAddActivity={setShowAddActivity}
                setNewActivityVisibility={setNewActivityVisibility}
                setActivityItemId={setActivityItemId}
                isTemplateSupport={
                  (newActivityType === ActivityOptions.EMAIL ||
                    ActivityOptions.ONBOARD_VENDOR ||
                    newActivityType === ActivityOptions.APPROVAL_REQUEST) &&
                  !isNudgeActivity
                }
                defaultTemplateId={defaultTemplateId ?? ""}
                prepareActivityBodyByTemplateID={prepareActivityBodyByTemplateID}
                supportedTemplateList={supportedTemplateList}
                defaultTo={getDefaultContact() as To[]}
                defaultCc={isNudgeActivity ? NAComponentOptions?.replyAllCC : []}
                defaultBcc={isNudgeActivity ? NAComponentOptions?.replyAllBCC : []}
                defaultTitle={`${isNudgeActivity ? "Following up on " : ""}${props.activityData.subject ?? ""}`}
                typeAheadAction={typeAheadAction}
                resetFunction={() => {
                  setEnableTypeAheadSuggestions(false);
                  setIsNudgeActivity(false);
                }}
                disableTitle={
                  ![
                    ActivityOptions.EMAIL,
                    ActivityOptions.APPROVAL_REQUEST,
                    ActivityOptions.ONBOARD_VENDOR,
                    ActivityOptions.SHARE_AP_PROFILE,
                    ActivityOptions.SHARE_AR_PROFILE,
                    ActivityOptions.REQUEST_CUSTOMER_PROFILE,
                  ].includes(newActivityType as ActivityOptions)
                }
                isNudgeActivity={isNudgeActivity}
                fromTime={fromTime}
                toTime={toTime}
                setToTime={setToTime}
                disableSecondaryAction={
                  isNudgeActivity ||
                  newActivityType === ActivityOptions.APPROVAL_REQUEST ||
                  newActivityType === ActivityOptions.ONBOARD_VENDOR ||
                  newActivityType === ActivityOptions.SHARE_AP_PROFILE ||
                  newActivityType === ActivityOptions.SHARE_AR_PROFILE ||
                  newActivityType === ActivityOptions.REQUEST_CUSTOMER_PROFILE
                }
                defaultAttachmentsList={newActivityType === ActivityOptions.APPROVAL_REQUEST ? approvalAttachments : []}
                refs={{ toRef, popupRef }}
                {...(newActivityType === ActivityOptions.EMAIL ? { editorState: getLatestEmailContent() } : {})}
                onClickDownload={onClickDownload}
                workspaceData={selectedWorkspace}
                isSendButtonDisabled={visibility.isSendBtnDisabled}
                tempSubjectSnippetValues={getSelectedTempSubjSnippetValues()}
                suggestedTemplatesCode={getSuggestedTemplatesCode()}
                setToastOptions={(toastOptions: ToastOptions) => setToastOptions(toastOptions)}
              />
            )}
            {!_.isEmpty(suggestedResponse) && copilotContent !== undefined && showCopilot && !_.isEmpty(feed) && isCopilotEnabled ? (
              <Copilot
                id={suggestedResponse.id}
                subject={suggestedResponse.subject}
                content={copilotContent}
                attachments={suggestedResponse.attachments}
                from={suggestedResponse.from}
                to={suggestedResponse.to}
                cc={suggestedResponse.cc}
                from_time={suggestedResponse.time_savings_from_time}
                to_time={suggestedResponse.time_savings_to_time}
                attributes={suggestedResponse.additional_attributes}
                company_name={suggestedResponse.company_name}
                user={suggestedResponse.user}
                activityPopUpProps={{
                  ...evaluateNewActivityProps(),
                  activityType: "email",
                  handleSend: handleReplySend,
                  handleSendMarkClosed: handleReplySendClose,
                }}
                onClickDownload={onClickDownload}
                workspaceData={selectedWorkspace}
                showNewActivity={showNewActivity || showAddActivity}
                onDiscard={onDiscard}
                isSendButtonDisabled={visibility.isSendBtnDisabled}
              />
            ) : (
              <></>
            )}
            <div className="activity-card" ref={streamListRef}>
              {retryEmailPopup ? (
                <NewActivityPopup
                  key={`retry-${retryPopupValues?.reply?.[0]?.label ?? ""}`}
                  title={`Email Activity`}
                  open={retryEmailPopup}
                  contactOptions={allContactOptions}
                  activityId={retryPopupValues?.retryActivityId ?? ""}
                  defaultTo={retryPopupValues?.reply ?? []}
                  replyOptions={{
                    replyButton: true,
                    start: "Reply",
                    reply: retryPopupValues?.reply ?? [],
                    replyAll: retryPopupValues?.replyAll ?? [],
                    replyAllCC: retryPopupValues?.replyAllCC ?? [],
                  }}
                  editorState={retryPopupValues?.content ?? ""}
                  handleSend={handleReplySend}
                  handleSendMarkClosed={handleReplySendClose}
                  onClose={() => setRetryEmailPopup(false)}
                  defaultTitle={`${props.activityData.subject ?? ""}`}
                  onClickDownload={onClickDownload}
                  workspaceData={selectedWorkspace}
                  isSendButtonDisabled={visibility.isSendBtnDisabled}
                  tempSubjectSnippetValues={getSelectedTempSubjSnippetValues()}
                  suggestedTemplatesCode={getSuggestedTemplatesCode()}
                />
              ) : null}
              {showNewActivity && actionType !== "" && (
                // reply, reply all & forward
                <NewActivityPopup {...evaluateNewActivityProps()} onClickDownload={onClickDownload} workspaceData={selectedWorkspace} />
              )}
            </div>
            {feed?.[0]?.connectionViaRule && !feed?.[0]?.markedConfirmed && (
              <ActivitiesHeaderBanner
                OnClickPrimaryButton={_.isUndefined(feed?.[0]?.markedConfirmed) ? onClickConfirmAndMove : onClickSelectManually}
                OnClickSecondaryButton={_.isUndefined(feed?.[0]?.markedConfirmed) ? onClickSelectManually : onClickConfirmAndMove}
                contentText={getBannerContentText()}
                headerText={
                  _.isUndefined(feed?.[0]?.markedConfirmed)
                    ? (t("activityDetails.activitesHeaderBanner.erpDataNotExist.header", { ns: "activities" }) as string)
                    : (t("activityDetails.activitesHeaderBanner.erpDataExist.header", { ns: "activities" }) as string)
                }
                primaryBtnText={
                  _.isUndefined(feed?.[0]?.markedConfirmed)
                    ? (t("activityDetails.activitesHeaderBanner.actionButtons.notNow", { ns: "activities" }) as string)
                    : (t("activityDetails.activitesHeaderBanner.actionButtons.selectManually", { ns: "activities" }) as string)
                }
                secondaryBtnText={
                  _.isUndefined(feed?.[0]?.markedConfirmed)
                    ? (t("activityDetails.activitesHeaderBanner.actionButtons.selectManually", { ns: "activities" }) as string)
                    : (t("activityDetails.activitesHeaderBanner.actionButtons.confirmAndMove", { ns: "activities" }) as string)
                }
              />
            )}
            {feed?.map((feedValue: ActivityFeedItemProps, index: number) => (
              <ActivityFeedItem
                key={index}
                {...feedValue}
                retryEmail={retryEmail}
                setActionType={setActionType}
                setActivityItemId={setActivityItemId}
                setNewActivityType={setNewActivityType}
                handleUnsnoozeStream={handleUnsnoozeStream}
                handleNudge={handleNudge}
                enableKeyboardShortcut={index === DEFAULT_NUMERIC_VALUES?.DEFAULT_ZERO}
                curentActivePopperActivityOrder={curentActivePopperActivityOrder}
                setActivePopperActivityOrder={setActivePopperActivityOrder}
                selectedWorkspace={selectedWorkspace}
                handleReactionUpdate={handleReactionUpdate}
                activityReaction={activityReactions[feedValue.id]}
                isActivityReactionsSupportEnabled={isActivityReactionsSupportEnabled}
                handleCopyNote={handleCopyNote}
                onClickDownload={onClickDownload}
                handleSendForApproval={() => {
                  setNewActivityType(ActivityOptions.APPROVAL_REQUEST);
                  setShowAddActivity(true);
                }}
                isApprovalActive={props.activityData?.has_active_approval}
                isEInvoicingRoute={isEInvoicingRoute}
              />
            
              ))}
          </InfiniteScroll>
        )}
      </div>
      <ActivityMove
        open={isOpenSelectAndMoveActivity}
        onClose={() => setIsOpenSelectAndMoveActivity(false)}
        onCallback={() => {
          getActivityStream(true, true);
          props.refreshActivity();
        }}
        activityIds={[activityId]}
        connectionId={props.activityData?.primary_connection?.id}
        isUnlistedEmailMoveActivity={true}
      />
    </div>
  );
}

import React from "react";
import InvoiceProvider from "../../contexts/InvoiceContext";
import DashboardProvider from "../../contexts/DashboardContext";
import PaymentProvider from "../../contexts/PaymentContext";
import BodyContentHead from "./BodyContentHead/BodyContentHead";
import MegaMenu from "./MegaMenu/MegaMenu";
import SettingsProvider from "../../contexts/SettingsContext";
import ActivityProvider from "../../contexts/ActivityContext";
import "./MainContent.scss";
import ProfileContext from "../../contexts/ProfileContext";
import MainContentView from "./MainContentView";
import { Route, Switch } from "react-router-dom";
import FourOFourError from "../../routes/FourOFourError/FourOFourError";
import PrivateRoute from "../../auth/PrivateRoute";
import Settings from "../Settings/SettingsRoot/Settings";
import Profiles from "../Dashboard/Profiles/Profiles";
import DocumentSwitchContextProvider from "../../contexts/DocumentSwitchContext";
import { AppContext } from "../../contexts/AppContext";
import { WorkspaceContext } from "../../contexts/WorkspaceContext";
import LinearProgressBar from "../library/LinearProgressBar/LinearProgressBar";
import EInvoiceHeader from "./EInvoiceHeader/EInvoiceHeader";
import EInvoiceSidebar from "./EInvoiceSidebar/EInvoiceSidebar";

const MainContent = (): React.ReactElement => {
  const { isCompanySwitching } = React.useContext(AppContext) as AppType;
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const isEInvoicing = selectedWorkspace?.workspace_type === "einvoicing";

  return (
    <DocumentSwitchContextProvider>
      <SettingsProvider>
        <ProfileContext>
          <div className="uc-wrapper">
            <div className="body">
              {!isEInvoicing && (
                <div className={`megamenu-left ${selectedWorkspace?.workspace_type_route}-theme-content`}>
                  <MegaMenu />
                </div>
              )}

              <div className={`right activity-stream-v2 ${isEInvoicing ? "e-invoicing" : ""}`}>
                <div className={`head activity-stream-v2 ${isEInvoicing ? "e-invoicing" : ""}`}>
                  {isEInvoicing ? <EInvoiceHeader /> : <BodyContentHead />}
                </div>
                {isCompanySwitching && <LinearProgressBar />}
                <div className={`content ${isEInvoicing ? "e-invoicing" : ""}`}>
                  {isEInvoicing && (
                    <div className="sidebar-container e-invoicing">
                      <EInvoiceSidebar />
                    </div>
                  )}
                  <DashboardProvider>
                    <ActivityProvider>
                      <InvoiceProvider>
                        <PaymentProvider>
                          <Switch>
                            {/* Can be used on config array as well */}
                            <Route path={`/AR/${selectedWorkspace?.id?.toString()}`} component={MainContentView} />
                            {/* AP Routes */}
                            <Route path={`/AP/${selectedWorkspace?.id?.toString()}`} component={MainContentView} />
                            {/* AP Routes */}
                            <Route path={`/AW/${selectedWorkspace?.id?.toString()}`} component={MainContentView} />
                            {/* EI Routes */}
                            <Route path={`/E/${selectedWorkspace?.id?.toString()}`} component={MainContentView} />
                            <PrivateRoute
                              path={`/settings`}
                              component={() => {
                                return <Settings />;
                              }}
                            />
                            <PrivateRoute
                              path={`/profiles`}
                              component={() => {
                                return <Profiles />;
                              }}
                            />
                            <PrivateRoute
                              component={() => {
                                return <FourOFourError />;
                              }}
                            />
                          </Switch>
                        </PaymentProvider>
                      </InvoiceProvider>
                    </ActivityProvider>
                  </DashboardProvider>
                </div>
              </div>
            </div>
          </div>
        </ProfileContext>
      </SettingsProvider>
    </DocumentSwitchContextProvider>
  );
};

export default MainContent;

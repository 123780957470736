import React from "react";
import { activitiesClientV2, invoicesClientV2, PaymentsClientV2 } from "../db/version2Accessor";
import { invoicesClient, paymentsClient } from "../db/accessor";
import { DEFAULT_NUMERIC_VALUES } from "../constants/NumericConstants";
// import { useEffect, useRef } from "react";
// import { useLocation } from "react-router-dom";

export const DocumentSwitchContext = React.createContext<DocumentSwitchType | null>(null);
export interface DocumentSwitchProviderProps {
  children: (React.ReactNode & { type: string })[] | (React.ReactNode & { type: string });
}

type DocumentSwitcherState = {
  documentSwitcher: string | null;
  activityStreamId: string | null;
  isRelativeStream: string | null;
  paginationState: string | null;
  activityStreamIdSet: string | null;
  pageFetchParams: string | null;
  documentSwitcherType: string | null;
};

/**
 * @function usePrevious
 * A custom hook to keep a copy of variable for use in next update cycle.
 * This custom hook imitates the prevProps/prevState behaviour in useEffect
 * @param value - the value to be compared in previous context
 * @see {@link https://blog.logrocket.com/accessing-previous-props-state-react-hooks/ | usePrevious hook}
 * @returns value
 */
/*
function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
*/

/**
 * @function DocumentSwitchContextProvider
 * A react context to provide Activity stream/document switching functionality.
 * This context facilitates the activity stream switching by keeping track of
 * all the activity stream record ids available for current page.
 * Whenever an upper bound or lower bound is reached, this context decides
 * whether to stop further switching or continue switching by
 * fetching next/previous set of records. This context uses broweser's
 * localstorage api to persist data between refresh, and page laods
 * and can be effectively cleared on demand.
 * @param DocumentSwitchProviderProps
 */
const DocumentSwitchContextProvider: React.FC<DocumentSwitchProviderProps> = ({ children }: DocumentSwitchProviderProps) => {
  /**
   * @function getActivityStreamId
   * A helper function to get currently selected activity stream's id from localstorage.
   * @returns ActivityStreamId
   */
  const getActivityStreamId = (): ActivityStreamId => {
    if (window.localStorage.getItem("activityStreamId")?.length) {
      return JSON.parse(window.localStorage.getItem("activityStreamId") ?? "");
    }
    return {} as ActivityStreamId;
  };

  /**
   * @function setSelectedActivityStreamId
   * A helper function to set currently selected activity stream's id to localstorage.
   * @param activityStreamId
   */
  const setSelectedActivityStreamId = (activityStreamId: ActivityStreamId) => {
     window.localStorage.setItem("activityStreamId", JSON.stringify(activityStreamId));
  };

  /**
   * @function setActivityStreamIds
   * A helper function to set currently fetched activity stream index's record ids to localStorage.
   * @param activityStreamIds
   */
  const setActivityStreamIds = (activityStreamIds: ActivityStreamId[]) => {
     window.localStorage.setItem("activityStreamIdSet", JSON.stringify(activityStreamIds));
  };

  /**
   * @function getActivityStreamIds
   * A helper function to get currently fetched activity stream index's record ids from localStorage.
   * @param activityStreamIds[]
   */
  const getActivityStreamIds = (): ActivityStreamId[] => {
    if (window.localStorage.getItem("activityStreamIdSet")?.length) {
      return JSON.parse(window.localStorage.getItem("activityStreamIdSet") ?? "");
    }
    return [] as ActivityStreamId[];
  };

  /**
   * @function setCurrentPaginationState
   * A helper function to set current activity stream index's pagination state to localStorage.
   * @param paginationState
   */
  const setCurrentPaginationState = (paginationState: PaginationState) => {
    window.localStorage.setItem("paginationState", JSON.stringify(paginationState));
  };

  /**
   * @function getCurrentPaginationState
   * A helper function to get current activity stream index's pagination state from localStorage.
   * @param paginationState
   */
  const getCurrentPaginationState = (): PaginationState => {
    if (window.localStorage.getItem("paginationState")?.length) {
      return JSON.parse(window.localStorage.getItem("paginationState") ?? "");
    }
    return {} as PaginationState;
  };

  /**
   * @function setIsRelativeStream
   * A helper function to set currently selected activity stream as belonging to
   * a set of activity streams related to a document like invoice, bills, payments etc, to localstorage.
   * @param isRas - boolean denoting relative or not
   */
  const setIsRelativeStream = (isRas: boolean) => {
    window.localStorage.setItem("isRelativeStream", JSON.stringify({ isRas }));
  };

  /**
   * @function isRelativeStream
   * A helper function to check if an activity stream belongs to a set relative
   * to a document like invoice, bills, payments etc, to localstorage.
   * @returns boolean
   */
  const isRelativeStream = (): boolean => {
    if (window.localStorage.getItem("isRelativeStream")) {
      const isRelativeActivityStream = JSON.parse(window.localStorage.getItem("isRelativeStream") ?? "");
      if (isRelativeActivityStream?.isRas === true) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  /**
   * @function isTransaction
   * A helper function to check if a document is from a transaction index or not
   * @returns boolean
   */
  const isTransaction = (): boolean => {
    if (window.localStorage.getItem("documentSwitcherType")) {
      const isTransactionDoc = JSON.parse(window.localStorage.getItem("documentSwitcherType") ?? "");
      if (
        isTransactionDoc?.documentType === "invoice" ||
        isTransactionDoc?.documentType === "related-invoice" ||
        isTransactionDoc?.documentType === "payment" ||
        isTransactionDoc?.documentType === "related-payment"
      ) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  /**
   * @function getTransactionType
   * A helper function to get the type of transaction being handled.
   * @returns string
   */
  const getTransactionType = (): string => {
    if (window.localStorage.getItem("documentSwitcherType")) {
      const documentSwitcherType = JSON.parse(window.localStorage.getItem("documentSwitcherType") ?? "");
      return documentSwitcherType?.documentType;
    }
    return "";
  };

  /**
   * @function getTransactionsPage
   * A helper function to fetch a transactions' index page
   * @param pageNumber - The page number to fetch
   * @returns
   */
  const getTransactionsPage = async (pageNumber?: number) => {
    const transactionType = getTransactionType();
    const fetchParams = JSON.parse(window.localStorage.getItem("pageFetchParams") ?? "");
    const { page, pageSize, order, filter, spamFraudArchivedTab } = fetchParams;
    if (transactionType === "invoice") {
      return await invoicesClientV2.getInvoices(pageSize, pageNumber ?? page, order, filter, spamFraudArchivedTab);
    } else if (transactionType === "payment") {
      return await PaymentsClientV2.querySummaries(filter, order, pageSize, pageNumber ?? page, spamFraudArchivedTab);
    } else if (transactionType === "related-invoice") {
      return await invoicesClient.getInvoiceSummaries(filter, undefined, order, pageSize, pageNumber ?? page);
    } else if (transactionType === "related-payment") {
      return await paymentsClient.querySummaries(filter, undefined, order, pageSize, pageNumber ?? page);
    }
  };

  /**
   * @function getPage
   * A helper function to get a page from activity stream index based on a set of parameters.
   * @param pageNumber
   * @returns
   */
  const getPage = async (pageNumber?: number): Promise<any> => {
    const fetchParams = JSON.parse(window.localStorage.getItem("pageFetchParams") ?? "");
    const { assigneeType, workspaceId, status, page, page_size, customerId, sortQuery, searchFilter, spamFraudArchivedTab, others } = fetchParams;
    return await activitiesClientV2.fetchTableRecords(
      assigneeType,
      workspaceId,
      status,
      pageNumber ?? page,
      page_size,
      customerId,
      sortQuery,
      searchFilter,
      spamFraudArchivedTab,
      others
    );
  };

  /**
   * @function getRelativeStreamsPage
   * A helper function to get a page from activity stream index related to
   * a document like invoice, bills, payments etc, based on a set of parameters.
   * @param pageNumber
   * @returns
   */
  const getRelativeStreamsPage = async (pageNumber?: number): Promise<any> => {
    const fetchParams = JSON.parse(window.localStorage.getItem("pageFetchParams") ?? "");
    const { workspaceId, id, type, page, page_size } = fetchParams;
    return await activitiesClientV2.fetchRelatedStreams(workspaceId, pageNumber ?? page, page_size, id, type);
  };

  /**
   * @function setNextPage
   * A helper function to set next page number, get the page with activity stream records
   * and set it to localstorage and update pointer to 0th index as new selected activity stream id.
   * @param pageNumber
   */
  const setNextPage = async (pageNumber?: number) => {
    /**
     * Check if the index is of a transaction or a relative
     * stream or else assume activity stream
     */
    const res = isTransaction()
      ? await getTransactionsPage(pageNumber)
      : isRelativeStream()
      ? await getRelativeStreamsPage(pageNumber)
      : await getPage(pageNumber);
    if (res?.data?.length || res?.records?.length) {
      const activityStreamIds = res?.data?.length
        ? res.data.map((item: any) => ({ id: item.id }))
        : res?.records?.map((item: any) => ({ id: item.invoice_id }));
      await setActivityStreamIds(activityStreamIds);
      await setSelectedActivityStreamId(activityStreamIds[0]);
    }
  };

  /**
   * @function setPreviousPage
   * A helper function to set previous page number, get the page with activity stream records
   * and set it to localstorage and update pointer to last index (since moving backwards) as new selected activity stream id.
   * @param pageNumber
   */
  const setPreviousPage = async (pageNumber?: number) => {
    /**
     * Check if the index is of a transaction or a relative
     * stream or else assume activity stream
     */
    const res = isTransaction()
      ? await getTransactionsPage(pageNumber)
      : isRelativeStream()
      ? await getRelativeStreamsPage(pageNumber)
      : await getPage(pageNumber);
    if (res?.data?.length || res?.records?.length) {
      const activityStreamIds = res?.data?.length
        ? res?.data?.map((item: any) => ({ id: item.id }))
        : res?.records?.map((item: any) => ({ id: item.invoice_id }));
      await setActivityStreamIds(activityStreamIds);
      await setSelectedActivityStreamId(activityStreamIds[activityStreamIds.length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE]);
    }
  };

  /**
   * @function setPageFetchParams
   * A helper function to set current activity stream/document index's page properties
   * like page number, page size, total records, workspace id, type, status,
   * customer id, sort params, filter params, spam/fraud flags etc.
   * This will be consumed when fetching pages for next and previous cases.
   * @param pageFetchParams
   */
  const setPageFetchParams = (pageFetchParams: PageFetchParams) => {
    window.localStorage.setItem("pageFetchParams", JSON.stringify(pageFetchParams));
  };

  /**
   * @function setPlatformPageFetchParams
   * A helper function to set current activity stream/document index's page properties
   * like page number, page size, total records, workspace id, type, status,
   * customer id, sort params, filter params, spam/fraud flags etc.
   * This will be consumed when fetching pages for next and previous cases.
   * @param pageFetchParams
   */
  const setPlatformPageFetchParams = (pageFetchParams: PlatformPageFetchParams) => {
    window.localStorage.setItem("pageFetchParams", JSON.stringify(pageFetchParams));
  };

  /**
   * @function setDocumentType
   * A helper function to set the document type for current document switcher context
   * @param documentType
   */
  const setDocumentType = (documentType: string) => {
    window.localStorage.setItem("documentSwitcherType", JSON.stringify({ documentType }));
  };

  /**
   * @function getEnableDocumentSwitch
   * A helper function to check whether documentSwitcher is enabled for the current index or not.
   * @returns boolean
   */
  const getEnableDocumentSwitch = (): boolean => {
    if (window.localStorage.getItem("documentSwitcher")) {
      const documentSwitcherEnabled = JSON.parse(window.localStorage.getItem("documentSwitcher") ?? "");
      if (documentSwitcherEnabled?.flag === true) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  /**
   * @function setEnableDocumentSwitch
   * A helper function to set documentSwitcher on or off.
   * @param flag boolean
   */
  const setEnableDocumentSwitch = (flag: boolean) => {
    window.localStorage.setItem("documentSwitcher", JSON.stringify({ flag }));
  };

  /**
   * @function getCurrentSwitcherStateSnapshot
   * A helper function to get a snapshot of the current document swithcer state.
   * @returns DocumentSwitcherState
   */
  const getCurrentSwitcherStateSnapshot = (): DocumentSwitcherState => {
    return {
      documentSwitcher: window.localStorage.getItem("documentSwitcher"),
      activityStreamId: window.localStorage.getItem("activityStreamId"),
      isRelativeStream: window.localStorage.getItem("isRelativeStream"),
      paginationState: window.localStorage.getItem("paginationState"),
      activityStreamIdSet: window.localStorage.getItem("activityStreamIdSet"),
      pageFetchParams: window.localStorage.getItem("pageFetchParams"),
      documentSwitcherType: window.localStorage.getItem("documentSwitcherType"),
    };
  };

  /**
   * @function setCurrentSwitcherStateSnapshot
   * A helper function to set a snapshot of the current document swithcer
   * state to localStorage as cache to revert to the previous state.
   */
  const setCurrentSwitcherStateSnapshot = () => {
    window.localStorage.setItem("currentSwitcherStateSnapshot", JSON.stringify(getCurrentSwitcherStateSnapshot()));
  };

  /**
   * @function setRootSwitcherStateSnapshot
   * A helper function to set the root state snapshot of document switcher to localstorage,
   * This is required in case of a document having multiple related document indices
   * which can create circular linkage in document switcher state, causing back navigation
   * to root document to display wrong page peoperties.
   */
  const setRootSwitcherStateSnapshot = () => {
    window.localStorage.setItem("rootSwitcherStateSnapshot", JSON.stringify(getCurrentSwitcherStateSnapshot()));
  };

  /**
   * @function revertCurrentSwitcherStateSnapshot
   * A helper function to revert the current document swithcer state to previous state or root state.
   */
  const revertCurrentSwitcherStateSnapshot = (snapshotType?: string) => {
    const prevSwitcherStateSnapshot = JSON.parse(window.localStorage.getItem(snapshotType ?? "currentSwitcherStateSnapshot") ?? "{}");
    Object.keys(prevSwitcherStateSnapshot).forEach((item) => {
      return window.localStorage.setItem(item, prevSwitcherStateSnapshot[item]);
    });
  };

  /**
   * @function clearStorage
   * A helper function to clear all localstorage variables used to store data for the context
   */
  const clearStorage = () => {
    window.localStorage.removeItem("paginationState");
    window.localStorage.removeItem("pageFetchParams");
    window.localStorage.removeItem("activityStreamId");
    window.localStorage.removeItem("isRelativeStream");
    window.localStorage.removeItem("documentSwitcher");
    window.localStorage.removeItem("activityStreamIdSet");
    window.localStorage.removeItem("documentSwitcherType");
    window.localStorage.removeItem("rootSwitcherStateSnapshot");
    window.localStorage.removeItem("currentSwitcherStateSnapshot");
  };

  /**
   * The following logic can be used to pre-emptively clear localstorage variables used to store data for the context.
   * This effect checks for current location's path name from useLocation hook, and see if there is a change
   * between previous location and current location. If the path remains same but activityId changes,
   * and also length of path remains constant as activityId is a UUID, then it conveniently assumes to keep the data.
   * However when the path changes between previous and current location, then it means the user has navigated away
   * from activity stream detail page. In that case if the previous path's length is greater than current path's length,
   * its assumed to be an exit, and calls clearStorage function, else its assumed to be an entry and data is kept.
   *
   * This logic is not used as of now, because data is required to allow history when user uses browser navigation buttons.
   */
  /*
  const location = useLocation();
  const prevPathname = usePrevious(location?.pathname);
  React.useEffect(() => {
    if (prevPathname && location.pathname) {
      if ((prevPathname as string)?.length > location.pathname.length && (prevPathname as string)?.length !== location.pathname.length) {
        clearStorage();
      }
    }
  }, [location.pathname]);
  */

  return (
    <DocumentSwitchContext.Provider
      value={{
        getActivityStreamId,
        setSelectedActivityStreamId,
        getActivityStreamIds,
        getCurrentPaginationState,
        setCurrentPaginationState,
        setActivityStreamIds,
        setNextPage,
        setPreviousPage,
        setPageFetchParams,
        setPlatformPageFetchParams,
        setIsRelativeStream,
        setDocumentType,
        getEnableDocumentSwitch,
        setEnableDocumentSwitch,
        isTransaction,
        setCurrentSwitcherStateSnapshot,
        revertCurrentSwitcherStateSnapshot,
        setRootSwitcherStateSnapshot,
        getTransactionType,
        clearStorage,
      }}
    >
      {children}
    </DocumentSwitchContext.Provider>
  );
};
export default DocumentSwitchContextProvider;

import { ConnectionStatus } from "../../types/enums";
import APIClient from "../APIClient";
import { AxiosRequestConfig } from "axios";

const INBOX_API_BASE = `${process.env.REACT_APP_API_ENDPOINT_INBOX_API_PATH}${process.env.REACT_APP_API_ENDPOINT_INBOX_API_V2}`;
const CONFIG_OVERRIDE = {
  baseURL: process.env.REACT_APP_API_ENDPOINT_INBOX,
} as AxiosRequestConfig;

export class PaymentsClient {
  querySummaries(filters?: string, order?: string, pageSize?: number, pageNumber?: number, spamFraudArchivedTab = false): Promise<FetchResult> {
    let url = `${INBOX_API_BASE}/payments?page=${pageNumber}&page_size=${pageSize}`;
    if (filters) {
      url = `${url}&${filters}`;
    }
    if (order) {
      url = `${url}&${order}`;
    }

    const options = {
      params: {
        connection_status_not_in: spamFraudArchivedTab ? "" : `${ConnectionStatus.SPAM},${ConnectionStatus.FRAUD}`,
      },
      ...CONFIG_OVERRIDE,
    };
    return APIClient.get(url, options).then((response) => {
      return {
        pageNumber: response.data.page,
        pageSize: response.data.page_size,
        totalCount: response.data.total_records,
        records: response.data.data,
      } as PaymentSummaryModelFetchResultV2;
    });
  }

  getPaymentsSummary(filter?: string, spamFraudArchivedTab = false): Promise<APIResponse> {
    let url = `${INBOX_API_BASE}/payments/summary`;
    if (filter) {
      url = url + `?${filter}`;
    }

    const options = {
      params: {
        connection_status_not_in: spamFraudArchivedTab ? "" : `${ConnectionStatus.SPAM},${ConnectionStatus.FRAUD}`,
      },
      ...CONFIG_OVERRIDE,
    };
    return APIClient.get(url, options).then((response) => {
      return response.data;
    });
  }

  exportPayments(filters: string): Promise<APIResponse> {
    let url = `${INBOX_API_BASE}/payments.csv`;
    if (filters) {
      url = `${url}?${filters}`;
    }
    return APIClient.get(url, CONFIG_OVERRIDE).then((response) => response.data);
  }
}

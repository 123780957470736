import React from "react";
import PrimaryActivity from "../../PrimaryActivity/PrimaryActivity";
import { Pen } from "../../../../../../library/Icons/Icons";
import { ApprovalRegenerateProps } from "../ActivityItemVariantTypes";
import ApprovalUserActions from "../../PrimaryActivity/Helpers/ApprovalUserActions/ApprovalUserActions";

const Regenerate: React.FC<ApprovalRegenerateProps> = ({
  onClickApprove,
  onClickDecline,
  onClickCancel,
  onClickNudge,
  enableReviewOptions,
  ...props
}) => {
  return (
    <PrimaryActivity
      {...{
        ...props,
        icon: <Pen />,
        creator: { ...props.creator, name: props.creator.name ?? "Unknown user", message: "requested and received a new approval request link." },
        FLAGS: { REPLY_OPTIONS: false, TIME_SAVING_INDICATOR: false },
        content: {
          preview: "",
          HTMLEmailContent: "",
        },
      }}
    >
      {" "}
      {enableReviewOptions && (
        <ApprovalUserActions
          {...{ onClickApprove: onClickApprove, onClickDecline: onClickDecline, onClickNudge: onClickNudge, onClickCancel: onClickCancel }}
        />
      )}
    </PrimaryActivity>
  );
};

export default Regenerate;

import React, { useEffect, useRef, useState } from "react";
import _ from "underscore";
import { MenuItem, Select, SelectChangeEvent, SelectProps, Input, InputAdornment, Typography, ListItemIcon, ListItemText } from "@mui/material";
import { ArrowDropDown, Search } from "@mui/icons-material";
import { ActionBarActivityAssignUser, ActivityUnassigned, CheckedRoundSuccessGreen } from "../../../../../library/Icons/Icons";
import { NUMERIC_VALUES } from "../../../../../library/AtomicComponents/constants/numeric.constants";
import useActivityReassignDropdownStyle from "./useActivityReassignDropdownStyle";
import { ActivityContext } from "../../../../../../contexts/ActivityContext";
import { AlertContext } from "../../../../../../contexts/AlertContext";
import { WorkspaceContext } from "../../../../../../contexts/WorkspaceContext";
import { SmartText } from "../../../../../library/SmartText";
import { useTranslation } from "react-i18next";

interface DropdownProps extends SelectProps {
  onCallback: () => void;
  activityIds: string[];
  currentAssignee: UserItem | null;
  setInviteFlyout: (state: boolean) => void;
}

const DropdownSearch: React.FC<DropdownProps> = ({ onCallback, activityIds, currentAssignee, setInviteFlyout }) => {
  const {
    select,
    selectActive,
    menuRoot,
    menuSearchBox,
    adornmentSearchIcon,
    menuTypographyElement,
    unassignedTypography,
    divRoot,
    divRootActive,
    itemText,
    typography,
  } = useActivityReassignDropdownStyle();
  const [searchText, setSearchText] = React.useState<string>("");
  const selectRef = useRef(null);
  const [openSelect, setOpenSelect] = React.useState(false);
  const { handleReassign } = React.useContext(ActivityContext) as ActivityType;
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const { workspaceMembers, fetchingWorkspaceMembers } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const [noteValue, setNoteValue] = useState<string>("");
  const [teamMemberList, setTeamMemberList] = useState<Partial<UserAccountModel>[]>([]);
  const [selectedUser, setSelectedUser] = useState<Partial<UserAccountModel> | null>(null);
  const { t } = useTranslation();

  const handleUserSearch = (event: { target: { value: string } }) => setSearchText(event.target.value);
  const handleUserSelection = async (event: SelectChangeEvent) => {
    const userSelection = teamMemberList.find((item) => item.userId === event.target.value) ?? null;
    setOpenSelect(false);
    let toastOptions: ToastOptions = {
      open: true,
      severity: "success",
      message:
        activityIds.length === NUMERIC_VALUES.CONSTANT_ONE ? (
          <p>
            {t("toastMessages.reassignActivity.success", { ns: "activities" })} <span style={{ fontWeight: 700 }}>{userSelection?.userName}</span>
          </p>
        ) : (
          t("toastMessages.reassignActivity.bulkSuccess", { activitesCount: activityIds.length, ns: "activities" })
        ),
    };
    let response = {} as APIResponse;

    try {
      response = await handleReassign(
        activityIds.length === NUMERIC_VALUES.CONSTANT_ONE ? activityIds[0] : activityIds,
        userSelection?.userId ?? null,
        noteValue
      );
    } catch (e: unknown) {
      response.success = false;
    } finally {
      if (!response.success) {
        toastOptions = {
          ...toastOptions,
          severity: "error",
          message:
            activityIds.length === NUMERIC_VALUES.CONSTANT_ONE
              ? t("toastMessages.reassignActivity.failed", { ns: "activities" })
              : t("toastMessages.reassignActivity.bulkfailed", { activitesCount: activityIds.length, ns: "activities" }),
        };
      } else {
        setSelectedUser(userSelection);
      }
      onCallback();
      setNoteValue("");
      setToastOptions(toastOptions);
    }
  };

  const handleSelectClose = () => {
    setSearchText("");
    setOpenSelect(false);
  };

  const handleSelectOpen = () => {
    setOpenSelect(true);
  };

  const onClickInvitation = () => {
    setOpenSelect(false);
    setInviteFlyout(true);
  };

  useEffect(() => {
    if (!_.isEmpty(workspaceMembers)) {
      setTeamMemberList(
        workspaceMembers?.map((user: Partial<UserItem>) => ({
          userId: `${user.id}`,
          userName: user.name ?? "",
          userEmail: user.email_address,
        })) ?? []
      );
    }
    const unassignedUser = { userId: "", userName: t("modalAndFlyout.reassignActivity.unassigned", { ns: "activities" }), userEmail: "" };
    setTeamMemberList((prev: Partial<UserAccountModel>[]) => [unassignedUser, ...prev]);
  }, [workspaceMembers]);

  useEffect(() => {
    setSelectedUser({
      userEmail: currentAssignee?.email_address ?? "",
      userId: currentAssignee?.id ?? "",
      userName: currentAssignee?.name ?? "",
    } as Partial<UserAccountModel>);
  }, [currentAssignee]);

  return (
    <div className={`${divRoot} ${openSelect && divRootActive}`}>
      <Select
        id="activity-reassign-dropdown"
        ref={selectRef}
        open={openSelect}
        onClose={handleSelectClose}
        onOpen={handleSelectOpen}
        onChange={handleUserSelection}
        className={`${select} ${openSelect && selectActive}`}
        multiple={false}
        value={selectedUser?.userId ?? ""}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          className: menuRoot,
          disableAutoFocusItem: true,
        }}
        IconComponent={ArrowDropDown}
        displayEmpty={true}
        renderValue={() => {
          return (
            <>
              <ActionBarActivityAssignUser fill={openSelect ? "#1C4DA5" : "#4D4D4D"} />
              <span>
                {fetchingWorkspaceMembers || _.isEmpty(currentAssignee)
                  ? t("activityDetails.actionBar.activityReassign.defaultDropdownValue", { ns: "activities" })
                  : currentAssignee?.name}
              </span>
            </>
          );
        }}
      >
        <Input
          key="searchInput"
          placeholder={t("activityDetails.commandCenter.body.activityReassignView.searchPlaceholder", { ns: "activities" }) as string}
          value={searchText}
          className={menuSearchBox}
          onChange={handleUserSearch}
          onKeyDown={(event) => event.stopPropagation()}
          disableUnderline={true}
          startAdornment={
            <InputAdornment position="start">
              <Search className={adornmentSearchIcon} />
            </InputAdornment>
          }
        />
        <MenuItem value="">
          <Typography key="typography" className={typography}>
            {t("modalAndFlyout.reassignActivity.unassigned", { ns: "activities" })}
          </Typography>
          <Typography className={unassignedTypography}>
            <ActivityUnassigned />
          </Typography>
        </MenuItem>
        <MenuItem disabled className={menuTypographyElement}>
          <Typography key="typography" className={typography}>
            {(_.isEmpty(searchText) && teamMemberList.length > NUMERIC_VALUES.CONSTANT_ZERO) ||
            (!_.isEmpty(searchText) && teamMemberList.some((item) => item?.userName?.toLowerCase().includes(searchText.toLowerCase())))
              ? t("activityDetails.commandCenter.body.activityReassignView.memberListHeading.allTeammates", { ns: "activities" })
              : t("activityDetails.commandCenter.body.activityReassignView.memberListHeading.nothingFound", { ns: "activities" })}
          </Typography>
        </MenuItem>
        {teamMemberList?.map((item: Partial<UserAccountModel>) => {
          if (item.userId !== "" && !_.contains(selectedUser?.userName ?? "", item.userName)) {
            if (_.isEmpty(searchText) || item?.userName?.toLowerCase().indexOf(searchText.toLowerCase()) !== NUMERIC_VALUES.CONSTANT_NEGATIVE_ONE) {
              return (
                <MenuItem key={item.userName} value={item.userId}>
                  <ListItemIcon>{currentAssignee?.name === item?.userName && <CheckedRoundSuccessGreen />}</ListItemIcon>
                  <ListItemText className={itemText}>
                    <SmartText
                      highlighter={{
                        enableHighlighter: searchText.length > NUMERIC_VALUES.CONSTANT_ZERO ? true : false,
                        matchText: searchText ?? "~",
                      }}
                      title={item.userName ?? ""}
                      className="list-item-value"
                    />
                  </ListItemText>
                </MenuItem>
              );
            }
          }
        })}
        {teamMemberList.length === NUMERIC_VALUES.CONSTANT_TWO && (
          <div className="banner-invite-team">
            <span className="banner-text">{t("activityDetails.actionBar.activityReassign.inviteBanner.message", { ns: "activities" })}</span>
            <div className="banner-invite-button" onClick={onClickInvitation}>
              {t("activityDetails.actionBar.activityReassign.inviteBanner.actionLabel", { ns: "activities" })}
            </div>
          </div>
        )}
      </Select>
    </div>
  );
};

export default DropdownSearch;

import React from "react";
import { Skeleton } from "@mui/material";

import { SummaryHeaderComponentProps, SummaryItem } from "./SummaryHeaderInterfaces/SummaryHeaderInterface";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../../constants/NumericConstants";
import { PERCENTAGE_CONSTANTS } from "../../../constants/StyleConstants";

import "./SummaryHeader.scss";

function SummaryHeaderComponent(props: SummaryHeaderComponentProps) {
  // auto-adjusted widths: 80% / (NUMBER OF ITEMS + 1)
  const skeletonWidth = PERCENTAGE_CONSTANTS.EIGHTY_PERCENT / ((props.itemCount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) + NUMERIC_VALUES.CONSTANT_ONE);
  const skeletonElement = [...Array(props.itemCount)].map((item, i) => <Skeleton key={i} animation="wave" width={`${skeletonWidth}%`} />);

  return (
    <div className="summary-header">
      {props.isLoading
        ? skeletonElement
        : props.data.map((item: SummaryItem, index: number) => {
            return (
              <span className="summary-item" key={`${item.label}-${index}`}>
                {item.value}&nbsp;
                <span className="text-label">{item.label}</span>
              </span>
            );
          })}
    </div>
  );
}

export default SummaryHeaderComponent;

import { AxiosError } from "axios";
import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { TemplateDefinitionProps } from "../../../app/Templates/TemplateFactory";
import { TemplateTypes, getViewTypeTemplateCodes, viewType } from "../../../app/Templates/TemplateTypes";
import { CONNECTION_STATUS } from "../../../constants/ConnectionConstants";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES, DEFAULT_PAGINATION_VALUES } from "../../../constants/NumericConstants";
import { ActivityContext } from "../../../contexts/ActivityContext";
import { AlertContext } from "../../../contexts/AlertContext";
import { AppContext } from "../../../contexts/AppContext";
import { ApplicationRouteContext } from "../../../contexts/ApplicationRouteContext";
import { PaymentContext } from "../../../contexts/PaymentContext";
import { TemplateContext } from "../../../contexts/TemplateContext";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";
import { companiesClient } from "../../../db/accessor";
import { automationClientV2, activitiesClientV2, emailsClientV2, exportClient, PaymentsClientV2 } from "../../../db/version2Accessor";
import {
  ActivityOptions,
  EmailAction,
  FallbackTypes,
  ActivityType as ActivityTypeEnum,
  VIEW_CATEGORIES,
  WorkspaceType,
  EXPORT_CATEGORIES,
} from "../../../types/enums";
import TableUtils from "../../../utils/TableUtils/TableUtils";
import NewActivityPopup from "../../library/AddNewActivityDropdown/NewActivityPopup";
import { Email, ExportV2, NoteFill, Pen, Phone } from "../../library/Icons/Icons";
import { SummaryHeader } from "../../library/SummaryHeader";
import { SummaryItem } from "../../library/SummaryHeader/SummaryHeaderInterfaces/SummaryHeaderInterface";
import TrackingUtils from "../../Tracking/Tracking.Utils";
import { DocumentSwitchContext } from "../../../contexts/DocumentSwitchContext";
import "../Payments.scss";
import Utils from "../../../utils/utils";
import _ from "underscore";
import { CustomerContext } from "../../../contexts/CustomerContext";
import useLocale from "../../../hooks/useLocale";

import { Table, useColumnFilters } from "../../library/AtomicComponents/Table/index";
import { UserAction } from "../../library/AtomicComponents/Table/types/table.types";
import usePagination from "../../library/AtomicComponents/Table/hooks/usePagination";
import useColumnSort from "../../library/AtomicComponents/Table/hooks/useColumnSort";
import useRowSelection from "../../library/AtomicComponents/Table/hooks/useRowSelection";
import APIClientUtils from "../../../utils/APIClientUtils/APIClientUtils";
import { RowDataTypes } from "../../library/AtomicComponents/constants/table.constants";
import useContacts from "../../../hooks/useContacts";
import {
  ACTIVITY_COMPLETE_FAILURE,
  ACTIVITY_COMPLETE_SUCCESS,
  ACTIVITY_SUCCESS_TOAST_MSG,
  BTN_NEW_ACTIVITY,
  EMPTY_PAYMENT_CAPTION_MESSAGE,
  NO_DATA_MESSAGE,
} from "../../../constants/config";
import { CSVLink } from "react-csv";
import { FeatureFlagContext, FeatureFlagProviderType } from "../../../contexts/FeatureFlagContext";
import { ExportContext } from "../../../contexts/ExportContext";
import { ExportToastMessage } from "../../../constants/ExportConstant";
import ActivityFeedMethods from "../../Activities/ActivityDetail/ActivityFeedV2/helpers/ActivityFeedMethods";
import { useQuery } from "@tanstack/react-query";
import { RefetchIntervals } from "../../../constants/CacheConfig";
import useHandleVisibility from "../../../hooks/useHandleVisibility";

type PaymentsProps = {
  configs: any;
};

export default function AllPayments(props: PaymentsProps): React.ReactElement {
  const { path } = useRouteMatch();
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const tableConfigs = props.configs.view.all.table;
  const summaryConfigs = props.configs.view.all.summary;
  const pageNoFromUrl = parseInt(params.get("page") ?? "1", NUMERIC_VALUES.CONSTANT_TEN) || NUMERIC_VALUES.CONSTANT_ONE;
  const viewConfigs = props.configs.view;
  //eslint-disable-next-line
  const [isError, setError] = useState<boolean>(false);
  //eslint-disable-next-line
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [showActivity, setShowActivity] = useState<boolean>(false);
  const [fromTime, setFromTime] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [toTime, setToTime] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [editorState, setEditorState] = useState<string>("");
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const { handleClose } = React.useContext(ActivityContext) as ActivityType;
  const { signature, loginUserCompany } = React.useContext(CustomerContext) as CustomerType;
  const { selectedWorkspace, workspaceHomePath } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const [paymentId, setPaymentId] = useState<string>("");
  const [customerId, setCustomerId] = useState<string>("");
  const [companyContactOptions, setCompanyContactOptions] = useState<To[]>([]);
  const [newActivityType, setNewActivityType] = useState<string>("");
  const { templateFactory, templateData } = React.useContext(TemplateContext) as ITemplateProps;
  const [supportedTemplateList, setSupportedTemplateList] = useState<TemplateDefinitionProps[]>();
  const [requiredTemplates, setRequiredTemplates] = useState<Record<string, TemplateDefinitionProps[]>>();
  const [item, setItem] = useState<any>();
  const [companyData, setCompanyData] = useState<CompanyModel>({} as CompanyModel);
  const { userStatus } = React.useContext(AppContext) as AppType;
  const { getContactsOptions, allContactOptions, filterPrimaryContacts } = useContacts();
  const { getDetailViewInvoices } = React.useContext(PaymentContext) as PaymentType;
  const [templateName, setTemplateName] = useState<string>("");
  const [summaryItems, setSummaryItems] = useState<SummaryItem[]>([]);
  const [summaryLoader, setSummaryLoading] = useState<boolean>(false);
  const { updateBaseRoute, getBaseRoute } = React.useContext(ApplicationRouteContext) as ApplicationRouteType;
  const {
    setSelectedActivityStreamId,
    setActivityStreamIds,
    setCurrentPaginationState,
    setPlatformPageFetchParams,
    setDocumentType,
    setEnableDocumentSwitch,
    setRootSwitcherStateSnapshot,
  } = React.useContext(DocumentSwitchContext) as DocumentSwitchType;

  const primaryContact = filterPrimaryContacts(companyContactOptions);
  const isAPWorkspace =
    selectedWorkspace.workspace_type === "accounting" ? path?.includes("vendor-payments") : selectedWorkspace.workspace_type === "accounts_payable";
  const viewCategory = isAPWorkspace ? VIEW_CATEGORIES.VENDOR_PAYMENTS : VIEW_CATEGORIES.CUSTOMER_PAYMENTS;
  const { formatDateBasedOnCountry: formatDate, availableLanguagesForTemplates, calendarDateFormat } = useLocale();

  const { setTotalRecords, setTotalPages, updatePageNumber, ...paginationConfig } = usePagination(
    DEFAULT_PAGINATION_VALUES.PAGE_SIZE,
    pageNoFromUrl ? pageNoFromUrl - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE : DEFAULT_PAGINATION_VALUES.PAGE_COUNT
  );
  const { query: sortQuery, ...sortConfig } = useColumnSort("payment_date DESC, reference_code DESC");
  const selectionConfig = useRowSelection(paginationConfig?.page?.totalRecords);
  const { ...initialFilterConfigs } = useColumnFilters(true, [], updatePageNumber);
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [exportData, setExportData] = useState([]);
  const csvInstance = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const [columnsConfigData, setColumnsConfigData] = useState<any[]>(() => tableConfigs.columns);
  const [isColumnsConfigDataLoading, setIsColumnsConfigDataLoading] = useState<boolean>(true);
  const { isEnabled } = React.useContext(FeatureFlagContext) as FeatureFlagProviderType;
  const isFiltersEnabled = isEnabled("FILTERS_ENABLE");
  const isExportV2Enabled = isEnabled("EXPORTS_V2");
  const [isNoSearchResultFound, setIsNoSearchResultFound] = useState<boolean>(false);
  const { updateExportedRecordsIds } = React.useContext(ExportContext) as ExportType;
  const filterObjFromUrl = JSON.parse(params.get("filters") ?? "{}");
  const appliedFilters = initialFilterConfigs.generateFilterQuery(Object.values(filterObjFromUrl), true);
  const [lastApprover, setLastApprover] = useState<To>({} as To);
  const [lastApproverloading, setLastApproverloading] = useState<boolean>(false);

  /**
   * setup common state with reducer to handle open/close states for send and send and close button
   * React useState hooks.
   */
  const initialVisibility = {
    isSendBtnDisabled: false,
  };
  const { visibility, dispatch } = useHandleVisibility<typeof initialVisibility>({ isSendBtnDisabled: false });

  const fetchCompanyInfo = (companyId: UUID) => {
    if (!companyId) {
      return;
    }
    companiesClient.getCompany(companyId, "Classification").then((companyModel: CompanyModel) => {
      setCompanyData(companyModel);
    });
  };

  const fetchCompanyContactOptions = async (customerId: string) => {
    setCustomerId(customerId);
    await setCompanyContactOptions(await getContactsOptions(customerId));
  };

  // fetch call
  const fetchParser = (fetchResult: FetchResult, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.records?.map((record: PaymentSummaryModelV2) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record.payment_id,
          }),
          ...((variant === "export" || variant === "all") && {
            reference_code: record.reference_code,
            payment_company_name: record.payment_company_name,
            tender_type: record.tender_type,
            payment_date: record.payment_date,
            payment_amount: record.payment_amount,
            invoice_count: record.invoice_list,
            customerId: record.payment_company_id,
            base_currency_payment_amount: record.base_currency_payment_amount,
            base_currency_unapplied_amount: record.base_currency_unapplied_amount,
          }),
          ...(variant === "all" && {
            disableCheckbox: undefined,
            isUnread: undefined,
          }),
        };
      }) ?? []
    );
  };

  const filterQuery = TableUtils.getFilterFromURL(params, tableConfigs.columns);

  const fetchPayments = async (variant: FetchVariant, columnFilters?: string, pageNumber?: number, pageSize?: number) => {
    const sortQueryString = TableUtils.getSortQueryString(params, sortQuery);

    /**
     * Set page fetch params to ActivityStreamSwitchContext to facilitate page switching
     */
    const fetchParams = {
      pageSize: paginationConfig.page?.pageSize ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
      page: paginationConfig.page?.pageNumber ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
      order: TableUtils.sortQueryParser(sortQueryString ?? "", true),
      filter: TableUtils.columnFilterParser(filterQuery, true, tableConfigs.preDefinedFilters),
      spamFraudArchivedTab: false,
    };
    setPlatformPageFetchParams(fetchParams as PlatformPageFetchParams);

    const filters = [TableUtils.columnFilterParser(filterQuery, true, tableConfigs.preDefinedFilters), columnFilters].filter(Boolean).join("&");

    return PaymentsClientV2.querySummaries(
      filters,
      TableUtils.sortQueryParser(sortQueryString ?? "", true),
      pageSize ?? paginationConfig.page?.pageSize ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
      pageNumber ?? paginationConfig.page?.pageNumber ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
      false
    )
      .then((fetchResult: any) => {
        return { ...fetchResult, records: fetchParser(fetchResult, variant) };
      })
      .catch((error: any) => {
        setError(true);
        setErrorMessage(APIClientUtils.buildErrorMessage(error));
      });
  };

  const queryKey = `all-payment-index-${selectedWorkspace?.workspace_type_route}`;

  const { isLoading, data: cachedData } = useQuery(
    [queryKey, paginationConfig.page?.pageNumber, paginationConfig.page?.pageSize, TableUtils.getSortQueryString(params, sortQuery), appliedFilters],
    async () => await fetchPayments("all", appliedFilters),
    {
      refetchOnMount: true,
      refetchInterval: RefetchIntervals.twoHoursDelay,
    }
  );

  useEffect(() => {
    /**
     * update the page number once user perform any action and because of that total records get's changed
     */
    const totalPages = Math.ceil(cachedData?.totalCount / cachedData?.pageSize) ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
    if ((paginationConfig.page?.pageNumber || NUMERIC_VALUES.CONSTANT_ONE) > totalPages) {
      updatePageNumber(totalPages || NUMERIC_VALUES.CONSTANT_ONE);
    }
    setTotalRecords(cachedData?.totalCount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    setTotalPages(totalPages);
    setTotalCount(cachedData?.totalCount);
    setTableData(cachedData?.records ?? []);
  }, [cachedData]);

  const fetchPaymentSummary = async () => {
    setSummaryLoading(true);
    const filters = [TableUtils.columnFilterParser(filterQuery, true, tableConfigs.preDefinedFilters), appliedFilters].filter(Boolean).join("&");

    try {
      const result = await PaymentsClientV2.getPaymentsSummary(filters);
      const data = result.data as AllPaymentsSummary;
      setSummaryItems(
        summaryConfigs.items.map((item: SummaryItemMeta) => ({
          ...item,
          value: data[item.accessor as keyof typeof data] ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
        }))
      );
    } catch (error) {
      console.error("error: ", error);
      setSummaryItems([]);
    } finally {
      setSummaryLoading(false);
    }
  };

  // check if template code is required as content-type
  const needTemplateCodeAsPayload = React.useMemo(
    () =>
      [
        TemplateTypes.AR_CREATE_CUSTOMER_PROFILE,
        TemplateTypes.AR_UPDATE_CUSTOMER_PROFILE,
        TemplateTypes.AP_CREATE_VENDOR_PROFILE,
        TemplateTypes.AP_CREATE_VENDOR_PROFILE,
      ].includes(templateName as TemplateTypes),
    [templateName]
  );

  const handleSend = async (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    attachmentIds: AttachmentIds,
    inboxAttachments = [] as TransactionItemType[],
    isSendAndClose?: boolean
  ): Promise<ActivityStream> => {
    dispatch({ type: "open", payload: "isSendBtnDisabled" });
    const getEmailArray = (emailArray: To[]) => emailArray.filter((item) => item !== undefined).map((value: To) => value.id || value.label);
    let toastOptions: ToastOptions = { open: true, severity: "success", message: ACTIVITY_SUCCESS_TOAST_MSG };
    const activityTransactions = _.uniq([...inboxAttachments, { transaction_id: paymentId, transaction_type: "payment" }], "transaction_id");
    const payload: Partial<ActivityItemEmailModel> = {
      to: getEmailArray(to),
      cc: getEmailArray(cc),
      bcc: getEmailArray(bcc),
      subject: title,
      content: body,
      email_action: EmailAction.NEW,
      workspace_id: selectedWorkspace?.id || FallbackTypes.Id,
      connection_company_id: customerId ?? "",
      attachments: attachmentIds.filter((item) => typeof item === "number"),
      content_type: ActivityFeedMethods.evaluateContentVariantPayload(newActivityType ?? "", needTemplateCodeAsPayload ? templateName : ""),
      activity_type:
        newActivityType && newActivityType == ActivityOptions.PHONE_CALL
          ? ActivityTypeEnum.PhoneCall
          : newActivityType && newActivityType === ActivityOptions.PAYMENT_REMINDER
          ? ActivityTypeEnum.Email
          : newActivityType && newActivityType === ActivityOptions.APPROVAL_REQUEST
          ? ActivityTypeEnum.Approval
          : newActivityType,
      activity_transactions: activityTransactions,
      primary_connection_id: customerId,
      attach_pdf: Utils.isThereAnyAttachment(attachmentIds, activityTransactions),
    };
    return new Promise((resolve) => {
      emailsClientV2
        .post(payload)
        .then(async (emailResponse: ActivityStream) => {
          //Only call Automation API when email is sent successfully and Template is selected
          if (templateName && emailResponse.success) {
            let reqBody = {
              to_time: toTime,
              automation_type: "email_templates",
              automation_sub_type: templateName,
              resource_type: "Activity::Email",
              resource_id: emailResponse.data.id,
            } as AutomateTimeModel;
            if (fromTime != DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
              reqBody = { ...{ from_time: fromTime }, ...reqBody };
            }
            setToTime(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
            automationClientV2.post(selectedWorkspace?.id || FallbackTypes.Id, reqBody);
          }

          TrackingUtils.trackFSData(to, "Ellipsis", "New", isSendAndClose ?? false, allContactOptions, "Payments", path, newActivityType ?? "", body);
          setShowActivity(false);
          //Fetch latest contact (newly added) options to populate in activity pop up if user tries to reply on the same
          getContactsOptions();
          resolve(emailResponse ?? []);
        })
        .catch((err: AxiosError) => {
          toastOptions.severity = "error";
          if (err.response?.status === CONNECTION_STATUS.BAD_REQUEST_400.STATUS_CODE) {
            toastOptions = { ...toastOptions, severity: "error", message: "Messages not sent. Please check that an Email Connector is connected." };
          } else {
            const errorResponse = err.response as AxiosErrorResponseData;
            toastOptions = { ...toastOptions, message: errorResponse?.data?.messages?.errors[0] ?? "Messages not sent." };
          }
        })
        .finally(() => {
          setToastOptions(toastOptions);
          dispatch({ type: "close", payload: "isSendBtnDisabled" });
        });
    });
  };

  const handleSendAndMarkClosed = async (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    attachmentIds: AttachmentIds,
    inboxAttachments: TransactionItemType[]
  ) => {
    const sendEmailResponse = await handleSend(to, cc, bcc, title, body, attachmentIds, inboxAttachments, true);
    if (sendEmailResponse.success) {
      let toastOptions: ToastOptions = { open: true, severity: "success", message: ACTIVITY_COMPLETE_SUCCESS };
      let response = {} as APIResponse;

      try {
        response = await handleClose(sendEmailResponse.data.activity_stream.id, "");
      } catch (e: unknown) {
        response.success = false;
      } finally {
        if (!response.success) {
          toastOptions = {
            ...toastOptions,
            severity: "error",
            message: ACTIVITY_COMPLETE_FAILURE,
          };
        }
        setToastOptions(toastOptions);
      }
    }
  };

  // Handles redirect to Payment Details page on row click of the Data Grid
  const handleRowClick = (e: MouseEvent<HTMLTableRowElement>, row: { id?: string }, currentPageRecords?: { id: string; isSelected?: boolean }[]) => {
    /**
     * If a row item is already selected, then clicking anywhere on other rows should select those rows, and do not navigate.
     * if double clicked, then navigate to those row records
     */
    const clickType = e.detail;
    if (clickType === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE && selectionConfig.tableRecords?.selectedRecordsIds?.length && row.id) {
      selectionConfig?.onClickTableRow(row?.id, currentPageRecords ?? []);
      return;
    }

    /**
     * Set the following properties into DocumentSwitchContext
     * to facilitate document switching.
     * @param selectedActivityStreamId - currently selected document id
     * @param activityStreamIds - list of documents in the index
     * @param paginationState - current pagination state for reference
     */
    setEnableDocumentSwitch(true);
    setSelectedActivityStreamId({ id: row.id } as ActivityStreamId);
    setActivityStreamIds(tableData.map((item) => ({ id: item.id })));
    setCurrentPaginationState({
      page: paginationConfig.page?.pageNumber ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
      pageCount: paginationConfig.page?.totalPages ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
      pageSize: paginationConfig.page?.pageSize ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
      totalRecords: totalCount,
    });
    setDocumentType("payment");
    setRootSwitcherStateSnapshot();
    sessionStorage.setItem("lastPath", history.location.pathname);
    sessionStorage.setItem("lastPage", (paginationConfig.page?.pageNumber ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO).toString());

    /** handle multiple types of payment routes */
    let pathToPush!: string;
    if (path.includes("customer-payments")) {
      pathToPush = `${workspaceHomePath}/customer-payments/${row.id ?? ""}`;
    } else if (path.includes("vendor-payments")) {
      pathToPush = `${workspaceHomePath}/vendor-payments/${row.id ?? ""}`;
    } else if (path.includes("payments")) {
      pathToPush = `${workspaceHomePath}/payments/${row.id ?? ""}`;
    } else {
      const route = getBaseRoute();
      pathToPush = `${route}/payments/${row.id}`;
    }

    history.push(pathToPush);
    updateBaseRoute(pathToPush);
  };

  useEffect(() => {
    fetchCompanyInfo(userStatus.account_company_id as string);
    if (summaryConfigs) {
      fetchPaymentSummary();
    }
    return () => {
      setTotalCount(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    };
  }, [appliedFilters]);

  /**
   * Function is used to prepare the invoice data which need's to show in templates.
   *
   * @param {string} workspaceType The workspaceType indicate which works space is currently selected.
   * @param {TemplateTypes} templateID The templateID indicate which template is selected by user from subject dropdown.
   * @param {PaymentDetailViewResponse[]} invoices the invoice
   * @returns {}
   */
  const prepareInvoiceDataForTemplate = (workspaceType: string | undefined | null, invoices: PaymentDetailViewResponse[]): Record<string, any> => {
    let invoiceInfo: Record<string, any> = [];
    const isAccountReceivable = workspaceType === "accounts_receivable";
    if (invoices) {
      invoiceInfo = invoices.map((item, index) => {
        const props = {
          title: `Invoice- ${++index}`,
          items: [
            {
              key: isAccountReceivable ? "Invoice number" : "Bill number",
              value: item.invoice.referenceCode,
            },
            {
              key: "Due date",
              value: formatDate(item?.invoice?.paymentDueDate ?? ""),
            },
            {
              key: isAccountReceivable ? "Amount paid" : "Paid amount",
              value: Utils.formatValueAsCurrency(
                item.paymentAppliedAmount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
                userStatus?.currency?.locale,
                userStatus?.currency?.code
              ),
            },
            isAccountReceivable
              ? {
                  key: "Invoice status",
                  value: item.invoice.invoiceStatusCode,
                }
              : "",
          ],
        };

        return props;
      });
    }
    return invoiceInfo;
  };

  /**
   * Function which convert the template string to editor content state.
   *
   * @param {string} templateID The templateID selected by user from the subject drop down.
   * @param {EditorState} EditorState The setEditorState will update the email body.
   */
  const prepareActivityBodyByTemplateID = async (
    templateID: string | null,
    setEditorState: React.Dispatch<React.SetStateAction<string>>,
    lang: string
  ) => {
    if (templateID) {
      setTemplateName(templateID);
      const workspaceType = selectedWorkspace.workspace_type;
      let invoiceInfo: Record<string, any> = [];
      if (templateID !== TemplateTypes.PAYMENT_CONFIRMATION_REQUEST) {
        const invoices = await getDetailViewInvoices(`PaymentId eq ${item.id}`, "invoice");
        invoiceInfo = prepareInvoiceDataForTemplate(workspaceType, invoices);
      }
      const templateObj = templateData.get(selectedWorkspace?.id)?.get(lang)?.get(templateID);
      setFromTime(templateObj.getFromTime);
      setToTime(templateObj.getToTime);
      setEditorState(
        templateObj.parseTemplate({
          customer: item?.payment_company_name || "",
          vendor: item?.payment_company_name || "",
          paymentNumber: item?.reference_code || "",
          paymentDate: formatDate(item?.payment_date_original ?? "") || "",
          paymentDueDate: formatDate(item?.paymentDueDate ?? "") || "",
          totalPaymentAmount: Utils.formatValueAsCurrency(
            item.payment_amount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            userStatus?.currency?.locale,
            item.transaction_currency_code
          ),
          totalPaymentsApplied: Utils.formatValueAsCurrency(
            item.total_payments_applied ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            userStatus?.currency?.locale,
            item.transaction_currency_code
          ),
          transaction_currency_code: item.currency_code,
          emailAddress: companyData?.emailAddress || "",
          companyName: companyData?.companyName || "",
          phone: companyData?.phoneNumber || "",
          invoices: invoiceInfo,
          signature: signature.email_signature || "",
          // TODO come up with a more user friendly key names for the invoices list - this will worked on later
          userName: `${userStatus?.user_name ?? "{Your Name}"}`,
          contactName: primaryContact[0]?.label ?? item?.payment_company_name,
          workspaceName: `${selectedWorkspace?.name}`,
        })
      );
    }
  };

  /**
   * Event handler which triggered when user select the 'email', 'note' or 'phone call'
   * context menu item. It will open the new activity dialog based to the context menu item
   * selected by the user.
   *
   * @param {Object} row The selected row from the table.
   * @param {String} buttonId The buttonId indicate which button has been selected by the user.
   */
  const onClickActionButton = async (row: any, buttonId: string) => {
    setRequiredTemplates({
      [ActivityOptions.EMAIL]: templateFactory.getTemplateDefinitionsByView(
        isAPWorkspace ? viewType.AP_TRANSACTION_PAYMENTS : viewType.AR_TRANSACTION_PAYMENTS,
        {
          paymentNumber: row.reference_code,
          customer: row.payment_company_name,
          vendor: row.payment_company_name,
          //Passing first invoice number from the list of invoices assuming its the latest
          invoiceNumber: row.invoices ? row?.invoices[0] : "",
          companyName: loginUserCompany?.companyName ?? "",
        },
        availableLanguagesForTemplates
      ),
      [ActivityOptions.APPROVAL_REQUEST]: templateFactory.getTemplateDefinitionsByView(
        isAPWorkspace ? viewType.AP_APPROVAL_REQUEST_ACTIVITY : viewType.AR_APPROVAL_REQUEST_ACTIVITY,
        "",
        availableLanguagesForTemplates
      ),
    });
    setItem(row);
    await fetchCompanyContactOptions(row.customerId);
    setPaymentId(row.id);
    setNewActivityType(buttonId);
    setShowActivity(true);
  };

  useEffect(() => {
    // set appropriate template list based on activity type selected
    setSupportedTemplateList(requiredTemplates?.[newActivityType ?? ""] ?? []);
    // rest the selected template code
    setTemplateName("");
  }, [newActivityType]);

  /** enables popup to decide the default template id based on activity
   *  type without maintaining a state inside the component
   **/
  const getDefaultTemplateID = (newActivityType: ActivityOptions | string) => {
    if (newActivityType === ActivityOptions.APPROVAL_REQUEST) {
      return isAPWorkspace ? TemplateTypes.AP_APPROVAL_REQUEST : TemplateTypes.AR_APPROVAL_REQUEST;
    } else {
      return "";
    }
  };

  // table configuration
  const ellipsisActionHandlers: Partial<RowSelectButtons> = {
    "action.newEmail": onClickActionButton,
    "action.newNote": onClickActionButton,
    "action.newPhoneCall": onClickActionButton,
    "action.newApprovalRequest": onClickActionButton,
  };

  const ellipsisActions: RowSelectButtons = tableConfigs.ellipsisActions.map((action: EllipsisAction) => {
    return { ...action, clickFunction: ellipsisActionHandlers[action.handler] };
  });

  const cellRendererMapping: CellRendererMappingObject = {
    "RowDataTypes.BoldText": RowDataTypes.BoldText,
    "RowDataTypes.DateEpoch": RowDataTypes.DateEpoch,
    "RowDataTypes.ActionsDropdown": RowDataTypes.ActionsDropdown,
    "RowDataTypes.Currency": RowDataTypes.Currency,
    "RowDataTypes.FileWithCounts": RowDataTypes.FileWithCounts,
  };

  const filteredContacts = [...companyContactOptions, ...allContactOptions];

  useEffect(() => {
    if (
      (exportData?.length ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO &&
      csvInstance &&
      csvInstance.current &&
      csvInstance.current.link
    ) {
      setTimeout(() => {
        csvInstance?.current?.link.click();
        setExportData([]);
      });
    }
  }, [exportData]);

  const getFilters = (exportInvoice = false, ids?: string[]) => {
    const additionalFilters: Array<Record<string, string>> = [];
    // evaluate select filter on export
    if (exportInvoice && !selectionConfig?.isAllRecordsSelected && ids && ids.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      const { hasAllRecordsSelectedButtonClicked, isAllRecordsSelected, tableRecords } = selectionConfig;

      const exportIds =
        hasAllRecordsSelectedButtonClicked?.current && !isAllRecordsSelected
          ? tableRecords?.allRecords?.filter((item) => !item?.isSelected)?.map((deselectedItem) => deselectedItem?.id)
          : ids;

      exportIds.forEach((id) => {
        const searchlightFilter = `qa[payment_id_${hasAllRecordsSelectedButtonClicked?.current && !isAllRecordsSelected ? "not_in" : "in"}][]=${id}`;
        additionalFilters.push({
          searchlightFilter,
        });
      });
    }

    return TableUtils.columnFilterParser(filterQuery, true, additionalFilters?.length ? [...additionalFilters] : []);
  };

  const handleExport = async (ids: string[]) => {
    const filters = [
      TableUtils.columnFilterParser(filterQuery, true, tableConfigs.preDefinedFilters),
      getFilters(true, ids),
      initialFilterConfigs?.filterQuery,
    ]
      .filter(Boolean)
      .join("&");

    let response: any = {};
    try {
      setExportLoading(true);
      response = await PaymentsClientV2.exportPayments(filters);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setExportData(response);
      setExportLoading(false);
    }
  };

  const handleExportV2 = async (ids: string[]) => {
    const filters = [TableUtils.columnFilterParser(filterQuery, true, tableConfigs.preDefinedFilters), getFilters(true, ids), appliedFilters]
      .filter(Boolean)
      .join("&");

    let response: any = {};
    try {
      setExportLoading(true);
      const isExportAllRecords =
        (selectionConfig?.tableRecords?.selectedRecordsIds?.length ?? NUMERIC_VALUES.CONSTANT_ZERO) > NUMERIC_VALUES.CONSTANT_ZERO
          ? selectionConfig.isAllRecordsSelected || !!selectionConfig?.hasAllRecordsSelectedButtonClicked?.current
          : true;
      const export_type = isAPWorkspace ? EXPORT_CATEGORIES.AP_PAYMENTS : EXPORT_CATEGORIES.AR_PAYMENTS;
      const sortQueryString = TableUtils.getSortQueryString(params, sortQuery);

      response = await exportClient.exportRecords(export_type, isExportAllRecords, filters, TableUtils.sortQueryParser(sortQueryString ?? "", true));
      if (response.success) {
        setToastOptions({ open: true, severity: "success", message: ExportToastMessage.EXPORT_IN_PROGRESS });
        updateExportedRecordsIds([response.data?.export_request_id], false);
      }
    } catch (error) {
      setToastOptions({ open: true, severity: "error", message: ExportToastMessage.EXPORT_FAILED });
    } finally {
      setExportLoading(false);
    }
  };

  const rowSelectBtns: UserAction[] = [
    {
      children: "Export",
      icon: <ExportV2 />,
      loading: exportLoading,
      callback: async (ids: string[]): Promise<void> => {
        isExportV2Enabled ? await handleExportV2(ids) : await handleExport(ids);
      },
      visible: {
        onEllipsis: false,
        onHeader: false,
        onHover: false,
        onCaption: true,
      },
    } as UserAction,
  ];

  const getPreviousSuggestedData = (recentSearchHistory: FilterDropdownSuggestionRef) => {
    const { filterSearchedText } = initialFilterConfigs ?? {};
    const { reference_code, payment_company_name } = filterSearchedText ?? {};

    return {
      paymentNumber:
        reference_code?.value?.length >= NUMERIC_VALUES.CONSTANT_THREE
          ? recentSearchHistory?.reference_code?.filter((item) => initialFilterConfigs?.activeFilters?.reference_code?.ids?.includes(item.id))
          : recentSearchHistory?.reference_code,
      customerName:
        payment_company_name?.value?.length >= NUMERIC_VALUES.CONSTANT_THREE
          ? recentSearchHistory?.payment_company_name?.filter((item) =>
              initialFilterConfigs?.activeFilters?.payment_company_name?.ids?.includes(item.id)
            )
          : recentSearchHistory?.payment_company_name,
    };
  };

  //it will return maximum of 5 required formated record for dropdown search suggestion or result data
  const getDropdownSearchFilterData = (activitiesTableData: any) => {
    const requiredColumns = [...tableConfigs.columns];
    const { filterSearchedText = {}, getSearchSuggestionHistory } = initialFilterConfigs || {};
    const { reference_code, payment_company_name } = filterSearchedText;
    const recentPaymentsSearchedData = getSearchSuggestionHistory(
      selectedWorkspace.workspace_type_route?.toLocaleLowerCase() ?? WorkspaceType.AR,
      viewCategory
    );
    const previousData = getPreviousSuggestedData(recentPaymentsSearchedData);

    /**
     * if user searching for result then return result data along with previous selected data
     * else return max of 5 recent searched data if that is exist
     */
    const paymentNumberData =
      !reference_code?.value || reference_code?.value.length < NUMERIC_VALUES.CONSTANT_THREE
        ? (previousData?.paymentNumber ?? []).slice(NUMERIC_VALUES.CONSTANT_ZERO, NUMERIC_VALUES.CONSTANT_FIVE)
        : [...(previousData?.paymentNumber ?? []), ...(activitiesTableData ?? [])];

    const customerNameData =
      !payment_company_name?.value || payment_company_name?.value.length < NUMERIC_VALUES.CONSTANT_THREE
        ? (previousData?.customerName ?? []).slice(NUMERIC_VALUES.CONSTANT_ZERO, NUMERIC_VALUES.CONSTANT_FIVE)
        : [...(previousData?.customerName ?? []), ...(activitiesTableData ?? [])];

    //formatting data for th dropdown suggestion list
    const paymentNumberDropdownList = paymentNumberData
      .filter(({ reference_code, primaryText }) => reference_code || primaryText)
      .map(({ id, reference_code, primaryText }) => ({
        id,
        primaryText: reference_code ?? primaryText,
        isAttachemntRequired: false,
        selected: initialFilterConfigs?.activeFilters?.reference_code?.ids?.includes(id) ?? false,
        secondaryText: "",
        isAvatarRequired: false,
      }));

    const customerNameDropdownList = customerNameData
      .filter(({ payment_company_name, primaryText }) => payment_company_name || primaryText)
      .map(({ id, payment_company_name, primaryText }) => ({
        id,
        primaryText: payment_company_name ?? primaryText,
        isAttachemntRequired: false,
        selected: initialFilterConfigs?.activeFilters?.payment_company_name?.ids?.includes(id) ?? false,
        secondaryText: "",
        isAvatarRequired: true,
      }));

    /*
     * This code first creates a Map object by mapping each object in the original array to an array with two elements:
     * the key and the value. The Map constructor can then be called with the resulting array of key-value pairs to create
     * a new Map object. Finally, the Array.from method is used to convert the Map object back to an array, where the map
     * function is used to only return the values from the key-value pairs.
     */

    const uniquePaymentNumberSuggestions = Array.from(new Map(paymentNumberDropdownList.reverse().map((obj) => [obj.primaryText, obj])))
      .reverse()
      .map(([, value]) => value);

    const uniqeCustomerNameSuggestions = Array.from(new Map(customerNameDropdownList.reverse().map((obj) => [obj.primaryText, obj])))
      .reverse()
      .map(([, value]) => value);

    return requiredColumns.map((column) => {
      if (column.accessor === "reference_code") {
        return { ...column, dropdownSearchListItems: uniquePaymentNumberSuggestions };
      } else if (column.accessor === "payment_company_name") {
        return { ...column, dropdownSearchListItems: uniqeCustomerNameSuggestions };
      }
      return column;
    });
  };

  function createFilterQuery(field: string, value: string) {
    if (value && value.length >= NUMERIC_VALUES.CONSTANT_THREE) {
      return `qa[${field}_cont_any]=${encodeURIComponent(value)}`;
    }
    return "";
  }

  /**
   * fetch last_approver for current connection
   */
  const getLastApprover = () => {
    setLastApproverloading(true);
    activitiesClientV2
      .getLastApprover(selectedWorkspace?.id, item.customerId)
      .then((response) => {
        if (response?.data?.last_approver?.email_address) {
          setLastApprover({
            id: response.data.last_approver.email_address || "",
            label: response.data.last_approver.contact_name || "",
            primary: false,
            connectionId: response.data.last_approver.company_id || "",
            erpContact: false,
            companyId: response.data.last_approver.company_id || "",
          });
        } else {
          setLastApprover({} as To);
        }
      })
      .finally(() => {
        setLastApproverloading(false);
      });
  };

  useEffect(() => {
    if (newActivityType === ActivityOptions.APPROVAL_REQUEST && showActivity) {
      getLastApprover();
    } else {
      setLastApprover({} as To);
    }
  }, [newActivityType, showActivity]);

  useEffect(() => {
    const { filterSearchedText } = initialFilterConfigs || {};
    const { reference_code, payment_company_name } = filterSearchedText || {};
    let filters = "";

    if (reference_code?.value?.length >= NUMERIC_VALUES.CONSTANT_THREE) {
      const paymentQuery = createFilterQuery("reference_code", reference_code.value);
      filters += paymentQuery;
    }

    if (payment_company_name?.value?.length >= NUMERIC_VALUES.CONSTANT_THREE) {
      const customerNameQuery = createFilterQuery("payment_company_name", payment_company_name.value);
      filters += (filters ? "&" : "") + customerNameQuery;
    }

    setIsColumnsConfigDataLoading(true);

    if (filters) {
      fetchPayments("all", filters, NUMERIC_VALUES.CONSTANT_ONE, NUMERIC_VALUES.CONSTANT_TWENTY)
        .then((res) => {
          setIsNoSearchResultFound(!res.totalCount);
          setColumnsConfigData(getDropdownSearchFilterData(res.records));
        })
        .catch((error) => {
          setIsNoSearchResultFound(true);
          console.log(error);
        })
        .finally(() => {
          setIsColumnsConfigDataLoading(false);
        });
    } else {
      setColumnsConfigData(getDropdownSearchFilterData([]));
      setIsColumnsConfigDataLoading(false);
    }
  }, [initialFilterConfigs?.filterSearchedText, initialFilterConfigs?.filterQuery]);

  //return {key:value} pair for template subject to parse the values
  const getSelectedTempSubjSnippetValues = () => {
    return {
      paymentNumber: item?.reference_code ?? "",
      customer: item?.payment_company_name ?? "",
      vendor: item?.payment_company_name ?? "",
      //Passing first invoice number from the list of invoices assuming its the latest
      invoiceNumber: item?.invoices ? item?.invoices[0] : "",
      companyName: loginUserCompany?.companyName ?? "",
    };
  };

  //return suggested template codes based on certain condition
  const getSuggestedTemplatesCode = () => {
    if (newActivityType === ActivityOptions.APPROVAL_REQUEST) {
      return isAPWorkspace
        ? getViewTypeTemplateCodes(viewType.AP_APPROVAL_REQUEST_ACTIVITY)
        : getViewTypeTemplateCodes(viewType.AR_APPROVAL_REQUEST_ACTIVITY);
    }

    if (isAPWorkspace) {
      return getViewTypeTemplateCodes(viewType.AP_TRANSACTION_PAYMENTS);
    } else {
      return getViewTypeTemplateCodes(viewType.AR_TRANSACTION_PAYMENTS);
    }
  };

  //using caching data to update the footer at the same time
  const updatedPaginationObject = {
    ...paginationConfig,
    ...TableUtils.getUpdatePaginationPageState(paginationConfig, cachedData?.totalCount, cachedData?.pageSize),
  };

  return (
    <div className="table-wrapper">
      {summaryConfigs && (
        <SummaryHeader items={summaryItems} isLoading={summaryLoader} itemCount={summaryConfigs.items.length} currency={userStatus?.currency} />
      )}
      <div className="body-table">
        <Table
          configs={{
            //Added the feature flag for the newly added filters excpet which are already there on Production
            columnConfigs: columnsConfigData?.map((item) => {
              if (["tender_type", "payment_date", "base_currency_payment_amount", "actionHeader", "invoice_count"].includes(item.accessor)) {
                return { ...item, rowDataType: cellRendererMapping[item?.rowDataType] };
              } else {
                return { ...item, rowDataType: cellRendererMapping[item?.rowDataType], enableFilter: isFiltersEnabled };
              }
            }),
            initialTableConfigs: {
              name: "Payments",
              type: "Payment",
              footerTableTypeText: "Payment",
              loading: isLoading,
            },
            paginationConfigs: { ...updatedPaginationObject, enableNavigator: true, enableSummary: true },
            initialSortConfigs: sortConfig,
            selectionConfig: selectionConfig,
            initialFilterConfigs: {
              ...initialFilterConfigs,
              dateFormat: calendarDateFormat,
              isSuggestionDataLoading: isColumnsConfigDataLoading,
              category: viewCategory,
              workspace: selectedWorkspace.workspace_type_route?.toLocaleLowerCase() ?? WorkspaceType.AR,
              isNoSearchResultFound,
            },
            currencyConfig: {
              locale: userStatus?.currency?.locale,
              code: userStatus?.currency?.code,
              symbol: userStatus?.currency?.symbol,
            },
          }}
          rows={(cachedData?.records ?? [])?.map((rec: any) => {
            return {
              ...rec,
              read: true,
              payment_date: new Date(rec.payment_date).getTime() / DEFAULT_NUMERIC_VALUES.DEFAULT_THOUSAND,
              payment_date_original: rec.payment_date,
            };
          })}
          handlers={{
            onRowClick: handleRowClick,
          }}
          emptyMessage={{
            title: NO_DATA_MESSAGE,
            caption: EMPTY_PAYMENT_CAPTION_MESSAGE,
          }}
          secondaryRowActions={ellipsisActions}
          userActions={rowSelectBtns}
          hoverBar={false}
          visibility={{
            exportBtn: false,
            hoverbar: false,
          }}
        />
      </div>
      {showActivity && (
        <NewActivityPopup
          key={`key-add-activity-${newActivityType} loading-${lastApproverloading}`}
          title={BTN_NEW_ACTIVITY}
          open={showActivity}
          contactOptions={filteredContacts}
          defaultTo={
            newActivityType === ActivityOptions.APPROVAL_REQUEST
              ? [lastApprover]
              : !_.isEmpty(primaryContact)
              ? primaryContact
              : !_.isEmpty(companyContactOptions)
              ? [companyContactOptions[0]]
              : []
          }
          handleSend={handleSend}
          handleSendMarkClosed={handleSendAndMarkClosed}
          isTemplateSupport={newActivityType === ActivityOptions.EMAIL || newActivityType === ActivityOptions.APPROVAL_REQUEST}
          supportedTemplateList={supportedTemplateList}
          onClose={() => {
            setShowActivity(false);
            setEditorState("");
          }}
          editorState={editorState}
          activityType={newActivityType}
          setNewActivityType={setNewActivityType}
          addActivityDropdown={[
            { activityType: "Email", displayName: "Email", icon: <Email /> },
            { activityType: "Note", displayName: "Note", icon: <NoteFill /> },
            { activityType: "Phone Call", displayName: "Phone Call", icon: <Phone /> },
            { activityType: "Approval Request", displayName: "Approval Request", icon: <Pen /> },
          ]}
          prepareActivityBodyByTemplateID={prepareActivityBodyByTemplateID}
          fromTime={fromTime}
          toTime={toTime}
          setToTime={setToTime}
          disableSecondaryAction={newActivityType === ActivityOptions.APPROVAL_REQUEST}
          getDefaultTemplateId={getDefaultTemplateID}
          enableKeyboardShortcuts={true}
          loading={lastApproverloading}
          isSendButtonDisabled={visibility.isSendBtnDisabled}
          suggestedTemplatesCode={getSuggestedTemplatesCode()}
          tempSubjectSnippetValues={getSelectedTempSubjSnippetValues()}
        />
      )}
      {(exportData?.length ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO ? (
        <CSVLink data={exportData} filename={`${viewConfigs.exportFileName}`} ref={csvInstance} style={{ display: "none" }} />
      ) : undefined}
    </div>
  );
}

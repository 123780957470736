/*eslint-disable no-magic-numbers*/
import { styled } from "@mui/system";
import { pxToRem } from "../../theme/theme";

export const EmailChip = styled("div")({
  color: "#4C589E",
  border: `${pxToRem(1)} solid #4C589E`,
  borderRadius: pxToRem(4),
  background: "white",
  height: pxToRem(20),
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: `${pxToRem(2)} ${pxToRem(8)}`,
  svg: { "margin-right": pxToRem(4) },
  fontWeight: "400",
  fontSize: "0.75rem",
  lineHeight: "1rem",
});

export const ApprovalChip = styled("div")({
  color: "#37833B",
  border: `${pxToRem(1)} solid #37833B`,
  borderRadius: pxToRem(4),
  background: "white",
  height: pxToRem(20),
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: `${pxToRem(2)} ${pxToRem(8)}`,
  svg: { "margin-right": pxToRem(4) },
  fontWeight: "400",
  fontSize: "0.75rem",
  lineHeight: "1rem",
});

export const NoteChip = styled("div")({
  color: "#787885",
  border: `${pxToRem(1)} solid #787885`,
  borderRadius: pxToRem(4),
  background: "white",
  height: pxToRem(20),
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: `${pxToRem(2)} ${pxToRem(8)}`,
  svg: { marginRight: pxToRem(4) },
  fontWeight: "400",
  fontSize: "0.75rem",
  lineHeight: "1rem",
});

export const RaiseDispute = styled("div")({
  color: "#C23564",
  border: `${pxToRem(1)} solid #C23564`,
  borderRadius: pxToRem(4),
  background: "white",
  height: pxToRem(20),
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: `${pxToRem(2)} ${pxToRem(8)}`,
  svg: { marginRight: pxToRem(4) },
  fontWeight: "400",
  fontSize: "0.75rem",
  lineHeight: "1rem",
});

export const PromiseToPay = styled("div")({
  color: "#CE6B00",
  border: `${pxToRem(1)} solid #CE6B00`,
  borderRadius: pxToRem(4),
  background: "white",
  height: pxToRem(20),
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: `${pxToRem(2)} ${pxToRem(8)}`,
  svg: { marginRight: pxToRem(4) },
  fontWeight: "400",
  fontSize: "0.75rem",
  lineHeight: "1rem",
});

export const EInvoice = styled("div")({
  color: "#37833B",
  border: `${pxToRem(1)} solid #37833B`,
  borderRadius: pxToRem(4),
  background: "white",
  height: pxToRem(20),
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: `${pxToRem(2)} ${pxToRem(8)}`,
  svg: { marginRight: pxToRem(4) },
  fontWeight: "400",
  fontSize: "0.75rem",
  lineHeight: "1rem",
});
import { Checkbox } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { SmartText } from "../SmartText";
import { NUMERIC_VALUES } from "../../../constants/NumericConstants";
import { ColumnConfigInterface, TableComponentInterface } from "./Interfaces/TableInterface";
import Filter from "./TableActions/Filter/Filter";
import Sort from "./TableActions/Sort/Sort";

const TableComponent: React.FC<TableComponentInterface> = ({
  tableConfig: {
    tableRef,
    checkboxes: { enableSelection },
    header,
    column,
    rows,
    formatter,
    loading,
    highlighter,
    handlers: { onClickRowHandler, onClickSort, onClickFilter, onClickSelect, onClickSelectAll },
  },
}: TableComponentInterface) => {
  const processTableCellValue = (data: any, fieldType: string, fieldName: string) => {
    if (["currency", "number"].includes(fieldType)) {
      return formatter(data[fieldName], fieldType) as string;
    }
    return data[fieldName] ? (formatter(data[fieldName], fieldType) as string) : "-";
  };

  return (
    <div className="table-with-infinite-scroll-container">
      <div className="table-with-infinite-scroll-header">
        <div className={`${header?.classes ? header.classes : "table-with-infinite-scroll-header-title"}`}>{header?.title}</div>
      </div>
      <div className="table-with-infinite-scroll-main-container">
        <TableContainer ref={tableRef}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {enableSelection && (
                  <TableCell padding="checkbox" className="header-cell-checkbox" width="7%">
                    <Checkbox color="primary" checked={header?.selectedAll} onChange={(e) => onClickSelectAll?.(e)} />
                  </TableCell>
                )}
                {column?.map((col: ColumnConfigInterface, index: number) => (
                  <TableCell
                    key={col?.header + index}
                    style={{
                      zIndex: header.currentActiveFilterIndex === index ? NUMERIC_VALUES.CONSTANT_FIFTY : NUMERIC_VALUES.CONSTANT_TEN,
                      width: col?.width,
                    }}
                    align={col?.alignment}
                  >
                    <div
                      className="header-cell-group"
                      style={{
                        justifyContent: col?.alignment ?? "flex-start",
                      }}
                    >
                      <div className="header-cell-group-title">
                        <SmartText title={col?.header} className="header-cell-group-title" />
                      </div>
                      <div className="header-cell-group-icon">
                        {col?.sortable && <Sort disable={loading} field={col?.fieldName} onClickSort={onClickSort?.bind(this)} />}
                        {col?.filterable && col?.filter && (
                          <Filter
                            field={col?.filter.field ?? col?.fieldName}
                            onPopupActive={header.setCurrentFilterIndex.bind(this, index)}
                            onClickFilter={onClickFilter?.bind(this)}
                            {...col?.filter}
                          />
                        )}
                      </div>
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((data: any, index: number) => (
                <TableRow key={`row-number-${index}`} onClick={onClickRowHandler?.bind(this, data)}>
                  {enableSelection && (
                    <TableCell padding="checkbox" width="10%">
                      <Checkbox color="primary" onChange={(e) => onClickSelect?.(e, data)} checked={data?.isSelected} />
                    </TableCell>
                  )}
                  {column?.map((col: ColumnConfigInterface, pos: number) => (
                    <TableCell align={col?.alignment} width={col?.width} key={col?.fieldName + pos} component="th" scope="row">
                      <div className="infinite-scroll-table-smart-text">
                        {col?.fieldType === "HTMLElement" ? (
                          formatter(data[col?.fieldName], col?.elementType)
                        ) : (
                          <SmartText
                            highlighter={{
                              enableHighlighter: highlighter?.enableHighlighter ?? false,
                              matchText: highlighter?.matchText ?? "~",
                            }}
                            title={processTableCellValue(data, col?.fieldType, col?.fieldName)}
                            className="infinite-scroll-table-data-cell"
                          />
                        )}
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default TableComponent;

import React, { useRef } from "react";
import useGlobalSearchStyle from "../Styles/useGlobalSearchStyles";
import { Search } from "../../library/Icons/Icons";
import { Divider, Input, MenuItem, Select, Tab, Tabs } from "@mui/material";
import { DialogHeaderInterface } from "../Interface/SearchResultContentInterface";
import { GLOBAL_SEARCH_STATIC_TEXTS } from "../../../constants/GlobalSearchConstants";
import { NUMERIC_VALUES } from "../../../constants/NumericConstants";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const GlobalSearchDialogHeader: React.FC<DialogHeaderInterface> = ({
  rootProps: { contentScrollTop },
  inputProps: { inputRef, value, onChangeHandlerForInput, onFocusHandler, onBlurHandler, onKeyDownHandler, placeholder },
  tabProps: { selectedTabValue, handlClickTabs, tabLabels },
  resultHeaderProps: { showResultHeader, searchSortValue, handleSortDropdownChange, isEInvoice, },
}) => {
  const { searchHeadContainer, searchInput, tabContainer, tabs, resultHeaderContainer, select, menuRoot, scrollHeaderStatic } =
    useGlobalSearchStyle({isEInvoice});
  const selectRefEl = useRef(null);
  const { t } = useTranslation();

  return (
    <div className={contentScrollTop > NUMERIC_VALUES.CONSTANT_ZERO ? scrollHeaderStatic : ""}>
      {/* Search Container */}
      <div className={searchHeadContainer}>
        <Search />
        <Input
          inputRef={inputRef}
          value={value}
          placeholder={placeholder ?? GLOBAL_SEARCH_STATIC_TEXTS.GLOBAL_SEARCH_PLACEHOLDER}
          disableUnderline={true}
          classes={{ input: searchInput }}
          onChange={onChangeHandlerForInput}
          onFocus={onFocusHandler}
          onKeyDown={onKeyDownHandler}
          onBlur={onBlurHandler}
          className={searchInput}
          autoFocus={true}
        />
      </div>

      {/* Tabs Conatiner */}
      <div className={tabContainer}>
        <Tabs value={selectedTabValue} onChange={handlClickTabs} className={tabs}>
          {tabLabels?.map((item, i) => {
            return <Tab key={i} label={item} value={item} />;
          })}
        </Tabs>
      </div>

      <Divider />

      {/* Result Header Container */}
      {showResultHeader && (
        <div className={resultHeaderContainer}>
          <span>
            {i18next.exists("globalSearchSuggestions.resultsHeader", { ns: "global_search" })
              ? t("globalSearchSuggestions.resultsHeader", { ns: "global_search" })
              : GLOBAL_SEARCH_STATIC_TEXTS.RESULTS}
          </span>

          <Select
            ref={selectRefEl}
            id="result-sort-select"
            value={searchSortValue}
            onChange={handleSortDropdownChange}
            className={select}
            MenuProps={{
              className: menuRoot,
              anchorReference: "anchorEl",
              anchorEl: selectRefEl.current,
            }}
          >
            <MenuItem value={GLOBAL_SEARCH_STATIC_TEXTS.RELEVANT.toLowerCase()}>
              {i18next.exists("globalSearchSuggestions.sort.relevant", { ns: "global_search" })
                ? t("globalSearchSuggestions.sort.relevant", { ns: "global_search" })
                : GLOBAL_SEARCH_STATIC_TEXTS.RELEVANT}
            </MenuItem>
            <MenuItem value={GLOBAL_SEARCH_STATIC_TEXTS.RECENT.toLowerCase()}>
              {i18next.exists("globalSearchSuggestions.sort.recent", { ns: "global_search" })
                ? t("globalSearchSuggestions.sort.recent", { ns: "global_search" })
                : GLOBAL_SEARCH_STATIC_TEXTS.RECENT}
            </MenuItem>
          </Select>
        </div>
      )}
    </div>
  );
};

export default GlobalSearchDialogHeader;

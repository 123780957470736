import BaseTemplate from "./BaseTemplate";
import { Liquid } from "liquidjs";
import { IData } from "./TemplateTypes";
import { TEMPLATE_TIMING_CONSTANTS } from "../../constants/NumericConstants";

class ShareAPProfile extends BaseTemplate {
  /**
   * Constructor
   * @param data
   * @param liquid
   */

  constructor(workSpace: string, bodyTpl: string, liquid: Liquid, data?: IData) {
    super(workSpace, bodyTpl, liquid, data);
    this.fromTime = TEMPLATE_TIMING_CONSTANTS.THREE_MIN_THIRTY_SEC;
    this.toTime = TEMPLATE_TIMING_CONSTANTS.FIVE_MIN;
  }
}

export default ShareAPProfile;

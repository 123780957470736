import React, { useState, MouseEvent } from "react";
import { Box, IconButton, Menu, MenuItem, SvgIcon } from "@mui/material";
import { ReactComponent as HubIcon } from "../../../assets/Hub.svg";

import { ReactComponent as AccountOutline } from "../../../assets/Account_outline.svg";
import { ReactComponent as AccountFilled } from "../../../assets/Account_filled.svg";
import { ReactComponent as InboxOutline } from "../../../assets/Inbox_outline.svg";
import { ReactComponent as InboxtFilled } from "../../../assets/Inbox_filled.svg";
import { ReactComponent as SelfServiceOutline } from "../../../assets/Self_service_outline.svg";
import { ReactComponent as SelfServiceFilled } from "../../../assets/Self_service_filled.svg";

import { useHistory } from "react-router-dom";
import "./LockstepBar.scss";
import CustomTooltip from "../../library/CustomTooltip/CustomTooltip";
import { FeatureFlagContext, FeatureFlagProviderType } from "../../../contexts/FeatureFlagContext";
import { useTranslation } from "react-i18next";

export default function LockstepBar(): React.ReactElement {
  const history = useHistory();
  const { isEnabled } = React.useContext(FeatureFlagContext) as FeatureFlagProviderType;
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const menuOpen = Boolean(menuAnchor);
  const [activeIcon, setActiveIcon] = useState<string>("");
  const { t } = useTranslation();

  const handleAccountRedirect = () => {
    window.location.href = process.env.REACT_APP_ACCOUNT_REDIRECT_URL || "";
    setMenuAnchor(null);
  };

  const handleInboxRedirect = () => {
    history.push("/");
    setMenuAnchor(null);
  };

  const handleSelfServiceRedirect = () => {
    window.location.href = process.env.REACT_APP_SELF_SERVICE_MANAGER_REDIRECT_URL || "";
    setMenuAnchor(null);
  };

  return (
    <Box id="lockstep-bar">
      <CustomTooltip placement="bottom-end" title={t("header.tooltipMessages.inboxTabs.apps", { ns: "home" }) as string} disableFocusListener>
        <IconButton
          className={`bar-icon-btn ${menuOpen ? "active" : ""}`}
          onClick={(e: MouseEvent<HTMLButtonElement>) => setMenuAnchor(e.currentTarget)}
        >
          <SvgIcon component={HubIcon} inheritViewBox />
        </IconButton>
      </CustomTooltip>
      <Menu
        anchorEl={menuAnchor}
        open={menuOpen}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        MenuListProps={{
          id: "lockstep-bar-menu",
          className: `${isEnabled("SELF_SERVICE_APP_SWITCHER") ? "option-3" : "option-2"}`,
        }}
      >
        <MenuItem
          className="menu-item"
          onClick={() => handleAccountRedirect()}
          onMouseEnter={() => setActiveIcon("account")}
          onMouseLeave={() => setActiveIcon("")}
        >
          <IconButton disableRipple>
            <SvgIcon component={activeIcon === "account" ? AccountFilled : AccountOutline} inheritViewBox />
          </IconButton>
          <p>{t("header.tooltipMessages.networkAppLabels.account", { ns: "home" })}</p>
        </MenuItem>
        <MenuItem
          className="menu-item"
          onClick={() => handleInboxRedirect()}
          onMouseEnter={() => setActiveIcon("inbox")}
          onMouseLeave={() => setActiveIcon("")}
        >
          <IconButton disableRipple>
            <SvgIcon component={activeIcon === "inbox" ? InboxtFilled : InboxOutline} inheritViewBox />
          </IconButton>
          <p>{t("header.tooltipMessages.networkAppLabels.inbox", { ns: "home" })}</p>
        </MenuItem>
        {isEnabled("SELF_SERVICE_APP_SWITCHER") && (
          <MenuItem
            className="menu-item"
            onClick={() => handleSelfServiceRedirect()}
            onMouseEnter={() => setActiveIcon("self-service")}
            onMouseLeave={() => setActiveIcon("")}
          >
            <IconButton disableRipple>
              <SvgIcon component={activeIcon === "self-service" ? SelfServiceFilled : SelfServiceOutline} inheritViewBox />
            </IconButton>
            <p>{t("header.tooltipMessages.networkAppLabels.portal", { ns: "home" })}</p>
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
}

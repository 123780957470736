import APIClient from "../APIClient";
import { AxiosRequestConfig } from "axios";

const INBOX_API_BASE = `${process.env.REACT_APP_API_ENDPOINT_INBOX_API_PATH}${process.env.REACT_APP_API_ENDPOINT_INBOX_API_V2}`;
const CONFIG_OVERRIDE = {
  baseURL: process.env.REACT_APP_API_ENDPOINT_INBOX,
} as AxiosRequestConfig;

type LocalDataType = {
  locale: UserLocale;
};
export class LocaleClient {
  /**
   * * GET on  /api/v2/locale
   * @returns Promise of type APIResponse Model or Exception
   */
  async getLocale(): Promise<APIResponseGeneric<LocalDataType>> {
    const url = `${INBOX_API_BASE}/status/user_locale`;
    const response = await APIClient.get(url, CONFIG_OVERRIDE);
    return response.data;
  }
}

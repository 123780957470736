import React from "react";
import { useQuery } from "@tanstack/react-query";
import { exportClient } from "../db/version2Accessor";
import TableUtils from "../utils/TableUtils/TableUtils";
import { AlertContext } from "./AlertContext";
import { ExportToastMessage } from "../constants/ExportConstant";
import { NUMERIC_VALUES } from "../constants/NumericConstants";
import { RefetchIntervals } from "../constants/CacheConfig";

/**
 * @constant ExportContext
 * A context to poll and provide export information, to downloaded completed export
 */
export const ExportContext = React.createContext<ExportType | null>(null);
export interface Props {
  children: (React.ReactNode & { type: string })[] | (React.ReactNode & { type: string });
}

const ExportProvider: React.FC<Props> = ({ children }: Props) => {
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const [isPollingEnabled, setIsPollingEnabled] = React.useState<boolean>(false);
  const [overrideExportBehaviour, setOverrideExportBehaviour] = React.useState<boolean>(false);
  const [exportData, setExportData] = React.useState<ExportedData[]>([]);

  /**
   * @function updateExportedRecordsIds
   * A helper function to Add or Delete Id's to session storage
   * @param jobIds
   * @param shouldRemove
   */
  const updateExportedRecordsIds = (jobIds: string[], shouldRemove: boolean) => {
    const prevJobIds = JSON.parse(sessionStorage.getItem("export-jobs") ?? "[]");
    const updatedIds = new Set(prevJobIds);
    if (shouldRemove) {
      jobIds.forEach((id) => {
        updatedIds.delete(id);
      });
    } else {
      jobIds.forEach((id) => updatedIds.add(id));
    }
    sessionStorage.setItem("export-jobs", JSON.stringify(Array.from(updatedIds)));
    setIsPollingEnabled(updatedIds.size > NUMERIC_VALUES.CONSTANT_ZERO);
  };

  /**
   * @function downloadExportedFiled
   * A helper function to download the export file
   * @param exportedData
   */
  const downloadExportedFiled = (exportedData: ExportedData[]) => {
    if (exportedData?.length) {
      const exportedFiles = exportedData?.filter((item) => item.status === "success" || item.status === "failure");
      const completedJobIds = exportedFiles?.map((item) => item?.export_request_id);

      exportedFiles?.forEach((item) => {
        if (item.status === "success") {
          TableUtils?.downloadAll([item]);
          setToastOptions({ open: !overrideExportBehaviour, severity: "success", message: ExportToastMessage.EXPORT_COMPLETED });
        } else {
          setToastOptions({ open: !overrideExportBehaviour, severity: "error", message: ExportToastMessage.EXPORT_FAILED });
        }
      });
      setIsPollingEnabled(false);
      updateExportedRecordsIds(completedJobIds, true);
    }
  };

  /**
   * @function triggerDownload
   * A helper function to manually trigger a download of the export data saved in exportData-state variable
   */
  const triggerDownload = () => {
    downloadExportedFiled(exportData);
  };

  /**
   * @function flushExportData
   * A helper function to manually flush the export job ids in session store, 
   * clear export data in exportData-state variable, and stop polling if running.
   */
  const flushExportData = () => {
    setExportData([]);
    setIsPollingEnabled(false);
    sessionStorage.setItem("export-jobs", JSON.stringify([]));
  };

  /**
   * start polling to retrieve exported file status
   */
  useQuery(["export-records"], async () => exportClient.getExportedFiles(JSON.parse(sessionStorage.getItem("export-jobs") ?? "[]")), {
    onSuccess: (exportData) => {
      /**
       * If export behaviour is overridden, then save the export data to a state variable, so that custom behaviour can consume it,
       * Or else do the default automated export task.
       */
      if (!overrideExportBehaviour) {
        downloadExportedFiled(exportData?.data);
      } else {
        setExportData(exportData?.data);
      }
    },
    enabled: isPollingEnabled && JSON.parse(sessionStorage.getItem("export-jobs") ?? "[]")?.length > NUMERIC_VALUES.CONSTANT_ZERO, // to enable/disable polling
    refetchInterval: RefetchIntervals.export,
  });

  //The object passed as the value prop to the Context provider changes every render. To fix wrapped it in a useMemo hook.
  const contextValue = React.useMemo(
    () => ({
      updateExportedRecordsIds,
      overrideExportBehaviour,
      setOverrideExportBehaviour,
      exportData,
      triggerDownload,
      setIsPollingEnabled,
      flushExportData,
    }),
    [updateExportedRecordsIds, overrideExportBehaviour, exportData]
  );

  return <ExportContext.Provider value={contextValue}>{children}</ExportContext.Provider>;
};

export default ExportProvider;

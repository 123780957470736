import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { CircularProgress, Grid } from "@mui/material";
import jwt_decode from "jwt-decode";
import { NUMERIC_VALUES } from "../../constants/NumericConstants";
import { loginRequest } from "../../auth/authConfig";
import "./MagicLink.scss";

type MagicLinkToken = {
  aud: string;
  email: string;
  exp: number;
  iss: string;
  magicLinkId: string;
  magicLinkNonce: string;
  magicLinkVersion: number;
  nbf: number;
};

/**
 * @function MagicLinkAuth
 * A react functional component that checks and validates a magic-link authorization request to inbox
 * @returns
 */
export default function MagicLinkAuth() {
  const queryParams = new URLSearchParams(window.location.search);
  const { instance } = useMsal();

  useEffect(() => {
    (async () => {
      // Log out of all accounts
      await instance.logoutRedirect({
        onRedirectNavigate: () => false,
      });

      // Decode JWT from 'code' query
      const { magicLinkVersion } = jwt_decode(queryParams.get("code") || "") as MagicLinkToken;

      //get admin-grant token
      const adminGrant = queryParams.get("admin-grant") || "";

      // Early Exit - Version not valid
      if (magicLinkVersion !== NUMERIC_VALUES.CONSTANT_ONE && magicLinkVersion !== NUMERIC_VALUES.CONSTANT_TWO) {
        return;
      }

      // Switch between Magic Link Flows based on version
      const MLAuthority =
        magicLinkVersion === NUMERIC_VALUES.CONSTANT_ONE ? process.env.REACT_APP_B2C_ML_A_Authority : process.env.REACT_APP_B2C_ML_B_Authority;

      /**
       * Set the login type as magic-link based for future needs
       * This will be removed when user is logged out in any case
       * Here the admin-grnat is used to set login type
       */
      window.localStorage.setItem("adminGrant", JSON.stringify(adminGrant));

      // Request Magic Link
      await instance.loginRedirect({
        ...loginRequest,
        authority: MLAuthority,
        extraQueryParameters: { id_token_hint: queryParams.get("code") || "" },
      });
    })();
  }, [instance]);

  return (
    <Grid className="magic-auth-container" container direction="column" justifyContent="center" alignItems="center">
      <CircularProgress />
      <p className="body1">Redirecting...</p>
    </Grid>
  );
}

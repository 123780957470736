import { makeStyles } from "@mui/styles";
import styles from "../../../../../css/_export.module.scss";

/**
 * custom hook to provide classes which overrides the
 * default styles for MUI Dialog, DialogTitle, DialogContentText
 * and DialogActions components
 *
 * @returns styles to override dialog mui styles
 */
const useCopilotDiscardModalClasses = () => {
  const useStyles = makeStyles({
    copilotDiscardDialog: {
      "& .MuiDialog-container": {
        alignItems: "flex-start",
        "& .MuiPaper-root": {
          boxShadow: "none",
          marginTop: "15%",
          padding: styles.large,
          margin: 0,
          maxWidth: "45rem",
          "& .MuiDialogContent-root": {
            "& .mt-ba": {
              marginTop: styles.fontSizeMD,
            },
          },
          "& .MuiDialogActions-root": {
            justifyContent: "flex-start",
          },
        },
      },
    },
  });

  return useStyles();
};

export default useCopilotDiscardModalClasses;

import React, { useState, MouseEvent } from "react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import DropMenu from "../../../../library/DropMenu/DropMenu";
import MenuItem from "../../../../library/MenuItem/MenuItem";
import Menu from "../../../../library/Menu/Menu";
import "./ActivityAttachmentTab.scss";
import { DEFAULT_NUMERIC_VALUES } from "../../../../../constants/NumericConstants";
import Button from "../../../../library/Button/Button";
import { SmartText } from "../../../../library/SmartText";
import { Attachments } from "../../../../library/Icons/Icons";
import { formatDate, getDisplayDateFormat } from "../../../../../db/utils/date";
import { AttachmentPreview } from "../../../../library/AtomicComponents/AttachmentPreview";
import TableUtils from "../../../../../utils/TableUtils/TableUtils";

type AttachmentProps = {
  id: number;
  index: number;
  name: string;
  createdBy: string;
  type: string;
  createdDate: string;
  fileUrl: string;
  fileId: string | number;
  onClickPreviewOpen: (event: MouseEvent<HTMLDivElement>, fileId: string | number) => void;
  countryCode: string;
};

function Attachment(props: AttachmentProps): React.ReactElement {
  return (
    <div className="activity-attachment-item" onClick={(event: MouseEvent<HTMLDivElement>) => props.onClickPreviewOpen(event, props.fileId)}>
      <div className="head">
        <SmartText title={props.name} />
        <p className="body1">{props.createdBy}</p>
      </div>
      <div className="body">
        <p className="caption">{props.type}</p>
        <p className="caption">{formatDate(props.createdDate, getDisplayDateFormat(props.countryCode))}</p>
      </div>
    </div>
  );
}

const SORTMENU = [
  {
    label: "Sort By Name",
    sortFunction: (a: AttachmentItem, b: AttachmentItem) => {
      return (a.file_name ?? "").localeCompare(b.file_name ?? "") || a.extension.localeCompare(b.extension);
    },
  },
  {
    label: "Sort By Type",
    sortFunction: (a: AttachmentItem, b: AttachmentItem) => {
      return a.extension.localeCompare(b.extension) || (a.file_name ?? "").localeCompare(b.file_name ?? "");
    },
  },
  {
    label: "Sort By Date",
    sortFunction: (a: AttachmentItem, b: AttachmentItem) => {
      return a.created_at.toString().localeCompare(b.created_at.toString()) || (a.file_name ?? "").localeCompare(b.file_name ?? "");
    },
  },
];

interface AttachmentTabProps {
  attachments: AttachmentItem[];
  addButton?: { addAttachmentHandler: () => void; label: string };
  custom_classes?: string;
  handleOnClick?: (index: number) => void;
  countryCode: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function AttachmentTab(props: AttachmentTabProps): React.ReactElement {
  const [selectedIndex, setSelectedIndex] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [sortMenuAnchorEl, setSortMenuAnchorEl] = useState<HTMLDivElement | null>(null);
  const sortMenuIsOpen = Boolean(sortMenuAnchorEl);

  const [openPreview, setOpenPreview] = React.useState<boolean>(false);
  const [activeAttachmentID, setActiveAttachmentID] = React.useState<string | number>("");

  const onClickPreviewClose = (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement> | KeyboardEvent) => {
    event.stopPropagation();
    setOpenPreview(false);
  };

  const onClickPreviewOpen = (event: MouseEvent<HTMLDivElement>, activeAttachmentID: string | number) => {
    event.stopPropagation();
    setActiveAttachmentID(activeAttachmentID);
    setOpenPreview(!openPreview);
  };

  const onClickDownload = (event: MouseEvent<HTMLTableRowElement | HTMLDivElement | HTMLButtonElement>, attachments: AttachmentItem[] | BlobAttachmentItem[]) => {
    event.stopPropagation();
    TableUtils.downloadAll(attachments);
  };

  return (
    <div className={`activity-attachment-tab ${props.custom_classes && `${props.custom_classes}`}`}>
      {props.attachments.length < DEFAULT_NUMERIC_VALUES.DEFAULT_ONE ? (
        <div className="no-attachments">
          <div className="icon">
            <Attachments />
          </div>
          <h4 className="text">
            No Available
            <br />
            Attachments
          </h4>
        </div>
      ) : (
        <>
          <div className="head">
            <div className="head-container">
              <DropMenu
                title={SORTMENU[selectedIndex].label}
                isActive={sortMenuIsOpen}
                onClick={(e: MouseEvent<HTMLDivElement>) => setSortMenuAnchorEl(e.currentTarget)}
                iconAlign="right"
              />
              {props.addButton && props.addButton.addAttachmentHandler && (
                <Button type="button" size="sm" variant="secondary" onClick={props.addButton.addAttachmentHandler}>
                  {props.addButton.label || "Add an attachment"}
                </Button>
              )}
            </div>
            <Menu
              open={sortMenuIsOpen}
              anchorEl={sortMenuAnchorEl}
              onClose={() => setSortMenuAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              {SORTMENU.map((value: { label: string; sortFunction: (a: AttachmentItem, b: AttachmentItem) => number }, index: number) => {
                return (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      setSelectedIndex(index);
                      setSortMenuAnchorEl(null);
                    }}
                  >
                    {value.label}
                  </MenuItem>
                );
              })}
            </Menu>
          </div>

          <div className="body">
            <OverlayScrollbarsComponent
              options={{ paddingAbsolute: true, autoUpdate: true, sizeAutoCapable: true, className: "overlay-scrollbar-width-reset" }}
            >
              <div>
                {[...props.attachments].sort(SORTMENU[selectedIndex].sortFunction).map((document: AttachmentItem, index) => {
                  return (
                    <Attachment
                      key={`attachment-${index}`}
                      index={index}
                      id={document.id as number}
                      name={document?.file_name ?? ""}
                      createdBy={document.created_by ?? ""}
                      type={document.extension}
                      createdDate={document.created_at.toString()}
                      fileUrl={document.file_url.toString()}
                      fileId={document.id}
                      onClickPreviewOpen={onClickPreviewOpen}
                      countryCode={props.countryCode}
                    />
                  );
                })}
              </div>
            </OverlayScrollbarsComponent>
            {openPreview && (
              <AttachmentPreview
                openPreview={openPreview}
                activeAttachmentID={activeAttachmentID}
                handleClose={onClickPreviewClose}
                attachments={[...props.attachments]}
                onClickDownload={onClickDownload}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}

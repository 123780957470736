import React from "react";
import { BTN_COPY } from "./../../../../../../../../constants/config";
import { NoteUserActionProps } from "../HelperTypes";
import { Button } from "../../../../../../../library/AtomicComponents/atomic/Button";

const NoteUserActions: React.FC<NoteUserActionProps> = ({ onClickCopy }) => {
  return (
    <>
      <Button onClick={onClickCopy} variant="primary">
        {BTN_COPY}
      </Button>
    </>
  );
};

export default NoteUserActions;

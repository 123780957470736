import React from "react";
import { Email as EmailIcon } from "../../../../../../library/Icons/Icons";
import PrimaryActivity from "../../PrimaryActivity/PrimaryActivity";
import { EmailProps } from "../ActivityItemVariantTypes";
import * as Labels from "../../../../../../../constants/config";

// primary activity
const Email: React.FC<EmailProps> = ({ creator, ...props }) => {
  return (
    <PrimaryActivity
      {...{ ...props, creator: { ...creator, message: Labels.HEADER_EMAIL } }}
      icon={<EmailIcon />}
      FLAGS={{ REPLY_OPTIONS: true, TIME_SAVING_INDICATOR: true }}
    />
  );
};

export default Email;

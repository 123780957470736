import React from "react";
import { Route, Switch, useRouteMatch, matchPath } from "react-router-dom";
import FourOFourError from "../../../routes/FourOFourError/FourOFourError";
import ActivityDetailV2 from "../../Activities/ActivityDetailV2/ActivityDetail";
import CustomerDetail from "../../Customers/CustomerDetail/CustomerDetail";
import InvoiceDetail from "../../Invoices/InvoiceDetail/InvoiceDetail";
import PaymentDetail from "../../Payments/PaymentDetail/PaymentDetail";
import ResultPageContainer from "./ResultPageContainer";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";
import "../Styles/ResultPageStyles.scss";
import ResultPageHeadNavbar from "./ResultPageHeadNavbar";

const GlobalSearchNavigation: React.FC = () => {
  const { path } = useRouteMatch();
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const isEInvoice = selectedWorkspace?.workspace_type_route === "e";
  const matchActivityId = matchPath(location.pathname, {
    path: "*/activity_streams/:id",
    exact: true,
    strict: true,
  });

  return (
    <div className={`gsp-nav-wrapper ${isEInvoice ? "e-invoice" : ""}`}>
      {!isEInvoice && (
        <div className="head">
          <Switch>
            <Route exact path={`${path}/:category`} render={() => <ResultPageHeadNavbar currentSearchNestingLevel="L1" />} />
            <Route
              exact
              path={[
                `${path}/activity_streams/:activityId`,
                `${path}/invoices/:invoiceId`,
                `${path}/bills/:invoiceId`,
                `${path}/payments/:paymentId`,
                `${path}/customers/active/:customerId`,
                `${path}/customers/active/:customerId/documents`,
                `${path}/customers/active/:customerId/contacts`,
                `${path}/vendors/active/:customerId`,
                `${path}/vendors/active/:customerId/documents`,
                `${path}/vendors/active/:customerId/contacts`,
                `${path}/vendors/active/:customerId`,
                `${path}/connections/active/:customerId`,
                `${path}/internal/active/:customerId`,
              ]}
              render={() => <ResultPageHeadNavbar currentSearchNestingLevel="L2" />}
            />
          </Switch>
        </div>
      )}
      <div className={`body ${matchActivityId !== null ? "padding-v2" : ""} ${isEInvoice ? "e-invoice" : ""}`}>
        <Switch>
          {/* L1 Navigation - Result Table  */}
          <Route
            exact
            path={`${path}/:category`}
            render={() => {
              return <ResultPageContainer />;
            }}
          />

          {/* L2 Navigation - Details View */}
          <Route
            exact
            path={`${path}/activity_streams/:activityId`}
            render={() => {
              return <ActivityDetailV2 userView="all" />;
            }}
          />
          <Route
            exact
            path={[`${path}/invoices/:invoiceId`, `${path}/bills/:invoiceId`]}
            render={() => {
              return <InvoiceDetail />;
            }}
          />
          <Route
            exact
            path={`${path}/payments/:paymentId`}
            render={() => {
              return <PaymentDetail />;
            }}
          />
          <Route
            path={[
              `${path}/customers/active/:customerId`,
              `${path}/vendors/active/:customerId`,
              `${path}/connections/active/:customerId`,
              `${path}/internal/active/:customerId`,
            ]}
            render={() => {
              return <CustomerDetail />;
            }}
          />

          {/* 404 Error */}
          <Route
            render={() => {
              return <FourOFourError />;
            }}
          />
        </Switch>
      </div>
    </div>
  );
};

export default GlobalSearchNavigation;

import APIClient from "../APIClient";
import { AxiosRequestConfig } from "axios";

const INBOX_API_BASE = `${process.env.REACT_APP_API_ENDPOINT_INBOX_API_PATH}${process.env.REACT_APP_API_ENDPOINT_INBOX_API_V2}`;
const CONFIG_OVERRIDE = {
  baseURL: process.env.REACT_APP_API_ENDPOINT_INBOX,
} as AxiosRequestConfig;

export class IndexClient {
  getIndex(resolvedUrl: string): Promise<any> {
    let url = `${INBOX_API_BASE}/`;
    url = url + resolvedUrl;
    return APIClient.get(url, CONFIG_OVERRIDE).then((response) => {
      return {
        pageNumber: response.data.page,
        pageSize: response.data.page_size,
        totalCount: response.data.total_records,
        records: response.data.data,
      };
    });
  }
}

import APIClient from "../APIClient";
import { AxiosRequestConfig } from "axios";

const INBOX_API_BASE = `${process.env.REACT_APP_API_ENDPOINT_INBOX_API_PATH}${process.env.REACT_APP_API_ENDPOINT_INBOX_API_V2}`;
const CONFIG_OVERRIDE = {
  baseURL: process.env.REACT_APP_API_ENDPOINT_INBOX,
} as AxiosRequestConfig;

export class AttachmentsClient {
  URL = `${INBOX_API_BASE}/attachments`;

  /**
   * @param file - The file selected to upload
   * @returns Promise of type APIResponse or Exception
   */
  postAttachment(body: FormData): Promise<APIResponse> {
    return APIClient.post(this.URL, body, CONFIG_OVERRIDE).then((res) => res.data);
  }

  archiveAttachments(body: AttachmentsStatusUpdateModel): Promise<APIResponse> {
    const url = `${this.URL}/status`;
    return APIClient.patch(url, body, CONFIG_OVERRIDE).then((res) => res.data);
  }

  getAttachmentsForTransactions(
    filterType: string,
    transactionId: string,
    transactionType: string,
    ransackQuery: string,
    page?: number,
    pageSize?: number
  ): Promise<APIResponse> {
    const options = {
      params: {
        filter_by: filterType,
        transaction_id: transactionId,
        transaction_type: transactionType,
        "qs[s]": ransackQuery,
        page,
        page_size: pageSize,
      },
      ...CONFIG_OVERRIDE,
    };
    return APIClient.get(this.URL, options).then((res) => res.data);
  }

  getAttachments = (filters: string, order: string, page: number, pageSize: number, isSelectAll = false): Promise<FetchResult> => {
    let url = `${this.URL}?page=${page}&page_size=${pageSize}`;
    if (order) {
      url = `${url}&${order}`;
    }
    if (filters) {
      url = `${url}&${filters}`;
    }
    if (isSelectAll) {
      url = `${url}&select_all=${isSelectAll}`;
    }
    return APIClient.get(url, CONFIG_OVERRIDE).then((response) => {
      return {
        pageNumber: response.data.page,
        pageSize: response.data.page_size,
        totalCount: response.data.total_records,
        records: response.data.data,
      };
    });
  };
}

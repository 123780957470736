import React, { HTMLProps } from "react";
import { DropdownArrow } from "../Icons/Icons";
import "./DropMenu.scss";

interface DropMenuProps extends HTMLProps<HTMLDivElement> {
  title: string;
  isActive: boolean;
  iconAlign?: "left" | "right";
  icon?: React.ReactNode;
  appendIcon?: React.ReactNode;
}

// ? DropMenu is intended to be used with library's Menu/MenuItem components (Inherited from MUI & restyled) !
export default function DropMenu({ className, title, iconAlign, icon, isActive, appendIcon, ...rest }: DropMenuProps): React.ReactElement {
  const IconElement = icon ?? <DropdownArrow />;
  const AppendIcon = appendIcon ?? null;
  return (
    <div className={`drop-menu ${isActive ? "drop-menu-active" : "drop-menu-inactive"} ${className ?? ""}`} {...rest}>
      {(!iconAlign || iconAlign === "left") && IconElement}
      {AppendIcon ? <div className="append-icon">{AppendIcon}</div> : null}
      <p className="body2">{title}</p>
      {iconAlign === "right" && IconElement}
    </div>
  );
}

import React from "react";
import { contactsClientV2 } from "../db/version2Accessor";
import { WorkspaceContext } from "../contexts/WorkspaceContext";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../constants/NumericConstants";
import _ from "underscore";

/**
 * hook provides an interface to call for workspace contacts or workspace>customer
 * specific contact api call handlers.
 *
 * Contacts are then set to the workspace context.
 * @returns
 */
const useContacts = () => {
  const { selectedWorkspace, allContactOptions, setAllContactOptions } = React.useContext(WorkspaceContext) as WorkspaceDataType;

  async function fetchContacts(customerId?: string) {
    let filter = "qa[is_active_eq]=true";
    if (customerId) {
      filter = `${filter}&qa[company_id_eq]=${customerId}`;
    }
    return contactsClientV2.getContacts(
      selectedWorkspace?.id,
      filter,
      undefined,
      NUMERIC_VALUES.CONSTANT_FIVE_THOUSAND,
      DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
    );
  }

  /**
   * This function takes company contacts and filters out the primary contact from it
   * @param {To[]} companyContacts list of company contacts
   * @returns filtered Primary contact only
   */
  const filterPrimaryContacts = (companyContacts: To[]): To[] => companyContacts.filter((item) => item.primary === true);

  /**
   * This function fetches and stores all contact options or company contact option depending
   * upon whether customer Id is passed or not and sets it in the context so we can use it across the app
   *
   * @param {string} customerId if available
   * @returns contact options
   */
  async function getContactsOptions(customerId = ""): Promise<To[]> {
    const response: ContactModelFetchResultV2 = await fetchContacts(customerId);
    if (!response) {
      return [];
    }
    const filteredContacts = response?.records
      ?.filter((record) => !_.isEmpty(record?.email_address))
      .map((contact: ContactItem) => ({
        id: contact?.contact_name ? contact.email_address : "",
        label: contact?.contact_name || contact.email_address,
        primary: contact?.is_primary,
        connectionId: contact?.company_id,
        erpContact: !_.isNull(contact?.app_enrollment_id),
        companyId: contact?.company_id,
        phoneNumber: contact?.phone,
        profileImgURL: contact?.picture_url,
        userRole: contact?.role_code,
      })) as To[];
    /**
     * returns unique contacts
     * refer:
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/findIndex
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/filter
     */
    !customerId &&
      setAllContactOptions(!_.isEmpty(filteredContacts) ? filteredContacts.filter((v, i, a) => a.findIndex((t) => t.label === v.label) === i) : []);
    return filteredContacts.filter((v, i, a) => a.findIndex((t) => t.label === v.label) === i);
  }

  return {
    allContactOptions,
    fetchContacts,
    getContactsOptions,
    filterPrimaryContacts,
  };
};

export default useContacts;

import React from "react";
import { DEFAULT_NUMERIC_VALUES } from "../../../../constants/NumericConstants";
import { DraggableContainerInterface } from "../UploadComponentInterfaces/UploadComponentInterfaces";
import DraggableComponent from "./DraggableComponent";

const DraggableContainer: React.FC<DraggableContainerInterface> = ({ inputRef, labelProps, setImage }: DraggableContainerInterface) => {
  const [dragging, setDragging] = React.useState(false);
  const [draggingCounter, setDraggingCounter] = React.useState(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const draggableArea = React.useRef<HTMLInputElement>(null);

  const handleOnDragEnter = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    setDraggingCounter(draggingCounter + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);
    setDragging(true);
  };
  const handleOnDragLeave = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
    if (draggingCounter === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      setDragging(false);
    }
    setDraggingCounter(draggingCounter - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);
  };
  const handleOnDrapDrop = () => {
    setDraggingCounter(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    setDragging(false);
  };

  const onChangeFileHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImage(
      URL.createObjectURL((event?.target as HTMLInputElement)?.files?.[0] as Blob),
      (event?.target as HTMLInputElement)?.files?.[0]?.size || DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
    );
  };

  React.useEffect(() => {
    const draggableDiv = draggableArea.current;
    draggableDiv?.addEventListener("dragenter", handleOnDragEnter);
    draggableDiv?.addEventListener("dragleave", handleOnDragLeave);
    draggableDiv?.addEventListener("drop", handleOnDrapDrop);
    return () => {
      draggableDiv?.removeEventListener("dragenter", handleOnDragEnter);
      draggableDiv?.removeEventListener("dragleave", handleOnDragLeave);
      draggableDiv?.removeEventListener("drop", handleOnDrapDrop);
    };
  }, []);

  return (
    <DraggableComponent
      dragging={dragging}
      draggableArea={draggableArea}
      inputRef={inputRef}
      labelProps={labelProps}
      onChangeFileHandler={onChangeFileHandler}
    />
  );
};

export default DraggableContainer;

import React from "react";
import { Close } from "../../../../../../library/Icons/Icons";
import PrimaryActivity from "../../PrimaryActivity/PrimaryActivity";
import { ApprovalCancelledProps } from "../ActivityItemVariantTypes";

const Cancelled: React.FC<ApprovalCancelledProps> = (props) => {
  return (
    <PrimaryActivity
      {...{
        ...props,
        content: {
          HTMLEmailContent: "",
          preview: "",
        },
        icon: <Close />,
        FLAGS: { REPLY_OPTIONS: false, TIME_SAVING_INDICATOR: true },
      }}
    />
  );
};

export default Cancelled;

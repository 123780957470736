import React from "react";
import { formatExportName } from "../../../utils/table";
import { SnackbarTypes } from "../../../Snackbar/tsx/constants";
import { ReactComponent as ExportIcon } from "../../../svg/export_icon.svg";
import { CSVLink } from "react-csv";
import _ from "underscore";
import { TableDataContext, TableDataProviderType } from "../../contexts/TableDataContext";
import { Typography } from "@mui/material";
import { HeaderButton } from "../Body/button.styles";
import { NUMERIC_VALUES } from "../../../constants/numeric.constants";

const ExportRecords: React.FC = () => {
  const {
    columnConfigs: columnConfig,
    selectionConfigs: selectionConfig,
    tableConfigs: tableConfig,
  } = React.useContext(TableDataContext) as TableDataProviderType;

  return (
    <CSVLink
      headers={columnConfig
        ?.filter((col) => !_.isEmpty(col?.name) && !_.isEmpty(col?.accessor))
        ?.map((col) => ({
          label: col?.name,
          key: col?.accessor,
        }))}
      data={selectionConfig?.getFormattedSelectedRowData(selectionConfig.tableRecords?.selectedRecords)}
      filename={formatExportName(tableConfig?.name)}
      onClick={() => {
        tableConfig.extraHandlers?.setSnackbarConfig({
          open: true,
          text: "Exporting To Excel",
          type: SnackbarTypes.Export,
        });
      }}
    >
      <HeaderButton
        startIcon={<ExportIcon />}
        className={`${selectionConfig.tableRecords?.selectedRecords?.length ? "" : "inactive"}`}
        disabled={tableConfig?.rows?.length === NUMERIC_VALUES.CONSTANT_ZERO}
      >
        <Typography variant="body1">Export</Typography>
      </HeaderButton>
    </CSVLink>
  );
};
export default ExportRecords;

import React from "react";
import { FilterContainerTypes } from "../../preset.filter.types";
import { SelectChangeEvent } from "@mui/material";
import NumericFilterComponent from "./NumericFilterComponent";
import { NUMERIC_VALUES } from "../../../../constants/numeric.constants";

const NumberFilterContainer: React.FC<FilterContainerTypes> = ({
  filterKey,
  filterValue,
  filterRangeValue,
  onClearHandler,
  filterComparator = "",
  onChangeHandler,
  currencyData = {
    code: "",
    symbol: "",
  },
  filterAlignment = "center",
  searchlightField,
  numericFilterType,
  placeholder = "0.00",
}) => {
  const [selectedOption, setSelectedOption] = React.useState<string>(filterComparator);
  const [amountValue, setAmountValue] = React.useState<string>("");
  const [amountRangeValue, setAmountRangeValue] = React.useState<number[]>([NUMERIC_VALUES.CONSTANT_ZERO, NUMERIC_VALUES.CONSTANT_TEN_THOUSAND]);
  const [active, setActive] = React.useState<boolean>(Boolean(filterValue || filterRangeValue || false));
  // Allow only numbers and up to 2 decimal places
  const floatRegEx = /^-?\d*\.?\d{0,2}$/;

  const onClickFilterApply = () => {
    if (onChangeHandler) {
      onChangeHandler([{ key: filterKey, value: amountValue, rangeValue: amountRangeValue, comparator: selectedOption, searchlightField }]);
      setActive(true);
    }
  };

  const onClickClearFilter = () => {
    setSelectedOption("");
    setAmountValue("");
    setAmountRangeValue([NUMERIC_VALUES.CONSTANT_ZERO, NUMERIC_VALUES.CONSTANT_TEN_THOUSAND]);
    if (onClearHandler) {
      onClearHandler(filterKey);
      setActive(false);
    }
  };

  const onChangeDropdownValue = (event: SelectChangeEvent) => setSelectedOption(event.target.value);

  const onChangeAmountValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (floatRegEx.test(event.target.value)) {
      setAmountValue(event.target.value);
    }
  };

  const onChangeRangeSliderValue = (_event: Event, value: number[]) => setAmountRangeValue(value);

  const onChangeSliderInputMinValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (floatRegEx.test(event.target.value)) {
      setAmountRangeValue([parseFloat(event.target.value), amountRangeValue[1]]);
    }
  };

  const onChangeSliderInputMaxValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (floatRegEx.test(event.target.value)) {
      setAmountRangeValue([amountRangeValue[0], parseFloat(event.target.value)]);
    }
  };

  React.useEffect(() => {
    setActive(Boolean(filterValue ?? filterRangeValue) || false);
    setAmountRangeValue(() => {
      return filterRangeValue
        ? [parseFloat(filterRangeValue?.[0]), parseFloat(filterRangeValue?.[1])]
        : [NUMERIC_VALUES.CONSTANT_ZERO, NUMERIC_VALUES.CONSTANT_TEN_THOUSAND];
    });
    setAmountValue(filterValue ?? "");
  }, [filterValue, filterRangeValue]);

  return (
    <NumericFilterComponent
      selectedOption={selectedOption}
      filterActive={active}
      currencyData={currencyData}
      amountValue={amountValue}
      amountRangeValue={amountRangeValue}
      onChangeDropdownValue={onChangeDropdownValue}
      onChangeAmountValue={onChangeAmountValue}
      onChangeRangeSliderValue={onChangeRangeSliderValue}
      onChangeSliderInputMinValue={onChangeSliderInputMinValue}
      onChangeSliderInputMaxValue={onChangeSliderInputMaxValue}
      onClickFilterApply={onClickFilterApply}
      filterAlignment={filterAlignment}
      onClickClearFilter={onClickClearFilter}
      numericFilterType={numericFilterType}
      placeholder={placeholder}
    />
  );
};

export default NumberFilterContainer;

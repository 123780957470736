/*eslint-disable no-magic-numbers*/
import { styled } from "@mui/system";

export const RecordCardRoot = styled("div", {
  name: "record-card",
  slot: "root",
})({
  marginBottom: "1.5rem",
  "& .MuiCard-root.transaction-card": {
    padding: "1.5rem",
    boxShadow: "none",
    border: "0.0625rem solid #bdc3db",
    borderRadius: "0.5rem",
    "& .MuiPaper-root": {
      boxShadow: "none",
      border: "0.0625rem solid #bdc3db",
      "& .MuiTableHead-root .MuiTableCell-root": {
        fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif`,
        fontWeight: 600,
        fontSize: "0.75rem",
        lineHeight: "1rem",
      },
      ".MuiTableBody-root .MuiTableCell-root": {
        fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif`,
        fontWeight: 400,
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
      },
    },
  }
});

export const RecordHeader = styled("div", {
  name: "record-header",
  slot: "root",
})({
  display: "flex",
  flexDirection: "column",
  paddingBottom: "0.75rem",
  gap: "0.75rem",
});

export const RecordTitle = styled("div", {
  name: "record-title",
  slot: "root",
})({
  fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif`,
  fontWeight: 600,
  fontSize: "1.125rem",
  lineHeight: "1.5rem",
  color: "#ffffff",
  textAlign: "center",
  borderRadius: "0.25rem",
});

export const RecordDetails = styled("div", {
  name: "record-details",
  slot: "root",
})({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  "& .MuiTypography-root ": {
    fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif`,
    fontWeight: 600,
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    textDecoration: "none",
    cursor: "pointer",
  },
});

export const RecordDate = styled("div", {
  name: "record-date",
  slot: "root",
})({
  fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif`,
  fontWeight: 400,
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
});

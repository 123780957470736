import React from "react";
import { useLocation, useParams, useRouteMatch } from "react-router-dom";
import { DEFAULT_NUMERIC_VALUES } from "../../../constants/NumericConstants";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";
import Navbar from "../../library/Navbar/Navbar";
import { getSearchHistoryProperty } from "../Utils/LocalStorageUtils";

interface GSRLandingNavbarInterface {
  currentSearchNestingLevel: "L1" | "L2";
}

interface SearchHistoryInterface {
  backRoute?: string;
  backDisplayName?: string;
}

enum GSRNestingLevels {
  "l1" = "L1",
  "l2" = "L2",
}

const ResultsViewNavbar: React.FC<GSRLandingNavbarInterface> = ({ currentSearchNestingLevel }: GSRLandingNavbarInterface) => {
  const { path } = useRouteMatch();
  const { category } = useParams<{ category: string }>();
  const { search } = useLocation();
  const { workspaceHomePath } = React.useContext(WorkspaceContext) as WorkspaceDataType;

  const getSearchHistory = (): SearchHistoryInterface => {
    let searchHistory = {};
    if (currentSearchNestingLevel?.toLowerCase() === GSRNestingLevels.l1?.toLowerCase()) {
      const getHistory = getSearchHistoryProperty("previousContext");
      searchHistory = {
        backRoute: getHistory?.previousPath ?? `${workspaceHomePath}/dashboard`,
        backDisplayName: getHistory?.previousContextName ?? "Dashboard",
      };
    } else {
      const getHistory = getSearchHistoryProperty("currentSearchContext");
      searchHistory = {
        backRoute: getHistory?.currentSearchActivePath ?? `${workspaceHomePath}/dashboard`,
        backDisplayName: getHistory?.currentSearchTitle ?? "Dashboard",
      };
    }
    return searchHistory;
  };

  return (
    <Navbar
      key={`${category}${search}`}
      tabs={[
        { displayName: "Search Results", routeName: `${category ? category : path?.split("/")?.[DEFAULT_NUMERIC_VALUES.DEFAULT_FOUR]}${search}` },
      ]}
      rootPath={path?.split("/")?.slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, DEFAULT_NUMERIC_VALUES.DEFAULT_FOUR)?.join("/")}
      handleBackRoute={true}
      backRoute={getSearchHistory()?.backRoute}
      backDisplayName={getSearchHistory()?.backDisplayName}
    />
  );
};

export default ResultsViewNavbar;

import React from "react";
import { DisplayStatusProps } from "./StatusTypes";
import "./Status.scss";

const Status: React.FC<DisplayStatusProps> = ({ size = "sm", logo, statusMessage, subText, children }) => {
  return (
    <div className={`request-status-container display-${size}`}>
      <div className="status-logo">{logo}</div>
      <div className="status-message">{statusMessage}</div>
      {subText && <div className="status-ps">{subText}</div>}
      <div className="status-actions">{children}</div>
    </div>
  );
};

export default Status;

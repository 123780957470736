import React from "react";
import { RequestApproval } from "../../../../../../library/Icons/Icons";
import PrimaryActivity from "../../PrimaryActivity/PrimaryActivity";
import { ApprovalReviewedProps } from "../ActivityItemVariantTypes";
import * as Labels from "../../../../../../../constants/config";

const Reviewed: React.FC<ApprovalReviewedProps> = (props) => {
  return (
    <PrimaryActivity
      {...{
        ...props,
        icon: <RequestApproval />,
        creator: { ...props.creator, message: Labels.HEADER_APPROVAL_REQUEST },
        FLAGS: { REPLY_OPTIONS: true, TIME_SAVING_INDICATOR: true },
      }}
    />
  );
};

export default Reviewed;

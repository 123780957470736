import APIClient from "../APIClient";
import { AxiosRequestConfig } from "axios";

const INBOX_API_BASE = `${process.env.REACT_APP_API_ENDPOINT_INBOX_API_PATH}${process.env.REACT_APP_API_ENDPOINT_INBOX_API_V2}`;
const CONFIG_OVERRIDE = {
  baseURL: process.env.REACT_APP_API_ENDPOINT_INBOX,
} as AxiosRequestConfig;

export class ExportClient {
  /**
   * * GET on /api/v2/exports
   * @param JobIds - exported job ids, if no jobId's pass to param then it retrieves all exported files
   * @returns Promise of type ExportRecordStatusModel or Exception
   */
  async getExportedFiles(jobIds?: string[]): Promise<ExportRecordStatusModel> {
    const url = `${INBOX_API_BASE}/exports`;

    const options = {
      params: {
        export_request_ids: jobIds,
      },
      ...CONFIG_OVERRIDE,
    };

    return APIClient.get(url, options).then((res) => res.data);
  }

  /**
   * * POST on /api/v2/exports
   * @param exportType - page view type ex: invoices
   * @returns Promise of type ExportRecord or Exception
   */
  async exportRecords(
    exportType: string,
    isExportAllRecords: boolean,
    sorting?: string,
    filters?: string,
    workspaceId?: number
  ): Promise<ExportRecord> {
    let url = `${INBOX_API_BASE}/exports/?export_type=${exportType}&workspace_id=${workspaceId ?? ""}&select_all=${isExportAllRecords}`;

    if (filters) {
      url = `${url}&${filters}`;
    }
    if (sorting) {
      url = `${url}&${sorting}`;
    }

    return APIClient.post(url, "", CONFIG_OVERRIDE).then((response) => response.data);
  }
}

import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import "./FileAttachment.scss";

interface FileAttachmentProps {
  label: string;
  size: string;
  handleClick: () => void;
  showClose?: boolean;
}

export default function FileAttachment(props: FileAttachmentProps): React.ReactElement {
  return (
    <div className={"fa-wrapper"}>
      <div className={"fa-name"}>
        <p className={"fa-name-label"}>{props.label}</p>
        {props.size && <p className={"fa-name-size"}>{props.size !== "" && `(${props.size})`}</p>}
      </div>
      {(props?.showClose === null || props?.showClose === true) && (
        <div className={"fa-close"} onClick={props.handleClick}>
          <CloseIcon />
        </div>
      )}
    </div>
  );
}

import React, { MouseEvent } from "react";
import RelatedActivityStreamsComponent from "./RelatedActivityStreamsComponent";
import { WorkspaceContext } from "../../../../../../contexts/WorkspaceContext";
import { activitiesClientV2 } from "../../../../../../db/version2Accessor";
import { FallbackTypes } from "../../../../../../types/enums";
import { DEFAULT_NUMERIC_VALUES } from "../../../../../../constants/NumericConstants";
import Loading from "../../../../../library/Loading/Loading";
import "./RelatedActivityStreamsStyles.scss";
import Utils from "../../../../../../utils/utils";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
import { DocumentSwitchContext } from "../../../../../../contexts/DocumentSwitchContext";
import { ArrowDown } from "../../../../../library/Icons/Icons";
import Menu from "../../../../../library/Menu/Menu";
import MenuItem from "../../../../../library/MenuItem/MenuItem";
import Button from "../../../../../library/Button/Button";
import { ApplicationRouteContext } from "../../../../../../contexts/ApplicationRouteContext";
import { ACTIVITY_FETCH_MORE_MESSAGE, BTN_NEW_ACTIVITY, EMPTY_RELATED_TRANSACTIONS_MESSAGE } from "../../../../../../constants/config";
import { NUMERIC_VALUES } from "../../../../../library/AtomicComponents/constants/numeric.constants";

interface RelatedActivityStreamsInterface {
  newActivityComponentProps: NewActivityComponentProps;
  id: string;
  type: string;
  refreshList?: boolean;
}

export interface PageDetails {
  totalRecords: number;
  page: number;
  pageSize: number;
  pageCount: number;
}

const RelatedActivityStreamsContainer: React.FC<RelatedActivityStreamsInterface> = (props: RelatedActivityStreamsInterface) => {
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const {
    setSelectedActivityStreamId,
    setActivityStreamIds,
    setCurrentPaginationState,
    setPageFetchParams,
    setDocumentType,
    setIsRelativeStream,
    setEnableDocumentSwitch,
    setCurrentSwitcherStateSnapshot,
  } = React.useContext(DocumentSwitchContext) as DocumentSwitchType;
  const { getBaseRoute } = React.useContext(ApplicationRouteContext) as ApplicationRouteType;

  const [relatedStreams, setRelatedStreams] = React.useState<Array<ActivityStreamItem>>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [rasArr, setRasArr] = React.useState<Array<ActivityStreamItem>>([]);
  const history = useHistory();
  const [pageDetails, setPageDetails] = React.useState<PageDetails>({
    totalRecords: 0,
    page: 1,
    pageSize: 25,
    pageCount: 0,
  });

  const [dropdownMenuAnchorEl, setDropdownMenuAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const dropdownMenuOpen = Boolean(dropdownMenuAnchorEl);

  let initialLoad = false;
  const fetchStreams = () => {
    initialLoad && setIsLoading(true);
    activitiesClientV2
      .fetchRelatedStreams(
        selectedWorkspace?.id ?? FallbackTypes.Id,
        props.id,
        props.type,
        initialLoad ? DEFAULT_NUMERIC_VALUES.DEFAULT_ONE : pageDetails.page + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
        pageDetails.pageSize
      )
      .then((response) => {
        if (response.success) {
          setRasArr(response.data);
          initialLoad ? setRelatedStreams(response.data) : setRelatedStreams(relatedStreams.concat(response.data));
          setPageDetails({
            totalRecords: response.total_records ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            page: parseInt(response.page + "", NUMERIC_VALUES.CONSTANT_TEN) ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            pageSize: parseInt(response.page_size + "", NUMERIC_VALUES.CONSTANT_TEN) ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            pageCount: parseInt(response.total_pages + "", NUMERIC_VALUES.CONSTANT_TEN) ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
          });
          initialLoad = false;
        }
      })
      .catch((err) => {
        console.error("Error Fetching Related Streams :::", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const resetData = () => {
    setRelatedStreams([]);
    setPageDetails({
      totalRecords: DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
      page: DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
      pageSize: DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
      pageCount: DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
    });
    initialLoad = true;
  };

  const handleRASSelection = async (streamId: string, streamItemId: string) => {
    /**
     * Set the following properties into ActivityStreamSwitchContext
     * to facilitate activity stream switching.
     * @param selectedActivityStreamId - currently selected activity stream id
     * @param activityStreamIds - list of activity streams in the index
     * @param paginationState - current pagination state for reference
     */
    setEnableDocumentSwitch(true);
    /**
     * Save the current document switcher state
     */
    setCurrentSwitcherStateSnapshot();
    await setSelectedActivityStreamId({ id: streamId } as ActivityStreamId);
    await setActivityStreamIds(rasArr.map((item) => ({ id: item.id })));
    await setCurrentPaginationState({
      page: pageDetails.page,
      pageCount: pageDetails.pageCount,
      pageSize: pageDetails.pageSize,
      totalRecords: pageDetails.totalRecords,
    });
    setDocumentType("activity_stream");
    /**
     * Set the current activity streams as relative to
     * documents like bills, invoices, payments etc
     */
    setIsRelativeStream(true);
    /**
     * Set page fetch params for switching pages when upper bound or lower bound
     * is reached in activity stream switching
     */
    setPageFetchParams({
      workspaceId: selectedWorkspace?.id ?? FallbackTypes.Id,
      id: props.id,
      type: props.type,
      page: initialLoad ? DEFAULT_NUMERIC_VALUES.DEFAULT_ONE : pageDetails.page + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
      page_size: pageDetails.pageSize,
    });
    history.push({
      pathname: `${getBaseRoute()}/activities/${streamId}`,
      state: { itemId: streamItemId },
    });
  };

  React.useEffect(() => {
    resetData();
    fetchStreams();
  }, [props.refreshList]);

  /**
   * reset activity streams as non relative ones
   */
  React.useEffect(() => () => setIsRelativeStream(false), []);

  /**
   * Event handler triggered when user click on 'New Activity Stream' dropdown menu item.
   * it will open the new Activity dialog.
   *
   * @param {ActivityDropdownItem} item The item which is clicked by user.
   */
  const onClickMenuItems = (item: ActivityDropdownItem) => {
    props.newActivityComponentProps?.setNewActivityType(item.displayName.toLowerCase().split(" ").join("_"));
    setDropdownMenuAnchorEl(null);
    props.newActivityComponentProps?.setShowAddActivity(true);
  };

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && relatedStreams.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO ? (
        <div className="no-streams-wrapper">
          <p className="no-streams">{EMPTY_RELATED_TRANSACTIONS_MESSAGE}</p>
          <div className="activity-wrapper">
            <Button
              className={"add-activity-btn"}
              size={"sm"}
              icon={<ArrowDown />}
              alignIcon="right"
              onClick={(e: MouseEvent<any>) => setDropdownMenuAnchorEl(e.currentTarget)}
            >
              {BTN_NEW_ACTIVITY}
            </Button>
            <Menu
              open={dropdownMenuOpen}
              anchorEl={dropdownMenuAnchorEl}
              onClose={() => setDropdownMenuAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              {props?.newActivityComponentProps?.addActivityDropdown?.map((item: ActivityDropdownItem, index: number) => {
                return (
                  <MenuItem key={index} onClick={() => onClickMenuItems(item)}>
                    {item.displayName}
                  </MenuItem>
                );
              })}
            </Menu>
          </div>
        </div>
      ) : (
        <InfiniteScroll
          dataLength={relatedStreams.length}
          next={fetchStreams}
          hasMore={relatedStreams.length < pageDetails.totalRecords}
          loader={isLoading ? <p className="body2 loaderPadding">{ACTIVITY_FETCH_MORE_MESSAGE}</p> : ""}
          height={"calc(100vh - 16.06rem)"}
          scrollableTarget="infinite-scroll"
        >
          {relatedStreams.map((item) => (
            <RelatedActivityStreamsComponent
              key={item.id}
              header={item?.subject && item?.subject !== "" ? item?.subject : "N/A"}
              status={item?.status ?? null}
              lastActivity={item?.last_activity_at ? `${Utils.evaluateDate(item?.last_activity_at)}` : null}
              streamId={item?.id ?? ""}
              streamItemId={`${item?.id ?? ""}`}
              handleRASSelection={handleRASSelection}
            />
          ))}
        </InfiniteScroll>
      )}
    </>
  );
};

export default RelatedActivityStreamsContainer;

import React from "react";
import { DEFAULT_NUMERIC_VALUES } from "../../../../../constants/NumericConstants";
import { AttachmentSidebarItemProps } from "../interfaces/AttachmentsInterfaces";

const AttachmensSidebarItem: React.FC<AttachmentSidebarItemProps> = ({ title, icon, selected, count, onClick }) => {
  return (
    <div className={`item ${selected ? "selected" : ""}`} onClick={() => onClick(title)}>
      <div className="item-info">
        <div className="icon">{icon}</div>
        <div className="title">{title}</div>
      </div>
      <div className="item-count">{count ? count : DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO}</div>
    </div>
  );
};

export default AttachmensSidebarItem;

import React from "react";
import Button from "../../../../../../../library/Button/Button";
import { BTN_APPROVE, BTN_DECLINE, BTN_CANCEL_REQUEST, BTN_SEND_REMINDER } from "./../../../../../../../../constants/config";
import { ApprovalUserActionsProps } from "../HelperTypes";

const ApprovalUserActions: React.FC<ApprovalUserActionsProps> = ({ onClickApprove, onClickCancel, onClickDecline, onClickNudge }) => {
  return (
    <>
      <Button variant="primary" size="sm" type="button" onClick={onClickApprove}>
        {BTN_APPROVE}
      </Button>
      <Button variant="secondary" size="sm" type="button" onClick={onClickDecline}>
        {BTN_DECLINE}
      </Button>
      <Button variant="secondary" size="sm" type="button" onClick={onClickCancel}>
        {BTN_CANCEL_REQUEST}
      </Button>
      <Button variant="transparent" size="sm" type="button" onClick={onClickNudge}>
        {BTN_SEND_REMINDER}
      </Button>
    </>
  );
};

export default ApprovalUserActions;

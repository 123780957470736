import React from "react";
import { CustomerChip, VendorChip, OtherChip, InternalChip, CompanyChipText } from "./companychips.style";
import { TFunction } from "i18next";

type CompanyTypeChipProps = {
  companyType: string;
  translationObject: TFunction<"translation", undefined, "translation">;
};

const CompanyTypeChip = ({ companyType, translationObject }: CompanyTypeChipProps): React.ReactElement => {
  const getChipText = (displayText: string) => {
    return <CompanyChipText>{displayText}</CompanyChipText>;
  };

  const getCompanyTypeChip = (companyType: string) => {
    switch (companyType) {
      case "Customer":
        return <CustomerChip>{getChipText(translationObject("body.chipType.company.customer", { ns: "table" }))}</CustomerChip>;
      case "Vendor":
        return <VendorChip>{getChipText(translationObject("body.chipType.company.vendor", { ns: "table" }))}</VendorChip>;
      case "Other Connection":
        return <OtherChip>{getChipText(translationObject("body.chipType.company.other", { ns: "table" }))}</OtherChip>;
      case "My Company":
        return <InternalChip>{getChipText(translationObject("body.chipType.company.myCompany", { ns: "table" }))}</InternalChip>;
      default:
        return <OtherChip>{getChipText(translationObject("body.chipType.company.other", { ns: "table" }))}</OtherChip>;
    }
  };

  return <>{getCompanyTypeChip(companyType)}</>;
};

export default CompanyTypeChip;

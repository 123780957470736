import React from "react";
import { Route, RouteProps } from "react-router-dom";
import { InteractionType } from "@azure/msal-browser";
import { useMsal, MsalAuthenticationResult, MsalAuthenticationTemplate } from "@azure/msal-react";
import ErrorMessage from "../components/library/Error/CustomError";
import { loginRequest } from "./authConfig";
import WaitScreen from "../components/Onboard/WaitScreen/WaitScreen";
import { AppContext } from "../contexts/AppContext";
import { useTranslation } from "react-i18next";

function ErrorComponent({ error }: MsalAuthenticationResult) {
  const { instance } = useMsal();
  // When a user backs out of the Forgot Password screen, a specific error is thrown that we should simply intercept and redirect to login
  if (error && error?.errorMessage.includes("AADB2C90091")) {
    instance.loginRedirect(loginRequest);
  }
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ErrorMessage isRelative>Error Message: {error && error?.errorCode ? error.errorCode : "Unknown Error"}</ErrorMessage>
    </div>
  );
}

function LoadingComponent() {
  const { userLoggedOut } = React.useContext(AppContext) as AppType;
  const { t } = useTranslation();
  return (
    <WaitScreen
      loadingMessage={`${!userLoggedOut ? t("loginMessages.main", { ns: "home" }) : t("loginMessages.logout", { ns: "home" })}`}
      secondaryMessage={`${!userLoggedOut ? t("loginMessages.captionOne", { ns: "home" }) : ""}`}
    />
  );
}

export default function PrivateRoute({ ...routeProps }: RouteProps): React.ReactElement {
  const authRequest = { ...loginRequest, prompt: undefined };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={LoadingComponent}
    >
      <Route {...routeProps} />
    </MsalAuthenticationTemplate>
  );
}

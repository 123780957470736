import React from "react";

export type DisableFeatureProviderType = {
  disableKeyboardShortcut: boolean;
  setDisableKeyboardShortcut: (val: boolean) => void;
};
export const DisableFeatureContext = React.createContext<DisableFeatureProviderType | null>(null);

type Props = {
  children: (React.ReactNode & { type: string })[] | (React.ReactNode & { type: string });
};

/**
 * @function FeatureFlagsProvider
 * A react function to provide FeatureFlags context
 * @param param
 * @returns
 */
const DisableFeatureProvider: React.FC<Props> = ({ children }: Props) => {
  const [disableKeyboardShortcut, setDisableKeyboardShortcut] = React.useState(false);

  return (
    <DisableFeatureContext.Provider value={{ disableKeyboardShortcut, setDisableKeyboardShortcut: (val) => setDisableKeyboardShortcut(val) }}>
      {children}
    </DisableFeatureContext.Provider>
  );
};

export default DisableFeatureProvider;

import React from "react";
import { makeStyles } from "@mui/styles";
import _ from "underscore";
import styles from "../../../../../css/_export.module.scss";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES, TRIM_COUNT_CONSTANTS } from "../../../../../constants/NumericConstants";
import { ActivityType, AuditLogType, EmailType } from "../../../../../types/enums";
import { ActivityFeedItemVariant } from "../ActivityFeedItem/ActivityFeedItemContainer";
import Utils from "../../../../../utils/utils";
import CustomTooltip from "../../../../library/CustomTooltip/CustomTooltip";
import { TooltipTypes } from "../../../../../constants/TooltipConstants";

/**
 * Purpose: provides function definitions being used in
 * activity feed for various purposes.
 */
class ActivityFeedMethods {
  public static evaluateTo = (item: any): ContactItem | ContactItem[] => {
    return item.to;
  };

  public static evaluateFrom = (item: any, userStatus?: any) => {
    switch (item.type) {
      case ActivityType.Email:
      case ActivityType.IncomingEmail:
        return item.from?.name === userStatus?.user_name ? "Me" : item.from?.name ?? "";
      case ActivityType.PhoneCall:
        return item.to ?? "";
      case ActivityType.Note:
        return item.creator?.name === userStatus?.user_name ? "Me" : item.creator?.name ?? "";
      default:
        if (item.action_type === AuditLogType.Assign || item.action_type === AuditLogType.MergeConnection) {
          return item.to_user?.name === userStatus?.user_name
            ? [{ ...item.to_user, name: "Me", email_address: item.to_user?.email }]
            : item.to_user?.name === item.action_by?.name
            ? [{ ...item.to_user, name: "Self", email_address: item.to_user?.email }]
            : [{ ...item.to_user, name: item.to_user?.name, email_address: item.to_user?.email }];
        }
        return item.action_by ? [item.action_by] : [{ name: "", email_address: "" }];
    }
  };

  public static evaluateVariant = (item: ActivityItemModel & AuditLogItemModel): ActivityFeedItemVariant => {
    if (item.type === ActivityType.AuditLog) {
      if (item.action_type !== ActivityType.Approval) {
        return (item.action_type ?? "") as ActivityFeedItemVariant;
      } else {
        if (["approved", "declined"].includes(item.approval_request?.status)) {
          return ActivityType.ApprovalResponse;
        } else if (item.approval_request?.status === "cancelled") {
          return ActivityType.ApprovalCancelled;
        }
      }
    } else if (item.type === ActivityType.Approval) {
      return ["approved", "declined", "cancelled"].includes(item.approval_request?.status)
        ? ActivityType.ApprovalReviewed
        : ["redirected", "expired"].includes(item.approval_request?.status)
        ? ActivityType.ApprovalAccessRevoked
        : ActivityType.ApprovalRequest;
    } else if (item.type === ActivityType.IncomingEmail && item.email_type === EmailType.bounce) {
      return ActivityType.BouncedEmail;
    } else if (item.type === ActivityType.Email) {
      if (item.email_type === EmailType.nudge) {
        return ActivityType.Nudge;
      } else if (item.origin_activity_id && item.origin_activity_id !== "") {
        return ActivityType.Forward;
      }
    } else if (item.type === ActivityType.VendorOnboarding) {
      return AuditLogType.OnboardRequest;
    }
    return (item.type ?? "") as ActivityFeedItemVariant;
  };

  public static evaluateContent = (item: any): string => {
    if (item && item.type === ActivityType.AuditLog && item.action_type === AuditLogType.MergeConnection) {
      return `${item.merge_info.merged_from.name} has merged with ${item.merge_info.merged_into.name}.`;
    }
    return item?.content;
  };

  public static evaluateStatus = (item: any) => {
    if (
      item &&
      ((item.type === ActivityType.AuditLog && item.action_type === ActivityType.Approval) ||
        item.type === ActivityType.Approval ||
        item.type === ActivityType.ApprovalRedirect ||
        item.type === ActivityType.ApprovalLinkRegenerated)
    ) {
      return item.approval_request?.status;
    }
    return item?.activity_stream?.status ?? "";
  };

  public static evaluateHeader = (item: any, variant: ActivityFeedItemVariant, formatDate?: any) => {
    switch (variant) {
      case ActivityType.Move:
        return {
          header: `Moved to ${item?.activity_stream?.primary_connection?.name || "New"} Connection`,
        };
      case ActivityType.ApprovalResponse:
        return {
          header: `${item?.action_by?.name ?? item?.action_by?.email} has ${item?.approval_request.status} the approval request`,
        };
      case ActivityType.ApprovalRedirect:
        return {
          header: `Approval request is redirected to ${item?.to?.[0]?.name ?? item?.to?.[0]?.email_address}`,
        };
      case ActivityType.ApprovalCancelled:
        return {
          header: `cancelled the approval request sent to ${
            item?.approval_request?.approver?.name ?? item?.approval_request?.approver?.email
          } on ${formatDate(Utils.evaluateDate(item?.approval_request?.created_at ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO))}.`,
        };
      default:
        return {};
    }
  };

  public static evaluateCreator = (item: any) => {
    return (item?.type === ActivityType.AuditLog && item?.action_type === AuditLogType.Forward) ||
      item?.action_type === AuditLogType.Snoozed ||
      item?.action_type === AuditLogType.Unsnoozed ||
      item?.action_type === AuditLogType.Assign ||
      item?.action_type === AuditLogType.Unassign ||
      item?.action_type === AuditLogType.MergeConnection
      ? item?.action_by
      : item?.creator;
  };

  public static createTo = (contact: ContactItem) => {
    return {
      id: contact?.name ? contact?.email_address ?? "" : "",
      label: contact?.name || contact?.email_address || "",
    } as To;
  };

  public static filterValidContacts = (contacts: ContactItem[]) => {
    return contacts
      .filter((contact: ContactItem) => {
        return contact?.email_address !== "";
      })
      .map((contact: ContactItem) => {
        return this.createTo(contact);
      });
  };

  public static getReplyOptions: any = (email: ActivityItemModel) => {
    let reply: To[] = [];
    let replyAllTo: To[] = [];
    let replyAllCC: To[] = [];
    let replyAllBCC: To[] = [];
    const validTos = email?.to ? this.filterValidContacts(email?.to) : [];

    /**
     * Scenario : Creator and replier are same, then we are using the existing Tos.
     * Scenario : If creator and replier are different, then we are using from.
     */
    reply =
      email?.from?.email_address === email?.workspace?.email_address
        ? validTos
        : email?.from?.email_address
        ? [
            {
              id: email.from?.name ? email.from?.email_address ?? "" : "",
              label: email.from?.name || email.from?.email_address || "",
            },
          ]
        : [];

    // calculation: to + from - workspace
    replyAllTo = [...reply, ...validTos]
      .filter((to) => {
        return to.id !== email.workspace.email_address;
      })
      .filter((v, i, a) => a.findIndex((t) => t.label === v.label) === i);

    replyAllCC = email?.cc ? this.filterValidContacts(email?.cc) : [];
    replyAllBCC = email?.bcc ? this.filterValidContacts(email?.bcc) : [];
    return {
      reply,
      replyAll: replyAllTo,
      replyAllCC,
      replyAllBCC,
      nudgeTo: validTos,
    };
  };

  /**
   * helper function to populate To, Cc, Bcc for primary
   * activity stream items
   *
   * @param contacts list of contact objects
   * @returns a string of comma seperated emails
   */
  public static getContactEmailAsString = (contacts: ContactItem[]): string => {
    if (!contacts || !(_.size(contacts ?? []) > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO)) {
      return "";
    }
    return contacts?.map((contact: ContactItem) => contact?.name || contact?.email_address).join(", ");
  };

  /**
   * @param label Label to denote To, Cc, Bcc
   * @param contacts list of contacts to be displayed on screen
   * @returns comma separated contact list element with an tooltip
   */
  public static GenerateSubheadContactString(label: string, contacts: ContactItem[] = []) {
    return _.size(contacts) > TRIM_COUNT_CONSTANTS.SHOW_EMAIL ? (
      <CustomTooltip
        type={this.getContactEmailAsString(contacts)?.length > NUMERIC_VALUES.CONSTANT_TWENTY ? TooltipTypes.RICH : TooltipTypes.PLAIN}
        title={this.getContactEmailAsString(contacts)}
        placement="bottom"
      >
        <span>
          {label}{" "}
          {contacts
            ?.map((contact: ContactItem) => contact?.name || contact?.email_address)
            .slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, TRIM_COUNT_CONSTANTS.SHOW_EMAIL)
            .join(", ")}{" "}
          and {contacts?.length - TRIM_COUNT_CONSTANTS.SHOW_EMAIL} more
        </span>
      </CustomTooltip>
    ) : (
      `${label} ${this.getContactEmailAsString(contacts)}`
    );
  }

  // TODO: Nitin: move this to respective component
  public static useTooltipStyles = makeStyles(() => ({
    tooltip: {
      background: "white !important",
      margin: "0 !important",
      padding: "0 !important",
      borderRadius: "2px",
      boxShadow: "0 8px 12px rgba(0, 0, 0, 0.1), 0 4px 20px rgba(0, 0, 0, 0.1)",
    },
    richTooltip: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: styles.base,
      gap: "0.875rem",
      backgroundColor: styles.white100,
      color: styles.primary,
      boxShadow: `0px 0.25rem 0.25rem rgba(0, 0, 0, 0.08), 0px -0.125rem 0.5rem rgba(0, 0, 0, 0.04)`,
      borderRadius: styles.tiny,
      minHeight: "3.75rem",
      maxWidth: "17.5rem",
    },
  }));
}

export default ActivityFeedMethods;

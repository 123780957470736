import APIClient from "../APIClient";
const PLATFORM_API_BASE = process.env.REACT_APP_API_ENDPOINT_PLATFORM_V1;

export class ProvisioningClient {
  /**
   * * POST on /api/v1/Provisioning
   * @param token - Bearer token
   * @param onboardData - Onboard data object
   * @returns Promise of type ProvisioningResponseModel or Exception
   */
  provision(token: string, onboardData: OnboardDataModel): Promise<ProvisioningResponseModel> {
    const url = `${PLATFORM_API_BASE}/Provisioning`;

    // Parse for required values
    const body = { fullName: onboardData.fullName, erp: onboardData.erp };

    // We manually add the access token and bypass MSAL since we received it from the embedded Azure B2C account creation page
    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return APIClient.post(url, body, options, true).then((res) => res.data);
  }

  /**
   * * POST on /api/v1/Provisioning/finalize
   * @param token - Bearer token
   * @param onboardData - Onboard data object
   * @returns Promise of type ProvisioningResponseModel or Exception
   */
  finalize(token: string, onboardData: OnboardDataModel): Promise<ProvisioningResponseModel> {
    const url = `${PLATFORM_API_BASE}/Provisioning/finalize`;

    // Parse for required values
    const body = {
      fullName: onboardData.fullName,
      timeZone: onboardData.timeZone,
      defaultCurrency: onboardData.defaultCurrency,
      company: onboardData.company,
    };

    // We manually add the access token and bypass MSAL since we received it from the embedded Azure B2C account creation page
    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return APIClient.post(url, body, options, true).then((res) => res.data);
  }
}

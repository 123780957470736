import React, { useEffect } from "react";
import { AttachmentChip } from "../../../../library/AtomicComponents/AttachmentChip";
import { NUMERIC_VALUES } from "../../../../library/AtomicComponents/constants/numeric.constants";
import { CardActions, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { formatDate } from "../../../../../db/utils/date";
import TableUtils from "../../../../../utils/TableUtils/TableUtils";
import { AlertContext } from "../../../../../contexts/AlertContext";
import { AttachmentPreview } from "../../../../library/AtomicComponents/AttachmentPreview";
import { AttachmentCardRoot } from "./styled";
import { DisableFeatureContext, DisableFeatureProviderType } from "../../../../../contexts/DisableFeatureContext";
import { useTranslation } from "react-i18next";

interface AttachmentsProps {
  openAttachmentPreview: boolean;
  setOpenAttachmentPreview: (openAttachmentPreview: boolean) => void;
  attachments: AttachmentItem[];
}

export default function Attachments({ openAttachmentPreview, setOpenAttachmentPreview, attachments }: AttachmentsProps): React.ReactElement {
  const toastContext = React.useContext(AlertContext) as AlertContextType;
  const { t } = useTranslation();

  const onClickDownload = (
    event: React.MouseEvent<HTMLTableRowElement | HTMLDivElement | HTMLButtonElement | HTMLAnchorElement | HTMLSpanElement>,
    attachments: AttachmentItem[] | BlobAttachmentItem[]
  ) => {
    event.stopPropagation();
    TableUtils.downloadAll(attachments, toastContext?.setToastOptions);
  };

  const [activeAttachmentID, setActiveAttachmentID] = React.useState<string | number>("");
  const { setDisableKeyboardShortcut } = React.useContext(DisableFeatureContext) as DisableFeatureProviderType;

  const onClickPreviewOpen = (event: React.MouseEvent<HTMLDivElement>, activeAttachmentID: string | number) => {
    event.stopPropagation();
    setActiveAttachmentID(activeAttachmentID);
    setOpenAttachmentPreview(!openAttachmentPreview);
  };

  const onClickPreviewClose = (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement> | KeyboardEvent) => {
    event.stopPropagation();
    setOpenAttachmentPreview(false);
  };

  useEffect(() => {
    if (openAttachmentPreview) {
      setDisableKeyboardShortcut(true);
    } else {
      setDisableKeyboardShortcut(false);
    }
    return () => setDisableKeyboardShortcut(false);
  }, [openAttachmentPreview]);

  return (
    <>
      {attachments.length > NUMERIC_VALUES.CONSTANT_ZERO && (
        <AttachmentCardRoot raised={true}>
          <div className="attachments">
            {attachments.map((document: AttachmentItem) => {
              return (
                <AttachmentChip
                  key={`attachment-${document.id}`}
                  name={document?.file_name ?? ""}
                  extension={document.extension}
                  createdDate={formatDate(document.created_at.toString())}
                  attachment={document}
                  onClickAttachmentChip={(event) => onClickPreviewOpen(event, document.id)}
                  onClickDownload={(event) => onClickDownload(event, [document])}
                />
              );
            })}
          </div>
          <CardActions>
            <Link to="#" component={RouterLink} onClick={(event) => onClickDownload(event, attachments)}>
              {t("activityDetails.rightPanelDrawer.attachmentDrawer.attachmentCardView.downlaodActionLabel", { ns: "activities" })}
            </Link>
          </CardActions>
        </AttachmentCardRoot>
      )}
      {openAttachmentPreview && (
        <AttachmentPreview
          openPreview={openAttachmentPreview}
          activeAttachmentID={activeAttachmentID}
          handleClose={onClickPreviewClose}
          attachments={attachments}
          onClickDownload={onClickDownload}
        />
      )}
    </>
  );
}

import React, { useState } from "react";
import { ClickAwayListener, Drawer, IconButton } from "@mui/material";
import { ChevronLeftOutlined, ChevronRightOutlined, Close } from "@mui/icons-material";
import { AttachmentTab, CompanyTab, TransactionsTab } from "../../../library/Icons/Icons";
import { ActivityTabType } from "../../../../types/enums";
import Attachments from "./AttachmentsTab/Attachments";
import CompanyProfile from "./CompanyProfileTab/CompanyProfile";
import Transactions from "./TransactionsTab/Transactions";
import RightPanelCollapsibleSkeleton from "./RightPanelCollapsibleSkeleton";
import { RelatedTransactions as IRelatedTransactions } from "./RightPanelCollapsibleInterfaces";
import { NUMERIC_VALUES } from "../../../library/AtomicComponents/constants/numeric.constants";
import "./RightPanelCollapsible.scss";
import { useTranslation } from "react-i18next";

interface RightPanelCollapsibleProps {
  isLoading: boolean;
  companyName: string;
  contacts: ContactItem[];
  attachments: AttachmentItem[];
  transactions: IRelatedTransactions;
}

export default function RightPanelCollapsible({
  isLoading = true,
  companyName,
  contacts,
  attachments,
  transactions,
}: RightPanelCollapsibleProps): React.ReactElement {
  const [activePanelTab, setActivePanelTab] = useState<ActivityTabType | null>(null);
  const [openAttachmentPreview, setOpenAttachmentPreview] = React.useState<boolean>(false);
  const [rightPanelFlyout, setRightPanelFlyout] = useState<boolean>(false);
  const [drawerSubtitle, setDrawerSubtitle] = useState<string>("");
  const [drawerTitle, setDrawerTitle] = useState<string>("");
  const [transactionsInfoVisibility, setTransactionsInfoVisibility] = useState<boolean>(
    localStorage.getItem("rcpTransactionsInfoVisibility") === null || localStorage.getItem("rcpTransactionsInfoVisibility") === "true" ? true : false
  );
  const { t } = useTranslation();

  const onClickPanelClose = () => {
    if (!openAttachmentPreview) {
      setActivePanelTab(null);
      setRightPanelFlyout(false);
    }
  };

  const getAttachmentDrawerInfo = (attachments: AttachmentItem[]) => {
    const count = attachments.length > NUMERIC_VALUES.CONSTANT_ZERO ? attachments.length : NUMERIC_VALUES.CONSTANT_ZERO;
    const attachmentText =
      count < NUMERIC_VALUES.CONSTANT_TWO
        ? t("activityDetails.rightPanelDrawer.attachmentDrawer.title.singleAttachment", { count, ns: "activities" })
        : t("activityDetails.rightPanelDrawer.attachmentDrawer.title.multiAttachment", { count, ns: "activities" });
    const noAttachment = t("activityDetails.rightPanelDrawer.attachmentDrawer.title.noAttachment", { ns: "activities" });

    return {
      title: count === NUMERIC_VALUES.CONSTANT_ZERO ? noAttachment : attachmentText,
      subtitle: t("activityDetails.rightPanelDrawer.attachmentDrawer.subTitle", { ns: "activities" }),
    };
  };

  const getTransactionDrawerInfo = (transactions: IRelatedTransactions) => {
    const count = [...(transactions.invoices ?? []), ...(transactions.bills ?? []), ...(transactions.payments ?? [])].length;
    const transactionText =
      count < NUMERIC_VALUES.CONSTANT_TWO
        ? t("activityDetails.rightPanelDrawer.transactionDrawer.title.singleTransaction", { count, ns: "activities" })
        : t("activityDetails.rightPanelDrawer.transactionDrawer.title.multiTransaction", { count, ns: "activities" });
    const noTransaction = t("activityDetails.rightPanelDrawer.transactionDrawer.title.noTransaction", { ns: "activities" });

    return {
      title: count === NUMERIC_VALUES.CONSTANT_ZERO ? noTransaction : transactionText,
      subtitle: t("activityDetails.rightPanelDrawer.transactionDrawer.subTitle", { ns: "activities" }),
    };
  };

  const getProfileDrawerInfo = (companyName: string) => {
    return {
      title: companyName.length > NUMERIC_VALUES.CONSTANT_ZERO ? companyName : "N/A",
      subtitle: t("activityDetails.rightPanelDrawer.profileDrawer.subTitle", { ns: "activities" }),
    };
  };

  const toggleTransactionsInfoVisibility = () => {
    setTransactionsInfoVisibility(false);
    localStorage.setItem("rcpTransactionsInfoVisibility", "false");
  };

  const onClickPanelOpen = (Tab: ActivityTabType) => {
    setActivePanelTab(Tab);
    setRightPanelFlyout(Tab === activePanelTab ? !rightPanelFlyout : true);
    switch (Tab) {
      case ActivityTabType.Attachments:
        setDrawerTitle(getAttachmentDrawerInfo(attachments).title);
        setDrawerSubtitle(getAttachmentDrawerInfo(attachments).subtitle);
        return;
      case ActivityTabType.Transactions:
        setDrawerTitle(getTransactionDrawerInfo(transactions).title);
        setDrawerSubtitle(getTransactionDrawerInfo(transactions).subtitle);
        return;
      case ActivityTabType.Profile:
      default:
        setDrawerTitle(getProfileDrawerInfo(companyName).title);
        setDrawerSubtitle(getProfileDrawerInfo(companyName).subtitle);
        return;
    }
  };

  return (
    <ClickAwayListener onClickAway={onClickPanelClose}>
      <div className="activity-detail-right-panel-collapsible">
        <Drawer
          sx={{
            width: "26rem",
          }}
          open={rightPanelFlyout}
          variant="persistent"
          anchor="right"
          className="right-drawer"
        >
          {isLoading ? (
            <RightPanelCollapsibleSkeleton activePanelTab={activePanelTab} onPanelClose={onClickPanelClose} />
          ) : (
            <>
              <div className="right-drawer-info-container">
                <div className="drawer-info-panel">
                  <span className="drawer-sub-title">{drawerSubtitle}</span>
                  <span className="drawer-close-button" onClick={onClickPanelClose}>
                    <Close />
                  </span>
                </div>
                <span className="drawer-title">{drawerTitle}</span>
              </div>
              {activePanelTab === ActivityTabType.Transactions && transactionsInfoVisibility && (
                <div className="right-drawer-info-textbox">
                  <div className="drawer-info-text">{t("activityDetails.rightPanelDrawer.transactionDrawer.infoText", { ns: "activities" })}</div>
                  <div className="drawer-info-action-container">
                    <span className="drawer-info-action" onClick={toggleTransactionsInfoVisibility}>
                      {t("activityDetails.rightPanelDrawer.transactionDrawer.infoActionLabel", { ns: "activities" })}
                    </span>
                  </div>
                </div>
              )}
              {activePanelTab === ActivityTabType.Profile && <CompanyProfile contacts={contacts} />}
              {activePanelTab === ActivityTabType.Transactions &&
                [...(transactions.invoices ?? []), ...(transactions.bills ?? []), ...(transactions.payments ?? [])].length >
                  NUMERIC_VALUES.CONSTANT_ZERO && <Transactions transactions={transactions} />}
              {activePanelTab === ActivityTabType.Transactions &&
                [...(transactions.invoices ?? []), ...(transactions.bills ?? []), ...(transactions.payments ?? [])].length ===
                  NUMERIC_VALUES.CONSTANT_ZERO && (
                  <div className="right-drawer-empty-panel">
                    <div className="drawer-empty-title">
                      {t("activityDetails.rightPanelDrawer.transactionDrawer.dataUnavailableMessage.title", { ns: "activities" })}
                    </div>
                    <div className="drawer-empty-subtext">
                      {t("activityDetails.rightPanelDrawer.transactionDrawer.dataUnavailableMessage.subTitle", { ns: "activities" })}
                    </div>
                  </div>
                )}
              {activePanelTab === ActivityTabType.Attachments && (
                <Attachments
                  openAttachmentPreview={openAttachmentPreview}
                  setOpenAttachmentPreview={setOpenAttachmentPreview}
                  attachments={attachments}
                />
              )}
            </>
          )}
        </Drawer>
        <div className="top-buttons">
          <IconButton
            color="primary"
            onClick={() => onClickPanelOpen(ActivityTabType.Profile)}
            size="small"
            className={`right-panel-action-button ${activePanelTab === ActivityTabType.Profile ? "active" : ""}`}
          >
            <CompanyTab />
          </IconButton>
          <IconButton
            color="secondary"
            onClick={() => onClickPanelOpen(ActivityTabType.Transactions)}
            size="small"
            className={`right-panel-action-button ${activePanelTab === ActivityTabType.Transactions ? "active" : ""}`}
          >
            <TransactionsTab />
          </IconButton>
          <IconButton
            color="success"
            onClick={() => onClickPanelOpen(ActivityTabType.Attachments)}
            size="small"
            className={`right-panel-action-button ${activePanelTab === ActivityTabType.Attachments ? "active" : ""}`}
          >
            <AttachmentTab />
          </IconButton>
        </div>
        <div className="bottom-buttons">
          <span onClick={() => (rightPanelFlyout ? onClickPanelClose() : onClickPanelOpen(ActivityTabType.Profile))}>
            {rightPanelFlyout ? <ChevronRightOutlined /> : <ChevronLeftOutlined />}
          </span>
        </div>
      </div>
    </ClickAwayListener>
  );
}

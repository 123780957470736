import APIClient from "../APIClient";
const PLATFORM_API_BASE = process.env.REACT_APP_API_ENDPOINT_PLATFORM_V1;

export class LeadsClient {
  /**
   * * POST on /api/v1/Leads
   * @param body - LeadsModel that contains the email and ERP system which the user has requested.
   * @returns Promise of type LeadsModel
   */
  createLead(body: LeadsModel[]): Promise<LeadsModel[]> {
    const url = `${PLATFORM_API_BASE}/Leads`;

    return APIClient.post(url, body, undefined, true).then(response => {
      return response.data;
    });
  }
}

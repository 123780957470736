import React from "react";
import { ImagePreviewPropTypes } from "./types";
import { ImagePreviewRoot } from "../styled/styled";

export default function ImagePreview({ fileName = "", fileURL = "" }: ImagePreviewPropTypes): React.ReactElement {
  return (
    <ImagePreviewRoot>
      <img src={fileURL} alt={fileName} />
    </ImagePreviewRoot>
  );
}

import React from "react";
import { ApprovalActivityComponentProps } from "./ApprovalActivityTypes";
import { ActivityFeedItem } from "../../Activities/ActivityDetail/ActivityFeed/ActivityFeedItem/ActivityFeedItemContainer";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import ApprovalRelatedTransactions from "./ApprovalRelatedTransactions";
import "./../../Activities/ActivityDetail/ActivityFeed/ActivityFeed.scss";
import "../../Activities/ActivityDetail/ActivityFeed/ActivityFeedItem/ActivityFeedItem.scss";
import "./ApprovalActivity.scss";
import { ActivityTabType } from "../../../types/enums";
import AttachmentTab from "../../Activities/ActivityDetail/ActivityTab/ActivityAttachmentTab/ActivityAttachmentTab";
import { DEFAULT_NUMERIC_VALUES } from "../../../constants/NumericConstants";

const ApprovalActivityComponent: React.FC<ApprovalActivityComponentProps> = ({
  stream,
  transactions,
  attachments,
  erpConnection,
  isReadonly,
  currency,
  countryCode,
}) => {
  const { invoices, payments, bills } = transactions ?? {};
  const [currentTab, setTab] = React.useState<ActivityTabType>(ActivityTabType.Attachments);

  return (
    <div className="activity-detail-container">
      <div className="body">
        <div className="left">
          <div className="head">
            <div className="tabs-wrapper">
              <p className="tab tab-active body2">Activity</p>
            </div>
          </div>
          <div className={`body height${isReadonly ? "-readonly" : ""}`}>
            <OverlayScrollbarsComponent
              options={{ paddingAbsolute: true, autoUpdate: true, sizeAutoCapable: true, overflowBehavior: { x: "hidden" } }}
            >
              {stream?.map((item: any) => (
                <ActivityFeedItem {...item} key={`${item.id}`} readonly countryCode={countryCode} />
              ))}
            </OverlayScrollbarsComponent>
          </div>
        </div>
        <div className="right">
          <div className="head">
            <div className="tabs-wrapper">
              <p
                className={`tab ${currentTab === ActivityTabType.Attachments ? "tab-active" : ""} body2`}
                onClick={() => setTab(ActivityTabType.Attachments)}
              >
                Attachments
              </p>
              {erpConnection && (
                <p
                  className={`tab ${currentTab === ActivityTabType.Transactions ? "tab-active" : ""} body2`}
                  onClick={() => setTab(ActivityTabType.Transactions)}
                >
                  Transactions
                </p>
              )}
            </div>
          </div>
          <div className={`body height${isReadonly ? "-readonly" : ""}`}>
            {currentTab === ActivityTabType.Attachments && (
              <AttachmentTab
                custom_classes={`${attachments && attachments?.length < DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO ? "" : "no-"}attachments-wrapper`}
                attachments={attachments ?? []}
                countryCode={countryCode}
              />
            )}
            {currentTab === ActivityTabType.Transactions && (
              <ApprovalRelatedTransactions
                currency={currency}
                invoices={invoices ?? []}
                bills={bills ?? []}
                payments={payments ?? []}
                countryCode={countryCode}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApprovalActivityComponent;

import React, { useState, useMemo } from "react";
import { NUMERIC_VALUES } from "../constants/NumericConstants";
import { AlertContext } from "../contexts/AlertContext";
/**
 * @function useDragAndDropFiles
 * reusable handler for handling drag and drop of files event over the HTML element containers
 * @param acceptedFileTypes: string of array for the accepted files type ex : [".xls", ".pdf",]
 * @returns droppedFiles: a list of dropped files
 * handleFilesDrop and handleFilesDragOver functions
 */
const useDragAndDropFiles = (acceptedFileTypes: string[] = [], invalidDroppedFilesMessage?: string) => {
  const [droppedFiles, setDroppedFiles] = useState<FileList | undefined>();
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;

  const memoizedAcceptedFileTypes = useMemo(() => acceptedFileTypes.map((ext: string) => ext.toLowerCase()), [acceptedFileTypes]);

  /** function is invoked when a draggable item is dragged over an element in the component
   *  purpose is to prevent the default behavior that would otherwise occur when a file is dragged over
   *  an element (such as opening the file or navigating to the dropped file).
   */
  const handleFilesDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  // function is invoked when a draggable item is dropped onto the component.
  const handleFilesDrop = (e: React.DragEvent) => {
    e.preventDefault();
    const droppedDataFiles = e.dataTransfer.files;
    if (acceptedFileTypes.length === NUMERIC_VALUES.CONSTANT_ZERO && droppedDataFiles?.length) {
      // If acceptedFileTypes is empty, set droppedFiles to droppedFiles
      setDroppedFiles(droppedDataFiles);
      return;
    }

    //Else if acceptedFileTypes is not empty then check the dropped files type
    const validFiles: File[] = [];
    if (droppedDataFiles?.length) {
      Array.from(droppedDataFiles).forEach((file) => {
        const fileName = file.name.toLowerCase();
        /** Subtracts 1 from the index of dot(.) , then uses a bitwise zero-fill right shift (>>> 0) to ensure that the result is a non-negative integer.
         *  This step is used to handle cases where there might not be a dot in the filename. If there's no dot, lastIndexOf returns -1, so this operation
         *  converts it to 0 to avoid negative indices.+ 2 is added to the result because we want to start extracting characters from two positions after the dot
         *  (to exclude the dot itself).
         */
        const fileExtension = fileName.slice(
          ((fileName.lastIndexOf(".") - NUMERIC_VALUES.CONSTANT_ONE) >>> NUMERIC_VALUES.CONSTANT_ZERO) + NUMERIC_VALUES.CONSTANT_TWO
        );

        //Show toast message if there are any unsupported dropped files
        if (!memoizedAcceptedFileTypes?.includes("." + fileExtension) && invalidDroppedFilesMessage) {
          setToastOptions({
            open: true,
            severity: "error",
            message: invalidDroppedFilesMessage,
          });
        }

        //Add to the valid files array
        if (memoizedAcceptedFileTypes.includes("." + fileExtension)) {
          validFiles.push(file);
        }
      });
    }

    //convert the  validFiles to validFileList
    const validFileList = new DataTransfer();
    validFiles.forEach((file) => {
      validFileList.items.add(file);
    });

    setDroppedFiles(validFileList.files);
  };

  return { droppedFiles, handleFilesDrop, handleFilesDragOver };
};

export default useDragAndDropFiles;

import React, { useMemo, useRef, Dispatch, SetStateAction, useEffect } from "react";
import * as constants from "../../../../constants/config";
import Attachment from "./CustomButtons/Attachment";
import FileAttachment from "../../../library/FileAttachment/FileAttachment";
import Utils from "../../../../utils/utils";
import "./ActivityBody.scss";
import { DEFAULT_NUMERIC_VALUES, GENERIC_UNICODE_VALUES } from "../../../../constants/NumericConstants";
import JoditEditor from "./JoditHOC/JoditHOC";
import { IJodit, IUIButtonState } from "jodit/types";
import { Undo, Redo, Bold, Italic, Underline, Strikethrough } from "./JoditIcons/JoditIcons";
import DOMPurify from "dompurify";
import { Link } from "./JoditIcons/JoditIcons";
import { Jodit } from "jodit-pro-react";
import { ActivityWindowConfigs, TextBoxMsgVariants } from "./TextboxMsg/constants/InboxTextbox.configs";
import { Textbox } from "../../AtomicComponents/InAppTextbox";
import TextboxMsgs from "./TextboxMsg/TextboxMsg";
import { TextBoxConfigObj } from "./TextboxMsg/types/InboxTextbox.types";
import { WindowAttachment } from "../types/windowAttachment.types";
import { NUMERIC_VALUES } from "../../AtomicComponents/constants/numeric.constants";
import EmailTemplateComponent from "../../EmailTemplatePopoverComponent";
import useEmailTemplatePopover from "../../EmailTemplatePopoverComponent/hook/useEmailTemplatePopover";
import { TabListType, TemplateListItemType } from "../../EmailTemplatePopoverComponent/types/email.template.popover.types";

type MentionsDataItemType = {
  id: number;
  name?: string;
  email_address?: string;
  active_at?: string;
  user_role?: string;
};

type MentionMembersType = {
  loading: boolean;
  members: Array<MentionsDataItemType>;
};

type TemplateWorkflowTypes = {
  languageDropdownList: { id: string | number; text: string; icon?: React.ReactNode }[];
  templateList: TemplateListItemType[];
  templateTabList: TabListType[];
  isTemplatePreviewRequired: boolean;
  isTemplateListViewRequired: boolean;
  isCountRequiredWithTabs: boolean;
  hasTemplateApiFailedOrNoDataFound: boolean;
  isTemplateSearchRequired: boolean;
  isTemplateTabRequired: boolean;
  isTempListCardLangDrpdwnRequired: boolean;
  isTempPreviewLangDrpdwnRequired: boolean;
  onChangeTemplateTabs: (selectedTabId: number) => void;
  onChangeTemplateLanguage: (selectedLangCode: string) => void;
  onApplySelectedTemplate: (templatedId: string) => void;
  isTemplateFlowRequired?: boolean;
};

interface ActivityBodyProps {
  editorState: string;
  setEditorState: Dispatch<SetStateAction<string>>;
  handleFileSelect: (newFiles: FileList) => void;
  handleFileRemove: (key: number) => void;
  forwarding?: boolean;
  isCC?: boolean;
  isBCC?: boolean;
  to?: To[];
  onClickAddAttachments: (attachments: InboxAttachment[]) => void;
  addMagicLinkSnippet?: boolean;
  mentionMembers: MentionMembersType;
  enableMentions: boolean;
  attachments: WindowAttachment[];
  didRemoveUnsupported: boolean;
  templateWorkflowData: TemplateWorkflowTypes;
  onChange?: (state: string) => void;
}

export default function ActivityBody(props: ActivityBodyProps): React.ReactElement {
  /**
   * Jodit Editor
   * This component makes use of Jodit, an opensource WYSIWYG editor written in pure typescript and no dependencies.
   * This implementation is using jodit-react, which is a react wrapper on jodit base library. This component is initialized
   * with a memoized config object as shown below. Editor state is always created and consumed as string type.
   * @see {@link https://xdsoft.net/jodit/ | Jodit Editor}
   * @see {@link https://xdsoft.net/jodit/play.html | Customisation}
   */
  const editor = useRef<HTMLTextAreaElement | undefined | null>(null);
  const EditorInstance = useRef<IJodit>();
  const config = useMemo(() => {
    return {
      license: process.env.REACT_APP_JODIT_LICENSE,
      autofocus: false,
      cursorAfterAutofocus: "start",
      showTooltip: false,
      readonly: props?.forwarding ?? false,
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      spellcheck: true,
      toolbarButtonSize: "large" as IUIButtonState["size"],
      minHeight: "27.25rem",
      maxHeight: "27.25rem",
      tabIndex: 1,
      buttons: "undo,redo,|,bold,italic,underline,strikethrough",
      buttonsMD: "undo,redo,|,bold,italic,underline,strikethrough",
      buttonsSM: "undo,redo,|,bold,italic,underline,strikethrough",
      buttonsXS: "undo,redo,|,bold,italic,underline,strikethrough",
      editorCssClass: "na-body-editor",
      disablePlugins: "add-new-line,placeholder,inline-popup, tune-block",
      activeButtonsInReadOnly: [],
      extraButtons: ["LinkEditorPlugin"],
      extraIcons: {
        LinkEditorPlugin: Link,
      },
      enter: "div" as any,
      autocomplete: props.enableMentions
        ? {
            sources: [
              {
                feed: (query: string) => {
                  if (
                    query === "@" ||
                    ((query.charCodeAt(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) === GENERIC_UNICODE_VALUES.BOM ||
                      query.charCodeAt(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) === GENERIC_UNICODE_VALUES.SPACE) &&
                      query.indexOf("@") === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE &&
                      query.length === DEFAULT_NUMERIC_VALUES.DEFAULT_TWO)
                  ) {
                    return props.mentionMembers.members ?? [];
                  }
                  if (query.charAt(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) === "@") {
                    const searchParam = query.slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ONE, query.length);
                    return props.mentionMembers?.members?.filter(
                      (value) =>
                        value.name?.toLowerCase()?.indexOf(searchParam?.toLowerCase()) !== DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE ||
                        value.email_address?.toLowerCase()?.indexOf(searchParam?.toLowerCase()) !== DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE
                    );
                  }
                  if (
                    (query.charCodeAt(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) === GENERIC_UNICODE_VALUES.BOM ||
                      query.charCodeAt(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) === GENERIC_UNICODE_VALUES.SPACE) &&
                    query.indexOf("@") === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
                  ) {
                    const searchParam = query.slice(DEFAULT_NUMERIC_VALUES.DEFAULT_TWO, query.length);
                    return props.mentionMembers?.members?.filter(
                      (value) =>
                        value.name?.toLowerCase()?.indexOf(searchParam?.toLowerCase()) !== DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE ||
                        value.email_address?.toLowerCase()?.indexOf(searchParam?.toLowerCase()) !== DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE
                    );
                  }
                },
                itemRenderer: (item: MentionsDataItemType) => {
                  return `<div id="mention-list-items" class="mention">
                      <div id="mention-list-items" class="user-pic">
                        <svg
                          width=${Utils.getConvertedSizeInRem(parseInt("16", NUMERIC_VALUES.CONSTANT_TEN))}
                          height=${Utils.getConvertedSizeInRem(parseInt("21", NUMERIC_VALUES.CONSTANT_TEN))}
                          viewBox="0 0 16 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M16 21H14V19C14 18.2044 13.6839 17.4413 13.1213 16.8787C12.5587 16.3161 11.7956 16 11 16H5C4.20435 16 3.44129 16.3161 2.87868 16.8787C2.31607 17.4413 2 18.2044 2 19V21H0V19C0 17.6739 0.526784 16.4021 1.46447 15.4645C2.40215 14.5268 3.67392 14 5 14H11C12.3261 14 13.5979 14.5268 14.5355 15.4645C15.4732 16.4021 16 17.6739 16 19V21ZM8 12C7.21207 12 6.43185 11.8448 5.7039 11.5433C4.97595 11.2417 4.31451 10.7998 3.75736 10.2426C3.20021 9.68549 2.75825 9.02405 2.45672 8.2961C2.15519 7.56815 2 6.78793 2 6C2 5.21207 2.15519 4.43185 2.45672 3.7039C2.75825 2.97595 3.20021 2.31451 3.75736 1.75736C4.31451 1.20021 4.97595 0.758251 5.7039 0.456723C6.43185 0.155195 7.21207 -1.17411e-08 8 0C9.5913 2.37122e-08 11.1174 0.632141 12.2426 1.75736C13.3679 2.88258 14 4.4087 14 6C14 7.5913 13.3679 9.11742 12.2426 10.2426C11.1174 11.3679 9.5913 12 8 12ZM8 10C9.06087 10 10.0783 9.57857 10.8284 8.82843C11.5786 8.07828 12 7.06087 12 6C12 4.93913 11.5786 3.92172 10.8284 3.17157C10.0783 2.42143 9.06087 2 8 2C6.93913 2 5.92172 2.42143 5.17157 3.17157C4.42143 3.92172 4 4.93913 4 6C4 7.06087 4.42143 8.07828 5.17157 8.82843C5.92172 9.57857 6.93913 10 8 10Z" />
                        </svg>
                      </div>
                      <div id="mention-list-items" class="mention-data">
                        <span id="mention-list-items" class="name">
                          ${item.name}
                        </span>
                        <span id="mention-list-items" class="email">
                          ${item.email_address}
                        </span>
                      </div>
                    </div>`;
                },
                insertValueRenderer: ({ name }: MentionsDataItemType) => {
                  return `<span><span style="color:#2D9DE7;text-indent:2rem;">@${name}</span>&nbsp;</span>`;
                },
              },
            ],
          }
        : {},
      events: {
        getIcon: (name: string, control: any, clearName: string) => {
          let code = clearName;
          switch (clearName) {
            case "undo":
              code = Undo;
              break;
            case "redo":
              code = Redo;
              break;
            case "bold":
              code = Bold;
              break;
            case "italic":
              code = Italic;
              break;
            case "underline":
              code = Underline;
              break;
            case "strikethrough":
              code = Strikethrough;
              break;

            default:
              return;
          }
          return code;
        },
        afterInit: (instance: IJodit) => {
          EditorInstance.current = instance;
        },
      },
    };
  }, [props.mentionMembers, props.enableMentions]);

  // In-app Textbox for Activity popup window
  const [textboxMessageConfig, setTextboxMessageConfig] = React.useState<TextBoxConfigObj | undefined>();

  /**
   * When user clicks on Insert Magic Link, the snippet is added
   * on the cursor position of the user.
   */
  useEffect(() => {
    props.addMagicLinkSnippet &&
      !props.editorState.includes(constants.MAGIC_LINK_SNIPPET_BUTTON) &&
      EditorInstance.current?.selection.insertHTML(`${constants.MAGIC_LINK_SNIPPET}&nbsp`);
  }, [props.addMagicLinkSnippet]);

  React.useMemo(() => {
    if (props.didRemoveUnsupported) {
      setTextboxMessageConfig(ActivityWindowConfigs[TextBoxMsgVariants.INVALID_ATTACHMENT]);
    } else {
      setTextboxMessageConfig(undefined);
    }
  }, [props.didRemoveUnsupported]);

  /**
   * Keylogger for Jodit Editor For setEditorState on Meta and Ctrl click
   */
  React.useEffect(() => {
    EditorInstance.current?.events?.on?.("keydown", (e) => {
      if ((e.key === constants.LIST_OS_KEYS.Meta || e.key === constants.LIST_OS_KEYS.Ctrl) && editor?.current?.value) {
        props.setEditorState(editor?.current?.value);
      }
    });
  }, [EditorInstance.current]);

  const {
    templateList,
    templateTabList,
    isCountRequiredWithTabs,
    languageDropdownList,
    isTemplateListViewRequired,
    isTemplatePreviewRequired,
    hasTemplateApiFailedOrNoDataFound,
    isTemplateSearchRequired,
    isTemplateTabRequired,
    isTempListCardLangDrpdwnRequired,
    isTempPreviewLangDrpdwnRequired,
    onChangeTemplateTabs,
    onChangeTemplateLanguage,
    onApplySelectedTemplate,
    isTemplateFlowRequired,
  } = props.templateWorkflowData;
  const {
    isEmailTemplatePopoverOpen,
    onClickEmailTemplateIcon,
    onCloseEmailTemplatePopover,
    onScrollTemplateListContainer,
    onScrollTemplatePreviewBodyContainer,
    onClickTemplateListItem,
    onClickLanguageDropdownItem,
    iconAnchorEl,
    onChangeTabs,
    selectedTabId,
    searchedText,
    onChangeInSearchInput,
    showTemplateListHeaderShadow,
    showTemplatePreviewHeaderShadow,
    showTemplatePreviewFooterShadow,
    selectedTemplateId,
    selectedLanguageIndex,
    handleKeyDownEvent,
    selectedTemplateIndex,
    templateListContainerRef,
    templateListItemRef,
    templatePreviewBodyContentRef,
    searchedTemplateResultList,
    onClickUseTemplateButton,
  } = useEmailTemplatePopover(templateList);

  const handleChangeInTemplateTab = (_event: React.ChangeEvent<object>, newValue: string) => {
    onChangeTemplateTabs(parseInt(newValue, 10));
    onChangeTabs(_event, newValue);
  };

  const handleChangeInTemplateLanguage = (languageCode: string | number, index: number) => {
    onChangeTemplateLanguage(languageCode as string);
    onClickLanguageDropdownItem(languageCode, index);
  };

  const handleApplySelectedTemplate = (templateId: string | number) => {
    onApplySelectedTemplate(templateId as string);
    onClickUseTemplateButton(templateId);
  };

  //Handle when user search for particular template then searchTemplate should displayed and selected tab with new result count
  const isSearchValid = searchedText.length >= NUMERIC_VALUES.CONSTANT_THREE;
  const allTemplateList = isSearchValid ? searchedTemplateResultList : templateList;
  const allTabList = isSearchValid
    ? templateTabList.map((item, idx) => (idx === selectedTabId ? { ...item, count: searchedTemplateResultList?.length } : item))
    : templateTabList;
  //Handle TemplatePreview Card: initially when no id selected default selected id should be the first one
  const currentSelectedTemplateId = selectedTemplateId || allTemplateList?.[0]?.templateId;
  const selectedTempSubjectContent = allTemplateList?.[selectedTemplateIndex]?.templateContent?.subject;
  const selectedTempBodyContent = allTemplateList?.[selectedTemplateIndex]?.templateContent?.body;
  const currentSelectedTabId = allTabList?.length === NUMERIC_VALUES.CONSTANT_ONE ? NUMERIC_VALUES.CONSTANT_ZERO : selectedTabId;

  return (
    <div className={`na-body-wrapper`}>
      <Attachment
        renderKey={0}
        handleFileSelect={props.handleFileSelect}
        fileSelected={props.attachments.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO}
        customerId={props.to?.[0]?.connectionId ?? ""}
        onClickAddAttachments={props.onClickAddAttachments}
      />
      {isTemplateFlowRequired && (
        <EmailTemplateComponent
          onClickEmailTemplateIcon={onClickEmailTemplateIcon}
          popoverRootProps={{
            isModalOpen: isEmailTemplatePopoverOpen,
            isListViewCardRequired: isTemplateListViewRequired,
            isPreviewCardRequired: isTemplatePreviewRequired,
            isApiFailureOrNoDataFound: hasTemplateApiFailedOrNoDataFound,
            iconAnchorEl,
            handleKeyDownEvent,
            onCloseEmailTemplatePopover,
          }}
          templateListCardProps={{
            listComponentProps: {
              templateList: allTemplateList,
              selectedTemplateIndex,
              templateListContainerRef,
              templateListItemRef,
              onScrollTemplateListContainer,
              onClickTemplateListItem,
            },
            header: {
              isSearchRequired: isTemplateSearchRequired,
              isTabRequired: isTemplateTabRequired,
              isTempListCardLangDrpdwnRequired,
              showTemplateListHeaderShadow,
              searchComponentProps: {
                searchedText,
                onChangeInSearchInput,
              },
              tabComponentProps: {
                tabList: allTabList,
                isCountRequired: isCountRequiredWithTabs,
                selectedTabId: currentSelectedTabId,
                onChangeTabs: handleChangeInTemplateTab,
              },
              dropdownComponentProps: {
                dropDownList: languageDropdownList,
                selectedDropdownItemIndex: selectedLanguageIndex,
                onClickDropdownItem: handleChangeInTemplateLanguage,
              },
            },
          }}
          templatePreviewCardProps={{
            headerComponentProps: {
              subjectContent: selectedTempSubjectContent,
              titleText: "Subject:",
              showTemplatePreviewHeaderShadow,
              isTempPreviewLangDrpdwnRequired,
              dropdownComponentProps: {
                dropDownList: languageDropdownList,
                selectedDropdownItemIndex: selectedLanguageIndex,
                onClickDropdownItem: handleChangeInTemplateLanguage,
              },
            },
            bodyComponentProps: {
              bodyContent: selectedTempBodyContent,
              onScrollTemplatePreviewBodyContainer,
              templatePreviewBodyContentRef,
            },
            footerComponentProps: {
              onClickUseTemplateButton: handleApplySelectedTemplate,
              showTemplatePreviewFooterShadow,
            },
            selectedTemplateId: currentSelectedTemplateId,
          }}
        />
      )}
      <Textbox
        open={props.didRemoveUnsupported}
        variant={textboxMessageConfig?.variant}
        message={{ ele: <TextboxMsgs variant={textboxMessageConfig?.messageType} /> }}
      />
      <JoditEditor
        ref={editor as React.Ref<Jodit>}
        value={props.editorState?.length ? DOMPurify.sanitize(props.editorState, { USE_PROFILES: { html: true } }) : ""}
        config={config as any}
        /**
         * preferred to use only this option to update the content for performance reasons
         */
        onBlur={(newContent: string) => {
          props.setEditorState(newContent);
        }}
        onChange={props?.onChange}
      />

      {props.attachments.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && (
        <div className={`na-body-attachments`}>
          {props.attachments.map((value, index) => (
            <FileAttachment
              key={index}
              label={value.name ?? value}
              size={Utils.calculateFileSize((value.data as File)?.size) ?? ""}
              showClose={props?.forwarding !== null && !props.forwarding}
              handleClick={() => props.handleFileRemove(index)}
            />
          ))}
        </div>
      )}
    </div>
  );
}

import React from "react";
import { File } from "../../../../library/Icons/Icons";
import { EINVOICE_ATTACHMENT_TYPES } from "../../../../../types/enums";
import CustomTooltip from "../../CustomTooltip/CustomTooltip";
import { TooltipTypes } from "../../constants/tooltip.constants";

type EInvoiceAttachemntsProps = {
  attachments: (AttachmentItem & EInvoiceAttachmentItem)[];
  secondaryValue: string;
  callback: (attachment: (AttachmentItem & EInvoiceAttachmentItem)[]) => void;
};

const EInvoiceAttachemnts = ({ attachments, secondaryValue, callback }: EInvoiceAttachemntsProps) => {
  const objectPDF = attachments?.filter((item) => !item?.is_archived && item?.attachment_type !== EINVOICE_ATTACHMENT_TYPES.DEMATERIALISED);
  const tooltipTitle = objectPDF?.length ? objectPDF?.[0]?.file_name + objectPDF?.[0]?.file_ext : <></>;
  if (!objectPDF?.length) {
    return <>-</>;
  }
  return (
    <CustomTooltip type={TooltipTypes.PLAIN} title={tooltipTitle}>
      <div
        className="e-invoice-attachemnt-cell-item"
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          callback(
            objectPDF?.map((object: AttachmentItem & EInvoiceAttachmentItem) => {
              return { ...object, invoice_id: secondaryValue };
            })
          );
        }}
      >
        <File />
      </div>
    </CustomTooltip>
  );
};

export default EInvoiceAttachemnts;

import { makeStyles } from "@mui/styles";
import styles from "../../../css/_export.module.scss";
import Utils from "../../../utils/utils";
import { SKELTON_NUMERIC_CONSTANT, STYLE_CONSTANT } from "./constant";

const useGlobalSearchStyle = ({ isEInvoice = false }) => {
  const useStyles = makeStyles({
    searchDialogContainer: {
      width: "100%",
      height: "100%",

      "& .MuiDialog-paper": {
        width: `${Utils.getConvertedSizeInRem(STYLE_CONSTANT.SEARCH_DIALOG_HEIGHT)}`,
        height: `${Utils.getConvertedSizeInRem(STYLE_CONSTANT.SEARCH_DIALOG_WIDTH)}`,
        maxWidth: "none",
        maxHeight: "none",
      },
      "& .MuiPaper-root": {
        overflow: "hidden",
        padding: "0 !important",
        maxWidth: "100% !important",
      },
    },
    searchHeadContainer: {
      display: "flex",
      flexDirection: "row",
      height: `${Utils.getConvertedSizeInRem(STYLE_CONSTANT.SEARCH_HEADER_CONTAINER_WIDTH)}`,
      alignItems: "center",
      padding: "1.125rem",
    },
    searchInput: {
      width: "100%",
      marginLeft: styles.small,
      "&::placeholder": {
        fontFamily: isEInvoice ? styles.sageFontFamily : styles.primaryFontFamily,
        fontWeight: "400",
        fontSize: styles.fontSizeSM,
        lineHeight: styles.lineHeightMD,
        color: styles.grey70,
      },
    },
    tabContainer: {
      height: "3rem",
      padding: `${styles.small} ${styles.base}`,
      "& .MuiTabs-root": {
        height: "2rem",
        minHeight: "2rem",
      },
    },
    tabs: {
      "& .MuiTabs-flexContainer": {
        height: "2rem",
        minHeight: "2rem",
        gap: styles.small,
        "& .MuiTab-textColorPrimary": {
          color: "#4D4D4D",
        },
        "& .MuiButtonBase-root.MuiTab-root": {
          minWidth: "2rem",
          minHeight: "2rem",
          fontFamily: isEInvoice ? styles.sageFontFamily : styles.primaryFontFamily,
          fontWeight: "500",
          fontSize: styles.fontSizeXS,
          textTransform: "capitalize",
          lineHeight: styles.lineHeightSM,
          padding: styles.small,
        },
        "& .Mui-selected": {
          background: "#4D4D4D",
          borderRadius: styles.small,
          color: `${styles.white100}`,
          padding: styles.small,
          "&:hover": {
            background: "#4D4D4D",
            borderRadius: styles.small,
            color: `${styles.white100}`,
          },
        },
        "& .MuiTab-root": {
          "&:hover": {
            background: styles.grey94,
            borderRadius: styles.small,
            color: styles.primary,
          },
        },
      },
      "& .MuiTabs-indicator": {
        display: "none",
      },
    },
    rootDialogContent: {
      height: `${Utils.getConvertedSizeInRem(STYLE_CONSTANT.ROOT_DIALOG_CONTEN_HEIGHT)}`,
    },
    resultHeaderContainer: {
      display: "flex",
      justifyContent: "space-between",
      height: `${Utils.getConvertedSizeInRem(STYLE_CONSTANT.RESULT_HEADER_HEIGHT)}`,
      alignItems: "center",
      padding: `0 ${styles.base}`,
      color: styles.black,
      fontFamily: isEInvoice ? styles.sageFontFamily : styles.primaryFontFamily,
      fontWeight: "500",
      fontSize: styles.fontSizeSM,
      lineHeight: styles.lineHeightMD,
    },
    footerContainer: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      height: `${Utils.getConvertedSizeInRem(STYLE_CONSTANT.FOOTER_HEIGHT)}`,
      background: styles.grey98,
      alignItems: "center",
      paddingLeft: `${styles.base}`,
      gap: styles.large,
      whiteSpace: "nowrap",
    },
    spanHelperActionText: {
      fontFamily: isEInvoice ? styles.sageFontFamily : styles.primaryFontFamily,
      fontWeight: "600",
      fontSize: styles.fontSizeXS,
      lineHeight: styles.lineHeightSM,
    },
    spanHelperText: {
      fontFamily: isEInvoice ? styles.sageFontFamily : styles.primaryFontFamily,
      fontWeight: "400",
      fontSize: styles.fontSizeXS,
      lineHeight: styles.lineHeightSM,
      marginLeft: styles.tiny,
    },
    searchListContainer: {
      padding: "0",
    },
    listItem: {
      paddingTop: 0,
      paddingBottom: 0,
      minHeight: `${Utils.getConvertedSizeInRem(STYLE_CONSTANT.LIST_ITEM_MIN_HEIGHT)}`,
      maxHeight: `${Utils.getConvertedSizeInRem(STYLE_CONSTANT.LIST_ITEM_MAX_WIDTH)}`,
    },
    listItemAvatar: {
      background: "none",
    },
    smart: {
      width: "80%",
    },
    recentVieweditem: {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: styles.grey98,
      },
    },
    recentViewedItemSelected: {
      backgroundColor: styles.grey95,
      "&:hover": {
        backgroundColor: styles.grey95,
      },
    },
    recentViewedPrimaryHeader: {
      fontFamily: isEInvoice ? styles.sageFontFamily : styles.primaryFontFamily,
      fontWeight: "500",
      fontSize: styles.fontSizeSM,
      lineHeight: styles.lineHeightMD,
      marginLeft: styles.tiny,
      color: styles.black,
    },
    recentViewedSecondaryHeader: {
      fontFamily: isEInvoice ? styles.sageFontFamily : styles.primaryFontFamily,
      fontWeight: "500",
      fontSize: styles.fontSizeXS,
      lineHeight: styles.lineHeightSM,
      marginLeft: styles.tiny,
      color: styles.grey50,
    },
    contentHeadingContainer: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      height: `${Utils.getConvertedSizeInRem(STYLE_CONSTANT.CONTENT_HEADING_HEIGHT)}`,
      background: styles.grey98,
      alignItems: "center",
      justifyContent: "space-between",
    },
    contenHeadingLeftSpan: {
      paddingLeft: styles.base,
      color: styles.black,
      fontFamily: isEInvoice ? styles.sageFontFamily : styles.primaryFontFamily,
      fontWeight: "600",
      fontSize: styles.fontSizeSM,
      lineHeight: styles.lineHeightMD,
      whiteSpace: "nowrap",
    },
    contenHeadingRightSpan: {
      fontFamily: isEInvoice ? styles.sageFontFamily : styles.primaryFontFamily,
      fontWeight: "400",
      fontSize: styles.fontSizeSM,
      lineHeight: styles.lineHeightMD,
      textDecorationLine: "underline",
      color: styles.link,
      paddingRight: styles.base,
      cursor: "pointer",
    },
    resultItemPrimaryContainer: {
      fontFamily: isEInvoice ? styles.sageFontFamily : styles.primaryFontFamily,
      fontWeight: "500",
      fontSize: styles.fontSizeSM,
      lineHeight: styles.lineHeightMD,
      marginLeft: styles.tiny,
      color: styles.black,
      display: "flex",
      justifyContent: "space-between",

    },
    resultItemPrimaryRightTitle: {
      // width: "50%",
      textAlign: "end",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontFamily: isEInvoice ? styles.sageFontFamily : styles.primaryFontFamily,
      fontWeight: "500",
      fontSize: styles.fontSizeXS,
      lineHeight: styles.lineHeightSM,
      color: styles.grey50,
    },
    resultItemSecondaryContainer: {
      fontFamily: isEInvoice ? styles.sageFontFamily : styles.primaryFontFamily,
      fontWeight: "500",
      fontSize: styles.fontSizeXS,
      lineHeight: styles.lineHeightSM,
      marginLeft: styles.tiny,
      color: styles.grey50,
    },
    resultItemSecondaryHeaderContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    resultItemSecondaryHeader: {
      fontFamily: isEInvoice ? styles.sageFontFamily : styles.primaryFontFamily,
      fontWeight: "500",
      fontSize: `${styles.fontSizeXS} !important`,
      lineHeight: `${styles.lineHeightSM} !important`,
      color: styles.grey50,
      padding: `${styles.tiny} 0`,
    },
    resultItemSecondaryDetails: {
      width: "50%",
      textAlign: "end",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    resultItemContentPreview: {
      fontFamily: isEInvoice ? styles.sageFontFamily : styles.primaryFontFamily,
      fontWeight: "400 !important",
      fontSize: `${styles.fontSizeXS} !important`,
      lineHeight: `${styles.lineHeightSM} !important`,
      color: styles.grey50,
    },
    suggestionPrimaryConatinar: {
      fontFamily: isEInvoice ? styles.sageFontFamily : styles.primaryFontFamily,
      fontWeight: "400",
      fontSize: styles.fontSizeSM,
      lineHeight: styles.lineHeightMD,
      marginLeft: styles.tiny,
      color: styles.black,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    suggestionQueryFieldText: {
      fontFamily: isEInvoice ? styles.sageFontFamily : styles.primaryFontFamily,
      fontWeight: "600",
      fontSize: styles.fontSizeSM,
      lineHeight: styles.lineHeightMD,
      margin: `0 ${styles.tiny}`,
      color: styles.black,
    },
    // class to align the InvoiceID with Status
    headerAdjust: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",

      "& div[variant]": {
        margin: "0 10px",
        padding: "0 6px"
      }
    },

    menuRoot: {
      "& .MuiPaper-root": {
        width: `${Utils.getConvertedSizeInRem(STYLE_CONSTANT.SORT_MENU_WIDTH)}`,
        height: `${Utils.getConvertedSizeInRem(STYLE_CONSTANT.SORT_MENU_HEIGHT)}`,
        background: styles.white100,
        marginLeft: `-${styles.medium}`,
        marginTop: "-0.4375rem",
        borderRadius: styles.atom,
        boxShadow: `0 ${styles.small} ${styles.medium} rgba(0, 0, 0, 0.1), 0 ${styles.tiny} ${styles.semi} rgba(0, 0, 0, 0.1)`,
      },
      "& .MuiList-root": {
        height: "100%",
        "& .MuiMenuItem-root": {
          height: "50%",
          fontFamily: isEInvoice ? styles.sageFontFamily : styles.primaryFontFamily,
          fontWeight: "400",
          fontSize: styles.fontSizeSM,
          lineHeight: styles.lineHeightMD,
          color: styles.primary,
          "&:hover": {
            background: styles.grey98,
          },
        },
        "& .Mui-selected": {
          background: styles.grey98,
        },
      },
    },
    select: {
      "& .MuiOutlinedInput-notchedOutline": {
        border: 0,
        boxShadow: "none",
      },
      "& .MuiOutlinedInput-input": {
        color: "#4D4D4D",
        fontFamily: isEInvoice ? styles.sageFontFamily : styles.primaryFontFamily,
        fontWeight: "500",
        fontSize: styles.fontSizeXS,
        lineHeight: styles.lineHeightSM,
      },
      "& .MuiSelect-icon": {
        color: styles.grey700,
      },
    },
    headerRootSkelton: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      height: `${Utils.getConvertedSizeInRem(SKELTON_NUMERIC_CONSTANT.HEADER_ROOT_HEIGHT)}`,
      background: styles.grey98,
      padding: `0 ${styles.base}`,
      justifyContent: "space-between",
      alignItems: "center",
    },
    listRootSkelton: {
      padding: `0.5625rem 0`,
    },
    listItemRootSkelton: {
      display: "flex",
      alignItems: "center",
      margin: `0 ${styles.base} 0 ${styles.semi}`,
    },
    avatarSkeltonDiv: {
      marginRight: styles.medium,
    },
    itemsSkeltonContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    primaryTextSkelton: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    secondaryTextSkelton: {
      margin: `0.3125rem 0`,
    },
    scrollHeaderStatic: {
      position: "relative",
      zIndex: 1,
      boxShadow: "0 0.0625rem 0.1875rem rgb(0 0 0 / 30%)",
    },
    addConnetionContainer: {
      display: "flex",
      background: "#FFEFDE",
      padding: "0.625rem",
      gap: "3.6875rem",
      whiteSpace: "nowrap",
    },
    addConnectionHeaderText: {
      display: "flex",
      alignItems: "center",
      fontFamily: isEInvoice ? styles.sageFontFamily : styles.primaryFontFamily,
      fontWeight: 500,
      fontSize: "0.6875rem",
      lineHeight: styles.fontSizeSM,
      color: styles.grey10,
    },
    addConnectionButton: {
      fontFamily: isEInvoice ? styles.sageFontFamily : styles.primaryFontFamily,
      fontWeight: 600,
      fontSize: styles.fontSizeSM,
      lineHeight: styles.lineHeightMD,
      color: styles.babyBlue,
    },
  });
  return useStyles();
};

export default useGlobalSearchStyle;

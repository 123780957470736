import React from "react";
import { useHistory } from "react-router";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../constants/NumericConstants";

export const ApplicationRouteContext = React.createContext<ApplicationRouteType | null>(null);

interface ApplicationRouterPropsInterface {
  children:
    | (React.ReactNode & {
        type: string;
      })[]
    | (React.ReactNode & {
        type: string;
      });
}

const ApplicationRouteProvider = (props: ApplicationRouterPropsInterface) => {
  const [baseRoute, setBaseRoute] = React.useState<string>("");
  const history = useHistory();

  /**
   * This function sets the state and also sets the incoming
   * value to session storage
   *
   * @param route new base route
   */
  const updateBaseRoute = (route: string) => {
    // set state
    setBaseRoute(route);
    return route;
  };
  /**
   * This function breaks down the user's current location
   * path to generate the route.
   *
   * @param status list of entity specific statuses
   * @param userView (OPTIONAL) list of table specific views
   * @returns generated route
   */
  const evaluatePath = (entity: string) => {
    let baseURL: string[] = [];

    const url = history.location.pathname.split("/");
    baseURL = [...url.slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ONE, NUMERIC_VALUES.CONSTANT_FOUR)];

    switch (entity) {
      case "invoices":
        baseURL.push(url[4]);
        break;
      case "bills":
        baseURL.push(url[4]);
        break;
      case "payments":
      case "customer-payments":
      case "vendor-payments":
        baseURL.push(url[4]);
        break;
      case "activities":
      case "customers":
      case "vendors":
      case "connections":
      case "internal":
        baseURL.push(url[4]);
        baseURL.push(url[5]);
        break;
      default:
        break;
    }
    return "/" + baseURL.join("/");
  };

  /**
   * This function evaluates the application base route using
   * user's current location path.
   * It takes help of the routing convention being used in
   * specific application entities e.g. customers, invoices,
   * activities etc and generates the base route.
   *
   * @returns current base route
   */
  const getBaseFromPath = () => {
    const entity = history.location.pathname.split("/")[3];
    // customers/view/:id OR vendors/view/:id OR connections/view/:id
    return evaluatePath(entity);
  };

  /**
   * Two levels to evaluate the base route:
   *  I. first check if app has baseRoute in context state
   * if does not have it in context or current entity does not
   * match with the stored state,
   *  II. evaluates the base route from user's current location
   *
   * @returns current base route of the application
   */
  const getBaseRoute = () => {
    let route = "";
    if (baseRoute && baseRoute !== "") {
      route = baseRoute;
    } else {
      const route = getBaseFromPath();
      updateBaseRoute(route);
    }
    return route;
  };

  return (
    <ApplicationRouteContext.Provider
      value={{
        getBaseRoute,
        updateBaseRoute,
        getBaseFromPath,
      }}
    >
      {props.children}
    </ApplicationRouteContext.Provider>
  );
};

export default ApplicationRouteProvider;

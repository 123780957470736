import React, { useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import { default as View } from "./ViewMyProfile";
import { default as Edit } from "./EditMyProfile";
import { DataMode } from "../../../../types/enums";
import { Grid } from "@mui/material";
import { AppContext } from "../../../../contexts/AppContext";
import { UserActions } from "../../../../types/enums";
import { codeDefinitionsClient, userAccountsClient } from "../../../../db/accessor";
import Navbar from "../../../library/Navbar/Navbar";
import Button from "../../../library/Button/Button";
import Flyout from "../../../Settings/Flyout/Flyout";
import { msalInstance } from "../../../..";
import { AUTHORIZE_URI, REDIRECT_URI, CLIENT_ID, NONCE, SCOPE, RESPONSE_TYPE, PROMPT, RESPONSE_MODE } from "../../../../auth/authConfig";
import "../Profiles.scss";
import Header from "./Header";
import moment from "moment-timezone";
import { ProfileContext } from "../../../../contexts/ProfileContext";
import { AlertContext } from "../../../../contexts/AlertContext";
import useWorkspaceConfigurations from "../../../../hooks/useWorkspaceConfigurations";

export default function MyProfile(): React.ReactElement {
  const { userStatus, hasPermission } = React.useContext(AppContext) as AppType;
  const [dataMode, setDataMode] = React.useState<DataMode>(DataMode.View);
  const { customerId } = useParams<{ customerId: string }>();
  const [profileData, setProfileData] = useState<UserAccountModel>({} as UserAccountModel);
  const [codeDefinitions, setCodeDefinitions] = useState<CodeDefinitionModel[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [viewPasswordFlyout, setPasswordFlyout] = useState<boolean>(false);
  // edit profile states
  const [showCancel, setShowCancel] = useState<boolean>(false);
  const [saveLoad, setSaveLoad] = useState<boolean>(false);
  const submitBtnRef = React.useRef<HTMLButtonElement>(null);
  const [showUploadModal, setShowUploadModal] = React.useState(false);
  const { imageUrl, setImageUrl } = React.useContext(ProfileContext) as ProfileType;
  const { path } = useRouteMatch();
  const timezonesOptions = moment.tz.names().map((timezone) => ({
    id: timezone,
    label: timezone,
  }));
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const { selectedWorkspace } = useWorkspaceConfigurations();

  useEffect(() => {
    (async function fetchData() {
      setLoading(true);
      await userAccountsClient.getUserAccount(customerId ?? userStatus.user_id, "AccountingRole").then((data: UserAccountModel) => {
        setProfileData(data);
        setImageUrl(data?.imageURL ?? imageUrl);
      });
      await codeDefinitionsClient
        .queryCodeDefinitions("CODETYPE EQ 'AccountingRole'", undefined, "codeDefinitionId")
        .then((data: CodeDefinitionModelFetchResult) => {
          setCodeDefinitions(data?.records ?? []);
        });
      setLoading(false);
    })();
  }, []);

  // handle msal response
  window.onmessage = function (event) {
    if (event?.data?.type === "cancel_password_reset") {
      setPasswordFlyout(false);
    } else if (event?.data?.type === "forgot_success") {
      setPasswordFlyout(false);
      setToastOptions({
        open: true,
        severity: "success",
        message: "Password Changed",
      });
    }
  };

  const username = msalInstance.getActiveAccount()?.username;

  const flyoutChangePasswordInstructions = `A verification code has been sent to ${username}.`;
  const sidebarChangePasswordInstructions = `The email address registered with your account is ${username}. Click the button below to begin the password reset process.`;

  const passwordResetURI = `${AUTHORIZE_URI}?p=B2C_1A_PLATFORMEMBEDDEDPASSWORDRESET&client_id=${CLIENT_ID}&nonce=${NONCE}&redirect_uri=${REDIRECT_URI}&scope=${SCOPE}&response_type=${RESPONSE_TYPE}&prompt=${PROMPT}&response_mode=${RESPONSE_MODE}&login_hint=${username}`;

  const handleImageUpload = (imageURL: string) => {
    return userAccountsClient.patchUserAccount(userStatus?.user_id ?? "", { imageURL });
  };

  return (
    <div className="profile-detail-container">
      <Header
        dataMode={dataMode}
        setDataMode={setDataMode}
        setShowCancel={setShowCancel}
        setSaveLoad={setSaveLoad}
        saveLoad={saveLoad}
        submitBtnRef={submitBtnRef}
        userDetails={{
          userName: profileData.userName,
          userRole: profileData.accountingRoleCodeDefinition?.codeDescription ?? "",
          imageURL: imageUrl,
        }}
        isLoading={isLoading}
      />
      <Grid container className="my-profile-detail">
        <Grid item className="profile-detail-container">
          {dataMode === DataMode.View && (
            <View
              setDataMode={setDataMode}
              data={profileData}
              hasEditPermission={hasPermission(UserActions.EditMyProfile)}
              isSelf={!customerId || userStatus.user_id === customerId}
              isLoading={isLoading}
              handleImageUpload={handleImageUpload}
              showUploadModal={showUploadModal}
              setShowUploadModal={setShowUploadModal}
              imageUrl={imageUrl}
              setImageUrl={setImageUrl}
              selectedWorkspace={selectedWorkspace}
            />
          )}
          {dataMode === DataMode.Edit && (
            <Edit
              setDataMode={setDataMode}
              defaultData={profileData}
              setDefaultData={setProfileData}
              codeDefinitions={codeDefinitions}
              showCancel={showCancel}
              setShowCancel={setShowCancel}
              saveLoad={saveLoad}
              setSaveLoad={setSaveLoad}
              submitBtnRef={submitBtnRef}
              isLoading={isLoading}
              dataMode={dataMode}
              handleImageUpload={handleImageUpload}
              showUploadModal={showUploadModal}
              setShowUploadModal={setShowUploadModal}
              imageUrl={imageUrl}
              setImageUrl={setImageUrl}
              timezones={timezonesOptions}
              selectedWorkspace={selectedWorkspace}
            />
          )}
        </Grid>

        <Grid item>
          <div className="side-section-head">
            <Navbar tabs={[{ displayName: "Password Settings", routeName: "" }]} rootPath={path} />
          </div>
          <div className="side-content-wrapper">
            <h4 className="content-title">Change Your Password</h4>
            <p className="content-text">{sidebarChangePasswordInstructions}</p>
            <Button variant="primary" size="lg" onClick={() => setPasswordFlyout((prev: boolean) => !prev)}>
              Send Password Reset Instructions
            </Button>
          </div>
        </Grid>
      </Grid>
      <Flyout
        isOpen={viewPasswordFlyout}
        onClose={() => {
          setPasswordFlyout(false);
        }}
      >
        <div className="password-reset-flyout-wrapper">
          <h3 className="header">Change Your Password</h3>

          <p className="description">{flyoutChangePasswordInstructions}</p>

          <iframe frameBorder="0" width="100%" height="400px" src={viewPasswordFlyout ? passwordResetURI : undefined} />
        </div>
      </Flyout>
    </div>
  );
}

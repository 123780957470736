import { Modal } from "@mui/material";
import React from "react";
import Button from "../Button/Button";
import { UploadComponentInterface } from "./UploaderInterfaces/UploaderInterfaces";
import { CircularArrowLoader, Close, Upload } from "../Icons/Icons";
import "./UploaderStyles/UploaderStyles.scss";
import Utils from "../../../utils/utils";
import { DEFAULT_NUMERIC_VALUES } from "../../../constants/NumericConstants";

const UploaderComponent: React.FC<UploadComponentInterface> = ({
  children,
  file,
  getUploadedFile,
  labelProps,
  loading,
  open,
  toggleFunction,
  uploadFileToPlatformAPI,
}: UploadComponentInterface) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const clickInputButton = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const getFileUploadSection = () => {
    return (
      <div className="file-content">
        <div className="file-children">{children}</div>
        <div className="file-btn-grp">
          <Button icon={<Upload />} variant="secondary" alignIcon={"right"} className="file-btn" type="button" onClick={clickInputButton}>
            {labelProps?.buttonProps?.fileLabel || "Find Your File"}
          </Button>
          <input
            type="file"
            accept=".jpg, .xls, .pdf, .doc, .docx, .xlsx, .csv, .txt"
            className="hidden-input"
            ref={inputRef}
            onChange={getUploadedFile}
          />
          {!file && <p className="caption-sm">{labelProps?.buttonProps?.cancelLabel || "(.jpg, .xls, .pdf, .doc, .docx, .xlsx, .csv, .txt)"}</p>}
          {file && (
            <p className="caption-sm">
              {file?.name || ""} ({Utils.calculateFileSize(file?.size || DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO)})
            </p>
          )}
        </div>
      </div>
    );
  };

  const getTitles = () => {
    return (
      <>
        <span className="modal-close-icon" onClick={toggleFunction}>
          <Close />
        </span>
        <h2>{labelProps?.modalProps?.header || "Add A File"}</h2>
      </>
    );
  };

  const getUploadButtonGroup = () => {
    return (
      <div className="btn-grp">
        <Button
          disabled={!file || loading}
          icon={
            loading ? (
              <span className="rotate_anim_loader">
                <CircularArrowLoader />
              </span>
            ) : (
              <Upload />
            )
          }
          variant="primary"
          alignIcon={loading ? "right" : "left"}
          className="upload-btn"
          type="button"
          onClick={uploadFileToPlatformAPI}
        >
          {!loading ? labelProps?.buttonProps?.actionLabel || "Upload Your File" : "Uploading..."}
        </Button>
        <Button variant="secondary" onClick={toggleFunction}>
          {labelProps?.buttonProps?.cancelLabel || "Cancel"}
        </Button>
      </div>
    );
  };

  return (
    <Modal open={open} onClose={toggleFunction}>
      <div className="upload-modal-file">
        {getTitles()}
        {Utils.isNonEmptyString(labelProps?.modalProps?.subHeader) && <p className="subheader">{labelProps?.modalProps?.subHeader}</p>}
        {getFileUploadSection()}
        {getUploadButtonGroup()}
      </div>
    </Modal>
  );
};

export default UploaderComponent;

import React from "react";
import Button from "@mui/material/Button";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import { styled } from "@mui/system";

export const FilterIconContainer = styled("div", {
  name: "filter-icon",
  slot: "container",
})({
  padding: "0.3125rem 0.5rem",
  background: "#F7F7FB",
  cursor: "pointer",
  display: "flex",
  borderRadius: "0.25rem",
  "& svg": {
    height: "0.6875rem",
    width: "0.5rem",
  },
});

export const FilterIconContainerActive = styled(FilterIconContainer, {
  name: "filter-icon-active",
  slot: "container",
})({
  background: "#4D4D4D",
  "& svg": {
    "> path": {
      fill: "#FFFFFF",
    },
  },
});

const ToolTipContainer: React.FC<TooltipProps> = ({ className, ...props }) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
);

export const ToolTipContainerForFilters = styled(ToolTipContainer, { name: "ToolTipContainerForFilters" })({
  boxShadow: "0 -0.125rem 0.25rem rgba(0, 0, 0, 0.08), 0 0.25rem 0.25rem rgba(0, 0, 0, 0.08)",
  backgroundColor: "#FFFFFF",
  padding: "1rem",
  color: "#4D4D4D",
  minWidth: "13.1875rem",
});

export const ClearButton = styled("div", {
  name: "clear-button",
  slot: "container",
})({
  padding: "0.375rem 0",
  cursor: "pointer",
});

export const ActionFooterContainer = styled("div")({
  display: "flex",
  gap: "0.75rem",
  justifyContent: "flex-end",
});

export const CancelButton = styled(Button, { name: "cancel-button", slot: "root" })({
  height: "2.5rem",
  background: "#EBF1FA",
  color: "#1a73e8",
  textTransform: "capitalize",
  fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  "Helvetica Neue", sans-serif`,
  fontWeight: "600",
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
  "&:hover": {
    background: "#EBF1FA",
  },
});
export const ApplyButton = styled(Button, { name: "apply-button", slot: "root" })({
  height: "2.5rem",
  background: "#1a73e8",
  color: "#FFFFFF",
  textTransform: "capitalize",
  fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif`,
  fontWeight: "600",
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
  "&.Mui-disabled": {
    color: "rgba(255,255,255,0.8)",
    background: "rgba(26, 115, 232, 0.8)",
  },
  "&:hover": {
    background: "#1a73e8",
  },
  "&:not(:first-of-type)": {
    marginLeft: "0",
  },
});

import { makeStyles } from "@mui/styles";
import styles from "../../../css/_export.module.scss";

/**
 * custom hook to provide classes which overrides the
 * default styles for MUI Dialog, DialogTitle, DialogContentText
 * and DialogActions components
 *
 * @returns styles to override dialog mui styles
 */
const useDialogClasses = () => {
  /* typography: body1 */
  const body = {
    fontFamily: styles.primaryFontFamily,
    fontWeight: 400,
    fontSize: styles.fontSizeL,
    lineHeight: styles.lineHeightL,
  };
  /* typography: h2 */
  const title = {
    fontFamily: styles.primaryFontFamily,
    fontWeight: 600,
    fontSize: styles.fontSizeXL,
    lineHeight: styles.lineHeightXL,
    margin: 0,
  };

  const useStyles = makeStyles({
    dialog: {
      "& .MuiPaper-root": {
        boxShadow: "none",
        padding: styles.xlarge,
        margin: 0,
        maxWidth: "30.375rem",
      },
    },
    title: {
      ...title,
      color: styles.primary,
      padding: 0,
    },
    actions: {
      padding: 0,
      display: "flex",
      justifyContent: "flex-start",
    },
    content: {
      padding: 0,
    },
    contentText: {
      ...body,
      color: styles.black,
    },
  });

  return useStyles();
};

export default useDialogClasses;

export const GLOBAL_SEARCH_CONSTANTS = Object.freeze({
  ALL_FILTER_MAX_SHOW_RESULTS: 3,
  MIN_SEARCH_CHARACTERS: 3,
  recentSearch: "recent_search",
  searchHistory: "search_history",
  ESCAPE_KEY_CODE: 27,
});

export const GLOBAL_SEARCH_STATIC_TEXTS = Object.freeze({
  RESULTS: "Results",
  RELEVANT: "Relevant",
  RECENT: "Recent",
  GLOBAL_SEARCH_PLACEHOLDER: "Try searching in Activities, Connections, Contacts, Documents, Invoices, or Payments",
  VIEW_ALL: "View All",
  NO_RESULT_FOUND: "No Results Found",
  TRY_DIFFERENT_KEYWORD: "Try different keywords or remove search filters",
  FETCHING_RESULT_HEADER: "We are fetching the search results in a bit",
  FETCHING_RESULT_SUBHEADER: "Hang tight, this won’t take more than a few seconds",
  RECENTLY_VIEW_RESULTS: "Recently Viewed Results",
  SEARCH_SUGGESTIONS: "Search Suggestions",
  ALL_CAUGHT_UP: "You are all caught up!",
  ENTER_KEYWORDS: "Please enter keywords and add filters",
  CONNECTION_NOT_ADDED: "Looks-like this connection is not added in your Accounting software system, please add it for seamless Inbox experience.",
  ADD_CONNECTION: "Add Connection",
});

export const GLOBAL_SEARCH_FILTER_TYPES = Object.freeze({
  CHIP: "CHIP",
  DROPDOWN: "DROPDOWN",
  DROPDOWN_INTEGER: "INTEGER",
  DROPDOWN_NUMERIC: "NUMERIC",
  DROPDOWN_SEARCH: "DROPDOWN_SEARCH",
  DATE: "DATE",
});

export const GLOBAL_SEARCH_NUMERIC_FILTER_OPERATORS = Object.freeze({
  LESS_THAN: "Less Than",
  GREATER_THAN: "Greater Than",
  EQUALS_TO: "Equals To",
  IN_BETWEEN: "In-Between",
});

import React from "react";
import { GLOBAL_SEARCH_STATIC_TEXTS } from "../../../constants/GlobalSearchConstants";
import useGlobalSearchStyle from "../Styles/useGlobalSearchStyles";

const AddConnectionBanner = () => {
  const { addConnetionContainer, addConnectionHeaderText } = useGlobalSearchStyle({});

  return (
    <div className={addConnetionContainer}>
      <span className={addConnectionHeaderText}>{GLOBAL_SEARCH_STATIC_TEXTS.CONNECTION_NOT_ADDED}</span>
      {/*TODO: Needs to add later  */}
      {/* <span className={addConnectionButton}>{GLOBAL_SEARCH_STATIC_TEXTS.ADD_CONNECTION}</span> */}
    </div>
  );
};

export default AddConnectionBanner;

import React from "react";
import { NUMERIC_VALUES } from "../../../constants/NumericConstants";
import { TooltipTypes } from "../../../constants/TooltipConstants";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import { SmartTextComponentInterface } from "./SmartTextInterfaces";
import "./SmartTextStyles/index.scss";

const SmartTextComponent: React.FC<SmartTextComponentInterface> = (props: SmartTextComponentInterface) => {
  return (
    <div ref={props.divRef} className="container">
      <CustomTooltip
        type={props.tooltip?.title?.length > NUMERIC_VALUES.CONSTANT_TWENTY ? TooltipTypes.RICH : TooltipTypes.PLAIN}
        title={props.tooltip.showTooltip ? props.tooltip.title : ""}
        placement="top"
        followCursor
      >
        <p
          dangerouslySetInnerHTML={{
            __html: props.title,
          }}
          ref={props.contentRef}
          onMouseOver={props.toggleTooltip}
          onMouseOut={props.toggleTooltip}
          className={`default-styles-${props.type ?? "p"} ${props.className} default-styles-width ${props.tooltip.showTooltip && "overflow-styles"}`}
        />
      </CustomTooltip>
    </div>
  );
};

export default SmartTextComponent;

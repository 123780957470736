import React, { MouseEvent, useState, useEffect, useRef } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import TableUtils from "../../../utils/TableUtils/TableUtils";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES, DEFAULT_PAGINATION_VALUES } from "../../../constants/NumericConstants";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";
import { PaymentsClientV2, exportClient } from "../../../db/version2Accessor";
import { ApplicationRouteContext } from "../../../contexts/ApplicationRouteContext";
import { DocumentSwitchContext } from "../../../contexts/DocumentSwitchContext";
import { AppContext } from "../../../contexts/AppContext";

import { Table, useColumnFilters } from "../../library/AtomicComponents/Table/index";
import { UserAction } from "../../library/AtomicComponents/Table/types/table.types";
import usePagination from "../../library/AtomicComponents/Table/hooks/usePagination";
import useColumnSort from "../../library/AtomicComponents/Table/hooks/useColumnSort";
import useRowSelection from "../../library/AtomicComponents/Table/hooks/useRowSelection";
import APIClientUtils from "../../../utils/APIClientUtils/APIClientUtils";
import { RowDataTypes } from "../../library/AtomicComponents/constants/table.constants";
import { EMPTY_PAYMENT_CAPTION_MESSAGE, NO_DATA_MESSAGE } from "../../../constants/config";
import { ExportV2 } from "../../library/Icons/Icons";
import { CSVLink } from "react-csv";
import useLocale from "../../../hooks/useLocale";
import { FeatureFlagContext, FeatureFlagProviderType } from "../../../contexts/FeatureFlagContext";
import { EXPORT_CATEGORIES, VIEW_CATEGORIES, WorkspaceType } from "../../../types/enums";
import { ExportContext } from "../../../contexts/ExportContext";
import { ExportToastMessage } from "../../../constants/ExportConstant";
import { AlertContext } from "../../../contexts/AlertContext";
import { useQuery } from "@tanstack/react-query";
import { RefetchIntervals } from "../../../constants/CacheConfig";

type PaymentsProps = {
  configs: any;
};

export default function UnappliedPayments(props: PaymentsProps): React.ReactElement {
  const history = useHistory();
  const { path } = useRouteMatch();
  const params = new URLSearchParams(window.location.search);
  const tableConfigs = props.configs.view.unappliedPayments.table;
  const pageNoFromUrl = parseInt(params.get("page") ?? "1", NUMERIC_VALUES.CONSTANT_TEN) || NUMERIC_VALUES.CONSTANT_ONE;
  const viewConfigs = props.configs.view;
  //eslint-disable-next-line
  const [isError, setError] = useState<boolean>(false);
  //eslint-disable-next-line
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const { workspaceHomePath, selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { updateBaseRoute, getBaseRoute } = React.useContext(ApplicationRouteContext) as ApplicationRouteType;
  //eslint-disable-next-line
  const { userStatus } = React.useContext(AppContext) as AppType;
  const {
    setSelectedActivityStreamId,
    setActivityStreamIds,
    setCurrentPaginationState,
    setPlatformPageFetchParams,
    setDocumentType,
    setEnableDocumentSwitch,
    setRootSwitcherStateSnapshot,
  } = React.useContext(DocumentSwitchContext) as DocumentSwitchType;

  const { setTotalRecords, setTotalPages, updatePageNumber, ...paginationConfig } = usePagination(
    DEFAULT_PAGINATION_VALUES.PAGE_SIZE,
    pageNoFromUrl ? pageNoFromUrl - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE : DEFAULT_PAGINATION_VALUES.PAGE_COUNT
  );
  const { query: sortQuery, ...sortConfig } = useColumnSort("payment_date DESC");
  const selectionConfig = useRowSelection(paginationConfig?.page?.totalRecords);
  const { ...initialFilterConfigs } = useColumnFilters(true, [], updatePageNumber);
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [exportData, setExportData] = useState([]);
  const csvInstance = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const [columnsConfigData, setColumnsConfigData] = useState<any[]>(() => tableConfigs.columns);
  const [isColumnsConfigDataLoading, setIsColumnsConfigDataLoading] = useState<boolean>(true);
  const { calendarDateFormat } = useLocale();
  const { isEnabled } = React.useContext(FeatureFlagContext) as FeatureFlagProviderType;
  const isFiltersEnabled = isEnabled("FILTERS_ENABLE");
  const isExportV2Enabled = isEnabled("EXPORTS_V2");
  const [isNoSearchResultFound, setIsNoSearchResultFound] = useState<boolean>(false);
  const { updateExportedRecordsIds } = React.useContext(ExportContext) as ExportType;
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const filterObjFromUrl = JSON.parse(params.get("filters") ?? "{}");
  const appliedFilters = initialFilterConfigs.generateFilterQuery(Object.values(filterObjFromUrl), true);

  const fetchParser = (fetchResult: FetchResult, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.records?.map((record: PaymentSummaryModelV2) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record.payment_id,
          }),
          ...((variant === "export" || variant === "all") && {
            reference_code: record.reference_code,
            payment_company_name: record.payment_company_name,
            tender_type: record.tender_type,
            payment_date: record.payment_date,
            payment_amount: record.payment_amount,
            unapplied_amount: record?.unapplied_amount,
            base_currency_unapplied_amount: record?.base_currency_unapplied_amount,
            base_currency_payment_amount: record?.base_currency_payment_amount,
          }),
          ...(variant === "all" && {
            disableCheckbox: undefined,
            isUnread: undefined,
          }),
        };
      }) ?? []
    );
  };

  const fetchPayments = async (variant: FetchVariant, columnFilters?: string, pageNumber?: number, pageSize?: number) => {
    const filterQuery = TableUtils.getFilterFromURL(params, tableConfigs.columns);
    const sortQueryString = TableUtils.getSortQueryString(params, sortQuery);
    /**
     * Set page fetch params to ActivityStreamSwitchContext to facilitate page switching
     */
    const fetchParams = {
      pageSize: paginationConfig.page?.pageSize ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
      page: paginationConfig.page?.pageNumber ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
      order: TableUtils.sortQueryParser(sortQueryString ?? "", true),
      filter: TableUtils.columnFilterParser(filterQuery, true, tableConfigs.preDefinedFilters),
      spamFraudArchivedTab: false,
    };
    setPlatformPageFetchParams(fetchParams as PlatformPageFetchParams);
    const filters = [TableUtils.columnFilterParser(filterQuery, true, tableConfigs.preDefinedFilters), columnFilters].filter(Boolean).join("&");

    return PaymentsClientV2.querySummaries(
      filters,
      TableUtils.sortQueryParser(sortQueryString ?? "", true),
      pageSize ?? paginationConfig.page?.pageSize ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
      pageNumber ?? paginationConfig.page?.pageNumber ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
      false
    )
      .then((fetchResult: any) => {
        return { ...fetchResult, records: fetchParser(fetchResult, variant) };
      })
      .catch((error: any) => {
        setError(true);
        setErrorMessage(APIClientUtils.buildErrorMessage(error));
      });
  };

  const queryKey = `unapplied-payment-index-${selectedWorkspace?.workspace_type_route}`;

  const { isLoading, data: cachedData } = useQuery(
    [queryKey, paginationConfig.page?.pageNumber, paginationConfig.page?.pageSize, TableUtils.getSortQueryString(params, sortQuery), appliedFilters],
    async () => await fetchPayments("all", appliedFilters),
    {
      refetchOnMount: true,
      refetchInterval: RefetchIntervals.twoHoursDelay,
    }
  );
  useEffect(() => {
    /**
     * update the page number once user perform any action and because of that total records get's changed
     */
    const totalPages = Math.ceil(cachedData?.totalCount / cachedData?.pageSize) ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
    if ((paginationConfig.page?.pageNumber || NUMERIC_VALUES.CONSTANT_ONE) > totalPages) {
      updatePageNumber(totalPages || NUMERIC_VALUES.CONSTANT_ONE);
    }
    setTotalRecords(cachedData?.totalCount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    setTotalPages(totalPages);
    setTotalCount(cachedData?.totalCount);
    setTableData(cachedData?.records ?? []);
  }, [cachedData]);

  // Handles redirect to Payment Details page on row click of the Data Grid
  const handleRowClick = (
    e: MouseEvent<HTMLTableRowElement>,
    row: {
      id?: string;
    },
    currentPageRecords?: { id: string; isSelected?: boolean }[]
  ) => {
    /**
     * If a row item is already selected, then clicking anywhere on other rows should select those rows, and do not navigate.
     * if double clicked, then navigate to those row records
     */
    const clickType = e.detail;
    if (clickType === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE && selectionConfig.tableRecords?.selectedRecordsIds?.length && row.id) {
      selectionConfig?.onClickTableRow(row?.id, currentPageRecords ?? []);
      return;
    }

    /**
     * Set the following properties into DocumentSwitchContext
     * to facilitate document switching.
     * @param selectedActivityStreamId - currently selected document id
     * @param activityStreamIds - list of documents in the index
     * @param paginationState - current pagination state for reference
     */
    setEnableDocumentSwitch(true);
    setSelectedActivityStreamId({ id: row.id } as ActivityStreamId);
    setActivityStreamIds(tableData.map((item) => ({ id: item.id })));
    setCurrentPaginationState({
      page: paginationConfig.page?.pageNumber ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
      pageCount: paginationConfig.page?.totalPages ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
      pageSize: paginationConfig.page?.pageSize ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
      totalRecords: totalCount,
    });
    setDocumentType("payment");
    setRootSwitcherStateSnapshot();
    sessionStorage.setItem("lastPath", history.location.pathname);

    /** handle multiple types of payment routes */
    let pathToPush!: string;
    if (path.includes("customer-payments")) {
      pathToPush = `${workspaceHomePath}/customer-payments/${row.id ?? ""}`;
    } else if (path.includes("vendor-payments")) {
      pathToPush = `${workspaceHomePath}/vendor-payments/${row.id ?? ""}`;
    } else if (path.includes("payments")) {
      pathToPush = `${workspaceHomePath}/payments/${row.id ?? ""}`;
    } else {
      const route = getBaseRoute();
      pathToPush = `${route}/payments/${row.id}`;
    }

    history.push(pathToPush);
    updateBaseRoute(pathToPush);
  };

  // table configuration
  const cellRendererMapping: CellRendererMappingObject = {
    "RowDataTypes.BoldText": RowDataTypes.BoldText,
    "RowDataTypes.DateEpoch": RowDataTypes.DateEpoch,
    "RowDataTypes.Currency": RowDataTypes.Currency,
  };

  useEffect(() => {
    if (
      (exportData?.length ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO &&
      csvInstance &&
      csvInstance.current &&
      csvInstance.current.link
    ) {
      setTimeout(() => {
        csvInstance?.current?.link.click();
        setExportData([]);
      });
    }
  }, [exportData]);

  const getFilters = (exportInvoice = false, ids?: string[]) => {
    const filterQuery = TableUtils.getFilterFromURL(params, tableConfigs.columns);
    const additionalFilters: Array<Record<string, string>> = [];
    // evaluate select filter on export
    if (exportInvoice && selectionConfig?.isAllRecordsSelected && ids && ids.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      const { hasAllRecordsSelectedButtonClicked, isAllRecordsSelected, tableRecords } = selectionConfig;

      const exportIds =
        hasAllRecordsSelectedButtonClicked?.current && !isAllRecordsSelected
          ? tableRecords?.allRecords?.filter((item) => !item?.isSelected)?.map((deselectedItem) => deselectedItem?.id)
          : ids;

      exportIds.forEach((id) => {
        const searchlightFilter = `qa[payment_id_${hasAllRecordsSelectedButtonClicked?.current && !isAllRecordsSelected ? "not_in" : "in"}][]=${id}`;
        additionalFilters.push({
          searchlightFilter,
        });
      });
    }

    return TableUtils.columnFilterParser(filterQuery, true, additionalFilters?.length ? [...additionalFilters] : []);
  };

  const handleExport = async (ids: string[]) => {
    const filterQuery = TableUtils.getFilterFromURL(params, tableConfigs.columns);
    const filters = [
      TableUtils.columnFilterParser(filterQuery, true, tableConfigs.preDefinedFilters),
      getFilters(true, ids),
      initialFilterConfigs?.filterQuery,
    ]
      .filter(Boolean)
      .join("&");

    let response: any = {};
    try {
      setExportLoading(true);
      response = await PaymentsClientV2.exportPayments(filters);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setExportData(response);
      setExportLoading(false);
    }
  };

  const handleExportV2 = async (ids: string[]) => {
    const filterQuery = TableUtils.getFilterFromURL(params, tableConfigs.columns);
    const filters = [TableUtils.columnFilterParser(filterQuery, true, tableConfigs.preDefinedFilters), getFilters(true, ids), appliedFilters]
      .filter(Boolean)
      .join("&");

    let response: any = {};
    try {
      setExportLoading(true);
      const isExportAllRecords =
        (selectionConfig?.tableRecords?.selectedRecordsIds?.length ?? NUMERIC_VALUES.CONSTANT_ZERO) > NUMERIC_VALUES.CONSTANT_ZERO
          ? selectionConfig.isAllRecordsSelected || !!selectionConfig?.hasAllRecordsSelectedButtonClicked?.current
          : true;
      const sortQueryString = TableUtils.getSortQueryString(params, sortQuery);

      response = await exportClient.exportRecords(
        EXPORT_CATEGORIES.UNAPPLIED_PAYMENTS,
        isExportAllRecords,
        filters,
        TableUtils.sortQueryParser(sortQueryString ?? "", true)
      );
      if (response.success) {
        setToastOptions({ open: true, severity: "success", message: ExportToastMessage.EXPORT_IN_PROGRESS });
        updateExportedRecordsIds([response.data?.export_request_id], false);
      }
    } catch (error) {
      setToastOptions({ open: true, severity: "error", message: ExportToastMessage.EXPORT_FAILED });
    } finally {
      setExportLoading(false);
    }
  };

  const rowSelectBtns: UserAction[] = [
    {
      children: "Export",
      icon: <ExportV2 />,
      loading: exportLoading,
      callback: async (ids: string[]): Promise<void> => {
        isExportV2Enabled ? await handleExportV2(ids) : await handleExport(ids);
      },
      visible: {
        onEllipsis: false,
        onHeader: false,
        onHover: false,
        onCaption: true,
      },
    } as UserAction,
  ];

  const getPreviousSuggestedData = (recentSearchHistory: FilterDropdownSuggestionRef) => {
    const { filterSearchedText } = initialFilterConfigs ?? {};
    const { reference_code, payment_company_name } = filterSearchedText ?? {};

    return {
      paymentNumber:
        reference_code?.value?.length >= NUMERIC_VALUES.CONSTANT_THREE
          ? recentSearchHistory?.reference_code?.filter((item) => initialFilterConfigs?.activeFilters?.reference_code?.ids?.includes(item.id))
          : recentSearchHistory?.reference_code,
      customerName:
        payment_company_name?.value?.length >= NUMERIC_VALUES.CONSTANT_THREE
          ? recentSearchHistory?.payment_company_name?.filter((item) =>
              initialFilterConfigs?.activeFilters?.payment_company_name?.ids?.includes(item.id)
            )
          : recentSearchHistory?.payment_company_name,
    };
  };

  //it will return maximum of 5 required formated record for dropdown search suggestion or result data
  const getDropdownSearchFilterData = (activitiesTableData: any) => {
    const requiredColumns = [...tableConfigs.columns];
    const { filterSearchedText = {}, getSearchSuggestionHistory } = initialFilterConfigs || {};
    const { reference_code, payment_company_name } = filterSearchedText;
    const recentPaymentsSearchedData = getSearchSuggestionHistory(
      selectedWorkspace.workspace_type_route?.toLocaleLowerCase() ?? WorkspaceType.AR,
      VIEW_CATEGORIES.CUSTOMER_UNAPPLIED_PAYMENTS
    );
    const previousData = getPreviousSuggestedData(recentPaymentsSearchedData);

    /**
     * if user searching for result then return result data along with previous selected data
     * else return max of 5 recent searched data if that is exist
     */
    const paymentNumberData =
      !reference_code?.value || reference_code?.value.length < NUMERIC_VALUES.CONSTANT_THREE
        ? (previousData?.paymentNumber ?? []).slice(NUMERIC_VALUES.CONSTANT_ZERO, NUMERIC_VALUES.CONSTANT_FIVE)
        : [...(previousData?.paymentNumber ?? []), ...(activitiesTableData ?? [])];

    const customerNameData =
      !payment_company_name?.value || payment_company_name?.value.length < NUMERIC_VALUES.CONSTANT_THREE
        ? (previousData?.customerName ?? []).slice(NUMERIC_VALUES.CONSTANT_ZERO, NUMERIC_VALUES.CONSTANT_FIVE)
        : [...(previousData?.customerName ?? []), ...(activitiesTableData ?? [])];

    //formatting data for th dropdown suggestion list

    const paymentNumberDropdownList = paymentNumberData
      .filter(({ reference_code, primaryText }) => reference_code || primaryText)
      .map(({ id, reference_code, primaryText }) => ({
        id,
        primaryText: reference_code ?? primaryText,
        isAttachemntRequired: false,
        selected: initialFilterConfigs?.activeFilters?.reference_code?.ids?.includes(id) ?? false,
        secondaryText: "",
        isAvatarRequired: false,
      }));

    const customerNameDropdownList = customerNameData
      .filter(({ payment_company_name, primaryText }) => payment_company_name || primaryText)
      .map(({ id, payment_company_name, primaryText }) => ({
        id,
        primaryText: payment_company_name ?? primaryText,
        isAttachemntRequired: false,
        selected: initialFilterConfigs?.activeFilters?.payment_company_name?.ids?.includes(id) ?? false,
        secondaryText: "",
        isAvatarRequired: true,
      }));

    /*
     * This code first creates a Map object by mapping each object in the original array to an array with two elements:
     * the key and the value. The Map constructor can then be called with the resulting array of key-value pairs to create
     * a new Map object. Finally, the Array.from method is used to convert the Map object back to an array, where the map
     * function is used to only return the values from the key-value pairs.
     */

    const uniquePaymentNumberSuggestions = Array.from(new Map(paymentNumberDropdownList.reverse().map((obj) => [obj.primaryText, obj])))
      .reverse()
      .map(([, value]) => value);

    const uniqeCustomerNameSuggestions = Array.from(new Map(customerNameDropdownList.reverse().map((obj) => [obj.primaryText, obj])))
      .reverse()
      .map(([, value]) => value);

    return requiredColumns.map((column) => {
      if (column.accessor === "reference_code") {
        return { ...column, dropdownSearchListItems: uniquePaymentNumberSuggestions };
      } else if (column.accessor === "payment_company_name") {
        return { ...column, dropdownSearchListItems: uniqeCustomerNameSuggestions };
      }
      return column;
    });
  };

  function createFilterQuery(field: string, value: string) {
    if (value && value.length >= NUMERIC_VALUES.CONSTANT_THREE) {
      return `qa[${field}_cont_any]=${encodeURIComponent(value)}`;
    }
    return "";
  }

  useEffect(() => {
    const { filterSearchedText } = initialFilterConfigs || {};
    const { reference_code, payment_company_name } = filterSearchedText || {};
    let filters = "";

    if (reference_code?.value?.length >= NUMERIC_VALUES.CONSTANT_THREE) {
      const paymentQuery = createFilterQuery("reference_code", reference_code.value);
      filters += paymentQuery;
    }

    if (payment_company_name?.value?.length >= NUMERIC_VALUES.CONSTANT_THREE) {
      const customerNameQuery = createFilterQuery("payment_company_name", payment_company_name.value);
      filters += (filters ? "&" : "") + customerNameQuery;
    }

    setIsColumnsConfigDataLoading(true);

    if (filters) {
      fetchPayments("all", filters, NUMERIC_VALUES.CONSTANT_ONE, NUMERIC_VALUES.CONSTANT_TWENTY)
        .then((res) => {
          setIsNoSearchResultFound(!res.totalCount);
          setColumnsConfigData(getDropdownSearchFilterData(res.records));
        })
        .catch((error) => {
          setIsNoSearchResultFound(true);
          console.log(error);
        })
        .finally(() => {
          setIsColumnsConfigDataLoading(false);
        });
    } else {
      setColumnsConfigData(getDropdownSearchFilterData([]));
      setIsColumnsConfigDataLoading(false);
    }
  }, [initialFilterConfigs?.filterSearchedText, initialFilterConfigs?.filterQuery]);

  useEffect(() => {
    return () => {
      setTotalCount(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    };
  }, []);

  //using caching data to update the footer at the same time
  const updatedPaginationObject = {
    ...paginationConfig,
    ...TableUtils.getUpdatePaginationPageState(paginationConfig, cachedData?.totalCount, cachedData?.pageSize),
  };

  return (
    <>
      <Table
        configs={{
          //Added the feature flag for the newly added filters excpet which are already there on Production
          columnConfigs: columnsConfigData?.map((item) => {
            if (
              ["tender_type", "payment_date", "base_currency_payment_amount", "base_currency_unapplied_amount", "actionHeader"].includes(
                item.accessor
              )
            ) {
              return { ...item, rowDataType: cellRendererMapping[item?.rowDataType] };
            } else {
              return { ...item, rowDataType: cellRendererMapping[item?.rowDataType], enableFilter: isFiltersEnabled };
            }
          }),
          initialTableConfigs: {
            name: "Unapplied Payments",
            type: "Payment",
            footerTableTypeText: "Payment",
            loading: isLoading,
          },
          paginationConfigs: { ...updatedPaginationObject, enableNavigator: true, enableSummary: true },
          initialSortConfigs: sortConfig,
          selectionConfig: selectionConfig,
          initialFilterConfigs: {
            ...initialFilterConfigs,
            dateFormat: calendarDateFormat,
            isSuggestionDataLoading: isColumnsConfigDataLoading,
            category: VIEW_CATEGORIES.CUSTOMER_UNAPPLIED_PAYMENTS,
            workspace: selectedWorkspace.workspace_type_route?.toLocaleLowerCase() ?? WorkspaceType.AR,
            isNoSearchResultFound,
          },
          currencyConfig: {
            locale: userStatus?.currency?.locale,
            code: userStatus?.currency?.code,
            symbol: userStatus?.currency?.symbol,
          },
        }}
        rows={(cachedData?.records ?? [])?.map((rec: any) => {
          return {
            ...rec,
            read: true,
            payment_date: new Date(rec.payment_date).getTime() / DEFAULT_NUMERIC_VALUES.DEFAULT_THOUSAND,
          };
        })}
        handlers={{
          onRowClick: handleRowClick,
        }}
        emptyMessage={{
          title: NO_DATA_MESSAGE,
          caption: EMPTY_PAYMENT_CAPTION_MESSAGE,
        }}
        userActions={rowSelectBtns}
        hoverBar={false}
        visibility={{
          exportBtn: false,
          hoverbar: false,
        }}
      />
      {(exportData?.length ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO ? (
        <CSVLink data={exportData} filename={`${viewConfigs.exportFileName}`} ref={csvInstance} style={{ display: "none" }} />
      ) : undefined}
    </>
  );
}

/*eslint-disable no-magic-numbers*/
import { styled } from "@mui/system";
import { pxToRem } from "../../../theme/theme";

const hoverBarWidth = "21rem";
const TableHoverbarContainer = styled("div", {
  name: "hoverbar",
})({
  "&&": {
    display: "block",
    position: "absolute",
    width: hoverBarWidth,
    padding: `${pxToRem(4)} ${pxToRem(24)} 0 0`,
    left: `calc(100% - ${hoverBarWidth})`,
    height: "98%",
    background: "#F7F7FB",

    "& .user-actions": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "top",

      "& .MuiIconButton-root": {
        "& svg path": {
          fill: "#8592AD",
        },
        "&:hover": {
          "& svg path": {
            fill: "#FFFFFF",
          },
        },
      },
    },
  },
});

export default TableHoverbarContainer;

import { TemplateAttachDetail } from "../../../../contexts/TemplateContext";
import { AttachmentType } from "../../../../types/enums";

export enum AttachmentTypes {
  WindowProp,
  Upload,
  PreviousUpload,
  TemplateProp,
  InboxDrive,
}

export type PreviousUploaded = { id: UUID | number; name: string };

export type ERPAttachment = {
  transactionId: UUID;
  transactionType: AttachmentType;
  transactionTypeCode: string;
  attachmentTitle: string;
};

export type AttachmentDataTypes =
  | FileList
  | File
  | AttachmentItem
  | PreviousUploaded
  | TemplateAttachDetail
  | string
  | TransactionItemType
  | InboxAttachment
  | ERPAttachment;

export type WindowAttachment = {
  id: UUID | number;
  name: string;
  data: AttachmentDataTypes;
  variant: AttachmentTypes;
};

export type InboxAttachmentPayload = {
  transaction_id: string | number;
  transaction_type: string;
};

export type WindowAttachmentPayload = {
  id: string | number;
  name: string;
};

import React from "react";
import { SecondaryActivityProps, SecondaryType } from "./SecondaryActivityTypes";
import AccordionActivity from "./AccordionActivity/AccordionActivity";
import BaseActivity from "./BaseActivity/BaseActivity";

const SecondaryActivity: React.FC<SecondaryActivityProps> = ({ variant, children, ...props }) => {
  switch (variant) {
    case SecondaryType.Base:
      return <BaseActivity {...props} />;
    case SecondaryType.Accordion:
      return <AccordionActivity {...props}>{children}</AccordionActivity>;
  }
};

export default SecondaryActivity;

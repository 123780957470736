import React from "react";
import { DEFAULT_NUMERIC_VALUES } from "../../../../constants/NumericConstants";
import { AlertContext } from "../../../../contexts/AlertContext";
import { CustomerMergeComponent } from "./CustomerMergeComponent";
import { CustomerMergeContainerProps } from "../../CustomerInterfaces/CustomerMergeInterface";
import { companiesClientV2 } from "../../../../db/version2Accessor";
import { WorkspaceContext } from "../../../../contexts/WorkspaceContext";
import { FallbackTypes } from "../../../../types/enums";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import { Modal } from "../../../library/DialogModal";
import { ACTIVITY_MERGE_SUBHEADER_MESSAGE_TWO } from "../../../../constants/config";
import { ActivityContext } from "../../../../contexts/ActivityContext";

const CustomerMergeContainer = (props: CustomerMergeContainerProps) => {
  const [selectedCompany, setSelectedCompany] = React.useState<Partial<CompanyV2> | null>(null);
  const [isLoadingCompanyList, setLoadingCompanyList] = React.useState<boolean>(true);
  const [showLoader, setShowLoader] = React.useState<boolean>(false);
  const [openMergeDialog, setOpenMergeDialog] = React.useState<boolean>(false);

  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { toggleConnectionUpdate } = React.useContext(CustomerContext) as CustomerType;
  const { allRowsSelected } = React.useContext(ActivityContext) as ActivityType;

  const onClickMerge = async () => {
    setShowLoader(true);
    let toastOptions: ToastOptions = { severity: "error", message: "Something Went Wrong", open: true };
    const bulkActivitiesCount = props.selectedActivitiesCount ?? props.connectionIds.length;

    const createMessage = (actionTaken: string, noOfRecords: number) => {
      return noOfRecords > DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
        ? `${noOfRecords} contacts ${actionTaken} to ${selectedCompany?.company_name} successfully`
        : `Contact is ${actionTaken} to ${selectedCompany?.company_name} successfully`;
    };
    const companyIdsQuery = selectedCompany?.company_id ? `qa[company_id_not_in][]=${selectedCompany.company_id}` : "";
    const appEnrollmentIdQuery = `qa[app_enrollment_id_null]=1`;
    const filter = [companyIdsQuery, appEnrollmentIdQuery, props?.deselectedRowsFilter].filter(Boolean).join("&");

    try {
      await companiesClientV2.mergeConnections(
        selectedCompany?.company_id ?? "",
        [...props.connectionIds],
        selectedWorkspace.id || FallbackTypes.Id,
        allRowsSelected,
        filter
      );
      toastOptions = { severity: "success", message: createMessage("merged", bulkActivitiesCount), open: true };
      // refresh table & side bar
      props.softRefresh?.();
      toggleConnectionUpdate();
    } catch (error) {
      console.error("error: ", error);
    } finally {
      setShowLoader(false);
      setToastOptions(toastOptions);
      setOpenMergeDialog(false);
      // reset states
      props.setOpen(false);
      props.setConnectionIds([]);
    }
  };

  const onClickCancel = () => {
    setOpenMergeDialog(false);
    props.setConnectionIds([]);
    props.setOpen(false);
  };

  const onClickClose = () => {
    props.setOpen(false);
    setSelectedCompany(null);
    setLoadingCompanyList(false);
  };

  return (
    <>
      <CustomerMergeComponent
        companyListDropdownLoader={isLoadingCompanyList}
        setSelectedCompany={setSelectedCompany}
        onClose={onClickClose}
        open={props.open}
        isLoading={showLoader}
        setOpenDialog={setOpenMergeDialog}
        disableMerge={selectedCompany === null}
        fetchCompanyList={props.fetchCompanyList}
      />

      <Modal
        modalStates={{
          open: openMergeDialog,
          onCloseFunction: () => setOpenMergeDialog(false),
        }}
        title={{
          label: "Merge these connections?",
        }}
        content={{
          label: ACTIVITY_MERGE_SUBHEADER_MESSAGE_TWO,
        }}
        secondaryButton={{
          enable: true,
          type: "button",
          variant: "primary",
          label: "Merge",
          loader: showLoader,
          onClickHandler: onClickMerge,
        }}
        primaryButton={{
          enable: true,
          type: "button",
          variant: "grey",
          label: "Cancel",
          onClickHandler: onClickCancel,
        }}
      />
    </>
  );
};

export default CustomerMergeContainer;

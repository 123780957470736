import React, { useRef, useState } from "react";
import Autocomplete from "../../../library/Autocomplete/Autocomplete";
import { AutocompleteRenderGetTagProps, AutocompleteRenderInputParams, Chip, ClickAwayListener, Popper, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Caution, PrimaryContact, UserPic } from "../../../library/Icons/Icons";
import { makeStyles } from "@mui/styles";
import styles from "../../../../css/_export.module.scss";
import CustomTooltip from "../../CustomTooltip/CustomTooltip";
import { TooltipTypes } from "../../../../constants/TooltipConstants";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../AtomicComponents/constants/numeric.constants";
import ContactPopoverCard from "../../ContactPopoverCard/ContactPopoverCard";
import { AlertContext } from "../../../../contexts/AlertContext";
import { useTranslation } from "react-i18next";

interface ActivityToProps {
  options: To[];
  to: To[];
  setTo: (e: React.SyntheticEvent, value: To[]) => void;
  typeAheadAction?: (val: string) => void;
  // restricts user to provide single contact
  restrictOneContact?: boolean;
  placeHolderText?: string;
}

interface IRenderOptions {
  pictureUrl: string;
  label: string;
  id: string;
  primary: boolean;
}

export default function ActivityTo(props: ActivityToProps): React.ReactElement {
  const tooltipStyles = makeStyles(() => ({
    tooltip: {
      maxWidth: "9.25rem",
      padding: "0.25rem 0.5rem",
      backgroundColor: styles.grey30,
    },
  }))();

  const { t } = useTranslation();
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const inputRef = useRef<HTMLInputElement>(null);
  const [editedChipIndex, setEditedChipIndex] = useState<number | null>(null);
  const [editedValue, setEditedValue] = useState<string>("");
  const [showContactPopup, setShowContactPopup] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedContact, setSelectedContact] = useState<ContactItem>({});
  const [activeTriggerIndex, setActiveTriggerIndex] = useState<number | null>(null);

  /**
   * @function onCopyToClipBoard
   * A helper function to store a string to the clipboard.
   * @param string - the string to store
   */
  const onCopyToClipBoard = (string?: string, type?: string) => {
    if (string) {
      navigator.clipboard.writeText(string ?? "N/A");
      let toastOptions = {} as ToastOptions;
      switch (type) {
        case "phone":
          toastOptions = {
            open: true,
            severity: "success",
            message: t("activityDetails.feedItems.contactAndSenderPopover.toastMessages.copyPhone", { ns: "activities" }),
          };
          setToastOptions(toastOptions);
          break;

        case "email":
          toastOptions = {
            open: true,
            severity: "success",
            message: t("activityDetails.feedItems.contactAndSenderPopover.toastMessages.copyEmail", { ns: "activities" }),
          };
          setToastOptions(toastOptions);
          break;

        default:
          break;
      }
    }
  };

  /**
   * Function used to render the input field.
   *
   * @param {AutocompleteRenderInputParams} params The params which used to compose input field.
   * @returns
   */
  const onRenderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      inputRef={inputRef}
      {...params}
      inputProps={{
        ...params.inputProps,
        disabled: props.restrictOneContact && !(props.to.length <= DEFAULT_NUMERIC_VALUES.ZERO),
        style: {
          ...params.inputProps.style,
          padding: 0,
        },
      }}
      InputProps={{
        ...params.InputProps,
        disableUnderline: true,
      }}
      variant="filled"
      autoComplete=""
      onChange={(event) => {
        if (props.typeAheadAction) {
          props.typeAheadAction(event.target.value);
        }
      }}
      placeholder={props?.to?.length ? "" : props?.placeHolderText ?? "At-least add one recipient"}
    />
  );

  /**
   * this funtion triggers the states to show/close the popper on click
   * @param index index of the chip clicked
   * @param option details of the contact
   * @param event event fired on mouse click
   */
  const handleChipClick = (index: number, option: To, event: React.MouseEvent<HTMLDivElement>) => {
    const selectedContact = {
      name: option.id === "" ? "" : option.label,
      email_address: option.id === "" ? option.label : option.id,
      phone: option?.phoneNumber,
      role_code: option?.userRole,
    };
    event.stopPropagation();
    setShowContactPopup(!showContactPopup);
    setAnchorEl(event.currentTarget as HTMLElement);
    setSelectedContact(selectedContact);
    setActiveTriggerIndex((prevIndex) => (prevIndex !== null ? null : index));
  };

  /**
   * Clears all the states for contact pop up
   */
  const clearContactPopup = () => {
    setShowContactPopup(false);
    setActiveTriggerIndex(null);
    setSelectedContact({});
    setAnchorEl(null);
  };

  /**
   * Function which used to create the contact suggestion list in activity dialog.
   *
   * @param {HTMLLIElement} props The props contains properties to create list.
   * @param {IRenderOptions} option The option which contains the optional properties.
   * @returns {HTMLLIElement} the contact list option.
   */
  const onRenderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: IRenderOptions) => (
    <li key={`${option.id}`} className="contact-item-parent" {...props}>
      <div className="contact-item-profile-tag">
        {option.pictureUrl ? (
          <img loading="lazy" width="24" alt={option.label} src={option.pictureUrl} />
        ) : option.primary ? (
          <PrimaryContact />
        ) : (
          <UserPic />
        )}
      </div>
      <div className="contact-info-item">
        {option.id !== "" && option.label !== "" ? (
          <>
            <span className="contact-name">{option.label || "--"}</span>
            <span className="contact-email">{option.id || "--"}</span>
          </>
        ) : (
          <span className="contact-name">{option.label || "--"}</span>
        )}
      </div>
    </li>
  );

  const handleChipDoubleClick = (index: number, option: To) => {
    clearContactPopup();
    setEditedChipIndex(index);
    setEditedValue(option?.id.length > NUMERIC_VALUES.CONSTANT_ZERO ? option?.id : option.label);
  };

  const handleTextFieldKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, index: number) => {
    if (event.key === "Enter") {
      if (editedChipIndex !== null) {
        const newValue = editedValue.trim();
        if (newValue === "") {
          // Remove the chip if content is empty
          props.setTo(
            event,
            props.to.filter((_, index) => index !== editedChipIndex)
          );
        } else {
          props.setTo(event, [
            ...props.to.slice(NUMERIC_VALUES.CONSTANT_ZERO, index),
            { id: "", label: newValue },
            ...props.to.slice(index + NUMERIC_VALUES.CONSTANT_ONE),
          ]);
        }
        setEditedChipIndex(null);
        setEditedValue("");
      }
    } else if (event.key === "Backspace" || event.key === "ArrowLeft" || event.key === "ArrowRight") {
      // Prevent the default behavior of backspace, left, and right arrow keys
      event.stopPropagation();
    }
  };

  /**
   * Function to handle on blur and create chips
   * @param event Event fired on blur
   */
  const handleBlur = (event: any) => {
    if (event.target.value !== "" && editedChipIndex === null) {
      props.setTo(event, [...props.to, { id: "", label: event.target.value }]);
    }
  };

  /**
   * Function used to prepare the chip of selected contact.
   *
   * @param {To[]} value The value contains the recipient items.
   * @param {AutocompleteRenderGetTagProps} getTagProps
   * @returns
   */
  const renderTags = (value: To[], getTagProps: AutocompleteRenderGetTagProps) =>
    value?.map(
      (option: To, index: number) =>
        option && (
          <div key={option?.label} className={props.restrictOneContact ? "na-body-toline-chip" : ""}>
            {editedChipIndex === index ? (
              <TextField
                autoFocus
                size="small"
                value={editedValue}
                onChange={(event) => setEditedValue(event.target.value)}
                onBlur={() => setEditedChipIndex(null)}
                onKeyDown={(event) => handleTextFieldKeyDown(event, index)}
              />
            ) : (
              <ClickAwayListener
                onClickAway={() => {
                  clearContactPopup();
                }}
              >
                <div>
                  <Chip
                    onClick={(event: React.MouseEvent<HTMLDivElement>) => handleChipClick(index, option, event)}
                    onDoubleClick={() => handleChipDoubleClick(index, option)}
                    variant="outlined"
                    label={option.label}
                    deleteIcon={<CloseIcon />}
                    {...getTagProps({ index })}
                    onDelete={() => {
                      value.splice(index, DEFAULT_NUMERIC_VALUES.ONE);
                      clearContactPopup();
                    }}
                    className={`${activeTriggerIndex === index ? "selected" : ""}`}
                  />
                  <Popper
                    open={showContactPopup}
                    disablePortal={false}
                    anchorEl={anchorEl}
                    placement={"bottom-start"}
                    className="contact-popover-popper"
                    modifiers={[
                      {
                        name: "flip",
                        enabled: true,
                        options: {
                          altBoundary: true,
                          rootBoundary: "document",
                          padding: 8,
                        },
                      },
                    ]}
                  >
                    <ContactPopoverCard selectedContact={selectedContact} onCopyToClipBoard={onCopyToClipBoard} />
                  </Popper>
                </div>
              </ClickAwayListener>
            )}
            {props.restrictOneContact && (
              <CustomTooltip
                type={TooltipTypes.RICH}
                title={"Approval requests can only be sent to one recipient at a time."}
                placement="bottom-start"
                classes={{ tooltip: tooltipStyles.tooltip }}
              >
                <div className="na-body-toline-tooltip">
                  <Caution />
                </div>
              </CustomTooltip>
            )}
          </div>
        )
    );
  /**
   * Event handler used to create a chip when user type or select the email address and press space key.
   *
   * @param {KeyboardEvent} event The event is keyDown event which triggered by user.
   */
  const onKeyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === " " && (event.target as HTMLInputElement).value && !/\s/g.test((event.target as HTMLInputElement).value)) {
      event.preventDefault();
      props.setTo(event, [...props.to.filter((value) => value !== undefined), { id: "", label: (event.target as HTMLInputElement).value }]);
      if (props.typeAheadAction) {
        props.typeAheadAction("");
      }
    }
  };

  /**
   * This Function filters out the options when user types to search for contacts.
   *
   * @param {To} val After comparing return the label if Id is present else return the Id.
   */
  const filterOptions = (val: To) => {
    return val.label + val.id;
  };

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <Autocomplete
      multiple
      id="tags-filled"
      options={props.options}
      freeSolo
      value={props.to}
      disablePortal
      clearOnBlur
      className="activity-to-autocomplete"
      onBlur={(event) => {
        // This is because we are using the clearOnBlur above prop
        if (props.typeAheadAction) {
          props.typeAheadAction("");
        }
        handleBlur(event);
      }}
      onKeyDown={onKeyDownHandler}
      onChange={props.setTo}
      autoSelect={false}
      getOptionLabel={filterOptions}
      style={{ border: "none" }}
      renderTags={renderTags}
      renderOption={onRenderOption}
      renderInput={onRenderInput}
      forcePopupIcon={false}
    />
  );
}

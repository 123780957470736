import { makeStyles } from "@mui/styles";
import styles from "../../../../../../css/_export.module.scss";

/**
 * custom hook to provide classes which overrides the
 * default styles for MUI Dialog, DialogTitle, DialogContentText
 * and DialogActions components
 *
 * @returns styles to override dialog mui styles
 */
const useBulkMoveDialogClasses = () => {
  const useStyles = makeStyles({
    bulkMoveDialog: {
      "& .MuiDialog-container": {
        alignItems: "flex-start",
        "& .MuiPaper-root": {
          boxShadow: "none",
          marginTop: "15%",
          padding: styles.large,
          margin: 0,
          maxWidth: "33rem",
          "& .MuiDialogContent-root": {
            "& .move-to-connection": {
              fontWeight: "bold",
            }
          },
          "& .MuiDialogActions-root": {
            justifyContent: "flex-end",
          },
        },
      }
    },
  });

  return useStyles();
};

export default useBulkMoveDialogClasses;

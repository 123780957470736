import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Card, Skeleton } from "@mui/material";
import { EmailOutlined, LocalPhoneOutlined, KeyboardArrowDown } from "@mui/icons-material";
import { ContactsCardRoot, ContactType, ContactHeaderPanel, ContactDetails } from "../styled/styled";

export default function ContactsCardLoader(): React.ReactElement {
  return (
    <ContactsCardRoot>
      <Card raised={true}>
        <ContactType>
          <Skeleton variant="text" width="20%" height="100%" animation="wave" />
        </ContactType>
        <Accordion disableGutters expanded={false}>
          <AccordionSummary expandIcon={<KeyboardArrowDown />}>
            <ContactHeaderPanel>
              <Skeleton variant="circular" width="2rem" height="2rem" animation="wave" />
              <div className="contact-header-info">
                <div className="contact-name">
                  <Skeleton variant="text" width="40%" height="100%" animation="wave" />
                </div>
                <div className="contact-email-tooltip">
                  <Skeleton variant="text" width="60%" height="100%" animation="wave" />
                </div>
              </div>
            </ContactHeaderPanel>
          </AccordionSummary>
          <AccordionDetails>
            <ContactDetails>
              <LocalPhoneOutlined />
              <div className="contact-detail">
                <Skeleton variant="text" width="100%" height="100%" animation="wave" />
              </div>
            </ContactDetails>
            <ContactDetails>
              <EmailOutlined />
              <div className="contact-detail">
                <Skeleton variant="text" width="100%" height="100%" animation="wave" />
              </div>
            </ContactDetails>
          </AccordionDetails>
        </Accordion>
      </Card>
    </ContactsCardRoot>
  );
}

import React, { useRef } from "react";
import { EmailAttachment } from "../../../../library/Icons/Icons";
import { AttachmentModal } from "../../Attachments";

interface AttachmentProps {
  renderKey: number;
  handleFileSelect: (files: FileList) => void;
  fileSelected: boolean;
  customerId: UUID;
  onClickAddAttachments: (inboxFiles: InboxAttachment[]) => void;
}

export default function Attachment(props: AttachmentProps): React.ReactElement {
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);

  /**
   * detaches the attachment select modal from dom and opens
   * opens the input file explorer to select and upload.
   */
  const openFiles = () => {
    setOpenDialog(false);
    if (inputFile && inputFile.current) {
      inputFile.current.click();
    }
  };

  /**
   * checks if customer id is available and if not opens the default
   * file input.
   */
  const onClickAttachmentIcon = () => {
    if (!props.customerId) {
      openFiles();
    } else {
      setOpenDialog(true);
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.handleFileSelect(e.target.files ?? new FileList());
  };

  return (
    <div key={props.renderKey} className={"rdw-custom-wrapper"}>
      <div className={"rdw-option-wrapper rdw-custom-option-wrapper"} style={{ backgroundColor: props.fileSelected ? "#EEF0F6" : "none" }}>
        <input
          className={"na-body-message-attachment-input"}
          type="file"
          ref={inputFile}
          accept=".jpg, .xls, .pdf, .docx, .xlsx, .csv, .txt, .jfif"
          onChange={handleOnChange}
          multiple
        />
        {openDialog && (
          <AttachmentModal
            openFileExp={openFiles}
            toggleDialog={setOpenDialog}
            customerId={props.customerId}
            addAttachmentsCallback={props.onClickAddAttachments}
          />
        )}
        <div onClick={onClickAttachmentIcon} className={`${props.fileSelected ? "rdw-custom-option-file-selected" : "rdw-custom-option-file"}`}>
          <EmailAttachment />
        </div>
      </div>
    </div>
  );
}

export const STYLE_CONSTANTS = {
  MODAL_WIDTH: 100,
  MODAL_HEIGHT: 100,
  MODAL_PAPER_WIDTH: 80,
  MODAL_PAPER_HEIGHT: 41.25,
};

export const NONE = "none";

export const VIEW = Object.freeze({
  INVOICES: "Invoices",
  PAYMENTS: "Payments",
  BILLS: "Bills",
  DOCUMENTS: "Documents",
});

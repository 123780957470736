import React, { MouseEvent, ReactElement } from "react";
import { UserPic } from "../../../library/Icons/Icons";
import "./Userpic.scss";

interface Props {
  className?: string;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  id?: string;
  imageUrl?: string;
}

/**
 * * Defines the User Picture Button
 */
export default function Userpic(props: Props): ReactElement {
  const [hasError, setHasError] = React.useState<boolean>(false);
  const handleImageLoadError = () => {
    setHasError(true);
  };

  return (
    <button
      id={props.id}
      className={`megamenu-userpic-btn ${props.onClick ? "userpic-btn-hoverable" : "user-icon-default"} ${props.className ?? ""}`}
      onClick={props.onClick}
      type="button"
    >
      {props.imageUrl && !hasError ? (
        <img src={props.imageUrl} alt="User Picture" className="userpic-btn-img" onError={handleImageLoadError} />
      ) : (
        <>{props.icon ?? <UserPic />}</>
      )}
    </button>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, MouseEvent, useEffect } from "react";
import { HeaderButton } from "../../library/Table/TableForActivityStreams";
import { activitiesClientV2, emailsClientV2, automationClientV2 } from "../../../db/version2Accessor";
import {
  Email,
  MarkAsCompleteSecondaryButtonOutline,
  MarkAsUnread,
  AssignTo,
  Spam,
  Phone,
  NoteFill,
  MoveTo,
  Snoozed,
  SnoozedToast,
  Ellipses,
  UserPic,
} from "../../library/Icons/Icons";
import { useHistory, useRouteMatch, useParams } from "react-router-dom";
import ActivityMove from "../ActivityDetail/ActivityFeedV2/ActivityFeedActions/MoveActivity/MoveActivity";
import ActivityReassign from "../ActivityDetail/ActivityFeed/ActivityFeedActions/ReassignActivity/ReassignActivity";
import { AlertContext } from "../../../contexts/AlertContext";
import { AxiosError } from "axios";
import { ActivityActionTypes, ActivityStatus, FallbackTypes, WorkspaceType, EmailAction, AttachmentType } from "../../../types/enums";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES, DEFAULT_PAGINATION_VALUES } from "../../../constants/NumericConstants";
import { CONNECTION_STATUS } from "../../../constants/ConnectionConstants";
import { ActivityActionPerformStatus, ActivityContext } from "../../../contexts/ActivityContext";
import NewActivityDropdown from "../../library/AddNewActivityDropdown/AddNewActivityDropdown";
import MenuItem from "../../library/MenuItem/MenuItem";
import { ClickAwayListener, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SpamFraudModal from "../ActivityDetail/ActivityFeed/ActivityFeedActions/MarkConnectionSpamFraud/MarkConnectionSpamFraud";
import { ComponentConfigContext } from "../../../contexts/ComponentConfigContext";
import { TemplateContext } from "../../../contexts/TemplateContext";
import { TemplateDefinitionProps } from "../../../app/Templates/TemplateFactory";
import { AppContext } from "../../../contexts/AppContext";
import NewActivityUtils from "../../../utils/NewActivityUtils/NewActivityUtils";
import { TemplateTypes, viewType } from "../../../app/Templates/TemplateTypes";
import NewActivityPopup from "../../library/AddNewActivityDropdown/NewActivityPopup";
import { Row } from "react-table";
import { DocumentSwitchContext } from "../../../contexts/DocumentSwitchContext";
import TrackingUtils from "../../Tracking/Tracking.Utils";
import { ApplicationRouteContext } from "../../../contexts/ApplicationRouteContext";
import { CustomerContext } from "../../../contexts/CustomerContext";
import _ from "underscore";
import Utils from "../../../utils/utils";
import { companiesClient } from "../../../db/accessor";
import { FeatureFlagContext, FeatureFlagProviderType } from "../../../contexts/FeatureFlagContext";
import { Table, useColumnFilters } from "../../library/AtomicComponents/Table/index";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { RefetchIntervals } from "../../../constants/CacheConfig";
import { UserAction } from "../../library/AtomicComponents/Table/types/table.types";
import usePagination from "../../library/AtomicComponents/Table/hooks/usePagination";
import useColumnSort from "../../library/AtomicComponents/Table/hooks/useColumnSort";
import useRowSelection from "../../library/AtomicComponents/Table/hooks/useRowSelection";
import useLocale from "../../../hooks/useLocale";
import { ACTIVITY_SPAM_PENDO, BTN_NEW_ACTIVITY } from "../../../constants/config";
import useContacts from "../../../hooks/useContacts";
import ActivityFeedMethods from "../ActivityDetail/ActivityFeedV2/helpers/ActivityFeedMethods";
import { useTranslation } from "react-i18next";
import useWorkspaceConfigurations from "../../../hooks/useWorkspaceConfigurations";
import TableUtils from "../../../utils/TableUtils/TableUtils";
import useHandleVisibility from "../../../hooks/useHandleVisibility";

type TableProps = {
  columns: any[];
  predefinedFilter?: { route: string; searchlightFilter: string }[];
  assigneeType?: string;
  route?: string;
  fromCustomers?: boolean;
  category?: string;
};

export default function ActivitiesTable(props: TableProps): React.ReactElement {
  const { getContactsOptions, filterPrimaryContacts, allContactOptions } = useContacts();
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const { configs: currentWorkspaceConfigs, configProvider, pathType, selectedWorkspace, workspaceHomePath } = useWorkspaceConfigurations();
  const { getConfig } = React.useContext(ComponentConfigContext) as ComponentConfigType;
  const configs = selectedWorkspace.workspace_type_route === WorkspaceType.AW ? configProvider[pathType] : currentWorkspaceConfigs;
  const {
    activityPerformInProgress,
    activityActionInProgress,
    activityCloseAction,
    attachSelectedAllPayload,
    handleClose,
    activityStatus,
    setActivityStatus,
    setUserView,
    setAllRowsSelected,
  } = React.useContext(ActivityContext) as ActivityType;
  const [enableTypeAheadSuggestions, setEnableTypeAheadSuggestions] = React.useState(false);
  const params = new URLSearchParams(window.location.search);
  const { path } = useRouteMatch();
  const history = useHistory();
  const { customerId } = useParams<{ customerId: string }>();

  //eslint-disable-next-line
  const [isLoading, setLoading] = useState<boolean>(true);
  const [fromTime, setFromTime] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [toTime, setToTime] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);

  const [newActivity, setNewActivity] = useState<boolean>(false);
  const [activityIds, setActivityIds] = useState<string[]>([]);
  const [isMarkAsUnread, setIsMarkAsUnread] = useState<boolean>(false);
  const [moveDrawerOpen, setMoveDrawerOpen] = useState<boolean>(false);
  const [bulkConnection, setBulkConnection] = useState<PrimaryConnection | null>(null);
  const [reassignDrawerOpen, setReassignDrawerOpen] = useState<boolean>(false);
  const [isSnoozeOpen, setIsSnoozeOpen] = useState<boolean>(false);
  const [isSenderOpen, setIsSenderOpen] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [connectionSpam, setConnectionSpam] = useState<boolean>(false);
  const [companyContactOptions, setCompanyContactOptions] = useState<To[]>([]);
  // templateName > indicates the selected template code by the user
  const [templateName, setTemplateName] = useState<string>("");
  const [newActivityType, setNewActivityType] = useState<string | null>(null);
  // New activity dropdown states
  const [showActivity, setShowActivity] = useState<boolean>(false);
  const [supportedTemplateList, setSupportedTemplateList] = useState<TemplateDefinitionProps[]>([]);
  const [item, setSelectedItem] = useState<any>();
  const [companyData, setCompanyData] = useState<CompanyModel>({} as CompanyModel);
  const { userStatus } = React.useContext(AppContext) as AppType;
  const [editorState, setEditorState] = useState<string>("");
  const newActivityUtils = new NewActivityUtils(selectedWorkspace?.id || FallbackTypes.Id);
  const [isTemplateSupport, setIsTemplateSupport] = useState<boolean>(false);
  const [originalSubjectLine, setOriginalSubjectLine] = useState<string>("");
  const [isNudgeActivity, setIsNudgeActivity] = useState<boolean>(false);
  const [isReply, setIsReply] = useState<boolean>(false);
  const [nudgeTo, setNudgeTo] = useState<To[]>([]);
  const [nudgeCc, setNudgeCc] = useState<To[]>([]);
  const [nudgeBcc, setNudgeBcc] = useState<To[]>([]);
  const [isActivityPopupLoading, setIsActivityPopupLoading] = useState<boolean>(false);
  const [inboxCompany, setInboxCompany] = useState<CompanyModel | null>(null);
  const [trackActivityTransactions, setTrackActivityTransactions] = useState<TransactionItemType[]>([]);
  const {
    setSelectedActivityStreamId,
    setActivityStreamIds,
    setCurrentPaginationState,
    setDocumentType,
    setEnableDocumentSwitch,
    setRootSwitcherStateSnapshot,
  } = React.useContext(DocumentSwitchContext) as DocumentSwitchType;
  const { company, signature, loginUserCompany } = React.useContext(CustomerContext) as CustomerType;
  const { updateBaseRoute, getBaseRoute } = React.useContext(ApplicationRouteContext) as ApplicationRouteType;
  const { templateFactory, prepareTemplateDataObject, handleTemplateAttachment, templateData } = React.useContext(TemplateContext) as ITemplateProps;
  const [defaultTemplateId, setDefaultTemplateId] = useState<TemplateTypes | string>();
  const useStyles = makeStyles(() => ({
    tooltip: {
      background: "white !important",
      margin: "0 !important",
      padding: "0 !important",
      borderRadius: "0.125rem",
      boxShadow: "0 0.5rem 0.75rem rgba(0, 0, 0, 0.1), 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1)",
    },
  }));
  const { isEnabled } = React.useContext(FeatureFlagContext) as FeatureFlagProviderType;
  const isProfileManagementEnabled = isEnabled("PROFILE_MANAGEMENT");
  const isFiltersEnabled = isEnabled("FILTERS_ENABLE");
  const { url } = useRouteMatch();

  // bind table states & configs
  const pageNoFromUrl = parseInt(params.get("page") ?? "1", NUMERIC_VALUES.CONSTANT_TEN) || NUMERIC_VALUES.CONSTANT_ONE;
  const { setTotalRecords, setTotalPages, updatePageNumber, ...paginationConfig } = usePagination(
    DEFAULT_PAGINATION_VALUES.PAGE_SIZE,
    pageNoFromUrl ? pageNoFromUrl - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE : DEFAULT_PAGINATION_VALUES.PAGE_COUNT
  );
  const { query: sortQuery, ...sortConfig } = useColumnSort("last_activity_at desc");
  const selectionConfig = useRowSelection(paginationConfig?.page?.totalRecords);
  const { ...initialFilterConfigs } = useColumnFilters(true, [], updatePageNumber);
  const { calendarDateFormat, availableLanguagesForTemplates } = useLocale();
  const [currentAssignee, setCurrentAssignee] = useState({} as any);
  const [columnsConfigData, setColumnsConfigData] = useState<any[]>(() => props.columns);
  const [isColumnsConfigDataLoading, setIsColumnsConfigDataLoading] = useState<boolean>(true);
  const [isNoSearchResultFound, setIsNoSearchResultFound] = useState<boolean>(false);
  const [deselectedRowsFilter, setDeselectedRowsFilter] = useState<string>("");
  const selectedRowsCount = selectionConfig?.getSelectedRowsCount();
  const [connectionIdquery, setConnectionIdquery] = useState<string>("");
  const { t } = useTranslation();
  const filterObjFromUrl = JSON.parse(params.get("filters") ?? "{}");
  const appliedFilters = initialFilterConfigs.generateFilterQuery(Object.values(filterObjFromUrl), true);
  /**
   * setup common state with reducer to handle open/close states for send and send and close button
   * React useState hooks.
   */
  const initialVisibility = {
    isSendBtnDisabled: false,
  };
  const { visibility, dispatch } = useHandleVisibility<typeof initialVisibility>({ isSendBtnDisabled: false });

  const getSnoozeList = () => {
    const hours = t("snoozeMenuListLabels.hours", { ns: "activities" });
    const days = t("snoozeMenuListLabels.days", { ns: "activities" });
    const week = t("snoozeMenuListLabels.week", { ns: "activities" });
    const snoozeFor = t("snoozeMenuListLabels.snoozeFor", { ns: "activities" });
    return [
      { id: `12 ${hours}`, value: `${snoozeFor} 12 ${hours}`, valueInEpoch: 43200 },
      { id: `24 ${hours}`, value: `${snoozeFor} 24 ${hours}`, valueInEpoch: 86400 },
      { id: `2 ${days}`, value: `${snoozeFor} 2 ${days}`, valueInEpoch: 172800 },
      { id: `3 ${days}`, value: `${snoozeFor} 3 ${days}`, valueInEpoch: 259200 },
      { id: `1 ${week}`, value: `${snoozeFor} 1 ${week}`, valueInEpoch: 604800 },
    ];
  };

  const primaryContact = filterPrimaryContacts(companyContactOptions);

  const setViewAndStatus = (url: string) => {
    //it return status for the activities , but for connection activities returns connection id
    const status = url.slice(url.lastIndexOf("/") + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);

    setUserView(props?.assigneeType ?? "me");
    const connectionKeywords = /(customers|vendors|connections|internal)/;

    if (status === props?.assigneeType || status === "mine") {
      setActivityStatus("active");
    } else if (status === "waitingForResponse") {
      setActivityStatus("waiting_for_response");
    } else if (connectionKeywords.test(url)) {
      //for connections activities set the assignee type as all and pass primary connection id as ransac
      setActivityStatus("all");
      setConnectionIdquery(`qa[primary_connection_id_eq]=${status}`);
    } else {
      setActivityStatus(status);
    }
  };

  const baseConfigs = getConfig("base");
  const getWorkspaceType = () => {
    const workspaceType = baseConfigs.workspaces.supportedWorkspaces.filter((item: any) => item.workspace_type === selectedWorkspace.workspace_type);
    return workspaceType[0].workspace_name;
  };
  const emptyTitleMessage = t("table.emptyMessage.title", {
    workspaceName: getWorkspaceType() ?? "",
    endingWord: selectedWorkspace?.workspace_type_route === WorkspaceType.AW ? "." : " workspace.",
    ns: "activities",
  });

  const getSearchStatus = () => {
    const statusArray = Object.keys(ActivityStatus).map((e) => Object(ActivityStatus)[e]?.toLocaleLowerCase?.());
    let searchStatus = url.slice(url.lastIndexOf("/") + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);
    if (searchStatus === "waitingForResponse") {
      searchStatus = "waiting_for_response";
    } else if (!statusArray.includes(searchStatus)) {
      searchStatus = "active";
    } else if (props?.assigneeType === "spam") {
      searchStatus = "";
    }
    searchStatus =
      url.includes("customers") || url.includes("vendors") || url.includes("connections") || url.includes("internal") ? "" : searchStatus;
    return searchStatus;
  };

  function getFilterFromURL(): SearchFilter[] {
    const ret: SearchFilter[] = [];
    const filters: any[] = [];

    props?.columns &&
      props?.columns.map((column: any) => {
        const value = params.get(column.accessor)?.replaceAll(/'/g, "''") ?? null;
        filters.push({ field: column.searchlightField ?? null, token: column.searchlightToken ?? null, value: value ?? null });
      });
    filters.forEach((filter: { field: string; token: string; value: string | null }) => {
      if (filter.value !== null) {
        ret.push({ filterKey: filter.field, filterToken: filter.token, filterValue: filter.value });
      }
    });
    return ret;
  }

  const queryClient = useQueryClient();

  const refreshInboxMegamenuCount = async () => {
    await queryClient.refetchQueries({ queryKey: [`${selectedWorkspace?.workspace_type}-${selectedWorkspace?.id}`], type: "active", exact: true });
  };

  const queryKey = `activity-streams-index-${selectedWorkspace?.workspace_type_route}-${props?.assigneeType}-${getSearchStatus()}-${
    paginationConfig.page?.pageSize
  }-${paginationConfig.page?.pageNumber}-${sortQuery}`;

  const determineCompanyTypeLabel = (type: string) => {
    switch (type) {
      case "":
        return "Other Connection";
      case "Company":
        return "My Company";
      default:
        return type;
    }
  };

  const fetchParser = (fetchResult: ActivityModelFetchResult, variant?: FetchVariant) => {
    return (
      fetchResult?.data?.map((record: ActivityStreamItem) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record.id,
          }),
          ...((variant === "export" || variant === "all") && {
            company_type: determineCompanyTypeLabel(record.primary_connection?.company_type ?? ""),
            assignee_name: record.assignee?.name,
            assigneeEmail: record.assignee?.email,
            status: record?.status,
            last_activity_at: record?.last_activity_at,
            subject: record?.subject,
            attachments: record?.attachments ?? [],
            auto_detected_flag: record?.auto_detected_flag,
            platform_connection_company_name: record?.primary_connection?.name,
            emailAddress: record?.primary_connection?.email_address,
            snoozed_until: record?.snoozed_until,
            primary_connection: record.primary_connection,
          }),
          ...(variant === "all" && {
            read: record.read,
          }),
        };
      }) ?? []
    );
  };

  const getActivitiesTableRecords = async (searchedTextQuery?: string, columnFilters?: string, pageNumber?: number, pageSize?: number) => {
    let others = url.slice(url.lastIndexOf("/") + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);
    others = url.includes("customers") || url.includes("vendors") || url.includes("connections") ? "qa[status_not_eq]=4" : "";

    const finalAppliedFilter = [others, searchedTextQuery, columnFilters].filter(Boolean).join("&");
    const sortQueryString = TableUtils.getSortQueryString(params, sortQuery);

    const res = await activitiesClientV2.fetchTableRecords(
      props?.assigneeType ?? "me",
      selectedWorkspace?.id,
      getSearchStatus(),
      pageNumber ?? paginationConfig.page?.pageNumber,
      pageSize ?? paginationConfig.page?.pageSize,
      customerId,
      sortQueryString,
      getFilterFromURL(),
      false,
      `&${finalAppliedFilter}`
    );
    return res;
  };

  const {
    isLoading: isDataLoadingRC,
    data: cachedData,
    refetch,
    isFetching,
  } = useQuery(
    [
      queryKey,
      paginationConfig.page?.pageNumber,
      paginationConfig.page?.pageSize,
      getSearchStatus(),
      TableUtils.getSortQueryString(params, sortQuery),
      appliedFilters,
      customerId,
    ],
    async () => {
      const res = await getActivitiesTableRecords("", appliedFilters);
      return { ...res, data: fetchParser(res, "all") };
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchInterval: url.toLowerCase().includes("/spam")
        ? RefetchIntervals.activityStreamsSpamIndexOverride
        : RefetchIntervals.activityStreamsIndex,
    }
  );

  const refetchCacheData = () => {
    setActivityIds([]);
    selectionConfig.resetTableRecords();
    setIsMarkAsUnread(false);
    refreshInboxMegamenuCount();
    return refetch();
  };

  const checkPrimaryConnectionId = (data: any[]) => {
    if (data.length === NUMERIC_VALUES.CONSTANT_ZERO) {
      return null;
    }
    const nonERPConnections = data.filter((item) => item.primary_connection.app_enrollment_id === null);

    if (nonERPConnections.length === NUMERIC_VALUES.CONSTANT_ZERO) {
      return null;
    }

    const initialPrimaryConnection = nonERPConnections[NUMERIC_VALUES.CONSTANT_ZERO].primary_connection;
    const isSameId = data.every(
      (item) => item.primary_connection.id === initialPrimaryConnection.id && item.primary_connection.app_enrollment_id === null
    );
    return isSameId ? initialPrimaryConnection : null;
  };

  useEffect(() => {
    /**
     * update the page number once user perform any action and because of that total records get's changed
     */
    if ((paginationConfig.page?.pageNumber || NUMERIC_VALUES.CONSTANT_ONE) > cachedData?.total_pages) {
      updatePageNumber(cachedData?.total_pages || NUMERIC_VALUES.CONSTANT_ONE);
    }
    setTotalRecords(cachedData?.total_records ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    setTotalPages(cachedData?.total_pages ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);

    /**
     * Side-effect to watch cachedData change and if an update is received,
     * then make an active call to update meta count
     */
    refreshInboxMegamenuCount();
  }, [cachedData]);

  /**
   * Side effect to control mark as read/unread toggle
   */
  useEffect(() => {
    if (selectionConfig.tableRecords?.selectedRecords.length) {
      setIsMarkAsUnread(!selectionConfig.tableRecords?.selectedRecords.some((row: any) => !row.read));
    }
  }, [selectionConfig.tableRecords?.selectedRecords.length]);

  /**
   * Side effect to keep track of Primary Connection ID in case multiple activities are moved to a connection
   */
  useEffect(() => {
    setBulkConnection(checkPrimaryConnectionId(selectionConfig.tableRecords?.selectedRecords));
  }, [selectionConfig.tableRecords?.selectedRecords]);

  async function handleRowClick(
    e: MouseEvent<HTMLTableRowElement>,
    row: { id?: string; read?: boolean },
    currentPageRecords?: { id: string; isSelected?: boolean }[]
  ) {
    /**
     * If a row item is already selected, then clicking anywhere on other rows should select those rows, and do not navigate.
     * if double clicked, then navigate to those row records
     */
    const clickType = e.detail;
    if (clickType === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE && selectionConfig.tableRecords?.selectedRecordsIds.length && row.id) {
      selectionConfig?.onClickTableRow(row?.id, currentPageRecords ?? []);
      return;
    }

    let pathToPush!: string;

    if (path.includes("all")) {
      pathToPush = `${workspaceHomePath}/activities/all/${row.id}`;
    } else if (path.includes("mine")) {
      pathToPush = `${workspaceHomePath}/activities/mine/${row.id}`;
    } else if (path.includes("unassigned")) {
      pathToPush = `${workspaceHomePath}/activities/unassigned/${row.id}`;
    } else if (path.includes("spam")) {
      pathToPush = `${workspaceHomePath}/activities/spam/${row.id}`;
    } else if (path.includes("search")) {
      pathToPush = `${history.location.pathname}/activities/${row.id ?? ""}`;
    } else {
      // coming from customer dashboard
      const route = getBaseRoute();
      pathToPush = `${route}/activities/${row.id}`;
    }

    /**
     * Set the following properties into ActivityStreamSwitchContext
     * to facilitate activity stream switching.
     * @param selectedActivityStreamId - currently selected activity stream id
     * @param activityStreamIds - list of activity streams in the index
     * @param paginationState - current pagination state for reference
     */
    setEnableDocumentSwitch(true);
    await setSelectedActivityStreamId({ id: row.id } as ActivityStreamId);
    await setActivityStreamIds(cachedData?.data.map((item: any) => ({ id: item.id })));
    await setCurrentPaginationState({
      page: paginationConfig.page?.pageNumber ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
      pageCount: cachedData?.total_records,
      pageSize: paginationConfig.page?.pageSize ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
      totalRecords: paginationConfig.page?.totalRecords ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
    });
    setDocumentType("activity_stream");
    setRootSwitcherStateSnapshot();
    history.push(pathToPush);
    // if coming from customers or internal, no need to update the base route
    if (!path.includes(configs.activities.detail.resources.connections) && !path.includes("connections") && !path.includes("internal")) {
      updateBaseRoute(pathToPush);
    }
    if (!row.read) {
      try {
        await activitiesClientV2.patch(selectedWorkspace?.id || FallbackTypes.Id, row.id ?? "", ActivityActionTypes.Read, {
          read: true,
        });
      } catch (error: unknown) {
        console.log(error);
      }
    }
  }

  // check if template code is required as content-type
  const needTemplateCodeAsPayload = React.useMemo(
    () =>
      [
        TemplateTypes.AR_CREATE_CUSTOMER_PROFILE,
        TemplateTypes.AR_UPDATE_CUSTOMER_PROFILE,
        TemplateTypes.AP_CREATE_VENDOR_PROFILE,
        TemplateTypes.AP_CREATE_VENDOR_PROFILE,
      ].includes(templateName as TemplateTypes),
    [templateName]
  );

  const handleSend = async (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    attachmentIds: AttachmentIds,
    inboxAttachments = [] as TransactionItemType[],
    disableSoftRefresh?: boolean,
    isSendAndClose?: boolean
  ): Promise<ActivityStream> => {
    setLoading(true);
    dispatch({ type: "open", payload: "isSendBtnDisabled" });
    const getEmailArray = (emailArray: To[]) => emailArray.filter((item) => item !== undefined).map((value: To) => value.id || value.label);
    let toastOptions: ToastOptions = { open: true, severity: "success", message: t("toastMessages.emailSend.success", { ns: "activities" }) };
    const transactionSelected = _.uniq([...inboxAttachments, ...trackActivityTransactions], "transaction_id");
    const contentBody = body?.replace("[View Profile Button]", "{View Profile Button}") ?? body;
    const requestBody = {
      to: getEmailArray(to),
      cc: getEmailArray(cc),
      bcc: getEmailArray(bcc),
      subject: title,
      content: contentBody,
      email_action: EmailAction.NEW,
      workspace_id: selectedWorkspace?.id || FallbackTypes.Id,
      connection_company_id: customerId ?? "",
      content_type: ActivityFeedMethods.evaluateContentVariantPayload(newActivityType ?? "", needTemplateCodeAsPayload ? templateName : ""),
      attachments: attachmentIds.filter((item) => typeof item === "number"),
      activity_transactions: transactionSelected.filter(
        (item) => attachmentIds.indexOf(item.transaction_id) !== DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE
      ),
      attach_pdf: Utils.isThereAnyAttachment(attachmentIds, transactionSelected),
      activity_type: isNudgeActivity
        ? EmailAction.NUDGE
        : newActivityType && newActivityType == "phone_call"
        ? "call_log"
        : newActivityType && (newActivityType === "share_ar_profile" || newActivityType === "share_ap_profile")
        ? "profile_share"
        : newActivityType,
      primary_connection_id: customerId ? customerId : "",
    };

    return new Promise((resolve) => {
      emailsClientV2
        .post(requestBody)
        .then(async (emailResponse: ActivityStream) => {
          //Only call Automation API when email is sent successfully and Template is selected
          if (templateName && emailResponse.success) {
            let reqBody = {
              to_time: toTime,
              automation_type: "email_templates",
              automation_sub_type: templateName,
              resource_type: "Activity::Email",
              resource_id: emailResponse.data.id,
            } as AutomateTimeModel;
            if (fromTime != DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
              reqBody = { ...{ from_time: fromTime }, ...reqBody };
            }
            setToTime(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
            automationClientV2.post(selectedWorkspace?.id || FallbackTypes.Id, reqBody);
          }

          TrackingUtils.trackFSData(
            to,
            "New Activity Stream",
            "New",
            isSendAndClose ?? false,
            allContactOptions,
            props.fromCustomers ? "Customers" : props.assigneeType ?? "",
            path,
            newActivityType ?? "",
            body
          );
          setNewActivity(false);
          setShowActivity(false);
          if (!disableSoftRefresh) {
            await refetchCacheData();
          }
          //Fetch latest contact (newly added) options to populate in activity pop up if user tries to reply on the same
          getContactsOptions();
          resolve(emailResponse ?? []);
        })
        .catch((err: AxiosError) => {
          toastOptions.severity = "error";
          if (err.response?.status === CONNECTION_STATUS.BAD_REQUEST_400.STATUS_CODE) {
            toastOptions = { ...toastOptions, severity: "error", message: t("toastMessages.emailSend.failed.statusCode400", { ns: "activities" }) };
          } else {
            const errorResponse = err.response as AxiosErrorResponseData;
            toastOptions = {
              ...toastOptions,
              message: errorResponse?.data?.messages?.errors[0] ?? t("toastMessages.emailSend.failed.otherError", { ns: "activities" }),
            };
          }
        })
        .finally(() => {
          setToastOptions(toastOptions);
          setLoading(false);
          dispatch({ type: "close", payload: "isSendBtnDisabled" });
        });
    });
  };

  const handleSendReply = async (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    attachmentIds: AttachmentIds,
    inboxAttachments: TransactionItemType[],
    disableSoftRefresh?: boolean,
    isSendAndClose?: boolean
  ): Promise<ActivityStream> => {
    const getEmailArray = (emailArray: To[]) => emailArray.filter((item) => item !== undefined).map((value: To) => value.id || value.label);
    let toastOptions: ToastOptions = { open: true, severity: "success", message: t("toastMessages.emailSend.success", { ns: "activities" }) };
    setLoading(true);

    const requestBody = {
      to: getEmailArray(to),
      cc: getEmailArray(cc),
      bcc: getEmailArray(bcc),
      subject: title,
      content: body,
      email_action: EmailAction.REPLY,
      workspace_id: selectedWorkspace?.id || FallbackTypes.Id,
      connection_company_id: customerId ?? "",
      content_type: ActivityFeedMethods.evaluateContentVariantPayload(newActivityType ?? "", needTemplateCodeAsPayload ? templateName : ""),
      attachments: attachmentIds.filter((item) => typeof item === "number"),
      activity_transactions: inboxAttachments,
      attach_pdf: Utils.isThereAnyAttachment(attachmentIds, inboxAttachments),
      activity_type: isNudgeActivity
        ? EmailAction.NUDGE
        : newActivityType && newActivityType == "phone_call"
        ? "call_log"
        : newActivityType && (newActivityType === "share_ar_profile" || newActivityType === "share_ap_profile")
        ? "profile_share"
        : newActivityType,
      primary_connection_id: customerId ? customerId : "",
    } as ActivityItemEmailModel;

    return new Promise((resolve) => {
      emailsClientV2
        .postActivities(selectedWorkspace?.id || FallbackTypes.Id, item.id, requestBody)
        .then(async (emailResponse: ActivityStream) => {
          //Only call Automation API when email is sent successfully and Template is selected
          if (templateName && emailResponse.success) {
            let reqBody = {
              to_time: toTime,
              automation_type: "email_templates",
              automation_sub_type: templateName,
              resource_type: "Activity::Email",
              resource_id: emailResponse.data.id,
            } as AutomateTimeModel;
            if (fromTime != DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
              reqBody = { ...{ from_time: fromTime }, ...reqBody };
            }
            setToTime(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
            automationClientV2.post(selectedWorkspace?.id || FallbackTypes.Id, reqBody);
          }

          TrackingUtils.trackFSData(
            to,
            BTN_NEW_ACTIVITY,
            "Reply",
            isSendAndClose ?? false,
            allContactOptions,
            props.fromCustomers ? "Customers" : props.assigneeType ?? "",
            path,
            newActivityType ?? "",
            body
          );
          setNewActivity(false);
          setShowActivity(false);
          if (!disableSoftRefresh) {
            await refetchCacheData();
          }
          //Fetch latest contact (newly added) options to populate in activity pop up if user tries to reply on the same
          getContactsOptions();
          resolve(emailResponse ?? []);
        })
        .catch((err: AxiosError) => {
          toastOptions.severity = "error";
          if (err.response?.status === CONNECTION_STATUS.BAD_REQUEST_400.STATUS_CODE) {
            toastOptions = { ...toastOptions, severity: "error", message: t("toastMessages.emailSend.failed.statusCode400", { ns: "activities" }) };
          } else {
            const errorResponse = err.response as AxiosErrorResponseData;
            toastOptions = {
              ...toastOptions,
              message: errorResponse?.data?.messages?.errors[0] ?? t("toastMessages.emailSend.failed.otherError", { ns: "activities" }),
            };
          }
        })
        .finally(() => {
          setToastOptions(toastOptions);
          setLoading(false);
        });
    });
  };

  const handleSendAndMarkClosed = async (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    attachmentIds: AttachmentIds,
    inboxAttachments: TransactionItemType[]
  ) => {
    const sendEmailResponse = await handleSend(to, cc, bcc, title, body, attachmentIds, inboxAttachments, true, true);
    const bulkActivitiesCount = selectedRowsCount || activityIds.length;

    if (sendEmailResponse.success) {
      let toastOptions: ToastOptions = {
        open: true,
        severity: "success",
        message: t("toastMessages.emailSendAndClosed.success", { ns: "activities" }),
      };
      let response = {} as APIResponse;

      try {
        response = await handleClose(sendEmailResponse.data.activity_stream.id, "");
      } catch (e: unknown) {
        response.success = false;
      } finally {
        if (!response.success) {
          toastOptions = {
            ...toastOptions,
            severity: "error",
            message:
              activityIds?.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
                ? t("toastMessages.emailSendAndClosed.failed", { ns: "activities" })
                : t("toastMessages.emailSendAndClosed.bulkFailed", { activitesCount: bulkActivitiesCount, ns: "activities" }),
          };
        }
        await refetchCacheData();
        setToastOptions(toastOptions);
      }
    }
  };

  const handleMarkAsRead = async (ids: string[], row: any) => {
    const isActivityUnread = row ? row?.read : isMarkAsUnread;
    const bulkActivitiesCount = selectedRowsCount || ids.length;
    const markAsReadSuccessToast =
      bulkActivitiesCount > DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
        ? t("toastMessages.markAsRead.bulkSuccess", { activitesCount: bulkActivitiesCount, ns: "activities" })
        : t("toastMessages.markAsRead.success", { activitesCount: bulkActivitiesCount, ns: "activities" });
    const markAsUnreadSuccessToast =
      bulkActivitiesCount > DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
        ? t("toastMessages.markAsUnRead.bulkSuccess", { activitesCount: bulkActivitiesCount, ns: "activities" })
        : t("toastMessages.markAsUnRead.success", { activitesCount: bulkActivitiesCount, ns: "activities" });
    const markAsReadFailedToast =
      bulkActivitiesCount > DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
        ? t("toastMessages.markAsRead.bulkFailed", { activitesCount: bulkActivitiesCount, ns: "activities" })
        : t("toastMessages.markAsRead.failed", { activitesCount: bulkActivitiesCount, ns: "activities" });
    const markAsUnreadFailedToast =
      bulkActivitiesCount > DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
        ? t("toastMessages.markAsUnRead.bulkFailed", { activitesCount: bulkActivitiesCount, ns: "activities" })
        : t("toastMessages.markAsUnRead.failed", { activitesCount: bulkActivitiesCount, ns: "activities" });

    let toastOptions: ToastOptions = {
      open: true,
      severity: "success",
      message: isActivityUnread ? markAsUnreadSuccessToast : markAsReadSuccessToast,
    };
    let response = {} as APIResponse;

    try {
      let reqBody: ActivitiesStreamUpdateBody = {
        read: !(row ? row?.read : isMarkAsUnread),
      };
      reqBody = attachSelectedAllPayload(reqBody);
      const isBulk = ids instanceof Array;
      const filter = [connectionIdquery, deselectedRowsFilter].filter(Boolean).join("&");

      response =
        isBulk && ids?.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
          ? await activitiesClientV2.patch(selectedWorkspace?.id || FallbackTypes.Id, ids[0], ActivityActionTypes.Read, reqBody)
          : await activitiesClientV2.patch(
              selectedWorkspace?.id || FallbackTypes.Id,
              "",
              ActivityActionTypes.Read,
              { activity_stream_ids: ids, ...reqBody },
              true,
              filter
            );
    } catch {
      response.success = false;
    } finally {
      if (!response.success) {
        toastOptions = {
          ...toastOptions,
          severity: "error",
          message: isActivityUnread ? markAsUnreadFailedToast : markAsReadFailedToast,
        };
      }
      setToastOptions(toastOptions);
      if (response.success) refetchCacheData();
    }
  };

  const fetchCompanyContacts = async (customerId: string) => {
    setCompanyContactOptions(await getContactsOptions(customerId));
  };

  useEffect(() => {
    newActivityUtils.fetchCompanyInfo(userStatus.account_company_id as string).then((companyData) => setCompanyData(companyData));
    if (customerId) {
      fetchCompanyContacts(customerId);
    }
  }, []);

  const handleSenderClose = () => {
    setIsSenderOpen(false);
  };

  // base client integration
  const spamAndFraudHandler = async (ids: string[], spamStatus: boolean, actionType: ActivityActionTypes, markSender = false) => {
    let response: APIResponse;
    let reqBody: ActivitiesStreamUpdateBody = { mark_sender: markSender };
    if (actionType === ActivityActionTypes.Spam) {
      reqBody = { ...reqBody, spam: spamStatus };
    }

    reqBody = attachSelectedAllPayload(reqBody);
    const isBulk = ids instanceof Array;
    const filter = [connectionIdquery, deselectedRowsFilter].filter(Boolean).join("&");
    try {
      response =
        isBulk && ids.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
          ? await activitiesClientV2.patch(selectedWorkspace?.id || FallbackTypes.Id, ids[0], actionType, reqBody)
          : await activitiesClientV2.patch(
              selectedWorkspace?.id || FallbackTypes.Id,
              "",
              actionType,
              { activity_stream_ids: ids, ...reqBody },
              true,
              filter
            );
    } catch (error) {
      response = (error as AxiosError).response?.data;
    }
    return response;
  };

  // mark activity as spam action event
  const onClickSpam = async (ids: string[], spamStatus: boolean, actionType: ActivityActionTypes, markSender = false) => {
    const bulkActivitiesCount = selectedRowsCount || ids.length;
    const spamSuccessToast =
      bulkActivitiesCount > DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
        ? t("toastMessages.markAsSpam.bulkSuccess", { ns: "activities" })
        : t("toastMessages.markAsSpam.success", { ns: "activities" });
    const notSpamSuccessToast =
      bulkActivitiesCount > DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
        ? t("toastMessages.markAsNotSpam.bulkSuccess", { ns: "activities" })
        : t("toastMessages.markAsNotSpam.success", { ns: "activities" });
    const spamFailedToast =
      bulkActivitiesCount > DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
        ? t("toastMessages.markAsSpam.bulkFailed", { ns: "activities" })
        : t("toastMessages.markAsSpam.failed", { ns: "activities" });
    const notSpamFailedToast =
      bulkActivitiesCount > DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
        ? t("toastMessages.markAsNotSpam.bulkFailed", { ns: "activities" })
        : t("toastMessages.markAsNotSpam.failed", { ns: "activities" });
    let toastOptions: ToastOptions = {
      open: true,
      severity: "success",
      message: spamStatus ? spamSuccessToast : notSpamSuccessToast,
    };
    const res = await spamAndFraudHandler(ids, spamStatus, actionType, markSender);
    if (!res.success) {
      toastOptions = {
        ...toastOptions,
        severity: "error",
        message: spamStatus ? spamFailedToast : notSpamFailedToast,
      };
    }
    //Only call Pendo when marking activity as spam
    if (!spamStatus) {
      TrackingUtils.trackUserActivities(ACTIVITY_SPAM_PENDO, res.success);
    }
    setToastOptions(toastOptions);
    if (res.success) {
      refetchCacheData();
    }
  };

  // mark activitiy's connection as spam or fraud action event
  const onClickMarkSender = async (ids: string[], actionType: ActivityActionTypes) => {
    const toastOptions: ToastOptions = { open: true, severity: "success", message: "" };
    const response = await spamAndFraudHandler(ids, true, actionType, true);
    const bulkActivitiesCount = selectedRowsCount || ids.length;
    const senderSpamSuccessToast = t("toastMessages.markSendAsSpam.success", { ns: "activities" });
    const senderFraudSuccessToast = t("toastMessages.markSendAsFraud.success", { ns: "activities" });
    const senderSpamFailedToast =
      bulkActivitiesCount > DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
        ? t("toastMessages.markSendAsSpam.bulkFailed", { ns: "activities" })
        : t("toastMessages.markSendAsSpam.failed", { ns: "activities" });
    const senderFraudFailedToast =
      bulkActivitiesCount > DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
        ? t("toastMessages.markSendAsFraud.bulkFailed", { ns: "activities" })
        : t("toastMessages.markSendAsFraud.failed", { ns: "activities" });

    handleSenderClose();
    setShowModal(false);
    if (response.success) {
      setToastOptions({
        ...toastOptions,
        message: connectionSpam ? senderSpamSuccessToast : senderFraudSuccessToast,
      });
      refetchCacheData();
    }

    if (!response.success) {
      setToastOptions({
        ...toastOptions,
        severity: "error",
        message: connectionSpam ? senderSpamFailedToast : senderFraudFailedToast,
      });
    }
  };

  const handleUnSnooze = async (ids: string[]) => {
    const toastOptions: ToastOptions = { open: true, severity: "info", message: "", icon: <SnoozedToast /> };
    let response = {} as APIResponse;
    const bulkActivitiesCount = selectedRowsCount || ids.length;

    try {
      let reqBody: ActivitiesStreamUpdateBody = {
        status: ActivityStatus.Active.toLocaleLowerCase() as Status,
      };
      reqBody = attachSelectedAllPayload(reqBody);
      const isBulk = ids instanceof Array;
      const filter = [connectionIdquery, deselectedRowsFilter].filter(Boolean).join("&");

      response =
        isBulk && ids?.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
          ? await activitiesClientV2.patch(selectedWorkspace?.id || FallbackTypes.Id, ids[0], ActivityActionTypes.Status, reqBody)
          : await activitiesClientV2.patch(
              selectedWorkspace?.id || FallbackTypes.Id,
              "",
              ActivityActionTypes.Status,
              { activity_stream_ids: ids, ...reqBody },
              true,
              filter
            );

      if (response.success) {
        setToastOptions({
          ...toastOptions,
          message:
            bulkActivitiesCount > NUMERIC_VALUES.CONSTANT_ONE
              ? t("toastMessages.markUnsnooze.bulkSuccess", { activitesCount: bulkActivitiesCount, ns: "activities" })
              : t("toastMessages.markUnsnooze.success", { activitesCount: bulkActivitiesCount, ns: "activities" }),
        });
        refetchCacheData();
      }
    } catch (error) {
      response.success = false;
      setToastOptions({
        ...toastOptions,
        severity: "error",
        message: t("toastMessages.markUnsnooze.failed", { ns: "activities" }),
      });
      console.log(error);
    }
  };

  const handleTooltipClose = () => {
    setIsSnoozeOpen(false);
  };

  const handleTooltipToggle = (tooltipToggle: boolean) => {
    setIsSnoozeOpen(!tooltipToggle);
  };

  const generateMenuList = (menuList: Array<any>, ids: string[]) => {
    const toastOptions: ToastOptions = { open: true, severity: "info", message: "", icon: <SnoozedToast /> };
    const bulkActivitiesCount = selectedRowsCount || ids.length;
    return menuList.map((item, index) => {
      return (
        <MenuItem
          key={index}
          onClick={async (e) => {
            e.stopPropagation();
            let response = {} as APIResponse;
            const now = Math.floor(Date.now() / NUMERIC_VALUES.CONSTANT_THOUSAND);
            try {
              let reqBody: ActivitiesStreamUpdateBody = {
                status: ActivityStatus.Snoozed.toLocaleLowerCase() as Status,
                snooze_until: now + item.valueInEpoch,
              };
              reqBody = attachSelectedAllPayload(reqBody);
              const isBulk = ids instanceof Array;
              const filter = [connectionIdquery, deselectedRowsFilter].filter(Boolean).join("&");

              response =
                isBulk && ids?.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
                  ? await activitiesClientV2.patch(selectedWorkspace?.id || FallbackTypes.Id, ids[0], ActivityActionTypes.Status, reqBody)
                  : await activitiesClientV2.patch(
                      selectedWorkspace?.id || FallbackTypes.Id,
                      "",
                      ActivityActionTypes.Status,
                      { activity_stream_ids: ids, ...reqBody },
                      true,
                      filter
                    );

              if (response.success) {
                setToastOptions({
                  ...toastOptions,
                  message:
                    bulkActivitiesCount > NUMERIC_VALUES.CONSTANT_ONE
                      ? t("toastMessages.markSnooze.bulkSuccess", { activitesCount: bulkActivitiesCount, timeDuration: item.id, ns: "activities" })
                      : t("toastMessages.markSnooze.success", { timeDuration: item.id, ns: "activities" }),
                });
                refetchCacheData();
              }
            } catch (error) {
              response.success = false;
              setToastOptions({
                ...toastOptions,
                severity: "error",
                message: t("toastMessages.markSnooze.failed", { ns: "activities" }),
              });
              console.log(error);
            }
            handleTooltipClose();
          }}
          id={item.id}
        >
          {item.value}
        </MenuItem>
      );
    });
  };

  const classes = useStyles();

  const handleSnooze = () => {
    return (
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          title={generateMenuList(getSnoozeList(), activityIds)}
          classes={{ tooltip: classes.tooltip }}
          placement="bottom-start"
          open={isSnoozeOpen}
          disableHoverListener
        >
          <div onClick={() => handleTooltipToggle(isSnoozeOpen)} className="snooze-icon">
            <Snoozed />
          </div>
        </Tooltip>
      </ClickAwayListener>
    );
  };

  const handleSenderToggle = (tooltipToggle: boolean) => {
    setIsSenderOpen(!tooltipToggle);
  };

  const openModal = (clickedItem: string) => {
    setConnectionSpam(clickedItem === "Spam");
    setShowModal(true);
  };

  const generateMenuListForSender = (menuList: { id: string; displayName: string }[]) => {
    return menuList.map((item, index) => {
      return (
        <MenuItem
          key={index}
          onClick={() => {
            openModal(item.id);
          }}
          id={item.id}
        >
          {item.displayName}
        </MenuItem>
      );
    });
  };

  const generateSenderSpamFraudList = () => {
    return (
      <ClickAwayListener onClickAway={handleSenderClose}>
        <Tooltip
          title={generateMenuListForSender([
            { id: "Spam", displayName: t("table.headerCaption.ellipsesAction.markSenderAsSpam", { ns: "activities" }) },
            { id: "Fraud", displayName: t("table.headerCaption.ellipsesAction.markSenderAsFraud", { ns: "activities" }) },
          ])}
          classes={{ tooltip: classes.tooltip }}
          placement="bottom-end"
          open={isSenderOpen}
          disableHoverListener
        >
          <div onClick={() => handleSenderToggle(isSenderOpen)} className="sender-icon">
            <Ellipses />
          </div>
        </Tooltip>
      </ClickAwayListener>
    );
  };

  const handleUnSnoozeIcon = () => t("table.headerCaption.actionButtons.unSnooze", { ns: "activities" });
  const handleNotSpamIcon = () => t("table.headerCaption.actionButtons.notSpam", { ns: "activities" });

  const rowSelectButtons: UserAction[] = [
    ...(activityStatus === "closed" || activityStatus === "snoozed"
      ? []
      : [
          {
            tooltipLabel: t("table.rowsActions.tooltipMessage.markAsComplete", { ns: "activities" }),
            icon: <MarkAsCompleteSecondaryButtonOutline />,
            disabled: activityActionInProgress,
            loading: (activityPerformInProgress as ActivityActionPerformStatus).Close,
            callback: (ids: string[]) => {
              if (ids instanceof Array) {
                setActivityIds(ids);
              } else {
                setActivityIds([ids]);
              }
              const filter = [connectionIdquery, deselectedRowsFilter].filter(Boolean).join("&");
              activityCloseAction(ids instanceof Array ? ids : [ids], refetchCacheData, filter, selectedRowsCount);
            },
            visible: {
              onHeader: true,
              onHover: true,
            },
          } as UserAction,
        ]),

    ...(activityStatus === "snoozed"
      ? [
          {
            tooltipLabel: t("table.rowsActions.tooltipMessage.unSnooze", { ns: "activities" }),
            children: handleUnSnoozeIcon(),
            disabled: activityActionInProgress,
            loading: (activityPerformInProgress as ActivityActionPerformStatus).Snooze,
            callback: (ids: string[]) => {
              if (ids instanceof Array) {
                setActivityIds(ids);
              } else {
                setActivityIds([ids]);
              }
              handleUnSnooze(ids instanceof Array ? ids : [ids]);
            },
            visible: {
              onHeader: true,
              onHover: false,
            },
          } as UserAction,
        ]
      : []),
    ...[
      {
        icon: <AssignTo />,
        className: "btn-icon-only",
        tooltipLabel: t("table.rowsActions.tooltipMessage.assign", { ns: "activities" }),
        disabled: activityActionInProgress,
        type: "assign-to",
        loading: (activityPerformInProgress as ActivityActionPerformStatus).Assign,
        callback: (ids: string[], row: any) => {
          if (ids instanceof Array) {
            setActivityIds(ids);
          } else {
            setActivityIds([ids]);
            selectionConfig?.onClickTableRow(ids as string, []);
          }
          setReassignDrawerOpen(true);
          handleTooltipClose();
          setCurrentAssignee(row?.assignee);
        },
        visible: {
          onHeader: true,
          onHover: true,
        },
      },
      {
        icon: <MarkAsUnread />,
        className: "btn-icon-only",
        tooltipLabel: isMarkAsUnread
          ? t("table.rowsActions.tooltipMessage.markAsUnread", { ns: "activities" })
          : t("table.rowsActions.tooltipMessage.markAsRead", { ns: "activities" }),
        type: "read-unread-toggle",
        disabled: activityActionInProgress,
        loading: (activityPerformInProgress as ActivityActionPerformStatus).Read,
        callback: (ids: string[], row: any) => {
          if (ids instanceof Array) {
            handleMarkAsRead(ids, row);
          } else {
            handleMarkAsRead([ids], row);
          }
        },
        visible: {
          onHeader: true,
          onHover: true,
        },
      },
      ...(path.includes("/waitingForResponse")
        ? []
        : [
            {
              icon: <MoveTo />,
              className: "btn-icon-only",
              tooltipLabel: t("table.rowsActions.tooltipMessage.move", { ns: "activities" }),
              disabled: activityActionInProgress,
              loading: (activityPerformInProgress as ActivityActionPerformStatus).Move,
              callback: (ids: string[]) => {
                if (ids instanceof Array) {
                  setActivityIds(ids);
                } else {
                  setActivityIds([ids]);
                  selectionConfig?.onClickTableRow(ids as string, []);
                }
                setMoveDrawerOpen(true);
                handleTooltipClose();
              },
              visible: {
                onHeader: true,
                onHover: true,
              },
            },
          ]),
      ...(activityStatus === "snoozed"
        ? []
        : [
            {
              icon: handleSnooze(),
              className: "btn-icon-only",
              tooltipLabel: t("table.rowsActions.tooltipMessage.snooze", { ns: "activities" }),
              disabled: activityActionInProgress,
              loading: (activityPerformInProgress as ActivityActionPerformStatus).Snooze,
              callback: (ids) => {
                if (ids instanceof Array) {
                  setActivityIds(ids);
                } else {
                  setActivityIds([ids]);
                }
              },
              visible: {
                onHeader: true,
                onHover: true,
              },
            } as UserAction,
          ]),
      ...(path.includes("/waitingForResponse")
        ? []
        : [
            {
              icon: <Spam />,
              className: "btn-icon-only",
              tooltipLabel: t("table.rowsActions.tooltipMessage.spam", { ns: "activities" }),
              disabled: activityActionInProgress,
              loading: (activityPerformInProgress as ActivityActionPerformStatus).Spam,
              callback: (ids: string[]) => {
                if (ids instanceof Array) {
                  setActivityIds(ids);
                } else {
                  setActivityIds([ids]);
                }
                onClickSpam(ids instanceof Array ? ids : [ids], true, ActivityActionTypes.Spam, false);
              },
              visible: {
                onHeader: true,
                onHover: true,
              },
            },
          ]),
      {
        icon: generateSenderSpamFraudList(),
        className: "btn-icon-only",
        disabled: activityActionInProgress,
        callback: (ids: string[]) => {
          if (ids instanceof Array) {
            setActivityIds(ids);
          } else {
            setActivityIds([ids]);
          }
        },
        visible: {
          onHeader: true,
          onHover: false,
        },
      } as UserAction,
    ],
  ] as UserAction[];

  const spamTableButtons: UserAction[] = [
    {
      icon: <Spam />,
      tooltipLabel: t("table.rowsActions.tooltipMessage.notSpam", { ns: "activities" }) as string,
      disabled: activityActionInProgress,
      children: handleNotSpamIcon(),
      loading: (activityPerformInProgress as ActivityActionPerformStatus).Spam,
      callback: (ids: string[] | string) => {
        if (ids instanceof Array) {
          onClickSpam(ids, false, ActivityActionTypes.Spam, false);
        } else {
          onClickSpam([ids], false, ActivityActionTypes.Spam, false);
        }
      },
      visible: {
        onHeader: true,
        onHover: false,
      },
    },
  ];

  const spam = path.toLowerCase().includes("/spam") ? true : false;
  /**
   * Only show contacts relevant to the connection
   */
  let filteredContactOptions = allContactOptions;

  if (customerId) {
    filteredContactOptions = enableTypeAheadSuggestions ? [...companyContactOptions, ...allContactOptions] : companyContactOptions;
  }

  const typeAheadAction = (val: string) => {
    if (val?.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      setEnableTypeAheadSuggestions(true);
    } else {
      setEnableTypeAheadSuggestions(false);
    }
  };

  /**
   * Function which return the Template instance based on workspace selected.
   *
   * @param {String} Customer The customer name which need to show in mail body.
   * @returns {Object} return the template instance.
   */
  const getTemplateInstance = (customer: string | null) => {
    const view: viewType = pathType === WorkspaceType.AP ? viewType.AP_CONNECTION_VENDOR_ACTIVITY : viewType.AR_CONNECTION_CUSTOMER_ACTIVITIES;
    return templateFactory.getTemplateDefinitionsByView(
      view,
      { customer: customer, companyName: loginUserCompany?.companyName ?? "", vendor: customer },
      availableLanguagesForTemplates
    );
  };

  /**
   * Function which help's to give template list for the subject drop down
   * based on view type.
   *
   * @param {Object} row The selected row record from table
   * @returns {TemplateDefinitionProps[]} The template definition array of objects.
   */
  const getTemplateDefinitionsByView = (row: Row<TableData>): TemplateDefinitionProps[] => {
    const {
      original: { primary_connection },
    } = row;
    return getTemplateInstance(primary_connection.name);
  };

  useEffect(() => {
    if (newActivityType === "email" && (path.includes("vendors") || path.includes("customers"))) {
      setSupportedTemplateList(getTemplateInstance((company as CustomerDetailsModel).name));
    }
    if (
      (newActivityType === "share_ap_profile" || newActivityType === "share_ar_profile") &&
      (path.includes("vendors") || path.includes("customers") || path.includes("activities"))
    ) {
      const templateObj: Array<TemplateDefinitionProps> = templateFactory.getTemplateDefinitionsByView(
        pathType === WorkspaceType.AP ? viewType.AP_SHARE_PROFILE_ACTIVITY : viewType.AR_SHARE_PROFILE_ACTIVITY,
        { companyName: loginUserCompany?.companyName ?? "" },
        availableLanguagesForTemplates
      );

      setSupportedTemplateList(templateObj);
      setDefaultTemplateId(pathType === WorkspaceType.AP ? TemplateTypes.AP_SHARE_PROFILE : TemplateTypes.AR_SHARE_PROFILE);
    }
    // rest the selected template code
    setTemplateName("");
  }, [newActivityType, loginUserCompany?.companyName, selectedWorkspace?.workspace_type]);

  const getCompanyData = async (): Promise<CompanyModel> => {
    if (!inboxCompany) {
      const companyModel: CompanyModel = await companiesClient.getCompany(userStatus.account_company_id as string, "Attachments");
      setInboxCompany(companyModel);
      return companyModel;
    }
    return inboxCompany;
  };

  const handleTemplateAttachments = async (templateObj: any, setFileCallback: FunctionCall) => {
    const comapnyData = await getCompanyData();
    const allW9Documents: AttachmentModel[] = (comapnyData?.attachments as AttachmentModel[]).filter(
      (attachment: AttachmentModel) => attachment.attachmentType === "W-9"
    );
    const allDcoumentsMeta = allW9Documents
      ? allW9Documents.map((document: AttachmentModel) => ({
          name: `${document.fileName ?? "N/A"}`,
          id: document.attachmentId,
        }))
      : [];
    setTrackActivityTransactions([
      ...allDcoumentsMeta.map((document) => ({ transaction_id: document.id, transaction_type: AttachmentType.DOCUMENT })),
    ]);
    const templateDataMapper = prepareTemplateDataObject(templateObj, null, allDcoumentsMeta, null);
    handleTemplateAttachment(templateObj, setFileCallback, templateDataMapper);
  };

  /**
   * Function which convert the template string to editor content state.
   *
   * @param {string} templateID The templateID selected by user from the subject drop down.
   * @param {EditorState} EditorState The setEditorState will update the email body.
   */
  const prepareActivityBodyByTemplateID = (
    templateID: string | null,
    setEditorState: React.Dispatch<React.SetStateAction<string>>,
    lang: string,
    setFileCallback?: FunctionCall
  ) => {
    if (templateID) {
      setTemplateName(templateID);

      let customerName = "{customer}";
      if (!_.isEmpty(item)) {
        const {
          original: { primary_connection = {} },
        } = item;

        customerName = primary_connection?.name;
      } else {
        customerName = (company as CustomerDetailsModel)?.name ?? "{customer}";
      }

      const templateObj = templateData.get(selectedWorkspace?.id)?.get(lang)?.get(templateID);
      setFromTime(templateObj.getFromTime);
      setToTime(templateObj.getToTime);
      setEditorState(
        templateObj.parseTemplate({
          customer: customerName,
          vendor: customerName,
          workspaceCompanyName: companyData?.companyName || "{AR Company Name}",
          emailAddress: companyData?.emailAddress || "",
          companyName: companyData?.companyName || "",
          phone: companyData?.phoneNumber || "",
          signature: signature?.email_signature || "",
          view_profile_button: "<span>[View Profile Button]</span>",
          contactName: primaryContact[0]?.label || customerName,
        })
      );
      if (templateObj.templateAttachment) {
        handleTemplateAttachments(templateObj, setFileCallback as FileCallback);
      }
    }
  };

  const dropdownOptionsForCustomers = React.useMemo(() => {
    const returnOpt = [
      { activityType: "Email", displayName: t("addNewActivity.dropdownList.email", { ns: "activities" }), icon: <Email /> },
      { activityType: "Note", displayName: t("addNewActivity.dropdownList.note", { ns: "activities" }), icon: <NoteFill /> },
      { activityType: "Phone Call", displayName: t("addNewActivity.dropdownList.phoneCall", { ns: "activities" }), icon: <Phone /> },
    ];
    if (isProfileManagementEnabled) {
      returnOpt.push(
        pathType === WorkspaceType.AP
          ? {
              activityType: "Share AP Profile",
              displayName: t("addNewActivity.dropdownList.shareApProfile", { ns: "activities" }),
              icon: <UserPic />,
            }
          : {
              activityType: "Share AR Profile",
              displayName: t("addNewActivity.dropdownList.shareArProfile", { ns: "activities" }),
              icon: <UserPic />,
            }
      );
    }
    return returnOpt;
  }, [selectedWorkspace, isProfileManagementEnabled]);

  const filteredCompanyContactOptions = [...companyContactOptions, ...allContactOptions];
  const hideNewActivityDropdownTypes = ["/spam"];
  const showNewActivityDropdown = hideNewActivityDropdownTypes.some((type) => path.toLowerCase().includes(type));

  const headerButtons: HeaderButton[] = showNewActivityDropdown
    ? []
    : [
        {
          component: (
            <NewActivityDropdown
              buttonName={t("addNewActivity.button.label", { ns: "activities" }) as string}
              origin={"index"}
              handleReplySend={handleSend}
              handleReplySendClose={handleSendAndMarkClosed}
              addActivityDropdown={
                path.includes("customers") || path.includes("vendors")
                  ? dropdownOptionsForCustomers
                  : [
                      { activityType: "Email", displayName: t("addNewActivity.dropdownList.email", { ns: "activities" }), icon: <Email /> },
                      {
                        activityType: "Phone Call",
                        displayName: t("addNewActivity.dropdownList.phoneCall", { ns: "activities" }),
                        icon: <Phone />,
                      },
                    ]
              }
              isTemplateSupport={newActivityType === "email" || newActivityType === "share_ap_profile" || newActivityType === "share_ar_profile"}
              defaultTemplateId={
                (newActivityType === "share_ap_profile" || newActivityType === "share_ar_profile") &&
                (path.includes("vendors") || path.includes("customers"))
                  ? defaultTemplateId
                  : undefined
              }
              defaultTo={
                (newActivityType === "share_ap_profile" || newActivityType === "share_ar_profile") &&
                (path.includes("vendors") || path.includes("customers"))
                  ? primaryContact
                  : undefined
              }
              supportedTemplateList={supportedTemplateList}
              prepareActivityBodyByTemplateID={prepareActivityBodyByTemplateID}
              replyToOptions={filteredContactOptions}
              newActivityType={newActivityType}
              setNewActivityType={setNewActivityType}
              showAddActivity={newActivity}
              setShowAddActivity={setNewActivity}
              typeAheadAction={typeAheadAction}
              resetFunction={() => {
                setEnableTypeAheadSuggestions(false);
              }}
              fromTime={fromTime}
              toTime={toTime}
              setToTime={setToTime}
              disableSecondaryAction={newActivityType === "share_ap_profile" || newActivityType === "share_ar_profile"}
              enableKeyboardShortcuts={true}
              isSendButtonDisabled={visibility.isSendBtnDisabled}
            />
          ),
        },
      ];

  const createTo = (contact: ContactItem) => {
    return {
      id: contact?.name ? contact?.email_address ?? "" : "",
      label: contact?.name || contact?.email_address || "",
    } as To;
  };

  const filterValidContacts = (contacts: ContactItem[]) => {
    return contacts
      .filter((contact: ContactItem) => {
        return contact.email_address !== "";
      })
      .map((contact: ContactItem) => {
        return createTo(contact);
      });
  };

  /**
   * @function setContactsToNudge
   * A helper function to set contact options to perform nudge on a selected activity stream.
   * This function will fetch required activities belonging to the activity stream by using its id.
   * @param activityStreamId
   */
  const setContactsToNudge = async (activityStreamId: string) => {
    const activities = await activitiesClientV2.getStream(selectedWorkspace?.id, activityStreamId);
    const latestActivity = (activities?.data?.length ? activities?.data[0] : {}) as ActivityItemModel;
    setNudgeTo(latestActivity?.to ? filterValidContacts(latestActivity?.to) : []);
    setNudgeCc(latestActivity?.cc ? filterValidContacts(latestActivity?.cc) : []);
    setNudgeBcc(latestActivity?.bcc ? filterValidContacts(latestActivity?.bcc) : []);
    setIsActivityPopupLoading(false);
  };

  /**
   * Ellipse handler which open the new activity dialog.
   *
   * @param {TableData} row The current selected row of the table.
   * @param {boolean} requireTemplates Flag to check if templates are required or not. This flag is set on hover bar nudge activity creation
   */

  //eslint-disable-next-line
  const handleNewActivity = (row: Row<TableData>, requireTemplates = true) => {
    setSelectedItem(row);
    setSupportedTemplateList(getTemplateDefinitionsByView(row));
    setIsTemplateSupport(requireTemplates);
    setIsReply(true);
    setIsActivityPopupLoading(true);
    if (!requireTemplates) {
      setContactsToNudge(row?.original?.id);
      setOriginalSubjectLine(row?.original?.subject);
      setIsNudgeActivity(true);
    }
    setNewActivityType("email");
    setShowActivity(true);
  };

  /**
   * @function closeAddActivity
   * A helper function to close Add activity popup and clear subscriptions.
   */
  const closeAddActivity = () => {
    setShowActivity(false);
    setIsNudgeActivity(false);
    setOriginalSubjectLine("");
    setIsReply(false);
    setNudgeTo([]);
    setNudgeCc([]);
    setNudgeBcc([]);
    setFromTime(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    setToTime(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    setEditorState("");
  };

  useEffect(() => {
    setViewAndStatus(url);
  }, [url]);

  const handleRowHover = () => {
    handleTooltipToggle(true);
  };

  useEffect(() => {
    setAllRowsSelected(selectionConfig?.isAllRecordsSelected || !!selectionConfig?.hasAllRecordsSelectedButtonClicked?.current);
  }, [selectionConfig?.isAllRecordsSelected]);

  // create deselected ids ransac query when user deselect any records after selecting all
  useEffect(() => {
    const { hasAllRecordsSelectedButtonClicked, isAllRecordsSelected, tableRecords } = selectionConfig;

    if (!hasAllRecordsSelectedButtonClicked?.current || isAllRecordsSelected || !tableRecords?.allRecords) {
      if (deselectedRowsFilter) {
        setDeselectedRowsFilter("");
      }
      return;
    }

    const deselectedIds = tableRecords?.allRecords?.filter((item) => !item?.isSelected)?.map((deselectedItem) => deselectedItem?.id);
    let filter = "";
    deselectedIds.forEach((id) => {
      filter = filter ? `${filter}&qa[uuid_not_in][]=${id}` : `qa[uuid_not_in][]=${id}`;
    });
    setDeselectedRowsFilter(filter);
  }, [selectionConfig?.tableRecords?.selectedRecordsIds]);

  const getPreviousSuggestedData = (recentSearchHistory: FilterDropdownSuggestionRef) => {
    const { filterSearchedText } = initialFilterConfigs ?? {};
    const { subject, assignee_name, platform_connection_company_name } = filterSearchedText ?? {};

    return {
      from:
        platform_connection_company_name?.value?.length >= NUMERIC_VALUES.CONSTANT_THREE
          ? recentSearchHistory?.platform_connection_company_name?.filter((item) =>
              initialFilterConfigs?.activeFilters?.platform_connection_company_name?.ids?.includes(item.id)
            )
          : recentSearchHistory?.platform_connection_company_name,
      subject:
        subject?.value?.length >= NUMERIC_VALUES.CONSTANT_THREE
          ? recentSearchHistory?.subject?.filter((item) => initialFilterConfigs?.activeFilters?.subject?.ids?.includes(item.id))
          : recentSearchHistory?.subject,
      assignee:
        assignee_name?.value?.length >= NUMERIC_VALUES.CONSTANT_THREE
          ? recentSearchHistory?.assignee_name?.filter((item) => initialFilterConfigs?.activeFilters?.assignee_name?.ids?.includes(item.id))
          : recentSearchHistory?.assignee_name,
    };
  };

  //it will return maximum of 5 required formated record for dropdown search suggestion or result data
  const getDropdownSearchFilterData = (activitiesTableData: any) => {
    const { filterSearchedText = {}, getSearchSuggestionHistory } = initialFilterConfigs || {};
    const { subject, assignee_name, platform_connection_company_name } = filterSearchedText;
    const recentActivitiesSearchedData = getSearchSuggestionHistory(
      selectedWorkspace.workspace_type_route?.toLocaleLowerCase() ?? WorkspaceType.AR,
      props.category ?? ""
    );
    const previousData = getPreviousSuggestedData(recentActivitiesSearchedData);

    /**
     * if user searching for result then return result data along with previous selected data
     * else return max of 5 recent searched data if that is exist
     */
    const fromData =
      !platform_connection_company_name?.value || platform_connection_company_name?.value.length < NUMERIC_VALUES.CONSTANT_THREE
        ? (previousData?.from ?? []).slice(NUMERIC_VALUES.CONSTANT_ZERO, NUMERIC_VALUES.CONSTANT_FIVE)
        : [...(previousData?.from ?? []), ...(activitiesTableData ?? [])];

    const subjectData =
      !subject?.value || subject?.value.length < NUMERIC_VALUES.CONSTANT_THREE
        ? (previousData?.subject ?? []).slice(NUMERIC_VALUES.CONSTANT_ZERO, NUMERIC_VALUES.CONSTANT_FIVE)
        : [...(previousData?.subject ?? []), ...(activitiesTableData ?? [])];

    const assigneeData =
      !assignee_name?.value || assignee_name?.value.length < NUMERIC_VALUES.CONSTANT_THREE
        ? (previousData.assignee ?? []).slice(NUMERIC_VALUES.CONSTANT_ZERO, NUMERIC_VALUES.CONSTANT_FIVE)
        : [...(previousData?.assignee ?? []), ...(activitiesTableData ?? [])];

    //formatting data for th dropdown suggestion list
    const fromDropDownList = fromData
      ?.filter(({ platform_connection_company_name, primaryText }) => platform_connection_company_name || primaryText)
      .map(({ id, emailAddress = "", primaryText, platform_connection_company_name, secondaryText = "" }) => ({
        id,
        primaryText: platform_connection_company_name ?? primaryText,
        isAttachemntRequired: false,
        selected: initialFilterConfigs?.activeFilters?.platform_connection_company_name?.ids?.includes(id) ?? false,
        secondaryText: emailAddress || secondaryText || "--",
        isAvatarRequired: true,
      }));

    const subjectDropDownList = subjectData
      ?.filter(({ subject, primaryText }) => subject || primaryText)
      .map(({ id, subject, attachments, primaryText, secondaryText }) => ({
        id,
        primaryText: subject ?? primaryText,
        isAttachemntRequired: true,
        selected: initialFilterConfigs?.activeFilters?.subject?.ids?.includes(id) ?? false,
        secondaryText: attachments?.map((item: { file_name: string }) => item.file_name) ?? secondaryText?.map((item: string) => item),
        isAvatarRequired: false,
      }));

    const assignedToDropDownList = assigneeData
      ?.filter(({ assignee_name, primaryText }) => assignee_name || primaryText)
      .map(({ id, assignee_name, assigneeEmail = "", primaryText, secondaryText = "" }) => ({
        id,
        primaryText: assignee_name ?? primaryText,
        isAttachemntRequired: false,
        selected: initialFilterConfigs?.activeFilters?.assignee_name?.ids?.includes(id) ?? false,
        secondaryText: assigneeEmail || secondaryText || "--",
        isAvatarRequired: true,
      }));

    /**
     * This code first creates a Map object by mapping each object in the original array to an array with two elements:
     * the key and the value. The Map constructor can then be called with the resulting array of key-value pairs to create
     * a new Map object. Finally, the Array.from method is used to convert the Map object back to an array, where the map
     * function is used to only return the values from the key-value pairs.
     */
    const uniqueAssignedToSuggestions = Array.from(new Map(assignedToDropDownList?.reverse()?.map((obj) => [obj.secondaryText, obj])))
      .reverse()
      .map(([, value]) => value);

    const uniqueSubjectSuggestions = Array.from(new Map(subjectDropDownList?.reverse().map((obj) => [obj.primaryText, obj])))
      .reverse()
      .map(([, value]) => value);

    const uniqueFromSuggestions = Array.from(new Map(fromDropDownList?.reverse().map((obj) => [obj.primaryText, obj])))
      .reverse()
      .map(([, value]) => value);

    return props.columns.map((column) => {
      switch (column.name) {
        case "From":
          return { ...column, dropdownSearchListItems: uniqueFromSuggestions };
        case "Subject":
          return { ...column, dropdownSearchListItems: uniqueSubjectSuggestions };
        case "Assigned To":
          return { ...column, dropdownSearchListItems: uniqueAssignedToSuggestions };
        default:
          return column;
      }
    });
  };

  function createFilterQuery(field: string, value: string) {
    if (value && value.length >= NUMERIC_VALUES.CONSTANT_THREE) {
      return `qa[${field}_i_cont]=${encodeURIComponent(value)}`;
    }
    return "";
  }

  /**
   * if user type anything, it will invoke api and retrieve the result when searched text value greater than 3
   * else display max of 5 recent searched data if that is exist
   */
  useEffect(() => {
    const { filterSearchedText } = initialFilterConfigs || {};
    const { subject, assignee_name, platform_connection_company_name } = filterSearchedText || "";

    setIsColumnsConfigDataLoading(true);

    let filters = "";
    if (subject?.value?.length >= NUMERIC_VALUES.CONSTANT_THREE) {
      const subjectQuery = createFilterQuery("subject", subject.value);
      filters += subjectQuery;
    }

    if (assignee_name?.value?.length >= NUMERIC_VALUES.CONSTANT_THREE) {
      const assigneeQuery = createFilterQuery("assignee_name", assignee_name.value);
      filters += (filters ? "&" : "") + assigneeQuery;
    }

    if (platform_connection_company_name?.value?.length >= NUMERIC_VALUES.CONSTANT_THREE) {
      const fromQuery = createFilterQuery("platform_connection_company_name", platform_connection_company_name.value);
      filters += (filters ? "&" : "") + fromQuery;
    }
    if (filters) {
      getActivitiesTableRecords(filters, "", NUMERIC_VALUES.CONSTANT_ONE, NUMERIC_VALUES.CONSTANT_TWENTY)
        .then((res) => {
          if (res.success) {
            setIsNoSearchResultFound(!res.total_records);
            setColumnsConfigData(getDropdownSearchFilterData(fetchParser(res, "all")));
          }
        })
        .catch(() => {
          setIsNoSearchResultFound(true);
        })
        .finally(() => {
          setIsColumnsConfigDataLoading(false);
        });
    } else {
      setIsColumnsConfigDataLoading(false);
      setColumnsConfigData(getDropdownSearchFilterData([]));
    }
  }, [initialFilterConfigs?.filterSearchedText, initialFilterConfigs?.filterQuery]);

  /**
   * This Logic has been implemented to handle the table header showing activities count and selection,
   * this is a one of case where activity plural becomes activities, for other cases like invoice -< invoices, payments -> payments
   * we have generalised handling inside library, if there are more such occurences in future we might look at making hchanges to
   * accomodate those in the library itself
   */

  const getHeaderTableType = () => {
    if (selectedRowsCount === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) {
      return t("table.headerCaption.tableType.activity", { ns: "activities" });
    } else if (selectedRowsCount < DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) {
      return cachedData?.data.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
        ? t("table.headerCaption.tableType.activity", { ns: "activities" })
        : t("table.headerCaption.tableType.activitie", { ns: "activities" });
    } else {
      return t("table.headerCaption.tableType.activitie", { ns: "activities" });
    }
  };

  const getFooterTableType = () => {
    if (cachedData?.data.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) {
      return t("table.headerCaption.tableType.activity", { ns: "activities" });
    } else {
      return t("table.headerCaption.tableType.activitie", { ns: "activities" });
    }
  };

  //using caching data to update the footer at the same time
  const updatedPaginationObject = {
    ...paginationConfig,
    ...TableUtils.getUpdatePaginationPageState(paginationConfig, cachedData?.total_records, cachedData?.total_pages),
  };

  return (
    <>
      <div className={`table-activity-wrapper activity-stream-v2`}>
        {headerButtons && headerButtons.length
          ? headerButtons.map((button, key) => {
              return (
                <div className="table-header-button" key={key}>
                  {button.component}
                </div>
              );
            })
          : null}
        <div className="body-table">
          <Table
            configs={{
              //Added the feature flag for the newly added filters excpet which are already there on Production
              columnConfigs: columnsConfigData?.map((item) => {
                if (["company_type", "last_activity_at", "snoozed_until", "status"].includes(item.accessor)) {
                  return { ...item };
                } else {
                  return { ...item, enableFilter: isFiltersEnabled };
                }
              }),
              initialTableConfigs: {
                name: "Mine",
                type: getHeaderTableType(),
                footerTableTypeText: getFooterTableType(),
                loading: isDataLoadingRC,
                extraHandlers: {
                  setSnackbarConfig: (payload: any) =>
                    setToastOptions({
                      open: true,
                      severity: payload?.type ?? "info",
                      message: payload?.text ?? t("toastMessages.downloadAttachment.inProgress", { ns: "activities" }),
                    }),
                },
                isRefetching: isFetching,
              },
              paginationConfigs: { ...updatedPaginationObject, enableNavigator: true, enableSummary: true },
              initialSortConfigs: sortConfig,
              selectionConfig: selectionConfig,
              initialFilterConfigs: {
                ...initialFilterConfigs,
                dateFormat: calendarDateFormat,
                isSuggestionDataLoading: isColumnsConfigDataLoading,
                category: props.category,
                workspace: selectedWorkspace.workspace_type_route?.toLocaleLowerCase() ?? WorkspaceType.AR,
                isNoSearchResultFound,
              },
            }}
            rows={(cachedData?.data || [])?.map((rec: any) => ({
              ...rec,
            }))}
            handlers={{
              onRowClick: handleRowClick,
              onRowHover: handleRowHover,
            }}
            userActions={[...(spam ? [...spamTableButtons] : [...rowSelectButtons])]}
            visibility={{
              exportBtn: false,
              hoverbar: false,
            }}
            emptyMessage={{
              title: emptyTitleMessage,
              caption: t("table.emptyMessage.caption", { ns: "activities" }),
            }}
          />
        </div>
      </div>
      {showActivity && (
        <NewActivityPopup
          key={`key-add-activity-${newActivityType}-${isActivityPopupLoading}`}
          title={t("addNewActivity.button.label", { ns: "activities" })}
          open={showActivity}
          contactOptions={filteredCompanyContactOptions}
          handleSend={isReply ? handleSendReply : handleSend}
          isTemplateSupport={isTemplateSupport}
          supportedTemplateList={supportedTemplateList}
          editorState={editorState}
          handleSendMarkClosed={handleSendAndMarkClosed}
          onClose={closeAddActivity}
          activityType={newActivityType}
          setNewActivityType={(type: any) => {
            setNewActivityType(type);
          }}
          addActivityDropdown={[
            { activityType: "Email", displayName: t("addNewActivity.dropdownList.email", { ns: "activities" }), icon: <Email /> },
            { activityType: "Note", displayName: t("addNewActivity.dropdownList.note", { ns: "activities" }), icon: <NoteFill /> },
            { activityType: "Phone Call", displayName: t("addNewActivity.dropdownList.phoneCall", { ns: "activities" }), icon: <Phone /> },
          ]}
          prepareActivityBodyByTemplateID={prepareActivityBodyByTemplateID}
          defaultTitle={originalSubjectLine}
          clickDisabled={isNudgeActivity}
          defaultTo={isNudgeActivity ? nudgeTo : _.isEmpty(primaryContact) ? [companyContactOptions[0]] : primaryContact}
          defaultCc={isNudgeActivity ? nudgeCc : []}
          defaultBcc={isNudgeActivity ? nudgeBcc : []}
          loading={isNudgeActivity && isActivityPopupLoading}
          fromTime={fromTime}
          toTime={toTime}
          setToTime={setToTime}
          isSendButtonDisabled={visibility.isSendBtnDisabled}
        />
      )}
      <ActivityMove
        open={moveDrawerOpen}
        onClose={() => {
          setMoveDrawerOpen(false);
          selectionConfig?.resetTableRecords();
        }}
        activityIds={activityIds}
        onCallback={() => {
          refetchCacheData();
          selectionConfig?.resetTableRecords();
        }}
        connectionId={
          activityIds.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
            ? cachedData?.data.filter((data: any) => data.id === activityIds[0])?.[0]?.["primary_connection"]?.["id"]
            : ""
        }
        bulkConnection={bulkConnection}
        selectedActivitiesCount={selectedRowsCount}
        deselectedRowsFilter={deselectedRowsFilter}
        connectionIdQueryFilter={connectionIdquery}
      />
      <ActivityReassign
        open={reassignDrawerOpen}
        onClose={() => {
          setReassignDrawerOpen(false);
          selectionConfig?.resetTableRecords();
        }}
        activityIds={activityIds}
        onCallback={() => {
          refetchCacheData();
          selectionConfig?.resetTableRecords();
        }}
        currentAssignee={currentAssignee}
        selectedActivitiesCount={selectedRowsCount}
        deselectedRowsFilter={deselectedRowsFilter}
        connectionIdQueryFilter={connectionIdquery}
      />
      <SpamFraudModal
        showModal={showModal}
        closeModal={setShowModal}
        connectionSpam={connectionSpam}
        handleSpamFraud={() =>
          onClickMarkSender(
            activityIds instanceof Array ? activityIds : [activityIds],
            connectionSpam ? ActivityActionTypes.Spam : ActivityActionTypes.Fraud
          )
        }
      />
    </>
  );
}

import React from "react";
import ToggleButton, { ToggleButtonProps } from "@mui/material/ToggleButton";
import CheckIcon from "@mui/icons-material/Check";
import Style from "./ToggleChip.module.scss";

interface ToggleChipProps extends ToggleButtonProps {
  text: string;
  onButtonToggle: (event: React.MouseEvent<HTMLElement, MouseEvent>, selected: boolean, value: string) => void;
  selected: boolean;
  value: string;
  checkIconChipRequired?: boolean;
}

export default function ToggleChip({ value, text, selected, onButtonToggle, checkIconChipRequired, className, ...rest }: ToggleChipProps) {
  return (
    <ToggleButton
      value={value}
      selected={selected}
      onChange={(event) => {
        onButtonToggle(event, selected, value);
      }}
      className={`${checkIconChipRequired ? Style.checkIconToggleChipRoot : Style.toggleChipRoot} ${className ?? ""}`}
      {...rest}
    >
      {checkIconChipRequired && selected && <CheckIcon />}
      {text}
    </ToggleButton>
  );
}

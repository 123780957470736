import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import FileNotAvailable from "../../../../../assets/lotties/file-not-available.json";
import { ReactComponent as DownloadIcon } from "../../svg/preview-unavailable-download.svg";
import { Button } from "@mui/material";
import { PreviewUnavailablePropTypes } from "./types";
import { PreviewUnavailableRoot, PreviewUnavailableView } from "../styled/styled";

export default function PreviewUnavailable({ attachment, onClickDownload }: PreviewUnavailablePropTypes): React.ReactElement {
  return (
    <PreviewUnavailableRoot>
      <PreviewUnavailableView>
        <Player direction={1} autoplay loop src={FileNotAvailable} />
        <h4 className="heading">Couldn’t preview file!</h4>
        <p className="content">Due to some issue preview is not available. Please download to view the file.</p>
        <Button variant="contained" startIcon={<DownloadIcon />} onClick={(event) => onClickDownload(event, [attachment])}>
          Download Now
        </Button>
      </PreviewUnavailableView>
    </PreviewUnavailableRoot>
  );
}

import { TEMPLATE_TIMING_CONSTANTS } from "../../constants/NumericConstants";
import { AttachmentType } from "../../types/enums";
import BaseTemplate from "./BaseTemplate";

/**
 * Template class which generate the text for Request for Invoice(s) Payment Status.
 */
class RequestInvoicePaymentStatus extends BaseTemplate {
  /**
   * Templates From Time definition
   */
  protected fromTime = TEMPLATE_TIMING_CONSTANTS.THREE_MIN_THIRTY_SEC;

  /**
   * Templates To Time definition
   */
  protected toTime = TEMPLATE_TIMING_CONSTANTS.FIVE_MIN;

  /**
   * To allow attachment with templates
   */
  protected templateAttachment = true;

  /**
   * Allowing multiple type of attachments with templates
   */
  public attachmentType = [AttachmentType.INVOICE];
}

export default RequestInvoicePaymentStatus;

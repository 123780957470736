/*eslint-disable no-magic-numbers*/
import { styled } from "@mui/system";
import { pxToRem } from "../../theme/theme";
import Button from "@mui/material/Button";

type ButtonProps = {
  error?: boolean;
};

export const ButtonPrimary = styled(Button)({
  height: pxToRem(40),
  borderRadius: pxToRem(4),
  color: "#FFFFFF",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: pxToRem(14),
  lineHeight: pxToRem(20),
  display: "flex",
  textAlign: "center",
  textTransform: "none",
  background: "#1A73E8",
  "&:hover": {
    background: "#1C4DA5",
  },
  "&:active": {
    background: "#4D4D4D",
  },
  "&.small": {
    height: pxToRem(24),
    padding: `${pxToRem(2)} ${pxToRem(12)}`,
  },
});

export const ButtonSecondary = styled(ButtonPrimary)({
  background: "#EBF1FA",
  color: "#1A73E8",
  "&:hover": {
    background: "#DDECFF",
    color: "#1C4DA5",
  },
  "&:active": {
    background: "#1A73E8",
    color: "#FFFFFF",
  },
  "&.small": {
    height: pxToRem(24),
    padding: `${pxToRem(2)} ${pxToRem(12)}`,
  },
});

export const ButtonTertiary = styled(ButtonPrimary)<ButtonProps>(({ error }) => ({
  background: "transparent",
  color: error ? "#BF360C" : "#1A73E8",
  "&:hover": {
    background: "transparent",
    color: "#1C4DA5",
  },
  "&:active": {
    background: "transparent",
    color: "#4D4D4D",
  },
  "&.small": {
    height: pxToRem(24),
    padding: `${pxToRem(2)} ${pxToRem(12)}`,
  },
}));

export const AnchorButtonPrimary = styled("a")({
  height: pxToRem(40),
  borderRadius: pxToRem(4),
  color: "#FFFFFF",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: pxToRem(14),
  lineHeight: pxToRem(20),
  display: "flex",
  textAlign: "center",
  textTransform: "none",
  background: "#1A73E8",
  padding: `${pxToRem(10)} ${pxToRem(12)}`,
  alignItems: "center",
  "&:hover": {
    background: "#1C4DA5",
  },
  "&:active": {
    background: "#4D4D4D",
  },
  "&.small": {
    height: pxToRem(24),
    padding: `${pxToRem(2)} ${pxToRem(12)}`,
  },
});

export type ButtonMasterProps = {
  btnVariant: "primary" | "secondary" | "tertiary";
};

export const ButtonMaster = styled(Button)<ButtonMasterProps>(({ btnVariant = "primary" }) => {
  const color = btnVariant === "primary" ? "#FFFFFF" : "#1A73E8";
  const background = btnVariant === "primary" ? "#1A73E8" : btnVariant === "secondary" ? "#F5F5F5" : "#FFFFFF";
  const border = btnVariant === "primary" ? "#1A73E8" : btnVariant === "secondary" ? "#F5F5F5" : "#1A73E8";

  const hoverColor = btnVariant === "primary" ? "#FFFFFF" : btnVariant === "secondary" ? "#0D47A1" : "#FFFFFF";
  const hoverBackground = btnVariant === "primary" ? "#0D47A1" : btnVariant === "secondary" ? "#EEEEEE" : "#0D47A1";
  const hoverBorder = btnVariant === "primary" ? "#0D47A1" : btnVariant === "secondary" ? "#FFFFFF" : "#0D47A1";

  const activeColor = btnVariant === "primary" ? "#FFFFFF" : btnVariant === "secondary" ? "#424242" : "#FFFFFF";
  const activeBackground = btnVariant === "primary" ? "#424242" : btnVariant === "secondary" ? "#EEEEEE" : "#1A73E8";
  const activeBorder = btnVariant === "primary" ? "#424242" : btnVariant === "secondary" ? "#FFFFFF" : "#1A73E8";

  const disabledColor = btnVariant === "primary" ? "#FFFFFF" : btnVariant === "secondary" ? "#757575" : "#757575";
  const disabledBackground = btnVariant === "primary" ? "#757575" : btnVariant === "secondary" ? "#FFFFFF" : "transparent";
  const disabledBorder = btnVariant === "primary" ? "#FFFFFF" : btnVariant === "secondary" ? "#FFFFFF" : "#757575";

  const focusedShadow = `${pxToRem(0)} ${pxToRem(4)} ${pxToRem(20)} ${pxToRem(0)} rgba(0, 0, 0, 0.10), ${pxToRem(0)} ${pxToRem(8)} ${pxToRem(
    12
  )} ${pxToRem(0)} rgba(0, 0, 0, 0.10) `;

  return {
    height: pxToRem(40),
    borderRadius: pxToRem(8),
    color: color,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: pxToRem(14),
    lineHeight: pxToRem(20),
    display: "flex",
    textAlign: "center",
    textTransform: "none",
    background: background,
    padding: `${pxToRem(8)} ${pxToRem(16)}`,
    border: `${pxToRem(1)} solid ${border}`,
    "&:hover": {
      background: hoverBackground,
      color: hoverColor,
      border: `${pxToRem(1)} solid ${hoverBorder}`,
    },
    "&:active": {
      background: activeBackground,
      color: activeColor,
      border: `${pxToRem(1)} solid ${activeBorder}`,
    },
    "&.small": {
      height: pxToRem(24),
      padding: `${pxToRem(2)} ${pxToRem(12)}`,
    },
    "&:disabled": {
      background: disabledBackground,
      border: disabledBorder,
      color: disabledColor,
    },
    "&:focus": {
      boxShadow: btnVariant === "primary" ? focusedShadow : "reset",
    },
  };
});

import { TEMPLATE_TIMING_CONSTANTS } from "../../constants/NumericConstants";
import BaseTemplate from "./BaseTemplate";

/**
 * Template class which generate the text for Request Copy of Bill.
 */
class RequestCopyBill extends BaseTemplate {
  /**
   * Templates From Time definition
   */
  protected fromTime = TEMPLATE_TIMING_CONSTANTS.ZERO;

  /**
   * Templates To Time definition
   */
  protected toTime = TEMPLATE_TIMING_CONSTANTS.TWO_MIN_THIRTY_SEC;
}

export default RequestCopyBill;

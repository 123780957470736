import { TableRoot, TableViewContainer } from "./table.styles";
import { Checkbox, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { ColumnConfigs, TableProps } from "../../types/table.types";
import { RowDataTypes } from "../../../constants/table.constants";
import { NUMERIC_VALUES, DEFAULT_PAGINATION_VALUES } from "../../../constants/numeric.constants";
import createTheme, { pxToRem } from "../../../theme/theme";
import React, { useEffect } from "react";
import useTable from "../../hooks/useTable";
import { ThemeProvider } from "@mui/system";
import TableFooter from "../Footer/Footer";
import TableDataProvider from "../../contexts/TableDataContext";
import Cell from "../Cell/Cell";
import TableCaption from "../Caption/Caption";
import TableHeader from "../Header/Header";
import Hoverbar from "../Hoverbar/Hoverbar";
import { getValuebyStringPath } from "../../../utils/table";
import CustomTooltip from "../../../CustomTooltip/CustomTooltip";
import EmptyStates from "../EmptyStates/EmptyStates";
import _ from "underscore";
import FilterResultEmptyState from "../EmptyStates/FilterResultEmptyState";
import { useTranslation } from "react-i18next";

const TableComponent: React.FC<TableProps> = ({
  configs: {
    captionConfigs,
    columnConfigs,
    initialTableConfigs,
    paginationConfigs,
    initialSortConfigs,
    selectionConfig,
    initialFilterConfigs,
    currencyConfig,
    disableRowMessage = "",
  },
  handlers,
  rows,
  userActions,
  visibility,
  hoverBar = true,
  secondaryRowActions = {},
  emptyMessage = "There are no activities in this category",
}) => {
  const { actionConfigs, selectionConfigs, filterConfigs, tableConfigs, sortConfigs } = useTable(
    initialTableConfigs,
    columnConfigs,
    rows,
    initialSortConfigs,
    selectionConfig,
    initialFilterConfigs,
    paginationConfigs,
    currencyConfig
  );

  const { t } = useTranslation();
  /**
   * A helper to scroll table body to the top when a page change or per page change happens.
   * Note: element.scrollIntoView(), The element may not be scrolled completely to the top
   * or bottom depending on the layout of other elements. Inorder to achieve this correctly,
   * we can use scroll-padding-top or scroll-margin-top on the element or its parent
   * container (depending on DOM structure).
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView | Element: scrollIntoView() method}
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/scroll-padding-top | scroll-padding-top}
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/scroll-margin-top | scroll-margin-top}
   */
  const tableRef = React.createRef<HTMLTableSectionElement>();
  useEffect(() => {
    tableRef.current && tableRef.current.scrollIntoView();
  }, [paginationConfigs?.page?.pageNumber, paginationConfigs?.page?.pageSize, initialSortConfigs?.sort]);

  const onRowClick = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: any) => {
    if (!tableConfigs?.loading) {
      handlers?.onRowClick(e, row, tableConfigs?.rows);
    }
  };
  const getTable = () => (
    <TableViewContainer className="table-view-container">
      <TableContainer component={Paper} className="table-container-root">
        <Table
          stickyHeader
          className={tableConfigs?.rows?.length === NUMERIC_VALUES.CONSTANT_ZERO ? "table-header-cell-name" : ""}
          sx={{ minWidth: NUMERIC_VALUES.CONSTANT_SIX_HUNDRED_FIFTY }}
          size="small"
          aria-label="a dense table"
        >
          <TableHeader />
          <TableBody className="table-body" ref={tableRef}>
            {tableConfigs?.rows?.length === NUMERIC_VALUES.CONSTANT_ZERO && (
              <TableRow
                key={`${tableConfigs.name}-rows-no-data`}
                sx={{ "&:last-child td, &:last-child th": { border: NUMERIC_VALUES.CONSTANT_ZERO } }}
              >
                <TableCell padding="checkbox" style={{ width: "4%", height: "100%" }}>
                  <>{/* Empty cell to adjust table style when empty */}</>
                </TableCell>
                <TableCell
                  className="mui-table-cell-no-data"
                  align="center"
                  colSpan={columnConfigs?.length + NUMERIC_VALUES.CONSTANT_ONE}
                  rowSpan={paginationConfigs?.page?.pageSize || DEFAULT_PAGINATION_VALUES.PAGE_SIZE}
                >
                  {!_.isEmpty(filterConfigs?.filterQuery) ? (
                    <FilterResultEmptyState
                      title={t("body.emptyFilteredRecords.title", { ns: "table" })}
                      caption={t("body.emptyFilteredRecords.caption", { ns: "table" })}
                    />
                  ) : (
                    <>
                      {!tableConfigs.customNoRowDataComponent?.enable &&
                        (typeof emptyMessage === "string" ? (
                          <Typography variant="body1Med">{emptyMessage}</Typography>
                        ) : (
                          <EmptyStates title={emptyMessage?.title} caption={emptyMessage?.caption} />
                        ))}
                      {tableConfigs.customNoRowDataComponent?.enable && tableConfigs.customNoRowDataComponent?.component}
                    </>
                  )}
                </TableCell>
              </TableRow>
            )}
            {tableConfigs?.rows?.length > NUMERIC_VALUES.CONSTANT_ZERO &&
              tableConfigs?.rows?.map((row: any, index: number) => {
                const isRowSelected = selectionConfigs?.tableRecords?.selectedRecordsIds?.includes(row?.id);
                const isCheckboxDisabled: boolean = tableConfigs?.loading || row.isPrimary;
                return (
                  <>
                    <TableRow
                      className={`table-row ${isRowSelected ? "table-row-selected" : ""} ${row?.read ? "read" : "unread"}`}
                      key={`${row?.id}-rows-${index}`}
                      sx={{ "&:last-child td, &:last-child th": { border: NUMERIC_VALUES.CONSTANT_ZERO } }}
                      onMouseEnter={(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
                        actionConfigs.setActionHoverId(row?.id || index);
                        handlers?.onRowHover && handlers?.onRowHover(event, row);
                      }}
                      onMouseLeave={() => {
                        actionConfigs.setActionHoverId("");
                      }}
                      onClick={(e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => onRowClick(e, row)}
                    >
                      <CustomTooltip title={isCheckboxDisabled ? disableRowMessage : ""}>
                        <TableCell
                          padding="checkbox"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <Checkbox
                            disableRipple={true}
                            disabled={isCheckboxDisabled}
                            checked={isRowSelected}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            onChange={selectionConfigs?.onClickTableRow?.bind(this, row?.id, tableConfigs?.rows)}
                          />
                        </TableCell>
                      </CustomTooltip>
                      {columnConfigs?.map((config: ColumnConfigs, pos: number) => {
                        return (
                          <TableCell
                            key={`${tableConfigs.name}-row-cells-id-${pos}`}
                            width={config?.width}
                            component="th"
                            scope="row"
                            align={config?.rowDataAlign}
                            className={config?.emboldenOnUnread && !row?.read ? "unread" : ""}
                          >
                            {!tableConfigs?.loading && (
                              <Cell
                                actionHover={actionConfigs?.actionHoverId === row?.id}
                                colConfig={columnConfigs}
                                rowData={row}
                                width={config?.width}
                                view={tableConfigs?.name || "Transaction"}
                                key={`cell-renderer-${config.accessor}-${pos}-${index}`}
                                value={getValuebyStringPath(row, config.accessor) || "-"}
                                type={config?.rowDataType || RowDataTypes.SimpleText}
                                secondaryValue={
                                  config?.conditionalAccessor instanceof Array
                                    ? config?.conditionalAccessor?.map((fields) => row[fields])
                                    : row[config?.conditionalAccessor ?? ""]
                                }
                                extraHandlers={tableConfigs?.extraHandlers}
                                secondaryRowActions={_.isEmpty(secondaryRowActions) ? row.row_actions : secondaryRowActions}
                                currencyConfig={currencyConfig}
                              />
                            )}
                            {tableConfigs?.loading && config?.enableSkeleton && (
                              <Skeleton
                                animation="wave"
                                width={"100%"}
                                height={pxToRem(config?.skeletonHeightInPX || NUMERIC_VALUES.CONSTANT_TWENTY_FIVE)}
                              />
                            )}
                          </TableCell>
                        );
                      })}
                      {hoverBar &&
                        selectionConfigs?.tableRecords?.selectedRecordsIds?.length === NUMERIC_VALUES.CONSTANT_ZERO &&
                        actionConfigs.actionHoverId === row?.id && <Hoverbar row={row} />}
                    </TableRow>
                  </>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </TableViewContainer>
  );

  return (
    <ThemeProvider theme={createTheme}>
      <TableDataProvider
        {...{
          tableConfigs,
          columnConfigs,
          paginationConfigs,
          selectionConfigs,
          captionConfigs,
          filterConfigs,
          sortConfigs,
          actionConfigs,
          userActions,
          visibility,
          currencyConfig,
        }}
      >
        <TableRoot className="table-root">
          <TableCaption />
          {getTable()}
          <TableFooter />
        </TableRoot>
      </TableDataProvider>
    </ThemeProvider>
  );
};

export default TableComponent;

import React from "react";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../../../../../constants/NumericConstants";
import { AlertContext } from "../../../../../../contexts/AlertContext";
import { companiesClientV2, contactsClientV2 } from "../../../../../../db/version2Accessor";
import { ConnectionStatus, FallbackTypes, WorkspaceType } from "../../../../../../types/enums";
import { ContactMoveComponent } from "./ContactMoveComponent";
import useWorkspaceConfigurations from "../../../../../../hooks/useWorkspaceConfigurations";

type ContactMoveContainerProps = {
  moveIds: string[];
  open: boolean;
  setMoveIds: React.Dispatch<React.SetStateAction<string[]>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  softRefresh: () => Promise<TableData[] | undefined>;
};

export const ContactMoveContainer = (props: ContactMoveContainerProps) => {
  const [selectedCompany, setSelectedCompany] = React.useState<Partial<CompanyV2> | null>(null);
  const [isLoadingCompanyList, setLoadingCompanyList] = React.useState<boolean>(true);
  const [showLoader, setShowLoader] = React.useState<boolean>(false);
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const { selectedWorkspace, pathType } = useWorkspaceConfigurations();

  const fetchCompanyList = async (searchText: string, page?: number) => {
    return companiesClientV2.getCustomersList(
      ConnectionStatus.ACTIVE,
      "qs[s]=company_name",
      NUMERIC_VALUES.CONSTANT_TWENTY,
      page ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
      selectedWorkspace.id || FallbackTypes.Id,
      selectedWorkspace.workspace_type_route === WorkspaceType.AW
        ? ["Vendor", "Customer", "Other"]
        : pathType === WorkspaceType.AP
        ? ["Vendor"]
        : ["Customer"],
      undefined,
      searchText ? `qa[company_name_cont]=${searchText}` : undefined
    );
  };

  const fetchCompanyListFunc = async (searchText: string): Promise<{ records: CompanyV2[]; totalRecords: number }> => {
    const response = await fetchCompanyList(searchText);
    return { records: response.records ?? [], totalRecords: response.totalCount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO };
  };

  const handleMove = async () => {
    setShowLoader(true);
    const createMessage = (actionTaken: string, noOfRecords: number) => {
      return noOfRecords > DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
        ? `${noOfRecords} contacts ${actionTaken} to ${selectedCompany?.company_name} successfully`
        : `Contact is ${actionTaken} to ${selectedCompany?.company_name} successfully`;
    };

    try {
      await contactsClientV2.updateContacts(
        {
          items: props.moveIds.map((contactId: string) => ({
            contact_id: contactId,
            company_id: selectedCompany?.company_id,
            title: "",
          })),
        },
        selectedWorkspace.id ?? DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE
      );
      setToastOptions({ severity: "success", message: createMessage("moved", props.moveIds.length), open: true });
      // Refresh to GET latest updated contacts
      props.softRefresh();
    } catch (error: unknown) {
      console.error("error:: ", error);
      setToastOptions({ severity: "error", message: "Something Went Wrong", open: true });
    } finally {
      setShowLoader(false);
      props.setOpen(false);
      props.setMoveIds([]);
    }
  };

  const onClose = () => {
    props.setOpen(false);
    setSelectedCompany(null);
    setLoadingCompanyList(false);
  };

  return (
    <ContactMoveComponent
      ids={props.moveIds}
      handleMove={handleMove}
      isLoadingCompanyList={isLoadingCompanyList}
      setSelectedCompany={setSelectedCompany}
      onClose={onClose}
      open={props.open}
      isLoading={showLoader}
      fetchCompanyList={fetchCompanyListFunc}
    />
  );
};

import Card from "@mui/material/Card";
import { styled as muiStyled } from "@mui/material/styles";

export const AttachmentCardRoot = muiStyled(Card, {
  name: "attachment-card",
  slot: "root",
})({
  padding: "0.75rem 0.78125rem",
  boxShadow: "none",
  border: "0.0625rem solid #bdc3db",
  borderRadius: "0.5rem",
  background: "#ffffff",
  color: "#4D4D4D",
  "& .attachments": {
    maxHeight: "20rem",
    overflowY: "scroll",
  },
  "& .MuiCardActions-root": {
    "& .MuiTypography-root": {
      fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif`,
      fontWeight: "600",
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      textDecoration: "none",
    }
  }
});
import { makeStyles } from "@mui/styles";
import styles from "../../../../../../css/_export.module.scss";

const useActivityReassignDropdownStyle = () => {
  const useStyles = makeStyles({
    divRoot: {
      display: "flex",
      alignItems: "center",
      border: `${styles.borderline} solid #4D4D4D`,
      borderRadius: styles.small,
      height: "2rem",
    },
    divRootActive: {
      border: `${styles.borderline} solid #1C4DA5`,
    },
    select: {
      boxShadow: "none",
      "&:hover": {
        "& .MuiSelect-icon": {
          color: styles.selectionDark,
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
        boxShadow: "none",
      },
      "& .MuiOutlinedInput-input": {
        display: "flex",
        height: "100%",
        alignItems: "center",
        color: styles.greyText,
        fontFamily: styles.primaryFontFamily,
        fontWeight: "400",
        fontSize: styles.fontSizeXS,
        lineHeight: styles.lineHeightSM,
        padding: `0 ${styles.medium}`,
        gap: styles.small,
        "&:hover": {
          color: styles.selectionDark,
        },
      },
      "& .MuiSelect-icon": {
        color: styles.greyText,
      },
    },
    selectActive: {
      "& .MuiOutlinedInput-input": {
        color: "#1C4DA5",
      },
      "& .MuiSelect-icon": {
        color: "#1C4DA5",
        svg: {
          fill: "#1C4DA5",
        },
      },
    },
    menuRoot: {
      "& .MuiPaper-root": {
        width: "18.85rem",
        maxHeight: "21rem",
        background: styles.white100,
        marginTop: styles.base,
        borderRadius: styles.small,
        boxShadow: `0 ${styles.tiny} ${styles.small} rgba(0, 0, 0, 0.12)`,
        gap: styles.small,
      },
      "& .MuiList-root": {
        height: "100%",
        overflowY: "hidden",
        overflowX: "hidden",
        msOverflowStyle: "none",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        "& .scrollable-menu-list": {
          maxHeight: "12rem",
          overflowY: "overlay",
          paddingBottom: "1rem",
        },
        "& .banner-invite-team": {
          margin: "0 0.5625rem",
          background: "#ffefde",
          display: "flex",
          flexDirection: "column",
          gap: "0.375rem",
          padding: "0.625rem",
          borderRadius: "0 0 0.5rem 0.5rem",
          "& .banner-text": {
            color: "#1a1a1a",
            fontFamily: styles.primaryFontFamily,
            fontWeight: "500",
            fontSize: "0.6875rem",
            lineHeight: "0.875rem",
          },
          "& .banner-invite-button": {
            color: "#1a73e8",
            fontFamily: styles.primaryFontFamily,
            fontWeight: "600",
            fontSize: "0.875rem",
            lineHeight: "1.125rem",
            textAlign: "right",
            cursor: "pointer",
          },
        },
        "& .MuiMenuItem-root": {
          height: "2.25rem",
          fontFamily: styles.primaryFontFamily,
          fontWeight: "400",
          fontSize: styles.fontSizeSM,
          lineHeight: styles.lineHeightMD,
          margin: `0 ${styles.small}`,
          gap: styles.small,
          borderRadius: styles.small,
          padding: `0 ${styles.small}`,
          "&:hover": {
            background: styles.grey98,
          },
        },
        "& .MuiInputBase-root:before": {
          borderBottom: "none",
        },
        "& .MuiFocused .MuiInputBase-root:before": {
          borderBottom: "none",
        },
        "& .Mui-selected": {
          background: styles.grey98,
          marginTop: "0.5rem",
          marginBottom: "0.5rem",
        },
        "& .Mui-disabled": {
          opacity: "1",
        },
      },
    },
    menuSearchBox: {
      width: "17.75rem",
      height: "2.25rem",
      margin: `${styles.small}`,
      background: styles.grey98,
      borderColor: styles.grey90,
      borderRadius: "0.5rem",
      boxSizing: "border-box",
      fontFamily: styles.primaryFontFamily,
      fontWeight: "400",
      fontSize: styles.fontSizeSM,
      lineHeight: styles.lineHeightMD,
      "& :before": {
        borderBottom: "none",
      },
      "&:focus": {
        borderColor: styles.grey90,
      },
    },
    adornmentSearchIcon: {
      display: "flex",
      color: styles.grey40,
      fontSize: styles.fontSizeSM,
      marginLeft: "0.5rem",
    },
    menuTypographyElement: {
      "& .Mui-disabled": {
        opacity: "1",
      },
    },
    hidelist: {
      display: "none",
    },
    itemText: {
      "& .MuiListItemText-primary": {
        fontFamily: styles.primaryFontFamily,
        color: styles.grey10,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        "& .list-item-value": {
          fontWeight: "400!important",
          fontSize: styles.fontSizeSM,
          lineHeight: styles.lineHeightSM,
        }
      },
    },
    typography: {
      fontFamily: styles.primaryFontFamily,
      fontWeight: "500",
      fontSize: styles.fontSizeXS,
      lineHeight: styles.fontSizeMD,
      color: styles.grey40,
    },
    unassignedTypography: {
      marginLeft: "auto",
    },
    horizontalRule: {
      borderTop: `${styles.small}`,
      backgroundColor: `${styles.grey90}`,
      margin: `${styles.small} ${styles.small}`,
    },
  });
  return useStyles();
};

export default useActivityReassignDropdownStyle;

import InfoIcon from "../../svg/info_icon.svg";
import { InfoIconContainer, ToolTipContainer } from "./info.icon.style";
import { InfoIconType } from "./info.icon.types";
import React from "react";

const Component: React.FC<InfoIconType> = ({ title, placement }: InfoIconType) => {
  return (
    <ToolTipContainer disableFocusListener disableTouchListener className="info-icon-tooltip" title={title || ""} placement={placement || "right"}>
      <InfoIconContainer className="info-icon-container">
        <InfoIcon />
      </InfoIconContainer>
    </ToolTipContainer>
  );
};

export default Component;

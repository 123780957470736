import React from "react";
import { SaveApAutomationEmailProps } from "../ActivityItemVariantTypes";
import { SecondaryType } from "../../SecondaryActivity/SecondaryActivityTypes";
import SecondaryActivity from "../../SecondaryActivity/SecondaryActivity";
import { useTranslation } from "react-i18next";

const SaveApAutomationEmail: React.FC<SaveApAutomationEmailProps> = ({ creator, countryCode }) => {
  const { t } = useTranslation();
  creator = { ...creator, message: t("activityDetails.feedItems.saveApAutomationEmail.headerMessage", { ns: "activities" }) };

  return <SecondaryActivity creator={creator} variant={SecondaryType.Base} countryCode={countryCode} />;
};

export default SaveApAutomationEmail;

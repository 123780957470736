import React from "react";
import { useParams, useLocation, useRouteMatch } from "react-router-dom";
import Navbar from "../../../library/Navbar/Navbar";
import { DEFAULT_NUMERIC_VALUES } from "../../../../constants/NumericConstants";
import { WorkspaceContext } from "../../../../contexts/WorkspaceContext";
import { getCurrentSearchContext, getPreviousViewContext } from "../../Utils/LocalStorageUtils";

interface GSRLandingNavbarInterface {
  currentSearchNestingLevel: "L1" | "L2";
}

interface SearchHistoryInterface {
  backRoute?: string;
  backDisplayName?: string;
}

enum GSRNestingLevels {
  "l1" = "L1",
  "l2" = "L2",
}

const ResultsViewNavbar: React.FC<GSRLandingNavbarInterface> = ({ currentSearchNestingLevel }: GSRLandingNavbarInterface) => {
  const { path } = useRouteMatch();
  const { category } = useParams<{ category: string }>();
  const { search } = useLocation();
  const { workspaceHomePath } = React.useContext(WorkspaceContext) as WorkspaceDataType;

  const getSearchHistory = (): SearchHistoryInterface => {
    let searchHistory = {};
    if (currentSearchNestingLevel?.toLowerCase() === GSRNestingLevels.l1?.toLowerCase()) {
      const getHistory = getPreviousViewContext();
      searchHistory = {
        backRoute: getHistory?.previousPath ?? `${workspaceHomePath}/dashboard`,
        backDisplayName: getHistory?.previousContextName ?? "Dashboard",
      };
    } else {
      const getHistory = getCurrentSearchContext();
      searchHistory = {
        backRoute: getHistory?.currentSearchActivePath ?? `${workspaceHomePath}/dashboard`,
        backDisplayName: getHistory?.currentSearchTitle ?? "Dashboard",
      };
    }
    return searchHistory;
  };

  return (
    <Navbar
      key={`${category}${search}`}
      tabs={[{ displayName: "Search Results", routeName: `${category}${search}` }]}
      rootPath={path
        ?.split("/")
        ?.slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, path?.split("/")?.length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE)
        ?.join("/")}
      handleBackRoute={true}
      backRoute={getSearchHistory()?.backRoute}
      backDisplayName={getSearchHistory()?.backDisplayName}
    />
  );
};

export default ResultsViewNavbar;

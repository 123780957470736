export const TooltipTitles = {
  ASSIGN: "Assign",
  CLOSE: "Close",
  MARK_AS_COMPLETE: "Mark as complete",
  MARK_AS_UNREAD: "Mark as unread",
  SNOOZE: "Snooze",
  MOVE: "Move",
  MOVE_AS_SPAM: "Mark as spam",
  READ_LESS: "Read Less",
  READ_MORE: "Read More",
  REPLY_TO_EMAIL: "Reply To Email",
  REPLY_TO_ALL: "Reply All",
  FORWARD: "Forward Email",
  GO_TO_SEARCH: "Go To Search",
  ADD_NEW: "Add New",
  SEND: "Send",
  SEND_CLOSE: "Send & Close",
  GO_PREVIOUS: "Go Previous",
  GO_NEXT: "Go Next",
  GO_BACK_ACTIVITIES: "Go To All Activities",
  CLICK_TO_DOWNLOAD: "Click to download",
  CLICK_TO_PREVIEW: "Click to preview",
  OPEN_COMMAND_CENTER: "Open Command Center",
  NO_THANKS: "No Thanks",
  CANCEL_DRAFT: "Cancel Draft",
};

export const TooltipTypes = {
  PLAIN: "PLAIN",
  SHORTCUT: "SHORTCUT",
  RICH: "RICH",
  RICH_CONDENSE: "RICH_CONDENSE",
  RICH_CONDENSE_V2: "RICH_CONDENSE_V2",
};

import React, { useState } from "react";
import { Input } from "../../../library/Input/Input";
import Button from "../../../library/Button/Button";
import { Drawer } from "@mui/material";
import { userAccountsClientV2 } from "../../../../db/version2Accessor";
import Utils from "../../../../utils/utils";
import { DEFAULT_NUMERIC_VALUES } from "../../../../constants/NumericConstants";
import * as configValue from "../../../../constants/config";
import { AlertContext } from "../../../../contexts/AlertContext";
import { WorkspaceContext } from "../../../../contexts/WorkspaceContext";
import { useTranslation } from "react-i18next";
import "./inviteFlyout.scss";

type InviteMembersFlyoutButtonProps = {
  open: boolean;
  refreshTable?: () => void;
  setInviteFlyout: React.Dispatch<React.SetStateAction<boolean>>;
};

const InviteMembersFlyoutButton: React.FC<InviteMembersFlyoutButtonProps> = ({ open, setInviteFlyout, refreshTable }): React.ReactElement => {
  const [inviteEmails, setInviteEmails] = useState<string>("");
  const [inviteErrorMessage, setInviteErrorMessage] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const { fetchWorkspaceMembers } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { t } = useTranslation();

  // Helper functions to determine invitation error
  const isAlreadyRegistered = (errorMessage: string | null): boolean => {
    if (!errorMessage) return false;
    return errorMessage.includes(configValue.ERROR_RESPONSE_ALREADY_REGISTERED);
  };
  const isRecentlyInvited = (errorMessage: string | null): boolean => {
    if (!errorMessage) return false;
    return errorMessage.includes(configValue.ERROR_RESPONSE_RECENTLY_INVITED);
  };

  // TODO: Refactor Nitin
  const handleInvite = async () => {
    if (!inviteEmails) {
      setInviteErrorMessage(configValue.ERROR_NO_EMAILS);
      return;
    }

    const emails = inviteEmails.split(",").map((email) => email.trim());
    const isValid = emails.every((email: string) => Utils.validEmail(email));

    if (!isValid) {
      setInviteErrorMessage(configValue.ERROR_INVALID_EMAIL_IDS);
      return;
    }

    let toastOptions: ToastOptions = {
      open: true,
      severity: "error",
      message:
        emails.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
          ? t("toastMessages.inviteMember.error.multiInvite", { ns: "activities" })
          : t("toastMessages.inviteMember.error.singleInvite", { ns: "activities" }),
    };

    try {
      setLoading(true);

      const res = await userAccountsClientV2.inviteUserInGroup(emails);
      const data = res.data;
      // evaluate success & failure counts
      const successCount = data.reduce((prev: number, curr: InviteModelV2) => prev + Number(curr.success), DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
      const alreadyRegisteredCount =
        successCount !== data.length
          ? data.reduce(
              (prev: number, curr: InviteModelV2) => prev + Number(isAlreadyRegistered(curr.error_message)),
              DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
            )
          : DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
      const recentlyInvitedCount =
        successCount !== data.length
          ? data.reduce(
              (prev: number, curr: InviteModelV2) => prev + Number(isRecentlyInvited(curr.error_message)),
              DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
            )
          : DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;

      // all invites success
      if (successCount === data.length) {
        toastOptions = {
          ...toastOptions,
          severity: "success",
          message:
            data.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
              ? t("toastMessages.inviteMember.bulkSuccess", { ns: "activities" })
              : t("toastMessages.inviteMember.success", { ns: "activities" }),
        };
      }
      // failures
      else if (alreadyRegisteredCount || recentlyInvitedCount) {
        const recentlyInvitedMsg =
          data.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
            ? t("toastMessages.inviteMember.bulkFailed.recentlyInvited", { recentlyInvitedCount, ns: "activities" })
            : t("toastMessages.inviteMember.failed.recentlyInvited", { email: data[0].email, ns: "activities" });
        const alreadyRegisteredMsg =
          data.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
            ? t("toastMessages.inviteMember.bulkFailed.recentlyInvited", { recentlyInvitedCount, ns: "activities" })
            : t("toastMessages.inviteMember.failed.recentlyInvited", { email: data[0].email, ns: "activities" });

        const message = alreadyRegisteredCount ? alreadyRegisteredMsg : recentlyInvitedMsg;

        toastOptions = { ...toastOptions, message };
      }

      refreshTable?.();
      // refresh workspace members state
      fetchWorkspaceMembers();
    } catch (error) {
      console.error("error: ", error);
    } finally {
      setInviteErrorMessage("");
      setInviteFlyout(false);
      setLoading(false);
      setToastOptions(toastOptions);
    }
  };

  return (
    <Drawer anchor="right" open={open} onClose={() => setInviteFlyout(false)}>
      <div className={"invite-flyout-wrapper"}>
        <pre className={"header"}>{t("modalAndFlyout.inviteMember.header", { ns: "activities" })}</pre>
        <Input
          label={{
            regularText: t("modalAndFlyout.inviteMember.searchFieldLabel.regularText", { ns: "activities" }),
            extraText: t("modalAndFlyout.inviteMember.searchFieldLabel.extraText", { ns: "activities" }),
          }}
          placeholder={t("modalAndFlyout.inviteMember.searchPlaceHolder", { ns: "activities" }) as string}
          onChange={(e) => {
            setInviteEmails(e.target.value.trim());
            setInviteErrorMessage("");
          }}
          errorMessage={inviteErrorMessage}
        />
        <div className={"invite-btn-grp"}>
          <Button className={"invite-btn"} variant="secondary" onClick={() => setInviteFlyout(false)}>
            {t("modalAndFlyout.buttons.cancel", { ns: "activities" })}
          </Button>
          <Button className={"invite-btn"} onClick={() => handleInvite()} loading={isLoading}>
            {t("modalAndFlyout.buttons.sendInvite", { ns: "activities" })}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default InviteMembersFlyoutButton;

import React from "react";
import SecondaryActivity from "../../SecondaryActivity/SecondaryActivity";
import { SecondaryType } from "../../SecondaryActivity/SecondaryActivityTypes";
import { UnspamProps } from "../ActivityItemVariantTypes";
import * as Labels from "../../../../../../../constants/config";

const Unspam: React.FC<UnspamProps> = ({ creator, countryCode }) => {
  return (
    <SecondaryActivity
      variant={SecondaryType.Base}
      creator={{ ...creator, message: Labels.HEADER_REMOVED_MARKED_NOT_SPAM }}
      countryCode={countryCode}
    />
  );
};

export default Unspam;

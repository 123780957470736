import APIClient from "../APIClient";
import { AxiosRequestConfig } from "axios";

const INBOX_API_BASE = process.env.REACT_APP_API_ENDPOINT_INBOX_API_PATH;
const CONFIG_OVERRIDE = {
  baseURL: process.env.REACT_APP_API_ENDPOINT_INBOX,
} as AxiosRequestConfig;

export interface ReviewResponse extends APIResponse {
  creator: {
    name: string;
    email_address: string;
    type: string;
    id: number;
  };
}
export class ApprovalActivitiesClient {
  BASE_URL = `${INBOX_API_BASE}/magic_links`;

  /**
   * This redirects the approval request to new approver
   *
   * @param id redirect magic UUID
   * @param data redirect request data
   */
  redirectApprovalRequest(id: UUID, data: any): Promise<APIResponse> {
    const url = `${this.BASE_URL}/${id}/activity/redirect`;
    return APIClient.post(url, data, CONFIG_OVERRIDE, true, false, false).then((res) => res.data);
  }

  /**
   * Updates the approval request with approver review details
   *
   * @param requestId approval request Id
   * @param reviewAction approve/decline
   * @param reviewNote message from approver
   */

  reviewRequest(requestId: string, reviewAction: string, reviewNote: string | null): Promise<ReviewResponse> {
    const url = `${this.BASE_URL}/${requestId}/activity/review`;
    const payload = {
      review_action: reviewAction,
      note: reviewNote,
    };
    return APIClient.patch(url, payload, CONFIG_OVERRIDE, true, false, false).then((res) => res.data);
  }

  /**
   * This fetches the details of the approval request. includes -
   * approver, message & approval request date
   *
   * @param id magic UUID
   * @returns approval request details
   */
  getRequestDetails(id: UUID): Promise<APIResponse> {
    const url = `${this.BASE_URL}/${id}/approval_activity`;
    return APIClient.get(url, CONFIG_OVERRIDE, true, false, true).then((res) => res.data);
  }

  /**
   * This fetches the details for approval activity stream (top 3
   * stream items) & related transactions
   *
   * @param id magic UUID
   * @returns approval activity stream details
   */
  getActivityDetails(id: UUID): Promise<APIResponse> {
    const url = `${this.BASE_URL}/${id}/activity_stream`;
    return APIClient.get(url, CONFIG_OVERRIDE, true, false, true).then((res) => res.data);
  }

  /**
   * This requests backend to regenerate link for expired approval request
   * if user has not taken any action on it (reviewed/redirected/cancelled)
   * @param id approval_request_uuid or redirect_request_uuid
   * @returns success/failure
   */
  regenerateLink(id: UUID): Promise<APIResponse> {
    const url = `${this.BASE_URL}/${id}/regenerate`;
    return APIClient.post(url, {}, CONFIG_OVERRIDE, true, false, true).then((res) => res.data);
  }
}

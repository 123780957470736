import React, { useEffect, useRef, useState } from "react";
import { Modal } from "@mui/material";
import DOMPurify from "dompurify";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { ActivityType } from "../../../../../../../../types/enums";
import { Attachments, Close, CloseDarken, Email, Forward, Note, Phone, RequestApproval } from "../../../../../../../library/Icons/Icons";
import Button from "../../../../../../../library/Button/Button";
import Chip from "../../../../../../../library/Chip/Chip";
import { DEFAULT_NUMERIC_VALUES, TRIM_COUNT_CONSTANTS, NUMERIC_VALUES } from "../../../../../../../../constants/NumericConstants";
import { CUSTOM_ACTIVITY_FEED_ITEM_CONSTANTS } from "../../../../../../../../constants/StyleConstants";
import { ViewOriginalEmailProps } from "../../../ActivityFeedItemTypes";
import { MAGIC_LINK } from "../../../../../../../../constants/RegexConstants";
import _ from "underscore";
import AttachmentChip from "../../../../../../AttachmentChip/AttachmentChip";
import CustomTooltip from "../../../../../../../library/CustomTooltip/CustomTooltip";
import { TooltipTypes } from "../../../../../../../../constants/TooltipConstants";
import { formatDate, getDisplayDateFormat } from "../../../../../../../../db/utils/date";
import { AlertContext } from "../../../../../../../../contexts/AlertContext";
import TableUtils from "../../../../../../../../utils/TableUtils/TableUtils";
import { AttachmentPreview } from "../../../../../../../library/AtomicComponents/AttachmentPreview";
import { DisableFeatureContext, DisableFeatureProviderType } from "../../../../../../../../contexts/DisableFeatureContext";

const INTERVAL = 300;

export default function ViewOriginalEmail(props: ViewOriginalEmailProps): React.ReactElement {
  const [attachmentsVisible, setAttachmentsVisible] = useState<boolean>(false);
  const iframeRefForOriginal = useRef<HTMLIFrameElement>(null);
  const [showAttachmentList, setShowAttachmentList] = React.useState<boolean>(false);

  const toastContext = React.useContext(AlertContext) as AlertContextType;

  const [openPreview, setOpenPreview] = React.useState<boolean>(false);
  const [activeAttachmentID, setActiveAttachmentID] = React.useState<string | number>("");
  const { setDisableKeyboardShortcut } = React.useContext(DisableFeatureContext) as DisableFeatureProviderType;

  const onClickPreviewClose = (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement> | KeyboardEvent) => {
    event.stopPropagation();
    setOpenPreview(false);
  };

  const onClickPreviewOpen = (event: React.MouseEvent<HTMLDivElement>, activeAttachmentID: string | number) => {
    event.stopPropagation();
    setActiveAttachmentID(activeAttachmentID);
    setOpenPreview(!openPreview);
  };

  const onClickDownload = (
    event: React.MouseEvent<HTMLTableRowElement | HTMLDivElement | HTMLButtonElement>,
    attachments: AttachmentItem[] | BlobAttachmentItem[]
  ) => {
    event.stopPropagation();
    TableUtils.downloadAll(attachments, toastContext?.setToastOptions);
  };

  const toggleAttachments = () => {
    setAttachmentsVisible(!attachmentsVisible);
    setShowAttachmentList(false);
  };
  const toEmailString = Array.isArray(props.to) && props.to?.map((contact: ContactItem) => contact?.name || contact?.email_address).join(", ");
  function downloadAll(files: AttachmentItem[]) {
    //eslint-disable-next-line
    let asyncDownload = setInterval(download, INTERVAL, files);

    function download(files: AttachmentItem[]) {
      const file = files.pop();
      const link = document.createElement("a");
      link.download = file?.file_name ?? "file";
      link.href = file?.file_url ?? "";
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      link.remove();
      if (files.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
        clearInterval(asyncDownload);
        return;
      }
    }
  }

  const icon: React.ReactNode = (() => {
    switch (props.variant) {
      case ActivityType.Email:
        return <Email />;
      case ActivityType.PhoneCall:
        return <Phone />;
      case ActivityType.ApprovalRequest:
      case ActivityType.ApprovalReviewed:
        return <RequestApproval />;
      case ActivityType.Forward:
        return <Forward />;
      default:
        return <Note />;
    }
  })();

  const onClickShowMore = () => {
    setShowAttachmentList(true);
  };

  const AttachmentShowMoreChip: React.FC<{ restCount: number }> = ({ restCount }) => {
    return (
      <CustomTooltip title={"Show More"}>
        <div className="attachment-show-more-chip" onClick={() => onClickShowMore()}>
          <span className="attachment-text">{`${restCount} More`}</span>
        </div>
      </CustomTooltip>
    );
  };

  /**
   * @function isValidMagicLink
   * A helper function to validate if the activity has a valid magic-link
   * @returns boolean
   */
  const isValidMagicLink = () => {
    return !_.isUndefined(props?.magicLink);
  };

  /**
   * @function filterMagicLinkHandle
   * A helper function to filter magic-link handle from email content body
   * @param contentBody - The email content body
   * @returns
   */
  const filterMagicLinkHandle = (contentBody: string) => {
    if (isValidMagicLink()) {
      return contentBody.replace(MAGIC_LINK.HANDLE, "");
    }
    return contentBody;
  };

  /**
   * @function calculateMagicLinkEta
   * A helper function to calculate the number of days remaining for the magic link to expire.
   * @returns number - eta
   */
  const calculateMagicLinkEta = () => {
    const createdOn = new Date(props?.date as string | number | Date);
    const expiryOn = new Date(createdOn.setDate(createdOn.getDate() + NUMERIC_VALUES.CONSTANT_THIRTY));
    const now = new Date();
    const etaInTime = expiryOn.getTime() - now.getTime();
    const etaInDays =
      etaInTime /
      (NUMERIC_VALUES.CONSTANT_TWENTY_FOUR * NUMERIC_VALUES.CONSTANT_SIXTY * NUMERIC_VALUES.CONSTANT_SIXTY * NUMERIC_VALUES.CONSTANT_THOUSAND);
    return Math.ceil(etaInDays);
  };

  /**
   * @function makeMagicLinkEtaString
   * A helper function to generate an eta string for the magic link.
   * @returns string
   */
  const makeMagicLinkEtaString = () => {
    const eta = calculateMagicLinkEta();
    if (eta > NUMERIC_VALUES.CONSTANT_ZERO) {
      return `This link will expire after ${eta} days.`;
    } else if (eta === NUMERIC_VALUES.CONSTANT_ZERO) {
      return `This link will expire today.`;
    } else if (eta < NUMERIC_VALUES.CONSTANT_ZERO) {
      return `This link has expired`;
    }
  };

  useEffect(() => {
    if (openPreview) {
      setDisableKeyboardShortcut(true);
    } else {
      setDisableKeyboardShortcut(false);
    }
    return () => setDisableKeyboardShortcut(false);
  }, [openPreview]);

  return (
    <Modal
      open={props.openOriginalEmail}
      onClose={() => {
        setAttachmentsVisible(false);
        setShowAttachmentList(false);
        props.toggleOriginalEmail();
      }}
    >
      <div className="modal-activity-feed-item-render">
        <span
          className="modal-close-icon"
          onClick={() => {
            setAttachmentsVisible(false);
            props.toggleOriginalEmail();
          }}
        >
          <Close />
        </span>
        <div className="modal-content-activity-feed">
          <div className="head" id="head">
            <div className="icon">{icon}</div>
          </div>
          <div className="original-body">
            <div className="header">
              <p className="body2">
                {props.creator?.name} <span className="activity-variant">{props.header}</span>
              </p>
              {props && props?.attachments?.length ? (
                <div className={`tag attachments ${attachmentsVisible ? "active" : ""}`} onClick={toggleAttachments}>
                  <Attachments />
                  &nbsp;{props?.attachments?.length}
                </div>
              ) : null}
              {!props.is_read && <Chip text="NEW" variant="late1" />}
            </div>
            <div className="subheader-container">
              <p className="subheader caption">
                {(() => {
                  if (
                    props.to &&
                    props.to.length &&
                    (props.variant === ActivityType.Email || props.variant === ActivityType.IncomingEmail || props.variant === ActivityType.PhoneCall)
                  ) {
                    if (props.to.length > TRIM_COUNT_CONSTANTS.SHOW_EMAIL) {
                      return (
                        <CustomTooltip
                          type={toEmailString?.toString()?.length > NUMERIC_VALUES.CONSTANT_TWENTY ? TooltipTypes.RICH : TooltipTypes.PLAIN}
                          title={toEmailString}
                          placement="bottom"
                        >
                          <span>
                            To{" "}
                            {props.to
                              ?.map((contact: ContactItem) => contact?.name || contact?.email_address)
                              .slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, TRIM_COUNT_CONSTANTS.SHOW_EMAIL)
                              .join(", ")}{" "}
                            and {props?.to?.length ? props?.to?.length - TRIM_COUNT_CONSTANTS.SHOW_EMAIL : ""} more&nbsp;•&nbsp;
                          </span>
                        </CustomTooltip>
                      );
                    } else {
                      return <>To {toEmailString}&nbsp;•&nbsp;</>;
                    }
                  } else {
                    return <></>;
                  }
                })()}
                {props.date ? `${(formatDate(props.date), getDisplayDateFormat(props.countryCode))}` : ""}
              </p>
              <p className="subheader caption">Subject: {props.originalEmailContent?.subject ?? ""}</p>
            </div>
            <OverlayScrollbarsComponent options={{ paddingAbsolute: true, autoUpdate: true, sizeAutoCapable: true }}>
              <iframe
                className={`email-container`}
                ref={iframeRefForOriginal}
                srcDoc={DOMPurify.sanitize(filterMagicLinkHandle(props.originalEmailContent?.body ?? ""), { FORBID_TAGS: ["img"] })}
                scrolling="no"
                onLoad={function () {
                  if (iframeRefForOriginal?.current?.contentWindow) {
                    iframeRefForOriginal.current.style.height =
                      iframeRefForOriginal.current.contentWindow.document.body.scrollHeight +
                      CUSTOM_ACTIVITY_FEED_ITEM_CONSTANTS.IFRAME_HEIGHT +
                      "px";
                    iframeRefForOriginal.current.contentWindow.document.body.style.margin = "0px";
                    iframeRefForOriginal.current.contentWindow.document.body.style.fontFamily =
                      '"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans","Helvetica Neue", sans-serif';
                    iframeRefForOriginal.current.contentWindow.document.body.style.fontWeight = "400";
                    iframeRefForOriginal.current.contentWindow.document.body.style.fontSize = "0.875rem";
                    iframeRefForOriginal.current.contentWindow.document.body.style.lineHeight = " 1.25rem";
                    iframeRefForOriginal.current.contentWindow.document.body.style.color = "#4d4d4d";
                  }
                }}
              />

              {isValidMagicLink() && (
                <div className="magic-link-cta-container">
                  <a
                    className={`btn btn-primary btn-lg magic-link-cta ${calculateMagicLinkEta() < NUMERIC_VALUES.CONSTANT_ZERO ? "disabled" : ""}`}
                    href={props?.magicLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p className="btn-text">Open Portal</p>
                  </a>
                  <p className="magic-link-cta-eta">{makeMagicLinkEtaString()}</p>
                </div>
              )}

              {attachmentsVisible ? (
                <div className="attachments-wrapper">
                  <div className="list-wrapper">
                    <div className="attachments-container">
                      {props.attachments?.length <= NUMERIC_VALUES.CONSTANT_THREE || showAttachmentList ? (
                        props.attachments?.map((item: AttachmentItem) => (
                          <AttachmentChip key={item.file_name} attachment={item} onClickPreviewOpen={onClickPreviewOpen} />
                        ))
                      ) : (
                        <>
                          <AttachmentChip
                            key={props.attachments[0].file_name}
                            attachment={props.attachments[0]}
                            onClickPreviewOpen={onClickPreviewOpen}
                          />
                          <AttachmentChip
                            key={props.attachments[1].file_name}
                            attachment={props.attachments[1]}
                            onClickPreviewOpen={onClickPreviewOpen}
                          />
                          <AttachmentShowMoreChip restCount={props.attachments?.length - NUMERIC_VALUES.CONSTANT_TWO} />
                        </>
                      )}
                    </div>
                  </div>
                  <div className="attachments-action">
                    <div className="close-container" onClick={toggleAttachments}>
                      <CloseDarken />
                    </div>
                    <Button
                      size="sm"
                      variant="secondary"
                      alignIcon="left"
                      className="btn btn-secondary downlaod-all"
                      onClick={() => downloadAll([...props.attachments])}
                    >
                      Download All
                    </Button>
                  </div>
                  {openPreview && (
                    <AttachmentPreview
                      openPreview={openPreview}
                      activeAttachmentID={activeAttachmentID}
                      handleClose={onClickPreviewClose}
                      attachments={props.attachments}
                      onClickDownload={onClickDownload}
                    />
                  )}
                </div>
              ) : null}
            </OverlayScrollbarsComponent>
          </div>
        </div>
      </div>
    </Modal>
  );
}

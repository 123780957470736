import React from "react";
import { makeStyles } from "@mui/styles";
import AttachmentsSidebar from "./AttachmensSidebar/AttachmentsSidebar";
import AttachmentsView from "./AttachmentsView/AttachmentsView";
import "./Attachments.scss";
import { Dialog } from "@mui/material";
import { NONE, STYLE_CONSTANTS, VIEW } from "./AttachmentConstants";
import { AttachmentsComponentProps, Count } from "./interfaces/AttachmentsInterfaces";
import { TableConfiguration, TableRow } from "./interfaces/TableConfigurationInterfaces";
import useWorkspaceConfigurations from "../../../../hooks/useWorkspaceConfigurations";
import { WorkspaceType } from "../../../../types/enums";
import { AppContext } from "../../../../contexts/AppContext";

const AttachmentsComponent: React.FC<AttachmentsComponentProps> = ({
  showModal,
  setShowModal,
  openFileExp,
  toggleDialog,
  addAttachmentsCallback,
  invoices,
  payments,
  documents,
}): React.ReactElement => {
  /**
   * overriding styles for MUI dialog modal as per design
   */
  const classes = makeStyles({
    dialog: {
      width: `${STYLE_CONSTANTS.MODAL_HEIGHT}%`,
      height: `${STYLE_CONSTANTS.MODAL_HEIGHT}%`,
      "& .MuiDialog-paper": {
        width: `${STYLE_CONSTANTS.MODAL_PAPER_WIDTH}rem`,
        height: `${STYLE_CONSTANTS.MODAL_PAPER_HEIGHT}rem`,
        maxWidth: `${NONE}`,
        maxHeight: `${NONE}`,
      },
    },
  })();
  const [showLoader, setShowLoader] = React.useState<boolean>(false);
  const [view, setView] = React.useState<string>(VIEW.DOCUMENTS);
  const [table, setTable] = React.useState<TableConfiguration<InvoiceModelV2 | PaymentSummaryModelV2 | AttachmentItem>>(documents);
  const [currentSelection, setCurrentSelection] = React.useState<Count>({ Documents: 0, Invoices: 0, Bills: 0, Payments: 0 });
  const { workspaceType } = useWorkspaceConfigurations();
  const { userStatus } = React.useContext(AppContext) as AppType;

  /**
   * updates the table with required states i.e. states of
   * invoice/bills/documents/payments table when either view OR
   * internal state of any of these tables change
   */
  React.useEffect(() => {
    switch (view) {
      case VIEW.INVOICES:
        setTable(invoices);
        break;
      case VIEW.BILLS:
        setTable(invoices);
        break;
      case VIEW.PAYMENTS:
        setTable(payments);
        break;
      case VIEW.DOCUMENTS:
        setTable(documents);
        break;
      default:
        console.error("Did not find the table user has selected.");
        break;
    }
    setCurrentSelection({
      Documents: documents.meta.selected.length,
      Payments: payments.meta.selected.length,
      ...(workspaceType === WorkspaceType.AR ? { Invoices: invoices.meta.selected.length } : { Bills: invoices.meta.selected.length }),
    });
  }, [view, invoices, payments, documents]);

  const transformAttachment = (name: string, uuid: UUID | number, type: string): InboxAttachment => {
    return { transaction_type: type, transaction_id: uuid, attachment_title: name };
  };

  const onClickAddAttachmentsButton = () => {
    addAttachmentsCallback([
      ...documents.meta.selected.map((item: TableRow<Partial<AttachmentItem>>) =>
        transformAttachment(item.file_name ?? "", item.id ?? "", VIEW.DOCUMENTS)
      ),
      ...payments.meta.selected.map((item: TableRow<Partial<PaymentSummaryModelV2>>) =>
        transformAttachment(item?.reference_code ? `Payment #${item.reference_code}` : "", item?.payment_id ?? "", "payment")
      ),
      ...invoices.meta.selected.map((item: TableRow<Partial<InvoiceModelV2>>) => {
        const transactionItemName = `${workspaceType === WorkspaceType.AR ? "Invoice" : "Bill"} #${item?.invoice_number}`;
        return transformAttachment(transactionItemName ?? "", item?.invoice_id ?? "", "invoice");
      }),
    ]);
    setShowModal(false);
  };
  return (
    <Dialog open={showModal} className={classes.dialog}>
      <div className="attachments-dialog">
        <AttachmentsSidebar
          view={view}
          setView={setView}
          totalRecords={{
            ...(workspaceType === WorkspaceType.AR ? { Invoices: invoices.data.records.length } : { Bills: invoices.data.records.length }),
            Documents: documents.data.records.length,
            Payments: payments.data.records.length,
          }}
          openFileExp={openFileExp}
        />
        <AttachmentsView
          view={view}
          showLoader={showLoader}
          setShowLoader={setShowLoader}
          setShowModal={setShowModal}
          currentSelection={currentSelection}
          setCurrentSelection={setCurrentSelection}
          toggleDialog={toggleDialog}
          onClickAddAttachments={onClickAddAttachmentsButton}
          table={table}
          currencyConfig={userStatus?.currency}
        />
      </div>
    </Dialog>
  );
};

export default AttachmentsComponent;

export const flagScheme: Record<StreamFlag, { [key: string]: string }> = {
  new_vendor: {
    title: "New Vendor",
    color: "purple",
  },
  new_bill: {
    title: "New Bill",
    color: "green",
  },
  bill: {
    title: "Bill",
    color: "orange",
  },
  existing_bill: {
    title: "Existing Bill",
    color: "orange",
  },
};

/* eslint-disable */
export const ADAPTIVE_COEFFICIENT = Object.freeze({
  SMALLER: 0.825, // width >= 1280 < 1920 = 125%
  TARGET: 0.66, // width >= 1280 < 1920 = 100%
  WIDER: 0.528, // width >= 1920 = 80%
});

export const PROJECT_DIMENSIONS = Object.freeze({
  HEIGHT: 1024,
  WIDTH: 1440,
  REM_CONVERTER: 16,
});

export const DEFAULT_VALUES = Object.freeze({
  DEFAULT_FUNCTION: () => {},
  DEFAULT_NUMBER: 0,
  DEFAULT_STRING: "N/A",
});

export const SUPPORTED_APPLICATIONS: { CUSTOMER: string; VENDOR: string } = Object.freeze({
  CUSTOMER: "customer",
  VENDOR: "vendor",
});

import React from "react";
import SecondaryActivity from "../../SecondaryActivity/SecondaryActivity";
import { SecondaryType } from "../../SecondaryActivity/SecondaryActivityTypes";
import { MergeConnectionProps } from "../ActivityItemVariantTypes";
import { useTranslation } from "react-i18next";

const MergeConnection: React.FC<MergeConnectionProps> = ({ connectionName, creator, countryCode }) => {
  const { t } = useTranslation();
  const headerMessage = connectionName
    ? t("activityDetails.feedItems.mergeConnection.headerMessage.mergedWithConnection", { connectionName, ns: "activities" })
    : t("activityDetails.feedItems.mergeConnection.headerMessage.merged", { ns: "activities" });

  return <SecondaryActivity variant={SecondaryType.Base} creator={{ ...creator, message: headerMessage }} countryCode={countryCode} />;
};

export default MergeConnection;

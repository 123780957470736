import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import Button from "../../../../../library/Button/Button";
import styles from "../../../../../../css/_export.module.scss";
import { makeStyles } from "@mui/styles";
import { Close } from "../../../../../library/Icons/Icons";
import { useTranslation } from "react-i18next";

type CancelApprovalRequestProps = {
  open: boolean;
  onClose: VoidFunction;
  onClickCancel: (note: string) => void;
  loader: boolean;
};

const CancelApprovalRequest: React.FC<CancelApprovalRequestProps> = ({ open, loader, onClickCancel, onClose }) => {
  /**
   * custom styles for ap automation settings from
   * activity feed header
   **/
  const customStyles = makeStyles({
    dialog: {
      "& .MuiPaper-root": {
        boxShadow: "none",
        padding: styles.large,
        margin: 0,
        width: "33rem",
        borderRadius: "0.5rem",
      },
      "& .MuiDialogTitle-root": {
        fontFamily: styles.primaryFontFamily,
        fontWeight: 600,
        fontSize: styles.fontSizeXL,
        lineHeight: styles.lineHeightXL,
        color: styles.grey30,
        padding: 0,
        margin: 0,
      },
      "& .MuiDialogContent-root": {
        margin: "1rem 0 1rem 0",
      },
      "& .MuiDialogContentText-root": {
        margin: 0,
        fontFamily: styles.primaryFontFamily,
        fontWeight: 400,
        fontSize: styles.fontSizeSM,
        lineHeight: styles.lineHeightMD,
        color: styles.grey30,
        letterSpacing: 0,
      },
      "& .MuiDialogActions-root": {
        gap: styles.base,
      },
      "& .title": {
        fontFamily: styles.primaryFontFamily,
        fontWeight: 600,
        fontSize: styles.fontSizeXS,
        lineHeight: styles.lineHeightSM,
        letterSpacing: 0,
        color: styles.primary,
        margin: "1rem 0 0.25rem 0",
      },
      "& div": {
        padding: 0,
      },
    },
  })();

  const { t } = useTranslation();

  return (
    <Dialog className={customStyles.dialog} open={open} onClose={onClose}>
      <DialogTitle>{t("modalAndFlyout.cancelApprovalRequest.dialogTitle", { ns: "activities" })}</DialogTitle>
      <Button className="library-modal-close-icon" type="button" variant="solo-icon" onClick={onClose}>
        <Close />
      </Button>
      <DialogContent className="mt-ba mb-lg">
        <DialogContentText>{t("modalAndFlyout.cancelApprovalRequest.dialogMessage", { ns: "activities" })}</DialogContentText>
      </DialogContent>
      <DialogActions className="">
        <Button className="ml-auto" variant="secondary" disabled={loader} onClick={onClose}>
          {t("modalAndFlyout.buttons.goBack", { ns: "activities" })}
        </Button>
        <Button variant="primary" loading={loader} onClick={() => onClickCancel("")}>
          {t("modalAndFlyout.buttons.confirm", { ns: "activities" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelApprovalRequest;

import { styled } from "@mui/system";
import { Input } from "@mui/material";
import Popover from "../../../../atomic/Popover/Popover";
import Dropdown from "../../../../../Dropdown/Dropdown";

export const NumericPopupContainer = styled(Popover)({
  "& .MuiPaper-root": {
    display: "flex",
    flexDirection: "column",
    minHeight: "6.25rem",
    maxHeight: "16rem",
    width: "27.375rem",
    boxShadow: `0 0.5rem 0.75rem rgba(0, 0, 0, 0.1), 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1)`,
    borderRadius: "0.5rem",
    padding: "1rem",
    gap: "0.75rem",
    marginTop: "1.25rem",
  },
});

export const PopupContentRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "0.75rem",
});
export const PopupHeaderTextContainer = styled("div")({
  display: "flex",
  height: "1rem",
  alignItems: "center",
  fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif`,
  fontWeight: "400",
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
  color: "#1a1a1a",
});

export const DropdownAndFieldWrapper = styled("div")({
  display: "flex",
  gap: "0.5rem",
  height: "2.5rem",
});

export const DropdownComponent = styled(Dropdown)({
  ".MuiOutlinedInput-root": {
    width: "11.1875rem", //"max-content",
    ".MuiOutlinedInput-input": {
      color: "#1a1a1a",
      "&:hover": {
        background: "none",
        color: "#1a1a1a",
      },
    },
    ".MuiSelect-icon": {
      color: "#1a1a1a",
    },
    "&:hover": {
      ".MuiSelect-icon": {
        color: "#1a1a1a",
      },
    },
  },
});

export const AmountInputField = styled(Input)({
  width: "100%",
  border: "0.0625rem solid #dee1ed",
  padding: "0.625rem",
  borderRadius: "0.5rem",
  fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif`,
  fontWeight: "400",
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
  color: "#576375",
  ".MuiInputAdornment-root": {
    ".MuiTypography-root": {
      borderRadius: "0.5rem",
      fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif`,
      fontWeight: "400",
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      color: "#576375",
    },
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    WebkitAppearance: "none",
    margin: "0",
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
    margin: "0",
  },
});

export const RangeSliderContainer = styled("div")({
  height: "5.75rem",
});

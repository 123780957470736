import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES, ROTATIONAL_CONSTANTS } from "../../../../constants/NumericConstants";

export const createImage = (url: string) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });
};

export const getRadianAngle = (degreeValue: number) => {
  return (degreeValue * Math.PI) / ROTATIONAL_CONSTANTS.ROTATE_HALF_CLOCKWISE;
};

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export const rotateSize = (width: number, height: number, rotation: number) => {
  const rotRad = getRadianAngle(rotation);

  return {
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
};

export default async function getCroppedImg(
  imageSrc: string,
  pixelCrop: any,
  rotation = DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
  flip = { horizontal: false, vertical: false }
) {
  const image = <HTMLImageElement>await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / NUMERIC_VALUES.CONSTANT_TWO, bBoxHeight / NUMERIC_VALUES.CONSTANT_TWO);
  ctx.rotate(rotRad);
  ctx.scale(
    flip.horizontal ? DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE : DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
    flip.vertical ? DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE : DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
  );
  ctx.translate(-image.width / NUMERIC_VALUES.CONSTANT_TWO, -image.height / NUMERIC_VALUES.CONSTANT_TWO);

  // draw rotated image
  ctx.drawImage(image, ROTATIONAL_CONSTANTS.ZERO_DEVIATION, ROTATIONAL_CONSTANTS.ZERO_DEVIATION);

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, ROTATIONAL_CONSTANTS.ZERO_DEVIATION, ROTATIONAL_CONSTANTS.ZERO_DEVIATION);

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob);
    }, "image/jpeg");
  });
}

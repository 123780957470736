import React, { useState, useEffect } from "react";
import { LeftArrow } from "../../library/Icons/Icons";
import { useHistory } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";
import { DEFAULT_NUMERIC_VALUES } from "../../../constants/NumericConstants";

import "./Breadcrumbs.scss";

type Breadcrumb = {
  id?: string;
  name?: string;
  type?: string;
  path?: string;
};

/**
 * @function BreadcrumbsComponent
 * A react component to render a breadcrumbs based on current url path.
 * This component renders breadcrumbs from url path and no assumptions are made,
 * essentially making it stateless, and there fore can retain breadcrumbs when refreshed
 * or shared to other tabs or browsers.
 */
export default function BreadcrumbsComponent(): React.ReactElement {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);
  const [isBackRequired, setIsBackRequired] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);

  /**
   * URL path patterns
   */
  const dashboardRegExp = new RegExp(/\/[A-Z]{2}[/][0-9]*[/]dashboard/g);
  const activitiesRegExp = new RegExp(/\/[A-Z]{2}[/][0-9]*[/]activities/g);
  const billsRegExp = new RegExp(/\/[A-Z]{2}[/][0-9]*[/]bills/g);
  const invoicesRegExp = new RegExp(/\/[A-Z]{2}[/][0-9]*[/]invoices/g);
  const paymentsRegExp = new RegExp(/\/[A-Z]{2}[/][0-9]*[/]payments/g);
  const vendorsRegExp = new RegExp(/\/[A-Z]{2}[/][0-9]*[/]vendors/g);
  const customersRegExp = new RegExp(/\/[A-Z]{2}[/][0-9]*[/]customers/g);
  const connectionsRegExp = new RegExp(/\/[A-Z]{2}[/][0-9]*[/]connections/g);
  const internalRegExp = new RegExp(/\/[A-Z]{2}[/][0-9]*[/]internal/g);
  const settingsRegExp = new RegExp(/\/settings\/\S*/g);
  const myProfilesRegExp = new RegExp(/\/profiles/g);
  const customerPayments = new RegExp(/\/[A-Z]{2}[/][0-9]*[/]customer-payments/g);
  const vendorPayments = new RegExp(/\/[A-Z]{2}[/][0-9]*[/]vendor-payments/g);

  const history = useHistory();

  /**
   * @function convertToTitleCase
   * A helper function to convert a camel cased or snake cased string to a title case.
   * @param string
   * @returns string
   */
  const convertToTitleCase = (string: string) => {
    const result = string.replace(/([A-Z])/g, " $1");
    return (result.charAt(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO).toUpperCase() + result.slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ONE)).split("_").join("-");
  };

  /**
   * @function getPathConfigurationByRoute
   * A helper function to get configuration object for a route path.
   * @param route
   * @returns
   */
  const getPathConfigurationByRoute = (route: string) => {
    return {
      activities: {
        id: "activities",
        baseCrumb: "All Activities",
        splitPathBy: "activities",
        defaultPath: "/all",
        defaultSearchPath: "?sort=last_activity_at+DESC&page=1",
        defaultDetailTitle: "Activity",
        defaultPathSplitsPosition: DEFAULT_NUMERIC_VALUES.DEFAULT_FOUR,
      },
      invoices: {
        id: "invoices",
        baseCrumb: "All Invoices",
        splitPathBy: "invoices",
        defaultPath: "",
        defaultSearchPath: "?sort=invoice_date+DESC&page=1",
        defaultDetailTitle: "Invoices",
        defaultPathSplitsPosition: DEFAULT_NUMERIC_VALUES.DEFAULT_FOUR,
      },
      bills: {
        id: "bills",
        baseCrumb: "All Bills",
        splitPathBy: "bills",
        defaultPath: "",
        defaultSearchPath: "?sort=invoice_date+DESC&page=1",
        defaultDetailTitle: "Bill",
        defaultPathSplitsPosition: DEFAULT_NUMERIC_VALUES.DEFAULT_FOUR,
      },
      payments: {
        id: "payments",
        baseCrumb: "All Payments",
        splitPathBy: "payments",
        defaultPath: "",
        defaultSearchPath: "?sort=payment_date+DESC&page=1",
        defaultDetailTitle: "Payment",
        defaultPathSplitsPosition: DEFAULT_NUMERIC_VALUES.DEFAULT_FOUR,
      },
      customers: {
        id: "customers",
        baseCrumb: "All Customers",
        splitPathBy: "customers",
        defaultPath: "/active",
        defaultSearchPath: "?sort=amount_past_due+DESC&page=1",
        defaultDetailTitle: "Customer",
        defaultPathSplitsPosition: DEFAULT_NUMERIC_VALUES.DEFAULT_FOUR,
      },
      vendors: {
        id: "vendors",
        baseCrumb: "All Vendors",
        splitPathBy: "vendors",
        defaultPath: "/active",
        defaultSearchPath: "?sort=amount_billed_past_thirty_days+DESC&page=1",
        defaultDetailTitle: "Vendor",
        defaultPathSplitsPosition: DEFAULT_NUMERIC_VALUES.DEFAULT_FOUR,
      },
      connections: {
        id: "connections",
        baseCrumb: "Other Connections",
        splitPathBy: "connections",
        defaultPath: "/active",
        defaultSearchPath: "?sort=last_activity_at+DESC&page=1",
        defaultDetailTitle: "Connection",
        defaultPathSplitsPosition: DEFAULT_NUMERIC_VALUES.DEFAULT_FOUR,
      },
      internal: {
        id: "internal",
        baseCrumb: "My Company",
        splitPathBy: "internal",
        defaultPath: "/active",
        defaultSearchPath: "?sort=last_activity_at+DESC&page=1",
        defaultDetailTitle: "Connection",
        defaultPathSplitsPosition: DEFAULT_NUMERIC_VALUES.DEFAULT_FOUR,
      },
      customerPayments: {
        id: "customer-payments",
        baseCrumb: "Customer Payments",
        splitPathBy: "customer-payments",
        defaultPath: "",
        defaultSearchPath: "?sort=payment_date+DESC%2C+reference_code+DESC",
        defaultDetailTitle: "Payment",
        defaultPathSplitsPosition: DEFAULT_NUMERIC_VALUES.DEFAULT_FOUR,
      },
      vendorPayments: {
        id: "vendor-payments",
        baseCrumb: "Vendor Payments",
        splitPathBy: "vendor-payments",
        defaultPath: "",
        defaultSearchPath: "?sort=payment_date+DESC%2C+reference_code+DESC",
        defaultDetailTitle: "Payment",
        defaultPathSplitsPosition: DEFAULT_NUMERIC_VALUES.DEFAULT_FOUR,
      },
    }[route];
  };

  const breadcrumbLabelLookup = (key: string | undefined) => {
    if (key) {
      return (
        {
          userPermissions: "Users and Permissions",
          apAutomation: "Forward to AP Automation",
          emailSettings: "Email Settings",
          inboxSettings: "Inbox Settings",
          workspaceSettings: "Workspace Settings",
          notificationsSettings: "Notifications Settings",
          templateSettings: "Template Settings",
          signatureSettings: "Signature Settings",
          Mine: "Assigned to Me",
          activity: "Activity",
        }[key] ?? key
      );
    }
    return key;
  };

  /**
   * @function checkAnyPattern
   * A helper function to check if a route path string matches any of the patterns
   * @param route
   * @returns
   */
  const checkAnyPattern = (route: string) => {
    const multiPatternRegex = /(\b((invoices)|(payments)|(bills)|(contactProfile)|(activities))\b)(?!.*\b\1\b)/g;
    const matched = route.match(multiPatternRegex) as string[];
    if (matched) {
      if (matched[DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO] && matched[DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO] == "contactProfile") {
        return {
          path: "contacts",
          name: "contact",
          splitBy: matched[DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO],
        };
      }
      return {
        path: matched[DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO],
        name:
          matched[DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO] == "activities"
            ? "activity"
            : matched[DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO].slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE),
        splitBy: matched[DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO],
      };
    }
    return null;
  };

  /**
   * @function prepareCrumbsForRoute
   * A helper function to prepare breadcrumb for a route path, based on the configuration
   * obtained from checkAnyPattern()
   * @param route
   * @returns
   */
  const prepareCrumbsForRoute = (route: string) => {
    const crumbConfig = getPathConfigurationByRoute(route);
    const breadCrumbs = [
      {
        name: crumbConfig?.baseCrumb,
        type: "root",
        path:
          location.pathname.split(crumbConfig?.splitPathBy ?? "")[DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO] +
          `${crumbConfig?.splitPathBy}${crumbConfig?.defaultPath}${crumbConfig?.defaultSearchPath}`,
      },
    ];
    const pathSplitBySlash = location.pathname.split("/");
    for (let i = DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO; i <= pathSplitBySlash.length; i++) {
      if (
        i >= (crumbConfig?.defaultPathSplitsPosition ?? DEFAULT_NUMERIC_VALUES.DEFAULT_FOUR) &&
        pathSplitBySlash[i] &&
        !pathSplitBySlash[i].match(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/)
      ) {
        const pattern = checkAnyPattern(pathSplitBySlash[i]);
        breadCrumbs.push({
          name: breadcrumbLabelLookup(pattern ? pattern.path : convertToTitleCase(pathSplitBySlash[i])),
          type: "index",
          path: pattern
            ? location.pathname.split(pattern.splitBy)[DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO]
            : location.pathname.split(pathSplitBySlash[i])[DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO] +
              pathSplitBySlash[i] +
              crumbConfig?.defaultSearchPath,
        });
      } else if (pathSplitBySlash[i] && pathSplitBySlash[i].match(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/)) {
        const pattern = checkAnyPattern(pathSplitBySlash[i - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE]);
        breadCrumbs.push({
          name: breadcrumbLabelLookup(pattern ? pattern.name : crumbConfig?.defaultDetailTitle),
          type: "detail",
          path: location.pathname.split(pathSplitBySlash[i])[DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO] + pathSplitBySlash[i],
        });
      }
    }
    if (crumbConfig?.id == "activities") {
      if (breadCrumbs.length >= DEFAULT_NUMERIC_VALUES.DEFAULT_TWO) {
        setIsBackRequired(true);
      }
    } else {
      if (breadCrumbs.length) {
        setIsBackRequired(true);
      }
    }
    if (pathSplitBySlash[pathSplitBySlash.length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE] === "mine") {
      setIsBackRequired(false);
    }
    return breadCrumbs;
  };

  /**
   * @function generateBreadCrumbsArray
   * A helper function to generate breadcrumbs array
   */
  const generateBreadCrumbsArray = () => {
    let breadCrumbs = [] as Breadcrumb[];
    setIsBackRequired(false);
    if (dashboardRegExp.test(location.pathname.toString())) {
      breadCrumbs = [{ name: "Dashboard", type: "dashboard", path: location.pathname }];
    }
    if (activitiesRegExp.test(location.pathname.toString())) {
      breadCrumbs = prepareCrumbsForRoute("activities");
    }

    if (billsRegExp.test(location.pathname.toString())) {
      breadCrumbs = prepareCrumbsForRoute("bills");
    }

    if (invoicesRegExp.test(location.pathname.toString())) {
      breadCrumbs = prepareCrumbsForRoute("invoices");
    }

    if (paymentsRegExp.test(location.pathname.toString())) {
      breadCrumbs = prepareCrumbsForRoute("payments");
    }

    if (vendorsRegExp.test(location.pathname.toString())) {
      breadCrumbs = prepareCrumbsForRoute("vendors");
    }

    if (customersRegExp.test(location.pathname.toString())) {
      breadCrumbs = prepareCrumbsForRoute("customers");
    }

    if (connectionsRegExp.test(location.pathname.toString())) {
      breadCrumbs = prepareCrumbsForRoute("connections");
    }

    if (internalRegExp.test(location.pathname.toString())) {
      breadCrumbs = prepareCrumbsForRoute("internal");
    }

    if (customerPayments.test(location.pathname.toString())) {
      breadCrumbs = prepareCrumbsForRoute("customerPayments");
    }

    if (vendorPayments.test(location.pathname.toString())) {
      breadCrumbs = prepareCrumbsForRoute("vendorPayments");
    }

    if (settingsRegExp.test(location.pathname.toString())) {
      const pathSplitBySlash = location.pathname.split("/");
      for (let i = DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO; i <= pathSplitBySlash.length; i++) {
        if (i <= DEFAULT_NUMERIC_VALUES.DEFAULT_TWO && pathSplitBySlash[i]) {
          breadCrumbs.push({
            name: breadcrumbLabelLookup(pathSplitBySlash[i]) ?? "",
            type: "settings-index",
            path: "/settings",
          });
        }
      }
      if (breadCrumbs.length) {
        setIsBackRequired(false);
      }
    }

    if (myProfilesRegExp.test(location.pathname.toString())) {
      setIsBackRequired(true);
    }

    setBreadcrumbs(breadCrumbs);
  };

  useEffect(() => {
    generateBreadCrumbsArray();
  }, [location.pathname]);

  const navigateToCrumb = (path: string) => {
    setAnchorEl(null);
    history.push(path);
  };

  /**
   * @function isBreadCrumbRequired
   * A helper function to check whether breadcrumbs needs to be rendered or not
   * @returns boolean
   */
  const isBreadCrumbRequired = () => {
    const nonCrumbTypes = breadcrumbs.filter((item) => item.type == "root" || item.type == "index");
    if (nonCrumbTypes.length == breadcrumbs.length) {
      return false;
    }
    return true;
  };

  /**
   * @function Separator
   * A helper function to render a separator
   * @returns
   */
  const Separator = () => {
    return (
      <li className="breadcrumbs-list-item">
        <span className="breadcrumb-item-separator">/</span>
      </li>
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const MAX_BREADCRUMBS_TO_SHOW = DEFAULT_NUMERIC_VALUES.DEFAULT_THREE;
  const IS_MENU_REQUIRED = breadcrumbs.length > MAX_BREADCRUMBS_TO_SHOW;
  const breadCrumbsArray = [] as React.ReactElement[];
  const breadCrumbsCollapsedListArray = [] as React.ReactElement[];

  if (breadcrumbs && breadcrumbs.length) {
    breadcrumbs.forEach((crumb, index) => {
      if (crumb.type == "dashboard") {
        return;
      }
      if (IS_MENU_REQUIRED && index > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && index < breadcrumbs.length - DEFAULT_NUMERIC_VALUES.DEFAULT_TWO) {
        breadCrumbsCollapsedListArray.push(
          <MenuItem key={index} onClick={() => navigateToCrumb(crumb.path ?? "")}>
            <li key={index} className="breadcrumbs-list-item" data-key={index}>
              <span className="crumb" data-href={crumb.path}>
                {crumb.name}
              </span>
            </li>
          </MenuItem>
        );
        if (index === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) {
          breadCrumbsArray.push(
            <li key={index} className="breadcrumbs-list-item" onClick={handleClick}>
              <span className="breadcrumb-item-collapse">...</span>
            </li>
          );
          breadCrumbsArray.push(<Separator key={index + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE * Math.random()} />);
        }
        return;
      }
      breadCrumbsArray.push(
        <>
          <li key={index} className={`breadcrumbs-list-item ${crumb?.type ? crumb.type+"-crumb" : ""}`} data-key={index} onClick={() => navigateToCrumb(crumb.path ?? "")}>
            <span className="crumb" data-href={crumb.path}>
              {crumb.name}
            </span>
          </li>
          {index < breadcrumbs.length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE ? (
            <Separator key={index + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE * Math.random()} />
          ) : null}
        </>
      );
    });
  }

  return (
    <div className="breadcrumbs-wrapper">
      {isBackRequired && (
        <span className="breadcrumbs-back-navigation" onClick={() => window.history.back()}>
          <LeftArrow />
        </span>
      )}
      {isBreadCrumbRequired() && (
        <nav className="breadcrumbs-nav-container">
          <ol className="breadcrumbs-list-container">{breadCrumbsArray}</ol>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} className={"breadcrumbs-collapse-list"}>
            {breadCrumbsCollapsedListArray}
          </Menu>
        </nav>
      )}
    </div>
  );
}

import React from "react";
import Loading from "../../library/Loading/Loading";
import { TableWithInfiniteScroll } from "../../library/TableWithInfiniteScroll";
import { SearchResultsComponentInterface } from "../Interfaces/SearchResultsInterfaces";

const ResultsViewComponent: React.FC<SearchResultsComponentInterface> = ({
  gsrConfig: { initialLoading },
  tableConfig,
}: SearchResultsComponentInterface) => {
  return (
    <div className="gsr-component-wrapper">
      {initialLoading ? (
        <Loading />
      ) : (
        <div className="gsr-component-container">
          <TableWithInfiniteScroll tableConfig={tableConfig} />
        </div>
      )}
    </div>
  );
};

export default ResultsViewComponent;

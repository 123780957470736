import React from "react";
import { Note } from "../../../../../../library/Icons/Icons";
import PrimaryActivity from "../../PrimaryActivity/PrimaryActivity";
import { NoteProps } from "../ActivityItemVariantTypes";
import * as Labels from "../../../../../../../constants/config";

// primary activity
const NoteActivity: React.FC<NoteProps> = (props) => {
  return <PrimaryActivity {...{ ...props, creator: { ...props.creator, message: Labels.HEADER_NOTE }, icon: <Note /> }} />;
};

export default NoteActivity;

import React, { useEffect } from "react";
import { ContactsCard } from "../../../../library/AtomicComponents/ContactsCard";
import { NUMERIC_VALUES } from "../../../../library/AtomicComponents/constants/numeric.constants";
import { useTranslation } from "react-i18next";

interface CompanyProfileProps {
  contacts: ContactItem[];
}

export default function CompanyProfile({ contacts }: CompanyProfileProps): React.ReactElement {
  const [activeCard, setActiveCard] = React.useState<string>("");
  const [primaryContactsData, setPrimaryContactsData] = React.useState<Array<ContactItem>>([]);
  const [otherContactsData, setOtherContactsData] = React.useState<Array<ContactItem>>([]);
  const { t } = useTranslation();
  const primaryContactTitle = t("activityDetails.rightPanelDrawer.profileDrawer.companProfile.primaryContact", { ns: "activities" });
  const otherContactsTitle = t("activityDetails.rightPanelDrawer.profileDrawer.companProfile.otherContacts", { ns: "activities" });

  /**
   * @function partition
   * A helper function to populate primaryContactsData and otherContactsData
   * from the contacts props received as input using the key is_primary
   * @param contacts Array of contacts both primary and others
   *
   * @returns primaryContacts otherContacts
   */
  const partition = (contacts: ContactItem[]) => {
    const primaryContacts = [] as ContactItem[];
    const otherContacts = [] as ContactItem[];

    return contacts.reduce(
      ([primaryContacts, otherContacts], elem) =>
        elem.is_primary ? [[...primaryContacts, elem], otherContacts] : [primaryContacts, [...otherContacts, elem]],
      [primaryContacts, otherContacts]
    );
  };

  useEffect(() => {
    const [primaryContacts, otherContacts] = partition(contacts);
    setPrimaryContactsData(primaryContacts);
    setOtherContactsData(otherContacts);
    setActiveCard(primaryContacts.length > NUMERIC_VALUES.CONSTANT_ZERO ? primaryContactTitle : otherContactsTitle);
  }, [contacts]);

  return (
    <>
      {primaryContactsData.length > NUMERIC_VALUES.CONSTANT_ZERO && (
        <ContactsCard title={primaryContactTitle} contacts={primaryContactsData} activeCard={activeCard} setActiveCard={setActiveCard} />
      )}
      <br />
      {otherContactsData.length > NUMERIC_VALUES.CONSTANT_ZERO && (
        <ContactsCard title={otherContactsTitle} contacts={otherContactsData} activeCard={activeCard} setActiveCard={setActiveCard} />
      )}
    </>
  );
}

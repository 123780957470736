import React from "react";
import { Typography } from "@mui/material";
import theme from "../theme/theme";
import { MessageProps } from "./types/Textbox";

const TextMessage: React.FC<MessageProps> = ({ variant, children }) => {
  return (
    <Typography fontWeight={500} color={theme.palette?.[variant].contrastText} variant="body1Med">
      {children}
    </Typography>
  );
};

export default TextMessage;

import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { AuthenticationResult, InteractionRequiredAuthError } from "@azure/msal-common";
import Utils from "../utils/utils";
import { msalInstance } from "../index";
import { loginRequest, silentRequest } from "../auth/authConfig";
import _ from "underscore";

const singletonEnforcer = Symbol("APIClientSingletonEnforcer");

type ParamsWithNoData = [
  url: string,
  config?: AxiosRequestConfig,
  overrideMSAL?: boolean,
  mockUrl?: boolean,
  isMagicUrl?: boolean,
  fetchPlatform?: boolean
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ParamsWithData = [url: string, data?: any, config?: AxiosRequestConfig, overrideMSAL?: boolean, mockUrl?: boolean, isMagicUrl?: boolean];

class APIClient {
  private static clientInstance: APIClient;

  private readonly session: AxiosInstance;

  constructor(enforcer: symbol) {
    if (enforcer !== singletonEnforcer) {
      throw new Error("Cannot construct singleton");
    }

    const axiosConfig = {
      baseURL: process.env.REACT_APP_API_ENDPOINT_PLATFORM + "/",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "-1",
      },
    };

    this.session = axios.create(axiosConfig);
  }

  static get instance() {
    if (!this.clientInstance) {
      this.clientInstance = new APIClient(singletonEnforcer);
    }

    return this.clientInstance;
  }

  private async getMsalToken() {
    const account = msalInstance.getActiveAccount();
    return await msalInstance
      .acquireTokenSilent({
        ...silentRequest,
        authority: `https://${process.env.REACT_APP_AZURE_B2C_KNOWN_AUTHORITY}/${process.env.REACT_APP_AZURE_B2C_KNOWN_AUTHORITY}/${account?.idTokenClaims?.acr}`,
        account: account ?? undefined,
      })
      .then((res: AuthenticationResult) => {
        return res?.accessToken ?? "";
      })
      // Silent request fails, default to interactive login
      .catch(async (error) => {
        if (error instanceof InteractionRequiredAuthError) {
          return await msalInstance.acquireTokenRedirect(loginRequest).catch((acquireTokenError) => {
            console.log(acquireTokenError);
          });
        }
      });
  }

  private async getToken(isMagicToken = false) {
    if (isMagicToken) {
      // get magic token from session storage
      return sessionStorage.getItem("token") ?? "";
    }
    return this.getMsalToken();
  }

  /**
   * @function getGroupKey
   * A helper method to read the group key from localStorage. This value will be sent with every call to api server
   * under the header key "Group-Key", which will be cached using the cache key bearertoken + groupkey.
   * If this key is not available in cache, a fresh fetch is made at server side to update the group key
   * respective to the logged in user.
   * @returns String - group-key
   */
  private getGroupKey() {
    return window.localStorage.getItem("groupKey") || "";
  }

  private getLocale() {
    return window.localStorage.getItem("locale");
  }

  private async getConfig(
    config?: AxiosRequestConfig,
    overrideMSAL?: boolean,
    mockUrl?: boolean,
    isMagicUrl?: boolean,
    data?: any,
    fetchPlatform = false
  ) {
    if (mockUrl) return this.checkForMockURL();

    // We set some config defaults and then let the caller provide additional ones or override those defaults
    let requestConfig: AxiosRequestConfig = {
      headers: {
        Accept: "text/plain",
        "Content-Type": "application/json",
        ApplicationName: "Lockstep Inbox",
        "Accept-Language": this.getLocale(),
        ...(!fetchPlatform && { "Group-Key": this.getGroupKey() }),
      },
    };

    if (config) {
      requestConfig = { ...requestConfig, ...config };
    }

    if (data) {
      requestConfig = { ...requestConfig, ...config, data: data };
    }

    if (_.isUndefined(overrideMSAL) || !overrideMSAL) {
      requestConfig.headers["Authorization"] = `Bearer ${await this.getToken()}`;
    }

    if (!_.isUndefined(isMagicUrl) && isMagicUrl) {
      requestConfig.headers["Authorization"] = `MagicToken ${await this.getToken(isMagicUrl)}`;
    }

    /**
     * If the authentication/login is established through magic-link token, then this header needs
     * to be set inorder to differentiate between regular api access and magic-link based api access
     */
    if (Utils.isMagicLinkAuthRequest()) {
      requestConfig.headers["admin-grant"] = JSON.parse(window.localStorage.getItem("adminGrant") ?? "");
    }

    return requestConfig;
  }

  private async makeGetRequest(
    url: string,
    config?: AxiosRequestConfig,
    overrideMSAL?: boolean,
    mockUrl?: boolean | undefined,
    isMagicToken?: boolean,
    fetchPlatform = false
  ) {
    const requestConfig = await this.getConfig(config, overrideMSAL, mockUrl, isMagicToken, undefined, fetchPlatform);
    return this.session.get(url, requestConfig);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async makePostRequest(
    url: string,
    data: any,
    config?: AxiosRequestConfig,
    overrideMSAL?: boolean,
    mockUrl?: boolean | undefined,
    isMagicToken?: boolean
  ) {
    const requestConfig = await this.getConfig(config, overrideMSAL, mockUrl, isMagicToken);
    return this.session.post(url, data, requestConfig);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async makePutRequest(
    url: string,
    data: any,
    config?: AxiosRequestConfig,
    overrideMSAL?: boolean,
    mockUrl?: boolean | undefined,
    isMagicToken?: boolean
  ) {
    const requestConfig = await this.getConfig(config, overrideMSAL, mockUrl, isMagicToken);
    return this.session.put(url, data, requestConfig);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async makePatchRequest(
    url: string,
    data: any,
    config?: AxiosRequestConfig,
    overrideMSAL?: boolean,
    mockUrl?: boolean | undefined,
    isMagicToken?: boolean
  ) {
    const requestConfig = await this.getConfig(config, overrideMSAL, mockUrl, isMagicToken);
    return this.session.patch(url, data, requestConfig);
  }

  private async makeDeleteRequest(
    url: string,
    config?: AxiosRequestConfig,
    overrideMSAL?: boolean,
    mockUrl?: boolean | undefined,
    isMagicToken?: boolean,
    fetchPlatform = false
  ) {
    const requestConfig = await this.getConfig(config, overrideMSAL, mockUrl, isMagicToken, fetchPlatform);
    return this.session.delete(url, requestConfig);
  }

  private async makeDeleteRequestWithBody(
    url: string,
    data: any,
    config?: AxiosRequestConfig,
    overrideMSAL?: boolean,
    mockUrl?: boolean | undefined,
    isMagicUrl?: boolean | undefined
  ) {
    const requestConfig = await this.getConfig(config, overrideMSAL, mockUrl, isMagicUrl, data);
    return this.session.delete(url, requestConfig);
  }

  private async makeHeadRequest(
    url: string,
    config?: AxiosRequestConfig,
    overrideMSAL?: boolean,
    mockUrl?: boolean | undefined,
    isMagicToken?: boolean,
    fetchPlatform = false
  ) {
    const requestConfig = await this.getConfig(config, overrideMSAL, mockUrl, isMagicToken, undefined, fetchPlatform);
    return this.session.head(url, requestConfig);
  }

  private checkForMockURL(url?: string) {
    const requestConfig: AxiosRequestConfig = {
      baseURL: url || process.env.REACT_APP_MOCK_SERVER_URL + "/",
      headers: {
        Accept: "text/plain",
        "Content-Type": "application/json",
      },
    };
    return requestConfig;
  }

  get = (...params: ParamsWithNoData) => {
    return this.makeGetRequest(...params);
  };
  post = (...params: ParamsWithData) => {
    return this.makePostRequest(...params);
  };
  put = (...params: ParamsWithData) => {
    return this.makePutRequest(...params);
  };
  patch = (...params: ParamsWithData) => {
    return this.makePatchRequest(...params);
  };
  delete = (...params: ParamsWithNoData) => {
    return this.makeDeleteRequest(...params);
  };
  head = (...params: ParamsWithNoData) => {
    return this.makeHeadRequest(...params);
  };
  /**
   * It allows to call delete with a body/payload.
   *
   * @param {ParamsWithData} params The params contain url, data and other configs related to Axios
   * @returns { AxiosResponse } returns AxiosResponse object
   */
  bulkDelete = (...params: ParamsWithData) => {
    return this.makeDeleteRequestWithBody(...params);
  };
}

export default APIClient.instance;

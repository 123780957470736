import React from "react";
import SecondaryActivity from "../../SecondaryActivity/SecondaryActivity";
import { SecondaryType } from "../../SecondaryActivity/SecondaryActivityTypes";
import { ApAutomationProps } from "../ActivityItemVariantTypes";
import automationProviders, { evaluateProvider } from "../../../../../../Settings/ApAutomation/providerDetails";
import { Button } from "../../../../../../library/AtomicComponents/atomic/Button";
import { useTranslation } from "react-i18next";

const ApAutomation: React.FC<ApAutomationProps> = ({ onClickResend, creator, email, readonly, countryCode }) => {
  const { t } = useTranslation();
  const provider = automationProviders[evaluateProvider(email) ?? "others"].alt;
  creator = {
    ...creator,
    name: t("activityDetails.feedItems.apAutomation.creatorName", { ns: "activities" }),
    message:
      provider === "Other"
        ? t("activityDetails.feedItems.apAutomation.headerMessage.forwardedToErp", { ns: "activities" })
        : t("activityDetails.feedItems.apAutomation.headerMessage.forwarderToOther", { provider, ns: "activities" }),
  };

  const actions = !readonly
    ? [
        <Button variant="secondary" size="small" onClick={onClickResend} key="resend">
          {t("activityDetails.feedItems.apAutomation.resendButton", { ns: "activities" })}
        </Button>,
      ]
    : [];

  return (
    <SecondaryActivity
      variant={SecondaryType.Base}
      creator={creator}
      flags={{ TIME_SAVING_INDICATOR: true }}
      headerActions={actions}
      countryCode={countryCode}
    />
  );
};

export default ApAutomation;

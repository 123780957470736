export const SyncStatusCodes = {
  success: "Success",
  failure: "Failed",
  cancelled: "Cancelled",
};

export const SyncStatusTexts = {
  connected: "Connected to {{ERP}}",
  not_connected: "Not connected",
  failed_to_connect: "{{ERP}} sync has failed",
  unknown: "N/A",
  sync_success: "Successfully synced",
  sync_failed: "Failed to sync",
  not_connected_details: "Please connect your ERP to Sage Network via the account application or contact support for assistance",
  failed_to_connect_details: "Please try connecting to your ERP again, or contact support for additional support.",
  success_details: "Last successful connection was on {{Date}}",
};

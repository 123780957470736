import { Configuration, LogLevel, RedirectRequest, SilentRequest } from "@azure/msal-browser";
import * as UUID from "uuid";
import Utils from "../utils/utils";

/**
 * Config object to be passed to MSAL on creation
 */

/**
 * @constant msalConfig
 * @type {Configuration}
 * This object allows you to configure important elements of MSAL functionality and is passed into
 * the constructor of PublicClientApplication
 * In this config, navigateToLoginRequestUrl, which is a boolean indicating whether to navigate
 * to the original request URL after the auth server navigates to the redirect URL, is set as true
 * when there is no magic-link flag set or else its set as false to allow root redirect.
 */
export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID ?? "",
    authority: process.env.REACT_APP_AZURE_B2C_AUTHORITY,
    knownAuthorities: [`${process.env.REACT_APP_AZURE_B2C_KNOWN_AUTHORITY}`],
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin + "/logout-success",
    navigateToLoginRequestUrl: Utils.isMagicLinkAuthRequest() ? false : true,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    allowRedirectInIframe: true,
    iframeHashTimeout: 10000,
    ...((Utils.isLocal() || Utils.isDev() || Utils.isQA()) && {
      loggerOptions: {
        loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
            default:
              console.log(message);
              return;
          }
        },
      },
    }),
  },
};

/**
 *  The scopes used for acquiring an access token from various MS Identity Platform endpoints
 * are specified in the following configuration objects.
 */

/**
 * @constant loginRequest
 * @type {RedirectRequest}
 * RedirectRequest: Request object passed by user to retrieve a Code from the server
 * (first leg of authorization code grant flow) with a full page redirect.
 * The scopes for this configuration object can be found in the following.
 * @see {@link https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/src/request/RedirectRequest.ts | RedirectRequest.ts}
 * Here we are using redirectUri and redirectStartPage together, in which the redirect URI where authentication responses can be received by your application.
 * It must exactly match one of the redirect URIs registered in the Azure portal.
 * And redirectStartPage is the page that should be returned to after loginRedirect or acquireTokenRedirect. This should only be used if this is different
 * from the redirectUri and will default to the page that initiates the request. When the navigateToLoginRequestUrl config option is set to false this parameter will be ignored.
 */
export const loginRequest: RedirectRequest = {
  scopes: ["openid", process.env.REACT_APP_B2C_LockstepAPI_Scope || ""],
  redirectUri: window.location.origin,
  redirectStartPage: window.location.origin + window.location.pathname + window.location.search,
};

export const signupRequest: RedirectRequest = {
  scopes: ["openid", process.env.REACT_APP_B2C_LockstepAPI_Scope || ""],
  authority: process.env.REACT_APP_B2C_SU_Authority,
  redirectUri: window.location.origin,
  redirectStartPage: window.location.origin,
};

export const silentRequest: SilentRequest = {
  scopes: ["openid", process.env.REACT_APP_B2C_LockstepAPI_Scope || ""],
  redirectUri: window.location.origin + "/auth",
};

// authorize uri and contanst query params
export const AUTHORIZE_URI = `https://${process.env.REACT_APP_AZURE_B2C_KNOWN_AUTHORITY}/${process.env.REACT_APP_AZURE_B2C_KNOWN_AUTHORITY}/oauth2/v2.0/authorize`;
export const REDIRECT_URI = `${encodeURIComponent(window.location.origin + "/auth")}`;
export const CLIENT_ID = process.env.REACT_APP_AZURE_CLIENT_ID ?? "";
export const NONCE = "defaultNonce";
export const SCOPE = "openid";
export const RESPONSE_TYPE = "id_token";
export const PROMPT = "login";
export const RESPONSE_MODE = "query";

export const buildAuthRedirectURL = (connector: string, appId: string, localStorageKey?: string): string => {
  const id = UUID.v4().replace(/-/g, "");
  const state = encodeURIComponent(`${connector}|${appId}|` + id);

  // We save the state value so we can compare it to what the given ERP returns in its redirect, as a security measure
  localStorage.setItem(localStorageKey ?? "ERPAuthState", id);

  const redirectURL = process.env.REACT_APP_REDIRECT_URL ?? "";

  let scopes: Array<string>;
  let url = "";
  switch (connector) {
    case "QuickBooks Online":
      scopes = ["openid", "profile", "email", "address", "phone", "com.intuit.quickbooks.accounting"];

      url =
        "https://appcenter.intuit.com/connect/oauth2?" +
        "response_type=code" +
        "&client_id=" +
        process.env.REACT_APP_QUICKBOOKS_CLIENT_ID +
        "&redirect_uri=" +
        encodeURIComponent(redirectURL) +
        "&scope=" +
        scopes.join("%20") +
        "&state=" +
        state;

      break;
    case "Xero":
      scopes = [
        "openid",
        "profile",
        "email",
        "offline_access",
        "accounting.transactions.read",
        "accounting.contacts.read",
        "accounting.settings.read",
        "accounting.attachments.read",
        "accounting.reports.read",
      ];

      url =
        "https://login.xero.com/identity/connect/authorize?" +
        "response_type=code" +
        "&client_id=" +
        process.env.REACT_APP_XERO_CLIENT_ID +
        "&redirect_uri=" +
        encodeURIComponent(redirectURL) +
        "&scope=" +
        scopes.join("%20") +
        "&state=" +
        state;

      break;
    case "Microsoft Office":
    case "Microsoft Outlook":
      scopes = ["user.read", "openid", "profile", "offline_access", "mail.read", "mail.send", "mail.readwrite"];

      url =
        "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?response_type=code" +
        "&client_id=" +
        process.env.REACT_APP_MICROSOFT_CLIENT_ID +
        "&redirect_uri=" +
        encodeURIComponent(redirectURL) +
        "&scope=" +
        scopes.join("%20") +
        "&state=" +
        state +
        "&prompt=select_account";

      break;
    case "Gmail":
      scopes = ["https://www.googleapis.com/auth/gmail.readonly", "https://www.googleapis.com/auth/gmail.send"];

      url =
        "https://accounts.google.com/o/oauth2/v2/auth?" +
        "&scope=" +
        scopes.join("%20") +
        "&access_type=offline" +
        "&include_granted_scopes=true" +
        "&response_type=code" +
        "&state=" +
        state +
        "&redirect_uri=" +
        encodeURIComponent(redirectURL) +
        "&client_id=" +
        process.env.REACT_APP_GOOGLE_CLIENT_ID +
        "&prompt=consent";

      break;
  }

  return url;
};

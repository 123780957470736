import React from "react";
import { ProfileShareProps } from "../ActivityItemVariantTypes";
import { Player } from "@lottiefiles/react-lottie-player";
import "./ProfileShare.scss";
import Confetti from "../../../../../../../assets/lotties/confetti.json";
import SecondaryActivity from "../../SecondaryActivity/SecondaryActivity";
import { SecondaryType } from "../../SecondaryActivity/SecondaryActivityTypes";
import { DATE_CONSTANTS_IN_MIN, DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../../../../../../constants/NumericConstants";
import useLocale from "../../../../../../../hooks/useLocale";
import { useTranslation } from "react-i18next";

const ProfileShare: React.FC<ProfileShareProps> = ({ creator, isShowPrimaryProfileShare, mailList, showAPOrARProfile, countryCode }) => {
  const { t } = useTranslation();
  const creatorNew = {
    ...creator,
    message: showAPOrARProfile
      ? t("activityDetails.feedItems.profileShareAndRequest.headerMessage.sharedProfile.ap", { ns: "activities" })
      : t("activityDetails.feedItems.profileShareAndRequest.headerMessage.sharedProfile.ar", { ns: "activities" }),
  };
  const isAfter24hrs = creator.createdOn
    ? (new Date().getTime() - new Date(creator.createdOn).getTime()) / DATE_CONSTANTS_IN_MIN.HRS_IN_MILIS > NUMERIC_VALUES.CONSTANT_TWENTY_FOUR
    : false;
  const { formatDateBasedOnCountry: formatDate } = useLocale();

  const ReceiversList: string = React.useMemo(() => {
    let stringToRet = "";
    if (mailList.to) {
      mailList.to?.forEach((contact, index) => {
        if (index === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) stringToRet += `To ${contact?.name ?? contact?.email_address ?? "N/A"}`;
        else stringToRet += `,${contact?.name ?? contact?.email_address ?? "N/A"}`;
      });
    }
    if (mailList.cc) {
      mailList.cc?.forEach((contact, index) => {
        if (index === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO)
          stringToRet += `${stringToRet.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && ","} Cc ${contact?.name ?? contact?.email_address ?? "N/A"}`;
        else stringToRet += `,${contact?.name ?? contact?.email_address ?? "N/A"}`;
      });
    }
    if (mailList.bcc) {
      mailList.bcc?.forEach((contact, index) => {
        if (index === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO)
          stringToRet += `${stringToRet.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && ","} Bcc ${contact?.name ?? contact?.email_address ?? "N/A"}`;
        else stringToRet += `,${contact?.name ?? contact?.email_address ?? "N/A"}`;
      });
    }

    return stringToRet;
  }, [mailList]);

  return (
    <>
      {!isAfter24hrs && isShowPrimaryProfileShare && (
        <div className="sharing-initiated activity-feed-item-card">
          <div className="sharing-initiated-icon">
            <Player src={Confetti} autoplay style={{ width: "4.5rem", height: "4.5rem", margin: "auto" }} />
          </div>
          <div className="sharing-initiated-body">
            <h3 className="sharing-initiated-body-header">
              {t("activityDetails.feedItems.profileShareAndRequest.card.headerText", { ns: "activities" })}
            </h3>
            <p className="sharing-initiated-body-text">
              {showAPOrARProfile
                ? t("activityDetails.feedItems.profileShareAndRequest.card.bodyText.sharedProfile.ap", {
                    date: formatDate(creator.createdOn),
                    ns: "activities",
                  })
                : t("activityDetails.feedItems.profileShareAndRequest.card.bodyText.sharedProfile.ar", {
                    date: formatDate(creator.createdOn),
                    ns: "activities",
                  })}
            </p>
          </div>
        </div>
      )}
      <SecondaryActivity
        variant={SecondaryType.DetailedWithAction}
        creator={creatorNew}
        flags={{ TIME_SAVING_INDICATOR: true }}
        countryCode={countryCode}
      >
        <p className="receiver-list">{ReceiversList}</p>
      </SecondaryActivity>
    </>
  );
};

export default ProfileShare;

import React from "react";
import { List, ListItem, ListItemAvatar, ListItemText, Skeleton } from "@mui/material";
import {
  AvatarSkeletonContainer,
  PrimaryTextSkeleton,
  SecondaryTextSkeleton,
  SubjectSkeletonContainer,
} from "../styled/dropdown.search.filter.styled";
import { NUMERIC_VALUES } from "../../../../constants/numeric.constants";

type SuggestionSkeletonProps = {
  listCount?: number;
  dropdownSearchVariant?: "small" | "large";
};

const FromListItemSkeleton: React.FC = () => {
  return (
    <ListItem>
      <ListItemAvatar>
        <AvatarSkeletonContainer className={""}>
          <Skeleton variant="circular" animation="wave" />
        </AvatarSkeletonContainer>
      </ListItemAvatar>
      <ListItemText
        primary={<PrimaryTextSkeleton variant="rectangular" animation="wave" />}
        secondary={<SecondaryTextSkeleton variant="rectangular" animation="wave" />}
      />
    </ListItem>
  );
};

const SubjectListItemSkeleton: React.FC = () => {
  return (
    <SubjectSkeletonContainer>
      <Skeleton className="primary-skeleton" variant="rectangular" animation="wave" />
      <div className="subject-skeleton-root">
        <Skeleton className="primary" variant="rectangular" animation="wave" />
        <div className="attachment-container">
          <Skeleton className="attachment" variant="rectangular" animation="wave" />
          <Skeleton className="attachment" variant="rectangular" animation="wave" />
          <Skeleton className="more-attachment" variant="rectangular" animation="wave" />
        </div>
      </div>
      <div className="subject-skeleton-root">
        <Skeleton className="primary" variant="rectangular" animation="wave" />
        <div className="attachment-container">
          <Skeleton className="attachment" variant="rectangular" animation="wave" />
          <Skeleton className="attachment" variant="rectangular" animation="wave" />
        </div>
      </div>
    </SubjectSkeletonContainer>
  );
};

const SuggestionSkeleton: React.FC<SuggestionSkeletonProps> = ({ listCount = NUMERIC_VALUES.CONSTANT_FIVE, dropdownSearchVariant = "small" }) => {
  return (
    <List>
      {dropdownSearchVariant === "large" ? <SubjectListItemSkeleton /> : [...Array(listCount)].map((item) => <FromListItemSkeleton key={item} />)}
    </List>
  );
};

export default SuggestionSkeleton;

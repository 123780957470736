import React, { useEffect } from "react";
import _ from "underscore";
import "../EInvoiceFeedItem.scss";
import { AppContext } from "../../../../../../contexts/AppContext";
import { WorkspaceContext } from "../../../../../../contexts/WorkspaceContext";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../../../../../constants/NumericConstants";
import { SmartText } from "../../../../../library/SmartText";
import { ActivityTypeChip } from "../../../../../library/AtomicComponents/atomic/ActivityTypeChip";
import { ContactPopover as ContactPopoverV2, SenderPopover } from "../../../../../library/ContactPopoverV2";
import { useTranslation } from "react-i18next";
import Utils from "../../../../../../utils/utils";
import EInvoiceTable from "./EInvoiceTable";
import DocumentStateActionsRenderer from "./DocumentStateActionsRenderer/DocumentStateActionsRenderer";
import useLocale from "../../../../../../hooks/useLocale";
import { StateLabelChip } from "./StateLabelChip/StateLabelChip";
import { AttachmentChip } from "../../../../../library/AtomicComponents/AttachmentChip";
import { AttachmentPreview } from "../../../../../library/AtomicComponents/AttachmentPreview";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import TableUtils from "../../../../../../utils/TableUtils/TableUtils";
import { AlertContext } from "../../../../../../contexts/AlertContext";
import { EINVOICE_ATTACHMENT_TYPES } from "../../../../../../types/enums";
import { documentsClient } from "../../../../../../db/accessor";
import AttachmentsLoadingSkeleton from "../../FeedLoadingSkeleton/AttachmentsLoadingSkeleton";
import { IDocumentState } from "./DocumentStateActionsRenderer/DocumentStateActionsRenderer";

export type PrimaryActivityProps = {
  feedItem: any;
};

const PrimaryActivity: React.FC<PrimaryActivityProps> = ({ feedItem }) => {
  const { t } = useTranslation();
  const { userStatus } = React.useContext(AppContext) as AppType;
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const toastContext = React.useContext(AlertContext) as AlertContextType;
  const [currentActivePopper, setCurrentActivePopper] = React.useState<string>("");
  const [openPreview, setOpenPreview] = React.useState<boolean>(false);
  const [activeAttachmentID, setActiveAttachmentID] = React.useState<string | number>("");
  const READ_ONLY = false;
  const [docState] = React.useState<IDocumentState>({
    id: feedItem?.activity_stream?.transactions?.einvoices[NUMERIC_VALUES.CONSTANT_ZERO]?.workflow_status_id,
    key: feedItem?.activity_stream?.transactions?.einvoices[NUMERIC_VALUES.CONSTANT_ZERO]?.einvoice_status?.split(" ")?.join("_")?.toLowerCase(),
    value: feedItem?.activity_stream?.transactions?.einvoices[NUMERIC_VALUES.CONSTANT_ZERO]?.einvoice_status,
    invoiceTypeCode: feedItem?.activity_stream?.transactions?.einvoices[NUMERIC_VALUES.CONSTANT_ZERO]?.invoice_type_code,
  });
  const attachemntsFromFeed = feedItem?.activity_stream?.transactions?.einvoices?.[NUMERIC_VALUES.CONSTANT_ZERO]?.attachments ?? [];
  const [enhancedAttachemnts, setEnhancedAttachemnts] = React.useState<any[]>([]);
  const [isAttachmentsLoading, setIsAttachmentsLoading] = React.useState<boolean>(true);

  const clearPopperSiblings = (label: string) => {
    setCurrentActivePopper(label);
  };
  const { formatDateBasedOnCountry: formatDate } = useLocale();

  const onClickPreviewOpen = (activeAttachmentID: string | number, attachmentType: string | undefined) => {
    if (attachmentType !== EINVOICE_ATTACHMENT_TYPES.DEMATERIALISED) {
      setActiveAttachmentID(activeAttachmentID);
      setOpenPreview(!openPreview);
    }
  };

  const onClickPreviewClose = (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement> | KeyboardEvent) => {
    event.stopPropagation();
    setOpenPreview(false);
  };

  const onClickDownload = (
    event: React.MouseEvent<HTMLTableRowElement | HTMLDivElement | HTMLButtonElement | HTMLAnchorElement | HTMLSpanElement>,
    attachments: AttachmentItem[] | BlobAttachmentItem[]
  ) => {
    event.stopPropagation();
    TableUtils.downloadAll(attachments, toastContext?.setToastOptions);
  };

  /**
   * @function getFileUrls
   * A helper function to fetch url for a file object from platform using file id.
   * @param id
   * @returns
   */
  const getFileUrls = async (id: string) => {
    const asyncFileURLs = await Promise.resolve(documentsClient.downloadUrl(id));
    return asyncFileURLs?.downloadLink;
  };

  const validateAttachments = async (files: (AttachmentItem & EInvoiceAttachmentItem)[]) => {
    return await Promise.all(
      files
        ?.filter((file) => !file?.is_archived)
        ?.map(async (file: any) => {
          const fileUrl = await getFileUrls(file?.attachment_id);
          const data = {
            ...file,
            file_url: file?.file_url || (fileUrl as unknown as string),
            extension: file?.file_ext?.split(".")[NUMERIC_VALUES.CONSTANT_ONE],
            file_name: file?.file_name + file?.file_ext,
            created_at: file?.created,
            id: file?.attachment_id || Math.random(),
          };
          return await Promise.resolve(data);
        })
    );
  };

  useEffect(() => {
    if (attachemntsFromFeed?.length) {
      setIsAttachmentsLoading(true);
      validateAttachments(attachemntsFromFeed)
        .then((results: any) => {
          setEnhancedAttachemnts(results as AttachmentItem[]);
        })
        .catch(() => {
          setEnhancedAttachemnts([] as AttachmentItem[]);
          const toastOptions: ToastOptions = {
            open: true,
            severity: "error",
            message: t("table.attachmentPreviewError", { ns: "einvoices" }),
          };
          toastContext?.setToastOptions?.(toastOptions);
        })
        .finally(() => {
          setIsAttachmentsLoading(false);
        });
    }
  }, [attachemntsFromFeed]);

  return (
    <div className="e-invoicing-feed-item-wrapper">
      <div className="body">
        <div className="feed-item-section header-section-wrapper">
          <div className="header-left-section">
            <div className="left-column">
              <SenderPopover
                avatar={true}
                contact={feedItem?.from}
                label={"CreatorAvatar"}
                currentPopper={clearPopperSiblings}
                clearPopper={currentActivePopper !== "CreatorAvatar"}
                readonly={false}
                selectedWorkspace={selectedWorkspace}
              />
            </div>
            <div className="right-column">
              <div className="header">
                <div className="header-details">
                  <p className="body2">
                    <span
                      className="activity-creator"
                      style={{ fontFamily: "Sage UI", fontWeight: "500", fontSize: "14px", lineHeight: "21px", marginBottom: "0.75rem" }}
                    >
                      {feedItem?.from.name}
                    </span>
                  </p>
                </div>
              </div>
              {/* subheader */}
              <div className="subheader-wrapper">
                <table className="subheader-key-value-table">
                  {_.isArray(feedItem?.to) && _.size(feedItem?.to) > NUMERIC_VALUES.CONSTANT_ZERO && (
                    <tr>
                      <td>{t("activityPopupEditor.inputField.labels.to", { ns: "activities" })}:</td>
                      <td>
                        <ContactPopoverV2
                          to={feedItem?.to}
                          label={"To"}
                          currentPopper={clearPopperSiblings}
                          clearPopper={currentActivePopper !== "To"}
                          readonly={READ_ONLY}
                          selectedWorkspace={selectedWorkspace}
                        />
                      </td>
                    </tr>
                  )}

                  {_.isArray(feedItem?.to) && _.size(feedItem?.cc ?? []) > NUMERIC_VALUES.CONSTANT_ZERO && (
                    <tr>
                      <td>{t("activityPopupEditor.inputField.labels.cc", { ns: "activities" })}:</td>
                      <td>
                        <ContactPopoverV2
                          to={feedItem?.cc as ContactItem[]}
                          label={"Cc"}
                          currentPopper={clearPopperSiblings}
                          clearPopper={currentActivePopper !== "Cc"}
                          readonly={READ_ONLY}
                          selectedWorkspace={selectedWorkspace}
                        />
                      </td>
                    </tr>
                  )}

                  {_.isArray(feedItem?.to) && _.size(feedItem?.bcc ?? []) > NUMERIC_VALUES.CONSTANT_ZERO && (
                    <tr>
                      <td>{t("activityPopupEditor.inputField.labels.bcc", { ns: "activities" })}:</td>
                      <td>
                        <ContactPopoverV2
                          to={feedItem?.bcc as ContactItem[]}
                          label={"Bcc"}
                          currentPopper={clearPopperSiblings}
                          clearPopper={currentActivePopper !== "Bcc"}
                          readonly={READ_ONLY}
                          selectedWorkspace={selectedWorkspace}
                        />
                      </td>
                    </tr>
                  )}
                  {feedItem?.subject !== "" && (
                    <tr>
                      <td>{t("activityPopupEditor.inputField.labels.subject", { ns: "activities" })}:</td>
                      <td>
                        <p className="subheader caption">
                          <span className="subheader-value">{feedItem?.subject}</span>
                        </p>
                      </td>
                    </tr>
                  )}
                </table>
              </div>
            </div>
          </div>
          <div className="header-right-section">
            <div className="state-label-row">
              <StateLabelChip variant={docState?.key as any}>{docState?.value}</StateLabelChip>
            </div>
            <div className="timestamp-row">
              <div className="header-date">
                <SmartText
                  title={feedItem?.created_at && `${formatDate(Utils.evaluateDate(feedItem?.created_at ?? NUMERIC_VALUES.CONSTANT_ZERO))}`}
                />
              </div>
            </div>
            <div className="feed-item-type-row">
              <ActivityTypeChip activityType="E-Invoice" />
            </div>
          </div>
        </div>
        <div className="feed-item-section feed-body">
          {isAttachmentsLoading ? (
            <AttachmentsLoadingSkeleton />
          ) : (
            <>
              {feedItem && enhancedAttachemnts?.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && (
                <div className="feed-item-attachments-container">
                  {enhancedAttachemnts?.map((document: AttachmentItem & EInvoiceAttachmentItem) => {
                    return (
                      <AttachmentChip
                        key={`attachment-${document?.id}`}
                        name={document?.file_name ?? ""}
                        extension={document.extension}
                        createdDate={formatDate(document?.created_at as string)}
                        attachment={document}
                        onClickAttachmentChip={() => onClickPreviewOpen(document?.id, document?.attachment_type)}
                        onClickDownload={(event) => onClickDownload(event, [document])}
                        clossable={false}
                        origin={"e-invoice"}
                      />
                    );
                  })}
                  <div className="attachemnts-actions">
                    <Link to="#" component={RouterLink} onClick={(event) => onClickDownload(event, enhancedAttachemnts)}>
                      {t("activityDetails.feedItems.primaryActivity.downLoadAllLink", { ns: "activities" })}
                    </Link>
                  </div>
                </div>
              )}
              {openPreview && (
                <AttachmentPreview
                  openPreview={openPreview}
                  activeAttachmentID={activeAttachmentID}
                  handleClose={onClickPreviewClose}
                  attachments={enhancedAttachemnts?.filter((item) => item?.attachment_type !== EINVOICE_ATTACHMENT_TYPES.DEMATERIALISED)}
                  onClickDownload={onClickDownload}
                  renderProps={
                    <DocumentStateActionsRenderer
                      recordId={feedItem?.activity_stream?.transactions?.einvoices[NUMERIC_VALUES.CONSTANT_ZERO]?.invoice_id}
                      state={docState}
                    />
                  }
                />
              )}
            </>
          )}
          <DocumentStateActionsRenderer
            recordId={feedItem?.activity_stream?.transactions?.einvoices[NUMERIC_VALUES.CONSTANT_ZERO]?.invoice_id}
            state={docState}
          />
          <EInvoiceTable
            lineItems={feedItem?.activity_stream?.transactions?.einvoices[NUMERIC_VALUES.CONSTANT_ZERO]?.lines}
            currency={userStatus?.currency}
          />
        </div>
      </div>
    </div>
  );
};

export default PrimaryActivity;

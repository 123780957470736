import React from "react";
import { List, ListItemButton } from "@mui/material";
import { NUMERIC_VALUES } from "../../AtomicComponents/constants/numeric.constants";
import { TabsComponentContainer, TemplateResultContainer, TemplateSearchListCard } from "../styled/email.template.styled";
import CustomTabsWithCount from "./CustomTabsWithCount";
import DropdownListComponent from "./DropdownListComponent";
import EmptyResult from "./EmptyResult";
import SearchComponent from "./SearchComponent";
import { SmartText } from "../../SmartText";
import { TemplateListCardProps } from "../types/email.template.popover.types";

/**
 * @param showTemplateListHeaderShadow:[optional]- boolean value to show shadow styling when user scroll the list
 * @param isSearchRequired:[optional]- if it's true then only search component will be display in the header
 * @param isTabRequired:[optional]- if it's true then only tabs will be display in the header
 * @param isTempListCardLangDrpdwnRequired:[optional]- if it's true then only langauge drodown will be display along with tabs
 * @param searchComponentProps: required props for search component
 * @param tabComponentProps: required props for tabs component
 * @param dropdownComponentProps: required props for language dropdowns
 * @param templateList: list of templates
 * @param selectedTemplateIndex: select template index
 * @param templateListContainerRef and @param templateListItemRef: to attach the ref to the list container to implement scroll behaviour
 * @param onScrollTemplateListContainer: to handle template list scroll event
 * @param onClickTemplateListItem: to handle click event of the template list items
 */
const TemplateListCardComponent: React.FC<TemplateListCardProps> = ({
  header: {
    showTemplateListHeaderShadow,
    searchComponentProps: { searchedText, ...restSearchComponentProps },
    tabComponentProps,
    dropdownComponentProps,
    isTabRequired,
    isSearchRequired,
    isTempListCardLangDrpdwnRequired,
  },
  listComponentProps: {
    templateList,
    selectedTemplateIndex,
    templateListContainerRef,
    templateListItemRef,
    onScrollTemplateListContainer,
    onClickTemplateListItem,
  },
}) => {
  return (
    <TemplateSearchListCard customwidth={templateList?.length <= NUMERIC_VALUES.CONSTANT_ZERO ? "43.75rem" : undefined}>
      {/**----- HEADER ----- */}
      <div
        className={
          showTemplateListHeaderShadow && templateList?.length > NUMERIC_VALUES.CONSTANT_ZERO ? "sticky-header-container" : "header-container"
        }
      >
        {isSearchRequired && <SearchComponent searchedText={searchedText} {...restSearchComponentProps} />}
        {isTabRequired && (
          <TabsComponentContainer>
            <CustomTabsWithCount {...tabComponentProps} />
            {isTempListCardLangDrpdwnRequired && <DropdownListComponent {...dropdownComponentProps} />}
          </TabsComponentContainer>
        )}
      </div>

      {/**----- BODY ----- */}
      <>
        {templateList?.length <= NUMERIC_VALUES.CONSTANT_ZERO ? (
          <EmptyResult />
        ) : (
          <TemplateResultContainer onScroll={onScrollTemplateListContainer} ref={templateListContainerRef}>
            <List>
              {templateList?.map((item, index) => (
                <ListItemButton
                  key={item.templateId}
                  onClick={() => onClickTemplateListItem(item.templateId, index)}
                  selected={selectedTemplateIndex === index}
                  ref={(el) => (templateListItemRef.current[index] = el)} // Assign the ref to each li element
                >
                  <SmartText
                    title={item.templateName}
                    highlighter={{
                      enableHighlighter: searchedText?.length >= NUMERIC_VALUES.CONSTANT_THREE,
                      matchText: searchedText ?? "~",
                    }}
                  />
                </ListItemButton>
              ))}
            </List>
          </TemplateResultContainer>
        )}
      </>
    </TemplateSearchListCard>
  );
};

export default TemplateListCardComponent;

/*eslint-disable no-magic-numbers*/
import React from "react";
import { styled } from "@mui/system";
import { pxToRem } from "../../../theme/theme";
import { styled as muiStyled } from "@mui/material/styles";
import Typography, { TypographyProps } from "@mui/material/Typography";
import CheckboxIcon from "../../../svg/checkbox_reset.svg";
import CheckboxHoverIcon from "../../../svg/checkbox_hover.svg";

export const TableRoot = styled("div", {
  name: "table",
  slot: "root",
})({
  height: "100%",
  borderRadius: pxToRem(8),
  boxShadow: "none",
  display: "flex",
  flexDirection: "column",
  "& .table-caption-selected-view": {
    background: "#124564",
    color: "#FFFFFF",
  },
});

export const TableViewContainer = styled("div", {
  name: "table-view",
  slot: "container",
})({
  flex: "1 1 auto",
  height: pxToRem(1),
  "& .MuiTableContainer-root": {
    height: "100%",
    borderColor: "#DEE1ED",
    borderRadius: "0px",
    boxShadow: "none",
  },

  border: "1px solid #DEE1ED",
  borderBottom: "none",
  "& .table-header-cell-name": {
    height: "100%",
  },

  "& .MuiTable-root .MuiTableHead-root .MuiTableRow-root": {
    "& .MuiTableCell-root": {
      padding: pxToRem(5),
      whiteSpace: "nowrap",
      height: pxToRem(56),
      borderColor: "#DEE1ED",
      "&:hover": {
        "& .MuiCheckbox-root": {
          cursor: "pointer",
          background: `url(${CheckboxHoverIcon}) center no-repeat`,
        },
      },
      "& .MuiCheckbox-root": {
        background: `url(${CheckboxIcon}) center no-repeat`,
        color: "#576375",
        height: "100%",
        width: "100%",

        "& input[type='checkbox']": {
          height: "100%",
          width: "100%",
        },
      },
      "& .MuiCheckbox-root.Mui-disabled": {
        opacity: 0.5,
      },
    },
    borderBottom: "1px solid #DEE1ED",
  },
  "& .MuiTable-root .MuiTableBody-root .MuiTableRow-root": {
    position: "relative",
    "& .MuiTableCell-root": {
      padding: `${pxToRem(18)} ${pxToRem(25)}`,
      whiteSpace: "nowrap",
      borderBottom: "1px solid #DEE1ED",
      "& .MuiCheckbox-root": {
        color: "#576375",
        height: pxToRem(60),
        width: pxToRem(60),
        background: `url(${CheckboxIcon}) center no-repeat`,

        "& input[type='checkbox']": {
          height: pxToRem(60),
          width: pxToRem(60),
        },
      },
      "& .MuiCheckbox-root.Mui-disabled": {
        opacity: 0.5,
      },
    },
    "& .MuiTableCell-root:first-child": {
      padding: `0`,
      height: pxToRem(60),
      width: pxToRem(60),
    },
  },
  "& .table-body": {
    scrollMarginTop: pxToRem(60),
  },
  "& .table-body .table-row": {
    background: "#FFFFFF",
    borderColor: "#DEE1ED",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F7F7FB",
      "& .MuiTableCell-root .MuiCheckbox-root": {
        background: `url(${CheckboxHoverIcon}) center no-repeat`,
      },
    },
  },
  "& .table-body .table-row.unread": {
    "& th": {
      "& span": {
        fontWeight: "500",
      },
    },
  },
  "& .table-body .table-row-selected": {
    background: "#EEF0F6",
    "&:hover": {
      "& .MuiTableCell-root .MuiCheckbox-root": {
        background: "none",
      },
    },
  },
});

export const SuccessTypography = muiStyled(({ ...props }: TypographyProps) => <Typography {...props} />)({
  color: "#24B14B",
});

export const ErrorTypography = muiStyled(({ ...props }: TypographyProps) => <Typography {...props} />)({
  color: "#E54646",
});

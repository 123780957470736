import React from "react";
import { EmptyStatesWrapper } from "./emptystates.styles";
import { ThemeProvider, Typography } from "@mui/material";
import theme from "../../../theme/theme";
import { ReactComponent as NoResultFoundIcon } from "../../../svg/no_result_found.svg";

type FilterResultEmptyStateProps = {
  title: string;
  caption: string;
};

const FilterResultEmptyState: React.FC<FilterResultEmptyStateProps> = ({ title, caption }) => {
  return (
    <ThemeProvider theme={theme}>
      <EmptyStatesWrapper>
        <div className="icon-container">
          <NoResultFoundIcon />
        </div>
        <Typography variant="h4" className="empty-title">
          {title ?? ""}
        </Typography>
        <Typography variant="body1Reg" className="empty-caption">
          {caption ?? ""}
        </Typography>
      </EmptyStatesWrapper>
    </ThemeProvider>
  );
};
export default FilterResultEmptyState;

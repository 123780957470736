import React, { useMemo, useState } from "react";
import _ from "underscore";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { ActivityActionTypes, ActivityStatus, FallbackTypes } from "../../../../types/enums";
import { ActivityContext } from "../../../../contexts/ActivityContext";
import { TooltipTitles } from "../../../../constants/TooltipConstants";
import {
  CommandCenterActivityAssign,
  CommandCenterActivityRead,
  CommandCenterActivitySpam,
  CommandCenterActivitySnooze,
  CommandCenterActivityMove,
  CommandCenterActivityNudge,
} from "../../../library/Icons/Icons";
import { useParams } from "react-router-dom";
import { FeatureFlagContext, FeatureFlagProviderType } from "../../../../contexts/FeatureFlagContext";
import { ActivityFeedKeyboardShortCutConstants } from "../../../../constants/KeyboardShortcutConstants";
import useWorkspaceConfigurations from "../../../../hooks/useWorkspaceConfigurations";
import { Input, InputAdornment, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { activitiesClientV2, companiesClientV2 } from "../../../../db/version2Accessor";
import { AlertContext } from "../../../../contexts/AlertContext";
import ActivityMoveView from "../../ActivityDetail/ActivityFeedV2/ActionBar/ActivitySubmenuViews/ActivityMoveView";
import ActivityReassignView from "../../ActivityDetail/ActivityFeedV2/ActionBar/ActivitySubmenuViews/ActivityReassignView";
import ActivitySnoozeView from "../../ActivityDetail/ActivityFeedV2/ActionBar/ActivitySubmenuViews/ActivitySnoozeView";
import SenderSpamView from "../../ActivityDetail/ActivityFeedV2/ActionBar/ActivitySubmenuViews/SenderSpamView";
import { ArrowBack, Close, Search } from "@mui/icons-material";
import SenderFraudView from "../../ActivityDetail/ActivityFeedV2/ActionBar/ActivitySubmenuViews/SenderFraudView";
import BulkMoveModal from "../../ActivityDetail/ActivityFeed/ActivityFeedActions/MoveActivity/BulkMoveModal";
import {
  CommandCenterContainer,
  CommandCenterHeader,
  CommandCenterBody,
  CommandCenterActionsView,
  CommandCenterSearchInputContainer,
  CommandCenterActionsList,
  CommandCenterShortcutMessageBox,
} from "./styled";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";

interface CommandCenterModalProps {
  open: boolean;
  isSecondaryViewOpen: boolean;
  showCommandCenterTooltip: boolean;
  visibility: { assignSubMenu: boolean; moveSubMenu: boolean; snoozeSubMenu: boolean; senderSpamSubMenu: boolean; senderFraudSubMenu: boolean };
  handleCommandCenterClose: () => void;
  handleNudge: () => void;
  getActivityStream: (
    withLoadingAnimation: boolean,
    onActionPerform?: boolean,
    explicitlySetPageSize?: number,
    refreshStream?: boolean,
    refreshActivityDetail?: boolean
  ) => void;
  refreshActivity: () => void;
  onCloseSubMenu: () => void;
  onMarkActivitySpam: () => Promise<void>;
  onMarkActivityUnread: () => Promise<void>;
  onClickOpenAssignSubMenu: () => void;
  onClickOpenMoveSubMenu: () => void;
  onClickOpenSnoozeSubMenu: () => void;
  onClickMarkSenderSpam: () => void;
  onClickMarkSenderFraud: () => void;
  onCallback: () => void;
}

export default function CommandCenterModal({
  open,
  isSecondaryViewOpen,
  showCommandCenterTooltip,
  visibility,
  handleCommandCenterClose,
  handleNudge,
  getActivityStream,
  refreshActivity,
  onCloseSubMenu,
  onMarkActivitySpam,
  onMarkActivityUnread,
  onClickOpenAssignSubMenu,
  onClickOpenMoveSubMenu,
  onClickOpenSnoozeSubMenu,
  onClickMarkSenderSpam,
  onClickMarkSenderFraud,
  onCallback,
}: CommandCenterModalProps): React.ReactElement {
  const { isEnabled } = React.useContext(FeatureFlagContext) as FeatureFlagProviderType;
  const { activityId } = useParams<{ customerId: string; activityId: string }>();
  const { activityData, activityActionInProgress } = React.useContext(ActivityContext) as ActivityType;
  const isKeyboardShortcutFlagEnabled = isEnabled("KEYBOARD_SHORTCUTS");
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const { selectedWorkspace } = useWorkspaceConfigurations();
  const [searchText, setSearchText] = React.useState<string>("");
  const [showBulkMoveModal, setShowBulkMoveModal] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<Partial<CompanyV2> | null>(null);
  const { t } = useTranslation();

  const shortcutDisplayMapper: { [key: string]: string } = useMemo(() => {
    if (isKeyboardShortcutFlagEnabled) {
      const mapper: { [key: string]: string } = {};
      ActivityFeedKeyboardShortCutConstants.forEach((item) => {
        if (item.identifier) {
          mapper[item.identifier] = item.displayText;
        }
      });
      return mapper;
    }
    return {};
  }, [isKeyboardShortcutFlagEnabled]);

  const handleActionSearch = (event: { target: { value: string } }) => setSearchText(event.target.value.toLowerCase());

  const onClickMarkActivityUnread = async () => {
    await onMarkActivityUnread();
  };

  const onClickMarkActivitySpam = async () => {
    await onMarkActivitySpam();
  };

  // handler
  const spamAndFraudHandler = async (id: string, actionType: ActivityActionTypes, markSender = false) => {
    let response: APIResponse;
    let reqBody: ActivitiesStreamUpdateBody = { mark_sender: markSender };
    if (actionType === ActivityActionTypes.Spam) {
      reqBody = { ...reqBody, spam: true };
    }
    try {
      response = await activitiesClientV2.patch(selectedWorkspace?.id || FallbackTypes.Id, id, actionType, reqBody);
    } catch (error) {
      response = (error as AxiosError).response?.data;
    }
    return response;
  };

  const onClickMarkSender = async (actionType: ActivityActionTypes) => {
    const toastOptions: ToastOptions = { open: true, severity: "success", message: "" };
    const senderSpamSuccessToast = t("toastMessages.markSendAsSpam.success", { ns: "activities" });
    const senderFraudSuccessToast = t("toastMessages.markSendAsFraud.success", { ns: "activities" });
    const senderSpamFailedToast = t("toastMessages.markSendAsSpam.failed", { ns: "activities" });
    const senderFraudFailedToast = t("toastMessages.markSendAsFraud.failed", { ns: "activities" });

    const response: APIResponse = await spamAndFraudHandler(activityData.id, actionType, true);
    if (response.success) {
      setToastOptions({
        ...toastOptions,
        message: actionType === ActivityActionTypes.Spam ? senderSpamSuccessToast : senderFraudSuccessToast,
      });
      refreshActivity();
      getActivityStream(false, true);
    } else {
      setToastOptions({
        ...toastOptions,
        severity: "error",
        message: actionType === ActivityActionTypes.Spam ? senderSpamFailedToast : senderFraudFailedToast,
      });
    }
    handleCommandCenterClose();
  };

  const { handleMove } = React.useContext(ActivityContext) as ActivityType;

  const onMoveHandler = async (connectionId: string) => {
    let toastOptions: ToastOptions = {
      open: true,
      severity: "success",
      message: t("toastMessages.moveActivity.success", { ns: "activities" }),
    };
    let response = {} as APIResponse;
    try {
      response = await handleMove(activityId, connectionId ?? null, "");
    } catch (e: unknown) {
      response.success = false;
    } finally {
      if (!response.success) {
        toastOptions = {
          ...toastOptions,
          severity: "error",
          message: t("toastMessages.moveActivity.failed", { ns: "activities" }),
        };
      }
      onCallback();
      setToastOptions(toastOptions);
    }
  };

  const onMergeHandler = async (connectionId: string) => {
    let toastOptions: ToastOptions = {
      open: true,
      severity: "success",
      message: t("toastMessages.moveActivity.bulkSuccessMerge", { ns: "activities" }),
    };
    let response = {} as APIResponse;
    try {
      response = await companiesClientV2.mergeConnections(connectionId ?? "", [activityData.primary_connection?.id ?? ""], selectedWorkspace?.id);
    } catch (e: unknown) {
      response.success = false;
    } finally {
      if (!response.success) {
        toastOptions = {
          ...toastOptions,
          severity: "error",
          message: t("toastMessages.moveActivity.bulkFailedMerge", { ns: "activities" }),
        };
      }
      onCallback();
      setToastOptions(toastOptions);
    }
  };

  const handleBulkMove = async (connectionId: string) => {
    setShowBulkMoveModal(false);
    handleCommandCenterClose();
    await onMergeHandler(connectionId);
  };

  const handleSingleMove = async (connectionId: string) => {
    setShowBulkMoveModal(false);
    handleCommandCenterClose();
    await onMoveHandler(connectionId);
  };

  const onCloseDialog = () => {
    setShowBulkMoveModal(false);
    handleCommandCenterClose();
  };

  const onClickMarkActivityNudge = () => {
    handleCommandCenterClose();
    handleNudge();
  };
  return (
    <>
      {showBulkMoveModal ? (
        <BulkMoveModal
          showModal={showBulkMoveModal}
          closeModal={onCloseDialog}
          handleSingleMove={handleSingleMove}
          handleBulkMove={handleBulkMove}
          from={activityData?.primary_connection}
          to={selectedCompany}
        />
      ) : (
        <Modal open={open} onClose={handleCommandCenterClose}>
          <>
            <CommandCenterContainer>
              <CommandCenterHeader>
                <Typography className="command-center-title">
                  {isSecondaryViewOpen && <ArrowBack onClick={onCloseSubMenu} />}
                  {isSecondaryViewOpen
                    ? t("activityDetails.commandCenter.header.goBack", { ns: "activities" })
                    : t("activityDetails.commandCenter.header.welcomeMessage", { ns: "activities" })}
                </Typography>
                <div className="command-center-close" onClick={handleCommandCenterClose}>
                  <Close />
                </div>
              </CommandCenterHeader>
              <CommandCenterBody>
                {!isSecondaryViewOpen && (
                  <CommandCenterActionsView>
                    <CommandCenterSearchInputContainer>
                      <Input
                        value={searchText}
                        className=""
                        placeholder={t("activityDetails.commandCenter.body.searchPlaceHolderText", { ns: "activities" }) as string}
                        onChange={handleActionSearch}
                        onKeyDown={(event) => event.stopPropagation()}
                        disableUnderline={true}
                        startAdornment={
                          <InputAdornment position="start">
                            <Search className="" />
                          </InputAdornment>
                        }
                      />
                    </CommandCenterSearchInputContainer>
                    <CommandCenterActionsList>
                      {activityData?.status?.toLowerCase() !== ActivityStatus.Closed.toLowerCase() &&
                        !activityData?.spam &&
                        (_.isEmpty(searchText) ||
                          (!_.isEmpty(searchText) &&
                            t("activityDetails.commandCenter.body.activitiyActionList.markAsUnread", { ns: "activities" })
                              ?.toLowerCase()
                              .includes(searchText))) && (
                          <MenuItem className="cc-primary-menu-item" onClick={onClickMarkActivityUnread} disabled={activityActionInProgress}>
                            <ListItemIcon>
                              <CommandCenterActivityRead />
                            </ListItemIcon>
                            <ListItemText>
                              {t("activityDetails.commandCenter.body.activitiyActionList.markAsUnread", { ns: "activities" })}
                            </ListItemText>
                            <Typography className="keyboard-shortcut-text">
                              {isKeyboardShortcutFlagEnabled ? shortcutDisplayMapper?.[TooltipTitles.MARK_AS_UNREAD] : ""}
                            </Typography>
                          </MenuItem>
                        )}
                      {activityData?.status?.toLowerCase() !== ActivityStatus.Snoozed.toLowerCase() &&
                        !activityData?.spam &&
                        (_.isEmpty(searchText) ||
                          (!_.isEmpty(searchText) &&
                            t("activityDetails.commandCenter.body.activitiyActionList.snooze", { ns: "activities" })
                              ?.toLowerCase()
                              .includes(searchText))) && (
                          <MenuItem className="cc-primary-menu-item" onClick={onClickOpenSnoozeSubMenu} disabled={activityActionInProgress}>
                            <ListItemIcon>
                              <CommandCenterActivitySnooze />
                            </ListItemIcon>
                            <ListItemText>{t("activityDetails.commandCenter.body.activitiyActionList.snooze", { ns: "activities" })}</ListItemText>
                            <Typography className="keyboard-shortcut-text">
                              {isKeyboardShortcutFlagEnabled ? shortcutDisplayMapper?.[TooltipTitles.SNOOZE] : ""}
                            </Typography>
                          </MenuItem>
                        )}
                      {activityData?.status &&
                        activityData?.status?.toLowerCase() !== ActivityStatus.WaitingForResponse.toLowerCase() &&
                        (_.isEmpty(searchText) ||
                          (!_.isEmpty(searchText) &&
                            t("activityDetails.commandCenter.body.activitiyActionList.markAsSpam", { ns: "activities" })
                              ?.toLowerCase()
                              .includes(searchText))) && (
                          <MenuItem className="cc-primary-menu-item" onClick={onClickMarkActivitySpam} disabled={activityActionInProgress}>
                            <ListItemIcon>
                              <CommandCenterActivitySpam />
                            </ListItemIcon>
                            <ListItemText>
                              {activityData?.spam
                                ? t("activityDetails.commandCenter.body.activitiyActionList.markAsNotSpam", { ns: "activities" })
                                : t("activityDetails.commandCenter.body.activitiyActionList.markAsSpam", { ns: "activities" })}
                            </ListItemText>
                            <Typography className="keyboard-shortcut-text">
                              {isKeyboardShortcutFlagEnabled ? shortcutDisplayMapper?.[TooltipTitles.MOVE_AS_SPAM] : ""}
                            </Typography>
                          </MenuItem>
                        )}
                      {!activityData?.spam &&
                        (_.isEmpty(searchText) ||
                          (!_.isEmpty(searchText) &&
                            t("activityDetails.commandCenter.body.activitiyActionList.assign", { ns: "activities" })
                              ?.toLowerCase()
                              .includes(searchText))) && (
                          <MenuItem className="cc-primary-menu-item" onClick={onClickOpenAssignSubMenu} disabled={activityActionInProgress}>
                            <ListItemIcon>
                              <CommandCenterActivityAssign />
                            </ListItemIcon>
                            <ListItemText>{t("activityDetails.commandCenter.body.activitiyActionList.assign", { ns: "activities" })}</ListItemText>
                            <Typography className="keyboard-shortcut-text">
                              {isKeyboardShortcutFlagEnabled ? shortcutDisplayMapper?.[TooltipTitles.ASSIGN] : ""}
                            </Typography>
                          </MenuItem>
                        )}
                      {!activityData?.spam &&
                        (_.isEmpty(searchText) ||
                          (!_.isEmpty(searchText) &&
                            t("activityDetails.commandCenter.body.activitiyActionList.move", { ns: "activities" })
                              ?.toLowerCase()
                              .includes(searchText))) && (
                          <MenuItem className="cc-primary-menu-item" onClick={onClickOpenMoveSubMenu} disabled={activityActionInProgress}>
                            <ListItemIcon>
                              <CommandCenterActivityMove />
                            </ListItemIcon>
                            <ListItemText>{t("activityDetails.commandCenter.body.activitiyActionList.move", { ns: "activities" })}</ListItemText>
                            <Typography className="keyboard-shortcut-text">
                              {isKeyboardShortcutFlagEnabled ? shortcutDisplayMapper?.[TooltipTitles.MOVE] : ""}
                            </Typography>
                          </MenuItem>
                        )}
                      {!activityData?.spam &&
                        activityData?.status?.toLowerCase() === ActivityStatus.WaitingForResponse.toLowerCase() &&
                        (_.isEmpty(searchText) ||
                          (!_.isEmpty(searchText) &&
                            t("activityDetails.commandCenter.body.activitiyActionList.nudge", { ns: "activities" })
                              ?.toLowerCase()
                              .includes(searchText))) && (
                          <MenuItem className="cc-primary-menu-item" onClick={onClickMarkActivityNudge} disabled={activityActionInProgress}>
                            <ListItemIcon>
                              <CommandCenterActivityNudge />
                            </ListItemIcon>
                            <ListItemText>{t("activityDetails.commandCenter.body.activitiyActionList.nudge", { ns: "activities" })}</ListItemText>
                          </MenuItem>
                        )}
                      {(_.isEmpty(searchText) ||
                        (!_.isEmpty(searchText) &&
                          t("activityDetails.commandCenter.body.activitiyActionList.markSenderAsSpam", { ns: "activities" })
                            ?.toLowerCase()
                            .includes(searchText))) && (
                        <MenuItem className="cc-primary-menu-item" onClick={onClickMarkSenderSpam} disabled={activityActionInProgress}>
                          <ListItemIcon>
                            <CommandCenterActivitySpam />
                          </ListItemIcon>
                          <ListItemText>
                            {t("activityDetails.commandCenter.body.activitiyActionList.markSenderAsSpam", { ns: "activities" })}
                          </ListItemText>
                        </MenuItem>
                      )}
                      {(_.isEmpty(searchText) ||
                        (!_.isEmpty(searchText) &&
                          t("activityDetails.commandCenter.body.activitiyActionList.markSenderAsFraud", { ns: "activities" })
                            ?.toLowerCase()
                            .includes(searchText))) && (
                        <MenuItem className="cc-primary-menu-item" onClick={onClickMarkSenderFraud} disabled={activityActionInProgress}>
                          <ListItemIcon>
                            <CommandCenterActivitySpam />
                          </ListItemIcon>
                          <ListItemText>
                            {t("activityDetails.commandCenter.body.activitiyActionList.markSenderAsFraud", { ns: "activities" })}
                          </ListItemText>
                        </MenuItem>
                      )}
                    </CommandCenterActionsList>
                  </CommandCenterActionsView>
                )}
                {isSecondaryViewOpen && (
                  <>
                    {visibility.snoozeSubMenu && (
                      <ActivitySnoozeView
                        handleCommandCenterClose={handleCommandCenterClose}
                        getActivityStream={getActivityStream}
                        refreshActivity={refreshActivity}
                        selectedWorkspace={selectedWorkspace}
                        activityId={activityId}
                        currentAssignee={activityData.assignee}
                        keyboardShortcutText={isKeyboardShortcutFlagEnabled ? shortcutDisplayMapper?.[TooltipTitles.SNOOZE] : ""}
                      />
                    )}
                    {visibility.assignSubMenu && (
                      <ActivityReassignView
                        onCallback={onCallback}
                        activityIds={[activityId]}
                        currentAssignee={activityData.assignee}
                        handleCommandCenterClose={handleCommandCenterClose}
                        keyboardShortcutText={isKeyboardShortcutFlagEnabled ? shortcutDisplayMapper?.[TooltipTitles.ASSIGN] : ""}
                      />
                    )}
                    {visibility.moveSubMenu && (
                      <ActivityMoveView
                        activityIds={[activityId]}
                        currentConnection={activityData?.primary_connection}
                        setSelectedCompany={setSelectedCompany}
                        setShowBulkMoveModal={setShowBulkMoveModal}
                        selectedCompany={selectedCompany}
                        onClickBulkModalClose={handleSingleMove}
                        keyboardShortcutText={isKeyboardShortcutFlagEnabled ? shortcutDisplayMapper?.[TooltipTitles.MOVE] : ""}
                      />
                    )}
                    {visibility.senderSpamSubMenu && (
                      <SenderSpamView onCloseSubMenu={onCloseSubMenu} handleMarkSenderSpam={() => onClickMarkSender(ActivityActionTypes.Spam)} />
                    )}
                    {visibility.senderFraudSubMenu && (
                      <SenderFraudView onCloseSubMenu={onCloseSubMenu} handleMarkSenderFraud={() => onClickMarkSender(ActivityActionTypes.Fraud)} />
                    )}
                  </>
                )}
              </CommandCenterBody>
            </CommandCenterContainer>
            {showCommandCenterTooltip && (
              <CommandCenterShortcutMessageBox>
                {t("activityDetails.commandCenter.shortcutMessageBox.firstPart", { ns: "activities" })}
                <span className="shortcut">CTRL</span> + <span className="shortcut">K</span>
                {t("activityDetails.commandCenter.shortcutMessageBox.lastPart", { ns: "activities" })}
              </CommandCenterShortcutMessageBox>
            )}
          </>
        </Modal>
      )}
    </>
  );
}

import React from "react";
import { TooltipTitles, TooltipTypes } from "../../../../constants/TooltipConstants";
import MenuItem from "../../MenuItem/MenuItem";
import { APARStances } from "../../../../types/enums";
import { ActivityAddNewButtonShortcuts } from "../../../../constants/KeyboardShortcutConstants";
import { makeStyles } from "@mui/styles";
import styles from "../../../../css/_export.module.scss";
import CustomTooltip from "../../CustomTooltip/CustomTooltip";

type DropdownOptionProps = ActivityDropdownItem & {
  enableShortcutKey: boolean | undefined;
  onClickOption: (displayName: string) => void;
};

const DropdownOption: React.FC<DropdownOptionProps> = ({ enableShortcutKey = false, onClickOption, ...option }) => {
  const getDisplayName = React.useCallback((val: string) => {
    if (val === APARStances.REQUEST_CUSTOMER_PROFILE) return val.replace("Customer ", "");
    return val;
  }, []);

  const shortcutDisplayMapper: { [key: string]: string } = React.useMemo(() => {
    if (enableShortcutKey) {
      const mapper: { [key: string]: string } = {};
      ActivityAddNewButtonShortcuts.forEach((item) => {
        if (item.identifier) mapper[item.identifier] = item.displayText;
      });
      return mapper;
    }
    return {};
  }, [enableShortcutKey]);

  const tooltipStyles = makeStyles(() => ({
    tooltip: {
      maxWidth: "8.625rem",
      padding: "0.25rem 0.5rem",
      backgroundColor: styles.grey30,
    },
  }))();

  const shortcutLabel = shortcutDisplayMapper[option.displayName];
  const shortcutKeyTitle = enableShortcutKey && shortcutLabel ? `${TooltipTitles.ADD_NEW} ${option.displayName}` : "";
  return (
    <CustomTooltip
      type={enableShortcutKey && shortcutLabel ? TooltipTypes.SHORTCUT : TooltipTypes.PLAIN}
      shortcutKeysText={enableShortcutKey && shortcutLabel && !option?.disabled ? shortcutLabel : ""}
      title={option?.disabled ? option?.disabledToolip ?? "" : shortcutKeyTitle}
      placement="right"
      classes={option?.disabled ? { tooltip: tooltipStyles.tooltip } : {}}
    >
      <span>
        <MenuItem disabled={option.disabled} onClick={() => onClickOption(option.activityType)}>
          {getDisplayName(option.displayName)}
        </MenuItem>
      </span>
    </CustomTooltip>
  );
};

export default DropdownOption;

import React from "react";
import _ from "underscore";
import { Input, InputAdornment, Select, SelectProps, SelectChangeEvent } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import Dropdown from "../Dropdown/Dropdown";
import RangeSlider from "../RangeSlider/RangeSlider";
import useDropdownNumericStyle from "./useDropdownNumericStyle";
import { NUMERIC_VALUES } from "../../../constants/NumericConstants";
import { GLOBAL_SEARCH_NUMERIC_FILTER_OPERATORS } from "../../../constants/GlobalSearchConstants";
import { MenuOptionType } from "../AtomicComponents/TableFilters/Presets/preset.filter.types";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

interface DropdownProps extends SelectProps {
  handleDropDownChange: (amountValue: string, param: string, amountType: string) => void;
  adornmentSymbol: string;
  defaultText: string;
  param: string;
  titleText: string;
}

const OperatorOptionsList = (t: TFunction): MenuOptionType[] => [
  {
    key: GLOBAL_SEARCH_NUMERIC_FILTER_OPERATORS.LESS_THAN,
    value: GLOBAL_SEARCH_NUMERIC_FILTER_OPERATORS.LESS_THAN,
    label: t("globalSearchResults.filters.numericFilter.menuListLabels.lessThan", { ns: "global_search" }),
  },
  {
    key: GLOBAL_SEARCH_NUMERIC_FILTER_OPERATORS.GREATER_THAN,
    value: GLOBAL_SEARCH_NUMERIC_FILTER_OPERATORS.GREATER_THAN,
    label: t("globalSearchResults.filters.numericFilter.menuListLabels.greaterThan", { ns: "global_search" }),
  },
  {
    key: GLOBAL_SEARCH_NUMERIC_FILTER_OPERATORS.EQUALS_TO,
    value: GLOBAL_SEARCH_NUMERIC_FILTER_OPERATORS.EQUALS_TO,
    label: t("globalSearchResults.filters.numericFilter.menuListLabels.equalsTo", { ns: "global_search" }),
  },
  {
    key: GLOBAL_SEARCH_NUMERIC_FILTER_OPERATORS.IN_BETWEEN,
    value: GLOBAL_SEARCH_NUMERIC_FILTER_OPERATORS.IN_BETWEEN,
    label: t("globalSearchResults.filters.numericFilter.menuListLabels.inBetween", { ns: "global_search" }),
  },
];

const DropdownNumeric: React.FC<DropdownProps> = ({ handleDropDownChange, adornmentSymbol = "", defaultText = "", param = "", titleText = "" }) => {
  const { t } = useTranslation();
  const { select, divRoot, fillDivRoot, amountMenuRoot, amountMenuWrapper, amountOperatorWrapper, inputContainer } = useDropdownNumericStyle();
  const [selectedAmountOperator, setSelectedAmountOperator] = React.useState<string>("");
  const [selectedAmountValue, setSelectedAmountValue] = React.useState<string>("");
  const [selectedAmountRange, setSelectedAmountRange] = React.useState<number[]>([NUMERIC_VALUES.CONSTANT_SIXTY, NUMERIC_VALUES.CONSTANT_THOUSAND]);

  const handleAmountOperatorChange = (event: SelectChangeEvent) => {
    setSelectedAmountOperator(event.target.value);
    if (_.isEmpty(event.target.value)) {
      setSelectedAmountValue("");
      handleDropDownChange("", param, "");
    }
  };

  const handleAmountValueChange = (event: React.ChangeEvent<HTMLInputElement>) => setSelectedAmountValue(event.target.value);

  const handleSliderChange = (_event: Event, value: number[]) => setSelectedAmountRange(value);

  const handleSliderMinChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSelectedAmountRange([parseInt(event.target.value, NUMERIC_VALUES.CONSTANT_TEN), selectedAmountRange[NUMERIC_VALUES.CONSTANT_ONE]]);

  const handleSliderMaxChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSelectedAmountRange([selectedAmountRange[NUMERIC_VALUES.CONSTANT_ZERO], parseInt(event.target.value, NUMERIC_VALUES.CONSTANT_TEN)]);

  /**
   * @function handleDropdownClose
   * Function triggered on Dropdown close to generate numeric query
   * and return computed query to parent component based on condition
   * if a single value is selected or range from slider is selected
   * and clear query if no operator is selected
   */
  const handleDropdownClose = () => {
    if (_.isEmpty(selectedAmountOperator)) {
      // Clear filter if no operator is selected
      setSelectedAmountValue("");
      handleDropDownChange("", param, "");
    } else if (selectedAmountOperator !== GLOBAL_SEARCH_NUMERIC_FILTER_OPERATORS.IN_BETWEEN && _.isEmpty(selectedAmountValue)) {
      setSelectedAmountOperator("");
      handleDropDownChange("", param, "");
    }
  };

  /**
   * @function processSelectedValue
   * Function to get multiple selected values from Dropdown
   * and return computed custom Title to be shown on Dropdown
   * based on condition that one or more menu items have been selected
   * @param selected string[]: array of valus selected in Dropdown
   * @returns string
   */
  const processSelectedValue = () => {
    if (selectedAmountOperator === GLOBAL_SEARCH_NUMERIC_FILTER_OPERATORS.IN_BETWEEN) {
      return `${adornmentSymbol}${selectedAmountRange[NUMERIC_VALUES.CONSTANT_ZERO]} - ${adornmentSymbol}${
        selectedAmountRange[NUMERIC_VALUES.CONSTANT_ONE]
      }`;
    } else if (_.isEmpty(selectedAmountValue)) {
      return defaultText;
    } else {
      return `${selectedAmountOperator} ${adornmentSymbol}${selectedAmountValue}`;
    }
  };

  // Trigger query on selectedAmountValue change
  React.useEffect(() => {
    if (!_.isEmpty(selectedAmountValue)) {
      handleDropDownChange(selectedAmountValue, param, selectedAmountOperator);
    }
  }, [selectedAmountValue, selectedAmountOperator]);

  // Trigger query on selectedAmountRange change
  React.useEffect(() => {
    if (selectedAmountOperator === GLOBAL_SEARCH_NUMERIC_FILTER_OPERATORS.IN_BETWEEN) {
      setSelectedAmountValue("");
      handleDropDownChange(selectedAmountRange.join(" TO "), param, selectedAmountOperator);
    }
  }, [selectedAmountRange, selectedAmountOperator]);

  return (
    <div
      className={`${
        selectedAmountOperator === GLOBAL_SEARCH_NUMERIC_FILTER_OPERATORS.IN_BETWEEN || !_.isEmpty(selectedAmountOperator) ? fillDivRoot : divRoot
      }`}
    >
      <Select
        onClose={handleDropdownClose}
        className={select}
        MenuProps={{
          className: amountMenuRoot,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
        IconComponent={KeyboardArrowDown}
        defaultValue={""}
        displayEmpty={true}
        renderValue={processSelectedValue}
      >
        <div className={amountMenuWrapper}>{titleText}</div>
        <div className={amountOperatorWrapper}>
          <Dropdown
            handleDropDownChange={handleAmountOperatorChange}
            selectedValue={selectedAmountOperator}
            optionList={OperatorOptionsList(t)}
            hasValueChanged={!_.isEmpty(selectedAmountOperator)}
            defaultText={t("globalSearchResults.filters.numericFilter.menuListLabels.select", { ns: "global_search" })}
            param=""
          />
          {selectedAmountOperator !== GLOBAL_SEARCH_NUMERIC_FILTER_OPERATORS.IN_BETWEEN && (
            <Input
              className={inputContainer}
              value={selectedAmountValue}
              placeholder={"0.00"}
              disabled={_.isEmpty(selectedAmountOperator) || selectedAmountOperator === GLOBAL_SEARCH_NUMERIC_FILTER_OPERATORS.IN_BETWEEN}
              onChange={handleAmountValueChange}
              startAdornment={<InputAdornment position="start">{adornmentSymbol}</InputAdornment>}
              type="number"
              disableUnderline={true}
            />
          )}
        </div>
        {selectedAmountOperator === GLOBAL_SEARCH_NUMERIC_FILTER_OPERATORS.IN_BETWEEN && (
          <RangeSlider
            adornmentSymbol={adornmentSymbol}
            range={selectedAmountRange}
            onSliderChange={handleSliderChange}
            onMinChange={handleSliderMinChange}
            onMaxChange={handleSliderMaxChange}
          />
        )}
      </Select>
    </div>
  );
};

export default DropdownNumeric;

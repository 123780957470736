/*eslint-disable no-magic-numbers*/
import { createTheme } from "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import { colors } from "./styles";
import { Palette } from "./constants/colours.palette";

interface ExtendedTypographyOptions extends TypographyOptions {
  h4Reg: React.CSSProperties;
  h4Med: React.CSSProperties;
  body1Med: React.CSSProperties;
  body1Reg: React.CSSProperties;
  captionReg: React.CSSProperties;
  captionMed: React.CSSProperties;
  captionSm: React.CSSProperties;
}

/**
 * @param px input px as per 1440 * 1024 resolution
 */

export const pxToRem = (px: number) => `${px / 16}rem`;

/**
 * @theme as per 1440 * 1024 resolution
 */

export default createTheme({
  palette: {
    primary: {
      main: "#143B52",
    },
    secondary: {
      main: "#64748B",
    },
    background: {
      light: "#FFFFFF",
      primary: "#F5F5F5",
      secondary: "#EADDFF",
      dark: "#F7F7FB",
    },
    text: {
      primary: "#000000",
      secondary: "#143B52",
      disabled: "#49454F",
    },
    error: {
      contrastText: colors.red[Palette["700"]],
      main: colors.red[Palette["50"]],
      border: colors.red[Palette["700"]],
    },
    info: {
      contrastText: colors.blue[Palette["700"]],
      main: colors.blue[Palette["50"]],
      border: colors.blue[Palette["700"]],
    },
    success: {
      contrastText: colors.green[Palette["700"]],
      main: colors.green[Palette["50"]],
      border: colors.green[Palette["700"]],
    },
    warning: {
      contrastText: colors.orange[Palette["700"]],
      main: colors.orange[Palette["50"]],
      border: colors.orange[Palette["700"]],
    },
  },
  typography: {
    allVariants: {
      margin: 0,
    },
    h1: {
      fontFamily: "Work Sans",
      fontSize: pxToRem(40),
      fontWeight: 600,
      fontStyle: "normal",
      lineHeight: pxToRem(56),
    },
    h2: {
      fontFamily: "Work Sans",
      fontSize: pxToRem(24),
      fontWeight: 600,
      fontStyle: "normal",
      lineHeight: pxToRem(32),
    },
    h3: {
      fontFamily: "Work Sans",
      fontSize: pxToRem(18),
      fontWeight: 600,
      fontStyle: "normal",
      lineHeight: pxToRem(24),
    },
    h4: {
      fontFamily: "Work Sans",
      fontSize: pxToRem(16),
      fontWeight: 600,
      fontStyle: "normal",
      lineHeight: pxToRem(24),
    },
    h4Med: {
      fontFamily: "Work Sans",
      fontSize: pxToRem(16),
      fontWeight: 500,
      fontStyle: "normal",
      lineHeight: pxToRem(24),
    },
    h4Reg: {
      fontFamily: "Work Sans",
      fontSize: pxToRem(16),
      fontWeight: 400,
      fontStyle: "normal",
      lineHeight: pxToRem(24),
    },
    body1: {
      fontFamily: "Work Sans",
      fontSize: pxToRem(14),
      fontWeight: 600,
      fontStyle: "normal",
      lineHeight: pxToRem(20),
    },
    body1Med: {
      fontFamily: "Work Sans",
      fontSize: pxToRem(14),
      fontWeight: 500,
      fontStyle: "normal",
      lineHeight: pxToRem(20),
    },
    body1Reg: {
      fontFamily: "Work Sans",
      fontSize: pxToRem(14),
      fontWeight: 400,
      fontStyle: "normal",
      lineHeight: pxToRem(20),
    },
    captionReg: {
      fontFamily: "Work Sans",
      fontSize: pxToRem(12),
      fontWeight: 400,
      fontStyle: "normal",
      lineHeight: pxToRem(16),
    },
    caption: {
      fontFamily: "Work Sans",
      fontSize: pxToRem(12),
      fontWeight: 600,
      fontStyle: "normal",
      lineHeight: pxToRem(16),
    },
    captionMed: {
      fontFamily: "Work Sans",
      fontSize: pxToRem(12),
      fontWeight: 500,
      fontStyle: "normal",
      lineHeight: pxToRem(16),
    },
    captionSm: {
      fontFamily: "Work Sans",
      fontSize: pxToRem(10),
      fontWeight: 500,
      fontStyle: "normal",
      lineHeight: pxToRem(12),
      letterSpacing: pxToRem(0.4),
      textTransform: "uppercase",
    },
  } as ExtendedTypographyOptions,
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
          color: "#BDC3DB",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: "100%",
          height: "100%",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: `${pxToRem(4)}`,
          fontFamily: "Work Sans",
          fontSize: pxToRem(14),
          fontWeight: 400,
          fontStyle: "normal",
          lineHeight: pxToRem(20),
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFFFFF",
          height: pxToRem(2),
        },
        bar: {
          backgroundColor: "transparent",
          background: "linear-gradient(90deg, #5955A5 5.24%, #EE398B 44.07%, #F9A456 68.87%, #00BAD4 95.07%);",
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          fontFamily: "Work Sans",
          fontSize: pxToRem(14),
          fontWeight: 400,
          fontStyle: "normal",
          lineHeight: pxToRem(20),
          margin: `0 ${pxToRem(3)}`,
          borderRadius: pxToRem(4),
          minWidth: pxToRem(32),
          height: pxToRem(32),
          padding: `0 ${pxToRem(6)}`,
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
  },
});

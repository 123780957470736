import React from "react";
import SmartTextComponent from "./SmartTextComponent";
import { SmartTextContainerInterface } from "./SmartTextInterfaces";

const SmartTextContainer: React.FC<SmartTextContainerInterface> = ({ tooltipRequired = true, ...props }) => {
  const divRef = React.useRef<HTMLDivElement | null>(null);
  const contentRef = React.useRef<HTMLParagraphElement | null>(null);
  const [showTooltip, setShowTooltip] = React.useState<boolean>(false);

  const toggleTooltip = () => {
    if (divRef.current && contentRef.current && divRef.current.clientWidth < contentRef.current.scrollWidth) {
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  };

  const highlightContent = () => {
    const { highlighter, title } = props;
    if (!highlighter?.matchText) {
      return title;
    }
    /* check if the highlighter.matchText property contains any characters that could be
     * interpreted as part of a character class, such as [ or ], and escape them using a backslash \.
     */
    const escapedMatchText = highlighter.matchText.replace(/[+()[\]\\]/g, "\\$&");

    const matchRegex = new RegExp(escapedMatchText, "ig");
    const matchKeys = title.match(matchRegex);
    return matchKeys ? title.replace(matchRegex, "<mark>$&</mark>") : title;
  };

  React.useEffect(() => {
    toggleTooltip();
    window.addEventListener("resize", toggleTooltip);
    return () => window.removeEventListener("resize", toggleTooltip);
  }, []);

  return (
    <SmartTextComponent
      {...props}
      title={props?.highlighter?.enableHighlighter ? highlightContent() : props.title}
      toggleTooltip={toggleTooltip}
      tooltip={{ showTooltip: tooltipRequired ? showTooltip : false, title: props.title }}
      divRef={divRef}
      contentRef={contentRef}
    />
  );
};

export default SmartTextContainer;

import APIClient from "../APIClient";
import { AxiosRequestConfig } from "axios";

const INBOX_API_BASE = process.env.REACT_APP_API_ENDPOINT_INBOX_API_PATH;
const CONFIG_OVERRIDE = {
  baseURL: process.env.REACT_APP_API_ENDPOINT_INBOX,
} as AxiosRequestConfig;

export class AuthClient {
  BASE_URL = `${INBOX_API_BASE}/magic_links`;

  getToken(id: UUID): Promise<APIResponse> {
    const url = `${this.BASE_URL}/${id}/token`;
    return APIClient.get(url, CONFIG_OVERRIDE, true, false, false).then((res) => res.data);
  }
}

import React, { useRef, useState, useEffect, MouseEvent } from "react";

import Chip from "../../components/library/Chip/Chip";
import { calcDaysBetween, formatTime } from "../../db/utils/date";
import Utils from "../utils";
import {
  Ellipses,
  EmailAttachment,
  PrimaryContact,
  UserPic,
  FileAttachment,
  VideoAttachment,
  DocAttachment,
  OtherAttachment,
  CSVAttachment,
  PDFAttachment,
  ImageAttachment,
} from "../../components/library/Icons/Icons";
import { ReactComponent as ActivityFlag } from "../../assets/ActivityFlag.svg";
import "./TableUtils.scss";
import { ClickAwayListener, Tooltip } from "@mui/material";
import { BUCKET_CONSTANTS, DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../constants/NumericConstants";
import { makeStyles } from "@mui/styles";
import MenuItem from "../../components/library/MenuItem/MenuItem";
import { InvoiceStatusType } from "../../types/enums";
import { AlertContext } from "../../contexts/AlertContext";
import { ActivityContext } from "../../contexts/ActivityContext";
import { flagScheme } from "../../components/Activities/ActivityDetail/ActivityFeed/ActivityFeedItem/PrimaryActivity/activityFlagScheme";
import CustomTooltip from "../../components/library/CustomTooltip/CustomTooltip";
import { TooltipTitles, TooltipTypes } from "../../constants/TooltipConstants";
import _ from "underscore";
import useLocale from "../../hooks/useLocale";
import { AttachmentPreview } from "../../components/library/AtomicComponents/AttachmentPreview";
import { Pagination } from "../../components/library/AtomicComponents/Table/types/hooks.types";

const INTERVAL = 300;

const TableDateFormat = (date: string | null) => {
  const { formatDateBasedOnCountry: formatDate } = useLocale();
  return formatDate(date);
};
export default class TableUtils {
  public static withFormattedCellString(child: string, isLight?: boolean, style?: string): React.ReactElement {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [disableHover, setDisableHover] = useState(false);
    function handleResize() {
      if (wrapperRef) {
        if (
          (wrapperRef.current?.offsetWidth ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) <
          (wrapperRef.current?.scrollWidth ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO)
        ) {
          setDisableHover(false);
        } else {
          setDisableHover(true);
        }
      }
    }

    useEffect(() => {
      handleResize();
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    });

    return (
      <CustomTooltip
        type={child?.length > NUMERIC_VALUES.CONSTANT_TWENTY ? TooltipTypes.RICH : TooltipTypes.PLAIN}
        title={child}
        followCursor
        disableHoverListener={disableHover}
      >
        <div ref={wrapperRef} className={`table-formatted-cell ${style} ${isLight ? "light" : ""}`}>
          {child}
        </div>
      </CustomTooltip>
    );
  }

  public static withFormattedCellElement(child: React.ReactElement, isLight?: boolean): React.ReactElement {
    return <div className={`table-formatted-cell ${isLight ? "light" : ""}`}>{child}</div>;
  }

  public static formatString(str: string | null, isLight?: boolean, style?: string): React.ReactElement {
    return this.withFormattedCellString(str || "-", isLight, style);
  }

  public static formatContactString(value: string | null) {
    return <div className={"table-formatted-cell contact-bold"}>{value}</div>;
  }

  public static formatNumber(num: number | null, isLight?: boolean): React.ReactElement {
    return this.withFormattedCellString(num && typeof num === "number" ? num?.toString?.() : "-", isLight);
  }

  public static formatId(id: string | null, isLight?: boolean): React.ReactElement {
    return this.withFormattedCellString(id ? `#${id}` : "-", isLight);
  }

  public static formatPhoneNumber(value: string | null, style?: string): React.ReactElement {
    return <div className={`table-formatted-cell ${style}`}>{Utils.formatPhoneNumber(value ?? "-")}</div>;
  }

  public static formatStringWithIcon(value: string | null, isPrimary = false): React.ReactElement {
    return (
      <div className={"table-formatted-cell contact-bold icon-wrapper"}>
        <div className="profile-wrapper">{isPrimary ? <PrimaryContact /> : <UserPic />}</div>
        <div className="user-name">{value}</div>
      </div>
    );
  }

  public static formatIcon(value: string | null): React.ReactElement {
    let tooltipText;
    if (value === "primary") {
      tooltipText = "Primary";
    }
    return (
      <div className={`table-formatted-cell`}>
        <CustomTooltip
          type={(tooltipText || "")?.length > NUMERIC_VALUES.CONSTANT_TWENTY ? TooltipTypes.RICH : TooltipTypes.PLAIN}
          title={tooltipText ?? ""}
          followCursor
          disableHoverListener={tooltipText ? false : true}
        >
          <div className={"icon-wrapper"}>{value === "primary" ? <PrimaryContact /> : ""}</div>
        </CustomTooltip>
      </div>
    );
  }

  public static formatActivityCellValue(value: string | null, isUnread: boolean): React.ReactElement {
    if (!value) return this.withFormattedCellString("N/A", false);
    return (
      <div className={`table-formatted-cell ${isUnread ? "unread" : "read"}`}>
        <p className="subject">{value}</p>
      </div>
    );
  }

  public static formatActivitySubject(value: { subject: string | null; attachments: any } | null, isUnread: boolean): React.ReactElement {
    const { setShowAttachmentTab } = React.useContext(ActivityContext) as ActivityType;

    const onClickShowMore = () => {
      setShowAttachmentTab(true);
    };

    const toastContext = React.useContext(AlertContext) as AlertContextType;

    const [openPreview, setOpenPreview] = React.useState<boolean>(false);
    const [activeAttachmentID, setActiveAttachmentID] = React.useState<string | number>("");

    const onClickPreviewClose = (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement> | KeyboardEvent) => {
      event.stopPropagation();
      setOpenPreview(false);
    };

    const onClickPreviewOpen = (event: MouseEvent<HTMLDivElement>, activeAttachmentID: string | number) => {
      event.stopPropagation();
      setActiveAttachmentID(activeAttachmentID);
      setOpenPreview(!openPreview);
    };

    const onClickDownload = (
      event: MouseEvent<HTMLTableRowElement | HTMLDivElement | HTMLButtonElement>,
      attachments: AttachmentItem[] | BlobAttachmentItem[]
    ) => {
      event.stopPropagation();
      TableUtils.downloadAll(attachments, toastContext?.setToastOptions);
    };

    const AttachmenChip: React.FC<{ attachment: AttachmentItem }> = ({ attachment }) => {
      const getAttachmentIcon = (extension: string) => {
        switch (extension) {
          case "pdf":
            return <PDFAttachment />;
          case "doc":
            return <DocAttachment />;
          case "zip":
            return <FileAttachment />;
          case "csv":
          case "xlss":
            return <CSVAttachment />;
          case "mp4":
          case "mov":
          case "wmv":
          case "avi":
          case "avchd":
          case "flv":
          case "f4v":
          case "swf":
          case "mkv":
          case "webm":
          case "html5":
          case "mpeg2":
          case "gif":
            return <VideoAttachment />;
          case "jpg":
          case "jpeg":
          case "png":
          case "tiff":
          case "psd":
          case "eps":
          case "ai":
          case "indd":
          case "raw":
            return <ImageAttachment />;
          default:
            return <OtherAttachment />;
        }
      };

      return (
        <CustomTooltip type={TooltipTypes.PLAIN} title={TooltipTitles.CLICK_TO_PREVIEW}>
          <div className="attachment-chip" onClick={(event: MouseEvent<HTMLDivElement>) => onClickPreviewOpen(event, attachment.id)}>
            <>
              {getAttachmentIcon(attachment.extension)} <span className="attachment-text">{attachment.file_name}</span>
            </>
          </div>
        </CustomTooltip>
      );
    };

    const ShowMoreChip: React.FC<{ restCount: number }> = ({ restCount }) => {
      return (
        <CustomTooltip type={TooltipTypes.PLAIN} title={"Show More"}>
          <div className="attachment-show-more-chip" onClick={() => onClickShowMore()}>
            <span className="attachment-text">{`${restCount} More`}</span>
          </div>
        </CustomTooltip>
      );
    };

    if (!value || (!value.attachments && !value.subject)) return this.withFormattedCellString("N/A", false);

    return value?.attachments?.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO ? (
      <div className={`table-formatted-cell ${isUnread ? "unread" : "read"}`}>
        <div className="subject-attachment-wrapper">
          <span className="subject">{value?.subject ?? "N/A"}</span>
          <div className="attachments-container">
            {value?.attachments.length > NUMERIC_VALUES.CONSTANT_THREE ? (
              <>
                <AttachmenChip key={value?.attachments[0].file_name + Math.random()} attachment={value?.attachments[0]} />
                <AttachmenChip key={value?.attachments[1].file_name + Math.random()} attachment={value?.attachments[1]} />
                <ShowMoreChip restCount={value?.attachments.length - NUMERIC_VALUES.CONSTANT_TWO} />
              </>
            ) : (
              value?.attachments.map((item: any, key: number) => <AttachmenChip key={key} attachment={item} />)
            )}
          </div>
        </div>
        {openPreview && (
          <AttachmentPreview
            openPreview={openPreview}
            activeAttachmentID={activeAttachmentID}
            handleClose={onClickPreviewClose}
            attachments={value?.attachments}
            onClickDownload={onClickDownload}
          />
        )}
      </div>
    ) : (
      <div className={`table-formatted-cell ${isUnread ? "unread" : "read"}`}>
        {value.subject && <span className="subject">{value?.subject}</span>}
      </div>
    );
  }

  public static formatActivityIndex(value: any, isUnread: boolean): React.ReactElement {
    if (value) {
      if (value instanceof Object) {
        return (
          <div className={`table-formatted-cell ${isUnread ? "unread" : "read"}`}>
            {value?.primary_connection?.name ? (
              <p className="subject">{value.primary_connection.name}</p>
            ) : value?.primary_connection?.email_address ? (
              <p className="subject">{value.primary_connection.email_address}</p>
            ) : (
              this.withFormattedCellString("N/A", false)
            )}
          </div>
        );
      } else {
        return (
          <div className={`table-formatted-cell ${isUnread ? "unread" : "read"}`}>
            <p className="subject">{value}</p>
          </div>
        );
      }
    } else {
      return this.withFormattedCellString("N/A", false);
    }
  }

  public static formatActivityFlag(flag: StreamFlag | null): React.ReactElement {
    return (
      <div className="table-formatted-cell">
        {flag && Object.keys(flagScheme).includes(flag) ? (
          <div className={`table-formatted-cell-flag ${flagScheme[flag].color}`}>
            <div className={`table-formatted-cell-flag-icon`}>
              <ActivityFlag />
            </div>
            <span className={`table-formatted-cell-title ${flag}`}>{flagScheme[flag].title}</span>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }

  public static formatCurrency(num: number | null, isLight?: boolean, locale = "en-US", code = "USD"): React.ReactElement {
    const currency = _.isNumber(num)
      ? new Intl.NumberFormat(locale, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
          style: "currency",
          currency: code,
        }).format(num)
      : "-";
    return this.withFormattedCellString(currency, isLight);
  }

  public static formatAttachmentIcon(hasAttachment?: boolean): React.ReactElement {
    if (!hasAttachment) return <></>;
    return this.withFormattedCellElement(<EmailAttachment />);
  }

  public static formatActivityBody(content: string[] | null, isUnread?: boolean): React.ReactElement {
    if (content) {
      if (isUnread) {
        return (
          <>
            <div className={`table-formatted-cell bold`}>
              <span>{content[0]}</span> <span className="email-content">- {content[1]}</span>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className={`table-formatted-cell read-bold`}>
              <span>{content[0]}</span> <span className="email-content">- {content[1]}</span>
            </div>
          </>
        );
      }
    } else {
      return this.withFormattedCellString("N/A", false);
    }
  }

  public static formatActivitySimple(value: string | null, isUnread?: boolean): React.ReactElement {
    if (!value) return this.withFormattedCellString("N/A", false);
    return (
      <div className={`table-formatted-cell ${isUnread ? "unread" : "read"}`}>
        <p className="subject">{value}</p>
      </div>
    );
  }

  public static formatActivitySentDate(date: string | null, isUnread?: boolean): React.ReactElement {
    if (date) {
      const diffDays = calcDaysBetween(date.split("T")[0]);
      if (diffDays == DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
        return this.formatActivitySimple(formatTime(date), isUnread);
      }
      if (diffDays == DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) {
        return this.formatActivitySimple("Yesterday", isUnread);
      }
      return this.formatActivitySimple(TableDateFormat(date), isUnread);
    } else {
      return this.withFormattedCellString("N/A");
    }
  }

  public static formatActivitySentDateEpoch(date: number | null, isUnread?: boolean): React.ReactElement {
    if (date) {
      const dateFromEpoch = new Date(date * NUMERIC_VALUES.CONSTANT_THOUSAND);
      return this.formatActivitySentDate(dateFromEpoch.toISOString(), isUnread);
    } else {
      return this.withFormattedCellString("N/A");
    }
  }

  public static formatDate(date: string | null, isLight?: boolean, style?: string): React.ReactElement {
    return this.withFormattedCellString(TableDateFormat(date), isLight, style);
  }

  public static formatLastActivity(date: string | null, isLight?: boolean): React.ReactElement {
    const diffDays = calcDaysBetween(date);
    const lastActivity =
      diffDays === null
        ? "-"
        : diffDays === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
        ? "Today"
        : diffDays === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
        ? "Yesterday"
        : diffDays >= NUMERIC_VALUES.CONSTANT_TWO && diffDays <= NUMERIC_VALUES.CONSTANT_SEVEN
        ? `${diffDays} days ago`
        : TableDateFormat(date);
    return this.withFormattedCellString(lastActivity, isLight);
  }

  public static formatDaysLate(date: string, isLight?: boolean): React.ReactElement {
    const daysLate = Math.abs(calcDaysBetween(date) ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    const finalDateString =
      `${daysLate?.toString()} ${
        Math.abs(daysLate ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE ? "day" : "days"
      }` ?? "-";
    return this.withFormattedCellString(finalDateString, isLight);
  }

  public static formatActivityStatus(status: string | null): React.ReactElement {
    if (status) {
      const activityStatus = status.toLowerCase();
      if (activityStatus === "active") {
        return this.withFormattedCellElement(<Chip variant="active" text="ACTIVE" size="lg" />);
      }
      if (activityStatus === "closed") {
        return this.withFormattedCellElement(<Chip variant="activity-closed" text="CLOSED" size="lg" />);
      }
      if (activityStatus === "waiting_for_response") {
        return this.withFormattedCellElement(<Chip variant="not-started" text="WAITING FOR RESPONSE" size="lg" />);
      }
      if (activityStatus === "snoozed") {
        return this.withFormattedCellElement(<Chip variant="snoozed" text="SNOOZED" size="lg" />);
      }
    }
    return this.withFormattedCellElement(<Chip variant="not-started" text="NOT STARTED" size="lg" />);
  }

  public static formatDocumentStatus(status: string | null): React.ReactElement {
    if (status && status === "active") {
      return this.withFormattedCellElement(<Chip variant="active" text="ACTIVE" size="lg" />);
    }
    return this.withFormattedCellElement(<Chip variant="activity-closed" text="ARCHIVED" size="lg" />);
  }

  public static formatInvoiceStatus(status?: string | null, dueDate?: string, isLight?: boolean): React.ReactElement {
    // Check for an empty date
    switch (status) {
      case "Open":
        return this.withFormattedCellElement(<Chip variant="open" text="OPEN" size="lg" />, isLight);
      case "Closed":
        return this.withFormattedCellElement(<Chip variant="closed" text="CLOSED" size="lg" />, isLight);
      case "Past Due":
        {
          const daysPastDue = calcDaysBetween(dueDate ?? "");
          if (daysPastDue) {
            if (daysPastDue > BUCKET_CONSTANTS.FIRST_PAST_DUE_BUCKET && daysPastDue <= BUCKET_CONSTANTS.SECOND_PAST_DUE_BUCKET) {
              return this.withFormattedCellElement(<Chip variant="late1" text="PAST DUE 1-30" size="lg" />, isLight);
            } else if (daysPastDue > BUCKET_CONSTANTS.SECOND_PAST_DUE_BUCKET && daysPastDue <= BUCKET_CONSTANTS.THIRD_PAST_DUE_BUCKET) {
              return this.withFormattedCellElement(<Chip variant="late31" text="PAST DUE 31-60" size="lg" />, isLight);
            } else if (daysPastDue > BUCKET_CONSTANTS.THIRD_PAST_DUE_BUCKET && daysPastDue <= BUCKET_CONSTANTS.FOURTH_PAST_DUE_BUCKET) {
              return this.withFormattedCellElement(<Chip variant="late61" text="PAST DUE 61-90" size="lg" />, isLight);
            } else if (daysPastDue > BUCKET_CONSTANTS.FOURTH_PAST_DUE_BUCKET) {
              return this.withFormattedCellElement(<Chip variant="late91" text="PAST DUE 91+" size="lg" />, isLight);
            }
          }
        }
        return this.withFormattedCellElement(<Chip variant="grey" text="-" size="lg" />, isLight);
      default:
        return this.withFormattedCellElement(<Chip variant="grey" text="-" size="lg" />, isLight);
    }
  }

  public static formatPaymentType(type: string | null, isLight?: boolean): React.ReactElement {
    let paymentType = "-";
    if (type === "Check") {
      paymentType = "Check";
    } else if (type === "Wire") {
      paymentType = "Wire";
    } else if (type === "Cash") {
      paymentType = "Cash";
    } else if (type === "Credit Card") {
      paymentType = "Credit Card";
    } else if (type === "CR") {
      paymentType = "Cash Receipt";
    } else if (type === "RV") {
      paymentType = "Reversal";
    } else if (type === "Wire Transfer") {
      paymentType = "Wire Transfer";
    } else if (type === "AP Payment") {
      paymentType = "AP Payment";
    }
    return this.withFormattedCellString(paymentType, isLight);
  }

  public static formatList(list: string[] | null, isLight?: boolean, id?: any): React.ReactElement {
    let invoiceText = "";
    let invoiceTooltip: string | React.ReactElement = "";
    if (Array.isArray(list) && list.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      if (list.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) {
        invoiceText = invoiceTooltip = list[0];
      } else {
        invoiceText = `${list.length} Applications`;
        invoiceTooltip = (
          <div key={`${id}-${list[0]}`}>
            {list.map((elem: string, index: number) => {
              return (
                <>
                  {elem}
                  {index !== list.length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE && <br />}
                </>
              );
            })}
          </div>
        );
      }
    } else {
      invoiceText = invoiceTooltip = "-";
    }

    const wrapperRef = useRef<HTMLDivElement>(null);
    const [disableHover, setDisableHover] = useState(false);

    function handleResize() {
      if (wrapperRef) {
        if (
          (wrapperRef.current?.offsetWidth ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) <
            (wrapperRef.current?.scrollWidth ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) ||
          (list?.length ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) > DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
        ) {
          setDisableHover(false);
        } else {
          setDisableHover(true);
        }
      }
    }

    useEffect(() => {
      handleResize();
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    });

    return (
      <CustomTooltip type={TooltipTypes.PLAIN} title={invoiceTooltip} followCursor disableHoverListener={disableHover}>
        <div ref={wrapperRef} className={`table-formatted-cell ${isLight ? "light" : ""}`}>
          {invoiceText}
        </div>
      </CustomTooltip>
    );
  }

  public static generateMenuList(menuList: Array<any>, ClickValue: any = {}) {
    return menuList.map((item, index) => {
      return (
        <MenuItem
          key={index}
          onClick={(e) => {
            e.stopPropagation();
            if (item.clickFunction) {
              item.clickFunction(ClickValue, item.id);
            }
          }}
          id={item.id}
          disabled={item?.disabled}
        >
          {item.value}
        </MenuItem>
      );
    });
  }

  public static formatAction(value: any, listValue: any[]): React.ReactElement {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    if (!listValue.length) {
      return <></>;
    }
    const useStyles = makeStyles(() => ({
      tooltip: {
        background: "white !important",
        margin: "0 !important",
        padding: "0 !important",
        borderRadius: "0.125rem",
        boxShadow: "0 0.5rem 0.75rem rgba(0, 0, 0, 0.1), 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1)",
      },
    }));

    const classes = useStyles();

    const handleTooltipToggle = (e: React.SyntheticEvent, toggleTooltip: boolean) => {
      e.stopPropagation();
      setIsTooltipOpen(!toggleTooltip);
    };

    const handleTooltipClose = () => {
      setIsTooltipOpen(false);
    };

    return (
      <ClickAwayListener onClickAway={handleTooltipClose} mouseEvent="onMouseUp">
        <Tooltip title={this.generateMenuList(listValue, value)} classes={{ tooltip: classes.tooltip }} placement="bottom-start" open={isTooltipOpen}>
          <div className="action-handler" onClick={(e) => handleTooltipToggle(e, isTooltipOpen)}>
            <Ellipses />
          </div>
        </Tooltip>
      </ClickAwayListener>
    );
  }

  /**
   * This method enhances the url with required filters and sort params
   */
  public static urlEnhancer(url: string, sortQuery: string | undefined, filterQuery: Array<any>): string {
    if (sortQuery) {
      sortQuery = sortQuery && !sortQuery.includes("DESC") ? `${sortQuery} ASC` : sortQuery;
      url = `${url}&qs[s]=${sortQuery.toLowerCase()}`;
    }
    if (!sortQuery) {
      url = `${url}&qs[s]=created_at desc`;
    }
    url = `${url}&${this.columnFilterParser(filterQuery, true)}`;
    return url;
  }

  /**
   * This function creates a downloadable link and triggers a click on a url iterable,
   * one by one and simulates an organic user click by using a stack
   *
   * Extended to provide toast if toastOptions dispatcher is provided as input.
   * @param files - file url array
   * @param setToastOptions toast options state dispatcher
   */
  public static downloadAll(files: any[], setToastOptions?: React.Dispatch<React.SetStateAction<ToastOptions>>): void {
    //eslint-disable-next-line
    let asyncDownload = setInterval(download, INTERVAL, files);
    function download(files: any[]) {
      const file = files.pop();
      const link = document.createElement("a");
      link.download = file?.file_name ?? "file";
      link.href = file?.file_url;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      link.remove();
      setToastOptions?.({ open: true, severity: "success", message: "Downloading attachment..." });
      if (files.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
        clearInterval(asyncDownload);
        return;
      }
    }
  }

  /**
   * This function just returns the sort query from the url
   * @param params - params to be passed to the url
   * @returns sort parameter from url
   */
  public static getSortFromURL = (params: URLSearchParams): string | undefined => {
    return params.get("sort") ?? undefined;
  };

  public static sortQueryParser = (query: string, getRansacQuery: boolean) => {
    let sortQuery = "";
    if (query) {
      if (getRansacQuery) {
        sortQuery = `qs[s]=${(!(query.includes("DESC") || query.includes("desc"))
          ? `${query} ${query.includes(" asc") ? "" : "asc"}`
          : query
        ).toLowerCase()}`;
      } else {
        sortQuery = query;
      }
    }
    return sortQuery;
  };

  /**
   * This function returns filter object array for V1 api based on search light field, token and value selected
   * @param params - params to be passed to the url
   * @param columns - table columns
   * @returns filters array from url
   */
  public static getFilterFromURL = (params: URLSearchParams, columns: ColumnHeaderType[]): SearchFilter[] => {
    const ret: SearchFilter[] = [];
    type FilterType = {
      field: string | null;
      token: string | null;
      value: string | null;
    };

    const filters: FilterType[] = [];

    columns.forEach((column: ColumnHeaderType) => {
      const value = params.get(column.accessor)?.replaceAll(/'/g, "''") ?? null;
      if (column.searchlightField !== "days_past_due" && column.searchlightField !== "DAYSPASTDUE") {
        filters.push({ field: column.searchlightField ?? null, token: column.searchlightToken ?? null, value: value ?? null });
      } else {
        this.addInvoiceDaysPastDueFilters(
          value,
          column.searchlightField ?? null,
          column.searchlightToken ?? null,
          filters,
          column.upperBoundToken ?? null
        );
      }
    });
    filters.forEach((filter: FilterType) => {
      if (filter.value !== null && filter.field != null && filter.token != null) {
        ret.push({ filterKey: filter.field, filterToken: filter.token, filterValue: filter.value });
      }
    });
    return ret;
  };

  public static columnFilterParser = (filters: SearchFilter[], getRansacQuery: boolean, preDefinedFilters?: any[]): string => {
    let constructedFilter = "";
    const list: any = [];
    preDefinedFilters?.map((filter: any) => {
      list.push(filter.searchlightFilter);
    });
    if (getRansacQuery) {
      filters.forEach((filter: SearchFilter) => {
        list.push(`qa[${filter.filterKey}_${filter.filterToken}]=${filter.filterValue}`);
      });
      constructedFilter = `${list.join("&")}`;
    } else {
      // getPlatformFilters
      filters.forEach((filter: SearchFilter) => {
        list.push(`(${filter.filterKey} ${filter.filterToken} '${filter.filterValue}')`);
      });
      constructedFilter = `${list.join(" AND ")}`;
    }
    return constructedFilter;
  };

  public static addInvoiceDaysPastDueFilters = (
    value: string | null,
    searchlightField: string | null,
    searchlightToken: string | null,
    filters: any[],
    upperBoundToken?: string | null
  ) => {
    const valueInt = parseInt(value ?? "0", 10);
    const status = InvoiceStatusType[valueInt];
    if (!value || !searchlightField || !searchlightToken) {
      return;
    }

    if (status?.toLocaleLowerCase() === "closed") {
      filters.push({ field: "status", token: "eq", value: "Closed" });
    } else if (status?.toLocaleLowerCase() === "open") {
      filters.push({ field: "status", token: "eq", value: "Open" });
    } else {
      if (searchlightField === "DAYSPASTDUE") {
        filters.push({ field: "status", token: "ne", value: "Closed" });
      } else {
        filters.push({ field: "status", token: "not_eq", value: "Closed" });
      }
      filters.push({
        field: searchlightField,
        token: searchlightToken,
        value: valueInt,
      });
    }

    if (upperBoundToken && valueInt >= DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && valueInt < BUCKET_CONSTANTS.FOURTH_PAST_DUE_BUCKET) {
      filters.push({
        field: searchlightField,
        token: upperBoundToken,
        value: valueInt + BUCKET_CONSTANTS.SECOND_PAST_DUE_BUCKET,
      });
    }
  };

  public static getSortQueryString = (params: URLSearchParams, sortQuery: string) => {
    let sortQueryFromUrl = TableUtils.getSortFromURL(params);
    if (!sortQueryFromUrl) {
      sortQueryFromUrl = sortQuery;
    }
    return sortQueryFromUrl;
  };

  // public static getUpdatePaginationPageState = (paginationConfig: any, cachedData: TableData) => {
  //   const totalRecords = cachedData?.totalCount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
  //   const totalPages = Math.ceil(totalRecords / cachedData?.pageSize) ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
  //   const { page } = paginationConfig;
  //   return {
  //     page: {
  //       pageSize: page?.pageSize ?? 20,
  //       pageNumber: page?.pageNumber ?? 1,
  //       totalPages,
  //       totalRecords,
  //     },
  //   };
  // };
  public static getUpdatePaginationPageState = (paginationConfig: Pagination, totalCount: number, pageSize: number) => {
    const totalRecords = totalCount ?? NUMERIC_VALUES.CONSTANT_ZERO;
    const totalPages = Math.ceil(totalRecords / pageSize) ?? NUMERIC_VALUES.CONSTANT_ZERO;
    const { page } = paginationConfig;
    return {
      page: {
        pageSize: page?.pageSize ?? NUMERIC_VALUES.CONSTANT_TWENTY,
        pageNumber: page?.pageNumber ?? NUMERIC_VALUES.CONSTANT_ONE,
        totalPages,
        totalRecords,
      },
    };
  };
}

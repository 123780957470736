import React from "react";
import SecondaryActivity from "../../SecondaryActivity/SecondaryActivity";
import { SecondaryType } from "../../SecondaryActivity/SecondaryActivityTypes";
import { MarkedSenderProps } from "../ActivityItemVariantTypes";
import { AuditLogType } from "../../../../../../../types/enums";
import { useTranslation } from "react-i18next";

const MarkedSender: React.FC<MarkedSenderProps> = ({ currentStatus: connectionStatus, creator, countryCode }) => {
  const { t } = useTranslation();
  const auditMessage =
    connectionStatus === AuditLogType.SpamConnection
      ? t("activityDetails.feedItems.markSender.headerMessage.asSpam", { ns: "activities" })
      : connectionStatus === AuditLogType.UnspamConnection
      ? t("activityDetails.feedItems.markSender.headerMessage.removeSpam", { ns: "activities" })
      : connectionStatus === AuditLogType.FraudConnection
      ? t("activityDetails.feedItems.markSender.headerMessage.asFraud", { ns: "activities" })
      : connectionStatus === AuditLogType.UnfraudConnection
      ? t("activityDetails.feedItems.markSender.headerMessage.removeFraud", { ns: "activities" })
      : "N/A";

  return <SecondaryActivity variant={SecondaryType.Base} creator={{ ...creator, message: auditMessage }} countryCode={countryCode} />;
};

export default MarkedSender;

import React from "react";
import { Card, CardActions, Skeleton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { ActivityTabType } from "../../../../types/enums";
import { AttachmentChipLoader } from "../../../library/AtomicComponents/AttachmentChip";
import { ContactsCardLoader } from "../../../library/AtomicComponents/ContactsCard";
import { RecordCardViewLoader } from "../../../library/AtomicComponents/RecordCardView";
import "./RightPanelCollapsible.scss";
import "./AttachmentsTab/Attachments.scss";

interface RightPanelCollapsibleSkeletonProps {
  activePanelTab: ActivityTabType | null;
  onPanelClose: () => void;
}

export default function RightPanelCollapsibleSkeleton({ activePanelTab, onPanelClose }: RightPanelCollapsibleSkeletonProps): React.ReactElement {
  return (
    <>
      <div className="right-drawer-info-container">
        <div className="drawer-info-panel">
          <span className="drawer-sub-title">
            <Skeleton variant="text" width="6rem" height="100%" animation="wave" />
          </span>
          <span className="drawer-close-button" onClick={onPanelClose}>
            <Close />
          </span>
        </div>
        <div className="drawer-title">
          <Skeleton variant="text" width="12rem" height="100%" animation="wave" />
        </div>
      </div>
      {activePanelTab === ActivityTabType.Profile && (
        <>
          <ContactsCardLoader />
          <br />
          <ContactsCardLoader />
        </>
      )}
      {activePanelTab === ActivityTabType.Transactions && (
        <div className="transactions-container">
          <RecordCardViewLoader />
        </div>
      )}
      {activePanelTab === ActivityTabType.Attachments && (
        <>
          <Card raised={true} className="attachment-card">
            <AttachmentChipLoader />
            <AttachmentChipLoader />
            <AttachmentChipLoader />
            <AttachmentChipLoader />
            <AttachmentChipLoader />
            <CardActions>
              <Skeleton variant="text" width="25%" height="100%" animation="wave" />
            </CardActions>
          </Card>
        </>
      )}
    </>
  );
}

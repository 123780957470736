import React from "react";
import DialogModalComponent from "./DialogModalComponent";
import { DialogModalInterface } from "./DialogModalInterface";
import "./DialogModalStyles.scss";

const DialogModalContainer: React.FC<DialogModalInterface> = (props: DialogModalInterface) => {
  return (
    <div className="library-modal-container">
      <DialogModalComponent {...props} />
    </div>
  );
};

export default DialogModalContainer;

/*eslint-disable no-magic-numbers*/
import { styled } from "@mui/system";
import Chip from "@mui/material/Chip";
import { pxToRem } from "../../theme/theme";

export const OpenChip = styled(Chip)({
  color: "#24B14B",
  border: `${pxToRem(0.5)} solid #24B14B`,
  background: "white",
  height: "fit-content",
});

export const ClosedChip = styled(Chip)({
  color: "#143b52",
  border: `${pxToRem(0.5)} solid #000`,
  background: "#f2f3f8",
  height: "fit-content",
});

export const WaitingForResponseChip = styled(Chip)({
  color: "#f38923",
  border: `${pxToRem(0.5)} solid #f4bd7c`,
  background: "#fffdf9",
  height: "fit-content",
});

export const ActiveChip = styled(Chip)({
  color: "#1db08a",
  border: `${pxToRem(0.5)} solid #79eacd`,
  background: "#edfffa",
  height: "fit-content",
});

export const SnoozedChip = styled(Chip)({
  color: "#126b88",
  border: `${pxToRem(0.5)} solid #79aebf`,
  background: "#effbff",
  height: "fit-content",
});

import React from "react";
import { BannerRootContainer, BannerContent, PrimaryButton, SecondaryButton, BannerFooterContainer } from "./activitiesheader.banner.styled";
import { useTranslation } from "react-i18next";

type ActivitiesHeaderBannerProps = {
  headerText?: string;
  contentText?: string | React.ReactElement;
  primaryBtnText?: string;
  secondaryBtnText?: string;
  OnClickPrimaryButton: VoidFunction;
  OnClickSecondaryButton: VoidFunction;
};
const ActivitiesHeaderBanner: React.FC<ActivitiesHeaderBannerProps> = ({
  headerText,
  contentText,
  primaryBtnText,
  secondaryBtnText,
  OnClickPrimaryButton,
  OnClickSecondaryButton,
}) => {
  const { t } = useTranslation();

  return (
    <BannerRootContainer>
      <BannerContent>
        <div className="icon-container">{"📣"}</div>
        <div className="text-container">
          <span className="header-text">{headerText ?? t("activityDetails.activitesHeaderBanner.defaultTitle", { ns: "activities" })}</span>
          <span className="content-text">{contentText ?? t("activityDetails.activitesHeaderBanner.defaultContentText", { ns: "activities" })}</span>
        </div>
      </BannerContent>
      <BannerFooterContainer>
        <PrimaryButton onClick={OnClickPrimaryButton}>
          {primaryBtnText ?? t("activityDetails.activitesHeaderBanner.actionButtons.selectManually", { ns: "activities" })}
        </PrimaryButton>
        <SecondaryButton onClick={OnClickSecondaryButton}>
          {secondaryBtnText ?? t("activityDetails.activitesHeaderBanner.actionButtons.confirmAndMove", { ns: "activities" })}
        </SecondaryButton>
      </BannerFooterContainer>
    </BannerRootContainer>
  );
};

export default ActivitiesHeaderBanner;

import React, { useState } from "react";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { CustomDropDownComponent, CustomDropdownPopover } from "../styled/email.template.styled";
import { DropdownArrowDown, DropdownArrowUp } from "../../Icons/Icons";
import { NUMERIC_VALUES } from "../../AtomicComponents/constants/numeric.constants";
import { DropdownListProps } from "../types/email.template.popover.types";

/**
 * It's an custom dropdown to support the customize dropdown list with icon and text
 * icon : isIconRequired true then only left icon will be displayed
 * dropdown: Incase there is only single option then no need of dropdown
 * @param dropDownList: list of items display over the dropdown popover
 * @param selectedDropdownItemIndex: current selected item index from the dropdown
 * @param onClickDropdownItem: handle on click even of the dropdown items
 * @param dropdownAnchorOrigin:[optional]- set the anchor origin posistion of dropdown popover
 * @param dropdownTransformOrigin:[optional]- set the transform origin posistion of dropdown popover
 * @param isIconRequired:[optional] @default value : true - to display left icon incase if there variant of the list items with the icon and text
 */
const DropdownListComponent: React.FC<DropdownListProps> = ({
  dropDownList,
  selectedDropdownItemIndex,
  dropdownAnchorOrigin,
  dropdownTransformOrigin,
  onClickDropdownItem,
  isLeftIconRequired = true,
}) => {
  const [isMenuPopupOpen, setIsMenuPopupOpen] = useState<boolean>(false);
  const [dropdownAnchorEl, setDropdownAnchorEl] = React.useState<null | HTMLElement>(null);

  const toggleMenuPopup = (event: React.MouseEvent<HTMLElement>) => {
    if (dropDownList?.length > NUMERIC_VALUES.CONSTANT_ONE) {
      setIsMenuPopupOpen(!isMenuPopupOpen);
      setDropdownAnchorEl(event.currentTarget);
    }
  };

  const closeMenuPopup = () => {
    setIsMenuPopupOpen(false);
  };

  const getDropDownIcon = () => (isMenuPopupOpen ? <DropdownArrowUp /> : <DropdownArrowDown />);

  return (
    <>
      {/**
       * Selected Value Content from the dropdown with customization of
       * icon : isIconRequired true then only left icon will be displayed
       * dropdown: Incase there is only single option then no need of dropdown
       */}
      <CustomDropDownComponent
        onClick={toggleMenuPopup}
        className={dropDownList?.length > NUMERIC_VALUES.CONSTANT_ONE ? "dropdown-container-root" : "not-dropdown-container-root"}
      >
        <div className="content-container">
          {isLeftIconRequired && <span className="icon">{dropDownList?.[selectedDropdownItemIndex]?.icon}</span>}
          <span className="text">{dropDownList[selectedDropdownItemIndex].text}</span>
        </div>
        <span className="arrow-icon">{dropDownList?.length > NUMERIC_VALUES.CONSTANT_ONE ? getDropDownIcon() : ""}</span>
      </CustomDropDownComponent>

      {/** Dropdown Popover to show list of options */}
      <CustomDropdownPopover
        open={isMenuPopupOpen}
        anchorEl={dropdownAnchorEl}
        anchorOrigin={
          dropdownAnchorOrigin ?? {
            vertical: "bottom",
            horizontal: "right",
          }
        }
        transformOrigin={
          dropdownTransformOrigin ?? {
            vertical: "top",
            horizontal: "right",
          }
        }
        onClose={closeMenuPopup}
      >
        {dropDownList?.map((item, index) => (
          <MenuItem
            key={item.id}
            value={item.id}
            selected={index === selectedDropdownItemIndex}
            onClick={() => {
              if (index !== selectedDropdownItemIndex) {
                onClickDropdownItem(item.id, index);
                closeMenuPopup();
              }
            }}
          >
            {item?.icon && <ListItemIcon>{item?.icon}</ListItemIcon>}
            <ListItemText>{item.text}</ListItemText>
          </MenuItem>
        ))}
      </CustomDropdownPopover>
    </>
  );
};

export default DropdownListComponent;

import React, { useEffect } from "react";
import _ from "underscore";
import { userAccountsClientV2, workspaceClientV2 } from "../db/version2Accessor";
import { useHistory } from "react-router-dom";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../constants/NumericConstants";
import { Alert, Snackbar } from "@mui/material";
import { FallbackTypes, WorkspaceType } from "../types/enums";
import { FeatureFlagContext, FeatureFlagProviderType } from "./FeatureFlagContext";
import useHandleVisibility from "../hooks/useHandleVisibility";
import { AppContext } from "./AppContext";

interface Props {
  children: (React.ReactNode & { type: string })[] | (React.ReactNode & { type: string });
}

export const WorkspaceContext = React.createContext<WorkspaceDataType | null>(null);

const WorkspaceProvider: React.FC<Props> = ({ children }: Props) => {
  /** manages the boolean states through reducer */
  const initialFlags = {
    fetchingWorkspaceMember: false,
  };
  const { visibility, dispatch: visibilityDispatcher } = useHandleVisibility(initialFlags);

  const [showWorkspaceCreateModal, setShowWorkspaceCreateModal] = React.useState<boolean>(false);
  const [fetchingWorkspace, setFetchingWorkspace] = React.useState<boolean>(false);
  const [workspaceData, setWorkspaceData] = React.useState<Array<UserItem>>([]);
  const [workspaceHomePath, setWorkspaceHomePath] = React.useState<string>("");
  const [workspaceConfigChangeInProgress, setWorkspaceConfigChangeInProgress] = React.useState<boolean>(false);
  // TODO Resequence: state should exist in app context
  const [userGroupChanged, setUserGroupChanged] = React.useState<boolean>(false);
  const [workspaceMembers, setFetchWorkspaceMembers] = React.useState<Partial<UserItem>[]>([]);
  // store workspace config when user switches or take the default one or first item in workspace data array
  const [selectedWorkspace, setSelectedWorkspace] = React.useState<UserItem>(workspaceData[0]);
  const [toastOptions, setToastOptions] = React.useState<ToastOptions>({
    open: false,
    severity: "success",
    message: "",
  });
  const [allContactOptions, setAllContactOptions] = React.useState<To[]>([]);
  const { isEnabled } = React.useContext(FeatureFlagContext) as FeatureFlagProviderType;
  const { userStatus } = React.useContext(AppContext) as AppType;
  const history = useHistory();

  const setCurrentWorkspace = (workspace: UserItem) => {
    setSelectedWorkspace(workspace);
    sessionStorage.setItem("selectedWorkspaceId", `${workspace.id}`);
  };

  /**
   * Setting Home Path
   * @param workspace_type : workspace workspace_type
   * @param id : workspace id
   */

  const setWorkspacePath = (workspace_type: string, id: number) => {
    const path = `/${workspace_type?.toUpperCase()}/${id}`;
    setWorkspaceHomePath(path);
    return path;
  };

  /**
   * filter out selected workspace config and set it as selected
   * @param workspaceId
   */
  const switchActiveWorkspace = (workspaceId: number | null) => {
    if (workspaceId && selectedWorkspace?.active_at?.toString?.() !== workspaceId?.toString()) {
      const temp = workspaceData.filter((item) => item.id === workspaceId)[0];
      // Note: rare but possible condition exist where the temp object is undefined
      if (!_.isUndefined(temp) && !_.isEmpty(temp)) {
        setWorkspacePath(temp?.workspace_type_route as string, temp.id as number);
        setCurrentWorkspace(temp);
        if (selectedWorkspace?.workspace_type === "einvoicing") {
          history.push(`/${temp?.workspace_type_route?.toUpperCase?.()}/${temp?.id}/activities/receivedEInvoices`);
        } else {
          history.push(`/${temp?.workspace_type_route?.toUpperCase?.()}/${temp?.id}/activities/mine`);
        }
      }
    }
  };

  /**
   * a pure function to update current workspace & redirects user to it's path
   * @param workspaceId
   */
  const handleWorkspaceSwitch = (workspace: UserItem) => {
    if (workspace && workspace.id && selectedWorkspace?.active_at?.toString?.() !== workspace.id.toString()) {
      setWorkspacePath(workspace?.workspace_type_route as string, workspace.id as number);
      setCurrentWorkspace(workspace);
      if (selectedWorkspace?.workspace_type === "einvoicing") {
        history.push(`/${workspace?.workspace_type_route?.toUpperCase?.()}/${workspace?.id}/activities/receivedEInvoices`);
      } else {
        history.push(`/${workspace?.workspace_type_route?.toUpperCase?.()}/${workspace?.id}/activities/mine`);
      }
    }
  };

  const processWorkspaceData = (data: WorkspaceModel[]) => {
    const records: UserItem[] = [];
    data.forEach((item: WorkspaceModel) => {
      if (!isEnabled("ACCOUNTING_WORKSPACE") && item.workspace_type === "accounting") {
        return;
      }
      if (isEnabled("ACCOUNTING_WORKSPACE")) {
        records.push({
          ...item,
          workspace_type_route:
            item.workspace_type === "accounting"
              ? "aw"
              : item?.workspace_type
                  ?.split?.("_")
                  ?.map?.((e: string) => e[0])
                  .join("") ?? WorkspaceType.AR,
          name: item.workspace_type === "accounting" ? "Accounting Workspace" : item?.name,
        });
      } else {
        records.push({
          ...item,
          workspace_type_route:
            item?.workspace_type
              ?.split?.("_")
              ?.map?.((e: string) => e[0])
              .join("") ?? WorkspaceType.AR,
        });
      }
    });
    return records;
  };

  const setupWorkspace = (data: WorkspaceModel[]) => {
    setFetchingWorkspace(true);
    const workspaces: UserItem[] = processWorkspaceData(data);
    setWorkspaceData(workspaces);

    // Required If we have multiple workspaces and we refresh on any of them
    // Finding if the url is having any workspace ids
    const getWorkspaceArray: Array<UserItem> = workspaces.filter(
      (element: UserItem) => element.id === parseInt(history?.location?.pathname?.split?.("/")[2], 10)
    );
    // If Yes getting the workspace details
    const getWorkspace: UserItem | undefined = getWorkspaceArray[0];
    if (getWorkspace) {
      // Setting the details based on workspaces
      const newPath = setWorkspacePath(getWorkspace.workspace_type_route ?? "", getWorkspace.id ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
      setCurrentWorkspace({ ...(getWorkspace as UserItem) });
      history.push(
        newPath + "/" + history?.location?.pathname?.split("/")?.slice(NUMERIC_VALUES.CONSTANT_THREE)?.join("/") + history?.location?.search
      );
    } else {
      //If Url has no workspace like settings and profile we take the last selected workspace from session and keep the url
      const prevSelectedWorkspace: UserItem = workspaces.filter(
        (element: UserItem) => element.id === parseInt(sessionStorage.getItem("selectedWorkspaceId") ?? "1", 10)
      )[0];
      // If no workspace id in url or session that is a fresh app load take the default workspace
      const defaultWorkspace = workspaces?.filter?.((item: UserItem) => item?.default)[0] ?? workspaces[0];
      setWorkspacePath(
        prevSelectedWorkspace?.workspace_type_route ?? (defaultWorkspace?.workspace_type_route as string),
        prevSelectedWorkspace?.id ?? (defaultWorkspace.id as number)
      );
      setCurrentWorkspace({ ...(prevSelectedWorkspace ?? defaultWorkspace) });
      history.push(history?.location?.pathname + history?.location?.search);
    }
    setFetchingWorkspace(false);
  };

  const currentGroupWorkspaces = React.useCallback(() => {
    return userStatus?.workspaces;
  }, [userStatus?.workspaces]);

  /**
   * if user status object is changed, we check for the current user group workspaces
   * and set up the user's workspace accordingly.
   */
  useEffect(() => {
    const groupWorkspaces = currentGroupWorkspaces();
    if (groupWorkspaces.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      setupWorkspace(groupWorkspaces);
    }
  }, [JSON.stringify(userStatus)]);

  const createNewWorkspace = async (accountConfig: WorkspaceCreate) => {
    setFetchingWorkspace(true);
    let response = {} as APIResponse;
    const toastOptions: ToastOptions = {
      open: true,
      severity: "error",
      message: "Workspace Creation Failed!",
    };
    try {
      response = await workspaceClientV2.createWorkspace(accountConfig as WorkspaceCreate, undefined, false);
    } catch (err: unknown) {
      response.success = false;
    } finally {
      if (response.success && response.data) {
        const newWorkspace = processWorkspaceData([response.data])[0];
        const setNewWorkspaceData: Array<UserItem> = [...workspaceData, newWorkspace];

        setWorkspaceData(setNewWorkspaceData);
        const selectWorkspace = setNewWorkspaceData[setNewWorkspaceData.length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE] as UserItem;
        const newPath = setWorkspacePath(selectWorkspace.workspace_type_route ?? "", selectWorkspace.id ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
        setCurrentWorkspace(selectWorkspace);
        history.push(`${newPath}/activities/mine`);
      } else {
        setToastOptions(toastOptions);
      }
      setFetchingWorkspace(false);
      setShowWorkspaceCreateModal(true);
    }
    return response.success || false;
  };

  /**
   * members belong to the current user workspace are required to enable
   * multiple inbox functionalities -
   * 1. re-assign activity stream
   * 2. single user workspace - auto assignment + invite people banner on unassigned
   * 3. inbox mentions
   */
  const fetchWorkspaceMembers = async () => {
    try {
      visibilityDispatcher({ type: "open", payload: "fetchingWorkspaceMember" });
      const result = await userAccountsClientV2.getWorkspaceMembersForGroup(selectedWorkspace?.id || FallbackTypes.Id);
      setFetchWorkspaceMembers(result.data);
    } catch (error) {
      console.error("error: ", error);
    } finally {
      visibilityDispatcher({ type: "close", payload: "fetchingWorkspaceMember" });
    }
  };

  React.useEffect(() => {
    if (!_.isNull(selectedWorkspace?.id) && !_.isUndefined(selectedWorkspace?.id)) {
      fetchWorkspaceMembers();
    }
  }, [selectedWorkspace]);

  return (
    <WorkspaceContext.Provider
      value={{
        createNewWorkspace,
        fetchingWorkspace,
        setFetchingWorkspace,
        workspaceData,
        setWorkspaceData,
        switchActiveWorkspace,
        handleWorkspaceSwitch,
        selectedWorkspace,
        workspaceHomePath,
        workspaceConfigChangeInProgress,
        setWorkspaceConfigChangeInProgress,
        showWorkspaceCreateModal,
        setShowWorkspaceCreateModal,
        userGroupChanged,
        setUserGroupChanged,
        processWorkspaceData,
        workspaceMembers,
        allContactOptions,
        setAllContactOptions,
        fetchingWorkspaceMembers: visibility.fetchingWorkspaceMember,
        fetchWorkspaceMembers,
      }}
    >
      {children}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={toastOptions.open}
        onClose={() => setToastOptions((prev) => ({ ...prev, open: false }))}
        autoHideDuration={3000}
      >
        <Alert
          icon={toastOptions.icon ?? ""}
          onClose={() => setToastOptions((prev) => ({ ...prev, open: false }))}
          severity={toastOptions.severity}
          sx={{ width: "100%" }}
          action={
            <p className="body3" onClick={() => setToastOptions((prev) => ({ ...prev, open: false }))}>
              CLOSE
            </p>
          }
        >
          {toastOptions.message}
        </Alert>
      </Snackbar>
    </WorkspaceContext.Provider>
  );
};

export default WorkspaceProvider;

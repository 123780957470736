import React from "react";
import { PopoverProps } from "@mui/material/Popover/Popover";
import Popover from "../../AtomicComponents/atomic/Popover/Popover";
import { styled } from "@mui/system";
import { Button, ButtonProps, Tabs, TabsProps } from "@mui/material";

const CustomTemplatePopoverContainer: React.FC<PopoverProps> = ({ className, ...props }) => <Popover {...props} className={className} />;
export const TemplatePopoverContainer = styled(CustomTemplatePopoverContainer, {
  name: "TemplatePopoverContainer",
})<{ custommargin?: string }>(({ custommargin }) => ({
  ".MuiPaper-root": {
    display: "flex",
    flexDirection: "row",
    height: "25rem",
    width: "max-content",
    minWidth: "18.75rem",
    maxWidth: "43.75rem",
    boxShadow: "0 0.25rem 1.25rem 0 rgba(0, 0, 0, 0.10), 0 0.5rem 0.75rem 0 rgba(0, 0, 0, 0.10)",
    borderRadius: "0.5rem",
    margin: custommargin ?? "-1.375rem 0 0 0",
    padding: "0",
    ".MuiTabs-flexContainer .Mui-selected": {
      background: "#4D4D4D",
    },
    ".MuiList-root .Mui-selected": {
      background: "#F5F5F5",
    },
  },
}));

export const TemplateSearchListCard = styled("div", {
  name: "TemplateSearchListCard",
})<{ customwidth?: string }>(({ customwidth }) => ({
  display: "flex",
  width: customwidth ?? "18.75rem",
  flexDirection: "column",
  alignItems: "flex-start",
  flexShrink: "0",
  alignSelf: "stretch",
  background: "#FFFFFF",
  borderRight: "0.0625rem solid #EEEEEE",
  ".header-container, .sticky-header-container": {
    maxHeight: "6.375rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "stretch",
    borderBottom: "0.0625rem solid #EEEEEE",
  },
  ".sticky-header-container": {
    boxShadow: "0 0.0625rem 0.125rem 0 rgba(0, 0, 0, 0.15)",
  },
}));

export const TemplatePreviewCard = styled("div", {
  name: "TemplatePreviewCard",
})({
  display: "flex",
  width: "100%",
  minWidth: "25rem",
  maxWidth: "31.25rem",
  flexDirection: "column",
  alignItems: "flex-start",
  alignSelf: "stretch",
  background: "#FFFFFF",
  ".preview-header-container, .sticky-preview-header-container": {
    display: "flex",
    gap: "48px",
    padding: "1.5rem 1.5rem 1rem 1.5rem",
    height: "4.75rem",
    width: "100%",
    ".subject-content": {
      display: "flex",
      alignItems: "flex-start",
      gap: "0.5rem",
      alignSelf: "stretch",
      ".title": {
        fontFamily: "Sage UI",
        fontSize: "0.75rem",
        fontWeight: "500",
        lineHeight: "1.125rem",
        color: "#212121",
      },
      ".body-content": {
        fontFamily: "Sage UI",
        fontSize: "0.75rem",
        fontWeight: "400",
        lineHeight: "1.125rem",
        color: "#212121",
        width: "15.4375rem",
      },
    },
    ".dropdown-container-root, .not-dropdown-container-root": {
      height: "1.0625rem",
      padding: "0",
    },
    ".not-dropdown-container-root": {
      cursor: "auto",
    },
  },
  ".preview-footer-container, .sticky-preview-footer-container": {
    height: "4rem",
    display: "flex",
    padding: "1rem",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "0.75rem",
    alignSelf: "stretch",
  },
  ".sticky-preview-header-container": {
    boxShadow: "0 0.0625rem 0.125rem 0 rgba(0, 0, 0, 0.15)",
  },
  ".sticky-preview-footer-container": {
    boxShadow: "0 0.25rem 1.25rem 0 rgba(0, 0, 0, 0.10), 0 0.5rem 0.75rem 0 rgba(0, 0, 0, 0.10)",
  },
});

export const SearchComponentContainer = styled("div", {
  name: "SearchComponentContainer",
})({
  display: "flex",
  padding: "1rem 1.5rem",
  alignItems: "center",
  gap: "0.75rem",
  alignSelf: "stretch",
  height: "3.5rem",
  ".icon": {
    display: "flex",
    alignItems: "center",
  },
  "input::placeholder": {
    color: "#757575",
    fontFamily: "Sage UI",
    fontSize: "0.875rem",
    fontWeight: "400",
    lineHeight: "1.3125rem",
  },
  ".MuiInput-root": {
    width: "100%",
    ".MuiInputBase-input": {
      padding: 0,
      height: "1.3125rem",
      color: "#212121",
      fontFamily: "Sage UI",
      fontSize: "0.875rem",
      fontWeight: "400",
      lineHeight: "1.3125rem",
    },
  },
});

export const TabsComponentContainer = styled("div", {
  name: "TabsComponentContainer",
})({
  display: "flex",
  paddingLeft: "1.4rem",
  alignItems: "flex-start",
  gap: "0.5rem",
  alignSelf: "stretch",
  height: "3.5rem",
  justifyContent: "space-between",
  ".not-dropdown-container-root": {
    cursor: "auto",
  },
});

const CustomTabs: React.FC<TabsProps> = ({ className, ...props }) => <Tabs {...props} className={className} />;
export const CustomTabsWithCountContainer = styled(CustomTabs, {
  name: "CustomTabsWithCountContainer",
})({
  alignItems: "center",
  justifyContent: "center",
  height: "3.5rem",
  ".MuiTabs-flexContainer": {
    height: "2.5rem",
    minHeight: "2.5rem",
    gap: "0.5rem",
    ".MuiTab-textColorPrimary": {
      color: "#4D4D4D",
    },
    ".MuiButtonBase-root.MuiTab-root": {
      minWidth: "2rem",
      minHeight: "2rem",
      fontFamily: "Sage UI",
      fontWeight: "500",
      fontSize: "0.75rem",
      textTransform: "capitalize",
      lineHeight: "1rem",
      padding: "0.5rem",
    },
    ".MuiTab-root": {
      transition: "transform 0.2s ease",
      ".label-container": {
        display: "flex",
        gap: "0.5rem",
        justifyContent: "center",
        alignItems: "center",
        ".label-count": {
          display: "flex",
          padding: "0.125rem 0.25rem",
          justifyContent: "center",
          alignItems: "center",
          border: "0.25rem",
          background: "#FFFFFF",
          color: "#2962FF",
          borderRadius: "0.25rem",
        },
      },
      ":hover": {
        background: "#f0f0f0",
        borderRadius: "0.5rem",
        color: "$14eb52",
      },
    },
    ".Mui-selected": {
      background: "#4D4D4D",
      borderRadius: "0.5rem",
      color: "#FFFFFF",
      padding: "0.5rem",
      transition: "none",
      ":hover": {
        background: "#4D4D4D",
        borderRadius: "0.5rem",
        color: "#FFFFFF",
        transform: "none",
      },
    },
  },
  ".MuiTabs-indicator": {
    display: "none",
  },
});

export const TemplateResultContainer = styled("div", {
  name: "TemplateResultContainer",
})({
  maxHeight: "17.5rem",
  overflowY: "auto",
  width: "100%",
  ".MuiList-root": {
    padding: "0",
    ".MuiListItemButton-root": {
      color: "#212121",
      minHeight: "2.25rem",
      padding: "0.5rem 1.5rem",
      fontFamily: "Sage UI",
      fontSize: "0.875rem",
      fontWeight: "500",
      lineHeight: "1.3125rem",
      borderBottom: "0.0625rem solid #EEEEEE",
      ":hover": {
        background: "none",
      },
      ".container .default-styles-width": {
        width: "100%",
        whiteSpace: "break-spaces",
      },
      ".container .overflow-styles": {
        whiteSpace: "break-spaces",
      },
      ".container .default-styles-width mark": {
        background: "rgba(255, 183, 77, 0.5)",
      },
    },
    ".Mui-selected": {
      background: "#F5F5F5",
      ":hover": {
        background: "#F5F5F5",
      },
    },
  },
  "::-webkit-scrollbar": {
    width: "1rem",
    height: "5.5rem",
  },
  "::-webkit-scrollbar-thumb": {
    height: "5.5rem",
    backgroundColor: "#BDBDBD",
    border: "0.25rem solid transparent",
    backgroundClip: "content-box",
    borderRadius: "0.125rem",
  },
});

export const CustomDropDownComponent = styled("div", {
  name: "CustomDropDownComponent",
})({
  display: "flex",
  height: "3.5rem",
  justifyContent: "center",
  alignItems: "center",
  padding: "1.5rem 0",
  cursor: "pointer",
  ".content-container": {
    display: "flex",
    alignItems: "center",
    gap: "0.25rem",
    ".icon": {
      display: "flex",
      width: "0.75rem",
    },
    ".text": {
      fontFamily: "Sage UI",
      fontSize: "0.625rem",
      fontWeight: "400",
      lineHeight: "0.9375rem",
      width: "max-content",
    },
  },
  ".arrow-icon": {
    display: "flex",
    padding: "0.5rem",
    alignItems: "center",
  },
});

const DropdownPopover: React.FC<PopoverProps> = ({ className, ...props }) => <Popover {...props} className={className} />;
export const CustomDropdownPopover = styled(DropdownPopover, {
  name: "CustomDropdownPopover",
})({
  ".MuiPaper-root": {
    display: "flex",
    flexDirection: "column",
    minHeight: "4.125rem",
    width: "8.625rem",
    boxShadow: "0 0.25rem 1.25rem 0 rgba(0, 0, 0, 0.10), 0 0.5rem 0.75rem 0 rgba(0, 0, 0, 0.10)",
    borderRadius: "0.5rem",
    padding: "0.625rem",
    marginTop: "-1.125rem",
    ".MuiMenuItem-root": {
      width: "100%",
      padding: "0.25rem",
      gap: "0.5rem",
      height: "1.4375rem",
      ".MuiListItemIcon-root": {
        minWidth: "0.75rem",
        maxWidth: "0.75rem",
      },
      ".MuiTypography-root ": {
        color: "#212121",
        fontFamily: "Sage UI",
        fontSize: "0.625rem",
        lineHeight: "0.9375rem",
      },
      ":hover": {
        borderRadius: "0.25rem",
        background: "#F5F5F5",
      },
    },
    ".Mui-selected": {
      background: "none",
      ".MuiTypography-root ": {
        fontWeight: "500",
      },
      ":hover": {
        background: "none",
      },
    },
  },
});

export const PreviewCardBodyContent = styled("div", {
  name: "PreviewCardBodyContent",
})({
  width: "100%",
  height: "100%",
  padding: "0 0 1.5rem 1.5rem",
  fontFamily: "Sage UI",
  fontSize: "0.75rem",
  fontWeight: "400",
  lineHeight: "1.125rem",
  whiteSpace: "pre-wrap",
  wordBreak: "break-word",
  wordWrap: "break-word",
  overflowY: "auto",
  color: "#424242",
  "::-webkit-scrollbar": {
    width: "1rem",
    height: "5.5rem",
  },
  "::-webkit-scrollbar-thumb": {
    height: "5.5rem",
    backgroundColor: "#BDBDBD",
    border: "0.25rem solid transparent",
    backgroundClip: "content-box",
    borderRadius: "0.125rem",
  },
});

const MuiButton: React.FC<ButtonProps> = ({ className, ...props }) => <Button {...props} className={className} />;
export const CustomButton = styled(MuiButton, {
  name: "CustomButton",
})({
  height: "2rem",
  padding: "0.5rem 1rem",
  background: "#2962FF",
  borderRadius: "0.5rem",
  color: "#FFFFFF",
  fontFamily: "Sage UI",
  fontSize: "0.75rem",
  lineHeight: "1.125rem",
  letterSpacing: "unset",
  textTransform: "none",
  ":hover": {
    background: "#0D47A1",
  },
  ":active": {
    background: "#424242",
  },
});

export const EmptyResultContainer = styled("div", {
  name: "EmptyResultContainer",
})({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "1.5rem",
  flexShrink: "0",
  alignSelf: "center",
  width: "31.25rem",
  ".header-icon": {
    width: "10rem",
    height: "10rem",
    "> svg": {
      width: "10rem",
      height: "10rem",
    },
  },
  ".text-content": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "0.5rem",
    alignSelf: "stretch",
    ".title": {
      color: "#212121",
      textAlign: "center",
      fontFamily: "Sage UI",
      fontSize: "1.125rem",
      fontWeight: "500",
      lineHeight: "1.6875rem",
      margin: "0",
    },
    ".sub-title": {
      color: "#757575",
      textAlign: "center",
      fontFamily: "Sage UI",
      fontSize: "0.875rem",
      fontWeight: "400",
      lineHeight: "1.3125rem",
      margin: "0",
      width: "60%",
    },
  },
});

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import FullStory from "react-fullstory";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import reportWebVitals from "./reportWebVitals";
import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import App from "./App";
import { msalConfig } from "./auth/authConfig";
import Utils from "./utils/utils";
import "./css/globalClasses/index.scss";
import "overlayscrollbars/css/OverlayScrollbars.css";
import { primaryTheme } from "./css/MUITheme";
import { DEFAULT_NUMERIC_VALUES } from "./constants/NumericConstants";
import { MsalProvider } from "@azure/msal-react";
import MagicLinks from "./MagicLinks";
import ApprovalDetailsProvider from "./contexts/external/ApprovalDetailsContext";
import MagicLinkAuth from "./routes/MagicLinkAuth/MagicLinkAuth";
import { QueryClient, QueryClientProvider, QueryObserverOptions } from "@tanstack/react-query";
import { CachePolicyGlobal, CachePolicyMutationGlobal } from "./constants/CacheConfig";
import "./i18n/i18n";
import AlertProvider from "./contexts/AlertContext";
import { LicenseInfo } from "@mui/x-license-pro";

//Setup the mui pro license key
LicenseInfo.setLicenseKey(Utils.getMuiProLicenseKey());

export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();

// Enable single active accounts
if (accounts.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
  msalInstance.setActiveAccount(accounts[DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO]);
}

const fullStoryRoot = createRoot(document.getElementById("fullstory-node") as HTMLDivElement);
fullStoryRoot.render(<FullStory org={Utils.getFullStoryConfig().fullStoryId} />);

const inboxAppRoot = createRoot(document.getElementById("root") as HTMLDivElement);

/**
 * check if route is external
 */
const isMagicRoute = window.location.pathname.split("/")[1] === "external";

/**
 * Check if route is requesting magic-link authorization bypass
 */
const isMagicLinkAuth = window.location.pathname.split("/")[1] === "magic-link";

/**
 * Initialize react-query client
 */
const queryClient = new QueryClient({
  defaultOptions: {
    queries: { ...CachePolicyGlobal } as QueryObserverOptions,
    mutations: {
      ...CachePolicyMutationGlobal,
    },
  },
});

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID || "",
  });

  /**
   * Strict mode has been removed temporarily
   * makeStyles() hook from MUI is throwing error when in strict mode.
   * @see {@link https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-strict-mode | Updates to Strict Mode}
   * @see {@link https://coderpad.io/blog/development/why-react-18-broke-your-app/}
   */
  inboxAppRoot.render(
    <LDProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={primaryTheme}>
          <AlertProvider>
            <QueryClientProvider client={queryClient}>
              {isMagicRoute ? (
                <ApprovalDetailsProvider>
                  <Router basename="/external">
                    <MagicLinks />
                  </Router>
                </ApprovalDetailsProvider>
              ) : isMagicLinkAuth ? (
                <MsalProvider instance={msalInstance}>
                  <Router basename="/magic-link">
                    <MagicLinkAuth />
                  </Router>
                </MsalProvider>
              ) : (
                <MsalProvider instance={msalInstance}>
                  <Router>
                    <App />
                  </Router>
                </MsalProvider>
              )}
            </QueryClientProvider>
          </AlertProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </LDProvider>
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { makeStyles } from "@mui/styles";
import styles from "../../../css/_export.module.scss";

const useDropdownSearchStyle = () => {
  const useStyles = makeStyles({
    divRoot: {
      border: `${styles.borderline} solid ${styles.grey90}`,
      background: `${styles.white100}`,
      borderRadius: styles.small,
    },
    fillDivRoot: {
      border: `${styles.borderline} solid ${styles.grey90}`,
      borderRadius: styles.small,
      background: "#4D4D4D",
      color: styles.white100,
      "& .MuiOutlinedInput-root": {
        "& .MuiOutlinedInput-input": {
          color: styles.white100,
        },
        "& .MuiSelect-icon": {
          color: styles.white100,
        },
      },
    },
    select: {
      height: "2.5rem",
      boxShadow: "none",
      "&:hover": {
        "& .MuiSelect-icon": {
          color: styles.white100,
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
        boxShadow: "none",
      },
      "& .MuiOutlinedInput-input": {
        display: "flex",
        height: "100%",
        alignItems: "center",
        color: styles.grey10,
        fontFamily: styles.primaryFontFamily,
        fontWeight: "400",
        fontSize: styles.fontSizeSM,
        lineHeight: styles.lineHeightSM,
        padding: `0 ${styles.medium}`,
        "&:hover": {
          background: "#4D4D4D",
          color: styles.white100,
        },
      },
      "& .MuiSelect-icon": {
        color: styles.grey700,
      },
    },
    menuRoot: {
      "& .MuiPaper-root": {
        width: `16rem`,
        minHeight: `2.5rem`,
        maxHeight: `18.8rem`,
        background: styles.white100,
        marginTop: styles.small,
        borderRadius: styles.atom,
        boxShadow: `0 ${styles.tiny} ${styles.small} rgba(0, 0, 0, 0.12)`,
        gap: styles.small,
        overflowY: "overlay",
        overflowX: "hidden",
        msOverflowStyle: "none",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": { 
          display: "none"
        },
      },
      "& .MuiList-root": {
        height: "100%",
        "& .MuiMenuItem-root": {
          height: "2.25rem",
          fontFamily: styles.primaryFontFamily,
          fontWeight: "400",
          fontSize: styles.fontSizeSM,
          lineHeight: styles.lineHeightMD,
          margin: `0 ${styles.small}`,
          gap: styles.small,
          borderRadius: styles.small,
          padding: `0 ${styles.small}`,
          "&:hover": {
            background: styles.grey98,
          },
        },
        "& .MuiInputBase-root:before": {
          borderBottom: "none",
        },
        "& .MuiFocused .MuiInputBase-root:before": {
          borderBottom: "none",
        },
        "& .Mui-selected": {
          background: styles.grey98,
          marginTop: "0.5rem",
          marginBottom: "0.5rem",
        },
        "& .Mui-disabled": {
          opacity: "1",
        },
      },
    },
    menuSearchBox: {
      width: "15rem",
      height: "2.25rem",
      margin: `${styles.small}`,
      background: styles.grey98,
      borderColor: styles.grey90,
      borderRadius: "8px",
      boxSizing: "border-box",
      "& :before": {
        borderBottom: "none",
      },
      "&:focus": {
        borderColor: styles.grey90,
      },
    },
    adornmentSearchIcon: {
      display: "flex",
      color: styles.grey40,
      fontSize: styles.fontSizeSM,
      marginLeft: "0.5rem",
    },
    menuTypographyElement: {
      "& .Mui-disabled": {
        opacity: "1",
      },
    },
    hidelist: {
      display: "none",
    },
    listItemIcon: {
      width: "0.75rem",
      height: "0.5625rem",
      display: "flex",
    },
    itemText: {
      fontFamily: styles.primaryFontFamily,
      fontWeight: "400",
      fontSize: styles.fontSizeSM,
      lineHeight: styles.lineHeightSM,
      color: styles.grey10,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    typography: {
      color: styles.grey40,
      fontSize: styles.fontSizeXS,
      fontWeight: "600",
    },
    horizontalRule: {
      borderTop: `${styles.small}`,
      backgroundColor: `${styles.grey90}`,
      margin: `${styles.small} ${styles.small}`,
    },
  });
  return useStyles();
};

export default useDropdownSearchStyle;

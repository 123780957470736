import React, { useState, useRef, Dispatch } from "react";
import Button, { ButtonProps } from "../../Button/Button";
import { TextArea } from "../../TextArea/TextArea";
import { notesClient } from "../../../../db/accessor";
import "./AddNote.scss";
import { BTN_CANCEL, BTN_SAVE } from "../../../../constants/config";

interface AddNoteProps extends ButtonProps {
  tableKey: string;
  objectKey: string;
  refreshNotes: () => void;
  isOpen: boolean;
  handleClose: () => void;
  textAreaRef: React.RefObject<HTMLTextAreaElement>;
  setLoading: Dispatch<boolean>;
}

export default function AddNote(props: AddNoteProps): React.ReactElement {
  const [input, setInput] = useState<string>("");
  const noteRef = useRef<HTMLDivElement>(null);

  async function handleOnClick() {
    props.setLoading(true);
    try {
      props.handleClose();
      await notesClient.post([
        {
          tableKey: props.tableKey,
          objectKey: props.objectKey,
          noteText: input,
          noteType: "note",
        },
      ]);
      await props.refreshNotes();
    } catch (e) {
      console.info(e);
    }
    props.setLoading(false);
  }

  return (
    <div className={`wrapper`}>
      {props.isOpen && (
        <div className={`add-wrapper`} ref={noteRef}>
          <TextArea placeholder={""} onChange={(e) => setInput(e.target.value)} inputRef={props.textAreaRef} />
          <div className={`add-btn-grp`}>
            <Button
              variant="secondary"
              size="sm"
              onClick={() => {
                props.handleClose();
                setInput("");
              }}
            >
              {BTN_CANCEL}
            </Button>
            <Button variant="primary" size="sm" disabled={!input} onClick={() => handleOnClick()}>
              {BTN_SAVE}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

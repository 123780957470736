import React from "react";
import BaseActivity from "../BaseActivity/BaseActivity";
import { DetailedWithActionProps } from "../SecondaryActivityTypes";
import "./DetailedWithAction.scss";

const DetailedWithAction: React.FC<DetailedWithActionProps> = ({ creator, flags, children, headerActions, countryCode, type }) => {
  return (
    <div className={`base-wrapper ${type}`}>
      <BaseActivity creator={creator} flags={flags} headerActions={headerActions} countryCode={countryCode}>
        {children}
      </BaseActivity>
    </div>
  );
};

export default DetailedWithAction;

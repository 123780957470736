import React from "react";
import { useHistory } from "react-router";
import { MagicAuthContext, MagicAuthContextType } from "../../../contexts/external/MagicAuthContext";
import { ReviewResponse } from "../../../db/magicClients/ApprovalActivitiesClient";
import { ApprovalActivityAccessor } from "../../../db/unauthAccessors";
import { ApprovalRequestData } from "./ApprovalRequestTypes";
import ApprovalRequestComponent from "./ApprovalRequestComponent";
import { AxiosError } from "axios";
import { CONNECTION_STATUS } from "../../../constants/ConnectionConstants";
import useHandleVisibility from "../../../hooks/useHandleVisibility";

type ReviewAction = { actionTaken: boolean; success: boolean };
interface ApprovalRequestContainerProps {
  id: UUID;
  onClickReview: (action: ReviewAction, reviewerName: string) => void;
  requestData: ApprovalRequestData;
}
const initialVisibility = {
  approvedOrDeclined: false,
};

const ApprovalRequestContainer: React.FC<ApprovalRequestContainerProps> = ({ id, onClickReview, requestData }) => {
  const [approverMsg, setApproverMsg] = React.useState<string>("");
  const history = useHistory();
  const { refreshIntervalId, setRefreshIntervalId } = React.useContext(MagicAuthContext) as MagicAuthContextType;
  const { visibility, dispatch } = useHandleVisibility<typeof initialVisibility>(initialVisibility);

  // Network
  const updateApprovalRequest = async (requestId: string, action: string, message: string | null) => {
    let response = {} as ReviewResponse;
    dispatch({ type: "open", payload: "approvedOrDeclined" });

    try {
      response = await ApprovalActivityAccessor.reviewRequest(requestId, action, message?.replace(/\n/g, "<br>\n").replace(/ /g, "&nbsp;") ?? "");
    } catch (error) {
      /** check for the error HTTP code */
      if ((error as AxiosError).response?.status === CONNECTION_STATUS.LINK_EXPIRED_422.STATUS_CODE) {
        history.push("/link-expired", { from: "view-request", error: "reviewed" });
      } else {
        history.push("/error");
      }
    } finally {
      onClickReview(
        {
          actionTaken: true,
          success: response.success,
        },
        response.creator.name
      );
      // cancels the next token call after review action has been taken place
      clearInterval(refreshIntervalId);
      setRefreshIntervalId(undefined);
      dispatch({ type: "close", payload: "approvedOrDeclined" });
    }
  };

  // Event Handlers
  const onChangeNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setApproverMsg(e.target.value);
  };
  const onClickApprove = () => {
    updateApprovalRequest(id, "approve", approverMsg);
  };
  const onClickDecline = () => {
    updateApprovalRequest(id, "decline", approverMsg);
  };

  return (
    <ApprovalRequestComponent
      requestData={requestData}
      onChangeNote={onChangeNote}
      onClickApprove={onClickApprove}
      onClickDecline={onClickDecline}
      isApprovalUpdating={visibility.approvedOrDeclined}
    />
  );
};

export default ApprovalRequestContainer;

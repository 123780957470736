import React from "react";
import {
  ActionFooterContainer,
  ApplyButton,
  CancelButton,
  FilterIconContainer,
  FilterIconContainerActive,
} from "../../../styled/table.filter.styled";
import { NumericFilterComponentProps } from "../../preset.filter.types";
import {
  NumericPopupContainer,
  AmountInputField,
  DropdownComponent,
  PopupHeaderTextContainer,
  PopupContentRoot,
  DropdownAndFieldWrapper,
  RangeSliderContainer,
} from "../styled/numeric.filter.styled";
import InputAdornment from "@mui/material/InputAdornment";
import { ReactComponent as FilterIcon } from "../../../../svg/filter_icon.svg";
import RangeSlider from "../../../../../RangeSlider/RangeSlider";
import _ from "underscore";
import { TableFilterComparatorConstant, TableFilterConstant } from "../../../../constants/table.constants";
import { useTranslation } from "react-i18next";

const NumericFilterComponent: React.FC<NumericFilterComponentProps> = ({
  selectedOption,
  filterActive,
  currencyData,
  amountValue,
  amountRangeValue,
  onChangeDropdownValue,
  onChangeAmountValue,
  onChangeRangeSliderValue,
  onChangeSliderInputMaxValue,
  onChangeSliderInputMinValue,
  onClickFilterApply,
  filterAlignment,
  onClickClearFilter,
  numericFilterType,
  placeholder,
}) => {
  const IconWrapper = filterActive ? FilterIconContainerActive : FilterIconContainer;
  const [numericFilterAnchorEl, setNumericFilterAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpenNumericFilter = Boolean(numericFilterAnchorEl);
  const { t } = useTranslation();

  const onClickNumericFilter = (event: React.MouseEvent<HTMLElement>) => setNumericFilterAnchorEl(event.currentTarget);

  const onClickClearButton = () => {
    setNumericFilterAnchorEl(null);
    onClickClearFilter();
  };

  const onClickFilterApplyButton = () => {
    setNumericFilterAnchorEl(null);
    onClickFilterApply();
  };

  const createNumericFilterMainContent = () => {
    return (
      <PopupContentRoot>
        <PopupHeaderTextContainer>
           {currencyData?.code} {TableFilterConstant.getDefaultValueText(t).label}
        </PopupHeaderTextContainer>
        <DropdownAndFieldWrapper>
          <DropdownComponent
            handleDropDownChange={onChangeDropdownValue}
            selectedValue={selectedOption}
            optionList={TableFilterConstant.getNumericDropdownList(t)}
            hasValueChanged={false}
            defaultText={TableFilterConstant.getDefaultDropdownText(t).label}
            isNoneItemRequired={false}
          />
          {!selectedOption.includes(TableFilterComparatorConstant.IN_BETWEEN) && (
            <AmountInputField
              value={amountValue}
              placeholder={placeholder}
              disabled={_.isEmpty(selectedOption)}
              onChange={onChangeAmountValue}
              startAdornment={<InputAdornment position="start">{currencyData?.symbol}</InputAdornment>}
              type="number"
              disableUnderline={true}
              onKeyDown={(e) => {
                if (e.key === "." && numericFilterType === "number") {
                  e.preventDefault();
                }
              }}
            />
          )}
        </DropdownAndFieldWrapper>
        {selectedOption.includes(TableFilterComparatorConstant.IN_BETWEEN) && (
          <RangeSliderContainer>
            <RangeSlider
              adornmentSymbol={currencyData?.symbol}
              range={amountRangeValue}
              onSliderChange={onChangeRangeSliderValue}
              onMinChange={onChangeSliderInputMinValue}
              onMaxChange={onChangeSliderInputMaxValue}
              numericFilterType={numericFilterType}
              placeholder={placeholder}
            />
          </RangeSliderContainer>
        )}
        <ActionFooterContainer>
          <CancelButton onClick={onClickClearButton}>{t("filters.buttons.clear", { ns: "table" })}</CancelButton>
          <ApplyButton
            onClick={onClickFilterApplyButton}
            disabled={
              selectedOption?.includes(TableFilterComparatorConstant.IN_BETWEEN)
                ? _.isEmpty(amountRangeValue) || _.isNaN(amountRangeValue[0]) || _.isNaN(amountRangeValue[1])
                : _.isEmpty(amountValue)
            }
          >
            {t("filters.buttons.apply", { ns: "table" })}
          </ApplyButton>
        </ActionFooterContainer>
      </PopupContentRoot>
    );
  };

  return (
    <>
      <IconWrapper onClick={onClickNumericFilter}>
        <FilterIcon />
      </IconWrapper>
      <NumericPopupContainer
        open={isOpenNumericFilter}
        onClose={() => setNumericFilterAnchorEl(null)}
        anchorEl={numericFilterAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: filterAlignment,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: filterAlignment,
        }}
      >
        {createNumericFilterMainContent()}
      </NumericPopupContainer>
    </>
  );
};

export default NumericFilterComponent;

import React from "react";
import { Route, useRouteMatch, Switch, useLocation, matchPath } from "react-router";
import Mine from "./Mine/Mine";
import Unassigned from "./Unassigned/Unassigned";
import All from "./AllActivities/AllActivities";
import ActivityDetailV2 from "./ActivityDetailV2/ActivityDetail";
import FourOFourError from "../../routes/FourOFourError/FourOFourError";
import "./Activities.scss";
import Spam from "./Spam/Spam";
import { default as CustomerHeader } from "../Customers/CustomerDetail/Header/Header";
import { default as CustomerProfile } from "../Customers/CustomerDetail/Profile/Profile";
import ContactDetail from "../Customers/CustomerDetail/Contacts/ContactDetail/ContactDetail";
import useWorkspaceConfigurations from "../../hooks/useWorkspaceConfigurations";
import InvoiceDetail from "../Invoices/InvoiceDetail/InvoiceDetail";
import PaymentDetail from "../Payments/PaymentDetail/PaymentDetail";
import { WorkspaceContext } from "../../contexts/WorkspaceContext";

import ReceivedEInvocies from "./ReceivedEInvoices/ReceivedEInvoices";
import SentEInvoices from "./SentEInvoices/SentEInvoices";
import EInvoiceDetail from "./EInvoiceDetail/EInvoiceDetail";

export default function Activities(): React.ReactElement {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const { configs } = useWorkspaceConfigurations();
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const isEInvoicing = selectedWorkspace?.workspace_type === "einvoicing";

  /* Path patterns for Activity Detail that require class padding-v2 with ACTIVITY_STREAM_V2 */
  const activityStreamV2ViewURLS = [
    `${path}/mine/:activityId`,
    `${path}/mine/:activityId/activities/:activityId`,
    `${path}/unassigned/:activityId`,
    `${path}/unassigned/:activityId/activities/:activityId`,
    `${path}/all/:activityId`,
    `${path}/all/:activityId/activities/:activityId`,
    `${path}/spam/:activityId`,
    `${path}/spam/:activityId/activities/:activityId`,

    `${path}/receivedEInvoices/:activityId`,
    `${path}/receivedEInvoices/:activityId/activities/:activityId`,
    `${path}/sentEInvoices/:activityId`,
    `${path}/sentEInvoices/:activityId/activities/:activityId`,
  ];

  const activeUrl = activityStreamV2ViewURLS.find((url) => {
    const match = matchPath(pathname, { path: url, exact: true });
    const isExcluded = ["waitingForResponse", "closed", "snoozed"].some((excludedWord) => pathname.includes(excludedWord));
    return match !== null && !isExcluded;
  });

  return (
    <div className={`activities-wrapper ${isEInvoicing ? "e-invoicing" : ""}`}>
      <div className={`body activity-table-v2 ${activeUrl ? "padding-v2" : ""} ${isEInvoicing ? "e-invoicing" : ""}`}>
        <Switch>
          {/* Mine -> Table -> Detail -> Contact, Customer */}
          <Route
            exact
            path={[`${path}/receivedEInvoices`]}
            component={() => {
              return <ReceivedEInvocies />;
            }}
          />
          <Route
            exact
            path={[`${path}/sentEInvoices`]}
            component={() => {
              return <SentEInvoices />;
            }}
          />
          <Route
            exact
            path={[
              `${path}/receivedEInvoices/:activityId`,
              `${path}/sentEInvoices/:activityId`,
              `${path}/sentEInvoices/:activityId/activities/:activityId`,
              `${path}/receivedEInvoices/:activityId/activities/:activityId`,
            ]}
            component={() => {
              return <EInvoiceDetail/>;
            }}
          />

          <Route
            exact
            path={[`${path}/mine`, `${path}/mine/closed`, `${path}/mine/waitingForResponse`, `${path}/mine/snoozed`]}
            component={() => {
              return <Mine />;
            }}
          />
          <Route
            exact
            path={[`${path}/mine/:activityId`, `${path}/mine/:activityId/activities/:activityId`]}
            component={() => {
              return <ActivityDetailV2 userView="mine" />;
            }}
          />
          <Route
            exact
            path={`${path}/mine/:activityId/${configs.activities.detail.resources.connections}/:customerId`}
            render={() => {
              return (
                <div className="customer-detail-wrapper">
                  <div className="head">
                    <CustomerHeader />
                  </div>
                  <div className="body">
                    <CustomerProfile />
                  </div>
                </div>
              );
            }}
          />
          <Route
            exact
            path={`${path}/mine/:activityId/${configs.activities.detail.resources.invoices}/:invoiceId`}
            component={() => <InvoiceDetail />}
          />
          <Route exact path={`${path}/mine/:activityId/payments/:paymentId`} component={() => <PaymentDetail />} />
          <Route exact path={`${path}/mine/:activityId/contactProfile/:contactId`} component={() => <ContactDetail />} />
          {/* Unassigned */}
          <Route
            exact
            path={[`${path}/unassigned`, `${path}/unassigned/closed`]}
            component={() => {
              return <Unassigned />;
            }}
          />
          <Route
            exact
            path={[`${path}/unassigned/:activityId`, `${path}/unassigned/:activityId/activities/:activityId`]}
            component={() => {
              return <ActivityDetailV2 userView="unassigned" />;
            }}
          />
          <Route
            exact
            path={`${path}/unassigned/:activityId/${configs.activities.detail.resources.connections}/:customerId`}
            render={() => {
              return (
                <div className="customer-detail-wrapper">
                  <div className="head">
                    <CustomerHeader />
                  </div>
                  <div className="body">
                    <CustomerProfile />
                  </div>
                </div>
              );
            }}
          />
          <Route
            exact
            path={`${path}/unassigned/:activityId/${configs.activities.detail.resources.invoices}/:invoiceId`}
            component={() => <InvoiceDetail />}
          />
          <Route exact path={`${path}/unassigned/:activityId/payments/:paymentId`} component={() => <PaymentDetail />} />
          <Route exact path={`${path}/unassigned/:activityId/contactProfile/:contactId`} component={() => <ContactDetail />} />
          {/* All */}
          <Route
            exact
            path={[`${path}/all`, `${path}/all/waitingForResponse`, `${path}/all/closed`, `${path}/all/snoozed`]}
            component={() => {
              return <All />;
            }}
          />
          <Route
            exact
            path={[`${path}/all/:activityId`, `${path}/all/:activityId/activities/:activityId`]}
            component={() => {
              return <ActivityDetailV2 userView="all" />;
            }}
          />
          <Route
            exact
            path={`${path}/all/:activityId/${configs.activities.detail.resources.connections}/:customerId`}
            render={() => {
              return (
                <div className="customer-detail-wrapper">
                  <div className="head">
                    <CustomerHeader />
                  </div>
                  <div className="body">
                    <CustomerProfile />
                  </div>
                </div>
              );
            }}
          />
          <Route exact path={`${path}/all/:activityId/contactProfile/:contactId`} component={() => <ContactDetail />} />
          <Route
            exact
            path={`${path}/all/:activityId/${configs.activities.detail.resources.invoices}/:invoiceId`}
            component={() => <InvoiceDetail />}
          />
          <Route exact path={`${path}/all/:activityId/payments/:paymentId`} component={() => <PaymentDetail />} />
          {/* Spam */}
          <Route
            exact
            path={[`${path}/spam`]}
            component={() => {
              return <Spam />;
            }}
          />
          <Route
            exact
            path={[`${path}/spam/:activityId`, `${path}/spam/:activityId/activities/:activityId`]}
            component={() => {
              return <ActivityDetailV2 userView="spam" />;
            }}
          />
          <Route
            exact
            path={`${path}/spam/:activityId/${configs.activities.detail.resources.connections}/:customerId`}
            render={() => {
              return (
                <div className="customer-detail-wrapper">
                  <div className="head">
                    <CustomerHeader />
                  </div>
                  <div className="body">
                    <CustomerProfile />
                  </div>
                </div>
              );
            }}
          />
          <Route
            exact
            path={`${path}/spam/:activityId/${configs.activities.detail.resources.invoices}/:invoiceId`}
            component={() => <InvoiceDetail />}
          />
          <Route exact path={`${path}/spam/:activityId/payments/:paymentId`} component={() => <PaymentDetail />} />
          <Route exact path={`${path}/spam/:activityId/contactProfile/:contactId`} component={() => <ContactDetail />} />
          <Route
            component={() => {
              return <FourOFourError />;
            }}
          />
        </Switch>
      </div>
    </div>
  );
}

import React from "react";
import { AuditLogType } from "../../../../../../../types/enums";
import Button from "../../../../../../library/Button/Button";
import SecondaryActivity from "../../SecondaryActivity/SecondaryActivity";
import { SecondaryType } from "../../SecondaryActivity/SecondaryActivityTypes";
import { SnoozedProps } from "../ActivityItemVariantTypes";
import * as Labels from "../../../../../../../constants/config";
import { DateTime } from "luxon";
import { formatDate, getDisplayDateFormat } from "../../../../../../../db/utils/date";

const Snoozed: React.FC<SnoozedProps> = ({ onClickUnsnooze, timestampUntilSnoozed, creator, status, readonly, countryCode }) => {
  const formatDateTime = (value: string) => {
    const date = formatDate(value, getDisplayDateFormat(countryCode ?? ""));
    const time = DateTime.fromFormat(value.split("T")[1], "hh:mm:ss").toFormat("hh:mm a");
    return `${date}, ${time}`;
  };
  // write conversion for time stamp -> MMM dd, yyyy, hh:mm
  creator = { ...creator, message: `Snoozed until ${timestampUntilSnoozed ? formatDateTime(timestampUntilSnoozed) : "N/A"}` };

  return status === AuditLogType.Snoozed && !readonly ? (
    <SecondaryActivity variant={SecondaryType.Accordion} creator={creator} countryCode={countryCode}>
      <Button variant="primary" size="sm" onClick={onClickUnsnooze}>
        {Labels.BTN_UNSNOOZE}
      </Button>
    </SecondaryActivity>
  ) : (
    <SecondaryActivity variant={SecondaryType.Base} creator={creator} countryCode={countryCode} />
  );
};

export default Snoozed;

import React, { useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
import NoteStream from "../../../library/NoteStream/NoteStream";
import NotesProvider from "../../../../contexts/NoteContext";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import { formatAddress } from "../../../../db/utils/address";
import { User as UserSVG, Email as EmailSVG, Fax as FaxSVG, Phone as PhoneSVG, Location as LocationSVG } from "../../../library/Icons/Icons";
import Detail from "../../../library/Detail/Detail";
import Loading from "../../../library/Loading/Loading";
import ErrorMessage from "../../../library/Error/CustomError";
import useWorkspaceConfigurations from "../../../../hooks/useWorkspaceConfigurations";
import EmptyStates from "../../../library/AtomicComponents/Table/tsx/EmptyStates/EmptyStates";
import { EMPTY_COMPANY_PROFILE_CAPTION_MESSAGE, EMPTY_COMPANY_PROFILE_MESSAGE } from "../../../../constants/config";
import { ThemeProvider } from "@mui/system";
import createTheme from "../../../library/AtomicComponents/theme/theme";

export default function Profile(): React.ReactElement {
  const { customerId } = useParams<{ customerId: string }>();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { company, getCompanyDetails } = React.useContext(CustomerContext) as CustomerType;

  const { configs } = useWorkspaceConfigurations();
  const { path } = useRouteMatch();
  const isMyCompany = path.includes(configs?.myCompany?.routeType);

  const fetchData = async () => {
    setErrorMessage("");
    setLoading(true);
    try {
      await getCompanyDetails(customerId);
    } catch (error: any) {
      setErrorMessage(error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const address = formatAddress(
    company?.address1 ?? "",
    company?.address2 ?? "",
    company?.address3 ?? "",
    company?.city ?? "",
    company?.state ?? "",
    company?.postalCode ?? "",
    company?.country ?? ""
  );

  const validateProfileDataForNullState = () => {
    return !company?.contactName && !company?.contactEmail && !company?.phoneNumber && !company?.faxNumber && !address;
  };

  return errorMessage ? (
    <ErrorMessage>{errorMessage}</ErrorMessage>
  ) : isLoading ? (
    <Loading />
  ) : (
    <ThemeProvider theme={createTheme}>
      <>
        {/* Contacts */}
        {validateProfileDataForNullState() ? (
          <div className={`left-empty-state`}>
            <EmptyStates title={EMPTY_COMPANY_PROFILE_MESSAGE} caption={EMPTY_COMPANY_PROFILE_CAPTION_MESSAGE} />
          </div>
        ) : (
          <div className={`left`}>
            <Detail icon={<UserSVG />} columns={{ title: isMyCompany ? "Team Member Name" : "Primary Contact", value: company?.contactName }} />
            <Detail
              icon={<EmailSVG />}
              columns={{
                title: "Email",
                value: company?.contactEmail ?? company?.email,
                format: "email",
                copyToClipboard: true,
              }}
            />
            <Detail icon={<PhoneSVG />} columns={{ title: "Phone", value: company?.phoneNumber, format: "tel" }} />
            <Detail icon={<FaxSVG />} columns={{ title: "Fax", value: company?.faxNumber, format: "tel" }} />
            <Detail
              icon={<LocationSVG />}
              columns={{
                title: "Mailing Address",
                value: formatAddress(
                  company?.address1 ?? "",
                  company?.address2 ?? "",
                  company?.address3 ?? "",
                  company?.city ?? "",
                  company?.state ?? "",
                  company?.postalCode ?? "",
                  company?.country ?? ""
                ),
              }}
            />
          </div>
        )}
        {/* Note Stream */}
        <NotesProvider>
          <NoteStream title="Notes" tableKey="company" objectKey={customerId} />
        </NotesProvider>
      </>
    </ThemeProvider>
  );
}

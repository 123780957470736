import React from "react";
import SecondaryActivity from "../../SecondaryActivity/SecondaryActivity";
import { SecondaryType } from "../../SecondaryActivity/SecondaryActivityTypes";
import { AssignProps } from "../ActivityItemVariantTypes";
import { useTranslation } from "react-i18next";

const Assign: React.FC<AssignProps> = ({ assigneeNote, assigneeName, creator, ...props }) => {
  const { t } = useTranslation();
  creator = {
    ...creator,
    message: assigneeName
      ? t("activityDetails.feedItems.assignActiviy.assignedToMessage", { ns: "activities", assigneeName })
      : t("activityDetails.feedItems.assignActiviy.assignedMessage", { ns: "activities" }),
  };

  return assigneeNote ? (
    <SecondaryActivity variant={SecondaryType.DetailedWithAction} creator={creator} {...props}>
      <p>{assigneeNote}</p>
    </SecondaryActivity>
  ) : (
    <SecondaryActivity creator={creator} variant={SecondaryType.Base} {...props} />
  );
};

export default Assign;

import React from "react";
import { Divider, Skeleton } from "@mui/material";
import Utils from "../../../utils/utils";
import useGlobalSearchStyle from "../Styles/useGlobalSearchStyles";
import { SKELTON_NUMERIC_CONSTANT } from "../Styles/constant";

const ResultLoadingSkelton: React.FC = () => {
  const classes = useGlobalSearchStyle({});

  return (
    <>
      <div className={classes.headerRootSkelton}>
        <div>
          <Skeleton
            variant="rectangular"
            width={Utils.getConvertedSizeInRem(SKELTON_NUMERIC_CONSTANT.LEFT_HEADER_WIDTH)}
            height={Utils.getConvertedSizeInRem(SKELTON_NUMERIC_CONSTANT.LEFT_HEADER_HEIGHT)}
            animation="wave"
          />
        </div>
        <div>
          <Skeleton
            variant="rectangular"
            width={Utils.getConvertedSizeInRem(SKELTON_NUMERIC_CONSTANT.RIGHT_HEADER_WIDTH)}
            height={Utils.getConvertedSizeInRem(SKELTON_NUMERIC_CONSTANT.RIGHT_HEADER_HEIGHT)}
            animation="wave"
          />
        </div>
      </div>

      {["1", "2", "3", "4"].map((item) => {
        return (
          <div key={item}>
            <div className={classes.listRootSkelton}>
              <div className={classes.listItemRootSkelton}>
                <div className={classes.avatarSkeltonDiv}>
                  <Skeleton
                    variant="rectangular"
                    width={Utils.getConvertedSizeInRem(SKELTON_NUMERIC_CONSTANT.AVATAR_SIZE)}
                    height={Utils.getConvertedSizeInRem(SKELTON_NUMERIC_CONSTANT.AVATAR_SIZE)}
                    animation="wave"
                  />
                </div>
                <div className={classes.itemsSkeltonContainer}>
                  <div className={classes.primaryTextSkelton}>
                    <Skeleton
                      variant="rectangular"
                      width={Utils.getConvertedSizeInRem(SKELTON_NUMERIC_CONSTANT.LEFT_PRIMARY_TEXT_WIDTH)}
                      height={Utils.getConvertedSizeInRem(SKELTON_NUMERIC_CONSTANT.LEFT_PRIMARY_TEXT_HEIGHT)}
                      animation="wave"
                    />
                    <Skeleton
                      variant="rectangular"
                      width={Utils.getConvertedSizeInRem(SKELTON_NUMERIC_CONSTANT.RIGHT_PRIMARY_TEXT_WIDTH)}
                      height={Utils.getConvertedSizeInRem(SKELTON_NUMERIC_CONSTANT.RIGHT_PRIMARY_TEXT_HEIGHT)}
                      animation="wave"
                    />
                  </div>
                  <div className={classes.secondaryTextSkelton}>
                    <Skeleton
                      variant="rectangular"
                      width={Utils.getConvertedSizeInRem(SKELTON_NUMERIC_CONSTANT.SECONDARY_TEXT_WIDTH)}
                      height={Utils.getConvertedSizeInRem(SKELTON_NUMERIC_CONSTANT.SECONDARY_TEXT_HEIGHT)}
                      animation="wave"
                    />
                  </div>
                  <div>
                    <Skeleton
                      variant="rectangular"
                      width={Utils.getConvertedSizeInRem(SKELTON_NUMERIC_CONSTANT.SECONDARY_SUBHEADER_TEXT_WIDTH)}
                      height={Utils.getConvertedSizeInRem(SKELTON_NUMERIC_CONSTANT.SECONDARY_SUBHEADER_TEXT_HEIGHT)}
                      animation="wave"
                    />
                  </div>
                </div>
              </div>
            </div>
            <Divider />
          </div>
        );
      })}
    </>
  );
};

export default ResultLoadingSkelton;

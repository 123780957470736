import React, { ReactNode } from "react";
import { ButtonPrimary, ButtonSecondary, ButtonTertiary } from "./button.styles";

type ButtonProps = {
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  children?: React.ReactNode;
  loading?: boolean;
  tooltip?: string | ReactNode;
  disableFocusListner?: boolean;
  shortcutKeysText?: string;
  variant?: "primary" | "secondary" | "tertiary";
  size?: "large" | "small";
};

const Button = (props: ButtonProps): React.ReactElement => {
  const ButtonWithVariant = props.variant === "primary" ? ButtonPrimary : props.variant === "secondary" ? ButtonSecondary : ButtonTertiary;
  return (
    <ButtonWithVariant
      disableFocusRipple
      disableRipple
      className={`${props.variant} ${props.className ?? ""} ${props.size ?? "large"}`}
      onClick={props.onClick}
      disabled={props.disabled || props.loading}
    >
      {props?.children}
    </ButtonWithVariant>
  );
};

export default Button;

import { EmojiOptions } from "../types/enums";

export const ReactionsDefault: {
  [key: string]: {
    count: number;
    givers: Array<{ name: string; id: string; user_id: number }>;
    highlight: boolean;
  };
} = {
  [EmojiOptions.THUMBS_UP]: {
    count: 0,
    givers: [],
    highlight: false,
  },
  [EmojiOptions.THUMBS_DOWN]: {
    count: 0,
    givers: [],
    highlight: false,
  },
  [EmojiOptions.HAPPY]: {
    count: 0,
    givers: [],
    highlight: false,
  },
  [EmojiOptions.SAD]: {
    count: 0,
    givers: [],
    highlight: false,
  },
  [EmojiOptions.CELEBRATE]: {
    count: 0,
    givers: [],
    highlight: false,
  },
  [EmojiOptions.HEART]: {
    count: 0,
    givers: [],
    highlight: false,
  },
};

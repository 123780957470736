import React from "react";
import Dialog from "../Dialog/Dialog";
import DialogTitle from "../DialogTitle/DialogTitle";
import DialogContent from "../DialogContent/DialogContent";
import DialogContentText from "../DialogContentText/DialogContentText";
import DialogActions from "../DialogActions/DialogActions";
import { DialogModalInterface } from "./DialogModalInterface";
import Button from "../Button/Button";
import { Close } from "../Icons/Icons";

const DialogModalComponent: React.FC<DialogModalInterface> = (props: DialogModalInterface) => {
  return (
    <Dialog
      className={props.classes}
      open={props.modalStates.open}
      onClose={() => props.modalStates.onCloseFunction()}
      overrideDialogClasses={props?.overrideDialogClasses}
    >
      {!props.modalStates.disableCloseIcon && (
        <Button className="library-modal-close-icon" type="button" variant="solo-icon" onClick={() => props.modalStates.onCloseFunction()}>
          <Close />
        </Button>
      )}
      <DialogTitle className={props.title.classes ?? "h3"}>{props.title.label}</DialogTitle>
      <DialogContent className="mt-ba mb-lg">
        <DialogContentText className={props.content.classes ?? "body1"}>{props.content.label}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {props.secondaryButton.enable && (
          <Button
            className={`mr-auto ${props.secondaryButton.classes ?? ""}`}
            variant={props.secondaryButton.variant ?? "secondary"}
            type={props.secondaryButton.type ?? "button"}
            onClick={() => {
              props.secondaryButton.onClickHandler();
            }}
            loading={props.secondaryButton.loader}
            disabled={props.secondaryButton?.disabled}
          >
            {props.secondaryButton.label ?? "Cancel"}
          </Button>
        )}
        {props.primaryButton.enable && (
          <Button
            className={props.primaryButton.classes ?? ""}
            variant={props.primaryButton.variant ?? "primary"}
            type={props.primaryButton.type ?? "button"}
            onClick={() => {
              props.primaryButton.onClickHandler();
            }}
            loading={props.primaryButton.loader}
            disabled={props.primaryButton?.disabled}
          >
            {props.primaryButton.label ?? "Submit"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DialogModalComponent;

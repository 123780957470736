import React from "react";
import { MenuItem, Select, SelectChangeEvent, SelectProps } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import useDropdownStyle from "./useDropdownStyle";
import { CheckList } from "../Icons/Icons";
import _ from "underscore";
import { MenuOptionType } from "../AtomicComponents/TableFilters/Presets/preset.filter.types";
import { useTranslation } from "react-i18next";

interface DropdownProps extends SelectProps {
  handleDropDownChange: (event: SelectChangeEvent, param: string, child: React.ReactNode) => void;
  selectedValue: string;
  optionList: MenuOptionType[];
  hasValueChanged: boolean;
  defaultText: string;
  param?: string;
  isNoneItemRequired?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  handleDropDownChange,
  selectedValue = "",
  optionList = [],
  hasValueChanged,
  defaultText = "",
  param = "",
  isNoneItemRequired = true,
  className,
}) => {
  const { select, menuRoot, divRoot, fillDivRoot, itemText, listItemIcon } = useDropdownStyle();
  const { t } = useTranslation();
  /**
   * @function processSelectedValue
   * Function to get multiple selected values from Dropdown
   * and return computed custom Title to be shown on Dropdown
   * based on condition that one or more menu items have been selected
   * @param selected string[]: array of valus selected in Dropdown
   * @returns string
   */
  const processSelectedValue = (selected: string) => (_.isEmpty(selected) ? defaultText : selected);

  /**
   * Convert selectedValue into translated string (if applicable) in dropdown
  */ 
  const selectedOption = optionList.find((optionItem) => selectedValue === optionItem.value);
  const selectedOptionValue = selectedOption?.label ?? selectedValue;

  return (
    <div className={`${hasValueChanged ? fillDivRoot : divRoot} ${className}`}>
      <Select
        id="dropdown"
        onChange={(event, child) => handleDropDownChange(event, param, child)}
        className={select}
        value={selectedOptionValue}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          className: menuRoot,
        }}
        IconComponent={KeyboardArrowDown}
        displayEmpty={true}
        renderValue={(selected) => processSelectedValue(selected)}
      >
        {isNoneItemRequired && (
          <MenuItem key="None" value="">
            <span className={listItemIcon}>{selectedValue === `` && <CheckList />}</span>{" "}
            <span className={itemText}>
              <em>{t("globalSearchResults.filters.numericFilter.menuListLabels.none", {ns: "global_search"})}</em>
            </span>
          </MenuItem>
        )}
        {optionList?.map((item) => (
          <MenuItem key={item.key} value={item.value}>
            <span className={listItemIcon}>
              {item.value === selectedValue && <CheckList />}</span>
              <span className={itemText}>
                {item.label ?? item.value}
              </span>
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default Dropdown;

import React from "react";
import { Pen } from "../../../../../../library/Icons/Icons";
import PrimaryActivity from "../../PrimaryActivity/PrimaryActivity";
import { HEADER_APPROVAL_REQUEST } from "../../../../../../../constants/config";
import { ApprovalReviewedProps as ApprovalAccessRevokedProps } from "../ActivityItemVariantTypes";

const AccessRevoked: React.FC<ApprovalAccessRevokedProps> = (props) => {
  return (
    <PrimaryActivity
      {...{
        ...props,
        creator: { ...props.creator, message: HEADER_APPROVAL_REQUEST },
        icon: <Pen />,
        FLAGS: { REPLY_OPTIONS: true, TIME_SAVING_INDICATOR: true },
      }}
    />
  );
};

export default AccessRevoked;

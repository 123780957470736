import React from "react";
import { SaveApAutomationEmailProps } from "../ActivityItemVariantTypes";
import * as Labels from "../../../../../../../constants/config";
import { SecondaryType } from "../../SecondaryActivity/SecondaryActivityTypes";
import SecondaryActivity from "../../SecondaryActivity/SecondaryActivity";

const SaveApAutomationEmail: React.FC<SaveApAutomationEmailProps> = ({ creator, countryCode }) => {
  creator = { ...creator, message: `${Labels.HEADER_SAVE_AP_AUTOMATION_EMAIL}` };

  return <SecondaryActivity creator={creator} variant={SecondaryType.Base} countryCode={countryCode} />;
};

export default SaveApAutomationEmail;

import React from "react";
import "./DetailsTab.scss";
import RelatedActivityStreamsContainer from "./RelatedActivityStreams/RelatedActivityStreamsContainer";
import RelatedAttachmentsContainer from "./RelatedAttachments/RelatedAttachmentsContainer";
import RelatedProfileInfoContainer from "./Profile/RelatedProfileInfoContainer";
import { ACTIVITIES_TAB_LBL, ATTACHMENTS_TAB_LBL, PROFILE_TAB_LBL } from "../../../../../constants/config";

enum Tabs {
  Related_Activity_Streams,
  Profile,
  Attachments,
}

interface DetailsTabInterface {
  newActivityComponentProps: NewActivityComponentProps;
  id: string;
  type: string;
  refreshList?: boolean;
  profileInfo: {
    customerId: string;
    phone: string;
    email: string;
  };
}

const DetailsTab: React.FC<DetailsTabInterface> = (props: DetailsTabInterface) => {
  const [tab, setTab] = React.useState<Tabs>(Tabs.Profile);
  return (
    <div className="details-tab-container">
      <div className="head">
        <div className="tabs-wrapper">
          <p className={`tab ${tab === Tabs.Profile ? "tab-active" : ""} body2`} onClick={() => setTab(Tabs.Profile)}>
            {PROFILE_TAB_LBL}
          </p>
          <p
            className={`tab ${tab === Tabs.Related_Activity_Streams ? "tab-active" : ""} body2`}
            onClick={() => setTab(Tabs.Related_Activity_Streams)}
          >
            {ACTIVITIES_TAB_LBL}
          </p>
          <p className={`tab ${tab === Tabs.Attachments ? "tab-active" : ""} body2`} onClick={() => setTab(Tabs.Attachments)}>
            {ATTACHMENTS_TAB_LBL}
          </p>
        </div>
      </div>
      <div className={`body ${tab === Tabs.Attachments ? "remove-padding" : ""}`} id="infinite-scroll">
        {tab === Tabs.Related_Activity_Streams && (
          <RelatedActivityStreamsContainer
            newActivityComponentProps={props.newActivityComponentProps}
            id={props.id}
            type={props.type}
            refreshList={props.refreshList}
          />
        )}
        {tab === Tabs.Profile && (
          <RelatedProfileInfoContainer
            email={props?.profileInfo?.email ?? ""}
            phone={props?.profileInfo?.phone ?? ""}
            customerId={props?.profileInfo?.customerId ?? ""}
          />
        )}
        {tab === Tabs.Attachments && <RelatedAttachmentsContainer transactionId={props.id} transactionType={props.type} />}
      </div>
    </div>
  );
};

export default DetailsTab;

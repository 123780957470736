import React, { useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { useMsal } from "@azure/msal-react";
import { IntercomProvider } from "react-use-intercom";

import Home from "./routes/Home/Home";
import FourOFourError from "./routes/FourOFourError/FourOFourError";
import AppProvider from "./contexts/AppContext";
import { CustomNavigationClient } from "./auth/NavigationClient";
import PrivateRoute from "./auth/PrivateRoute";
import { reactPlugin } from "./AppInsights";
import Utils from "./utils/utils";
import WorkspaceProvider from "./contexts/WorkspaceContext";
import ComponentConfigProvider from "./contexts/ComponentConfigContext";
import Logout from "./routes/Logout/Logout";
import { DEFAULT_NUMERIC_VALUES, SENTRY_TRACES_SAMPLE_RATES } from "./constants/NumericConstants";
import { useLocation } from "react-router";
import LogoutSucccess from "./routes/LogoutSuccess/LogoutSuccess";
import TemplateProvider from "./contexts/TemplateContext";
import { AccountInfo, AuthenticationResult, EventMessage, EventType } from "@azure/msal-browser";
import { globalSearchRemoveStorage } from "./components/GlobalSearch/Utils/LocalStorageUtils";
import Invitation from "./routes/Invitation/Invitation";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { advanceGlobalSearchRemoveStorage } from "./components/AdvanceGlobalSearch/Utils/LocalStorageUtils";
import FeatureFlagsProvider from "./contexts/FeatureFlagContext";
import { useColumnFilters } from "./components/library/AtomicComponents/Table";
import ExportProvider from "./contexts/ExportContext";
import LocaleProvider from "./contexts/LocaleContext";

function App(): React.ReactElement {
  const { instance } = useMsal();
  const history = useHistory();
  const location = useLocation();
  const navigationClient = new CustomNavigationClient(history);
  instance.setNavigationClient(navigationClient);
  const { removeSearchSuggestionStorage } = useColumnFilters();

  const queryParams = new URLSearchParams(location.search);

  // Here we handle an in-progress OAuth flow where a given ERP redirects back to us with an auth code. If a new window was opened, We need to
  // signal the main window with the code then close this one.
  if (queryParams.has("code") && queryParams.has("state")) {
    const state = queryParams.get("state")?.split("|") || [];

    // Are we dealing with an erp or email connector?
    const storageKeys = ["ERPAuthState", "EmailAuthState"];
    let localStorageKey;
    for (const key of storageKeys) {
      const storageItem = localStorage.getItem(key);
      if (state[state.length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE] === storageItem) {
        localStorageKey = key;
        break;
      }
    }

    // Only proceed if the redirect URL includes a state value and the ID from it matches the one we originally sent
    if (localStorageKey !== undefined) {
      localStorage.removeItem(localStorageKey);

      let realmId = "";

      if (queryParams.has("realmId")) {
        realmId = queryParams.get("realmId") || "";

        if (realmId === "null") {
          realmId = "";
        }
      }

      const messageBody = {
        type: "auth_code",
        code: queryParams.get("code"),
        erp: state[0],
        appId: state[1],
        realmId,
      };

      if (window.opener) {
        window.opener.postMessage(messageBody, window.location.origin);
        window.close();
      } else {
        // TODO: Add code to handle the case where the redirect is happening in the same browser window
      }
    }
    // Handle OAuth Error flow
  } else if (queryParams.has("error") && queryParams.has("error_description") && queryParams.has("state") && window.opener) {
    window.opener.postMessage({ type: "erp_error", description: queryParams.get("error_description") }, window.location.origin);
    window.close();
  }

  // Sync logged-in state from B2C Redirects
  useEffect(() => {
    const callbackId = instance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        instance.setActiveAccount(account);
      } else if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
        instance.logoutRedirect();
        globalSearchRemoveStorage();
        advanceGlobalSearchRemoveStorage();
        removeSearchSuggestionStorage();
        /**
         * Clear magic-link headers
         */
        Utils.clearMagicLinkAuthRequest();
      }
    });
    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);

  // Sync logged-in state across tabs and windows
  useEffect(() => {
    instance.enableAccountStorageEvents();
    const callbackId = instance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.ACCOUNT_ADDED) {
        const account = event.payload as AccountInfo;
        instance.setActiveAccount(account);
      } else if (event.eventType === EventType.ACCOUNT_REMOVED) {
        instance.setActiveAccount(null);
        window.location.href = window.location.origin + "/account/logout-success";
      }
    });
    return () => {
      instance.disableAccountStorageEvents();
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);

  /**
   * Sentry tracing of transactions for performance monitoring.
   * Here set tracesSampleRate to 1.0 to capture 100% of events, however it can put load on to the system,
   * therefore its recommended to adjust this value in production
   * @param dsn - The DSN tells the SDK where to send the events.
   * @param debug - Turns debug mode on or off. If debug is enabled SDK will attempt to print out
   * useful debugging information if something goes wrong with sending the event. The default is always false
   * @param maxBreadcrumbs - This variable controls the total amount of breadcrumbs that should be captured.
   * This defaults to 100, but you can set this to any number.
   * @param autoSessionTracking - When set to true, the SDK will send session events to Sentry.
   * This is supported in all browser SDKs, emitting one session per pageload and page navigation to Sentry.
   */
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    maxBreadcrumbs: 50,
    autoSessionTracking: true,
    environment: process.env.REACT_APP_ENV,
    debug: false,
    integrations: [
      new BrowserTracing({
        tracingOrigins: [
          "localhost",
          process.env.REACT_APP_REDIRECT_URL ?? "",
          process.env.REACT_APP_API_ENDPOINT ?? "",
          process.env.REACT_APP_API_ENDPOINT_PLATFORM ?? "",
          process.env.REACT_APP_API_ENDPOINT_INBOX ?? "",
          /^\//,
          /^htpps:\/\//,
        ],
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: Utils.isProduction() ? SENTRY_TRACES_SAMPLE_RATES.PROD : SENTRY_TRACES_SAMPLE_RATES.STAGING,
  });

  return (
    <IntercomProvider appId={Utils.getIntercomAppId()} shouldInitialize={Utils.shouldEnableIntercom()}>
      <FeatureFlagsProvider>
        <LocaleProvider>
          <ComponentConfigProvider>
            <AppProvider>
              <WorkspaceProvider>
                <ExportProvider>
                  <TemplateProvider>
                    <Switch>
                      {/* Route solely for solely for Single-sign out defined by B2C */}
                      <Route
                        exact
                        path="/logout"
                        component={() => {
                          return <Logout />;
                        }}
                      />
                      <Route
                        exact
                        path="/logout-success"
                        component={() => {
                          return <LogoutSucccess />;
                        }}
                      />
                      {/* This route exists solely for Azure B2C to have a stable redirect location, meaning one that doesn't itself redirect or modify the
                browser URL. The path is defined in our B2C policies. */}
                      <Route
                        exact
                        path="/auth"
                        component={() => {
                          return <FourOFourError fullScreen />;
                        }}
                      />
                      <Route
                        exact
                        path="/invitation"
                        component={() => {
                          return <Invitation />;
                        }}
                      />
                      <PrivateRoute path="/" component={Home} />
                      <Route
                        component={() => {
                          return <FourOFourError />;
                        }}
                      />
                    </Switch>
                  </TemplateProvider>
                </ExportProvider>
              </WorkspaceProvider>
            </AppProvider>
          </ComponentConfigProvider>
        </LocaleProvider>
      </FeatureFlagsProvider>
    </IntercomProvider>
  );
}

export default withAITracking(reactPlugin, App);

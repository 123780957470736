import React from "react";
import { useLocation, useParams } from "react-router-dom";
import _ from "underscore";
import { AppContext } from "../../../contexts/AppContext";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";
import { GlobalSearchCategories, WorkspaceType } from "../../../types/enums";
import { globalSearchClientV2 } from "../../../db/version2Accessor";
import { globalSearchConfigGetter } from "../Config";
import {
  FilterViewContainerInterface,
  FilterOptionsInterface,
  FilterSelectedValuesInterface,
  ObjectInterface,
  SearchResultsStringObject,
} from "../Interface/SearchResultContentInterface";
import Dropdown from "../../library/Dropdown/Dropdown";
import DateAndRangePicker from "../../library/DateAndRangePicker/DateAndRangePicker";
import DropdownInteger from "../../library/DropdownInteger/DropdownInteger";
import DropdownNumeric from "../../library/DropdownNumeric/DropdownNumeric";
import DropdownSearch from "../../library/DropdownSearch/DropdownSearch";
import ToggleChip from "../../library/ToggleChip/ToggleChip";
import { SelectChangeEvent } from "@mui/material/Select";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../../constants/NumericConstants";
import { GLOBAL_SEARCH_FILTER_TYPES } from "../../../constants/GlobalSearchConstants";
import { GLOBAL_SEARCH_NUMERIC_FILTER_OPERATORS } from "../../../constants/GlobalSearchConstants";
import { useTranslation } from "react-i18next";
import { TableFilterConstant, TableFilterComparatorConstant } from "../../library/AtomicComponents/constants/table.constants";
import { DateTime } from "luxon";

type GSDateFilter = {
  comparator: string;
  field: string;
};

const FilterViewContainer: React.FC<FilterViewContainerInterface> = ({ updateFilterQuery }) => {
  const { userStatus } = React.useContext(AppContext) as AppType;
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { category } = useParams<{ category: string }>();
  const filterConfig = React.useRef(
    globalSearchConfigGetter(selectedWorkspace?.workspace_type_route?.toUpperCase() || WorkspaceType.AR?.toUpperCase())["global-search-results"]
      .filters
  );
  const { search } = useLocation();
  const { t } = useTranslation();
  const getQueryFromParams = React.useMemo(() => {
    const searchParams = new URLSearchParams(search);
    return { query: searchParams.get("query"), sort: searchParams.get("sort") };
  }, [search]);
  const [hasChipSelected, setHasChipSelected] = React.useState<boolean>(false);
  const [filterOptionsMenu, setFilterOptionsMenu] = React.useState<FilterOptionsInterface>({});
  const [filterSelectedValues, setFilterSelectedValues] = React.useState<FilterSelectedValuesInterface>({});

  /**
   * @function fetchSearchSuggestions
   * Function to
   * - fetch suggestions from getSearchSuggestions API to be displayed in DROPDOWN_SEARCH type dropdowns
   * - initialise the filterSelectedValues for filters based on category and filter type
   */
  const fetchSearchSuggestions = async () => {
    let result = {} as APIResponse;
    if (getQueryFromParams?.query?.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      return;
    }
    /**
     * For Accounting workspace: if category is invoices or bills update
     * query to have filters.
     * TODO: update object to have pre-defined filters on search tabs and
     * make it the filters generic
     **/
    const resultFilters = (function () {
      if (selectedWorkspace?.workspace_type_route === WorkspaceType.AW) {
        if (category === "invoices") {
          return [{ invoice_type_code: ["AR Invoice", "AR Credit Memo"] }];
        } else if (category === "bills") {
          return [{ invoice_type_code: ["AP Invoice", "AP Credit Memo"] }];
        }
      } else return [];
    })();
    try {
      result = await globalSearchClientV2.getSearchSuggestions(
        getQueryFromParams.query as string,
        (GlobalSearchCategories as SearchResultsStringObject)[category],
        selectedWorkspace?.id,
        getQueryFromParams?.sort as string,
        DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
        DEFAULT_NUMERIC_VALUES.DEFAULT_THOUSAND,
        resultFilters
      );
    } catch (error) {
      console.error(error);
    } finally {
      if (result.success) {
        const resultData = result.data ?? [];
        const resultsArray = resultData
          ?.map((data: ObjectInterface) => data && data?.result?.map((res: ObjectInterface, pos: number) => ({ id: pos, ...res })))
          ?.flat(DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);
        {
          // Populating the suggestions for every filter as per category from config from resultsArray
          filterConfig?.current?.[category]?.map((item: { name: string; key: string; type: string; defaultValues: string[] }) => {
            switch (item?.type) {
              case GLOBAL_SEARCH_FILTER_TYPES.DROPDOWN_SEARCH: {
                const filterKey = item?.key;
                const optionsList = _.compact(Array.from(new Set(resultsArray.map((item: ObjectInterface) => item?.[filterKey])))) as string[];

                // Set suggestion options for filter from resultsArray
                setFilterOptionsMenu((prevState) => ({ ...prevState, [`${filterKey}`]: optionsList }));
                return;
              }
              case GLOBAL_SEARCH_FILTER_TYPES.DROPDOWN: {
                const filterKey = item?.key;
                const optionsList = _.isEmpty(item?.defaultValues)
                  ? (_.compact(Array.from(new Set(resultsArray.map((item: ObjectInterface) => item?.[filterKey])))) as string[])
                  : item?.defaultValues;

                // Set suggestion options for filter from defaultValues or from resultsArray if defaultValues is empty
                setFilterOptionsMenu((prevState) => ({ ...prevState, [`${filterKey}`]: optionsList }));
                return;
              }
              default:
                return;
            }
          });
        }
      }
    }
  };

  /**
   * @function formatDateQuery
   * A helper function to generate date filter query
   * @param filter
   * @returns
   */
  const formatDateQuery = (filter: GSDateFilter) => {
    switch (filter.comparator) {
      case TableFilterComparatorConstant.TODAY:
        return `${filter?.field}:'${DateTime.now().toFormat("yyyy-MM-dd")}'`;
      case TableFilterComparatorConstant.THIS_WEEK:
        return `${filter?.field}: [${DateTime.now().startOf("week").toFormat("yyyy-MM-dd")} TO ${DateTime.now()
          .endOf("week")
          .toFormat("yyyy-MM-dd")}]`;
      case TableFilterComparatorConstant.THIS_MONTH:
        return `${filter?.field}: [${DateTime.now().startOf("month").toFormat("yyyy-MM-dd")} TO ${DateTime.now()
          .endOf("month")
          .toFormat("yyyy-MM-dd")}]`;
      case TableFilterComparatorConstant.LAST_MONTH:
        return `${filter?.field}: [${DateTime.now().minus({ month: 1 }).startOf("month").toFormat("yyyy-MM-dd")} TO ${DateTime.now()
          .minus({ month: 1 })
          .endOf("month")
          .toFormat("yyyy-MM-dd")}]`;
      case TableFilterComparatorConstant.LAST_THREE_MONTHS:
        return `${filter?.field}: [${DateTime.now().minus({ month: 3 }).endOf("month").toFormat("yyyy-MM-dd")} TO ${DateTime.now()
          .minus({ month: 1 })
          .endOf("month")
          .toFormat("yyyy-MM-dd")}]`;
      case TableFilterComparatorConstant.LAST_SIX_MONTHS:
        return `${filter?.field}: [${DateTime.now().minus({ month: 6 }).endOf("month").toFormat("yyyy-MM-dd")} TO ${DateTime.now()
          .minus({ month: 1 })
          .endOf("month")
          .toFormat("yyyy-MM-dd")}]`;
      case TableFilterComparatorConstant.LAST_TWELEVE_MONTHS:
        return `${filter?.field}: [${DateTime.now().minus({ month: 12 }).endOf("month").toFormat("yyyy-MM-dd")} TO ${DateTime.now()
          .minus({ month: 1 })
          .endOf("month")
          .toFormat("yyyy-MM-dd")}]`;
      case TableFilterComparatorConstant.LAST_YEAR_TO_DATE:
        return `${filter?.field}: [${DateTime.now().startOf("year").toFormat("yyyy-MM-dd")} TO ${DateTime.now().toFormat("yyyy-MM-dd")}]`;
      case TableFilterComparatorConstant.YEAR_TO_DATE:
        return `${filter?.field}: [${DateTime.now().minus({ year: 1 }).startOf("year").toFormat("yyyy-MM-dd")} TO ${DateTime.now().toFormat(
          "yyyy-MM-dd"
        )}]`;
      case TableFilterComparatorConstant.ANY_TIME: {
        return "";
      }
      default: {
        if (filter?.comparator) {
          const range = filter?.comparator?.split(",");
          return `${filter?.field}: [${range?.[NUMERIC_VALUES.CONSTANT_ZERO]} TO ${range?.[NUMERIC_VALUES.CONSTANT_ONE]}]`;
        }
        return "";
      }
    }
  };

  /* It will form button filter queries for applied filter values in two different formats
   * 1st format: for numeric and date filters, append the applied filter value to the queryString params "query"
   *  for ex:- query: Demo AND payment_amount:<1000
   * 2nd format: for dropdown,dropdownsearch and chip filters, add the values to the queryString param "filter"
   *  for ex:- filter: {"company_name":["xyz","Tiger"],"type":"Check"}
   */
  const createFilterQuery = () => {
    let combinedFilterQuery = "";
    let filterParamQuery = {};

    filterConfig?.current?.[category]?.map((item: { name: string; key: string; payload: string; type: string }) => {
      const filterKey = item?.key;
      const filterPayload = item?.payload;
      switch (item?.type) {
        case GLOBAL_SEARCH_FILTER_TYPES.DROPDOWN_SEARCH: {
          filterParamQuery = {
            ...filterParamQuery,
            ...(!_.isEmpty(filterSelectedValues?.[filterKey]) && {
              [filterPayload]:
                filterSelectedValues?.[filterKey]?.length === NUMERIC_VALUES.CONSTANT_ONE
                  ? filterSelectedValues?.[filterKey][0]
                  : filterSelectedValues?.[filterKey],
            }),
          };

          return;
        }
        case GLOBAL_SEARCH_FILTER_TYPES.DROPDOWN: {
          filterParamQuery = {
            ...filterParamQuery,
            ...(!_.isEmpty(filterSelectedValues?.[filterKey]) && { [filterPayload]: filterSelectedValues?.[filterKey] }),
          };
          return;
        }
        case GLOBAL_SEARCH_FILTER_TYPES.CHIP: {
          filterParamQuery = {
            ...filterParamQuery,
            ...(hasChipSelected && { [filterPayload]: hasChipSelected }),
          };
          return;
        }
        case GLOBAL_SEARCH_FILTER_TYPES.DROPDOWN_INTEGER:
        case GLOBAL_SEARCH_FILTER_TYPES.DROPDOWN_NUMERIC: {
          const dropdownSelectedValue = filterSelectedValues?.[filterKey] as string;
          const formQuery = `${filterPayload}${dropdownSelectedValue}`;
          combinedFilterQuery = _.isEmpty(dropdownSelectedValue) ? combinedFilterQuery : `${combinedFilterQuery} AND ${formQuery}`;
          return;
        }
        case GLOBAL_SEARCH_FILTER_TYPES.DATE: {
          const selectedFilterValue = filterSelectedValues?.[filterKey] as string;
          const formQuery = formatDateQuery({
            comparator: selectedFilterValue,
            field: filterPayload,
          });
          combinedFilterQuery = _.isEmpty(formQuery) ? combinedFilterQuery : `${combinedFilterQuery} AND ${formQuery}`;
          return;
        }
        default:
          return;
      }
    });
    updateFilterQuery(combinedFilterQuery, filterParamQuery);
  };

  /**
   * @function handleToogleChip
   * Function to update Boolean value selected by CHIP type filters in the view
   * and update it in hasChipSelected to be used for filter query generation
   */
  const handleToogleChip = () => {
    setHasChipSelected(!hasChipSelected);
  };

  /**
   * @function handleDropDownChange
   * Function to accept value selected by DROPDOWN type filters in the view
   * and update it in filterSelectedValues to be used for filter query generation
   * @param event Value selected by Dropdown
   * @param param Identifier key of parameter in query for which value is selected
   */
  const handleDropDownChange = (event: SelectChangeEvent, param: string) => {
    setFilterSelectedValues((prevState) => ({ ...prevState, [`${param}`]: event.target.value }));
  };

  /**
   * @function handleDateChange
   * A helper function to set the selected date values
   * @param event
   * @param param
   * @param child
   */
  const handleDateChange = (event: any, param: string, child?: string) => {
    if (child === TableFilterComparatorConstant.CUSTOM_DATE) {
      setFilterSelectedValues((prevState) => ({ ...prevState, [`${param}`]: event }));
    } else {
      setFilterSelectedValues((prevState) => ({ ...prevState, [`${param}`]: event?.currentTarget?.dataset?.["value"] }));
    }
  };

  const handleDropDownNumericChange = (query: string, param: string, child: string) => {
    switch (child) {
      case GLOBAL_SEARCH_NUMERIC_FILTER_OPERATORS.LESS_THAN:
        setFilterSelectedValues((prevState) => ({ ...prevState, [`${param}`]: `:<${query}` }));
        return;
      case GLOBAL_SEARCH_NUMERIC_FILTER_OPERATORS.GREATER_THAN:
        setFilterSelectedValues((prevState) => ({ ...prevState, [`${param}`]: `:>${query}` }));
        return;
      case GLOBAL_SEARCH_NUMERIC_FILTER_OPERATORS.EQUALS_TO:
        setFilterSelectedValues((prevState) => ({ ...prevState, [`${param}`]: `:${query}` }));
        return;
      case GLOBAL_SEARCH_NUMERIC_FILTER_OPERATORS.IN_BETWEEN:
        setFilterSelectedValues((prevState) => ({ ...prevState, [`${param}`]: `:[${query}]` }));
        return;
      default:
        setFilterSelectedValues((prevState) => ({ ...prevState, [`${param}`]: "" }));
        return;
    }
  };

  /**
   * @function handleDropDownSearchChange
   * Function to accept array of values selected by DROPDOWN_SEARCH type filters in the view
   * and update it in filterSelectedValues to be used for filter query generation
   * @param event Array of Values selected by Dropdown
   * @param param Identifier key of parameter in query for which value is selected
   */
  const handleDropDownSearchChange = (event: SelectChangeEvent<string[]>, param: string) => {
    const selectedMultipleValues = event.target.value as string[];
    setFilterSelectedValues((prevState) => ({ ...prevState, [`${param}`]: selectedMultipleValues }));
  };

  /**
   * @function getFilterComponent
   * Function to determine the type of filter to be rendered on view
   * @param name
   * @param key
   * @param type
   * @returns
   */
  const getFilterComponent = (name: string, key: string, type: string, multiSelectLabel: string) => {
    switch (type) {
      case GLOBAL_SEARCH_FILTER_TYPES.DROPDOWN_SEARCH: {
        const dropdownSearchSelectedValues = filterSelectedValues?.[key] as string[];
        return (
          <DropdownSearch
            key={name}
            handleDropDownChange={handleDropDownSearchChange}
            selectedValues={_.isEmpty(dropdownSearchSelectedValues) ? [] : dropdownSearchSelectedValues}
            optionList={filterOptionsMenu?.[key]}
            defaultText={name}
            hasValueChanged={!_.isEmpty(dropdownSearchSelectedValues)}
            param={key}
            multiSelectLabel={multiSelectLabel}
          />
        );
      }
      case GLOBAL_SEARCH_FILTER_TYPES.DROPDOWN: {
        const dropdownSelectedValue = filterSelectedValues?.[key] as string;
        return (
          <Dropdown
            key={name}
            handleDropDownChange={handleDropDownChange}
            selectedValue={dropdownSelectedValue}
            optionList={filterOptionsMenu?.[key]?.map((optionString) => ({
              key: optionString,
              value: optionString,
            }))}
            defaultText={name}
            hasValueChanged={!_.isEmpty(dropdownSelectedValue)}
            param={key}
          />
        );
      }
      case GLOBAL_SEARCH_FILTER_TYPES.DROPDOWN_INTEGER: {
        return (
          <DropdownInteger
            key={name}
            handleDropDownChange={handleDropDownNumericChange}
            adornmentSymbol=""
            defaultText={name}
            param={key}
            titleText="Number Value"
          />
        );
      }
      case GLOBAL_SEARCH_FILTER_TYPES.DROPDOWN_NUMERIC: {
        return (
          <DropdownNumeric
            key={name}
            handleDropDownChange={handleDropDownNumericChange}
            adornmentSymbol={userStatus?.currency?.symbol ?? "$"}
            defaultText={name}
            param={key}
            titleText={`${userStatus?.currency?.code ?? "USD"} ${t("globalSearchResults.filters.numericFilter.value", { ns: "global_search" })}`}
          />
        );
      }
      case GLOBAL_SEARCH_FILTER_TYPES.CHIP:
        return (
          <ToggleChip
            key={name}
            value="3"
            selected={hasChipSelected}
            onButtonToggle={handleToogleChip}
            text={name}
            style={{ whiteSpace: "nowrap" }}
          />
        );
      case GLOBAL_SEARCH_FILTER_TYPES.DATE: {
        const dropdownSelectedValue = filterSelectedValues?.[key] as string;
        return (
          <DateAndRangePicker
            key={name}
            handleDropDownChange={handleDateChange}
            selectedValue={dropdownSelectedValue}
            className="date-range-filter"
            optionList={TableFilterConstant.getDateDropDownList(t)?.map((optionString) => ({
              key: optionString?.key,
              value: optionString?.value,
              label: optionString?.label,
            }))}
            defaultText={name}
            hasValueChanged={!_.isEmpty(dropdownSelectedValue)}
            selectedRange={dropdownSelectedValue?.split(",")?.length ? dropdownSelectedValue?.split(",") : []}
            param={key}
            isNoneItemRequired={true}
          />
        );
      }
      default:
        return null;
    }
  };

  // Fetch Search Suggestions on Fresh load
  React.useEffect(() => {
    fetchSearchSuggestions();
  }, [getQueryFromParams, category]);

  React.useEffect(() => {
    setFilterSelectedValues({});
    setHasChipSelected(false);
  }, [category]);

  React.useEffect(() => {
    createFilterQuery();
  }, [filterSelectedValues, hasChipSelected]);

  return (
    <div className="gsp-filter-wrapper">
      {filterConfig?.current?.[category]?.map((item: { name: string; key: string; type: string; multiSelectLabel: string }) =>
        getFilterComponent(item?.name, item?.key, item?.type, item?.multiSelectLabel)
      )}
    </div>
  );
};

export default FilterViewContainer;

import React from "react";
import { EmptyStatesWrapper } from "./emptystates.styles";
import { ThemeProvider, Typography } from "@mui/material";
import theme from "../../../theme/theme";

type EmptyStatesProps = {
  title: string;
  caption: string;
};

export default function EmptyStates(props: EmptyStatesProps): React.ReactElement {
  return (
    <ThemeProvider theme={theme}>
      <EmptyStatesWrapper>
        <Typography variant="h2" className="empty-title">
          {props.title ?? ""}
        </Typography>
        <Typography variant="h4Reg" className="empty-caption">
          {props.caption ?? ""}
        </Typography>
      </EmptyStatesWrapper>
    </ThemeProvider>
  );
}

import { Skeleton } from "@mui/material";
import React from "react";
import { ACTIVITY_DETAILS_SKELETON_CONSTANTS } from "../../../../../../constants/StyleConstants";
import Utils from "../../../../../../utils/utils";

const ActivityFeedItemSkeleton = (): React.ReactElement => {
  return (
    <div className="activity-feed-item-wrapper loader-skeleton">
      <div className="head">
        <div className="icon">
          <Skeleton
            variant="circular"
            width={Utils.getConvertedSizeInRem(ACTIVITY_DETAILS_SKELETON_CONSTANTS.ITEM.HEAD.ICON_WIDTH)}
            height={Utils.getConvertedSizeInRem(ACTIVITY_DETAILS_SKELETON_CONSTANTS.ITEM.HEAD.ICON_HEIGHT)}
            animation="wave"
          />
        </div>
      </div>
      <div className="body">
        <div className="header">
          <Skeleton
            variant="text"
            width={Utils.getConvertedSizeInRem(ACTIVITY_DETAILS_SKELETON_CONSTANTS.ITEM.BODY.HEADER_WIDTH)}
            height={Utils.getConvertedSizeInRem(ACTIVITY_DETAILS_SKELETON_CONSTANTS.ITEM.BODY.HEADER_HEIGHT)}
            animation="wave"
          />
        </div>
        <Skeleton
          variant="text"
          width={Utils.getConvertedSizeInRem(ACTIVITY_DETAILS_SKELETON_CONSTANTS.ITEM.BODY.SUBHEADER_WIDTH)}
          height={Utils.getConvertedSizeInRem(ACTIVITY_DETAILS_SKELETON_CONSTANTS.ITEM.BODY.SUBHEADER_HEIGHT)}
          animation="wave"
        />
        <Skeleton
          variant="text"
          width={"100%"}
          height={Utils.getConvertedSizeInRem(ACTIVITY_DETAILS_SKELETON_CONSTANTS.ITEM.BODY.DESC_HEIGHT)}
          animation="wave"
        />
        <Skeleton
          variant="text"
          width={"100%"}
          height={Utils.getConvertedSizeInRem(ACTIVITY_DETAILS_SKELETON_CONSTANTS.ITEM.BODY.DESC_HEIGHT)}
          animation="wave"
        />
      </div>
    </div>
  );
};

export default ActivityFeedItemSkeleton;

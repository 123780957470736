import React from "react";

import { LargeLoading } from "../../library/Icons/Icons";
import "./WaitScreen.scss";
import { useTranslation } from "react-i18next";
import _ from "underscore";

interface Props {
  loadingMessage?: string;
  secondaryMessage?: string;
}

const WaitScreen: React.FC<Props> = ({ loadingMessage, secondaryMessage }: Props) => {
  const { t } = useTranslation();
  if (_.isUndefined(loadingMessage)) {
    loadingMessage = t("loginMessages.main", { ns: "home" }) as string;
  }
  return (
    <div className={"ws-wrapper"}>
      <div className={"ws-card"}>
        <div data-testid="WaitscreenLoader" className={"ws-loading-wrapper"}>
          <LargeLoading />
        </div>
        <div className="message-wrapper">
          <p className={"text"}>{loadingMessage}</p>
          <p className={"secondary-text"}>{secondaryMessage}</p>
        </div>
      </div>
    </div>
  );
};

export default WaitScreen;

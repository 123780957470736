import { TableFilterVariants } from "../constants/table.constants";
import DropdownSearchFilter from "./Presets/DropdownSearchFilter/tsx/DropdownSearchFilterContainer";
import NumericFilter from "./Presets/NumericFilter/tsx/NumericFilterContainer";
import DateFilter from "./Presets/DateFilter/tsx/DateFilterContainer";
import DropdownFilter from "./Presets/DropdownFilter/tsx/DropdownFilterContainer";

// Preset Type
type FilterVariantModel = {
  id:
    | typeof TableFilterVariants.DROPDOWN_SEARCH_FILTER
    | typeof TableFilterVariants.NUMERIC_FILTER
    | typeof TableFilterVariants.DATE_FILTER
    | typeof TableFilterVariants.DROPDOWN_FILTER;
};

// Preset Filters
const config = Object.freeze({
  [TableFilterVariants.DROPDOWN_SEARCH_FILTER]: DropdownSearchFilter,
  [TableFilterVariants.NUMERIC_FILTER]: NumericFilter,
  [TableFilterVariants.DATE_FILTER]: DateFilter,
  [TableFilterVariants.DROPDOWN_FILTER]: DropdownFilter,
});

const filterVariants = ({ id }: FilterVariantModel) => {
  return config[id];
};

export { default as TableFilter } from "./tsx/TableFilterContainer";

export { filterVariants };

import React, { useEffect, useState, useMemo } from "react";
import useWorkspaceConfigurations from "./useWorkspaceConfigurations";
import { TemplateContext } from "../contexts/TemplateContext";
import useLocale from "./useLocale";
import {
  TabListType,
  TemplateListItemType,
  dropdownListItemType,
} from "../components/library/EmailTemplatePopoverComponent/types/email.template.popover.types";
import { ReactComponent as USFlag } from "../assets/Flags/US.svg";
import { ReactComponent as CAFlag } from "../assets/Flags/CA.svg";
import { ReactComponent as UKFlag } from "../assets/Flags/UK.svg";
import { NUMERIC_VALUES } from "../constants/NumericConstants";

type TemplateData = {
  templateCode: string;
  templateSubject: string;
  templateBody: string;
  templateName: string;
};

type StateType = {
  suggestedEmailTemplateList: TemplateListItemType[];
  allEmailTemplateList: TemplateListItemType[];
  templateTabList: TabListType[];
  tempalteLanguageDropdownList: dropdownListItemType[];
};

export type TempSubjectSnippetValuesType = {
  [key: string]: string | number | boolean | null;
};

/**
 * @customhook useProcessTemplateData
 * reusable hook to proccess template data and prepare and provide the required format data that can
 * be consumed into useEmailTemplatePopover and EmailTemplateComponent
 * @param excludedTemplatesCode: template codes that needs be exluded from the all list [for ex: AP_APPROVAL_REQUEST which is a different
 * activity and don't want to include in all email template list]
 * @param suggestedTemplatesCode: template codes that we want to display into suggested list or other template activity like AP_APPROVAL_REQUEST
 * @param selectedLangCode: default or selected lang code for ex:"en-US"
 * @param tempSubjectSnippetValues: snippet objects that we want to parse from the template subject content {customer:"ABC"}
 */
const useProcessTemplateData = (
  excludedTemplatesCode: string[] = [],
  suggestedTemplatesCode: string[] = [],
  selectedLangCode = "",
  tempSubjectSnippetValues: TempSubjectSnippetValuesType = {}
) => {
  const { selectedWorkspace } = useWorkspaceConfigurations();
  const { templateData } = React.useContext(TemplateContext) as ITemplateProps;
  const { availableLanguagesForTemplates } = useLocale();
  const [state, setState] = useState<StateType>({
    suggestedEmailTemplateList: [],
    allEmailTemplateList: [],
    templateTabList: [],
    tempalteLanguageDropdownList: [],
  });

  //languageOptions which is enabled for templates
  const templateDataForWorkspace = templateData?.get(selectedWorkspace.id);
  const languageOptions = templateDataForWorkspace ? Array.from(templateDataForWorkspace.keys()) : [];
  const currentSelectedLanguage = selectedLangCode?.length ? selectedLangCode : languageOptions[0];

  //all available template for the current workspace with current selected langauge(selectedLangCode) or default langauge (languageOptions[0])
  const allAvailableTemplates: TemplateData[] = templateDataForWorkspace
    ? Array.from(templateData?.get(selectedWorkspace.id).get(currentSelectedLanguage)?.values())
    : [];

  //return the parsed subject text of the selected templateID
  const getSelectedTemplateSubject = (templateID: string) =>
    templateData?.get(selectedWorkspace.id)?.get(currentSelectedLanguage)?.get(templateID)?.parseTemplateSubject(tempSubjectSnippetValues);

  //language and flag mapping
  const getCountryFlagIcon = (countryCode: string) => {
    const flagMap: Record<string, JSX.Element | null> = {
      "en-US": <USFlag />,
      "en-GB": <UKFlag />,
      "fr-CA": <CAFlag />,
    };
    return flagMap[countryCode] ?? null;
  };

  //all template list in the formated type of TemplateListItemType[]
  const allTemplateList = useMemo(() => {
    if (!availableLanguagesForTemplates?.length) {
      return [];
    }

    return allAvailableTemplates
      .filter((item) => item?.templateName)
      .map((item) => ({
        templateId: item.templateCode,
        templateName: item?.templateName,
        templateContent: { subject: item?.templateSubject, body: item?.templateBody },
      }));
  }, [allAvailableTemplates, availableLanguagesForTemplates]);

  //return suggested templates list
  const getSuggestedTemplates = (allTemplateListData: TemplateListItemType[]) =>
    allTemplateListData.filter((item) => suggestedTemplatesCode.includes(item.templateId as string));

  //all template list excluding the excludedTemplatesCode
  const getAllTemplates = (allTemplateListData: TemplateListItemType[]) =>
    allTemplateListData.filter((item) => !excludedTemplatesCode.includes(item.templateId as string));

  useEffect(() => {
    const allRequiredList = getAllTemplates(allTemplateList);
    const suggestedList = getSuggestedTemplates(allTemplateList);

    //formated lanaguage data for langauge dropdown options
    const dropdownOption: dropdownListItemType[] = availableLanguagesForTemplates
      .filter((item) => languageOptions.includes(item.id))
      .map((item) => ({
        id: item.id,
        text: item.displayText,
        icon: getCountryFlagIcon(item.id),
      }));

    //if there is any suggested template required then only show the Suggested tab along with All else only All tab option
    const newTabList = [];
    if (suggestedList.length > NUMERIC_VALUES.CONSTANT_ZERO) {
      newTabList.push({ id: NUMERIC_VALUES.CONSTANT_ZERO, tabKey: "suggested", tabsName: "Suggested", count: suggestedList.length });
    }
    if (allRequiredList.length > NUMERIC_VALUES.CONSTANT_ZERO) {
      newTabList.push({
        id: suggestedList.length > NUMERIC_VALUES.CONSTANT_ZERO ? NUMERIC_VALUES.CONSTANT_ONE : NUMERIC_VALUES.CONSTANT_ZERO,
        tabKey: "all",
        tabsName: "All",
        count: allRequiredList.length,
      });
    }

    setState({
      suggestedEmailTemplateList: suggestedList,
      allEmailTemplateList: allRequiredList,
      templateTabList: newTabList,
      tempalteLanguageDropdownList: dropdownOption,
    });
  }, [availableLanguagesForTemplates, selectedLangCode]);

  return { ...state, getSelectedTemplateSubject };
};

export default useProcessTemplateData;

import React from "react";
import { SecondaryActivityProps, SecondaryType } from "./SecondaryActivityTypes";
import DetailedWithAction from "./DetailedWithAction/DetailedWithAction";
import BaseActivity from "./BaseActivity/BaseActivity";

const SecondaryActivity: React.FC<SecondaryActivityProps> = ({ variant, children, ...props }) => {
  switch (variant) {
    case SecondaryType.Base:
      return <BaseActivity {...props}>{children}</BaseActivity>;
    case SecondaryType.Accordion:
      return <DetailedWithAction {...props}>{children}</DetailedWithAction>;
    case SecondaryType.DetailedWithAction:
      return <DetailedWithAction {...props}>{children}</DetailedWithAction>;
  }
};

export default SecondaryActivity;

import { ClickAwayListener, IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import TableUtils from "../../../../../../../../utils/TableUtils/TableUtils";
import { Ellipses, ReplyFilled } from "../../../../../../../library/Icons/Icons";
import * as configValue from "../../../../../../../../constants/config";
import { EmailAction } from "../../../../../../../../types/enums";
import methods from "../../../../helpers/ActivityFeedMethods";
import { ReplyOptionsProps } from "../HelperTypes";
import useKeyboardShortcut from "../../../../../../../../hooks/useKeyboardShortcut";
import { ActivitySetActionShortcuts, KeyboardActionsConstants } from "../../../../../../../../constants/KeyboardShortcutConstants";
import { TooltipTitles, TooltipTypes } from "../../../../../../../../constants/TooltipConstants";
import CustomTooltip from "../../../../../../../library/CustomTooltip/CustomTooltip";

const ReplyOptions: React.FC<ReplyOptionsProps> = ({ onClickForward, onClickReplyAll, onClickViewOriginalEmail, onClickReply, enableShortcuts }) => {
  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);

  /**
   * HOC Hook for Keyboard ShortCut UI
   */

  const { actionToPerform, setActionDone } = useKeyboardShortcut(ActivitySetActionShortcuts, !enableShortcuts);

  const shortcutDisplayMapper: { [key: string]: string } = useMemo(() => {
    if (!enableShortcuts) return {};
    const mapper: { [key: string]: string } = {};
    ActivitySetActionShortcuts.forEach((item) => {
      if (item.identifier) mapper[item.identifier] = item.displayText;
    });
    return mapper;
  }, [enableShortcuts]);

  /* EVENT HANDLERS */
  const onClickAwayTooltip = () => {
    setIsTooltipOpen(false);
  };

  const onClickTooltipAction = (action: string) => {
    switch (action) {
      case EmailAction.REPLY_ALL:
        onClickReplyAll?.();
        break;
      case EmailAction.FORWARD:
        onClickForward?.();
        break;
      case EmailAction.VIEW_ORIGINAL:
        onClickViewOriginalEmail?.();
        break;
      default:
        throw new Error("Email action does not exist.");
    }
    onClickAwayTooltip();
  };

  /* ELLIPSES-TOOLTIP CONFIGURATIONS */
  const ellipsesActionConfigs = TableUtils.generateMenuList([
    {
      value: configValue.LBL_REPLAY_ALL,
      id: EmailAction.REPLY_ALL,
      clickFunction: () => onClickTooltipAction(EmailAction.REPLY_ALL),
    },
    {
      value: (
        <CustomTooltip
          type={enableShortcuts ? TooltipTypes.SHORTCUT : TooltipTypes.PLAIN}
          title={enableShortcuts ? TooltipTitles.FORWARD : ""}
          shortcutKeysText={enableShortcuts ? shortcutDisplayMapper?.[TooltipTitles.FORWARD] : ""}
        >
          <div>{configValue.LBL_FORWARD}</div>
        </CustomTooltip>
      ),
      id: EmailAction.FORWARD,
      clickFunction: () => onClickTooltipAction(EmailAction.FORWARD),
    },
    {
      value: configValue.LBL_VIEW_ORIGINAL,
      id: "something bad is gere",
      clickFunction: () => onClickTooltipAction(EmailAction.VIEW_ORIGINAL),
    },
  ]);

  /**
   * Actions for Keyboard Shortcuts
   */

  const keyboardActions: { [key: string]: any } = useMemo(
    () => ({
      [KeyboardActionsConstants.send_reply]: () => onClickReply?.({}),
      [KeyboardActionsConstants.send_forward]: () => onClickTooltipAction(EmailAction.FORWARD),
    }),
    [onClickReply, onClickTooltipAction]
  );

  /**
   * Use Effect Calls
   */

  useEffect(() => {
    if (enableShortcuts && actionToPerform && keyboardActions[actionToPerform] && typeof keyboardActions[actionToPerform] === "function") {
      keyboardActions[actionToPerform]();
      setActionDone();
    }
  }, [actionToPerform, enableShortcuts]);

  return (
    <>
      <CustomTooltip
        type={enableShortcuts ? TooltipTypes.SHORTCUT : TooltipTypes.PLAIN}
        title={enableShortcuts ? TooltipTitles.REPLY_TO_EMAIL : "Reply"}
        shortcutKeysText={enableShortcuts ? shortcutDisplayMapper?.[TooltipTitles.REPLY_TO_EMAIL] : ""}
      >
        <div className="reply-action">
          <IconButton
            disableRipple
            onClick={(e) => {
              //To suppress event bubbling up to feed component and triggering "Read More" toggle
              e.stopPropagation();
              onClickReply?.(e);
            }}
          >
            <ReplyFilled />
          </IconButton>
        </div>
      </CustomTooltip>
      <ClickAwayListener onClickAway={onClickAwayTooltip}>
        <div
          className="tooltip-actions"
          onClick={(e) => {
            //To suppress event bubbling up to feed component and triggering "Read More" toggle
            e.stopPropagation();
            setIsTooltipOpen(!isTooltipOpen);
          }}
        >
          <Tooltip title={ellipsesActionConfigs} open={isTooltipOpen} classes={methods.useTooltipStyles()} placement="top-end">
            <IconButton disableRipple>
              <Ellipses />
            </IconButton>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </>
  );
};

export default ReplyOptions;

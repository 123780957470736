import React from "react";
import NavLinkWrapper from "../../library/NavLink";
import "./SettingsPopUp.scss";
import { Settings } from "../../library/Icons/Icons";
import { makeStyles } from "@mui/styles";
import CustomTooltip from "../../library/CustomTooltip/CustomTooltip";
import { useTranslation } from "react-i18next";

interface SettingsPopUpInterface {
  tooltipPlacement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
}

const useStyles = makeStyles(() => ({
  tooltip: {
    borderRadius: "0.125rem",
    boxShadow: "0 0.5rem 0.75rem rgba(0, 0, 0, 0.1), 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1)",
  },
}));

const SettingsPopUp: React.FC<SettingsPopUpInterface> = ({ tooltipPlacement }: SettingsPopUpInterface) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <CustomTooltip
        placement={tooltipPlacement}
        title={t("header.tooltipMessages.inboxTabs.settings", { ns: "home" }) as string}
        classes={{ tooltip: classes.tooltip }}
        disableFocusListener
      >
        <div className="navlink-wrapper">
          <NavLinkWrapper className={"settings-btn"} exact={false} strict={false} key={"settings-nav"} to={`/settings`}>
            {(isActive: boolean) => {
              return (
                <div className={`settings-icon ${isActive ? "settings-icon-active" : ""}`}>
                  <Settings />
                </div>
              );
            }}
          </NavLinkWrapper>
        </div>
      </CustomTooltip>
    </>
  );
};
export default SettingsPopUp;

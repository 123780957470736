import APIClient from "../APIClient";
import { AxiosRequestConfig } from "axios";

const INBOX_API_BASE = `${process.env.REACT_APP_API_ENDPOINT_INBOX_API_PATH}${process.env.REACT_APP_API_ENDPOINT_INBOX_API_V2}`;
const CONFIG_OVERRIDE = {
  baseURL: process.env.REACT_APP_API_ENDPOINT_INBOX,
} as AxiosRequestConfig;

export class UserAccountsClient {
  /**
   * * GET on  /api/v2/workspaces/{workspace_id}/members
   * @param workspace_id - Workspace ID associated with the users
   * @returns Promise of type APIResponse model or Exception
   */
  getWorkspaceMembersForGroup(workspace_id: number): Promise<APIResponse> {
    const url = `${INBOX_API_BASE}/workspaces/${workspace_id}/members`;

    return APIClient.get(url, CONFIG_OVERRIDE).then((response) => {
      return response.data;
    });
  }

  /**
   * PATCH on /api/v2/user_accounts/{id}
   * @param id - Database ID associated with a user
   * @param body: The fields to change
   * @returns Promise of type UserAccountModel or Exception
   */
  patchUserAccount(id: string, body: Partial<UserAccountsModelV2>): Promise<APIResponse> {
    const url = `${INBOX_API_BASE}/user_accounts/${id}`;
    return APIClient.patch(url, body, CONFIG_OVERRIDE).then((res) => res.data);
  }

  /**
   * @function queryUserAccounts
   * A helper method to query user accounts based on various parameters.
   * @param filter
   * @param order
   * @param page_size
   * @param page
   * @returns Promise<APIResponse>
   */
  queryUserAccounts(filter?: string, include?: string, order?: string, page_size?: number, page?: number): Promise<FetchResult & APIResponse> {
    const url = `${INBOX_API_BASE}/user_accounts?filter=${filter}&order=${order}&page_size=${page_size}&page=${page}`;
    return APIClient.get(url, CONFIG_OVERRIDE).then((response) => {
      return response.data;
    });
  }

  /**
   * @function inviteUserInGroup
   * handler to send invitation & reminder for a group
   * @param emailIds list of email ids for invitations
   * @returns Promise<InviteUserAPIResponse>
   */
  async inviteUserInGroup(emailIds: string[]): Promise<InviteUserAPIResponse> {
    const url = `${INBOX_API_BASE}/user_accounts/invite`;
    const payload = {
      emails: emailIds,
    };
    const res = await APIClient.post(url, payload, CONFIG_OVERRIDE);
    return res.data;
  }

  /**
   * @function deleteUserFromGroup
   * handler to remove a member from the group
   * @param id user-id
   * @returns Promise<RemoveUserAPIResponse>
   */
  async deleteUserFromGroup(id: string): Promise<RemoveUserAPIResponse> {
    const url = `${INBOX_API_BASE}/user_accounts/${id}`;
    const res = await APIClient.delete(url, CONFIG_OVERRIDE);
    return res.data;
  }
}

import React from "react";
import { Tab, Tabs } from "@mui/material";
import useCustomTabsStyle from "./useCustomTabsStyle";

/**
 * @param tabList : List of tabs item
 * @param selectedTabValue : The value of the currently selected Tab
 * @param onChangeTabs: Callback fired when the Tab changes.
 */

export type tabListItem = {
  id: string | number;
  displayText: string;
};
type CustomTabsProps = {
  tabList: tabListItem[];
  selectedTabValue: string;
  onChangeTabs: (event: React.ChangeEvent<object>, newValue: string) => void;
};
const CustomTabs: React.FC<CustomTabsProps> = ({ tabList, selectedTabValue, onChangeTabs }) => {
  const { tabContainer, tabs } = useCustomTabsStyle();
  return (
    <div className={tabContainer}>
      <Tabs value={selectedTabValue} onChange={onChangeTabs} className={tabs}>
        {tabList?.map((item: tabListItem) => (
          <Tab key={item.id} label={item.displayText} value={item.id} />
        ))}
      </Tabs>
    </div>
  );
};

export default CustomTabs;

import React from "react";
import RelatedAttachmentComponent from "./RelatedAttachmentComponent";
import "./RelatedAttachmentsStyles.scss";
import { attachmentsClientV2 } from "../../../../../../db/version2Accessor";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../../../../../constants/NumericConstants";

interface RelatedAttachmentInterface {
  transactionId: string;
  transactionType: string;
}

const SORTMENU = [
  {
    label: "Sort By Name",
    ransackQuery: "file_name asc",
    sortFunction: (a: AttachmentItem, b: AttachmentItem) => {
      return (a.file_name ?? "").localeCompare(b.file_name ?? "") || a.extension.localeCompare(b.extension);
    },
  },
  {
    label: "Sort By Type",
    ransackQuery: "file_type asc",
    sortFunction: (a: AttachmentItem, b: AttachmentItem) => {
      return a.extension.localeCompare(b.extension) || (a.file_name ?? "").localeCompare(b.file_name ?? "");
    },
  },
  {
    label: "Sort By Date",
    ransackQuery: "created_at asc",
    sortFunction: (a: AttachmentItem, b: AttachmentItem) => {
      return a.created_at.toString().localeCompare(b.created_at.toString()) || (a.file_name ?? "").localeCompare(b.file_name ?? "");
    },
  },
];

const RelatedAttachmentsContainer: React.FC<RelatedAttachmentInterface> = ({ transactionId, transactionType }: RelatedAttachmentInterface) => {
  const [attachments, setAttachments] = React.useState<Array<AttachmentItem>>([]);
  const [pageDetails, setPageDetails] = React.useState({
    total_records: 0,
    page: 0,
    page_size: 20,
  });
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = React.useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [sortMenuAnchorEl, setSortMenuAnchorEl] = React.useState<HTMLDivElement | null>(null);
  let initialLoad = false;
  const fetchAttachments = () => {
    initialLoad && setIsLoading(true);
    attachmentsClientV2
      .getAttachmentsForTransactions(
        "transaction",
        transactionId,
        transactionType,
        SORTMENU[selectedIndex].ransackQuery,
        initialLoad ? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO : pageDetails.page + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
        pageDetails.page_size
      )
      .then((response) => {
        if (response.success) {
          initialLoad ? setAttachments(response.data) : setAttachments([...attachments, ...response.data]);
          setPageDetails({
            total_records: response.total_records ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            page: response.page ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            page_size: response.page_size ?? NUMERIC_VALUES.CONSTANT_TEN,
          });
          initialLoad = false;
        }
      })
      .catch((err) => {
        console.log("Error Loading Attachments:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const downloadHandler = (fileUrl: string) => {
    window.open(fileUrl, "_blank");
  };

  const resetData = () => {
    setAttachments([]);
    setPageDetails({
      total_records: 0,
      page: 0,
      page_size: 20,
    });
    initialLoad = true;
  };

  React.useEffect(() => {
    resetData();
    fetchAttachments();
  }, [selectedIndex]);

  return (
    <RelatedAttachmentComponent
      attachments={attachments}
      pageDetails={pageDetails}
      fetchAttachments={fetchAttachments}
      isLoading={isLoading}
      downloadHandler={downloadHandler}
      selectedIndex={selectedIndex}
      setSelectedIndex={setSelectedIndex}
      setSortMenuAnchorEl={setSortMenuAnchorEl}
      sortMenuAnchorEl={sortMenuAnchorEl}
      sortMenu={SORTMENU}
    />
  );
};

export default RelatedAttachmentsContainer;

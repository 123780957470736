import { makeStyles } from "@mui/styles";
import styles from "../../../css/_export.module.scss";

const useCustomTabsStyle = () => {
  const useStyles = makeStyles({
    tabContainer: {
      height: "3rem",
      padding: `${styles.small} ${styles.base}`,
      "& .MuiTabs-root": {
        height: "2rem",
        minHeight: "2rem",
      },
    },
    tabs: {
      "& .MuiTabs-flexContainer": {
        height: "2rem",
        minHeight: "2rem",
        gap: styles.small,
        "& .MuiTab-textColorPrimary": {
          color: "#4D4D4D",
        },
        "& .MuiButtonBase-root.MuiTab-root": {
          minWidth: "2rem",
          minHeight: "2rem",
          fontFamily: styles.primaryFontFamily,
          fontWeight: "500",
          fontSize: styles.fontSizeXS,
          textTransform: "capitalize",
          lineHeight: styles.lineHeightSM,
          padding: styles.small,
        },
        "& .Mui-selected": {
          background: "#4D4D4D",
          borderRadius: styles.small,
          color: `${styles.white100}`,
          padding: styles.small,
          "&:hover": {
            background: "#4D4D4D",
            borderRadius: styles.small,
            color: `${styles.white100}`,
          },
        },
        "& .MuiTab-root": {
          "&:hover": {
            background: styles.grey94,
            borderRadius: styles.small,
            color: styles.primary,
          },
        },
      },
      "& .MuiTabs-indicator": {
        display: "none",
      },
    },
  });
  return useStyles();
};

export default useCustomTabsStyle;

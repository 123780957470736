import React from "react";
import { OnboardingRequestedProps } from "../../ActivityItemVariantTypes";
import "./OnboardingRequested.scss";
import SecondaryActivity from "../../../SecondaryActivity/SecondaryActivity";
import { SecondaryType } from "../../../SecondaryActivity/SecondaryActivityTypes";
import { DATE_CONSTANTS_IN_MIN, NUMERIC_VALUES, DEFAULT_NUMERIC_VALUES } from "../../../../../../../../constants/NumericConstants";
import useLocale from "../../../../../../../../hooks/useLocale";
import { useTranslation } from "react-i18next";
// import Button from "../../../../../../library/Button/Button";
// import * as Labels from "../../../../../../../constants/config";

const OnboardingRequested: React.FC<OnboardingRequestedProps> = ({
  creator,
  mailList,
  isShowPrimaryOnboardRequested,
  countryCode,
  // readonly,
  // onClickResendOnboardingRequest,
}) => {
  const { t } = useTranslation();
  const creatorNew = { ...creator, message: t("activityDetails.feedItems.onboardingRequested.headerMessage", { ns: "activities" }) };
  const isAfter24hrs = creator.createdOn
    ? (new Date().getTime() - new Date(creator.createdOn).getTime()) / DATE_CONSTANTS_IN_MIN.HRS_IN_MILIS > NUMERIC_VALUES.CONSTANT_TWENTY_FOUR
    : false;
  const { formatDateBasedOnCountry: formatDate } = useLocale();

  const ReceiversList: string = React.useMemo(() => {
    let stringToRet = "";
    if (mailList.to) {
      mailList.to?.forEach((contact, index) => {
        if (index === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) stringToRet += `To ${contact?.name ?? contact?.email_address ?? "N/A"}`;
        else stringToRet += `,${contact?.name ?? contact?.email_address ?? "N/A"}`;
      });
    }
    if (mailList.cc) {
      mailList.cc?.forEach((contact, index) => {
        if (index === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO)
          stringToRet += `${stringToRet.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && ","} Cc ${contact?.name ?? contact?.email_address ?? "N/A"}`;
        else stringToRet += `,${contact?.name ?? contact?.email_address ?? "N/A"}`;
      });
    }
    if (mailList.bcc) {
      mailList.bcc?.forEach((contact, index) => {
        if (index === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO)
          stringToRet += `${stringToRet.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && ","} Bcc ${contact?.name ?? contact?.email_address ?? "N/A"}`;
        else stringToRet += `,${contact?.name ?? contact?.email_address ?? "N/A"}`;
      });
    }

    return stringToRet;
  }, [mailList]);

  /* 
  * REASON: commented until /resend api for onboard vendor request
  * which is planned for Profile Management (MVP + 1)
  *
  const actions = !readonly
    ? [
        <Button size="sm" variant="secondary" key="resend" onClick={onClickResendOnboardingRequest}>
          {Labels.BTN_RESEND}
        </Button>,
      ]
    : []; 
  */

  return (
    <>
      {!isAfter24hrs && isShowPrimaryOnboardRequested && (
        <div className="boarding-initiated activity-feed-item-card">
          <div className="boarding-initiated-icon">🙌</div>
          <div className="boarding-initiated-body">
            <h3 className="boarding-initiated-body-header">Great work!</h3>
            <p className="boarding-initiated-body-text">
              {t("activityDetails.feedItems.onboardingRequested.timeSavingMessage", { date: formatDate(creator.createdOn), ns: "activities" })}
            </p>
          </div>
        </div>
      )}
      <SecondaryActivity
        variant={SecondaryType.DetailedWithAction}
        creator={creatorNew}
        flags={{ TIME_SAVING_INDICATOR: true }}
        countryCode={countryCode} /* headerActions={actions} */
      >
        <p className="receiver-list">{ReceiversList}</p>
      </SecondaryActivity>
    </>
  );
};

export default OnboardingRequested;

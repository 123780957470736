import React from "react";
import { FilterIconContainer, FilterIconContainerActive } from "../../../styled/table.filter.styled";
import { ReactComponent as FilterIcon } from "../../../../svg/filter_icon.svg";
import DateFilterMenuList from "./DateFilterMenuList";
import CustomDateRangePicker from "./CustomDateRangePicker";
import { DateFilterComponentProps } from "../../preset.filter.types";
import { TableFilterComparatorConstant } from "../../../../constants/table.constants";
import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";

const DateFilterComponent: React.FC<DateFilterComponentProps> = ({
  filterValue,
  filterActive,
  menuList,
  onDateFilterListOptionChange,
  isOpenCustomDateRangePicker,
  closeDateRangeCalendarPicker,
  onClickApplyDate,
  dateFormat,
  filterAlignment,
  filterRangeValue,
}) => {
  const IconWrapper = filterActive ? FilterIconContainerActive : FilterIconContainer;
  const [dateFilterAnchorEl, setDateFilterAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpenDateFilterList = Boolean(dateFilterAnchorEl);

  const onClickDateFilter = (event: React.MouseEvent<HTMLElement>) => setDateFilterAnchorEl(event.currentTarget);

  const onCloseDateRangePicker = () => {
    closeDateRangeCalendarPicker();
    setDateFilterAnchorEl(null);
  };

  const onClickMenuListItem = (item: string) => {
    if (!item?.includes(TableFilterComparatorConstant.CUSTOM_DATE)) {
      setDateFilterAnchorEl(null);
    }
    onDateFilterListOptionChange(item);
  };

  const onClickApplyDateButton = (selectedDateRange: DateRange<dayjs.Dayjs>) => {
    onClickApplyDate(selectedDateRange);
    setDateFilterAnchorEl(null);
  };

  const onCloseMenuList = () => {
    setDateFilterAnchorEl(null);
  };
  return (
    <div>
      <IconWrapper onClick={onClickDateFilter}>
        <FilterIcon />
      </IconWrapper>
      <DateFilterMenuList
        isOpenDateFilteMenu={isOpenDateFilterList && !isOpenCustomDateRangePicker}
        onCloseMenuList={onCloseMenuList}
        anchorEl={dateFilterAnchorEl}
        menuList={menuList}
        onClickMenuListItem={onClickMenuListItem}
        selectedItemIndex={menuList?.findIndex((menuItem) => menuItem.key === filterValue)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: filterAlignment,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: filterAlignment,
        }}
      />
      <CustomDateRangePicker
        isOpenDateRangePopup={isOpenCustomDateRangePicker}
        onCloseDateRangePicker={onCloseDateRangePicker}
        anchorEl={dateFilterAnchorEl}
        onClickApplyDate={onClickApplyDateButton}
        onClickCancel={onCloseDateRangePicker}
        dateFormat={dateFormat}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: filterAlignment,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: filterAlignment,
        }}
        appliedDateRangeValue={filterRangeValue}
      />
    </div>
  );
};

export default DateFilterComponent;

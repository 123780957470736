import APIClient from "../APIClient";
import { AxiosRequestConfig } from "axios";

const INBOX_API_BASE = `${process.env.REACT_APP_API_ENDPOINT_INBOX_API_PATH}${process.env.REACT_APP_API_ENDPOINT_INBOX_API_V2}`;
const CONFIG_OVERRIDE = {
  baseURL: process.env.REACT_APP_API_ENDPOINT_INBOX,
} as AxiosRequestConfig;

export class StatusClient {
  /**
   * * GET on /api/v2/Status
   * @returns Promise of type StatusModel or Exception
   */
  getStatusV2(fetchPlatform = false): Promise<APIResponse> {
    const url = `${INBOX_API_BASE}/status`;

    return APIClient.get(url, CONFIG_OVERRIDE, false, false, false, fetchPlatform).then((response) => {
      return response.data;
    });
  }
}

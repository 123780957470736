import React, { MouseEvent, useEffect, useState } from "react";
import _ from "underscore";
import { Divider, Input, InputAdornment } from "@mui/material";
import { useIntercom } from "react-use-intercom";
import { AppContext } from "../../../contexts/AppContext";
import {
  SageNetworkInbox,
  SageNetworkLogo,
  SageNetworkSearchIcon,
  SageNetworkNavigationUpIcon,
  SageNetworkNavigationDownIcon,
} from "../../library/Icons/Icons";
import Menu from "../../library/Menu/Menu";
import MenuItem from "../../library/MenuItem/MenuItem";
import Userpic from "../../library/Sidebar/Userpic/Userpic";
import { ProfileContext } from "../../../contexts/ProfileContext";
import GlobalSearchDialogContainer from "../../AdvanceGlobalSearch/GlobalSearchDialog/GlobalSearchDialogContainer";
import { FeatureFlagContext, FeatureFlagProviderType } from "../../../contexts/FeatureFlagContext";
import { useTranslation } from "react-i18next";
import { userAccountsClient } from "./../../../db/accessor";
import "./EInvoiceHeader.scss";

const EInvoiceHeader = (): React.ReactElement => {
  const { initialLoad, userStatus, logout } = React.useContext(AppContext) as AppType;
  const { imageUrl, setImageUrl } = React.useContext(ProfileContext) as ProfileType;
  const [isOpenGlobalSearch, setIsOpenGlobalSearch] = React.useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLDivElement | null>(null);
  const menuIsOpen = Boolean(menuAnchorEl);
  const { shutdown } = useIntercom();
  const { isEnabled, featureFlagsLoading } = React.useContext(FeatureFlagContext) as FeatureFlagProviderType;
  const searchDivRef = React.useRef<HTMLDivElement>(null);
  const isGloabalSearchV2Enable = isEnabled("GLOBAL_SEARCH_V2");
  const { t } = useTranslation();

  const onGlobalSearchClose = () => setIsOpenGlobalSearch(false);

  /**
   * @function setupUserProfileData
   * A helper function to fetch user image information
   */
  const setupUserProfileData = (userStatus: StatusModelV2) => {
    if (!initialLoad) {
      userAccountsClient.getUserAccount(userStatus?.user_id ?? "", "AccountingRole").then((userStatus: UserAccountModel) => {
        setImageUrl(userStatus?.imageURL ?? imageUrl);
      });
    }
  };

  useEffect(() => {
    if (!_.isUndefined(userStatus) || !_.isEmpty(userStatus)) {
      setupUserProfileData(userStatus);
    }
  }, [JSON.stringify(userStatus)]);
  return (
    <div className="e-invoice-header-container">
      <div className="menu-left-container">
        <SageNetworkLogo />
        {isGloabalSearchV2Enable && !featureFlagsLoading && (
          <>
            {isOpenGlobalSearch && (
              <GlobalSearchDialogContainer openDialog={isOpenGlobalSearch} onCloseGlobalSearch={onGlobalSearchClose} context={"e-invoice"} />
            )}
            <Input
              className="menu-search-input"
              onClick={() => {
                setIsOpenGlobalSearch((prevState: boolean) => !prevState);
              }}
              ref={searchDivRef}
              startAdornment={
                <InputAdornment position="start">
                  <SageNetworkSearchIcon />
                </InputAdornment>
              }
              disabled={false}
              disableUnderline={true}
              placeholder={t("header.searchPlaceholder", { ns: "home" }) as string}
            />
            <Divider orientation="vertical" className="header-divider" />
          </>
        )}
      </div>
      <div className="menu-right-container">
        <div className="menu-options-container">
          <div className="option active">
            <div className="option-icon">
              <SageNetworkInbox />
            </div>
            <div className="option-text">{t("header.menuOptions.inbox", { ns: "home" })}</div>
          </div>
          <div className="option" onClick={(e: MouseEvent<HTMLDivElement>) => setMenuAnchorEl(e.currentTarget)}>
            <div className="option-icon">
              <Userpic id="logout-btn" imageUrl={imageUrl ?? ""} />
            </div>
            <div className="option-text">
              {t("header.menuOptions.me", { ns: "home" })} {menuIsOpen ? <SageNetworkNavigationUpIcon /> : <SageNetworkNavigationDownIcon />}
            </div>
          </div>
          <Menu
            open={menuIsOpen}
            anchorEl={menuAnchorEl}
            onClose={() => setMenuAnchorEl(null)}
            anchorOrigin={{
              vertical: 60,
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: 22,
            }}
          >
            <MenuItem
              onClick={() => {
                shutdown();
                logout();
              }}
            >
              {t("header.tooltipMessages.inboxTabs.signOut", { ns: "home" })}
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default EInvoiceHeader;

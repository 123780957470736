export const CONNECTION_STATUS = Object.freeze({
  CONTINUE_100: { STATUS_CODE: 100, STATUS_LABEL: "CONTINUE" },
  OK_200: { STATUS_CODE: 200, STATUS_LABEL: "OK" },
  ACCEPTED_202: { STATUS_CODE: 202, STATUS_LABEL: "ACCEPTED" },
  MOVED_PERMANENTLY_301: { STATUS_CODE: 301, STATUS_LABEL: "MOVED PERMANENTLY" },
  BAD_REQUEST_400: { STATUS_CODE: 400, STATUS_LABEL: "BAD REQUEST" },
  UNAUTHORIZED_401: { STATUS_CODE: 401, STATUS_LABEL: "UNAUTHORIZED" },
  FORBIDDEN_403: { STATUS_CODE: 403, STATUS_LABEL: "FORBIDDEN" },
  NOT_FOUND_404: { STATUS_CODE: 404, STATUS_LABEL: "NOT FOUND" },
  REQUEST_TIMEOUT_408: { STATUS_CODE: 408, STATUS_LABEL: "NOT FOUND" },
  LINK_EXPIRED_422: { STATUS_CODE: 422, STATUS_LABEL: "LINK EXPIRED" },
  INTERNAL_SERVER_ERROR_500: { STATUS_CODE: 500, STATUS_LABEL: "INTERNAL SERVER ERROR" },
  SERVICE_UNAVAILABLE_503: { STATUS_CODE: 503, STATUS_LABEL: "SERVICE UNAVAILABLE" },
  ACCEPTED_204: { STATUS_CODE: 204, STATUS_LABEL: "NO CONTENT" },
});

export interface ActivityCreator {
  name: string | null;
  message: string | null;
  createdOn: string;
}
export enum SecondaryType {
  Base,
  Accordion,
}

export type SecondaryActionFlags = {
  flags?: {
    TIME_SAVING_INDICATOR?: boolean;
  };
};

export type SecondaryActivityProps = {
  variant: SecondaryType;
  creator: ActivityCreator;
  readonly?: boolean;
  children?: React.ReactNode;
  headerActions?: JSX.Element[];
  countryCode?: string;
} & SecondaryActionFlags;

export type AccordionActivityProps = Omit<SecondaryActivityProps, "variant">;
export type BaseActivityProps = Omit<SecondaryActivityProps, "variant" | "children">;

import React from "react";
import { TooltipTitles, TooltipTypes } from "../../../constants/TooltipConstants";
import CustomTooltip from "../../library/CustomTooltip/CustomTooltip";
import {
  FileAttachment,
  VideoAttachment,
  DocAttachment,
  OtherAttachment,
  CSVAttachment,
  PDFAttachment,
  ImageAttachment,
} from "../../library/Icons/Icons";
import "./AttachmentChip.scss";

const AttachmentChip: React.FC<{
  attachment: AttachmentItem;
  readonly?: boolean;
  onClickPreviewOpen: (event: React.MouseEvent<HTMLDivElement>, activeAttachmentID: string | number) => void;
}> = ({ attachment, readonly = false, onClickPreviewOpen }) => {
  const getAttachmentIcon = (extension: string) => {
    switch (extension) {
      case "pdf":
        return <PDFAttachment />;
      case "doc":
        return <DocAttachment />;
      case "zip":
        return <FileAttachment />;
      case "csv":
      case "xlss":
        return <CSVAttachment />;
      case "mp4":
      case "mov":
      case "wmv":
      case "avi":
      case "avchd":
      case "flv":
      case "f4v":
      case "swf":
      case "mkv":
      case "webm":
      case "html5":
      case "mpeg2":
      case "gif":
        return <VideoAttachment />;
      case "jpg":
      case "jpeg":
      case "png":
      case "tiff":
      case "psd":
      case "eps":
      case "ai":
      case "indd":
      case "raw":
        return <ImageAttachment />;
      default:
        return <OtherAttachment />;
    }
  };

  return (
    <CustomTooltip type={TooltipTypes.PLAIN} title={TooltipTitles.CLICK_TO_PREVIEW}>
      <div
        className={`attachment-chip ${readonly ? "read-only" : ""}`}
        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
          event.stopPropagation();
          !readonly && onClickPreviewOpen(event, attachment.id);
        }}
      >
        <>
          {getAttachmentIcon(attachment.extension)} <span className="attachment-text">{attachment.file_name}</span>
        </>
      </div>
    </CustomTooltip>
  );
};

export default AttachmentChip;

import React, { useState, useEffect } from "react";
import { ClickAwayListener, Popper, Typography } from "@mui/material";
import { DEFAULT_NUMERIC_VALUES, TRIM_COUNT_CONSTANTS } from "../../../constants/NumericConstants";
import _ from "underscore";
import "./ContactPopover.scss";
import { AlertContext } from "../../../contexts/AlertContext";
import { useTranslation } from "react-i18next";
import ContactPopoverCard from "../ContactPopoverCard/ContactPopoverCard";
import ContactsPopoverShowMore from "../ContactsPopoverShowMore/ContactsPopoverShowMore";

const ContactPopover: React.FC<{
  to: To[] | ContactItem[];
  label: string;
  currentPopper: (label: string) => void;
  clearPopper: boolean;
  readonly: boolean | undefined;
  selectedWorkspace: UserItem;
}> = ({ to, label, currentPopper, clearPopper = false, readonly = false }) => {
  const { t } = useTranslation();
  const [showContactPopup, setShowContactPopup] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedContact, setSelectedContact] = useState<ContactItem>({});
  const [showMorePopUp, setShowMorePopUp] = useState<boolean>(false);
  const [activeTriggerIndex, setActiveTriggerIndex] = useState<number | null>(null);
  const [showContactPopupInShowMore, setShowContactPopupInShowMore] = React.useState<boolean>(false);
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const [isShowMoreChipActive, setIsShowMoreChipActive] = React.useState<boolean>(false);

  /**
   * @function onCopyToClipBoard
   * A helper function to store a string to the clipboard.
   * @param string - the string to store
   */
  const onCopyToClipBoard = (string?: string, type?: string) => {
    if (string) {
      navigator.clipboard.writeText(string ?? "N/A");
      let toastOptions = {} as ToastOptions;
      switch (type) {
        case "phone":
          toastOptions = {
            open: true,
            severity: "success",
            message: t("activityDetails.feedItems.contactAndSenderPopover.toastMessages.copyPhone", { ns: "activities" }),
          };
          setToastOptions(toastOptions);
          break;

        case "email":
          toastOptions = {
            open: true,
            severity: "success",
            message: t("activityDetails.feedItems.contactAndSenderPopover.toastMessages.copyEmail", { ns: "activities" }),
          };
          setToastOptions(toastOptions);
          break;

        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (clearPopper) {
      setShowContactPopup(false);
      setActiveTriggerIndex(null);
      setSelectedContact({});
      setAnchorEl(null);
    }
  }, [clearPopper]);

  return (
    <>
      {/* The following section contains a duplicated code, this is kept as such for properly rendering the popover */}
      <p className="subheader caption v2">
        {_.size(to) > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
          ? _.size(to) <= TRIM_COUNT_CONSTANTS.SHOW_EMAIL
            ? to.map((contact: any, index: number) => {
                return (
                  <span
                    key={index}
                    className={`contact-popup-trigger ${activeTriggerIndex === index && !readonly ? "active" : ""}`}
                    onClick={(event: React.MouseEvent<HTMLSpanElement>) => {
                      event.stopPropagation();
                      if (!readonly) {
                        currentPopper(label);
                        setShowContactPopup(!showContactPopup);
                        setAnchorEl(event.currentTarget as HTMLElement);
                        setSelectedContact(contact);
                        setActiveTriggerIndex((prevIndex) => (prevIndex !== null ? null : index));
                        setShowMorePopUp(false);
                        setShowContactPopupInShowMore(false);
                        setIsShowMoreChipActive(false);
                      }
                    }}
                  >
                    <Typography variant="captionReg">{contact?.name || contact?.email_address}</Typography>
                  </span>
                );
              })
            : to.slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, TRIM_COUNT_CONSTANTS.CONTACTS_TRIM).map((contact: any, index: number) => {
                return (
                  <span
                    key={index}
                    className={`contact-popup-trigger ${activeTriggerIndex === index && !readonly ? "active" : ""}`}
                    onClick={(event: React.MouseEvent<HTMLSpanElement>) => {
                      event.stopPropagation();
                      if (!readonly) {
                        currentPopper(label);
                        setShowContactPopup(!showContactPopup);
                        setAnchorEl(event.currentTarget as HTMLElement);
                        setSelectedContact(contact);
                        setActiveTriggerIndex((prevIndex) => (prevIndex !== null ? null : index));
                        setShowMorePopUp(false);
                        setShowContactPopupInShowMore(false);
                        setIsShowMoreChipActive(false);
                      }
                    }}
                  >
                    <Typography variant="captionReg">{contact?.name || contact?.email_address}</Typography>
                  </span>
                );
              })
          : ""}
        {_.size(to) > TRIM_COUNT_CONSTANTS.SHOW_EMAIL && (
          <>
            <span
              className={`contact-popup-trigger ${activeTriggerIndex === TRIM_COUNT_CONSTANTS.CONTACTS_TRIM && !readonly ? "active" : ""}`}
              onClick={(event: React.MouseEvent<HTMLSpanElement>) => {
                event.stopPropagation();
                if (!readonly) {
                  currentPopper(label);
                  setShowContactPopup(!showContactPopup);
                  setAnchorEl(event.currentTarget as HTMLElement);
                  setSelectedContact(to[TRIM_COUNT_CONSTANTS.CONTACTS_TRIM] as ContactItem);
                  setActiveTriggerIndex((prevIndex) => (prevIndex !== null ? null : TRIM_COUNT_CONSTANTS.CONTACTS_TRIM));
                  setShowMorePopUp(false);
                  setShowContactPopupInShowMore(false);
                  setIsShowMoreChipActive(false);
                }
              }}
            >
              <Typography variant="captionReg">
                {(to[TRIM_COUNT_CONSTANTS.CONTACTS_TRIM] as ContactItem)?.name ||
                  (to[TRIM_COUNT_CONSTANTS.CONTACTS_TRIM] as ContactItem)?.email_address}
              </Typography>
            </span>
            <span
              className={`more-contacts-trigger ${isShowMoreChipActive ? "active" : ""}`}
              onClick={(event: React.MouseEvent<HTMLSpanElement>) => {
                event.stopPropagation();
                if (!readonly) {
                  currentPopper(label);
                  setShowMorePopUp(!showMorePopUp);
                  setShowContactPopupInShowMore(false);
                  setShowContactPopup(false);
                  setAnchorEl(event.currentTarget as HTMLElement);
                  setSelectedContact(to[TRIM_COUNT_CONSTANTS.SHOW_EMAIL] as ContactItem);
                  setIsShowMoreChipActive(!showMorePopUp);
                  setActiveTriggerIndex(null);
                }
              }}
            >
              <Typography variant="body1Bold">+ {to?.length - TRIM_COUNT_CONSTANTS.SHOW_EMAIL || ""} others</Typography>
            </span>
          </>
        )}
      </p>
      <ClickAwayListener
        onClickAway={() => {
          setShowContactPopup(false);
          setActiveTriggerIndex(null);
          setSelectedContact({});
        }}
      >
        <Popper
          open={showContactPopup}
          disablePortal={false}
          anchorEl={anchorEl}
          placement={"bottom-start"}
          className="contact-popover-popper"
          modifiers={[
            {
              name: "flip",
              enabled: true,
              options: {
                altBoundary: true,
                rootBoundary: "document",
                padding: 8,
              },
            },
          ]}
        >
          <ContactPopoverCard selectedContact={selectedContact} onCopyToClipBoard={onCopyToClipBoard} />
        </Popper>
      </ClickAwayListener>
      <ContactsPopoverShowMore
        showPopover={showMorePopUp}
        showContactPopup={showContactPopupInShowMore}
        setShowContactPopup={setShowContactPopupInShowMore}
        setShowPopover={setShowMorePopUp}
        anchorEl={anchorEl}
        to={to.slice(TRIM_COUNT_CONSTANTS.SHOW_EMAIL, to.length)}
        setIsShowMoreChipActive={setIsShowMoreChipActive}
      />
    </>
  );
};
export default ContactPopover;

import React, { useState, useEffect } from "react";
import { ClickAwayListener, Popper, Skeleton } from "@mui/material";
import { Phone, Email, FlowChart, Copy as CopySVG } from "../Icons/Icons";
import { NUMERIC_VALUES } from "../../../constants/NumericConstants";
import { activitiesClientV2 } from "../../../db/version2Accessor";
import { FallbackTypes, WorkspaceType } from "../../../types/enums";
import { CONTACT_POPOVER_EMPTY_ITEM } from "../../../constants/config";
import { ApplicationRouteContext } from "../../../contexts/ApplicationRouteContext";
import { useHistory } from "react-router";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";
import { ComponentConfigContext } from "../../../contexts/ComponentConfigContext";
import { AlertContext } from "../../../contexts/AlertContext";
import { SmartText } from "../SmartText";
import "./ContactPopover.scss";
import { useTranslation } from "react-i18next";
import { ReactComponent as UserDefaultAvatar } from "../../../assets/UserDefaultAvatar.svg";

const SenderPopover: React.FC<{
  avatar?: boolean;
  contact: ContactItem | undefined | null;
  label: string;
  currentPopper: (label: string) => void;
  clearPopper: boolean;
  readonly: boolean | undefined;
  selectedWorkspace: UserItem;
  enableHighlighter?: boolean;
  matchText?: string;
}> = ({
  avatar = false,
  contact,
  label,
  currentPopper,
  clearPopper = false,
  readonly = false,
  selectedWorkspace,
  enableHighlighter = false,
  matchText = "~",
}) => {
  const { t } = useTranslation();
  const [showContactPopup, setShowContactPopup] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openActivitiesCount, setOpenActivitiesCount] = useState<number>(NUMERIC_VALUES.CONSTANT_ZERO);
  const [loadingCounts, setLoadingCounts] = useState<boolean>(false);
  const workspaceContext = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const componentConfigContext = React.useContext(ComponentConfigContext) as ComponentConfigType;
  const componentConfig = componentConfigContext?.getConfig(selectedWorkspace.workspace_type_route?.toLocaleLowerCase() ?? WorkspaceType.AR) as any;
  const applicationRouteContext = React.useContext(ApplicationRouteContext) as ApplicationRouteType;

  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;

  const history = useHistory();
  /**
   * @function onCopyToClipBoard
   * A helper function to store a string to the clipboard.
   * @param string - the string to store
   */
  const onCopyToClipBoard = (string?: string, type?: string) => {
    if (string) {
      navigator.clipboard.writeText(string ?? "N/A");
      let toastOptions = {} as ToastOptions;
      switch (type) {
        case "phone":
          toastOptions = {
            open: true,
            severity: "success",
            message: t("activityDetails.feedItems.contactAndSenderPopover.toastMessages.copyPhone", { ns: "activities" }),
          };
          setToastOptions(toastOptions);
          break;

        case "email":
          toastOptions = {
            open: true,
            severity: "success",
            message: t("activityDetails.feedItems.contactAndSenderPopover.toastMessages.copyEmail", { ns: "activities" }),
          };
          setToastOptions(toastOptions);
          break;

        default:
          break;
      }
    }
  };

  const redirectOnCompanyClick = () => {
    let base = "";
    if (contact?.company_id) {
      const connectionRoute = contact?.app_enrollment_id ? componentConfig.connections.routeType : componentConfig.otherConnections.routeType;
      const backRoute = contact?.type === "Workspace" ? componentConfig.myCompany.routeType : connectionRoute;
      base = `${workspaceContext?.workspaceHomePath}/${backRoute}/active/${contact?.company_id}`;
    } else {
      base = `${workspaceContext?.workspaceHomePath}/${componentConfig.myCompany?.routeType}/${componentConfig.myCompany?.routes?.main[0]?.routeName}`;
    }
    applicationRouteContext?.updateBaseRoute(base);
    history.push(base);
  };

  /**
   * @function getOpenActivitiesCountForContact
   * A helper function to get the number of open activities for a contact
   */
  const getOpenActivitiesCountForContact = async () => {
    setLoadingCounts(true);
    await activitiesClientV2
      .fetchTableRecords(
        "all",
        selectedWorkspace?.id || FallbackTypes.Id,
        "active",
        NUMERIC_VALUES.CONSTANT_ONE,
        NUMERIC_VALUES.CONSTANT_TWENTY_FOUR,
        "",
        "",
        [],
        true,
        "&qa[assignee_email_eq]=" + contact?.email_address
      )
      .then((response) => {
        setOpenActivitiesCount(response.total_records);
      })
      .finally(() => {
        setLoadingCounts(false);
      });
  };

  useEffect(() => {
    if (contact?.email_address && !readonly) {
      getOpenActivitiesCountForContact();
    }
  }, [contact]);

  useEffect(() => {
    if (clearPopper) {
      setShowContactPopup(false);
      setAnchorEl(null);
    }
  }, [clearPopper]);

  return (
    <>
      {avatar ? (
        <div
          onClick={(event: React.MouseEvent<HTMLSpanElement>) => {
            event.stopPropagation();
            if (!readonly) {
              currentPopper(label);
              setShowContactPopup(!showContactPopup);
              setAnchorEl(event.currentTarget as HTMLElement);
            }
          }}
          className="icon contact-avatar"
        >
          <UserDefaultAvatar />
        </div>
      ) : (
        <span
          className="activity-creator"
          onClick={(event: React.MouseEvent<HTMLSpanElement>) => {
            event.stopPropagation();
            if (!readonly) {
              currentPopper(label);
              setShowContactPopup(!showContactPopup);
              setAnchorEl(event.currentTarget as HTMLElement);
            }
          }}
        >
          <SmartText
            title={contact?.name ?? CONTACT_POPOVER_EMPTY_ITEM}
            highlighter={{
              enableHighlighter,
              matchText,
            }}
          />
          &nbsp;
        </span>
      )}
      <ClickAwayListener
        onClickAway={() => {
          setShowContactPopup(false);
        }}
      >
        <Popper
          open={showContactPopup}
          disablePortal={false}
          anchorEl={anchorEl}
          placement={"bottom-start"}
          className="contact-popover-popper"
          modifiers={[
            {
              name: "flip",
              enabled: true,
              options: {
                altBoundary: true,
                rootBoundary: "document",
                padding: 8,
              },
            },
          ]}
        >
          <div
            className="contact-card-container"
            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
              event.stopPropagation();
            }}
          >
            <>
              <div
                className="contact-profile-row"
                onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                  event.stopPropagation();
                }}
              >
                <div className="contact-avatar-container">
                  {contact?.name
                    ?.match(/(\b\S)?/g)
                    ?.join("")
                    ?.match(/(^\S|\S$)?/g)
                    ?.join("")
                    .toUpperCase()}
                </div>
                <div className="contact-profile-container">
                  <span className="contact-name">{contact?.name ?? CONTACT_POPOVER_EMPTY_ITEM}</span>
                  {contact?.title && contact?.type !== "Workspace" && (
                    <span className="contact-role">{contact?.title ?? CONTACT_POPOVER_EMPTY_ITEM}</span>
                  )}
                  {contact?.type !== "Workspace" && (
                    <div
                      className="contact-connection-container"
                      onClick={() => {
                        !readonly && redirectOnCompanyClick();
                      }}
                    >
                      <div className={`tag`}>
                        {contact?.company_name ? contact?.company_name?.charAt(NUMERIC_VALUES.CONSTANT_ZERO)?.toUpperCase() : ""}
                      </div>
                      <span className="connection-name">{contact?.company_name ?? CONTACT_POPOVER_EMPTY_ITEM}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className={`contact-contacts-row ${openActivitiesCount ? "" : "hide-border"}`}>
                <div
                  className={`contact-phone ${contact?.phone ? "show-hover" : ""}`}
                  onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                    event.stopPropagation();
                    contact?.phone && onCopyToClipBoard(contact?.phone, "phone");
                  }}
                >
                  <Phone />
                  <span>{contact?.phone ?? CONTACT_POPOVER_EMPTY_ITEM}</span>
                  {contact?.phone && (
                    <div>
                      <CopySVG
                        onClick={(event: React.MouseEvent<SVGSVGElement>) => {
                          event.stopPropagation();
                          onCopyToClipBoard(contact?.phone, "phone");
                        }}
                      />
                    </div>
                  )}
                </div>
                <div
                  className={`contact-email ${contact?.email_address ? "show-hover" : ""}`}
                  onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                    event.stopPropagation();
                    onCopyToClipBoard(contact?.email_address, "email");
                  }}
                >
                  <Email />
                  <span>{contact?.email_address ?? CONTACT_POPOVER_EMPTY_ITEM}</span>
                  {contact?.email_address && (
                    <div>
                      <CopySVG
                        onClick={(event: React.MouseEvent<SVGSVGElement>) => {
                          event.stopPropagation();
                          onCopyToClipBoard(contact?.email_address, "email");
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              {!readonly && openActivitiesCount > NUMERIC_VALUES.CONSTANT_ZERO && (
                <div
                  className="contact-activities-count-row"
                  onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                    event.stopPropagation();
                  }}
                >
                  <FlowChart />
                  {loadingCounts ? (
                    <Skeleton variant="text" width={NUMERIC_VALUES.CONSTANT_HUNDRED} height={NUMERIC_VALUES.CONSTANT_TWENTY} animation="wave" />
                  ) : (
                    <span>
                      {openActivitiesCount > NUMERIC_VALUES.CONSTANT_ZERO
                        ? openActivitiesCount === NUMERIC_VALUES.CONSTANT_ONE
                          ? t("activityDetails.feedItems.contactAndSenderPopover.activitesStatusMessage.singleOpenActivites", {
                              openActivitiesCount,
                              ns: "activities",
                            })
                          : t("activityDetails.feedItems.contactAndSenderPopover.activitesStatusMessage.multiOpenActivites", {
                              openActivitiesCount,
                              ns: "activities",
                            })
                        : t("activityDetails.feedItems.contactAndSenderPopover.activitesStatusMessage.noActiviesOpen", { ns: "activities" })}
                    </span>
                  )}
                </div>
              )}
            </>
          </div>
        </Popper>
      </ClickAwayListener>
    </>
  );
};
export default SenderPopover;

import APIClient from "../APIClient";
const PLATFORM_API_BASE = process.env.REACT_APP_API_ENDPOINT_PLATFORM_V1;

export class EnrollmentsClient {
  getEnrollments(filter?: string, include?: string, order?: string, pageSize?: number, pageNumber?: number): Promise<AppEnrollmentModelFetchResult> {
    const url = `${PLATFORM_API_BASE}/AppEnrollments/query`;

    const options = {
      params: {
        filter,
        include: include ?? "App, LastSync",
        order,
        pageSize,
        pageNumber,
      },
    };

    return APIClient.get(url, options).then((response) => {
      return response.data;
    });
  }

  changeEnrollment(id: string, body: { isActive: boolean }): Promise<AppEnrollmentModel> {
    const url = `${PLATFORM_API_BASE}/AppEnrollments/${id}`;

    return APIClient.patch(url, body).then((response) => {
      return response.data;
    });
  }

  getEnrollment(id: string): Promise<AppEnrollmentModel> {
    const url = `${PLATFORM_API_BASE}/AppEnrollments/${id}`;

    const options = {
      params: {
        include: "App",
      },
    };

    return APIClient.get(url, options).then((response) => {
      return response.data;
    });
  }

  createEnrollment(body: Partial<AppEnrollmentModel>[]): Promise<AppEnrollmentModel[]> {
    const url = `${PLATFORM_API_BASE}/AppEnrollments`;

    return APIClient.post(url, body).then((response) => {
      return response.data;
    });
  }

  deleteEnrollment(id: string, removeData: boolean): Promise<ActionResultModel> {
    const url = `${PLATFORM_API_BASE}/AppEnrollments/${id}`;

    const options = {
      params: {
        removeEnrollmentData: removeData,
      },
    };
    return APIClient.delete(url, options).then((res) => res.data);
  }
}

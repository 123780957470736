/*eslint-disable no-magic-numbers*/
import { styled } from "@mui/system";
import Chip from "@mui/material/Chip";
import { pxToRem } from "../../theme/theme";

export const OpenChip = styled(Chip)({
  color: "#24B14B",
  border: `${pxToRem(0.5)} solid #24B14B`,
  background: "white",
  height: "fit-content",
});

export const PastDue1Chip = styled(Chip)({
  color: "#1F91B7",
  border: `${pxToRem(0.5)} solid #80C2D8`,
  background: "#f0fbff",
  height: "fit-content",
});

export const PastDue31Chip = styled(Chip)({
  color: "#1fb6c1",
  border: `${pxToRem(0.5)} solid #1fb6c1`,
  background: "#F7FFFB",
  height: "fit-content",
});

export const PastDue61Chip = styled(Chip)({
  color: "#F38923",
  border: `${pxToRem(0.5)} solid #f4bd7c`,
  background: "#fffdf9",
  height: "fit-content",
});

export const PastDue91Chip = styled(Chip)({
  color: "#F37391",
  border: `${pxToRem(0.5)} solid #ef3c6b`,
  background: "#fff8f9",
  height: "fit-content",
});

export const ClosedChip = styled(Chip)({
  color: "#143b52",
  border: `${pxToRem(0.5)} solid #000000`,
  background: "#f2f3f8",
  height: "fit-content",
});

export const RejectedChip = styled(Chip)({
  color: "#750507",
  border: `${pxToRem(0.5)} solid #750507`,
  background: "#FFE6E6",
  height: "fit-content",
});
import React, { useRef, useState } from "react";
import { NUMERIC_VALUES } from "../../../../../../../constants/NumericConstants";
import { FilterContainerTypes } from "../../preset.filter.types";
import { TableFilterComparatorConstant } from "../../../../constants/table.constants";
import _ from "underscore";
import DropdownFilterComponent from "./DropdownFilterComponent";
import { useTranslation } from "react-i18next";

const DropdownFilterContainer: React.FC<FilterContainerTypes> = ({
  filterKey,
  filterValue,
  onChangeHandler,
  onClearHandler,
  filterAlignment = "center",
  dropdownListItems,
  searchlightField,
}: FilterContainerTypes) => {
  const { t } = useTranslation();
  const DropdownList = useRef([
    { key: "All Status", label: t("filters.dropdownFilter.defaultStatusList.allStatus", { ns: "table" }), value: "All Status" },
    { key: "OPEN", label: t("filters.dropdownFilter.defaultStatusList.open", { ns: "table" }), value: "0" },
    { key: "CLOSED", label: t("filters.dropdownFilter.defaultStatusList.closed", { ns: "table" }), value: "1" },
  ]);
  const [selectedListItemIndex, setSelectedListItemIndex] = useState<number>(NUMERIC_VALUES.CONSTANT_ZERO);
  const [active, setActive] = useState<boolean>(false);

  const onClearFilter = () => {
    if (onClearHandler) {
      setSelectedListItemIndex(NUMERIC_VALUES.CONSTANT_ZERO);
      onClearHandler(filterKey);
      setActive(false);
    }
  };

  const onClickMenuListItem = (selectedValue: string, index: number) => {
    if (onChangeHandler && index !== NUMERIC_VALUES.CONSTANT_ZERO) {
      setSelectedListItemIndex((dropdownListItems ?? DropdownList.current).findIndex((item) => item?.value === selectedValue));
      onChangeHandler([{ key: filterKey, value: selectedValue, comparator: TableFilterComparatorConstant.EQUALS_TO, searchlightField }]);
      setActive(true);
    } else {
      onClearFilter();
    }
  };

  React.useEffect(() => {
    setActive(Boolean(!_.isEqual("All Status", filterValue) && filterValue));
    setSelectedListItemIndex(() => {
      const selectedIndex = (dropdownListItems ?? DropdownList.current).findIndex((item) => item?.value === filterValue);
      return selectedIndex === NUMERIC_VALUES.CONSTANT_NEGATIVE_ONE ? NUMERIC_VALUES.CONSTANT_ZERO : selectedIndex;
    });
  }, [filterValue]);

  return (
    <DropdownFilterComponent
      onClickMenuListItem={onClickMenuListItem}
      selectedListItemIndex={selectedListItemIndex}
      filterActive={active}
      filterAlignment={filterAlignment}
      dropdownListItems={dropdownListItems ?? DropdownList.current}
    />
  );
};

export default DropdownFilterContainer;

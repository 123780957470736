import React from "react";
import { DateRange, DateRangeValidationError, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MultiInputDateRangeField from "../../../../atomic/MultiInputDateRangeField/MultiInputDateRangeField";
import dayjs, { Dayjs } from "dayjs";
import { CustomInputDateFieldSepartor, DateRangeCalendarPopupContainer } from "../styled/date.filter.styled";
import { FieldChangeHandlerContext, PickerSelectionState } from "@mui/x-date-pickers/internals";
import { NUMERIC_VALUES } from "../../../../constants/numeric.constants";
import { CustomDateRangePickerProps } from "../../preset.filter.types";
import DateRangeCalendar from "../../../../atomic/DateRangeCalendar/DateRangeCalendar";
import { ActionFooterContainer, ApplyButton, CancelButton } from "../../../styled/table.filter.styled";
import { useTranslation } from "react-i18next";

const CustomSepartor = () => {
  const { t } = useTranslation();
  return <CustomInputDateFieldSepartor>{t("filters.dateFilter.to", { ns: "table" })}</CustomInputDateFieldSepartor>;
};

const CustomDateRangePicker: React.FC<CustomDateRangePickerProps> = ({
  isOpenDateRangePopup,
  anchorEl,
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "right",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "right",
  },
  onClickCancel,
  onCloseDateRangePicker,
  onClickApplyDate,
  dateFormat,
  appliedDateRangeValue,
  origin = "",
}) => {
  const getInitialInputFieldValue = (): DateRange<Dayjs> => {
    const todayDate = dayjs();
    return appliedDateRangeValue?.length === NUMERIC_VALUES.CONSTANT_TWO
      ? [dayjs(appliedDateRangeValue?.[0]), dayjs(appliedDateRangeValue?.[1])]
      : [todayDate, todayDate];
  };
  const [inputDateFieldValue, setInputDateFieldValue] = React.useState<DateRange<Dayjs>>(getInitialInputFieldValue);
  const [isInvalidDate, setIsInvalidDate] = React.useState<boolean>(true);
  const [calendarDateFieldValue, setCalendarDateFieldValue] = React.useState<DateRange<Dayjs>>(getInitialInputFieldValue);
  const { t } = useTranslation();

  const onChangeDateInputField = (value: DateRange<Dayjs>, context: FieldChangeHandlerContext<DateRangeValidationError>) => {
    setInputDateFieldValue(value);

    // date range validation
    const dateRangeDiffernece = value[NUMERIC_VALUES.CONSTANT_ONE]?.diff(value[NUMERIC_VALUES.CONSTANT_ZERO]);
    const isInputNotValid = Boolean(
      !context.validationError?.includes("invalidDate") && dateRangeDiffernece && dateRangeDiffernece < NUMERIC_VALUES.CONSTANT_ZERO
    );

    // setting validation results
    setIsInvalidDate(isInputNotValid ?? context.validationError?.includes("invalidDate"));

    // setting value if input is valid
    if (!isInputNotValid) {
      setCalendarDateFieldValue(value);
    }
  };

  const onChangeCalendar = (value: DateRange<Dayjs>, selectionState?: PickerSelectionState | undefined) => {
    setCalendarDateFieldValue(value);
    setInputDateFieldValue(value);

    const startDateDefined = Boolean(value[NUMERIC_VALUES.CONSTANT_ZERO]);
    const endDateDefined = Boolean(value[NUMERIC_VALUES.CONSTANT_ONE]);

    if (selectionState?.includes("partial") && !(startDateDefined && endDateDefined)) {
      setIsInvalidDate(true);
    } else {
      setIsInvalidDate(false);
    }
  };

  const onCloseCalendarPopover = () => {
    setInputDateFieldValue(getInitialInputFieldValue);
    setCalendarDateFieldValue(getInitialInputFieldValue);
    onCloseDateRangePicker();
  };

  const onClickCancelButton = () => {
    setInputDateFieldValue(getInitialInputFieldValue);
    setCalendarDateFieldValue(getInitialInputFieldValue);
    onClickCancel();
  };

  React.useEffect(() => {
    setInputDateFieldValue(getInitialInputFieldValue());
    setCalendarDateFieldValue(getInitialInputFieldValue());
  }, [appliedDateRangeValue]);

  return (
    <DateRangeCalendarPopupContainer
      id={"calendar-popover"}
      open={isOpenDateRangePopup}
      onClose={onCloseCalendarPopover}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      className={origin}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MultiInputDateRangeField
          slots={{
            separator: CustomSepartor,
          }}
          onChange={onChangeDateInputField}
          format={dateFormat}
          value={inputDateFieldValue}
        />
        <DateRangeCalendar value={calendarDateFieldValue} onChange={onChangeCalendar} autoFocus={false} />
        <ActionFooterContainer>
          <CancelButton onClick={onClickCancelButton}>{t("filters.buttons.cancel", { ns: "table" })}</CancelButton>
          <ApplyButton onClick={() => onClickApplyDate(inputDateFieldValue)} disabled={isInvalidDate}>
            {t("filters.buttons.apply", { ns: "table" })}
          </ApplyButton>
        </ActionFooterContainer>
      </LocalizationProvider>
    </DateRangeCalendarPopupContainer>
  );
};

export default CustomDateRangePicker;

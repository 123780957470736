/*eslint-disable*/
import { formatRowDataForExport } from "../../utils/table";
import { ColumnConfigs, CurrencyConfigType } from "../types/table.types";
import { ExportRecordsHook, RowSelect } from "../types/hooks.types";
import { NUMERIC_VALUES } from "../../constants/numeric.constants";

const useExport: ExportRecordsHook = (columnConfigs: ColumnConfigs[], rows: any[], dateFormat?: string, currencyFormat?: CurrencyConfigType) => {
  // Get Formatted Export Data based on selection
  const getFormattedSelectionDataForExport = (selectedData: any[]) => {
    if (selectedData && selectedData.length === NUMERIC_VALUES.CONSTANT_ZERO) {
      return rows?.map((data) => formatRowDataForExport(columnConfigs, data, dateFormat ?? "", currencyFormat));
    }
    return selectedData?.map((data) => formatRowDataForExport(columnConfigs, data, dateFormat ?? "", currencyFormat));
  };

  return { getFormattedSelectedRowData: getFormattedSelectionDataForExport };
};
export default useExport;

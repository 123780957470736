import React from "react";
import "./../Attachments.scss";
import Button from "../../../../library/Button/Button";
import { AttachmentsViewProps } from "../interfaces/AttachmentsInterfaces";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../../../../constants/NumericConstants";
import { TableWithInfiniteScroll } from "../../../../library/TableWithInfiniteScroll";

const AttachmentsView = ({
  table: {
    columns,
    loading,
    meta: { selectedAll },
    data: { records, loadMoreRecords, pageCount },
    handlers,
  },
  view,
  setShowModal,
  toggleDialog,
  currentSelection,
  onClickAddAttachments,
  currencyConfig,
}: AttachmentsViewProps) => {
  const onClickCancel = () => {
    // close modal
    setShowModal(false);
    // detach component
    toggleDialog(false);
  };

  const selectionSummary = (function generateSummary() {
    const result = Object.keys(currentSelection)
      .filter((item: any) => (currentSelection as any)[item] > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO)
      .map(
        (item: any) =>
          `${(currentSelection as any)[item]} ${
            (currentSelection as any)[item] > DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
              ? item
              : item.slice(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, NUMERIC_VALUES.CONSTANT_NEGATIVE_ONE)
          }`
      )
      .join(", ");
    return result !== "" ? `${result} Selected` : "";
  })();

  return (
    <div className="content-wrapper">
      <div className="content-body">
        <div className="table">
          <TableWithInfiniteScroll
            tableConfig={{
              header: { title: view, selectedAll },
              column: columns,
              rows: records,
              controllers: {
                loading: loading,
                hasMore: pageCount.current + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE < pageCount.total,
                loadMore: loadMoreRecords,
              },
              checkboxes: {
                enableSelection: true,
              },
              handlers: handlers,
              currencyConfig,
            }}
          />
        </div>
      </div>
      <div className="content-footer">
        <div className="left">{selectionSummary}</div>
        <div className="right">
          <Button variant="secondary" onClick={onClickCancel}>
            Cancel
          </Button>
          <Button disabled={!selectionSummary} onClick={onClickAddAttachments}>
            Add Attachment
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AttachmentsView;

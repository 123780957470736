import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Utils from "../../../../../../utils/utils";
import { NUMERIC_VALUES } from "../../../../../../constants/NumericConstants";
import { useTranslation } from "react-i18next";

export type EInvoiceTableProps = {
  lineItems: any;
  currency: CurrencyType;
};

export type EInvoiceTableRowData = {
  product_code: string;
  description: string;
  quantity: number;
  unit_price: number;
  total_amount: number;
};

const EInvoiceTable: React.FC<EInvoiceTableProps> = ({ lineItems, currency }) => {
  const { t } = useTranslation();
  const totalDue = lineItems.reduce((acc: number, line: EInvoiceTableRowData) => acc + line.total_amount, NUMERIC_VALUES.CONSTANT_ZERO);

  return (
    <div className="e-invoice-line-items-table">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left" className="width150">
                {t("activityDetails.feedItems.primaryActivity.eInvoiceTable.columns.eInvoiceNumber", { ns: "einvoices" })}
              </TableCell>
              <TableCell align="left" className="width-full">
                {t("activityDetails.feedItems.primaryActivity.eInvoiceTable.columns.description", { ns: "einvoices" })}
              </TableCell>
              <TableCell align="left" className="width100">
                {t("activityDetails.feedItems.primaryActivity.eInvoiceTable.columns.quantity", { ns: "einvoices" })}
              </TableCell>
              <TableCell align="left" className="width100">
                {t("activityDetails.feedItems.primaryActivity.eInvoiceTable.columns.unitPrice", { ns: "einvoices" })}
              </TableCell>
              <TableCell align="left" className="width135">
                {t("activityDetails.feedItems.primaryActivity.eInvoiceTable.columns.quantityShipped", { ns: "einvoices" })}
              </TableCell>
              <TableCell align="left" className="width135">
                {t("activityDetails.feedItems.primaryActivity.eInvoiceTable.columns.quantityReceived", { ns: "einvoices" })}
              </TableCell>
              <TableCell align="left" className="width100">
                {t("activityDetails.feedItems.primaryActivity.eInvoiceTable.columns.totalAmount", { ns: "einvoices" })}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lineItems.map((row: EInvoiceTableRowData) => (
              <TableRow key={row.product_code}>
                <TableCell align="left">{row.product_code ?? "-"}</TableCell>
                <TableCell align="left">{row.description ?? "-"}</TableCell>
                <TableCell align="right">{row.quantity ?? "-"}</TableCell>
                <TableCell align="right">{Utils.formatValueAsCurrency(row.unit_price, currency?.locale, currency?.code) ?? "-"}</TableCell>
                <TableCell align="right">-</TableCell>
                <TableCell align="right">-</TableCell>
                <TableCell align="right">{Utils.formatValueAsCurrency(row.total_amount, currency?.locale, currency?.code) ?? "-"}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell align="left" colSpan={6}>
                {t("activityDetails.feedItems.primaryActivity.eInvoiceTable.rows.totalDue", { ns: "einvoices" })}
              </TableCell>
              <TableCell align="right" colSpan={1}>
                {Utils.formatValueAsCurrency(totalDue, currency?.locale, currency?.code)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default EInvoiceTable;

import APIClient from "../APIClient";
const PLATFORM_API_BASE = process.env.REACT_APP_API_ENDPOINT_PLATFORM_V1;

export class FeatureFlagClient {
  /**
   * * POST on /api/v1/feature-flags
   * To call this endpoint, you must have one of these roles:
   * Group Owner
   * Group Admin
   * Member
   * Read-Only
   * @returns Promise of type FeatureFlagsResponseModel or Exception
   */
  fetchFeatureFlags(body: FeatureFlagsRequestModel): Promise<FeatureFlagsResponseModel> {
    const url = `${PLATFORM_API_BASE}/feature-flags`;

    return APIClient.post(url, body).then((response) => {
      return response.data;
    });
  }
}

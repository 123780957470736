import React from "react";
import { Invoices, Attachments, Payments } from "../../../../library/Icons/Icons";
import Button from "../../../../library/Button/Button";
import AttachmensSidebarItem from "./AttachmentsSidebarItem";
import useWorkspaceConfigurations from "../../../../../hooks/useWorkspaceConfigurations";
import { AttachmentsSidebarProps } from "../interfaces/AttachmentsInterfaces";
import "../Attachments.scss";
import { WorkspaceType } from "../../../../../types/enums";

const AttachmentsSidebar: React.FC<AttachmentsSidebarProps> = ({ view, setView, totalRecords, openFileExp }) => {
  const { configs, workspaceType } = useWorkspaceConfigurations();

  const onClickItem = (view: string) => setView(view);
  const onClickNewUpload = () => openFileExp?.();
  const isSelected = (title: string) => view === title;

  const sidebar = {
    document: {
      title: configs.attachments.view.documents.title,
      icon: <Attachments />,
      selected: isSelected(configs.attachments.view.documents.title),
      count: totalRecords.Documents,
      onClick: onClickItem,
    },
    invoice: {
      title: configs.attachments.view.invoices.title,
      icon: <Invoices />,
      selected: isSelected(configs.attachments.view.invoices.title),
      count: workspaceType === WorkspaceType.AR ? totalRecords.Invoices : totalRecords.Bills,
      onClick: onClickItem,
    },
    payment: {
      title: configs.attachments.view.payments.title,
      icon: <Payments />,
      selected: isSelected(configs.attachments.view.payments.title),
      count: totalRecords.Payments,
      onClick: onClickItem,
    },
  };

  return (
    <div className="sidebar-wrapper">
      <div className="sidebar-items">
        {Object.values(sidebar).map((item: any) => (
          <AttachmensSidebarItem {...item} key={item.title} />
        ))}
      </div>
      <div className="sidebar-action">
        <Button size="lg" className="upload-btn" onClick={onClickNewUpload}>
          Upload File
        </Button>
      </div>
    </div>
  );
};

export default AttachmentsSidebar;

import { TFunction } from "i18next";

export const RowDataTypes = Object.freeze({
  BoldText: "BoldText",
  BoldTextWithCaption: "BoldTextWithCaption",
  Currency: "Currency",
  CurrencyWithTypeCheck: "CurrencyWithTypeCheck",
  DateString: "DateString",
  DateEpoch: "DateEpoch",
  Status: "Status",
  SimpleText: "SimpleText",
  StatusChip: "StatusChip",
  DownloadDoc: "DownloadDoc",
  FileCount: "FileCount",
  FileCountWithDrawerHandler: "FileCountWithDrawerHandler",
  Flag: "Flag",
  ActivitySubject: "ActivitySubject",
  DateEpochSnoozed: "DateEpochSnoozed",
  ActionsDropdown: "ActionsDropdown",
  ActivityStatus: "ActivityStatus",
  ContactName: "ContactName",
  Phone: "Phone",
  DaysLate: "DaysLate",
  Number: "Number",
  Percentage: "Percentage",
  FileWithCounts: "FileWithCounts",
  EInvoiceStatus: "EInvoiceStatus",
  EInvoiceDaysStatusChips: "EInvoiceDaysStatusChips",
  CompanyChip: "CompanyChip",
  EInvoiceAttachment: "EInvoiceAttachment",
});

export const CustomerCurrencyTypes = Object.freeze({
  DEBIT: ["ar credit memo", "ar payment"],
  CREDIT: ["ar invoice"],
});

export const VendorCurrencyTypes = Object.freeze({
  DEBIT: ["ap credit memo", "ap payment"],
  CREDIT: ["ap invoice"],
});

export const TABLE_CONSTANTS = Object.freeze({
  PAGE_SIZE: 20,
  PAYMENTS_VIEW: "Payment",
  INVOICES_VIEW: "Invoice",
  STATEMENT_VIEW: "Transaction",
  CLEAR_ALL: "Clear All",
  SELECT_ALL: "Select All",
});

export const TableFilterVariants = Object.freeze({
  DROPDOWN_SEARCH_FILTER: "dropdown-search",
  NUMERIC_FILTER: "numeric",
  DATE_FILTER: "date",
  DROPDOWN_FILTER: "dropdown",
});

interface paginationInterface {
  PAGE_SIZE: number;
  PAGE_COUNT: number;
  TOTAL_PAGE_COUNT: number;
  PAGE_SIZE_OPTIONS: { [key: string]: number };
  START: number;
}

export const DEFAULT_PAGINATION_VALUES: paginationInterface = Object.freeze({
  PAGE_SIZE: 20,
  PAGE_COUNT: 0,
  TOTAL_PAGE_COUNT: 1,
  PAGE_SIZE_OPTIONS: {
    OPTION_1: 20,
    OPTION_2: 50,
    OPTION_3: 100,
  },
  START: 0,
});

export const TableFilterComparatorConstant = Object.freeze({
  EQUALS_TO: "Equals To",
  LESS_THAN: "Less Than",
  LESS_THAN_EQUAL: "Less Than Equal",
  GREATER_THAN: "Greater Than",
  GREATER_THAN_EQUAL: "Greater Than Equal",
  IN_BETWEEN: "In-Between",
  MULTI_VALUE: "Multi Value",
  ANY: "Any",
  ANY_TIME: "Any Time",
  TODAY: "Today",
  THIS_WEEK: "This Week",
  THIS_MONTH: "This Month",
  LAST_MONTH: "Last Month",
  LAST_THREE_MONTHS: "Last 3 Months",
  LAST_SIX_MONTHS: "Last 6 Months",
  LAST_TWELEVE_MONTHS: "Last 12 Months",
  YEAR_TO_DATE: "Year to Date",
  LAST_YEAR_TO_DATE: "Last Year to Date",
  CUSTOM_DATE: "Custom Date",
});

export const TableFilterConstant = Object.freeze({
  getNumericDropdownList: (t: TFunction) => [
    {
      key: TableFilterComparatorConstant.LESS_THAN,
      value: TableFilterComparatorConstant.LESS_THAN,
      label: t("table:filters.numericFilter.menuListLabels.lessThan", { ns: "table" }),
    },
    {
      key: TableFilterComparatorConstant.GREATER_THAN,
      value: TableFilterComparatorConstant.GREATER_THAN,
      label: t("table:filters.numericFilter.menuListLabels.greaterThan", { ns: "table" }),
    },
    {
      key: TableFilterComparatorConstant.EQUALS_TO,
      value: TableFilterComparatorConstant.EQUALS_TO,
      label: t("table:filters.numericFilter.menuListLabels.equalsTo", { ns: "table" }),
    },
    {
      key: TableFilterComparatorConstant.IN_BETWEEN,
      value: TableFilterComparatorConstant.IN_BETWEEN,
      label: t("filters.numericFilter.menuListLabels.inBetween", { ns: "table" }),
    },
  ],
  getDateDropDownList: (t: TFunction) => [
    {
      key: TableFilterComparatorConstant.ANY_TIME,
      value: TableFilterComparatorConstant.ANY_TIME,
      label: t("filters.dateFilter.menuListLabels.anyTime", { ns: "table" }),
    },
    {
      key: TableFilterComparatorConstant.TODAY,
      value: TableFilterComparatorConstant.TODAY,
      label: t("filters.dateFilter.menuListLabels.today", { ns: "table" }),
    },
    {
      key: TableFilterComparatorConstant.THIS_WEEK,
      value: TableFilterComparatorConstant.THIS_WEEK,
      label: t("filters.dateFilter.menuListLabels.currentWeek", { ns: "table" }),
    },
    {
      key: TableFilterComparatorConstant.THIS_MONTH,
      value: TableFilterComparatorConstant.THIS_MONTH,
      label: t("filters.dateFilter.menuListLabels.currentMonth", { ns: "table" }),
    },
    {
      key: TableFilterComparatorConstant.LAST_MONTH,
      value: TableFilterComparatorConstant.LAST_MONTH,
      label: t("filters.dateFilter.menuListLabels.lastMonth", { ns: "table" }),
    },
    {
      key: TableFilterComparatorConstant.LAST_THREE_MONTHS,
      value: TableFilterComparatorConstant.LAST_THREE_MONTHS,
      label: t("filters.dateFilter.menuListLabels.last3Months", { ns: "table" }),
    },
    {
      key: TableFilterComparatorConstant.LAST_SIX_MONTHS,
      value: TableFilterComparatorConstant.LAST_SIX_MONTHS,
      label: t("filters.dateFilter.menuListLabels.last6Months", { ns: "table" }),
    },
    {
      key: TableFilterComparatorConstant.LAST_TWELEVE_MONTHS,
      value: TableFilterComparatorConstant.LAST_TWELEVE_MONTHS,
      label: t("filters.dateFilter.menuListLabels.last12Months", { ns: "table" }),
    },
    {
      key: TableFilterComparatorConstant.YEAR_TO_DATE,
      value: TableFilterComparatorConstant.YEAR_TO_DATE,
      label: t("filters.dateFilter.menuListLabels.currentYearToDate", { ns: "table" }),
    },
    {
      key: TableFilterComparatorConstant.LAST_YEAR_TO_DATE,
      value: TableFilterComparatorConstant.LAST_YEAR_TO_DATE,
      label: t("filters.dateFilter.menuListLabels.lastYearToDate", { ns: "table" }),
    },
    {
      key: TableFilterComparatorConstant.CUSTOM_DATE,
      value: TableFilterComparatorConstant.CUSTOM_DATE,
      label: t("filters.dateFilter.menuListLabels.customDate", { ns: "table" }),
    },
  ],
  getDefaultDropdownText: (t: TFunction) => ({
    key: "Select",
    value: "Select",
    label: t("filters.numericFilter.menuListLabels.select", { ns: "table" }),
  }),
  getDefaultValueText: (t: TFunction) => ({
    key: "Value",
    value: "Value",
    label: t("filters.numericFilter.value", { ns: "table" }),
  }),
  AMOUNT_FIELD_PLACEHOLDER: "0.00",
  CANCEL: "Cancel",
  CLEAR: "Clear",
  APPLY: "Apply",
  SUGGESTIONS: "Suggestions",
  NOTHING_FOUND: "Nothing found",
  DATA_UNAVAILABLE: "Data unavailable.",
  NO_SUGGESTION_DATA_FOUND: "There is no suggestion data to be found.",
  TO: "To",
  SEARCH_PLACEHOLDER: "Ex. John Doe",
  DATE_FORMAT: "YYYY-MM-DD",
  EMPTY_MESSAGE_TITLE: "No Results Found",
  EMPTY_MESSAGE_CAPTION: "Try different keywords or remove search filters",
  SEARCH_RESULT: "Search Results",
  FILTER_SUGGESTIONS: "filter-suggestions",
});

import { StatusClient } from "./clients/StatusClient";
import { CompaniesClient } from "./clients/CompaniesClient";
import { InvoicesClient } from "./clients/InvoicesClient";
import { ReportsClient } from "./clients/ReportsClient";
import { ActivitiesClient } from "./clients/ActivitiesClient";
import { LeadsClient } from "./clients/LeadsClient";
import { DefinitionsClient } from "./clients/DefinitionsClient";
import { ProvisioningClient } from "./clients/ProvisioningClient";
import { EnrollmentsClient } from "./clients/EnrollmentsClient";
import { PaymentsClient } from "./clients/PaymentsClient";
import { PaymentApplicationsClient } from "./clients/PaymentApplicationsClient";
import { SyncClient } from "./clients/SyncClient";
import { UserAccountsClient } from "./clients/UserAccountsClient";
import { ContactsClient } from "./clients/ContactsClient";
import { ApplicationsClient } from "./clients/ApplicationsClient";
import { CodeDefinitionsClient } from "./clients/CodeDefinitionsClient";
import { UserRolesClient } from "./clients/UserRolesClient";
import { NotesClient } from "./clients/NotesClient";
import { DocumentsClient } from "./clients/DocumentsClient";
import { EmailsClient } from "./clients/EmailsClient";
import { FeatureFlagClient } from "./clients/FeatureFlagClient";

export const statusClient = new StatusClient();
export const companiesClient = new CompaniesClient();
export const invoicesClient = new InvoicesClient();
export const reportsClient = new ReportsClient();
export const activitiesClient = new ActivitiesClient();
export const leadsClient = new LeadsClient();
export const definitionsClient = new DefinitionsClient();
export const provisioningClient = new ProvisioningClient();
export const enrollmentsClient = new EnrollmentsClient();
export const paymentsClient = new PaymentsClient();
export const paymentsApplicationsClient = new PaymentApplicationsClient();
export const syncClient = new SyncClient();
export const userAccountsClient = new UserAccountsClient();
export const contactsClient = new ContactsClient();
export const applicationsClient = new ApplicationsClient();
export const codeDefinitionsClient = new CodeDefinitionsClient();
export const userRolesClient = new UserRolesClient();
export const notesClient = new NotesClient();
export const documentsClient = new DocumentsClient();
export const emailsClient = new EmailsClient();
export const featureFlagClient = new FeatureFlagClient();

// Lockstep DSM Palettes
export const green: { [key: number]: string } = {
  50: "#ECFDF5",
  700: "#047857",
};

export const orange: { [key: number]: string } = {
  50: "#FFF7ED",
  700: "#C2410C",
};

export const red: { [key: number]: string } = {
  50: "#FEF2F2",
  700: "#B91C1C",
};

export const blue: { [key: number]: string } = {
  50: "#EFF6FF",
  700: "#1E40AF",
};

import React from "react";
import { localeClient } from "../db/version2Accessor";
import { useTranslation } from "react-i18next";
import { FeatureFlagContext, FeatureFlagProviderType } from "./FeatureFlagContext";

export const LocaleContext = React.createContext<LocaleData | null>(null);

export interface LocaleProviderProps {
  children: (React.ReactNode & { type: string })[] | (React.ReactNode & { type: string });
}

/**
 * Scope: dealing with anything related to locales -
 *  - User language prference
 *  - Email template language preferenes
 *
 * mounts the locale call on application load, sets
 * the i18n language preference and in cache
 *
 * @param childern application
 * @returns none
 */
export const LocaleProvider: React.FC<LocaleProviderProps> = ({ children }) => {
  const { featureFlagsLoading, isEnabled } = React.useContext(FeatureFlagContext) as FeatureFlagProviderType;

  const { i18n } = useTranslation();

  const fetchLocale = async () => {
    let response = null;
    try {
      response = (await localeClient.getLocale()).data;
    } catch (error) {
      console.error(error);
    } finally {
      const locale = response?.locale;
      // update locale data
      i18n.changeLanguage(locale);
      localStorage.setItem("locale", locale ?? "");
    }
  };

  /**
   * get locale
   *
   * if feature flag is turned off -
   *  set the locale to empty
   *  i18n will fallback to "fallbackLng"
   * Otherwise -
   *  fetch the locale
   *  set it to i18n Language
   *  update locale cache
   */
  React.useEffect(() => {
    if (!featureFlagsLoading) {
      isEnabled("LOCALIZATION_MULTILANG") ? fetchLocale() : localStorage.setItem("locale", "");
    }
  }, [featureFlagsLoading]);

  return <LocaleContext.Provider value={null}>{children}</LocaleContext.Provider>;
};

export default LocaleProvider;

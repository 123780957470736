import React, { ReactElement } from "react";

const SetupMessage = (): ReactElement => {
  return (
    <>
      <div className="help-text-header">Setup Your Signature</div>
      <div className="help-text-body">
        Configure and save your signature to be included in all email activities sent via Inbox. Please note that you can add and edit your signature
        for all communications. Insert or drag and drop your logo.
      </div>
    </>
  );
};

export default SetupMessage;

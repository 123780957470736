import React from "react";
import SecondaryActivity from "../../SecondaryActivity/SecondaryActivity";
import { SecondaryType } from "../../SecondaryActivity/SecondaryActivityTypes";
import { ForwardLogProps } from "../ActivityItemVariantTypes";
import { Button } from "../../../../../../library/AtomicComponents/atomic/Button";
import { useTranslation } from "react-i18next";

const ForwardedLog: React.FC<ForwardLogProps> = ({ creator, onClickRedirect, readonly, countryCode }) => {
  const { t } = useTranslation();
  creator = { ...creator, message: t("activityDetails.feedItems.forwardedLogAndActivity.forwardedText", { ns: "activities" }) };
  return readonly ? (
    <SecondaryActivity variant={SecondaryType.Base} creator={creator} countryCode={countryCode} />
  ) : (
    <SecondaryActivity variant={SecondaryType.DetailedWithAction} creator={creator} countryCode={countryCode}>
      <Button variant="secondary" size="small" onClick={onClickRedirect}>
        {t("activityDetails.feedItems.forwardedLogAndActivity.forwardedStreamButton", { ns: "activities" })}
      </Button>
    </SecondaryActivity>
  );
};

export default ForwardedLog;

import React, { useState } from "react";

import { companiesClient } from "../db/accessor";
import { SignatureClientsV2 } from "../db/version2Accessor";
import { WorkspaceContext } from "../contexts/WorkspaceContext";

export const CustomerContext = React.createContext<CustomerType | null>(null);

export interface CustomerProviderProps {
  children: (React.ReactNode & { type: string })[] | (React.ReactNode & { type: string });
}

const CustomerProvider: React.FC<CustomerProviderProps> = ({ children }: CustomerProviderProps) => {
  const [company, setCompany] = React.useState<CustomerDetailsModel>({} as CustomerDetailsModel);
  const [loginUserCompany, setLoginUserCompany] = useState<CompanyModel>({} as CompanyModel);
  const [isConnectionUpdated, setConnectionUpdated] = React.useState<boolean>(false);
  const [viewDetails, setViewDetails] = React.useState<CustomerTableView>({} as CustomerTableView);
  const [customerId, setCustomerId] = React.useState<UUID>("");
  const [isDataUpdated, setConnectionDataUpdated] = React.useState<boolean>(false);
  const [signature, setSignature] = React.useState<Signature>({} as Signature);

  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;

  // GET on  api/v1/Companies/views/details/{id}
  async function getCompanyDetails(id: string): Promise<CustomerDetailsModel> {
    return companiesClient.getCompanyDetails(id).then((data) => {
      setCompany(data);
      return data;
    });
  }

  // GET on /api/v1/Companies/{id}
  async function getCompany(id: string, include?: string): Promise<CompanyModel> {
    return companiesClient.getCompany(id, include).then((data) => {
      setLoginUserCompany(data);
      return data;
    });
  }

  /**
   * GET on /api/v2/settings/signatures
   * @returns SignatureFetchModel
   */
  async function getUserSignature(): Promise<any> {
    return SignatureClientsV2.fetch()
      .then((response: SignatureFetchModel) => {
        setSignature(response.data);
        return;
      })
      .catch(() => {
        setSignature({} as Signature);
      });
  }

  const toggleConnectionUpdate = () => {
    setConnectionUpdated(!isConnectionUpdated);
  };

  const toggleDataUpdated = () => {
    setConnectionDataUpdated(!isDataUpdated);
  };

  React.useEffect(() => {
    if (selectedWorkspace?.id) {
      getUserSignature();
    }
  }, [selectedWorkspace]);

  return (
    <CustomerContext.Provider
      value={{
        getCompany,
        getCompanyDetails,
        company,
        loginUserCompany,
        isConnectionUpdated,
        toggleConnectionUpdate,
        toggleDataUpdated,
        isDataUpdated,
        setCompany,
        setLoginUserCompany,
        customerId,
        setCustomerId,
        viewDetails,
        setViewDetails,
        signature,
        getUserSignature,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

export default CustomerProvider;

import React, { useEffect, useState } from "react";
import { Drawer, DrawerProps } from "@mui/material";
import Button from "../../../../../library/Button/Button";
import { TextArea } from "../../../../../library/TextArea/TextArea";
import { companiesClientV2 } from "../../../../../../db/version2Accessor";
import { ActivityContext } from "../../../../../../contexts/ActivityContext";
import { AlertContext } from "../../../../../../contexts/AlertContext";
import "./../ActivityFeedActions.scss";
import { NUMERIC_VALUES } from "../../../../../../constants/NumericConstants";
import { ConnectionStatus, FallbackTypes, WorkspaceType } from "../../../../../../types/enums";
import { ACTIVITY_MOVE_PENDO } from "../../../../../../constants/config";
import { DisableFeatureContext, DisableFeatureProviderType } from "../../../../../../contexts/DisableFeatureContext";
import useWorkspaceConfigurations from "../../../../../../hooks/useWorkspaceConfigurations";
import BulkMoveModal from "./BulkMoveModal";
import { GroupHeader, GroupItems } from "./MoveActivity.styled";
import TrackingUtils from "../../../../../Tracking/Tracking.Utils";
import DebouncedAutocomplete from "../../../../../library/DebouncedAutocomplete/tsx/DebouncedAutocomplete";
import { useTranslation } from "react-i18next";

interface ActivityMoveProps extends DrawerProps {
  onClose: () => void;
  onCallback: () => void;
  activityIds: string[];
  connectionId?: string;
  isUnlistedEmailMoveActivity?: boolean;
  bulkConnection?: PrimaryConnection | null;
  deselectedRowsFilter?: string;
  selectedActivitiesCount?: number;
  connectionIdQueryFilter?: string;
}

type CompanyV2WithCategory = CompanyV2 & { option_category: string };

export default function ActivityMove({
  open,
  onClose,
  onCallback,
  activityIds,
  connectionId,
  isUnlistedEmailMoveActivity,
  bulkConnection,
  deselectedRowsFilter,
  selectedActivitiesCount,
  connectionIdQueryFilter,
  ...rest
}: ActivityMoveProps): React.ReactElement {
  const { handleMove, handleUnlistedEmailMove } = React.useContext(ActivityContext) as ActivityType;
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const { setDisableKeyboardShortcut } = React.useContext(DisableFeatureContext) as DisableFeatureProviderType;
  const { activityData } = React.useContext(ActivityContext) as ActivityType;
  const [isLoading, setLoading] = useState<boolean>(false);
  const [noteValue, setNoteValue] = useState<string>("");

  const [selectedCompany, setSelectedCompany] = useState<CompanyV2WithCategory | null>(null);

  // if accounting workspace is enabled take config based on pathType
  const { pathType, selectedWorkspace } = useWorkspaceConfigurations();

  const [showBulkMoveModal, setShowBulkMoveModal] = useState<boolean>(false);
  const { t } = useTranslation();

  const onMoveHandler = async () => {
    const bulkActivitiesCount = selectedActivitiesCount ?? activityIds.length;
    const movedSuccessTost =
      bulkActivitiesCount > NUMERIC_VALUES.CONSTANT_ONE
        ? t("toastMessages.moveActivity.bulkSuccess", { activitesCount: bulkActivitiesCount, ns: "activities" })
        : t("toastMessages.moveActivity.success", { ns: "activities" });
    const movedFailedTost =
      bulkActivitiesCount > NUMERIC_VALUES.CONSTANT_ONE
        ? t("toastMessages.moveActivity.bulkFailed", { activitesCount: bulkActivitiesCount, ns: "activities" })
        : t("toastMessages.moveActivity.failed", { ns: "activities" });

    let toastOptions: ToastOptions = {
      open: true,
      severity: "success",
      message: movedSuccessTost,
    };
    let response = {} as APIResponse;
    const filter = [connectionIdQueryFilter, deselectedRowsFilter].filter(Boolean).join("&");

    try {
      if (isUnlistedEmailMoveActivity) {
        response = await handleUnlistedEmailMove(activityIds[NUMERIC_VALUES.CONSTANT_ZERO], true, selectedCompany?.company_id, noteValue);
      } else {
        response = await handleMove(
          activityIds.length === NUMERIC_VALUES.CONSTANT_ONE ? activityIds[0] : activityIds,
          selectedCompany?.company_id ?? null,
          noteValue,
          filter
        );
      }
    } catch (e: unknown) {
      response.success = false;
    } finally {
      if (!response.success) {
        toastOptions = {
          ...toastOptions,
          severity: "error",
          message: movedFailedTost,
        };
      }
      TrackingUtils.trackUserActivities(ACTIVITY_MOVE_PENDO, response.success);
      onCallback();
      setNoteValue("");
      onClose();
      setToastOptions(toastOptions);
    }
  };

  const onMergeHandler = async () => {
    const movedSuccessTost = t("toastMessages.moveActivity.bulkSuccessMerge", { ns: "activities" });

    const movedFailedTost = t("toastMessages.moveActivity.bulkFailedMerge", { ns: "activities" });

    let toastOptions: ToastOptions = {
      open: true,
      severity: "success",
      message: movedSuccessTost,
    };
    let response = {} as APIResponse;
    try {
      response = await companiesClientV2.mergeConnections(
        selectedCompany?.company_id ?? "",
        bulkConnection && bulkConnection !== null ? [bulkConnection.id ?? ""] : [activityData.primary_connection?.id ?? ""],
        selectedWorkspace?.id
      );
    } catch (e: unknown) {
      response.success = false;
    } finally {
      if (!response.success) {
        toastOptions = {
          ...toastOptions,
          severity: "error",
          message: movedFailedTost,
        };
      }
      TrackingUtils.trackUserActivities(ACTIVITY_MOVE_PENDO, response.success);
      onCallback();
      setNoteValue("");
      onClose();
      setToastOptions(toastOptions);
    }
  };

  const openModal = () => {
    setShowBulkMoveModal(true);
  };

  const onCloseDialog = () => {
    setShowBulkMoveModal(false);
    setSelectedCompany(null);
    onClose();
  };

  const handleSubmit = async () => {
    setLoading(true);
    await onMoveHandler();
    setLoading(false);
    onCloseDialog();
  };

  const handleBulkMove = async () => {
    setLoading(true);
    await onMergeHandler();
    setLoading(false);
    onCloseDialog();
  };

  const handleSingleMove = () => {
    handleSubmit();
    onCloseDialog();
  };

  const companyTypes = React.useMemo(() => {
    if (selectedWorkspace.workspace_type_route === WorkspaceType.AW) {
      return ["Vendor", "Customer", "Other"];
    }
    if (pathType === WorkspaceType.AP) {
      return ["Vendor"];
    }
    return ["Customer"];
  }, [selectedWorkspace.workspace_type_route]);

  const fetchCompanyList = async (searchText: string, page?: number) => {
    return companiesClientV2.getCustomersList(
      ConnectionStatus.ACTIVE,
      "qs[s]=company_name",
      NUMERIC_VALUES.CONSTANT_TWENTY,
      page ?? NUMERIC_VALUES.CONSTANT_ZERO,
      selectedWorkspace.id || FallbackTypes.Id,
      companyTypes,
      activityIds.length === NUMERIC_VALUES.CONSTANT_ONE ? connectionId : "",
      searchText ? `qa[company_name_cont]=${searchText}` : undefined
    );
  };

  const fetchCompanyListFunc = async (searchText: string, page?: number): Promise<{ records: CompanyV2WithCategory[]; totalRecords: number }> => {
    const response = await fetchCompanyList(searchText, page);
    return {
      records: [
        ...(response.suggested?.map(
          (company: CompanyV2) =>
            ({
              ...company,
              option_category: "Suggested",
            } as CompanyV2WithCategory)
        ) ?? []),
        ...(response.records
          ?.filter((company) => company.company_id !== activityData.primary_connection?.id)
          .map(
            (company: CompanyV2) =>
              ({
                ...company,
                option_category: "All",
              } as CompanyV2WithCategory)
          ) ?? []),
      ],
      totalRecords: response.totalCount ?? NUMERIC_VALUES.CONSTANT_ZERO,
    };
  };

  /** autocomplete helpers */
  const getOptionInfo = (company: CompanyV2WithCategory) => {
    return {
      key: company.company_id,
      label: company.company_name ?? "",
    };
  };

  const isOptionEqualToValue = (company: CompanyV2, value: CompanyV2) => company.company_id === value.company_id;

  useEffect(() => {
    if (open) {
      setDisableKeyboardShortcut(true);
    } else {
      setDisableKeyboardShortcut(false);
    }
    return () => setDisableKeyboardShortcut(false);
  }, [open]);

  return (
    <Drawer className="activity-feed-action-drawer" open={open} anchor="right" disablePortal onClose={() => onClose()} {...rest}>
      <div className="activity-flyout" style={{ overflow: "hidden" }}>
        <h3 className="header">{t("modalAndFlyout.moveActivity.header", { ns: "activities" })}</h3>
        <p className="subheader body1">{t("modalAndFlyout.moveActivity.subHeader", { ns: "activities" })}</p>
        <label htmlFor={"string"}>{t("modalAndFlyout.moveActivity.connectionLabel", { ns: "activities" })}</label>
        <div>
          <DebouncedAutocomplete<CompanyV2WithCategory>
            network={{
              apiCall: fetchCompanyListFunc,
              debounce: true,
              delay: NUMERIC_VALUES.CONSTANT_FIVE_HUNDRED,
            }}
            data={{
              isOptionEqualToValue,
              optionParser: getOptionInfo,
              onOptionSelect: (option: CompanyV2WithCategory | null) => setSelectedCompany(option),
              searchPlaceholder: t("modalAndFlyout.moveActivity.autoCompleteSearchPlaceHolder", { ns: "activities" }),
            }}
            groupBy={(option) => option.option_category}
            renderGroup={(params) => (
              <li key={params.key}>
                <GroupHeader>{params.group}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
              </li>
            )}
          />
        </div>
        <TextArea
          label={{
            regularText: t("modalAndFlyout.noteTextFieldLabel.label1", { ns: "activities" }),
            extraText: t("modalAndFlyout.noteTextFieldLabel.label2", { ns: "activities" }),
          }}
          placeholder={""}
          onChange={(e) => setNoteValue(e.target.value)}
          defaultValue={noteValue}
        />
        <div className="btn-grp">
          <Button variant="secondary" size="lg" onClick={onClose} disabled={isLoading}>
            {t("modalAndFlyout.buttons.cancel", { ns: "activities" })}
          </Button>
          <Button
            size="lg"
            onClick={
              (bulkConnection && bulkConnection !== null) ||
              (activityIds.length === NUMERIC_VALUES.CONSTANT_ONE &&
                activityData?.primary_connection?.app_enrollment_id === null &&
                !isUnlistedEmailMoveActivity)
                ? openModal
                : handleSubmit
            }
            loading={isLoading}
            disabled={selectedCompany === null}
          >
            {t("modalAndFlyout.buttons.move", { ns: "activities" })}
          </Button>
        </div>
        <BulkMoveModal
          showModal={showBulkMoveModal}
          handleSingleMove={handleSingleMove}
          closeModal={onCloseDialog}
          handleBulkMove={() => void (async () => await handleBulkMove())()}
          from={bulkConnection && bulkConnection !== null ? bulkConnection : activityData?.primary_connection}
          to={{ companyName: selectedCompany?.company_name }}
        />
      </div>
    </Drawer>
  );
}

import APIClient from "../APIClient";
import { AxiosRequestConfig } from "axios";

const INBOX_API_BASE = `${process.env.REACT_APP_API_ENDPOINT_INBOX_API_PATH}${process.env.REACT_APP_API_ENDPOINT_INBOX_API_V2}`;
const CONFIG_OVERRIDE = {
  baseURL: process.env.REACT_APP_API_ENDPOINT_INBOX,
} as AxiosRequestConfig;

export class WorkspaceClient {
  /**
   * * POST on  /api/v2/workspaces
   * @returns Promise of type APIResponse Model or Exception
   */
  createWorkspace(body: WorkspaceCreate, bearer?: string, overrideMSAL = true): Promise<APIResponse> {
    const url = `${INBOX_API_BASE}/workspaces`;

    const options = bearer
      ? {
          headers: {
            Authorization: `Bearer ${bearer}`,
          },
        }
      : undefined;

    return APIClient.post(url, body, { ...options, ...CONFIG_OVERRIDE }, overrideMSAL).then((response) => {
      return response.data;
    });
  }

  /**
   * * GET on  /api/v2/workspaces
   * @returns Promise of type APIResponse Model or Exception
   */
  getAllWorkspaces(): Promise<APIResponse> {
    const url = `${INBOX_API_BASE}/workspaces`;

    return APIClient.get(url, CONFIG_OVERRIDE).then((response) => {
      return response.data;
    });
  }

  /**
   * * Delete on  /api/v2/workspaces/workspaceId
   * @returns Promise of type APIResponse Model or Exception
   */
  deleteWorkspace(workspaceId: number, delete_data = false): Promise<APIResponse> {
    const url = `${INBOX_API_BASE}/workspaces/${workspaceId}?delete_data=${delete_data}`;

    return APIClient.delete(url, CONFIG_OVERRIDE).then((response) => {
      return response.data;
    });
  }

  /**
   * * Patch on  /api/v2/workspaces
   * @returns Promise of type APIResponse Model or Exception
   */
  updateWorkspace(workspaceId: number, workspaceName: string, defaultMarker: boolean): Promise<APIResponse> {
    const url = `${INBOX_API_BASE}/workspaces/`;

    const body = {
      id: workspaceId,
      name: workspaceName,
      default: defaultMarker,
    };

    return APIClient.patch(url, body, CONFIG_OVERRIDE).then((response) => {
      return response.data;
    });
  }
}

import { Drawer } from "@mui/material";
import React from "react";
import { DisableFeatureContext, DisableFeatureProviderType } from "../../../../../../contexts/DisableFeatureContext";
import Button from "../../../../../library/Button/Button";
import { TextArea } from "../../../../../library/TextArea/TextArea";
import "./../ActivityFeedActions.scss";
import { useTranslation } from "react-i18next";

interface ApprovalRequestReviewProps {
  open: boolean;
  onClose: VoidFunction;
  onClickUpdate: (note: string) => void;
  loader: boolean;
}

const ApprovalRequestReview: React.FC<ApprovalRequestReviewProps> = ({ open, onClose, onClickUpdate, loader }) => {
  const [note, setNote] = React.useState<string>("");
  const disableFeatureContext = React.useContext(DisableFeatureContext) as DisableFeatureProviderType;
  const { t } = useTranslation();

  const onChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value);
  };

  // useEffect for disable shortcut

  React.useEffect(() => {
    const functionToExec =
      typeof disableFeatureContext.setDisableKeyboardShortcut === "function" ? disableFeatureContext.setDisableKeyboardShortcut : () => "";
    if (open) {
      functionToExec(true);
    } else {
      functionToExec(false);
    }
    return () => functionToExec(false);
  }, [open]);

  return (
    <Drawer className="activity-feed-action-drawer" open={open} anchor="right" disablePortal onClose={onClose}>
      <div className="activity-flyout">
        <h3 className="header">{t("modalAndFlyout.approvalRequestReview.header", { ns: "activities" })}</h3>
        <p className="subheader body1">{t("modalAndFlyout.approvalRequestReview.subHeader", { ns: "activities" })}</p>
        <TextArea placeholder="Add a note" onChange={onChangeTextArea} />
        <div className="btn-grp">
          <div></div>
          <Button size="lg" onClick={() => onClickUpdate(note)} variant="primary" loading={loader}>
            {t("modalAndFlyout.buttons.update", { ns: "activities" })}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default ApprovalRequestReview;

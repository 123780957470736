import React from "react";
import Button from "../../../../../../library/Button/Button";
import SecondaryActivity from "../../SecondaryActivity/SecondaryActivity";
import { SecondaryType } from "../../SecondaryActivity/SecondaryActivityTypes";
import { BouncedEmailProps } from "../ActivityItemVariantTypes";
import * as Labels from "../../../../../../../constants/config";

const BouncedEmail: React.FC<BouncedEmailProps> = ({ onClickRetrySend, creator, readonly, bounceErrorMsg, countryCode }) => {
  creator = { ...creator, name: "Message not delivered", message: null };
  return (
    <SecondaryActivity variant={SecondaryType.Accordion} creator={creator} countryCode={countryCode}>
      <p>{`${bounceErrorMsg}`}</p>
      {!readonly && (
        <Button variant="primary" size="sm" onClick={onClickRetrySend}>
          {Labels.BTN_RETRY_SENDING}
        </Button>
      )}
    </SecondaryActivity>
  );
};

export default BouncedEmail;

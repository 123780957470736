import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import i18n from "../../../i18n/i18n";
import { useLocation } from "react-router";

type SidebarHeadProps = {
  workspaceHomePath: string;
  einvoicesCount?: EInvoiceMetaCounts;
};

const SidebarLinks = ({
  workspaceHomePath,
  einvoicesCount
}: SidebarHeadProps): React.ReactElement => {
  const { t } = i18n;
  const { pathname } = useLocation();
  const eInvoiceType = pathname.includes("receivedEInvoices") || pathname.includes("received_einvoices") ? "received" : "sent";
  const [activeTab, setActiveTab] = useState<string>(eInvoiceType);

  return (
    <>
      <NavLink to={`${workspaceHomePath}/activities/receivedEInvoices?sort=payment_due_date+DESC&page=1`} exact={true}>
        <div
          className={`nav-link-item ${activeTab === "received" ? "active" : ""}`}
          onClick={() => {
            setActiveTab("received");
          }}
        >
          <span>{t("sidebar.activities.options.receivedEInvoices", { ns: "home" })}</span>
          <span className="activities-count">{einvoicesCount?.received?.total ?? "" }</span>
        </div>
      </NavLink>
      <NavLink to={`${workspaceHomePath}/activities/sentEInvoices?sort=payment_due_date+DESC&page=1`} exact={true}>
        <div
          className={`nav-link-item ${activeTab === "sent" ? "active" : ""}`}
          onClick={() => {
            setActiveTab("sent");
          }}
        >
          <span>{t("sidebar.activities.options.sentEInvoices", { ns: "home" })}</span>
          <span className="activities-count">{einvoicesCount?.sent?.total ?? ""}</span>
        </div>
      </NavLink>
    </>
  );
};

export default SidebarLinks;

import React from "react";
import { Input } from "../../../Input/Input";
import { SearchFilterProps } from "../../Interfaces/TableActionInterfaces";

const DefaultFilter: React.FC<SearchFilterProps> = ({ filterValue, setFilterValue, onPressKeyDown, placeholder }) => {
  return (
    <Input
      placeholder={placeholder ?? "Enter Value.."}
      defaultValue={filterValue}
      onChange={(e) => setFilterValue(e.target.value)}
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => onPressKeyDown(e)}
    />
  );
};

export default DefaultFilter;

import React from "react";
import { WorkspaceType } from "../../../../types/enums";
import _ from "underscore";
import i18n from "./../../../../i18n/i18n";

type RecordCountChipType = {
  count: boolean;
  counterAccessor: string;
};

export type OptionListItemType = {
  label: string;
  id: string;
  icon: string;
  exact: boolean;
  className: string;
  countChip?: RecordCountChipType;
  workspacesApplicable: WorkspaceType[];
};

export type OptionType = {
  title: string;
  accordian: boolean;
  className: string;
  icon: string;
  path: string[];
  workspacesApplicable: WorkspaceType[];
  listItems: OptionListItemType[];
};

const { t } = i18n;

const useSidebar = () => {
  const options: OptionType[] = React.useMemo(
    () => [
      {
        title: t("sidebar.activities.label", { ns: "home" }),
        accordian: true,
        icon: "inbox",
        className: "activity-streams-nav",
        path: [
          "activities/mine",
          "activities/all/waitingForResponse",
          "activities/unassigned",
          "activities/all",
          "activities/all/snoozed",
          "activities/all/closed",
          "activities/spam",
        ],
        workspacesApplicable: [WorkspaceType.AR, WorkspaceType.AP, WorkspaceType.AW],
        listItems: [
          {
            label: t("sidebar.activities.options.assignedToMe", { ns: "home" }),
            id: "activities/mine?sort=last_activity_at+DESC&page=1",
            icon: "",
            exact: true,
            className: "",
            countChip: {
              count: true,
              counterAccessor: "mine_count",
            },
            workspacesApplicable: [WorkspaceType.AR, WorkspaceType.AP, WorkspaceType.AW],
          },
          {
            label: t("sidebar.activities.options.awaitingResponse", { ns: "home" }),
            id: "activities/all/waitingForResponse?sort=last_activity_at+DESC&page=1",
            icon: "",
            exact: true,
            className: "",
            countChip: {
              count: false,
              counterAccessor: "",
            },
            workspacesApplicable: [WorkspaceType.AR, WorkspaceType.AP, WorkspaceType.AW],
          },
          {
            label: t("sidebar.activities.options.unassigned", { ns: "home" }),
            id: "activities/unassigned?sort=last_activity_at+DESC&page=1",
            icon: "",
            exact: true,
            className: "",
            countChip: {
              count: true,
              counterAccessor: "unassigned_count",
            },
            workspacesApplicable: [WorkspaceType.AR, WorkspaceType.AP, WorkspaceType.AW],
          },
          {
            label: t("sidebar.activities.options.all", { ns: "home" }),
            id: "activities/all?sort=last_activity_at+DESC&page=1",
            icon: "",
            exact: true,
            className: "",
            countChip: {
              count: true,
              counterAccessor: "all_count",
            },
            workspacesApplicable: [WorkspaceType.AR, WorkspaceType.AP, WorkspaceType.AW],
          },
          {
            label: t("sidebar.activities.options.snoozed", { ns: "home" }),
            id: "activities/all/snoozed?sort=last_activity_at+DESC&page=1",
            icon: "",
            exact: true,
            className: "",
            countChip: {
              count: false,
              counterAccessor: "",
            },
            workspacesApplicable: [WorkspaceType.AR, WorkspaceType.AP, WorkspaceType.AW],
          },
          {
            label: t("sidebar.activities.options.completed", { ns: "home" }),
            id: "activities/all/closed?sort=last_activity_at+DESC&page=1",
            icon: "",
            exact: true,
            className: "",
            countChip: {
              count: false,
              counterAccessor: "",
            },
            workspacesApplicable: [WorkspaceType.AR, WorkspaceType.AP, WorkspaceType.AW],
          },
          {
            label: t("sidebar.activities.options.spam", { ns: "home" }),
            id: "activities/spam?sort=last_activity_at+DESC&page=1",
            exact: true,
            className: "",
            countChip: {
              count: false,
              counterAccessor: "",
            },
            icon: "",
            workspacesApplicable: [WorkspaceType.AR, WorkspaceType.AP, WorkspaceType.AW],
          },
        ],
      },
      {
        title: t("sidebar.transactions.label", { ns: "home" }),
        accordian: true,
        className: "",
        icon: "transactions",
        path: ["invoices", "customer-payments", "bills", "vendor-payments", "payments"],
        workspacesApplicable: [WorkspaceType.AR, WorkspaceType.AP, WorkspaceType.AW],
        listItems: [
          {
            label: t("sidebar.transactions.options.invoices", { ns: "home" }),
            id: "invoices?sort=invoice_date+DESC",
            icon: "",
            exact: false,
            className: "",
            countChip: {
              count: false,
              counterAccessor: "",
            },
            workspacesApplicable: [WorkspaceType.AR, WorkspaceType.AW],
          },
          {
            label: t("sidebar.transactions.options.customerPayments", { ns: "home" }),
            id: "customer-payments?sort=payment_date+DESC%2C+reference_code+DESC",
            icon: "",
            exact: false,
            className: "",
            countChip: {
              count: false,
              counterAccessor: "",
            },
            workspacesApplicable: [WorkspaceType.AW],
          },
          {
            label: t("sidebar.transactions.options.bills", { ns: "home" }),
            id: "bills?sort=invoice_date+DESC%2C+invoice_number+DESC",
            icon: "",
            exact: false,
            className: "",
            countChip: {
              count: false,
              counterAccessor: "",
            },
            workspacesApplicable: [WorkspaceType.AP, WorkspaceType.AW],
          },
          {
            label: t("sidebar.transactions.options.vendorPayments", { ns: "home" }),
            id: "vendor-payments?sort=payment_date+DESC%2C+reference_code+DESC",
            icon: "",
            exact: false,
            className: "",
            countChip: {
              count: false,
              counterAccessor: "",
            },
            workspacesApplicable: [WorkspaceType.AW],
          },
          {
            label: t("sidebar.transactions.options.payments", { ns: "home" }),
            id: "payments?sort=payment_date+DESC%2C+reference_code+DESC",
            icon: "",
            exact: false,
            className: "",
            countChip: {
              count: false,
              counterAccessor: "",
            },
            workspacesApplicable: [WorkspaceType.AR, WorkspaceType.AP],
          },
        ],
      },
      {
        title: t("sidebar.connections.label", { ns: "home" }),
        className: "accordion-connections",
        icon: "connections",
        accordian: true,
        path: ["customers", "vendors", "connections", "internal"],
        workspacesApplicable: [WorkspaceType.AR, WorkspaceType.AP, WorkspaceType.AW],
        listItems: [
          {
            label: t("sidebar.connections.options.customers", { ns: "home" }),
            id: "customers?sort=amount_past_due+DESC&page=1",
            icon: "", // update
            exact: false,
            className: "",
            workspacesApplicable: [WorkspaceType.AR, WorkspaceType.AW],
          },
          {
            label: t("sidebar.connections.options.vendors", { ns: "home" }),
            id: "vendors?sort=amount_billed_past_thirty_days+DESC&page=1",
            icon: "", // update
            exact: false,
            className: "",
            workspacesApplicable: [WorkspaceType.AP, WorkspaceType.AW],
          },
          {
            label: t("sidebar.connections.options.otherConnections", { ns: "home" }),
            id: "connections?sort=last_activity_at+DESC&page=1",
            icon: "", // update
            exact: false,
            className: "",
            workspacesApplicable: [WorkspaceType.AR, WorkspaceType.AP, WorkspaceType.AW],
          },
          {
            label: t("sidebar.connections.options.myCompany", { ns: "home" }),
            id: "internal?sort=last_activity_at+DESC&page=1",
            icon: "", // update
            exact: false,
            className: "",
            workspacesApplicable: [WorkspaceType.AR, WorkspaceType.AP, WorkspaceType.AW],
          },
        ],
      },
    ],
    [i18n.language]
  );
  const sidebar = React.useMemo(
    () => ({
      options,
      fetchMenu(requiredWorkspace: WorkspaceType) {
        return this.options
          .filter(
            (option: OptionType) =>
              option.workspacesApplicable.includes(requiredWorkspace) && {
                ..._.omit(option, ["worksapcesApplicable"]),
              }
          )
          .map((option: OptionType) => ({
            ...option,
            listItems: option.listItems.filter(
              (item: OptionListItemType) =>
                item.workspacesApplicable.includes(requiredWorkspace) && {
                  ..._.omit(item, ["worksapcesApplicable", "countChip"]),
                  count: item.countChip?.count,
                }
            ),
          }));
      },
      parseMenuAccordianOptions(options: OptionListItemType[], iconGetter: (key: string) => JSX.Element | undefined, counts?: Record<string, any>) {
        return options.map((option) => ({
          count: option.countChip?.count ? counts?.[option.countChip?.counterAccessor]?.["total"] : undefined,
          ..._.omit(option, ["workspacesApplicable", "countChip"]),
          icon: iconGetter(option.icon as keyof typeof iconGetter),
        }));
      },
    }),
    [i18n.language]
  );

  return sidebar;
};

export default useSidebar;

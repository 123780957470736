import { TEMPLATE_TIMING_CONSTANTS } from "../../constants/NumericConstants";
import BaseTemplate from "./BaseTemplate";

class ARApprovalRequest extends BaseTemplate {

  protected fromTime = TEMPLATE_TIMING_CONSTANTS.THREE_MIN_THIRTY_SEC;
  protected toTime = TEMPLATE_TIMING_CONSTANTS.FIVE_MIN;
}

export default ARApprovalRequest;

import React from "react";
import _ from "underscore";
import { DEFAULT_NUMERIC_VALUES } from "../constants/NumericConstants";
import TableUtils from "../utils/TableUtils/TableUtils";
import {
  TableInterface,
  TableConfiguration,
  TableRow,
  PageCount,
} from "../components/library/AddNewActivityDropdown/Attachments/interfaces/TableConfigurationInterfaces";

/**
 * Hook provides consistent structure for table specific details
 * such as data, filters, page etc. and bind the table data with it's
 * type. purpose of such handldling is to have plug and play table UI
 * and eventually persist the table states when a user switches between
 * multiple table view.
 *
 * @returns table specific states & respective setter functions
 */
function useTable<T extends Record<string, unknown>>({
  columns,
  fetch,
  parser,
  ransacQuery: isRansacRequired,
}: TableInterface<T>): TableConfiguration<T> {
  const [records, setRecords] = React.useState<TableRow<Partial<T>>[]>([]);
  const [filters, setFilters] = React.useState<SearchFilter[]>([]);
  const [order, setOrder] = React.useState<SortOrder>();
  const [pageCount, setPageCount] = React.useState<PageCount>({
    current: DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
    total: DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
  });
  const [selected, setSelected] = React.useState<TableRow<Partial<T>>[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [selectedAll, setSelectedAll] = React.useState<boolean>(false);

  /**
   * This function generates the sort query based on the table
   * sort state. If ransacQuery is true, it will return sort query
   * as ransac query otherwise returns searchlight query.
   *
   * @param order table sort order
   * @param ransacQuery true | false
   * @returns sort query
   */
  const sortOrderParser = (order: SortOrder | undefined, ransacQuery = false) => {
    if (!_.isEmpty(order) && !_.isUndefined(order) && order.sortKey) {
      if (ransacQuery) {
        return `qs[s]=${order.sortKey} ${order.sortOrder}`.toLocaleLowerCase();
      }
      return `${order.sortKey} ${order.sortOrder}`;
    }
  };

  /**
   * generic fetch call for the table
   *
   * @param initialLoad indicates initial api call
   * @returns parsed table records
   */
  const getRecords = async (initialLoad = false) => {
    setLoading(true);

    let parsedResults: ((prevState: TableRow<T>[]) => TableRow<T>[]) | TableRow<Partial<T>>[] = [];
    let response: FetchResult = {} as FetchResult;
    initialLoad ?? setRecords([]);

    try {
      response = await fetch(
        initialLoad ? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO : pageCount.current + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
        sortOrderParser(order, true) ?? "",
        TableUtils.columnFilterParser(filters, isRansacRequired)
      );
      parsedResults = parser(response?.records ?? []);
    } catch (error) {
      console.error("fetch called failed for page: ", pageCount.current, "error: ", error);
    } finally {
      setRecords(initialLoad ? parsedResults : [...records, ...parsedResults]);
      setPageCount({
        current: response?.pageNumber as number,
        total: Math.ceil(
          ((response?.totalCount as number) ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) /
            ((response?.pageSize as number) ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ONE)
        ),
      });
      setLoading(false);
      setSelectedAll(false);
    }
    return parsedResults;
  };

  /**
   * load more for paginated records.
   */
  const loadMoreRecords = async () => {
    await getRecords();
  };

  /**
   * Table Event Handlers
   */
  /**
   * This function updates the selected state of a record based on
   * checkbox change event. It also takes selected all state in
   * consideration i.e. if all records are selected and user deselect
   * any one selected all state will be updated to false.
   *
   * @param event record checkbox change event
   * @param row row/record data
   */
  const onClickSelect = (event: React.ChangeEvent<HTMLInputElement>, row: TableRow<Partial<T>>) => {
    if (!event.target.checked && selectedAll) {
      setSelectedAll(false);
    }
    // update data
    setRecords([...records.map((val: TableRow<Partial<T>>) => (val.id === row.id ? { ...val, isSelected: event.target.checked } : val))]);
    // if unchecked remove from selection other wise add it
    setSelected(!event.target.checked ? [...selected.filter((val: TableRow<Partial<T>>) => val.id !== row.id)] : [...selected, row]);
  };

  /**
   * This function marks all of the table records as selected if
   * select all checkbox is checked, otherwise deselects all records.
   *
   * @param event select all checkbox change event
   */
  const onClickSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      // if available records > selected => more records to be selected
      if (records.length === selected.length) return;
      // update data state
      setRecords(records.map((val: TableRow<Partial<T>>) => (!val.isSelected ? { ...val, isSelected: true } : val)));
      setSelected([...records]);
      setSelectedAll(true);
    } else {
      setRecords(records.map((val: TableRow<Partial<T>>) => (val.isSelected ? { ...val, isSelected: false } : val)));
      setSelected([]);
      setSelectedAll(false);
    }
  };

  const getUnchangedFilters = (newFilters: SearchFilter[]) => {
    return filters.filter((item: SearchFilter) => !newFilters.filter((filter: SearchFilter) => filter.filterKey === item.filterKey).length);
  };

  /**
   * This function takes in the new filter applied on the column
   * if filter already exists it updates the value, else appends
   * new filter in the filters array.
   *
   * @param newFilter new table column filter applied by user
   */
  const updateFilters = (newFilters: SearchFilter[]) => {
    // append unchanged filters
    const filters = getUnchangedFilters(newFilters);
    // update filter without clear property
    filters.push(...newFilters.filter((filter: SearchFilter) => !filter.clear));
    setFilters(filters);
  };

  /**
   * This function sets the table sorting order.
   *
   * @param sort column sort order
   */
  const onClickSort = (sort: SortOrder) => {
    setOrder(sort);
  };

  const onClickFilter = (newFilters: SearchFilter[], clear = false) => {
    clear ? setFilters([...getUnchangedFilters(newFilters)]) : updateFilters(newFilters);
  };

  /**
   * if table filters or order is applied/updated, fetch data with
   * updated filters or sort order.
   */
  React.useEffect(() => {
    getRecords(true);
  }, [filters, order]);

  return {
    columns,
    loading,
    meta: {
      filters,
      order,
      selected,
      selectedAll,
    },
    data: {
      records,
      pageCount,
      getRecords,
      loadMoreRecords,
    },
    handlers: {
      onClickFilter,
      onClickSort,
      onClickSelect,
      onClickSelectAll,
    },
  };
}

export default useTable;

import React from "react";

/**
 * @customhook useCheckContentOverflow
 * A custom hook that check whether content has overflowed or not
 * @param bodyRef: ref of the element for which we need to check the overflow
 * @param recheckDependency:  dependency to re-evaluate
 */
const useCheckContentOverflow = (bodyRef: React.RefObject<any>, recheckDependency: string | number | boolean[]) => {
  const [isOverflowed, setIsOverflowed] = React.useState(false);

  React.useEffect(() => {
    const checkOverflow = () => {
      if (bodyRef.current) {
        const body = bodyRef.current;
        setIsOverflowed(body.scrollHeight > body.clientHeight);
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [recheckDependency]);

  return {
    isOverflowed,
  };
};

export default useCheckContentOverflow;

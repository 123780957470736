import React from "react";
import { CustomTabsWithCountProps, TabListType } from "../types/email.template.popover.types";
import { CustomTabsWithCountContainer } from "../styled/email.template.styled";
import { Tab } from "@mui/material";
import { NUMERIC_VALUES } from "../../AtomicComponents/constants/numeric.constants";

//Return tab content with count
const TabLabelWithCount: React.FC<{ label: string; count: number }> = ({ label, count }) => {
  return (
    <div className="label-container">
      <span className="label-name">{label}</span>
      <span className="label-count">{count}</span>
    </div>
  );
};

/**
 * It's an custom tabs to support the customize tab with text and count
 * @param tabList: list of tab details
 * @param selectedTabId: current selected tab
 * @param onChangeTabs: funct to handle the change tab event
 * @param isCountRequired:[optional]- boolean value to identify whether needs to show count along with name or not.
 * other rest props of type TabsProps
 */
const CustomTabsWithCount: React.FC<CustomTabsWithCountProps> = ({ tabList, selectedTabId, onChangeTabs, isCountRequired, ...rest }) => {
  return (
    <>
      <CustomTabsWithCountContainer value={selectedTabId} onChange={onChangeTabs} {...rest}>
        {tabList?.map((item: TabListType) => (
          <Tab
            key={item.id}
            label={isCountRequired ? <TabLabelWithCount label={item.tabsName} count={item?.count ?? NUMERIC_VALUES.CONSTANT_ZERO} /> : item.tabsName}
            value={item.id}
          />
        ))}
      </CustomTabsWithCountContainer>
    </>
  );
};

export default CustomTabsWithCount;

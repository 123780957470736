import React from "react";
import { useRouteMatch } from "react-router-dom";
import { VIEW_CATEGORIES, WorkspaceType } from "../../../../types/enums";
import ActivitiesTable from "../../../Activities/ActivitiesTable/ActivitiesTable";
import activityTableConfigs, { ActivityTableViews } from "../../../Activities/ActivitiesTable/activityTable.configs";
import useWorkspaceConfigurations from "../../../../hooks/useWorkspaceConfigurations";
import useProcessTableConfigs from "../../../../hooks/useProcessTableConfigs";

/**
 * * Define the Activities Tab of the Customer Detail's page
 */
export default function Activities(): React.ReactElement {
  const { path } = useRouteMatch();

  //it return category view name for suggestion history
  const getViewCategory = () => {
    const categoryMap: { [key: string]: VIEW_CATEGORIES } = {
      "internal/active": VIEW_CATEGORIES.ACTIVE_INTERNAL_ACTIVITES,
      "internal/archived": VIEW_CATEGORIES.ARCHIVED_INTERNAL_ACTIVITES,
      "connections/active": VIEW_CATEGORIES.ACTIVE_OTHER_ACTIVITES,
      "connections/archived": VIEW_CATEGORIES.ARCHIVED_OTHER_ACTIVITES,
      "connections/spam": VIEW_CATEGORIES.SPAM_OTHER_ACTIVITES,
      "connections/fraud": VIEW_CATEGORIES.FRAUD_OTHER_ACTIVITES,
      "vendors/active": VIEW_CATEGORIES.ACTIVE_VENDOR_ACTIVITES,
      "vendors/archived": VIEW_CATEGORIES.ARCHIVED_VENDOR_ACTIVITES,
      "vendors/spam": VIEW_CATEGORIES.SPAM_VENDOR_ACTIVITES,
      "vendors/fraud": VIEW_CATEGORIES.FRAUD_VENDOR_ACTIVITES,
      "customers/active": VIEW_CATEGORIES.ACTIVE_CUSTOMER_ACTIVITES,
      "customers/archived": VIEW_CATEGORIES.ARCHIVED_CUSTOMER_ACTIVITES,
      "customers/spam": VIEW_CATEGORIES.SPAM_CUSTOMER_ACTIVITES,
    };

    for (const pathKey in categoryMap) {
      if (path.includes(pathKey)) {
        return categoryMap[pathKey];
      }
    }
    // Handle the case when no category is found
    return "";
  };

  const { selectedWorkspace } = useWorkspaceConfigurations();
  const { /* tableConfigs, */ columns: columnsConfigs } = activityTableConfigs();
  const currentWorkspaceType = (selectedWorkspace.workspace_type_route ?? WorkspaceType.AR) as WorkspaceType;
  const columns = useProcessTableConfigs(columnsConfigs, currentWorkspaceType, ActivityTableViews.ConnectionActivitiesTable);

  return <ActivitiesTable columns={columns} assigneeType={"all"} route={"all"} category={getViewCategory()} />;
}

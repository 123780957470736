/*eslint-disable no-magic-numbers*/
import { styled as muiStyled } from "@mui/material/styles";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import { pxToRem } from "../../theme/theme";
import React from "react";

export const InfoIconContainer = styled("div", {
  name: "info-icon",
  slot: "container",
})({
  width: pxToRem(14),
  height: pxToRem(14),
  cursor: "pointer",
});

export const ToolTipContainer = muiStyled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
))({
  boxShadow: `0 -${pxToRem(2)} ${pxToRem(4)} rgba(0, 0, 0, 0.08), 0 ${pxToRem(4)} ${pxToRem(4)} rgba(0, 0, 0, 0.08)`,
  backgroundColor: "#FFFFFF",
  padding: ` ${pxToRem(12)} ${pxToRem(16)}`,
  color: "#4D4D4D",
});

import React from "react";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../../../../../../../constants/NumericConstants";
import { TooltipTypes } from "../../../../../../../../constants/TooltipConstants";
import { EmojiActionIds, EmojiOptions } from "../../../../../../../../types/enums";
import CustomTooltip from "../../../../../../../library/CustomTooltip/CustomTooltip";
import { ReactionsProps } from "../HelperTypes";

const Reactions: React.FC<ReactionsProps> = ({ reactions, onClickReaction }) => {
  const onClick = (option: EmojiOptions) => onClickReaction?.(option, EmojiActionIds.tag);

  const emojiMapper: { [key: string]: { symbol: string; text: string; onClick: () => void } } = React.useMemo(
    () => ({
      [EmojiOptions.THUMBS_UP]: { symbol: "👍", text: "Thumbs Up", onClick: () => onClick?.(EmojiOptions.THUMBS_UP) },
      [EmojiOptions.THUMBS_DOWN]: {
        symbol: "👎",
        text: "Thumbs Down",
        onClick: () => onClick?.(EmojiOptions.THUMBS_DOWN),
      },
      [EmojiOptions.HAPPY]: { symbol: "😃", text: "Happy Face", onClick: () => onClick?.(EmojiOptions.HAPPY) },
      [EmojiOptions.SAD]: { symbol: "😦", text: "Sad Face", onClick: () => onClick?.(EmojiOptions.SAD) },
      [EmojiOptions.CELEBRATE]: {
        symbol: "🎉",
        text: "Let's Celebrate",
        onClick: () => onClick?.(EmojiOptions.CELEBRATE),
      },
      [EmojiOptions.HEART]: { symbol: "❤️", text: "Love", onClick: () => onClick?.(EmojiOptions.HEART) },
    }),
    [onClick]
  );

  const getTooltip = (emoji: string, reactionGivers: Array<{ [key: string]: string | number | null }>) => {
    if (reactionGivers.length > DEFAULT_NUMERIC_VALUES.DEFAULT_TWO) return `${reactionGivers.length} reacted with ${emojiMapper[emoji].text}`;
    return `${reactionGivers[0]?.name}${reactionGivers[1]?.name ? ` and ${reactionGivers[1].name}` : ""} reacted with ${emojiMapper[emoji].text}`;
  };

  return (
    <>
      {reactions
        ? Object.keys(reactions).map((reaction) =>
            reactions[reaction].count > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO ? (
              <CustomTooltip
                type={
                  getTooltip(reaction, reactions[reaction].givers)?.length > NUMERIC_VALUES.CONSTANT_TWENTY ? TooltipTypes.RICH : TooltipTypes.PLAIN
                }
                title={getTooltip(reaction, reactions[reaction].givers)}
                key={reaction}
              >
                <div
                  className={reactions[reaction].highlight ? "reaction-tag-highlight" : "reaction-tag"}
                  onClick={(e) => {
                    e.stopPropagation();
                    emojiMapper[reaction].onClick();
                  }}
                >
                  <span className="reaction">{emojiMapper[reaction].symbol}</span> {reactions[reaction].count}
                </div>
              </CustomTooltip>
            ) : null
          )
        : null}
    </>
  );
};

export default Reactions;

import React from "react";
import SecondaryActivity from "../../SecondaryActivity/SecondaryActivity";
import { SecondaryType } from "../../SecondaryActivity/SecondaryActivityTypes";
import { BouncedEmailProps } from "../ActivityItemVariantTypes";
import { Button } from "../../../../../../library/AtomicComponents/atomic/Button";
import { useTranslation } from "react-i18next";

const BouncedEmail: React.FC<BouncedEmailProps> = ({ 
  onClickRetrySend, 
  creator, 
  readonly, 
  bounceErrorMsg, 
  countryCode,
  retried
 }) => {
  creator = { ...creator, name: "Message not Delivered", message: null };
  const { t } = useTranslation();

  return (
    <SecondaryActivity variant={SecondaryType.DetailedWithAction} creator={creator} countryCode={countryCode} type="Error">
      <p>{`${bounceErrorMsg}`}</p>
      {(!readonly && !retried) && (
        <Button variant="primary" size="small" onClick={onClickRetrySend}>
          {t("activityDetails.feedItems.bouncedEmail.retrySendingButton", { ns: "activities" })}
        </Button>
      )}
    </SecondaryActivity>
  );
};

export default BouncedEmail;

/*eslint-disable*/
import React from "react";
import { ColumnConfigs, SortConfigType } from "../types/table.types";
import { ColumnSortHook } from "../types/hooks.types";

/*
 * Sorting Functionality Implementation
 */
const useColumnSort: ColumnSortHook = (defaultSortKey?: string) => {
  const params = new URLSearchParams(window.location.search);

  // Setting the initial sorting config
  const urlSortValue = React.useRef<SortConfigType>({
    active: Boolean(params.get("sort")),
    sortField: params.get("sort")?.split?.(" ")?.[0] || "", // SortField Value Default : "InvoiceNumber ASC"
    sortBy: (params.get("sort")?.split?.(" ")?.[1] as "asc" | "desc") || "asc",
  });

  const sortValues = React.useRef<SortConfigType>({
    active: Boolean(defaultSortKey),
    sortField: defaultSortKey?.split?.(" ")?.[0] || "", // SortField Value Default : "InvoiceNumber ASC"
    sortBy: (defaultSortKey?.split?.(" ")?.[1] as "asc" | "desc") || "asc",
  });

  const [sortConfig, setSortConfig] = React.useState<SortConfigType>(urlSortValue.current);
  const [query, setQuery] = React.useState<string>(defaultSortKey ?? "");

  // onclick Sort Icon Handler
  const onClickSortHandler = (col: ColumnConfigs) => {
    let sortString = defaultSortKey ?? "";
    const accessor = col?.sortField || col?.accessor;

    if (!sortConfig?.active || col?.accessor !== sortConfig?.sortField) {
      setSortConfig({
        ...sortConfig,
        active: true,
        sortField: col?.accessor,
        sortBy: "desc",
      });
      sortString = accessor + " desc";
    } else if (sortConfig?.active && sortConfig?.sortBy?.toLowerCase().replace (/,/g, "") === "desc") {
      setSortConfig({
        ...sortConfig,
        sortBy: "asc",
      });

      sortString = accessor + " asc";
    } else if (sortConfig?.active && sortConfig?.sortBy?.toLowerCase().replace (/,/g, "") === "asc") {
      if (sortString?.includes(accessor)) {
        setSortConfig({
          ...sortConfig,
          sortBy: "desc",
        });
        sortString = accessor + " desc";
      } else {
        setSortConfig(sortValues.current);
      }
    }
    const sortBy = col?.secondarySort ? new Array(col?.secondarySort) : [];
    if (sortString?.length > 0) {
      if (!sortBy?.[0]?.split?.(" ")?.[0].includes(sortString?.split?.(" ")?.[0])) {
        sortBy.unshift(sortString);
      } else {
        sortBy[0] = sortString;
      }
    }
    // update table url with sort query
    const url = new URL(window.location.href);
    url.searchParams.set("sort", sortString);
    window.history.pushState({}, "", url.toString());
    setQuery(sortString);
  };

  return {
    sort: sortConfig,
    query,
    sortClick: onClickSortHandler,
  };
};

export default useColumnSort;

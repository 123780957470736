/*eslint-disable no-magic-numbers*/
import { createTheme } from "@mui/material";
import styles from "../css/_export.module.scss";
import { TypographyOptions } from "@mui/material/styles/createTypography";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    h4Reg: true;
    h4Med: true;
    h5: false;
    h6: false;
    body1Bold: true;
    body1Med: true;
    body1Reg: true;
    captionReg: true;
    captionMed: true;
    captionSm: true;
    subtitle1: false;
    subtitle2: false;
    overline: false;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  captionReg: React.CSSProperties;
  body1Reg: React.CSSProperties;
  body1Bold: React.CSSProperties;
  body1Med: React.CSSProperties;
}

/**
 * @param px input px as per 1440 * 1024 resolution
 */

export const primaryTheme = createTheme({
  typography: {
    captionReg: {
      fontFamily: "Work Sans",
      fontSize: "0.75rem",
      fontWeight: 400,
      fontStyle: "normal",
      lineHeight: "1rem",
    },
    body1Bold: {
      fontFamily: "Work Sans",
      fontSize: "0.875rem",
      fontWeight: 700,
      fontStyle: "normal",
      lineHeight: "1.25rem",
    },
    body1Reg: {
      fontFamily: "Work Sans",
      fontSize: "0.875rem",
      fontWeight: 400,
      fontStyle: "normal",
      lineHeight: "1.25rem",
    },
    body1Med: {
      fontFamily: "Work Sans",
      fontSize: "0.875rem",
      fontWeight: 500,
      fontStyle: "normal",
      lineHeight: "1.25rem",
    },
    captionMed: {
      fontFamily: "Work Sans",
      fontSize: "0.875rem",
      fontWeight: 500,
      fontStyle: "normal",
      lineHeight: "1rem",
    },
  } as ExtendedTypographyOptions,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paperAnchorRight: {
          padding: styles.large,
          width: "19.5rem",
          boxShadow: "0 0.5rem 0.75rem rgba(0, 0, 0, 0.1), 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1)",
        },
        modal: { backgroundColor: "rgba(0, 0, 0, 0)" },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          border: "0.0625rem solid transparent",
          padding: "0",
          borderRadius: "0.125rem",
          "&.Mui-focused": {
            border: `0.0625rem solid ${styles.grey70}`,
          },
          "&.MuiAutocomplete-tag": {
            margin: "0.1875rem",
            maxWidth: "calc(100% - 0.375rem)",
          },
        },
        inputRoot: {
          backgroundColor: styles.grey98,
          color: styles.primary,
          fontFamily: styles.primaryFontFamily,
          fontSize: styles.fontSizeSM,
          height: "2.5rem",
          width: "100%",
          padding: "0 !important",
          borderRadius: "0.125rem",
          "&:hover": {
            backgroundColor: styles.grey96,
          },
          "&.Mui-focused": {
            backgroundColor: styles.grey93,
          },
          "&.Mui-focused input": {
            color: styles.primary,
          },
          "&.Mui-focused input:focus::placeholder": {
            color: "transparent",
          },
          ".MuiAutocomplete-endAdornment": {
            marginRight: "0.75rem",
            button: {
              "&:hover": {
                backgroundColor: "transparent",
              },
            },
            svg: {
              fill: styles.grey60,
            },
            top: "unset",
          },
          ".MuiInput-input": {
            padding: "0",
          },
          ".MuiAutocomplete-clearIndicator": {
            display: "none",
          },
        },
        input: {
          textIndent: "0.75rem",
          height: "100%",
          fontSize: styles.fontSizeSM,
          paddingRight: "2.9375rem !important",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        },
        listbox: {
          maxHeight: "12.8125rem",
          fontFamily: styles.primaryFontFamily,
          fontSize: styles.fontSizeSM,
          fontWeight: "normal",
          boxShadow: "none",
          padding: "0.5rem 0",
          "&::-webkit-scrollbar-track": {
            backgroundColor: styles.white100,
          },

          "&::-webkit-scrollbar": {
            width: "0.5rem",
            backgroundColor: styles.white100,
          },

          "&::-webkit-scrollbar-thumb": {
            borderRadius: "0.125rem",
            backgroundColor: styles.grey96,
            height: "5rem",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: styles.grey90,
          },

          "& .MuiAutocomplete-option": {
            paddingTop: "0.375rem",
            paddingBottom: "0.375rem",
            paddingLeft: "1rem",
            paddingRight: "1rem",
          },
        },
        paper: {
          borderRadius: "0 0 0.125rem 0.125rem",
          boxShadow: "0 0.5rem 0.75rem rgba(0, 0, 0, 0.1), 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1)",
        },
        noOptions: {
          fontFamily: styles.primaryFontFamily,
          fontSize: styles.fontSizeSM,
          fontWeight: "normal",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiAlert: {
      variants: [
        {
          props: { severity: "success" },
          style: {
            minHeight: "3.5rem",
            maxWidth: "21.75rem",
            borderRadius: "0.25rem",
            backgroundColor: "#ECFDF5",
            boxShadow: "0 0.5rem 0.75rem rgba(0, 0, 0, 0.1), 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "0.625rem",
            padding: "0.25rem 0.25rem 0.25rem 0.75rem",
            border: "0.0625rem solid #065F46",
            "& .MuiAlert-icon": {
              color: styles.white100,
              display: "flex",
              alignItems: "center",
              margin: "0",
              padding: "0",
              width: "0.75rem",
              height: "0.75rem",
              "& .MuiSvgIcon-root": {
                width: "0.75rem",
                height: "0.75rem",
                fill: "#065F46",
              },
            },
            "& .MuiAlert-message": {
              color: "#065F46",
              fontFamily: styles.primaryFontFamily,
              fontWeight: "500",
              fontSize: "0.875rem",
              lineHeight: "1.25rem",
              padding: "0",
              display: "flex",
              alignItems: "center",
              hyphens: "auto",
              flexWrap: "wrap",
            },
            "& .MuiAlert-action": {
              display: "flex",
              alignItems: "flex-start",
              padding: "0",
              margin: "0",
              cursor: "pointer",
              color: styles.white100,
              height: "100%",
              "& .MuiButtonBase-root": {
                width: "1.5rem",
                height: "1.5rem",
                "& .MuiSvgIcon-root": {
                  width: "0.75rem",
                  height: "0.75rem",
                  fill: "#212121",
                },
              },
            },
          },
        },
        {
          props: { severity: "error" },
          style: {
            minHeight: "3.5rem",
            maxWidth: "21.75rem",
            borderRadius: "0.25rem",
            backgroundColor: "#FEF2F2",
            boxShadow: "0 0.5rem 0.75rem rgba(0, 0, 0, 0.1), 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "0.625rem",
            padding: "0.25rem 0.25rem 0.25rem 0.75rem",
            border: "1px solid #991B1B",
            "& .MuiAlert-icon": {
              color: styles.white100,
              display: "flex",
              alignItems: "center",
              margin: "0",
              padding: "0",
              width: "0.75rem",
              height: "0.75rem",
              "& .MuiSvgIcon-root": {
                width: "0.75rem",
                height: "0.75rem",
                fill: "#991B1B",
              },
            },
            "& .MuiAlert-message": {
              color: "#991B1B",
              fontFamily: styles.primaryFontFamily,
              fontWeight: "500",
              fontSize: "0.875rem",
              lineHeight: "1.25rem",
              padding: "0",
              display: "flex",
              alignItems: "center",
              hyphens: "auto",
              flexWrap: "wrap",
            },
            "& .MuiAlert-action": {
              display: "flex",
              alignItems: "flex-start",
              padding: "0",
              margin: "0",
              cursor: "pointer",
              color: styles.white100,
              height: "100%",
              "& .MuiButtonBase-root": {
                width: "1.5rem",
                height: "1.5rem",
                "& .MuiSvgIcon-root": {
                  width: "0.75rem",
                  height: "0.75rem",
                  fill: "#212121",
                },
              },
            },
          },
        },
        {
          props: { severity: "info" },
          style: {
            minHeight: "3.5rem",
            maxWidth: "21.75rem",
            borderRadius: "0.25rem",
            backgroundColor: "#EFF6FF",
            boxShadow: "0 0.5rem 0.75rem rgba(0, 0, 0, 0.1), 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "0.625rem",
            padding: "0.25rem 0.25rem 0.25rem 0.75rem",
            border: "0.0625rem solid #1E40AF",
            "& .MuiAlert-icon": {
              color: styles.white100,
              display: "flex",
              alignItems: "center",
              margin: "0",
              padding: "0",
              width: "0.75rem",
              height: "0.75rem",
              "& .MuiSvgIcon-root": {
                width: "0.75rem",
                height: "0.75rem",
                fill: "#1E40AF",
              },
            },
            "& .MuiAlert-message": {
              color: "#1E40AF",
              fontFamily: styles.primaryFontFamily,
              fontWeight: "500",
              fontSize: "0.875rem",
              lineHeight: "1.25rem",
              padding: "0",
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              hyphens: "auto",
            },
            "& .MuiAlert-action": {
              display: "flex",
              alignItems: "flex-start",
              padding: "0",
              margin: "0",
              cursor: "pointer",
              color: styles.white100,
              height: "100%",
              "& .MuiButtonBase-root": {
                width: "1.5rem",
                height: "1.5rem",
                "& .MuiSvgIcon-root": {
                  width: "0.75rem",
                  height: "0.75rem",
                  fill: "#212121",
                },
              },
            },
          },
        },
        {
          props: { severity: "warning" },
          style: {
            minHeight: "3.5rem",
            borderRadius: "0.25rem",
            maxWidth: "21.75rem",
            backgroundColor: "#FFF7ED;",
            boxShadow: "0 0.5rem 0.75rem rgba(0, 0, 0, 0.1), 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "0.625rem",
            padding: "0.25rem 0.25rem 0.25rem 0.75rem",
            border: "0.0625rem solid #9A3412",
            "& .MuiAlert-icon": {
              color: styles.white100,
              display: "flex",
              alignItems: "center",
              margin: "0",
              padding: "0",
              width: "0.75rem",
              height: "0.75rem",
              "& .MuiSvgIcon-root": {
                width: "0.75rem",
                height: "0.75rem",
                fill: "#9A3412",
              },
            },
            "& .MuiAlert-message": {
              color: "#9A3412",
              fontFamily: styles.primaryFontFamily,
              fontWeight: "500",
              fontSize: "0.875rem",
              lineHeight: "1.25re",
              padding: "0",
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              hyphens: "auto",
            },
            "& .MuiAlert-action": {
              display: "flex",
              alignItems: "flex-start",
              padding: "0",
              margin: "0",
              cursor: "pointer",
              color: styles.white100,
              height: "100%",
              "& .MuiButtonBase-root": {
                width: "1.5rem",
                height: "1.5rem",
                "& .MuiSvgIcon-root": {
                  width: "0.75rem",
                  height: "0.75rem",
                  fill: "#212121",
                },
              },
            },
          },
        },
      ],
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: styles.primary,
          fontFamily: styles.primaryFontFamily,
          fontWeight: 500,
          fontSize: styles.fontSizeXS,
          lineHeight: styles.lineHeightSM,
          color: styles.white100,
          fontStyle: "normal",
          padding: "0.5rem",
          borderRadius: "0.125rem",
          margin: "0.125rem",
          minWidth: "fit-content",
          maxWidth: "18.75rem",

          "& .MuiTooltip-arrow::before": {
            color: styles.primary,
          },
          "& .MuiTooltip-tooltipPlacementBottom": {
            color: styles.primary,
          },
        },
        popper: {
          "& .MuiTooltip-tooltipPlacementBottom": {
            marginTop: "0.875rem !important",
          },
          "& .MuiTooltip-tooltipPlacementTop": {
            marginBottom: "0.875rem !important",
          },
          "& .MuiTooltip-tooltipPlacementLeft": {
            marginRight: "0.875rem !important",
          },
          "& .MuiTooltip-tooltipPlacementRight": {
            marginLeft: "0.875rem !important",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: "0.5rem",
          "& > svg": {
            fill: styles.grey60,
          },
          "&:hover > svg": {
            fill: styles.primary,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: "3rem",
          padding: "0 1rem",
        },
        content: {
          margin: "0.75rem 0",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: "0.25rem 1rem",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "0.25rem",
          maxHeight: " calc(100% - 4rem)",
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        text: { borderRadius: "0.25rem/0.41875rem" },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderRightWidth: "0.0625rem",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: "2rem",
        },
        deleteIcon: {
          fontSize: "1.375rem",
          margin: "0 0.3125rem 0 -0.375rem",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "0.0625rem solid rgba(224, 224, 224, 1)",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          padding: "0.25rem 0 0.3125rem",
        },
      },
    },
  },
});

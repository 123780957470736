import React from "react";
import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import DOMPurify from "dompurify";
import { SmartText } from "../../library/SmartText";
import useGlobalSearchStyle from "../Styles/useGlobalSearchStyles";
import { ResultItemInterface } from "../Interface/SearchResultContentInterface";
import { EInvoiceStatusChips } from "../../library/AtomicComponents/atomic/EInvoiceStatusChips";
import { useTranslation } from "react-i18next";
import { ActivityStatusChipsComponentType } from "../../library/AtomicComponents/atomic/EInvoiceStatusChips/activitystatuschips.types";

const ResultItemComponent: React.FC<ResultItemInterface> = ({ 
  icon, 
  header, 
  query, 
  info, 
  subheader, 
  moreDetails, 
  contentPreview,
  eInvoiceStatus
 }) => {
  const {
    listItem,
    listItemAvatar,
    resultItemPrimaryContainer,
    smart,
    resultItemPrimaryRightTitle,
    resultItemSecondaryContainer,
    resultItemSecondaryHeaderContainer,
    resultItemSecondaryHeader,
    resultItemSecondaryDetails,
    resultItemContentPreview,
    headerAdjust
  } = useGlobalSearchStyle({});
  const { t, i18n } = useTranslation();
  return (
    <ListItem className={listItem}>
      <ListItemAvatar>
        <Avatar className={listItemAvatar}>{icon}</Avatar>
      </ListItemAvatar>
      <ListItemText
        disableTypography={true}
        primary={
          // display the first text line of category
          <div className={resultItemPrimaryContainer}>
            <div className={headerAdjust}>
              <div>
                <SmartText
                  title={DOMPurify.sanitize(header) ?? ""}
                  highlighter={{
                    enableHighlighter: true,
                    matchText: query ?? "~",
                  }}
                  className={smart}
                />
              </div>
              {eInvoiceStatus && <EInvoiceStatusChips
                  translationObject={{ t, i18n }}
                  chipStatus={eInvoiceStatus as ActivityStatusChipsComponentType["chipStatus"]}
                  chipConfig={{
                    chipVariant: "outlined",
                    fontVariant: "captionReg",
                    width: 90,
                    height: 16,
                  }}
                />}
              </div>
            <span className={resultItemPrimaryRightTitle}>{DOMPurify.sanitize(info) ?? ""}</span>
          </div>
        }
        secondary={
          //display second and third text line of category item
          <div className={resultItemSecondaryContainer}>
            <div className={resultItemSecondaryHeaderContainer}>
              <SmartText
                title={DOMPurify.sanitize(subheader) ?? ""}
                highlighter={{
                  enableHighlighter: true,
                  matchText: query ?? "~",
                }}
                className={resultItemSecondaryHeader}
              />
              <span className={resultItemSecondaryDetails}>{DOMPurify.sanitize(moreDetails) ?? ""}</span>
            </div>
            <SmartText
              title={DOMPurify.sanitize(contentPreview) ?? ""}
              highlighter={{
                enableHighlighter: true,
                matchText: query ?? "~",
              }}
              className={resultItemContentPreview}
            />
          </div>
        }
      />
    </ListItem>
  );
};

export default ResultItemComponent;

import { useCallback, useEffect, useState, useContext } from "react";
import { DEFAULT_NUMERIC_VALUES } from "../constants/NumericConstants";
import { DisableFeatureContext, DisableFeatureProviderType } from "../contexts/DisableFeatureContext";

type ShortcutConfig = {
  keyCombo: { ctrl: boolean; alt: boolean; shift: boolean; key: string };
  action: string;
};

const useKeyboardShortcut = (config: Array<ShortcutConfig>, disableEventListner = false, enforceContextCheck = true) => {
  const [actionToPerform, setActionToPerform] = useState<string | null>(null);
  const disableFeatureContext = useContext(DisableFeatureContext) as DisableFeatureProviderType;

  const setActionDone = useCallback(() => {
    setActionToPerform(null);
  }, [setActionToPerform]);

  const checkKeyPressed = useCallback(
    (ctrl: boolean, shift: boolean, alt: boolean, key: string, onSuccessfulFind: () => void) => {
      const filteredKey = config.filter(
        (keyVal) => keyVal.keyCombo.ctrl === ctrl && keyVal.keyCombo.shift === shift && keyVal.keyCombo.alt === alt && keyVal.keyCombo.key === key
      );
      if (filteredKey.length !== DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
        onSuccessfulFind();
        setActionToPerform(filteredKey[0].action);
      }
    },
    [config]
  );

  const keyChecker = (e: KeyboardEvent) => {
    checkKeyPressed(e.ctrlKey || e.metaKey, e.shiftKey, e.altKey, e.key, () => {
      e.preventDefault();
    });
  };

  useEffect(() => {
    if (!disableEventListner && !(disableFeatureContext?.disableKeyboardShortcut && enforceContextCheck)) {
      document.addEventListener("keydown", keyChecker);
    }
    return () => {
      if (!disableEventListner) document.removeEventListener("keydown", keyChecker);
    };
  }, [config, setActionToPerform, disableEventListner, disableFeatureContext?.disableKeyboardShortcut]);

  return { actionToPerform, setActionDone };
};

export default useKeyboardShortcut;

import React, { Dispatch, MouseEvent, SetStateAction, useEffect, useMemo, useState } from "react";
import Button from "../Button/Button";
import { ArrowDown } from "../Icons/Icons";
import Menu from "../Menu/Menu";
import NewActivityPopup, { ActivityPopupRef, ActivityToRef } from "./NewActivityCard";
import { TemplateType } from "./ActivityTemplateSelect/ActivityTemplateSelect";
import { TemplateTypes } from "../../../app/Templates/TemplateTypes";
import { ActivityAddNewButtonShortcuts, KeyboardActionsConstants } from "../../../constants/KeyboardShortcutConstants";
import useKeyboardShortcut from "../../../hooks/useKeyboardShortcut";
import { DEFAULT_NUMERIC_VALUES } from "../../../constants/NumericConstants";
import { FeatureFlagContext, FeatureFlagProviderType } from "../../../contexts/FeatureFlagContext";
import DropdownOption from "./DropdownOption/DropdownOption";
import { useTranslation } from "react-i18next";
import { TempSubjectSnippetValuesType } from "../../../hooks/useProcessTemplateData";

export type AddNewActivityDropdownProps = {
  key?: string;
  title?: string;
  open?: boolean;
  origin?: string;
  toTime?: number;
  defaultTo?: To[];
  defaultCc?: To[];
  defaultBcc?: To[];
  fromTime?: number;
  buttonName?: string;
  onClose?: () => void;
  forwarding?: boolean;
  defaultTitle?: string;
  replyToOptions?: To[];
  contactOptions?: To[];
  disableTitle?: boolean;
  isNudgeActivity?: boolean;
  showAddActivity?: boolean;
  resetFunction?: () => void;
  isTemplateSupport?: boolean;
  newActivityType?: string | null;
  defaultFiles?: (File | string)[];
  disableSecondaryAction?: boolean;
  defaultTemplateId?: TemplateTypes | string;
  supportedTemplateList?: TemplateType[];
  typeAheadAction?: (val: string) => void;
  editorState?: string | null;
  setNewActivityType?: (type: string) => void;
  addActivityDropdown?: ActivityDropdownItem[];
  setToTime?: Dispatch<SetStateAction<number>>;
  setShowAddActivity?: (type: boolean) => void;
  setNewActivityVisibility?: (type: boolean) => void;
  setActivityItemId?: (activityItemId: string) => void;
  replyOptions?: { replyButton: boolean; start: string; reply: To[]; replyAll: To[]; replyAllCC: To[] };
  handleSend?: (to: To[], cc: To[], bcc: To[], title: string, body: string, attachmentIds: AttachmentIds, inboxAttachments: any) => Promise<any>;
  handleReplySend?: (to: To[], cc: To[], bcc: To[], title: string, body: string,  activityId: string, attachmentIds: AttachmentIds, inboxAttachments: any) => Promise<any>;
  handleReplySendClose?: (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    activityId: string,
    attachmentIds: AttachmentIds,
    inboxAttachments: any
  ) => Promise<any>;
  handleSendMarkClosed?: (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    attachmentIds: AttachmentIds,
    inboxAttachments: any
  ) => Promise<any>;
  prepareActivityBodyByTemplateID?: (
    templateID: string | null,
    setEditorState: React.Dispatch<React.SetStateAction<string>>,
    lang: string,
    cb: any
  ) => void;
  defaultAttachmentsList?: AttachmentItem[];
  refs?: {
    toRef?: React.MutableRefObject<ActivityToRef>;
    popupRef?: React.MutableRefObject<ActivityPopupRef>;
  };
  enableKeyboardShortcuts?: boolean | undefined;
  onClickDownload: (
    event: React.MouseEvent<HTMLTableRowElement | HTMLDivElement | HTMLButtonElement | HTMLAnchorElement | HTMLSpanElement>,
    attachments: AttachmentItem[] | BlobAttachmentItem[]
  ) => void;
  workspaceData?: UserItem;
  tempSubjectSnippetValues?: TempSubjectSnippetValuesType;
  suggestedTemplatesCode?: string[];
  isSendButtonDisabled: boolean;
  setToastOptions?: (toastOptions: ToastOptions) => void;
};

export default function AddNewActivityDropdown(props: AddNewActivityDropdownProps): React.ReactElement {
  const [dropdownMenuAnchorEl, setDropdownMenuAnchorEl] = useState<HTMLDivElement | null>(null);
  const dropdownMenuOpen = Boolean(dropdownMenuAnchorEl);
  const { t } = useTranslation();

  const typeAheadActionHandler = (val: string) => {
    if (props.typeAheadAction) {
      props.typeAheadAction(val);
    }
  };

  const onCloseHandler = () => {
    if (props?.setShowAddActivity) {
      props?.setShowAddActivity(false);
    }
    // reset activity type on closing the pop up
    if (props?.setNewActivityType) {
      props?.setNewActivityType("");
    }
    if (props?.resetFunction) {
      props?.resetFunction();
    }
  };

  /**
   * Event handler triggered when user click on 'New Activity Stream' dropdown menu item.
   * it will open the new Activity dialog.
   *
   * @param {string} label The label of the item which is clicked by user.
   */
  const onClickMenuItems = (label: string) => {
    if (props?.setNewActivityType) {
      props?.setNewActivityType(label.toLowerCase().split(" ").join("_"));
    }
    setDropdownMenuAnchorEl(null);
    if (props?.setShowAddActivity) {
      props?.setShowAddActivity(true);
    }
    props.setNewActivityVisibility?.(false);
    props.setActivityItemId?.("");
  };

  const { isEnabled } = React.useContext(FeatureFlagContext) as FeatureFlagProviderType;

  /**
   * Flag for Keyboard Shortcut
   */

  const isKeyboardShortcutFlagEnabled = isEnabled("KEYBOARD_SHORTCUTS") && props.enableKeyboardShortcuts;

  /**
   * HOC Hook for Keyboard ShortCut UI
   */

  const { actionToPerform, setActionDone } = useKeyboardShortcut(ActivityAddNewButtonShortcuts, !isKeyboardShortcutFlagEnabled);

  /**
   * on key press, function takes the action type as input and simulate
   * the manu item click
   **/
  const onActionKeyPress = (actionLabel: string) => {
    const action = props.addActivityDropdown?.filter((item) => item.displayName === actionLabel);
    if (action && action?.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      onClickMenuItems(action[0].displayName);
    }
  };
  /**
   * Maps Keyboard Shortcuts available for Activity Dropdown Actions
   *
   * function maps a keyboard action with dropdown item to simulate a
   * click action
   */
  const keyboardActions: { [key: string]: any } = useMemo(
    () => ({
      [KeyboardActionsConstants.make_activity_email]: () => onActionKeyPress("Email"),
      [KeyboardActionsConstants.make_activity_note]: () => onActionKeyPress("Note"),
      [KeyboardActionsConstants.make_activity_phone_call]: () => onActionKeyPress("Phone Call"),
      [KeyboardActionsConstants.make_activity_approval_request]: () => onActionKeyPress("Approval Request"),
    }),
    [onClickMenuItems, props.addActivityDropdown]
  );

  /**
   * Use Effect Calls
   */

  useEffect(() => {
    if (
      isKeyboardShortcutFlagEnabled &&
      actionToPerform &&
      keyboardActions[actionToPerform] &&
      typeof keyboardActions[actionToPerform] === "function"
    ) {
      keyboardActions[actionToPerform]();
    }
    setActionDone();
  }, [actionToPerform, isKeyboardShortcutFlagEnabled]);

  return (
    <div>
      <Button
        className={props.origin && props.origin === "index" ? " " : "add-activity-btn"}
        size={props.origin && props.origin === "index" ? "lg" : "sm"}
        icon={<ArrowDown />}
        alignIcon="right"
        onClick={(e: MouseEvent<any>) => setDropdownMenuAnchorEl(e.currentTarget)}
      >
        {props.buttonName ? props.buttonName : t("addNewActivity.button.label", { ns: "activities" })}
      </Button>
      <Menu
        open={dropdownMenuOpen}
        anchorEl={dropdownMenuAnchorEl}
        onClose={() => setDropdownMenuAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {props?.addActivityDropdown?.map((item: ActivityDropdownItem) => (
          <DropdownOption key={item.activityType} enableShortcutKey={isKeyboardShortcutFlagEnabled} onClickOption={onClickMenuItems} {...item} />
        ))}
      </Menu>
      {props.showAddActivity && (
        <NewActivityPopup
          key={`key-add-activity-${props.newActivityType}`}
          title={props.buttonName ?? t("addNewActivity.button.label", { ns: "activities" })}
          open={props.showAddActivity}
          contactOptions={
            props?.replyToOptions && props?.replyToOptions.length
              ? props.replyToOptions.filter((v, i, a) => a.findIndex((t) => t.label === v.label) === i)
              : []
          }
          defaultTo={props.defaultTo ?? []}
          handleSend={props.handleReplySend}
          handleSendMarkClosed={props.handleReplySendClose}
          onClose={onCloseHandler}
          defaultTitle={`${props.defaultTitle ? props.defaultTitle : ""}`}
          disableTitle={props.disableTitle}
          activityType={props.newActivityType}
          setNewActivityType={props.setNewActivityType}
          addActivityDropdown={props.addActivityDropdown}
          typeAheadAction={typeAheadActionHandler}
          editorState={props.editorState ?? ""}
          clickDisabled={props?.isNudgeActivity}
          disableSecondaryAction={props.disableSecondaryAction}
          isTemplateSupport={props?.isTemplateSupport}
          supportedTemplateList={props?.supportedTemplateList}
          defaultTemplateId={props?.defaultTemplateId}
          prepareActivityBodyByTemplateID={props?.prepareActivityBodyByTemplateID}
          defaultCc={props?.defaultCc}
          defaultBcc={props?.defaultBcc}
          fromTime={props.fromTime}
          toTime={props.toTime}
          setToTime={props.setToTime}
          defaultAttachmentsList={props.defaultAttachmentsList ?? []}
          refs={props.refs}
          enableKeyboardShortcuts={props.enableKeyboardShortcuts}
          onClickDownload={props.onClickDownload}
          workspaceData={props.workspaceData}
          isSendButtonDisabled={props.isSendButtonDisabled}
          tempSubjectSnippetValues={props?.tempSubjectSnippetValues ?? {}}
          suggestedTemplatesCode={props?.suggestedTemplatesCode}
          setToastOptions={props?.setToastOptions}
        />
      )}
    </div>
  );
}

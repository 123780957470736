import React from "react";
import { useLocation, useParams, useRouteMatch } from "react-router-dom";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../../../../constants/NumericConstants";
import { AppContext } from "../../../../../contexts/AppContext";
import { CustomerContext } from "../../../../../contexts/CustomerContext";
import { PaymentsClientV2, attachmentsClientV2, contactsClientV2, invoicesClientV2 } from "../../../../../db/version2Accessor";
import { WorkspaceType } from "../../../../../types/enums";
import { HeaderComponent } from "../HeaderComponent/HeaderComponent";
import { TransactionData } from "../HeaderInterfaces/HeaderInterfaces";
import useWorkspaceConfigurations from "../../../../../hooks/useWorkspaceConfigurations";
import { CustomerDetailsDashboardContext, CustomerDetailsDashboardData } from "../../../../../contexts/CustomerDetailsDashboardContext";
import TableUtils from "../../../../../utils/TableUtils/TableUtils";
import { AlertContext } from "../../../../../contexts/AlertContext";
import { Skeleton } from "@mui/material";
import { ACTIVITY_DETAILS_SKELETON_CONSTANTS } from "../../../../../constants/StyleConstants";
import Utils from "../../../../../utils/utils";

type HeaderContainerProps = {
  isLoading: boolean;
};

export default function HeaderContainer(props: HeaderContainerProps) {
  // get the data
  const [transactionHeaderInfo, setTransactionHeaderInfo] = React.useState<TransactionData>({} as TransactionData);
  const [contacts, setContacts] = React.useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [documentsCount, setDocumentsCount] = React.useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const { company, isDataUpdated } = React.useContext(CustomerContext) as CustomerType;
  const { configs: currentWorkspaceConfigs, configProvider, pathType, selectedWorkspace } = useWorkspaceConfigurations();
  const { userStatus } = React.useContext(AppContext) as AppType;

  const { customerId } = useParams<{ customerId: string }>();
  const { path } = useRouteMatch();

  const configsV2 = (selectedWorkspace.workspace_type_route === WorkspaceType.AW ? configProvider[pathType] : currentWorkspaceConfigs)[
    path.includes("connections") ? "otherConnectionsV2" : "connectionsV2"
  ]["details"];

  const headerInfo = configsV2["header"];

  const { viewBasedFilters } = React.useContext(CustomerDetailsDashboardContext) as CustomerDetailsDashboardData;
  const location = useLocation();
  const isSpamOrFraud = path?.includes("spam") || path.includes("fraud");
  const params = new URLSearchParams(window.location.search);
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;

  const getTransactionsFilters = () => {
    const additionalFilters: Record<string, string>[] = [];

    if (location.pathname?.includes("open")) {
      const openInvoiceConfigs = configsV2["open_invoices"];

      const preDefinedFilters = [
        ...openInvoiceConfigs.table.preDefinedFilters["invoices"],
        {
          searchlightFilter: `qa[customer_id_eq]=${customerId}`,
        },
      ];
      preDefinedFilters.forEach((item) => additionalFilters.push(item));
      const filterQuery = TableUtils.getFilterFromURL(params, openInvoiceConfigs.table.columns);
      return TableUtils.columnFilterParser(filterQuery, true, additionalFilters ?? []);
    } else if (location.pathname?.includes("closed")) {
      const closedInvoiceConfigs = configsV2["closed_invoices"];

      const preDefinedFilters = [
        ...closedInvoiceConfigs.table.preDefinedFilters["invoices"],
        {
          searchlightFilter: `qa[customer_id_eq]=${customerId}`,
        },
      ];
      preDefinedFilters.forEach((item) => additionalFilters.push(item));
      const filterQuery = TableUtils.getFilterFromURL(params, closedInvoiceConfigs.table.columns);
      return TableUtils.columnFilterParser(filterQuery, true, additionalFilters ?? []);
    } else {
      const paymentConfigs = configsV2["payments"];

      const preDefinedFilters = [
        ...paymentConfigs.table.preDefinedFilters,
        {
          searchlightFilter: `qa[payment_company_id_eq]=${customerId}`,
        },
      ];
      preDefinedFilters.forEach((item) => additionalFilters.push(item));
      const filterQuery = TableUtils.getFilterFromURL(params, paymentConfigs.table.columns);
      return TableUtils.columnFilterParser(filterQuery, true, additionalFilters ?? []);
    }
  };

  const fetchInvoiceHeaderData = React.useCallback(async () => {
    const filters = [getTransactionsFilters(), viewBasedFilters].filter(Boolean).join("&");
    try {
      const result = await invoicesClientV2.getSummary(filters, isSpamOrFraud);
      const data = result.data as TransactionData;
      setTransactionHeaderInfo(data);
    } catch (_error) {
      setToastOptions({ open: true, severity: "error", message: "Invoice Summary Failed" });
    }
  }, [viewBasedFilters]);

  const fetchPaymentHeaderData = React.useCallback(async () => {
    const filters = [getTransactionsFilters(), viewBasedFilters].filter(Boolean).join("&");
    try {
      const result = await PaymentsClientV2.getPaymentsSummary(filters, isSpamOrFraud);
      const data = result.data as TransactionData;
      setTransactionHeaderInfo(data);
    } catch (_error) {
      setToastOptions({ open: true, severity: "error", message: "Payment Summary Failed" });
    }
  }, [viewBasedFilters]);

  async function fetchContactCount() {
    const filters = viewBasedFilters
      ? `qa[status_eq]=active&qa[is_active_eq]=true&qa[company_id_eq]=${customerId}&${viewBasedFilters}`
      : `qa[status_eq]=active&qa[is_active_eq]=true&qa[company_id_eq]=${customerId}`;

    const contacts = await contactsClientV2.getContacts(
      selectedWorkspace.id,
      filters,
      undefined,
      NUMERIC_VALUES.CONSTANT_THOUSAND,
      DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
    );
    setContacts(contacts.records.length);
  }

  async function fetchDocumentCount() {
    const filters = viewBasedFilters
      ? `filter_by=connection&connection_id=${customerId}&${viewBasedFilters}`
      : `filter_by=connection&connection_id=${customerId}`;
    const documents = await attachmentsClientV2.getAttachments(filters, "", DEFAULT_NUMERIC_VALUES.DEFAULT_ONE, NUMERIC_VALUES.CONSTANT_THOUSAND);
    setDocumentsCount(documents.records?.length ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  }

  React.useEffect(() => {
    (async function fetchData() {
      switch (true) {
        case location?.pathname?.includes("contacts"):
          await fetchContactCount();
          break;
        case location?.pathname?.includes("documents"):
          await fetchDocumentCount();
          break;
        case location?.pathname?.includes("payments"):
          await fetchPaymentHeaderData();
          break;
        default:
          await fetchInvoiceHeaderData();
      }
    })();
  }, [isDataUpdated, viewBasedFilters]);

  return props.isLoading ? (
    <Skeleton
      variant="text"
      animation="wave"
      height={Utils.getConvertedSizeInRem(ACTIVITY_DETAILS_SKELETON_CONSTANTS.ITEM.BODY.HEADER_WIDTH)}
      width={Utils.getConvertedSizeInRem(ACTIVITY_DETAILS_SKELETON_CONSTANTS.HEADER.SUBJECT_WIDTH)}
    />
  ) : (
    <HeaderComponent
      transactionData={transactionHeaderInfo}
      companyData={company as CustomerDetailsModel}
      configs={headerInfo}
      contactsCount={contacts}
      documentsCount={documentsCount}
      currencyConfig={userStatus?.currency}
    />
  );
}

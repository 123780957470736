import { styled } from "@mui/material/styles";
import { spaces } from "../../theme/styles/spacing";

type BannerStyledProps = { variant: "info" | "success" | "warning" | "error" };

// variant interface - default - Information
export const Banner = styled("div")<BannerStyledProps>(({ theme, variant }) => {
  return {
    backgroundColor: theme.palette?.[variant].main,
    padding: spaces.medium,
    border: `${spaces.borderline} solid ${theme.palette?.[variant].border}`,
    borderRadius: spaces.small,
  };
});

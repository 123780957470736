import React from "react";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../../../../../../../constants/NumericConstants";
import { TooltipTypes } from "../../../../../../../../constants/TooltipConstants";
import { EmojiActionIds, EmojiOptions } from "../../../../../../../../types/enums";
import CustomTooltip from "../../../../../../../library/CustomTooltip/CustomTooltip";
import { ReactionsProps } from "../HelperTypes";
import { useTranslation } from "react-i18next";

const Reactions: React.FC<ReactionsProps> = ({ reactions, onClickReaction }) => {
  const onClick = (option: EmojiOptions) => onClickReaction?.(option, EmojiActionIds.tag);
  const { t } = useTranslation();
  const thumbsUp = t("activityDetails.feedItems.primaryActivity.helpers.reactions.emojiLabel.thumbsUp", { ns: "activities" });
  const thumbsDown = t("activityDetails.feedItems.primaryActivity.helpers.reactions.emojiLabel.thumbsDown", { ns: "activities" });
  const happyFace = t("activityDetails.feedItems.primaryActivity.helpers.reactions.emojiLabel.happyFace", { ns: "activities" });
  const sadFace = t("activityDetails.feedItems.primaryActivity.helpers.reactions.emojiLabel.sadFace", { ns: "activities" });
  const celebrate = t("activityDetails.feedItems.primaryActivity.helpers.reactions.emojiLabel.celebrate", { ns: "activities" });
  const love = t("activityDetails.feedItems.primaryActivity.helpers.reactions.emojiLabel.love", { ns: "activities" });

  const emojiMapper: { [key: string]: { symbol: string; text: string; onClick: () => void } } = React.useMemo(
    () => ({
      [EmojiOptions.THUMBS_UP]: { symbol: "👍", text: thumbsUp, onClick: () => onClick?.(EmojiOptions.THUMBS_UP) },
      [EmojiOptions.THUMBS_DOWN]: {
        symbol: "👎",
        text: thumbsDown,
        onClick: () => onClick?.(EmojiOptions.THUMBS_DOWN),
      },
      [EmojiOptions.HAPPY]: { symbol: "😃", text: happyFace, onClick: () => onClick?.(EmojiOptions.HAPPY) },
      [EmojiOptions.SAD]: { symbol: "😦", text: sadFace, onClick: () => onClick?.(EmojiOptions.SAD) },
      [EmojiOptions.CELEBRATE]: {
        symbol: "🎉",
        text: celebrate,
        onClick: () => onClick?.(EmojiOptions.CELEBRATE),
      },
      [EmojiOptions.HEART]: { symbol: "❤️", text: love, onClick: () => onClick?.(EmojiOptions.HEART) },
    }),
    [onClick]
  );

  const getTooltip = (emoji: string, reactionGivers: Array<{ [key: string]: string | number | null }>) => {
    if (reactionGivers.length > DEFAULT_NUMERIC_VALUES.DEFAULT_TWO) {
      return t("activityDetails.feedItems.primaryActivity.helpers.reactions.tooltipMessage.moreThanTwoReaction", {
        count: reactionGivers.length,
        emojiText: emojiMapper[emoji].text,
        ns: "activities",
      });
    }

    return reactionGivers[1]?.name
      ? t("activityDetails.feedItems.primaryActivity.helpers.reactions.tooltipMessage.twoReaction", {
          email1: reactionGivers[0]?.name,
          email2: reactionGivers[1]?.name,
          emojiText: emojiMapper[emoji].text,
          ns: "activities",
        })
      : t("activityDetails.feedItems.primaryActivity.helpers.reactions.tooltipMessage.singleReaction", {
          email: reactionGivers[0]?.name,
          emojiText: emojiMapper[emoji].text,
          ns: "activities",
        });
  };

  return (
    <>
      {reactions
        ? Object.keys(reactions).map((reaction) =>
            reactions[reaction].count > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO ? (
              <CustomTooltip
                type={
                  getTooltip(reaction, reactions[reaction].givers)?.length > NUMERIC_VALUES.CONSTANT_TWENTY ? TooltipTypes.RICH : TooltipTypes.PLAIN
                }
                title={getTooltip(reaction, reactions[reaction].givers)}
                key={reaction}
              >
                <div
                  className={reactions[reaction].highlight ? "reaction-tag-highlight" : "reaction-tag"}
                  onClick={(e) => {
                    e.stopPropagation();
                    emojiMapper[reaction].onClick();
                  }}
                >
                  <span className="reaction">{emojiMapper[reaction].symbol}</span> {reactions[reaction].count}
                </div>
              </CustomTooltip>
            ) : null
          )
        : null}
    </>
  );
};

export default Reactions;

/*eslint-disable*/
import React from "react";
import { styled } from "@mui/system";
import { pxToRem } from "../../../theme/theme";
import Ellipsis from "../../../svg/ellipsis.svg";
import Next from "../../../svg/pagination_next.svg";
import Previous from "../../../svg/pagination_previous.svg";

export const TableFooterContainer = styled("div", {
  name: "table-pagination",
  slot: "container",
})({
  border: `${pxToRem(1)} solid #DEE1ED`,
  flex: "0 0 auto",
  padding: `${pxToRem(12)} ${pxToRem(16)}`,
  zIndex: 2,
  background: "#FFFFFF",
  borderRadius: `${pxToRem(0)} ${pxToRem(0)} ${pxToRem(8)} ${pxToRem(8)} `,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: pxToRem(56),
  boxShadow: "0px -2.5px 2.5px -1.5px rgba(0, 0, 0, 0.15)",
  "& .pagination-nav-skeleton-container": {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: pxToRem(16),
  },
  "& .MuiPaginationItem-ellipsis": {
    backgroundImage: `url(${Ellipsis})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    textIndent: "-999px", //Hide Ellipsis text
    color: "transparent", //Hide Ellipsis text
  },
  "& .MuiPaginationItem-previousNext": {
    border: "1px solid #4D4D4D",
    "& svg": {
      display: "none",
    },
  },
  "& .Mui-selected": {
    border: "1px solid #4D4D4D",
    backgroundColor: "transparent",
  },
  "& .MuiPagination-ul": {
    "& :first-child": {
      button: {
        backgroundImage: `url(${Previous})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      },
    },
    "& :last-child": {
      button: {
        backgroundImage: `url(${Next})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      },
    },
  },
  "& .MuiTypography-body1Reg": {
    cursor: "pointer",
    display: "flex",
    gap: pxToRem(12.5),
    alignItems: "center",
    justifyContent: "center",
  },
  "& .bulk-selection-container": {
    borderLeft: "1px solid #000000",
    paddingLeft: pxToRem(12),
    "& .bulk-action-button": {
      color: "#124564",
      borderRadius: pxToRem(8),
      textTransform: "Capitalize",
      padding: `${pxToRem(8)} ${pxToRem(12)}`,
      height: pxToRem(40),
      background: "#F7F7FB",
      border: `${pxToRem(1)} solid #DEE1ED`,
      boxShadow: "none",
    },
  },
});

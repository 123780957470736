import APIClient from "../APIClient";
const PLATFORM_API_BASE = process.env.REACT_APP_API_ENDPOINT_PLATFORM_V1;

export class CodeDefinitionsClient {
  public getCodeDefinition = (id: string, include?: string): Promise<CodeDefinitionModel> => {
    const url = `${PLATFORM_API_BASE}/CodeDefinitions/${id}`;

    const options = {
      params: {
        include,
      },
    };

    return APIClient.get(url, options).then((res) => res.data);
  };

  public queryCodeDefinitions = (
    filter?: string,
    include?: string,
    order?: string,
    pageSize?: number,
    pageNumber?: number
  ): Promise<CodeDefinitionModelFetchResult> => {
    const url = `${PLATFORM_API_BASE}/CodeDefinitions/query`;

    const options = {
      params: {
        filter,
        include,
        order,
        pageSize,
        pageNumber,
      },
    };

    return APIClient.get(url, options).then((response) => {
      return response.data;
    });
  };
}

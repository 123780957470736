import React from "react";
import { Route, Switch, useRouteMatch, useLocation } from "react-router-dom";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";
import Navbar from "../../library/Navbar/Navbar";
import "./Settings.scss";
import Loading from "../../library/Loading/Loading";
import { DEFAULT_NUMERIC_VALUES } from "../../../constants/NumericConstants";

const UserPermissions = React.lazy(() => import("../UserPermissions/UserPermissions"));
const EmailSettings = React.lazy(() => import("../EmailSettings/EmailSettings"));
const WorkspaceSettings = React.lazy(() => import("../WorkspaceSettings/WorkspaceSettings"));
const TemplateSettings = React.lazy(() => import("../TemplateSettings/TemplateSettings"));
const FourOFourError = React.lazy(() => import("../../../routes/FourOFourError/FourOFourError"));
const NotificationsSettings = React.lazy(() => import("../NotificationsSettings/NotificationsSettings"));
const ApAutomation = React.lazy(() => import("../ApAutomation/ApAutomation"));
const InboxSettings = React.lazy(() => import("../InboxSettings/InboxSettings"));
import SignatureSettings from "../SignatureSettings/SignatureSettings";
import { WorkspaceType } from "../../../types/enums";

const userPermissionsRoutes = [
  {
    displayName: "Active Members",
    routeName: "userPermissions?sort=USERNAME+DESC",
  },
  {
    displayName: "Invitations",
    routeName: "userPermissions/invitations?sort=EMAIL+DESC",
  },
  {
    displayName: "Removed Members",
    routeName: "userPermissions/removed?sort=USERNAME+DESC",
  },
];
interface EmailRouteTypes {
  displayName: string;
  routeName: string;
}

const Settings = (): React.ReactElement => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const { workspaceData, selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const [emailRoutes] = React.useState<Array<EmailRouteTypes>>(
    workspaceData.map((data: UserItem) => {
      return {
        displayName: data.name ?? "",
        routeName: `emailSettings/workspace/${data?.id}`,
      };
    })
  );

  // push 'Accounting Workspace' last in order'.
  const workspacesDataCustomOrder = workspaceData;
  const index = workspacesDataCustomOrder.findIndex((item) => item.workspace_type_route === WorkspaceType.AW);
  // if key exists - Clone the value to the bottom of the array and remove the original key
  if (index !== DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE) {
    workspacesDataCustomOrder.push(workspacesDataCustomOrder[index]);
    workspacesDataCustomOrder.splice(index, DEFAULT_NUMERIC_VALUES.DEFAULT_ONE);
  }

  // set to keep the routes unique
  const routeTypes = new Set();

  /**
   * prepare templateSettings NavRoutes
   *
   * Note: starting accounting workspace feature, backend will not be using the workspace id
   * for performing updates, the template data will be updated on account level, which means
   * irrespective of the workspace id the user should always be able to see latest template copy.
   *
   * below segregation and filters are stop-gap solution until UI moves away from the dependency of
   * workspace id to workspace type.
   *
   * Future Steps/TODO:
   * 1. refactor router and remvoe workspaces/:workspace_id to template-settings/:template_type
   * (accounts_payable/accounts_receivable/accounting)
   * 2. refactor templateSettingRoutes to have above routes
   */
  const [templateSettingsRoutes] = React.useState<Array<EmailRouteTypes>>(
    workspacesDataCustomOrder.flatMap((data: UserItem) => {
      const tempRoutes = [];
      /**
       * if selected workspace is not combined accounting, preference is provided to the
       * workspace specific routes
       */
      if (selectedWorkspace.workspace_type_route !== WorkspaceType.AW) {
        if (!routeTypes.has(data.workspace_type_route) && data.workspace_type_route !== WorkspaceType.AW) {
          tempRoutes.push({
            displayName: data?.name ?? "",
            routeName: `templateSettings/workspaces/${data?.id}/${data.workspace_type_route}`,
          });
          routeTypes.add(data.workspace_type_route);
        }
      }
      // otherwise combined workspace routes are loaded
      if (data.workspace_type_route === WorkspaceType.AW) {
        if (!routeTypes.has(WorkspaceType.AP)) {
          tempRoutes.push({
            displayName: "Accounts Payables",
            routeName: `templateSettings/workspaces/${data?.id}/ap`,
          });
          routeTypes.add(WorkspaceType.AP);
        }
        if (!routeTypes.has(WorkspaceType.AR)) {
          tempRoutes.push({
            displayName: "Accounts Receivables",
            routeName: `templateSettings/workspaces/${data?.id}/ar`,
          });
          routeTypes.add(WorkspaceType.AR);
        }
      }

      return tempRoutes;
    })
  );
  const isInboxSettings = location.pathname === `${path}`;

  return (
    <div
      className={`settings-wrapper ${location.pathname.slice(location.pathname.lastIndexOf("/") + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE)} ${
        isInboxSettings ? "settings-wrapper-fullpage" : "settings-wrapper-tab"
      }`}
    >
      {isInboxSettings ? (
        <div className={"head"}>
          <Route
            exact
            path={`${path}`}
            render={() => <Navbar tabs={[]} rootPath={path} title="Inbox Settings" subtitle="Set-up and maintain your Inbox." />}
          />
        </div>
      ) : (
        <div className={"head"}>
          <Route
            exact
            path={emailRoutes.map((val) => `${path}/${val.routeName}`)}
            component={() => {
              return (
                <Navbar
                  tabs={emailRoutes}
                  rootPath={`${path}`}
                  dropdownRootPath={path}
                  dropdownDefault="Email Settings"
                  backDisplayName="Inbox Settings"
                  backRoute={`${path}`}
                  handleBackRoute={true}
                />
              );
            }}
          />
          <Route
            exact
            path={userPermissionsRoutes.map((val) => `${path}/${val.routeName.split("?")[0]}`)}
            component={() => {
              return (
                <Navbar
                  tabs={userPermissionsRoutes}
                  rootPath={`${path}`}
                  dropdownRootPath={path}
                  dropdownDefault="Users and Permissions"
                  backDisplayName="Inbox Settings"
                  backRoute={`${path}`}
                  tooltip="Settings to manage the users on Insights."
                  handleBackRoute={true}
                />
              );
            }}
          />
          <Route
            exact
            path={`${path}/workspaceSettings`}
            component={() => {
              return (
                <Navbar
                  tabs={[]}
                  rootPath={`${path}`}
                  dropdownRootPath={path}
                  backDisplayName="Inbox Settings"
                  backRoute={`${path}`}
                  tooltip="Settings to manage workspaces."
                  handleBackRoute={true}
                  title="Workspace Settings"
                  subtitle="Set-up and manage your Inbox workspaces."
                />
              );
            }}
          />
          <Route
            exact
            path={templateSettingsRoutes?.map((val) => `${path}/${val.routeName}`)}
            component={() => {
              return (
                <Navbar
                  tabs={templateSettingsRoutes}
                  rootPath={`${path}`}
                  backDisplayName="Inbox Settings"
                  backRoute={`${path}`}
                  handleBackRoute={true}
                />
              );
            }}
          />
          <Route
            exact
            path={`${path}/notificationsSettings`}
            component={() => {
              return (
                <Navbar
                  tabs={[]}
                  rootPath={`${path}`}
                  dropdownDefault="Notifications Settings"
                  backDisplayName="Inbox Settings"
                  backRoute={`${path}`}
                  tooltip="Settings to manage notifications."
                  handleBackRoute={true}
                  title="Notifications Settings"
                  subtitle="Manage notification emails."
                />
              );
            }}
          />
          <Route
            exact
            path={`${path}/signatureSettings`}
            component={() => {
              return (
                <Navbar
                  tabs={[]}
                  rootPath={`${path}`}
                  backDisplayName="Inbox Settings"
                  backRoute={`${path}`}
                  handleBackRoute={true}
                  title="Signature Settings"
                  subtitle="Add or manage your signature."
                />
              );
            }}
          />
          <Route
            exact
            path={`${path}/apAutomation`}
            component={() => {
              return (
                <Navbar
                  tabs={[]}
                  rootPath={`${path}`}
                  backDisplayName="Inbox Settings"
                  backRoute={`${path}`}
                  handleBackRoute={true}
                  title="Forward to AP Automation Settings"
                  subtitle="Set-up and manage auto-forward to AP automation tool."
                />
              );
            }}
          />
        </div>
      )}
      <div className={"body"}>
        <React.Suspense fallback={<Loading isRelative />}>
          <Switch>
            <Route exact path={`${path}`} component={InboxSettings} />
            <Route path={`${path}/emailSettings/workspace/:workspaceId`} component={EmailSettings} />
            <Route path={`${path}/userPermissions`} component={UserPermissions} />
            <Route path={`${path}/workspaceSettings`} component={WorkspaceSettings} />
            <Route path={`${path}/notificationsSettings`} component={NotificationsSettings} />
            <Route path={`${path}/templateSettings/workspaces/:workspaceId/:workspaceRoute`} component={TemplateSettings} />
            <Route path={`${path}/apAutomation`} component={ApAutomation} />
            <Route path={`${path}/signatureSettings`} component={SignatureSettings} />
            <Route component={FourOFourError} />
          </Switch>
        </React.Suspense>
      </div>
    </div>
  );
};

export default Settings;

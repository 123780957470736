import React from "react";
import Dialog from "../../../../../library/Dialog/Dialog";
import DialogActions from "../../../../../library/DialogActions/DialogActions";
import DialogContent from "../../../../../library/DialogContent/DialogContent";
import DialogTitle from "../../../../../library/DialogTitle/DialogTitle";
import Button from "../../../../../library/Button/Button";
import { ModalError } from "../../../../../library/Icons/Icons";
import useBulkMoveDialogClasses from "./useBulkMoveDialogClasses";
import { useTranslation } from "react-i18next";

type BulkMoveModalProps = {
  showModal: boolean;
  from?: PrimaryConnection;
  to?: Partial<CompanyV2> | null;
  closeModal: () => void;
  handleSingleMove: (connectionId: string) => void;
  handleBulkMove: (connectionId: string) => void;
};

export default function BulkMoveModal(props: BulkMoveModalProps): React.ReactElement {
  const { bulkMoveDialog } = useBulkMoveDialogClasses();
  const { t } = useTranslation();

  const onClickMoveAll = () => {
    props.handleBulkMove(props.to?.company_id ?? "");
  };
  const onClickJustOneMove = () => {
    props.handleSingleMove(props.to?.company_id ?? "");
  };
  return (
    <Dialog open={props.showModal} onClose={props.closeModal} overrideDialogClasses={true} className={bulkMoveDialog}>
      <DialogTitle align="center">
        <ModalError />
        <span className="ml-sm">{t("modalAndFlyout.moveActivity.header", { ns: "activities" })}</span>
      </DialogTitle>
      <DialogContent className="mt-ba mb-ba">
        <p>
          {t("modalAndFlyout.moveActivity.bulkMoveDialogMessage.firstPart", {
            connectionName: props.from?.name,
            ns: "activities",
          })}
          <span className="move-to-connection">{props.to?.company_name}</span>
          {t("modalAndFlyout.moveActivity.bulkMoveDialogMessage.lastPart", {
            ns: "activities",
          })}
        </p>
      </DialogContent>
      <DialogActions>
        <Button className="ml-auto" variant="secondary2" onClick={onClickJustOneMove}>
          {t("modalAndFlyout.buttons.moveOne", { ns: "activities" })}
        </Button>
        <Button variant="primary2" onClick={onClickMoveAll}>
          {t("modalAndFlyout.buttons.moveAll", { ns: "activities" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import React from "react";
import { Input } from "../../../../library/Input/Input";
import { AutocompleteRenderInputParams, TextField } from "@mui/material";
import Button from "../../../../library/Button/Button";
import Autocomplete from "../../../../library/Autocomplete/Autocomplete";
import { DataMode } from "../../../../../types/enums";

type ContactFormComponentProps = {
  newContactName: string;
  setNewContactName: React.Dispatch<React.SetStateAction<string>>;
  newContactRole: string;
  setNewContactRole: React.Dispatch<React.SetStateAction<string>>;
  newContactEmail: string;
  setNewContactEmail: React.Dispatch<React.SetStateAction<string>>;
  newContactPhone: string;
  setNewContactPhone: React.Dispatch<React.SetStateAction<string>>;
  newContactPrimary: boolean;
  setNewContactPrimary: React.Dispatch<React.SetStateAction<boolean>>;
  addContactNameError: string;
  addContactEmailError: string;
  addContactRoleError: string;
  handleAddContact: () => void;
  handleEditContact: () => void;
  resetFormStates: () => void;
  isLoadingRoleList: boolean;
  roleList: CodeDefinitionModel[];
  dataMode: DataMode;
  showLoader: boolean;
};

export const ContactFormComponent = (props: ContactFormComponentProps) => {
  return (
    <div className={"invite-flyout-wrapper"}>
      <pre className={"header"}>{props.dataMode === DataMode.View ? "Add Contact" : "Edit Contact"}</pre>
      <Input
        className="sb-wrapper bottom"
        value={props.newContactName}
        label={{ regularText: "Name", extraText: "" }}
        placeholder=""
        onChange={(e) => props.setNewContactName(e.target.value)}
        errorMessage={props.addContactNameError}
      />
      <Autocomplete
        label={"Role"}
        options={props.roleList.map((role: CodeDefinitionModel) => ({ label: role.codeDescription, id: role.code }))}
        onChange={(_event, value: { label: string; id: string }) => props.setNewContactRole(value.id)}
        value={props.roleList.find((role: CodeDefinitionModel) => role.code === props.newContactRole)?.codeDescription || null}
        disableClearable
        isOptionEqualToValue={(option: { label: string; id: string }, value: string) => option.label === value}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField {...params} InputProps={{ ...params.InputProps, disableUnderline: true }} />
        )}
        loading={props.isLoadingRoleList}
        style={props.addContactRoleError ? { border: "0.0625rem solid #e54646" } : {}}
      />
      {props.addContactRoleError && (
        <span className="sb-error-container">
          <label className="sb-error">{`${props.addContactRoleError}`}</label>
        </span>
      )}
      <Input
        className="sb-wrapper bottom"
        label={{ regularText: "Email", extraText: "" }}
        placeholder=""
        value={props.newContactEmail}
        onChange={(e) => props.setNewContactEmail(e.target.value.trim())}
        errorMessage={props.addContactEmailError}
      />
      <Input
        className="sb-wrapper bottom"
        label={{ regularText: "Phone Number ", extraText: "(Optional)" }}
        placeholder=""
        value={props.newContactPhone}
        onChange={(e) => props.setNewContactPhone(e.target.value.trim())}
        type="number"
      />
      <div className="checkbox-wrapper">
        <input
          type="checkbox"
          className="is-primary-checkbox"
          checked={props.newContactPrimary}
          onChange={() => props.setNewContactPrimary(!props.newContactPrimary)}
        />
        <span className="contact-text">Make Primary Contact</span>
      </div>
      <div className={"add-contact-btn-grp"}>
        <Button className={"invite-btn"} variant="secondary" onClick={props.resetFormStates}>
          Cancel
        </Button>
        {props.dataMode === DataMode.View ? (
          <Button loading={props.showLoader} className={"invite-btn"} onClick={props.handleAddContact}>
            Add Contact
          </Button>
        ) : (
          <Button loading={props.showLoader} className={"invite-btn"} onClick={props.handleEditContact}>
            Update
          </Button>
        )}
      </div>
    </div>
  );
};

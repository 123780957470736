import React from "react";
import { DEFAULT_NUMERIC_VALUES } from "../../../constants/NumericConstants";
import SummaryHeaderComponent from "./SummaryHeaderComponent";
import { Formatter, SummaryHeaderContainerProps, SummaryItem } from "./SummaryHeaderInterfaces/SummaryHeaderInterface";

function SummaryHeaderContainer(props: SummaryHeaderContainerProps) {
  const [items, setItems] = React.useState<SummaryItem[]>();

  const formatter: Formatter = {
    number: (value: number) => value ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
    currency: (value: number) =>
      new Intl.NumberFormat(props.currency?.locale ?? "en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        style: "currency",
        currency: props.currency?.code ?? "USD",
      }).format(value ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO),
    percentage: (value: number) =>
      `${new Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2, style: "decimal" }).format(
        value ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
      )}%`,
  };

  const parseValues = (items: SummaryItem[]): SummaryItem[] => {
    return items.map((item: SummaryItem) => {
      return {
        label: item.label,
        value: formatter[item.format ?? "number"](item.value),
      };
    });
  };

  React.useEffect(() => {
    setItems(parseValues(props.items));
  }, [props.items]);
  return <SummaryHeaderComponent data={items ?? []} {...props} />;
}

export default SummaryHeaderContainer;

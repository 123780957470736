import APIClient from "../APIClient";
import { AxiosRequestConfig } from "axios";

const INBOX_API_BASE = `${process.env.REACT_APP_API_ENDPOINT_INBOX_API_PATH}${process.env.REACT_APP_API_ENDPOINT_INBOX_API_V2}`;
const CONFIG_OVERRIDE = {
  baseURL: process.env.REACT_APP_API_ENDPOINT_INBOX,
} as AxiosRequestConfig;

export class EnrollmentsClient {
  getEnrollments2(id: UUID | number): Promise<any> {
    const apiUrl = `${INBOX_API_BASE}/workspaces/${id}/channels`;
    return APIClient.get(apiUrl, CONFIG_OVERRIDE).then((res) => res.data);
  }

  deleteEnrollment(id: string, workspace_id: number, data: EmailConnectorRemoveModel): Promise<APIResponse> {
    const url = `${INBOX_API_BASE}/workspaces/${workspace_id}/channels/${id}`;
    const options = { data, ...CONFIG_OVERRIDE };
    return APIClient.delete(url, options).then((res) => res.data);
  }

  reconnectChannel(workspace_id: UUID | number, channel_id: UUID | number, body: AddConnector): Promise<APIResponse> {
    const url = `${INBOX_API_BASE}/workspaces/${workspace_id}/channels/${channel_id}/reconnect`;
    const apiBody = Object.assign(body, process.env.REACT_APP_ENV === "local" ? { local_testing: true } : {});
    return APIClient.patch(url, apiBody, CONFIG_OVERRIDE).then((res) => res.data);
  }

  createEnrollment(workspace_id: UUID | number, body: AddConnector) {
    const url = `${INBOX_API_BASE}/workspaces/${workspace_id}/channels`;
    const apiBody = Object.assign(body, process.env.REACT_APP_ENV === "local" ? { local_testing: true } : {});
    return APIClient.post(url, apiBody, CONFIG_OVERRIDE).then((res) => res.data);
  }
}

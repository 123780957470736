import APIClient from "../APIClient";
import { AxiosRequestConfig } from "axios";

const INBOX_API_BASE = `${process.env.REACT_APP_API_ENDPOINT_INBOX_API_PATH}${process.env.REACT_APP_API_ENDPOINT_INBOX_API_V2}`;
const CONFIG_OVERRIDE = {
  baseURL: process.env.REACT_APP_API_ENDPOINT_INBOX,
} as AxiosRequestConfig;

export class ContactsClient {
  getContacts(workspaceId: number, columnFilters?: string, sortQuery?: string, pageSize?: number, pageNumber?: number) {
    let url = `${INBOX_API_BASE}/workspaces/${workspaceId}/contacts?page=${pageNumber}&page_size=${pageSize}`;
    if (sortQuery) {
      url = `${url}&${sortQuery}`;
    }
    if (columnFilters) {
      url = `${url}&${columnFilters}`;
    }

    return APIClient.get(url, CONFIG_OVERRIDE).then((response) => {
      return {
        pageNumber: response.data.page,
        pageSize: response.data.page_size,
        totalCount: response.data.total_records,
        records: response.data.records,
      };
    });
  }

  createContact(payload: BulkUpdateCreateContact, workspaceId?: number) {
    const url = `${INBOX_API_BASE}/workspaces/${workspaceId}/contacts/`;
    return APIClient.post(url, payload, CONFIG_OVERRIDE).then((response) => {
      return response.data;
    });
  }

  deleteContacts(ids: string[], workspaceId?: number) {
    const payload: Record<string, string[]> = {
      contact_ids: ids,
    };
    const url = `${INBOX_API_BASE}/workspaces/${workspaceId}/contacts/`;
    return APIClient.bulkDelete(url, payload, CONFIG_OVERRIDE).then((response) => {
      return response.data;
    });
  }

  updateContacts(payload: BulkUpdateContactStatusPayload, workspaceId?: number) {
    const url = `${INBOX_API_BASE}/workspaces/${workspaceId}/contacts/`;
    return APIClient.patch(url, payload, CONFIG_OVERRIDE).then((response) => {
      return response.data;
    });
  }

  getContactDetails(id: UUID, workspaceId?: number): Promise<APIResponse> {
    const url = `${INBOX_API_BASE}/workspaces/${workspaceId}/contacts/${id}`;
    return APIClient.get(url, CONFIG_OVERRIDE).then((res) => res.data);
  }
}

import React, { MouseEvent, ReactElement } from "react";
import CustomTooltip from "../../CustomTooltip/CustomTooltip";
import { UserPic } from "../../Icons/Icons";
import "./Userpic.scss";
import { useTranslation } from "react-i18next";

interface Props {
  className?: string;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  id?: string;
  imageUrl?: string;
}

/**
 * * Defines the User Picture Button
 */
export default function Userpic(props: Props): ReactElement {
  const [hasError, setHasError] = React.useState<boolean>(false);
  const handleImageLoadError = () => {
    setHasError(true);
  };
  const { t } = useTranslation();

  return (
    <CustomTooltip placement="bottom-end" title={t("header.tooltipMessages.inboxTabs.myProfile", { ns: "home" }) as string} disableFocusListener>
      <button
        id={props.id}
        className={`userpic-btn ${props.onClick ? "userpic-btn-hoverable" : "user-icon-default"} ${props.className ?? ""}`}
        onClick={props.onClick}
        type="button"
      >
        {props.imageUrl && !hasError ? (
          <img src={props.imageUrl} alt="User Picture" className="userpic-btn-img" onError={handleImageLoadError} />
        ) : (
          <>{props.icon ?? <UserPic />}</>
        )}
      </button>
    </CustomTooltip>
  );
}

import React from "react";
import { useHistory } from "react-router";
import { ApprovalDetailsContext, ApprovalsProviderType } from "../../../contexts/external/ApprovalDetailsContext";
import AlreadyApprovedError from "./AlreadyApprovedError";
import RequestTimedOut from "./RequestTimedOut";
import RequestCancelledError from "./RequestCancelledError";
import { LocationState } from "./LinkExpiryTypes";
import _ from "underscore";
import { NUMERIC_VALUES } from "../../../constants/NumericConstants";

/**
 * Magic Link Error Scenarios:
 * case 1: request cancellation:
 *              HTTP CODE:  200
 *              ERROR MESSAGES: ""
 *              Condition:  status === "cancelled"
 * case 2: request previously reviewed/redirected:
 *              HTTP CODE:  200
 *              Condition:  status is either one of approved/declined/redirected
 * case 4: request timeout - 60 days link expiry:
 *              HTTP Code: 422
 *              ERROR MESSAGES: "expired on <link-expiry-date>"
 *              Condition: error message contains 'expired'
 * case 3: connection-error:
 *              HTTP CODE:  Other than 422 & 200, Example: 500, 401, 403
 *              Condition:  if there are any server issues while loading a request
 */

const LinkExpiry = (): React.ReactElement => {
  const history = useHistory();
  const approvalContext = React.useContext(ApprovalDetailsContext) as ApprovalsProviderType;

  /**
   * get request data and link expiry location information from cache
   */
  const locCacheStr = sessionStorage.getItem("link-expiry-location");
  const locCache = locCacheStr && JSON.parse(locCacheStr);
  const reqCacheStr = sessionStorage.getItem("request-data");
  const reqCache = reqCacheStr && JSON.parse(reqCacheStr);

  /**
   * if there is no state available on history context => page was
   * refreshed, load data from cache.
   */
  const location = (history.location.state as LocationState) ?? locCache?.location ?? {};
  /**
   * if the requestData object received from approval context is empty => page
   * was refreshed, load data from cache.
   */
  const requestData = !_.isEmpty(approvalContext.requestData) ? approvalContext.requestData : reqCache;

  /**
   * save the prior location data in local storage, so that view
   * won't break on page refresh
   */
  React.useEffect(() => {
    sessionStorage.setItem(
      "request-expired",
      JSON.stringify({
        location: location,
      })
    );
  }, [location, requestData]);

  return location.error?.includes("cancelled") ? (
    <RequestCancelledError requestData={requestData} />
  ) : location.error?.includes("expired") ? (
    <RequestTimedOut
      errorMsg={location.error}
      requestInfo={
        location?.info ?? { status: "", has_active_approval: false, approval_request_id: "", expiry_duration: NUMERIC_VALUES.CONSTANT_THIRTY }
      }
    />
  ) : location.from === "redirect-request" ? (
    <AlreadyApprovedError requestData={requestData} />
  ) : (
    (function () {
      history.push("/error");
      return <></>;
    })()
  );
};

export default LinkExpiry;

import React from "react";
import RedirectRequestContainer from "./components/Approvals/RedirectRequest/RedirectRequestContainer";
import "./styles.scss";
import { Route, Switch } from "react-router";
import FourOFourError from "./routes/FourOFourError/FourOFourError";
import ApprovalReview from "./components/Approvals/ApprovalReview";
import MagicAuthProvider from "./contexts/external/MagicAuthContext";
import { ApprovalDetailsContext, ApprovalsProviderType } from "./contexts/external/ApprovalDetailsContext";
import LinkExpiry from "./components/Approvals/LinkExpiry/LinkExpiry";
import ConnectionError from "./components/Approvals/LinkExpiry/ConnectionError";
import RegenerationSuccess from "./components/Approvals/LinkExpiry/RegenerationSuccess";
import _ from "underscore";

const MagicLinks = () => {
  const approvalContext = React.useContext(ApprovalDetailsContext) as ApprovalsProviderType;

  // get request data information from cache
  const reqCacheStr = sessionStorage.getItem("request-data");
  const reqCache = reqCacheStr && JSON.parse(reqCacheStr);
  /**
   * if the requestData object received from approval context is empty => page
   * was refreshed, load data from cache.
   */
  const requestData = !_.isEmpty(approvalContext.requestData) ? approvalContext.requestData : reqCache;

  React.useEffect(() => {
    return () => {
      sessionStorage.removeItem("request-data");
    };
  }, []);

  const AccountHeader = () => {
    return requestData?.account_details?.account_logo_url ? (
      <img src={requestData.account_details.account_logo_url} alt={requestData?.account_details?.account_name} />
    ) : requestData?.account_details?.account_name ? (
      <h2 className="account-name">{requestData?.account_details?.account_name}</h2>
    ) : (
      <></>
    );
  };

  return (
    <div className="magic-app-container">
      <div className="app-header">
        <AccountHeader />
      </div>
      <div className="app-content">
        <MagicAuthProvider>
          <Switch>
            <Route exact path={`/approval-request/:id`} component={ApprovalReview} />
            <Route exact path={`/redirect-request/:id`} component={RedirectRequestContainer} />
            <Route exact path={`/link-expired`} component={LinkExpiry} />
            <Route exact path={`/error`} component={ConnectionError} />
            <Route exact path={`/link-regeneration-success`} component={RegenerationSuccess} />
            <Route path={`/`} component={() => <FourOFourError transparent />} />
          </Switch>
        </MagicAuthProvider>
      </div>
      <div className="app-footer">
        <p className="footer-txt">Connected by Sage Network</p>
      </div>
    </div>
  );
};

export default MagicLinks;

import React from "react";
import DOMPurify from "dompurify";
import { GLOBAL_SEARCH_CONSTANTS, GLOBAL_SEARCH_STATIC_TEXTS } from "../../../constants/GlobalSearchConstants";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../../constants/NumericConstants";
import { GlobalSearchCategories } from "../../../types/enums";
import Utils from "../../../utils/utils";
import ContentHeadingComponent from "../GlobalSearchDialogComponents/ContentHeading";
import RecentViewedResults from "../GlobalSearchDialogComponents/RecentViewedResults";
import SearchSuggestions from "../GlobalSearchDialogComponents/SearchSuggestions";
import useGlobalSearchStyle from "../Styles/useGlobalSearchStyles";
import "../Styles/GlobalMainContentStyles.scss";
import { Divider, List } from "@mui/material";
import { ObjectInterface, RecentSearchItem, SearchResultContentInterface } from "../Interface/SearchResultContentInterface";
import NoResultFoundComponent from "../GlobalSearchDialogComponents/NoResultFound";
import ResultItemComponent from "../GlobalSearchDialogComponents/ResultItemComponent";
import ResultLoadingSkelton from "../GlobalSearchDialogComponents/ResultLoadingSkelton";
import AddConnectionBanner from "../GlobalSearchDialogComponents/AddConnectionBanner";
import useLocale from "../../../hooks/useLocale";

const SearchResultContent: React.FC<SearchResultContentInterface> = ({
  commonProps: {
    activeItemRef,
    activeKeyDownIndex,
    config,
    containerRef,
    iconMap,
    loading,
    onClickNavToAllResults,
    onClickSuggestions,
    query,
    currency,
    selectedTab,
  },
  globalSearchResultProps: { data, enableGlobalSuggestions, isEInvoice },
  recentSearchProps: { enableRecentViewed, recentSuggestions },
  suggestionQueryListProps: { searchQueryListData },
}: SearchResultContentInterface) => {
  const { searchListContainer, recentViewedItemSelected, recentVieweditem } = useGlobalSearchStyle({isEInvoice});
  const { formatDateBasedOnCountry: formatDate } = useLocale();

  // List Items View
  const getListItem = (
    header = "-",
    subheader = "-",
    contentPreview = "",
    info = "-",
    moreDetails = "",
    id = "invalid-id",
    category = "",
    itemIndex: number,
    app_enrollment_id = "",
    company_type = "",
    einvoice_status = ""
  ) => (
    <div
      key={`gsw-${header}-${subheader}-${itemIndex}`}
      ref={(enableGlobalSuggestions && itemIndex === activeKeyDownIndex && activeItemRef) || undefined}
      className={`gs-suggestion-listItem-container ${itemIndex === activeKeyDownIndex ? "gs-suggestion-listItem-container-active" : ""} ${category
        ?.split("_")
        .join("-")}`}
      onClick={() => {
        onClickSuggestions(id, header, einvoice_status, category, app_enrollment_id, company_type);
      }}
    >
      <ResultItemComponent
        icon={iconMap[category]}
        header={header}
        query={query}
        info={info}
        subheader={subheader}
        moreDetails={moreDetails}
        contentPreview={contentPreview}
        eInvoiceStatus={einvoice_status}
      />

      <Divider />
    </div>
  );

  // Formatters for different values
  const formatListData = (value: string | number | null | undefined, type?: string): string | undefined => {
    switch (type) {
      case "timestamp":
        return formatDate(Utils.evaluateDate(value as number));
      case "isoDate":
        return formatDate(value as string);
      case "currency":
        return Utils.formatValueAsCurrency(value as number, currency?.locale, currency?.code);
      default:
        return value?.toString();
    }
  };

  // for list data splitting
  const getListData = () => {
    const dataKeyMap = config.dataSelectors;
    return (
      <>
        {data.map((info, index: number) => {
          const infoData = dataKeyMap[info.category ?? ""];
          if (!infoData) return;
          return (
            <div key={index}>
              <ContentHeadingComponent
                key={`gsw-${info?.category}`}
                title={config["displayLabels"][info?.category]}
                isViewResultRequired={info?.count > NUMERIC_VALUES.CONSTANT_THREE}
                totalCount={info?.count > NUMERIC_VALUES.CONSTANT_THOUSAND ? NUMERIC_VALUES.CONSTANT_THOUSAND + "+" : info?.count}
                onClickViewAll={onClickNavToAllResults.bind(this, query, info?.category)}
              />
              <List className={searchListContainer} key={`${index}-${info?.category}`}>
                {info?.result.map((item: ObjectInterface, pos: number) => {
                  return getListItem(
                    formatListData(item[infoData.header.key], infoData.header.type),
                    formatListData(item[infoData.subheader.key], infoData.subheader.type),
                    infoData?.contentPreview && item[infoData?.contentPreview?.key]
                      ? formatListData(item[infoData?.contentPreview?.key], infoData.contentPreview.type)
                      : "",
                    formatListData(item[infoData.info.key], infoData.info.type),
                    formatListData(item[infoData.moreDetails.key], infoData.moreDetails.type),
                    item[infoData.id],
                    info?.category,
                    index * GLOBAL_SEARCH_CONSTANTS.ALL_FILTER_MAX_SHOW_RESULTS + pos,
                    item?.app_enrollment_id,
                    item?.company_type,
                    item?.einvoice_status
                  );
                })}
              </List>
            </div>
          );
        })}
      </>
    );
  };

  // View for search Results
  const getAllSearchResults = () => {
    return (
      <div ref={(enableGlobalSuggestions && containerRef) || undefined} className="gs-suggestion-all-category">
        {getListData()}
      </div>
    );
  };
 
  //Recently Viewed
  const getRecentViewedItems = (item: RecentSearchItem, index: number) => {
    const recentQuery = item.query ? DOMPurify.sanitize(item?.query) : "N/A";
    return (
      <div
        key={item?.id + item?.category + recentQuery}
        ref={(enableRecentViewed && index === activeKeyDownIndex && activeItemRef) || undefined}
        className={`${recentVieweditem} ${index === activeKeyDownIndex ? recentViewedItemSelected : ""}`}
        onClick={() => {
          onClickSuggestions(item?.id, recentQuery, item?.einvoice_status, item?.category, item?.app_enrollment_id, item?.company_type);
        }}
      >
        <RecentViewedResults
          icon={iconMap[item?.category]}
          title={recentQuery}
          categoryIsNotAll={item?.category !== GlobalSearchCategories.all}
          categoryLabel={config["displayLabels"][item.category]}
          eInvoiceStatus={item?.einvoice_status}
        />
      </div>
    );
  };

  const getRecentSuggestions = () => {
    return (
      <div ref={(enableRecentViewed && containerRef) || undefined} className="gs-recent-suggestions-container">
        {!selectedTab.includes("All") && <SearchSuggestions data={searchQueryListData} />}

        {recentSuggestions?.length > NUMERIC_VALUES.CONSTANT_ZERO ? (
          <>
            <ContentHeadingComponent title={GLOBAL_SEARCH_STATIC_TEXTS.RECENTLY_VIEW_RESULTS} isViewResultRequired={false} />
            <List className={searchListContainer}>
              {recentSuggestions?.map((item: RecentSearchItem, index: number) => getRecentViewedItems(item, index))}
            </List>
          </>
        ) : (
          selectedTab.includes("All") && <SearchSuggestions data={searchQueryListData} />
        )}
      </div>
    );
  };

  // Rendering
  const getSearchDialogContent = () => {
    if (loading) {
      return <ResultLoadingSkelton />;
    } else {
      if (enableGlobalSuggestions) {
        if (data?.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
          return (
            <>
              {selectedTab.includes("Connections") && <AddConnectionBanner />}
              <NoResultFoundComponent hasEmptySearchKeyword={false} />
            </>
          );
        } else {
          return getAllSearchResults();
        }
      } else {
        return getRecentSuggestions();
      }
    }
  };
  return <div className={`gs-main-content-container`}>{getSearchDialogContent()}</div>;
};

export default SearchResultContent;

import { makeStyles } from "@mui/styles";
import styles from "../../../css/_export.module.scss";

const useDropdownIntegerStyle = () => {
  const useStyles = makeStyles({
    divRoot: {
      border: `${styles.borderline} solid ${styles.grey90}`,
      background: `${styles.white100}`,
      borderRadius: styles.small,
    },
    fillDivRoot: {
      border: `${styles.borderline} solid ${styles.grey90}`,
      borderRadius: styles.small,
      background: "#4D4D4D",
      color: styles.white100,
      "& .MuiOutlinedInput-root": {
        "& .MuiOutlinedInput-input": {
          color: styles.white100,
        },
        "& .MuiSelect-icon": {
          color: styles.white100,
        },
      },
    },
    select: {
      height: "2.5rem",
      boxShadow: "none",
      "&:hover": {
        "& .MuiSelect-icon": {
          color: styles.white100,
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
        boxShadow: "none",
      },
      "& .MuiOutlinedInput-input": {
        display: "flex",
        height: "100%",
        alignItems: "center",
        color: styles.grey10,
        fontFamily: styles.primaryFontFamily,
        fontWeight: "400",
        fontSize: styles.fontSizeSM,
        lineHeight: styles.lineHeightSM,
        padding: `0 ${styles.medium}`,
        "&:hover": {
          background: "#4D4D4D",
          color: styles.white100,
        },
      },
      "& .MuiSelect-icon": {
        color: styles.grey700,
      },
    },
    menuRoot: {
      "& .MuiPaper-root": {
        width: `11.25rem`,
        minHeight: `2.5rem`,
        maxHeight: `23.5rem`,
        background: styles.white100,
        marginTop: styles.small,
        borderRadius: styles.atom,
        boxShadow: `0 ${styles.tiny} ${styles.small} rgba(0, 0, 0, 0.12)`,
        gap: styles.small,
      },
      "& .MuiList-root": {
        height: "100%",
        "& .MuiMenuItem-root": {
          height: "2.25rem",
          fontFamily: styles.primaryFontFamily,
          fontWeight: "400",
          fontSize: styles.fontSizeSM,
          lineHeight: styles.lineHeightMD,
          margin: `0 ${styles.small}`,
          gap: styles.small,
          borderRadius: styles.small,
          padding: `0 ${styles.small}`,
          "&:hover": {
            background: styles.grey98,
          },
        },
        "& .Mui-selected": {
          background: styles.grey98,
        },
      },
    },
    hidelist: {
      display: "none",
    },
    listItemIcon: {
      width: "0.75rem",
      height: "0.5625rem",
      display: "flex",
    },
    itemText: {
      fontFamily: styles.primaryFontFamily,
      fontWeight: "400",
      fontSize: styles.fontSizeSM,
      lineHeight: styles.lineHeightSM,
      color: styles.grey10,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    amountMenuWrapper: {
      fontFamily: "Work Sans",
      fontWeight: "400",
      fontSize: "0.875rem",
      lineHeight: "20px",
      color: "#1A1A1A",
      marginBottom: "0.75rem",
    },
    amountOperatorWrapper: {
      display: "flex",
      gap: "0.75rem",
    },
    amountOption: {
      height: "2.5rem",
      boxShadow: "none",
      width: "11.1875rem",
      "&:hover": {
        "& .MuiSelect-icon": {
          color: styles.white100,
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
        boxShadow: "none",
      },
      "& .MuiOutlinedInput-input": {
        display: "flex",
        height: "100%",
        alignItems: "center",
        color: "#4D4D4D",
        fontFamily: styles.primaryFontFamily,
        fontWeight: "500",
        fontSize: styles.fontSizeXS,
        lineHeight: styles.lineHeightSM,
        padding: `0 ${styles.medium}`,
        "&:hover": {
          background: "#4D4D4D",
          color: styles.white100,
        },
      },
      "& .MuiSelect-icon": {
        color: styles.grey700,
      },
    },
    amountMenuRoot: {
      "& .MuiPaper-root": {
        maxWidth: `27.375rem`,
        minWidth: `23.375rem`,
        minHeight: `6.25rem`,
        maxHeight: `12.75rem`,
        background: styles.white100,
        marginTop: styles.small,
        borderRadius: styles.atom,
        boxShadow: `0 ${styles.tiny} ${styles.small} rgba(0, 0, 0, 0.12)`,
        gap: styles.small,
      },
      "& .MuiList-root": {
        padding: "1rem",
      },
    },
    inputContainerOld: {
      borderColor: "#DEE1ED",
    },
    inputContainer: {
      backgroundColor: styles.grey98,
      color: styles.primary,
      fontFamily: styles.primaryFontFamily,
      fontSize: styles.fontSizeSM,
      padding: "0",
      textIndent: styles.medium,
      background: styles.white100,
      borderColor: styles.grey90,
      borderRadius: styles.small,
      boxSizing: "border-box",
      height: "2.5rem",
      width: "100%",
      "&::placeholder": {
        fontStyle: "normal",
        fontWeight: "normal",
        color: styles.grey70,
      },
      "&:focus": {
        background: styles.white100,
        borderColor: styles.grey98,
      },
      "&:focus-visible": {
        outline: "none",
      },
      "&:disabled": {
        backgroundColor: styles.grey98,
        color: styles.grey70,
        cursor: "not-allowed",
      },
      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },
      "& input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& input[type=number]::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
    adornmentSearchIcon: {
      display: "flex",
      color: styles.grey40,
      fontSize: styles.fontSizeSM,
      marginLeft: "0.5rem",
    },
  });
  return useStyles();
};

export default useDropdownIntegerStyle;

import { ProviderDetails } from "./ApAutomationTypes";
import Stampli from "../../../assets/StampliLogo.svg";
import Avidx from "../../../assets/AvidxchangeLogo.svg";
import Billdotcom from "../../../assets/BilldotcomLogo.svg";
import Sage from "../../../assets/SageLogo.svg";
import ERP from "../../../assets/OtherAutomationProvider.svg";
import Beanworks from "../../../assets/Beanworks.svg";
import _ from "underscore";

/**
 * Supported provider details for visuals
 */
const automationProviders: Record<string, ProviderDetails> = {
  beanworks: {
    icon: Beanworks,
    alt: "Beanworks.com",
  },
  bill: {
    icon: Billdotcom,
    alt: "Bill.com",
  },
  avidxchange: {
    icon: Avidx,
    alt: "Avidxchange.com",
  },
  stampli: {
    icon: Stampli,
    alt: "Stampli.com",
  },
  sage: {
    icon: Sage,
    alt: "Sage.com",
  },
  others: {
    icon: ERP,
    alt: "Other",
  },
};

export type Providers = keyof typeof automationProviders | null;

/**
 * function takes the email from the user and check if the domain is a
 * known provider to Inbox, if yes it would return the provider's name
 * other wise return 'others'
 * @param mail input automation email address
 */
export const evaluateProvider = (mail: string): Providers => {
  if (_.isEmpty(mail)) return null;
  const domain = mail?.split("@")[1];
  return (
    Object.values(automationProviders)
      .map((provider: ProviderDetails) => provider.alt.toLocaleLowerCase())
      .filter((value: string) => value !== "other")
      .includes(domain)
      ? domain.split(".")[0]
      : "others"
  ) as Providers;
};

export default automationProviders;

import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import ActionGenerator, { ActionDefinition, ActionState } from "./ActionGeneratorHOC/ActionGenerator";
import { EINVOICE_ACTIONS, EINVOICE_STATES } from "../../../../../../../types/enums";
import { DEFAULT_NUMERIC_VALUES } from "../../../../../../../constants/NumericConstants";
import { workflowStatusClient, eInvoicesClient } from "../../../../../../../db/version3Accessor";
import useWorkspaceConfigurations from "../../../../../../../hooks/useWorkspaceConfigurations";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import StateActionLoadingSkeleton from "../../../FeedLoadingSkeleton/StateActionLoadingSkeleton";
import { AlertContext } from "../../../../../../../contexts/AlertContext";

import "./DocumentStateActionsRenderer.scss";

export type IDocumentState = {
  id: string;
  key: string;
  value: string;
  invoiceTypeCode: "AP Invoice" | "AR Invoice" | string;
};

export type IDocumentStateActionsRendererProps = {
  state: IDocumentState;
  recordId: string;
  onSuccess?: () => void;
};

export type IActionDefenitions = {
  [key: string]: React.ReactElement;
};

export type ActionConfig = {
  [key: string]: ActionDefinition["actionConfig"];
};

/**
 * @function DocumentStateActionsRenderer
 * A react functional component to render document actions based on an input state.
 * These actions are rendered based on a finite state definition
 * @param inputState
 * @param recordId
 * @param countryCode
 * @param view
 * @returns
 */
const DocumentStateActionsRenderer: React.FC<IDocumentStateActionsRendererProps> = ({ state, recordId, onSuccess }) => {
  const { t } = useTranslation();
  const { selectedWorkspace } = useWorkspaceConfigurations();
  const [isApplicableStatesRefreshing, setIsApplicableStatesRefreshing] = React.useState<boolean>(false);
  const [actionInProgress, setActionInProgress] = React.useState<string | null>(null);
  const queryClient = useQueryClient();
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;

  /**
   * query-cache for applicable states of a document.
   * cache key is set using record-id and workflow-status-id
   */
  const {
    isLoading: isApplicableStatesloading,
    data: applicableStates,
    isError,
  } = useQuery(
    [`e-invoice-status-workflow-${recordId}-${state?.id}`],
    async () => {
      const res = await workflowStatusClient.getApplicableStatuses(state?.id, state?.invoiceTypeCode);
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
    }
  );

  if (isError) {
    const toastOptions = {
      open: true,
      severity: "error",
      message: `Failed to fetch e-invoicing workflow details`,
    } as ToastOptions;
    setToastOptions(toastOptions);
  }

  const refereshEInvoiceStream = async (toastOptions: ToastOptions) => {
    setIsApplicableStatesRefreshing(true);
    await queryClient.refetchQueries();
    setActionInProgress(null);
    setToastOptions(toastOptions);
    setIsApplicableStatesRefreshing(false);
  };

  /**
   * @function actionConfigGenerator
   * A helper method to generate configuration for a given action
   * @param applicableState
   * @returns
   */
  const actionConfigGenerator = (applicableState: any) => {
    let actionConfig = {
      action_label: applicableState?.name,
      action_type: applicableState?.name?.split(" ")?.join("_")?.toLowerCase(),
      action_variant: "primary",
    } as any;
    if (applicableState?.is_notes_required && !applicableState?.is_reason_required) {
      actionConfig = {
        ...actionConfig,
        confirmation: true,
        confirmation_options: {
          title: `Are you sure you want to ${applicableState?.name?.toLowerCase()} this e-invoice?`,
          form: [
            {
              label: "Enter note",
              type: "text",
              inputIdentifier: "note",
              required: false,
              id: 1,
            },
          ],
          buttons: [
            {
              label: "Confirm",
              variant: "primary",
            },
          ],
        },
      };
    } else if (applicableState?.is_notes_required && applicableState?.is_reason_required) {
      actionConfig = {
        ...actionConfig,
        confirmation: true,
        confirmation_options: {
          title: "Are you sure you want to refuse this e-invoice?",
          subtitle: "By refusing, no further actions can be taken on this e-invoice.*",
          form: [
            {
              label: "",
              id: 1,
              type: "dropdown",
              inputIdentifier: "reason",
              options: [
                "Other reason than 'Validator error'",
                "Others: Contact your buyer",
                "Bank details error",
                "Creditor unknown or different from that of the market/order",
                "Recipient error",
                "Double billing",
                "Incorrect or non-existent commitment/order",
                "Bad validator",
                "Invoice is not in accordance with the order",
                "Absent or insufficient proof",
                "Delivery incomplete / not carried out",
                "Market finished",
                "Incorrect invoice amount",
                "Incorrect recipient service",
                "Undeclared subcontractor/co-contractor",
                "Deletion for asset compensation",
                "Transfer for payment in management (Reserved B2G)",
                "Wrong VAT rate",
                "Collection not made or cancellation of collection",
                "Other",
                "Routing error",
              ],
              required: true,
            },
            {
              label: "Add Note",
              inputIdentifier: "note",
              id: 2,
              type: "text",
              required: false,
            },
          ],
          buttons: [
            {
              label: "Confirm",
              variant: "primary",
            },
          ],
        },
      };
    }
    return actionConfig;
  };

  /**
   * Define the actions for a given FSM definition
   */
  const ACTION_CONFIG: ActionConfig = {
    refuse: {
      action_label: t("activityDetails.feedItems.primaryActivity.actionButtonLabels.refuse", { ns: "einvoices" }),
      action_type: "refuse",
      action_variant: "tertiary",
      confirmation: true,
      confirmation_options: {
        title: "Are you sure you want to refuse this e-invoice?",
        subtitle: "By refusing, no further actions can be taken on this e-invoice.*",
        form: [
          {
            label: "",
            id: 1,
            type: "dropdown",
            inputIdentifier: "reason",
            options: [
              "Other reason than 'Validator error'",
              "Others: Contact your buyer",
              "Bank details error",
              "Creditor unknown or different from that of the market/order",
              "Recipient error",
              "Double billing",
              "Incorrect or non-existent commitment/order",
              "Bad validator",
              "Invoice is not in accordance with the order",
              "Absent or insufficient proof",
              "Delivery incomplete / not carried out",
              "Market finished",
              "Incorrect invoice amount",
              "Incorrect recipient service",
              "Undeclared subcontractor/co-contractor",
              "Deletion for asset compensation",
              "Transfer for payment in management (Reserved B2G)",
              "Wrong VAT rate",
              "Collection not made or cancellation of collection",
              "Other",
              "Routing error",
            ],
            required: true,
          },
          {
            label: "Add Note",
            inputIdentifier: "note",
            id: 2,
            type: "text",
            required: false,
          },
        ],
        buttons: [
          {
            label: "Confirm",
            variant: "primary",
          },
        ],
      },
    },
    in_hand: {
      action_label: t("activityDetails.feedItems.primaryActivity.actionButtonLabels.in_hand", { ns: "einvoices" }),
      action_type: "in_hand",
      action_variant: "primary",
      confirmation: true,
      confirmation_options: {
        title: "Are you sure you want to mark this e-invoice as In Hand?",
        form: [
          {
            label: "Enter note",
            type: "text",
            inputIdentifier: "note",
            required: false,
            id: 1,
          },
        ],
        buttons: [
          {
            label: "Confirm",
            variant: "primary",
          },
        ],
      },
    },
    approve: {
      action_label: t("activityDetails.feedItems.primaryActivity.actionButtonLabels.approve", { ns: "einvoices" }),
      action_type: "approve",
      action_variant: "primary",
      confirmation: true,
      confirmation_options: {
        title: "Are you sure you want to approve this e-invoice?",
        form: [
          {
            label: "Enter note",
            type: "text",
            inputIdentifier: "note",
            required: false,
            id: 1,
          },
        ],
        buttons: [
          {
            label: "Confirm",
            variant: "primary",
          },
        ],
      },
    },
    partially_approve: {
      action_label: t("activityDetails.feedItems.primaryActivity.actionButtonLabels.partially_approve", { ns: "einvoices" }),
      action_type: "partially_approve",
      action_variant: "secondary",
      confirmation: true,
      confirmation_options: {
        title: "Are you sure you want to partially approve this e-invoice?",
        form: [
          {
            label: "Enter note",
            type: "text",
            inputIdentifier: "note",
            required: false,
            id: 1,
          },
        ],
        buttons: [
          {
            label: "Confirm",
            variant: "primary",
          },
        ],
      },
    },
    suspend: {
      action_label: t("activityDetails.feedItems.primaryActivity.actionButtonLabels.suspend", { ns: "einvoices" }),
      action_type: "suspend",
      action_variant: "tertiary",
      confirmation: true,
      confirmation_options: {
        title: "Are you sure you want to suspend this e-invoice?",
        form: [
          {
            label: "Enter note",
            type: "text",
            inputIdentifier: "note",
            required: false,
            id: 1,
          },
        ],
        buttons: [
          {
            label: "Confirm",
            variant: "primary",
          },
        ],
      },
    },
    complete: {
      action_label: t("activityDetails.feedItems.primaryActivity.actionButtonLabels.complete", { ns: "einvoices" }),
      action_type: "complete",
      action_variant: "tertiary",
      confirmation: true,
      confirmation_options: {
        title: "Are you sure you want to complete this e-invoice?",
        form: [
          {
            label: "Enter note",
            type: "text",
            inputIdentifier: "note",
            required: false,
            id: 1,
          },
        ],
        buttons: [
          {
            label: "Confirm",
            variant: "primary",
          },
        ],
      },
    },
    payment_sent: {
      action_label: t("activityDetails.feedItems.primaryActivity.actionButtonLabels.payment_sent", { ns: "einvoices" }),
      action_type: "payment_sent",
      action_variant: "tertiary",
      confirmation: true,
      confirmation_options: {
        title: "Are you sure you want to mark this e-invoice as payment sent?",
        form: [
          {
            label: "Enter note",
            type: "text",
            inputIdentifier: "note",
            required: false,
            id: 1,
          },
        ],
        buttons: [
          {
            label: "Confirm",
            variant: "primary",
          },
        ],
      },
    },
    payment_received: {
      action_label: t("activityDetails.feedItems.primaryActivity.actionButtonLabels.payment_received", { ns: "einvoices" }),
      action_type: "payment_received",
      action_variant: "tertiary",
      confirmation: true,
      confirmation_options: {
        title: "Are you sure you want to mark this e-invoice as payment received?",
        form: [
          {
            label: "Enter note",
            type: "text",
            inputIdentifier: "note",
            required: false,
            id: 1,
          },
        ],
        buttons: [
          {
            label: "Confirm",
            variant: "primary",
          },
        ],
      },
    },
    dispute: {
      action_label: t("activityDetails.feedItems.primaryActivity.actionButtonLabels.dispute", { ns: "einvoices" }),
      action_type: "dispute",
      action_variant: "tertiary",
      confirmation: true,
      confirmation_options: {
        title: "Are you sure you want to dispute this e-invoice?",
        form: [
          {
            label: "Enter note",
            type: "text",
            inputIdentifier: "note",
            required: false,
            id: 1,
          },
        ],
        buttons: [
          {
            label: "Confirm",
            variant: "primary",
          },
        ],
      },
    },
  };

  const actionCallback = async (callbackConfig: any | ActionState | ActionDefinition["actionConfig"]) => {
    const inputDataObject = Object.keys(callbackConfig)
      ?.filter((key) => key === "1" || key === "2")
      .map((keyIdentifier) => callbackConfig[keyIdentifier]);
    const payload: any = [];
    inputDataObject.forEach((obj) => payload.push({ [obj.inputIdentifier]: obj.value }));
    setActionInProgress(callbackConfig.action_type);
    const severity = [
      EINVOICE_STATES.IN_HAND,
      EINVOICE_STATES.DISPUTED,
      EINVOICE_STATES.SUSPENDED,
      EINVOICE_STATES.COMPLETED,
      EINVOICE_STATES.REFUSED,
    ].includes(callbackConfig.action_type)
      ? "info"
      : "success";
    let toastOptions = {
      open: true,
      severity: severity,
      message: `You've successfully changed the status to ${callbackConfig.action_label}`,
    } as ToastOptions;
    await eInvoicesClient
      .patchEInvoice(selectedWorkspace?.id, recordId, {
        workflow_status_id: callbackConfig?.id,
        read: true,
        ...Object.assign({}, ...payload.map((item: any) => ({ ...item }))),
      })
      .then(() => {
        onSuccess?.();
        toastOptions = {
          ...toastOptions,
          message: `You've successfully changed the status to ${callbackConfig.action_label}`,
        };
      })
      .catch(() => {
        toastOptions = {
          ...toastOptions,
          severity: "error",
          message: `Failed to update the status of this e-invoice.`,
        };
      })
      .finally(() => {
        refereshEInvoiceStream(toastOptions);
      });
  };

  /**
   * @constant actionDefinitions
   * This object holds the action component for each action definitions.
   */
  const actionDefinitions: IActionDefenitions = {
    in_hand: (
      <ActionGenerator
        actionConfig={{
          ...ACTION_CONFIG[EINVOICE_ACTIONS.IN_HAND],
          ...applicableStates?.find((state: any) => state?.name?.split(" ")?.join("_")?.toLowerCase() === EINVOICE_ACTIONS.IN_HAND),
          loading: (actionInProgress && actionInProgress === EINVOICE_ACTIONS.IN_HAND) || false,
          disabled: (actionInProgress && actionInProgress !== EINVOICE_ACTIONS.IN_HAND) || false,
        }}
        callback={actionCallback}
      />
    ),
    refuse: (
      <ActionGenerator
        actionConfig={{
          ...ACTION_CONFIG[EINVOICE_ACTIONS.REFUSE],
          ...applicableStates?.find((state: any) => state?.name?.split(" ")?.join("_")?.toLowerCase() === EINVOICE_ACTIONS.REFUSE),
          loading: (actionInProgress && actionInProgress === EINVOICE_ACTIONS.REFUSE) || false,
          disabled: (actionInProgress && actionInProgress !== EINVOICE_ACTIONS.REFUSE) || false,
        }}
        callback={actionCallback}
      />
    ),
    approve: (
      <ActionGenerator
        actionConfig={{
          ...ACTION_CONFIG[EINVOICE_ACTIONS.APPROVE],
          ...applicableStates?.find((state: any) => state?.name?.split(" ")?.join("_")?.toLowerCase() === EINVOICE_ACTIONS.APPROVE),
          loading: (actionInProgress && actionInProgress === EINVOICE_ACTIONS.APPROVE) || false,
          disabled: (actionInProgress && actionInProgress !== EINVOICE_ACTIONS.APPROVE) || false,
        }}
        callback={actionCallback}
      />
    ),
    partially_approve: (
      <ActionGenerator
        actionConfig={{
          ...ACTION_CONFIG[EINVOICE_ACTIONS.PARTIALLY_APPROVE],
          ...applicableStates?.find((state: any) => state?.name?.split(" ")?.join("_")?.toLowerCase() === EINVOICE_ACTIONS.PARTIALLY_APPROVE),
          loading: (actionInProgress && actionInProgress === EINVOICE_ACTIONS.PARTIALLY_APPROVE) || false,
          disabled: (actionInProgress && actionInProgress !== EINVOICE_ACTIONS.PARTIALLY_APPROVE) || false,
        }}
        callback={actionCallback}
      />
    ),
    suspend: (
      <ActionGenerator
        actionConfig={{
          ...ACTION_CONFIG[EINVOICE_ACTIONS.SUSPEND],
          ...applicableStates?.find((state: any) => state?.name?.split(" ")?.join("_")?.toLowerCase() === EINVOICE_ACTIONS.SUSPEND),
          loading: (actionInProgress && actionInProgress === EINVOICE_ACTIONS.SUSPEND) || false,
          disabled: (actionInProgress && actionInProgress !== EINVOICE_ACTIONS.SUSPEND) || false,
        }}
        callback={actionCallback}
      />
    ),
    complete: (
      <ActionGenerator
        actionConfig={{
          ...ACTION_CONFIG[EINVOICE_ACTIONS.COMPLETE],
          ...applicableStates?.find((state: any) => state?.name?.split(" ")?.join("_")?.toLowerCase() === EINVOICE_ACTIONS.COMPLETE),
          loading: (actionInProgress && actionInProgress === EINVOICE_ACTIONS.COMPLETE) || false,
          disabled: (actionInProgress && actionInProgress !== EINVOICE_ACTIONS.COMPLETE) || false,
        }}
        callback={actionCallback}
      />
    ),
    payment_sent: (
      <ActionGenerator
        actionConfig={{
          ...ACTION_CONFIG[EINVOICE_ACTIONS.PAYMENT_SENT],
          ...applicableStates?.find((state: any) => state?.name?.split(" ")?.join("_")?.toLowerCase() === EINVOICE_ACTIONS.PAYMENT_SENT),
          loading: (actionInProgress && actionInProgress === EINVOICE_ACTIONS.PAYMENT_SENT) || false,
          disabled: (actionInProgress && actionInProgress !== EINVOICE_ACTIONS.PAYMENT_SENT) || false,
        }}
        callback={actionCallback}
      />
    ),
    payment_received: (
      <ActionGenerator
        actionConfig={{
          ...ACTION_CONFIG[EINVOICE_ACTIONS.PAYMENT_RECEIVED],
          ...applicableStates?.find((state: any) => state?.name?.split(" ")?.join("_")?.toLowerCase() === EINVOICE_ACTIONS.PAYMENT_RECEIVED),
          loading: (actionInProgress && actionInProgress === EINVOICE_ACTIONS.PAYMENT_RECEIVED) || false,
          disabled: (actionInProgress && actionInProgress !== EINVOICE_ACTIONS.PAYMENT_RECEIVED) || false,
        }}
        callback={actionCallback}
      />
    ),
    dispute: (
      <ActionGenerator
        actionConfig={{
          ...ACTION_CONFIG[EINVOICE_ACTIONS.DISPUTE],
          ...applicableStates?.find((state: any) => state?.name?.split(" ")?.join("_")?.toLowerCase() === EINVOICE_ACTIONS.DISPUTE),
          loading: (actionInProgress && actionInProgress === EINVOICE_ACTIONS.DISPUTE) || false,
          disabled: (actionInProgress && actionInProgress !== EINVOICE_ACTIONS.DISPUTE) || false,
        }}
        callback={actionCallback}
      />
    ),
  };

  const assignActionToConfig = (action: any) => {
    return (
      <ActionGenerator
        actionConfig={{
          ...action,
          ...[...(applicableStates?.length ? applicableStates : [])]?.find(
            (state: any) => state?.name?.split(" ")?.join("_")?.toLowerCase() === action?.action_label?.split(" ")?.join("_")?.toLowerCase()
          ),
          loading: (actionInProgress && actionInProgress === action?.action_label?.split(" ")?.join("_")?.toLowerCase()) || false,
          disabled: (actionInProgress && actionInProgress !== action?.action_label?.split(" ")?.join("_")?.toLowerCase()) || false,
        }}
        callback={actionCallback}
      />
    );
  };

  const actionsToRender = [...(applicableStates?.length ? applicableStates : [])]?.map((action: any) => {
    //custom french workflow
    if (actionDefinitions[action?.name?.split(" ")?.join("_")?.toLowerCase()]) {
      return actionDefinitions[action?.name?.split(" ")?.join("_")?.toLowerCase()];
    } else {
      //generic workflow
      return assignActionToConfig(actionConfigGenerator(action));
    }
  });

  const actionMessages = () => {
    if (state?.key === EINVOICE_STATES.REFUSED) {
      return <p className="action-text-refused">You've refused this e-invoice. No additional actions can be taken.</p>;
    } else if (state?.key === EINVOICE_STATES.PAYMENT_SENT) {
      return <p className="action-text-refused">Awaiting payment status update from the sender.</p>;
    } else if (state?.key === EINVOICE_STATES.SUSPENDED) {
      return <p className="action-text-refused">Once supporting documents are added, this invoice will be made available.</p>;
    } else {
      return actionsToRender;
    }
  };

  if (
    state?.key !== EINVOICE_STATES.REFUSED &&
    state?.key !== EINVOICE_STATES.SUSPENDED &&
    state?.key !== EINVOICE_STATES.PAYMENT_SENT &&
    actionsToRender?.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
  ) {
    return null;
  }

  if (isApplicableStatesloading || isApplicableStatesRefreshing) {
    return <StateActionLoadingSkeleton key={Date.now()} />;
  }

  return (
    <div className={`e-invoice-actions ${isApplicableStatesloading ? "loading" : ""}`}>
      <div className="actions-info">
        <p className="header">{t("activityDetails.actions.actionHeader", { ns: "einvoices" })}</p>
        <p className="sub-header">{t("activityDetails.actions.actionSubHeader", { ns: "einvoices" })}</p>
      </div>
      <div className="actions">{actionMessages()}</div>
    </div>
  );
};

export default memo(DocumentStateActionsRenderer);

import React from "react";
import { log } from "react-fullstory";

import Utils from "../../../utils/utils";
import "./Error.scss";

interface ErrorProps {
  children: React.ReactNode;
  isTableCell?: boolean;
  isRelative?: boolean;
}

export default function CustomError(props: ErrorProps): React.ReactElement {
  if (Utils.isProduction() && typeof props.children === "string") {
    log("warn", props.children);
  }

  return props.isTableCell ? (
    <tr>
      <td className={`error-cell`}>
        <div className={`error-wrapper error-wrapper${props.isRelative ? "-relative" : ""}`}>
          <pre>{props.children}</pre>
        </div>
      </td>
    </tr>
  ) : (
    <div className={`error-wrapper error-wrapper${props.isRelative ? "-relative" : ""}`}>
      <pre>{props.children}</pre>
    </div>
  );
}

import React, { useState, MouseEvent, useEffect, SetStateAction, Dispatch, useMemo } from "react";
import Button from "../Button/Button";
import ActivityTo from "./ActivityTo/ActivityTo";
import ActivityTitle from "./ActivityTitle/ActivityTitle";
import ActivityBody from "./ActivityBody/ActivityBody";
import CloseIcon from "@mui/icons-material/Close";
import "./NewActivityCard.scss";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../../constants/NumericConstants";
import Loading from "../../library/Loading/Loading";
import DropMenu from "../../library/DropMenu/DropMenu";
import Menu from "../../library/Menu/Menu";
import MenuItem from "../../library/MenuItem/MenuItem";
import _ from "underscore";
import { VIEW } from "./Attachments/AttachmentConstants";
import { TemplateTypes } from "../../../app/Templates/TemplateTypes";
import DOMPurify from "dompurify";
import { Reply, AlertDanger, AlertInfo, ArrowDown, ArrowUp, EmailIcon } from "../../library/Icons/Icons";
import * as constants from "../../../constants/config";
import useWorkspaceConfigurations from "../../../hooks/useWorkspaceConfigurations";
import { MENTION_REGEX_CONSTANTS } from "../../../constants/RegexConstants";
import { CustomerContext } from "../../../contexts/CustomerContext";
import { ActivityOptions, APARStances } from "../../../types/enums";
import { useLocation } from "react-router";
import { ActivityEmailBodyActionShortcuts, KeyboardActionsConstants } from "../../../constants/KeyboardShortcutConstants";
import useKeyboardShortcut from "../../../hooks/useKeyboardShortcut";
import { TooltipTitles, TooltipTypes } from "../../../constants/TooltipConstants";
import { FeatureFlagContext, FeatureFlagProviderType } from "../../../contexts/FeatureFlagContext";
import { DisableFeatureContext, DisableFeatureProviderType } from "../../../contexts/DisableFeatureContext";
import { SelectChangeEvent, Link } from "@mui/material";
import TemplateSelectMenu, { TemplateType } from "./TemplateSelectMenu/TemplateSelectMenu";
import useLocale from "../../../hooks/useLocale";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Button as ButtonV2 } from "../../library/AtomicComponents/atomic/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import CustomTooltip from "../../library/CustomTooltip/CustomTooltip";
import { AttachmentPreview } from "../../library/AtomicComponents/AttachmentPreview";
import { AttachmentChip } from "../../library/AtomicComponents/AttachmentChip";
import { Link as RouterLink } from "react-router-dom";
import { formatDate } from "../../../db/utils/date";
import TimeIcon from "../AddNewActivityDropdown/ActivityBody/CustomButtons/TimeIcon";
import { AttachmentTypes, ERPAttachment, WindowAttachment } from "../AddNewActivityDropdown/types/windowAttachment.types";
import useWindowAttachmentManager from "../AddNewActivityDropdown/hooks/useWindowAttachmentManager";
import { useTranslation } from "react-i18next";
import DiscardDraftModal from "../DiscardDraftModal/DiscardDraftModal";
import useDragAndDropFiles from "../../../hooks/useDragAndDropFiles";
import useProcessTemplateData, { TempSubjectSnippetValuesType } from "../../../hooks/useProcessTemplateData";
import { TemplateListItemType } from "../EmailTemplatePopoverComponent/types/email.template.popover.types";
import { documentsClient } from "../../../db/accessor";

export type ActivityToRef = {
  onSelectTo: (contacts: To[]) => void;
} | null;

export type ActivityPopupRef = {
  refreshPopupContent: () => void;
} | null;

type MentionsDataItemType = {
  id: number;
  name?: string;
  email_address?: string;
  active_at?: string;
  user_role?: string;
};

type MentionMembersType = {
  loading: boolean;
  members: Array<MentionsDataItemType>;
};

export interface NewActivityPopupProps {
  title?: string | null;
  open?: boolean;
  activityId?: string,
  defaultTo?: To[];
  defaultCc?: To[];
  defaultBcc?: To[];
  defaultTitle?: string;
  defaultBody?: string;
  defaultFiles?: (File | AttachmentItem)[];
  replyOptions?: { replyButton: boolean; start: string; reply: To[]; replyAll: To[]; replyAllCC: To[] };
  forwarding?: boolean;
  disableTitle?: boolean;
  handleSend?: (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    activityId: string,
    attachmentIds: AttachmentIds,
    inboxAttachments: any,
    disableSoftRefresh?: boolean,
    isSendAndClose?: boolean,
    fromCopilot?: boolean,
    responseEdited?: boolean
  ) => Promise<any>;
  handleSendMarkClosed?: (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    activityId: string,
    attachmentIds: AttachmentIds,
    inboxAttachments: any,
    fromCopilot?: boolean,
    responseEdited?: boolean
  ) => Promise<void>;
  discardCopilot?: () => void;
  onClose?: () => void;
  contactOptions?: To[];
  activityType?: string | null;
  setNewActivityType?: (value: any) => void;
  addActivityDropdown?: ActivityDropdownItem[];
  editorState?: any;
  buttonProps?: {
    primaryActionLabel?: string;
    secondaryActionLabel?: string;
  } | null;
  typeAheadAction?: (val: string) => void;
  setDefaultTitle?: (value: any) => void;
  isTemplateSupport?: boolean;
  supportedTemplateList?: any;
  defaultTemplateId?: TemplateTypes | string;
  prepareActivityBodyByTemplateID?: (
    templateID: string | null,
    setEditorState: React.Dispatch<React.SetStateAction<string>>,
    lang: string,
    cb: any
  ) => void;
  clickDisabled?: boolean;
  disableSecondaryAction?: boolean;
  loading?: boolean;
  fromTime?: number;
  toTime?: number;
  setToTime?: Dispatch<SetStateAction<number>>;
  defaultAttachmentsList?: AttachmentItem[];
  refs?: {
    toRef?: React.MutableRefObject<ActivityToRef>;
    popupRef?: React.MutableRefObject<ActivityPopupRef>;
  };
  enableKeyboardShortcuts?: boolean | undefined;
  getDefaultTemplateId?: (newActivityType: ActivityOptions | string) => TemplateTypes | string;
  onClickDownload: (
    event: React.MouseEvent<HTMLTableRowElement | HTMLDivElement | HTMLButtonElement | HTMLAnchorElement | HTMLSpanElement>,
    attachments: AttachmentItem[] | BlobAttachmentItem[]
  ) => void;
  workspaceData?: UserItem;
  isCopilot?: boolean;
  copilotContent?: string;
  isSendButtonDisabled: boolean;
  suggestedTemplatesCode?: string[];
  tempSubjectSnippetValues?: TempSubjectSnippetValuesType;
  setToastOptions?: (toastOptions: ToastOptions) => void;
}

export default function NewActivityPopup(props: NewActivityPopupProps): React.ReactElement {
  const { t } = useTranslation();
  const sendText = t("activityPopupEditor.buttons.labels.send", { ns: "activities" });
  const sendAndCloseText = t("activityPopupEditor.buttons.labels.sendAndClose", { ns: "activities" });
  const replyText = t("activityPopupEditor.buttons.labels.reply", { ns: "activities" });
  const replyAllText = t("activityPopupEditor.buttons.labels.replyAll", { ns: "activities" });
  const saveText = t("activityPopupEditor.buttons.labels.save", { ns: "activities" });
  const saveAndCloseText = t("activityPopupEditor.buttons.labels.saveAndClose", { ns: "activities" });
  const unsupportedFileToastMessage = t("toastMessages.dragAndDropAttachment.unsupportFileType", { ns: "activities" });
  const newActivityTypings = new Map();
  newActivityTypings.set("Reply", props?.replyOptions?.reply);
  newActivityTypings.set("Reply All", props?.replyOptions?.replyAll);
  const replyButtonStateMappings = new Map();
  replyButtonStateMappings.set("Reply", replyAllText);
  replyButtonStateMappings.set("Reply All", replyText);
  const [to, setTo] = React.useState<To[]>(props.defaultTo ?? []);
  const [cc, setCc] = React.useState<To[]>(props.defaultCc ?? []);
  const [bcc, setBcc] = React.useState<To[]>(props.defaultBcc ?? []);
  //just not need to populate subject for newEmail
  const isNotNewEmail = props.forwarding || Boolean(props?.replyOptions?.start);
  const isPhoneCallOrLogOrNote = ["phone_call", "call_log", "note"].includes(props.activityType ?? "");
  const [title, setTitle] = React.useState<string>(() =>
    isPhoneCallOrLogOrNote || isNotNewEmail || props?.isCopilot ? props.defaultTitle ?? "" : ""
  );
  const [editorState, setEditorState] = React.useState<string>(props?.defaultBody || props.editorState || "");
  const [ccVisible, setCcVisible] = useState<boolean>(props.defaultCc && props.defaultCc.length ? true : false);
  const [bccVisible, setBccVisible] = useState<boolean>(props.defaultBcc && props.defaultBcc.length ? true : false);
  const [defaultPrimaryActionLabel, setDefaultPrimaryActionLabel] = React.useState<string>(sendText);
  const [defaultSecondaryActionLabel, setDefaultSecondaryActionLabel] = React.useState<string>(sendAndCloseText);
  const [dropdownMenuAnchorEl, setDropdownMenuAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const dropdownMenuOpen = Boolean(dropdownMenuAnchorEl);
  const [selectedTemplateID, setSelectedTemplateID] = useState<string>("");
  const [toOption, setToOption] = useState<string>(replyButtonStateMappings.get(props?.replyOptions?.start) ?? "");
  const [subjectInputValue, setSubjectInputValue] = useState<string>("");
  const [contentHasMagicLink, setContentHasMagicLink] = React.useState<boolean>(false);
  const [mentionMembers, setMentionMembers] = React.useState<MentionMembersType>({ loading: false, members: [] });
  const mentionsAvailableFor = Object.freeze(["note", "phone_call", "call_log"]);
  const { workspaceMembers, fetchingWorkspaceMembers } = useWorkspaceConfigurations();
  const { signature } = React.useContext(CustomerContext) as CustomerType;
  const { isEnabled } = React.useContext(FeatureFlagContext) as FeatureFlagProviderType;
  const { defaultSelectedLangaugeForTemplateTabs, availableLanguagesForTemplates } = useLocale();
  const [currentSelectedTabLang, setCurrentSelectedTabLang] = React.useState<string>(defaultSelectedLangaugeForTemplateTabs);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [buttonGroupDropdownAnchorEl, setButtonGroupDropdownAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const buttonGroupDropdownOpen = Boolean(buttonGroupDropdownAnchorEl);
  const [openPreview, setOpenPreview] = React.useState<boolean>(false);
  const [activeAttachmentID, setActiveAttachmentID] = React.useState<string | number>("");
  const [isEditorEmpty, setIsEditorEmpty] = useState<boolean>(false);
  const [showSubjectLineValidation, setShowSubjectLineValidation] = useState<boolean>(false);
  const [showToValidation, setShowToValidation] = useState<boolean>(false);
  const [isSubjectLineRequired, setIsSubjectLineRequired] = useState<boolean>(
    () => !isPhoneCallOrLogOrNote && ((props.isTemplateSupport && !subjectInputValue) || !title)
  );
  const [dirtyText, setDirtyText] = React.useState<boolean>(false);
  const [showDiscardDraftModal, setShowDiscardDraftModal] = React.useState<boolean>(false);
  const [isDraftDirty, setIsDraftDirty] = React.useState<boolean>(false);
  const location = useLocation();
  const acceptedFileTypes: string[] = [".jpg", ".xls", ".pdf", ".docx", ".xlsx", ".csv", ".txt", ".jfif"];
  const { droppedFiles, handleFilesDragOver, handleFilesDrop } = useDragAndDropFiles(acceptedFileTypes, unsupportedFileToastMessage);
  const [selectedLangCode, setSelectedLangCode] = React.useState<string>("");
  const [isFetchingERPAttachments, setIsFetchingERPAttachments] = React.useState<boolean>(false);
  const { suggestedEmailTemplateList, templateTabList, allEmailTemplateList, tempalteLanguageDropdownList, getSelectedTemplateSubject } =
    useProcessTemplateData(
      [
        "AP_APPROVAL_REQUEST",
        "AR_APPROVAL_REQUEST",
        "AP_VENDOR_PROFILE_REQUEST",
        "AP_SHARE_PROFILE",
        "AR_SHARE_PROFILE",
        "AR_CUSTOMER_PROFILE_REQUEST",
      ],
      props.suggestedTemplatesCode,
      selectedLangCode,
      props.tempSubjectSnippetValues
    );
  const [templateList, setTemplateList] = React.useState<TemplateListItemType[]>([]);
  const [selectedTabKey, setSelectedTabKey] = React.useState<string>("");

  const onClickPreviewOpen = (activeAttachmentID: string | number) => {
    setActiveAttachmentID(activeAttachmentID);
    setOpenPreview(!openPreview);
  };

  /**
   * Flag for Keyboard Shortcut
   */

  const isKeyboardShortcutFlagEnabled = isEnabled("KEYBOARD_SHORTCUTS");
  const isTemplateWorkflowV2Enabled = isEnabled("TEMPLATE_WORKKFLOW_V2");

  /**
   * HOC Hook for Keyboard ShortCut UI
   */

  const { actionToPerform, setActionDone } = useKeyboardShortcut(
    ActivityEmailBodyActionShortcuts,
    !props.enableKeyboardShortcuts && !isKeyboardShortcutFlagEnabled,
    !props.open
  );

  const { setDisableKeyboardShortcut } = React.useContext(DisableFeatureContext) as DisableFeatureProviderType;
  const {
    add,
    remove,
    disableValidationBanner,
    attachmentConfigs: attachmentsList,
    visibility,
    inboxAttachmentPayload,
    allAttachmentIds,
    removeAllAttachedFiles,
    hasTemplateAttachments,
    setHasTemplateAttachments,
  } = useWindowAttachmentManager(props?.defaultAttachmentsList ?? []);
  const [attachmentConfigs, setAttachmentConfigs] = React.useState<WindowAttachment[]>(attachmentsList);

  /**
   * @function checkAndAddFiles
   * @param attachments : TemplateAttachDetail[]
   * @param unsupportedAttachments list of unsupported template attachments
   * This function is responsible for attaching invoices/payments/documents into the editor.
   * Which will be for viewing of file in the editor and setting up corresponding Id's
   * Note: not responsible to prepare payload for API
   *
   * if haveInvalidAttachments = true > triggers attachment-validation with inline textbox
   */
  const checkAndAddFiles = (attachments: ERPAttachment[]) => {
    if (attachments.length) {
      setHasTemplateAttachments(true);
    }
    add(attachments, AttachmentTypes.TemplateProp, true);
  };

  /**
   * This function takes in array of items selected from the new
   * attachment modal and attaches them to the activity pop-up
   *
   * @param selection files selected from the new attachment modal
   */
  const addCompanyAttachments = (selection: InboxAttachment[]) => {
    // invoices and payments are attached as inbox attachments
    add(
      selection
        .filter((item) => item.transaction_type !== VIEW.DOCUMENTS)
        .map((item) => ({
          transactionId: item.transaction_id,
          transactionType: item.transaction_type.toLocaleLowerCase(),
          transactionTypeCode: item.transaction_type_code,
          attachmentTitle: item.attachment_title,
        })),
      AttachmentTypes.InboxDrive
    );
    // documents are stored as normal attachments as they have
    add(
      selection
        .filter((item) => item.transaction_type === VIEW.DOCUMENTS)
        .map((item) => ({
          name: item.attachment_title,
          id: item.transaction_id,
        })),
      AttachmentTypes.PreviousUpload
    );
  };

  const setActivityTitle = (value: string) => {
    setIsSubjectLineRequired(!value || _.isEmpty(value));
    setTitle(DOMPurify.sanitize(value));
  };

  /**
   * This function toggles cc, bcc based on the action
   * selected by the user. It requires replyOptions
   * props to be able to understand what action is performed.
   *
   * Applicable actions: reply, reply-all and forward
   */
  const toggleCC = () => {
    if (props?.replyOptions) {
      if (toOption === replyText) {
        // set reply all and cc
        setCc(props?.replyOptions?.replyAllCC ?? []);
        setTo(props?.replyOptions?.replyAll ?? []);
        setCcVisible(true);
      } else {
        // reset reply and cc
        setCc([]);
        setBcc([]);
        setTo(props?.replyOptions?.reply ?? []);
        setCcVisible(false);
        setBccVisible(false);
      }
    }
  };

  const handleReplyButtonClick = () => {
    setTo(newActivityTypings.get(toOption) ?? []);
    setToOption(replyButtonStateMappings.get(toOption) ?? "");
    if (toOption === replyAllText) {
      setCcVisible(true);
      setCc(props?.replyOptions?.replyAllCC ?? []);
      setTo(props?.replyOptions?.replyAll ?? []);
    } else {
      setCc([]);
      setBcc([]);
      setCcVisible(false);
      setBccVisible(false);
    }
  };

  const handleSetTo = (e: React.SyntheticEvent, value: To[]) => {
    value.forEach((val: string | To, index, value) => {
      if (typeof val === "string") {
        value[index] = { id: "", label: val.replace(/\s+/g, "") };
      }
    });
    setTo(value);

    // trigger component to rerender the template
    if (props.refs?.toRef) {
      props.refs?.toRef?.current?.onSelectTo(value);
    }
  };

  const handleSetCc = (e: React.SyntheticEvent, value: To[]) => {
    value.forEach((val: string | To, index, value) => {
      if (typeof val === "string") {
        value[index] = { id: "", label: val.replace(/\s+/g, "") };
      }
    });
    setCc(value);
  };

  const handleSetBcc = (e: React.SyntheticEvent, value: To[]) => {
    value.forEach((val: string | To, index, value) => {
      if (typeof val === "string") {
        value[index] = { id: "", label: val.replace(/\s+/g, "") };
      }
    });
    setBcc(value);
  };

  /**
   * function uploads single/multiple files to the server and updates the attachmentIds
   *
   * @param newFiles list of files to upload and attach with activity stream
   */
  const handleFileSelect = async (newFiles: FileList) => {
    await add(newFiles, AttachmentTypes.Upload);
  };

  const handleFileRemove = (index: number) => {
    remove(index);
  };

  const handleCcVisibility = () => {
    setCcVisible(true);
  };

  const handleBccVisibility = () => {
    setBccVisible(true);
  };

  const typeAheadActionHandler = (val: string) => {
    if (props.typeAheadAction) {
      props.typeAheadAction(val);
    }
  };

  /**
   * @ mention tag replacer
   * @returns editorState with body removing move markers and replaces all tags with backend friendly format [@John K](mention://user/11/John%20K)
   */
  const replaceTagsWithMentions = () => {
    if (isPhoneCallOrLogOrNote) {
      let newState = editorState;
      let allMentions: Array<MentionsDataItemType> = [];
      const listOfMentions = newState.match(MENTION_REGEX_CONSTANTS.REGEX_REPLACE_MENTION);
      if (listOfMentions) {
        const listOfAllMentions = listOfMentions.map((item) =>
          item.replace(`<span><span style="color:#2D9DE7;text-indent:2rem;">@`, "").replace(`</span>&nbsp;</span>`, "")
        );
        allMentions = mentionMembers.members.filter((item) => listOfAllMentions?.indexOf(item.name ?? "") !== DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE);
      }
      allMentions.forEach((item) => {
        newState = newState.replaceAll(
          `<span><span style="color:#2D9DE7;text-indent:2rem;">@${item.name}</span>&nbsp;</span>`,
          `[@${item.name}](mention://user/${item.id}/${encodeURIComponent(item.name ?? "")}) `
        );
      });
      return newState.replaceAll(`<span id="move-marker-here"></span>`, "");
    }
    return editorState.replaceAll(`<span id="move-marker-here"></span>`, "");
  };

  const isCcApplicable = props.activityType !== "phone_call" && props.activityType !== "approval_request" && props.activityType !== "note";
  const isBccApplicable = props.activityType !== "phone_call" && props.activityType !== "approval_request" && props.activityType !== "note";

  /**
   * @function isValidActivityForm
   * A helper function to validate activity create form
   * @returns boolean
   */
  const isValidActivityForm = () => {
    if (isSubjectLineRequired || to?.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      if (isSubjectLineRequired) {
        setShowSubjectLineValidation(true);
      }
      if (to?.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
        setShowToValidation(true);
      }
      return false;
    }
    return true;
  };

  const closePopUp = () => {
    setCcVisible(false);
    setBccVisible(false);
    props?.setToTime?.(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    if (props?.onClose) {
      props.onClose();
    }
    setSelectedTemplateID("");
  };
  /*
   * Actions for Keyboard Shortcuts
   */
  const keyboardActions: { [key: string]: any } = useMemo(
    () => ({
      [KeyboardActionsConstants.cancel_draft]: () => {
        if (isDraftDirty) {
          setShowDiscardDraftModal(true);
        } else {
          closePopUp();
        }
      },
      [KeyboardActionsConstants.send_action]: () => {
        if (props?.handleSend && isValidActivityForm()) {
          props.handleSend(to, cc, bcc, title, replaceTagsWithMentions(), props.activityId ?? "", allAttachmentIds, inboxAttachmentPayload);
        }
      },
      [KeyboardActionsConstants.send_close_action]: () => {
        if (props?.handleSendMarkClosed && isValidActivityForm()) {
          props.handleSendMarkClosed(to, cc, bcc, title, replaceTagsWithMentions(), props.activityId ?? "", allAttachmentIds, inboxAttachmentPayload);
        }
      },
    }),
    [
      props.handleSend,
      props.handleSendMarkClosed,
      to,
      cc,
      bcc,
      title,
      replaceTagsWithMentions,
      allAttachmentIds,
      inboxAttachmentPayload,
      isSubjectLineRequired,
    ]
  );

  const shortcutDisplayMapper: { [key: string]: string } = useMemo(() => {
    if (isKeyboardShortcutFlagEnabled && props.enableKeyboardShortcuts) {
      const mapper: { [key: string]: string } = {};
      ActivityEmailBodyActionShortcuts.forEach((item) => {
        if (item.identifier) mapper[item.identifier] = item.displayText;
      });
      return mapper;
    }
    return {};
  }, [props.enableKeyboardShortcuts, isKeyboardShortcutFlagEnabled]);

  /**
   * @function isMagicLinkSupported
   * A helper function to check if the current popup origin supports magic link insertion.
   * @returns boolean
   */
  const isMagicLinkSupported = () => {
    if (!isEnabled("PORTAL_LINK")) return false;
    const validRoutes = ["invoices", "bills", "payments", "vendors", "customers"];
    return validRoutes.some((route) => location.pathname.includes(route));
  };

  const sanitizeTitle = () => {
    const selectedActivity = props?.addActivityDropdown?.find((item) => item.activityType.toLowerCase().split(" ").join("_") === props.activityType);

    if (props.activityType === ActivityOptions.REQUEST_CUSTOMER_PROFILE) {
      const requestCustomerProfile = t("addNewActivity.dropdownList.requestCustomerProfile", { ns: "activities" });
      return requestCustomerProfile?.replace("Customer ", "");
    } else {
      return selectedActivity?.displayName;
    }
  };

  const getDisplayName = (val: string) => {
    if (val === APARStances.REQUEST_CUSTOMER_PROFILE) return val.replace("Customer ", "");
    return val;
  };

  //Event handlers
  const onChangeTabs = (event: React.ChangeEvent<object>, newValue: string) => {
    setCurrentSelectedTabLang(newValue);
    setSelectedTemplateID("");
  };

  const onChangeTemplateListItem = (event: SelectChangeEvent) => {
    //on change of template previous attached files should get removed
    removeAllAttachedFiles();
    setSelectedTemplateID?.(event?.target?.value);

    const selectedItem = props.supportedTemplateList.get(currentSelectedTabLang)?.filter((item: TemplateType) => event?.target?.value === item?.id);

    setActivityTitle(selectedItem?.length > NUMERIC_VALUES.CONSTANT_ZERO ? selectedItem[NUMERIC_VALUES.CONSTANT_ZERO]?.displayText : "");

    setSubjectInputValue(selectedItem?.length > NUMERIC_VALUES.CONSTANT_ZERO ? selectedItem[NUMERIC_VALUES.CONSTANT_ZERO]?.displayText : "");
  };

  const onChangeSubjectInputText = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setIsSubjectLineRequired(!event?.target?.value || _.isEmpty(event.target.value));
    setSubjectInputValue(event?.target?.value);
    setActivityTitle(event?.target?.value);
  };

  React.useEffect(() => {
    if (["phone_call", "note"].includes(props.activityType ?? "")) {
      setDefaultPrimaryActionLabel(saveText);
      setDefaultSecondaryActionLabel(saveAndCloseText);
      props.setToTime?.(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    } else {
      setDefaultPrimaryActionLabel(sendText);
      setDefaultSecondaryActionLabel(sendAndCloseText);
    }
  }, [props.activityType]);

  useEffect(() => {
    // hide activity validation text box if template is changed
    disableValidationBanner();
    // Update the email body when user has selected template from subject dropdown.
    if (props.prepareActivityBodyByTemplateID) {
      props.prepareActivityBodyByTemplateID(
        selectedTemplateID,
        setEditorState,
        currentSelectedTabLang || defaultSelectedLangaugeForTemplateTabs,
        checkAndAddFiles
      );
    }
  }, [selectedTemplateID]);

  /**
   * attach event hooks for activity pop up ref
   */
  React.useImperativeHandle(props.refs?.popupRef, () => ({
    refreshPopupContent() {
      props.prepareActivityBodyByTemplateID?.(
        props.defaultTemplateId ?? "",
        setEditorState,
        currentSelectedTabLang || defaultSelectedLangaugeForTemplateTabs,
        checkAndAddFiles
      );
    },
  }));

  /**
   * checks the editor state on every update for magic link snippet
   * if snippet is not matched, editor enables the 'Insert Magic Link'
   * button on the activity popup actions
   */
  useEffect(() => {
    setContentHasMagicLink(editorState?.includes(constants.MAGIC_LINK_SNIPPET_BUTTON));
  }, [editorState]);

  /**
   * Use Effect Calls
   */

  useEffect(() => {
    if (
      isKeyboardShortcutFlagEnabled &&
      props.enableKeyboardShortcuts &&
      actionToPerform &&
      keyboardActions[actionToPerform] &&
      typeof keyboardActions[actionToPerform] === "function"
    ) {
      keyboardActions[actionToPerform]();
      setActionDone();
    }
  }, [actionToPerform, props.enableKeyboardShortcuts, isKeyboardShortcutFlagEnabled]);

  useEffect(() => {
    if (props.open) {
      setDisableKeyboardShortcut(true);
    } else {
      setDisableKeyboardShortcut(false);
    }
    return () => setDisableKeyboardShortcut(false);
  }, [props.open, props.editorState]);

  /* This gets invoked whenever there is any changes in defaultTemplateId and template list
   * If the defaultTemplateId is empty then set the editor state to default else as per
   * the default templateId
   */
  useEffect(() => {
    if (!_.isEmpty(props.defaultTemplateId) && props.supportedTemplateList?.size) {
      const selectedItem = props.supportedTemplateList
        .get(defaultSelectedLangaugeForTemplateTabs)
        ?.find((item: any) => item.id === props.defaultTemplateId);
      setActivityTitle(selectedItem?.displayText ?? "");
      setSubjectInputValue(selectedItem?.displayText ?? "");
      setSelectedTemplateID(props.defaultTemplateId ?? "");
    } else {
      setEditorState(props.editorState);
      setSubjectInputValue("");
    }
  }, [props.defaultTemplateId, props.supportedTemplateList?.size, defaultSelectedLangaugeForTemplateTabs]);

  useEffect(() => {
    toggleCC();
  }, [toOption]);

  useEffect(() => {
    if (fetchingWorkspaceMembers) {
      setMentionMembers({ loading: true, members: [] });
    } else if (!fetchingWorkspaceMembers && !_.isEmpty(workspaceMembers) && !_.isUndefined(workspaceMembers)) {
      setMentionMembers({ loading: false, members: workspaceMembers as unknown as MentionsDataItemType[] });
    } else {
      setMentionMembers({ loading: false, members: [] });
    }
  }, [workspaceMembers]);

  /**
   * Setup user's signature into activity popup body
   * If activity type is email or approval request and has no template selected
   * then simply append the signature from user context at once.
   */
  useEffect(() => {
    const emailSignature = signature?.email_signature ?? "";

    if (!_.isEmpty(signature) && props?.forwarding) {
      setEditorState(`${editorState}<div></div>${emailSignature}`);
    } else if (
      !_.isEmpty(signature) &&
      editorState.length == DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO &&
      (props.activityType === "email" || props.activityType === "approval_request")
    ) {
      setEditorState(`${editorState}<div></div>${emailSignature}`);
    }
  }, []);

  useEffect(() => {
    setCurrentSelectedTabLang(defaultSelectedLangaugeForTemplateTabs);
  }, [defaultSelectedLangaugeForTemplateTabs]);

  //filter tab list of enable languages for which templates are available
  const tabs = availableLanguagesForTemplates.filter(
    (item) => props.supportedTemplateList?.size >= NUMERIC_VALUES.CONSTANT_ZERO && props.supportedTemplateList?.has(item.id) && item.isChecked
  );

  const showActivityDropdown = !(props?.replyOptions?.reply || props?.replyOptions?.replyAll || props?.forwarding);

  /**
   * @function parseFiles
   * A helper function to parse attachments array and enrich the data if necessary
   * @param files
   * @returns
   */
  const parseFiles = (files: WindowAttachment[]) => {
    return files.map((file) => {
      return {
        ...(file.data as AttachmentItem),
        extension:
          (file as any)?.extension?.split(".")[NUMERIC_VALUES.CONSTANT_ONE] ||
          ((file.data as AttachmentItem)?.extension?.split(".")[NUMERIC_VALUES.CONSTANT_ONE] ?? (file.data as AttachmentItem)?.extension),
        file_name: (file.data as AttachmentItem)?.file_name || (file as any)?.file_name || file?.name,
        file_type: (file.data as AttachmentItem)?.file_type,
        file_url: (file.data as AttachmentItem)?.file_url || (file as any)?.file_url,
        id: (file.data as AttachmentItem)?.id || (file as any)?.id,
      };
    });
  };

  /**
   * @function isEditorTextEmpty
   * A helper funtion to check if the current editor state has empty html when rendered.
   * This includes Zero width no-break space chars, so they need to be selectively removed.
   * @see {@link https://stackoverflow.com/questions/11392312/zero-width-non-breaking-space | Zero width no-break space }
   */
  const isEditorTextEmpty = (string: string) => {
    const stringArray = Array.from(string);
    const isEmpty = (stringArray[0] === "\n" && stringArray.length === NUMERIC_VALUES.CONSTANT_ONE) || string === "";
    setIsEditorEmpty(isEmpty);
  };

  useEffect(() => {
    if (subjectInputValue?.length || title?.length) {
      setShowSubjectLineValidation(false);
    }
  }, [subjectInputValue, title]);

  useEffect(() => {
    if (to?.length) {
      setShowToValidation(false);
    }
  }, [to]);

  /**
   * @function getValidationMessages
   * A helper function to create and render the validation messages
   * @returns
   */
  const getValidationMessages = () => {
    const isToLineRequired = showToValidation;
    const isSubejectValidationRequired = isSubjectLineRequired && showSubjectLineValidation;
    const errorMsgArray = [] as string[];

    if (isToLineRequired) {
      errorMsgArray.push(t("activityPopupEditor.inputField.validationMessages.recipientRequired", { ns: "activities" }));
    }
    if (isSubejectValidationRequired) {
      errorMsgArray.push(t("activityPopupEditor.inputField.validationMessages.subjectRequired", { ns: "activities" }));
    }
    if (errorMsgArray?.length) {
      return (
        <div className="field-validation-message-container">
          <AlertInfo /> {errorMsgArray[0]} {errorMsgArray?.length > NUMERIC_VALUES.CONSTANT_ONE ? `| ${errorMsgArray?.length}` : ""}
        </div>
      );
    }
    return null;
  };

  const ValidationAlert = ({ classNames }: { classNames?: string }) => {
    return (
      <div className={`field-validation-container ${classNames}`}>
        <AlertDanger />
      </div>
    );
  };

  const fromLabel = t("activityPopupEditor.inputField.labels.from", { ns: "activities" });
  const toLabel = t("activityPopupEditor.inputField.labels.to", { ns: "activities" });
  const ccLabel = t("activityPopupEditor.inputField.labels.cc", { ns: "activities" });
  const bccLabel = t("activityPopupEditor.inputField.labels.bcc", { ns: "activities" });
  const subjectLabel = t("activityPopupEditor.inputField.labels.subject", { ns: "activities" });
  const subjectPlaceholder = t("activityPopupEditor.inputField.placeholderText.addSubject", { ns: "activities" });
  const toPlaceholder = t("activityPopupEditor.inputField.placeholderText.addRecipient", { ns: "activities" });

  /**
   * @function validateIfDraftDirty
   * A helper function check if activity draft has any change made to it
   */
  const validateIfDraftDirty = (stateString?: string) => {
    if (
      to?.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO &&
      cc?.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO &&
      bcc?.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO &&
      subjectInputValue === "" &&
      (!stateString || stateString === "<div><br></div>" || stateString === "" || stateString === undefined) &&
      attachmentConfigs?.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO &&
      isEditorEmpty
    ) {
      setIsDraftDirty(false);
    } else {
      setIsDraftDirty(true);
    }
  };

  //prepare templateList on tab change
  const onChangeTemplateTabs = (tabsId: number) => {
    setSelectedTabKey(templateTabList?.[tabsId]?.tabKey);
    setTemplateList(templateTabList?.[tabsId]?.tabKey === "suggested" ? suggestedEmailTemplateList : allEmailTemplateList);
  };

  //prepare templateList on tab change
  const onChangeTemplateLanguage = (langCode: string) => {
    setSelectedLangCode(langCode);
  };

  //apply the template to the editior [subject and body]
  const onApplySelectedTemplate = (templateID: string) => {
    removeAllAttachedFiles();
    setAttachmentConfigs([]);
    if (props.prepareActivityBodyByTemplateID) {
      setActivityTitle(getSelectedTemplateSubject(templateID));
      props.prepareActivityBodyByTemplateID(
        templateID,
        setEditorState,
        !_.isEmpty(selectedLangCode) ? selectedLangCode : (tempalteLanguageDropdownList[0]?.id as string),
        checkAndAddFiles
      );
    }
  };

  //all the required templateworkflow props
  const getTemplateWorkflowProps = () => {
    return {
      // to enable and disable email template option in editor toolbar
      isTemplateFlowRequired:
        isTemplateWorkflowV2Enabled &&
        props.isTemplateSupport &&
        [
          ActivityOptions.EMAIL,
          ActivityOptions.APPROVAL_REQUEST,
          ActivityOptions.ONBOARD_VENDOR,
          ActivityOptions.SHARE_AP_PROFILE,
          ActivityOptions.SHARE_AR_PROFILE,
          ActivityOptions.REQUEST_CUSTOMER_PROFILE,
        ].includes(props.activityType as ActivityOptions),

      //list of enabled langauge for template
      languageDropdownList: tempalteLanguageDropdownList,
      //to display count along with tab name
      isCountRequiredWithTabs: true,
      /**
       * For email both listview and preview card required , but for other activity
       * like approval,share profile etc activity  only preview card required
       */
      isTemplateListViewRequired: props.activityType === ActivityOptions.EMAIL,
      isTemplatePreviewRequired: true,
      // to display api failed or data not found message
      hasTemplateApiFailedOrNoDataFound:
        props.activityType !== ActivityOptions.EMAIL
          ? suggestedEmailTemplateList?.length <= NUMERIC_VALUES.CONSTANT_ZERO
          : templateList?.length <= NUMERIC_VALUES.CONSTANT_ZERO,
      // to display search component
      isTemplateSearchRequired: true,
      // to display tab component
      isTemplateTabRequired: true,
      // to display langauage dropdown option on listview card header
      isTempListCardLangDrpdwnRequired: true,
      // to display langauage dropdown option on preview card header
      isTempPreviewLangDrpdwnRequired: props.activityType !== ActivityOptions.EMAIL,
      //All template list based on selected tabs[Suggested and All]
      templateList,
      // all the intial tabs
      templateTabList,
      // to handle logic on tab change,template language change,and on template apply
      onChangeTemplateTabs,
      onChangeTemplateLanguage,
      onApplySelectedTemplate,
    };
  };

  //set the templatelist based on selected tab
  useEffect(() => {
    setTemplateList(
      _.isEmpty(suggestedEmailTemplateList) || (selectedTabKey !== "suggested" && !_.isEmpty(selectedTabKey))
        ? allEmailTemplateList
        : suggestedEmailTemplateList
    );
  }, [suggestedEmailTemplateList, allEmailTemplateList]);

  /**
   * Side-effect to check and set activity draft as dirty if user has made changes
   */
  useEffect(() => {
    validateIfDraftDirty();
  }, [to, cc, bcc, subjectInputValue, title, attachmentConfigs, isEditorEmpty]);

  React.useEffect(() => {
    if (droppedFiles && droppedFiles.length > NUMERIC_VALUES.CONSTANT_ZERO) {
      handleFileSelect(droppedFiles);
    }
  }, [droppedFiles]);

  /**
   * @function getFileUrls
   * A helper function to fetch url for a file object from platform using file id.
   * @param file
   * @returns
   */
  const getFileUrls = async (file: WindowAttachment) => {
    const asyncFileURLs = await Promise.resolve(documentsClient.downloadUrl(file?.id as string));
    return asyncFileURLs?.downloadLink;
  };

  /**
   * Side-effect to validate an attachments array, to see if its added
   * from templates like W-9 and enrich the data with file urls to
   * enable attachments preview
   */
  useEffect(() => {
    /**
     * If attachments from templates are available, then map the attachments array
     * and generate a AJAX Promise Object for each attachment file url fetch
     * operation and return it to the Promise Array. The resolve the entire promise
     * array at once, and set to state to allow the app to re-render.
     */
    const validateAttachments = async (files: WindowAttachment[]) => {
      const results = await Promise.all(
        files.map(async (file: any) => {
          const fileUrl = await getFileUrls(file);
          const data = {
            ...file,
            file_url: file?.file_url || (fileUrl as unknown as string),
            extension: (file.data as any)?.attachmentTitle?.split(".")[NUMERIC_VALUES.CONSTANT_ONE] ?? (file.data as any)?.extension,
            file_name: (file.data as any)?.attachmentTitle + (file.data as any)?.extension || file?.name,
            id: Math.random(),
          };
          return await Promise.resolve(data);
        })
      );
      return results;
    };

    if (attachmentsList?.length && hasTemplateAttachments) {
      setIsFetchingERPAttachments(true);
      validateAttachments(attachmentsList)
        .then((results: any) => {
          setAttachmentConfigs(results as WindowAttachment[]);
        })
        .catch(() => {
          setAttachmentConfigs([] as WindowAttachment[]);
          const toastOptions: ToastOptions = {
            open: true,
            severity: "error",
            message: t("toastMessages.downloadAttachment.error", { ns: "activities" }),
          };
          props?.setToastOptions?.(toastOptions);
        })
        .finally(() => {
          setIsFetchingERPAttachments(false);
          setHasTemplateAttachments(false);
        });
    }
    // Regular attachments flow
    if (attachmentsList?.length && !hasTemplateAttachments) {
      const attachmentsWithURL = attachmentsList?.filter((attachment: any) => attachment?.data?.file_url?.length > NUMERIC_VALUES.CONSTANT_ZERO);
      const attachmentsWithOutURL = attachmentsList?.filter((attachment: any) => !attachment?.data?.file_url);
      /**
       * Non optimised solution to refetch and set the urls if not found
       */
      validateAttachments(attachmentsWithOutURL)
        .then((results: any) => {
          setAttachmentConfigs([...(results as WindowAttachment[]), ...attachmentsWithURL]);
        })
        .catch(() => {
          setAttachmentConfigs([] as WindowAttachment[]);
          const toastOptions: ToastOptions = {
            open: true,
            severity: "error",
            message: t("toastMessages.downloadAttachment.error", { ns: "activities" }),
          };
          props?.setToastOptions?.(toastOptions);
        })
        .finally(() => {
          setIsFetchingERPAttachments(false);
          setHasTemplateAttachments(false);
        });
    }
  }, [attachmentsList]);

  return (
    <div className={`na-wrapper-card${props.open ? " open" : "-closed"} activity-create-card`}>
      {getValidationMessages()}
      {props?.isCopilot ? (
        <></>
      ) : (
        <div className={"na-header"}>
          <>
            {props?.addActivityDropdown?.length && showActivityDropdown ? (
              <div className={"new-activity-type-selector-container"}>
                <div className={`activity-type-selector ${props.clickDisabled ? "disabled" : ""}`}>
                  <DropMenu
                    key={`activity-type-dropdown-${props?.addActivityDropdown?.length}`}
                    className={`activity-type-dropdown-${dropdownMenuOpen ? "open" : "closed"} ${props.activityType}`}
                    title={sanitizeTitle() ?? ""}
                    isActive={true}
                    onClick={(e: MouseEvent<HTMLDivElement>) => (props.clickDisabled ? null : setDropdownMenuAnchorEl(e.currentTarget))}
                    iconAlign="right"
                    appendIcon={
                      props.addActivityDropdown && props.addActivityDropdown.length ? (
                        props.addActivityDropdown.filter(
                          (item: ActivityDropdownItem) => item.activityType.toLowerCase().split(" ").join("_") === props.activityType
                        )[0]?.icon
                      ) : (
                        <></>
                      )
                    }
                  />
                  <Menu
                    open={dropdownMenuOpen}
                    anchorEl={dropdownMenuAnchorEl}
                    onClose={() => setDropdownMenuAnchorEl(null)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    className="activity-types-list-menu"
                  >
                    {props?.addActivityDropdown?.length
                      ? props?.addActivityDropdown.map((tab: ActivityDropdownItem, index: number) => {
                          if (!tab.disabled) {
                            return (
                              <MenuItem
                                key={index}
                                onClick={() => {
                                  if (props?.setNewActivityType) {
                                    props?.setNewActivityType(tab.activityType.toLowerCase().split(" ").join("_"));
                                    setDropdownMenuAnchorEl(null);
                                  }
                                }}
                              >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                  <div className="icon">{tab.icon}</div>
                                  <div>{getDisplayName(tab.displayName)}</div>
                                </div>
                              </MenuItem>
                            );
                          }
                        })
                      : null}
                  </Menu>
                </div>
              </div>
            ) : props?.forwarding || props?.replyOptions?.start === "Reply" || props?.replyOptions?.start === "Reply All" ? (
              <div className="reply-forward-tag-header">
                <EmailIcon />
                <span>{t("addNewActivity.dropdownList.email", { ns: "activities" })}</span>
              </div>
            ) : null}
            <div
              className={"new-activity-header-close-wrapper"}
              onClick={() => {
                if (isDraftDirty) {
                  setShowDiscardDraftModal(true);
                } else {
                  closePopUp();
                }
              }}
            >
              <CloseIcon />
            </div>
          </>
        </div>
      )}
      {props.loading ? (
        <Loading />
      ) : (
        <>
          <div className={"na-body-card"}>
            <div className="email-head-wrapper">
              <table className="email-head-section">
                <tr>
                  <td width="90">
                    <div className={"na-body-to-To"}>
                      <p>{fromLabel}:</p>
                    </div>
                  </td>
                  <td>
                    <div className={"na-body-to na-body-child"}>
                      <div className={"na-body-toline disabled-from-line"}>{props?.workspaceData?.email_address ?? ""}</div>
                    </div>
                  </td>
                </tr>
                {props.activityType !== "note" ? (
                  <tr>
                    <td width="90">
                      <div className={"na-body-to-To"}>
                        <p>{toLabel}:</p>
                      </div>
                    </td>
                    <td>
                      <div className={"na-body-to na-body-child"}>
                        <div className={"na-body-toline to-address"}>
                          {showToValidation && <ValidationAlert classNames="to-line"></ValidationAlert>}
                          <ActivityTo
                            options={props?.contactOptions && props?.contactOptions.length ? props?.contactOptions : []}
                            to={to}
                            setTo={handleSetTo}
                            typeAheadAction={typeAheadActionHandler}
                            restrictOneContact={
                              props.activityType === ActivityOptions.APPROVAL_REQUEST && to.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
                            }
                            placeHolderText={toPlaceholder}
                          />
                        </div>
                        <div className={"na-body-to-extra-recipients"}>
                          {isCcApplicable && !ccVisible ? (
                            <div className={"na-body-to-extra-cc"} onClick={handleCcVisibility}>
                              <p>{ccLabel?.toUpperCase()}</p>
                            </div>
                          ) : null}
                          {isBccApplicable && !bccVisible ? (
                            <div className={"na-body-to-extra-bcc"} onClick={handleBccVisibility}>
                              <p>{bccLabel?.toUpperCase()}</p>
                            </div>
                          ) : null}
                        </div>
                        {props?.replyOptions?.replyButton && (
                          <div className={"na-body-toline-reply"}>
                            <Button icon={<Reply />} onClick={handleReplyButtonClick} alignIcon={"right"} variant={"secondary"}>
                              {toOption}
                            </Button>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ) : null}

                {ccVisible && (
                  <tr>
                    <td width="90">
                      <div className={"na-body-to-Cc"}>
                        <p>{ccLabel}:</p>
                      </div>
                    </td>
                    <td>
                      <div className={"na-body-to na-body-child"}>
                        <div className={"na-body-ccline"}>
                          <ActivityTo
                            options={props?.contactOptions && props?.contactOptions.length ? props?.contactOptions : []}
                            to={cc}
                            setTo={handleSetCc}
                            typeAheadAction={typeAheadActionHandler}
                            placeHolderText={toPlaceholder}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                {bccVisible && (
                  <tr>
                    <td width="90">
                      <div className={"na-body-to-Bcc"}>
                        <p>{bccLabel}:</p>
                      </div>
                    </td>
                    <td>
                      <div className={"na-body-to na-body-child"}>
                        <div className={"na-body-bccline"}>
                          <ActivityTo
                            options={props?.contactOptions && props?.contactOptions.length ? props?.contactOptions : []}
                            to={bcc}
                            setTo={handleSetBcc}
                            typeAheadAction={typeAheadActionHandler}
                            placeHolderText={toPlaceholder}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                )}

                <tr>
                  <td width="90">
                    <div className={"na-body-to-To"}>
                      <p>{subjectLabel}:</p>
                    </div>
                  </td>
                  <td>
                    {isSubjectLineRequired && showSubjectLineValidation && <ValidationAlert></ValidationAlert>}
                    {(props.activityType === ActivityOptions.EMAIL ||
                      props.activityType === ActivityOptions.APPROVAL_REQUEST ||
                      props.activityType === ActivityOptions.ONBOARD_VENDOR ||
                      props.activityType === ActivityOptions.SHARE_AP_PROFILE ||
                      props.activityType === ActivityOptions.SHARE_AR_PROFILE ||
                      props.activityType === ActivityOptions.REQUEST_CUSTOMER_PROFILE) &&
                    props.isTemplateSupport &&
                    props.supportedTemplateList?.size > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO &&
                    !isTemplateWorkflowV2Enabled ? (
                      <div className={"na-body-title na-body-child na-body-template-selector"}>
                        <TemplateSelectMenu
                          templateList={props.supportedTemplateList}
                          tabList={tabs}
                          isTabsRequired={tabs?.length > NUMERIC_VALUES.CONSTANT_ONE}
                          subjectInputValue={subjectInputValue}
                          currentSelectedTab={currentSelectedTabLang}
                          selectedTemplateItemId={selectedTemplateID}
                          onChangeTabs={onChangeTabs}
                          onChangeSubjectInputText={onChangeSubjectInputText}
                          onChangeTemplateListItem={onChangeTemplateListItem}
                          placeHolderText={subjectPlaceholder}
                        />
                      </div>
                    ) : (
                      <div className={"na-body-title na-body-child subject-line"}>
                        <ActivityTitle
                          title={title ?? ""}
                          handleOnChange={setActivityTitle}
                          isDisabled={props.disableTitle}
                          placeHolderText={subjectPlaceholder}
                        />
                      </div>
                    )}
                  </td>
                </tr>
              </table>
            </div>

            {isFetchingERPAttachments && (
              <div className="feed-item-attachments-container">
                <div className="feed-item-attachments-loading-container">
                  <Loading />
                </div>
              </div>
            )}
            {!visibility.uploading && attachmentConfigs && attachmentConfigs?.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && (
              <div className="feed-item-attachments-container">
                {parseFiles(attachmentConfigs)?.map((document: AttachmentItem, index: number) => {
                  return (
                    <AttachmentChip
                      key={`attachment-${index}-${document.id}-${document?.file_url}`}
                      name={document?.file_name ?? ""}
                      extension={document?.extension ?? ""}
                      createdDate={
                        document?.created_at
                          ? formatDate(new Date((document.created_at as any) * NUMERIC_VALUES.CONSTANT_THOUSAND).toISOString())
                          : ""
                      }
                      attachment={document}
                      onClickAttachmentChip={() => onClickPreviewOpen(document.id)}
                      onClickDownload={(event) => props.onClickDownload(event, [document])}
                      clossable={true}
                      onClose={() => {
                        handleFileRemove(index);
                      }}
                    />
                  );
                })}
                <div className="attachemnts-actions">
                  <Link to="#" component={RouterLink} onClick={(event) => props.onClickDownload(event, parseFiles(attachmentConfigs))}>
                    {t("activityPopupEditor.buttons.labels.downloadAll", { ns: "activities" })}
                  </Link>
                </div>
              </div>
            )}
            {openPreview && (
              <AttachmentPreview
                key={activeAttachmentID}
                openPreview={openPreview}
                activeAttachmentID={activeAttachmentID}
                handleClose={() => setOpenPreview(false)}
                attachments={parseFiles(attachmentConfigs) as any}
                onClickDownload={props.onClickDownload}
              />
            )}

            <div className={"hyperlink-modal-container"}>{/* Insert hyperlink edit modal here*/}</div>
            <div className={"hyperlink-edit-modal-container"}>{/* Insert hyperlink edit modal here*/}</div>
            {visibility.uploading ? (
              <Loading />
            ) : (
              <div className={"na-body-message-card na-body-child"} onDragOver={handleFilesDragOver} onDrop={handleFilesDrop}>
                <ActivityBody
                  key={`body-${attachmentConfigs?.length}`}
                  forwarding={props.forwarding}
                  editorState={editorState}
                  setEditorState={setEditorState}
                  handleFileSelect={handleFileSelect}
                  onClickAddAttachments={addCompanyAttachments}
                  handleFileRemove={handleFileRemove}
                  isCC={ccVisible}
                  isBCC={bccVisible}
                  to={to} // connection id of the first contact's in the to list
                  addMagicLinkSnippet={contentHasMagicLink}
                  enableMentions={mentionsAvailableFor.includes(props.activityType ?? "")}
                  mentionMembers={mentionMembers}
                  attachments={attachmentConfigs}
                  didRemoveUnsupported={visibility.didRemoveUnsupported}
                  getEditorText={isEditorTextEmpty}
                  dirtyText={dirtyText}
                  setDirtyText={setDirtyText}
                  copilotContent={props?.copilotContent ?? ""}
                  onChange={validateIfDraftDirty}
                  templateWorkflowData={getTemplateWorkflowProps()}
                />

                <div className={"na-body-message-send"}>
                  <div className={"na-body-message-send-button"}>
                    {!_.isUndefined(props.toTime) &&
                      props.toTime > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO &&
                      (props.activityType === ActivityOptions.EMAIL ||
                        props.activityType === ActivityOptions.APPROVAL_REQUEST ||
                        props.activityType === ActivityOptions.ONBOARD_VENDOR ||
                        props.activityType === ActivityOptions.SHARE_AP_PROFILE ||
                        props.activityType === ActivityOptions.SHARE_AR_PROFILE) && (
                        <TimeIcon index={0} timeFrom={props.fromTime} timeTo={props.toTime} />
                      )}
                    {isMagicLinkSupported() && props.activityType === "email" && (
                      <Button
                        disabled={contentHasMagicLink}
                        onClick={() => setContentHasMagicLink(true)}
                        // tooltip={!to?.[0]?.erpContact ? "The Self Service portal not supported for email generated connections" : ""}
                      >
                        {"Insert Portal Link"}
                      </Button>
                    )}

                    <ButtonGroup
                      disableElevation
                      disabled={isEditorEmpty || showSubjectLineValidation || showToValidation}
                      variant="contained"
                      className="send-and-close-group"
                      ref={anchorRef}
                      aria-label="split button"
                    >
                      {props.isCopilot && (
                        <ButtonV2
                          variant="tertiary"
                          onClick={() => {
                            props?.discardCopilot?.();
                          }}
                        >
                          {t("activityPopupEditor.buttons.labels.noThanks", { ns: "activities" })}
                        </ButtonV2>
                      )}
                      <CustomTooltip
                        type={isKeyboardShortcutFlagEnabled && props.enableKeyboardShortcuts ? TooltipTypes.SHORTCUT : TooltipTypes.PLAIN}
                        title={isKeyboardShortcutFlagEnabled && props.enableKeyboardShortcuts ? sendText : ""}
                        shortcutKeysText={
                          isKeyboardShortcutFlagEnabled && props.enableKeyboardShortcuts ? shortcutDisplayMapper?.[TooltipTitles.SEND] : ""
                        }
                        placement="top"
                      >
                        <span>
                          <ButtonV2
                            disabled={visibility.uploading || props.isSendButtonDisabled}
                            variant="primary"
                            onClick={() => {
                              if (props?.handleSend && isValidActivityForm()) {
                                props.handleSend(
                                  to,
                                  cc,
                                  bcc,
                                  title,
                                  replaceTagsWithMentions(),
                                  props.activityId ?? "",
                                  allAttachmentIds,
                                  inboxAttachmentPayload,
                                  true,
                                  false,
                                  props?.isCopilot ?? false,
                                  dirtyText
                                );
                              }
                            }}
                          >
                            {props?.buttonProps?.primaryActionLabel ?? defaultPrimaryActionLabel}
                          </ButtonV2>
                        </span>
                      </CustomTooltip>
                      {!props.disableSecondaryAction && (
                        <ButtonV2
                          disabled={visibility.uploading || props.isSendButtonDisabled}
                          variant="primary"
                          onClick={(e: MouseEvent<HTMLButtonElement>) => setButtonGroupDropdownAnchorEl(e.currentTarget)}
                        >
                          {buttonGroupDropdownOpen ? <ArrowUp /> : <ArrowDown />}
                        </ButtonV2>
                      )}
                      <ClickAwayListener onClickAway={() => setButtonGroupDropdownAnchorEl(null)}>
                        <Menu
                          open={buttonGroupDropdownOpen}
                          anchorEl={buttonGroupDropdownAnchorEl}
                          onClose={() => setButtonGroupDropdownAnchorEl(null)}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <CustomTooltip
                            type={isKeyboardShortcutFlagEnabled && props.enableKeyboardShortcuts ? TooltipTypes.SHORTCUT : TooltipTypes.PLAIN}
                            shortcutKeysText={
                              isKeyboardShortcutFlagEnabled && props.enableKeyboardShortcuts ? shortcutDisplayMapper?.[TooltipTitles.SEND_CLOSE] : ""
                            }
                            title={isKeyboardShortcutFlagEnabled && props.enableKeyboardShortcuts ? sendAndCloseText : ""}
                          >
                            <MenuItem
                              onClick={() => {
                                if (props?.handleSendMarkClosed && isValidActivityForm()) {
                                  props.handleSendMarkClosed(
                                    to,
                                    cc,
                                    bcc,
                                    title,
                                    replaceTagsWithMentions(),
                                    props.activityId ?? "",
                                    allAttachmentIds,
                                    inboxAttachmentPayload,
                                    props?.isCopilot ?? false,
                                    dirtyText
                                  );
                                }
                              }}
                            >
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <div>{props?.buttonProps?.secondaryActionLabel ?? defaultSecondaryActionLabel}</div>
                              </div>
                            </MenuItem>
                          </CustomTooltip>
                        </Menu>
                      </ClickAwayListener>
                    </ButtonGroup>
                  </div>
                </div>
              </div>
            )}
          </div>
          <DiscardDraftModal
            showModal={showDiscardDraftModal}
            closeModal={() => setShowDiscardDraftModal(!showDiscardDraftModal)}
            onDiscard={() => closePopUp()}
            className="discard-draft-variant-v2"
          />
        </>
      )}
    </div>
  );
}

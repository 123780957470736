import React, { Dispatch, SetStateAction } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import Button from "../../../../../library/Button/Button";
import { Input } from "../../../../../library/Input/Input";
import { makeStyles } from "@mui/styles";
import styles from "../../../../../../css/_export.module.scss";
import { Close } from "../../../../../library/Icons/Icons";
import { useTranslation } from "react-i18next";

type SetupAutomationEmailProps = {
  showModal: boolean;
  closeModal: VoidFunction;
  updateSettings: VoidFunction;
  setInputEmail: Dispatch<SetStateAction<string>>;
};

const SetupAutomationEmail: React.FC<SetupAutomationEmailProps> = ({ showModal, closeModal, updateSettings, setInputEmail }) => {
  /**
   * custom styles for ap automation settings from
   * activity feed header
   **/
  const useStyles = makeStyles({
    dialog: {
      "& .MuiPaper-root": {
        boxShadow: "none",
        padding: styles.large,
        margin: 0,
        width: "33rem",
        borderRadius: "0.5rem",
      },
      "& .MuiDialogTitle-root": {
        fontFamily: styles.primaryFontFamily,
        fontWeight: 600,
        fontSize: styles.fontSizeXL,
        lineHeight: styles.lineHeightXL,
        color: styles.primary,
        padding: 0,
        margin: 0,
      },
      "& .MuiDialogContent-root": {
        margin: "1rem 0 1rem 0",
      },
      "& .MuiDialogContentText-root": {
        margin: 0,
        fontFamily: styles.primaryFontFamily,
        fontWeight: 400,
        fontSize: styles.fontSizeXS,
        lineHeight: styles.lineHeightSM,
        color: styles.grey30,
        letterSpacing: 0,
      },
      "& .title": {
        fontFamily: styles.primaryFontFamily,
        fontWeight: 600,
        fontSize: styles.fontSizeXS,
        lineHeight: styles.lineHeightSM,
        letterSpacing: 0,
        color: styles.primary,
        margin: "1rem 0 0.25rem 0",
      },
      "& div": {
        padding: 0,
      },
    },
  })();

  const { t } = useTranslation();

  return (
    <Dialog className={useStyles.dialog} open={showModal} onClose={closeModal}>
      <DialogTitle>{t("modalAndFlyout.setupAutomation.dialogTitle", { ns: "activities" })}</DialogTitle>
      <Button className="library-modal-close-icon" type="button" variant="solo-icon" onClick={closeModal}>
        <Close />
      </Button>
      <DialogContent className="mt-ba mb-lg">
        <DialogContentText>{t("modalAndFlyout.setupAutomation.dialogMessage", { ns: "activities" })}</DialogContentText>
        <p className="title">{t("modalAndFlyout.setupAutomation.textField.label", { ns: "activities" })}</p>
        <Input
          className="input"
          placeholder={t("modalAndFlyout.setupAutomation.textField.placeholderText", { ns: "activities" }) as string}
          onChange={(e) => {
            setInputEmail(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button className="ml-auto" variant="secondary" onClick={closeModal}>
          {t("modalAndFlyout.buttons.cancel", { ns: "activities" })}
        </Button>
        <Button variant="primary" onClick={() => updateSettings()}>
          {t("modalAndFlyout.buttons.automate", { ns: "activities" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SetupAutomationEmail;

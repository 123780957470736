import React from "react";
import _ from "underscore";
import Button from "../../library/Button/Button";
import { Input } from "../../library/Input/Input";
import { TextArea } from "../../library/TextArea/TextArea";
import { RedirectRequestComponentProps } from "./RedirectRequestTypes";

const RedirectRequestComponent: React.FC<RedirectRequestComponentProps> = ({
  onChangeFormData,
  onSubmitForm,
  onBlurValidateData,
  formErrors,
  hasSubmitted,
}) => {
  return (
    <div className="redirect-form-container">
      <div className="form-header">
        <div className="header">Redirect Approval Request</div>
      </div>
      <form onSubmit={onSubmitForm} id="redirect-request" className="form-container" onBlur={onBlurValidateData}>
        <Input
          label={"Approver Email Address"}
          name="email_address"
          defaultValue={""}
          onChange={onChangeFormData}
          placeholder="Enter approver email address"
          required
          errorMessage={formErrors.email_address}
        />
        <Input
          label={"Approver Name"}
          name="name"
          defaultValue={""}
          onChange={onChangeFormData}
          placeholder="Enter approver name"
          required
          errorMessage={formErrors.name}
        />
        <TextArea
          label={"Note To The Approver"}
          name="note"
          defaultValue={""}
          onChange={onChangeFormData}
          placeholder="Add a note"
          required
          errorMessage={formErrors.note}
        />
        <Button type="submit" className="redirect-submit-btn" disabled={!_.isEmpty(formErrors)} loading={hasSubmitted}>
          Redirect Approval Request
        </Button>
      </form>
    </div>
  );
};

export default RedirectRequestComponent;

import React, { useEffect, useState, useCallback } from "react";
import { useParams, useLocation } from "react-router-dom";
import { activitiesClientV2 } from "../../../db/version2Accessor";
import Utils from "../../../utils/utils";
import ActivityFeedV2 from "../ActivityDetail/ActivityFeedV2/ActivityFeed";
import { Email, NoteFill, Pen, Phone, UserPic } from "../../library/Icons/Icons";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";
import { FallbackTypes } from "../../../types/enums";
import { ActivityContext } from "../../../contexts/ActivityContext";
import { FeatureFlagContext, FeatureFlagProviderType } from "../../../contexts/FeatureFlagContext";
import { useQuery } from "@tanstack/react-query";
import { RefetchIntervals } from "../../../constants/CacheConfig";
import { NUMERIC_VALUES } from "../../../constants/NumericConstants";
import RightPanelCollapsible from "./RightPanelCollapsible/RightPanelCollapsible";
import "./ActivityDetail.scss";
import { useTranslation } from "react-i18next";

interface ActivityDetailProps {
  userView?: string;
}

export default function ActivityDetail(props: ActivityDetailProps): React.ReactElement {
  const { activityId } = useParams<{ activityId: string }>();
  const [connectionData, setConnectionData] = useState<Partial<PrimaryConnection>>({
    started_vendor_onboarding: true,
  } as PrimaryConnection);
  const [hasOnboardingInitiated, setHasOnboardingInitiated] = useState<boolean>(false);
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { activityData, setActivityData, setShowAttachmentTab } = React.useContext(ActivityContext) as ActivityType;
  const { search } = useLocation();
  const queryFromParams = React.useMemo(() => new URLSearchParams(search), [search]);
  const { isEnabled } = React.useContext(FeatureFlagContext) as FeatureFlagProviderType;
  const { t } = useTranslation();

  /**
   * @function fetchActivity
   * Function mapped to cache: cachedActivityDetailKey for fetching activity detail from API
   * @returns APIResponse
   */
  const fetchActivity = async () => activitiesClientV2.get(selectedWorkspace?.id || FallbackTypes.Id, activityId);

  /**
   * @constant cachedActivityDetailKey
   * Activity Feed cache key setup
   * Cache key being scoped on workspace_type, id and activityId
   */
  const cachedActivityDetailKey = [`${selectedWorkspace?.workspace_type}-${selectedWorkspace?.id}`, activityId];
  const {
    isLoading,
    isRefetching,
    data: activityDetailData,
    refetch,
  } = useQuery({
    enabled: activityId.length > NUMERIC_VALUES.CONSTANT_ZERO,
    queryKey: [cachedActivityDetailKey],
    queryFn: fetchActivity,
    refetchInterval: RefetchIntervals.activityStreamDetail,
    refetchOnMount: true,
  });

  useEffect(() => {
    if (activityDetailData) {
      let response = {} as ActivityStreamItem;
      const data: ActivityStreamItem = activityDetailData.data;
      if (data.attachments) {
        data.attachments = data.attachments.map((item: AttachmentItem) => ({
          ...item,
          created_at: Utils.evaluateDate(item.created_at as number),
        }));
      }
      if (data) {
        setConnectionData(data.primary_connection ? data.primary_connection : {});
        setHasOnboardingInitiated(data.primary_connection?.started_vendor_onboarding ?? false);
        response = data;
      }
      setActivityData(response);
      setShowAttachmentTab(false);
      sessionStorage.setItem("activitySubject", response.subject ?? "");
    }
  }, [activityDetailData]);

  /**
   * @function escapeTextHighlight
   * A helper function to catch "escape key" press and disable highlighting of search results, and also delete the query param
   */
  const escapeTextHighlight = useCallback((event: KeyboardEvent) => {
    if (event.key === "Escape" || event.code === "Escape") {
      queryFromParams?.delete("query");
    }
  }, []);

  /**
   * A side-effect to catch escape key press to disable search result text highlighting
   */
  useEffect(() => {
    document.addEventListener("keydown", escapeTextHighlight);
    return () => {
      document.removeEventListener("keydown", escapeTextHighlight);
    };
  }, [escapeTextHighlight]);

  const isProfileManagementEnabled = isEnabled("PROFILE_MANAGEMENT");

  const dropdownOptions = React.useMemo(() => {
    const returnOpt: ActivityDropdownItem[] = [
      { activityType: "Email", displayName: t("addNewActivity.dropdownList.email", { ns: "activities" }), icon: <Email /> },
      { activityType: "Note", displayName: t("addNewActivity.dropdownList.note", { ns: "activities" }), icon: <NoteFill /> },
      { activityType: "Phone Call", displayName: t("addNewActivity.dropdownList.phoneCall", { ns: "activities" }), icon: <Phone /> },
      {
        activityType: "Approval Request",
        displayName: t("addNewActivity.dropdownList.approvalRequest", { ns: "activities" }),
        icon: <Pen />,
        disabled: activityData.has_active_approval,
        disabledToolip: t("addNewActivity.tooltipMessage.approvalDisabled", { ns: "activities" }) as string,
      },
    ];
    if (isProfileManagementEnabled) {
      if (connectionData.type === "Vendor") {
        returnOpt.push({
          activityType: "Share AP Profile",
          displayName: t("addNewActivity.dropdownList.shareApProfile", { ns: "activities" }),
          icon: <UserPic />,
        });
      } else if (connectionData.type === "Customer") {
        returnOpt.push(
          ...[
            {
              activityType: "Share AR Profile",
              displayName: t("addNewActivity.dropdownList.shareArProfile", { ns: "activities" }),
              icon: <UserPic />,
            },
            {
              activityType: "Request Customer Profile",
              displayName: t("addNewActivity.dropdownList.requestCustomerProfile", { ns: "activities" }),
              icon: <UserPic />,
            },
          ]
        );
      }
    }
    return returnOpt;
  }, [selectedWorkspace, isProfileManagementEnabled, activityData]);

  return (
    <div className="activity-detail-wrapper-grid">
      <div className="activity-detail-left">
        <div className="body">
          <ActivityFeedV2
            isRefetching={isRefetching}
            activityData={activityData}
            refreshActivity={() => refetch()}
            addActivityDropdown={dropdownOptions}
            connectionStatus={connectionData.status ?? ""}
            onboardingInitiated={hasOnboardingInitiated}
            userView={props.userView}
            companyName={connectionData.name ?? "N/A"}
            companyType={connectionData?.type ? connectionData?.type : "Other"}
            companyId={connectionData?.id ?? "N/A"}
            erpConnection={connectionData.app_enrollment_id !== null}
          />
        </div>
      </div>
      <RightPanelCollapsible
        isLoading={isLoading}
        companyName={connectionData?.name ?? "N/A"}
        contacts={activityData.contacts ?? []}
        attachments={activityData.attachments ?? []}
        transactions={activityData.transactions ?? {}}
      />
    </div>
  );
}

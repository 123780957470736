import React from "react";
import { Note } from "../../../../../../library/Icons/Icons";
import PrimaryActivity from "../../PrimaryActivity/PrimaryActivity";
import { NoteProps } from "../ActivityItemVariantTypes";
import * as Labels from "../../../../../../../constants/config";
import NoteUserActions from "../../PrimaryActivity/Helpers/NoteUserActions/NoteUserActions";

const NoteActivity: React.FC<NoteProps> = (props) => {
  return (
    <PrimaryActivity {...{ ...props, creator: { ...props.creator, message: Labels.HEADER_NOTE }, icon: <Note /> }}>
      {props?.enableReviewOptions && <NoteUserActions {...{ onClickCopy: props?.onCopyBody }} />}
    </PrimaryActivity>
  );
};

export default NoteActivity;

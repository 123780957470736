import React from "react";
import { FilterIconContainer, FilterIconContainerActive } from "../../../styled/table.filter.styled";
import {
  HeaderLabel,
  ListItemContainer,
  SearchInputField,
  SelectedMenuItemsContainer,
  DropdownSearchPopupContainer,
  SuggestionMenuItemsContainer,
} from "../styled/dropdown.search.filter.styled";
import { ReactComponent as FilterIcon } from "../../../../svg/filter_icon.svg";
import { DropdownItemList, DropdownSearchFilterComponentProps } from "../../preset.filter.types";
import { Avatar, Box, Divider, InputAdornment, ListItemAvatar, ListItemText, ListSubheader, MenuItem } from "@mui/material";
import { CheckList } from "../../../../../Icons/Icons";
import { Close, Search } from "@mui/icons-material";
import AttachmentChipItems from "./AttachmentChipItems";
import { SmartText } from "../../../../../SmartText";
import DOMPurify from "dompurify";
import { NUMERIC_VALUES } from "../../../../constants/numeric.constants";
import SuggestionSkeleton from "./SuggestionSkeleton";
import EmptySuggestionMessage from "./EmptySuggestionMessage";
import { useTranslation } from "react-i18next";

const DropdownSearchFilterComponent: React.FC<DropdownSearchFilterComponentProps> = ({
  onClickSuggestionListItem,
  onClickSelectedListItem,
  searchFieldText,
  filterActive,
  onChangeSearchInputFieldValue,
  onClickClearFilter,
  filterAlignment,
  dropdownSearchListItems,
  searchPlaceholder,
  isSuggestionDataLoading,
  dropdownSearchVariant,
  isNoSearchResultFound,
}) => {
  const IconWrapper = filterActive ? FilterIconContainerActive : FilterIconContainer;
  const [dropdownSearchFilterAnchorEl, setDropdownSearchFilterAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpenStringFilter = Boolean(dropdownSearchFilterAnchorEl);
  const { t } = useTranslation();

  const onClickStringFilter = (event: React.MouseEvent<HTMLElement>) => {
    setDropdownSearchFilterAnchorEl(event.currentTarget);
  };

  //return menu item for suggested and selected category based on required conditions
  const getListItemContent = (itemList: DropdownItemList) => {
    return (
      <ListItemContainer>
        {itemList?.isAvatarRequired && (
          <ListItemAvatar>
            <Avatar>
              {itemList?.primaryText?.split(" ")?.[0]?.[0]}
              {itemList?.primaryText?.split(" ")?.[1]?.[0]}
            </Avatar>
          </ListItemAvatar>
        )}

        <ListItemText
          primary={
            <SmartText
              title={DOMPurify.sanitize(itemList?.primaryText) ?? ""}
              highlighter={{
                enableHighlighter: searchFieldText?.length >= NUMERIC_VALUES.CONSTANT_THREE,
                matchText: searchFieldText ?? "~",
              }}
              tooltipRequired={false}
            />
          }
          secondary={
            itemList?.isAttachemntRequired && Array.isArray(itemList?.secondaryText) ? (
              <AttachmentChipItems attachmentList={itemList.secondaryText} attachmentCount={itemList?.secondaryText.length} />
            ) : (
              <SmartText
                title={DOMPurify.sanitize(itemList?.secondaryText?.toString()) ?? ""}
                highlighter={{
                  enableHighlighter: searchFieldText?.length >= NUMERIC_VALUES.CONSTANT_THREE,
                  matchText: searchFieldText ?? "~",
                }}
                tooltipRequired={false}
              />
            )
          }
        />
      </ListItemContainer>
    );
  };

  const selectedList = dropdownSearchListItems?.filter((item: DropdownItemList) => item.selected);
  const suggestedList = dropdownSearchListItems?.filter((item: DropdownItemList) => !item.selected);
  const searchResultHeaderLabel = t("filters.dropdownSearchFilter.headerLabels.searchResults", { ns: "table" });
  const searchSuggestionHeaderLabel = t("filters.dropdownSearchFilter.headerLabels.searchSuggestions", { ns: "table" });
  const emptySearchResultMessage = t("filters.dropdownSearchFilter.emptySearchResultMessage", { ns: "table" });

  return (
    <>
      <IconWrapper onClick={onClickStringFilter}>
        <FilterIcon />
      </IconWrapper>
      <DropdownSearchPopupContainer
        open={isOpenStringFilter}
        onClose={() => {
          setDropdownSearchFilterAnchorEl(null);
          onClickClearFilter();
        }}
        anchorEl={dropdownSearchFilterAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: filterAlignment,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: filterAlignment,
        }}
        dropdownSearchVariant={dropdownSearchVariant}
      >
        <ListSubheader>
          {/* Search Field */}
          <SearchInputField
            key="searchInput"
            value={searchFieldText}
            placeholder={searchPlaceholder ?? (t("filters.dropdownSearchFilter.searchInputPlacholder", { ns: "table" }) as string)}
            onChange={(event) => {
              const { value } = event.target;
              if (value !== searchFieldText) {
                onChangeSearchInputFieldValue(value);
              }
            }}
            onKeyDown={(event) => event.stopPropagation()}
            disableUnderline={true}
            startAdornment={
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            }
            endAdornment={
              searchFieldText?.length >= NUMERIC_VALUES.CONSTANT_THREE && (
                <InputAdornment position="end" onClick={onClickClearFilter}>
                  <Close />
                </InputAdornment>
              )
            }
          />
        </ListSubheader>

        {/* Selected Menu List Items */}
        {selectedList?.length > NUMERIC_VALUES.CONSTANT_ZERO && (
          <SelectedMenuItemsContainer>
            {selectedList.map((item: DropdownItemList) => {
              return (
                (item?.primaryText || item?.secondaryText) && (
                  <MenuItem
                    key={item?.id}
                    value={item?.id}
                    selected={item?.selected}
                    onClick={() => {
                      setDropdownSearchFilterAnchorEl(null);
                      onClickSelectedListItem(item?.id);
                    }}
                  >
                    <Box>{item?.selected && <CheckList />}</Box>
                    {getListItemContent(item)}
                  </MenuItem>
                )
              );
            })}
          </SelectedMenuItemsContainer>
        )}

        {/* Suggested Menu List Items */}
        {isSuggestionDataLoading ? (
          <>
            {selectedList?.length > NUMERIC_VALUES.CONSTANT_ZERO && <Divider />}
            <HeaderLabel>
              {searchFieldText?.length >= NUMERIC_VALUES.CONSTANT_THREE ? searchResultHeaderLabel : searchSuggestionHeaderLabel}
            </HeaderLabel>
            <SuggestionSkeleton dropdownSearchVariant={dropdownSearchVariant} />
          </>
        ) : (
          <>
            {suggestedList?.length > NUMERIC_VALUES.CONSTANT_ZERO && (
              <>
                {selectedList?.length > NUMERIC_VALUES.CONSTANT_ZERO && <Divider />}
                <HeaderLabel>
                  {searchFieldText?.length >= NUMERIC_VALUES.CONSTANT_THREE ? searchResultHeaderLabel : searchSuggestionHeaderLabel}
                </HeaderLabel>
                <SuggestionMenuItemsContainer>
                  {suggestedList.map((item: DropdownItemList) => {
                    return (
                      (item?.primaryText || item?.secondaryText) && (
                        <MenuItem
                          key={item?.id}
                          value={item?.id}
                          onClick={() => {
                            setDropdownSearchFilterAnchorEl(null);
                            onClickSuggestionListItem(item?.id);
                          }}
                        >
                          <Box />
                          {getListItemContent(item)}
                        </MenuItem>
                      )
                    );
                  })}
                </SuggestionMenuItemsContainer>
              </>
            )}

            {/* when no search result found */}
            {(dropdownSearchListItems?.length <= NUMERIC_VALUES.CONSTANT_ZERO || isNoSearchResultFound) &&
              searchFieldText?.length >= NUMERIC_VALUES.CONSTANT_THREE && <HeaderLabel>{emptySearchResultMessage}</HeaderLabel>}

            {/* when no suggestion available */}
            {dropdownSearchListItems?.length <= NUMERIC_VALUES.CONSTANT_ZERO && searchFieldText?.length < NUMERIC_VALUES.CONSTANT_THREE && (
              <>
                <HeaderLabel>{searchSuggestionHeaderLabel}</HeaderLabel>
                <EmptySuggestionMessage />
              </>
            )}
          </>
        )}
      </DropdownSearchPopupContainer>
    </>
  );
};

export default DropdownSearchFilterComponent;

import React, { useEffect, useState } from "react";
import _ from "underscore";
import { Input, InputAdornment, Typography } from "@mui/material";
import { Search } from "@mui/icons-material";
import { ActivityUnassigned, CheckedRoundSuccessGreen, CommandCenterActivityAssign } from "../../../../../library/Icons/Icons";
import { NUMERIC_VALUES } from "../../../../../library/AtomicComponents/constants/numeric.constants";
import { ActivityContext } from "../../../../../../contexts/ActivityContext";
import { AlertContext } from "../../../../../../contexts/AlertContext";
import { WorkspaceContext } from "../../../../../../contexts/WorkspaceContext";
import { ActivitySubmenuContainer, ActivitySubmenuHeader, SearchInputContainer, OptionValuesList, OptionValuesListItem } from "./styled";
import TrackingUtils from "../../../../../Tracking/Tracking.Utils";
import { ACTIVITY_REASSIGN_PENDO } from "../../../../../../constants/config";
import { SmartText } from "../../../../../library/SmartText";
import { useTranslation } from "react-i18next";

interface ActivityReassignViewProps {
  onCallback: () => void;
  activityIds: string[];
  currentAssignee: UserItem | null;
  handleCommandCenterClose: () => void;
  keyboardShortcutText: string;
}

const ActivityReassignView: React.FC<ActivityReassignViewProps> = ({
  onCallback,
  activityIds,
  currentAssignee,
  handleCommandCenterClose,
  keyboardShortcutText,
}) => {
  const [searchText, setSearchText] = React.useState<string>("");
  const { handleReassign } = React.useContext(ActivityContext) as ActivityType;
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const { workspaceMembers } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const [noteValue, setNoteValue] = useState<string>("");
  const [teamMemberList, setTeamMemberList] = useState<Partial<UserAccountModel>[]>([]);
  const [selectedUser, setSelectedUser] = useState<Partial<UserAccountModel> | null>(null);
  const { t } = useTranslation();

  const handleUserSearch = (event: { target: { value: string } }) => setSearchText(event.target.value);

  const handleUserSelection = async (userID: string | undefined) => {
    handleCommandCenterClose();
    const userSelection = teamMemberList.find((item) => item.userId === userID) ?? null;
    let toastOptions: ToastOptions = {
      open: true,
      severity: "success",
      message:
        activityIds.length === NUMERIC_VALUES.CONSTANT_ONE ? (
          <p>
            {t("toastMessages.reassignActivity.success", { ns: "activities" })} <span style={{ fontWeight: 700 }}>{userSelection?.userName}</span>
          </p>
        ) : (
          t("toastMessages.reassignActivity.bulkSuccess", { activitesCount: activityIds.length, ns: "activities" })
        ),
    };
    let response = {} as APIResponse;

    try {
      response = await handleReassign(
        activityIds.length === NUMERIC_VALUES.CONSTANT_ONE ? activityIds[0] : activityIds,
        userSelection?.userId ?? null,
        noteValue
      );
    } catch (e: unknown) {
      response.success = false;
    } finally {
      if (!response.success) {
        toastOptions = {
          ...toastOptions,
          severity: "error",
          message:
            activityIds.length === NUMERIC_VALUES.CONSTANT_ONE
              ? t("toastMessages.reassignActivity.failed", { ns: "activities" })
              : t("toastMessages.reassignActivity.bulkfailed", { activitesCount: activityIds.length, ns: "activities" }),
        };
      } else {
        setSelectedUser(userSelection);
      }
      TrackingUtils.trackUserActivities(ACTIVITY_REASSIGN_PENDO, response.success);
      onCallback();
      setNoteValue("");
      setSearchText("");
      setToastOptions(toastOptions);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(workspaceMembers)) {
      setTeamMemberList(
        workspaceMembers?.map((user: Partial<UserItem>) => ({
          userId: `${user.id}`,
          userName: user.name ?? "",
          userEmail: user.email_address,
        })) ?? []
      );
    }

    const unassignedUser = {
      userId: "",
      userName: t("modalAndFlyout.reassignActivity.unassigned", { ns: "activities" }),
      userEmail: "",
    };
    setTeamMemberList((prev: Partial<UserAccountModel>[]) => [unassignedUser, ...prev]);
  }, [workspaceMembers]);

  useEffect(() => {
    setSelectedUser({
      userEmail: currentAssignee?.email_address ?? "",
      userId: currentAssignee?.id ?? "",
      userName: currentAssignee?.name ?? "",
    } as Partial<UserAccountModel>);
  }, [currentAssignee]);

  return (
    <ActivitySubmenuContainer>
      <ActivitySubmenuHeader>
        <CommandCenterActivityAssign />{" "}
        <div className="description">
          <Typography className="title">{t("activityDetails.commandCenter.body.activityReassignView.title", { ns: "activities" })}</Typography>
          <Typography className="subtitle">{t("activityDetails.commandCenter.body.activityReassignView.subTitle", { ns: "activities" })}</Typography>
        </div>
        <Typography className="keyboard-shortcut-text">{keyboardShortcutText}</Typography>
      </ActivitySubmenuHeader>
      <SearchInputContainer>
        <Input
          key="searchInput"
          value={searchText}
          placeholder={t("activityDetails.commandCenter.body.activityReassignView.searchPlaceholder", { ns: "activities" }) as string}
          onChange={handleUserSearch}
          onKeyDown={(event) => event.stopPropagation()}
          disableUnderline={true}
          startAdornment={
            <InputAdornment position="start">
              <Search className="" />
            </InputAdornment>
          }
        />
      </SearchInputContainer>
      <Typography onClick={() => handleUserSelection("")} className="current-value-subtitle subtitle-item">
        {t("modalAndFlyout.reassignActivity.unassigned", { ns: "activities" })}
        <span className="list-item-end-icon">
          <ActivityUnassigned />
        </span>
      </Typography>
      <Typography className="current-value-subtitle">
        {(_.isEmpty(searchText) && teamMemberList.length > NUMERIC_VALUES.CONSTANT_ZERO) ||
        (!_.isEmpty(searchText) && teamMemberList.some((item) => item?.userName?.toLowerCase().includes(searchText.toLowerCase())))
          ? t("activityDetails.commandCenter.body.activityReassignView.memberListHeading.allTeammates", { ns: "activities" })
          : t("activityDetails.commandCenter.body.activityReassignView.memberListHeading.nothingFound", { ns: "activities" })}
      </Typography>
      <OptionValuesList>
        {teamMemberList?.map((item: Partial<UserAccountModel>) => {
          if (item.userId !== "" && !_.contains(selectedUser?.userName ?? "", item.userName)) {
            if (_.isEmpty(searchText) || item?.userName?.toLowerCase().indexOf(searchText.toLowerCase()) !== NUMERIC_VALUES.CONSTANT_NEGATIVE_ONE) {
              return (
                <OptionValuesListItem key={item.userName} onClick={() => handleUserSelection(item.userId)}>
                  <span className="list-item-icon">{currentAssignee?.name === item.userName && <CheckedRoundSuccessGreen />}</span>
                  <SmartText
                    highlighter={{
                      enableHighlighter: searchText.length > NUMERIC_VALUES.CONSTANT_ZERO ? true : false,
                      matchText: searchText ?? "~",
                    }}
                    title={item.userName ?? ""}
                    className="list-item-value"
                  />
                </OptionValuesListItem>
              );
            }
          }
        })}
      </OptionValuesList>
    </ActivitySubmenuContainer>
  );
};

export default ActivityReassignView;

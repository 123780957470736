/*eslint-disable no-magic-numbers*/
// import React from "react";
import { styled } from "@mui/system";
import { pxToRem } from "../../../theme/theme";
import { TableCellHeaderContainerProps } from "../../types/table.types";
import { colors } from "@mui/material";

export const TableHeader = styled("div", {
  name: "table-header",
  slot: "thead",
})({
  background: "#FFFFFF",
  width: "100%",
  height: "100%",
  boxShadow: "0px 2.5px 2.5px -1.5px rgba(0, 0, 0, 0.15)",
});

export const TableCellHeaderContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "disableHover",
  name: "table-cell-header",
  slot: "container",
})<TableCellHeaderContainerProps>(({ isHoverDisabled }) => ({
  position: "relative",
  padding: `${pxToRem(10)} ${pxToRem(20)}`,
  borderRadius: `${pxToRem(4)} ${pxToRem(4)} ${pxToRem(0)} ${pxToRem(0)} `,
  display: "flex",
  "& .table-sortIcon": {
    height: pxToRem(16),
    position: "absolute",
    left: pxToRem(0),
    top: 0,
    bottom: 0,
    margin: "auto 0",
    cursor: "pointer",
    display: "none",
    "& svg": {
      height: "inherit",
    },
  },
  "& .table-header-cell-label": {
    display: "flex",
    width: "100%",
    alignItems: "center",
    gap: pxToRem(4),
    flex: "1 1 auto",
    color: colors.grey["900"], // "#4D4D4D",
    "& .table-header-cell-name": {
      userSelect: "none",
    },
    "& .info-icon-container": {
      height: pxToRem(19),
    },
  },
  "& .table-header-filter": {
    marginLeft: pxToRem(4),
    flex: "0 0 auto",
  },
  " :hover": {
    "& .table-sortIcon.hide-by-default": {
      display: "block",
    },
    backgroundColor: isHoverDisabled ? "#FFFFFF" : "#F0F0F0",
  },
}));

export const IconContainer = styled("div", {
  name: "cell-icon",
  slot: "root",
})({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& svg": {
    height: pxToRem(20),
  },
});

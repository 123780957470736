import APIClient from "../APIClient";
import { AxiosRequestConfig } from "axios";

const INBOX_API_BASE = `${process.env.REACT_APP_API_ENDPOINT_INBOX_API_PATH}${process.env.REACT_APP_API_ENDPOINT_INBOX_API_V2}`;
const CONFIG_OVERRIDE = {
  baseURL: process.env.REACT_APP_API_ENDPOINT_INBOX,
} as AxiosRequestConfig;

export class NotificationsClient {
  /**
   * * GET on  /api/v2/notifications/settings
   * @returns Promise of type APIResponse Model or Exception
   */
  getAllNotificationPermissions(): Promise<APIResponse> {
    const url = `${INBOX_API_BASE}/notifications/settings`;

    return APIClient.get(url, CONFIG_OVERRIDE).then((response) => {
      return response.data;
    });
  }

  /**
   * * Patch on  /api/v2/notifications/settings
   * @returns Promise of type APIResponse Model or Exception
   */
  updateNotificationsSettings(payload: NotificationsSettings): Promise<APIResponse> {
    const url = `${INBOX_API_BASE}/notifications/settings`;
    return APIClient.patch(url, payload, CONFIG_OVERRIDE).then((response) => {
      return response.data;
    });
  }
}

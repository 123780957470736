import APIClient from "../APIClient";
import { AxiosRequestConfig } from "axios";

const INBOX_API_BASE = `${process.env.REACT_APP_API_ENDPOINT_INBOX_API_PATH}${process.env.REACT_APP_API_ENDPOINT_INBOX_API_V2}`;
const CONFIG_OVERRIDE = {
  baseURL: process.env.REACT_APP_API_ENDPOINT_INBOX,
} as AxiosRequestConfig;

export class SignatureClients {
  /**
   * * POST on /api/v2/settings/signature
   * @returns Promise or Exception
   */
  create(body: SignatureModel): Promise<SignatureFetchModel> {
    const url = `${INBOX_API_BASE}/settings/signatures`;
    return APIClient.post(url, body, CONFIG_OVERRIDE).then((response) => {
      return response.data;
    });
  }

  update(body: SignatureModel): Promise<SignatureFetchModel> {
    const url = `${INBOX_API_BASE}/settings/signatures`;

    return APIClient.patch(url, body, CONFIG_OVERRIDE).then((response) => {
      return response.data;
    });
  }

  fetch(): Promise<SignatureFetchModel> {
    const url = `${INBOX_API_BASE}/settings/signatures`;
    return APIClient.get(url, CONFIG_OVERRIDE).then((response) => {
      return response.data;
    });
  }

  delete(): Promise<void> {
    const url = `${INBOX_API_BASE}/settings/signatures`;
    return APIClient.delete(url, CONFIG_OVERRIDE).then((response) => {
      return response.data;
    });
  }
}

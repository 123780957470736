import React from "react";
import { MenuItem, Select, SelectProps, Input, ListSubheader, SelectChangeEvent } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import useTemplateSelectMenuStyle from "./useTemplateSelectMenuStyle";
import CustomTabs, { tabListItem } from "../../Tabs/CustomTabs";
import { TooltipTypes } from "../../AtomicComponents/constants/tooltip.constants";
import CustomTooltip from "../../AtomicComponents/CustomTooltip/CustomTooltip";
import { NUMERIC_VALUES } from "../../../../constants/NumericConstants";
import { FeatureFlagContext, FeatureFlagProviderType } from "../../../../contexts/FeatureFlagContext";
import { TemplateTypes } from "../../../../app/Templates/TemplateTypes";
import { DEFAULT_NUMERIC_VALUES } from "../../AtomicComponents/constants/numeric.constants";

export type TemplateType = {
  displayText: string;
  id: string;
  label: string;
};

interface TemplateSelectMenuProps extends SelectProps {
  templateList: Map<string, [TemplateType]>;
  tabList: tabListItem[];
  isTabsRequired?: boolean;
  subjectInputValue: string;
  placeHolderText?: string;
  currentSelectedTab: string;
  selectedTemplateItemId: string;
  onChangeSubjectInputText: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onChangeTabs: (event: React.ChangeEvent<object>, newValue: string) => void;
  onChangeTemplateListItem: (event: SelectChangeEvent) => void;
}

const TemplateSelectMenu: React.FC<TemplateSelectMenuProps> = ({
  templateList,
  tabList,
  isTabsRequired = true,
  subjectInputValue,
  currentSelectedTab,
  selectedTemplateItemId,
  onChangeSubjectInputText,
  onChangeTabs,
  onChangeTemplateListItem,
  placeHolderText = "Subject",
}) => {
  const { select, searchInput, menuRoot, subHeader, subjectRoot } = useTemplateSelectMenuStyle();
  const inputRef = React.useRef(null);
  const { isEnabled } = React.useContext(FeatureFlagContext) as FeatureFlagProviderType;
  const [templates, setTemplates] = React.useState<TemplateType[] | undefined>();

  /**
   * feature flag individual template selection menu
   *
   * Note:
   * remove this implementation when Profile_management_templates feature flag is removed
   */
  React.useEffect(() => {
    setTemplates(
      templateList?.get(currentSelectedTab)?.filter((item: TemplateType) => {
        if (
          [
            TemplateTypes.AP_UPDATE_VENDOR_PROFILE,
            TemplateTypes.AR_UPDATE_CUSTOMER_PROFILE,
            TemplateTypes.AP_CREATE_VENDOR_PROFILE,
            TemplateTypes.AR_CREATE_CUSTOMER_PROFILE,
          ].includes(item.id as TemplateTypes) &&
          !isEnabled("PROFILE_MANAGEMENT_TEMPLATES")
        ) {
          return false;
        }
        return true;
      })
    );
  }, [currentSelectedTab]);

  return (
    <div className={subjectRoot} ref={inputRef}>
      {/* Subject Input field */}
      <Input
        value={subjectInputValue}
        placeholder={placeHolderText}
        disableUnderline={true}
        classes={{ input: searchInput }}
        onChange={onChangeSubjectInputText}
        className={searchInput}
        spellCheck={true}
      />

      {templates?.length !== DEFAULT_NUMERIC_VALUES.ZERO && (
        <Select
          id="template-dropdown"
          onChange={onChangeTemplateListItem}
          className={select}
          value={selectedTemplateItemId}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            className: menuRoot,
          }}
          IconComponent={KeyboardArrowDown}
          renderValue={() => ""}
        >
          {/* Tabs For Language selection */}
          {isTabsRequired && (
            <ListSubheader className={subHeader}>
              <CustomTabs onChangeTabs={onChangeTabs} tabList={tabList} selectedTabValue={currentSelectedTab} />
            </ListSubheader>
          )}

          {/*Menu List Item for template  */}
          {templates?.map((item: TemplateType) => {
            const itemLabel = item?.label || "";
            return itemLabel.length > NUMERIC_VALUES.CONSTANT_EIGHTY ? (
              <MenuItem key={item?.id} value={item?.id}>
                <CustomTooltip type={TooltipTypes.RICH} title={item?.label} shortcutKeysText={item?.label}>
                  <div className="label-overflow">{item?.label}</div>
                </CustomTooltip>
              </MenuItem>
            ) : (
              <MenuItem key={item?.id} value={item?.id}>
                {item?.label}
              </MenuItem>
            );
          })}
        </Select>
      )}
    </div>
  );
};

export default TemplateSelectMenu;

import React from "react";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES, TRIM_COUNT_CONSTANTS } from "../constants/NumericConstants";
import { reportsClient, companiesClient } from "../db/accessor";
import { dashboardClientV2 } from "../db/version2Accessor";
import { FallbackTypes, TopCompaniesBy, WorkspaceType } from "../types/enums";
import { WorkspaceContext } from "./WorkspaceContext";
import { ComponentConfigContext } from "./ComponentConfigContext";
import Utils from "../utils/utils";
import { DateTime } from "luxon";

export const DashboardContext = React.createContext<DashboardType | null>(null);

export interface DashboardProviderProps {
  children: (React.ReactNode & { type: string })[] | (React.ReactNode & { type: string });
}

const DashboardProvider: React.FC<DashboardProviderProps> = ({ children }: DashboardProviderProps) => {
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { getConfig } = React.useContext(ComponentConfigContext) as ComponentConfigType;
  const {
    dashboard: { barColors },
  } = getConfig(selectedWorkspace?.workspace_type_route?.toLocaleLowerCase() ?? WorkspaceType.AR) as any;

  // GET on /api/v1/ar-aging-header
  async function getArAgingData(): Promise<GraphData[]> {
    return reportsClient.getArAging(selectedWorkspace.workspace_type_route as string).then((data: ArAgingHeaderInfoModel[]) => {
      if (data.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
        return [];
      }
      // future due -> 1-30 -> ... -> 91+
      data.sort((left: ArAgingHeaderInfoModel, right: ArAgingHeaderInfoModel) =>
        (left?.reportBucket ?? "") < (right?.reportBucket ?? "") ? DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE : DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
      );
      return [
        {
          id: "LATE 91+ DAYS",
          value: data[4].totalOutstandingAmount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
          color: "#EF476F",
        },
        {
          id: "LATE 61-90 DAYS",
          value: data[3].totalOutstandingAmount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
          color: "#FFD166",
        },
        {
          id: "LATE 31-60 DAYS",
          value: data[2].totalOutstandingAmount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
          color: "#046180",
        },
        {
          id: "LATE 1-30 DAYS",
          value: data[1].totalOutstandingAmount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
          color: "#06D6A0",
        },
        {
          id: "FUTURE DUE",
          value: data[0].totalOutstandingAmount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
          color: "#2D9DE7",
        },
      ];
    });
  }

  // GET on /api/v1/ap-aging-header
  async function getApAgingData(): Promise<GraphData[]> {
    return reportsClient.getApAging(selectedWorkspace.workspace_type_route as string).then((data: ApAgingHeaderInfoModel[]) => {
      if (data.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
        return [];
      }
      // future due -> 1-30 -> ... -> 91+
      data.sort((left: ApAgingHeaderInfoModel, right: ApAgingHeaderInfoModel) =>
        (left?.reportBucket ?? "") < (right?.reportBucket ?? "") ? DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE : DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
      );
      return [
        {
          id: "LATE 91+ DAYS",
          value: data[4].totalOutstandingAmount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
          color: "#EF476F",
        },
        {
          id: "LATE 61-90 DAYS",
          value: data[3].totalOutstandingAmount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
          color: "#FFD166",
        },
        {
          id: "LATE 31-60 DAYS",
          value: data[2].totalOutstandingAmount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
          color: "#046180",
        },
        {
          id: "LATE 1-30 DAYS",
          value: data[1].totalOutstandingAmount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
          color: "#06D6A0",
        },
        {
          id: "FUTURE DUE",
          value: data[0].totalOutstandingAmount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
          color: "#2D9DE7",
        },
      ];
    });
  }

  // GET on /api/v1/Reports/cashflow
  async function getSummaryData(summaryType: "accountSummary" | "comingDueSummary"): Promise<GraphData[]> {
    if (selectedWorkspace.workspace_type_route === WorkspaceType.AP) {
      switch (summaryType) {
        case "accountSummary": {
          return reportsClient.getPayablesSummary(undefined).then((data) => {
            return [
              {
                id: "PAID",
                color: barColors.light,
                value: (data as PayablesSummaryModel).totalPaymentsAmount,
              },
              {
                id: "BILLED",
                color: barColors.dark,
                value: (data as PayablesSummaryModel).totalAmountBilled,
              },
            ];
          });
        }
        case "comingDueSummary": {
          return reportsClient.getPayablesComingDue().then((data: Array<PayablesComingDueData>) => {
            data.sort((left: PayablesComingDueData, right: PayablesComingDueData) => {
              return Utils.dateSortFunction(left.date, right.date);
            });

            return [
              {
                id: "Due Now",
                color: barColors.light,
                value: (data[3] as PayablesComingDueData).amountDue,
              },
              {
                id: "DUE NEXT 30 DAYS",
                color: barColors.light,
                value: (data[0] as PayablesComingDueData).amountDue,
              },
              {
                id: "DUE NEXT 14 DAYS",
                color: barColors.light,
                value: (data[1] as PayablesComingDueData).amountDue,
              },
              {
                id: "DUE NEXT 7 DAYS",
                color: barColors.dark,
                value: (data[2] as PayablesComingDueData).amountDue,
              },
            ];
          });
        }
        default:
          return [];
      }
    } else {
      switch (summaryType) {
        case "accountSummary": {
          return reportsClient.getCashflow(undefined).then((data) => {
            return [
              {
                id: "INVOICED",
                color: barColors.light,
                value: (data as CashFlowModel).invoicesBilled,
              },
              {
                id: "COLLECTED",
                color: barColors.dark,
                value: (data as CashFlowModel).paymentsCollected,
              },
            ];
          });
        }
        default:
          return [];
      }
    }
  }

  // GET on /api/v1/Reports/dailysalesoutstanding
  async function getOutstandingData(): Promise<GraphData[]> {
    const apCohortNames = ["PRIOR", "LAST", "CURRENT"];
    switch (selectedWorkspace.workspace_type_route) {
      case WorkspaceType.AP:
        return reportsClient.getDPO().then((data: Array<DailyPayableOutstandingReportModel>) => {
          return data
            .sort((left: DailyPayableOutstandingReportModel, right: DailyPayableOutstandingReportModel) =>
              Utils.dateSortFunction(left.timeframe, right.timeframe)
            )
            .reverse()
            .map((val: DailyPayableOutstandingReportModel, index: number, arr: DailyPayableOutstandingReportModel[]) => {
              // Change in DPO from last quarter to this quarter

              return {
                id: apCohortNames[index],
                // Set top bar to dark color
                color: index === arr.length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE ? barColors.dark : barColors.light,
                value: Math.ceil(val.dailyPayableOutstanding),
                timestamp: val.timeframe,
              };
            });
        });
      case WorkspaceType.AR:
        return reportsClient.getDSO(DateTime.now().toFormat("yyyy-MM-dd")).then((data) => {
          return (
            data
              // Sort data points in ascending order
              .sort((a: { timeframe: string }, b: { timeframe: string }) => new Date(a.timeframe).getMonth() - new Date(b.timeframe).getMonth())
              // Map data points to readable barChart data
              .map((val: DailySalesOutstandingReportModel, index: number, arr: DailySalesOutstandingReportModel[]) => {
                // Change in DSO from last month to this month

                return {
                  id: new Intl.DateTimeFormat("en-US", { month: "long" })
                    .format(new Date(val.timeframe))
                    .toUpperCase()
                    .substr(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, TRIM_COUNT_CONSTANTS.DSO_VALUE),
                  // Set top bar to dark color
                  color: index === arr.length - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE ? barColors.dark : barColors.light,
                  value: Utils.roundToPrecisionPoint(val.dailySalesOutstanding, DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO),
                  timestamp: val.timeframe,
                };
              })
          );
        });
      default:
        return [];
    }
  }

  // GET on /api/v1/Companies/views/customer-summary
  async function getARTopRisksData(
    filter?: TopCompaniesBy,
    sortByAsc?: boolean,
    workspace_id?: number,
    fetchType?: string,
    sortValues?: { [key: string]: { [key: string]: string } }
  ): Promise<CustomerSummaryModelInterface[] | APIResponse | null> {
    const sortOrder = sortByAsc ? "ASC" : "DESC";
    const filterChecks: Array<string> = [
      TopCompaniesBy.Open_Invoices,
      TopCompaniesBy.Past_Due_Balance,
      TopCompaniesBy.Past_Due_Invoices,
      TopCompaniesBy.Total_Balance,
      TopCompaniesBy.Open_Bills,
      TopCompaniesBy.Coming_Due,
    ];

    if (filterChecks.includes(filter ?? "") && fetchType === "company") {
      return companiesClient
        .getCustomerSummaries(
          "appEnrollmentId IS NOT NULL",
          undefined,
          `${filter ? `${sortValues?.[filter].filter} ${sortOrder}${sortValues?.[filter].additionalFilters}` : ""}`,
          NUMERIC_VALUES.CONSTANT_FIVE,
          DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
        )
        .then((data) => {
          return data.records;
        }) as Promise<CustomerSummaryModelInterface[] | null>;
    }

    return dashboardClientV2.getTopCustomers(
      "recent_activity",
      workspace_id ?? FallbackTypes.Id,
      NUMERIC_VALUES.CONSTANT_FIVE,
      `${filter ? `${sortValues?.[filter].filter} ${sortOrder.toLowerCase()}${sortValues?.[filter].additionalFilters}` : ""}`
    ) as Promise<APIResponse>;
  }

  // GET on /api/v1/Companies/views/vendor-summary
  async function getAPTopRisksData(
    filter?: TopCompaniesBy,
    sortByAsc?: boolean,
    workspace_id?: number,
    fetchType?: string,
    sortValues?: { [key: string]: { [key: string]: string } }
  ): Promise<VendorSummaryModelInterface[] | APIResponse | null> {
    const sortOrder = sortByAsc ? "ASC" : "DESC";
    const filterChecks: Array<string> = [
      TopCompaniesBy.Open_Invoices,
      TopCompaniesBy.Past_Due_Balance,
      TopCompaniesBy.Past_Due_Invoices,
      TopCompaniesBy.Total_Balance,
      TopCompaniesBy.Open_Bills,
      TopCompaniesBy.Coming_Due,
    ];

    if (filterChecks.includes(filter ?? "") && fetchType === "vendor") {
      return companiesClient
        .getVendorSummaries(
          "appEnrollmentId IS NOT NULL",
          undefined,
          `${filter ? `${sortValues?.[filter].filter} ${sortOrder}${sortValues?.[filter].additionalFilters}` : ""}`,
          NUMERIC_VALUES.CONSTANT_FIVE,
          DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
        )
        .then((data) => {
          return data.records;
        }) as Promise<VendorSummaryModelInterface[] | null>;
    }

    return dashboardClientV2.getTopCustomers(
      "recent_activity",
      workspace_id ?? FallbackTypes.Id,
      NUMERIC_VALUES.CONSTANT_FIVE,
      `${filter ? `${sortValues?.[filter].filter} ${sortOrder.toLowerCase()}${sortValues?.[filter].additionalFilters}` : ""}`
    ) as Promise<APIResponse>;
  }

  // GET on /api/v1/Reports/riskrates
  async function getRiskRateData(): Promise<RiskRateModel[]> {
    return reportsClient.getRiskRate().then((data) => {
      return data;
    });
  }

  return (
    <DashboardContext.Provider
      value={{
        getArAgingData,
        getApAgingData,
        getSummaryData,
        getOutstandingData,
        getARTopRisksData,
        getAPTopRisksData,
        getRiskRateData,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardProvider;

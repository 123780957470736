import JoditEditor, { Jodit } from "jodit-pro-react";
/**
 * Import custom Jodit plugins
 */
import InlineLinkEditor from "./CustomPlugins/InlineLinkEditor";
import LinkEditor from "./CustomPlugins/LinkEditor";

/**
 * Add custom plugins to Jodit, this will be initialized when Jodit is initialized.
 */
Jodit.plugins.add("LinkEditor", LinkEditor);
Jodit.plugins.add("InlineLinkEditor", InlineLinkEditor);

export default JoditEditor;

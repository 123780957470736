import React, { useState, useEffect, MouseEvent, useRef } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import TableUtils from "../../../utils/TableUtils/TableUtils";
import {
  EmailAction,
  FallbackTypes,
  ActivityOptions,
  ActivityType as ActivityTypeEnum,
  WorkspaceType,
  VIEW_CATEGORIES,
  EXPORT_CATEGORIES,
} from "../../../types/enums";
import { DEFAULT_NUMERIC_VALUES, DIFF_IN_DAYS, NUMERIC_VALUES, DEFAULT_PAGINATION_VALUES } from "../../../constants/NumericConstants";
import NewActivityPopup from "../../library/AddNewActivityDropdown/NewActivityPopup";
import { AppContext } from "../../../contexts/AppContext";
import { automationClientV2, activitiesClientV2, emailsClientV2, exportClient, invoicesClientV2 } from "../../../db/version2Accessor";
import { WorkspaceContext } from "../../../contexts/WorkspaceContext";
import { AxiosError } from "axios";
import { CONNECTION_STATUS } from "../../../constants/ConnectionConstants";
import { AlertContext } from "../../../contexts/AlertContext";
import "./InvoiceTable.scss";
import { Email, ExportV2, NoteFill, Phone, Pen, DownloadCloud, Download, Share } from "../../library/Icons/Icons";
import { ActivityContext } from "../../../contexts/ActivityContext";
import { CSVLink } from "react-csv";
import { SummaryItem } from "../../library/SummaryHeader/SummaryHeaderInterfaces/SummaryHeaderInterface";
import { SummaryHeader } from "../../library/SummaryHeader";
import { TemplateDefinitionProps } from "../../../app/Templates/TemplateFactory";
import { TemplateContext } from "../../../contexts/TemplateContext";
import { viewType, TemplateTypes, getViewTypeTemplateCodes } from "../../../app/Templates/TemplateTypes";
import TrackingUtils from "../../Tracking/Tracking.Utils";
import { DocumentSwitchContext } from "../../../contexts/DocumentSwitchContext";
import { ApplicationRouteContext } from "../../../contexts/ApplicationRouteContext";
import Utils from "../../../utils/utils";
import _ from "underscore";
import { CustomerContext } from "../../../contexts/CustomerContext";
import useLocale from "../../../hooks/useLocale";

import { Table, useColumnFilters } from "../../library/AtomicComponents/Table/index";
import { UserAction } from "../../library/AtomicComponents/Table/types/table.types";
import usePagination from "../../library/AtomicComponents/Table/hooks/usePagination";
import useColumnSort from "../../library/AtomicComponents/Table/hooks/useColumnSort";
import useRowSelection from "../../library/AtomicComponents/Table/hooks/useRowSelection";
import APIClientUtils from "../../../utils/APIClientUtils/APIClientUtils";
import { RowDataTypes } from "../../library/AtomicComponents/constants/table.constants";
import { IconButton } from "@mui/material";
import { InvoiceContext } from "../../../contexts/InvoiceContext";
import {
  ACTIVITY_COMPLETE_FAILURE,
  ACTIVITY_COMPLETE_SUCCESS,
  ACTIVITY_SUCCESS_TOAST_MSG,
  BTN_NEW_ACTIVITY,
  NO_DATA_MESSAGE,
  PDF_DOWNLOAD_SUCCESS,
} from "../../../constants/config";
import useContacts from "../../../hooks/useContacts";
import { FeatureFlagContext, FeatureFlagProviderType } from "../../../contexts/FeatureFlagContext";
import { TransactionTypes } from "../../library/AtomicComponents/constants/string.constants";
import { ExportToastMessage } from "../../../constants/ExportConstant";
import { ExportContext } from "../../../contexts/ExportContext";
import ActivityFeedMethods from "../../Activities/ActivityDetail/ActivityFeedV2/helpers/ActivityFeedMethods";
import { useQuery } from "@tanstack/react-query";
import { RefetchIntervals } from "../../../constants/CacheConfig";
import useHandleVisibility from "../../../hooks/useHandleVisibility";
import usePdfDownloadValidation from "../../../hooks/validations/usePdfDownloadValidation";

type InvoiceTableProps = {
  configs: any;
};

export default function InvoiceTable(props: InvoiceTableProps): React.ReactElement {
  const history = useHistory();
  const { url } = useRouteMatch();
  const params = new URLSearchParams(window.location.search);
  const location = history.location.state as any;
  const viewConfigs = props.configs.view;
  const summaryConfigs = props.configs.summary;
  //eslint-disable-next-line
  const [isError, setError] = useState<boolean>(false);
  //eslint-disable-next-line
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [fromTime, setFromTime] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [toTime, setToTime] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);

  const [totalCount, setTotalCount] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [showActivity, setShowActivity] = useState<boolean>(false);
  const { userStatus } = React.useContext(AppContext) as AppType;
  const { getContactsOptions, allContactOptions, filterPrimaryContacts } = useContacts();
  const { invoiceData, downloadTransaction } = React.useContext(InvoiceContext) as InvoiceType;
  // const [companyData, setCompanyData] = useState<CompanyModel>({} as CompanyModel);
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const { signature, loginUserCompany } = React.useContext(CustomerContext) as CustomerType;
  const { selectedWorkspace, workspaceHomePath } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { handleClose } = React.useContext(ActivityContext) as ActivityType;
  const [companyContactOptions, setCompanyContactOptions] = useState<To[]>([]);
  const [newActivityType, setNewActivityType] = useState<string>("");
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [exportData, setExportData] = useState<any[]>([]);
  const csvInstance = useRef<any | null>(null);
  const [defaultTitle, setDefaultTitle] = useState<string>("");
  const { templateFactory, handleTemplateAttachment, prepareTemplateDataObject, templateData } = React.useContext(TemplateContext) as ITemplateProps;
  const [supportedTemplateList, setSupportedTemplateList] = useState<TemplateDefinitionProps[]>();
  const [requiredTemplates, setRequiredTemplates] = useState<Record<string, TemplateDefinitionProps[]>>();
  const [selectedInvoice, setSelectedInvoice] = useState<any>();
  // templateName > indicates the selected template code by the user
  const [templateName, setTemplateName] = useState<string>("");
  const [summaryItems, setSummaryItems] = useState<SummaryItem[]>([]);
  const {
    setSelectedActivityStreamId,
    setActivityStreamIds,
    setCurrentPaginationState,
    setPlatformPageFetchParams,
    setDocumentType,
    setEnableDocumentSwitch,
    setRootSwitcherStateSnapshot,
  } = React.useContext(DocumentSwitchContext) as DocumentSwitchType;
  const { updateBaseRoute } = React.useContext(ApplicationRouteContext) as any;
  const { formatDateBasedOnCountry: formatDate, availableLanguagesForTemplates, calendarDateFormat } = useLocale();

  const primaryContact = filterPrimaryContacts(companyContactOptions);

  const isAPWorkspace =
    selectedWorkspace.workspace_type === "accounting" ? url?.includes("bills") : selectedWorkspace.workspace_type === "accounts_payable";
  const pageNoFromUrl = parseInt(params.get("page") ?? "1", NUMERIC_VALUES.CONSTANT_TEN) || NUMERIC_VALUES.CONSTANT_ONE;
  const { setTotalRecords, setTotalPages, updatePageNumber, ...paginationConfig } = usePagination(
    DEFAULT_PAGINATION_VALUES.PAGE_SIZE,
    pageNoFromUrl ? pageNoFromUrl - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE : DEFAULT_PAGINATION_VALUES.PAGE_COUNT
  );
  const { query: sortQuery, ...sortConfig } = useColumnSort("invoice_date DESC");
  const selectionConfig = useRowSelection(paginationConfig?.page?.totalRecords);
  const { ...initialFilterConfigs } = useColumnFilters(true, [], updatePageNumber);
  const userView = useRef(url.slice(url.lastIndexOf("/") + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE));
  const [columnsConfigData, setColumnsConfigData] = useState<any[]>(() => viewConfigs[userView.current]?.table?.columns);
  const [isColumnsConfigDataLoading, setIsColumnsConfigDataLoading] = useState<boolean>(true);
  const { isEnabled } = React.useContext(FeatureFlagContext) as FeatureFlagProviderType;
  const isFiltersEnabled = isEnabled("FILTERS_ENABLE");
  const isExportV2Enabled = isEnabled("EXPORTS_V2");
  const [isNoSearchResultFound, setIsNoSearchResultFound] = useState<boolean>(false);
  const { updateExportedRecordsIds } = React.useContext(ExportContext) as ExportType;
  const filterObjFromUrl = JSON.parse(params.get("filters") ?? "{}");
  const appliedFilters = initialFilterConfigs.generateFilterQuery(Object.values(filterObjFromUrl), true);
  const [lastApprover, setLastApprover] = useState<To>({} as To);
  const [lastApproverloading, setLastApproverloading] = useState<boolean>(false);
  const { getPDFDwnldToastMessage } = usePdfDownloadValidation("");
  /**
   * setup common state with reducer to handle open/close states for send and send and close button
   * React useState hooks.
   */
  const initialVisibility = {
    isSendBtnDisabled: false,
  };
  const { visibility, dispatch } = useHandleVisibility<typeof initialVisibility>({ isSendBtnDisabled: false });

  // return the view category name for suggestion history
  const getViewCategory = () => {
    if (url?.includes("late_91")) {
      return isAPWorkspace ? VIEW_CATEGORIES.BILLS_91 : VIEW_CATEGORIES.INVOICES_91;
    } else if (url?.includes("late_61_90")) {
      return isAPWorkspace ? VIEW_CATEGORIES.BILLS_61 : VIEW_CATEGORIES.INVOICES_61;
    } else if (url?.includes("late_31_60")) {
      return isAPWorkspace ? VIEW_CATEGORIES.BILLS_31 : VIEW_CATEGORIES.INVOICES_31;
    } else if (url?.includes("late_1_30")) {
      return isAPWorkspace ? VIEW_CATEGORIES.BILLS_1 : VIEW_CATEGORIES.INVOICES_1;
    } else if (url?.includes("future_due")) {
      return isAPWorkspace ? VIEW_CATEGORIES.BILLS_FUTURE : VIEW_CATEGORIES.INVOICES_FUTURE;
    } else if (url?.includes("closed")) {
      return isAPWorkspace ? VIEW_CATEGORIES.BILLS_CLOSED : VIEW_CATEGORIES.INVOICES_CLOSED;
    } else {
      return isAPWorkspace ? VIEW_CATEGORIES.BILLS : VIEW_CATEGORIES.INVOICES;
    }
  };

  const emptyCaptionMessage = `There are no ${props.configs.routes.resource.invoices} to be found.`;

  const fetchCompanyContactOptions = async (customerId: string) => {
    await setCompanyContactOptions(await getContactsOptions(customerId));
  };

  const fetchParser = (fetchResult: InvoiceModelFetchResultV2, variant?: FetchVariant): Partial<TableData>[] => {
    const results =
      fetchResult?.records?.map((record: Partial<InvoiceModelV2>) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record.invoice_id,
          }),
          ...((variant === "export" || variant === "all") && {
            invoice_number: record.invoice_number,
            customer_name: record.customer_name,
            invoice_date: record.invoice_date,
            status: record.status,
            payment_due_date: record.payment_due_date,
            days_past_due: record.days_past_due,
            invoice_amount: record.invoice_amount,
            outstanding_amount: record.outstanding_balance,
            base_currency_invoice_amount: record.base_currency_invoice_amount,
            base_currency_outstanding_balance: record.base_currency_outstanding_balance,
            customer_id: record.customer_id,
            last_activity_at: record?.last_activity_at,

            transaction_currency_code: record.currency_code,
            invoice_closed_date: record.invoice_closed_date,
            //Assuming the fisrt record is the latest payment
            payment_number: record?.payment_numbers ? record?.payment_numbers[0] : "{paymentNumber}",
            //TODO API call for total payment if payment numbers start coming in - currently all null
            invoice_type_code: record?.invoice_type_code ?? "",
          }),
          ...(variant === "all" && {
            disableCheckbox: false,
            isUnread: undefined,
          }),
        };
      }) ?? [];
    return results;
  };

  const getFilters = (exportInvoice = false, ids?: string[]) => {
    const additionalFilters: Array<Record<string, string>> = [];
    //Apply Past Due Date Filters for different tabs
    let paymentDueDateFilter: Array<string> = [];
    if (userView.current === "late_61_90") {
      paymentDueDateFilter = Utils.createPastDueFilters(DIFF_IN_DAYS.LATE61.FROM, userView.current, DIFF_IN_DAYS.LATE61.TO);
    } else if (userView.current === "late_31_60") {
      paymentDueDateFilter = Utils.createPastDueFilters(DIFF_IN_DAYS.LATE31.FROM, userView.current, DIFF_IN_DAYS.LATE31.TO);
    } else if (userView.current === "late_1_30") {
      paymentDueDateFilter = Utils.createPastDueFilters(DIFF_IN_DAYS.OPEN.FROM, userView.current, DIFF_IN_DAYS.OPEN.TO);
    } else if (userView.current === "future_due") {
      paymentDueDateFilter = Utils.createPastDueFilters(DIFF_IN_DAYS.OPEN.FROM, userView.current);
    } else if (userView.current === "late_91") {
      paymentDueDateFilter = Utils.createPastDueFilters(DIFF_IN_DAYS.LATE91.FROM, userView.current);
    }
    paymentDueDateFilter.forEach((item) =>
      additionalFilters.push({
        searchlightFilter: item,
      })
    );

    //If Credit Memo Table page then apply below filter
    if (location && location.from === "applied-discounts") {
      location.creditMemoIds.forEach((id: string) => {
        additionalFilters.push({
          searchlightFilter: `qa[invoice_id_in][]=${id}`,
        });
      });
    }

    // evaluate select filter on export
    if (exportInvoice && !selectionConfig?.isAllRecordsSelected && ids && ids.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      const { hasAllRecordsSelectedButtonClicked, isAllRecordsSelected, tableRecords } = selectionConfig;

      const exportIds =
        hasAllRecordsSelectedButtonClicked?.current && !isAllRecordsSelected
          ? tableRecords?.allRecords?.filter((item) => !item?.isSelected)?.map((deselectedItem) => deselectedItem?.id)
          : ids;

      exportIds.forEach((id) => {
        const searchlightFilter = `qa[invoice_id_${hasAllRecordsSelectedButtonClicked?.current && !isAllRecordsSelected ? "not_in" : "in"}][]=${id}`;
        additionalFilters.push({
          searchlightFilter,
        });
      });
    }
    const filterQuery = TableUtils.getFilterFromURL(params, viewConfigs[userView.current].table.columns);

    return TableUtils.columnFilterParser(
      filterQuery,
      true,
      viewConfigs[userView?.current]?.preDefinedFilters && additionalFilters
        ? [...viewConfigs[userView.current].preDefinedFilters, ...additionalFilters]
        : []
    );
  };

  /** function will return the final sort query string
   *  added this function to handle multi key and reverse sorting for status column
   *  ex: status desc,payment_due_date asc
   *  and adds null last for last_activity to display all the null values at last
   *  @returns: string
   * */
  const getSortQuery = () => {
    let sortQueryFromUrl = TableUtils.getSortFromURL(params);
    if (!sortQueryFromUrl) {
      sortQueryFromUrl = sortQuery;
    }
    const hasStatusSorting = sortQueryFromUrl?.includes("status");
    const hasLastActivtySorting = sortQueryFromUrl?.includes("last_activity_at");

    const isDesc = sortQueryFromUrl?.includes("desc");

    if (hasStatusSorting) {
      return `${sortQueryFromUrl},${isDesc ? "payment_due_date asc" : "payment_due_date desc"}`;
    } else if (hasLastActivtySorting) {
      return `${sortQueryFromUrl} NULLS LAST`;
    }

    return sortQueryFromUrl;
  };

  const fetchInvoices = async (variant: FetchVariant, columnFilters?: string, pageNumber?: number, pageSize?: number) => {
    const invoiceSortQuery = getSortQuery();
    /**
     * Set page fetch params to ActivityStreamSwitchContext to facilitate page switching
     */
    const fetchParams = {
      pageSize: paginationConfig.page?.pageSize,
      page: paginationConfig.page?.pageNumber,
      order: TableUtils.sortQueryParser(invoiceSortQuery ?? "", true),
      filter: getFilters(),
    };
    setPlatformPageFetchParams(fetchParams as PlatformPageFetchParams);

    const filters = [getFilters(), columnFilters].filter(Boolean).join("&");

    return invoicesClientV2
      .getInvoices(
        pageSize ?? paginationConfig.page?.pageSize,
        pageNumber ?? paginationConfig.page?.pageNumber,
        TableUtils.sortQueryParser(invoiceSortQuery ?? "", true),
        filters
      )
      .then((fetchResult: any) => {
        return { ...fetchResult, records: fetchParser(fetchResult, variant) };
      })
      .catch((error: any) => {
        setError(true);
        setErrorMessage(APIClientUtils.buildErrorMessage(error));
      });
  };

  const fetchInvoicesSummary = async () => {
    const filters = [getFilters(), appliedFilters].filter(Boolean).join("&");
    try {
      return await invoicesClientV2.getSummary(filters, false);
    } catch (error) {
      setSummaryItems([]);
    }
  };

  const queryKey = `transaction-invoice-index-${selectedWorkspace?.workspace_type_route}-${getFilters()}`;

  const { isLoading, data: cachedData } = useQuery(
    [queryKey, paginationConfig.page?.pageNumber, paginationConfig.page?.pageSize, getSortQuery(), appliedFilters],
    async () => await fetchInvoices("all", appliedFilters),
    {
      refetchOnMount: true,
      refetchInterval: RefetchIntervals.twoHoursDelay,
    }
  );

  const { isLoading: isSummaryLoading, data: cachedSummaryData } = useQuery(
    [`transaction-invoice-summary-${getFilters()}`, appliedFilters],
    async () => await fetchInvoicesSummary(),
    {
      refetchOnMount: true,
      refetchInterval: RefetchIntervals.twoHoursDelay,
    }
  );

  useEffect(() => {
    /**
     * update the page number once user perform any action and because of that total records get's changed
     */
    const totalPages = Math.ceil(cachedData?.totalCount / cachedData?.pageSize) ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
    if ((paginationConfig.page?.pageNumber || NUMERIC_VALUES.CONSTANT_ONE) > totalPages) {
      updatePageNumber(totalPages || NUMERIC_VALUES.CONSTANT_ONE);
    }
    setTotalRecords(cachedData?.totalCount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    setTotalPages(totalPages);
    setTotalCount(cachedData?.totalCount);
    setTableData(cachedData?.records ?? []);
  }, [cachedData]);

  useEffect(() => {
    setSummaryItems(
      summaryConfigs?.items?.map((item: SummaryItemMeta) => ({
        value: cachedSummaryData?.data?.[item.accessor] ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
        ...item,
      }))
    );
  }, [cachedSummaryData]);

  /**
   * Function which convert the template string to editor content state.
   *
   * @param {string} templateID The templateID selected by user from the subject drop down.
   * @param {EditorState} EditorState The setEditorState will update the email body.
   */
  const prepareActivityBodyByTemplateID = (
    templateID: string | null,
    setEditorState: React.Dispatch<React.SetStateAction<string>>,
    lang: string,
    setFileCallback: FunctionCall
  ) => {
    if (templateID) {
      setTemplateName(templateID);
      const templateObj = templateData.get(selectedWorkspace?.id)?.get(lang)?.get(templateID);
      setFromTime(templateObj?.getFromTime);
      setToTime(templateObj?.getToTime);
      setEditorState(
        templateObj.parseTemplate({
          customer: selectedInvoice?.customer_name || "",
          vendor: selectedInvoice?.customer_name || "",
          invoiceNumber: selectedInvoice?.invoice_number || "",
          invoiceDate: formatDate(selectedInvoice.invoice_date) || "",
          paymentDueDate: formatDate(selectedInvoice.payment_due_date) || "",
          invoiceAmount: Utils.formatValueAsCurrency(
            selectedInvoice.invoice_amount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            userStatus?.currency?.locale,
            selectedInvoice.transaction_currency_code
          ),
          outstandingBalance: Utils.formatValueAsCurrency(
            selectedInvoice.outstanding_amount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            userStatus?.currency?.locale,
            selectedInvoice.transaction_currency_code
          ),
          emailAddress: loginUserCompany?.emailAddress ?? "",
          companyName: loginUserCompany?.companyName ?? "",
          phone: loginUserCompany?.phoneNumber ?? "",
          paymentNumber: selectedInvoice.payment_number,
          signature: signature?.email_signature || "",
          userName: `${userStatus?.user_name ?? "{Your Name}"}`,
          contactName: primaryContact[0]?.label ?? selectedInvoice?.customer_name,
          workspaceName: `${selectedWorkspace?.name}`,
        })
      );

      if (templateObj.templateAttachment) {
        const templateDataMapper = prepareTemplateDataObject(
          templateObj,
          [{ name: `Invoice #${selectedInvoice.invoice_number ?? "N/A"}`, id: selectedInvoice.id, typeCode: selectedInvoice.invoice_type_code }],
          null,
          null
        );
        handleTemplateAttachment(templateObj, setFileCallback, templateDataMapper);
      }
    }
  };

  /**
   * Function which help's to give template list for the subject drop down
   * based on view type.
   *
   * @param {Object} row The selected row record from table
   * @returns {TemplateDefinitionProps[]} The template definition array of objects.
   */
  const getTemplateDefinitionsByView = (row: InvoiceModelV2, isApproval: boolean): TemplateDefinitionProps[] => {
    let view: viewType;
    const isRecordStatusClosed = row.status === "Closed";

    // create template strings for the AP & AR template names
    const transactionClosed = `${isAPWorkspace ? "AP" : "AR"}_TRANSACTION_${isAPWorkspace ? "BILLS" : "INVOICES"}_CLOSED` as keyof typeof viewType;
    const transaction = `${isAPWorkspace ? "AP" : "AR"}_TRANSACTION_${isAPWorkspace ? "BILLS" : "INVOICES"}` as keyof typeof viewType;
    const approvalRequest = `${isAPWorkspace ? "AP" : "AR"}_APPROVAL_REQUEST_ACTIVITY` as keyof typeof viewType;

    if (isApproval) {
      view = viewType[approvalRequest];
    } else {
      view = isRecordStatusClosed ? viewType[transactionClosed] : viewType[transaction];
    }

    return templateFactory.getTemplateDefinitionsByView(
      view,
      { invoiceNumber: row.invoice_number, customer: row.customer_name, companyName: loginUserCompany.companyName ?? "", vendor: row.customer_name },
      availableLanguagesForTemplates
    );
  };

  /**
   * Download Credit Memo from Index Elipssis
   * Server sends File content (to recieve directly blob FE is sending {responseType: 'blob'} as a config in API)
   * If any error cones from server (because FE initially sends {responseType: 'blob'}) so in error-block response is converted back into JSON
   * And server error is shown
   */
  const onClickDownloadAction = async (row: any) => {
    const toastOptions: ToastOptions = { open: true, severity: "info", message: "Your download will start in a few seconds" };
    try {
      setToastOptions({
        ...toastOptions,
        icon: (
          <IconButton style={{ padding: "0" }}>
            <Download className="download-icon" />
          </IconButton>
        ),
      });
      const response = await downloadTransaction(row.id);
      const uri = URL.createObjectURL(response);
      const fileName = `#${invoiceData?.referenceCode}-${new Date().toDateString()}__${new Date().toLocaleTimeString()}`;
      TableUtils.downloadAll([
        {
          file_name: `CreditMemo-${fileName}`,
          file_url: uri,
        },
      ]);
      setToastOptions({ open: true, severity: "success", message: PDF_DOWNLOAD_SUCCESS });
    } catch (error: any) {
      const errorMessage = getPDFDwnldToastMessage(error.response.status, row?.invoice_type_code);
      setToastOptions({ ...toastOptions, severity: "error", message: errorMessage });
    }
  };

  /**
   * Event handler which triggered when user select the 'email', 'note' or 'phone call'
   * context menu item. It will open the new activity dialog based to the context menu item
   * selected by the user.
   *
   * @param {Object} row The selected row from the table.
   * @param {String} buttonId The buttonId indicate which button has been selected by the user.
   */
  const onClickActionButton = async (row: any, buttonId: string) => {
    //TODO : Need to Implement Credit Memo share funtionality
    setSelectedInvoice(row);
    setRequiredTemplates({
      [ActivityOptions.EMAIL]: getTemplateDefinitionsByView(row, false),
      [ActivityOptions.APPROVAL_REQUEST]: getTemplateDefinitionsByView(row, true),
    });
    await fetchCompanyContactOptions(row.customer_id);
    setNewActivityType(buttonId);
    setShowActivity(true);
  };

  //return {key:value} pair for template subject to parse the values
  const getSelectedTempSubjSnippetValues = () => {
    return {
      invoiceNumber: selectedInvoice.invoice_number ?? "",
      customer: selectedInvoice.customer_name ?? "",
      companyName: loginUserCompany.companyName ?? "",
      vendor: selectedInvoice.customer_name ?? "",
    };
  };

  //return suggested template codes based on certain condition
  const getSuggestedTemplatesCode = () => {
    const isRecordStatusClosed = selectedInvoice.status === "Closed";

    if (newActivityType === ActivityOptions.APPROVAL_REQUEST) {
      return isAPWorkspace
        ? getViewTypeTemplateCodes(viewType.AP_APPROVAL_REQUEST_ACTIVITY)
        : getViewTypeTemplateCodes(viewType.AR_APPROVAL_REQUEST_ACTIVITY);
    }

    if (isAPWorkspace) {
      return isRecordStatusClosed
        ? getViewTypeTemplateCodes(viewType.AP_TRANSACTION_BILLS_CLOSED)
        : getViewTypeTemplateCodes(viewType.AP_TRANSACTION_BILLS);
    } else {
      return isRecordStatusClosed
        ? getViewTypeTemplateCodes(viewType.AR_TRANSACTION_INVOICES_CLOSED)
        : getViewTypeTemplateCodes(viewType.AR_TRANSACTION_INVOICES);
    }
  };

  useEffect(() => {
    // set appropriate template list based on activity type selected
    setSupportedTemplateList(requiredTemplates?.[newActivityType ?? ""] ?? []);
    // rest the selected template code
    setTemplateName("");
  }, [newActivityType]);

  /** enables popup to decide the default template id based on activity
   *  type without maintaining a state inside the component
   **/
  const getDefaultTemplateID = (newActivityType: ActivityOptions | string) => {
    if (newActivityType === ActivityOptions.APPROVAL_REQUEST) {
      return isAPWorkspace ? TemplateTypes.AP_APPROVAL_REQUEST : TemplateTypes.AR_APPROVAL_REQUEST;
    } else {
      return "";
    }
  };

  /**
   * The ellipsis action handlers which used to open the activity dialog.
   */
  const ellipsisActionHandlers: Partial<RowSelectButtons> = {
    "action.newEmail": onClickActionButton,
    "action.newNote": onClickActionButton,
    "action.newPhoneCall": onClickActionButton,
    "action.newApprovalRequest": onClickActionButton,
    "action.download": onClickDownloadAction,
    "action.shareCreditMemo": onClickActionButton,
  };

  /**
   * The ellipsis icons which will be used in lists.
   */
  const ellipsisIconMappings: Partial<RowSelectButtons> = {
    Download: <DownloadCloud />,
    Share: <Share />,
  };

  // 42656
  // check if template code is required as content-type
  const needTemplateCodeAsPayload = React.useMemo(
    () =>
      [
        TemplateTypes.AR_CREATE_CUSTOMER_PROFILE,
        TemplateTypes.AR_UPDATE_CUSTOMER_PROFILE,
        TemplateTypes.AP_CREATE_VENDOR_PROFILE,
        TemplateTypes.AP_CREATE_VENDOR_PROFILE,
      ].includes(templateName as TemplateTypes),
    [templateName]
  );

  const handleSend = async (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    attachmentIds: AttachmentIds,
    inboxAttachments = [] as TransactionItemType[],
    isSendAndClose?: boolean
  ): Promise<ActivityStream> => {
    dispatch({ type: "open", payload: "isSendBtnDisabled" });
    const getEmailArray = (emailArray: To[]) => emailArray.filter((item) => item !== undefined).map((value: To) => value.id || value.label);
    let toastOptions: ToastOptions = { open: true, severity: "success", message: ACTIVITY_SUCCESS_TOAST_MSG };
    const activityTransactions = _.uniq([...inboxAttachments, { transaction_id: selectedInvoice.id, transaction_type: "invoice" }], "transaction_id");

    const payload: Partial<ActivityItemEmailModel> = {
      to: getEmailArray(to),
      cc: getEmailArray(cc),
      bcc: getEmailArray(bcc),
      subject: title,
      content: body,
      email_action: EmailAction.NEW,
      workspace_id: selectedWorkspace?.id || FallbackTypes.Id,
      connection_company_id: selectedInvoice.customer_id ?? "",
      content_type: ActivityFeedMethods.evaluateContentVariantPayload(newActivityType, needTemplateCodeAsPayload ? templateName : ""),
      attachments: attachmentIds.filter((item) => typeof item === "number"),
      activity_type:
        newActivityType && newActivityType == ActivityOptions.PHONE_CALL
          ? ActivityTypeEnum.PhoneCall
          : newActivityType && newActivityType === ActivityOptions.PAYMENT_REMINDER
          ? ActivityTypeEnum.Email
          : newActivityType && newActivityType === ActivityOptions.APPROVAL_REQUEST
          ? ActivityTypeEnum.Approval
          : newActivityType,
      activity_transactions: activityTransactions,
      primary_connection_id: selectedInvoice.customer_id,
      attach_pdf: Utils.isThereAnyAttachment(attachmentIds, activityTransactions),
    };

    return new Promise((resolve) => {
      emailsClientV2
        .post(payload)
        .then(async (emailResponse: ActivityStream) => {
          //Only call Automation API when email is sent successfully and Template is selected
          if (templateName && emailResponse.success) {
            let reqBody = {
              to_time: toTime,
              automation_type: "email_templates",
              automation_sub_type: templateName,
              resource_type: "Activity::Email",
              resource_id: emailResponse.data.id,
            } as AutomateTimeModel;
            if (fromTime != DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
              reqBody = { ...{ from_time: fromTime }, ...reqBody };
            }
            setToTime(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
            automationClientV2.post(selectedWorkspace?.id || FallbackTypes.Id, reqBody);
          }

          TrackingUtils.trackFSData(
            to,
            "Ellipsis",
            "New",
            isSendAndClose ?? false,
            allContactOptions,
            props.configs.routes.resource.invoices,
            url,
            newActivityType ?? "",
            body
          );
          setShowActivity(false);
          //Fetch latest contact (newly added) options to populate in activity pop up if user tries to reply on the same
          getContactsOptions();
          resolve(emailResponse ?? []);
        })
        .catch((err: AxiosError) => {
          toastOptions.severity = "error";
          if (err.response?.status === CONNECTION_STATUS.BAD_REQUEST_400.STATUS_CODE) {
            toastOptions = { ...toastOptions, severity: "error", message: "Messages not sent. Please check that an Email Connector is connected." };
          } else {
            const errorResponse = err.response as AxiosErrorResponseData;
            toastOptions = { ...toastOptions, message: errorResponse?.data?.messages?.errors[0] ?? "Messages not sent." };
          }
        })
        .finally(() => {
          setToastOptions(toastOptions);
          dispatch({ type: "close", payload: "isSendBtnDisabled" });
        });
    });
  };

  const handleSendAndMarkClosed = async (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    attachmentIds: AttachmentIds,
    inboxAttachments: TransactionItemType[]
  ) => {
    const sendEmailResponse = await handleSend(to, cc, bcc, title, body, attachmentIds, inboxAttachments, true);
    if (sendEmailResponse.success) {
      let toastOptions: ToastOptions = { open: true, severity: "success", message: ACTIVITY_COMPLETE_SUCCESS };
      let response = {} as APIResponse;

      try {
        response = await handleClose(sendEmailResponse.data.activity_stream.id, "");
      } catch (e: unknown) {
        response.success = false;
      } finally {
        if (!response.success) {
          toastOptions = {
            ...toastOptions,
            severity: "error",
            message: ACTIVITY_COMPLETE_FAILURE,
          };
        }
        setToastOptions(toastOptions);
      }
    }
  };

  // Handles redirect to Invoice Details page on row click of the Data Grid
  const handleRowClick = (e: MouseEvent<HTMLTableRowElement>, row: { id?: string }, currentPageRecords?: { id: string; isSelected?: boolean }[]) => {
    /**
     * If a row item is already selected, then clicking anywhere on other rows should select those rows, and do not navigate.
     * if double clicked, then navigate to those row records
     */
    const clickType = e.detail;
    if (clickType === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE && selectionConfig.tableRecords?.selectedRecordsIds?.length && row.id) {
      selectionConfig?.onClickTableRow(row?.id, currentPageRecords ?? []);
      return;
    }

    /**
     * Set the following properties into DocumentSwitchContext
     * to facilitate document switching.
     * @param selectedActivityStreamId - currently selected document id
     * @param activityStreamIds - list of documents in the index
     * @param paginationState - current pagination state for reference
     */
    setEnableDocumentSwitch(true);
    setSelectedActivityStreamId({ id: row.id } as ActivityStreamId);
    setActivityStreamIds(tableData.map((item) => ({ id: item.id })));
    setCurrentPaginationState({
      page: paginationConfig.page?.pageNumber ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
      pageCount: paginationConfig.page?.totalPages ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
      pageSize: paginationConfig.page?.pageSize ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
      totalRecords: totalCount,
    });
    setDocumentType("invoice");
    setRootSwitcherStateSnapshot();
    sessionStorage.setItem("lastPath", history.location.pathname);
    history.push(`${workspaceHomePath}/${props.configs.routes.resource.invoices}/${row.id ?? ""}`);
    updateBaseRoute(`${workspaceHomePath}/${props.configs.routes.resource.invoices}/${row.id ?? ""}`);
  };

  const handleExport = async (ids: string[]) => {
    const filters = [getFilters(true, ids), appliedFilters].filter(Boolean).join("&");
    let response: any = {};
    try {
      setExportLoading(true);
      response = await invoicesClientV2.exportInvoices(filters);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setExportData(response);
      setExportLoading(false);
    }
  };

  const handleExportV2 = async (ids: string[]) => {
    const filters = [getFilters(true, ids), initialFilterConfigs?.filterQuery].filter(Boolean).join("&");
    let response: any = {};
    try {
      setExportLoading(true);
      const isExportAllRecords =
        (selectionConfig?.tableRecords?.selectedRecordsIds?.length ?? NUMERIC_VALUES.CONSTANT_ZERO) > NUMERIC_VALUES.CONSTANT_ZERO
          ? selectionConfig.isAllRecordsSelected || !!selectionConfig?.hasAllRecordsSelectedButtonClicked?.current
          : true;
      const export_type = isAPWorkspace ? EXPORT_CATEGORIES.AP_INVOICES : EXPORT_CATEGORIES.AR_INVOICES;

      response = await exportClient.exportRecords(export_type, isExportAllRecords, filters, TableUtils.sortQueryParser(getSortQuery() ?? "", true));
      if (response.success) {
        setToastOptions({ open: true, severity: "success", message: ExportToastMessage.EXPORT_IN_PROGRESS });
        updateExportedRecordsIds([response.data?.export_request_id], false);
      }
    } catch (error) {
      setToastOptions({ open: true, severity: "error", message: ExportToastMessage.EXPORT_FAILED });
    } finally {
      setExportLoading(false);
    }
  };

  const rowSelectBtns: UserAction[] = [
    {
      children: "Export",
      icon: <ExportV2 />,
      loading: exportLoading,
      callback: (ids: string[]) => {
        isExportV2Enabled ? handleExportV2(ids) : handleExport(ids);
      },
      visible: {
        onEllipsis: false,
        onHeader: false,
        onHover: false,
        onCaption: true,
      },
    } as UserAction,
  ];

  useEffect(() => {
    if (
      (exportData?.length ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO &&
      csvInstance &&
      csvInstance.current &&
      csvInstance.current.link
    ) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setExportData([]);
      });
    }
  }, [exportData]);

  const cellRendererMapping: CellRendererMappingObject = {
    "RowDataTypes.BoldTextWithCaption": RowDataTypes.BoldTextWithCaption,
    "RowDataTypes.BoldText": RowDataTypes.BoldText,
    "RowDataTypes.DateEpoch": RowDataTypes.DateEpoch,
    "RowDataTypes.ActionsDropdown": RowDataTypes.ActionsDropdown,
    "RowDataTypes.Currency": RowDataTypes.Currency,
    "RowDataTypes.Status": RowDataTypes.Status,
    "RowDataTypes.DateString": RowDataTypes.DateString,
  };

  //We return Ellipsis actions based on the type of invoice we have on each row
  const getEllipsisActions = (row: string) => {
    const invoiceType = row.includes("Credit Memo") ? "creditMemos" : "invoices";
    return viewConfigs[userView.current].ellipsisActions[invoiceType].map((action: EllipsisAction) => {
      return { ...action, clickFunction: ellipsisActionHandlers[action.handler], icon: ellipsisIconMappings[action.icon ?? ""] };
    });
  };

  const getPreviousSuggestedData = (recentSearchHistory: FilterDropdownSuggestionRef) => {
    const { filterSearchedText } = initialFilterConfigs ?? {};
    const { invoice_number, customer_name } = filterSearchedText ?? {};

    return {
      invoiceNumber:
        invoice_number?.value?.length >= NUMERIC_VALUES.CONSTANT_THREE
          ? recentSearchHistory?.invoice_number?.filter((item) => initialFilterConfigs?.activeFilters?.invoice_number?.ids?.includes(item.id))
          : recentSearchHistory?.invoice_number,
      customerName:
        customer_name?.value?.length >= NUMERIC_VALUES.CONSTANT_THREE
          ? recentSearchHistory?.customer_name?.filter((item) => initialFilterConfigs?.activeFilters?.customer_name?.ids?.includes(item.id))
          : recentSearchHistory?.customer_name,
    };
  };

  //it will return maximum of 5 required formated record for dropdown search suggestion or result data
  const getDropdownSearchFilterData = (activitiesTableData: any) => {
    const requiredColumns = [...(viewConfigs[userView.current]?.table?.columns ?? [])];
    const { filterSearchedText = {}, getSearchSuggestionHistory } = initialFilterConfigs || {};
    const { invoice_number, customer_name } = filterSearchedText;
    const recentInvoicesSearchedData = getSearchSuggestionHistory(
      selectedWorkspace.workspace_type_route?.toLocaleLowerCase() ?? WorkspaceType.AR,
      getViewCategory()
    );
    const previousData = getPreviousSuggestedData(recentInvoicesSearchedData);

    /**
     * if user searching for result then return result data along with previous selected data
     * else return max of 5 recent searched data if that is exist
     */
    const invoiceNumberData =
      !invoice_number?.value || invoice_number?.value.length < NUMERIC_VALUES.CONSTANT_THREE
        ? (previousData?.invoiceNumber ?? []).slice(NUMERIC_VALUES.CONSTANT_ZERO, NUMERIC_VALUES.CONSTANT_FIVE)
        : [...(previousData?.invoiceNumber ?? []), ...(activitiesTableData ?? [])];

    const customerNameData =
      !customer_name?.value || customer_name?.value.length < NUMERIC_VALUES.CONSTANT_THREE
        ? (previousData?.customerName ?? []).slice(NUMERIC_VALUES.CONSTANT_ZERO, NUMERIC_VALUES.CONSTANT_FIVE)
        : [...(previousData?.customerName ?? []), ...(activitiesTableData ?? [])];

    //formatting data for th dropdown suggestion list
    const invoiceNumberDropdownList = invoiceNumberData
      .filter(({ invoice_number, primaryText }) => invoice_number || primaryText)
      .map(({ id, invoice_number, primaryText, invoice_type_code, secondaryText }) => ({
        id,
        primaryText: invoice_number ?? primaryText,
        isAttachemntRequired: false,
        selected: initialFilterConfigs?.activeFilters?.invoice_number?.ids?.includes(id) ?? false,
        secondaryText: TransactionTypes[invoice_type_code?.toLowerCase()] ?? secondaryText,
        isAvatarRequired: false,
      }));

    const customerNameDropdownList = customerNameData
      .filter(({ customer_name, primaryText }) => customer_name || primaryText)
      .map(({ id, customer_name, primaryText }) => ({
        id,
        primaryText: customer_name ?? primaryText,
        isAttachemntRequired: false,
        selected: initialFilterConfigs?.activeFilters?.customer_name?.ids?.includes(id) ?? false,
        secondaryText: "",
        isAvatarRequired: true,
      }));

    /*
     * This code first creates a Map object by mapping each object in the original array to an array with two elements:
     * the key and the value. The Map constructor can then be called with the resulting array of key-value pairs to create
     * a new Map object. Finally, the Array.from method is used to convert the Map object back to an array, where the map
     * function is used to only return the values from the key-value pairs.
     */

    const uniqueInvoiceNumberSuggestions = Array.from(new Map(invoiceNumberDropdownList.reverse().map((obj) => [obj.primaryText, obj])))
      .reverse()
      .map(([, value]) => value);

    const uniqeCustomerNameSuggestions = Array.from(new Map(customerNameDropdownList.reverse().map((obj) => [obj.primaryText, obj])))
      .reverse()
      .map(([, value]) => value);

    return requiredColumns.map((column) => {
      if (column.accessor === "invoice_number") {
        return { ...column, dropdownSearchListItems: uniqueInvoiceNumberSuggestions };
      } else if (column.accessor === "customer_name") {
        return { ...column, dropdownSearchListItems: uniqeCustomerNameSuggestions };
      }
      return column;
    });
  };

  function createFilterQuery(field: string, value: string) {
    if (value && value.length >= NUMERIC_VALUES.CONSTANT_THREE) {
      return `qa[${field}_cont_any]=${encodeURIComponent(value)}`;
    }
    return "";
  }

  /**
   * fetch last_approver for current connection
   */
  const getLastApprover = () => {
    setLastApproverloading(true);
    activitiesClientV2
      .getLastApprover(selectedWorkspace?.id, selectedInvoice.customer_id)
      .then((response) => {
        if (response?.data?.last_approver?.email_address) {
          setLastApprover({
            id: response.data.last_approver.email_address || "",
            label: response.data.last_approver.contact_name || "",
            primary: false,
            connectionId: response.data.last_approver.company_id || "",
            erpContact: false,
            companyId: response.data.last_approver.company_id || "",
          });
        } else {
          setLastApprover({} as To);
        }
      })
      .finally(() => {
        setLastApproverloading(false);
      });
  };

  useEffect(() => {
    if (newActivityType === ActivityOptions.APPROVAL_REQUEST && showActivity) {
      getLastApprover();
    } else {
      setLastApprover({} as To);
    }
  }, [newActivityType, showActivity]);

  useEffect(() => {
    const { filterSearchedText } = initialFilterConfigs || {};
    const { invoice_number, customer_name } = filterSearchedText || {};
    let filters = "";

    if (invoice_number?.value?.length >= NUMERIC_VALUES.CONSTANT_THREE) {
      const invoiceQuery = createFilterQuery("invoice_number", invoice_number.value);
      filters += invoiceQuery;
    }

    if (customer_name?.value?.length >= NUMERIC_VALUES.CONSTANT_THREE) {
      const customerNameQuery = createFilterQuery("customer_name", customer_name.value);
      filters += (filters ? "&" : "") + customerNameQuery;
    }

    setIsColumnsConfigDataLoading(true);

    if (filters) {
      fetchInvoices("all", filters, NUMERIC_VALUES.CONSTANT_ONE, NUMERIC_VALUES.CONSTANT_TWENTY)
        .then((res) => {
          setIsNoSearchResultFound(!res.totalCount);
          setColumnsConfigData(getDropdownSearchFilterData(res.records));
        })
        .catch((error) => {
          setIsNoSearchResultFound(true);
          console.log(error);
        })
        .finally(() => {
          setIsColumnsConfigDataLoading(false);
        });
    } else {
      setColumnsConfigData(getDropdownSearchFilterData([]));
      setIsColumnsConfigDataLoading(false);
    }
  }, [initialFilterConfigs?.filterSearchedText, initialFilterConfigs?.filterQuery]);

  const filteredContacts = [...companyContactOptions, ...allContactOptions];

  //using caching data to update the footer at the same time
  const updatedPaginationObject = {
    ...paginationConfig,
    ...TableUtils.getUpdatePaginationPageState(paginationConfig, cachedData?.totalCount, cachedData?.pageSize),
  };

  return (
    <div className="table-wrapper">
      {summaryConfigs && (
        <SummaryHeader items={summaryItems} isLoading={isSummaryLoading} itemCount={summaryConfigs.items.length} currency={userStatus.currency} />
      )}
      <div className="body-table">
        <Table
          configs={{
            //Added the feature flag for the newly added filters excpet which are already there on Production
            columnConfigs: columnsConfigData?.map((item) => {
              if (
                [
                  "last_activity_at",
                  "base_currency_invoice_amount",
                  "status",
                  "base_currency_outstanding_balance",
                  "payment_due_date",
                  "invoice_closed_date",
                  "action",
                ].includes(item.accessor)
              ) {
                return { ...item, rowDataType: cellRendererMapping[item?.rowDataType] };
              } else {
                return { ...item, rowDataType: cellRendererMapping[item?.rowDataType], enableFilter: isFiltersEnabled };
              }
            }),
            initialTableConfigs: {
              name: userView.current === "applied_credit_memos" ? "Credit Memos" : userView.current === "bills" ? "Bills" : "Invoices",
              type: userView.current === "applied_credit_memos" ? "Credit Memo" : userView.current === "bills" ? "Bill" : "Invoice",
              loading: isLoading,
              footerTableTypeText: userView.current === "applied_credit_memos" ? "Credit Memo" : userView.current === "bills" ? "Bill" : "Invoice",
            },
            paginationConfigs: { ...updatedPaginationObject, enableNavigator: true, enableSummary: true },
            initialSortConfigs: sortConfig,
            selectionConfig: selectionConfig,
            initialFilterConfigs: {
              ...initialFilterConfigs,
              dateFormat: calendarDateFormat,
              isSuggestionDataLoading: isColumnsConfigDataLoading,
              category: getViewCategory(),
              workspace: selectedWorkspace.workspace_type_route?.toLocaleLowerCase() ?? WorkspaceType.AR,
              isNoSearchResultFound,
            },
            currencyConfig: {
              locale: userStatus?.currency?.locale,
              code: userStatus?.currency?.code,
              symbol: userStatus?.currency?.symbol,
            },
          }}
          rows={(cachedData?.records ?? [])?.map((rec: any) => {
            return {
              ...rec,
              read: true,
              last_activity_date: new Date(rec.last_activity_date).getTime() / DEFAULT_NUMERIC_VALUES.DEFAULT_THOUSAND,
              row_actions: getEllipsisActions(rec.invoice_type_code),
            };
          })}
          emptyMessage={{
            title: NO_DATA_MESSAGE,
            caption: emptyCaptionMessage,
          }}
          handlers={{
            onRowClick: handleRowClick,
          }}
          userActions={[...rowSelectBtns]}
          hoverBar={false}
          visibility={{
            exportBtn: false,
            hoverbar: false,
          }}
        />
      </div>
      {showActivity && (
        <NewActivityPopup
          key={`key-add-activity-${newActivityType} loading-${lastApproverloading}`}
          title={BTN_NEW_ACTIVITY}
          open={showActivity}
          contactOptions={filteredContacts}
          defaultTo={
            newActivityType === ActivityOptions.APPROVAL_REQUEST
              ? [lastApprover]
              : !_.isEmpty(primaryContact)
              ? primaryContact
              : !_.isEmpty(companyContactOptions)
              ? [companyContactOptions[0]]
              : []
          }
          handleSend={handleSend}
          isTemplateSupport={newActivityType === ActivityOptions.EMAIL || newActivityType === ActivityOptions.APPROVAL_REQUEST}
          supportedTemplateList={supportedTemplateList}
          defaultTitle={defaultTitle}
          handleSendMarkClosed={handleSendAndMarkClosed}
          onClose={() => setShowActivity(false)}
          activityType={newActivityType}
          setNewActivityType={(type) => {
            setNewActivityType(type);
          }}
          addActivityDropdown={[
            { activityType: "Email", displayName: "Email", icon: <Email /> },
            { activityType: "Note", displayName: "Note", icon: <NoteFill /> },
            { activityType: "Phone Call", displayName: "Phone Call", icon: <Phone /> },
            { activityType: "Approval Request", displayName: "Approval Request", icon: <Pen /> },
          ]}
          setDefaultTitle={setDefaultTitle}
          prepareActivityBodyByTemplateID={prepareActivityBodyByTemplateID}
          fromTime={fromTime}
          toTime={toTime}
          setToTime={setToTime}
          disableSecondaryAction={newActivityType === ActivityOptions.APPROVAL_REQUEST}
          getDefaultTemplateId={getDefaultTemplateID}
          enableKeyboardShortcuts={true}
          loading={lastApproverloading}
          isSendButtonDisabled={visibility.isSendBtnDisabled}
          suggestedTemplatesCode={getSuggestedTemplatesCode()}
          tempSubjectSnippetValues={getSelectedTempSubjSnippetValues()}
        />
      )}
      {(exportData?.length ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO ? (
        <CSVLink data={exportData} filename={`${viewConfigs.exportFileName}`} ref={csvInstance} style={{ display: "none" }} />
      ) : undefined}
    </div>
  );
}

import React from "react";
import { Typography } from "@mui/material";
import { CommandCenterActivitySpam } from "../../../../../library/Icons/Icons";
import { ActivitySubmenuContainer, ActivitySubmenuHeader, ActivitySubmenuContentBody } from "./styled";
import DialogActions from "../../../../../library/DialogActions/DialogActions";
import DialogContent from "../../../../../library/DialogContent/DialogContent";
import DialogContentText from "../../../../../library/DialogContentText/DialogContentText";
import DialogTitle from "../../../../../library/DialogTitle/DialogTitle";
import Button from "../../../../../library/Button/Button";
import { useTranslation } from "react-i18next";

interface SenderSpamViewProps {
  onCloseSubMenu: () => void;
  handleMarkSenderSpam: () => void;
}

const SenderSpamView: React.FC<SenderSpamViewProps> = ({ onCloseSubMenu, handleMarkSenderSpam }) => {
  const { t } = useTranslation();

  return (
    <ActivitySubmenuContainer>
      <ActivitySubmenuHeader>
        <CommandCenterActivitySpam />
        <div className="description">
          <Typography className="title">{t("modalAndFlyout.spamFraudActivity.spamDialogTitle", { ns: "activities" })}</Typography>
          <Typography className="subtitle"></Typography>
        </div>
      </ActivitySubmenuHeader>
      <ActivitySubmenuContentBody>
        <DialogTitle>{t("modalAndFlyout.spamFraudActivity.spamDialogTitle", { ns: "activities" })}</DialogTitle>
        <DialogContent className="mt-ba mb-lg">
          <DialogContentText>{t("modalAndFlyout.spamFraudActivity.spamDialogMessage", { ns: "activities" })}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="primary" onClick={handleMarkSenderSpam}>
            {t("modalAndFlyout.buttons.markSpam", { ns: "activities" })}
          </Button>
          <Button className="ml-auto" variant="secondary" onClick={onCloseSubMenu}>
            {t("modalAndFlyout.buttons.cancel", { ns: "activities" })}
          </Button>
        </DialogActions>
      </ActivitySubmenuContentBody>
    </ActivitySubmenuContainer>
  );
};

export default SenderSpamView;

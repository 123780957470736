import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import useGlobalSearchStyle from "../Styles/useGlobalSearchStyles";
import { SmartText } from "../../library/SmartText";
import { RecentViewedResultInterface } from "../Interface/SearchResultContentInterface";
import { useTranslation } from "react-i18next";
import { EInvoiceStatusChips } from "../../library/AtomicComponents/atomic/EInvoiceStatusChips";

const RecentViewedResults: React.FC<RecentViewedResultInterface> = ({ 
  icon, 
  title,
  eInvoiceStatus,
  categoryIsNotAll, 
  categoryLabel
}) => {
  const { listItem, listItemAvatar, headerAdjust,
    recentViewedPrimaryHeader, recentViewedSecondaryHeader } = useGlobalSearchStyle({});
  const {t, i18n} = useTranslation();
  return (
    <>
      <ListItem className={listItem}>
        <ListItemAvatar>
          <Avatar className={listItemAvatar}>{icon}</Avatar>
        </ListItemAvatar>
        <ListItemText
          disableTypography={true}
          primary={
              <div className={recentViewedPrimaryHeader}>
              <div className={headerAdjust}>
                <div>
                  <SmartText title={title} />  
                </div>
                {eInvoiceStatus && <EInvoiceStatusChips
                  translationObject={{ t, i18n }}
                  chipStatus={eInvoiceStatus}
                  chipConfig={{
                    chipVariant: "outlined",
                    fontVariant: "captionReg",
                    width: 90,
                    height: 16,
                  }}
                  />}
                </div>
              </div>
          }
          secondary={<div className={recentViewedSecondaryHeader}>{categoryIsNotAll && categoryLabel}</div>}
        />
      </ListItem>
      <Divider />
    </>
  );
};

export default RecentViewedResults;

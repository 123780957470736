import { ActivityWindowConfigObj } from "../types/InboxTextbox.types";
// message variants for text box
export enum TextBoxMsgVariants {
  INVALID_ATTACHMENT,
}

// config objects
export const ActivityWindowConfigs: ActivityWindowConfigObj = {
  [TextBoxMsgVariants.INVALID_ATTACHMENT]: {
    variant: "info",
    messageType: TextBoxMsgVariants.INVALID_ATTACHMENT,
  },
};

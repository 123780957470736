import React, { useEffect, useState, memo } from "react";
import { Grid } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { ReactComponent as ImageAttachment } from "../../svg/preview_image_attachment.svg";
import { ReactComponent as OtherAttachment } from "../../svg/preview_other_attachment.svg";
import { ReactComponent as PDFAttachment } from "../../svg/preview_pdf_attachment.svg";
import { ReactComponent as DownloadIcon } from "../../svg/preview_download_icon.svg";
import { ReactComponent as SheetAttachment } from "../../svg/preview_sheet_attachment.svg";
import { ReactComponent as DocumentAttachment } from "../../svg/preview_doc_attachment.svg";
import { ReactComponent as VideoAttachment } from "../../svg/preview_video_attachment.svg";
import { ReactComponent as FolderAttachment } from "../../svg/preview_zip_attachment.svg";
import { NUMERIC_VALUES } from "../../constants/numeric.constants";
import { KEYBOARD_KEYCODE_CONSTANTS } from "../../constants/keyboard.constants";
import { AttachmentPreviewPropTypes } from "./types";
import PDFPreview from "./PDFPreview";
import ImagePreview from "./ImagePreview";
import PreviewUnavailable from "./PreviewUnavailable";
import {
  AttachmentPreviewRoot,
  AttachmentPreviewContainerGrid,
  AttachmentTopbarGrid,
  AttachmentFilePreviewGrid,
  AttachmentFilePreviewGridItem,
  PreviewTopbarLeftContainer,
  PreviewTopbarRightContainer,
  PreviousFileGrid,
  NextFileGrid,
  RenderPropsSubChildContainer,
} from "../styled/styled";
import CircularProgress from "@mui/material/CircularProgress";

const AttachmentPreview = ({
  openPreview,
  activeAttachmentID,
  attachments,
  handleClose,
  onClickDownload,
  isAttachmentsLoading = false,
  renderProps = null,
}: AttachmentPreviewPropTypes): React.ReactElement => {
  const [activeAttachmentIndex, setActiveAttachmentIndex] = useState<number>(NUMERIC_VALUES.CONSTANT_ZERO);

  const getAttachmentIcon = (extension: string) => {
    switch (extension?.toLowerCase()) {
      case "pdf":
      case "application/pdf":
        return <PDFAttachment />;
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
      case "bmp":
      case "tif":
      case "tiff":
        return <ImageAttachment />;
      case "csv":
      case "xlx":
      case "xlsx":
        return <SheetAttachment />;
      case "doc":
      case "docx":
        return <DocumentAttachment />;
      case "zip":
      case "rar":
        return <FolderAttachment />;
      case "mp4":
      case "mkv":
      case "wmv":
        return <VideoAttachment />;
      default:
        return <OtherAttachment />;
    }
  };

  const RenderPropsSubChild = () => <RenderPropsSubChildContainer>{renderProps}</RenderPropsSubChildContainer>;

  const getAttachmentPreview = (extension: string, fileName: string, fileURL: string) => {
    switch (extension?.toLowerCase()) {
      case "pdf":
      case "application/pdf":
        return <PDFPreview fileName={fileName} fileURL={fileURL} />;
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
      case "bmp":
      case "tif":
      case "tiff":
        return <ImagePreview fileName={fileName} fileURL={fileURL} />;
      default:
        return <PreviewUnavailable attachment={attachments[activeAttachmentIndex]} onClickDownload={onClickDownload} />;
    }
  };

  const openPreviousAttachment = (event: React.MouseEvent<SVGSVGElement>) => {
    event.stopPropagation();
    setActiveAttachmentIndex(
      activeAttachmentIndex > NUMERIC_VALUES.CONSTANT_ZERO ? activeAttachmentIndex - NUMERIC_VALUES.CONSTANT_ONE : activeAttachmentIndex
    );
  };

  const openNextAttachment = (event: React.MouseEvent<SVGSVGElement>) => {
    event.stopPropagation();
    setActiveAttachmentIndex(
      activeAttachmentIndex < attachments.length - NUMERIC_VALUES.CONSTANT_ONE
        ? activeAttachmentIndex + NUMERIC_VALUES.CONSTANT_ONE
        : activeAttachmentIndex
    );
  };

  useEffect(() => {
    const activeAttachment = attachments.findIndex((attachment) => attachment.id === activeAttachmentID);
    setActiveAttachmentIndex(activeAttachment ? activeAttachment : NUMERIC_VALUES.CONSTANT_ZERO);
  }, [attachments, activeAttachmentID]);

  const handleEscapeKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Escape" || event.code === "Escape" || (event && event.keyCode === KEYBOARD_KEYCODE_CONSTANTS.ESCAPE_KEY)) {
      handleClose(event);
    }
  };

  useEffect(() => {
    if (openPreview) {
      window.addEventListener("keydown", handleEscapeKeyPress);
    }

    return () => {
      window.removeEventListener("keydown", handleEscapeKeyPress);
    };
  }, [openPreview]);

  return (
    <AttachmentPreviewRoot open={openPreview} onClick={(event) => event.stopPropagation()}>
      <AttachmentPreviewContainerGrid container>
        {isAttachmentsLoading && (
          <Grid item xs={12} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <CircularProgress color="inherit" />
          </Grid>
        )}
        {!isAttachmentsLoading && activeAttachmentIndex >= NUMERIC_VALUES.CONSTANT_ZERO && (
          <>
            <AttachmentTopbarGrid container>
              <Grid item xs={6}>
                <PreviewTopbarLeftContainer>
                  <div onClick={handleClose}>
                    <ChevronLeft />
                  </div>
                  {getAttachmentIcon(attachments[activeAttachmentIndex]?.extension)}
                  <h4 className="filename">{attachments[activeAttachmentIndex]?.file_name}</h4>
                </PreviewTopbarLeftContainer>
              </Grid>
              <Grid item xs={6}>
                <PreviewTopbarRightContainer>
                  <div onClick={(event) => onClickDownload(event, [attachments[activeAttachmentIndex]])}>
                    <DownloadIcon />
                  </div>
                </PreviewTopbarRightContainer>
              </Grid>
            </AttachmentTopbarGrid>
            <AttachmentFilePreviewGrid container alignItems="center" justifyContent="center" onClick={handleClose}>
              <PreviousFileGrid item xs={1}>
                <ChevronLeft
                  fontSize="large"
                  htmlColor={`${activeAttachmentIndex > NUMERIC_VALUES.CONSTANT_ZERO ? "#ffffff" : "#808283"}`}
                  onClick={openPreviousAttachment}
                />
              </PreviousFileGrid>
              <AttachmentFilePreviewGridItem item xs={10}>
                {getAttachmentPreview(
                  attachments[activeAttachmentIndex]?.extension,
                  attachments[activeAttachmentIndex]?.file_name,
                  attachments[activeAttachmentIndex]?.file_url
                )}
              </AttachmentFilePreviewGridItem>
              <NextFileGrid item xs={1}>
                <ChevronRight
                  fontSize="large"
                  htmlColor={`${activeAttachmentIndex < attachments.length - NUMERIC_VALUES.CONSTANT_ONE ? "#ffffff" : "#808283"}`}
                  onClick={openNextAttachment}
                />
              </NextFileGrid>
            </AttachmentFilePreviewGrid>
            {renderProps && <RenderPropsSubChild />}
          </>
        )}
      </AttachmentPreviewContainerGrid>
    </AttachmentPreviewRoot>
  );
};

export default memo(AttachmentPreview);

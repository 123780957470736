import { styled } from "@mui/system";
import Popover from "../../../../atomic/Popover/Popover";

export const StatusPopupContainer = styled(Popover)({
  ".MuiPaper-root": {
    marginTop: "1.25rem",
    boxShadow: "0 0.25rem 0.5rem rgba(0, 0, 0, 0.12)",
    backgroundColor: "#FFFFFF",
    padding: "0.5rem",
    minWidth: "11.1875rem",
    width: "fit-content",
    ".MuiMenuItem-root": {
      fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif`,
      fontWeight: "500",
      fontSize: "0.75rem",
      lineHeight: "1rem",
      justifyContent: "flex-start",
      letterSpacing: "0.025rem",
    },
  },
});

const statusChipContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  height: "1.5rem",
  borderRadius: "1rem",
  padding: "0.25rem 0.5rem",
});

export const OpenItem = styled(statusChipContainer)({
  background: "#FFFFFF",
  border: "0.03125rem solid #24b14b",
  color: "#24b14b",
});

export const FutureDueItem = styled(statusChipContainer)({
  background: "#edfffa",
  border: "0.03125rem solid #79eacd",
  color: "#1db08a",
});
export const CompletedItem = styled(statusChipContainer)({
  background: "#f2f3f8",
  border: "0.03125rem solid #000000",
  color: "#143b52",
});
export const PastDue30Item = styled(statusChipContainer)({
  background: "#f0fbff",
  border: `0.03125rem solid #80C2D8`,
  color: "#1F91B7",
});
export const PastDue60Item = styled(statusChipContainer)({
  background: "#F7FFFB",
  border: "0.03125rem solid #1fb6c1",
  color: "#1fb6c1",
});
export const PastDue90Item = styled(statusChipContainer)({
  background: "#fffdf9",
  border: "0.03125rem solid #f4bd7c",
  color: "#f38923",
});
export const PastDueMoreThan90Item = styled(statusChipContainer)({
  background: "#fff8f9",
  border: "0.03125rem solid #ef3c6b",
  color: "#F37391",
});
export const WaitingForResponse = styled(statusChipContainer)({
  background: "#fffdf9",
  border: "0.03125rem solid #f4bd7c",
  color: "#f38923",
});
export const Snoozed = styled(statusChipContainer)({
  background: "#effbff",
  border: "0.03125rem solid #79aebf",
  color: "#126b88",
});

import React from "react";
import { EmailTemplate as EmailTemplateIcon } from "../Icons/Icons";
import { EmailTemplateComponentProps } from "./types/email.template.popover.types";
import EmailTemplatePopover from "./tsx/EmailTemplatePopover";
import { IconButton } from "@mui/material";

const EmailTemplateComponent: React.FC<EmailTemplateComponentProps> = ({ onClickEmailTemplateIcon, hasTemplatedDisabled, ...restProps }) => {
  return (
    <div className="email-template-wrapper">
      {/** Popover Component*/}
      <EmailTemplatePopover {...restProps} />

      {/** Email Icon Component*/}
      {!hasTemplatedDisabled && (
        <IconButton className={restProps?.popoverRootProps.isModalOpen ? "container-selected" : "container"} onClick={onClickEmailTemplateIcon}>
          <EmailTemplateIcon />
        </IconButton>
      )}
    </div>
  );
};

export default EmailTemplateComponent;

import React from "react";
import { TableCaptionContainer, MenuItemContainer, MenuContainer } from "./caption.styles";
import { Typography } from "@mui/material";
import ExportRecords from "./ExportRecords";
import { TableDataContext, TableDataProviderType } from "../../contexts/TableDataContext";
import { HeaderButton, HeaderIconButton } from "../Body/button.styles";
import { Ellipses } from "../../../../Icons/Icons";
import { NUMERIC_VALUES } from "../../../constants/numeric.constants";
import CustomTooltip from "../../../CustomTooltip/CustomTooltip";
import { useTranslation } from "react-i18next";

const TableCaption: React.FC = () => {
  const { selectionConfigs, tableConfigs, paginationConfigs, userActions, visibility } = React.useContext(TableDataContext) as TableDataProviderType;
  const [actionTooltipVisible, setActionTooltipVisible] = React.useState<boolean>(false);
  const [paginationMenuAnchorEl, setPaginationMenuAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const { isAllRecordsSelected, getSelectedRowsCount } = selectionConfigs;
  const selectedCount = getSelectedRowsCount();
  const { t } = useTranslation();

  const getHeaderCaptionText = () => {
    const totalRecordsCount = paginationConfigs?.page?.totalRecords ?? NUMERIC_VALUES.CONSTANT_ZERO;
    const allRecordselectedText =
      selectedCount > NUMERIC_VALUES.CONSTANT_ONE
        ? t("captions.allRecordsSelected.multiRecordText", { count: selectedCount, tableType: tableConfigs.type, ns: "table" })
        : t("captions.allRecordsSelected.singleRecordText", { count: selectedCount, tableType: tableConfigs.type, ns: "table" });
    const partialRecordselectedText =
      selectedCount > NUMERIC_VALUES.CONSTANT_ONE
        ? t("captions.partialRecordsSelected.multiRecordText", { count: selectedCount, tableType: tableConfigs.type, ns: "table" })
        : t("captions.partialRecordsSelected.singleRecordText", { count: selectedCount, tableType: tableConfigs.type, ns: "table" });
    const noRecordselectedText =
      totalRecordsCount > NUMERIC_VALUES.CONSTANT_ONE || totalRecordsCount === NUMERIC_VALUES.CONSTANT_ZERO
        ? t("captions.totalRecords.multiRecordText", { count: totalRecordsCount, tableType: tableConfigs.type, ns: "table" })
        : t("captions.totalRecords.singleRecordText", { count: totalRecordsCount, tableType: tableConfigs.type, ns: "table" });

    if (isAllRecordsSelected) {
      return selectedCount >= NUMERIC_VALUES.CONSTANT_ONE ? allRecordselectedText : "";
    } else if (selectedCount > NUMERIC_VALUES.CONSTANT_ZERO) {
      return partialRecordselectedText;
    } else {
      return tableConfigs?.loading ? "" : noRecordselectedText;
    }
  };

  return (
    <TableCaptionContainer
      className={`table-caption-container ${
        selectedCount > NUMERIC_VALUES.CONSTANT_ZERO && !tableConfigs?.loading ? "table-caption-selected-view" : ""
      }`}
    >
      <div className="table-caption-wrapper">
        {tableConfigs?.loading && paginationConfigs.page?.pageNumber === NUMERIC_VALUES.CONSTANT_ONE && (
          <Typography variant="body1">&nbsp;</Typography>
        )}
        {!(tableConfigs?.loading && paginationConfigs.page?.pageNumber === NUMERIC_VALUES.CONSTANT_ONE) && (
          <>
            <Typography variant="body1">{getHeaderCaptionText()}</Typography>
            {/* export is going to controlled with a boolean, if all all record selected true then no need to pass any ids
             * other action can be enabled with an array of input called - HeaderActions[]
             */}
            <div className="user-actions">
              {userActions.map(
                (action) =>
                  action.visible.onCaption &&
                  (action.children ? (
                    <CustomTooltip title={action?.tooltipLabel ?? ""} placement="top-start">
                      <HeaderButton
                        className={`table-caption-action-button caption-button ${
                          selectionConfigs?.tableRecords?.selectedRecordsIds?.length > NUMERIC_VALUES.CONSTANT_ZERO ? "" : "inactive"
                        }`}
                        variant="contained"
                        disableFocusRipple={true}
                        disableRipple={true}
                        startIcon={action.icon}
                        onClick={() =>
                          action.callback(selectionConfigs?.isAllRecordsSelected ? [] : selectionConfigs.tableRecords?.selectedRecordsIds ?? [])
                        }
                      >
                        {action.children ? (
                          action.icon ? (
                            <Typography variant="body1">{action.children}</Typography>
                          ) : (
                            <Typography variant="inherit">{action.children}</Typography>
                          )
                        ) : null}
                      </HeaderButton>
                    </CustomTooltip>
                  ) : (
                    <CustomTooltip title={action?.tooltipLabel ?? ""} placement="top-start">
                      <HeaderIconButton
                        className="table-caption-active-action-icon-button header-icon-button"
                        disableFocusRipple={true}
                        disableRipple={true}
                        onClick={() => action.callback(selectionConfigs.tableRecords?.selectedRecordsIds ?? [])}
                      >
                        {action.icon}
                      </HeaderIconButton>
                    </CustomTooltip>
                  ))
              )}

              {selectedCount > NUMERIC_VALUES.CONSTANT_ZERO && (
                <>
                  {userActions.map(
                    (action) =>
                      !action.visible.onCaption &&
                      action.visible.onHeader &&
                      (action.children ? (
                        <CustomTooltip title={action?.tooltipLabel ?? ""} placement="top-start">
                          <HeaderButton
                            className="table-caption-active-action-button header-button"
                            variant="contained"
                            disableFocusRipple={true}
                            disableRipple={true}
                            startIcon={action.icon}
                            onClick={() => action.callback(selectionConfigs.tableRecords?.selectedRecordsIds ?? [])}
                          >
                            {action.children ? (
                              action.icon ? (
                                <Typography variant="body1">{action.children}</Typography>
                              ) : (
                                <Typography variant="inherit">{action.children}</Typography>
                              )
                            ) : null}
                          </HeaderButton>
                        </CustomTooltip>
                      ) : (
                        <CustomTooltip title={action?.tooltipLabel ?? ""} placement="top-start">
                          <HeaderIconButton
                            className="table-caption-active-action-icon-button header-icon-button"
                            disableFocusRipple={true}
                            disableRipple={true}
                            onClick={() => action.callback(selectionConfigs.tableRecords?.selectedRecordsIds ?? [])}
                          >
                            {action.icon}
                          </HeaderIconButton>
                        </CustomTooltip>
                      ))
                  )}
                  {/* ellipsis actions */}
                  {userActions.filter((action) => action.visible.onEllipsis).length > NUMERIC_VALUES.CONSTANT_ZERO && (
                    <>
                      <MenuContainer
                        open={actionTooltipVisible}
                        onClose={() => setActionTooltipVisible(false)}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        anchorEl={paginationMenuAnchorEl}
                      >
                        {userActions
                          .filter((action) => action.visible.onEllipsis)
                          .map((item, index) => {
                            return (
                              <MenuItemContainer
                                key={index}
                                onClick={() => item.callback(selectionConfigs.tableRecords?.selectedRecordsIds)}
                                id={"x"}
                              >
                                <Typography variant="body1Reg">{item.tooltipLabel}</Typography>
                              </MenuItemContainer>
                            );
                          })}
                      </MenuContainer>

                      <HeaderIconButton
                        disabled={false}
                        onClick={(e: any) => {
                          setPaginationMenuAnchorEl(e.currentTarget);
                          setActionTooltipVisible((prevVal) => !prevVal);
                        }}
                      >
                        <Ellipses />
                      </HeaderIconButton>
                    </>
                  )}
                </>
              )}
              {visibility.exportBtn && <ExportRecords />}
            </div>
          </>
        )}
      </div>
    </TableCaptionContainer>
  );
};

export default TableCaption;

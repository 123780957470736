import React, { useState } from "react";
import { DEFAULT_NUMERIC_VALUES } from "../constants/NumericConstants";
import { AppContext } from "./AppContext";
import { settingsClient } from "../db/version2Accessor";
import { CONNECTION_STATUS } from "../constants/ConnectionConstants";
import { AlertContext } from "./AlertContext";

export const SettingsContext = React.createContext<SettingsType | null>(null);

export interface SettingsProviderProps {
  children: (React.ReactNode & { type: string })[] | (React.ReactNode & { type: string });
}

const SettingsProvider: React.FC<SettingsProviderProps> = ({ children }: SettingsProviderProps) => {
  const { userStatus } = React.useContext(AppContext) as AppType;
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const [isCopilotEnabled, setIsCopilotEnabled] = React.useState<boolean>(true);

  // State needed for Settings pages, hoisted up to pass to the header.
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [syncHasUpdated, setSyncHasUpdated] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [lastSync, setLastSync] = useState<LastSyncsState>({
    syncRequestId: "",
    groupKey: "",
    statusCode: "",
    processResultMessage: "",
    appEnrollmentId: "",
    created: "",
    modified: "",
    modifiedUserId: "",
    details: null,
  });
  /**
   * this limits the scope of the ap automation mail state to settings.
   * So, that while writing updates for the same, we could reduce
   * unnecessary re-renders by not updating userStatus global state.
   *
   * Note: writing in this context also means that userStatus could have stale
   * ap_automation_email data. But that does not affect the application as that
   * is only being used to load intial server data and update requests will be
   * write-through i.e. state here will be update first!
   */
  const [apAutomationMail, setApAutomationMail] = useState<string>(userStatus.ap_automation_email || "");

  /**
   * refreshes the state of ap automation email, which
   * updates the UI for the settings.
   */
  React.useEffect(() => {
    setApAutomationMail(userStatus.ap_automation_email ?? "");
  }, [userStatus.ap_automation_email]);

  /**
   * GET on /api/v2/account-user/settings
   */
  async function initialCopilotSettings(): Promise<any> {
    return settingsClient
      .getCopilotSettings()
      .then((response) => {
        setIsCopilotEnabled(response?.data?.suggested_response_setting ?? true);
      })
      .catch(() => {
        setIsCopilotEnabled(isCopilotEnabled);
      });
  }

  /**
   * PATCH on /api/v2/account-user/
   */
  async function updateCopilotSettings(enableCopilot: boolean): Promise<any> {
    let toastOptions: ToastOptions = {
      open: false,
      severity: "success",
      message: "",
    };
    return settingsClient
      .copilotSettingsPatch({ suggested_response_setting: enableCopilot })
      .then((status) => {
        if (status === CONNECTION_STATUS.ACCEPTED_204.STATUS_CODE) {
          toastOptions = {
            ...toastOptions,
            open: true,
            message: `Suggested response has been ${enableCopilot ? "enabled" : "disabled"}.`,
          };
        }
        setIsCopilotEnabled(enableCopilot);
      })
      .catch((error) => {
        toastOptions = {
          ...toastOptions,
          open: true,
          severity: "error",
          message: "Network error, please try after some time.",
        };
        setIsCopilotEnabled(!enableCopilot);
        console.log(error);
      })
      .finally(() => {
        setToastOptions(toastOptions);
      });
  }

  return (
    <SettingsContext.Provider
      value={{
        tableData,
        setTableData,
        setSyncHasUpdated,
        syncHasUpdated,
        setLastSync,
        lastSync,
        apAutomationMail,
        setApAutomationMail,
        initialCopilotSettings,
        updateCopilotSettings,
        isCopilotEnabled,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;

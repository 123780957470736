import AP from "./AP.json";
import AR from "./AR.json";
import AW from "./AW.json";
import E from "./E";

export interface GSConfigInterface {
  [key: string]: any;
}

export const globalSearchConfigGetter = (workspaceType: string) => {
  const globalSearchConfig: GSConfigInterface = Object.freeze({
    AP: AP,
    AR: AR,
    AW: AW,
    E: E(),
  });

  return globalSearchConfig[workspaceType];
};

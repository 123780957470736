import { StatusChips } from "../../../atomic/StatusChips";
import { ActivityStatusChips } from "../../../atomic/ActivityStatusChips";
import { EInvoiceStatusChips } from "../../../atomic/EInvoiceStatusChips";
import { EInvoiceDaysStatusChips } from "../../../atomic/EInvoiceDaysStatusChips";
import { SnackbarTypes } from "../../../Snackbar/tsx/constants";
import { EInvoiceAttachemnts } from "../../../atomic/EInvoiceAttachemnts";
import {
  ActionButtonWrapper,
  ActionContainer,
  FilesCountContainer,
  SubjectAttachmentWrapper,
  TableFormattedCell,
  ContactWrapper,
  TextWithCaption,
  ElipsisActionWithIcon,
} from "./cell.styles";
import { CellRendererType } from "../../types/table.types";
import { DEFAULT_VALUES } from "../../../constants/global.constants";
import { DEFAULT_NUMERIC_VALUES } from "../../../constants/numeric.constants";
import { TransactionTypes } from "../../../constants/string.constants";
import { RowDataTypes } from "../../../constants/table.constants";
import { TooltipTitles } from "../../../constants/tooltip.constants";
import { Tooltip as MuiTooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";
import DownloadCloud from "../../../svg/download_cloud.svg";
import DownloadIcon from "../../../svg/download_document.svg";
import ExportIcon from "../../../svg/export_icon.svg";
import { ReactComponent as ActivityFlag } from "../../../svg/activity_flag.svg";
import { formatCurrency } from "../../../utils/currency";
import { formatEpoch } from "../../../utils/date";
import { filesFormattedLabel, formatExportName, formatRowDataForExport } from "../../../utils/table";
import React, { MouseEvent, useState } from "react";
import { CSVLink } from "react-csv";
import _ from "underscore";
import { NUMERIC_VALUES } from "../../../constants/numeric.constants";
import { ReactComponent as CSVAttachment } from "../../../svg/csv_attachment.svg";
import { ReactComponent as FileAttachment } from "../../../svg/file_attachment.svg";
import { ReactComponent as VideoAttachment } from "../../../svg/video_attachment.svg";
import { ReactComponent as DocAttachment } from "../../../svg/doc_attachment.svg";
import { ReactComponent as OtherAttachment } from "../../../svg/other_attachment.svg";
import { ReactComponent as PDFAttachment } from "../../../svg/pdf_attachment.svg";
import { ReactComponent as ImageAttachment } from "../../../svg/image_attachment.svg";
import { ReactComponent as SnoozedIndex } from "../../../svg/snoozed_index.svg";
import { ReactComponent as Ellipsis } from "../../../svg/ellipsis.svg";
import { ReactComponent as PrimaryContact } from "../../../svg/primary_contact.svg";
import { ReactComponent as UserPic } from "../../../svg/user_pic.svg";
import { DateTime } from "luxon";
import CustomTooltip from "../../../CustomTooltip/CustomTooltip";
import useLocale from "../../../../../../hooks/useLocale";
import { makeStyles } from "@mui/styles";
import { ClickAwayListener } from "@mui/material";
import MenuItem from "../../../atomic/MenuItem/MenuItem";
import { AttachmentPreview } from "../../../AttachmentPreview";
import { CompanyTypeChip } from "../../../atomic/CompanyChip";
import { useTranslation } from "react-i18next";
import usePhoneNumberUtilities from "../../../../../../hooks/usePhoneNumberUtilities";
import { ActivityStatusChipsComponentType } from "../../../atomic/EInvoiceStatusChips/activitystatuschips.types";

export const flagScheme: Record<StreamFlag, { [key: string]: string }> = {
  new_vendor: {
    title: "New Vendor",
    color: "purple",
  },
  new_bill: {
    title: "New Bill",
    color: "green",
  },
  bill: {
    title: "Bill",
    color: "orange",
  },
  existing_bill: {
    title: "Existing Bill",
    color: "orange",
  },
};

const INTERVAL = 300;

const Cell: React.FC<CellRendererType> = ({
  actionHover,
  colConfig,
  rowData,
  view,
  value,
  type,
  secondaryValue,
  extraHandlers,
  secondaryRowActions,
  currencyConfig,
  dateFormat,
}: CellRendererType) => {
  const [downloading] = React.useState<boolean>(false);
  const { formatDateBasedOnCountry: formatDate } = useLocale();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const { phoneUtil, PNF, locale } = usePhoneNumberUtilities();

  /**
   * Funtion to format the Phone number as per user locale
   * @returns a formmated string of Phone Number or Empty State in International format
   */
  const formatPhoneNumber = (number: string): string => {
    return number === "-" ? "-" : phoneUtil.format(phoneUtil.parse(number ?? "", locale), PNF.INTERNATIONAL);
  };

  const useStyles = makeStyles(() => ({
    tooltip: {
      background: "white !important",
      margin: "0 !important",
      padding: "0 !important",
      borderRadius: "0.125rem",
      boxShadow: "0 0.5rem 0.75rem rgba(0, 0, 0, 0.1), 0 0.25rem 1.25rem rgba(0, 0, 0, 0.1)",
    },
  }));

  const classes = useStyles();

  const formatAction = (value: any, listValue: any[]): React.ReactElement => {
    if (!listValue.length) {
      return <></>;
    }
    const handleTooltipToggle = (e: React.SyntheticEvent, toggleTooltip: boolean) => {
      e.stopPropagation();
      setIsTooltipOpen(!toggleTooltip);
    };

    const generateMenuList = (menuList: Array<any>, ClickValue: any = {}) => {
      return menuList.map((item, index) => {
        return (
          <MenuItem
            key={index}
            onClick={(e) => {
              e.stopPropagation();
              if (item.clickFunction) {
                item.clickFunction(ClickValue, item.id);
              }
            }}
            id={item.id}
          >
            {item.icon ? (
              <ElipsisActionWithIcon>
                {item.icon} {item.value}
              </ElipsisActionWithIcon>
            ) : (
              item.value
            )}
          </MenuItem>
        );
      });
    };

    const handleTooltipClose = () => {
      setIsTooltipOpen(false);
    };

    return (
      <ClickAwayListener onClickAway={handleTooltipClose} mouseEvent="onMouseUp">
        <MuiTooltip title={generateMenuList(listValue, value)} classes={{ tooltip: classes.tooltip }} placement="bottom-start" open={isTooltipOpen}>
          <div className="action-handler" onClick={(e) => handleTooltipToggle(e, isTooltipOpen)}>
            <Ellipsis />
          </div>
        </MuiTooltip>
      </ClickAwayListener>
    );
  };

  const Tooltip = withStyles({
    tooltip: {
      backgroundColor: "#143b52",
      fontFamily:
        '"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
      fontWeight: "500",
      fontSize: "0.75rem",
      lineHeight: "1rem",
      color: "#ffffff",
      fontStyle: "normal",
      padding: "0.5rem",
      borderRadius: "0.125rem",
      margin: "0.125rem",
      minWidth: "fit-content",
      maxWidth: "18.75rem",

      "& .MuiTooltip-arrow::before": {
        color: "#143b52",
      },
      "& .MuiTooltip-tooltipPlacementBottom": {
        color: "#143b52",
      },
    },
    popper: {
      "& .MuiTooltip-tooltipPlacementBottom": {
        marginTop: "0.875rem !important",
      },
      "& .MuiTooltip-tooltipPlacementTop": {
        marginBottom: "0.875rem !important",
      },
      "& .MuiTooltip-tooltipPlacementLeft": {
        marginRight: "0.875rem !important",
      },
      "& .MuiTooltip-tooltipPlacementRight": {
        marginLeft: "0.875rem !important",
      },
    },
  })(MuiTooltip);

  /**
   * This function creates a downloadable link and triggers a click on a url iterable,
   * one by one and simulates an organic user click by using a stack
   *
   * Extended to provide toast if toastOptions dispatcher is provided as input.
   * @param files - file url array
   * @param setToastOptions toast options state dispatcher
   */
  const downloadAll = (files: any[]): void => {
    //eslint-disable-next-line
    let asyncDownload = setInterval(download, INTERVAL, files);
    function download(files: any[]) {
      const file = files.pop();
      const link = document.createElement("a");
      link.download = file?.file_name ?? "file";
      link.href = file?.file_url;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      link.remove();
      if (files.length === DEFAULT_VALUES.DEFAULT_NUMBER) {
        clearInterval(asyncDownload);
        return;
      }
    }
  };

  const onClickShowMore = () => {
    // setShowAttachmentTab(true);
  };

  const [openPreview, setOpenPreview] = React.useState<boolean>(false);
  const [activeAttachmentID, setActiveAttachmentID] = React.useState<string | number>("");

  const onClickPreviewClose = (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement> | KeyboardEvent) => {
    event.stopPropagation();
    setOpenPreview(false);
  };

  const onClickPreviewOpen = (event: MouseEvent<HTMLDivElement>, activeAttachmentID: string | number) => {
    event.stopPropagation();
    setActiveAttachmentID(activeAttachmentID);
    setOpenPreview(!openPreview);
  };

  const onClickDownload = (
    event: MouseEvent<HTMLTableRowElement | HTMLDivElement | HTMLButtonElement>,
    attachments: AttachmentItem[] | BlobAttachmentItem[]
  ) => {
    event.stopPropagation();
    extraHandlers?.setSnackbarConfig({
      open: true,
      text: "Downloading attachment...",
      type: SnackbarTypes.Success,
    });
    downloadAll(attachments);
  };

  const AttachmenChip: React.FC<{ attachment: any }> = ({ attachment }) => {
    const getAttachmentIcon = (extension: string) => {
      switch (extension) {
        case "pdf":
          return <PDFAttachment />;
        case "doc":
          return <DocAttachment />;
        case "zip":
          return <FileAttachment />;
        case "csv":
        case "xlss":
          return <CSVAttachment />;
        case "mp4":
        case "mov":
        case "wmv":
        case "avi":
        case "avchd":
        case "flv":
        case "f4v":
        case "swf":
        case "mkv":
        case "webm":
        case "html5":
        case "mpeg2":
        case "gif":
          return <VideoAttachment />;
        case "jpg":
        case "jpeg":
        case "png":
        case "tiff":
        case "psd":
        case "eps":
        case "ai":
        case "indd":
        case "raw":
          return <ImageAttachment />;
        default:
          return <OtherAttachment />;
      }
    };

    return (
      <CustomTooltip title={TooltipTitles.CLICK_TO_PREVIEW}>
        <div className="attachment-chip" onClick={(event: MouseEvent<HTMLDivElement>) => onClickPreviewOpen(event, attachment.id)}>
          <>
            {getAttachmentIcon(attachment.extension)} <span className="attachment-text">{attachment.file_name}</span>
          </>
        </div>
      </CustomTooltip>
    );
  };

  /**
   * Helper component to render chip button
   * @param param0
   * @returns
   */
  const ShowMoreChip: React.FC<{ restCount: number }> = ({ restCount }) => {
    return (
      <CustomTooltip title={"Show More"}>
        <div className="attachment-show-more-chip" onClick={() => onClickShowMore()}>
          <span className="attachment-text">{`${restCount} More`}</span>
        </div>
      </CustomTooltip>
    );
  };

  const calcDaysBetween = (date: string | null): number | null => {
    if (!date) {
      return null;
    }
    const parsedDate = DateTime.fromFormat(date, "yyyy-MM-dd");
    if (!parsedDate.isValid) {
      return null;
    } else {
      return Math.floor(-parsedDate.diffNow("days").days);
    }
  };

  const formatDaysLate = (date: string): string => {
    const daysLate = Math.abs(calcDaysBetween(date) ?? DEFAULT_NUMERIC_VALUES.ZERO);
    return daysLate ? `${daysLate.toString()} ${daysLate === DEFAULT_NUMERIC_VALUES.ONE ? "day" : "days"}` : "-";
  };

  const getFileAndFileCounts = (value: string) => {
    const isTooltipRequired = !(value === "-" || value === "N/A");
    const fileArray = value as any as Array<string>;
    const fileArrayString = isTooltipRequired ? fileArray.join(",\n").toString() : "";
    return (
      <CustomTooltip title={`${isTooltipRequired && fileArray.length > NUMERIC_VALUES.CONSTANT_ONE ? fileArrayString : ""}`}>
        <Typography
          sx={{ wordBreak: "break-word", display: "inline-block", whiteSpace: "pre-line" }}
          noWrap={false}
          variant={fileArray.length && fileArray.length >= NUMERIC_VALUES.CONSTANT_ZERO ? "body1" : "body1Reg"}
          className={fileArray.length && fileArray.length >= NUMERIC_VALUES.CONSTANT_ZERO ? "files-label-active" : "files-label"}
        >
          {fileArray.length > NUMERIC_VALUES.CONSTANT_ONE ? filesFormattedLabel(fileArray.length) : fileArray[NUMERIC_VALUES.CONSTANT_ZERO]}
        </Typography>
      </CustomTooltip>
    );
  };

  /*
   * Cell Renderer for Row Data
   */
  const getFormattedValue = () => {
    let snoozedUntill;
    if (type == RowDataTypes.DateEpochSnoozed) {
      snoozedUntill = value ? DateTime.fromISO(formatEpoch(value as string), { zone: "utc" }).toRelative() : "-";
    }
    switch (type) {
      case RowDataTypes.BoldText:
        return (
          <Typography sx={{ wordBreak: "break-word", display: "inline-block", whiteSpace: "pre-line" }} noWrap={false} variant="body1Reg">
            {value}
          </Typography>
        );
      case RowDataTypes.BoldTextWithCaption:
        return (
          <TextWithCaption>
            <Typography sx={{ wordBreak: "break-word", display: "inline-block", whiteSpace: "pre-line" }} noWrap={false} variant="body1Reg">
              {value}
            </Typography>
            <Typography sx={{ wordBreak: "break-word", display: "inline-block", whiteSpace: "pre-line" }} noWrap={false} variant="caption">
              {TransactionTypes[`${secondaryValue?.toString?.()?.toLowerCase()}`]}
            </Typography>
          </TextWithCaption>
        );
      case RowDataTypes.Currency:
        return (
          <Typography sx={{ wordBreak: "break-word", display: "inline-block", whiteSpace: "pre-line" }} noWrap={false} variant="body1Reg">
            {formatCurrency(value as number, false, "", {} as any, currencyConfig?.locale, currencyConfig?.code)}
          </Typography>
        );
      case RowDataTypes.CurrencyWithTypeCheck:
        return (
          <Typography sx={{ wordBreak: "break-word", display: "inline-block", whiteSpace: "pre-line" }} noWrap={false} variant="body1Reg">
            {formatCurrency(value as number, true, secondaryValue as string)}
          </Typography>
        );
      case RowDataTypes.DateString: {
        const renderValue = (value: string) => {
          if (value === "-" || value === "N/A" || value === "") {
            return "-";
          }
          return formatDate(value);
        };

        return (
          <Typography sx={{ wordBreak: "break-word", display: "inline-block", whiteSpace: "pre-line" }} noWrap={false} variant="body1Reg">
            {renderValue(value as string)}
          </Typography>
        );
      }
      case RowDataTypes.ActionsDropdown:
        return (
          <Typography sx={{ wordBreak: "break-word", display: "inline-block", whiteSpace: "pre-line" }} noWrap={false} variant="body1Reg">
            {/* <Ellipsis /> */}
            {formatAction(rowData, secondaryRowActions)}
          </Typography>
        );
      case RowDataTypes.ContactName:
        return (
          <Typography sx={{ wordBreak: "break-word", display: "inline-block", whiteSpace: "pre-line" }} noWrap={false} variant="body1Reg">
            <ContactWrapper>
              <div>{secondaryValue ? <PrimaryContact /> : <UserPic />}</div>
              {value}
            </ContactWrapper>
          </Typography>
        );
      case RowDataTypes.Phone:
        return (
          <Typography sx={{ wordBreak: "break-word", display: "inline-block", whiteSpace: "pre-line" }} noWrap={false} variant="body1Reg">
            {formatPhoneNumber(value as string)}
          </Typography>
        );
      case RowDataTypes.Number:
        if (value == "N/A" || value == "-") {
          return (
            <Typography sx={{ wordBreak: "break-word", display: "inline-block", whiteSpace: "pre-line" }} noWrap={false} variant="body1Reg">
              {DEFAULT_NUMERIC_VALUES.ZERO}
            </Typography>
          );
        }
        return (
          <Typography sx={{ wordBreak: "break-word", display: "inline-block", whiteSpace: "pre-line" }} noWrap={false} variant="body1Reg">
            {value && value !== null ? Math.round(value as number) : DEFAULT_NUMERIC_VALUES.ZERO}
          </Typography>
        );
      case RowDataTypes.Percentage:
        return (
          <Typography sx={{ wordBreak: "break-word", display: "inline-block", whiteSpace: "pre-line" }} noWrap={false} variant="body1Reg">
            {value && value !== null ? (value as number).toFixed(NUMERIC_VALUES.CONSTANT_TWO) + "%" : DEFAULT_NUMERIC_VALUES.ZERO + "%"}
          </Typography>
        );
      case RowDataTypes.DaysLate:
        return (
          <Typography sx={{ wordBreak: "break-word", display: "inline-block", whiteSpace: "pre-line" }} noWrap={false} variant="body1Reg">
            {formatDaysLate(value as string)}
          </Typography>
        );
      case RowDataTypes.DateEpoch:
        if (value == "-" || value == "N/A" || value == "") {
          return (
            <Typography sx={{ wordBreak: "break-word", display: "inline-block", whiteSpace: "pre-line" }} noWrap={false} variant="body1Reg">
              {"-"}
            </Typography>
          );
        }
        return (
          <Typography sx={{ wordBreak: "break-word", display: "inline-block", whiteSpace: "pre-line" }} noWrap={false} variant="body1Reg">
            {formatDate(formatEpoch(value as string))}
          </Typography>
        );
      case RowDataTypes.DateEpochSnoozed:
        if (value == "-") {
          return (
            <Typography sx={{ wordBreak: "break-word", display: "inline-block", whiteSpace: "pre-line" }} noWrap={false} variant="body1Reg">
              {value}
            </Typography>
          );
        }
        return (
          <Typography sx={{ wordBreak: "break-word", display: "flex", gap: ".5rem", whiteSpace: "pre-line" }} noWrap={false} variant="body1Reg">
            <SnoozedIndex />
            {snoozedUntill?.substring(NUMERIC_VALUES.CONSTANT_TWO)}
          </Typography>
        );
      case RowDataTypes.EInvoiceAttachment: {
        return (
          <EInvoiceAttachemnts
            attachments={value as unknown as AttachmentItem[]}
            secondaryValue={secondaryValue as string}
            callback={extraHandlers?.drawerHandler as any}
          />
        );
      }
      case RowDataTypes.Status:
        return (
          <StatusChips
            translationObject={t}
            chipStatus={value as "Open" | "Closed" | "Past Due" | "Rejected"}
            daysPastDue={Math.abs(calcDaysBetween(secondaryValue as string) ?? DEFAULT_NUMERIC_VALUES.ZERO) as number}
            chipConfig={{
              chipVariant: "outlined",
              fontVariant: "captionReg",
              width: 90,
              height: 16,
            }}
          />
        );

      case RowDataTypes.EInvoiceDaysStatusChips:
        return (
          <EInvoiceDaysStatusChips
            translationObject={t}
            chipStatus={value as "Open" | "Closed" | "Past Due" | "Rejected"}
            daysPastDue={Math.abs(calcDaysBetween(secondaryValue as string) ?? DEFAULT_NUMERIC_VALUES.ZERO) as number}
            chipConfig={{
              chipVariant: "outlined",
              fontVariant: "captionReg",
              width: 90,
              height: 16,
            }}
          />
        );

      case RowDataTypes.EInvoiceStatus:
        return (
          <EInvoiceStatusChips
            translationObject={{ t, i18n }}
            chipStatus={
              value as ActivityStatusChipsComponentType["chipStatus"]
            }
            chipConfig={{
              chipVariant: "outlined",
              fontVariant: "captionReg",
              width: 90,
              height: 16,
            }}
          />
        );
      case RowDataTypes.ActivityStatus:
        return (
          <ActivityStatusChips
            chipStatus={value as "active" | "closed" | "waiting_for_response" | "snoozed"}
            chipConfig={{
              chipVariant: "outlined",
              fontVariant: "captionReg",
              width: 90,
              height: 16,
            }}
          />
        );
      case RowDataTypes.DownloadDoc:
        return (
          <ActionContainer>
            {!downloading && !actionHover && (
              <div className="table-action-icon">
                <DownloadIcon />
              </div>
            )}
            <ActionButtonWrapper>
              {(actionHover || downloading) && value && (
                <Tooltip title={`Download ${view}`} arrow placement="top-end">
                  <div
                    className={`table-action-icon ${downloading ? "table-action-icon-blink " : ""}`}
                    // onClick={downloadDocument.bind(this, secondaryValue)}
                  >
                    <DownloadCloud />
                  </div>
                </Tooltip>
              )}
              {actionHover && (
                <Tooltip title={`Export ${view}`} arrow placement="top-end">
                  <div className="table-action-icon" onClick={formatRowDataForExport.bind(this, colConfig, rowData, dateFormat)}>
                    <CSVLink
                      headers={colConfig
                        ?.filter((col: any) => !_.isEmpty(col?.name) && !_.isEmpty(col?.accessor))
                        ?.map((col: any) => ({
                          label: col?.name,
                          key: col?.accessor,
                        }))}
                      data={[formatRowDataForExport(colConfig, rowData, dateFormat ?? "")]}
                      filename={formatExportName(view)}
                      onClick={() => {
                        extraHandlers?.setSnackbarConfig({
                          open: true,
                          type: SnackbarTypes.Export,
                          text: "Exporting To Excel",
                        });
                      }}
                    >
                      <ExportIcon />
                    </CSVLink>
                  </div>
                </Tooltip>
              )}
            </ActionButtonWrapper>
          </ActionContainer>
        );
      case RowDataTypes.FileWithCounts:
        return <FilesCountContainer>{getFileAndFileCounts(value as string)}</FilesCountContainer>;
      case RowDataTypes.FileCount:
        return (
          <FilesCountContainer>
            <Typography
              sx={{ wordBreak: "break-word", display: "inline-block", whiteSpace: "pre-line" }}
              noWrap={false}
              variant={(value as number) && (value as number) >= NUMERIC_VALUES.CONSTANT_ZERO ? "body1" : "body1Reg"}
              className={(value as number) && (value as number) >= NUMERIC_VALUES.CONSTANT_ZERO ? "files-label-active" : "files-label"}
            >
              {filesFormattedLabel(value as number)}
            </Typography>
          </FilesCountContainer>
        );
      case RowDataTypes.FileCountWithDrawerHandler:
        return (
          <FilesCountContainer
            onClick={
              extraHandlers?.drawerHandler ? extraHandlers?.drawerHandler?.bind(this, rowData?.id, secondaryValue) : DEFAULT_VALUES.DEFAULT_FUNCTION
            }
          >
            <Typography
              sx={{ wordBreak: "break-word", display: "inline-block", whiteSpace: "pre-line" }}
              noWrap={false}
              variant={(value as number) && (value as number) >= NUMERIC_VALUES.CONSTANT_ZERO ? "body1" : "body1Reg"}
              className={(value as number) && (value as number) >= NUMERIC_VALUES.CONSTANT_ZERO ? "files-label-active" : "files-label"}
            >
              {filesFormattedLabel(value as number)}
            </Typography>
          </FilesCountContainer>
        );
      case RowDataTypes.Flag:
        return (
          <TableFormattedCell>
            {value && Object.keys(flagScheme).includes(value as string) ? (
              <div className={`table-formatted-cell-flag ${flagScheme[value].color}`}>
                <div className={`table-formatted-cell-flag-icon`}>
                  <ActivityFlag />
                </div>
                <span className={`table-formatted-cell-title ${value}`}>{flagScheme[value].title}</span>
              </div>
            ) : (
              <></>
            )}
          </TableFormattedCell>
        );

      case RowDataTypes.ActivitySubject:
        return (
          <>
            <Typography sx={{ wordBreak: "break-word", display: "inline-block", whiteSpace: "pre-line" }} noWrap={false} variant="body1Reg">
              {value}
            </Typography>
            {secondaryValue && (secondaryValue as Array<any>).length && (secondaryValue as Array<any>).length > NUMERIC_VALUES.CONSTANT_ZERO ? (
              <SubjectAttachmentWrapper>
                <div className="attachments-container">
                  {(secondaryValue as Array<any>).length > NUMERIC_VALUES.CONSTANT_THREE ? (
                    <>
                      <AttachmenChip key={(secondaryValue as Array<any>)[0].file_name} attachment={(secondaryValue as Array<any>)[0]} />
                      <AttachmenChip key={(secondaryValue as Array<any>)[1].file_name} attachment={(secondaryValue as Array<any>)[1]} />
                      <ShowMoreChip restCount={(secondaryValue as Array<any>).length - NUMERIC_VALUES.CONSTANT_TWO} />
                    </>
                  ) : (
                    (secondaryValue as Array<any>).map((item: any, key: number) => <AttachmenChip key={key} attachment={item} />)
                  )}
                  {openPreview && (
                    <AttachmentPreview
                      openPreview={openPreview}
                      activeAttachmentID={activeAttachmentID}
                      handleClose={onClickPreviewClose}
                      attachments={secondaryValue as AttachmentItem[]}
                      onClickDownload={onClickDownload}
                    />
                  )}
                </div>
              </SubjectAttachmentWrapper>
            ) : null}
          </>
        );
      case RowDataTypes.CompanyChip:
        return <CompanyTypeChip companyType={rowData?.company_type} translationObject={t} />;

      default:
        return (
          <Typography sx={{ wordBreak: "break-word", display: "inline-block", whiteSpace: "pre-line" }} noWrap={false} variant="body1Reg">
            {value || "N/A"}
          </Typography>
        );
    }
  };

  return getFormattedValue();
};

export default Cell;

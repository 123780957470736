import styled from "@emotion/styled";
import inboxStyles from "../../../../../../css/_export.module.scss";

export const GroupHeader = styled("div")(() => ({
  position: "sticky",
  top: `-${inboxStyles.small}`,
  padding: `${inboxStyles.small} 0.625rem`,
  fontFamily: "Work Sans",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: inboxStyles.fontSizeXS,
  lineHeight: inboxStyles.lineHeightSM,
  color: inboxStyles.grey40,
  backgroundColor: inboxStyles.white100,
}));

export const GroupItems = styled("ul")({
  padding: 0,
});

export const ListItem = styled("li")({ margin: `0 ${inboxStyles.small}`, borderRadius: inboxStyles.small });

import React from "react";
import { RequestCancelledErrorProps } from "./LinkExpiryTypes";
import Status from "../Status/Status";
import { Failure } from "../../library/Icons/Icons";
import { formatDate } from "../../../db/utils/date";

const RequestCancelledError: React.FC<RequestCancelledErrorProps> = ({ requestData }) => {
  return (
    <Status
      size="lg"
      logo={<Failure />}
      statusMessage={`This link has expired`}
      subText={`This approval request link was cancelled by ${requestData?.approver?.name ?? "-"} on ${formatDate(requestData?.updated_at) ?? "-"}.`}
    />
  );
};

export default RequestCancelledError;

import React from "react";
import { VIEW_CATEGORIES } from "../../../types/enums";
import { default as EInvoicesTable } from "../EInvoicesTable/EInvoicesTable";
import { RowDataTypes } from "../../library/AtomicComponents/constants/table.constants";
import { useTranslation } from "react-i18next";
import { File } from "../../library/Icons/Icons";

export default function SentEInvoices(): React.ReactElement {
  const { t } = useTranslation();

  const SENT_E_INVOICES = [
    {
      name: t("table.sentEInvoices.columns.eInvoicingStatus", { ns: "einvoices" }),
      accessor: "state",
      sortField: "state",
      rowDataAlign: "left",
      enableSkeleton: true,
      rowDataType: RowDataTypes.EInvoiceStatus,
      enableSort: false,
      enableFilter: false,
      filterType: "dropdown",
      filterAlignment: "right",
      dropdownOptions: [
        {
          label: "Accepted",
          id: "accepted",
        },
        {
          label: "Rejected",
          id: "rejected",
        },
      ],
      dropdownListItems: [
        { label: "Accepted", value: "accepted" },
        { label: "Rejected", value: "rejected" },
      ],
      width: "10%",
    },
    {
      name: t("table.sentEInvoices.columns.to", { ns: "einvoices" }),
      sortField: "company_name",
      accessor: "company_name",
      rowDataType: RowDataTypes.BoldText,
      rowDataAlign: "left",
      enableSkeleton: true,
      enableSort: false,
      enableFilter: false,
      filterType: "dropdown-search",
      filterAlignment: "center",
      placeholder: t("table.sentEInvoices.placeHolder.to", { ns: "einvoices" }),
      width: "10%",
    },
    {
      name: "Attachment",
      icon: <File />,
      accessor: "attachments",
      conditionalAccessor: "invoice_id",
      rowDataType: RowDataTypes.EInvoiceAttachment,
      rowDataAlign: "left",
      enableSkeleton: true,
      width: "10%",
    },
    {
      name: t("table.sentEInvoices.columns.invoice", { ns: "einvoices" }),
      accessor: "reference_code",
      sortField: "reference_code",
      rowDataAlign: "left",
      rowDataType: RowDataTypes.BoldText,
      enableSkeleton: true,
      enableSort: true,
      enableFilter: true,
      filterType: "dropdown-search",
      filterAlignment: "right",
      dropdownSearchVariant: "large",
      placeholder: t("table.sentEInvoices.placeHolder.invoiceNumber", { ns: "einvoices" }),
      width: "10%",
    },
    {
      name: t("table.sentEInvoices.columns.dueDate", { ns: "einvoices" }),
      accessor: "payment_due_date",
      sortField: "payment_due_date",
      rowDataAlign: "left",
      enableSkeleton: true,
      rowDataType: RowDataTypes.DateEpoch,
      enableSort: true,
      enableFilter: true,
      filterType: "date",
      filterAlignment: "right",
      width: "10%",
    },
    {
      name: t("table.sentEInvoices.columns.aging", { ns: "einvoices" }),
      accessor: "invoice_status",
      conditionalAccessor: "payment_due_date_raw",
      rowDataAlign: "left",
      enableSkeleton: true,
      rowDataType: RowDataTypes.EInvoiceDaysStatusChips,
      enableSort: false,
      enableFilter: false,
      filterType: "dropdown",
      filterAlignment: "right",
      dropdownOptions: [
        {
          label: "OPEN",
          value: "-180",
          id: "open",
        },
        {
          label: "PAST DUE 1-30",
          value: "0",
          id: "late1",
        },
        {
          label: "PAST DUE 31-60",
          value: "30",
          id: "late31",
        },
        {
          label: "PAST DUE 61-90",
          value: "60",
          id: "late61",
        },
        {
          label: "PAST DUE 91+",
          value: "90",
          id: "late91",
        },
        {
          label: "CLOSED",
          value: "-1",
          id: "closed",
        },
      ],
      dropdownListItems: [
        { label: "All Status", value: "All Status" },
        { label: "OPEN", value: "Open" },
        { label: "PAST DUE", value: "Past Due" },
      ],
      width: "10%",
    },
    {
      name: t("table.sentEInvoices.columns.transactionAmount", { ns: "einvoices" }),
      accessor: "total_amount",
      enableSort: true,
      rowDataAlign: "right",
      enableSkeleton: true,
      sortField: "base_currency_total_amount",
      alignment: "right",
      filterAnchorPosition: "center",
      rowDataType: RowDataTypes.Currency,
      enableFilter: true,
      filterType: "numeric",
      filterAlignment: "right",
      width: "4%",
    },
    {
      name: t("table.sentEInvoices.columns.pretaxTransactionAmount", { ns: "einvoices" }),
      accessor: "amount_paid",
      enableSort: false,
      rowDataAlign: "right",
      enableSkeleton: true,
      sortField: "amount_paid",
      alignment: "right",
      filterAnchorPosition: "center",
      rowDataType: RowDataTypes.Currency,
      enableFilter: false,
      filterType: "numeric",
      filterAlignment: "right",
      width: "10%",
    },
  ];

  return (
    <div className={`table-activity-wrapper activity-stream-v2`}>
      <div className="body-table">
        <EInvoicesTable columns={SENT_E_INVOICES} assigneeType={"all"} route={"all"} category={VIEW_CATEGORIES.MINE_ACTIVITES} />
      </div>
    </div>
  );
}

/* eslint-disable @typescript-eslint/no-use-before-define */
import { AxiosError } from "axios";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { TemplateDefinitionProps } from "../../../../app/Templates/TemplateFactory";
import { TemplateTypes, getViewTypeTemplateCodes, viewType } from "../../../../app/Templates/TemplateTypes";
import { CONNECTION_STATUS } from "../../../../constants/ConnectionConstants";
import { DEFAULT_NUMERIC_VALUES } from "../../../../constants/NumericConstants";
import { CUSTOM_DETAILS_CONSTANTS } from "../../../../constants/StyleConstants";
import { ActivityContext } from "../../../../contexts/ActivityContext";
import { AlertContext } from "../../../../contexts/AlertContext";
import { AppContext } from "../../../../contexts/AppContext";
import { ApplicationRouteContext } from "../../../../contexts/ApplicationRouteContext";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import { PaymentContext } from "../../../../contexts/PaymentContext";
import { TemplateContext } from "../../../../contexts/TemplateContext";
import { emailsClientV2, activitiesClientV2 } from "../../../../db/version2Accessor";
import { ActivityOptions, ActivityType as ActivityTypeEnum, EmailAction, FallbackTypes, WorkspaceType } from "../../../../types/enums";
import { getIcon } from "../../../../utils/IconUtils/IconUtils";
import NewActivityDropdown from "../../../library/AddNewActivityDropdown/AddNewActivityDropdown";
import Detail from "../../../library/Detail/Detail";
import { Email, NoteFill, Pen, Phone } from "../../../library/Icons/Icons";
import { SmartText } from "../../../library/SmartText";
import "./Details.scss";
import DetailsTab from "./DetailsTab/DetailsTab";
import DocumentSwitcher from "../../../../components/library/DocumentSwitcher/DocumentSwitcher";
import Utils from "../../../../utils/utils";
import { DocumentSwitchContext } from "../../../../contexts/DocumentSwitchContext";
import _ from "underscore";
import useLocale from "../../../../hooks/useLocale";
import useContacts from "../../../../hooks/useContacts";
import {
  ACTIVITY_COMPLETE_FAILURE,
  ACTIVITY_COMPLETE_SUCCESS,
  ACTIVITY_SUCCESS_TOAST_MSG,
  BTN_NEW_ACTIVITY,
  EMPTY_TRANSACTIONS_MESSAGE,
  NO_DATA_MESSAGE,
} from "../../../../constants/config";
import ActivityFeedMethods from "../../../Activities/ActivityDetail/ActivityFeedV2/helpers/ActivityFeedMethods";
import useWorkspaceConfigurations from "../../../../hooks/useWorkspaceConfigurations";
import useHandleVisibility from "../../../../hooks/useHandleVisibility";
import EmptyStates from "../../../library/AtomicComponents/Table/tsx/EmptyStates/EmptyStates";

interface DetailsInterface {
  errorMessage: string;
  configs: any;
}

type DetailValueMappingObject = {
  [key: string]: any;
};

export default function Details({ errorMessage, configs: config }: DetailsInterface): React.ReactElement {
  const { paymentDetailViewData, paymentDetailViewInvoices } = React.useContext(PaymentContext) as PaymentType;
  const { paymentId } = useParams<{ paymentId: string }>();
  const history = useHistory();
  const tooltipRef = useRef<HTMLDivElement>(null);
  const detailRef = useRef<any>(null);
  const [fromTime, setFromTime] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [toTime, setToTime] = useState<number>(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
  const [retryEmailPopup, setRetryEmailPopup] = useState<boolean>(false);
  const [newActivityType, setNewActivityType] = useState<string | null>(null);
  const [showAddActivity, setShowAddActivity] = useState<boolean>(false);
  const [companyContactOptions, setCompanyContactOptions] = useState<To[]>([]);
  const [actionType, setActionType] = useState<string>("");
  const [refreshToggler, setRefreshToggler] = React.useState<boolean>(false);
  const [enableTypeAheadSuggestions, setEnableTypeAheadSuggestions] = React.useState(false);
  const [supportedTemplateList, setSupportedTemplateList] = useState<TemplateDefinitionProps[]>([]);
  const [defaultTemplateId, setDefaultTemplateId] = useState<TemplateTypes | string>();
  // templateName > indicates the selected template code by the user
  const [templateName, setTemplateName] = useState<string>("");
  const { handleClose } = React.useContext(ActivityContext) as ActivityType;
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const { selectedWorkspace, workspaceHomePath, pathType } = useWorkspaceConfigurations();
  const { templateFactory, templateData } = React.useContext(TemplateContext) as ITemplateProps;
  const { loginUserCompany, signature } = React.useContext(CustomerContext) as CustomerType;
  const { userStatus } = React.useContext(AppContext) as AppType;
  const { getContactsOptions, allContactOptions, filterPrimaryContacts } = useContacts();
  const { getBaseRoute, updateBaseRoute } = React.useContext(ApplicationRouteContext) as any;
  const [lastApprover, setLastApprover] = useState<To>({} as To);
  const [lastApproverloading, setLastApproverloading] = useState<boolean>(false);
  const {
    setSelectedActivityStreamId,
    setActivityStreamIds,
    setCurrentPaginationState,
    setDocumentType,
    setEnableDocumentSwitch,
    setCurrentSwitcherStateSnapshot,
  } = React.useContext(DocumentSwitchContext) as DocumentSwitchType;
  const { formatDateBasedOnCountry: formatDate, availableLanguagesForTemplates } = useLocale();
  const { url } = useRouteMatch();
  const isAPWorkspace =
    selectedWorkspace.workspace_type === "accounting" ? url?.includes("bills") : selectedWorkspace.workspace_type === "accounts_payable";

  /**
   * setup common state with reducer to handle open/close states for send and send and close button
   * React useState hooks.
   */
  const initialVisibility = {
    isSendBtnDisabled: false,
  };
  const { visibility, dispatch } = useHandleVisibility<typeof initialVisibility>({ isSendBtnDisabled: false });

  const fetchConnectionContacts = async () => {
    if (!paymentDetailViewData?.customerId) return;
    setCompanyContactOptions(await getContactsOptions(paymentDetailViewData.customerId));
  };

  const primaryContact = filterPrimaryContacts(companyContactOptions);

  useEffect(() => {
    fetchConnectionContacts();
  }, []);

  const handleNameClick = (id: string) => {
    sessionStorage.setItem("lastPath", history.location.pathname);
    const base = `${workspaceHomePath}/${config.routes.resource.customer}/active/${id ?? ""}`;
    updateBaseRoute(base);
    history.push(base);
  };

  const handleInvoiceClick = (id: string) => {
    /**
     * Set the following properties into DocumentSwitchContext
     * to facilitate document switching.
     */
    setEnableDocumentSwitch(true);
    /**
     * Save the current document switcher state
     */
    setCurrentSwitcherStateSnapshot();
    setSelectedActivityStreamId({ id: id } as ActivityStreamId);
    setActivityStreamIds(paymentDetailViewInvoices.map((item) => ({ id: item.invoiceId })));
    setCurrentPaginationState({
      page: DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
      pageCount: DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
      pageSize: paymentDetailViewInvoices.length,
      totalRecords: paymentDetailViewInvoices.length,
    });
    setDocumentType("related-invoices");
    sessionStorage.setItem("lastPath", history.location.pathname);
    history.push(`${getBaseRoute()}/${config.routes.resource.invoices}/${id ?? ""}`);
  };

  const handleMouseEvent = (e: MouseEvent, invoiceId: string) => {
    if (e.type === "mouseleave") {
      if (tooltipRef.current) {
        tooltipRef.current.innerHTML = "";
        tooltipRef.current.style.display = "none";
      }
    }
    if (e.type === "mouseenter" && tooltipRef.current) {
      tooltipRef.current.innerHTML = invoiceId;
      //checks for ellipsis to decide if hover state should be displayed
      if ((e.currentTarget as HTMLElement).offsetWidth < (e.currentTarget as HTMLElement).scrollWidth) {
        tooltipRef.current.style.display = "inline";
        const top = 540;
        const left = e.clientX - CUSTOM_DETAILS_CONSTANTS.TOOLTIP_LEFT;
        tooltipRef.current.style.top = `${top / DEFAULT_NUMERIC_VALUES.DEFAULT_REM_CONVERSION_VALUE}rem`;
        tooltipRef.current.style.left = `${left / DEFAULT_NUMERIC_VALUES.DEFAULT_REM_CONVERSION_VALUE}rem`;
      }
    }
  };

  // 42656
  // check if template code is required as content-type
  const needTemplateCodeAsPayload = React.useMemo(
    () =>
      [
        TemplateTypes.AR_CREATE_CUSTOMER_PROFILE,
        TemplateTypes.AR_UPDATE_CUSTOMER_PROFILE,
        TemplateTypes.AP_CREATE_VENDOR_PROFILE,
        TemplateTypes.AP_CREATE_VENDOR_PROFILE,
      ].includes(templateName as TemplateTypes),
    [templateName]
  );

  const handleReplySend = async (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    attachmentIds: AttachmentIds,
    inboxAttachments = [] as TransactionItemType[],
    disableSoftRefresh = false
  ) => {
    dispatch({ type: "open", payload: "isSendBtnDisabled" });
    const getEmailArray = (emailArray: To[]) => emailArray.filter((item) => item !== undefined).map((value: To) => value.id || value.label);
    let responseData: any = {};
    let toastOptions: ToastOptions = { open: true, severity: "success", message: ACTIVITY_SUCCESS_TOAST_MSG };
    const activityTransactions = _.uniq([...inboxAttachments, { transaction_id: paymentId, transaction_type: "payment" }], "transaction_id");
    const payload: Partial<ActivityItemEmailModel> = {
      workspace_id: selectedWorkspace?.id || FallbackTypes.Id,
      to: getEmailArray(to),
      cc: getEmailArray(cc),
      bcc: getEmailArray(bcc),
      subject: title,
      content: body,
      email_action: actionType ? actionType : EmailAction.NEW,
      connection_company_id: paymentDetailViewData?.customerId ?? "",
      content_type: ActivityFeedMethods.evaluateContentVariantPayload(newActivityType ?? "", needTemplateCodeAsPayload ? templateName : ""),
      attachments: attachmentIds.filter((item) => typeof item === "number"),
      activity_type:
        newActivityType && newActivityType == ActivityOptions.PHONE_CALL
          ? ActivityTypeEnum.PhoneCall
          : newActivityType === ActivityOptions.APPROVAL_REQUEST
          ? ActivityTypeEnum.Approval
          : newActivityType,
      activity_transactions: activityTransactions,
      attach_pdf: Utils.isThereAnyAttachment(attachmentIds, activityTransactions),
    };
    await emailsClientV2
      .post(payload)
      .then((response: ActivityStream) => {
        setToastOptions({ open: true, severity: "success", message: ACTIVITY_SUCCESS_TOAST_MSG });
        setShowAddActivity(false);
        setActionType("");
        setNewActivityType("");
        !disableSoftRefresh && setRefreshToggler(!refreshToggler);
        if (retryEmailPopup) {
          setRetryEmailPopup(false);
        }
        responseData = response;
      })
      .catch((err: AxiosError) => {
        toastOptions.severity = "error";

        if (err.response?.status === CONNECTION_STATUS.BAD_REQUEST_400.STATUS_CODE) {
          toastOptions = { ...toastOptions, severity: "error", message: "Messages not sent. Please check that an Email Connector is connected." };
        } else {
          const errorResponse = err.response as AxiosErrorResponseData;
          toastOptions = {
            ...toastOptions,
            message: errorResponse?.data?.messages?.errors[0] ?? "Messages not sent.",
          };
        }
        setToastOptions(toastOptions);
        dispatch({ type: "close", payload: "isSendBtnDisabled" });
      });

    return responseData;
  };

  const handleSendAndMarkClosed = async (
    to: To[],
    cc: To[],
    bcc: To[],
    title: string,
    body: string,
    attachmentIds: AttachmentIds,
    inboxAttachments: TransactionItemType[]
  ) => {
    const sendEmailResponse = await handleReplySend(to, cc, bcc, title, body, attachmentIds, inboxAttachments, true);
    if (sendEmailResponse.success) {
      let toastOptions: ToastOptions = { open: true, severity: "success", message: ACTIVITY_COMPLETE_SUCCESS };
      let response = {} as APIResponse;

      try {
        response = await handleClose(sendEmailResponse.data.activity_stream.id, "");
      } catch (e: unknown) {
        response.success = false;
      } finally {
        if (!response.success) {
          toastOptions = {
            ...toastOptions,
            severity: "error",
            message: ACTIVITY_COMPLETE_FAILURE,
          };
        } else {
          setRefreshToggler(!refreshToggler);
        }
        setToastOptions(toastOptions);
      }
    }
  };

  const typeAheadAction = (val: string) => {
    if (val.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
      setEnableTypeAheadSuggestions(true);
    } else {
      setEnableTypeAheadSuggestions(false);
    }
  };

  const propertyValueMap: DetailValueMappingObject = {
    "value.primaryContact": paymentDetailViewData?.primaryContact ?? null,
    "value.email": paymentDetailViewData?.email ?? null,
    "value.paymentAmount": paymentDetailViewData?.currencyCode
      ? new Intl.NumberFormat(paymentDetailViewData?.currencyCode ?? "en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
          style: "currency",
          currency: paymentDetailViewData?.currencyCode ?? "USD",
        }).format(paymentDetailViewData?.paymentAmount)
      : null,
    "value.unappliedAmount": new Intl.NumberFormat(userStatus?.currency?.locale ?? "en-US", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
      style: "currency",
      currency: paymentDetailViewData?.currencyCode ?? "USD",
    }).format(paymentDetailViewData?.unappliedAmount),
    "value.paymentType": paymentDetailViewData?.tenderType ?? null,
    "value.paymentDate": formatDate(paymentDetailViewData?.paymentDate ?? null),
    "value.postDate": formatDate(paymentDetailViewData?.postDate),
  };

  /**
   * Function is used to prepare the invoice data which need's to show in templates.
   *
   * @param {string} workspaceType The workspaceType indicate which works space is currently selected.
   * @param {TemplateTypes} templateID The templateID indicate which template is selected by user from subject dropdown.
   * @param {PaymentDetailViewResponse[]} invoices the invoice
   * @returns {}
   */
  const prepareInvoiceDataForTemplate = (workspaceType: string | undefined | null, invoices: PaymentDetailViewInvoice[]): Record<string, any> => {
    let invoiceInfo: Record<string, any> = [];
    const isAccountReceivable = workspaceType === "accounts_receivable";
    if (invoices) {
      invoiceInfo = invoices.map((item, index) => {
        const props = {
          title: `Invoice- ${++index}`,
          items: [
            {
              key: isAccountReceivable ? "Invoice number" : "Bill number",
              value: item?.referenceCode,
            },
            {
              key: "Due date",
              value: formatDate(item.paymentDueDate),
            },
            {
              key: isAccountReceivable ? "Amount paid" : "Paid amount",
              value: Utils.formatValueAsCurrency(
                item.paymentAppliedAmount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
                userStatus?.currency?.locale,
                paymentDetailViewData?.currencyCode
              ),
            },
            isAccountReceivable
              ? {
                  key: "Invoice status",
                  value: item.invoiceStatusCode,
                }
              : "",
          ],
        };

        return props;
      });
    }
    return invoiceInfo;
  };

  /**
   * Function which convert the template string to editor content state.
   *
   * @param {string} templateID The templateID selected by user from the subject drop down.
   * @param {EditorState} EditorState The setEditorState will update the email body.
   */
  const prepareActivityBodyByTemplateID = async (
    templateID: string | null,
    setEditorState: React.Dispatch<React.SetStateAction<string>>,
    lang: string
  ) => {
    setTemplateName(templateID ?? "");
    if (templateID) {
      const workspaceType = selectedWorkspace.workspace_type;
      let invoiceInfo: Record<string, any> = [];
      if (templateID !== TemplateTypes.PAYMENT_CONFIRMATION_REQUEST) {
        invoiceInfo = prepareInvoiceDataForTemplate(workspaceType, paymentDetailViewInvoices);
      }
      const templateObj = templateData.get(selectedWorkspace?.id)?.get(lang)?.get(templateID);
      setFromTime(templateObj.getFromTime);
      setToTime(templateObj.getToTime);
      setEditorState(
        templateObj.parseTemplate({
          customer: paymentDetailViewData?.customerName || "",
          vendor: paymentDetailViewData?.customerName || "",
          paymentNumber: paymentDetailViewData?.referenceCode || "",
          paymentDate: formatDate(paymentDetailViewData.paymentDate) || "",
          totalPaymentAmount: Utils.formatValueAsCurrency(
            paymentDetailViewData.paymentAmount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            userStatus?.currency?.locale,
            paymentDetailViewData?.currencyCode
          ),
          totalPaymentsApplied: Utils.formatValueAsCurrency(
            paymentDetailViewData?.paymentAmount && paymentDetailViewData?.unappliedAmount
              ? paymentDetailViewData?.paymentAmount - paymentDetailViewData?.unappliedAmount
              : DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
            userStatus?.currency?.locale,
            paymentDetailViewData?.currencyCode
          ),
          emailAddress: workspaceType === loginUserCompany?.emailAddress || "",
          companyName: loginUserCompany?.companyName || "",
          phone: loginUserCompany?.phoneNumber || "",
          invoices: invoiceInfo,
          userName: `${userStatus?.user_name || "{Your Name}"}`,
          workspaceName: `${selectedWorkspace?.name}`,
          contactName: primaryContact[0]?.label || paymentDetailViewData?.customerName,
          signature: signature.email_signature || "",
          // TODO come up with a more user friendly key names for the invoices list - will be taken up later
        })
      );
    }
  };

  /**
   * Function which help's to give template list for the subject drop down
   * based on view type.
   *
   * @param {Object} row The selected row record from table
   * @returns {TemplateDefinitionProps[]} The template definition array of objects.
   */
  const getTemplateDefinitionsByView = (item: PaymentDetailModel): TemplateDefinitionProps[] => {
    let view: viewType;
    if (pathType === WorkspaceType.AP) {
      view = viewType.AP_TRANSACTION_PAYMENTS;
    } else {
      view = viewType.AR_TRANSACTION_PAYMENTS;
    }
    const { customerName = "{Company name}" } = item;
    return templateFactory.getTemplateDefinitionsByView(
      view,
      {
        //Passing first invoice number from the list of invoices assuming its the latest
        invoiceNumber: paymentDetailViewInvoices[0]?.referenceCode ?? "",
        paymentNumber: item.referenceCode,
        customer: customerName,
        companyName: loginUserCompany?.companyName ?? "",
        vendor: customerName,
      },
      availableLanguagesForTemplates
    );
  };

  //return {key:value} pair for template subject to parse the values
  const getSelectedTempSubjSnippetValues = () => {
    const { customerName = "" } = paymentDetailViewData;

    return {
      //Passing first invoice number from the list of invoices assuming its the latest
      invoiceNumber: paymentDetailViewInvoices[0]?.referenceCode ?? "",
      paymentNumber: paymentDetailViewData?.referenceCode ?? "",
      customer: customerName,
      companyName: loginUserCompany?.companyName ?? "",
      vendor: customerName,
    };
  };

  //return suggested template codes based on certain condition
  const getSuggestedTemplatesCode = () => {
    if (newActivityType === ActivityOptions.APPROVAL_REQUEST) {
      return isAPWorkspace
        ? getViewTypeTemplateCodes(viewType.AP_APPROVAL_REQUEST_ACTIVITY)
        : getViewTypeTemplateCodes(viewType.AR_APPROVAL_REQUEST_ACTIVITY);
    }

    if (isAPWorkspace) {
      return getViewTypeTemplateCodes(viewType.AP_TRANSACTION_PAYMENTS);
    } else {
      return getViewTypeTemplateCodes(viewType.AR_TRANSACTION_PAYMENTS);
    }
  };

  useEffect(() => {
    if (newActivityType === ActivityOptions.EMAIL) {
      setDefaultTemplateId("");
      setSupportedTemplateList(getTemplateDefinitionsByView(paymentDetailViewData));
    } else if (newActivityType === ActivityOptions.APPROVAL_REQUEST) {
      setSupportedTemplateList(
        templateFactory.getTemplateDefinitionsByView(
          selectedWorkspace.workspace_type === "accounts_payable" ? viewType.AP_APPROVAL_REQUEST_ACTIVITY : viewType.AR_APPROVAL_REQUEST_ACTIVITY,
          "",
          availableLanguagesForTemplates
        )
      );
      setDefaultTemplateId(
        selectedWorkspace.workspace_type === "accounts_payable" ? TemplateTypes.AP_APPROVAL_REQUEST : TemplateTypes.AR_APPROVAL_REQUEST
      );
    }
    // reset template states
    else {
      setSupportedTemplateList([]);
      setDefaultTemplateId("");
    }
    // rest the selected template code
    setTemplateName("");
  }, [newActivityType]);

  const addActivityDropdownOptions: ActivityDropdownItem[] = [
    { activityType: "Email", displayName: "Email", icon: <Email /> },
    { activityType: "Note", displayName: "Note", icon: <NoteFill /> },
    { activityType: "Phone Call", displayName: "Phone Call", icon: <Phone /> },
    { activityType: "Approval Request", displayName: "Approval Request", icon: <Pen /> },
  ];

  const setPaymentNewActivity = (type: string) => {
    setNewActivityType(type);
    detailRef.current.hideDetailActivityComponent();
  };

  const getLastApprover = () => {
    setLastApproverloading(true);
    activitiesClientV2
      .getLastApprover(selectedWorkspace?.id, paymentDetailViewData.customerId)
      .then((response) => {
        if (response?.data?.last_approver?.email_address) {
          setLastApprover({
            id: response.data.last_approver.email_address || "",
            label: response.data.last_approver.contact_name || "",
            primary: false,
            connectionId: response.data.last_approver.company_id || "",
            erpContact: false,
            companyId: response.data.last_approver.company_id || "",
          });
        } else {
          setLastApprover({} as To);
        }
      })
      .finally(() => {
        setLastApproverloading(false);
      });
  };

  useEffect(() => {
    if (newActivityType === ActivityOptions.APPROVAL_REQUEST && showAddActivity) {
      getLastApprover();
    } else {
      setLastApprover({} as To);
    }
  }, [newActivityType, showAddActivity]);

  /**
   * Function which return the NewActivityDropDown component.
   *
   * @returns {React.Component} return the NewActivityDropDown component.
   */
  const addActivity = () => {
    return (
      <div className="activity-wrapper">
        <NewActivityDropdown
          buttonName={BTN_NEW_ACTIVITY}
          handleReplySend={handleReplySend}
          handleReplySendClose={handleSendAndMarkClosed}
          addActivityDropdown={addActivityDropdownOptions}
          replyToOptions={enableTypeAheadSuggestions ? [...companyContactOptions, ...allContactOptions] : companyContactOptions}
          defaultTo={
            newActivityType === ActivityOptions.APPROVAL_REQUEST
              ? [lastApprover]
              : _.isEmpty(primaryContact)
              ? [companyContactOptions[0]]
              : primaryContact
          }
          isTemplateSupport={newActivityType === ActivityOptions.EMAIL || newActivityType === ActivityOptions.APPROVAL_REQUEST}
          defaultTemplateId={defaultTemplateId ?? ""}
          supportedTemplateList={supportedTemplateList}
          prepareActivityBodyByTemplateID={prepareActivityBodyByTemplateID}
          newActivityType={newActivityType}
          setNewActivityType={setPaymentNewActivity}
          showAddActivity={showAddActivity}
          setShowAddActivity={setShowAddActivity}
          typeAheadAction={typeAheadAction}
          fromTime={fromTime}
          toTime={toTime}
          setToTime={setToTime}
          disableSecondaryAction={newActivityType === ActivityOptions.APPROVAL_REQUEST}
          enableKeyboardShortcuts={true}
          key={`loading-${lastApproverloading}`}
          isSendButtonDisabled={visibility.isSendBtnDisabled}
          loading={lastApproverloading}
          suggestedTemplatesCode={getSuggestedTemplatesCode()}
          tempSubjectSnippetValues={getSelectedTempSubjSnippetValues()}
        />
      </div>
    );
  };

  return (
    <div className={"id-wrapper"}>
      {errorMessage ? (
        <div className="empty-data-wrapper">
          <EmptyStates title={NO_DATA_MESSAGE} caption={EMPTY_TRANSACTIONS_MESSAGE} />
        </div>
      ) : (
        <>
          <div className={"header"}>
            <div className={"title-container"}>
              <SmartText title={`Payment ${paymentDetailViewData?.referenceCode ?? "N/A"}`} />
            </div>
            <div className={"due-wrapper"}>
              <p className={"due-amount"}>
                {new Intl.NumberFormat(userStatus?.currency?.locale ?? "en-US", {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: paymentDetailViewData?.currencyCode ?? "USD",
                }).format(paymentDetailViewData?.paymentAmount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO)}
              </p>
              <p className={"due-text"}>Total Payment Amount </p>
            </div>
            <div className="connection-chip">
              <div className={"tag"}>
                {paymentDetailViewData && (paymentDetailViewData?.customerName ?? "N/A").charAt(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO)}
              </div>
              <p className={"name"} onClick={() => handleNameClick(paymentDetailViewData.customerId)}>
                {paymentDetailViewData && (paymentDetailViewData?.customerName ?? "N/A")}
              </p>
              <DocumentSwitcher />
            </div>
          </div>

          {/* Body of Details*/}
          <div className={"body"}>
            {/* Payment Details */}
            <div className={"payment"}>
              {addActivity()}
              <div className={"payment-contact-wrapper"}>
                <div className="body">
                  {config.details.body.map((detail: any) => {
                    return (
                      <Detail
                        ref={detailRef}
                        key={detail.icon}
                        icon={getIcon(detail.icon)}
                        columns={detail.row.map((column: any) => ({ ...column, value: propertyValueMap[column.value] }))}
                        handleTransactionActivity={setShowAddActivity}
                      />
                    );
                  })}
                </div>
              </div>
              {paymentDetailViewInvoices.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO ? (
                <div className={"invoice-wrapper"}>
                  <div className={"title"}>{config.details.table.title}</div>
                  <div className="row row-header">
                    {config.details.table.columns.map((column: any) => {
                      return (
                        <div className={`header ${column.styles}`} key={column.header}>
                          {column.header}
                        </div>
                      );
                    })}
                  </div>
                  <OverlayScrollbarsComponent options={{ paddingAbsolute: true, autoUpdate: true, sizeAutoCapable: true }}>
                    {paymentDetailViewInvoices &&
                      paymentDetailViewInvoices.map((invoice) => {
                        return (
                          <div onClick={() => handleInvoiceClick(invoice.invoiceId)} className="row" key={invoice.invoiceId}>
                            <div
                              onMouseEnter={(event) => handleMouseEvent(event, invoice.invoiceId)}
                              onMouseLeave={(event) => handleMouseEvent(event, invoice.invoiceId)}
                              className={"invoiceNumber"}
                            >{`${invoice.referenceCode}`}</div>
                            <div className={"status"}>{invoice.invoiceStatusCode ?? "N/A"}</div>
                            <div className={"paymentAmount"}>
                              {invoice?.paymentAppliedAmount !== null &&
                                new Intl.NumberFormat(userStatus?.currency?.locale ?? "en-US", {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                  style: "currency",
                                  currency: paymentDetailViewData?.currencyCode ?? "USD",
                                }).format(invoice.paymentAppliedAmount)}
                            </div>
                            <div className={"outstandingAmount"}>
                              {invoice?.outstandingBalanceAmount !== null &&
                                new Intl.NumberFormat(userStatus?.currency?.locale ?? "en-US", {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                  style: "currency",
                                  currency: paymentDetailViewData?.currencyCode ?? "USD",
                                }).format(invoice.outstandingBalanceAmount)}
                            </div>
                          </div>
                        );
                      })}
                  </OverlayScrollbarsComponent>
                </div>
              ) : (
                ""
              )}
            </div>

            {/* Payments Tab */}
            <div className="details-tab-wrapper">
              <DetailsTab
                newActivityComponentProps={{ setNewActivityType, setShowAddActivity, addActivityDropdown: addActivityDropdownOptions }}
                id={paymentId}
                type="payment"
                refreshList={refreshToggler}
                profileInfo={{
                  customerId: paymentDetailViewData?.customerId ?? "",
                  phone: paymentDetailViewData?.phone ?? "",
                  email: paymentDetailViewData?.email ?? "",
                }}
              />
            </div>
          </div>
          <div id="tooltip-container" style={{ display: "none" }} ref={tooltipRef} />
        </>
      )}
    </div>
  );
}

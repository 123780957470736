import APIClient from "../APIClient";
import { AxiosRequestConfig } from "axios";

const INBOX_API_BASE = `${process.env.REACT_APP_API_ENDPOINT_INBOX_API_PATH}${process.env.REACT_APP_API_ENDPOINT_INBOX_API_V2}`;
const CONFIG_OVERRIDE = {
  baseURL: process.env.REACT_APP_API_ENDPOINT_INBOX,
} as AxiosRequestConfig;

export class CompaniesClient {
  /**
   * * Get on  /api/v2/connections/list-by-type
   * @returns Promise of type APIResponse Model or Exception
   */
  getCustomersList(
    status?: string,
    sortQuery?: string,
    pageSize?: number,
    pageNumber?: number,
    workspaceId?: number,
    companyType?: Array<string>,
    from_connection_id?: string,
    filter?: string
  ): Promise<FetchResult> {
    let url = `${INBOX_API_BASE}/connections/list-by-type`;

    if (filter) {
      url += `?${filter}`;
    }
    const options = {
      params: Object.assign({
        status,
        workspace_id: workspaceId,
        page_size: pageSize,
        page: pageNumber,
        company_type: companyType,
        sortQuery,
        from_connection_id,
      }),
      ...CONFIG_OVERRIDE,
    };

    return APIClient.get(url, options).then((response) => {
      return {
        pageNumber: response.data.page,
        pageSize: response.data.page_size,
        totalCount: response.data.total_records,
        suggested: response.data.suggested,
        records: response.data.records,
      };
    });
  }

  /**
   * * Get on  /api/v2/connections/connection-summary?page={pageNumber}&page_size={pageSize}&workspace_id={workspaceId}&company_type={company}
   * @returns Promise of type APIResponse Model or Exception
   * or
   *  Get on  /api/v2/connections/non-erp-summary?page={pageNumber}&page_size={pageSize}&workspace_id={workspaceId}&company_type={company}
   * @returns Promise of type APIResponse Model or Exception
   */
  getConnectionSummariesData(
    filters?: string,
    sortQuery?: string,
    pageSize?: number,
    pageNumber?: number,
    workspaceId?: number
  ): Promise<FetchResult> {
    let url = `${INBOX_API_BASE}/connections/connection-summary?page=${pageNumber}&page_size=${pageSize}&workspace_id=${workspaceId}`;
    if (filters) {
      url = `${url}&${filters}`;
    }
    if (sortQuery) {
      url = `${url}&${sortQuery}`;
    }
    return APIClient.get(url, CONFIG_OVERRIDE).then((response) => {
      return {
        pageNumber: response.data.page,
        pageSize: response.data.page_size,
        totalCount: response.data.total_records,
        suggested: response.data.suggested,
        records: response.data.records,
      };
    });
  }

  /**
   * * Get on  /api/v2/connections/connection-summary?page={pageNumber}&page_size={pageSize}&workspace_id={workspaceId}&company_type={company}
   * @returns Promise of type APIResponse Model or Exception
   * or
   *  Get on  /api/v2/connections/non-erp-summary?page={pageNumber}&page_size={pageSize}&workspace_id={workspaceId}&company_type={company}
   * @returns Promise of type APIResponse Model or Exception
   */
  getOtherConnectionData(filters?: string, sortQuery?: string, pageSize?: number, pageNumber?: number, workspaceId?: number): Promise<FetchResult> {
    let url = `${INBOX_API_BASE}/connections/non-erp-summary?page=${pageNumber}&page_size=${pageSize}&workspace_id=${workspaceId}`;
    if (filters) {
      url = `${url}&${filters}`;
    }
    if (sortQuery) {
      url = `${url}&${sortQuery}`;
    }
    return APIClient.get(url, CONFIG_OVERRIDE).then((response) => {
      return {
        pageNumber: response.data.page,
        pageSize: response.data.page_size,
        totalCount: response.data.total_records,
        suggested: response.data.suggested,
        records: response.data.records,
      };
    });
  }

  /**
   * * Get on  /api/v2/connections/dashboard-summary?page={pageNumber}&page_size={pageSize}&workspace_id={workspaceId}8&report_date={mm/dd/yyyy}
   * @returns Promise of type APIResponse Model or Exception
   */
  getDashboardSummaries(
    filters?: string,
    sortQuery?: string,
    pageSize?: number,
    pageNumber?: number,
    workspaceId?: number,
    workspaceType?: string
  ): Promise<FetchResult> {
    let url =
      workspaceType === "accounts_payable"
        ? `${INBOX_API_BASE}/workspaces/${workspaceId}/dashboards/daily_outstanding_summary?page=${pageNumber}&page_size=${pageSize}`
        : `${INBOX_API_BASE}/connections/dashboard-summary?page=${pageNumber}&page_size=${pageSize}&workspace_id=${workspaceId}`;
    if (filters) {
      url = `${url}&${filters}`;
    }
    if (sortQuery) {
      url = `${url}&${sortQuery}`;
    }
    return APIClient.get(url, CONFIG_OVERRIDE).then((response) => {
      return {
        pageNumber: response.data.page,
        pageSize: response.data.page_size,
        totalCount: response.data.total_records,
        suggested: response.data.suggested,
        records: workspaceType === "accounts_payable" ? response.data.data : response.data.records,
      };
    });
  }

  updateCustomer(payload: UpdateCustomerStatusPayload, deselectedRowsFilter?: string): Promise<APIResponse> {
    let url = `${INBOX_API_BASE}/connections/workspace_mappings/`;
    if (deselectedRowsFilter) {
      url = `${url}?${deselectedRowsFilter}`;
    }
    return APIClient.patch(url, payload, CONFIG_OVERRIDE).then((response) => {
      return response.data;
    });
  }

  exportConnections(filters: string): Promise<APIResponse> {
    let url = `${INBOX_API_BASE}/connections.csv`;
    if (filters) {
      url = `${url}?${filters}`;
    }
    return APIClient.get(url, CONFIG_OVERRIDE).then((response) => response.data);
  }

  exportOtherConnections(filters: string): Promise<APIResponse> {
    let url = `${INBOX_API_BASE}/connections/non-erp-summary.csv`;
    if (filters) {
      url = `${url}?${filters}`;
    }
    return APIClient.get(url, CONFIG_OVERRIDE).then((response) => response.data);
  }

  mergeConnections(
    connectionId: string,
    mergeIds: string[],
    workspaceId: number,
    isSelectAll = false,
    deselectedRowsFilter?: string
  ): Promise<APIResponse> {
    let url = `${INBOX_API_BASE}/connections/`;

    if (deselectedRowsFilter) {
      url = `${url}?${deselectedRowsFilter}`;
    }
    return APIClient.patch(url, {
      connection_ids: mergeIds,
      workspace_id: workspaceId,
      action_type: "merge",
      id: connectionId,
      select_all: isSelectAll,
    }).then((response) => {
      return response.data;
    });
  }

  getMyComanyData(filters?: string, sortQuery?: string, pageSize?: number, pageNumber?: number): Promise<FetchResult> {
    let url = `${INBOX_API_BASE}/connections/internal_connections?page=${pageNumber}&page_size=${pageSize}`;
    if (filters) {
      url = `${url}&${filters}`;
    }
    if (sortQuery) {
      url = `${url}&${sortQuery}`;
    }

    return APIClient.get(url, CONFIG_OVERRIDE).then((response) => {
      return {
        pageNumber: response.data.page,
        pageSize: response.data.page_size,
        totalCount: response.data.total_records,
        records: response.data.records,
      };
    });
  }

  exportMyCompany(filters: string): Promise<APIResponse> {
    let url = `${INBOX_API_BASE}/connections/internal_connections/export.csv`;
    if (filters) {
      url = `${url}?${filters}`;
    }
    return APIClient.get(url, CONFIG_OVERRIDE).then((response) => response.data);
  }

  updateMyCompanyStatus(payload: UpdateCustomerStatusPayload, deselectedRowsFilter?: string): Promise<APIResponse> {
    let url = `${INBOX_API_BASE}/connections/internal_connections/`;
    if (deselectedRowsFilter) {
      url = `${url}?${deselectedRowsFilter}`;
    }
    return APIClient.patch(url, payload, CONFIG_OVERRIDE).then((response) => {
      return response.data;
    });
  }
}

import React from "react";
import { FilterIconContainer, FilterIconContainerActive } from "../../../styled/table.filter.styled";
import { ReactComponent as FilterIcon } from "../../../../svg/filter_icon.svg";
import { Box, MenuItem } from "@mui/material";
import { CheckList } from "../../../../../Icons/Icons";
import {
  CompletedItem,
  FutureDueItem,
  OpenItem,
  PastDue30Item,
  PastDue60Item,
  PastDue90Item,
  PastDueMoreThan90Item,
  Snoozed,
  StatusPopupContainer,
  WaitingForResponse,
} from "../styled/dropdown.filter.styled";
import { DropdownFilterComponentProps } from "../../preset.filter.types";

const DropdownFilterComponent: React.FC<DropdownFilterComponentProps> = ({
  onClickMenuListItem,
  selectedListItemIndex,
  filterActive,
  filterAlignment,
  dropdownListItems,
}) => {
  const IconWrapper = filterActive ? FilterIconContainerActive : FilterIconContainer;
  const [dropdownFilterAnchorEl, setDropdownFilterAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpenStringFilter = Boolean(dropdownFilterAnchorEl);
  const onClickStringFilter = (event: React.MouseEvent<HTMLElement>) => {
    setDropdownFilterAnchorEl(event.currentTarget);
  };

  const getStatusListItem = (key: string, name: string) => {
    switch (key) {
      case "ACTIVE":
      case "OPEN":
        return <OpenItem>{name}</OpenItem>;
      case "FUTURE DUE":
        return <FutureDueItem>{name}</FutureDueItem>;
      case "CLOSED":
        return <CompletedItem>{name}</CompletedItem>;
      case "PAST DUE 1-30":
        return <PastDue30Item>{name}</PastDue30Item>;
      case "PAST DUE 31-60":
        return <PastDue60Item>{name}</PastDue60Item>;
      case "PAST DUE 61-90":
        return <PastDue90Item>{name}</PastDue90Item>;
      case "PAST DUE":
      case "PAST DUE 91+":
        return <PastDueMoreThan90Item>{name}</PastDueMoreThan90Item>;
      case "WAITING FOR RESPONSE":
        return <WaitingForResponse>{name}</WaitingForResponse>;
      case "SNOOZED":
        return <Snoozed>{name}</Snoozed>;
      default:
        return name;
    }
  };

  const onClickMenuItem = (label: string, index: number, value: string) => {
    setDropdownFilterAnchorEl(null);
    onClickMenuListItem(value, index, label);
  };
  return (
    <>
      <IconWrapper onClick={onClickStringFilter}>
        <FilterIcon />
      </IconWrapper>
      <StatusPopupContainer
        open={isOpenStringFilter}
        onClose={() => setDropdownFilterAnchorEl(null)}
        anchorEl={dropdownFilterAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: filterAlignment,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: filterAlignment,
        }}
      >
        {dropdownListItems?.map((item: { key: string; label: string; value: string }, index: number) => {
          return (
            <MenuItem
              key={item.label + item.value}
              value={item.label}
              selected={index === selectedListItemIndex}
              onClick={() => onClickMenuItem(item?.label, index, item?.value)}
            >
              <Box>{index === selectedListItemIndex && <CheckList />}</Box>
              {getStatusListItem(item.key, item.label)}
            </MenuItem>
          );
        })}
      </StatusPopupContainer>
    </>
  );
};

export default DropdownFilterComponent;

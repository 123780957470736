import React, { useState, useEffect } from "react";
import Utils from "../../../utils/utils";
import { statusClient } from "../../../db/accessor";
import "./ReadOnlyBanner.scss";

/**
 * @function ReadOnlyBanner
 * A helper component to render an alert banner at the bottom of the
 * ui to denote the user is in read-only mode
 * @returns
 */
export default function ReadOnlyBanner(): React.ReactElement {
  const [status, setStatus] = useState<StatusModel>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const pingStatus = async () => {
    const status = await statusClient.getStatus();
    setStatus(status);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    if (Utils.isMagicLinkAuthRequest()) {
      pingStatus();
    }
  }, []);

  if (Utils.isMagicLinkAuthRequest() && !isLoading) {
    return (
      <div className="view-as-readonly-banner">
        View as Account name: <span>{status?.accountName}</span> &nbsp; User name: <span>{status?.userName}</span>
      </div>
    );
  } else {
    return <></>;
  }
}

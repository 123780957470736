import React from "react";
import Button from "../../../library/Button/Button";
import { DataMode } from "../../../../types/enums";
import Userpic from "../../../library/Sidebar/Userpic/Userpic";
import "../Profiles.scss";
import { Skeleton } from "@mui/material";
import { BTN_CANCEL_EDIT, BTN_SAVE_EDITS } from "../../../../constants/config";
import { MY_PROFILE_HEADER_SKELETON_CONSTANTS } from "../../../../constants/StyleConstants";
import Utils from "../../../../utils/utils";

interface HeaderProps {
  dataMode: DataMode;
  setDataMode: (dataMode: DataMode) => void;
  setShowCancel: (showCancel: boolean) => void;
  setSaveLoad: (saveLoad: boolean) => void;
  saveLoad: boolean;
  submitBtnRef: React.RefObject<HTMLButtonElement>;
  userDetails: Partial<UserAccountModel>;
  isLoading: boolean;
}

export default function Header(props: HeaderProps): React.ReactElement {
  const userRole = props.userDetails?.userRole ? props.userDetails.userRole : "Employee";

  return (
    <div className="inner-header">
      <div className="left">
        {props.isLoading ? (
          <>
            <Skeleton
              variant="circular"
              width={Utils.getConvertedSizeInRem(MY_PROFILE_HEADER_SKELETON_CONSTANTS.PROFILE_IMG.RADIUS)}
              height={Utils.getConvertedSizeInRem(MY_PROFILE_HEADER_SKELETON_CONSTANTS.PROFILE_IMG.RADIUS)}
              animation="wave"
            />
            <div className="btns-grp">
              <Skeleton
                variant="text"
                width={Utils.getConvertedSizeInRem(MY_PROFILE_HEADER_SKELETON_CONSTANTS.PROFILE_UPLOAD.WIDTH)}
                height={Utils.getConvertedSizeInRem(MY_PROFILE_HEADER_SKELETON_CONSTANTS.PROFILE_UPLOAD.HEIGHT)}
                animation="wave"
                className="profile-edit-btn"
              />
              <Skeleton
                variant="text"
                width={Utils.getConvertedSizeInRem(MY_PROFILE_HEADER_SKELETON_CONSTANTS.PROFILE_REMOVE.WIDTH)}
                height={Utils.getConvertedSizeInRem(MY_PROFILE_HEADER_SKELETON_CONSTANTS.PROFILE_REMOVE.HEIGHT)}
                style={{ marginLeft: "1rem" }}
                animation="wave"
                className="profile-edit-btn"
              />
            </div>
          </>
        ) : (
          <>
            <Userpic imageUrl={props.userDetails?.imageURL ?? ""} />
            <p className="user-name">{props.userDetails?.userName ?? "N/A"}</p>
            <p className="user-role">{userRole}</p>
          </>
        )}
      </div>
      <div className="right">
        {props.dataMode === DataMode.View ? (
          <>
            {props.isLoading ? (
              <Skeleton
                variant="text"
                width={Utils.getConvertedSizeInRem(MY_PROFILE_HEADER_SKELETON_CONSTANTS.EDIT_BTN.WIDTH)}
                height={Utils.getConvertedSizeInRem(MY_PROFILE_HEADER_SKELETON_CONSTANTS.EDIT_BTN.HEIGHT)}
                style={{ marginRight: "0.3125rem" }}
                animation="wave"
              />
            ) : (
              <Button
                variant="primary"
                size="sm"
                className="edit-profile-btn"
                onClick={() => {
                  props.setDataMode(DataMode.Edit);
                }}
              >
                Edit
              </Button>
            )}
          </>
        ) : (
          <div className="btns-grp">
            <Button
              variant="secondary"
              size="sm"
              className="profile-edit-btn secondary-border"
              onClick={() => {
                props.setShowCancel(true);
              }}
              disabled={props.saveLoad}
            >
              {BTN_CANCEL_EDIT}
            </Button>
            <Button
              variant="primary"
              size="sm"
              className="profile-edit-btn primary-border"
              onClick={() => {
                props?.submitBtnRef?.current?.click();
              }}
              loading={props.saveLoad}
            >
              {BTN_SAVE_EDITS}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme/theme";
import { TextboxProps } from "./types/Textbox";
import { Banner } from "./styled/Textbox.styled";
import TextMessage from "./TextboxMessage";

/*
  Component Name: In App Text Box
  Location: Lockstep Design System > In-app Textbox
  Refer: https://www.figma.com/file/jzJcFwWrxdG6T26jGmR406/Lockstep-DSM?type=design&node-id=6643-117102&t=IaCgFy1RAv4ea7rX-0
 */
const Textbox: React.FC<TextboxProps> = ({ open = false, variant = "info", message }) => {
  // return if closed
  if (!open) {
    return <></>;
  }

  return (
    <ThemeProvider theme={theme}>
      <Banner variant={variant}>
        <TextMessage variant={variant}>{message.ele}</TextMessage>
      </Banner>
    </ThemeProvider>
  );
};

export default Textbox;

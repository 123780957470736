import React from "react";
import useWorkspaceConfigurations from "../../../../hooks/useWorkspaceConfigurations";
import { attachmentsClientV2 } from "../../../../db/version2Accessor";
import { DEFAULT_NUMERIC_VALUES } from "../../../../constants/NumericConstants";
import { AlertContext } from "../../../../contexts/AlertContext";
import { UPLOAD_FAILURE, UPLOAD_SUCCESS } from "../../../../constants/config";

// provides interface for uploading single or multiple attachments and returns upload logs
const useAttachmentFilesUploader = () => {
  const { selectedWorkspace } = useWorkspaceConfigurations();
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const successToast: ToastOptions = { open: true, severity: "success", message: UPLOAD_SUCCESS };
  const failureToast: ToastOptions = { open: true, severity: "error", message: UPLOAD_FAILURE };

  const processLogs = React.useCallback((files: File[], response: APIResponse[]) => {
    const log: { successes: { file: File; response: any }[]; failures: { file: File; response: any }[] } = { successes: [], failures: [] };

    // log status for uploads
    files.forEach((file: File, idx: number) => {
      if (response[idx].success) {
        log.successes.push({ file, response: response[idx] });
      } else {
        log.failures.push({ file, response: response[idx] });
      }
    });

    return log;
  }, []);

  const handleUpload = React.useCallback(async (file: File | string) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("workspace_id", selectedWorkspace?.id?.toString());

    let res: any;
    try {
      res = await attachmentsClientV2.postAttachment(formData);
    } catch (error) {
      res = error;
    }
    return res;
  }, []);

  const handleSingleFileUpload = React.useCallback(async (newFile: File) => {
    let toastOptions = successToast;
    let uploadLog;
    try {
      const res = await handleUpload(newFile);

      uploadLog = processLogs([newFile], [res]);

      if (uploadLog.failures.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
        toastOptions = failureToast;
      }
    } finally {
      setToastOptions(toastOptions);
    }
    return uploadLog;
  }, []);

  const handleMultipleFileUpload = React.useCallback(async (newFiles: File[]) => {
    let toastOptions = successToast;
    let uploadLog;
    try {
      // wait for all uploads to finish
      const res = await Promise.all(newFiles.map((file: File) => handleUpload(file)));

      uploadLog = processLogs(newFiles, res);

      if (uploadLog.failures.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO) {
        toastOptions = failureToast;
      }
    } finally {
      setToastOptions(toastOptions);
    }
    return uploadLog;
  }, []);

  return {
    handleSingleFileUpload,
    handleMultipleFileUpload,
  };
};

export default useAttachmentFilesUploader;

import React from "react";
import SecondaryActivity from "../../SecondaryActivity/SecondaryActivity";
import { SecondaryType } from "../../SecondaryActivity/SecondaryActivityTypes";
import { ClosedProps } from "../ActivityItemVariantTypes";
import { useTranslation } from "react-i18next";

const Closed: React.FC<ClosedProps> = ({ creator, countryCode, isAutoClosed }) => {
  const { t } = useTranslation();
  return (
    <SecondaryActivity
      variant={SecondaryType.Base}
      creator={{
        ...creator,
        message: isAutoClosed
          ? t("activityDetails.feedItems.closedActiviy.autoClosedMessage", { ns: "activities" })
          : t("activityDetails.feedItems.closedActiviy.closedMessage", { ns: "activities" }),
      }}
      countryCode={countryCode}
    />
  );
};

export default Closed;

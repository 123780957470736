import { Liquid } from "liquidjs";
import { IData, TemplateTypes, viewType } from "./TemplateTypes";
import InvoiceCopy from "./InvoiceCopy";
import InvoicePastDueReminder from "./InvoicePastDueReminder";
import InvoiceSecondReminder from "./InvoiceSecondReminder";
import RequestInvoicePaymentStatus from "./RequestInvoicePaymentStatus";
import AccountPastDueReminder from "./AccountPastDueReminder";
import AccountPastDueSecondReminder from "./AccountPastDueSecondReminder";
import RequestAccountPaymentStatus from "./RequestAccountPaymentStatus";
import PaymentConfirmation from "./PaymentConfirmation";
import PaymentConfirmationRequest from "./PaymentConfirmationRequest";
import APTaxExemptCertificate from "./APTaxExemptCertificate";
import ARTaxExemptCertificate from "./ARTaxExemptCertificate";
import W9Form from "./W9Form";
import BillReceiptNotification from "./BillReceiptNotification";
import RequestCopyBill from "./RequestCopyBill";
import ActionRequiredForBill from "./ActionRequiredForBill";
import BillPaymentScheduled from "./BillPaymentScheduled";
import PaymentRemittanceInfo from "./PaymentRemittanceInfo";
import PaymentApplicationDiscrepancy from "./PaymentApplicationDiscrepancy";
import SupportDocuments from "./SupportDocuments";
import RequestForW9 from "./RequestForW9";
import RequestForBankInfo from "./RequestForBankInfo";
import ARApprovalRequest from "./ARApprovalRequest";
import APApprovalRequest from "./APApprovalRequest";
import VendorProfileRequest from "./VendorProfileRequest";
import ShareAPProfile from "./ShareAPProfile";
import ShareARProfile from "./ShareARProfile";
import CustomerProfileRequest from "./CustomerProfileRequest";
import CreateCustomerProfile from "./CreateCustomerProfile";
import CreateVendorProfile from "./CreateVendorProfile";
import UpdateCustomerProfile from "./UpdateCustomerProfile";
import UpdateVendorProfile from "./UpdateVendorProfile";

export type TemplateDefinitionProps = {
  label: string;
  displayText: string;
  id: string;
};

export type FormatedTemplateProps = {
  label: string;
  displayText: string;
  id: string;
  subjectTpl: string;
};

/**
 * The template factory pattern which is used to create
 * and instance of template.
 */
class TemplateFactory {
  /**
   * The instance of liquid template engine
   */
  protected liquid;

  /**
   * The Map of all templates
   */
  protected templateObjectsMap;

  /**
   * Constructor of TemplateFactory
   */
  constructor(templateMap: Map<string, Map<TemplateTypes, any>>) {
    this.templateObjectsMap = templateMap;
    this.liquid = new Liquid({ cache: true });
  }

  setTemplateObjectsMap(templateMap: Map<string, Map<TemplateTypes, any>>) {
    this.templateObjectsMap = templateMap;
  }

  /**
   * Concrete function which used to create the
   * instance of template class base on give template type.
   *
   * @param {String} templateType The type of template is going to initiated.
   * @param {String} workSpace The work space which is currently active.
   * @returns {Class} return the instance of the template class.
   */
  createTemplateObjects(templateList: Array<any>): Array<any> {
    const templateObjectsList = templateList.map((template) => {
      switch (template.template_code) {
        case TemplateTypes.INVOICE_COPY:
          return new InvoiceCopy(template.workspace_type, template, this.liquid);
        case TemplateTypes.INVOICE_PAST_DUE_REMINDER:
          return new InvoicePastDueReminder(template.workspace_type, template, this.liquid);
        case TemplateTypes.INVOICE_SECOND_REMINDER:
          return new InvoiceSecondReminder(template.workspace_type, template, this.liquid);
        case TemplateTypes.REQUEST_FOR_INVOICE_PAYMENT_STATUS:
          return new RequestInvoicePaymentStatus(template.workspace_type, template, this.liquid);
        case TemplateTypes.ACCOUNT_PAST_DUE_REMINDER:
          return new AccountPastDueReminder(template.workspace_type, template, this.liquid);
        case TemplateTypes.ACCOUNT_PAST_DUE_SECOND_REMINDER:
          return new AccountPastDueSecondReminder(template.workspace_type, template, this.liquid);
        case TemplateTypes.REQUEST_FOR_ACCOUNT_PAYMENT_STATUS:
          return new RequestAccountPaymentStatus(template.workspace_type, template, this.liquid);
        case TemplateTypes.PAYMENT_CONFIRMATION:
          return new PaymentConfirmation(template.workspace_type, template, this.liquid);
        case TemplateTypes.AR_TAX_EXEMPT_CERTIFICATE:
          return new ARTaxExemptCertificate(template.workspace_type, template, this.liquid);
        case TemplateTypes.AP_TAX_EXEMPT_CERTIFICATE:
          return new APTaxExemptCertificate(template.workspace_type, template, this.liquid);
        case TemplateTypes.W9_FORM:
          return new W9Form(template.workspace_type, template, this.liquid);
        case TemplateTypes.BILL_RECEIPT_NOTIFICATION:
          return new BillReceiptNotification(template.workspace_type, template, this.liquid);
        case TemplateTypes.REQUEST_COPY_BILL:
          return new RequestCopyBill(template.workspace_type, template, this.liquid);
        case TemplateTypes.ACTION_REQUIRED_BILL:
          return new ActionRequiredForBill(template.workspace_type, template, this.liquid);
        case TemplateTypes.BILL_PAYMENT_SCHEDULED:
          return new BillPaymentScheduled(template.workspace_type, template, this.liquid);
        //Disabling as Product needs more refinement
        // case TemplateTypes.ACCOUNT_PAYMENT_SCHEDULED:
        //   return new AccountPaymentScheduled(workSpace, template , this.liquid);
        case TemplateTypes.PAYMENT_CONFIRMATION_REQUEST:
          return new PaymentConfirmationRequest(template.workspace_type, template , this.liquid);
        case TemplateTypes.PAYMENT_REMITTANCE_INFO:
          return new PaymentRemittanceInfo(template.workspace_type, template, this.liquid);
        case TemplateTypes.PAYMENT_APPLICATION_DISCREPANCY:
          return new PaymentApplicationDiscrepancy(template.workspace_type, template, this.liquid);
        case TemplateTypes.SUPPORTING_DOCUMENTS:
          return new SupportDocuments(template.workspace_type, template, this.liquid);
        case TemplateTypes.REQUEST_W9:
          return new RequestForW9(template.workspace_type, template, this.liquid);
        case TemplateTypes.REQUEST_BANK_INFO:
          return new RequestForBankInfo(template.workspace_type, template, this.liquid);
        case TemplateTypes.AR_APPROVAL_REQUEST:
          return new ARApprovalRequest(template.workspace_type, template, this.liquid);
        case TemplateTypes.AP_APPROVAL_REQUEST:
          return new APApprovalRequest(template.workspace_type, template, this.liquid);
        case TemplateTypes.AP_VENDOR_PROFILE_REQUEST:
          return new VendorProfileRequest(template.workspace_type, template, this.liquid);
        case TemplateTypes.AP_SHARE_PROFILE:
          return new ShareAPProfile(template.workspace_type, template, this.liquid);
        case TemplateTypes.AR_SHARE_PROFILE:
          return new ShareARProfile(template.workspace_type, template, this.liquid);
        case TemplateTypes.AR_CUSTOMER_PROFILE_REQUEST:
          return new CustomerProfileRequest(template.workspace_type, template, this.liquid);
        case TemplateTypes.AR_CREATE_CUSTOMER_PROFILE:
          return new CreateCustomerProfile(template.workspace_type, template, this.liquid);
        case TemplateTypes.AP_CREATE_VENDOR_PROFILE:
          return new CreateVendorProfile(template.workspace_type, template, this.liquid);
        case TemplateTypes.AR_UPDATE_CUSTOMER_PROFILE:
          return new UpdateCustomerProfile(template.workspace_type, template, this.liquid);
        case TemplateTypes.AP_UPDATE_VENDOR_PROFILE:
          return new UpdateVendorProfile(template.workspace_type, template, this.liquid);
        default:
          return null;
      }
    });
    return templateObjectsList;
  }

  /**
   * Convert list data  of Map<lang,Map(template,any)> into Map<lang,[all screen specific template]>
   * @param allTemplateList: all fetched list of template
   * @param allTemplateList: required templates for screenwise
   * @param langList: list of enabled template language
   * @returns {Map<string ,FormatedTemplateProps[]>}
   */
  getTemplateSubjectList(
    allTemplateList: Map<string, Map<TemplateTypes, any>>,
    screenSpecificTempList: string[],
    langList: templateLanguage[]
  ): Map<string, FormatedTemplateProps[]> {
    const finalMap = new Map<string, FormatedTemplateProps[]>();

    langList?.map((langItem: any) => {
      const finaltemp: any = [];
      screenSpecificTempList?.forEach((item: any) => {
        if (allTemplateList?.get(langItem.id)?.get(item)) {
          finaltemp.push({
            label: allTemplateList?.get(langItem.id)?.get(item)?.templateName,
            subjectTpl: allTemplateList?.get(langItem.id)?.get(item)?.templateSubject,
            displayText: "",
            id: item,
          });
        }
      });
      if (finaltemp?.length) {
        finalMap.set(langItem.id, finaltemp);
      }
    });
    return finalMap;
  }

  /**
   * Function which used to provide the template list of view supported by.
   *
   * @param {viewType} view The view from which we generate the template.
   * @param {IData} props The props which used to parse the template.
   * @param {templateLanguage[]} lang which is used to create required templates for given languages
   * @returns {Map<string ,TemplateDefinitionProps[]>}
   */
  getTemplateDefinitionsByView(view: viewType, props: IData, lang: templateLanguage[]): Map<string, TemplateDefinitionProps[]> {
    let templateItems: Map<string, FormatedTemplateProps[]>;

    switch (view) {
      case viewType.AR_DASHBOARD_AGING:
      case viewType.AR_DASHBOARD_RECEIVABLES_RISK:
      case viewType.AR_TRANSACTION_INVOICES:
      case viewType.AR_CONNECTION_CUSTOMER_OPEN_INVOICES:
        templateItems = this.getTemplateSubjectList(
          this.templateObjectsMap,
          [
            TemplateTypes.INVOICE_COPY,
            TemplateTypes.INVOICE_PAST_DUE_REMINDER,
            TemplateTypes.INVOICE_SECOND_REMINDER,
            TemplateTypes.REQUEST_FOR_INVOICE_PAYMENT_STATUS,
            TemplateTypes.AR_CREATE_CUSTOMER_PROFILE,
            TemplateTypes.AR_UPDATE_CUSTOMER_PROFILE,
          ],
          lang
        );
        break;
      case viewType.AR_DASHBOARD_DAYS_SALES_OUTSTANDING:
      case viewType.AR_ALL_CUSTOMER_CONNECTIONS:
        templateItems = this.getTemplateSubjectList(
          this.templateObjectsMap,
          [
            TemplateTypes.ACCOUNT_PAST_DUE_REMINDER,
            TemplateTypes.ACCOUNT_PAST_DUE_SECOND_REMINDER,
            TemplateTypes.REQUEST_FOR_ACCOUNT_PAYMENT_STATUS,
            TemplateTypes.AR_CREATE_CUSTOMER_PROFILE,
            TemplateTypes.AR_UPDATE_CUSTOMER_PROFILE,
          ],
          lang
        );
        break;
      case viewType.AP_ALL_VENDOR_CONNECTIONS:
        templateItems = this.getTemplateSubjectList(
          this.templateObjectsMap,
          [TemplateTypes.AP_CREATE_VENDOR_PROFILE, TemplateTypes.AP_UPDATE_VENDOR_PROFILE],
          lang
        );
        break;
      case viewType.AR_DASHBOARD_RECEIVABLES_SUMMARY_COLLECTED:
      case viewType.AR_TRANSACTION_PAYMENTS:
      case viewType.AR_CONNECTION_CUSTOMER_PAYMENTS:
        templateItems = this.getTemplateSubjectList(
          this.templateObjectsMap,
          [TemplateTypes.PAYMENT_CONFIRMATION, TemplateTypes.AR_CREATE_CUSTOMER_PROFILE, TemplateTypes.AR_UPDATE_CUSTOMER_PROFILE],
          lang
        );
        break;
      case viewType.AR_DASHBOARD_RECEIVABLES_SUMMARY_INVOICED:
        templateItems = this.getTemplateSubjectList(this.templateObjectsMap, [TemplateTypes.INVOICE_COPY], lang);
        break;
      case viewType.AR_CONNECTION_CUSTOMER_ACTIVITIES:
        templateItems = this.getTemplateSubjectList(
          this.templateObjectsMap,
          [
            TemplateTypes.AR_TAX_EXEMPT_CERTIFICATE,
            TemplateTypes.W9_FORM,
            TemplateTypes.AR_CREATE_CUSTOMER_PROFILE,
            TemplateTypes.AR_UPDATE_CUSTOMER_PROFILE,
          ],
          lang
        );
        break;
      case viewType.AR_CONNECTION_CUSTOMER_CLOSE_INVOICES:
      case viewType.AR_TRANSACTION_INVOICES_CLOSED:
        templateItems = this.getTemplateSubjectList(
          this.templateObjectsMap,
          [TemplateTypes.INVOICE_COPY, TemplateTypes.AR_CREATE_CUSTOMER_PROFILE, TemplateTypes.AR_UPDATE_CUSTOMER_PROFILE],
          lang
        );
        break;
      case viewType.AP_DASHBOARD_AGING:
      case viewType.AP_TRANSACTION_BILLS:
      case viewType.AP_CONNECTION_VENDOR_OPEN_BILLS:
        if (view === viewType.AP_TRANSACTION_BILLS || view === viewType.AP_CONNECTION_VENDOR_OPEN_BILLS) {
          templateItems = this.getTemplateSubjectList(
            this.templateObjectsMap,
            [
              TemplateTypes.BILL_RECEIPT_NOTIFICATION,
              TemplateTypes.REQUEST_COPY_BILL,
              TemplateTypes.ACTION_REQUIRED_BILL,
              TemplateTypes.BILL_PAYMENT_SCHEDULED,
              TemplateTypes.SUPPORTING_DOCUMENTS,
              TemplateTypes.AP_CREATE_VENDOR_PROFILE,
              TemplateTypes.AP_UPDATE_VENDOR_PROFILE,
            ],
            lang
          );
        } else {
          templateItems = this.getTemplateSubjectList(
            this.templateObjectsMap,
            [
              TemplateTypes.BILL_RECEIPT_NOTIFICATION,
              TemplateTypes.REQUEST_COPY_BILL,
              TemplateTypes.ACTION_REQUIRED_BILL,
              TemplateTypes.BILL_PAYMENT_SCHEDULED,
              TemplateTypes.AP_CREATE_VENDOR_PROFILE,
              TemplateTypes.AP_UPDATE_VENDOR_PROFILE,
            ],
            lang
          );
        }
        break;
      case viewType.AP_DASHBOARD_SUMMARY_PAID:
      case viewType.AP_TRANSACTION_PAYMENTS:
      case viewType.AP_CONNECTION_VENDOR_PAYMENTS:
        templateItems = this.getTemplateSubjectList(
          this.templateObjectsMap,
          [
            TemplateTypes.PAYMENT_REMITTANCE_INFO,
            TemplateTypes.PAYMENT_APPLICATION_DISCREPANCY,
            TemplateTypes.AP_CREATE_VENDOR_PROFILE,
            TemplateTypes.AP_UPDATE_VENDOR_PROFILE,
            TemplateTypes.PAYMENT_CONFIRMATION_REQUEST
          ],
          lang
        );
        break;

      case viewType.AP_CONNECTION_VENDOR_ACTIVITY:
        templateItems = this.getTemplateSubjectList(
          this.templateObjectsMap,
          [
            TemplateTypes.REQUEST_W9,
            TemplateTypes.REQUEST_BANK_INFO,
            TemplateTypes.AP_TAX_EXEMPT_CERTIFICATE,
            TemplateTypes.AP_CREATE_VENDOR_PROFILE,
            TemplateTypes.AP_UPDATE_VENDOR_PROFILE,
          ],
          lang
        );
        break;
      case viewType.AP_CONNECTION_VENDOR_CLOSED_BILLS:
      case viewType.AP_TRANSACTION_BILLS_CLOSED:
        templateItems = this.getTemplateSubjectList(
          this.templateObjectsMap,
          [TemplateTypes.REQUEST_COPY_BILL, TemplateTypes.AP_CREATE_VENDOR_PROFILE, TemplateTypes.AP_UPDATE_VENDOR_PROFILE],
          lang
        );
        break;
      case viewType.AR_APPROVAL_REQUEST_ACTIVITY:
        templateItems = this.getTemplateSubjectList(this.templateObjectsMap, [TemplateTypes.AR_APPROVAL_REQUEST], lang);
        break;
      case viewType.AP_APPROVAL_REQUEST_ACTIVITY:
        templateItems = this.getTemplateSubjectList(this.templateObjectsMap, [TemplateTypes.AP_APPROVAL_REQUEST], lang);
        break;
      case viewType.VENDOR_ONBOARD_REQUEST_ACTIVITY:
        templateItems = this.getTemplateSubjectList(this.templateObjectsMap, [TemplateTypes.AP_VENDOR_PROFILE_REQUEST], lang);
        break;
      case viewType.AP_SHARE_PROFILE_ACTIVITY:
        templateItems = this.getTemplateSubjectList(this.templateObjectsMap, [TemplateTypes.AP_SHARE_PROFILE], lang);
        break;
      case viewType.AR_SHARE_PROFILE_ACTIVITY:
        templateItems = this.getTemplateSubjectList(this.templateObjectsMap, [TemplateTypes.AR_SHARE_PROFILE], lang);
        break;
      case viewType.AR_CUSTOMER_PROFILE_REQUEST_ACTIVITY:
        templateItems = this.getTemplateSubjectList(this.templateObjectsMap, [TemplateTypes.AR_CUSTOMER_PROFILE_REQUEST], lang);
        break;
      case viewType.AP_TRANSACTION_ADVANCE_PAYMENTS:
        templateItems = this.getTemplateSubjectList(
          this.templateObjectsMap,
          [
            TemplateTypes.AP_CREATE_VENDOR_PROFILE,
            TemplateTypes.AP_UPDATE_VENDOR_PROFILE,
            TemplateTypes.PAYMENT_CONFIRMATION_REQUEST
          ],
          lang
        );
        break;
      default:
        templateItems = new Map();
    }

    //parsing the content for the dynamic field
    const tpMapList = new Map<string, TemplateDefinitionProps[]>();
    lang?.map((language) => {
      const tplList: TemplateDefinitionProps[] = [];
      const tempItems: FormatedTemplateProps[] = templateItems.get(language.id) ?? [];
      for (let i = 0; i < tempItems?.length; i++) {
        const templateItem: FormatedTemplateProps = tempItems[i];
        let displayText = "";
        try {
          displayText = this.liquid.parseAndRenderSync(templateItem?.subjectTpl ?? ("" as string), props);
        } catch (error) {
          displayText = templateItem?.subjectTpl;
        }
        tplList.push({
          label: templateItem.label,
          displayText: displayText,
          id: templateItem.id,
        });
      }
      if (tplList?.length) {
        tpMapList.set(language.id, tplList);
      }
    });
    return tpMapList;
  }
}

export default TemplateFactory;

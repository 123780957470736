import React from "react";
import { DialogProps as MuiDialogProps, Dialog as MuiDialog } from "@mui/material";
import useDialogClasses from "./useDialogClasses";

interface DialogProps extends MuiDialogProps {
  overrideDialogClasses?: boolean;
}
export default function Dialog({ className, overrideDialogClasses, ...rest }: DialogProps): React.ReactElement {
  const { dialog } = useDialogClasses();
  return <MuiDialog className={overrideDialogClasses ? className : `${dialog} ${className ?? ""}`} {...rest} />;
}

import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import Like from "../../../assets/lotties/like.json";
import { NoResultIcon } from "../../library/Icons/Icons";
import { GLOBAL_SEARCH_STATIC_TEXTS } from "../../../constants/GlobalSearchConstants";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
/**
 * @param hasEmptySearchKeyword : If true then message content will be displayed for less than 3 search keywords,
 *  otherwise for empty result
 */
type NoResultFoundProps = {
  hasEmptySearchKeyword: boolean;
};
const NoResultFoundComponent: React.FC<NoResultFoundProps> = ({ hasEmptySearchKeyword }) => {
  const { t } = useTranslation();
  const emptySearchMessage = {
    title: i18next.exists("emptySearch.title", { ns: "global_search" })
      ? t("emptySearch.title", { ns: "global_search" })
      : GLOBAL_SEARCH_STATIC_TEXTS.ALL_CAUGHT_UP,
    subtitle: i18next.exists("emptySearch.subtitle", { ns: "global_search" })
      ? t("emptySearch.subtitle", { ns: "global_search" })
      : GLOBAL_SEARCH_STATIC_TEXTS.ENTER_KEYWORDS,
  };
  const emptyStateMessage = {
    title: i18next.exists("emptyState.title", { ns: "global_search" })
      ? t("emptySearch.title", { ns: "global_search" })
      : GLOBAL_SEARCH_STATIC_TEXTS.NO_RESULT_FOUND,
    subtitle: i18next.exists("emptyState.subtitle", { ns: "global_search" })
      ? t("emptySearch.subtitle", { ns: "global_search" })
      : GLOBAL_SEARCH_STATIC_TEXTS.TRY_DIFFERENT_KEYWORD,
  };

  return (
    <div className="gs-no-results-container">
      <div className="no-results-icon">{hasEmptySearchKeyword ? <Player src={Like} autoplay loop /> : <NoResultIcon />}</div>
      <p className="no-results-header">{hasEmptySearchKeyword ? emptySearchMessage.title : emptyStateMessage.title}</p>
      <p className="no-results-subheader">{hasEmptySearchKeyword ? emptySearchMessage.subtitle : emptyStateMessage.subtitle}</p>
    </div>
  );
};
export default NoResultFoundComponent;

import React from "react";
import { NoResultIcon } from "../../Icons/Icons";
import { EmptyResultContainer } from "../styled/email.template.styled";
import { EmptyResultProps } from "../types/email.template.popover.types";

/**
 * It's an custom empty message component for the empty search results
 * @param icon:[optional]- icon for the empty results
 * @param titleText:[optional]- title text
 * @param subTitleText:[optional]- subtitle text
 */
const EmptyResult: React.FC<EmptyResultProps> = ({
  icon = <NoResultIcon />,
  titleText = "No Results Found.",
  subTitleText = "We can't find a matching template, please try a different word or phrase.",
}) => {
  return (
    <EmptyResultContainer>
      <div className="header-icon">{icon}</div>
      <div className="text-content">
        <p className="title">{titleText}</p>
        <p className="sub-title">{subTitleText}</p>
      </div>
    </EmptyResultContainer>
  );
};

export default EmptyResult;

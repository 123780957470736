import DOMPurify from "dompurify";
import React from "react";
import Button from "../../library/Button/Button";
import { RequestApproval } from "../../library/Icons/Icons";
import { TextArea } from "../../library/TextArea/TextArea";
import { ApprovalComponentProps } from "./ApprovalRequestTypes";
import "./ApprovalRequest.scss";
import { Link } from "react-router-dom";
import Chip, { ChipVariant } from "../../library/Chip/Chip";
import _ from "underscore";

const ApprovalRequestComponent: React.FC<ApprovalComponentProps> = ({
  requestData: request,
  onChangeNote,
  onClickApprove,
  onClickDecline,
  isApprovalUpdating,
}) => {
  const reqVariantMap: Record<string, Record<string, string>> = {
    approved: {
      variant: "open",
      label: "Approved",
    },
    declined: {
      variant: "late91",
      label: "Declined",
    },
    sent: {
      variant: "late61",
      label: "Pending Approval",
    },
    active: {
      variant: "late61",
      label: "Pending Approval",
    },
    redirected: {
      variant: "late61",
      label: "Redirected",
    },
  };

  return (
    <div className="approval-form-container">
      <div className="left">
        <div className="icon">
          <RequestApproval />
        </div>
      </div>
      <div className="right">
        <div className="header-wrapper">
          <div className="header">
            <h3 className="req-title">{request.title}</h3>
            <div className="req-date">
              {!_.isEmpty(request.status) && !_.isUndefined(request.status) ? (
                <Chip
                  variant={reqVariantMap[request.status]?.variant as ChipVariant}
                  text={reqVariantMap[request.status]?.label.toLocaleUpperCase()}
                />
              ) : (
                <div>-</div>
              )}
              {request.date}
            </div>
          </div>
          <div className="req-message">
            <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(request.message ?? "-") }}></p>
          </div>
        </div>

        {!request.isReadonly ? (
          <>
            <TextArea onChange={onChangeNote} label="Note" placeholder="Add a note" />
            <div className="btns-wrapper">
              <Button size="lg" type="button" variant="primary" onClick={onClickApprove} disabled={isApprovalUpdating}>
                Approve
              </Button>
              <Button size="lg" type="button" variant="secondary" onClick={onClickDecline} disabled={isApprovalUpdating}>
                Decline
              </Button>
              <Link className="redirect-link" to={`/redirect-request/${request.redirectId}`}>
                Redirect Approval Request
              </Link>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ApprovalRequestComponent;

import { Divider } from "@mui/material";
import React from "react";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../../../../../../../constants/NumericConstants";
import TableUtils from "../../../../../../../../utils/TableUtils/TableUtils";
import AttachmentChip from "../../../../../../AttachmentChip/AttachmentChip";
import Button from "../../../../../../../library/Button/Button";
import { CloseDarken } from "../../../../../../../library/Icons/Icons";
import { AttachmentListProps } from "../HelperTypes";
import CustomTooltip from "../../../../../../../library/CustomTooltip/CustomTooltip";
import { AlertContext } from "../../../../../../../../contexts/AlertContext";
import { AttachmentPreview } from "../../../../../../../library/AtomicComponents/AttachmentPreview";

const AttachmentsList: React.FC<AttachmentListProps> = ({
  visible,
  items,
  onClickCloseAttachmentBox,
  children,
  setShowAttachmentList,
  showAttachmentList,
  readonly,
}) => {
  const onClickShowMore = () => {
    setShowAttachmentList(true);
  };

  const AttachmentShowMoreChip: React.FC<{ restCount: number }> = ({ restCount }) => {
    return (
      <CustomTooltip title={"Show More"}>
        <div
          className="attachment-show-more-chip"
          onClick={(e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            onClickShowMore();
          }}
        >
          <span className="attachment-text">{`${restCount} More`}</span>
        </div>
      </CustomTooltip>
    );
  };

  const onClickDownloadAll = () => TableUtils.downloadAll([...items]);

  const toastContext = React.useContext(AlertContext) as AlertContextType;

  const [openPreview, setOpenPreview] = React.useState<boolean>(false);
  const [activeAttachmentID, setActiveAttachmentID] = React.useState<string | number>("");

  const onClickPreviewClose = (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement> | KeyboardEvent) => {
    event.stopPropagation();
    setOpenPreview(false);
  };

  const onClickPreviewOpen = (event: React.MouseEvent<HTMLDivElement>, activeAttachmentID: string | number) => {
    event.stopPropagation();
    setActiveAttachmentID(activeAttachmentID);
    setOpenPreview(!openPreview);
  };

  const onClickDownload = (event: React.MouseEvent<HTMLTableRowElement | HTMLDivElement | HTMLButtonElement>, attachments: AttachmentItem[] | BlobAttachmentItem[]) => {
    event.stopPropagation();
    TableUtils.downloadAll(attachments, toastContext?.setToastOptions);
  };

  return visible && items.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO ? (
    <div className="attachments-wrapper">
      <div className="list-wrapper">
        <div className="attachments-container">
          {items?.length <= NUMERIC_VALUES.CONSTANT_THREE || showAttachmentList ? (
            items.map((item: AttachmentItem) => <AttachmentChip readonly={readonly} key={item.file_name} attachment={item} onClickPreviewOpen={onClickPreviewOpen}/>)
          ) : (
            <>
              <AttachmentChip readonly={readonly} key={items[0].file_name} attachment={items[0]} onClickPreviewOpen={onClickPreviewOpen} />
              <AttachmentChip readonly={readonly} key={items[1].file_name} attachment={items[1]} onClickPreviewOpen={onClickPreviewOpen} />
              <AttachmentShowMoreChip restCount={items.length - NUMERIC_VALUES.CONSTANT_TWO} />
            </>
          )}
        </div>
      </div>
      {openPreview && (
        <AttachmentPreview
          openPreview={openPreview}
          activeAttachmentID={activeAttachmentID}
          handleClose={onClickPreviewClose}
          attachments={items}
          onClickDownload={onClickDownload}
        />
      )}
      <div className="attachments-action">
        <div
          className="close-container"
          onClick={(e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            onClickCloseAttachmentBox();
          }}
        >
          <CloseDarken />
        </div>
        <div className="attachments-button-wrapper">
          {/* additional children */}
          {children && (
            <>
              {children}
              <Divider flexItem orientation="vertical" />
            </>
          )}
          <Button
            size="sm"
            variant="secondary"
            alignIcon="left"
            className="btn btn-secondary downlaod-all"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              onClickDownloadAll();
            }}
          >
            Download All
          </Button>
        </div>
      </div>
    </div>
  ) : null;
};

export default AttachmentsList;

import APIClient from "../APIClient";
const PLATFORM_API_BASE = process.env.REACT_APP_API_ENDPOINT_PLATFORM_V1;

export class InvoicesClient {
  /**
   * * GET on /api/v1/Invoices/{id}
   * @param id - Database ID associated with an invoice
   * @param include
   * @returns Promise of type InvoiceModel or Exception
   */
  getInvoice(id: string, include?: string): Promise<InvoiceModel> {
    let url = `${PLATFORM_API_BASE}/Invoices/{id}?`;

    if (id === undefined || id === null) {
      throw new Error("The parameter 'id' must be defined.");
    }

    url = url.replace("{id}", encodeURIComponent("" + id));

    if (include === null) {
      throw new Error("The parameter 'include' cannot be null.");
    }

    if (include !== undefined) {
      url += "include=" + encodeURIComponent("" + include) + "&";
    }

    url = url.replace(/[?&]$/, "");

    return APIClient.get(url).then((response) => {
      return response.data;
    });
  }

  /**
   * * GET on /api/v1/Invoices/query
   * @param filter - The filter to use to select from the list of available apps (Searchlight query syntax)
   * @param include - Specify options
   * @param order - The sort order for the results (Searchlight query syntax)
   * @param pageSize - The page size for results (Defaults to 200, maximum of 1,000)
   * @param pageNumber - The page number for results (Defaults to 0)
   * @returns Promise of type InvoiceModelFetchResult or Exception
   */
  getInvoices(filter?: string, include?: string, order?: string, pageSize?: number, pageNumber?: number): Promise<InvoiceModelFetchResult> {
    const url = `${PLATFORM_API_BASE}/Invoices/query`;

    const config = {
      params: {
        filter,
        include,
        order,
        pageSize,
        pageNumber,
      },
    };

    return APIClient.get(url, config).then((response) => {
      return response.data;
    });
  }

  /**
   * * GET on /api/v1/Invoices/views/summary
   * @param filter - The filter to use to select from the list of available apps (Searchlight query syntax)
   * @param order - The sort order for the results (Searchlight query syntax)
   * @param pageSize - The page size for results (Defaults to 200, maximum of 1,000)
   * @param pageNumber - The page number for results (Defaults to 0)
   * @returns Promise of type InvoiceSummaryModelFetchResult or Exception
   */
  getInvoiceSummaries(
    filter?: string,
    include?: string,
    order?: string,
    pageSize?: number,
    pageNumber?: number
  ): Promise<InvoiceSummaryModelFetchResult> {
    const url = `${PLATFORM_API_BASE}/Invoices/views/summary`;

    const config = {
      params: {
        filter,
        include,
        order,
        pageSize,
        pageNumber,
      },
    };

    return APIClient.get(url, config).then((response) => {
      return response.data;
    });
  }

  /**
   * * GET on /api/v1/Invoices/views/summary
   * @param filter - The filter to use to select from the list of available apps (Searchlight query syntax)
   * @param include
   * @param order - The sort order for the results (Searchlight query syntax)
   * @param pageSize - The page size for results (Defaults to 200, maximum of 1,000)
   * @param pageNumber - The page number for results (Defaults to 0)
   * @returns Promise of type InvoiceSummaryModelFetchResult or Exception
   */
  queryAtRiskSummaries(
    filter?: string,
    include?: string,
    order?: string,
    pageSize?: number,
    pageNumber?: number
  ): Promise<AtRiskInvoiceSummaryModelFetchResult> {
    const url = `${PLATFORM_API_BASE}/invoices/views/at-risk-summary`;

    const options = {
      params: {
        filter,
        include,
        order,
        pageSize,
        pageNumber,
      },
    };

    return APIClient.get(url, options).then((response) => {
      return response.data;
    });
  }

  getTransactionPDF(invoiceId: UUID) {
    const url = `${PLATFORM_API_BASE}/invoices/${invoiceId}/pdf`;
    return APIClient.get(url, { responseType: "blob" }).then((response) => response.data);
  }

  /**
   * * HEAD on /api/v1/invoices/${invoiceId}/pdf
   * @param invoiceId - UUID of Invoice
   * @returns Promise with the Header Data from where we fetch the HTTP status
   */
  headTransactionPDF(invoiceId: UUID) {
    const url = `${PLATFORM_API_BASE}/invoices/${invoiceId}/pdf`;
    return APIClient.head(url).then((response) => response.status);
  }

  /**
   * * GET on /api/v1/credit-memos-applied/query
   * @param filter - The filter to use to select from the list of available apps (Searchlight query syntax)
   * @param include - Specify options
   * @param order - The sort order for the results (Searchlight query syntax)
   * @param pageSize - The page size for results (Defaults to 200, maximum of 1,000)
   * @param pageNumber - The page number for results (Defaults to 0)
   * @returns Promise of type InvoiceModelFetchResult or Exception
   */
  getCreditMemos(filter?: string, include?: string, order?: string, pageSize?: number, pageNumber?: number): Promise<CreditMemoFetchResult> {
    const url = `${PLATFORM_API_BASE}/credit-memos-applied/query`;
    const config = {
      params: {
        filter,
        include,
        order,
        pageSize,
        pageNumber,
      },
    };

    return APIClient.get(url, config).then((response) => {
      return response.data;
    });
  }
}

import React from "react";
import Button from "../../../../../../library/Button/Button";
import { Calendar } from "../../../../../../library/Icons/Icons";
import PrimaryActivity from "../../PrimaryActivity/PrimaryActivity";
import { NudgeProps } from "../ActivityItemVariantTypes";
import * as Labels from "../../../../../../../constants/config";

const NudgeActivity: React.FC<NudgeProps> = ({ onClickNudge, enableNudgeAgain, ...props }) => {
  return (
    <PrimaryActivity
      {...{ ...props, creator: { ...props.creator, message: Labels.HEADER_NUDGE } }}
      icon={<Calendar />}
      FLAGS={{ REPLY_OPTIONS: true, TIME_SAVING_INDICATOR: true }}
    >
      {enableNudgeAgain && (
        <Button variant="primary" size="sm" onClick={onClickNudge}>
          {Labels.BTN_NUDGE_AGAIN}
        </Button>
      )}
    </PrimaryActivity>
  );
};

export default NudgeActivity;

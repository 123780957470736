import React from "react";
import { FEATURE_FLAGS, FeatureFlagContext, FeatureFlagProviderType } from "../contexts/FeatureFlagContext";
import i18n from "../i18n/i18n";
import { WorkspaceType } from "../types/enums";
import _ from "underscore";
import { OptionType, OptionsListType } from "../types/schema/configurations.types";

/** This hook is implemented to generate the required column configs for the tables accross all view
 * @param columnsList : list of columns of type OptionsListType for the tables
 * @param requiredWorkspace : it generate the required coulmn configs for the given workspace
 * @param currentView: the view for which we want to generate columns
 * @returns array of OptionType excluding ["workspacesApplicable", "featureFlagsApplicable", "viewsApplicable", "styles"] properties
 */
const useProcessTableConfigs = (columnsList: OptionsListType, requiredWorkspace: WorkspaceType, currentView: string) => {
  const { isEnabled } = React.useContext(FeatureFlagContext) as FeatureFlagProviderType;
  const columns = React.useMemo(() => {
    return columnsList
      .filter((item: OptionType) => {
        const needFeatureFlagCheck = !!item.featureFlagsApplicable;
        const isFeatureEnabled = needFeatureFlagCheck ? isEnabled(item.featureFlagsApplicable as FEATURE_FLAGS) : true;
        return isFeatureEnabled && item?.workspacesApplicable?.includes(requiredWorkspace) && item.viewsApplicable?.includes(currentView);
      })
      .map((item: OptionType) => {
        const itemStyles = _.omit(
          item?.styles?.filter(
            (style: any) => style?.viewsApplicable?.includes(currentView) && style?.workspacesApplicable?.includes(requiredWorkspace)
          )[0],
          ["viewsApplicable", "workspacesApplicable"]
        );
        return { ..._.omit(item, ["workspacesApplicable", "featureFlagsApplicable", "viewsApplicable", "styles"]), ...itemStyles };
      });
  }, [i18n.language]);
  return columns;
};

export default useProcessTableConfigs;

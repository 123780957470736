import React from "react";
import Loading from "../../../../../library/Loading/Loading";
import InfiniteScroll from "react-infinite-scroll-component";
import { DEFAULT_NUMERIC_VALUES } from "../../../../../../constants/NumericConstants";
import Menu from "../../../../../library/Menu/Menu";
import MenuItem from "../../../../../library/MenuItem/MenuItem";
import DropMenu from "../../../../../library/DropMenu/DropMenu";
import { SmartText } from "../../../../../library/SmartText";
import useLocale from "../../../../../../hooks/useLocale";

interface RelatedAttachmentComponentInterface {
  attachments: Array<AttachmentItem>;
  pageDetails: {
    total_records: number;
    page: number;
    page_size: number;
  };
  fetchAttachments: () => void;
  downloadHandler: (fileUrl: string) => void;
  isLoading: boolean;
  selectedIndex: number;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
  setSortMenuAnchorEl: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
  sortMenuAnchorEl: HTMLDivElement | null;
  sortMenu: Array<{
    label: string;
    ransackQuery: string;
    sortFunction: (a: AttachmentItem, b: AttachmentItem) => number;
  }>;
}

const RelatedAttachmentComponent: React.FC<RelatedAttachmentComponentInterface> = ({
  attachments,
  pageDetails,
  fetchAttachments,
  isLoading,
  downloadHandler,
  selectedIndex,
  setSelectedIndex,
  setSortMenuAnchorEl,
  sortMenuAnchorEl,
  sortMenu,
}: RelatedAttachmentComponentInterface) => {
  const { formatDateBasedOnCountry: formatDate } = useLocale();

  return (
    <div className="rac-container">
      {isLoading && <Loading />}
      {!isLoading && attachments.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && (
        <>
          <div className="head">
            <div className="head-container">
              <DropMenu
                title={sortMenu[selectedIndex].label}
                isActive={Boolean(sortMenuAnchorEl)}
                onClick={(e: React.MouseEvent<HTMLDivElement>) => setSortMenuAnchorEl(e.currentTarget)}
                iconAlign="right"
              />
            </div>
            <Menu
              open={Boolean(sortMenuAnchorEl)}
              anchorEl={sortMenuAnchorEl}
              onClose={() => setSortMenuAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              {sortMenu.map((value: { label: string; sortFunction: (a: AttachmentItem, b: AttachmentItem) => number }, index: number) => {
                return (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      setSelectedIndex(index);
                      setSortMenuAnchorEl(null);
                    }}
                  >
                    {value.label}
                  </MenuItem>
                );
              })}
            </Menu>
          </div>
          <InfiniteScroll
            dataLength={attachments.length}
            next={fetchAttachments}
            hasMore={attachments.length < pageDetails.total_records}
            loader={attachments.length < pageDetails.total_records ? <p className="body2 loaderPadding">Fetching More Attachments...</p> : ""}
            height={"37.375rem"}
            scrollableTarget="infinite-scroll"
          >
            {attachments.map((item) => (
              <div key={item.id} className="activity-attachment-item" onClick={() => downloadHandler(item.file_url)}>
                <div className="head">
                  <div className="smarty-container">
                    <SmartText className="body1" title={item.file_name} />{" "}
                  </div>
                  <p className="body1">{item.owner?.name ?? ""}</p>
                </div>
                <div className="body">
                  <p className="caption">{item.file_type}</p>
                  <p className="caption">{formatDate(item.created_at as string)}</p>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        </>
      )}
      {!isLoading && attachments.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && <p className="no-attach">No Related Attachments</p>}
    </div>
  );
};

export default RelatedAttachmentComponent;

import i18n from "../../../i18n/i18n";
const { t } = i18n;

const E = () => {
  return {
    "global-search-suggestions": {
      searchBoxPlaceholder: t("globalSearchSuggestions.searchBoxPlaceholder", { ns: "global_search" }),
      connectionType: "vendors",
      invoiceType: "bills",
      predefinedCategorySet: ["all", "sent_einvoices", "received_einvoices"],
      displayLabels: {
        all: t("displayLabels.all", { ns: "global_search" }),
        received_einvoices: t("displayLabels.receivedEInvoices", { ns: "global_search" }),
        sent_einvoices: t("displayLabels.sentEInvoices", { ns: "global_search" }),
      },
      dataSelectors: {
        received_einvoices: {
          id: "id",
          header: { type: "string", key: "subject" },
          subheader: { type: "string", key: "from" },
          einvoice_status: { type: "string", key: "einvoice_status" },
          info: { type: "isoDate", key: "date" },
          moreDetails: { type: "", key: "" },
        },
        sent_einvoices: {
          id: "id",
          header: { type: "string", key: "subject" },
          subheader: { type: "string", key: "from" },
          einvoice_status: { type: "string", key: "einvoice_status" },
          info: { type: "isoDate", key: "date" },
          moreDetails: { type: "", key: "" },
        },
      },
      tabs: {
        [t("displayLabels.all", { ns: "global_search" })]: "all",
        [t("displayLabels.receivedEInvoices", { ns: "global_search" })]: "received_einvoices",
        [t("displayLabels.sentEInvoices", { ns: "global_search" })]: "sent_einvoices",
      },
    },
    "global-search-results": {
      table: {
        config: {
          column: {
            received_einvoices: [
              {
                header: t("globalSearchResults.table.received_einvoices.eInvoicingStatus", { ns: "global_search" }),
                fieldName: "einvoice_status",
                fieldType: "HTMLElement",
                elementType: "einvoice_status",
                width: "15%",
                alignment: "left",
              },
              {
                header: t("globalSearchResults.table.received_einvoices.from", { ns: "global_search" }),
                fieldName: "from",
                fieldType: "string",
                width: "20%",
                alignment: "left",
              },
              {
                header: t("globalSearchResults.table.received_einvoices.invoice_no", { ns: "global_search" }),
                fieldName: "subject",
                fieldType: "string",
                width: "15%",
                alignment: "left",
              },
              {
                header: t("globalSearchResults.table.received_einvoices.date", { ns: "global_search" }),
                fieldName: "due_date",
                fieldType: "isoDate",
                width: "20%",
                alignment: "left",
              },
              {
                header: t("globalSearchResults.table.received_einvoices.txn_amount", { ns: "global_search" }),
                fieldName: "txn_amount",
                fieldType: "currency",
                width: "20%",
                alignment: "left",
              },
              {
                header: t("globalSearchResults.table.received_einvoices.pretax_amount", { ns: "global_search" }),
                fieldName: "pretax_amount",
                fieldType: "currency",
                width: "20%",
                alignment: "left",
              }
            ],
            sent_einvoices: [
              {
                header: t("globalSearchResults.table.sent_einvoices.eInvoicingStatus", { ns: "global_search" }),
                fieldName: "einvoice_status",
                fieldType: "HTMLElement",
                elementType: "einvoice_status",
                width: "15%",
                alignment: "left",
              },
              {
                header: t("globalSearchResults.table.sent_einvoices.to", { ns: "global_search" }),
                fieldName: "from",
                fieldType: "string",
                width: "20%",
                alignment: "left",
              },
              {
                header: t("globalSearchResults.table.sent_einvoices.invoice_no", { ns: "global_search" }),
                fieldName: "subject",
                fieldType: "string",
                width: "15%",
                alignment: "left",
              },
              {
                header: t("globalSearchResults.table.sent_einvoices.date", { ns: "global_search" }),
                fieldName: "due_date",
                fieldType: "isoDate",
                width: "20%",
                alignment: "left",
              },
              {
                header: t("globalSearchResults.table.sent_einvoices.txn_amount", { ns: "global_search" }),
                fieldName: "txn_amount",
                fieldType: "currency",
                width: "20%",
                alignment: "left",
              },
              {
                header: t("globalSearchResults.table.sent_einvoices.pretax_amount", { ns: "global_search" }),
                fieldName: "pretax_amount",
                fieldType: "currency",
                width: "20%",
                alignment: "left",
              }
            ],
          },
        },
      },
      filters: {
        received_einvoices: [
          {
            name: t("globalSearchResults.filters.received_einvoices.from", { ns: "global_search" }),
            key: "from",
            payload: "from",
            type: "DROPDOWN_SEARCH",
            multiSelectLabel: "From",
            defaultValues: [],
          },
          // {
          //   name: t("globalSearchResults.filters.received_einvoices.invoice_no", { ns: "global_search" }),
          //   key: "subject",
          //   payload: "subject",
          //   type: "DROPDOWN_SEARCH",
          //   multiSelectLabel: "Invoice No",
          //   defaultValues: [],
          // },
          {
            name: t("globalSearchResults.filters.received_einvoices.txn_amount", { ns: "global_search" }),
            key: "txn_amount",
            payload: "txn_amount",
            type: "NUMERIC",
            multiSelectLabel: "",
            defaultValues: [],
          },
          // {
          //   name: t("globalSearchResults.filters.received_einvoices.pretax_amount", { ns: "global_search" }),
          //   key: "pretax_amount",
          //   payload: "pretax_amount",
          //   type: "NUMERIC",
          //   multiSelectLabel: "",
          //   defaultValues: [],
          // },
          {
            name: t("globalSearchResults.filters.received_einvoices.due_date", { ns: "global_search" }),
            key: "due_date",
            payload: "due_date",
            type: "DATE",
            multiSelectLabel: "",
            defaultValues: [],
          },
        ],
        sent_einvoices: [
          {
            name: t("globalSearchResults.filters.sent_einvoices.to", { ns: "global_search" }),
            key: "from",
            payload: "from",
            type: "DROPDOWN_SEARCH",
            multiSelectLabel: "To",
            defaultValues: [],
          },
          {
            name: t("globalSearchResults.filters.sent_einvoices.invoice_no", { ns: "global_search" }),
            key: "subject",
            payload: "subject",
            type: "DROPDOWN_SEARCH",
            multiSelectLabel: "Invoice No",
            defaultValues: [],
          },
          {
            name: t("globalSearchResults.filters.sent_einvoices.txn_amount", { ns: "global_search" }),
            key: "txn_amount",
            payload: "txn_amount",
            type: "NUMERIC",
            multiSelectLabel: "",
            defaultValues: [],
          },
          // {
          //   name: t("globalSearchResults.filters.sent_einvoices.pretax_amount", { ns: "global_search" }),
          //   key: "pretax_amount",
          //   payload: "pretax_amount",
          //   type: "NUMERIC",
          //   multiSelectLabel: "",
          //   defaultValues: [],
          // },
          {
            name: t("globalSearchResults.filters.sent_einvoices.due_date", { ns: "global_search" }),
            key: "due_date",
            payload: "due_date",
            type: "DATE",
            multiSelectLabel: "",
            defaultValues: [],
          },
        ],
      },
    },
  };
};

export default E;

import React from "react";
import { OnboardingCompletedProps } from "../../ActivityItemVariantTypes";
import "./OnboardingCompleted.scss";
import SecondaryActivity from "../../../SecondaryActivity/SecondaryActivity";
import { SecondaryType } from "../../../SecondaryActivity/SecondaryActivityTypes";
import { DATE_CONSTANTS_IN_MIN, NUMERIC_VALUES } from "../../../../../../../../constants/NumericConstants";
import useLocale from "../../../../../../../../hooks/useLocale";
import { useTranslation } from "react-i18next";

const OnboardingCompleted: React.FC<OnboardingCompletedProps> = ({ creator, countryCode }) => {
  const { t } = useTranslation();
  const creatorNew = { ...creator, message: t("activityDetails.feedItems.onboardingCompleted.headerMessage", { ns: "activities" }) };
  const isAfter24hrs = creator.createdOn
    ? (new Date().getTime() - new Date(creator.createdOn).getTime()) / DATE_CONSTANTS_IN_MIN.HRS_IN_MILIS > NUMERIC_VALUES.CONSTANT_TWENTY_FOUR
    : false;
  const { formatDateBasedOnCountry: formatDate } = useLocale();
  {
    t("activityDetails.feedItems.onboardingCompleted.card.headerText", { ns: "activities" });
  }

  return (
    <>
      {!isAfter24hrs ? (
        <div className="boarding-completed activity-feed-item-card">
          <div className="boarding-completed-icon">👍</div>
          <div className="boarding-completed-body">
            <h3 className="boarding-completed-body-header">
              {t("activityDetails.feedItems.onboardingCompleted.card.headerText", { ns: "activities" })}
            </h3>
            <p className="boarding-completed-body-text">
              {t("activityDetails.feedItems.onboardingCompleted.card.bodyText.firstPart", { ns: "activities" })}
              <span className="highlight"> {t("activityDetails.feedItems.onboardingCompleted.card.bodyText.middlePart", { ns: "activities" })}</span>
              {t("activityDetails.feedItems.onboardingCompleted.card.bodyText1.lastPart", { date: formatDate(creator.createdOn), ns: "activities" })}
            </p>
          </div>
        </div>
      ) : (
        <SecondaryActivity variant={SecondaryType.Base} creator={creatorNew} countryCode={countryCode} />
      )}
    </>
  );
};

export default OnboardingCompleted;

import React from "react";
import { useHistory } from "react-router";
import { RecordCardView } from "../../../../library/AtomicComponents/RecordCardView";
import { RelatedBillItem, RelatedInvoiceItem, RelatedPaymentItem, RelatedTransactionContainerProps } from "../RightPanelCollapsibleInterfaces";
import { formatDate } from "../../../../library/AtomicComponents/utils/date";
import { AppContext } from "../../../../../contexts/AppContext";
import Utils from "../../../../../utils/utils";
import { NUMERIC_VALUES } from "../../../../library/AtomicComponents/constants/numeric.constants";
import { ApplicationRouteContext } from "../../../../../contexts/ApplicationRouteContext";
import { DocumentSwitchContext } from "../../../../../contexts/DocumentSwitchContext";
import { useTranslation } from "react-i18next";

export default function Transactions({ transactions }: RelatedTransactionContainerProps): React.ReactElement {
  const history = useHistory();
  const { userStatus } = React.useContext(AppContext) as AppType;
  const { getBaseRoute } = React.useContext(ApplicationRouteContext) as ApplicationRouteType;
  const { setEnableDocumentSwitch } = React.useContext(DocumentSwitchContext) as DocumentSwitchType;
  const { t } = useTranslation();

  /**
   * Function takes in the entity name and uuid for the record at the
   * time of creation and attaches as onClick handler to the item.
   *
   * @param entityName record being clicked - payments/bills/invoices
   * @param id - uuid of the record
   * @returns navigate to the record detail page
   */
  const getNavigateHandler = (entityName: string, id: UUID) => {
    return () => {
      /**
       * Temporarily disable document switcher for related transactions.
       */
      setEnableDocumentSwitch(false);
      history.push(`${getBaseRoute()}/${entityName}/${id}`);
    };
  };

  return (
    <div className="transactions-container">
      {transactions.invoices &&
        transactions?.invoices.map((data: RelatedInvoiceItem) => {
          return (
            <RecordCardView
              key={data.invoice_number}
              recordLabel={t("activityDetails.rightPanelDrawer.transactionDrawer.transactionCardView.invoice.label", {
                invoiceNumber: data.invoice_number,
                ns: "activities",
              })}
              recordLabelColor="#60A564"
              recordDateTitle={t("activityDetails.rightPanelDrawer.transactionDrawer.transactionCardView.dateTitle", { ns: "activities" })}
              recordDateValue={formatDate(data.due_date?.toString())}
              recordLinkTitle={t("activityDetails.rightPanelDrawer.transactionDrawer.transactionCardView.invoice.linkTitle", { ns: "activities" })}
              colATitle={t("activityDetails.rightPanelDrawer.transactionDrawer.transactionCardView.colATitle", { ns: "activities" })}
              colAValue={Utils.formatValueAsCurrency(data.total_amount, userStatus?.currency?.locale, userStatus?.currency?.code)}
              colBTitle={t("activityDetails.rightPanelDrawer.transactionDrawer.transactionCardView.colBTitle", { ns: "activities" })}
              colBValue={Utils.formatValueAsCurrency(data.outstanding_balance, userStatus?.currency?.locale, userStatus?.currency?.code)}
              onClickRecordLink={getNavigateHandler("invoices", data.invoice_id)}
            />
          );
        })}
      {transactions.bills &&
        transactions?.bills.map((data: RelatedBillItem) => {
          return (
            <RecordCardView
              key={data.bill_number}
              recordLabel={t("activityDetails.rightPanelDrawer.transactionDrawer.transactionCardView.bill.label", {
                billNumber: data.bill_number,
                ns: "activities",
              })}
              recordLabelColor="#4C589E"
              recordDateTitle={t("activityDetails.rightPanelDrawer.transactionDrawer.transactionCardView.dateTitle", { ns: "activities" })}
              recordDateValue={formatDate(data.due_date?.toString())}
              recordLinkTitle={t("activityDetails.rightPanelDrawer.transactionDrawer.transactionCardView.bill.linkTitle", { ns: "activities" })}
              colATitle={t("activityDetails.rightPanelDrawer.transactionDrawer.transactionCardView.colATitle", { ns: "activities" })}
              colAValue={Utils.formatValueAsCurrency(data.total_amount, userStatus?.currency?.locale, userStatus?.currency?.code)}
              colBTitle={t("activityDetails.rightPanelDrawer.transactionDrawer.transactionCardView.colBTitle", { ns: "activities" })}
              colBValue={Utils.formatValueAsCurrency(
                data.outstanding_balance ?? NUMERIC_VALUES.CONSTANT_ZERO,
                userStatus?.currency?.locale,
                userStatus?.currency?.code
              )}
              onClickRecordLink={getNavigateHandler("bills", data.bill_id)}
            />
          );
        })}
      {transactions.payments &&
        transactions?.payments.map((data: RelatedPaymentItem) => {
          return (
            <RecordCardView
              key={data.payment_number}
              recordLabel={t("activityDetails.rightPanelDrawer.transactionDrawer.transactionCardView.payment.label", {
                paymentNumber: data.payment_number,
                ns: "activities",
              })}
              recordLabelColor="#419AF7"
              recordDateTitle={t("activityDetails.rightPanelDrawer.transactionDrawer.transactionCardView.payment.dateTitle", { ns: "activities" })}
              recordDateValue={formatDate(data.payment_date?.toString())}
              recordLinkTitle={t("activityDetails.rightPanelDrawer.transactionDrawer.transactionCardView.payment.linkTitle", { ns: "activities" })}
              colATitle={t("activityDetails.rightPanelDrawer.transactionDrawer.transactionCardView.colATitle", { ns: "activities" })}
              colAValue={Utils.formatValueAsCurrency(data.total_amount, userStatus?.currency?.locale, userStatus?.currency?.code)}
              colBTitle={t("activityDetails.rightPanelDrawer.transactionDrawer.transactionCardView.payment.colBTitle", { ns: "activities" })}
              colBValue={Utils.formatValueAsCurrency(
                data.unapplied_amount ?? NUMERIC_VALUES.CONSTANT_ZERO,
                userStatus?.currency?.locale,
                userStatus?.currency?.code
              )}
              onClickRecordLink={getNavigateHandler("payments", data.payment_id)}
            />
          );
        })}
    </div>
  );
}

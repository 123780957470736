import React, { ReactNode } from "react";
import { CircularProgress } from "@mui/material";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import { TooltipTypes } from "../../../constants/TooltipConstants";

export interface ButtonProps {
  className?: string;
  variant?: "primary" | "secondary" | "transparent" | "grey" | "error" | "solo-icon" | "snippet" | "primary2" | "secondary2" | "ghost";
  size?: "sm" | "lg" | "md";
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  children?: React.ReactNode;
  icon?: ReactNode;
  alignIcon?: "left" | "right";
  type?: "submit" | "reset" | "button" | any;
  form?: string;
  loading?: boolean;
  tooltip?: string | ReactNode;
  fullWidth?: boolean;
  disableFocusListner?: boolean;
  tooltipType?: string;
  shortcutKeysText?: string;
}

export default function Button({ tooltipType = TooltipTypes.PLAIN, ...props }: ButtonProps): React.ReactElement {
  return (
    <CustomTooltip
      type={tooltipType}
      shortcutKeysText={tooltipType === TooltipTypes.SHORTCUT ? props.shortcutKeysText : ""}
      title={props.tooltip ?? ""}
      followCursor
      placement="top"
      componentsProps={props.fullWidth ? { tooltip: { sx: { maxWidth: "none" } } } : {}}
      disableFocusListener={props.disableFocusListner}
    >
      {/**
       * MUI does not support tooltip with disabled elements
       * and as a workaround span is wrapped around the button.
       *
       * Official docs: https://mui.com/material-ui/react-tooltip/#disabled-elements
       */}
      <span>
        <button
          className={`
        btn ${props.icon ? "icon-btn" : ""} ${props.icon && props.alignIcon ? `icon-btn-${props.alignIcon}` : ""}
        btn-${props.variant ?? "primary"}
        btn-${props.size ?? "lg"}
        ${props.loading ? "btn-loading" : ""}
        ${props.className ? props.className : ""}
        ${props.variant === "secondary" && "secondary-border"}
      `}
          onClick={props.onClick}
          disabled={props.disabled || props.loading}
          type={props.type}
          form={props.form}
          style={props.disabled ? { pointerEvents: "none" } : {}}
        >
          {props.icon && props.alignIcon === "left" ? props.icon : null}
          {props.children && <p className={`btn-text${props.size === "sm" ? "-sm" : ""}`}>{props.children}</p>}
          {props.icon && props.alignIcon === "right" ? props.icon : null}
          <div className={`btn-load-wrapper btn-load-wrapper-${props.variant ?? "primary"}`}>
            <CircularProgress size={18} />
          </div>
        </button>
      </span>
    </CustomTooltip>
  );
}

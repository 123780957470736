/*eslint-disable*/
import React from "react";
import { styled } from "@mui/system";
import { pxToRem } from "../../theme/theme";
import { Menu as MuiMenu } from "@mui/material";

export const Menu = styled(MuiMenu, {
  name: "menu",
  slot: "root",
})({
  "& .MuiPaper-root": {
    boxShadow: `0 ${pxToRem(8)} ${pxToRem(12)} rgb(0 0 0 / 10%), 0 ${pxToRem(4)} ${pxToRem(20)} rgb(0 0 0 / 10%)`,
    "& .MuiList-root.MuiMenu-list": {
      padding: 0,
    },
  },
}) as typeof MuiMenu;

import React from "react";

export const ProfileContext = React.createContext<ProfileType | null>(null);

export interface ProfileProviderProps {
  children: (React.ReactNode & { type: string })[] | (React.ReactNode & { type: string });
}

const ProfileProvider: React.FC<ProfileProviderProps> = ({ children }: ProfileProviderProps) => {
  const [imageUrl, setImageUrl] = React.useState<string>("");
  const [userName, setUserName] = React.useState<string | null>(null);
  return (
    <ProfileContext.Provider
      value={{
        imageUrl,
        setImageUrl,
        userName,
        setUserName,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileProvider;

import APIClient from "../APIClient";
import { AxiosRequestConfig } from "axios";

const INBOX_API_BASE = `${process.env.REACT_APP_API_ENDPOINT_INBOX_API_PATH}${process.env.REACT_APP_API_ENDPOINT_INBOX_API_V2}`;
const CONFIG_OVERRIDE = {
  baseURL: process.env.REACT_APP_API_ENDPOINT_INBOX,
} as AxiosRequestConfig;

export class EmailsClient {
  /**
   * * GET on /inbox/api/v2/Emails/{id}
   * @param id - The unique Lockstep Platform ID number of this Email
   * @param include - To fetch additional data on this object, specify the list of elements to retrieve.
   * @returns Promise of type ActivityItemEmailModel or Exception
   */
  async get(id: string, include?: string): Promise<ActivityItemEmailModel> {
    const url = `${INBOX_API_BASE}/Emails/${id}`;
    const options = {
      params: {
        include,
      },
      ...CONFIG_OVERRIDE,
    };
    return APIClient.get(url, options).then((res) => res.data);
  }

  async getActivity(workspaceId: number, activityStreamId: string, id: string): Promise<any> {
    const url = `${INBOX_API_BASE}/workspaces/${workspaceId}/activity_streams/${activityStreamId}/activities/${id}`;
    return APIClient.get(url, CONFIG_OVERRIDE).then((res) => res.data);
  }

  /**
   * * PATCH on /inbox/api/v2/Emails/{id}
   * @param id - The unique Lockstep Platform ID number of this Email
   * @returns Promise of type ActivityItemEmailModel or Exception
   */
  async patch(id: string, body: Partial<ActivityItemEmailModel>): Promise<ActivityItemEmailModel> {
    const url = `${INBOX_API_BASE}/Emails/${id}`;
    return APIClient.patch(url, body, CONFIG_OVERRIDE).then((res) => res.data);
  }

  /**
   * * DELETE on /inbox/api/v2/Emails/{id}
   * @param id - The unique Lockstep Platform ID number of this Email
   * @returns Promise of type ActivityItemEmailModel or Exception
   */
  async delete(id: string): Promise<ActivityItemEmailModel> {
    const url = `${INBOX_API_BASE}/Emails/${id}`;
    return APIClient.delete(url, CONFIG_OVERRIDE).then((res) => res.data);
  }

  /**
   * * POST on /inbox/api/v2/activity_streams
   * @param body - Array of ActivityItemEmailModels to be posted
   * @returns Promise of type ActivityItemEmailModel[] or Exception
   */
  async post(body: Partial<ActivityItemEmailModel>): Promise<ActivityStream> {
    const url = `${INBOX_API_BASE}/workspaces/${body.workspace_id}/activity_streams`;
    return APIClient.post(url, body, CONFIG_OVERRIDE).then((res) => res.data);
  }

  /**
   * * POST on /inbox/api/v2/activity_streams
   * @param body - Array of ActivityItemEmailModels to be posted
   * @returns Promise of type ActivityItemEmailModel[] or Exception
   */
  async postActivities(workspaceId: number, id: string, body: Partial<ActivityItemEmailModel>): Promise<ActivityStream> {
    const url = `${INBOX_API_BASE}/workspaces/${workspaceId}/activity_streams/${id}/activities/`;
    return APIClient.post(url, body, CONFIG_OVERRIDE).then((res) => res.data);
  }

  /**
   * * POST on /inbox/api/v2/test_email/
   * @param body - Array of ActivityItemEmailModels to be posted
   * @returns Promise of type ActivityItemEmailModel[] or Exception
   */
  async postTestEmail(body: Partial<ActivityItemEmailModel>): Promise<ActivityStream> {
    const url = `${INBOX_API_BASE}/workspaces/${body.workspace_id}/send_test_email`;
    return APIClient.post(url, body, CONFIG_OVERRIDE).then((res) => res.data);
  }

  /**
   * * GET on /inbox/api/v2/Emails/query
   * @param filter - The filter to use to select from the list of available apps (Searchlight query syntax)
   * @param include - Specify options
   * @param order - The sort order for the results (Searchlight query syntax)
   * @param pageSize - The page size for results (Defaults to 200, maximum of 1,000)
   * @param pageNumber - The page number for results (Defaults to 0)
   * @returns Promise of type EmailModelFetchResult or Exception
   */
  async query(filter?: string, include?: string, order?: string, pageSize?: number, pageNumber?: number): Promise<EmailModelFetchResult> {
    const url = `${INBOX_API_BASE}/Emails/query`;
    const options = {
      params: {
        filter,
        include,
        order,
        pageSize,
        pageNumber,
      },
      ...CONFIG_OVERRIDE,
    };
    return APIClient.get(url, options).then((res) => res.data);
  }
}

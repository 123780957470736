import React from "react";
import i18n from "../../../i18n/i18n";
import { StatusBackendNumberMap, WorkspaceType } from "../../../types/enums";
import { RowDataTypes } from "../../library/AtomicComponents/constants/table.constants";
import { OptionsListType, TableBaseConfig } from "../../../types/schema/configurations.types";

const { t } = i18n;

export enum ActivityTableViews {
  UnassignedActivitiesTable = "unassigned-activities-table",
  AssignedActivitiesTable = "assigned-activities-table",
  SpamActivitiesTable = "spam-activities-table",
  AllActivitiesTable = "all-activities-table",
  SnoozedActivitiesTable = "snoozed-activities-table",
  CompletedActivitiesTable = "completed-activities-table",
  AwaitingActivitiesTable = "awaiting-activities-table",
  ConnectionActivitiesTable = "connection-activities-table",
}

const ActivityTableConfigs = () => {
  const tableConfigs: TableBaseConfig = {
    // basic table configs
    workspacesApplicable: [WorkspaceType.AR, WorkspaceType.AP, WorkspaceType.AW],
  };
  const columns: OptionsListType = React.useMemo(
    () => [
      {
        // base configs
        name: t("table.columns.from.label", { ns: "activities" }) as string,
        rowDataType: RowDataTypes.BoldText,
        accessor: "platform_connection_company_name",
        enableSkeleton: true,
        // sort configs
        enableSort: true,
        sortField: "platform_connection_company_name",
        // filter configs
        enableFilter: true,
        filterType: "dropdown-search",
        filterAlignment: "center",
        placeholder: t("table.columns.from.filter.placeholderText", { ns: "activities" }) as string,
        // applicability matrix
        workspacesApplicable: [WorkspaceType.AR, WorkspaceType.AW, WorkspaceType.AP],
        featureFlagsApplicable: "",
        viewsApplicable: [
          ActivityTableViews.UnassignedActivitiesTable,
          ActivityTableViews.AssignedActivitiesTable,
          ActivityTableViews.SpamActivitiesTable,
          ActivityTableViews.AllActivitiesTable,
          ActivityTableViews.SnoozedActivitiesTable,
          ActivityTableViews.CompletedActivitiesTable,
          ActivityTableViews.AwaitingActivitiesTable,
          ActivityTableViews.ConnectionActivitiesTable,
        ],
        // variable or view based configs
        styles: [
          {
            width: "30%",
            rowDataAlign: "left",
            viewsApplicable: [
              ActivityTableViews.UnassignedActivitiesTable,
              ActivityTableViews.AssignedActivitiesTable,
              ActivityTableViews.SpamActivitiesTable,
              ActivityTableViews.AllActivitiesTable,
              ActivityTableViews.SnoozedActivitiesTable,
              ActivityTableViews.CompletedActivitiesTable,
              ActivityTableViews.AwaitingActivitiesTable,
            ],
            workspacesApplicable: [WorkspaceType.AP, WorkspaceType.AW],
          },
          {
            width: "25%",
            rowDataAlign: "left",
            viewsApplicable: [ActivityTableViews.UnassignedActivitiesTable, ActivityTableViews.AssignedActivitiesTable],
            workspacesApplicable: [WorkspaceType.AR],
          },
          {
            width: "20%",
            rowDataAlign: "left",
            viewsApplicable: [
              ActivityTableViews.SpamActivitiesTable,
              ActivityTableViews.AllActivitiesTable,
              ActivityTableViews.SnoozedActivitiesTable,
              ActivityTableViews.CompletedActivitiesTable,
              ActivityTableViews.AwaitingActivitiesTable,
            ],
            workspacesApplicable: [WorkspaceType.AR],
          },
          {
            width: "15%",
            rowDataAlign: "left",
            viewsApplicable: [ActivityTableViews.ConnectionActivitiesTable],
            workspacesApplicable: [WorkspaceType.AP, WorkspaceType.AR, WorkspaceType.AW],
          },
        ],
      },
      {
        name: t("table.columns.companyType.label", { ns: "activities" }) as string,
        rowDataType: RowDataTypes.CompanyChip,
        accessor: "company_type",
        enableSkeleton: true,
        enableSort: false,
        enableFilter: false,
        filterType: "dropdown",
        filterAlignment: "right",
        dropdownListItems: [
          { key: "All", label: t("table.columns.companyType.filter.dropdownListItems.all", { ns: "activities" }), value: "All" },
          { key: "Vendor", label: t("table.columns.companyType.filter.dropdownListItems.vendor", { ns: "activities" }), value: "Vendor" },
          { key: "Customer", label: t("table.columns.companyType.filter.dropdownListItems.customer", { ns: "activities" }), value: "Customer" },
          {
            key: "Other Connection",
            label: t("table.columns.companyType.filter.dropdownListItems.otherConnection", { ns: "activities" }),
            value: "Other",
          },
          { key: "My Company", label: t("table.columns.companyType.filter.dropdownListItems.myCompany", { ns: "activities" }), value: "Company" },
        ],
        dropdownOptions: [
          {
            label: "Customer",
            value: "Customer",
            id: "customer",
          },
          {
            label: "Vendor",
            value: "Vendor",
            id: "vendor",
          },
          {
            label: "Other Connection",
            value: "Other",
            id: "other",
          },
          {
            label: "My Company",
            value: "Company",
            id: "customer",
          },
        ],
        workspacesApplicable: [WorkspaceType.AW],
        featureFlagsApplicable: "",
        viewsApplicable: [
          ActivityTableViews.AssignedActivitiesTable,
          ActivityTableViews.UnassignedActivitiesTable,
          ActivityTableViews.SpamActivitiesTable,
          ActivityTableViews.AllActivitiesTable,
          ActivityTableViews.SnoozedActivitiesTable,
          ActivityTableViews.CompletedActivitiesTable,
          ActivityTableViews.AwaitingActivitiesTable,
        ],
        styles: [
          {
            width: "17.5%",
            rowDataAlign: "left",
            viewsApplicable: [
              ActivityTableViews.UnassignedActivitiesTable,
              ActivityTableViews.AssignedActivitiesTable,
              ActivityTableViews.SpamActivitiesTable,
              ActivityTableViews.AllActivitiesTable,
              ActivityTableViews.SnoozedActivitiesTable,
              ActivityTableViews.CompletedActivitiesTable,
              ActivityTableViews.AwaitingActivitiesTable,
            ],
            workspacesApplicable: [WorkspaceType.AW],
          },
        ],
      },
      {
        name: t("table.columns.subject.label", { ns: "activities" }) as string,
        rowDataType: RowDataTypes.ActivitySubject,
        accessor: "subject",
        enableSkeleton: true,
        conditionalAccessor: "attachments",
        enableSort: true,
        sortField: "subject",
        enableFilter: true,
        filterType: "dropdown-search",
        filterAlignment: "right",
        dropdownSearchVariant: "large",
        placeholder: t("table.columns.subject.filter.placeholderText", { ns: "activities" }) as string,
        workspacesApplicable: [WorkspaceType.AR, WorkspaceType.AP, WorkspaceType.AW],
        featureFlagsApplicable: "",
        viewsApplicable: [
          ActivityTableViews.UnassignedActivitiesTable,
          ActivityTableViews.AssignedActivitiesTable,
          ActivityTableViews.SpamActivitiesTable,
          ActivityTableViews.AllActivitiesTable,
          ActivityTableViews.SnoozedActivitiesTable,
          ActivityTableViews.CompletedActivitiesTable,
          ActivityTableViews.AwaitingActivitiesTable,
          ActivityTableViews.ConnectionActivitiesTable,
        ],
        styles: [
          {
            width: "40%",
            rowDataAlign: "left",
            viewsApplicable: [
              ActivityTableViews.UnassignedActivitiesTable,
              ActivityTableViews.AssignedActivitiesTable,
              ActivityTableViews.SpamActivitiesTable,
              ActivityTableViews.AllActivitiesTable,
              ActivityTableViews.SnoozedActivitiesTable,
              ActivityTableViews.CompletedActivitiesTable,
              ActivityTableViews.AwaitingActivitiesTable,
            ],
            workspacesApplicable: [WorkspaceType.AP, WorkspaceType.AW],
          },
          {
            width: "50%",
            rowDataAlign: "left",
            viewsApplicable: [ActivityTableViews.UnassignedActivitiesTable, ActivityTableViews.AssignedActivitiesTable],
            workspacesApplicable: [WorkspaceType.AR],
          },
          {
            width: "45%",
            rowDataAlign: "left",
            viewsApplicable: [
              ActivityTableViews.SpamActivitiesTable,
              ActivityTableViews.AllActivitiesTable,
              ActivityTableViews.SnoozedActivitiesTable,
              ActivityTableViews.CompletedActivitiesTable,
              ActivityTableViews.AwaitingActivitiesTable,
            ],
            workspacesApplicable: [WorkspaceType.AR],
          },
          {
            width: "45%",
            rowDataAlign: "left",
            viewsApplicable: [ActivityTableViews.ConnectionActivitiesTable],
            workspacesApplicable: [WorkspaceType.AP, WorkspaceType.AR, WorkspaceType.AW],
          },
        ],
      },
      {
        name: t("table.columns.status.label", { ns: "activities" }) as string,
        accessor: "status",
        rowDataType: RowDataTypes.ActivityStatus,
        enableSkeleton: true,
        enableSort: true,
        sortField: "status",
        enableFilter: true,
        filterType: "dropdown",
        filterAlignment: "right",
        dropdownListItems: [
          { key: "All", label: t("table.columns.status.filter.dropdownListItems.all", { ns: "activities" }), value: "All" },
          { key: "ACTIVE", label: t("table.columns.status.filter.dropdownListItems.active", { ns: "activities" }), value: "0" },
          { key: "CLOSED", label: t("table.columns.status.filter.dropdownListItems.closed", { ns: "activities" }), value: "1" },
          { key: "SNOOZED", label: t("table.columns.status.filter.dropdownListItems.snoozed", { ns: "activities" }), value: "3" },
          {
            key: "WAITING FOR RESPONSE",
            label: t("table.columns.status.filter.dropdownListItems.waitingForResponse", { ns: "activities" }),
            value: "2",
          },
        ],
        dropdownOptions: [
          { label: "ACTIVE", value: StatusBackendNumberMap.active, id: "active" },
          { label: "WAITING FOR RESPONSE", value: StatusBackendNumberMap.waiting_for_response, id: "waiting_for_response" },
          { label: "SNOOZED", value: StatusBackendNumberMap.snoozed, id: "snoozed" },
          { label: "CLOSED", value: StatusBackendNumberMap.closed, id: "closed" },
        ],
        workspacesApplicable: [WorkspaceType.AW, WorkspaceType.AP, WorkspaceType.AR],
        featureFlagsApplicable: "",
        viewsApplicable: [ActivityTableViews.ConnectionActivitiesTable],
        styles: [
          {
            width: "18%",
            rowDataAlign: "left",
            viewsApplicable: [ActivityTableViews.ConnectionActivitiesTable],
            workspacesApplicable: [WorkspaceType.AW, WorkspaceType.AP, WorkspaceType.AR],
          },
        ],
      },
      {
        name: t("table.columns.assignedTo.label", { ns: "activities" }) as string,
        rowDataType: RowDataTypes.BoldText,
        accessor: "assignee_name",
        enableSkeleton: true,
        enableSort: true,
        sortField: "assignee_name",
        enableFilter: true,
        filterType: "dropdown-search",
        filterAlignment: "right",
        placeholder: t("table.columns.assignedTo.filter.placeholderText", { ns: "activities" }) as string,
        workspacesApplicable: [WorkspaceType.AP, WorkspaceType.AR, WorkspaceType.AW],
        featureFlagsApplicable: "",
        viewsApplicable: [
          ActivityTableViews.SpamActivitiesTable,
          ActivityTableViews.AllActivitiesTable,
          ActivityTableViews.SnoozedActivitiesTable,
          ActivityTableViews.CompletedActivitiesTable,
          ActivityTableViews.AwaitingActivitiesTable,
          ActivityTableViews.ConnectionActivitiesTable,
        ],
        styles: [
          {
            width: "15%",
            rowDataAlign: "left",
            viewsApplicable: [
              ActivityTableViews.SpamActivitiesTable,
              ActivityTableViews.AllActivitiesTable,
              ActivityTableViews.SnoozedActivitiesTable,
              ActivityTableViews.CompletedActivitiesTable,
              ActivityTableViews.AwaitingActivitiesTable,
            ],
            workspacesApplicable: [WorkspaceType.AP, WorkspaceType.AW],
          },
          {
            width: "17%",
            rowDataAlign: "left",
            viewsApplicable: [
              ActivityTableViews.SpamActivitiesTable,
              ActivityTableViews.AllActivitiesTable,
              ActivityTableViews.SnoozedActivitiesTable,
              ActivityTableViews.CompletedActivitiesTable,
              ActivityTableViews.AwaitingActivitiesTable,
            ],
            workspacesApplicable: [WorkspaceType.AR],
          },
          {
            width: "15%",
            rowDataAlign: "left",
            viewsApplicable: [ActivityTableViews.ConnectionActivitiesTable],
            workspacesApplicable: [WorkspaceType.AP, WorkspaceType.AW, WorkspaceType.AR],
          },
        ],
      },
      {
        name: t("table.columns.snoozedTill.label", { ns: "activities" }) as string,
        rowDataType: RowDataTypes.DateEpochSnoozed,
        accessor: "snoozed_until",
        enableSkeleton: true,
        enableSort: true,
        sortField: "snoozed_until",
        enableFilter: true,
        filterType: "date",
        filterAlignment: "right",
        workspacesApplicable: [WorkspaceType.AP, WorkspaceType.AR, WorkspaceType.AW],
        featureFlagsApplicable: "",
        viewsApplicable: [ActivityTableViews.SnoozedActivitiesTable],
        styles: [
          {
            width: "15%",
            rowDataAlign: "left",
            viewsApplicable: [ActivityTableViews.SnoozedActivitiesTable],
            workspacesApplicable: [WorkspaceType.AP, WorkspaceType.AW, WorkspaceType.AR],
          },
        ],
      },
      {
        name: t("table.columns.lastActivityDate.label", { ns: "activities" }) as string,
        rowDataType: RowDataTypes.DateEpoch,
        accessor: "last_activity_at",
        enableSkeleton: true,
        enableSort: true,
        sortField: "last_activity_at",
        enableFilter: true,
        filterType: "date",
        filterAlignment: "right",
        workspacesApplicable: [WorkspaceType.AR, WorkspaceType.AP, WorkspaceType.AW],
        featureFlagsApplicable: "",
        viewsApplicable: [
          ActivityTableViews.UnassignedActivitiesTable,
          ActivityTableViews.AssignedActivitiesTable,
          ActivityTableViews.SpamActivitiesTable,
          ActivityTableViews.AllActivitiesTable,
          ActivityTableViews.CompletedActivitiesTable,
          ActivityTableViews.AwaitingActivitiesTable,
          ActivityTableViews.ConnectionActivitiesTable,
        ],
        styles: [
          {
            width: "15%",
            rowDataAlign: "left",
            viewsApplicable: [
              ActivityTableViews.SpamActivitiesTable,
              ActivityTableViews.AllActivitiesTable,
              ActivityTableViews.CompletedActivitiesTable,
              ActivityTableViews.AwaitingActivitiesTable,
              ActivityTableViews.ConnectionActivitiesTable,
            ],
            workspacesApplicable: [WorkspaceType.AP, WorkspaceType.AR, WorkspaceType.AW],
          },
          {
            width: "25%",
            rowDataAlign: "left",
            viewsApplicable: [ActivityTableViews.UnassignedActivitiesTable, ActivityTableViews.AssignedActivitiesTable],
            workspacesApplicable: [WorkspaceType.AR],
          },
          {
            width: "30%",
            rowDataAlign: "left",
            viewsApplicable: [ActivityTableViews.UnassignedActivitiesTable, ActivityTableViews.AssignedActivitiesTable],
            workspacesApplicable: [WorkspaceType.AP, WorkspaceType.AW],
          },
        ],
      },
      {
        name: t("table.columns.flag.label", { ns: "activities" }) as string,
        accessor: "auto_detected_flag",
        rowDataType: RowDataTypes.Flag,
        enableSkeleton: true,
        enableSort: true,
        sortField: "auto_detected_flag",
        enableFilter: true,
        filterType: "dropdown",
        filterAlignment: "right",
        dropdownListItems: [
          { key: "All", label: t("table.columns.flag.filter.dropdownListItems.all", { ns: "activities" }), value: "All" },
          { key: "Bill", label: t("table.columns.flag.filter.dropdownListItems.bill", { ns: "activities" }), value: "3" },
          { key: "New Vendor", label: t("table.columns.flag.filter.dropdownListItems.newVendor", { ns: "activities" }), value: "0" },
        ],
        workspacesApplicable: [WorkspaceType.AW, WorkspaceType.AP],
        featureFlagsApplicable: "FORWARD_AP_AUTOMATION",
        viewsApplicable: [
          ActivityTableViews.UnassignedActivitiesTable,
          ActivityTableViews.AssignedActivitiesTable,
          ActivityTableViews.SpamActivitiesTable,
          ActivityTableViews.AllActivitiesTable,
          ActivityTableViews.SnoozedActivitiesTable,
          ActivityTableViews.CompletedActivitiesTable,
          ActivityTableViews.AwaitingActivitiesTable,
        ],
        styles: [
          {
            width: "15%",
            rowDataAlign: "left",
            viewsApplicable: [
              ActivityTableViews.SpamActivitiesTable,
              ActivityTableViews.AllActivitiesTable,
              ActivityTableViews.SnoozedActivitiesTable,
              ActivityTableViews.CompletedActivitiesTable,
              ActivityTableViews.AwaitingActivitiesTable,
            ],
            workspacesApplicable: [WorkspaceType.AW, WorkspaceType.AP],
          },
          {
            width: "25%",
            rowDataAlign: "left",
            viewsApplicable: [ActivityTableViews.UnassignedActivitiesTable, ActivityTableViews.AssignedActivitiesTable],
            workspacesApplicable: [WorkspaceType.AW, WorkspaceType.AP],
          },
        ],
      },
    ],
    [i18n.language]
  );

  return {
    tableConfigs,
    columns,
  };
};

export default ActivityTableConfigs;

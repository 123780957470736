import React from "react";
import { BoldCaretDown, BoldCaretUp } from "../../library/Icons/Icons";
import useGlobalSearchStyle from "../Styles/useGlobalSearchStyles";
import { KeyPressEventKeys, KeyBoardActionHelperText } from "../../../types/enums";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const GlobalSeachDialogFooter: React.FC = () => {
  const { footerContainer, spanHelperText, spanHelperActionText } = useGlobalSearchStyle({});
  const { t } = useTranslation();

  return (
    <div className={footerContainer}>
      <div>
        <BoldCaretDown />
        <BoldCaretUp />
        <span className={spanHelperText}>
          {i18next.exists("globalSearchSuggestions.footer.to_navigate", { ns: "global_search" })
            ? t("globalSearchSuggestions.footer.to_navigate", { ns: "global_search" })
            : KeyBoardActionHelperText?.TO_NAVIGATE}
        </span>
      </div>
      <div>
        <span className={spanHelperActionText}>{KeyPressEventKeys.enter}</span>
        <span className={spanHelperText}>
          {i18next.exists("globalSearchSuggestions.footer.to_select", { ns: "global_search" })
            ? t("globalSearchSuggestions.footer.to_select", { ns: "global_search" })
            : KeyBoardActionHelperText.TO_SELECT}
        </span>
      </div>
      <div>
        <span className={spanHelperActionText}>{KeyPressEventKeys.esc}</span>
        <span className={spanHelperText}>
          {i18next.exists("globalSearchSuggestions.footer.to_dismiss", { ns: "global_search" })
            ? t("globalSearchSuggestions.footer.to_dismiss", { ns: "global_search" })
            : KeyBoardActionHelperText.TO_DISMISS}
        </span>
      </div>
      <div>
        <span className={spanHelperActionText}>{KeyPressEventKeys.tab}</span>
        <span className={spanHelperText}>
          {i18next.exists("globalSearchSuggestions.footer.to_move", { ns: "global_search" })
            ? t("globalSearchSuggestions.footer.to_move", { ns: "global_search" })
            : KeyBoardActionHelperText.TO_MOVE_BETWEEN_SECTIONS}
        </span>
      </div>
    </div>
  );
};

export default GlobalSeachDialogFooter;

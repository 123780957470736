import React, { useEffect, forwardRef } from "react";
import "./Checkbox.scss";

// Define Checkbox's Table Props
interface Props extends React.ComponentPropsWithoutRef<"input"> {
  indeterminate?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useCombinedRefs = (...refs: any[]): React.MutableRefObject<any> => {
  const targetRef = React.useRef();

  React.useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === "function") {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
};

// eslint-disable-next-line react/display-name
const IndeterminateCheckbox = forwardRef<HTMLInputElement, Props>(({ indeterminate, ...rest }: Props, ref: React.Ref<HTMLInputElement>) => {
  const defaultRef = React.useRef(null);
  const combinedRef = useCombinedRefs(ref, defaultRef);

  useEffect(() => {
    if (combinedRef?.current) {
      combinedRef.current.indeterminate = indeterminate ?? false;
    }
  }, [combinedRef, indeterminate]);

  return (
    <React.Fragment>
      <input
        className={`checkbox ${indeterminate ? "checkbox-indeterminate" : ""}`}
        type="checkbox"
        ref={combinedRef}
        {...rest}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
    </React.Fragment>
  );
});

export default IndeterminateCheckbox;

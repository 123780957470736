/*eslint-disable no-magic-numbers*/
import { styled } from "@mui/system";

export const AttachmentChipRoot = styled("div", {
  name: "attachment-chip",
  slot: "root",
})({
  display: "inline-flex",
  alignItems: "center",
  padding: "0.125rem 0.5rem",
  gap: "0.5rem",
  background: "#ffffff",
  border: "0.0625rem solid #bdc3db",
  borderRadius: "0.5rem",
  height: "3.75rem",
  width: "10rem",
  margin: "0.25rem",
  cursor: "pointer",
});

export const AttachmentFileDetails = styled("div", {
  name: "attachment-file-details",
  slot: "root",
})({
  display: "flex",
  flexDirection: "column",
});

export const AttachmentFileName = styled("span", {
  name: "attachment-file-name",
  slot: "root",
})({
  fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif`,
  fontWeight: 600,
  fontSize: "0.75rem",
  lineHeight: "1rem",
  width: "5rem",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

export const AttachmentFileSize = styled("span", {
  name: "attachment-file-size",
  slot: "root",
})({
  fontFamily: `"Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif`,
  fontWeight: 500,
  fontSize: "0.625rem",
  lineHeight: "1rem",
});

export const AttachmentFileDownload = styled("div", {
  name: "attachment-file-download",
  slot: "root",
})({
  cursor: "pointer",
});

export const AttachmentFileDisposeContainer = styled("div", {
  name: "attachment-file-download",
  slot: "root",
})({
  cursor: "pointer",
  height: "1.875rem",
  "&& svg": {
    height: "0.796rem",
    width: "0.796rem",
  },
});

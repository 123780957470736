import React, { useState } from "react";
import { ZoomIn, ZoomOut } from "@mui/icons-material";
import { PDFPreviewPropTypes } from "./types";
import { PDFPreviewRoot, PDFPreviewControls } from "../styled/styled";
import { NUMERIC_VALUES } from "../../constants/numeric.constants";

export default function PDFPreview({ fileName = "", fileURL = "" }: PDFPreviewPropTypes): React.ReactElement {
  const [pdfZoomLevel, setPDFZoomLevel] = useState<number>(NUMERIC_VALUES.CONSTANT_TWO_HUNDRED);
  const [pdfViewLevel, setPDFViewLevel] = useState<string>("FitH");

  /**
   * @function onClickZoomIn
   * @param event MouseEvent To Trigger Zoom In from Toolbar icon
   * To Zoom In on the previewed PDF document
   * This is achieved by updating following params passed in
   * url as well as param tag inside object tag
   * @param zoom in pdfZoomLevel to define scale of PDF currently 100 or 200
   * @param view in pdfViewLevel to define the space of viewport that PDF preview will take
   * currently toggling between
   * FitH - Fit in height of the viewport
   * FitB - Fit in both width and height of viewport
   */
  const onClickZoomIn = (event: React.MouseEvent<SVGSVGElement>) => {
    event.stopPropagation();
    setPDFZoomLevel(NUMERIC_VALUES.CONSTANT_TWO_HUNDRED);
    setPDFViewLevel("FitH");
  };

  /**
   * @function onClickZoomOut
   * @param event MouseEvent To Trigger Zoom In from Toolbar icon
   * To Zoom In on the previewed PDF document
   * This is achieved by updating following params passed in
   * url as well as param tag inside object tag
   * @param zoom in pdfZoomLevel to define scale of PDF currently 100 or 200
   * @param view in pdfViewLevelto define the space of viewport that PDF preview will take
   * currently toggling between
   * FitH - Fit in height of the viewport
   * FitB - Fit in both width and height of viewport
   */
  const onClickZoomOut = (event: React.MouseEvent<SVGSVGElement>) => {
    event.stopPropagation();
    setPDFZoomLevel(NUMERIC_VALUES.CONSTANT_HUNDRED);
    setPDFViewLevel("FitV");
  };

  return (
    <PDFPreviewRoot>
      <object
        key={fileName}
        width={pdfZoomLevel === NUMERIC_VALUES.CONSTANT_HUNDRED ? "60%" : "80%"}
        height="700"
        data={`${typeof(fileURL) === 'string' ? fileURL.replace("?disposition=attachment", "") : URL.createObjectURL(fileURL as unknown as Blob)}`+`#toolbar=0&navpanes=0&scrollbar=0&zoom=${pdfZoomLevel}&view=${pdfViewLevel}`}
        type="application/pdf"
      >
        <param name="data" value={`${typeof(fileURL) === 'string' ? fileURL.replace("?disposition=attachment", "") : URL.createObjectURL(fileURL as unknown as Blob)}`+`#toolbar=0&navpanes=0&scrollbar=0&zoom=${pdfZoomLevel}&view=${pdfViewLevel}`}></param>
        <param name="toolbar" value={NUMERIC_VALUES.CONSTANT_ZERO}></param>
        <param name="navpanes" value={NUMERIC_VALUES.CONSTANT_ZERO}></param>
        <param name="scrollbar" value={NUMERIC_VALUES.CONSTANT_ZERO}></param>
        <param name="zoom" value={pdfZoomLevel}></param>
        <param name="view" value={pdfViewLevel}></param>
      </object>
      <PDFPreviewControls>
        <ZoomOut
          fontSize="large"
          htmlColor={`${pdfZoomLevel === NUMERIC_VALUES.CONSTANT_HUNDRED ? "#808283" : "#ffffff"}`}
          onClick={onClickZoomOut}
        />
        <ZoomIn
          fontSize="large"
          htmlColor={`${pdfZoomLevel === NUMERIC_VALUES.CONSTANT_TWO_HUNDRED ? "#808283" : "#ffffff"}`}
          onClick={onClickZoomIn}
        />
      </PDFPreviewControls>
    </PDFPreviewRoot>
  );
}

import React from "react";
import SecondaryActivity from "../../SecondaryActivity/SecondaryActivity";
import { SecondaryType } from "../../SecondaryActivity/SecondaryActivityTypes";
import { MergeConnectionProps } from "../ActivityItemVariantTypes";
import * as Labels from "../../../../../../../constants/config";

const MergeConnection: React.FC<MergeConnectionProps> = ({ connectionName, creator, countryCode }) => {
  const headerMessage = `${Labels.HEADER_MERGED_CONNECTION}${connectionName ? ` with ${connectionName}` : ""}`;

  return <SecondaryActivity variant={SecondaryType.Base} creator={{ ...creator, message: headerMessage }} countryCode={countryCode} />;
};

export default MergeConnection;

import React from "react";
import { useLocation, useParams } from "react-router";
import { FallbackTypes } from "../../../../types/enums";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../../../constants/NumericConstants";
import InfiniteScroll from "react-infinite-scroll-component";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { WorkspaceContext } from "../../../../contexts/WorkspaceContext";
import { eInvoicesClient } from "../../../../db/version3Accessor";
import { RefetchIntervals } from "../../../../constants/CacheConfig";
import PrimaryActivity from "./EInvoiceFeedItem/PrimaryActivity/PrimaryActivity";
import AuditLog from "./EInvoiceFeedItem/AuditLog/AuditLog";
import useLocale from "../../../../hooks/useLocale";
import FeedLoadingSkeleton from "./FeedLoadingSkeleton/FeedLoadingSkeleton";

import "./EInvoiceFeed.scss";

/**
 * @function EInvoiceFeed
 * A react functional component to fetch an E Invoice activity-stream by its id and render it in an infinite-scroll view.
 * @param {ActivityFeedProps} props - The input props for the functional component
 * The activity feed is cached and served using useInfiniteQuery hook from react-query.
 * On component mount, cache is enabled by default to triggers fetch activity-stream feed API through the function fetchFeed
 * Response is cached using the constant cachedActivityFeedKey identifier and cache will be refreshed as per
 * time interval defined under constant RefetchInterval.activityStreamFeed
 * The response of function fetchFeed is monitored by function createFeed to populate the feed object.
 * Actions that add activity in the feed are appended into existing feed using useMutation hook from react-query
 * @see {@link https://tanstack.com/query/latest/docs/react/reference/useQuery | useQuery}
 * @see {@link https://tanstack.com/query/latest/docs/react/reference/useInfiniteQuery | useInfiniteQuery}
 * @see {@link https://tanstack.com/query/latest/docs/react/reference/useMutation | useMutation}
 */
export default function EInvoiceFeed(): React.ReactElement {
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const { countryCode } = useLocale();
  const { activityId } = useParams<{ customerId: string; activityId: string }>();
  const { search, pathname } = useLocation();
  const eInvoiceType = pathname.includes("receivedEInvoices") ? "receivedEInvoice" : "sentEInvoice";
  const queryClient = useQueryClient();

  const activityPageDetails = {
    total_records: DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
    page: DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
    page_size: NUMERIC_VALUES.CONSTANT_TWENTY,
  };
  const feed = [];

  /**
   * @function fetchFeed
   * Function mapped to cache: cachedActivityFeedKey for fetching feed from API
   * @param pageParam
   * @returns ActivityStreamFetchResponse
   */
  const fetchFeed = async ({ pageParam = DEFAULT_NUMERIC_VALUES.DEFAULT_ONE }) =>
    eInvoicesClient.getEInvoice(selectedWorkspace?.id || FallbackTypes.Id, activityId, pageParam, activityPageDetails.page_size);

  /**
   * @constant cachedActivityFeedKey
   * Activity Feed cache key setup
   * Cache key being scoped on workspace_type, id, activityId and activity-feed string
   */
  const cachedActivityFeedKey = [
    `e-invoice-activity-stream-${selectedWorkspace?.workspace_type_route}-${selectedWorkspace?.id}-${activityId}`,
    activityId,
  ];
  const {
    isLoading: cacheLoading,
    data: feedAPIResponse,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: [cachedActivityFeedKey, activityId],
    queryFn: fetchFeed,
    getNextPageParam: () => {
      if (feed?.length < activityPageDetails.total_records) {
        return activityPageDetails.page + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE;
      }
      return undefined;
    },
    refetchInterval: RefetchIntervals.activityStreamFeed,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      /**
       * Mark the eInvoice as read if type is `receivedEInvoice` and
       * if the query params have `read=true` in URL (denoting this eInvoice is being opened for first time)
       * Refresh the meta and einvoices index(specifi page) queries to purge stale state.
       * Also, removing the query param from path once objective is acheived.
       */
      const unreadQps = new URLSearchParams(search);
      if (eInvoiceType === "receivedEInvoice" && unreadQps.get("read")) {
        eInvoicesClient
          .patchEInvoice(selectedWorkspace?.id || FallbackTypes.Id, activityId ?? "", {
            read: true,
          })
          .then(() => {
            queryClient.refetchQueries({ queryKey: [`${selectedWorkspace?.workspace_type}-${selectedWorkspace?.id}`], type: "active", exact: true });
            const { assigneeType, pageNumber, pageSize, searchStatus, sortQuery } = Object.fromEntries(unreadQps);
            queryClient.refetchQueries({
              queryKey: [
                `activity-streams-index-${selectedWorkspace?.workspace_type_route}-${assigneeType}-${searchStatus}-${pageSize}-${pageNumber}-${sortQuery}`,
              ],
            });
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            window.history.replaceState(history.state, "", window.location.origin + window.location.pathname);
          });
      }
    },
  });

  return (
    <div className="activity-feed-wrapper e-invoicing v2">
      <div id="infinite-scroll" className="e-invoice-feed-infinite-scrol-container">
        {cacheLoading ? (
          <FeedLoadingSkeleton />
        ) : (
          <InfiniteScroll
            className="e-invoice-feed-infinite-scroll"
            dataLength={feed?.length}
            next={fetchNextPage}
            hasMore={hasNextPage ?? false}
            loader={<FeedLoadingSkeleton />}
            scrollableTarget="infinite-scroll"
          >
            {feedAPIResponse?.pages.map((page) => (
              <div key={page?.data}>
                {page?.data.map((feedItem: any) => {
                  if (feedItem.type === "einvoice") {
                    return <PrimaryActivity key={feedItem?.id} feedItem={feedItem} />;
                  } else {
                    return <AuditLog key={feedItem?.id} feedItem={feedItem} countryCode={countryCode}></AuditLog>;
                  }
                })}
              </div>
            ))}
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
}

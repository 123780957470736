import React from "react";
import _ from "underscore";
import { AutomateActivity } from "../../../../../../library/Icons/Icons";
import { SmartText } from "../../../../../../library/SmartText";
import { BaseActivityProps } from "../SecondaryActivityTypes";
import "./BaseActivity.scss";
import { DEFAULT_NUMERIC_VALUES } from "../../../../../../../constants/NumericConstants";
import { formatDate, getDisplayDateFormat } from "../../../../../../../db/utils/date";

const BaseActivity: React.FC<BaseActivityProps> = ({ creator, flags: ACTION_FLAGS, headerActions, countryCode, children }) => {
  return (
    <div className="details-wrapper v2">
      <div className="header-row">
        <div className="left">
          <SmartText title={creator.name ?? ""} className="creator" />
          {!_.isNull(creator.message) && (
            <>
              <div className="time-wrapper">
                {ACTION_FLAGS?.TIME_SAVING_INDICATOR && <AutomateActivity />}
                <SmartText title={creator.message} className="message" />
              </div>
            </>
          )}
        </div>
        <div className="right">
          {!_.isUndefined(headerActions) && headerActions?.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && headerActions}
          <div className="date">{formatDate(creator.createdOn, getDisplayDateFormat(countryCode ?? ""))}</div>
        </div>
      </div>

      <div className="children-container">{children}</div>
    </div>
  );
};

export default BaseActivity;

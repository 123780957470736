import React from "react";
import { ActivityPopupRef, ActivityToRef } from "../components/library/AddNewActivityDropdown/NewActivityPopup";

const useRefreshApprover = () => {
  /** approval request's first TO contact address */
  const [approver, setApprover] = React.useState<To>();

  /**
   * references to gain control over new activity popup events
   *
   * for e.g. update the template content when the contact in ActivityTo
   * is updated
   */
  const toRef = React.useRef<ActivityToRef>(null);
  const popupRef = React.useRef<ActivityPopupRef>(null);

  toRef.current = {
    onSelectTo: (contact: To[]) => {
      setApprover(contact?.[0]);
    },
  };

  /**
   * rerender the activity popup body to reflect approver changes
   */
  React.useEffect(() => {
    popupRef?.current?.refreshPopupContent();
  }, [approver]);

  return { approver, setApprover, toRef, popupRef };
};

export default useRefreshApprover;

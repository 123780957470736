import React from "react";
import { VIEW_CATEGORIES, WorkspaceType } from "../../../types/enums";
import useWorkspaceConfigurations from "../../../hooks/useWorkspaceConfigurations";
import ActivitiesTable from "../ActivitiesTable/ActivitiesTable";
import activityTableConfigs, { ActivityTableViews } from "../ActivitiesTable/activityTable.configs";
import useProcessTableConfigs from "../../../hooks/useProcessTableConfigs";

export default function Mine(): React.ReactElement {
  const { selectedWorkspace } = useWorkspaceConfigurations();

  const { /* tableConfigs, */ columns: columnsConfigs } = activityTableConfigs();
  const currentWorkspaceType = (selectedWorkspace.workspace_type_route ?? WorkspaceType.AR) as WorkspaceType;
  const columns = useProcessTableConfigs(columnsConfigs, currentWorkspaceType, ActivityTableViews.AssignedActivitiesTable);

  return (
    <div className={`table-activity-wrapper activity-stream-v2`}>
      <div className="body-table">
        <ActivitiesTable columns={columns} assigneeType={"me"} route={"mine"} category={VIEW_CATEGORIES.MINE_ACTIVITES} />
      </div>
    </div>
  );
}

import React, { useState } from "react";
import { msalInstance } from "..";
import { leadsClient, reportsClient, syncClient, userRolesClient } from "../db/accessor";
import { StatusClientV2 } from "../db/version2Accessor";
import { appInsights } from "../AppInsights";
import { UserRoles, UserActions } from "../types/enums";
import { DEFAULT_NUMERIC_VALUES } from "../constants/NumericConstants";
import { globalSearchRemoveStorage } from "../components/GlobalSearch/Utils/LocalStorageUtils";
import { advanceGlobalSearchRemoveStorage } from "../components/AdvanceGlobalSearch/Utils/LocalStorageUtils";
import { useColumnFilters } from "../components/library/AtomicComponents/Table";
import { AxiosError } from "axios";
import { CONNECTION_STATUS } from "../constants/ConnectionConstants";

export const AppContext = React.createContext<AppType | null>(null);

export interface Props {
  children: (React.ReactNode & { type: string })[] | (React.ReactNode & { type: string });
}

const AppProvider: React.FC<Props> = ({ children }: Props) => {
  const [selectedERP, setSelectedERP] = useState<ERPModel | null>(null);
  const [syncError, setSyncError] = useState<boolean>(false);
  const [erpConnectionError, setERPConnectionError] = useState<boolean>(false);
  const [initialSyncInProgress, setInitialSyncInProgress] = useState<boolean>(true);
  const [userStatus, setUserStatus] = useState<StatusModelV2>({
    user_name: "",
    account_name: "",
    account_company_id: "",
    user_id: "",
    group_key: "",
    logged_in: false,
    roles: [],
    user_status: "",
    environment: "",
    version: "",
    onboarding_scheduled: false,
    dependencies: null,
    user_groups: [],
    account_id: "",
    account_type: "",
    email: "",
    email_address: "",
    erp_type: "",
    user_title: "",
    default_workspace: null,
    currency: { code: "USD", symbol: "$", locale: "en-US" },
    ap_automation_email: "",
    inbox_account_id: null,
    inbox_user_id: null,
    locale_data: {
      country_code: "US",
      multi_locale: false,
      locales: [],
    },
    workspace_permissions: [],
    workspaces: [],
  });
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [isCompanySwitching, setCompanySwitcher] = useState<boolean>(false);
  const [userRoles, setUserRoles] = useState<UserRoleModel[]>([]);
  const [selectedUserGroup, setSelectedUserGroup] = useState<UserGroupModel>({} as UserGroupModel);
  const [userLoggedOut, setUserLoogedOut] = useState<boolean>(false);
  const { removeSearchSuggestionStorage } = useColumnFilters();

  const permissionMap = new Map();
  permissionMap.set(UserActions.AddNote, [UserRoles.GroupOwner, UserRoles.GroupAdmin, UserRoles.Member]);
  permissionMap.set(UserActions.InvitationsCheckbox, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.InvitationsRemind, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.InvitationsRemove, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.InviteMembers, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.InvitationsChangeRole, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.RemovedMembersInvite, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.RemovedMembersCheckbox, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.ActiveMembersCheckbox, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.ActiveMembersRemove, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.ChangeRoles, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.EditMyProfile, [UserRoles.GroupOwner, UserRoles.GroupAdmin, UserRoles.Member]);
  permissionMap.set(UserActions.EditMyCompany, [UserRoles.GroupOwner, UserRoles.GroupAdmin, UserRoles.Member]);
  permissionMap.set(UserActions.AddEmailClient, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.EditAccountingSoftware, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.RunSync, [UserRoles.Member, UserRoles.GroupOwner, UserRoles.GroupAdmin]);
  permissionMap.set(UserActions.EditWorkspace, [UserRoles.GroupOwner, UserRoles.GroupAdmin]);

  async function getARHeader(reportDate: string, companyId?: string, columnFilters?: string): Promise<ArHeaderInfoModel> {
    return reportsClient.getARHeader(reportDate, companyId, columnFilters).then((data: ArHeaderInfoModel) => {
      return data;
    });
  }

  async function getAPHeader(reportDate: string, companyId?: string, columnFilters?: string): Promise<ApHeaderInfoModel> {
    return reportsClient.getAPHeader(reportDate, companyId, columnFilters).then((data: ApHeaderInfoModel) => {
      return data;
    });
  }

  async function getDPOHeader(reportDate: string, companyId?: string): Promise<ApDPOInfoHeader> {
    return reportsClient.getDPOHeader(reportDate, companyId).then((data: ApDPOInfoHeader) => {
      return data;
    });
  }

  async function getDocumentsHeader(companyId?: string): Promise<AttachmentHeaderInfoModel> {
    return reportsClient.getAttachmentsHeader(companyId).then((data: AttachmentHeaderInfoModel) => {
      return data;
    });
  }

  // Clears the user's session and redirects back to login
  function logout(): void {
    msalInstance.logoutRedirect();
    appInsights?.clearAuthenticatedUserContext();
    sessionStorage.removeItem("selectedWorkspaceId");
    globalSearchRemoveStorage();
    advanceGlobalSearchRemoveStorage();
    removeSearchSuggestionStorage();
    setUserLoogedOut(true);
  }

  function getTokenFromQueryString(): string | null {
    const urlParams = new URLSearchParams(window.location.search);

    if (!urlParams.has("id_token")) {
      return null;
    } else {
      return String(urlParams.get("id_token"));
    }
  }

  /**
   * @function getStatus
   * A helper function to fetch the status information of logged in user from v2 API
   * @returns Promise<StatusModelV2>
   */
  async function getStatus(): Promise<StatusModelV2> {
    const response = await StatusClientV2.getStatusV2()
      .then((res: APIResponse) => {
        window.localStorage.setItem("groupKey", res?.data?.group_key);

        setUserStatus((res?.data ? { ...userStatus, ...res?.data } : res?.data) as StatusModelV2);
        return res?.data;
      })
      .catch((err: AxiosError) => {
        if (
          err.response?.status === CONNECTION_STATUS.LINK_EXPIRED_422.STATUS_CODE &&
          err.response?.data?.messages?.errors_meta?.base[0] === "User is not associated with a group."
        ) {
          window.location.href = process.env.REACT_APP_ACCOUNT_REDIRECT_URL ?? "";
        }
        setInitialLoad(false);
      });

    await userRolesClient
      .query()
      .then((data: UserRoleModelFetchResult) => {
        setUserRoles(
          data?.records?.sort((a: UserRoleModel, b: UserRoleModel) =>
            a.userRoleId < b.userRoleId ? DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE : DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
          ) ?? []
        );
      })
      .catch(() => {
        setUserRoles([]);
      });
    setInitialLoad(false);
    return response;
  }

  /**
   * A helper function to fetch user status and update the state.
   * @returns Promise<StatusModelV2>
   */
  async function updateUserStatus(fetchPlatform = false): Promise<StatusModelV2> {
    let response = {} as StatusModelV2;
    try {
      const res = await StatusClientV2.getStatusV2(fetchPlatform);
      setUserStatus(res?.data as StatusModelV2);
      response = res?.data;
    } catch (error) {
      console.error(error);
    }
    return response;
  }

  function hasPermission(action: string) {
    if (action) {
      return permissionMap.get(action).some((role: string) => userStatus.roles?.includes(role));
    } else {
      return false;
    }
  }

  async function createLead(leadData: LeadsModel[]): Promise<LeadsModel[]> {
    return leadsClient.createLead(leadData);
  }

  async function getSyncStatus(syncRequestId: string, includeDetails?: boolean): Promise<SyncRequestModel> {
    return syncClient.getSyncStatus(syncRequestId, includeDetails);
  }

  return (
    <AppContext.Provider
      value={{
        logout,
        getTokenFromQueryString,
        getStatus,
        createLead,
        getSyncStatus,
        setSelectedERP,
        setInitialSyncInProgress,
        selectedERP,
        initialSyncInProgress,
        getARHeader,
        getAPHeader,
        getDPOHeader,
        getDocumentsHeader,
        erpConnectionError,
        setERPConnectionError,
        syncError,
        setSyncError,
        userStatus,
        hasPermission,
        initialLoad,
        userRoles,
        selectedUserGroup,
        setSelectedUserGroup,
        updateUserStatus,
        isCompanySwitching,
        setCompanySwitcher,
        userLoggedOut,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;

import APIClient from "../APIClient";
const PLATFORM_API_BASE = process.env.REACT_APP_API_ENDPOINT_PLATFORM_V1;

export class NotesClient {
  /**
   * * GET on /api/v1/Notes/{id}
   * @param id - The unique ID number of the Note to retrieve
   * @param include - To fetch additional data on this object, specify the list of elements to retrieve.
   * @returns Promise of type NoteModel or Exception
   */
  get(id: string, include?: string): Promise<NoteModel> {
    const url = `${PLATFORM_API_BASE}/Notes/${id}`;
    const options = {
      params: {
        include,
      },
    };
    return APIClient.get(url, options).then((res) => res.data);
  }

  /**
   * * DELETE on /api/v1/Notes/{id}
   * @param id - The unique ID number of the Note to retrieve
   * @returns Promise of type ActionResultModel or Exception
   */
  delete(id: string): Promise<ActionResultModel> {
    const url = `${PLATFORM_API_BASE}/Notes/${id}`;
    return APIClient.delete(url).then((res) => res.data);
  }

  /**
   * * POST on /api/v1/Notes
   * @param body - Array of NoteModels to be posted
   * @returns Promise of type NoteModel[] or Exception
   */
  post(body: Partial<NoteModel>[]): Promise<NoteModel[]> {
    const url = `${PLATFORM_API_BASE}/Notes`;
    return APIClient.post(url, body).then((res) => res.data);
  }

  /**
   * * GET on /api/v1/Notes/query
   * @param filter - The filter to use to select from the list of available apps (Searchlight query syntax)
   * @param include - Specify options
   * @param order - The sort order for the results (Searchlight query syntax)
   * @param pageSize - The page size for results (Defaults to 200, maximum of 1,000)
   * @param pageNumber - The page number for results (Defaults to 0)
   * @returns Promise of type NoteModelFetchResult or Exception
   */
  query(filter?: string, include?: string, order?: string, pageSize?: number, pageNumber?: number): Promise<NoteModelFetchResult> {
    const url = `${PLATFORM_API_BASE}/Notes/query`;
    const options = {
      params: {
        filter,
        include,
        order,
        pageSize,
        pageNumber,
      },
    };
    return APIClient.get(url, options).then((res) => res.data);
  }
}

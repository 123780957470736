import React from "react";
import { CustomButton, PreviewCardBodyContent, TemplatePreviewCard } from "../styled/email.template.styled";
import DropdownListComponent from "./DropdownListComponent";
import { TemplatePreviewCardProps } from "../types/email.template.popover.types";
import DOMPurify from "dompurify";
import useCheckContentOverflow from "../hook/useCheckContentOverflow";

/**
 * @param selectedTemplateId: to pass in the callback when user applied the template
 * @param titleText: template preview card subject title text
 * @param subjectContent: template preview card subject content text
 * @param showTemplatePreviewHeaderShadow:[optional]- boolean value to show shadow styling when user scroll preview content
 * @param showTemplatePreviewFooterShadow:[optional]- boolean value to show shadow styling when user scroll preview content
 * @param onScrollTemplatePreviewBodyContainer: to handle template preview scroll event
 * @param templatePreviewBodyContentRef: to attach the ref to the preview container to implement scroll behaviour
 * @param bodyContent: template preview card body content text
 * @param buttonText:[optional] footer button text
 * @param onClickUseTemplateButton: to handle use the selected template
 * @param isTempPreviewLangDrpdwnRequired:[optional]- if it's true then only langauge drodown will be display along with subject,
 * should only enable when preview is requires [for the approval request template]
 */

const TemplatePreviewCardComponent: React.FC<TemplatePreviewCardProps> = ({
  selectedTemplateId,
  headerComponentProps: { titleText, subjectContent, showTemplatePreviewHeaderShadow, isTempPreviewLangDrpdwnRequired, dropdownComponentProps },
  bodyComponentProps: { bodyContent, onScrollTemplatePreviewBodyContainer, templatePreviewBodyContentRef },
  footerComponentProps: { buttonText = "Use Template", showTemplatePreviewFooterShadow, onClickUseTemplateButton },
}) => {
  const { isOverflowed } = useCheckContentOverflow(templatePreviewBodyContentRef, bodyContent);
  return (
    <TemplatePreviewCard>
      {/** ------ HEADER ------ */}
      <div className={showTemplatePreviewHeaderShadow ? "sticky-preview-header-container" : "preview-header-container"}>
        <div className="subject-content">
          <span className="title">{titleText}</span>
          <span
            className="body-content"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(subjectContent),
            }}
          />
        </div>

        {isTempPreviewLangDrpdwnRequired && <DropdownListComponent {...dropdownComponentProps} />}
      </div>

      {/** ------ BODY ------ */}
      <PreviewCardBodyContent
        ref={templatePreviewBodyContentRef}
        onScroll={onScrollTemplatePreviewBodyContainer}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(bodyContent),
        }}
      />

      {/** ------ FOOTER ------ */}
      <div className={showTemplatePreviewFooterShadow && isOverflowed ? "sticky-preview-footer-container" : "preview-footer-container"}>
        <CustomButton onClick={() => onClickUseTemplateButton(selectedTemplateId)}>{buttonText}</CustomButton>
      </div>
    </TemplatePreviewCard>
  );
};

export default TemplatePreviewCardComponent;

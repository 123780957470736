import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import LoadingGIF from "../../../../assets/loading.gif";
import { ReactComponent as ExportSuccess } from "../../../../assets/ExportSuccess.svg";
import { EInvoiceExportModalStates } from "../../../../constants/ExportConstant";
import { ButtonMaster } from "../../../library/AtomicComponents/atomic/Button/button.styles";
import { useTranslation } from "react-i18next";

import "./ExportProgressModal.scss";

type ExportProgressModalProps = {
  open: boolean;
  state: string;
  onSuccess: () => void;
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "31.5625rem",
  height: "19.1875rem",
  padding: "1.5rem",
  flexDirection: "column",
  alignItems: "center",
  gap: "1.5rem",
  borderRadius: "1rem",
  bgcolor: "#FFF",
  boxShadow: "0rem 0.0625rem 0.125rem 0rem rgba(0, 0, 0, 0.15)",
  p: 4,
};

const ExportProgressModal: React.FC<ExportProgressModalProps> = ({ open, state, onSuccess }) => {
  const { t } = useTranslation();
  const primaryText =
    state === EInvoiceExportModalStates.EXPORT_IN_PROGRESS
      ? t("table.exports.modal.inProgress.primaryText", { ns: "einvoices" })
      : t("table.exports.modal.success.primaryText", { ns: "einvoices" });
  const secondaryText =
    state === EInvoiceExportModalStates.EXPORT_IN_PROGRESS ? t("table.exports.modal.inProgress.secondaryText", { ns: "einvoices" }) : null;
  return (
    <Modal open={open} className="export-progress-modal" aria-labelledby="modal-modal-export-progress" aria-describedby="modal-modal-export-progress">
      <Box sx={style}>
        <div className="modal-title">{t("table.exports.modal.dialogHeader", { ns: "einvoices" })}</div>
        <div className="modal-body">
          <p className="primary-text">{primaryText}</p>
          {state === EInvoiceExportModalStates.EXPORT_IN_PROGRESS ? <img src={LoadingGIF} width="311" height="175"></img> : <ExportSuccess />}
          {secondaryText && <p className="secondary-text">{secondaryText}</p>}
          {state === EInvoiceExportModalStates.EXPORT_SUCCESS && (
            <div className="modal-footer-button-container">
              <ButtonMaster
                btnVariant="primary"
                onClick={() => {
                  onSuccess();
                }}
              >
                {t("table.exports.modal.callToAction", { ns: "einvoices" })}
              </ButtonMaster>
            </div>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default ExportProgressModal;

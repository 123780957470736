import React, { ReactElement, useRef, useState, useEffect, useMemo } from "react";
import { Important, TextIcon, BinIcon, SignatureAttachmentIcon } from "../../library/Icons/Icons";
import DOMPurify from "dompurify";
import { Jodit } from "jodit-pro-react";
import JoditEditor from "../../library/NewActivityCard/ActivityBody/JoditHOC/JoditHOC";
import { IJodit, IUIButtonState } from "jodit/types";
import Button from "../../library/Button/Button";
import { NUMERIC_VALUES, RTE_FONT_SIZE_LIST } from "../../../constants/NumericConstants";
import { SignatureClientsV2 } from "../../../db/version2Accessor";
import { AlertContext } from "../../../contexts/AlertContext";
import SetupMessage from "./MessageBox/SetupMessage";
import AwsomeText from "./MessageBox/AwsomeText";
import _ from "underscore";
import Loading from "../../library/Loading/Loading";
import { AxiosError } from "axios";
import { Modal } from "../../library/DialogModal";
import { CustomerContext } from "../../../contexts/CustomerContext";
import {
  UndoDark,
  RedoDark,
  BoldDark,
  ItalicDark,
  UnderlineDark,
  StrikethroughDark,
  Left,
  Right,
  Center,
  Justify,
  Ul,
  Ol,
  Image,
  LinkDark,
} from "../../library/NewActivityCard/ActivityBody/JoditIcons/JoditIcons";
import "./SignatureSettings.scss";
import { DisableFeatureContext, DisableFeatureProviderType } from "../../../contexts/DisableFeatureContext";
import useDragAndDropFiles from "../../../hooks/useDragAndDropFiles";

const SignatureSettings = (): ReactElement => {
  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const { setDisableKeyboardShortcut } = React.useContext(DisableFeatureContext) as DisableFeatureProviderType;
  const EditorInstance = useRef<IJodit>();
  const editor = useRef<HTMLTextAreaElement | undefined | null>(null);
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [currentCursorPosition, setCurrentCursorPosition] = useState<null | undefined | Node>(null);
  const [isToolbarActive, setToolbarActive] = useState<boolean>(false);
  const toolbarRef = useRef<HTMLDivElement | null>(null);
  const { droppedFiles, handleFilesDragOver, handleFilesDrop } = useDragAndDropFiles(
    [".jpeg", ".jpg", ".png", ".bmp"],
    "Unsupported file type. Please add a jpeg/jpg, png or bmp image."
  );

  const config = useMemo(() => {
    return {
      license: process.env.REACT_APP_JODIT_LICENSE,
      autofocus: false,
      showTooltip: false,
      readonly: false, //if required props?.forwarding can be used to control this.
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      spellcheck: true,
      toolbarButtonSize: "large" as IUIButtonState["size"],
      minHeight: "27.25rem",
      maxHeight: "27.25rem",
      tabIndex: 1,
      buttons: "font,fontsize,brush,bold,italic,underline,strikethrough,align,ul,ol,image,undo,redo",
      buttonsMD: "font,fontsize,brush,bold,italic,underline,strikethrough,align,ul,ol,image,undo,redo",
      buttonsSM: "font,fontsize,brush,bold,italic,underline,strikethrough,align,ul,ol,image,undo,redo",
      buttonsXS: "font,fontsize,brush,bold,italic,underline,strikethrough,align,ul,ol,image,undo,redo",
      editorCssClass: "na-body-editor",
      disablePlugins: "add-new-line,inline-popup, tune-block",
      activeButtonsInReadOnly: [],
      extraButtons: ["LinkEditorPlugin"],
      extraIcons: {
        LinkEditorPlugin: LinkDark,
      },
      enter: "div" as any,
      placeholder: "Best Regards,<br/><br/>Your name <br/>Your position, team name <br/>company url.com",
      placeholderCssClass: "",
      events: {
        getIcon: (_name: string, _control: any, clearName: string) => {
          let code = clearName;
          switch (clearName) {
            case "undo":
              code = UndoDark;
              break;
            case "redo":
              code = RedoDark;
              break;
            case "bold":
              code = BoldDark;
              break;
            case "italic":
              code = ItalicDark;
              break;
            case "underline":
              code = UnderlineDark;
              break;
            case "strikethrough":
              code = StrikethroughDark;
              break;
            case "left":
              code = Left;
              break;
            case "ul":
              code = Ul;
              break;
            case "ol":
              code = Ol;
              break;
            case "right":
              code = Right;
              break;
            case "center":
              code = Center;
              break;
            case "justify":
              code = Justify;
              break;
            case "image":
              code = Image;
              break;

            default:
              return;
          }
          return code;
        },
        afterInit: (instance: IJodit) => {
          EditorInstance.current = instance;
        },
      },
      controls: {
        fontsize: {
          list: Jodit.atom([...Object.values(RTE_FONT_SIZE_LIST)]),
        },
      },
    };
  }, []);

  const [editorState, setEditorState] = useState<string>("");
  const [signature, setSignature] = useState<Signature>({} as Signature);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [actionLoading, setActionLoading] = useState<boolean>(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [showAwesomeText, setShowAwesomeText] = useState<boolean>(false);
  const { getUserSignature: updateUserSignatureInContext } = React.useContext(CustomerContext) as CustomerType;
  const [isSignaturePresent, setIsSignaturePresent] = useState<boolean>(false);

  /**
   * @function getSignature
   * A helper function to get the signature for a given user
   */
  const getSignature = () => {
    setIsLoading(true);
    setEditorState("");
    let toastOptions: ToastOptions = { open: true, severity: "error", message: "" };
    SignatureClientsV2.fetch()
      .then((response: SignatureFetchModel) => {
        setSignature(response.data);
      })
      .catch((error: AxiosError) => {
        const errorResponse = error.response as AxiosErrorResponseData;
        toastOptions = {
          ...toastOptions,
          severity: "error",
          message: errorResponse?.data?.messages?.errors[0] ?? "Something went wrong",
        };
        if (errorResponse?.data?.messages?.errors[0] !== "User No signature present") {
          setToastOptions(toastOptions);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * @function saveSignature
   * A helper function to save signature for a user
   */
  const saveSignature = () => {
    setActionLoading(true);
    let toastOptions: ToastOptions = { open: true, severity: "success", message: "" };
    SignatureClientsV2.create({ email_signature: editorState })
      .then((response: SignatureFetchModel) => {
        toastOptions = {
          ...toastOptions,
          severity: "success",
          message: response?.messages?.success[0] ?? "Signature added successfully",
        };
      })
      .catch((error: AxiosError) => {
        const errorResponse = error.response as AxiosErrorResponseData;
        toastOptions = {
          ...toastOptions,
          severity: "error",
          message: errorResponse?.data?.messages?.errors[0] ?? "Something went wrong",
        };
      })
      .finally(() => {
        setToastOptions(toastOptions);
        setActionLoading(false);
        setShowAwesomeText(true);
        getSignature();
      });
  };

  /**
   * @function deleteSignature
   * A helper function to delete signature for a user
   */
  const deleteSignature = () => {
    setActionLoading(true);
    let toastOptions: ToastOptions = { open: true, severity: "success", message: "" };
    SignatureClientsV2.delete()
      .then(() => {
        toastOptions = {
          ...toastOptions,
          severity: "success",
          message: "Your signature have been removed",
        };
        setSignature({} as Signature);
      })
      .catch((error: any) => {
        toastOptions = {
          ...toastOptions,
          severity: "error",
          message: error?.data?.messages?.errors[0] ?? "Something went wrong",
        };
      })
      .finally(() => {
        setToastOptions(toastOptions);
        setActionLoading(false);
        setConfirmModalVisible(false);
        setShowAwesomeText(false);
        getSignature();
      });
  };

  /**
   * @function updateSignature
   * A helper function to edit/update signature for a user
   */
  const updateSignature = () => {
    setActionLoading(true);
    let toastOptions: ToastOptions = { open: true, severity: "success", message: "" };
    SignatureClientsV2.update({ email_signature: editorState })
      .then((response: SignatureFetchModel) => {
        toastOptions = {
          ...toastOptions,
          severity: "success",
          message: response?.messages?.success[0] ?? "Signature updated successfully",
        };
        setIsUpdate(false);
      })
      .catch((error: AxiosError) => {
        const errorResponse = error.response as AxiosErrorResponseData;
        toastOptions = {
          ...toastOptions,
          severity: "error",
          message: errorResponse?.data?.messages?.errors[0] ?? "Something went wrong",
        };
      })
      .finally(() => {
        setToastOptions(toastOptions);
        setActionLoading(false);
        setShowAwesomeText(true);
        getSignature();
      });
  };

  const setupEditorForUpdate = () => {
    setEditorState(signature.email_signature);
    setSignature({} as Signature);
    setIsUpdate(true);
  };

  const cancelUpdate = () => {
    setActionLoading(false);
    setIsUpdate(false);
    getSignature();
  };

  /**
   * @function validateEditorState
   * A helper function to check the editor state value
   * @param state
   */
  const validateEditorState = (state: string) => {
    if (state === "<div><br></div>" || state === "") {
      setIsSignaturePresent(false);
    } else {
      setIsSignaturePresent(true);
    }
  };

  const binButtonHandler = () => {
    const toolbar = toolbarRef.current?.querySelector<HTMLElement>(".jodit-toolbar__box");
    if (toolbar?.classList) {
      toolbar.classList.remove("visible");
    }
    setToolbarActive(false);
    setEditorState("");
    validateEditorState("");
    if (EditorInstance?.current) {
      EditorInstance?.current?.focus();
    }
  };

  // functions is responsible for processing and uploading an image file.
  const handleImageUpload = (file: File | null) => {
    // Check if a file was provided and if it's of an allowed image type

    if (!file || !["image/jpeg", "image/jpg", "image/png", "image/bmp"].includes(file.type)) {
      return;
    }
    // Create a FileReader instance, which is a built-in browser API that allows you to read the contents of a file.
    const reader = new FileReader();

    // The onloadend event handler is assigned to the reader, which will be triggered when the file's content has been read.
    reader.onloadend = () => {
      // Get the data URL representation of the image content from reader.result.
      const imageDataURL = reader.result;

      // Create an <img> element to display the uploaded image
      const img = document.createElement("img");
      img.src = imageDataURL as string;

      // Set the cursor position in the Jodit Editor (if available)
      if (currentCursorPosition) {
        EditorInstance?.current?.selection?.setCursorIn(currentCursorPosition, false);
      }

      // Insert the image into the Jodit Editor at the current selection
      EditorInstance.current?.selection.insertHTML(img);
    };

    // Read the file as a data URL, which can be used as the image source
    reader.readAsDataURL(file);
  };

  //handling the click event for uploading an image.
  const handleImageUploadClick = () => {
    // Find the toolbar element using the ref you've assigned
    const toolbar = toolbarRef.current?.querySelector<HTMLElement>(".jodit-toolbar__box");

    // If the toolbar element's classList is available
    if (toolbar?.classList) {
      // Remove the "visible" class from the toolbar's class list
      toolbar.classList.remove("visible");
    }

    // Deactivate the toolbar by setting toolbarActive to false
    setToolbarActive(false);

    // Programmatically trigger a click on the hidden file input element
    fileInputRef?.current?.click();
  };

  React.useEffect(() => {
    if (droppedFiles && droppedFiles.length > NUMERIC_VALUES.CONSTANT_ZERO) {
      const files = Array.from(droppedFiles);
      files.forEach((file) => {
        handleImageUpload(file);
      });
    }
  }, [droppedFiles]);

  const toggleToolbar = (reset = false) => {
    const toolbar = toolbarRef.current?.querySelector<HTMLElement>(".jodit-toolbar__box");
    if (reset && toolbar && toolbar.classList) {
      setToolbarActive(false);
      toolbar.classList.remove("visible");
      return;
    }
    if (toolbar?.classList) {
      if (toolbar?.classList.contains("visible")) {
        toolbar.classList.remove("visible");
        setToolbarActive(false);
      } else {
        setToolbarActive(true);
        toolbar.classList.add("visible");
      }
    }
  };

  const cacheCursorPosition = () => {
    setCurrentCursorPosition(EditorInstance?.current?.selection?.sel?.anchorNode);
  };

  useEffect(() => {
    getSignature();
    return () => {
      updateUserSignatureInContext();
    };
  }, []);

  useEffect(() => {
    setDisableKeyboardShortcut(true);
    return () => setDisableKeyboardShortcut(false);
  }, []);

  useEffect(() => {
    validateEditorState(editorState);
  }, [editorState]);

  return (
    <>
      <div className="signature-settings container">
        <div className="hyperlink-modal-container">{/* Insert hyperlink edit modal here*/}</div>
        <div className="hyperlink-edit-modal-container">{/* Insert hyperlink edit modal here*/}</div>
        {isLoading ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Loading isRelative />
          </div>
        ) : (
          <>
            <div className="signature-settings-body">
              {_.isEmpty(signature) && (
                <div className="signature-settings-message-text-container">
                  <SetupMessage />
                </div>
              )}
              {!_.isEmpty(signature) && showAwesomeText && (
                <div className="signature-settings-message-text-container">
                  <AwsomeText />
                </div>
              )}
              <div className="signature-container">
                {_.isEmpty(signature) ? (
                  <div className="signature-text-editor" onDragOver={handleFilesDragOver} onDrop={handleFilesDrop}>
                    <div className="jodit-wrapper" ref={toolbarRef}>
                      <div
                        className="bin-button-wrapper"
                        onClick={() => {
                          binButtonHandler();
                        }}
                      >
                        <BinIcon />
                      </div>
                      <div className="attachment-button-wrapper" onClick={handleImageUploadClick}>
                        <SignatureAttachmentIcon />
                      </div>
                      <input
                        className="attachment-upload-input"
                        type="file"
                        accept="image/jpeg, image/jpg, image/png, image/bmp"
                        ref={fileInputRef}
                        onChange={(event) => handleImageUpload(event.target.files?.[0] ?? null)}
                      />
                      <div
                        className={`jodit-toolbar-toggle ${isToolbarActive ? "active" : ""}`}
                        onClick={() => {
                          toggleToolbar();
                        }}
                      >
                        <TextIcon />
                      </div>
                      <JoditEditor
                        ref={editor as React.Ref<Jodit>}
                        value={DOMPurify.sanitize(editorState, { USE_PROFILES: { html: true } })}
                        config={config}
                        onBlur={(newContent) => {
                          setEditorState(newContent);
                          cacheCursorPosition();
                        }}
                        onChange={validateEditorState}
                      />
                    </div>
                    <div className="save-button-container">
                      {isUpdate ? (
                        <>
                          <Button variant="secondary2" onClick={cancelUpdate}>
                            Cancel
                          </Button>
                          <Button variant="primary2" disabled={!isSignaturePresent} onClick={updateSignature} loading={actionLoading}>
                            Update Signature
                          </Button>
                        </>
                      ) : (
                        <Button variant="primary2" disabled={!isSignaturePresent} onClick={saveSignature} loading={actionLoading}>
                          {_.isEmpty(signature) ? "Add Signature" : "Update Signature"}
                        </Button>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="signature-text">
                    <div className="signature-header">Your Signature</div>
                    <div className="signature-body-container">
                      <div
                        className="signature-body"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(signature.email_signature),
                        }}
                      ></div>
                      <div className="signature-controls">
                        <Button variant="primary2" onClick={setupEditorForUpdate}>
                          Edit Signature
                        </Button>
                        <Button variant="secondary2" loading={actionLoading} onClick={() => setConfirmModalVisible(true)}>
                          Remove
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="signature-settings-help-text-container">
              <Important />
              <span className="signature-settings-help-text">
                Note: Including a signature ensures that your email recipient has the appropriate contact information for you or your department.
              </span>
            </div>
          </>
        )}
      </div>
      <Modal
        classes="modal-signature-settings"
        modalStates={{
          open: confirmModalVisible,
          onCloseFunction: () => setConfirmModalVisible(false),
        }}
        title={{
          label: "Are you sure!",
        }}
        content={{
          label:
            "Removing the signature will stop auto populating signatures from your email drafts and templates. This will add 1-2 minutes per email to add signature manually.",
        }}
        secondaryButton={{
          enable: true,
          type: "button",
          variant: "secondary2",
          label: "Cancel",
          onClickHandler: () => setConfirmModalVisible(false),
        }}
        primaryButton={{
          enable: true,
          type: "button",
          variant: "primary2",
          label: "Remove",
          loader: actionLoading,
          onClickHandler: deleteSignature,
        }}
      />
    </>
  );
};

export default SignatureSettings;

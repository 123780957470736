import { LinearProgress, Modal } from "@mui/material";
import React from "react";
import Button from "../Button/Button";
import { Close, Upload } from "../Icons/Icons";
import CropComponent from "./CropperComponent/CropContainer";
import DraggableComponent from "./DraggableComponent/DraggableContainer";
import "./UploadComponentStyles/UploadComponent.scss";
import { UploadComponentProps } from "./UploadComponentInterfaces/UploadComponentInterfaces";
import { DEFAULT_NUMERIC_VALUES } from "../../../constants/NumericConstants";

const UploadComponent: React.FC<UploadComponentProps> = ({
  cropperProps,
  enableFakeLoader,
  finalFileUploading,
  imageCropSuccess,
  imageLoadingLocal,
  imageLoadingLocalPercent,
  imageUploadSuccess,
  inputRef,
  labelProps,
  onClickCloseModal,
  onClickResetStates,
  onClickUploadButton,
  onCompleteCropping,
  onCompleteSave,
  open,
  onUploadSetImage,
  selectedImageBlobURL,
  selectedImageSize,
}: UploadComponentProps) => {
  const getLocalUploadSectionLoader = () => {
    return (
      <div className="upload_grp">
        <div className="label_grp">
          <p className="body2 dark-text">{labelProps?.fakeLoaderTitle || "Uploading"}</p>
          <p className="body2 faded-text">{selectedImageSize}</p>
        </div>

        <div className="progress_grp">
          <LinearProgress
            variant="determinate"
            color="inherit"
            sx={{
              width: "5.75rem",
              height: "0.75rem",
              borderRadius: "0.125rem",
              backgroundColor: "#FFFFFF",
            }}
            value={imageLoadingLocalPercent}
          />
          <div className="progress-close-icon" onClick={onClickResetStates}>
            <Close />
          </div>
        </div>
      </div>
    );
  };

  const getUploadButtonGroup = () => {
    return (
      <div className="btn-grp">
        <Button
          icon={<Upload />}
          disabled={selectedImageBlobURL?.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO}
          variant="primary"
          alignIcon={"left"}
          className="upload-btn"
          type="button"
          onClick={onClickUploadButton}
        >
          {labelProps?.buttonProps?.uploadButtonGroup?.actionLabel || "Upload An Image"}
        </Button>
        <Button variant="secondary" onClick={onClickCloseModal}>
          {labelProps?.buttonProps?.uploadButtonGroup?.cancelLabel || "Cancel"}
        </Button>
      </div>
    );
  };

  const getLocalUploadSection = () => {
    return (
      <>
        {!imageLoadingLocal && selectedImageBlobURL?.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && (
          <DraggableComponent
            labelProps={{
              dragNDropLabels: labelProps?.dragNDropLabels,
            }}
            inputRef={inputRef}
            setImage={onUploadSetImage}
          />
        )}
        {enableFakeLoader && imageLoadingLocal && selectedImageBlobURL?.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO && getLocalUploadSectionLoader()}
        {getUploadButtonGroup()}
      </>
    );
  };

  return (
    <Modal open={open} onClose={onClickCloseModal}>
      <div className="upload-modal-img">
        <span className="modal-close-icon" onClick={onClickCloseModal}>
          <Close />
        </span>
        <h2>{labelProps?.modalProps?.header || "Add Your Photo"}</h2>
        <p className="subheader">
          {imageUploadSuccess && !cropperProps?.disableCropper && !imageCropSuccess
            ? labelProps?.modalProps?.subHeaderCrop || "Drag your photo to position it."
            : labelProps?.modalProps?.subHeaderMain || "Bring a smile to your team by adding your own."}
        </p>
        {!imageUploadSuccess && getLocalUploadSection()}
        {imageUploadSuccess && (
          <CropComponent
            cropperProps={cropperProps}
            finalFileUploading={finalFileUploading}
            imageCropSuccess={Boolean(imageCropSuccess || cropperProps?.disableCropper)}
            imgSrc={selectedImageBlobURL}
            labelProps={{
              buttonProps: {
                cropperButtonGroup: labelProps?.buttonProps?.cropperButtonGroup,
                uploadToServerButtonGroup: labelProps?.buttonProps?.uploadToServerButtonGroup,
              },
              resetUploadText: labelProps?.resetUploadText,
            }}
            resetUploadedImage={onClickResetStates}
            onCompleteCropping={onCompleteCropping}
            onClickCloseModal={onClickCloseModal}
            onCompleteSave={onCompleteSave}
          />
        )}
      </div>
    </Modal>
  );
};

export default UploadComponent;

import React from "react";
import { TemplateListItemType } from "../types/email.template.popover.types";
import { NUMERIC_VALUES } from "../../AtomicComponents/constants/numeric.constants";

/**
 * @customhook useEmailTemplatePopover
 * A custom hook that manages all component logic in a single file.
 * @param templateList: list of templates
 * @param anchorReference:  "anchorEl" | "anchorPosition" | "none"
 */
const useEmailTemplatePopover = (templateList: TemplateListItemType[], anchorReference = "anchorEl") => {
  const [isEmailTemplatePopoverOpen, setIsEmailTemplatePopoverOpen] = React.useState<boolean>(false);
  const [iconAnchorEl, setIconAnchorEl] = React.useState<null | HTMLElement>(null);
  const [searchedText, setSearchedText] = React.useState<string>("");
  const [selectedTabId, setSelectedTabId] = React.useState<string | number>(NUMERIC_VALUES.CONSTANT_ZERO);
  const [selectedTemplateId, setSelectedTemplateId] = React.useState<string | number>("");
  const [appliedTemplateId, setAppliedTemplateId] = React.useState<string | number>("");
  const [selectedTemplateIndex, setSelectedTemplateIndex] = React.useState<number>(NUMERIC_VALUES.CONSTANT_ZERO);
  const [selectedLanguageCode, setSelectedLanguageCode] = React.useState<string | number>("en-US");
  const [selectedLanguageIndex, setSelectedLanguageIndex] = React.useState<number>(NUMERIC_VALUES.CONSTANT_ZERO);
  const [searchedTemplateResultList, setSearchedTemplateResultList] = React.useState<TemplateListItemType[]>(templateList);

  //states variables to handle scroll and shadow behaviour
  const [showTemplateListHeaderShadow, setShowTemplateListHeaderShadow] = React.useState(false);
  const [showTemplatePreviewHeaderShadow, setShowTemplatePreviewHeaderShadow] = React.useState(false);
  const [showTemplatePreviewFooterShadow, setShowTemplatePreviewFooterShadow] = React.useState(true);
  const templateListContainerRef = React.useRef<HTMLDivElement | null>(null); // Container ref
  const templateListItemRef = React.useRef<Array<HTMLLIElement | null>>([]); // Refs for individual list items
  const templatePreviewBodyContentRef = React.useRef<HTMLDivElement | null>(null); // Container ref

  //Handle onClick on the template icon to open email popover
  const onClickEmailTemplateIcon = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorReference === "anchorEl") {
      setIconAnchorEl(event.currentTarget);
    }
    setIsEmailTemplatePopoverOpen(true);
  };

  //To close email popover
  const onCloseEmailTemplatePopover = () => {
    if (anchorReference === "anchorEl") {
      setIconAnchorEl(null);
    }
    setIsEmailTemplatePopoverOpen(false);
  };

  //To switch tabs
  const onChangeTabs = (_event: React.ChangeEvent<object>, newValue: string) => {
    setSelectedTabId(newValue);
    setSearchedText("");
    setSelectedTemplateIndex(NUMERIC_VALUES.CONSTANT_ZERO);
    setSelectedTemplateId("");
    setShowTemplatePreviewFooterShadow(true);
  };

  //On change in the search input field
  const onChangeInSearchInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const searchVal = event.target.value;
    setSearchedText(searchVal);
    if (searchVal?.length >= NUMERIC_VALUES.CONSTANT_THREE) {
      setSelectedTemplateIndex(NUMERIC_VALUES.CONSTANT_ZERO);
      const filteredTemplates = templateList.filter((item) => item.templateName.toLowerCase().includes(searchVal?.toLowerCase()));
      setSearchedTemplateResultList(filteredTemplates);
      setSelectedTemplateId("");
      setShowTemplatePreviewFooterShadow(true);
    } else if (searchVal.length < NUMERIC_VALUES.CONSTANT_THREE && searchedText.length >= NUMERIC_VALUES.CONSTANT_THREE) {
      // Execute your desired actions when user clears or deletes text from the input
      setSelectedTemplateIndex(NUMERIC_VALUES.CONSTANT_ZERO);
      setSearchedTemplateResultList(templateList);
      setSelectedTemplateId("");
      setShowTemplatePreviewFooterShadow(true);
    }
  };

  //On scrolling the template list, when start scrolling and scroll not at the top then show shadow
  const onScrollTemplateListContainer = (event: React.UIEvent) => {
    const top = (event.target as HTMLElement).scrollTop;
    if (top > NUMERIC_VALUES.CONSTANT_ZERO) {
      setShowTemplateListHeaderShadow(true);
    } else {
      setShowTemplateListHeaderShadow(false);
    }
  };

  //On click the template list item to select the item and content
  const onClickTemplateListItem = (templateId: string | number, index: number) => {
    setSelectedTemplateId(templateId);
    setSelectedTemplateIndex(index);
    setShowTemplatePreviewFooterShadow(true);
  };

  //On scrolling the template preview card body container
  const onScrollTemplatePreviewBodyContainer = (event: React.UIEvent) => {
    const element = event.target as HTMLElement;
    const top = (event.target as HTMLElement).scrollTop;
    //The small discrepancy in the values (due to floating-point precision) ,This would consider the scroll as reaching the bottom when it's very close
    const tolerance = NUMERIC_VALUES.CONSTANT_ONE;

    const { clientHeight, scrollHeight } = element;
    const hasScrollReachedBottom = top + tolerance + clientHeight >= scrollHeight;
    if (top > NUMERIC_VALUES.CONSTANT_ZERO) {
      setShowTemplatePreviewHeaderShadow(true);
      setShowTemplatePreviewFooterShadow(!hasScrollReachedBottom);
    } else {
      setShowTemplatePreviewHeaderShadow(false);
      setShowTemplatePreviewFooterShadow(true);
    }
  };

  //On press arrow up and down to scroll and select the template list items
  const handleKeyDownEvent = (event: React.KeyboardEvent) => {
    const container = templateListContainerRef.current;
    const item = templateListItemRef.current;
    setShowTemplatePreviewFooterShadow(["ArrowUp", "ArrowDown"]?.includes(event.key));
    // Ensure the container exists
    if (!(container && item)) {
      return;
    }

    if (event.key === "ArrowUp" && selectedTemplateIndex > NUMERIC_VALUES.CONSTANT_ZERO) {
      const prevIndex = selectedTemplateIndex - NUMERIC_VALUES.CONSTANT_ONE;
      setSelectedTemplateIndex(prevIndex); // Move selection up
      setSelectedTemplateId(templateList?.[prevIndex]?.templateId);
      // Scroll up by the height of an item
      container.scrollTop -= item[selectedTemplateIndex - NUMERIC_VALUES.CONSTANT_ONE]?.clientHeight ?? NUMERIC_VALUES.CONSTANT_ZERO;
    } else if (event.key === "ArrowDown" && selectedTemplateIndex < templateList?.length - NUMERIC_VALUES.CONSTANT_ONE) {
      const nextIndex = selectedTemplateIndex + NUMERIC_VALUES.CONSTANT_ONE;
      setSelectedTemplateIndex(nextIndex); // Move selection down
      setSelectedTemplateId(templateList?.[nextIndex]?.templateId);
      // Scroll down by the height of an item
      container.scrollTop += item[selectedTemplateIndex + NUMERIC_VALUES.CONSTANT_ONE]?.clientHeight ?? NUMERIC_VALUES.CONSTANT_ZERO;
    }
  };

  //To change the language from the dropdown list
  const onClickLanguageDropdownItem = (languageCode: string | number, index: number) => {
    setSelectedLanguageCode(languageCode);
    setSelectedLanguageIndex(index);
    setSelectedTemplateIndex(NUMERIC_VALUES.CONSTANT_ZERO);
    setSearchedText("");
    setSelectedTemplateId("");
    setShowTemplatePreviewFooterShadow(true);
  };

  //To handle the event to use the template
  const onClickUseTemplateButton = (templatedId: string | number) => {
    setIsEmailTemplatePopoverOpen(false);
    setAppliedTemplateId(templatedId);
  };

  return {
    isEmailTemplatePopoverOpen,
    onClickEmailTemplateIcon,
    onCloseEmailTemplatePopover,
    onScrollTemplateListContainer,
    onScrollTemplatePreviewBodyContainer,
    onClickTemplateListItem,
    iconAnchorEl,
    onChangeTabs,
    searchedText,
    selectedTabId,
    selectedTemplateId,
    selectedTemplateIndex,
    onChangeInSearchInput,
    showTemplateListHeaderShadow,
    showTemplatePreviewHeaderShadow,
    showTemplatePreviewFooterShadow,
    handleKeyDownEvent,
    templateListContainerRef,
    templateListItemRef,
    templatePreviewBodyContentRef,
    onClickLanguageDropdownItem,
    selectedLanguageCode,
    selectedLanguageIndex,
    searchedTemplateResultList,
    onClickUseTemplateButton,
    appliedTemplateId,
  };
};

export default useEmailTemplatePopover;

import React, { useState, MouseEvent } from "react";
import { useHistory } from "react-router-dom";
import "./WorkspaceSwitcher.scss";
import { WorkspaceContext } from "../../../../contexts/WorkspaceContext";
import { ComponentConfigContext } from "../../../../contexts/ComponentConfigContext";
import { DEFAULT_NUMERIC_VALUES } from "../../../../constants/NumericConstants";
import Dialog from "../../../library/Dialog/Dialog";
import DialogActions from "../../../library/DialogActions/DialogActions";
import Button from "../../../library/Button/Button";
import DialogContent from "../../../library/DialogContent/DialogContent";
import DialogContentText from "../../../library/DialogContentText/DialogContentText";
import DialogTitle from "../../../library/DialogTitle/DialogTitle";
import Menu from "../../../library/Menu/Menu";
import MenuItem from "../../../library/MenuItem/MenuItem";
import Utils from "../../../../utils/utils";
import * as Labels from "../../../../constants/config";
import { UserActions, UserRoles } from "../../../../types/enums";
import _ from "underscore";
import { FeatureFlagContext, FeatureFlagProviderType } from "../../../../contexts/FeatureFlagContext";
import { AppContext } from "../../../../contexts/AppContext";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { NestedMenuItem } from "../../../library/NestedMenuItem/NestedMenuItem";

export type WorkspaceMenuListItem = {
  id: number;
  label: string;
  selected?: boolean;
  onClickHandler?: () => void;
};

export default function WorkspaceSwitcher(): React.ReactElement {
  const {
    hasPermission,
    updateUserStatus,
    userStatus: { workspaces: groupWorkspaces, roles: loggedInUserRoles, workspace_permissions: workspacePermissions },
  } = React.useContext(AppContext) as AppType;
  const { selectedWorkspace, createNewWorkspace, switchActiveWorkspace, showWorkspaceCreateModal, setShowWorkspaceCreateModal } = React.useContext(
    WorkspaceContext
  ) as WorkspaceDataType;
  const { isEnabled } = React.useContext(FeatureFlagContext) as FeatureFlagProviderType;
  const { getConfig } = React.useContext(ComponentConfigContext) as ComponentConfigType;
  const baseConfigs = getConfig("base");
  const history = useHistory();

  const addWorkspace = async (accountConfig: WorkspaceCreate) => {
    const res = await createNewWorkspace(accountConfig);
    await updateUserStatus();
    setShowWorkspaceCreateModal(res);
  };

  const [workspaceSwitchMenuAnchorEl, setWorkspaceSwitchMenuAnchorEl] = useState<HTMLDivElement | null>(null);
  const workspaceSwitchMenuOpen = Boolean(workspaceSwitchMenuAnchorEl);

  const [nestedMenuAnchorEl, setNestedMenuAnchorEl] = useState<HTMLLIElement | null>(null);
  const nestedMenuOpen = Boolean(nestedMenuAnchorEl);

  const getOnlyPermittedWorkspaces = (workspaces: WorkspaceModel[]) =>
    workspaces?.filter((record) => workspacePermissions?.includes(record?.workspace_type));
  let workspacesForSwitch: WorkspaceModel[] = [];
  if (groupWorkspaces) {
    workspacesForSwitch = loggedInUserRoles.includes(UserRoles.GroupOwner) ? groupWorkspaces : getOnlyPermittedWorkspaces(groupWorkspaces);
    // check selected workspace is permitted for the logged in user - incase selected workspace state coming from session storage on app load/refresh
    // skip this check if logged in user's role is Group Owner
    const isSelectedWorkspacePermitted = workspacePermissions.includes(selectedWorkspace?.workspace_type ?? "");
    if (!loggedInUserRoles.includes(UserRoles.GroupOwner) && !isSelectedWorkspacePermitted) {
      workspacesForSwitch?.[0]?.id && switchActiveWorkspace(workspacesForSwitch?.[0]?.id);
    }
  }

  /**
   * if feature flag is enabled => check for workspace_type is accounting
   * otherwise skip
   *
   * get workspace types which are not enabled
   */
  const WorkspaceMenuList = baseConfigs.workspaces.supportedWorkspaces
    ?.filter((workspace: WorkspaceConfig) => {
      // if combined accounting_workspace is enabled
      if (!isEnabled("ACCOUNTING_WORKSPACE") && workspace.workspace_type === "accounting") {
        return false;
      }
      return !_.pluck(workspacesForSwitch, "workspace_type").includes(workspace.workspace_type);
    })
    .map((w_space: WorkspaceConfig, index: number) => {
      return {
        id: index + DEFAULT_NUMERIC_VALUES.DEFAULT_ONE,
        label: w_space?.workspace_name,
        onClickHandler: () => {
          addWorkspace(w_space);
        },
      };
    }) as WorkspaceMenuListItem[];

  const getWorkspaceCreateModal = () => (
    <Dialog open={showWorkspaceCreateModal} onClose={() => setShowWorkspaceCreateModal(false)}>
      <DialogTitle className="h3">You're Almost There!</DialogTitle>
      <DialogContent className="mt-ba mb-lg">
        <DialogContentText className="body1">
          To make the most of your Sage Network experience, you’ll need to connect your team’s{" "}
          {selectedWorkspace?.workspace_type_route?.toUpperCase?.() + " " || " "}
          email address and add your team members to this workspace.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          className="mr-auto"
          variant="secondary"
          onClick={() => {
            setShowWorkspaceCreateModal(false);
          }}
        >
          {Labels.BTN_CANCEL}
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            setShowWorkspaceCreateModal(false);
            history.push(`/settings/emailSettings/workspace/${selectedWorkspace?.id}`);
          }}
        >
          {Labels.BTN_CONNECT_EMAIL}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const isAddWorkspaceEnabled =
    !_.isUndefined(workspacesForSwitch) &&
    !_.isEmpty(workspacesForSwitch) &&
    WorkspaceMenuList.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO &&
    !Utils.compareArrays(
      baseConfigs.workspaces.supportedWorkspaces.map((item: WorkspaceConfig) => item.workspace_type),
      groupWorkspaces.map((e) => e?.workspace_type?.toLowerCase?.())
    ) &&
    hasPermission(UserActions.EditWorkspace);

  /**
   * only enabled if -
   * EITHER user has permission for multiple workspaces
   * OR add workspace option is enabled
   */
  const isDropdownEnabled =
    (!_.isUndefined(workspacesForSwitch) && !_.isEmpty(workspacesForSwitch) && workspacesForSwitch.length > DEFAULT_NUMERIC_VALUES.DEFAULT_ONE) ||
    isAddWorkspaceEnabled;

  return (
    <div className="workspace-switcher-wrapper">
      <div className="switcher-container">
        <div
          className="workspace-switcher-item dropdown"
          onClick={(event: MouseEvent<HTMLDivElement>) => {
            setWorkspaceSwitchMenuAnchorEl(event.currentTarget);
          }}
        >
          <span className="workspace-title">{selectedWorkspace?.name}</span>
          {isDropdownEnabled && (workspaceSwitchMenuAnchorEl ? <KeyboardArrowUp /> : <KeyboardArrowDown />)}
        </div>
        {isDropdownEnabled && (
          <Menu
            open={workspaceSwitchMenuOpen}
            anchorEl={workspaceSwitchMenuAnchorEl}
            onClose={() => setWorkspaceSwitchMenuAnchorEl(null)}
            className="workspace-selector"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {workspacesForSwitch?.map((workspace) => {
              // only return accounting workspace option if feature flag is enabled
              if (!isEnabled("ACCOUNTING_WORKSPACE") && workspace.workspace_type === "accounting") {
                return <></>;
              }
              return (
                <MenuItem
                  key={workspace.id}
                  onClick={() => {
                    setWorkspaceSwitchMenuAnchorEl(null);
                    switchActiveWorkspace(workspace.id);
                  }}
                >
                  <span className="user-group-name">{workspace?.name || "--"}</span>
                </MenuItem>
              );
            })}
            {isAddWorkspaceEnabled && (
              <NestedMenuItem
                onClick={(event: MouseEvent<HTMLLIElement>) => {
                  setNestedMenuAnchorEl(event.currentTarget);
                }}
                label={Labels.BTN_ADD_WORKSPACE}
                parentMenuOpen={nestedMenuOpen}
              >
                {WorkspaceMenuList.map((item: WorkspaceMenuListItem) => {
                  return (
                    <MenuItem
                      key={item.label}
                      onClick={() => {
                        setNestedMenuAnchorEl(null);
                        item?.onClickHandler?.();
                      }}
                    >
                      {item.label}
                    </MenuItem>
                  );
                })}
              </NestedMenuItem>
            )}
          </Menu>
        )}
      </div>
      {getWorkspaceCreateModal()}
    </div>
  );
}

import React from "react";
import { Forward } from "../../../../../../library/Icons/Icons";
import PrimaryActivity from "../../PrimaryActivity/PrimaryActivity";
import * as Labels from "../../../../../../../constants/config";
import { ForwardActivityProps } from "../ActivityItemVariantTypes";
import { Button } from "../../../../../../library/AtomicComponents/atomic/Button";
import { useTranslation } from "react-i18next";

const ForwardedActivity: React.FC<ForwardActivityProps> = ({ onClickRedirect, ...props }) => {
  const { t } = useTranslation();
  return (
    <PrimaryActivity
      {...{
        ...props,
        creator: { ...props.creator, message: Labels.HEADER_FORWARDED },
        icon: <Forward />,
        FLAGS: { REPLY_OPTIONS: true },
      }}
    >
      <Button variant="secondary" size="small" onClick={onClickRedirect}>
        {t("activityDetails.feedItems.forwardedLogAndActivity.originalStreamButton", { ns: "activities" })}
      </Button>
    </PrimaryActivity>
  );
};

export default ForwardedActivity;

import { Liquid } from "liquidjs";
import { TEMPLATE_TIMING_CONSTANTS } from "../../constants/NumericConstants";
import BaseTemplate from "./BaseTemplate";
import { IData } from "./TemplateTypes";

/**
 * Template class which generate the text for Tax Exemption Certificate Request for customer .
 */
class APTaxExemptCertificate extends BaseTemplate {
  /**
   * Constructor
   * @param data
   * @param liquid
   */
  constructor(workSpace: string,bodyTpl: string ,liquid: Liquid, data?: IData) {
    super(workSpace, bodyTpl ,liquid, data);
  }

  /**
   * Templates From Time definition
   */
 protected fromTime = TEMPLATE_TIMING_CONSTANTS.ZERO;

 /**
 * Templates To Time definition
 */
protected toTime = TEMPLATE_TIMING_CONSTANTS.THREE_MIN;
}

export default APTaxExemptCertificate;

import React from "react";
import { Drawer } from "@mui/material";
import Button from "../../../library/Button/Button";
import { CustomerMergeComponentProps } from "../../CustomerInterfaces/CustomerMergeInterface";
import "./CustomerMerge.scss";
import { ACTIVITY_MERGE_HEADER_MESSAGE, ACTIVITY_MERGE_SUBHEADER_MESSAGE_ONE, CONNECTION_LIST } from "../../../../constants/config";
import { NUMERIC_VALUES } from "../../../../constants/NumericConstants";
import DebouncedAutocomplete from "../../../library/DebouncedAutocomplete/tsx/DebouncedAutocomplete";

export const CustomerMergeComponent = (props: CustomerMergeComponentProps) => {
  /** autocomplete helpers */
  const getOptionInfo = (company: CompanyV2) => {
    return {
      key: company.company_id,
      label: company.company_name ?? "",
    };
  };

  const isOptionEqualToValue = (company: CompanyV2, value: CompanyV2) => company.company_id === value.company_id;

  return (
    <Drawer className="customer-action-drawer" open={props.open} anchor="right" disablePortal onClose={() => props.onClose()}>
      <div className="flyout">
        <h3 className="header">{ACTIVITY_MERGE_HEADER_MESSAGE}</h3>
        <p className="subheader body1">{ACTIVITY_MERGE_SUBHEADER_MESSAGE_ONE}</p>
        <div>
          <DebouncedAutocomplete<CompanyV2>
            network={{
              apiCall: props.fetchCompanyList,
              debounce: true,
              delay: NUMERIC_VALUES.CONSTANT_FIVE_HUNDRED,
            }}
            data={{
              isOptionEqualToValue,
              optionParser: getOptionInfo,
              onOptionSelect: (option: CompanyV2 | null) => props.setSelectedCompany(option),
              searchPlaceholder: CONNECTION_LIST,
            }}
          />
        </div>
        <div className="btn-grp">
          <Button size="lg" onClick={() => props.setOpenDialog(true)} loading={props.isLoading} disabled={props.disableMerge}>
            Merge
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

import { TEMPLATE_TIMING_CONSTANTS } from "../../constants/NumericConstants";
import BaseTemplate from "./BaseTemplate";
class ARTaxExemptCertificate extends BaseTemplate {
  /**
   * Templates From Time definition
   */
   protected fromTime = TEMPLATE_TIMING_CONSTANTS.ZERO;

   /**
   * Templates To Time definition
   */
  protected toTime = TEMPLATE_TIMING_CONSTANTS.THREE_MIN; 
}

export default ARTaxExemptCertificate;
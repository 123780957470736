import APIClient from "../APIClient";
import { AxiosRequestConfig } from "axios";

const INBOX_API_BASE = `${process.env.REACT_APP_API_ENDPOINT_INBOX_API_PATH}${process.env.REACT_APP_API_ENDPOINT_INBOX_API_V2}`;
const CONFIG_OVERRIDE = {
  baseURL: process.env.REACT_APP_API_ENDPOINT_INBOX,
} as AxiosRequestConfig;

export class DashboardClient {
  /**
   * * Get on  /api/v2/dashboard/workspace_id/summary
   * @returns Promise of type APIResponse Model or Exception
   */
  dashboardSummary(workspace_id: number): Promise<APIResponse> {
    const url = `${INBOX_API_BASE}/workspaces/${workspace_id ?? ""}/dashboards/summary`;

    return APIClient.get(url, CONFIG_OVERRIDE).then((response) => {
      return response.data;
    });
  }

  getTopCustomers(filter_by: string, workspace_id: number, limit: number, sortQuery: string): Promise<APIResponse> {
    const url = `${INBOX_API_BASE}/workspaces/${workspace_id ?? ""}/dashboards/top_connections`;
    const options = {
      params: {
        filter_by,
        limit,
        "qs[s]": sortQuery,
      },
      ...CONFIG_OVERRIDE,
    };
    return APIClient.get(url, options).then((res) => res.data);
  }
}

import React, { useState } from "react";
import { AutomateActivity, CollapseIcon, ExpandIcon } from "../../../../library/Icons/Icons";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import "./Copilot.scss";
import NewActivityPopup, { NewActivityPopupProps } from "../../../../library/NewActivityCard/NewActivityCard";
import CustomTooltip from "../../../../library/AtomicComponents/CustomTooltip/CustomTooltip";
import _ from "underscore";
import { Liquid } from "liquidjs";
import { useTranslation } from "react-i18next";
import Button from "../../../../library/Button/Button";
import CopilotDiscardModal from "./CopilotDiscardModal";
import { SettingsContext } from "../../../../../contexts/SettingsContext";

type CopilotProps = {
  id: number;
  subject: string;
  content: string;
  attachments: Array<AttachmentItem>;
  from: ContactItem;
  to: ContactItem[];
  cc: ContactItem[];
  from_time: number;
  to_time: number;
  attributes: {
    invoice_number: string;
  };
  company_name: string;
  user: string;
  activityPopUpProps: NewActivityPopupProps;
  onClickDownload: (
    event: React.MouseEvent<HTMLTableRowElement | HTMLDivElement | HTMLButtonElement | HTMLAnchorElement | HTMLSpanElement>,
    attachments: AttachmentItem[] | BlobAttachmentItem[]
  ) => void;
  workspaceData?: UserItem;
  showNewActivity: boolean;
  onDiscard: () => void;
  isSendButtonDisabled: boolean;
};

const Copilot: React.FC<CopilotProps> = ({ activityPopUpProps, ...props }) => {
  const { updateCopilotSettings } = React.useContext(SettingsContext) as SettingsType;
  const [expanded, setExpanded] = useState<boolean>(true);
  const [showCopilotDiscardModal, setShowCopilotDiscardModal] = useState<boolean>(false);
  const liquid = new Liquid();
  const { t } = useTranslation();

  const getFormattedContacts = (contacts: ContactItem[]): To[] => {
    let formattedContacts = [] as To[];
    if (!_.isEmpty(contacts)) {
      formattedContacts = contacts?.map((item) => {
        return {
          id: item.email_address,
          label: item.name ?? item.email_address,
        } as To;
      });
    }
    return formattedContacts;
  };

  /**
   * @function parseResponseContent
   * Function to parse the template content and replace the dynamic values ex. Invoice number
   * @returns the parsed string with the dynamic values replaced in the content body
   */
  const parseResponseContent = (): string => {
    const attributesObject = {
      first_name: props?.from?.name ?? "{first_name}",
      invoice_number: props?.attributes?.invoice_number ?? "{invoice_number}",
      user: props?.user ?? "{user}",
      company_name: props?.company_name ?? "{company_name}",
    };
    try {
      return liquid.parseAndRenderSync(props.content, attributesObject);
    } catch (error) {
      console.error("Template Parsing Error:", error);
      return "";
    }
  };

  const handleDiscardCopilot = (): void => {
    setExpanded(false);
    //Show Editor when No Thanks is clicked by user on Copilot Response
    props.onDiscard();
  };

  //Close the opened Copilot modal
  const closeCopilotDisableModal = () => {
    setShowCopilotDiscardModal(!showCopilotDiscardModal);
  };

  //Re-render copilot with appropriate state when another editor window has been opened
  React.useEffect(() => {
    if (props.showNewActivity) {
      setExpanded(false);
    }
  }, [props.showNewActivity]);

  const [tooltipVisibility, setTooltipvisibility] = React.useState(false);

  const onMouseEnter = () => {
    !expanded && setTooltipvisibility(true);
  };
  const onMouseLeave = () => {
    !expanded && setTooltipvisibility(false);
  };

  // handle states on accrodion click
  const onAccordionClick = () => {
    setExpanded(!expanded);
    setTooltipvisibility(false);
  };

  return (
    <div className="copilot-wrapper">
      <Accordion disableGutters expanded={expanded}>
        <AccordionSummary onClick={onAccordionClick}>
          <div className="copilot-accordion-wrapper">
            <CustomTooltip
              open={tooltipVisibility}
              placement="right-end"
              type="RICH_CONDENSE"
              title={t("activityDetails.copilot.tooltipMessage", { ns: "activities" }) as string}
            >
              <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={`copilot-header ${expanded ? "expanded" : "close"}`}>
                <div className="copilot-header-left">
                  <AutomateActivity />
                  {expanded ? (
                    <>
                      <Typography variant="body1Bold">{t("activityDetails.copilot.expandedHeader", { ns: "activities" })}</Typography>
                      <Typography variant="body1Reg">{t("activityDetails.copilot.expandedCaption", { ns: "activities" })}</Typography>
                    </>
                  ) : (
                    <Typography variant="body1Bold">{t("activityDetails.copilot.collapsedHeader", { ns: "activities" })}</Typography>
                  )}
                </div>
                <div className="copilot-header-right">
                  {expanded ? (
                    <>
                      <Button
                        variant="ghost"
                        size="md"
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                          e.stopPropagation();
                          setShowCopilotDiscardModal(true);
                        }}
                      >
                        {t("activityDetails.copilot.disableButtonText", { ns: "activities" })}
                      </Button>
                      <div className="collapsed-icon-wrapper">
                        <Typography className="collapse-text" variant="captionReg">
                          {t("activityDetails.copilot.collapsedCaption", { ns: "activities" })}
                        </Typography>
                        <CollapseIcon />
                      </div>
                    </>
                  ) : (
                    <ExpandIcon />
                  )}
                </div>
              </div>
            </CustomTooltip>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="copilot-content">
            <NewActivityPopup
              key={`key-add-activity-`}
              title={`Add Activity`}
              open={expanded}
              defaultTo={getFormattedContacts([props.from])}
              defaultCc={getFormattedContacts(props.cc)}
              handleSend={activityPopUpProps.handleSend}
              handleSendMarkClosed={activityPopUpProps.handleSendMarkClosed}
              onClose={activityPopUpProps.onClose}
              defaultTitle={props.subject}
              disableTitle={false}
              activityType={activityPopUpProps.activityType}
              setNewActivityType={activityPopUpProps.setNewActivityType}
              typeAheadAction={activityPopUpProps.typeAheadAction}
              editorState={parseResponseContent()}
              clickDisabled={false}
              defaultAttachmentsList={props.attachments}
              onClickDownload={props.onClickDownload}
              isCopilot={true}
              discardCopilot={handleDiscardCopilot}
              workspaceData={props.workspaceData}
              toTime={props.to_time}
              fromTime={props.from_time}
              copilotContent={props?.content}
              isSendButtonDisabled={props?.isSendButtonDisabled}
            />
          </div>
        </AccordionDetails>
      </Accordion>
      <CopilotDiscardModal
        showModal={showCopilotDiscardModal}
        closeModal={closeCopilotDisableModal}
        handleCancel={closeCopilotDisableModal}
        handleDisableClick={() => {
          updateCopilotSettings(false);
          setShowCopilotDiscardModal(false);
        }}
      />
    </div>
  );
};

export default Copilot;

import React, { useState, MouseEvent, useEffect } from "react";
import TableUtils from "../../../../utils/TableUtils/TableUtils";
import Button from "../../../library/Button/Button";
import { PlusSign, Delete, MoveTo, Edit, PrimaryContact } from "../../../library/Icons/Icons";
import { useParams, useHistory, useRouteMatch } from "react-router-dom";
import { Drawer } from "@mui/material";
import "./Contacts.scss";
import { DEFAULT_NUMERIC_VALUES, NUMERIC_VALUES } from "../../../../constants/NumericConstants";
import { AlertContext } from "../../../../contexts/AlertContext";
import { CustomerContext } from "../../../../contexts/CustomerContext";
import { ContactFormContainer } from "./ContactForm/ContactFormContainer";
import { DataMode, VIEW_CATEGORIES, WorkspaceType } from "../../../../types/enums";
import { ContactMoveContainer } from "./ContactActions/ContactMove/ContactMoveContainer";
import { contactsClientV2 } from "../../../../db/version2Accessor";
import { WorkspaceContext } from "../../../../contexts/WorkspaceContext";
import { Modal } from "../../../library/DialogModal";

import { Table, useColumnFilters } from "../../../library/AtomicComponents/Table/index";
import { UserAction } from "../../../library/AtomicComponents/Table/types/table.types";
import usePagination from "../../../library/AtomicComponents/Table/hooks/usePagination";
import useColumnSort from "../../../library/AtomicComponents/Table/hooks/useColumnSort";
import useRowSelection from "../../../library/AtomicComponents/Table/hooks/useRowSelection";
import { DEFAULT_PAGINATION_VALUES } from "../../../../constants/NumericConstants";
import APIClientUtils from "../../../../utils/APIClientUtils/APIClientUtils";
import { RowDataTypes } from "../../../library/AtomicComponents/constants/table.constants";
import { CustomerDetailsDashboardContext, CustomerDetailsDashboardData } from "../../../../contexts/CustomerDetailsDashboardContext";
import { DropdownItemList } from "../../../library/AtomicComponents/TableFilters/Presets/preset.filter.types";
import useLocale from "../../../../hooks/useLocale";
import { codeDefinitionsClient } from "../../../../db/accessor";
import { RefetchIntervals } from "../../../../constants/CacheConfig";
import { useQuery } from "@tanstack/react-query";
import { FeatureFlagContext, FeatureFlagProviderType } from "../../../../contexts/FeatureFlagContext";

type ContactsProps = {
  configs: any;
};

/**
 * * Define the Contacts Tab of the Customer Detail's page
 */
export default function ContactsView(props: ContactsProps): React.ReactElement {
  const { customerId } = useParams<{ customerId: string }>();
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteIds, setDeleteIds] = useState<string[]>([]);
  //eslint-disable-next-line
  const [disableEdit, setDisableEdit] = useState<boolean>(false);
  const [disableRowSelectButtons, setDisableRowSelectButtons] = useState<boolean>(false);

  const [dataMode, setDataMode] = useState<DataMode>(DataMode.View);
  const [contactEditData, setContactEditData] = useState<ContactDataType | null>(null);
  const [contactFlyout, setContactFlyout] = useState<boolean>(false);

  const [moveIds, setMoveIds] = React.useState<string[]>([]);
  const [openMoveFlyout, setMoveFlyoutVisibility] = React.useState<boolean>(false);

  const [newPrimaryContact, setNewPrimaryContact] = React.useState<ContactDataType | null>(null);
  const [isContactAlreadyPrimary, setiIsContactAlreadyPrimary] = React.useState<boolean>(false);
  const [showPrimaryContactWarning, setShowPrimaryContactWarning] = React.useState<boolean>(false);

  const [showLoader, setShowLoader] = React.useState<boolean>(false);

  const { setToastOptions } = React.useContext(AlertContext) as AlertContextType;
  const { company, toggleDataUpdated } = React.useContext(CustomerContext) as CustomerType;
  const currentSelectedCompany = company as CustomerDetailsModel;
  const { selectedWorkspace } = React.useContext(WorkspaceContext) as WorkspaceDataType;
  const contactsConfigs = props.configs.contacts;
  const pageNoFromUrl = parseInt(params.get("page") ?? "1", NUMERIC_VALUES.CONSTANT_TEN) || NUMERIC_VALUES.CONSTANT_ONE;
  const { path } = useRouteMatch();
  const initalPageNumber = pageNoFromUrl - DEFAULT_NUMERIC_VALUES.DEFAULT_ONE;
  const { setTotalRecords, setTotalPages, updatePageNumber, ...paginationConfig } = usePagination(
    DEFAULT_PAGINATION_VALUES.PAGE_SIZE,
    initalPageNumber ?? DEFAULT_PAGINATION_VALUES.PAGE_COUNT
  );
  const { ...initialFilterConfigs } = useColumnFilters(true, [], updatePageNumber);

  const { query: sortQuery, ...sortConfig } = useColumnSort("contact_name asc");
  const selectionConfig = useRowSelection(paginationConfig?.page?.totalRecords);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const { calendarDateFormat } = useLocale();
  const { setViewBasedFilters } = React.useContext(CustomerDetailsDashboardContext) as CustomerDetailsDashboardData;
  const [columnsConfigData, setColumnsConfigData] = useState<any[]>(() => contactsConfigs?.table?.columns);
  const [isColumnsConfigDataLoading, setIsColumnsConfigDataLoading] = useState<boolean>(true);
  const { isEnabled } = React.useContext(FeatureFlagContext) as FeatureFlagProviderType;
  const isFiltersEnabled = isEnabled("FILTERS_ENABLE");
  const [isNoSearchResultFound, setIsNoSearchResultFound] = useState<boolean>(false);
  const filterObjFromUrl = JSON.parse(params.get("filters") ?? "{}");
  const appliedFilters = initialFilterConfigs.generateFilterQuery(Object.values(filterObjFromUrl), true);

  //it return category view name for suggestion history
  const getViewCategory = () => {
    const categoryMap: { [key: string]: VIEW_CATEGORIES } = {
      "vendors/active/:customerId/contacts": VIEW_CATEGORIES.ACTIVE_VENDOR_CONTACTS,
      "vendors/archived/:customerId/contacts": VIEW_CATEGORIES.ARCHIVED_VENDOR_CONTACTS,
      "vendors/spam/:customerId/contacts": VIEW_CATEGORIES.SPAM_VENDOR_CONTACTS,
      "vendors/fraud/:customerId/contacts": VIEW_CATEGORIES.FRAUD_VENDOR_CONTACTS,
      "customers/active/:customerId/contacts": VIEW_CATEGORIES.ACTIVE_CUSTOMER_CONTACTS,
      "customers/archived/:customerId/contacts": VIEW_CATEGORIES.ARCHIVED_CUSTOMER_CONTACTS,
      "customers/spam/:customerId/contacts": VIEW_CATEGORIES.SPAM_CUSTOMER_CONTACTS,
      "customers/fraud/:customerId/contacts": VIEW_CATEGORIES.FRAUD_CUSTOMER_CONTACTS,
      "connections/active/:customerId/contacts": VIEW_CATEGORIES.ACTIVE_OTHER_CONTACTS,
      "connections/archived/:customerId/contacts": VIEW_CATEGORIES.ARCHIVED_OTHER_CONTACTS,
      "connections/spam/:customerId/contacts": VIEW_CATEGORIES.SPAM_OTHER_OPEN_CONTACTS,
      "connections/fraud/:customerId/contacts": VIEW_CATEGORIES.FRAUD_OTHER_OPEN_CONTACTS,
    };
    for (const pathKey in categoryMap) {
      if (path.includes(pathKey)) {
        return categoryMap[pathKey];
      }
    }

    return "";
  };

  const cellRendererMapping: CellRendererMappingObject = {
    "RowDataTypes.BoldText": RowDataTypes.BoldText,
    "RowDataTypes.DateEpoch": RowDataTypes.DateEpoch,
    "RowDataTypes.ContactName": RowDataTypes.ContactName,
    "RowDataTypes.Phone": RowDataTypes.Phone,
  };

  const tranformContactData = (
    contactId: UUID,
    contactName: string,
    contactRole: string,
    contactEmail: string,
    contactPhone: string,
    contactIsPrimary: boolean
  ): ContactDataType => ({
    contactId,
    contactName,
    contactRole,
    contactEmail,
    contactPhone,
    contactIsPrimary,
  });

  const fetchParser = (fetchResult: ContactModelFetchResultV2, variant?: FetchVariant): Partial<TableData>[] => {
    return (
      fetchResult?.records?.map((record: ContactItem) => {
        return {
          ...((variant === "id" || variant === "all") && {
            id: record.contact_id,
          }),
          ...((variant === "export" || variant === "all") && {
            contact_name: record?.contact_name,
            role_code: record?.role_code,
            email_address: record?.email_address,
            phoneNumber: record?.phone,
            modified: record?.modified,
            //we do use app_enrollment_id for created from coulmns to determine the value
            app_enrollment_id_blank: record?.app_enrollment_id ? "Accounting System" : "Email",
            isPrimary: record?.is_primary,
            appEnrollmentId: record?.app_enrollment_id,
          }),
          ...(variant === "all" && {
            disableCheckbox: record?.app_enrollment_id ? true : false,
          }),
        };
      }) ?? []
    );
  };
  const { isLoading: isLoadingRoleList, data: cachedRoleList } = useQuery(
    ["contact-role-list"],
    async () => {
      const res = await codeDefinitionsClient.queryCodeDefinitions("CODETYPE EQ 'AccountingRole'", undefined, "codeDefinitionId");
      return res.records;
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchInterval: RefetchIntervals.contactRoles,
    }
  );
  // manage flyout and modal states
  const editContacts = (ids: string[] | string) => {
    setDataMode(DataMode.Edit);
    const item = tableData?.filter((row) => ids.includes(row.id))[0];
    setContactEditData(tranformContactData(item.id, item.contact_name, item.role_code, item.email_address, item.phoneNumber, item.isPrimary));
    setContactFlyout(true);
  };

  const deleteContacts = (ids: string[] | string) => {
    setDeleteIds(ids instanceof Array ? ids : [ids]);
    setShowDeleteModal(true);
  };

  const markPrimaryContact = (data: ContactDataType) => {
    setNewPrimaryContact(data);
    setShowPrimaryContactWarning(true);
  };

  const moveContacts = (ids: string[] | string) => {
    setMoveIds(ids instanceof Array ? ids : [ids]);
    setMoveFlyoutVisibility(true);
  };

  const fetchContacts = async (variant: FetchVariant, columnFilters?: string, pageNumber?: number, pageSize?: number) => {
    const preDefinedFilters = [
      ...contactsConfigs.table.preDefinedFilters,
      {
        searchlightFilter: `qa[company_id_eq]=${customerId}`,
      },
    ];
    const sortQueryString = TableUtils.getSortQueryString(params, sortQuery);

    const filters = [
      TableUtils.columnFilterParser(TableUtils.getFilterFromURL(params, contactsConfigs.table.columns), true, preDefinedFilters),
      columnFilters,
    ]
      .filter(Boolean)
      .join("&");
    return contactsClientV2
      .getContacts(
        selectedWorkspace.id,
        filters,
        TableUtils.sortQueryParser(sortQueryString ?? "", true),
        pageSize ?? paginationConfig.page?.pageSize ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO,
        pageNumber ?? paginationConfig.page?.pageNumber ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO
      )
      .then((fetchResult: any) => {
        return { ...fetchResult, records: fetchParser(fetchResult, variant) };
      })
      .catch((error: any) => {
        console.log(APIClientUtils.buildErrorMessage(error));
      });
  };

  const queryKey = `connections-contact-${selectedWorkspace?.workspace_type_route}`;

  const {
    isLoading,
    data: cachedData,
    refetch,
  } = useQuery(
    [queryKey, paginationConfig.page?.pageNumber, paginationConfig.page?.pageSize, TableUtils.getSortQueryString(params, sortQuery), appliedFilters],
    async () => await fetchContacts("all", appliedFilters),
    {
      refetchOnMount: true,
      refetchInterval: RefetchIntervals.twoHoursDelay,
    }
  );

  useEffect(() => {
    /**
     * update the page number once user perform any action and because of that total records get's changed
     */
    const totalPages = Math.ceil(cachedData?.totalCount / cachedData?.pageSize) ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO;
    if ((paginationConfig.page?.pageNumber || NUMERIC_VALUES.CONSTANT_ONE) > totalPages) {
      updatePageNumber(totalPages || NUMERIC_VALUES.CONSTANT_ONE);
    }
    setTotalRecords(cachedData?.totalCount ?? DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO);
    setTotalPages(totalPages);
    setTableData(cachedData?.records ?? []);
  }, [cachedData]);

  // handlers
  const handleDeleteContacts = async () => {
    setShowLoader(true);
    let toastOptions: ToastOptions = { severity: "error", message: "Something Went Wrong", open: true };

    const createMessage = (actionTaken: string, noOfRecords: number) => {
      return noOfRecords > DEFAULT_NUMERIC_VALUES.DEFAULT_ONE
        ? `${noOfRecords} contacts ${actionTaken} successfully`
        : `Contact is ${actionTaken} successfully`;
    };

    try {
      await contactsClientV2.deleteContacts(deleteIds, selectedWorkspace.id ?? DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE);
      toastOptions = { severity: "success", message: createMessage("deleted", deleteIds.length), open: true };
    } catch (error: unknown) {
      console.log("error:: ", error);
    } finally {
      setShowLoader(false);
      setToastOptions(toastOptions);
      // reset states
      setShowDeleteModal(false);
      setDeleteIds([]);
      // refresh table
      selectionConfig?.resetTableRecords();
      refetch();
      toggleDataUpdated();
    }
  };

  const handleMarkPrimaryContact = async () => {
    setShowLoader(true);
    let toastOptions: ToastOptions = {
      severity: "error",
      message: "Something Went Wrong",
      open: true,
    };
    if (newPrimaryContact) {
      try {
        await contactsClientV2.updateContacts(
          {
            items: [
              {
                contact_id: newPrimaryContact.contactId,
                is_primary: true,
              },
            ],
          },
          selectedWorkspace.id ?? DEFAULT_NUMERIC_VALUES.DEFAULT_NEG_ONE
        );
        toastOptions = {
          ...toastOptions,
          severity: "success",
          message: `${newPrimaryContact.contactName} is now the primary contact`,
        };
      } catch (error) {
        console.log("error:: ", error);
      } finally {
        setShowLoader(false);
        setToastOptions(toastOptions);
        // reset states
        setShowPrimaryContactWarning(false);
        setNewPrimaryContact(null);
        // refresh table
        refetch();
        toggleDataUpdated();
      }
    }
  };

  function handleRowClick(e: MouseEvent<HTMLTableRowElement>, row: { id?: string }, currentPageRecords?: { id: string; isSelected?: boolean }[]) {
    /**
     * If a row item is already selected, then clicking anywhere on other rows should select those rows, and do not navigate.
     * if double clicked, then navigate to those row records
     */
    const clickType = e.detail;
    if (clickType === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE && selectionConfig.tableRecords?.selectedRecordsIds?.length && row.id) {
      selectionConfig?.onClickTableRow(row?.id, currentPageRecords ?? []);
      return;
    }

    e.preventDefault();
    sessionStorage.setItem("lastPath", history.location.pathname);
    const path = history.location.pathname;
    history.push(`${path.substr(DEFAULT_NUMERIC_VALUES.DEFAULT_ZERO, path.lastIndexOf("/contacts"))}/contactProfile/${row.id ?? ""}`);
  }

  const primaryAndERPContactLookup = (selectedRows: any) => {
    const hasPrimaryAndERPContact = selectedRows.some((row: any) => row.isPrimary || row.appEnrollmentId);
    setDisableRowSelectButtons(hasPrimaryAndERPContact);
  };

  const hasSelectedRecordsPrimaryContact = () =>
    selectionConfig?.tableRecords?.selectedRecords.some((row: { [key: string]: boolean | undefined | string }) => row?.isPrimary);

  const rowSelectBtns: UserAction[] = [
    {
      icon: <Edit />,
      alignIcon: "left",
      tooltipLabel: "Edit",
      type: "edit",
      disabled: disableEdit,
      callback: (ids: string[]) => {
        editContacts(ids);
      },
      visible: {
        onHeader:
          selectionConfig.tableRecords?.selectedRecordsIds.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE && !hasSelectedRecordsPrimaryContact(),
        onHover: true,
      },
    } as UserAction,
    ...(!disableRowSelectButtons
      ? ([
          {
            icon: <PrimaryContact />,
            alignIcon: "left",
            tooltipLabel: "Make primary contact",
            callback: markPrimaryContact,
            type: "makePrimary",
            visible: {
              onHeader: false,
              onHover: true,
            },
          } as UserAction,
          {
            icon: <MoveTo />,
            alignIcon: "left",
            tooltipLabel: "Move",
            type: "move",
            callback: moveContacts,
            visible: {
              onHeader: true,
              onHover: true,
            },
          } as UserAction,
          {
            icon: <Delete />,
            alignIcon: "left",
            tooltipLabel: "Delete",
            type: "delete",
            callback: deleteContacts,
            visible: {
              onHeader: true,
              onHover: true,
            },
          } as UserAction,
        ] as UserAction[])
      : []),
  ];

  const getPreviousSuggestedData = (recentSearchHistory: FilterDropdownSuggestionRef) => {
    const { filterSearchedText = {} } = initialFilterConfigs ?? {};
    const { contact_name, email_address } = filterSearchedText;

    return {
      contactName:
        contact_name?.value?.length >= NUMERIC_VALUES.CONSTANT_THREE
          ? recentSearchHistory?.contact_name?.filter((item: DropdownItemList) =>
              initialFilterConfigs?.activeFilters?.contact_name?.ids?.includes(item.id)
            )
          : recentSearchHistory?.contact_name,
      emailAddress:
        email_address?.value?.length >= NUMERIC_VALUES.CONSTANT_THREE
          ? recentSearchHistory?.email_address?.filter((item: DropdownItemList) =>
              initialFilterConfigs?.activeFilters?.email_address?.ids?.includes(item.id)
            )
          : recentSearchHistory?.email_address,
    };
  };

  //it will return maximum of 5 required formated record for dropdown search suggestion
  const getDropdownSearchFilterData = (contactsTableData: TableData[]) => {
    const requiredColumns = [...(contactsConfigs?.table?.columns ?? [])];
    const { filterSearchedText = {}, getSearchSuggestionHistory } = initialFilterConfigs || {};
    const { contact_name, email_address } = filterSearchedText;
    const recentContactSearchedData = getSearchSuggestionHistory(
      selectedWorkspace.workspace_type_route?.toLocaleLowerCase() ?? WorkspaceType.AR,
      getViewCategory()
    );
    const previousData = getPreviousSuggestedData(recentContactSearchedData);

    /**
     * if user searching for result then return result data along with previous selected data
     * else return max of 5 recent searched data if that is exist
     */
    const contactData: DropdownSuggestionListData[] =
      !contact_name?.value || contact_name?.value.length < NUMERIC_VALUES.CONSTANT_THREE
        ? (previousData?.contactName ?? []).slice(NUMERIC_VALUES.CONSTANT_ZERO, NUMERIC_VALUES.CONSTANT_FIVE)
        : [...(previousData?.contactName ?? []), ...(contactsTableData ?? [])];

    const emailData: DropdownSuggestionListData[] =
      !email_address?.value || email_address?.value.length < NUMERIC_VALUES.CONSTANT_THREE
        ? (previousData?.emailAddress ?? []).slice(NUMERIC_VALUES.CONSTANT_ZERO, NUMERIC_VALUES.CONSTANT_FIVE)
        : [...(previousData?.emailAddress ?? []), ...(contactsTableData ?? [])];

    const contactNameDropdownList = contactData
      .filter(({ contact_name, primaryText }) => contact_name || primaryText)
      .map(({ id, contact_name, primaryText, email_address = "", secondaryText = "" }) => ({
        id,
        primaryText: contact_name ?? primaryText,
        isAttachemntRequired: false,
        selected: initialFilterConfigs?.activeFilters?.contact_name?.ids?.includes(id) ?? false,
        secondaryText: email_address || secondaryText || "--",
        isAvatarRequired: true,
      }));

    const emailDropdownList = emailData
      .filter(({ email_address, primaryText }) => email_address || primaryText)
      .map(({ id, email_address, primaryText }) => ({
        id,
        primaryText: email_address ?? primaryText,
        isAttachemntRequired: false,
        selected: initialFilterConfigs?.activeFilters?.email_address?.ids?.includes(id) ?? false,
        secondaryText: "",
        isAvatarRequired: false,
      }));

    /*
     * This code first creates a Map object by mapping each object in the original array to an array with two elements:
     * the key and the value. The Map constructor can then be called with the resulting array of key-value pairs to create
     * a new Map object. Finally, the Array.from method is used to convert the Map object back to an array, where the map
     * function is used to only return the values from the key-value pairs.
     */

    const reverseConatactData = [...contactNameDropdownList].reverse();
    const reverseEmailData = [...emailDropdownList].reverse();

    const uniqueContactNameSuggestions = Array.from(new Map(reverseConatactData.map((obj) => [obj.primaryText, obj])))
      .reverse()
      .map(([, value]) => value);
    const uniqueEmailSuggestions = Array.from(new Map(reverseEmailData.map((obj) => [obj.primaryText, obj])))
      .reverse()
      .map(([, value]) => value);

    return requiredColumns.map((column) => {
      if (column.accessor === "contact_name") {
        return { ...column, dropdownSearchListItems: uniqueContactNameSuggestions };
      }
      if (column.accessor === "email_address") {
        return { ...column, dropdownSearchListItems: uniqueEmailSuggestions };
      }
      return column;
    });
  };

  useEffect(() => {
    primaryAndERPContactLookup(selectionConfig.tableRecords?.selectedRecords);
  }, [selectionConfig.tableRecords?.selectedRecords]);

  useEffect(() => {
    setViewBasedFilters(appliedFilters);
  }, [appliedFilters]);

  useEffect(() => {
    if (contactEditData?.contactIsPrimary) {
      setiIsContactAlreadyPrimary(true);
    }
  }, [contactEditData?.contactIsPrimary]);

  function createFilterQuery(field: string, value: string) {
    if (value && value.length >= NUMERIC_VALUES.CONSTANT_THREE) {
      return `qa[${field}_cont_any]=${encodeURIComponent(value)}`;
    }
    return "";
  }

  useEffect(() => {
    const { filterSearchedText = {} } = initialFilterConfigs;
    const { contact_name = { value: "" }, email_address = { value: "" } } = filterSearchedText;

    const filters: string[] = [];

    const addFilterQuery = (field: string, value: string) => {
      if (value.length >= NUMERIC_VALUES.CONSTANT_THREE) {
        const query = createFilterQuery(field, value);
        filters.push(query);
      }
    };

    addFilterQuery("contact_name", contact_name.value);
    addFilterQuery("email_address", email_address.value);

    setIsColumnsConfigDataLoading(true);

    const fetchData = async () => {
      try {
        let res;
        if (filters.length > NUMERIC_VALUES.CONSTANT_ZERO) {
          const query = filters.join("&");
          res = await fetchContacts("all", query, NUMERIC_VALUES.CONSTANT_ONE, NUMERIC_VALUES.CONSTANT_TWENTY);
        } else {
          res = { totalCount: NUMERIC_VALUES.CONSTANT_ZERO, records: [] };
        }

        setIsNoSearchResultFound(!res.totalCount);
        setColumnsConfigData(getDropdownSearchFilterData(res.records));
      } catch (error) {
        setIsNoSearchResultFound(true);
        console.log(error);
      } finally {
        setIsColumnsConfigDataLoading(false);
      }
    };

    fetchData()?.catch(() => {
      setIsNoSearchResultFound(true);
    });
  }, [
    initialFilterConfigs?.filterSearchedText?.contact_name?.value,
    initialFilterConfigs?.filterSearchedText?.email_address?.value,
    initialFilterConfigs?.filterQuery,
  ]);

  //using caching data to update the footer at the same time
  const updatedPaginationObject = {
    ...paginationConfig,
    ...TableUtils.getUpdatePaginationPageState(paginationConfig, cachedData?.totalCount, cachedData?.pageSize),
  };

  return (
    <>
      <div className="header-button-container">
        <Button icon={<PlusSign />} alignIcon="left" onClick={() => setContactFlyout((prev: boolean) => !prev)}>
          Add Contact
        </Button>
      </div>
      <Table
        configs={{
          //Added the feature flag for the newly added filters excpet which are already there on Production
          columnConfigs: columnsConfigData?.map((item) => {
            if (["role_code", "modified", "phoneNumber", "app_enrollment_id_blank"].includes(item.accessor)) {
              return { ...item, rowDataType: cellRendererMapping[item?.rowDataType] };
            } else {
              return { ...item, rowDataType: cellRendererMapping[item?.rowDataType], enableFilter: isFiltersEnabled };
            }
          }),
          initialTableConfigs: {
            name: "Contacts",
            type: "Contact",
            footerTableTypeText: "Contact",
            loading: isLoading,
          },
          paginationConfigs: { ...updatedPaginationObject, enableNavigator: true, enableSummary: true },
          initialSortConfigs: sortConfig,
          selectionConfig: selectionConfig,
          disableRowMessage: "Contact synced from your accounting system, you cannot edit/move/delete this contact.",
          initialFilterConfigs: {
            ...initialFilterConfigs,
            dateFormat: calendarDateFormat,
            isSuggestionDataLoading: isColumnsConfigDataLoading,
            category: getViewCategory(),
            workspace: selectedWorkspace.workspace_type_route?.toLocaleLowerCase() ?? WorkspaceType.AR,
            isNoSearchResultFound,
          },
        }}
        rows={(cachedData?.records ?? [])?.map((rec: any) => {
          return {
            ...rec,
            read: true,
            modified: new Date(rec.modified).getTime() / DEFAULT_NUMERIC_VALUES.DEFAULT_THOUSAND,
          };
        })}
        handlers={{
          onRowClick: handleRowClick,
        }}
        secondaryRowActions={[]}
        userActions={[...rowSelectBtns]}
        hoverBar={true}
        visibility={{
          exportBtn: false,
          hoverbar: false,
        }}
      />
      <Drawer
        anchor={"right"}
        open={contactFlyout}
        onClose={() => {
          setContactFlyout(false);
          setDataMode(DataMode.View);
        }}
      >
        <ContactFormContainer
          dataMode={dataMode}
          customerId={customerId}
          setAddContactFlyout={setContactFlyout}
          flyoutState={contactFlyout}
          tableData={tableData}
          isLoadingRoleList={isLoadingRoleList}
          roleList={cachedRoleList ?? []}
          contactEditData={contactEditData}
          setDataMode={setDataMode}
          markPrimaryContact={markPrimaryContact}
          isContactAlreadyPrimary={isContactAlreadyPrimary}
        />
      </Drawer>
      <Modal
        modalStates={{
          open: showDeleteModal,
          onCloseFunction: () => setShowDeleteModal(false),
        }}
        title={{
          label: "Delete Contacts",
        }}
        content={{
          label: `Are you sure you want to delete ${deleteIds.length} contact${deleteIds.length === DEFAULT_NUMERIC_VALUES.DEFAULT_ONE ? "" : "s"}`,
        }}
        primaryButton={{
          enable: true,
          type: "button",
          variant: "error",
          label: "Delete",
          loader: showLoader,
          onClickHandler: handleDeleteContacts,
        }}
        secondaryButton={{
          enable: true,
          type: "button",
          variant: "grey",
          label: "Cancel",
          onClickHandler: () => setShowDeleteModal(false),
        }}
      />
      <Modal
        modalStates={{
          open: showPrimaryContactWarning,
          onCloseFunction: () => setShowPrimaryContactWarning(false),
        }}
        title={{
          label: "Make primary contact?",
        }}
        content={{
          label: `${newPrimaryContact?.contactName} will become the default contact for communications to ${currentSelectedCompany.name}.  You can change to another contact in the future.`,
        }}
        primaryButton={{
          enable: true,
          type: "button",
          variant: "primary",
          label: "Yes, make primary",
          loader: showLoader,
          onClickHandler: handleMarkPrimaryContact,
        }}
        secondaryButton={{
          enable: true,
          type: "button",
          variant: "grey",
          label: "No",
          onClickHandler: () => setShowPrimaryContactWarning(false),
        }}
      />
      <ContactMoveContainer
        moveIds={moveIds}
        setMoveIds={setMoveIds}
        open={openMoveFlyout}
        setOpen={setMoveFlyoutVisibility}
        softRefresh={() => refetch() as any}
      />
    </>
  );
}

import React from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Card, Skeleton } from "@mui/material";
import { RecordCardRoot, RecordHeader, RecordDetails } from "../styled/styled";

export default function ContactsCardLoader(): React.ReactElement {
  return (
    <RecordCardRoot>
      <Card raised={true} className="transaction-card">
        <RecordHeader>
          <Skeleton variant="text" width="100%" height="1.5rem" animation="wave" />
          <RecordDetails>
            <Skeleton variant="text" width="100%" height="100%" animation="wave" />
          </RecordDetails>
        </RecordHeader>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Skeleton variant="text" width="80%" height="100%" animation="wave" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width="80%" height="100%" animation="wave" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Skeleton variant="text" width="80%" height="100%" animation="wave" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" width="80%" height="100%" animation="wave" />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </RecordCardRoot>
  );
}

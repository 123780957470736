import React from "react";
import SecondaryActivity from "../../SecondaryActivity/SecondaryActivity";
import { SecondaryType } from "../../SecondaryActivity/SecondaryActivityTypes";
import { SpamProps } from "../ActivityItemVariantTypes";
import { Button } from "../../../../../../library/AtomicComponents/atomic/Button";
import { useTranslation } from "react-i18next";

const Spam: React.FC<SpamProps> = ({ onClickNotSpam, creator, readonly, enableNotSpam, countryCode }) => {
  const { t } = useTranslation();
  creator = { ...creator, message: t("activityDetails.feedItems.spam.markedAsSpamText", { ns: "activities" }) };
  return readonly || !enableNotSpam ? (
    <SecondaryActivity variant={SecondaryType.Base} creator={creator} countryCode={countryCode} />
  ) : (
    <SecondaryActivity variant={SecondaryType.DetailedWithAction} creator={creator} countryCode={countryCode}>
      <Button variant="secondary" size="small" onClick={onClickNotSpam}>
        {t("activityDetails.feedItems.spam.notSpamButton", { ns: "activities" })}
      </Button>
    </SecondaryActivity>
  );
};

export default Spam;

import { makeStyles } from "@mui/styles";
import styles from "../../../css/_export.module.scss";

const useCustomTooltipStyle = () => {
  const useStyles = makeStyles({
    plainTooltip: {
      maxWidth: "15.625rem",
      minHeight: "1.5625rem",
      padding: `${styles.tiny} ${styles.small}`,
      color: styles.white100,
      fontFamily: styles.primaryFontFamily,
      fontWeight: "400",
      fontSize: styles.fontSizeXS,
      lineHeight: styles.lineHeightSM,
      background: "#4D4D4D",
    },
    richTooltip: {
      maxWidth: "21.875rem",
      minHeight: "3.625rem",
      padding: styles.base,
      color: "#4D4D4D",
      fontFamily: styles.primaryFontFamily,
      fontWeight: "400",
      fontSize: styles.fontSizeXS,
      lineHeight: styles.lineHeightSM,
      background: styles.white100,
      boxShadow: `0 ${styles.borderline} ${styles.atom}  rgba(0, 0, 0, 0.15)`,
      pointerEvents: "auto",
    },
    richCondenseTooltip: {
      maxWidth: "21.875rem",
      maxHeight: "3.625rem",
      padding: `${styles.medium} ${styles.base}`,
      color: "#4D4D4D",
      fontFamily: styles.primaryFontFamily,
      fontWeight: "400",
      fontSize: styles.fontSizeXS,
      lineHeight: styles.lineHeightSM,
      background: styles.white100,
      boxShadow: `0 ${styles.borderline} ${styles.atom}  rgba(0, 0, 0, 0.15)`,
    },
    richCondenseTooltipV2: {
      maxWidth: "25rem",
      maxHeight: "4.25rem",
      padding: "1rem",
      fontFamily: "Sage UI",
      fontSize: "0.75rem",
      fontWeight: "400",
      lineHeight: "1.125rem",
      color: "#1A1A1A",
      background: "#F5F5F5",
      boxShadow: "0 0.0625rem 0.125rem 0 rgba(0, 0, 0, 0.15)",
      borderRadius: "0.5rem",
    },
    shortcutTooltip: {
      maxWidth: "21.875rem",
      minHeight: "1.5625rem",
      padding: `${styles.tiny} ${styles.tiny} ${styles.tiny} ${styles.small}`,
      color: styles.white100,
      fontFamily: styles.primaryFontFamily,
      fontWeight: "400",
      fontSize: styles.fontSizeXS,
      lineHeight: styles.lineHeightSM,
      background: "#4D4D4D",
    },
    shortcutDivRoot: {
      display: "flex",
      gap: styles.small,
    },
    shortcutButtonContainer: {
      display: "flex",
      flexDirection: "row",
      gap: styles.tiny,
    },
    shortcutButtons: {
      height: "1rem",
      minWidth: "1rem",
      fontFamily: styles.primaryFontFamily,
      fontWeight: "400",
      fontSize: styles.fontSizeXS,
      lineHeight: styles.lineHeightSM,
      background: styles.white100,
      borderRadius: styles.atom,
      color: "#4D4D4D",
      padding: `0 ${styles.tiny}`,
      textAlign: "center",
    },
  });
  return useStyles();
};

export default useCustomTooltipStyle;

import React from "react";
import "./OnboardVendor.scss";
import { OnboardVendorProps } from "../../ActivityItemVariantTypes";
import Menu from "../../../../../../../library/Menu/Menu";
import MenuItem from "../../../../../../../library/MenuItem/MenuItem";
import { CaretUp } from "../../../../../../../library/Icons/Icons";
import { CompanyType } from "../../../../../../../../types/enums";
import { Button } from "../../../../../../../library/AtomicComponents/atomic/Button";
import { useTranslation } from "react-i18next";

const OnboardVendor: React.FC<OnboardVendorProps> = ({ onClickOnboard, onClickDismissFlag, dynamicCompanySelection = false }) => {
  const [disappear, setDisappear] = React.useState<boolean>(false);
  const [startOnboardAnchor, setStartOnboardAnchor] = React.useState<HTMLDivElement | HTMLButtonElement | null>(null);
  const [actionTooltipVisible, setActionTooltipVisible] = React.useState<boolean>(false);
  const { t } = useTranslation();

  // AP - Start Onboarding Flow
  const onClickEnhancedOnboard = () => {
    onClickOnboard(CompanyType.VENDOR.toLocaleLowerCase());
    setDisappear(true);
  };

  // AW - Combined Onboarding Flow
  const onClickDynamicOnboardOption = (id: string) => {
    // 1. make tooltip disappear
    setActionTooltipVisible(false);
    // 2. call the onClickOnboard to open up the popup
    onClickOnboard(id);
    // 3. disappear the banner
    setDisappear(true);
  };

  const onClickDismiss = async () => {
    // 1. call backend handler
    const res = await onClickDismissFlag();
    // if dismiss was a success
    if (res) {
      // 2. hide banner
      setDisappear(true);
    }
  };

  const onboardingOptions = [
    {
      label: t("activityDetails.feedItems.onboardVendor.connectionLabel.customer", { ns: "activities" }),
      id: CompanyType.CUSTOMER.toLocaleLowerCase(),
    },
    {
      label: t("activityDetails.feedItems.onboardVendor.connectionLabel.vendor", { ns: "activities" }),
      id: CompanyType.VENDOR.toLocaleLowerCase(),
    },
  ];

  return !disappear ? (
    <div className="onboard-activity activity-feed-item-card">
      <div className="onboard-activity-icon">📢</div>
      <div className="onboard-activity-body">
        <h3 className="onboard-activity-body-header">{t("activityDetails.feedItems.onboardVendor.card.headerText", { ns: "activities" })}</h3>
        <p className="onboard-activity-body-text">
          {t("activityDetails.feedItems.onboardVendor.card.bodyText1.firstPart", { ns: "activities" })}
          <span className="highlight">{t("activityDetails.feedItems.onboardVendor.card.bodyText1.middlePart", { ns: "activities" })}</span>
          {t("activityDetails.feedItems.onboardVendor.card.bodyText1.lastPart", { ns: "activities" })}
          <br />
          {t("activityDetails.feedItems.onboardVendor.card.bodyText2", { ns: "activities" })}
        </p>
      </div>
      <Menu
        open={actionTooltipVisible}
        onClose={() => setActionTooltipVisible(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        anchorEl={startOnboardAnchor}
      >
        {onboardingOptions.map((item, index) => {
          return (
            <MenuItem key={`${item.id}-${index}`} onClick={() => onClickDynamicOnboardOption(item.id)} id={item.id}>
              {item.label}
            </MenuItem>
          );
        })}
      </Menu>

      <div
        className="onboard-activity-action"
        onClick={(e) => {
          setStartOnboardAnchor(e.currentTarget ?? null);
          setActionTooltipVisible((prev) => !prev);
        }}
      >
        {!dynamicCompanySelection ? (
          <>
            <Button className="request-onboard-btn" variant="primary" size="small" onClick={onClickEnhancedOnboard}>
              {t("activityDetails.feedItems.onboardVendor.buttons.startOnboarding", { ns: "activities" })}
            </Button>
          </>
        ) : (
          <div className="request-onboard-menu">
            <div className="request-onboard-menu-details">
              <span className="request-onboard-menu-title">
                {t("activityDetails.feedItems.onboardVendor.buttons.startOnboarding", { ns: "activities" })}
              </span>
              <div className={`menu-caret-icon ${actionTooltipVisible ? "expanded" : "close"}`}>
                <CaretUp />
              </div>
            </div>
          </div>
        )}
        <>
          <Button
            className="dismiss-onboard-btn"
            variant="secondary"
            size="small"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClickDismiss();
            }}
          >
            {t("activityDetails.feedItems.onboardVendor.buttons.dismiss", { ns: "activities" })}
          </Button>
        </>
      </div>
    </div>
  ) : null;
};

export default OnboardVendor;

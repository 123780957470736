import React from "react";
import { Calendar } from "../../../../../../library/Icons/Icons";
import PrimaryActivity from "../../PrimaryActivity/PrimaryActivity";
import { NudgeProps } from "../ActivityItemVariantTypes";
import * as Labels from "../../../../../../../constants/config";
import { Button } from "../../../../../../library/AtomicComponents/atomic/Button";
import { useTranslation } from "react-i18next";

const NudgeActivity: React.FC<NudgeProps> = ({ onClickNudge, enableNudgeAgain, ...props }) => {
  const { t } = useTranslation();
  return (
    <PrimaryActivity
      {...{ ...props, creator: { ...props.creator, message: Labels.HEADER_NUDGE } }}
      icon={<Calendar />}
      FLAGS={{ REPLY_OPTIONS: true, TIME_SAVING_INDICATOR: true }}
    >
      {enableNudgeAgain && (
        <Button variant="secondary" size="small" onClick={onClickNudge}>
          {t("activityDetails.feedItems.nudge.nudgeAgainButton", { ns: "activities" })}
        </Button>
      )}
    </PrimaryActivity>
  );
};

export default NudgeActivity;
